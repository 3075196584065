// tslint:disable-next-line:max-line-length
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComplexComponentFactoryComponent } from 'app/infraestrutura/componente/complex-component-factory/complex-component-factory.component';
import { Subject, Subscription } from 'rxjs';
import { EventoCardQuestionario } from '../../passos/questionario/card/EventoCardQuestionario';
import { EventoInput } from '../evento/eventoInput';
import { ComplexInputFactoryService } from './complex-input-factory.service';

/**
 * Componente responsavel por renderizar a instancia correta
 * do componente que compoe o elementoQuestionario
 */
@Component({
  selector: 'app-complex-input-factory',
  templateUrl: './complex.input.factory.component.html',
  styleUrls: ['./complex.input.factory.component.scss'],
})
export class ComplexInputFactoryComponent extends ComplexComponentFactoryComponent {

  constructor(complexInputFactory: ComplexInputFactoryService) {
    super(complexInputFactory)
  }
  /**
    * emite eventos relacionados ao input, nao sera
    * utilizado aqui e sim repassado aos componentes instanciados
    */
  @Output() inputUpdate: EventEmitter<EventoInput> = new EventEmitter<EventoInput>();

  /**
  * emite eventos relacionados aos EventoCardQuestionario, nao sera
  * utilizado aqui e sim repassado aos componentes instanciados
  */
  @Output() cardEvent: EventEmitter<EventoCardQuestionario> = new EventEmitter();

  /**
   * Publisher de re-renderização, caso deseje-se a re-renderização
   * manual do componente criado pela fabrica
   */
  @Input() reRenderPublisher: Subject<{}>;
  reRenderSubscription: Subscription;

  /**
   * receptor de eventos lancados pelo pai
   * utilizado aqui e sim repassado aos componentes instanciados
   */
  @Input() parentEventPublisher: Subject<EventoInput>;

   /**
   * Subject que informa o hash do CardQuestionario que está selecionado
   * atualmente. Informação útil para adicionar css customizado, nao sera
  * utilizado aqui e sim repassado aos componentes instanciados
   */
  @Input() seletorPublisher: Subject<any>;

  /**
   * @override
   */
  render() {

    if (!this.tipoInput) {
      return;
    }

    // tslint:disable-next-line:max-line-length
    const componentFactory = this.complexInputFactory.getComponetFactory(this.tipoInput);

    this.childElementRef = this.container.createComponent(componentFactory);
    const elementInstance = this.childElementRef.instance;

    elementInstance.parentEventPublisher = this.parentEventPublisher;
    elementInstance.inputUpdate = this.inputUpdate;

    elementInstance.cardEvent = this.cardEvent;
    elementInstance.seletorPublisher = this.seletorPublisher;

    if (this.params) {
      const paramsKeys = Object.keys(this.params);

      paramsKeys.forEach((paramKey) => {

        // console.log('key:  ', paramKey);
        // console.log('value: ', this.params[paramKey]);
        elementInstance[paramKey] = this.params[paramKey];
      });
    }
  }

}
