import { Input, OnInit, Component, Output, EventEmitter } from "@angular/core";
import { HeadAlignTypes, TableHead } from "../../interfaces/tHead";
import { CustomStyles } from "../../interfaces/tCell";

@Component({
  selector: "app-generic-table-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class GenericTableHeader implements OnInit {
  constructor() {}

  @Input() data: TableHead[] = [];
  @Input() widths: string[] = [];
  @Input() height: string = "60px";

  @Output() onClick = new EventEmitter<TableHead>();

  private defaultWidths: string[] = [];
  private defaultHeight: string = "60px";

  ngOnInit(): void {
    if (!this.height) this.height = this.defaultHeight;
    if (!this.widths) this.widths = this.defaultWidths;

    this.initCols();
  }

  initCols() {
    this.defaultWidths = this.data.map((_) => ".25");

    if (!this.widths.length) {
      this.widths = [...this.defaultWidths];
    }
  }

  handleCheck(ref: TableHead) {
    this.onClick.emit(ref);
  }

  getDynamicStyles(
    customStyles: CustomStyles,
    alignTypes: HeadAlignTypes = "flex-start",
    width: string
  ) {
    const dynamicStyle: CustomStyles = {
      justifyContent: alignTypes,
    };

    if (width.includes("px")) {
      dynamicStyle.width = width;
    } else {
      dynamicStyle.flex = width;
    }

    return {
      ...customStyles,
      ...dynamicStyle,
    };
  }
}
