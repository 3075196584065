import { AbstractControl, ValidationErrors } from '@angular/forms';

// validador que trata de inputs no formato de tempo HH:mm

export default (control: AbstractControl): ValidationErrors => {

  const time = control.value;

  if (isValidTime(time)) {
    return null;
  }

  return {
    invalid: true,
  };

};

function isValidTime(time: string): boolean {
  const timePattern = new RegExp(/[0-5][0-9]:[0-5][0-9]/g);
  const isValid = timePattern.test(time);

  return isValid;
}
