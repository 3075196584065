import { BarSeriesOption } from "echarts";

/**
 * Author João Pedro <joao.pedro@tensai.com.br>
 * 
 * Agrupa todas as series de dados do gráfico seguindo o contrato
 * 
 * {
 *    [key: valor_percentual]: [ocorrenciaA, ocorrenciaB, ocorrenciaC] 
 * }
 * 
 * para aplicar um algorítmo de ordenação alfabética por agrupamento, e em seguida,
 * com base nos valores percentuais armazenados na chave do objeto
 * 
 * é aplicado um algorítmo de sort do maior valor percentual para o menor.
 * 
 * TC-2406
 */
export function sortSeriesPercentuallyAndAlphabetically(
  series: Array<BarSeriesOption>
): Array<BarSeriesOption> {
  const groupedSeries: { [key: number]: Array<BarSeriesOption> } = {};

  series.forEach((currentSerie, index) => {
    const currentPercentual = (currentSerie.data as Array<number>)[0];

    const nextSerie = series[index + 1];

    if (groupedSeries[currentPercentual] === undefined) {
      groupedSeries[currentPercentual] = [];
    } else if (groupedSeries[currentPercentual].includes(currentSerie)) {
      return;
    }

    if (nextSerie !== undefined) {
      const nextPercentual = (nextSerie.data as Array<number>)[0];

      if (currentPercentual === nextPercentual) {
        groupedSeries[currentPercentual].push(nextSerie);
      }
    }

    groupedSeries[currentPercentual].push(currentSerie);
  });

  const groupedValues = Object.values(groupedSeries);

  // Sorting por ordem alfabética dos valores agrupados
  groupedValues.forEach((groupedSeriesArr: Array<BarSeriesOption>) => {
    groupedSeriesArr.sort((a, b) => {
      const textA = a.name.toString().toUpperCase();
      const textB = b.name.toString().toUpperCase();

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  });

  // Sorting pelos valores percentuais das chaves do objeto de agrupamento
  const sortedKeys = Object.keys(groupedSeries)
    .map(Number)
    .sort((valorPercentualA, valorPercentualB) => valorPercentualB - valorPercentualA);

  // Parse final
  try {
    const sortedSeries = [
      ...sortedKeys
        .map((percentual) => groupedSeries[percentual])
        .reduce((prev, curr) => [...prev, ...curr], []),
    ];

    return sortedSeries;
  } catch {
    return series;
  }
}
