import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { StatusPesquisa } from "app/modulos/pesquisa-old/status/statusPesquisa";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ElementoSecao } from "../../../../../../pesquisa-old/cadastro/model/elementoSecao";
import {
  MoverPergunta,
  RemoverSecao,
  ReordenarPergunta,
  Selecionar,
  SelecionarPergunta,
} from "../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction";
import { CadastroPesquisaStoreState } from "../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState";
import { PassoCadastro } from "../../../../../../pesquisa-old/cadastro/store/passoCadastro";
import { CardQuestionario } from "../../card/cardQuestionario";
import { TipoConteudo } from "./conteudo/tipoConteudo";

@Component({
  selector: "app-secao",
  templateUrl: "./secao.component.html",
  styleUrls: ["./secao.component.scss"],
})
export class SecaoComponent implements CardQuestionario, OnInit, OnDestroy {
  /**
   * Ordem da secao, deve ser igual à ordem do elemento questionario
   */
  @Input() ordem: number;

  /**
   * FormGroup da seção
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Acesso rápido aos controls do formGroup
   */
  controls: { [key: string]: AbstractControl };

  /**
   * Marcador se a seção está selecionada
   */
  selecionado: boolean = false;

  /**
   * Marcador se a pergunta está selecionada
   */
  perguntaSelecionada: boolean = false;
  /**
   * Hash do item selecionado atualmente,
   * este hash pode ser tanto da propria seção
   * quanto dos elementos que a compõe (pergunta)
   */
  hashItemSelecionado: string;

  /**
   * Subscriptions que deverão ser encerradas
   * junto com a destruição do componente
   */
  subscriptions: Subscription[] = [];

  @Input() showErrors: boolean;

  ocultarPulo: boolean = false;

  statusAtualPesquisa: StatusPesquisa;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {}

  print(json) {
    return JSON.stringify(json);
  }

  /**
   * @override
   */
  ngOnInit() {
    console.log("==================>>>>> AQUIIII", this.formGroup);

    this.controls = this.formGroup.controls;
    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> =
      this.store.pipe(
        map((x) => x["cadastroPesquisa"]),
        map((pesquisa) =>
          pesquisa ? pesquisa : new CadastroPesquisaStoreState()
        )
      );

    /**
     * Observando itens selecionados
     */
    const itemSelecionadoSubscription = pesquisaStoreObservable
      .pipe(
        map((store) => store.dadosPasso.get(PassoCadastro.QUESTIONARIO)),
        map((dadosPasso) => dadosPasso.itemSelecionado)
      )
      .subscribe((itemSelecionado) => {
        if (!itemSelecionado.item) {
          return;
        }
        this.perguntaSelecionada = itemSelecionado.item.perguntaSelecionada;
        this.hashItemSelecionado = itemSelecionado.item.hash;
        this.selecionado =
          this.controls.hash.value === this.hashItemSelecionado;
      });

    this.setUpValidators(this.formGroup);

    this.subscriptions.push(itemSelecionadoSubscription);
  }

  pegarUltimoElemento(elementosSecao: ElementoSecao) {
    // console.log("Pegando ultimo elemento", elementosSecao[elementosSecao.length - 1]);

    if (elementosSecao !== undefined) {
      // const ultimoElemento =  elementosSecao[elementosSecao.length - 1];
    }
  }

  setUpValidators(secaoFg: UntypedFormGroup) {
    secaoFg.controls.elementosSecao.setValidators([Validators.required]);
    secaoFg.controls.elementosSecao.updateValueAndValidity();
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  // onChange() {
  //   this.store.dispatch(new AlterarSecao(this.formGroup.value));
  // }

  removerSecao(evento: Event) {
    event.stopPropagation();

    const removerCalback = () => {
      this.store.dispatch(new RemoverSecao(this.formGroup.value));
    };
  }

  getNestedInputParams(elementoSecao: UntypedFormGroup) {
    const params = {
      formGroup: elementoSecao.controls.pergunta,
    };

    return params;
  }

  notificarSelecao() {
    this.store.dispatch(
      new Selecionar(this.formGroup.value, TipoConteudo.CONTEUDO_SECAO)
    );
  }

  /**
   * Evento disparado quando uma pergunta é solta
   * após uma ação de drag
   */
  //onDropPergunta(evento: CdkDragDrop<any, any>) {
  //  console.log('------>>>>>',evento.previousContainer === evento.container)

  //}
  onDropPergunta(evento: CdkDragDrop<string[]>) {
    if (!evento.isPointerOverContainer) return;
    if (evento.previousContainer === evento.container) {
      /**
       * Solicitando a reordenação da pergunta
       */
      //tslint:disable-next-line: max-line-length
      this.store.dispatch(
        new ReordenarPergunta(
          this.ordem,
          evento.previousIndex,
          evento.currentIndex
        )
      );
    } else {
      this.store.dispatch(
        new MoverPergunta(
          evento.previousContainer.data,
          evento.container.data,
          evento.previousIndex,
          evento.currentIndex
        )
      );
    }
  }

  selecionarPergunta(event: Event) {
    this.store.dispatch(
      new SelecionarPergunta(this.formGroup.value, TipoConteudo.CONTEUDO_SECAO)
    );
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(
    abstractFormArray: AbstractControl
  ): AbstractControl[] {
    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }
}
