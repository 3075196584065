import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { RecuperarSenhaService } from "../recuperarSenhaService";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";

@Component({
  selector: "app-solicitacao-troca-senha",
  templateUrl: "./solicitacao-troca-senha.component.html",
  styleUrls: ["./solicitacao-troca-senha.component.scss"],
})
export class SolicitacaoTrocaSenhaComponent implements OnInit {
  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };
  requested: boolean = false;
  isLoading: boolean = false;
  isButtonDisabled: boolean = false;
  timeLeft: number = 0;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private recuperarSenhaService: RecuperarSenhaService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  ngOnInit() {
    this.initFormGroup();
  }

  private initFormGroup() {
    const location = this.document.location;
    const callbackLink = `http://${location.hostname}:${location.port}/redefinir-senha`;

    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl("", [
        Validators.email,
        Validators.required,
      ]),
      callbackLink: new UntypedFormControl(callbackLink),
    });
    this.controls = this.formGroup.controls;
  }

  solicitar(formData: { email: string, callbackLink: string }) {
    this.isLoading = true;

    this.recuperarSenhaService.solicitarRecuperacao(formData).subscribe({
      next: () => {
        this.isLoading = false;
        this.requested = true;
        this.disableButtonAfterEmailSended();
      },
      error: (err) => {
        this.requested = false;
        this.isLoading = false;
        this.errorHandlerService.handleError(
          err,
          'Erro ao recuperar senha',
        );
      },
    });
  }


  // Return btn title according to request status
  get renderSubmitBtnTitle() {
    if(!this.requested) {
      return 'Enviar';
    } else if(this.requested && this.timeLeft !== 0) {
      return 'Reenviar link (' + this.timeLeft + ')';
    } else {
      return 'Reenviar link';
    }
  }

  // Disabled btn and show counter after e-mail sended
  disableButtonAfterEmailSended() {
    this.isButtonDisabled = true;
    this.timeLeft = 30;

    const interval = setInterval(() => {
      this.timeLeft--;

      if (this.timeLeft <= 0) {
        clearInterval(interval);
        this.isButtonDisabled = false;
      }
    }, 1000);
  }

  // Return to login page when cancel btn is clicked
  goBackLoginPage() {
    this.router.navigate(['/login']);
  }

}
