<div
  *ngIf="!!data"
  class="tooltip-container"
  [style.left]="x"
  [style.top]="y"
  [style.display]="!isVisible ? 'none' : 'initial'"
  #containerRef
>
  <p
    class="tooltip-message"
  >
   {{ data }}
  </p>
</div>
