import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl, Validators } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';
import { ElementoSecao } from './elementoSecao';
import { v4 as uuid } from 'uuid';

export class Secao {

  id: string;
  nome: string = '';
  descricao: string = '';
  elementosSecao: ElementoSecao[] = [];
  /**
   * Hash da sessão, igual ao do ElementoQuestionario
   */
  hash: string = uuid();

  perguntaSelecionada: boolean = false;

  /**
   * Cria um FormGroup representando a entidade
   */
  static getControl(): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line:max-line-length
      nome: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_NOME)),
      // tslint:disable-next-line:max-line-length
      descricao: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_DESCRICAO)),
      elementosSecao: new UntypedFormArray([], [Validators.required]),
      hash: new UntypedFormControl(uuid()),

    });

    return formGroup;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(secao: Secao): UntypedFormGroup {

    /**
     * Caso a seção nao tenha um ID, o hash aleatorio
     * sera utilizado, caso contrario, o ID assumira o
     * papel do hash.
     *
     * Por conta do hash ser um uuid(String), trabalharemos com
     * o id como se fosse uma também
     */
    secao.id = secao.id ? String(secao.id) : secao.id;
    const hash = secao.id ? secao.id : secao.hash; 

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(secao.id),
      // tslint:disable-next-line:max-line-length
      nome: new UntypedFormControl(secao.nome, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_NOME)),
      // tslint:disable-next-line:max-line-length
      descricao: new UntypedFormControl(secao.descricao, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_DESCRICAO)),
      hash: new UntypedFormControl(hash),
    });

    // tslint:disable-next-line: max-line-length
    /*secao.elementosSecao = secao.elementosSecao.sort((elementoSecao1, elementoSecao2) => {
      return elementoSecao1.ordem - elementoSecao2.ordem;
    });*/

    // tslint:disable-next-line: max-line-length
    const elementosSecaoControls = secao.elementosSecao.map(elementoSecao => ElementoSecao.getInitializedControl(elementoSecao));
    const elementosSecaoFormArray = new UntypedFormArray(elementosSecaoControls);

    formGroup.addControl('elementosSecao', elementosSecaoFormArray);
    formGroup.get('elementosSecao').setValidators([Validators.required]);
    formGroup.get('elementosSecao').updateValueAndValidity();

    return formGroup;

  }

}
