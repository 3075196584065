<div class="notification--container">
  <div class="notify" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <!-- ícone -->
    <div class="notify-icon">
      <!-- info -->
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          *ngIf="toastPackage.toastType === 'toast-success'"
          d="M32 5.088L13.744 23.36L6.96 16.576L9.216 14.32L13.744 18.848L29.744 2.848L32 5.088ZM28.464 13.152C28.672 14.064 28.8 15.024 28.8 16C28.8 23.072 23.072 28.8 16 28.8C8.928 28.8 3.2 23.072 3.2 16C3.2 8.928 8.928 3.2 16 3.2C18.528 3.2 20.864 3.936 22.848 5.2L25.152 2.896C22.56 1.072 19.408 0 16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 14.096 31.648 12.272 31.04 10.576L28.464 13.152Z" />
        <path
          *ngIf="toastPackage.toastType === 'toast-error'"
          d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM3.2 16C3.2 8.928 8.928 3.2 16 3.2C18.96 3.2 21.68 4.208 23.84 5.904L5.904 23.84C4.208 21.68 3.2 18.96 3.2 16ZM16 28.8C13.04 28.8 10.32 27.792 8.16 26.096L26.096 8.16C27.792 10.32 28.8 13.04 28.8 16C28.8 23.072 23.072 28.8 16 28.8Z" />
        <path
          *ngIf="toastPackage.toastType === 'toast-info'"
          d="M17.7778 5.44V0H14.2222V5.44C6.22222 6.32889 0 13.1022 0 21.3156C0 23.2889 1.6 24.8889 3.57333 24.8889H8.88889C8.88889 28.8178 12.0711 32 16 32C19.9289 32 23.1111 28.8178 23.1111 24.8889H28.4267C30.4 24.8889 32 23.2889 32 21.3156C32 13.1022 25.7778 6.32889 17.7778 5.44ZM16 28.4444C14.0444 28.4444 12.4444 26.8444 12.4444 24.8889H16H19.5556C19.5556 26.8444 17.9556 28.4444 16 28.4444ZM16 21.3333H3.55556C3.55556 14.4711 9.13778 8.88889 16 8.88889C22.8622 8.88889 28.4444 14.4711 28.4444 21.3333H16Z" />
        <path
          *ngIf="toastPackage.toastType === 'toast-warning'"
          d="M14.4 8H17.6V11.2H14.4V8ZM14.4 14.4H17.6V24H14.4V14.4ZM16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8Z" />
      </svg>
    </div>
    <span class="notifyLine"></span>
    <!-- corpo -->
    <div class="notify-body">
      <h5 *ngIf="title" class="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </h5>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
        [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" class="message" role="alert" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
