import { Component, Input, OnInit } from "@angular/core";

type ModeButton = "DISTRIBUTION" | "REGULAR";

@Component({
  selector: "app-icon-button",
  templateUrl: "./icon-button.component.html",
  styleUrls: ["./icon-button.component.scss"],
})
export class IconButtonComponent implements OnInit {
  // Título do botão
  @Input() title: string = "";
  // Desabilitar
  @Input()
  disabled: boolean = false;

  @Input()
  distributionMode: ModeButton = "REGULAR";

  ngOnInit() {
    setTimeout(() => { }, 0);
  }
}
