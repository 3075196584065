<div
  [ngClass]="{
    'container-overflow': true,
    'show-map': showMap
  }"
>
  <div
    [ngClass]="{
      'content-map': true
    }"
  >
    <div class="button-close" (click)="closeModal()">
      <span></span><span></span>
    </div>
    <div class="internal">
      <div class="title">
        <i class="fa-regular fa-location-dot"></i>
        <h3>Mapa da coleta</h3>
      </div>

      <div class="info">
        <h4 class="info-title">Coleta <span>{{ collectionInfo.id }}</span> </h4>
        <h4 class="info-title">Início: <span>{{ collectionInfo.hora_inicio }}</span> </h4>
        <h4 class="info-title">Fim: <span>{{ collectionInfo.hora_fim }}</span> </h4>
        <h4 class="info-title">Data <span>{{ collectionInfo.data }}</span> </h4>
      </div>

      <div class="map">
        <app-leaflet-map-beta [mapHeight]="'283px'"></app-leaflet-map-beta>
      </div>
    </div>

    <div class="footer-map">
      <button type="button" (click)="closeModal()">Sair</button>
    </div>
  </div>
</div>
