import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { TipoConteudo } from '../../conteudo/tipoConteudo';
import { AbstractPerguntaComponent } from '../abstractPerguntaComponent';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-foto-questionario',
  templateUrl: './foto.component.html',
  styleUrls: ['./foto.component.scss']
})
export class FotoComponent extends AbstractPerguntaComponent {

  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_FOTO;
  tipoPergunta: TipoPergunta = TipoPergunta.FOTO;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
