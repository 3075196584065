<section class="validator-container">
  <h4>A nova senha deve conter:</h4>
  <div class="validations-wrapper">
    <aside>
      <span class="validation">
        <i class="fa-solid fa-circle-check green-check" *ngIf="lengthValidate"></i>
        <i class="fa-sharp fa-solid fa-clock" *ngIf="!lengthValidate"></i>
        <p>Ao menos 8 caracteres</p>
      </span>
      <span class="validation">
        <i class="fa-solid fa-circle-check green-check" *ngIf="hasTwoNumbers"></i>
        <i class="fa-sharp fa-solid fa-clock" *ngIf="!hasTwoNumbers"></i>
        <p>Ao menos 2 números</p>
      </span>
      <span class="validation">
        <i class="fa-solid fa-circle-check green-check" *ngIf="hasOneUpercaseLetter"></i>
        <i class="fa-sharp fa-solid fa-clock" *ngIf="!hasOneUpercaseLetter"></i>
        <p>Ao menos 1 letra maiúscula</p>
      </span>
    </aside>

    <aside>
      <span class="validation">
        <i class="fa-solid fa-circle-check green-check" *ngIf="hasOneLowercaseLetter"></i>
        <i class="fa-sharp fa-solid fa-clock" *ngIf="!hasOneLowercaseLetter"></i>
        <p>Ao menos 1 letra minúscula</p>
      </span>
      <span class="validation">
        <i class="fa-solid fa-circle-check green-check" *ngIf="hasOneSpecialChar"></i>
        <i class="fa-sharp fa-solid fa-clock" *ngIf="!hasOneSpecialChar"></i>
        <p>Ao menos 1 caractere especial</p>
      </span>
    </aside>
  </div>
</section>
