import {
  IAlternative,
  IAlternativeTitle,
  IQuestion,
} from "../../interfaces/audit-question-collections";

/**
 * Converte o objeto recebido do backend para uma lista de objetos e ordena de acordo com atributo ordem
 * @param questionList Objeto de questões cru como recebido do backend
 * @returns retorna uma lista de objetos de acordo com interface IQuestion
 */
function orderQuestionList(questionList: IQuestion): IQuestion[] {
  const parsedArrayQuestions: IQuestion[] = Object.values(questionList);
  return [...parsedArrayQuestions].sort(
    ({ ordem: curr }, { ordem: next }) => curr - next
  );
}

/**
 * Ordena a lista de alternativas ou titulo_alternativa
 * @param alternativesList Lista de alternatiovas ou de titulo-alternativa caso seja uma pergunta do tipo grade
 * @returns Retorna um array do tipo IAlternative ou IAlternativeTitle
 */
function orderAlternatives(
  alternativesList: Array<IAlternative | IAlternativeTitle>
): Array<IAlternative | IAlternativeTitle> {
  if (!Array.isArray(alternativesList)) return null;
  return [...alternativesList].sort(
    ({ ordem: curr }, { ordem: next }) => curr - next
  );
}

/**
 * Organiza e chama os metodos de ordenação e conversão de questão e alternativa
 * @param questionArray Questões vindas do back para ordenação de perguntas e alternativas
 * @returns Retorna uma lista de questões de acordo com a interface IQuestion
 */
export function sortQuestionArray(question: IQuestion): IQuestion[] {
  const questions = orderQuestionList(question);

  return questions.map((question: IQuestion) => {
    return {
      ...question,
      alternativas: orderAlternatives(question.alternativas),
      ...(!!question.definicoes
        ? {
            definicoes: {
              ...question.definicoes,
              titulo_alternativas: orderAlternatives(
                question.definicoes.titulo_alternativas
              ),
            },
          }
        : {}),
    };
  }) as IQuestion[];
}
