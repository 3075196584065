import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Entrevista } from '../../model/entrevista';
import { LeafletMapBetaComponent } from 'app/componentes/leaflet-map-beta/leaflet-map-beta.component';
import { LocalidadeService } from 'app/modulos/localidade-beta/servico/localidade.service';


interface IMapCords {
  lat: number,
  lon: number
}

@Component({
  selector: 'app-localidades-modal',
  templateUrl: './localidades-modal.component.html',
  styleUrls: ['./localidades-modal.component.scss'],
})
export class LocalidadesModalComponent implements OnInit, AfterViewInit {
  @ViewChild(LeafletMapBetaComponent) leafletRef!: LeafletMapBetaComponent

  entrevistasSelecionadas: Entrevista[] = [];
  idPesquisa: number;
  mapCoords: IMapCords[] = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LocalidadesModalComponent>,
    private router: Router,
    private localidadeService: LocalidadeService) { }

  ngOnInit() {
    /**
     * Recuperando as entrevistas passadas por parâmetro no momento
     * de inicialização do modal.
     */
    this.entrevistasSelecionadas = this.data.entrevistasSelecionadas;
    this.idPesquisa = this.data.idPesquisa;
    /**
     * Limpando componente de LocalidadesMapaComponent
     * caso o modal seja fechado de maneira inesperada.
     * Este subscribe é implementado para caso o usuário
     * não feche o modal pelo botão "Sair" mas clicando
     * fora do modal ou algo do tipo.
     */
    this.dialogRef.beforeClosed().subscribe(() => {

    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dialogRef.close();
      }
    });

  }

  ngAfterViewInit() {
    this.handleBuildMap();
  }

  /**
   * Recupera informações adicionais sobre a localização informada
   * @param coords coordenadas
   */
  private async fetchLocalityDetailsByCoords(
    coords: { lat: number; lon: number }[]
  ) {
    const result = [];

    for (let i = 0; i < coords.length; i++) {
      const nominatimResp =
        await this.localidadeService.listarLocalidadesComDetalhesByCoords(
          coords[i].lat,
          coords[i].lon
        );

      result.push({ ...nominatimResp});
    }

    return result;
  }

  /**
   * Recupera as coordenadas da coleta
   */
  buildMapCoords(): IMapCords[] {
    return this.entrevistasSelecionadas.map(location => {
      return {lat: location.coordenadas.latitude, lon: location.coordenadas.longitude}
    })
  }

  /**
   * Cria os pontos de coletas no mapa de acordo com lat-long
   */
  buildPointsConfig(coordsConfig) {
    const polygonalResults = [];

    coordsConfig.forEach((osmObject) => {
      if (osmObject.geojson && osmObject.geojson.type === "Polygon") {
        polygonalResults.push(osmObject.geojson);
      }
    });

    return [
      ...polygonalResults,
      ...coordsConfig.map((v) => ({
        type: "Point",
        coordinates: [v.lon, v.lat],
      })),
    ]
  }

  /**
   * Realiza a construção e configuração do mapa
   */
  async handleBuildMap() {
    this.mapCoords = this.buildMapCoords();
    const coordsConfig = await this.fetchLocalityDetailsByCoords(this.mapCoords);
    const pointsConfig = this.buildPointsConfig(coordsConfig);

    await this.leafletRef.setLocation(this.mapCoords[0].lat, this.mapCoords[0].lon)
    await this.leafletRef.fitBoundsByLinearCoords(this.mapCoords);
    this.leafletRef.drawMarkers(pointsConfig)
  }

  /**
   * Callback executado quando o usuário clica no botão "Sair" do modal.
   * Fecha a referencia para o modal do angular material e limpa
   * o componente LocalidadesMapaComponent.
   */
  fecharModal() {
    this.dialogRef.close();
  }

}
