import { Component, Input, OnInit } from '@angular/core';
import { Pergunta } from '../../../../../../pesquisa-old/cadastro/model/pergunta';

@Component({
  selector: 'app-pergunta',
  templateUrl: './pergunta.component.html',
  styleUrls: ['./pergunta.component.scss']
})
export class PerguntaComponent implements OnInit {
  @Input() elementoSecao: any;
  @Input() selected: boolean = false;

  ngOnInit() { }

  /**
   * verifica se a pergunta passada possui marcação
   * @param pergunta
   */
  perguntaPossuiMarcacao(pergunta: Pergunta) {

    return pergunta.possuiCota
      || pergunta.possuiDiferenteDe
      || pergunta.possuiPulo
      || pergunta.possuiPuloPergunta;
  }

}
