import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-step',
  templateUrl: './header-step.component.html',
  styleUrls: ['./header-step.component.scss']
})
export class HeaderStepComponent {
  @Input() pulouMarcacaoDePulo: boolean;
  @Input() activeStepHeader: number;
  @Output() onNextStep = new EventEmitter<void>();
  @Output() onPreviousStep = new EventEmitter<void>();

  // constant TODO: não deveria estar aqui
  menuSteps = [
    {
      id: 1,
      title: 'Configuração',
      description: 'Informações gerais e dados técnicos',
      active: true,
      completed: false,
      disabled: false,
    },
    {
      id: 2,
      title: 'Questionário',
      description: 'Seções e perguntas da  sua pesquisa',
      active: false,
      completed: false,
      disabled: true,
    },
    {
      id: 3,
      title: 'Lógica',
      description: 'Configure a lógica de suas perguntas',
      active: false,
      completed: false,
      disabled: true,
    },
    {
      id: 4,
      title: 'Localidades',
      description: 'Defina a localidade de execução da pesquisa',
      active: false,
      completed: false,
      disabled: true,
    },
    {
      id: 5,
      title: 'Vínculos',
      description: 'Informe quem realizará as coletas',
      active: false,
      completed: false,
      disabled: true,
    },
    {
      id: 6,
      title: 'Conclusão',
      description: 'Status e compartilhamento',
      active: false,
      completed: false,
      disabled: true,
    },
  ];

  handleStepClick(index: number) {
    if (index > this.activeStepHeader) {
      this.onNextStep.emit()
    }
    if (index < this.activeStepHeader) {
      this.onPreviousStep.emit()
    }
  }

}
