import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import {
  TableEvents,
  TableGlobalConfig,
} from "app/util/componente/genericTable/interfaces/table";
import {
  iconCellFactory,
  imageCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tHeadFactory } from "app/util/componente/genericTable/utils/tHeadFactory";
import { IFilter, IFilterTotalOperators, IOperator, IPagination, OperatorSortFilter } from "../interfaces/listagem";
import { ProdutividadeBetaService } from "../services/produtividade-beta.service";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "../constants/listagem-operadores";
import { InterviewFormatter } from "../components/interviewer-formattter/interview-formatter.service";
import { IOverviewData } from "app/modulos/auditoria-beta/interfaces";
import { getAuditResum, getCollectionsPercentage } from "../utils/headerUtils";

@Component({
  selector: "app-listagem-operadores",
  templateUrl: "./listagem-operadores.component.html",
  styleUrls: ["./listagem-operadores.component.scss"],
})
export class LIstagemOperadoresComponent extends InterviewFormatter implements OnInit {
  dataBreadcrumb: IItemBreadcrumb[] = []

  header = null;

  tHeadOperator: TableHead[] = [
    tHeadFactory("Entrevistador").build(),
    tHeadFactory("Coletas cumpridas").build(),
    tHeadFactory("Dias trabalhados").build(),
    tHeadFactory("Coletas aprovadas").build(),
    tHeadFactory("Coletas reprovadas").build(),
    tHeadFactory("Avaliação geral")
      .class((cell) => this.assessmentStyle(cell.textValue))
      .cellDataToolTip({
        label: "Nota da avaliação",
        position: "right",
      })
      .build(),
    tHeadFactory("Visualizar")
      .alignType("center")
      .cellAlign("center")
      .iconDefaultValue({
        icon: ["fa-regular fa-eye"],
        behavior: "button",
      })
      .cellStyles({ fontSize: "16px" })
      .build(),
  ];

  tableData: TableRow[] = [];

  tableConfig: TableGlobalConfig = {
    colWidths: ["0.33", "0.11", "0.11", "0.11", "0.11", "0.11", "0.11"],
    headerHeight: "72px",
    rowHeight: "72px",
    rowStyles: {
      fontSize: "14px",
      border: "1px solid var(--tertiary)",
    },
    rowBackgroundColors: {
      even: "var(--white)",
      odd: "var(--white)",
    },
  };

  tableFilter: FilterConfigs[] = [
    {
      type: FilterTypes.RADIO,
      icon: FilterIcon.SORTING,
      placeholder: "Ordenar",
      options: [
        {
          id: 1,
          label: "Operador A a Z",
          key: DirectionOrderTypes.ASC,
          filter: OperatorSortFilter.NOME,
        },
        {
          id: 2,
          label: "Operador Z a A",
          key: DirectionOrderTypes.DESC,
          filter: OperatorSortFilter.NOME,
        },
        {
          id: 3,
          label: "Avaliação geral por ordem crescente",
          key: DirectionOrderTypes.ASC,
          filter: OperatorSortFilter.AVALIACAO_GERAL,
        },
        {
          id: 4,
          label: "Avaliação geral por ordem decrescente",
          key: DirectionOrderTypes.DESC,
          filter: OperatorSortFilter.AVALIACAO_GERAL,
        },
      ],
    },
  ];

  searchConfig: SearchInputConfig = {
    placeholder: "Busque por entrevistador",
    delay: 2000,
  };

  filters: IFilter = {
    sorting: { direction: null, sort: null },
    keywords: [""],
  };

  surveyId: number = null;
  isLoading: boolean = false;
  pagination: IPagination = {
    current: 1,
    previus: 1,
  };
  totalOperatos: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorHandlerService,
    private produtividadeBetaService: ProdutividadeBetaService,
    private errorHandler: ErrorHandlerService
  ) {super()}

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params["surveyId"];
    this.getHeaderData();
    this.updateBreadcrumb();
    this.getAllOperators();
    this.getTotalOperatos();
  }

  updateBreadcrumb() {
    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Produtividade",
        itemLink: "/produtividade-beta",
        active: false,
      },
      {
        itemName: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
        itemLink:`/produtividade-beta/visao-geral/${this.surveyId}/entrevistadores`,
        active: true,
      },
    ];
  }

  // recupera os dados do header
  getHeaderData(): void {
    this.produtividadeBetaService.getHeaderData(Number(this.surveyId)).subscribe({
      next: (overview: IOverviewData) => {
        const result = getAuditResum(overview.resumo_coletas.resumo_auditoria);
        const collectionsPercentage: number = getCollectionsPercentage(overview.resumo_coletas.efetuadas, overview.resumo_coletas.contratadas)

        this.header = {
          titulo: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
          periodo: {
            titulo: "Período de pesquisa",
            inicio: overview.data_inicial,
            fim: overview.data_final,
          },
          resumo: {
            titulo: "Resumo da auditoria",
            aprovadas: result.approved,
            reprovadas: result.refused,
          },
          progresso: {
            total: overview.resumo_coletas.contratadas,
            realizadas: overview.resumo_coletas.efetuadas,
            porcentagem: collectionsPercentage,
          }
        }
      }, error: (err) => {
        this.errorHandler.handleError(err, errorMessages.headerDataError.title);
      }
    })
  }

  /**
   * Recupera os operadores
   */
  getAllOperators() {
    this.isLoading = true;
    const { keywords, sorting } = this.filters;

    this.produtividadeBetaService
      .getOperatorsList(
        this.surveyId,
        this.pagination.current - 1,
        sorting.sort,
        sorting.direction,
        keywords
      )
      .subscribe({
        next: (operatorList: IOperator[]) => {
          const allOperators =
            operatorList && this.buildOperatorTableRow(operatorList);
          this.tableData = allOperators || [];
          this.isLoading = false;
        },
        error: (err) => {
          this.errorService.handleError(
            err,
            errorMessages.interviewersListError.title
          );
          this.isLoading = false;
        },
      });
  }

  /**
   * Constroi cada linha da tabela de operadores
   */
  buildOperatorTableRow(operators: IOperator[]): TableRow[] {
    return operators.map((operator: IOperator) =>
      tRowFactory(
        imageCellFactory(operator.foto.arquivo, operator.nome).build(),
        textCellFactory(
          `${operator.coletas.cumpridas} de ${operator.coletas.esperadas}`
        ).build(),
        textCellFactory(`${operator.dias_trabalhados}`).build(),
        textCellFactory(`${operator.coletas.aprovadas}`).build(),
        textCellFactory(`${operator.coletas.reprovadas}`).build(),
        textCellFactory(
          this.perfomanceFormat(operator.avaliacao_geral)
        ).build(),
        iconCellFactory().build()
      )
        .id(operator.id)
        .metadata({})
        .build()
    );
  }

  /**
   * Obtem o total de operadores baseado no filtro informado
   * @param keywords: palavras chave
   */
  getTotalOperatos(): void {
    const filters: IFilterTotalOperators = {
      palavrasChave: [...this.filters.keywords],
    };

    this.produtividadeBetaService
      .getTotalOperatos(this.surveyId, filters)
      .subscribe({
        next: (data) => {
          if (typeof data === "number") {
            this.totalOperatos = data;
          }
        },
        error(err) {
          this.errorService.handleError(
            err,
            errorMessages.interviewersTotalError.title
          );
        },
      });
  }

  // lida com todos eventos da tabela
  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["CELL_ICON_CLICK"]: this.handleClickView.bind(this),
      ["RADIO_FILTER_CHANGED"]: this.handleChangeSortingFilter.bind(this),
      ["PAGINATION_CHANGED"]: this.changePage.bind(this),
      ["SEARCH_FILTER_CHANGED"]: this.handleTextChange.bind(this),
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  // manipula filtro de ordenação
  public handleChangeSortingFilter({ filterValue }: TableEvents) {
    const [item] = filterValue;
    this.filters.sorting.direction = filterValue.length ? item.key : null;
    this.filters.sorting.sort = filterValue.length ? item.filter : null;
    this.getAllOperators();
  }

  // controla a paginação
  changePage({ requestedPage, previousPage }) {
    this.pagination.current = requestedPage;
    this.pagination.previus = previousPage;
    this.getAllOperators();
  }

  // metodo que recebe o texto emitido pelo componente de input de texto
  public handleTextChange({ filterValue }) {
    this.filters.keywords = [filterValue];
    this.getAllOperators();
    this.getTotalOperatos();
  }

  // Redireciona o usuário para o operador selecionado
  handleClickView($event) {
    this.router.navigate([
      `/produtividade-beta/visao-geral/${this.surveyId}/entrevistadores/entrevistador`,
      $event.rowId,
    ]);
  }
}
