import { Component, Input } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import alternativaAbertaTextoObrigatorio from "app/util/validador/alternativa/alternativaAbertaTextoObrigatorio";
import validadorArrayLengthMaiorMenor from "app/util/validador/validadorArrayLengthMaiorMenor";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-selecao-multipla",
  templateUrl: "./selecao-multipla.component.html",
  styleUrls: ["./selecao-multipla.component.scss"],
})
export class RespostaSelecaoMultiplaComponent extends RespostaComponent {
  @Input() protected respostaPergunta: RespostaPergunta;
  @Input() protected pergunta: PerguntaEsquema;
  @Input() alternativasSelecionadasFormGroup: UntypedFormGroup;
  @Input() alternativasSelecionadas: any;

  protected maiorDoQueDeveria: any;
  protected menorDoQueDeveria: any;

  onAlternativaChangeMaterial(event, alternativaHash: string): void {
    const adaptedEvent = { target: { checked: event.target.checked } };

    this.onAlternativaChange(adaptedEvent, alternativaHash);
  }

  configurarValidadoresRespostaAberta(
    alternativasSelecionadas: UntypedFormArray
  ) {
    alternativasSelecionadas.controls.forEach((alternativaSelecionada) => {
      alternativaSelecionada.setValidators([alternativaAbertaTextoObrigatorio]);
    });
  }

  /**
   * callback disparado sempre que uma alternativa é marcada ou desmarcada.
   * Este callback é implementado para manter um conjunto de hashes
   * em alternativasSelecionadasFormGroup, representando as alternativas marcadas.
   * Isto foi feito para facilitar a validação de quantidade minima e máxima de
   * alternativas selecionadas, de maneira que esta possa ser feito apenas verificando
   * o length da array de hashes.
   *
   * @param event
   */
  onAlternativaChange(
    event: { target: { checked: boolean } },
    alternativaHash: string
  ): void {
    const marcada: boolean = event.target.checked;

    // array de alternativas marcadas
    const alternativasMarcadas: string[] =
      this.alternativasSelecionadasFormGroup.value.selected;
    // FormControl das alternativas marcadas
    const alternativasMarcadasControl =
      this.alternativasSelecionadasFormGroup.controls.selected;

    // se for uma marcação de alternativa
    if (marcada) {
      // só adiciona o hash da alternativa ao conjunto de arrays marcadas e atualiza o FormControl
      alternativasMarcadasControl.patchValue([
        ...alternativasMarcadas,
        alternativaHash,
      ]);
      return;
    }

    // caso não seja uma marcação então é uma desmarcação...
    // recupera o índex da alternativa desmarcada na array de alternativas marcadas
    const alternativaIndex = alternativasMarcadas.indexOf(alternativaHash);

    // caso o elemento realmente exista no conjunto de alternativas marcadas
    if (alternativaIndex > -1) {
      // o item é removido das alternativas marcadas
      alternativasMarcadas.splice(alternativaIndex, 1);
      // o FormControl é atualizado com uma array sem o item desmarcado.
      alternativasMarcadasControl.patchValue(alternativasMarcadas);
    }
  }

  /**
   * Recupera uma lista de hashes representando as alternativas selecionadas.
   */
  getAlternativasMarcadasHashes(): string[] {
    return this.alternativasSelecionadas.value
      .filter((alternativa) => alternativa.selecionada)
      .map((alternativa) => alternativa.hash);
  }

  ngOnInit() {
    super.ngOnInit();

    this.inicializarValidadores();

    const alternativasMarcadasHashes: string[] =
      this.getAlternativasMarcadasHashes();
    // console.log('alternativas marcadas hashes: ', alternativasMarcadasHashes);

    // tslint:disable-next-line: max-line-length
    this.alternativasSelecionadasFormGroup.addControl(
      "selected",
      new UntypedFormControl(alternativasMarcadasHashes, [
        this.maiorDoQueDeveria,
        this.menorDoQueDeveria,
      ])
    );
    this.configurarValidadoresRespostaAberta(this.alternativasSelecionadas);
  }

  private inicializarValidadores() {
    // tslint:disable-next-line: max-line-length
    this.maiorDoQueDeveria = validadorArrayLengthMaiorMenor(
      this.pergunta.qtdMaximaSelecionada,
      true
    );
    // tslint:disable-next-line: max-line-length
    this.menorDoQueDeveria = validadorArrayLengthMaiorMenor(
      this.pergunta.qtdMinimaSelecionada,
      false
    );
  }

  hasErrors() {
    return this.alternativasSelecionadasFormGroup.status === "INVALID";
  }

  /**
   * @override
   */
  onProximaPergunta() {
    if (this.hasErrors()) {
      return;
    }

    // tslint:disable-next-line: max-line-length
    const alternativasSelecionadas: AlternativaSelecionada[] =
      this.alternativasSelecionadas.controls
        .filter((alternativa) => alternativa.value.selecionada)
        .map((alternativaSelecionda: UntypedFormGroup) => {
          return <AlternativaSelecionada>{
            id: alternativaSelecionda.value.id,
            idAlternativa: alternativaSelecionda.value.esquema.id,
            ordem: alternativaSelecionda.value.esquema.ordem,
            respostaAberta: alternativaSelecionda.value.respostaAberta,
            tipo: TipoRespostaPergunta.SELECAO_TEXTO,
          };
        });

    // tslint:disable-next-line: max-line-length
    const respostaPergunta: RespostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativasSelecionadas,
      this.respostaPergunta.num
    );

    this.notifyRespostaPergunta(respostaPergunta);
  }
}
