import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MascaraBase } from './mascaraBase';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-cep]',
})
export class MascaraCepDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 9);
  }

  aplicarMascara(valor: string): string {

    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

    // Coloca um hifen entre o quinto digito e o sexto
    const valorPrimeiraFormatacao = numberString.replace(/(\d{5})(\d)/, '$1-$2');

    return valorPrimeiraFormatacao;
  }

}
