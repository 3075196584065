<div class="table-container-header">
  <ng-container *ngIf="!selectedItems.length">
    <app-input-search-auditoria
      [placeholder]="'Buscar...'"
      (handleChange)="handleTextChange($event)"
    ></app-input-search-auditoria>
    <app-filter-select
      [type]="filterConf.type"
      [icon]="filterConf.icon"
      placeholder="Situação"
      [options]="filterOptions"
      (changeFilter)="handleChangeStatusFilter($event)"
    ></app-filter-select>
    <app-filter-select
      [type]="sortingFilter.type"
      [icon]="sortingFilter.icon"
      [placeholder]="'Ordenar'"
      [options]="sortingFilterOptions"
      (changeFilter)="handleChangeSorting($event)"
    ></app-filter-select>
  </ng-container>

  <ng-container *ngIf="selectedItems.length">
    <button
      [disabled]="disableButtonArchive()"
      (click)="
        handleActionButton($event)
      "
      class="archive-collection"
    >
      <i class="fa-light fa-box-archive"></i>
      <span>{{ getArchiveBtnTitle() }}</span>
    </button>
  </ng-container>
</div>

<div class="table-container">
  <div class="table-header">
    <div class="checkbox-wrapper">
      <app-checkbox
        [isChecked]="selectAll"
        name="select-all"
        (click)="handleOnSelectAllItems($event)"
      ></app-checkbox>
    </div>
    <div class="header-wrapper">
      <div class="header-item">
        <span>Título da avaliação</span>
      </div>
      <div class="header-item">
        <span>Cliente</span>
      </div>
      <div class="header-item">
        <span>Período</span>
      </div>
      <div class="header-item">Situação</div>
      <div class="header-item">Ações</div>
    </div>
  </div>

  <div class="table-body">
    <div
      class="table-item"
      [ngClass]="isChecked(item.id) && 'checked'"
      (click)="redirectToSurveyDetails(item.id)"
      *ngFor="let item of models"
    >
      <div class="checkbox-wrapper">
        <app-checkbox
          [isChecked]="isChecked(item.id)"
          name="select-item"
          (click)="handleOnSelectItem($event, item.id, item.situacao)"
        ></app-checkbox>
      </div>
      <div class="item-wrapper">
        <div class="item-content">
          <h4 appTooltipIfEllipsis="{{ item.titulo }}">{{ item.titulo }}</h4>
        </div>
        <div class="item-content">
          <h4 appTooltipIfEllipsis="{{ item.cliente }}">{{ item.cliente }}</h4>
        </div>
        <div class="item-content">
          {{ item.dataInicial }} a {{ item.dataFinal }}
        </div>
        <div class="item-content">
          <div class="item-status-wrapper">
            <span
              class="status"
              [style.backgroundColor]="getSurveyStatusColor(item.situacao)"
            ></span>
            {{ item.situacao }}
          </div>
        </div>
        <div class="item-content">
          <button
            class="view-container"
            (click)="redirectToSurveyDetails(item.id)"
          >
            <i class="fa-regular fa-eye"></i>
          </button>

          <button class="archive-container" (click)="archiveSurvey($event, [item.id])">
            <i class="fa-regular fa-box-archive"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
