import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Input() totalOfEntries: number = 0;
  @Input() placeholder: string = "";
  @Input() entriesPerPage: number = 0;
  @Output() changePage = new EventEmitter<number>();

  pagination = [];
  delta = 2;

  constructor() {}

  // private getStartPage () {
  //   if(this.totalPages < 5 ) {
  //     return this.currentPage
  //   } else if (this.changePage)
  // }

  ngOnInit(): void {}

  /**
   * Gera o array das páginas que é exibido no template html, um array heterogênao que pode conter "..." ou numeros.
   * O <<get>> é uma função de acesso em TypeScript que permite acessar um valor de uma propriedade de um objeto como
   * se fosse uma propriedade direta, em vez de uma função. Nesse contexto específico, ele indica que visiblePages é
   * uma propriedade calculada que retorna um array de números.
   */
  get visiblePages(): Array<number | "..."> {
    const pages = [];

    // Controla a partir de qual página o array começa
    const left =
      this.totalPages < 5
        ? 1
        : /**
         * verifica se a página atual está entre a quinta página e a quarta página antes da última página. Se essa condição
         * for verdadeira, significa que estamos em uma faixa intermediária de páginas, onde apenas duas páginas à direita da
         * página atual serão exibidas
         */
        this.currentPage > 4 && this.currentPage < this.totalPages - 4
        ? this.currentPage
        : // verifica se a página atual está dentro das últimas quatro páginas ou é a última página.
        this.currentPage >= this.totalPages - 4
        ? this.totalPages - 4
        : // Se nenhuma das condições anteriores for verdadeira, significa que estamos nas primeiras páginas.
          Math.max(1, this.currentPage - this.delta);

    // Verifica quais e qunatas páginas são exibidas a frente
    const right =
      // Caso seja verdadeiro exbirá todas as páginas até a última
      this.currentPage >= this.totalPages - 4
        ? this.totalPages
        : // Caso verdadeira vai exibir apenas 2 páginas
        this.currentPage > 4
        ? Math.min(this.totalPages, this.currentPage + this.delta)
        : // Indica que estamos no inicio da paginação e deve ser exibido 4 primeiras páginas
          5;

    // Adiciona o número da primeira página, se necessário
    if (left > 1) {
      pages.push(1);
    }

    // Adiciona '...' se há páginas ocultas à esquerda
    if (left > 2) {
      pages.push("...");
    }

    // Adiciona as páginas visíveis no intervalo [left, right]
    for (let i = left; i <= right; i++) {
      pages.push(i);
    }

    // Adiciona '...' se há páginas ocultas à direita
    if (right < this.totalPages - 1) {
      pages.push("...");
    }

    // Adiciona o número da última página, se necessário
    if (right < this.totalPages) {
      pages.push(this.totalPages);
    }

    return pages;
  }

  /**
   * Navega entre as páginas
   * @param page pode assumir 2 tipos de valores: number ou "..."
   */
  goToPage(page): void {
    // Valida se é um number, caso veradeiro ele muda a proxima página, se não ele pernece na atual
    const newPage =
      isNaN(page) || page > this.totalPages || page < 1
        ? this.currentPage
        : page;
    this.changePage.emit(newPage);
  }

  get visitedEntries() {
    const T = this.currentPage * this.entriesPerPage;

    return T < this.totalOfEntries ? T : this.totalOfEntries;
  }
}
