export enum TipoPergunta {
    UNICA = 'Única',
    MULTIPLA = 'Múltipla',
    MULTIPLA_ORDEM_CITACAO = 'Múltipla por Ordem de Citação',
    ESCALA_NUMERICA = 'Escala Numérica',
    FOTO = 'Foto',
    VIDEO = 'Vídeo',
    GRADE_UNICA = 'Grade de Seleção Única',
    GRADE_MULTIPLA = 'Grade de Seleção Múltipla',
    DATA = 'Data',
    HORARIO = 'Horário',
}
