import { Injectable } from "@angular/core";
import { matchPayload } from "../../utils/parsers/consolidacaoParser";
import {
  ICitationResponse,
  IConsolidationPayload,
  IConsolidationResponse,
  IGroup,
} from "../../interfaces/audit-open-answer";
import { AuditoriaBetaService } from "../auditoria-beta.service";
import { errorMessages } from "../../constant/errors";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { NotificatorService } from "app/notificador/notificator.service";
import {catchError, firstValueFrom, map, of, tap} from 'rxjs';
import { MatchOperationsService } from "./match-operations.service";
import { successNotify } from "../../constant/success";

@Injectable({
  providedIn: "root",
})
export class MatchPersistenceService {
  constructor(
    private auditoriaService: AuditoriaBetaService,
    private errorHandlerService: ErrorHandlerService,
    private notificatorService: NotificatorService,
    private matchOperationsService: MatchOperationsService
  ) {}

  /**
   * Pega as consolidações criadas por autoMatch e persiste no back-end
   * @param consolidations
   */
  async getAndPersistConsolidation(
    consolidations: IGroup[],
    groupsArray: IGroup[],
    surveyId: number,
    selectedQuestionId: number
  ) {
    for (let i = 0; i < consolidations.length; i++) {
      const consolidation = consolidations[i];
      const parsedData = matchPayload({
        backId: consolidation.backId,
        respostas: consolidation.citation,
        consolidacoes: [], // sempre vazio quando for automatch
        title: consolidation.title,
        tipo: consolidation.typeGroup,
      });
      const data: Partial<IConsolidationResponse> =
        await this.createOrUpdateConsolidations(
          parsedData,
          surveyId,
          selectedQuestionId
        ).catch((reason) => {
          this.errorHandlerService.handleError(
            reason,
            errorMessages.autoMatchConsolidation.title,
            errorMessages.autoMatchConsolidation.message
          );
        });
      const isCurrentGroup = groupsArray.find((c) => c.id === consolidation.id);
      isCurrentGroup.backId = data?.id || null;
      isCurrentGroup.porcentagem = data?.valor_percentual || 0;
    }
  }

  /**
   * Pega a consolidação criada pelo usuário e persiste no back-end
   * @param consolidation
   */
  async getAndPersistUserConsolidation(
    consolidation: IGroup,
    groupsArray: IGroup[],
    surveyId: number,
    selectedQuestionId: number,
    isDeleteFlow: boolean = false,
    isTitleEditFlow: boolean = false
  ) {
    const parsedData = matchPayload({
      backId: consolidation.backId,
      respostas: consolidation.citation,
      consolidacoes: consolidation.groups,
      title: consolidation.title,
      tipo: consolidation.typeGroup,
    });
    const data: Partial<IConsolidationResponse> =
      await this.createOrUpdateConsolidations(
        parsedData,
        surveyId,
        selectedQuestionId
      ).catch((reason) => {
        this.errorHandlerService.handleError(
          reason,
          errorMessages.createManualConsolidation.title,
          errorMessages.createManualConsolidation.message
        );
      });

    const isCurrentGroup = groupsArray.find((c) => c.id === consolidation.id);
    isCurrentGroup.backId = data?.id || null;
    isCurrentGroup.porcentagem = data?.valor_percentual || 0;

    if (isDeleteFlow) {
      this.notificatorService.showInfo(
        successNotify.removeCitation.title,
        successNotify.removeCitation.message
      );
    } else if (!isTitleEditFlow && !isDeleteFlow) {
      this.notificatorService.showInfo(
        successNotify.persistUserConsolidation.title,
        successNotify.persistUserConsolidation.message
      );
    }
  }

  // Cria ou atualiza uma consolidação
  private createOrUpdateConsolidations(
    consolidationPayload: IConsolidationPayload,
    surveyId: number,
    selectedQuestionId: number
  ) {
    return new Promise((resolve, reject) => {
      this.auditoriaService
        .createOrEditConsolidations(
          surveyId,
          selectedQuestionId,
          consolidationPayload
        )
        .subscribe({
          next: ({ body: data }) => {
            resolve(data as IConsolidationResponse);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  // desfaz uma consolidação baseado no index do group
  undoGroupByIndex(surveyId: number, questionId: number, groupArray: IGroup[], groupIndex: number): Promise<any> {
    const groupId: number = groupArray[groupIndex].backId;
    return firstValueFrom(
      this.auditoriaService.undoConsolidation(surveyId, questionId, groupId).pipe(
        map(() => {
          this.notificatorService.showInfo(
            "Grupo desfeito",
            "Devido a falta de citações o grupo foi desfeito"
          )
        }),
        catchError((err) => {
          this.errorHandlerService.handleError(err, errorMessages.undoGroupError.title);
          return of(groupArray);
        })
      )
    );
  }

  // desfaz uma consolidação
  undoGroup(
    surveyId: number,
    questionId: number,
    groupArray: IGroup[],
    groupIndex: number
  ): Promise<any> {
    const groupId: number = groupArray[groupIndex].backId;
    return firstValueFrom(
      this.auditoriaService
        .undoConsolidation(surveyId, questionId, groupId)
        .pipe(
          map(() => {
            this.notificatorService.showInfo(
              successNotify.undoConsolidation.title,
              successNotify.undoConsolidation.message
            );
          }),
          catchError((err) => {
            this.errorHandlerService.handleError(
              err,
              errorMessages.undoConsolidation.title
            );
            return of(groupArray);
          })
        )
    );
  }

  /**
   * Realiza a edição do titulo de uma citação
   */
  handleEditAvulseTitle(
    surveyId,
    questionId,
    citationId,
    citationTitle,
    citationOrigin
  ) {
    const newCitation: ICitationResponse = {
      id: citationId,
      resposta: citationTitle,
      origem_resposta: citationOrigin,
    };
    this.auditoriaService
      .editCitationTitle(surveyId, questionId, newCitation)
      .subscribe({
        next: () => {
          this.matchOperationsService.updateAvulseTitle(
            citationId,
            citationTitle
          );
        },
        error: (err) =>
          this.errorHandlerService.handleError(
            err,
            errorMessages.changeCitationTitle.title
          ),
      });
  }

  // desfaz uma consolidação baseado no id do group
  undoGroupById(surveyId: number, questionId: number, groupId: number): Promise<any> {
    return firstValueFrom(
      this.auditoriaService.undoConsolidation(surveyId, questionId, groupId).pipe(
        tap(() => {
          this.notificatorService.showInfo(
            "Grupo desfeito",
            "Devido a falta de citações o grupo foi desfeito"
          );
        }), catchError((err) => {
          this.errorHandlerService.handleError(err, errorMessages.undoGroupError.title)
          return of(null);
        })
      )
    )
  }

}
