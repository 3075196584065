<div [formGroup]="formGroup" class="sg-input-group container-white">
  <div class="row">
    <div class="col-12 title-step-section">
      <h3>1 de 4: Características</h3>
      <span class="error-message" *ngIf="!modificacaoPermitida">
        Você não tem permissão para modificar esse menu
      </span>
    </div>
  </div>
  <div class="d-flex flex-wrap section">
    <div class="col pl-0 flex-grow-0">
      <div class="campo">
        <div *ngIf="!isEdicao">
          <app-cliente-cadastro-input
            title="cliente vinculado a pesquisa"
            [modificavel]="modificacaoPermitida && !passoBloqueado"
            (clienteSelecionadoEvent)="onClienteSelecionado($event)"
          >
          </app-cliente-cadastro-input>
        </div>

        <div *ngIf="isEdicao">
          <app-cliente-cadastro-input
            title="cliente vinculado a pesquisa"
            [modificavel]="modificacaoPermitida && !passoBloqueado"
            [clienteSelecionado]="controls.cliente.value"
            (clienteSelecionadoEvent)="onClienteSelecionado($event)"
          >
          </app-cliente-cadastro-input>
        </div>

        <span
          class="error-message"
          [hidden]="!(showErrors && controls.cliente.errors)"
        >
          Um cliente deve ser selecionado para a pesquisa
        </span>
      </div>
    </div>
    <div
      class="wrapper-btn"
      *ngIf="
        adicionarClientePermitido && modificacaoPermitida && !passoBloqueado
      "
    >
      <button class="sg-btn sg-alternate" [routerLink]="'/cliente/cadastro'">
        NOVO CLIENTE
      </button>
    </div>
  </div>
  <div class="row section">
    <div class="col-12 sub-title">
      <h3>Descrição</h3>
    </div>
    <div
      formGroupName="descricaoPesquisa"
      class="col-lg-3 col-md-4 col-sm-6 campo"
    >
      <label>Título da pesquisa*</label>
      <input
        mascara-primeiro-digito-sem-espaco
        placeholder="Ex.:pesquisa de satisfação"
        title="título da pesquisa"
        type="text"
        formControlName="titulo"
        (blur)="onAlterarTitulos()"
        maxlength="255"
      />
      <span
        class="error-message"
        [hidden]="
          !(showErrors && controls.descricaoPesquisa.get('titulo').errors)
        "
      >
        Deve ser informado um título para a pesquisa de até 255 caracteres
      </span>
    </div>
    <div
      formGroupName="descricaoPesquisa"
      class="col-lg-3 col-md-4 col-sm-6 campo"
    >
      <label>Título curto da pesquisa*</label>
      <input
        mascara-primeiro-digito-sem-espaco
        placeholder="Ex.:pesquisa de satisfação do cliente"
        title="título curto da pesquisa"
        type="text"
        formControlName="tituloCurto"
        (blur)="onAlterarTitulos()"
        maxlength="100"
      />
      <span
        class="error-message"
        [hidden]="
          !(
            showErrors && controls.descricaoPesquisa.get('tituloCurto').errors
          ) &&
          !(
            exibirErrosValidacaoRascunho &&
            controls.descricaoPesquisa.get('tituloCurto').errors
          )
        "
      >
        Deve ser informado um título curto para a pesquisa de até 100 caracteres
      </span>
    </div>
    <div
      formGroupName="descricaoPesquisa"
      class="col-lg-3 col-md-4 col-sm-6 campo"
    >
      <label>Objetivo*</label>
      <input
        mascara-primeiro-digito-sem-espaco
        placeholder="Ex.:analisar grau de satisfação"
        title="objetivo da pesquisa"
        type="text"
        formControlName="objetivo"
        (blur)="onAlterarDescricaoPesquisa()"
        maxlength="150"
      />
      <span
        class="error-message"
        [hidden]="
          !(showErrors && controls.descricaoPesquisa.get('objetivo').errors)
        "
      >
        Deve ser informado um objetivo para a pesquisa de até 150 caracteres
      </span>
    </div>
    <div
      formGroupName="descricaoPesquisa"
      class="col-lg-3 col-md-4 col-sm-6 campo"
    >
      <label>Tipo*</label>
      <input
        mascara-primeiro-digito-sem-espaco
        placeholder="Ex.:em teste"
        title="tipo da pesquisa"
        type="text"
        formControlName="tipoPesquisa"
        (blur)="onAlterarDescricaoPesquisa()"
        maxlength="150"
      />
      <span
        class="error-message"
        [hidden]="
          !(showErrors && controls.descricaoPesquisa.get('tipoPesquisa').errors)
        "
      >
        Deve ser informado um tipo para a pesquisa de até 150 caracteres
      </span>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 campo">
      <select
        formControlName="status"
        [disableControl]="!isEdicao || !modificarStatusPermitido"
      >
        <option *ngFor="let status of statusPesquisaSelecao" [value]="status">
          {{ statusPesquisaNomeAmigavel[status] }}
        </option>
      </select>
    </div>
    <div
      formGroupName="descricaoPesquisa"
      class="col-lg-3 col-md-4 col-sm-6 campo-inline"
    >
      <mat-slide-toggle
        formControlName="permitirGravacao"
        (change)="onAlterarDescricaoPesquisa()"
        >Permitir Gravação
      </mat-slide-toggle>
    </div>
  </div>
  <div formGroupName="configuracaoPesquisa" class="row section">
    <div class="col-12 sub-title">
      <h3>Configuração</h3>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 campo">
      <label>Distância entre entrevistas (m)</label>
      <input
        title="distância entre entrevistas da pesquisa"
        mascara-numero
        type="text"
        placeholder="Em metros"
        formControlName="distancia"
        (blur)="onAlterarConfiguracaoPesquisa()"
        maxlength="4"
      />
      <span
        class="error-message"
        [hidden]="
          !(showErrors && controls.configuracaoPesquisa.get('distancia').errors)
        "
      >
        A distância entre entrevistas deve ser de até 9.999 metros
      </span>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 campo">
      <label>Amostras Presenciais*</label>
      <input
        title="quantidade de amostras presenciais da pesquisa"
        mascara-numero
        type="text"
        formControlName="amostrasPresenciais"
        (blur)="onAlterarConfiguracaoPesquisa()"
        placeholder="(0 a 9.999)"
        maxlength="4"
      />
      <span
        class="error-message"
        [hidden]="
          !(
            showErrors &&
            controls.configuracaoPesquisa.get('amostrasPresenciais').errors
          )
        "
      >
        Deve ser informada a quantidade de amostras presenciais da pesquisa (0 a
        9.999)
      </span>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 campo">
      <label>Amostras Online*</label>
      <input
        title="quantidade de amostras online da pesquisa"
        mascara-numero
        type="text"
        formControlName="amostrasOnline"
        (blur)="onAlterarConfiguracaoPesquisa()"
        placeholder="(0 a 9.999)"
        maxlength="4"
      />
      <span
        class="error-message"
        [hidden]="
          !(
            showErrors &&
            controls.configuracaoPesquisa.get('amostrasPresenciais').errors
          )
        "
      >
        Deve ser informada a quantidade de amostras online da pesquisa (1 a
        9.999)
      </span>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 campo">
      <label>Tempo mínimo de entrevistas*</label>
      <input
        placeholder="Ex.:12:00"
        title="tempo mínimo da entrevista"
        mascara-min-seg
        type="text"
        formControlName="tempoMinimo"
        (blur)="onAlterarConfiguracaoPesquisa()"
      />
      <span
        class="error-message"
        *ngIf="
          showErrors &&
            (controls.configuracaoPesquisa.get('tempoMinimo').errors
              ?.required ||
              controls.configuracaoPesquisa.get('tempoMinimo').errors?.invalid);
          else notInvalidFormat
        "
      >
        Deve ser informado um tempo mínimo de entrevistas válido
        (minutos:segundos)
      </span>
      <ng-template #notInvalidFormat>
        <span
          class="error-message"
          *ngIf="
            showErrors &&
            controls.configuracaoPesquisa.get('tempoMinimo').errors
              ?.temporalValueToShort
          "
        >
          O tempo mínimo de uma entrevista deve ser maior ou igual a
        </span>
      </ng-template>
    </div>
    <!-- <div class="col-lg-3 col-md-4 col-sm-6 campo-inline">
      <mat-slide-toggle formControlName="cercaEletronica" (change)="onAlterarConfiguracaoPesquisa()">Cerca eletrônica</mat-slide-toggle>
        /*
         ESTE TRECHO AQUI JÁ ESTAVA COMENTADO
        <label>Cerca eletrônica</label>
        <input type="checkbox" formControlName="cercaEletronica" (blur)="onChange()">
        */
    </div> -->
  </div>
  <!--  -->
  <div
    formGroupName="configuracaoPesquisa"
    class="row section"
    style="align-items: center"
  >
    <div class="col-12 sub-title">
      <h3>Compartilhamento</h3>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 campo">
      <label>Link para compartilhamento do formulário online</label>
      <input
        [ngClass]="
          controls.status.value === 'EXECUCAO' ? 'inputWithToken' : null
        "
        title="Link para compartilhamento do formulário online"
        type="text"
        placeholder="link não existe"
        readonly
        value="{{ generateFormUrl() }}"
      />
    </div>
    <div
      *ngIf="controls.configuracaoPesquisa.get('token').value"
      class="col-lg-6 col-md-6 col-sm-6"
    >
      <button
        class="sg-btn sg-alternate"
        (click)="copyToClipboard()"
        [ngClass]="
          controls.status.value !== 'EXECUCAO' ? 'buttonWithoutToken' : null
        "
        [disabled]="controls.status.value !== 'EXECUCAO'"
      >
        COPIAR LINK
      </button>
    </div>
    <div
      *ngIf="!controls.configuracaoPesquisa.get('token').value"
      class="col-lg-4 col-md-4 col-sm-4"
      style="background: #c4c4c4; color: #595656; padding: 10px 15px"
    >
      O link só será disponibilizado após a definição da amostra online e passar
      para o status de execução.
    </div>
  </div>
  <!--  -->
  <div class="row section align-items-start">
    <div class="col-lg-4 col-md-6">
      <div class="sub-title">
        <h3>Período*</h3>
      </div>
      <app-calendario
        [indicadorMudancaStatus]="subjectMudancaCalendar"
        title="periodo da pesquisa"
        [datasPreviamenteSelecionadas]="datasPreviamenteSelecionadas"
        (calendarioChange)="onCalendarioChange($event)"
        [editavel]="modificacaoPermitida"
        [statusAtualPesquisa]="statusOriginalPesquisa"
      >
      </app-calendario>
      <span
        class="error-message"
        [hidden]="
          !(
            showErrors &&
            controls.configuracaoPesquisa.get('agendamentos').errors
          )
        "
      >
        Informe uma data válida
      </span>
    </div>
    <!--
    <div formGroupName="analisePesquisa" class="col-lg-8 col-md-6">
      <div class="sub-title">
        <h3>Sistema de análise</h3>
      </div>

      <div class="campo-inline">
        <mat-slide-toggle formControlName="possuiTracking" (change)="onAlterarAnalisePesquisa()" color="#000">
            A pesquisa possui configuração de tracking
        </mat-slide-toggle>
      </div>
      <div class="row" *ngIf="controls.analisePesquisa.get('possuiTracking').value">
        <div class="col-lg-6 col-md-12 campo">
          <label>Amostra diária*</label>
          <input placeholder="(1 a 999.999)" title="quantidade de amostras diárias da pesquisa" mascara-numero type="text" formControlName="qtdDiariaEntrevistas" (blur)="onAlterarAnalisePesquisa()"
            maxlength="6">
          <span class="error-message"
            [hidden]="!(showErrors && controls.analisePesquisa.controls.qtdDiariaEntrevistas.errors)">
            O número de amostras diárias deve ser entre 1 e 999.999
          </span>
        </div>
        <div class="col-lg-6 col-md-12 campo">
          <label>Amostra mínima para consolidação*</label>
          <input placeholder="(1 a 999)" title="quantidade de amostra mínima" mascara-numero type="text" formControlName="qtdMinimaConsolidacao" (blur)="onAlterarAnalisePesquisa()"
            maxlength="3">
          <span class="error-message"
            [hidden]="!(showErrors && controls.analisePesquisa.controls.qtdMinimaConsolidacao.errors)">
            O número de amostras para consolidação deve ser entre 1 e 999
          </span>
        </div>
        <div class="col-lg-6 col-md-12 campo">
          <label>Amostras descartadas após consolidação*</label>
          <input placeholder="(1 a 999.999)" title="quantidade de amostras descartadas" mascara-numero type="text" formControlName="qtdEntrevistasDescarte" (blur)="onAlterarAnalisePesquisa()"
            maxlength="6">
          <span class="error-message"
            [hidden]="!(showErrors && controls.analisePesquisa.controls.qtdEntrevistasDescarte.errors)">
            O número de amostras para descarte deve ser entre 1 e 999.999
          </span>
        </div>
      </div>
    </div>
  --></div>

  <!-- <div>
        <h2>Descrição</h2>
        <label>
            Título da pesquisa*
            <input type="text" formControlName="titulo" (blur)="onChange()">
        </label>
        <label>
            Título curto da pesquisa*
            <input type="text" formControlName="tituloCurto" (blur)="onChange()">
        </label>
        <label>
            Objetivo*
            <input type="text" formControlName="objetivo" (blur)="onChange()">
        </label>
        <label>
            Tipo*
            <input type="text" formControlName="tipoPesquisa" (blur)="onChange()">
        </label>
        <label>
            Status
            <input type="text" value="Em teste" disabled>
        </label>
        <label>
            Permitir Gravação
            <input type="checkbox" formControlName="permitirGravacao" (blur)="onChange()">
        </label>
    </div> -->
  <!-- <div>
        <h2>Configuração</h2>
        <label>
            Distância entre entrevistas
            <input type="text" formControlName="distancia" (blur)="onChange()">
        </label>
        <label>
            Amostras*
            <input type="text" formControlName="amostras" (blur)="onChange()">
        </label>
        <label>
            Tempo mínimo de entrevistas*
            <input mascara-min-seg type="text" formControlName="tempoMinimo" (blur)="onChange()">
        </label>
        <label>
            Cerca eletrônica
            <input type="checkbox" formControlName="cercaEletronica" (blur)="onChange()">
        </label>
    </div> -->
  <!-- <div>
        <h2>Sistema de análise</h2>
        <label>
            A pesquisa possui configuração de tracking
            <input type="checkbox" formControlName="possuiTracking" (blur)="onConfiguracaoTrackingChange() || onChange()">
        </label>
        <div *ngIf="controls.possuiTracking.value">
            <label>
                Amostra diária*
                <input mascara-numero type="text" formControlName="qtdDiariaEntrevistas" (blur)="onChange()">
            </label>
            <label>
                Amostra mínima para consolidação*
                <input mascara-numero type="text" formControlName="qtdMinimaConsolidacao" (blur)="onChange()">
            </label>
            <label>
                Amostras descartadas após consolidação
                <input mascara-numero type="text" formControlName="qtdEntrevistasDescarte" (blur)="onChange()">
            </label>
        </div>
    </div> -->
  <!-- <div>
        <h2>Período</h2>
        <app-calendario [parentEventPublisher]="inputPublisher" (inputUpdate)="onInputUpdate($event)"></app-calendario>
    </div> -->
</div>
