import { Component, Input } from "@angular/core";
import {
  UntypedFormControl, UntypedFormGroup, Validators
} from "@angular/forms";
import * as moment from "moment/moment";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-data",
  templateUrl: "./data.component.html",
  styleUrls: ["./data.component.scss"],
})
export class RespostaDataComponent extends RespostaComponent {
  @Input() private pergunta: PerguntaEsquema;
  @Input() private respostaPergunta: RespostaPergunta;
  @Input() formGroup: UntypedFormGroup;

  ngOnInit() {
    super.ngOnInit();

    moment.locale("pt-BR");

    const respostas = this.respostaPergunta.alternativasSelecionadas;

    const resposta =
      respostas.length > 0
        ? {
            id: respostas[0].id,
            data: respostas[0].data,
            tipo: TipoRespostaPergunta.HORARIO,
          }
        : {
            id: null,
            data: "",
            tipo: TipoRespostaPergunta.HORARIO,
          };

    const data = resposta.data
      ? moment(resposta.data, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";

    this.formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(resposta.id),
      data: new UntypedFormControl(data, [Validators.required]),
      tipo: new UntypedFormControl(resposta.tipo),
    });

    this.formGroup.valueChanges.subscribe((val) => {
    });

  }

  hasErrors() {
    return this.formGroup.invalid;
  }

  /**
   * @override
   */
  onProximaPergunta() {
    const resposta = this.formGroup.value;
    const dataFormatada = moment(resposta.data).format("DD/MM/YYYY");

    if (this.hasErrors()) {
      return;
    }

    const alternativas: AlternativaSelecionada[] = [
      {
        id: resposta.id,
        data: dataFormatada,
        tipo: TipoRespostaPergunta.DATA,
      },
    ];

    const respostaPergunta: RespostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativas,
      this.respostaPergunta.num
    );


    this.notifyRespostaPergunta(respostaPergunta);
  }
}
