import { Component, Input, OnInit, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { HeaderSearchDataV4 } from '../header/servico/header-service';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { TipoUsuarioSistema } from 'app/util/usuario/tipoUsuario';
import { AuthUser } from 'app/infraestrutura/security/authUser';


@Component({
  selector: 'app-system-search',
  templateUrl: './system-search.component.html',
  styleUrls: ['./system-search.component.scss']
})
export class SystemSearchComponent implements OnInit, OnChanges {

  constructor(
    private securityService: SecurityService,
    private route: ActivatedRoute,
    protected router: Router,
  ) { }

  // total de resultados
  @Input()
  searchAmout: number;

  // resultados do dado da pesquisa
  @Input()
  researchData: HeaderSearchDataV4;
  // texto que foi escrito
  @Input()
  researchTitle: string = '';

  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  // visibilidade do resultados
  isVisible: boolean = false;
  userLogado: AuthUser;

  //  Função que retorna a informação em formato de boolean se o usuario é admnistrador ou não
  userIsAdm(): boolean {
    return this.userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR;
  }

  // pega o usuário que está logado
  getUsuarioLogado(): AuthUser {
    const user: AuthUser = this.securityService.getAuthenticatedUser();
    return user;
  }

  ngOnInit() {
    this.userLogado = new AuthUser();
    this.userLogado = this.getUsuarioLogado()
  }

  onRefresh(params: any, url: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([url, params]);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // verifica se há mudança nos dados da pesquisa
    this.isVisible = (!!changes.researchData && !!changes.researchData.currentValue) || !!this.researchData;
  }

  handleNavigateClick(route: string) {
    if (this.router.isActive(route, true)) {
      // recarrega a pagina atual
      this.onRefresh({ search: this.researchTitle }, route);
    } else {
      // navega para página de lista
      this.router.navigate([route, { search: this.researchTitle }]);
    }
    this.onClose.emit(true)
    this.isVisible = false;
  }

  handleClientsClick() {
    this.handleNavigateClick("/cliente");
  }

  handleOperatorClick() {
    this.handleNavigateClick("/operador");
  }

  handleGroupClick() {
    this.handleNavigateClick("/grupo");
  }

  handleContratanteClick() {
    this.handleNavigateClick("/management");
  }

  handleLocationClick() {
    this.handleNavigateClick("/localidade-beta")
  }

  handleEmployeesClick() {
    this.handleNavigateClick("/funcionarios");
  }

  handleResearchsClick() {
    this.handleNavigateClick("/pesquisa-beta");
  }

  // Função que redireciona o usuário para a página de resultados da pesquisa
  handleSeeAllResults() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/pesquisar'], { state: { researchData: this.researchData, researchSum: this.searchAmout } });
    this.isVisible = false;
    this.onClose.emit(true)
  }
}
