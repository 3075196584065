<div class="seletor-content" [ngClass]="selectOptionsAreVisible && 'addShadow'">
  <div class="seletor">
    <div
      [ngClass]="selectOptionsAreVisible ? 'select-header open':'select-header'"
      (click)="toggleSelectOptionsVisibility()"
    >
      <h3>
        Filtrar por:
        <b [ngClass]="!this.filterSelectorState.statusActive ? '' : 'selected'"
          >{{selectLabel}}</b
        >
      </h3>
      <i class="fa fa-chevron-down" aria-hidden="true"></i>
    </div>
    <div
      class="options-content"
      [ngClass]="selectOptionsAreVisible && 'addShadow'"
      *ngIf="selectOptionsAreVisible"
    >
      <div class="options">
        <div
          [ngClass]="filterSelectorState.statusActive === 'ATIVO' ? 'option-item checked' : 'option-item'"
          (click)="changeOptionItem('ATIVO')"
        >
          <div class="checkbox">
            <i class="fa fa-check"></i>
          </div>
          <h6>Ativo</h6>
        </div>
        <div
          [ngClass]="filterSelectorState.statusActive === 'INATIVO' ? 'option-item checked' : 'option-item'"
          (click)="changeOptionItem('INATIVO')"
        >
          <div class="checkbox">
            <i class="fa fa-check"></i>
          </div>
          <h6>Inativo</h6>
        </div>
      </div>
    </div>
  </div>
</div>
