import { AbstractControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export class AgendamentoColeta {

  dataColeta: string;

  constructor(dataColeta: string) {
    this.dataColeta = dataColeta;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(agendamentoColeta: AgendamentoColeta): AbstractControl {

    const formGroup = new UntypedFormGroup({
      dataColeta: new UntypedFormControl(agendamentoColeta.dataColeta),
    });

    return formGroup;
  }

}
