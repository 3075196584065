import { ValidationErrors, UntypedFormControl } from '@angular/forms';

/**
 * Validador generico, verifica se ao menos um dos formControls passados possue
 * um valor definido.
 */
export default (controls: UntypedFormControl[]) => {

  return (targetControl: UntypedFormControl): ValidationErrors => {

    if (targetControl.dirty) {
      controls.forEach((cotaControlToUpdate) => {
        if (cotaControlToUpdate.pristine) {
          cotaControlToUpdate.updateValueAndValidity();
        }
      });
    }
    targetControl.markAsPristine();

    // tslint:disable-next-line: max-line-length
    const valorDefinido = controls.some(control => control.value);
    return valorDefinido ? null : { semValores: true };
  };
};
