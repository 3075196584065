// tslint:disable-next-line: max-line-length
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ClienteService } from "../../../../cliente/servico/cliente.service";
import { Cliente } from "../../../../cliente/cliente";
import {
  UntypedFormControl,
  UntypedFormGroup,
  AbstractControl,
} from "@angular/forms";
import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/util/componente/filterable-select/filterable-select.component";
import { select } from "@ngrx/store";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-cliente-cadastro-input",
  templateUrl: "./cliente.cadastro.input.component.html",
  styleUrls: ["./cliente.cadastro.input.component.scss"],
})
export class ClienteCadastroInputComponent implements OnInit, OnChanges {
  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };

  clientes: SelectDataItem[] = [];

  @Input() clienteSelecionado: Cliente;
  idClienteSelecionado: number | null = null;
  @Input() modificavel: boolean = true;
  @Output() clienteSelecionadoEvent: EventEmitter<Cliente> = new EventEmitter();

  constructor(
    private clienteService: ClienteService,
    private modalService: ModalService
  ) {}

  /**
   * @override
   */
  ngOnInit() {
    console.log("[ClienteSelector.ngOnInit]");
    this.carregarClientes();
    console.log("[ClienteSelector.ngOnInit] after load clientes!");
    this.initFormGroup();
  }

  clienteToSelectedItem(cliente: Cliente) {
    return {
      value: cliente.id,
      label: cliente.nome,
    };
  }

  /**
   * @override
   */
  ngOnChanges(changes: SimpleChanges): void {
    /**
     * Se o componente nao é mais modificavel,
     * ele é desabilitado
     */
    if (changes.modificavel) {
      if (this.formGroup && !changes.modificavel.currentValue) {
        this.formGroup.disable();
      } else if (this.formGroup) {
        this.formGroup.enable();
      }
    }

    if (changes.clienteSelecionado) {
      const currentClienteSelecionado = changes.clienteSelecionado.currentValue;

      if (currentClienteSelecionado && currentClienteSelecionado.id) {
        this.idClienteSelecionado = currentClienteSelecionado.id;
        console.log(
          "[ClienteSelector.ngOnChanges] idClienteSelecionado: ",
          this.idClienteSelecionado
        );
      }
    }

    const changedClienteSelecionado = changes.clienteSelecionado;

    if (!changedClienteSelecionado) {
      return;
    }

    console.log(
      "[ClienteSelector.ngOnChanges] changedClienteSelecionado: ",
      changedClienteSelecionado
    );

    this.clienteSelecionado = changedClienteSelecionado.currentValue;
    this.carregarClientes();
  }

  initFormGroup() {
    console.log(
      "[ClienteSelector.initFormGroup] this.clienteSelecionado: ",
      this.clienteSelecionado
    );

    // tslint:disable-next-line:max-line-length
    this.formGroup = new UntypedFormGroup({
      cliente: new UntypedFormControl(this.clienteSelecionado),
    });

    console.log("[ClienteSelector.initFormGroup] formGroup: ", this.formGroup);

    this.controls = this.formGroup.controls;

    if (!this.modificavel) {
      this.formGroup.disable();
    }
  }

  getClienteById(id: number): SelectDataItem {
    const matchedResults = this.clientes.filter(
      (cliente) => cliente.value == id
    );

    // tslint:disable-next-line:max-line-length
    const clienteSelecionado =
      matchedResults && matchedResults.length > 0 ? matchedResults[0] : null;

    return clienteSelecionado;
  }

  selectDataItemToCliente(selectData: SelectDataItem) {
    return {
      id: selectData.value,
    };
  }

  /**
   * Carrega os clientes disponíveis bem como marca o selecionado
   */
  carregarClientes() {
    this.clienteService.listar().subscribe({
      next: (clientes) => {
        this.clientes.length = 0;
        this.clientes.push(...clientes.map(this.clienteToSelectedItem));

        if (this.clienteSelecionado) {
          console.log(
            "[ClienteSelector.carregarClientes] clienteSelecionado: ",
            this.clienteSelecionado
          );
          const clienteSelecionado: SelectDataItem = this.getClienteById(
            this.clienteSelecionado.id
          );
          this.controls.cliente.setValue(
            this.selectDataItemToCliente(clienteSelecionado)
          );
        }
        // tslint:disable-next-line:align
      },
      error: () => {
        this.modalService.showModal({
          title: "Carregar clientes",
          messageModal: "Houve um problema ao carregar os clientes",
          btnTitlePositive: "Entendi",
          isOnlyConfirmation: true,
        });
      },
    });
  }

  /**
   * Evento disparado quando um cliente é selecionado
   */
  handleSelectedCliente(selectedItem: SelectedItemEvent) {
    const idCliente = selectedItem.item.value;
    this.formGroup.patchValue({
      cliente: {
        id: idCliente,
      },
    });

    this.clienteSelecionadoEvent.emit(this.controls.cliente.value);
  }
}
