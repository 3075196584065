import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import validadorMarcacao from 'app/util/validador/pergunta/validadorMarcacao';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoPergunta } from '../../../../../../pesquisa-old/cadastro/inputs/tipo-pergunta-input/tipoPergunta';
import { ElementoSecao } from '../../../../../../pesquisa-old/cadastro/model/elementoSecao';
import { Pergunta } from '../../../../../../pesquisa-old/cadastro/model/pergunta';
import { PesquisaCadastro } from '../../../../../../pesquisa-old/cadastro/model/pesquisaCadastro';
import { ItemProtegido } from '../../../../../../pesquisa-old/cadastro/security/itemProtegido';
import { AlterarPergunta, Selecionar } from '../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../pesquisa-old/cadastro/store/passoCadastro';

@Component({
  selector: 'app-marcacao-pergunta-conteudo',
  templateUrl: './marcacao-pergunta-conteudo.component.html',
  styleUrls: ['./marcacao-pergunta-conteudo.component.scss'],
})
export class MarcacaoPerguntaConteudoComponent implements OnInit, OnDestroy {

  modificacaoPermitida: boolean = false;
  tipoPerguntaTitulo: string = '';

  private perguntasDisponiveis: Pergunta[] = [];

  @Input() source: Pergunta;
  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };

  private subscriptions: Subscription[] = [];

  /**
   * Marcador para saber se os erros de validacao
   * devem ser exibidos
   */
  showErrors: boolean = false;

  passoBloqueado: boolean = false;

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
    protected store: Store<CadastroPesquisaStoreState>) { }

  /**
   * Inicializa o formGroup baseado nos dados da pergunta
   * recebidos como @Input;
   *
   * Inicializa a variável tipoPerguntaTitulo baseado no tipo da pergunta
   * recebido no @Input;
   *
   * Inicializa a variável "modificacaoPermitida" baseado nas permissões
   * do usuário logado;
   *
   * Se subscreve na store para ser notificado de alterações no estado
   * da pesquisa. Uma dessas modificações é se deve exibir erros de validação
   * no formulário, se sim, o atributo "showErrors" é setado como true;
   */
  ngOnInit() {

    this.inicializarFormGroup(this.source);
    this.tipoPerguntaTitulo = TipoPergunta[this.source.tipo];

    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.MARCACOES);
    if (!this.modificacaoPermitida) {
      this.formGroup.disable();
    }

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    const passosBloqueadosSubscription = pesquisaStoreObservable.subscribe((store) => {
      // tslint:disable-next-line: max-line-length
      this.passoBloqueado = store.dadosGeraisCadastro.passosBloqueados.includes(PassoCadastro.MARCACOES);

      if (!this.modificacaoPermitida || this.passoBloqueado) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    });
    this.subscriptions.push(passosBloqueadosSubscription);

    const pesquisaSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.pesquisa),
      )
      .subscribe((pesquisa) => {
        this.carregarPerguntasDisponiveis(pesquisa);
      });

    this.subscriptions.push(pesquisaSubscription);

    /**
     * Verificando se deve-se exibir as mensagens de validação
     * no passo atual, caso positivo, é feito o unsubscribe
     * no canal
     */
    const dadosPassoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso),
      )
      .subscribe((dadosPasso) => {
        const dados = dadosPasso.get(PassoCadastro.QUESTIONARIO);
        // tslint:disable-next-line: max-line-length
        this.showErrors = dados.exibirValidacao;
        if (this.showErrors && dadosPassoSubscription) {
          dadosPassoSubscription.unsubscribe();
        }
      });

    this.subscriptions.push(dadosPassoSubscription);
  }

  /**
   * Inicializa o formgroup representando a pergunta
   * passada via @Input;
   *
   * Caso a pergunta possua 'pulo' ou 'diferente de', o formGroup
   * com esses tipos de marcação serão adicionados mesmo que
   * a pergunta não os possua;
   *
   * OBS: Quando a operação for finalizada, será verificado se
   * os pulos e diferenças foram configurados, se sim, eles são
   * enviados à store, caso negativo, terão a marcação removida
   * da alternativa e então enviados para a store;
   */
  inicializarFormGroup(pergunta: Pergunta) {
    // this.adicionarMarcacoesCasoNecessario(pergunta);

    this.formGroup = Pergunta.getInitializedControl(pergunta);
    // tslint:disable-next-line: max-line-length
    (<UntypedFormGroup>this.formGroup.controls.marcacaoPergunta).get('perguntaDestino').setValidators([validadorMarcacao]);

    this.controls = this.formGroup.controls;
    this.disableQuestionarioControls();
  }

  disableQuestionarioControls() {
    this.controls.nome.disable();
    this.controls.descricao.disable();
  }

  /**
   * Carrega as perguntas disponiveis para salto e diferente de
   */
  carregarPerguntasDisponiveis(pesquisa: PesquisaCadastro) {

    // tslint:disable-next-line: max-line-length
    const elementosSecaoMatriz = pesquisa.elementosQuestionario.map(elemetoQuestionario => elemetoQuestionario.secao.elementosSecao);
    const elementosSecaoArray: ElementoSecao[] = [].concat(...elementosSecaoMatriz);
    const perguntas = elementosSecaoArray.map(elementoSecao => elementoSecao.pergunta);

    /**
     * Removendo a propria questao
     */
    let indexToRemove = -1;
    perguntas.some((pergunta, index) => {

      if (pergunta.hash === this.formGroup.get('hash').value) {
        indexToRemove = index;
        return true;
      }
    });

    perguntas.splice(indexToRemove, 1);

    this.perguntasDisponiveis = perguntas;
  }

  onChange() {
    /**
     * Removendo pulos e diferenças caso estas não tenham sido configuradas
     */
    const perguntaConfigurada: Pergunta = this.formGroup.getRawValue();
    // this.removerPuloEDiferencaCasoNecessario(perguntaConfigurada);

    this.store.dispatch(new AlterarPergunta(perguntaConfigurada));
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Função que fecha o conteudo que está
   * sendo exibido
   */
  cancelar() {
    this.store.dispatch(new Selecionar(null, null));
  }

}
