import {
  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from "@angular/core";
import { ModalService } from "app/componentes/modal/modal.service";
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import { NotificatorService } from "app/notificador/notificator.service";
import { v4 as uuid } from "uuid";
import {
  CompletedSavingEvent,
  FailedSavingEvent,
  PendingSavingEvent
} from "../../events/save";
import { ICanNotSaveButton } from "../../pesquisas-cadastro.model";
import { SectionModel } from "./pesquisas-questionario-secoes-cadastro/pesquisas-questionario-secoes-cadastro.model";
import { SectionElemModel, TipoPergunta } from "./pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { SectionConfigModel } from "./pesquisas-questionario.model";

enum ScreenMode {
  listingSections = 0x01,
  editingSections = 0x02,
  editingElements = 0x03,
}

// Dados do elemento seção a ganhar foco na tela de listagem
export type ActiveSectionElementData = {
  sectionId: number;
  sectionElementOrdem: number;
};

// metadados da ação de salvar dentro de configurações
type SaveMetaData = {
  metadata: {
    name: string;
    seq: number;
    newSectionElementData?: ActiveSectionElementData;
  };
  models: SectionConfigModel[];
};

@Component({
  selector: "app-pesquisas-questionario",
  templateUrl: "./pesquisas-questionario.component.html",
  styleUrls: ["./pesquisas-questionario.component.scss"],
})
export class PesquisasQuestionarioComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() activeSectionElementData: ActiveSectionElementData;
  // token de visualização da pesquisa
  @Input() viewToken: string;
  // título da pesquisa
  @Input() surveyTitle: string;
  // model
  @Input() stepModels: SectionConfigModel[] = [];

  // modo de telas
  screenMode: ScreenMode = ScreenMode.listingSections;
  // atual modelo que está sendo editado
  sectionsModels: SectionModel[] = [];
  editedSectionModel: SectionModel = null;
  editedSectionElemModel: SectionElemModel = null;
  // seção selecionada
  selectedSectionId: number = -1;
  selectedSectionSeq: number = 0;
  selectedSectionName: string = null;
  // diz se a tela está ocupada (semelhante, mas ao contrário, de isReady)
  isBusy: boolean = false;
  subs = [];

  // secoesComPerguntasExpandidas: SectionModelWithElems[] = [] as SectionModelWithElems[];
  // Estado para armazenar localmente os elemenentos questionario
  elementosQuestionario: SectionConfigModel = {
    id: null,
    hash: "",
    ordem: 1,
    secao: null,
  };

  // Estado para armazenar a informação se o usuário deseja criar uma nova seção para o questionário atual
  criarNovaSecao: boolean = false;
  editarSecao: boolean = false;
  editarSecaoDados: any;

  //  Estado para armazenar a informação se o usuario tem o desejo de criar ou editar uma pergunta de uma secao
  desejaCriarOuEditarPergunta: boolean = false;

  //  Estado para armazenar a informação se o usuario tem o desejo de editar uma pergunta de uma secao
  desejaEditarPergunta: boolean = false;

  //  Indice de qual secao que será criada uma nova pergunta, caso o usuario deseje criar uma nova pergunta
  IdDaSecaoASerAdicionadaPergunta: number = -1;

  //  Indice de qual secao que será editada uma pergunta, caso o usuario deseje editar uma pergunta
  indexDaSecaoAEditarPergunta: number = -1;

  //  Indice de qual pergunta da seção que será editada
  indexDaPerguntaASerEditada: number = -1;

  // secao que encontra-se em edição no momento
  currentSectionModel: SectionModel;

  // codigo marcacao da pergunta resultante da operação de cópia
  codigoMarcacaoDaPerguntaCopia: string;

  // saída dos eventos
  @Output() onChange: EventEmitter<void> = new EventEmitter();
  @Output() onDone: EventEmitter<void> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSave: EventEmitter<SaveMetaData> = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter()

  constructor(
    private eventBus: EventBus,
    private notificatorService: NotificatorService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    // registrar ouvintes para os eventos
    const sub0 = this.eventBus.on(PendingSavingEvent.TYPE, () => {
      this.isBusy = true;
    });
    const sub1 = this.eventBus.on(CompletedSavingEvent.TYPE, () => {
      this.isBusy = false;
      this.screenMode = ScreenMode.listingSections;
    });
    const sub2 = this.eventBus.on(FailedSavingEvent.TYPE, () => {
      this.isBusy = false;
    });
    this.subs = [sub0, sub1, sub2];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stepModels) {
      this.sectionsModels = this.extractSectionModel(this.stepModels);
    }
  }

  ngOnDestroy() {
    // remover registro de ouvintes para os eventos
    this.subs.map((s) => this.eventBus.unregister(s));
  }

  // função que vem do pai, repassando para o filho
  // para desabilitar o botão de avançar ao cadastrar ou editar uma pergunta
  onCanNotSaveButtonQuestion($event: ICanNotSaveButton) {
    this.onCanNotSaveButton.emit($event)
  }

  cancelEditing() {
    // select mode
    this.screenMode = ScreenMode.listingSections;
    // notifique modificações
    this.onDone.emit();
  }

  // extrae apenas o modelo da secao
  extractSectionModel(stepModels: SectionConfigModel[]): SectionModel[] {
    return stepModels
      ? stepModels
        .sort((a, b) => a.ordem - b.ordem)
        .map((m) => ({
          ...m.secao,
        }))
      : [];
  }

  // [] recupera o maior valor da ordem de uma pergunta entre todas as seções
  maxStepElemOrdem(): number {
    let maxOrdem = 0;
    if (Array.isArray(this.stepModels) && this.stepModels.length > 0) {
      for (let i = 0; i < this.stepModels.length; i++) {
        const elems = this.stepModels[i].secao.elementosSecao;
        if (!Array.isArray(elems)) {
          break;
        }
        for (let j = 0; j < elems.length; j++) {
          const currOrdem = elems[j].ordem;
          if (maxOrdem < currOrdem) {
            maxOrdem = currOrdem;
          }
        }
      }
    }
    // result
    return maxOrdem;
  }

  // [] recupera o maior valor da ordem das seções
  maxStepOrdem(): number {
    let maxOrdem = 0;
    if (Array.isArray(this.stepModels) && this.stepModels.length > 0) {
      for (let i = 0; i < this.stepModels.length; i++) {
        const currOrdem = this.stepModels[i].ordem;
        if (maxOrdem < currOrdem) {
          maxOrdem = currOrdem;
        }
      }
    }
    // result
    return maxOrdem;
  }

  // [cadastro-listagem-seção] manipulação da solicitação de criar nova seção
  handleNewSectionClick() {
    // select mode
    this.screenMode = ScreenMode.editingSections;
    this.editedSectionModel = {
      descricao: null,
      hash: null,
      nome: null,
    };
    // propaga o evento de alteração
    this.onChange.emit();
  }

  //  função responsável por mostrar prompt de comando de deleção de seção
  handleShowDeleteSectionComandePrompt(section: SectionModel) {
    const ordem =
      this.sectionsModels.findIndex(
        (sectionModel) => sectionModel.id === section.id
      ) + 1;

    this.modalService.showModal({
      title: `Excluir seção ${ordem}`,
      messageModal: 'Ao excluir esta seção você perderá todos os dados de cadastro, inclusive todas as perguntas que fazem parte desta seção',
      btnTitlePositive: 'Excluir',
      btnTitleNegative: 'Cancelar',
      imgFile: 'icons/icon-warning.svg',
      positiveCallback: () => this.handleDeleteSectionAction(section)
    })
  }

  // [cadastro-listagem-seção] função que exclui uma seção
  handleDeleteSectionAction(section: SectionModel) {
    if (section && section.id) {
      this.stepModels = this.stepModels.filter(
        (m) => m.secao.id !== section.id
      );
      this.onChange.emit();
      this.onSave.emit({
        metadata: { name: "deletar-secao", seq: null },
        models: this.stepModels,
      });
    }
  }

  // [cadastro-listagem-seção] adicionar uma nova secao
  handleNewSectionElemAction(sectionId: number) {
    // modelo dessa
    const m = this.stepModels.find((m) => m.secao.id === sectionId);
    if (m && m.secao) {
      // modelos
      this.editedSectionElemModel = {
        id: null,
        hash: uuid(),
        ordem: this.maxStepElemOrdem() + 1,
        pergunta: null,
      };
      this.selectedSectionId = m.secao.id;
      this.selectedSectionSeq = m.ordem;
      this.selectedSectionName = m.secao.nome;
      // select mode
      this.screenMode = ScreenMode.editingElements;
      // notifique modificações
      this.onChange.emit();
    }
  }

  // [cadastro-listagem-seção] editar questionário
  handleEditSectionElemAction(params: {
    sectionId: number;
    sectionElemOrdem: number;
  }) {
    // modelo dessa
    const m = this.stepModels.find((sm) => sm.secao.id === params.sectionId);
    // verifica se sao elementos
    if (!!m && Array.isArray(m.secao.elementosSecao)) {
      // elementos
      const e = m.secao.elementosSecao.find(
        (e) => e.ordem === params.sectionElemOrdem
      );
      if (!!e) {
        // modelos
        this.editedSectionElemModel = e;
        this.selectedSectionId = m.secao.id;
        this.selectedSectionSeq = m.ordem;
        this.selectedSectionName = m.secao.nome;
        // select mode
        this.screenMode = ScreenMode.editingElements;
        // notifique modificações
        this.onChange.emit();
        // evita envio de mensagem
        return;
      }
    }
    //
    this.notificatorService.showError(
      "Atenção",
      "Não foi possível editar este item. Ele pode não existir mais!"
    );
  }

  // [cadastro-listagem-seção] função que deleta elemento questionario
  // da lista de elemento questionario de uma secao
  handleDeleteSectionElemAction(payload: {
    sectionId: number;
    sectionElemOrdem: number;
  }): void {
    // criar novo stepmodels
    const newStepModels = [] as SectionConfigModel[];
    for (let index = 0; index < this.stepModels.length; index++) {
      const sm = this.stepModels[index];
      const sme = sm.secao.elementosSecao.filter((e) => {
        return e.ordem !== payload.sectionElemOrdem;
      });
      newStepModels.push({
        ...sm,
        secao: {
          ...sm.secao,
          elementosSecao: sme,
        },
      });
    }
    // definir novo stepmodel
    this.stepModels = newStepModels;
    // log
    this.onChange.emit();
    this.onSave.emit({
      metadata: { name: "deletar-pergunta", seq: null },
      models: this.stepModels,
    });
  }

  // [cadastro-listagem-seção] função que duplica um elemento questionario da
  // lista de elemento questionario de uma secao
  handleDuplicateSectionElemAction(payload: {
    sectionId: number;
    sectionElemOrdem: number;
  }): void {
    // criar novo stepmodels
    const newStepModels = [] as SectionConfigModel[];
    for (let index = 0; index < this.stepModels.length; index++) {
      const sm = this.stepModels[index];

      const sme = sm.secao.elementosSecao.find(
        (e) => e.ordem === payload.sectionElemOrdem
      );

      this.codigoMarcacaoDaPerguntaCopia = uuid();

      newStepModels.push({
        ...sm,
        secao: {
          ...sm.secao,
          elementosSecao: !!sme
            ? [
              ...sm.secao.elementosSecao,
              {
                id: null,
                ordem: this.maxStepElemOrdem() + 1,
                hash: uuid(),
                pergunta: {
                  ...sme.pergunta,
                  titulosGrade: [TipoPergunta.GRADE_UNICA, TipoPergunta.GRADE_MULTIPLA].includes(sme.pergunta.tipo) ?
                    sme.pergunta.titulosGrade.map(tG => ({
                      ...tG,
                      id: null
                    })) :
                    [],
                  codigoMarcacao: this.codigoMarcacaoDaPerguntaCopia,
                  ...(sme.pergunta.marcacaoPergunta ? { marcacaoPergunta: null } : {}),
                  nome: !sme.pergunta.nome.includes(" - Cópia")
                    ? `${sme.pergunta.nome} - Cópia`
                    : !sme.pergunta.nome.includes(" - Cópia(")
                      ? `${sme.pergunta.nome.split(" - Cópia")[0]} - Cópia(1)`
                      : `${sme.pergunta.nome.split(" - Cópia")[0]} - Cópia(${+sme.pergunta.nome
                        .split(" - Cópia")[1]
                        .replace("(", "")
                        .replace(")", "") + 1
                      })`,
                  id: null,
                  alternativas: Array.isArray(sme.pergunta.alternativas)
                    ? sme.pergunta.alternativas.map((a) => ({
                      ...a,
                      id: null,
                      // Remoção de lógica de pulos para perguntas duplicadas
                      ...(a.codigoMarcacao ? { codigoMarcacao: null } : {}),
                      ...(a.marcacao ? { marcacao: null } : {}),
                      ...(a.comportamento ? { comportamento: 'CONTINUAR_ENTREVISTA' } : {}),
                    }))
                    : null,
                },
              },
            ]
            : sm.secao.elementosSecao,
        },
      });
    }
    // definir novo stepmodel
    this.stepModels = newStepModels;
    // comunicar mudança
    this.onChange.emit();
    this.onSave.emit({
      metadata: {
        name: "duplicar-pergunta",
        seq: payload.sectionElemOrdem,
        newSectionElementData: {
          sectionElementOrdem: this.maxStepElemOrdem(),
          sectionId: payload.sectionId,
        },
      },
      models: this.stepModels,
    });
  }

  // [cadastro-listagem-seção] manipula a solicitação de uma nova seção
  handleNewSectionAction() {
    // limpando dados do edit mode
    this.editedSectionModel = {
      descricao: null,
      hash: null,
      nome: null,
    };
    this.selectedSectionId = -1;
    this.selectedSectionSeq = 0;
    this.selectedSectionName = null;
    this.screenMode = ScreenMode.editingSections;

    // notifique modificações
    this.onChange.emit();
  }

  // [cadastro-listagem-seção] manipula solicitação de edição de seção
  handleEditSectionAction(model: SectionModel) {
    // select mode
    this.screenMode = ScreenMode.editingSections;
    this.editedSectionModel = model;
    // notifique modificações
    this.onChange.emit();
  }

  // TODO: este passo deverá ser revisto,
  // uma vez que todos o modelo já foi salvo
  // manipula o acionamento de salvar na tela
  // principal do questionário
  handleSaveAllAction(stepModels) {
    const newStepModels = stepModels.map((stepModel) => {
      return {
        ordem: stepModel.ordem,
        secao: {
          id: stepModel.id,
          nome: stepModel.nome,
          descricao: stepModel.descricao,
          elementosSecao: stepModel.elementosSecao,
          possuiGap: stepModel.possuiGap,
          quantidadePerguntas: stepModel.quantidadePerguntas,
        },
      };
    });

    const newStepModelsData = [] as SectionConfigModel[];

    this.stepModels.map((item, index) => {
      newStepModelsData.push({
        ...item,
        secao: newStepModels[index].secao,
      });
    });

    this.stepModels = newStepModelsData;

    // envia o modelo para pai cadastrar
    // Esse evento é disparado quando clicamos em "salvar" no passo 2, foi alterado o name em metadata
    // porque quando clicamos em salvar, a pesquisa é totalmente salva, e não só especificamente a seção.
    this.onSave.emit({
      metadata: { name: "cadastrar-pesquisa", seq: null },
      models: this.stepModels,
    });
  }

  // [cadastro-seção] manipulação do acionamento de cancelar
  handleSectionFormCancel() {
    this.cancelEditing();
  }

  // [cadastro-seção] manipula o acionamento do botão salva da seção
  handleSectionFormSave(model: SectionModel) {
    // se for edição, buscar antigo na lista
    let edited = false;
    let stepModelItem = null as SectionConfigModel;
    if (model && model.id) {
      stepModelItem = this.stepModels.find((m) => model.id === m.secao.id);
      stepModelItem = {
        ...stepModelItem,
        secao: {
          ...stepModelItem.secao,
          ...model,
        },
      };
      edited = true;
    }
    // redefine o model do passo
    if (edited) {
      this.stepModels = [
        ...this.stepModels.filter((m) => m.secao.id !== model.id),
        {
          ...stepModelItem,
        },
      ];
    } else {
      // calcular o valor da ordem
      const newOrdem = this.maxStepOrdem() + 1;
      // recriar step-config-model
      this.stepModels = [
        ...this.stepModels,
        {
          id: null,
          hash: uuid(),
          ordem: newOrdem,
          secao: {
            ...model,
            elementosSecao: [],
          },
        },
      ];
    }
    // envia o modelo para pai cadastrar
    this.onSave.emit({
      metadata: { name: "salvar-secao", seq: null },
      models: this.stepModels,
    });
  }

  // [cadastro-seção] notifica que o formulário foi aberto para alteração
  handleSectionFormChange() {
    // notifique modificações
    this.onChange.emit();
  }

  // [cadastro-perguntas] cancelar formulário
  handleSectionElemFormCancel() {
    this.cancelEditing();
    this.editedSectionElemModel = null;
    this.editedSectionModel = null;
  }

  // [cadastro-perguntas] save formulário
  handleSectionElemFormSave(params: {
    sectionId: number;
    sectionElem: SectionElemModel;
  }) {
    const newSectionElementData = {
      sectionElementOrdem: params.sectionElem.ordem,
      sectionId: params.sectionId,
    };

    // verifica se não tem alternativas para inserir array vazio
    if (!params.sectionElem.pergunta.alternativas) {
      params.sectionElem.pergunta.alternativas = [];
    }
    // se for edição, buscar antigo na lista
    // localizar os modelos
    const m = this.stepModels.find((m) => m.secao.id === params.sectionId);
    if (!!m) {
      // editing
      if (params.sectionElem.pergunta.id) {
        const e = m.secao.elementosSecao.find(
          (es) => es.pergunta.id === params.sectionElem.pergunta.id
        );
        if (e) {
          const r = [
            ...this.stepModels.filter((sm) => sm.secao.id !== params.sectionId),
            {
              ...m,
              secao: {
                ...m.secao,
                elementosSecao: [
                  ...m.secao.elementosSecao.filter(
                    (es) => es.pergunta.id !== params.sectionElem.pergunta.id
                  ),
                  {
                    ...e,
                    pergunta: {
                      ...params.sectionElem.pergunta,
                    },
                  },
                ],
              },
            } as SectionConfigModel,
          ];
          // propaga o evento de salvar
          const dataToSave = {
            metadata: {
              name: "editar-pergunta",
              seq: params.sectionElem.ordem,
              newSectionElementData,
            },
            models: r,
          };

          this.onSave.emit(dataToSave);
        } else {
          this.notificatorService.showError(
            "Atenção",
            "Não foi possível salvar os dados"
          );
        }
      } else {
        const latestStepElemOrdem = this.maxStepElemOrdem();
        // insering
        const temp = {
          ...m,
          secao: {
            ...m.secao,
            elementosSecao: [
              ...m.secao.elementosSecao,
              {
                ...params.sectionElem,
                ordem: latestStepElemOrdem + 1,
              },
            ],
          },
        } as SectionConfigModel;
        const index = this.stepModels.indexOf(m);
        const r = [...this.stepModels];
        r[index] = temp;
        // propaga o evento de salvar
        this.onSave.emit({
          metadata: {
            name: "cadastrar-pergunta",
            seq: params.sectionElem.ordem,
            newSectionElementData,
          },
          models: r,
        });
      }
    } else {
      this.notificatorService.showError(
        "Atenção",
        "Falha ao encontrar a seção para salvar esta pergunta"
      );
    }
  }

  // [cadastro-perguntas] perceber alterações
  handleReadyChanges(isReady) {
    if (isReady) {
      this.isBusy = false;
      this.onDone.emit();
    } else {
      this.isBusy = true;
      this.onChange.emit();
    }
  }
}
