<mat-paginator 
  #paginacao 
  (page)="onPagination($event)" 
  [length]="totalPaginas" 
  [pageSize]="tamanhoPagina"
  [pageIndex]="indicePagina"
  >
</mat-paginator>

<!-- <mat-paginator 
  #paginacao 
  (page)="onPagination($event)" 
  [length]="totalPaginas" 
  [pageSize]="tamanhoPagina"
  [pageIndex]="indicePagina" 
  [pageSizeOptions]="[5, 10, 20]">
</mat-paginator> -->
