<div [formGroup]="formGroup">

  <!-- <select 
    placeholder="Pergunta"
    formControlName="pergunta"
    (change)="onPerguntaSelect()">
    <option [ngValue]="null" disabled>Selecione uma pergunta</option>
    <option *ngFor="let pergunta of perguntas" [ngValue]="pergunta.id">
      {{ pergunta.nome }}
    </option>
  </select> -->

  <app-filterable-select-old
    [closeOnSelect]="true"
    (changeSelectedItem)="handleSelectedPergunta($event)"
    [placeholder]="'Selecione uma pergunta'"
    [filterPlaceholder]="'Digite o de uma pergunta aberta'"
    [errorMessage]="'Não há perguntas abertas na pesquisa selecionada'"
    [width]="'500px'"
    [data]="perguntas"
    [selectedCleaner]="selectedCleaner">
  </app-filterable-select-old>

</div>