import { Component, Input, OnInit } from "@angular/core";
import { JWTService } from "app/infraestrutura/security/service/jwtService";
import { PesquisaRequest } from "app/modulos/pesquisa-beta/cadastro/pesquisas-cadastro.model";
import { SelectDataItem } from "../../componentes/filterable-select/filterable-select-component";
import { CreateOperatorOutput } from "../create-operator/create-operator.component";
import {
  Location, LocationsData
} from "../sublocal/sublocal-component";
import {reporterUrl} from '../../infraestrutura/apiLocation';

interface Distribution {
  operator: SelectDataItem;
  locations: Location[];
  selectedLocation: Location;
  selectedLocationIndex: number;
}

@Component({
  selector: "app-distribution",
  templateUrl: "./see-distributions.component.html",
  styleUrls: ["./see-distributions.component.scss"],
})
export class SeeDistribution implements OnInit {

  @Input()
  onCloseModal: () => void;

  @Input() survey: PesquisaRequest;

  // lista de localidades populadas
  @Input()
  locations: LocationsData;
  // lista de operadores
  @Input()
  operators: SelectDataItem[];
  // lista de operadores para visialização de distribuição;
  distribuitionOperators: Distribution[] = [];

  constructor(private jwtService: JWTService) { }
  ngOnInit() {
    // cria distribuição de operadores e localidades
    this.createDistributionOperators();

  }

  createDistributionOperators() {
    if (this.locations) {
      // reseta variavel
      this.distribuitionOperators = [];
      // percorre localidades
      for (let i = 0; i < this.locations.locations.length; i++) {
        const location = this.locations.locations[i];
        // percorre operadores da localidade
        for (let index = 0; index < location.operators.length; index++) {
          const operator = location.operators[index];
          this.createOrAddOperator(operator, location);
          // verifica se tem localidades netas
          if (location.sublocations.length > 0) {
            // percorre localidades netas
            for (
              let sublocationIndex = 0;
              sublocationIndex < location.sublocations.length;
              sublocationIndex++
            ) {
              const sublocation = location.sublocations[sublocationIndex];
              // percorre operadores da sublocalidade
              for (
                let sublocationOperatorIndex = 0;
                sublocationOperatorIndex < sublocation.operators.length;
                sublocationOperatorIndex++
              ) {
                const sublocationOperator =
                  sublocation.operators[sublocationOperatorIndex];
                // transforma em location
                const locationTemp = {
                  id: sublocation.id,
                  label: sublocation.nome,
                  version: sublocation.versao,
                  value: sublocation.value,
                  checked: sublocation.checked,
                  toggleChecked: sublocation.toggleChecked,
                  operators: sublocation.operators,
                } as Location;
                // adiciona
                this.createOrAddOperator(sublocationOperator, locationTemp);
              }
            }
          }
        }
      }
    }
  }

  createOrAddOperator(operator: CreateOperatorOutput, location: Location) {
    const exists = this.distribuitionOperators.find(
      (v) => v.operator.value === operator.id
    );

    if (exists) {
      // const newOperatorDistribution: Distribution;
      const newOperatorDistribution = exists;
      newOperatorDistribution.locations.push(location);
      // atribui a variavel global
      const distribuitionOperatorIndex = this.distribuitionOperators.findIndex(
        (v) => v.operator.value === operator.id
      );
      this.distribuitionOperators[distribuitionOperatorIndex] =
        newOperatorDistribution;
    } else {
      // cria novo operador dentro de ver distrubuição
      const newOperatorDistribution = {
        operator: this.operators.find((v) => v.value === operator.id),
        locations: [location],
        selectedLocation: location,
        selectedLocationIndex: 0,
      };
      this.distribuitionOperators.push(newOperatorDistribution);
    }
  }

  /**
   * Função que recupera o valor total do operador da localidade selecionada
   */
  getDistribuitionOperatorLocationValue(
    selectedLocation: Location,
    operator: SelectDataItem
  ): number {
    return selectedLocation.operators.find((o) => o.id === operator.value)
      .value;
  }

   /**
   * Função que recupera a quantidade de coletas de todas as regiões e retorna de acordo com o operador
   */
  getDistribuitionOperatorTotal(item): number {
    const operatorTotalValue = item.locations.map((i) => {
      const operators = i.operators

      return operators.filter(l => l.id === item.operator.value)
    }).map(operatorArr => operatorArr[0].value).reduce((prev, cur) => prev + cur)

    return operatorTotalValue
  }

  /**
   * Função que muda a localidade selecionada da distribuição
   */
  selectChoosenLocation(
    d: Distribution,
    distribuitionIndex: number,
    moveFoward: boolean
  ) {
    // último item das localidades
    const last = d.locations.length - 1;
    // nova localidade selecionada
    let newSelectedLocationIndex;
    // verifica se ele está nas extremidades do array
    if (d.selectedLocationIndex === 0 || d.selectedLocationIndex === last) {
      if (d.selectedLocationIndex === 0) {
        // caso seja o primeiro
        newSelectedLocationIndex = moveFoward ? 1 : last;
      } else {
        // caso seja o último
        newSelectedLocationIndex = moveFoward ? 0 : last - 1;
      }
    } else {
      // caso ele esteja no meio do array
      newSelectedLocationIndex = moveFoward
        ? d.selectedLocationIndex + 1
        : d.selectedLocationIndex - 1;
    }
    // atualiza
    this.distribuitionOperators[distribuitionIndex] = {
      ...this.distribuitionOperators[distribuitionIndex],
      selectedLocationIndex: newSelectedLocationIndex,
      selectedLocation:
        this.distribuitionOperators[distribuitionIndex].locations[
        newSelectedLocationIndex
        ],
    };

  }

  /**
   * Função responsável por direcionar o usuário para a tela (temporária)
   * de PDF
   */
  // handleDirectUserToTemplatePDF(): void {
  //   const tokenString = this.jwtService.getToken();
  //   const urlLink = `${reporterUrl}/?id=${this.survey.id}&token=${tokenString}`;
  //   window.open(urlLink, "_blank");
  // }

  closeModal() {
    this.onCloseModal();
  }
}
