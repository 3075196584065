import { Injectable } from '@angular/core';
import { TipoPergunta } from '../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';

@Injectable({
  providedIn: 'root',
})
export class TipoPerguntaIconFactoryService {

  private tipoPerguntaIconMapper : Map<string, string>;

  constructor() {
    this.tipoPerguntaIconMapper = new Map();
    this.tipoPerguntaIconMapper.set(TipoPergunta.UNICA, 'fa fa-comment-o');
    this.tipoPerguntaIconMapper.set(TipoPergunta.MULTIPLA, 'fa fa-list');
    this.tipoPerguntaIconMapper.set(TipoPergunta.MULTIPLA_ORDEM_CITACAO, 'fa fa-object-group');
    this.tipoPerguntaIconMapper.set(TipoPergunta.ESCALA_NUMERICA, 'fa fa-sort-numeric-asc');
    this.tipoPerguntaIconMapper.set(TipoPergunta.FOTO, 'fa fa-picture-o');
    this.tipoPerguntaIconMapper.set(TipoPergunta.VIDEO, 'fa fa-video-camera');
    this.tipoPerguntaIconMapper.set(TipoPergunta.GRADE_UNICA, 'fa fa-square-o');
    this.tipoPerguntaIconMapper.set(TipoPergunta.GRADE_MULTIPLA, 'fa fa-clone');
    this.tipoPerguntaIconMapper.set(TipoPergunta.DATA, 'fa fa-calendar-o');
    this.tipoPerguntaIconMapper.set(TipoPergunta.HORARIO, 'fa fa-clock-o');
  }

  getIcon(tipoPergunta : string) : string {
    return this.tipoPerguntaIconMapper.get(tipoPergunta);
  }

}
