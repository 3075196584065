import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { PesquisaCadastroService } from "../cadastro/servico/pesquisa-cadastro.service";

@Component({
  selector: "app-informacoes",
  templateUrl: "./informacoes.component.html",
  styleUrls: ["./informacoes.component.scss"],
})
export class InformacoesComponent implements OnInit {
  pesquisaInfo;

  operadoresDataToVinculos;
  canShow: boolean = false;

  surveyTitle: string

  constructor(
    private route: ActivatedRoute,
    private pesquisaCadastroService: PesquisaCadastroService
  ) { }

  ngOnInit() {
    const idSurvey = this.route.snapshot.queryParams["id"];

    this.pesquisaCadastroService
      .operadoresPorPesquisa(+idSurvey)
      .subscribe((response) => {
        this.operadoresDataToVinculos = response;
      });

    this.pesquisaCadastroService.encontrar(+idSurvey).subscribe((response) => {
      this.surveyTitle = response.descricaoPesquisa.titulo
      this.pesquisaInfo = response;
      this.canShow = true;
    });
  }

  /**
   * função que retorna o caminho da página
   * @returns IItemBreadcrumb[]
   */
  handleGetSurveyTitle(): IItemBreadcrumb[] {
    return [
      {
        itemName: "Pesquisas",
        itemLink: "/pesquisa-beta",
        active: false,
      },
      {
        itemName: this.surveyTitle,
        itemLink: `/pesquisa-beta/informacoes-beta?id=${this.route.snapshot.queryParams["id"]}`,
        active: true,
      },
    ];
  }

  // Retorna a pesquisa completa
  handleGetPesquisaInfo() {
    return this.pesquisaInfo;
  }

  // Retornando apenas o objeto de vinculos
  handleGetPesquisaInfoVinculos() {
    return this.pesquisaInfo.vinculos;
  }

  // Função que retorna para validar se mostra o card de vinculos
  handleCanShowVinculos(): boolean {
    const hasVinculos = this.pesquisaInfo.vinculos;

    return hasVinculos;
  }
}
