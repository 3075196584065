<div [formGroup]="formGroup" class="sg-input-group">
  <div class="container-white">
    <div class="row">
      <div class="col-12 title-step-section">
        <h3>2 de 4: Questionário</h3>
        <span class="error-message" *ngIf="!modificacaoPermitida">
          Você não tem permissão para modificar esse menu
        </span>
      </div>
    </div>
    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12 campo">
        <label for="">Título da pesquisa*</label>
        <input
          mascara-primeiro-digito-sem-espaco
          title="título principal da pesquisa"
          type="text"
          formControlName="titulo"
          (blur)="onTituloChange()"
        />
        <span
          class="error-message"
          [hidden]="!(showErrors && controls.titulo.errors)"
        >
          Deve ser informado um título para a pesquisa de até 255 caracteres
        </span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 campo">
        <label for="">Título Curto da pesquisa*</label>
        <input
          mascara-primeiro-digito-sem-espaco
          title="título curto da pesquisa"
          type="text"
          formControlName="tituloCurto"
          (blur)="onTituloChange()"
        />
        <span
          class="error-message"
          [hidden]="!(showErrors && controls.tituloCurto.errors)"
        >
          Deve ser informado um título curto para a pesquisa de até 100
          caracteres
        </span>
      </div>
      <div class="col-lg-12 col-md-12 col-xs-12 campo">
        <label for="">Texto inicial*</label>
        <input
          mascara-primeiro-digito-sem-espaco
          placeholder="texto inicial"
          title="texto inicial da pesquisa"
          type="text"
          formControlName="textoInicial"
          (blur)="onTituloChange()"
        />
        <span
          class="error-message"
          [hidden]="!(showErrors && controls.textoInicial.errors)"
        >
          Deve ser informado um texto inicial de até 255 caracteres
        </span>
      </div>
    </div>
  </div>
  <div class="row section">
    <div class="col-12 header-section">
      <h3>Cadastre as perguntas e organize sua pesquisa</h3>
      <!-- <div class="actions" *ngIf="modificacaoPermitida">
        <label for=""> <span class="labelMenu">Selecione o que deseja adicionar</span></label>
        <i class="fa fa-plus" (click)="toggleShowQuestionarioSeletorAdicao()"></i>
        <div class="actionsMenu" [hidden]="!showQuestionarioSeletorAdicao">
          <span>Escolha uma das opções</span>
          <app-questionario-seletor-adicao [hidden]="!showQuestionarioSeletorAdicao">
          </app-questionario-seletor-adicao>
        </div>
      </div> -->
    </div>
  </div>

  <div class="row section positionRelative">
    <div class="col-lg-8 interviewContainer" cdkDropListGroup>
      <div class="interviewHeader">Seções e perguntas cadastradas</div>
      <span class="error-message" *ngIf="controls.elementosQuestionario.errors">
        Você deve adicionar pelo menos uma seção
      </span>

      <div
        *ngFor="
          let elementoQuestionario of getControlsFromAbstractFormArray(
            formGroup.get('elementosQuestionario')
          );
          let i = index
        "
      >
        <app-secao [ordem]="i + 1" [showErrors]="showErrors"></app-secao>
      </div>
      <div class="interviewAddSection" *ngIf="modificacaoPermitida">
        <img
          [hidden]="statusAtualPesquisa === 'EXECUCAO'"
          (click)="adicionarSecao()"
          src="assets/images/add.svg"
          alt=""
        />
        <span
          [hidden]="statusAtualPesquisa === 'EXECUCAO'"
          (click)="adicionarSecao()"
          >Adicionar seção</span
        >
        {{ statusAtualPesquisa }}
      </div>
      <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="onDropSecao($event)"> -->

      <!-- </div> -->
    </div>
    <div class="col-lg-4 positionSticky">
      <app-complex-input-factory
        *ngIf="itemSelecionado.item && itemSelecionado.tipoConteudo"
        [tipoInput]="itemSelecionado.tipoConteudo"
        [params]="conteudoComponentParams"
        [reRenderPublisher]=""
      ></app-complex-input-factory>
    </div>
  </div>
</div>
