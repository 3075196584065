import { Localidade } from './localidade';

function matches(first: string, second: string) {
  return removeAccents(first.toLowerCase()).includes(second);
}

/**
 * filtra os perfis passados, setando hidden = true os perfis que não possui caracteristicas
 * que atendem à query passada. Um perfil atende a uma argumento de busca quando
 * pelo menos uma de suas caracteristicas possui uma alternativa cujo nome inclui (includes
 * ou contains) a query passada, ignorando case e acentos.
 *
 * @param query criterio de busca
 * @param perfis perfis que serão filtrados
 */
function filter(query: string, localidades: Localidade[]): Localidade[] {

  const lowerCaseQueryWithoutAccents = removeAccents(query.toLowerCase());
  console.log('[SearchService] lowerCaseQueryWithoutAccents: ', lowerCaseQueryWithoutAccents);
  console.log('[SearchService] localidades: ', localidades);

  const localidadesMatchingIds = localidades.filter((localidade: Localidade) => {

    const localidadeStr = [
      ...localidade.parents.map(localidade => localidade.nome),
      localidade.nome,
    ]
    .reduce((reduced, next) => reduced.concat(' ').concat(next));

    const matching = matches(localidadeStr, lowerCaseQueryWithoutAccents);

    console.log(`[SearchService] ${localidadeStr} ${matching ? 'matches' : 'doesnt match'} ${lowerCaseQueryWithoutAccents}`);
    return matching;
  })
  .map((localidade) => {
    return localidade.id;
  });

  if (localidadesMatchingIds.length > 0) {

    return localidades.map((localidade) => {
      if (localidadesMatchingIds.includes(localidade.id)) {
        localidade.hidden = false;
      } else {
        localidade.hidden = true;
      }

      /**
       * resetando hidden dos operadores
       */
      localidade.operadoresVinculados.forEach((operador) => {
        operador.hidden = false;
      });

      return localidade;
    });
  }

  localidades.forEach((localidade) => {
    localidade.hidden = false;
  });

  localidades.forEach((localidade) => {

    localidade.operadoresVinculados = localidade.operadoresVinculados
      .map((operador) => {

        const operadorMatching = matches(operador.nome, lowerCaseQueryWithoutAccents);

        if (operadorMatching) {
          operador.hidden = false;
        } else {
          operador.hidden = true;
        }

        return operador;

      });
  });

  return localidades;
}

function removeAccents(text: string): string {
  return text.replace(/[áàãâä]/gi, 'a')
    .replace(/[éè¨ê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/[ç]/gi, 'c')
    .replace(/[ñ]/gi, 'n')
    .replace(/[^a-zA-Z0-9]/g, ' ');
}

const localidadesSearchService = {
  filter,
};

export default localidadesSearchService;
