<table class="table-localidades">
  <thead>
    <tr>
      <th scope="col">
        <input
          type="checkbox"
          name="Checkbox"
          [checked]="mainCheck"
          (input)="handleSetAllCheck($event)"
        />
      </th>
      <th scope="col" class="emptyThead"></th>
      <th scope="col" class="localidadeThead">Localidade</th>
      <th scope="col">Localidades Filhas</th>
      <th scope="col">Editar</th>
      <th scope="col">Excluir</th>
    </tr>
  </thead>
  <ng-container *ngIf="tableData.length == 0">
    <tbody>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
          Não encontramos na nossa base de dados a localidade que você procurou.
        </td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </ng-container>
  <tbody *ngFor="let line of tableData; let i = index">
    <tr>
      <td data-label="Checkbox" title="Selecionar">
        <input
          type="checkbox"
          [checked]="line.checked"
          (input)="handleSetCheck(i)"
        />
      </td>
      <td title="Filhas">
        <i
          [ngClass]="
            line.isOpen && line.quantidadeFilhos > 0
              ? 'fas fa-caret-down'
              : 'fas fa-caret-right'
          "
          *ngIf="line.quantidadeFilhos > 0"
          (click)="handleToShowFilhas(i)"
        ></i>
      </td>
      <td
        data-label="Localidade"
        class="first-description-column"
        title="Localidade"
      >
        {{ line.nome }}
      </td>
      <td title="Localidades Filhas">{{ line.quantidadeFilhos }}</td>
      <td title="Editar">
        <button
          type="button"
          title="Visualizar/editar"
          (click)="handleOpenLocalidadePai(line.id)"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
      </td>
      <td title="Excluir">
        <button
          type="button"
          title="Excluir"
          (click)="handleDeleteLocalidadePai(line.id)"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </td>
    </tr>
    <ng-container
      *ngFor="let filha of line.localidadesFilhas; let filhaId = index"
    >
      <ng-container *ngIf="line.quantidadeFilhos > 0 && line.isOpen">
        <tr>
          <td data-label="Checkbox">
            <input
              type="checkbox"
              [checked]="filha.checked"
              (input)="handleSetFilhaCheck(i, filhaId)"
            />
          </td>
          <td></td>
          <td
            class="localidadeFilhaNome"
            (click)="handleOpenEditLocalidadeFilha(line.id, filha.id, filhaId)"
          >
            <img src="../../../../../assets/images/icon-setaabaixo.svg" />
            <span>{{ filha.nome }}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
