import { Injectable } from '@angular/core';
import { RequestService } from 'app/servico/request.service';
import { ProdutividadeFiltro } from '../produtividadeFiltro';
import { Observable } from 'rxjs';
import { Produtividade } from '../model/produtividade';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { map } from 'rxjs/operators';
import produtividadeMocked from './produtividadeMocked';

@Injectable({
  providedIn: 'root',
})
export class ProdutividadeService {

  constructor(private requestService: RequestService) { }

  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  cleanNulls(filtro: ProdutividadeFiltro): ProdutividadeFiltro {
    this.clean(filtro);
    return filtro;
  }

  getEstatisticas(filtro: ProdutividadeFiltro): Observable<Produtividade> {

    const filtroWithoutEmptyProperties = this.cleanNulls(filtro);

    const requestUrl = `${apiLocation}/produtividade`;

    return this.requestService.get(requestUrl, null, true, filtroWithoutEmptyProperties).pipe(
      map((produtividade: Produtividade) => {

        // const produtividade: any = produtividadeMocked.data();

        const estatisticasEntrevista = produtividade.estatisticasEntrevista;

        // tslint:disable-next-line: max-line-length
        estatisticasEntrevista.entrevistasRealizadas = estatisticasEntrevista.entrevistasRealizadas ? estatisticasEntrevista.entrevistasRealizadas : 0;
        // tslint:disable-next-line: max-line-length
        estatisticasEntrevista.entrevistasAuditadas = estatisticasEntrevista.entrevistasAuditadas ? estatisticasEntrevista.entrevistasAuditadas : 0;
        // tslint:disable-next-line: max-line-length
        estatisticasEntrevista.entrevistasRejeitadas = estatisticasEntrevista.entrevistasRejeitadas ? estatisticasEntrevista.entrevistasRejeitadas : 0;
        // tslint:disable-next-line: max-line-length
        estatisticasEntrevista.entrevistasAuditadas = estatisticasEntrevista.entrevistasAuditadas ? estatisticasEntrevista.entrevistasAuditadas : 0;
        // tslint:disable-next-line: max-line-length
        estatisticasEntrevista.entrevistasAprovadas = estatisticasEntrevista.entrevistasAprovadas ? estatisticasEntrevista.entrevistasAprovadas : 0;

        return produtividade;
      }),
    );
  }

}
