<div *ngIf="isVisible" class="content_results">
  <div class="container">
    <div *ngIf="researchData.cliente.length > 0" class="results" (click)="handleClientsClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Clientes
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <div *ngIf="researchData.operador.length > 0" class="results" (click)="handleOperatorClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Operadores
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <div *ngIf="researchData.funcionario.length > 0" class="results" (click)="handleEmployeesClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Funcionarios
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <div *ngIf="researchData.localidade.length > 0" class="results" (click)="handleLocationClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Localidades
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <div *ngIf="researchData.grupo.length > 0" class="results" (click)="handleGroupClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Grupo
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <div *ngIf="researchData.instancia.length > 0 && userIsAdm()" class="results" (click)="handleContratanteClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Contratante
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <div *ngIf="researchData.pesquisa.length > 0" class="results" (click)="handleResearchsClick()">
      <div class="container-info">
        <span class="search">
          {{researchTitle }}
        </span> relacionado em:
        <span class="tags">
          Pesquisas
        </span>
      </div>
      <div class="access">
        Acessar
        <img src="../../../assets/images/arrowRightResearch.svg">
      </div>
    </div>
    <button type="button" class="see-all-results" (click)="handleSeeAllResults()">
      <img src="../../../assets/images/goButton.svg" alt="">
      Ver todos os resultados <span> ({{searchAmout}}) </span>
    </button>
  </div>

</div>
