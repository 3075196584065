import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { LgpdService } from "app/servico/lgpdService/lgpd.service";
import { ModalData } from "app/componentes/modal/modal";
import { IPromptTexts, promptTexts } from "./constants/prompt";
import { ModalService } from "app/componentes/modal/modal.service";
import { BehaviorSubject, Subscription } from "rxjs";

import { IDocumentInfo } from "app/servico/lgpdService/lgpd-interface";
import { ChangePasswordService } from "app/componentes/change-password/change-password.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { lgpdErrors } from "./constants/errors";
import { cancelPromptTexts } from "./constants/promptTexts";

interface DocumentState {
  PRIVACY_POLICY: IDocumentInfo;
  TERMS_OF_USAGE: IDocumentInfo;
}

@Injectable({
  providedIn: "root",
})
export class LgpdSignatureService {
  // Variável de controle de visibilidade da modal de assinatura.
  public userNeedsSignDocument: boolean = false;
  // flag indicadora da necessidade de alteração de senha do usuário
  private userNeedsChangePassword: boolean = true;
  // Dados de controle da modal de assinatura
  private promptData: ModalData = new ModalData();
  //
  private cancelPromptData: ModalData = new ModalData();
  // armazena o estado dos documentos lgpd recuperados.
  private state: DocumentState = {
    PRIVACY_POLICY: null,
    TERMS_OF_USAGE: null,
  };

  //
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private securityService: SecurityService,
    private lgpdService: LgpdService,
    private modalService: ModalService,
    private changePasswordService: ChangePasswordService,
    private notificationService: NotificatorService
  ) {
    this.handleNeedChangeSignDocumentSubscription();
    this.handleNeedChangePasswordSubscription();
  }

  // behaviourSubject: Cria um subject de controle do estado da variavel de assinatura
  public needSignDocument: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  // Atualiza o valor do subject
  public setNeedSignDocument(v: boolean) {
    this.needSignDocument.next(v);
  }

  // reseta toda a configuração definida, será chamado quando o usuário já tiver assinado os documentos.
  public dispose() {
    this.state.PRIVACY_POLICY = null;
    this.state.TERMS_OF_USAGE = null;

    this.modalService.handleCloseModal();

    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.length = 0;
  }

  /**
   * Inicializa todo o controle do processo de assinatura dos
   * documentos.
   */
  private init() {
    if (this.userNeedsSignDocument) {
      this.getToSignDocuments();
    } else {
      this.dispose();
    }
  }

  // Inscreve-se no evento de mudança da flag "need_sign_document"
  // advinda da lógica interna a app.component.ts
  private handleNeedChangeSignDocumentSubscription() {
    const subscription = this.needSignDocument.subscribe(
      (needSignDocument: boolean) => {
        if (this.userNeedsSignDocument !== needSignDocument) {
          this.userNeedsSignDocument = needSignDocument;
          this.init();
        }
      }
    );

    this.subscriptions.push(subscription);
  }

  // Inscreve-se no evento de mudança da flag "need_change_password"
  // advinda da lógica interna a app.component.ts
  private handleNeedChangePasswordSubscription() {
    const subscription = this.changePasswordService.changePassword.subscribe({
      next: (needChangePass: boolean) => {
        this.userNeedsChangePassword = needChangePass;
        this.initPrompt();
      },
    });

    this.subscriptions.push(subscription);
  }

  // Persiste os documentos de retorno da requisição "getLgpdDocuments" ao estado
  // interno do serviço "state".
  private persistDocuments(lgpdDocuments: IDocumentInfo[]) {
    for (const document of lgpdDocuments) {
      if (this.state.hasOwnProperty(document.type)) {
        this.state[document.type] = { ...document } as IDocumentInfo;
      }
    }
  }

  /**
   * Retorna uma flag indicando se a modal de assinatura está pronta
   * para ser exibida. São basicamente três verificações:
   *
   * userNeedsChangePassword deve ser falso, indicando que se o usuário ainda precisa redifinir
   * a senha pela primeira vez, a modal não deve ser exibida.
   *
   * userNeedsSignDocument deve ser true (intuitivo)
   *
   * modalService.isOpened deve ser false, pois, se ela já estava aberta anteriormente, não
   * faz sentido requisitarmos sua abertura novamente.
   */
  private get promptIsReady(): boolean {
    return (
      !this.userNeedsChangePassword &&
      this.userNeedsSignDocument &&
      !this.modalService.isOpened
    );
  }

  // negativeCallback da modal de assinatura
  private onCancel() {
    this.dispose();
    this.logout();
  }

  // positiveCallback da modal de assinatura
  private onConfirm() {
    this.signDocuments();
  }

  // Resolve quais textos serão usados na modal de assinatura
  private resolvePromptTexts(): { default: IPromptTexts, cancel: IPromptTexts } {
    const documents = [this.state.PRIVACY_POLICY, this.state.TERMS_OF_USAGE]
      .filter((v) => !!v)
      .map((document) => document);

    if (documents.length === 2) {
      return {
        default: {
          ...promptTexts.default,
          body: promptTexts.default.body(
            documents[0].location,
            documents[1].location
          ),
        },
        cancel: {
          ...cancelPromptTexts.default,
          body: cancelPromptTexts.default.body(documents[0].location, documents[1].location),
        }
      }
    }

    const lgpdDocument = documents.pop();

    return {
      default: {
        ...promptTexts[lgpdDocument.type],
        body: promptTexts[lgpdDocument.type].body(lgpdDocument.location),
      },
      cancel: {
        ...cancelPromptTexts[`${lgpdDocument.type}`],
        body: cancelPromptTexts[`${lgpdDocument.type}`].body(lgpdDocument.location),
      }
    };
  }

  private switchPromptToRejectMode(promptTexts: { default: IPromptTexts, cancel: IPromptTexts }) {
    this.cancelPromptData.icon = 'fa-regular fa-file-lines';

    this.cancelPromptData.title = promptTexts.cancel.title;
    this.cancelPromptData.messageModal = promptTexts.cancel.body;

    this.cancelPromptData.btnTitlePositive = 'Rejeitar';
    this.cancelPromptData.btnTitleNegative = 'Cancelar';

    this.cancelPromptData.positiveCallback = this.onCancel.bind(this);
    this.cancelPromptData.closeFromHeaderCallback = this.onCancel.bind(this);

    this.cancelPromptData.negativeCallback = this.switchPromptToDefaultMode.bind(this, promptTexts);

    this.modalService.showModal(this.cancelPromptData);
  }

  private switchPromptToDefaultMode(promptTexts: { default: IPromptTexts, cancel: IPromptTexts }) {
    this.promptData.icon = 'fa-regular fa-file-lines';
    this.promptData.close = false;

    this.promptData.title = promptTexts.default.title;
    this.promptData.messageModal = promptTexts.default.body;

    this.promptData.btnTitlePositive = 'Concordo';
    this.promptData.btnTitleNegative = 'Discordo';

    this.promptData.positiveCallback = this.onConfirm.bind(this);

    this.promptData.negativeCallback = this.switchPromptToRejectMode.bind(this, promptTexts);
    this.promptData.closeFromHeaderCallback = this.switchPromptToRejectMode.bind(this, promptTexts);

    this.modalService.showModal(this.promptData);
  }

  // Inicializa as variáveis da modal de assinatura e requisita sua abertura.
  private initPrompt() {
    if (this.promptIsReady) {
      const promptText = this.resolvePromptTexts();

      // default settings
      this.switchPromptToDefaultMode(promptText);
    }
  }

  // Realiza a requisição de assinatura de um ou mais documentos LGPD.
  private signDocuments() {
    this.modalService.setLoading(true);

    const documentIds = [
      this.state.PRIVACY_POLICY?.id,
      this.state.TERMS_OF_USAGE?.id,
    ].filter((v) => typeof v === "number");

    const onSuccess = () => {
      this.modalService.setLoading(false);
      this.dispose();
      this.setNeedSignDocument(false);
    };

    const onError = () => {
      this.notificationService.showError(
        lgpdErrors.signatures.title,
        lgpdErrors.signatures.message
      );

      this.modalService.setLoading(false);
    };

    const subscription = this.lgpdService.signDocuments(documentIds).subscribe({
      next: onSuccess,
      error: onError,
    });

    this.subscriptions.push(subscription);
  }

  // Recupera os documentos LGPD pendentes de assinatura.
  private getToSignDocuments() {
    if (this.userNeedsSignDocument) {
      const handleClientError = () => {
        this.notificationService.showError(
          lgpdErrors.getDocuments.clientError.title,
          lgpdErrors.getDocuments.clientError.message,
        );

        // Retornamos a mesma função getToSignDocuments de forma recursiva, por quê?
        // Segundo a regra, caso o erro seja de ordem 4xx (client error), a requisição
        // deve ser realizada novamente em segundo plano.
        return window.setTimeout(this.getToSignDocuments.bind(this), 200);
      };

      // Em caso de erro interno do servidor, o usuário deve ser deslogado.
      const handleServerError = () => {
        this.dispose();
        this.logout();

        this.notificationService.showError(
          lgpdErrors.getDocuments.serverError.title,
          lgpdErrors.getDocuments.serverError.message,
        );
      };

      const onError = (error) => {
        if (error.status >= 400 && error.status < 500) {
          handleClientError();
        } else if (error.status <= 504) {
          handleServerError();
        }
      };

      const onSuccess = (response: IDocumentInfo[]) => {
        if (response) {
          this.persistDocuments(response);
          this.initPrompt();
        }
      };

      const subscription = this.lgpdService.getAuthenticatedUserLgpdDocuments().subscribe({
        next: onSuccess,
        error: onError,
      });

      this.subscriptions.push(subscription);
    }
  }

  // caso o usuário clique no botão cancelar
  private logout() {
    this.securityService.logout();
    this.router.navigate(["login"], { queryParams: { redirect: true } });
  }
}
