<div [formGroup]="formGroup">


  <div class="sectionInterview">
    <div class="iconSection">
      <span class="fa fa-flag-o"></span>
    </div>
    <div class="infoSection">
      <span>SEÇÃO - {{ordem}}</span>
      <h3>{{controls.nome.value ? controls.nome.value : 'Nome da seção*'}}
        <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
          A seção deve possuir um nome de até 30 caracteres
        </span>
      </h3>
      <p>
        {{controls.descricao.value ? controls.descricao.value : 'Descrição*'}}
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
          A seção deve possuir uma descrição de até 150 caracteres
        </span>
      </p>
    </div>
  </div>

  <!-- (cdkDropListDropped) -->
  <div>

      <div [ngClass]="showErrors && elementoSecao.invalid ? 'possui-erros' : ''"
      *ngFor="let elementoSecao of getControlsFromAbstractFormArray(formGroup.get('elementosSecao')); let ordem = index">

      <app-pergunta [elementoSecao]="elementoSecao">
      </app-pergunta>
    </div>

  </div>

</div>
