import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { IAlternative } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";

@Component({
  selector: "app-alternativa-aberta-numero",
  templateUrl: "./aberta-numero.component.html",
  styleUrls: ["./aberta-numero.component.scss"],
})
export class AlternativaAbertaNumeroComponent implements OnInit {
  @Input() alternativa = {} as IAlternative;
  @Input() value: any = null;
  @Input() isChecked: boolean = false;
  @Input() selecionavel: boolean = true;

  @Output() onChange = new EventEmitter<{ id: number; value: number }>(null);
  @Output() onCheck = new EventEmitter<{ id: number; value: number }>(null);

  @ViewChild("numberInput") numberInputRef: ElementRef<HTMLElement> = null;

  private maxLength: number = 9;

  constructor() {}

  ngOnInit(): void {}

  public get valueToString() {
    if (isNaN(this.value) || this.value === null) {
      return ""
    }
    return String(this.value);
  }

  private parseToNumber(n: any) {
    if (isNaN(n) || typeof n === 'string' && !n.length) {
      return null;
    }

    return parseFloat(n);
  }

  private checkInputConsistence() {
    if (this.valueToString.search(/[eE]/)) {
      this.value = this.parseToNumber(this.valueToString.replace(/[eE]/g, ""));
    }

    if (this.valueToString.length > this.maxLength) {
      this.value = this.parseToNumber(this.valueToString.slice(0, 8));
    }
  }

  public handleInput($event) {
    this.checkInputConsistence();
    this.onChange.emit({ id: this.alternativa.id, value: this.value });
  }

  public handleFocus() {
    this.onCheck.emit({ id: this.alternativa.id, value: this.value });
  }
  
}
