<div class="tipoRespostaContainer" [formGroup]="formGroup" *ngIf="!enumsToIgnore.includes(formGroup.controls.tipoAlternativa.value)">
  <div class="">
    <select formControlName="tipoAlternativa" >
      <option [ngValue]="null" disabled> Selecione </option>
      <option *ngFor="let tipo of tiposResposta" [ngValue]="tipo.key">{{tipo.value}}</option>
    </select>
    <!-- <span class="error-message" [hidden]="!(showErrors && formGroup.controls.tipoAlternativa.errors)">
      O tipo da alternativa deve ser selecionado
    </span> -->
  </div>
</div>
