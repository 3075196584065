<h4 class="result-list-title">Total de respostas: {{answer.length}}</h4>
<div class="result-list-container" [style.height.px]="showModal ? 355 : 232">
  <div *ngFor="let result of answer" class="result-line-container">
    <div class="result-line">
      <div class="result-line-data" *ngIf="renderLineData">
        <span class="result-line-numeric">Citações:
          <label [ngStyle]="{'padding-left': countCharacters(result['resultado_numerico'])}">{{result['resultado_numerico']}}</label>
        </span>
        <label class="result-line-percentual">{{result['resultado_percentual']}}%</label>
      </div>
      {{ result['descricao'] }}
    </div>
  </div>
</div>
