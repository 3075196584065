<!-- BIBLIOTECA DOS GRAFICOS -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.3/Chart.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-annotation/0.5.5/chartjs-plugin-annotation.min.js"></script>


    <canvas #chart ngChartjs id="testChart"
    [datasets]="chartConfiguration.datasets"
    [labels]="chartConfiguration.labels"
    [colors]="chartConfiguration.colors"
    [options]="chartConfiguration.options"
    [inlinePlugins]="chartConfiguration.inlinePlugin"
    [legend]="chartConfiguration.legend"
    [chartType]="chartConfiguration.type"></canvas>

