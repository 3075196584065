import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ListagemGenerica } from "../../listagem/listagemGenerica";
import { Cliente } from "../cliente";
import { ClienteService } from "../servico/cliente.service";

@Component({
  selector: "app-cliente-listagem",
  templateUrl: "./cliente.listagem.component.html",
  styleUrls: ["./cliente.listagem.component.scss"],
})
// tslint:disable-next-line:max-line-length
export class ClienteListagemComponent
  extends ListagemGenerica<Cliente>
  implements OnDestroy, OnInit
{
  nomeAtributosTabela = ["nome", "cpfCnjp", "login"];
  nomeColunasTabela = ["Id", "Nome", "CPF/CNPJ", "Email de acesso"];
  placeholder: string = "Ex.: joão";

  /**
   * Permissões necessárias para realizar a remoção do item
   * da tabela
   */
  clienteRemovePermissions = [Authority.REMOVER_CLIENTE];

  initialSearchValue: string;

  // tslint:disable-next-line: max-line-length
  constructor(
    protected securityService: SecurityService,
    public clienteService: ClienteService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: ModalService
  ) {
    super(securityService, "cliente", clienteService, router, modalService);
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get("search")) {
      this.initialSearchValue = this.route.snapshot.paramMap.get("search");
    }
  }

  /**
   * @override implementando comportamento de quando a exclusão solicitada
   * foi realizada de maneira incompleta e por convenção, retorna as entidades cuja
   * remoção não foi realizada.
   * @param resposta entidades NÃO removidas.
   */
  manipularExclusaoIncompleta(clientesNaoRemovidos: any[]) {
    const refreshCallback = () => {
      this.router.navigate([this.nomeEntidade]);
    };

    // tslint:disable-next-line: max-line-length
    const listaHTMLClientesNaoRemovidos = clientesNaoRemovidos
      .map((clienteNaoRemovido) => `<li>${clienteNaoRemovido.pessoa.nome}</li>`)
      .reduce((prev, current) => `${prev} ${current}`);

    this.modalService.showModal({
      title: `Excluir ${this.nomeEntidade}`,
      messageModal: `Alguns registros não puderam ser excluídos pois já estão vinculados à pesquisas: <br> <ul>${listaHTMLClientesNaoRemovidos}<ul>`,
      btnTitlePositive: "Entendi",
      icon: "fa-light fa-circle-info",
      isOnlyConfirmation: true,
      positiveCallback: () => refreshCallback,
    });

    this.isLoading = false;
  }
}
