import { ValidatorFn, UntypedFormControl } from '@angular/forms';
/**
 * validador que invalida um campo caso ele não seja maior que um determinado
 * número constante.
 */
export default (min: number): ValidatorFn => {

  return (control: UntypedFormControl): { menorQueValorMinimo: boolean; valorMinimo: number; } | null => {

    const val: string = control.value;

    const formattedNum = val.replace(',', '.');

    if (Number(formattedNum) > min) {
      return null;
    }

    return { menorQueValorMinimo: true, valorMinimo: min };
  };
};
