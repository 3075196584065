import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputOptions, SubLocation } from '../sublocal/sublocal-component';

export interface ChildrenLocationOperatorOutput {
  sublocation: SubLocation;
  index: number;
}

@Component({
  selector: 'app-children-location-operator',
  templateUrl: './children-location-operator.component.html',
  styleUrls: ['./children-location-operator.component.scss']
})
export class ChildrenLocationOperatorComponent implements OnInit {
  @Input()
  data: SubLocation[];

  @Output()
  output: EventEmitter<ChildrenLocationOperatorOutput> = new EventEmitter();

  inputOptions: Array<InputOptions> = [];

  ngOnInit() {
    this.data.map(({ value }: SubLocation) => {
      value = Number(value);
      let valueLength = value ? `${value}`.length : 0;
      valueLength = valueLength > 0 ? valueLength : 15;
      this.inputOptions.push({
        displayValue: Number(value.toFixed(2)),
        realValue: value,
        width: valueLength,
      });
    });
  }

  getInputValueLength(value: number): number {
    return Number(`${value}`.length === 0 ? 15 : `${value}`.length);
  }

  handleClick(data: SubLocation, index: number) {
    this.output.emit({
      sublocation: data,
      index: index
    });
  }

}
