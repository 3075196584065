<!-- <div [formGroup]="alternativasSelecionadasFormGroup">

  <div formArrayName="alternativasSelecionadas">

    <div *ngFor="let alternativa of alternativasSelecionadas.controls">

      <div [formGroup]="alternativa">

        <input
          type="checkbox"
          (change)="onAlternativaChange($event, alternativa.value.hash)"
          [formControl]="alternativa.get('selecionada')" />

          <span *ngIf="alternativa.value.esquema.tipoAlternativa === 'FECHADA'">
            {{ alternativa.controls.esquema.value.descricao }}
          </span>

          <span *ngIf="alternativa.value.esquema.tipoAlternativa === 'ABERTA_TEXTO'" >
            <input type="text" placeholder="Insira a resposta aberta"
              [formControl]="alternativa.controls.respostaAberta"
              name="respostaAberta" />
          </span>


        </div>
      <br>

    </div>
    <span class="error-message" *ngIf="hasErrors()">
      Você deve selecionar entre {{ pergunta.qtdMinimaSelecionada }} e {{ pergunta.qtdMaximaSelecionada }} alternativas.
    </span>

  </div>

</div> -->
<div [formGroup]="alternativasSelecionadasFormGroup">
  <div formArrayName="alternativasSelecionadas" class="checks sg-input-group">

    <div class="checkItem" *ngFor="let alternativa of alternativasSelecionadas.controls; let i = index">
      <div [formGroup]="alternativa">

        <!-- <mat-checkbox color="primary" (change)="onAlternativaChangeMaterial($event, alternativa.value.hash)"
        [formControl]="alternativa.get('selecionada')">
          <span *ngIf="alternativa.value.esquema.tipoAlternativa === 'FECHADA'">
            {{ alternativa.controls.esquema.value.descricao }}
          </span>
          <span *ngIf="alternativa.value.esquema.tipoAlternativa === 'ABERTA_TEXTO' || alternativa.value.esquema.tipoAlternativa === 'ABERTA_NUMERO'">
            {{ alternativa.controls.esquema.value.descricao }}
          </span>
        </mat-checkbox> -->

        <div class="sg-checkbox-full">
          <input type="checkbox" [formControl]="alternativa.get('selecionada')"
            (change)="onAlternativaChangeMaterial($event, alternativa.value.hash)" [id]="alternativa.value.hash" />

          <label [for]="alternativa.value.hash"
            *ngIf="alternativa.value.esquema.tipoAlternativa === 'FECHADA' || alternativa.value.esquema.tipoAlternativa === 'ABERTA_TEXTO' || alternativa.value.esquema.tipoAlternativa === 'ABERTA_NUMERO'">
            {{ alternativa.controls.esquema.value.descricao }}
          </label>
        </div>

        <div class="campo"
          *ngIf="alternativa.value.esquema.tipoAlternativa === 'ABERTA_TEXTO' && alternativa.get('selecionada').value">
          <input type="text" placeholder="Insira a resposta aberta" [formControl]="alternativa.controls.respostaAberta"
            name="respostaAberta">
          <span class="error-message" *ngIf="alternativa.errors?.respostaAbertaVazia">
            Uma alternativa do tipo resposta aberta está marcada. Você deve preencher o campo textual.
          </span>
        </div>

        <div class="campo"
          *ngIf="alternativa.value.esquema.tipoAlternativa === 'ABERTA_NUMERO' && alternativa.get('selecionada').value">
          <input type="number" placeholder="Insira a resposta aberta" [formControl]="" name="respostaAberta">
          <span class="error-message" *ngIf="alternativa.errors?.respostaAbertaVazia">
            Uma alternativa do tipo resposta aberta está marcada. Você deve preencher o campo textual.
          </span>
        </div>

      </div>
    </div>

    <span class="error-message"
      *ngIf="alternativasSelecionadasFormGroup.get('selected').errors?.maiorQueDeveria || alternativasSelecionadasFormGroup.get('selected').errors?.menorQueDeveria">
      Você deve selecionar entre {{ pergunta.qtdMinimaSelecionada }} e {{ pergunta.qtdMaximaSelecionada }} alternativas.
    </span>
  </div>
</div>