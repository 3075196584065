import { IResumoAuditoria } from "app/modulos/auditoria-beta/interfaces";

// recebe o resumo de uma auditoria e retorna as aprovadas e reprovadas
export function getAuditResum(resumoAuditoria: IResumoAuditoria[]) {
  const result = {
    approved: 0,
    refused: 0
  }
  resumoAuditoria.forEach(auditValue => {
    if (auditValue.situacao === "APROVADA") {
      result.approved = auditValue.quantidade_coletas;
    } else if (auditValue.situacao === "REPROVADA") {
      result.refused = auditValue.quantidade_coletas;
    }
  })
  return result
}

// retorna a porcentagem de coletas realizadas
export function getCollectionsPercentage(efetuadas: number, contratadas: number): number {
  return (efetuadas / contratadas) * 100
}
