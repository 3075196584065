import { Component, Input } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TituloGrade } from "../../resposta-pergunta-listagem-tabela/model/tituloGrade";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-grade-unica",
  templateUrl: "./grade-unica.component.html",
  styleUrls: ["./grade-unica.component.scss"],
})
export class RespostaGradeUnicaComponent extends RespostaComponent {
  @Input() private respostaPergunta: RespostaPergunta;
  @Input() pergunta: PerguntaEsquema;
  @Input() private alternativasSelecionadas: UntypedFormArray;

  gradesAlternativas: UntypedFormGroup;

  ngOnInit() {
    super.ngOnInit();

    this.gradesAlternativas = this.toAlternativasSelecionadasGrade(
      this.pergunta.titulosGrades
    );

    this.gradesAlternativas.valueChanges.subscribe((val) => {});
  }

  /**
   * criando form group que representa a grade única.
   * estrutura:
   * gradesAlternativas: {
   *    { idTituloGrade}: FormControl([idAlternativa1, idAlternativa2]) }
   * }
   * @param titulosGrade
   */
  private toAlternativasSelecionadasGrade(
    titulosGrade: TituloGrade[]
  ): UntypedFormGroup {
    const controls = titulosGrade
      .map((t) => {
        let controls = {};

        const idsAlternativas: string[] = this.getIdAlternativasByIdTituloGrade(
          t.id
        );
        const idAlternativa =
          idsAlternativas.length > 0 ? idsAlternativas[0] : "";

        controls[t.id] = new UntypedFormControl(idAlternativa);

        return controls;
      })
      .reduce((prev, next) => ({ ...prev, ...next }));

    const alternativasSelecionadasGrade = new UntypedFormGroup({
      ...controls,
    });

    return alternativasSelecionadasGrade;
  }

  private getIdAlternativasByIdTituloGrade(idTituloGrade: number): string[] {
    return this.alternativasSelecionadas.controls
      .filter((fg) => fg.value.idTituloGrade === idTituloGrade)
      .map((fg: UntypedFormGroup) => {
        return fg.value.idAlternativa.toString();
      });
  }

  hasErrors() {
    return false;
  }

  /**
   * @override
   * Sobrescrevendo método de RespostaComponent,
   * com o comportamento especializado deste componente
   * durante o disparo da ação "Próxima Pergunta"
   */
  onProximaPergunta() {
    const idsTitulosGrades: string[] = Object.keys(
      this.gradesAlternativas.value
    );

    const alternativasSelecionadas: AlternativaSelecionada[] =
      idsTitulosGrades.map((idTituloGrade: string) => {
        const idAlternativa: string =
          this.gradesAlternativas.value[idTituloGrade];

        return <AlternativaSelecionada>{
          idTituloGrade: Number(idTituloGrade),
          idAlternativa: Number(idAlternativa),
          tipo: TipoRespostaPergunta.GRADE,
        };
      });

    const respostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativasSelecionadas,
      this.respostaPergunta.num
    );

    this.notifyRespostaPergunta(respostaPergunta);
  }
}
