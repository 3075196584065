import { Endereco } from './endereco';

export class Pessoa {

  id: number;
  nome: string;
  cpf: string;
  rg: string;
  cnpj: string;
  inscricaoEstadual: string;
  site: string;
  responsavel: string;
  tipoPessoa: string;
  endereco: Endereco;

  constructor() {
    this.endereco = new Endereco();
  }

}
