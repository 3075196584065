import { IAlternativeTitle, ISettings } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { PerguntaQuestionarioAuditadoManager } from "..";

/**
 * Representa as definições de uma pergunta no módulo
 * do questionário auditado, assim como encapsula
 * a lógica de inicialização necessária de definição
 * com base na pergunta;
 */
export class DefinicoesPerguntaProcessor {
  constructor(managerContext: PerguntaQuestionarioAuditadoManager) {
    this.context = managerContext;

    this.inicializar();
    this.processar();
  }

  private context: PerguntaQuestionarioAuditadoManager;

  private maximo: number = 0;
  private minimo: number = 0;
  private tituloAlternativas: IAlternativeTitle[];

  public getMaximo() {
    return this.maximo;
  }

  public getMinimo() {
    return this.minimo;
  }

  public getTituloAlternativas() {
    return this.tituloAlternativas;
  }

  public setMaximo(v: number) {
    this.maximo = v;
  }

  public setMinimo(v: number) {
    this.minimo = v;
  }

  public setTituloAlternativas(v: IAlternativeTitle[]) {
    this.tituloAlternativas = v;
  }

  private inicializar() {
    const { definicoes } = this.context.getDadosPergunta();

    if (typeof definicoes === "object") {
      const { numero_maximo_respostas, numero_minimo_respostas, titulo_alternativas } = definicoes;

      this.setMaximo(numero_maximo_respostas);
      this.setMinimo(numero_minimo_respostas);
      this.setTituloAlternativas(titulo_alternativas);
    }
  }

  private processar() {
    if (
      [TipoPergunta.MULTIPLA, TipoPergunta.GRADE_MULTIPLA].includes(
        this.context.utils.getTipoPergunta()
      )
    ) {      
      if (!this.context.utils.isObrigatoria()) {
        this.setMinimo(0);
      }
    }
  }
}
