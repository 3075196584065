import { Injectable } from "@angular/core";
import { ModalService } from "app/componentes/modal/modal.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { LocalidadeService } from "../servico/localidade.service";

interface LocalidadeFilha {
  id: number;
  nome: string;
  checked?: boolean;
}

export interface LocalidadeToTable {
  id: number;
  latitude: number;
  longitude: number;
  nome: string;
  localidadePai: {} | null;
  quantidadeFilhos: number;
  raio: number;
  versao: string;
  checked?: boolean;
  isOpen?: boolean;
  localidadesFilhas?: LocalidadeFilha[];
}

@Injectable()
export class ListReadService {
  localidades: LocalidadeToTable[] = [];

  mainCheck: boolean = false;

  localidadesFilhasPorPai = [];

  localidadesParaExclusaoEmMassa = [];

  canLookForChild: boolean = false;

  // Paginação
  currentPage: number = 1;
  lastPage: number = 0;
  startOf: number = 1;
  OneOfBackup: number = 10;
  OneOf: number = 10;
  amountOfPages: number = 0;
  totalOfLocalidades: number = 0;
  canClickNextPage: boolean = true;
  canClickPreviousPage: boolean = false;

  constructor(
    private localidadeService: LocalidadeService,
    private notificatorService: NotificatorService,
    private modalService: ModalService
  ) { }

  // BUSCA POR LOCALIDADES (NOME, PAGE)
  handleChangeLocalidades(search?: string, page?: number): void {
    const searchInput = search ? search : "";
    const pageInput = page ? page - 1 : 0;

    let requestObservable;

    requestObservable = this.localidadeService.listarLocalidadesParaTabela(
      searchInput,
      pageInput,
      this.canLookForChild
    );

    if (this.canLookForChild) {
      requestObservable.subscribe((response: LocalidadeToTable[]) => {
        let finalResponse: LocalidadeToTable[] = [];
        let finalResponseWithFather: LocalidadeToTable[];
        let finalResponseWithChildren: LocalidadeToTable[];

        const dataWithChildren: LocalidadeToTable[] = response.filter(
          (item) => item.localidadePai !== null
        );

        const dataWithFather: LocalidadeToTable[] = response.filter(
          (item) => item.quantidadeFilhos >= 0 && item.localidadePai === null
        );

        finalResponseWithFather = dataWithFather.map(
          (item: LocalidadeToTable) => ({
            ...item,
            checked: item.checked ? item.checked : false,
            isOpen: item.isOpen ? item.isOpen : false,
          })
        );

        const finalDataWithChildren = dataWithChildren.map(
          (item) => item.localidadePai
        );

        finalResponseWithChildren = finalDataWithChildren.map(
          (item: LocalidadeToTable) => ({
            ...item,
            checked: item.checked ? item.checked : false,
            isOpen: item.isOpen ? item.isOpen : false,
          })
        );

        finalResponse = finalResponse.concat(
          finalResponseWithFather,
          finalResponseWithChildren
        );

        this.setLocalidades(finalResponse);
        this.setTotal(finalResponse.length);
        this.mountChildrenData();
      });
    } else {
      requestObservable.subscribe((response: LocalidadeToTable[]) => {
        let finalResponse: LocalidadeToTable[];

        finalResponse = response.map((item: LocalidadeToTable) => ({
          ...item,
          checked: item.checked ? item.checked : false,
          isOpen: item.isOpen ? item.isOpen : false,
        }));

        this.setLocalidades(finalResponse);
        this.handleThisGetTotal(search);
        this.mountChildrenData();
      });
    }
  }

  // METODO PARA VERIFICAR SE PODE BUSCAR POR FILHA OU NÃO
  getCanLookForChild(): boolean {
    return this.canLookForChild;
  }

  setCanLookForChild(value: boolean): void {
    this.canLookForChild = value;
  }

  // Obter o total de localidades dado a pesquisa padrão ou por key
  handleThisGetTotal(search: string): void {
    let requestObservable;

    requestObservable = this.localidadeService.totalDeLocalidades(search);

    requestObservable.subscribe((response) => {
      this.setTotal(response);

      if (search === null) {
        this.startOf = this.currentPage === 1 ? 1 : this.startOf;
        this.OneOf = this.currentPage === 1 ? 10 : this.OneOfBackup;
      } else {
        this.handleCheckIfCanClickNextPage();
      }
    });
  }

  // Função responsável por montar o corpo das localidades filhas quando uma localidade pai possuir quantidadeFilhos maior que zero
  mountChildrenData() {
    if (this.localidades.length > 0) {
      this.localidades.map((item, index) => {
        let requestObservable;

        requestObservable = this.localidadeService.listarLocalidadeById(
          String(item.id)
        );

        if (item && (item.localidadesFilhas || item.quantidadeFilhos > 0)) {
          requestObservable.subscribe((response) => {
            if (this.localidades[index] && response.localidadesFilhas) {
              this.localidades[index].localidadesFilhas =
                response.localidadesFilhas ? response.localidadesFilhas : null;

              if (!!this.localidades[index].localidadesFilhas) {
                this.localidades[index].localidadesFilhas = this.localidades[
                  index
                ].localidadesFilhas.map((item) => ({
                  ...item,
                  checked: false,
                }));

                this.setNewArrayLocalidadesFilhasPorPaiRef();
              }
            }
          });
        }
      });
    }
  }

  // PAGINAÇÃO
  getTotalOfLocalidades(): number {
    return this.totalOfLocalidades;
  }

  // RETORNO DO INDEX INICIAL DA PÁGINA
  getStartOf(): number {
    return this.startOf;
  }

  // RETORNO DO INDEX FINAL DA PÁGINA
  getOneOf(): number {
    return this.OneOf;
  }

  // RETORNO PARA VALIDAR SE USUÁRIO PODE PASSAR PARA PRÓXIMA PÁGINA
  getCanSetNextPage(): boolean {
    return this.canClickNextPage;
  }

  // RETORNO PARA VALIDAR SE USUÁRIO PODE VOLTAR PARA PÁGINA ANTERIOR
  getCanSetPrevPage(): boolean {
    return this.canClickPreviousPage;
  }

  // FUNÇÃO QUE ATRIBUI O TOTAL DE LOCALIDADES
  setTotal(quantity: number): void {
    this.totalOfLocalidades = quantity;

    // this.lastPage = Math.floor(this.totalOfLocalidades / 10);
  }

  // FUNÇÃO QUE IRÁ PARA A PÁGINA ANTERIOR
  /**
   * A função primeiro verifica se o currentPage tirando um irá resultar em zero ou menor,
   * caso seja verdadeiro ele irá apenas informar que o usuário não pode mais voltar a página
   * caso seja falso, ele irá atualizar a tabela e os valores de controle da paginação
   */
  setPrevPage(): void {
    if (this.currentPage - 1 <= 0) {
      this.canClickPreviousPage = false;
      return;
    } else {
      this.currentPage = this.currentPage - 1;
      this.startOf = this.startOf - 10;
      this.OneOf = this.OneOf - 10;
      this.OneOfBackup = this.OneOfBackup - 10;

      this.handleChangeLocalidades(null, this.currentPage);

      this.OneOf = this.OneOfBackup;

      if (this.OneOf <= this.totalOfLocalidades) {
        this.canClickNextPage = true;
      }
    }
  }

  // FUNÇÃO QUE IRÁ PARA A PÁGINA POSTERIOR
  /**
   * A função primeiro valida se o this.OneOf valor que se refere ao index final da página é maior que o de localidades,
   * caso seja ele proibe o usuário de seguir para a próxima página
   * caso seja falso, ele irá atualizar os valores de controle da paginação
   * e também chamará a função para verificar e validar novamente para a pŕoxima página
   */
  setNextPage(): void {
    if (this.OneOf >= this.totalOfLocalidades) {
      this.canClickNextPage = false;
    } else {
      this.currentPage = this.currentPage + 1;
      this.startOf = this.startOf + 10;
      this.OneOf = this.OneOf + 10;
      this.OneOfBackup = this.OneOf;
      this.handleChangeLocalidades(null, this.currentPage);
      this.canClickNextPage = true;
      this.canClickPreviousPage = true;
    }

    this.handleCheckIfCanClickNextPage();
  }

  handleCheckIfCanClickNextPage(): void {
    if (this.OneOf >= this.totalOfLocalidades) {
      this.canClickNextPage = false;
      this.OneOf = this.totalOfLocalidades;
    }
  }

  // ARRAY DE LOCALIDADES
  getLocalidades(): LocalidadeToTable[] {
    return this.localidades;
  }

  setLocalidades(localidades: LocalidadeToTable[]): void {
    this.localidades = localidades;
  }

  // Função para expandir ou comprimir
  setOpenOrCloseToShowFilhas(index: number): void {
    this.localidades[index].isOpen = !this.localidades[index].isOpen;
  }

  // Checkebox controlls
  getIfHasSomeLocalidadesChecked(): boolean {
    return this.localidades.some((item) => item.checked);
  }

  getIfHasSomeLocalidadesFilhasChecked(): boolean {
    const result = this.localidadesFilhasPorPai.some((item) => item.checked);

    return result;
  }

  /**
   * Função para resetar e montar novamente o array de localidades disponíveis.
   */
  setNewArrayLocalidadesFilhasPorPaiRef(): void {
    this.localidadesFilhasPorPai = [];

    this.localidades.map((item) => {
      if (item.localidadesFilhas) {
        item.localidadesFilhas.map((filha) => {
          this.localidadesFilhasPorPai.push(filha);
        });
      }
    });
  }

  // FUNÇÃO QUE IRÁ SETAR O CHECKBOX EM LOCALIDADES PAIS ATRAVES DO INDEX
  setCheckeboxChecked(idList: number): void {
    this.localidades[idList].checked = !this.localidades[idList].checked;

    if (this.localidades[idList].localidadesFilhas) {
      this.localidades[idList].localidadesFilhas = this.localidades[
        idList
      ].localidadesFilhas.map((item) => ({
        ...item,
        checked: this.localidades[idList].checked,
      }));
    }

    // Verificando se as localidades exibidas todas estão marcadas (manualmente)
    this.mainCheck = !this.localidades.some((item) => !item.checked)
      ? true
      : false;
  }

  // FUNÇÃO QUE IRÁ SETAR O CHECKBOX EM LOCALIDADES FILHAS ATRAVES DO SEU INDEX E TAMBÉM DO INDEX DO PAI
  setFilhaCheckboxChecked(idPaiList: number, idFilhaList: number) {
    this.localidades[idPaiList].localidadesFilhas[idFilhaList].checked =
      !this.localidades[idPaiList].localidadesFilhas[idFilhaList].checked;

    // Verificando se todas as localidades filhas estão ativas então a localidade pai será ativada
    this.localidades[idPaiList].checked = !this.localidades[
      idPaiList
    ].localidadesFilhas.some((item) => !item.checked)
      ? true
      : false;

    this.setNewArrayLocalidadesFilhasPorPaiRef();

    this.mainCheck = !this.localidades.some((item) => !item.checked)
      ? true
      : false;
  }

  // FUNÇÃO QUE IRÁ CHECKAR TODAS AS LOCALIDDES
  setAllChecked(event: Event): void {
    this.mainCheck = !!event;
    this.localidades.map((item) => (item.checked = !!event));

    this.localidades.map((item) => {
      if (item.localidadesFilhas) {
        item.localidadesFilhas.map((filha) => (filha.checked = item.checked));
      }
    });
  }

  // RETORNO DO CHECKBOX GLOBAL
  getMainCheck(): boolean {
    return this.mainCheck;
  }

  // FUNÇÃO QUE ATUALIZA O VALOR DO CHECKBOX GLOBAL
  setMainCheck(event: Event): void {
    this.mainCheck = !!event;
  }

  // EXCLUIR LOCALIDADES
  deleteLocalidadePai(idApi: number): void {
    this.modalService.showModal({
      title: 'Excluir Localidade',
      messageModal: 'Ao excluir a localidade pai você perderá todas as localidades filhas, sendo necessário cadastrar novamente',
      btnTitlePositive: 'Excluir',
      btnTitleNegative: 'Cancelar',
      imgFile: 'icons/icon-warning.svg',
      positiveCallback: () => {
        let requestObservable;

        requestObservable = this.localidadeService.deletarLocalidade(idApi);

        requestObservable.subscribe(() => {
          this.handleChangeLocalidades();
          return;
        });
        this.notificatorService.showInfo(
          "Exclusão realizada",
          "Localidade excluída com sucesso!"
        );
      }
    })
  }

  // EXCLUSÃO EM MASSA DE LOCALIDADES
  deleteMassiveLocalidades(): void {
    this.localidadesParaExclusaoEmMassa = [];

    this.localidades.map((item) => {
      if (item.checked) {
        this.localidadesParaExclusaoEmMassa.push(item);
      }
    });

    this.localidadesFilhasPorPai.map((item) => {
      if (item.checked) {
        this.localidadesParaExclusaoEmMassa.push(item);
      }
    });

    this.modalService.showModal({
      title: 'Exclusão em massa',
      messageModal: `Você selecionou <b>${this.localidadesParaExclusaoEmMassa.length}</b> para exclusão em massa, deseja realmente continuar?`,
      btnTitlePositive: 'Excluir',
      btnTitleNegative: 'Cancelar',
      imgFile: 'icons/icon-warning.svg',
      positiveCallback: () => {
        let requestObservable;

        this.localidadesParaExclusaoEmMassa.map((item) => {
          requestObservable = this.localidadeService.deletarLocalidade(item.id);

          requestObservable.subscribe(() => {
            this.handleChangeLocalidades();
            return;
          });
        });
        this.notificatorService.showInfo(
          "Exclusão em massa realizada",
          "Localidades excluídas com sucesso!"
        );
      }
    })
  }

  // RESET
  reset(): void {
    this.mainCheck = false;
    this.localidades = [];
    this.localidadesFilhasPorPai = [];

    this.canLookForChild = false;

    this.totalOfLocalidades = this.getTotalOfLocalidades();
    this.currentPage = 1;
    this.startOf = 1;
    this.OneOf = 10;
    this.canClickNextPage = true;
    this.canClickPreviousPage = false;
  }
}
