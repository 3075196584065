import { colorPalette } from "app/modulos/auditoria-beta/constant";
import { pieTooltipFormatter } from "app/modulos/auditoria-beta/utils/formatter/tooltipFormatter";
import { EChartsOption } from "echarts";

export function toEchartOption(chartData): EChartsOption {
  const chartOption = {
    tooltip: {
      trigger: "item",
      borderColor: "var(--gray200)",
      backgroundColor: "var(--gray100)",
      formatter: (params) => pieTooltipFormatter(params),
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      icon: "circle",
      right: 0,
      top: 8,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 14,
        fontColor: "var(--gray400)"
      },
      itemGap: 12,
    },
    color: colorPalette,
    series: [
      {
        type: "pie",
        radius: ["70%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#FFF",
          borderWidth: 3,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: true,
        },
        data: chartData,
      },
    ],
  };

  return chartOption as EChartsOption;
}
