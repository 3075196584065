<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <img class="instance-icon" src="assets/icons/instance-icon.svg" />
      <h2 [routerLink]="'/management'">Gerenciamento</h2>
      <span>/</span>
      <h4>criar instância</h4>
    </div>
  </div>

  <form
    class="content-adicionar"
    #form="ngForm"
    [formGroup]="formGroup"
    (ngSubmit)="
      showNotificationError(); form.valid && salvar(formGroup.getRawValue())
    "
  >
    <div class="row form section sg-input-group">
      <div class="col-12 sub-title">
        <h3>Dados da nova instância</h3>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="campo">
          <label for="">Nome da instância*</label>
          <input
            title="nome da nova instância"
            type="text"
            mask-only-letters
            placeholder="Ex.: admin"
            formControlName="friendlyName"
            maxlength="250"
          />
          <span
            class="error-message"
            [hidden]="!(form.submitted && controls.friendlyName.errors)"
          >
            Deve ser informado um nome entre 5 e 255 caracteres
          </span>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="campo">
          <label for="">Status</label>
          <select title="status do instância" formControlName="ativo">
            <option value="false">Inativo</option>
            <option value="true" selected>Ativo</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row form sg-input-group section">
      <div class="col-12 sub-title">
        <h3>Dados do administrador</h3>
      </div>

      <!-- IMAGEM -->
      <div class="mt-3 mb-5 mr-5 ml-3">
        <app-new-foto-uploader></app-new-foto-uploader>
      </div>

      <div class="col-lg-9 col-md-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- NOME DO CLIENTE  -->
            <div formGroupName="admin">
              <div class="campo" formGroupName="pessoa">
                <label for="">Nome do administrador *</label>
                <input
                  mascara-primeiro-digito-sem-espaco
                  placeholder="Ex.:manoel soares da silva"
                  title="nome do administrador"
                  id="nome"
                  formControlName="nome"
                  type="text"
                  maxlength="50"
                />
                <span
                  class="error-message"
                  *ngIf="
                    form.submitted &&
                    controls.admin.get('pessoa').get('nome').errors
                  "
                  >Insira o nome</span
                >
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- CPF -->
            <div formGroupName="admin">
              <div class="campo" formGroupName="pessoa">
                <label for="">CPF *</label>
                <input
                  placeholder="Ex.:111.222.333-96"
                  title="cpf do administrador"
                  type="text"
                  mascara-cpf
                  formControlName="cpf"
                />
                <span
                  class="error-message"
                  [hidden]="
                    !(
                      form.submitted &&
                      controls.admin.get('pessoa').get('cpf').errors
                    )
                  "
                  >CPF inválido</span
                >
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- TELEFONE -->
            <div formGroupName="admin">
              <div class="campo" formGroupName="contato">
                <label for="">Telefone </label>
                <input
                  placeholder="Ex.:34216666"
                  title="telefone do administrador"
                  formControlName="telefone"
                  mascara-telefone
                  id="telefone"
                  type="text"
                />
                <span
                  class="error-message"
                  [hidden]="
                    !(
                      form.submitted &&
                      controls.admin.get('contato').get('telefone').errors
                    )
                  "
                  >Insira um telefone válido</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row section form sg-input-group">
      <div class="col-12 sub-title">
        <h3>Dados de acesso</h3>
      </div>
      <div class="col-md-4" formGroupName="admin">
        <!-- EMAIL -->
        <div class="campo" formGroupName="usuario">
          <label for="">Email *</label>
          <input
            formControlName="login"
            type="email"
            placeholder="Ex:.jose@email.com"
            title="email de login do sistema"
            maxlength="50"
          />
          <span
            class="error-message"
            *ngIf="
              form.submitted &&
              controls.admin.get('usuario').get('login').errors
            "
            >Informe um email válido</span
          >
        </div>
      </div>

      <!-- SENHA -->
      <div class="col-md-4" formGroupName="admin">
        <div class="campo" formGroupName="usuario">
          <label *ngIf="isEdicao" for="">Senha</label>
          <label *ngIf="!isEdicao" for="">Senha *</label>
          <app-input-password
            [isEdicao]="isEdicao"
            [isAlteracaoSenha]="isAlteracaoSenha"
            [parentFormGroup]="formGroup.controls.admin"
            [controlName]="'senha'"
          ></app-input-password>
          <span
            class="error-message"
            [hidden]="
              !(
                form.submitted &&
                controls.admin.get('usuario').get('senha').errors &&
                (controls.admin.get('usuario').get('senha').errors.required ||
                  controls.admin.get('usuario').get('senha').errors.minlength ||
                  controls.admin.get('usuario').get('senha').errors.maxlength)
              )
            "
          >
            Informe uma senha entre 8 e 30 caracteres
          </span>
        </div>
      </div>
      <div class="col-md-4" formGroupName="admin">
        <div class="campo" formGroupName="usuario">
          <label *ngIf="isEdicao" for="">Repetir Senha</label>
          <label *ngIf="!isEdicao" for="">Repetir Senha *</label>
          <app-input-password
            [isEdicao]="isEdicao"
            [isAlteracaoSenha]="isAlteracaoSenha"
            [parentFormGroup]="formGroup.controls.admin"
            [controlName]="'senhaConfirmar'"
          ></app-input-password>
          <span
            class="error-message"
            [hidden]="
              !(
                form.submitted &&
                controls.admin.get('usuario').get('senhaConfirmar').errors
              )
            "
          >
            As senhas estão diferentes
          </span>
        </div>
      </div>

      <!-- ATIVAR ALTERAÇÃO DE SENHA -->
      <div class="campo-inline" *ngIf="isEdicao">
        <mat-slide-toggle
          title="ativar alteração de senha"
          (click)="habilitarAlteracaoSenha()"
        ></mat-slide-toggle>
        <label>Habilitar alteração de senha</label>
      </div>

      <!-- ATIVAR ALTERAÇÃO DE SENHA -->
      <!-- <div class="col-12">
        <div class="campo-inline" *ngIf="isEdicao">
          <mat-slide-toggle title="ativar alteração de senha" (click)="habilitarAlteracaoSenha()"></mat-slide-toggle>
          <label>Habilitar alteração de senha</label>
        </div>
      </div> -->

      <!-- <div class="col-4">
        <div class="campo">
          <label for="">Permissões</label>
          <select title="permissões" name="" class="containerGrupo" (change)="onPermissaoSelected($event)">
            <option [value]="null">selecione</option>
            <option [hidden]="permissao.selecionado" [value]="permissao.nomeReal" *ngFor="let permissao of permissoes">
              {{permissao.nomeAmigavel}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 campo cloudTags">
        <label for="">Permissões Selecionadas</label>
        <div class="tags">
          <div class="tagItem" [hidden]="!permissao.selecionado" *ngFor="let permissao of permissoes">
            <span>{{permissao.nomeAmigavel}}</span>
            <span class="tagExcluir" title="excluir permissão"
              (click)="alterarSelecaoPermissao(permissao.nomeReal)">&times;</span>
          </div>
          <span class="error-message" [hidden]="!(form.submitted && controls.permissoes.errors)">
            Ao menos uma permissão deve ser adicionado
          </span>
        </div>
      </div> -->

      <div class="col-12 btns-actions">
        <button
          class="sg-btn sg-alternate"
          (click)="cancelarFluxo($event)"
          title="Cancelar"
        >
          CANCELAR
        </button>
        <button
          *ngIf="!isEdicao"
          class="sg-btn sg-secondary"
          type="submit"
          title="Adicionar administrador"
        >
          ADICIONAR
        </button>
        <button
          *ngIf="
            isEdicao &&
            getAuthenticatedUserAuthorities().includes(
              authority.ATUALIZAR_GRUPO
            )
          "
          class="sg-btn sg-secondary"
          type="submit"
          title="Atualizar dados do administrador"
        >
          ATUALIZAR
        </button>
      </div>
    </div>
  </form>
</div>

<app-loading *ngIf="isLoading"></app-loading>
