import { Component, Input, OnInit } from "@angular/core";
import { IQuestion } from "../../../interfaces/audit-question-collections";
import { AuditQuestionManager } from "./services/question-manager.service";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { AuditoriaBetaService } from "../../../services/auditoria-beta.service";

export interface AnswerOutput {
  type: TipoPergunta;
  questionId: number;
  answer: any;
}

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
  providers: [AuditQuestionManager]
})
export class QuestionsComponent implements OnInit {
  @Input() questionList: IQuestion[] = [];

  constructor(
    private autidService: AuditoriaBetaService,
    private questionManager: AuditQuestionManager,
  ) {}

  ngOnInit(): void {}

  /**
   * recebe a nova seleção feita pelo usuário
   * @param $event Valor da seleção
   */
  onChangeAnswer($event: AnswerOutput) {
    this.questionManager.update($event);
  }
}