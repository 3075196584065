/**
 * Agrupa uma lista de objetos por uma determinada key do objeto agregado.
 * @param lista lista de objetos
 * @param key nome do campo no qual deseja-se agrupar a lista.
 */
const groupBy = function (lista, key) {
  return lista.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  },                  {});
};

/**
 * move um elemento na array.
 * @param indiceInicial indice atual do elemento antes de ser movido
 * @param proximoIndice indice destino onde o elemento deverá residir após o movimento
 * @param elementos array com todos os elementos
 */
const moverElemento = function (indiceInicial: number, proximoIndice: number, elementos: any[]) {

  const copy = [...elementos];
  const elementoMovido = copy.splice(indiceInicial, 1);
  copy.splice(proximoIndice, 0, ...elementoMovido);

  return copy;
};

const isEmpty = function(arr: Array<any>) {
  return (!Array.isArray(arr) || arr.length === 0);
};

export default { groupBy, isEmpty, moverElemento };
