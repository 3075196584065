export class DadosPaginacao {

  public static DEFAULT_SIZE: number = 10;
  public static INITIAL_PAGE: number = 0;

  size: number;
  page: number;

  constructor(tamanhoPagina: number, indicePagina: number) {
    this.size = tamanhoPagina;
    this.page = indicePagina;
  }

}
