import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplaySubject, Subject } from "rxjs";
import { EventoInput } from "../../pesquisa-old/cadastro/inputs/evento/eventoInput";
// tslint:disable-next-line: max-line-length
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { PesquisaItem } from "../../pesquisa-old/pesquisa-select/pesquisaItem";
import {
  EventoPerguntaRespostaSelecionada,
  PerguntaRespostaItem,
  PerguntaRespostaMultiSelectorComponent,
} from "./pergunta-resposta-multi-selector/pergunta-resposta-multi-selector.component";
import { EventoRespostaEditada } from "./resposta-edicao/resposta-edicao.component";

@Component({
  selector: "app-respostas-abertas",
  templateUrl: "./respostas-abertas.component.html",
  styleUrls: ["./respostas-abertas.component.scss"],
})
export class RespostasAbertasComponent implements OnInit {
  idPesquisa: number;
  public idPergunta: number;
  citacoes: PerguntaRespostaItem[];

  // tslint:disable-next-line: max-line-length
  @ViewChild(PerguntaRespostaMultiSelectorComponent, { static: true })
  perguntaRespostaMultiSelectorComponent: PerguntaRespostaMultiSelectorComponent;

  /**
   * Subject que notifica EventoPerguntaSelecionada para quaisquer componentes filhos que se
   * subscreverem.
   */
  public perguntaSelecionadaSubject: Subject<EventoPerguntaSelecionada> =
    new ReplaySubject();
  public novaPesquisaSelecionadaSubject: Subject<PesquisaItem> =
    new ReplaySubject();

  selecionado: boolean = false;
  /**
   * Subject que notifica EventoPerguntaRespostaSelecionada para quaisquer componentes filhos que se
   * subscreverem.
   */
  // tslint:disable-next-line: max-line-length
  public perguntaRespostasSelecionadasSubject: Subject<EventoPerguntaRespostaSelecionada> =
    new ReplaySubject();

  @Input() public pesquisaSelecionada: PesquisaItem;
  public _pesquisaSelecionada: PesquisaItem;

  totalPerguntasRespostasCitacoes: number = 0;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public requestService: RequestService
  ) {}

  ngOnInit() {
    this.idPesquisa = this.pesquisaSelecionada.id;
    this._pesquisaSelecionada = this.pesquisaSelecionada;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pesquisaSelecionada) {
      this._pesquisaSelecionada = changes.pesquisaSelecionada.currentValue;
      this.idPesquisa = this._pesquisaSelecionada.id;
      this.selecionado = false;
    }
  }

  getIdPesquisaFromRoute(): number {
    const idPesquisa: string =
      this.route.snapshot.paramMap.get("idPesquisa") || "";
    return Number(idPesquisa);
  }

  /**
   * Callback disparado quando uma pergunta é selecionada no componente app-pergunta-selector.
   * Responsável por notificar o perguntaSelecionadaSubject da nova perguntaSelecionada.
   * @param eventoPerguntaSelecionada evento que encapsula a pergunta selecionada,
   * o payload trata-se
   * do identificador da mesma
   */
  onPerguntaSelecionada(eventoPerguntaSelecionada: EventoInput) {
    const idPergunta: number = eventoPerguntaSelecionada.payload.value;
    this.idPergunta = idPergunta;

    this.perguntaSelecionadaSubject.next({
      id: idPergunta,
    });

    this.selecionado = true;
    this.VerificarExistenciaPerguntaResposta(idPergunta);
  }

  /**
   * Callback disparado quando uma respostas de uma pergunta é selecionada no componente
   * app-pergunta-resposta-multi-selector.
   * Responsável por notificar o perguntaRespostasSelecionadasSubject do estado atual
   * das respostas selecionadas.
   * @param eventoPerguntaRespostaSelecionada evento que encapsula as
   * respostas selecionadas no momento.
   */
  // tslint:disable-next-line: max-line-length
  onPerguntaRespostaSelecionada(
    eventoPerguntaRespostaSelecionada: EventoPerguntaRespostaSelecionada
  ) {
    this.perguntaRespostasSelecionadasSubject.next(
      eventoPerguntaRespostaSelecionada
    );
  }

  VerificarExistenciaPerguntaResposta(idPergunta: number) {
    const uri = `${apiLocation}/pesquisas/${this.idPesquisa}/perguntas/${idPergunta}/citacoes`;

    // tslint:disable-next-line: prefer-template
    this.requestService
      .get(uri)
      .subscribe((respostasPerguntas: PerguntaRespostaItem[]) => {
        this.totalPerguntasRespostasCitacoes = respostasPerguntas.length;
        this.citacoes = respostasPerguntas;
      });
  }

  onRespostaEditada(eventoRespostaEditada: EventoRespostaEditada) {
    const novaResposta: string = eventoRespostaEditada.novaResposta;
    this.VerificarExistenciaPerguntaResposta(this.idPergunta);
    // emitindo evento de nova pergunta para mesma pergunta,
    // para causar ré-renderizacao do componente PerguntaRespostaMultiSelector
    this.perguntaSelecionadaSubject.next({
      id: this.idPergunta,
    });
  }
}

export class EventoPerguntaSelecionada {
  id: number;
}
