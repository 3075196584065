import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ListagemGenerica } from "../listagem/listagemGenerica";
import { ManagementService } from "./instance.service";
import { InstanciaSistema } from "./instancia-cadastro/InstanciaSistema";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-management",
  templateUrl: "./management.component.html",
  styleUrls: ["./management.component.scss"],
})
export class ManagementComponent
  extends ListagemGenerica<InstanciaSistema>
  implements OnInit
{
  nomeAtributosTabela = ["friendlyName"];
  nomeColunasTabela = ["ID", "Nome"];
  placeholder: string = "Ex.: alfa consultoria";
  initialSearchValue: string = "";
  // Atributo passado caso o atributo ID da entidade seja diferente do padrão
  nomeAtributoIdCustomizado = "name";

  // tslint:disable-next-line: max-line-length
  constructor(
    protected securityService: SecurityService,
    public managementService: ManagementService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "management",
      managementService,
      router,
      modalService
    );
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get("search"))
      this.initialSearchValue = this.route.snapshot.paramMap.get("search");
  }
}
