<div class="table-container">

  <table class="sg-table">
    <thead>
      <th>#</th>
      <th>Pergunta</th>
      <th>Resposta</th>
      <th>Tipo de pergunta</th>
      <th>Visualizar</th>
    </thead>
    <tbody>
      <tr *ngFor="let trBody of trBodies; let perguntaIndex = index">
        <td>{{ getNumberWithZeroBefore(trBody.numero) }}</td>
        <td>{{ trBody.pergunta }}</td>
        <td>
          <app-coluna-resposta-resumo [trBody]="trBody" (visualizarResposta)="requestVisualizacaoRespostas($event)">
          </app-coluna-resposta-resumo>
        </td>
        <td>
          <i [ngClass]="getIcon(trBody.tipoPergunta)"></i>
          {{ getTipoPerguntaPretty(trBody.tipoPergunta) }}
        </td>
        <td>
          <a (click)="requestVisualizacaoRespostas(trBody.respostaPergunta)" style="cursor: pointer">
            <i class="fa fa-eye"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-loading *ngIf="isLoading"></app-loading>