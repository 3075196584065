<div class="verdict-container">
  <section class="verdict-wrapper">
    <button class="verdict-btn" (click)="handleApplyVerdict()">{{ selectedOption.label }}</button>
    <div
      (click)="toggleSelect()"
      [ngClass]="{
        'select-header': true,
        'open-options': selectIsOpen
      }"
    >
      <i
        class="fa-regular arrow"
        [ngClass]="{
          'fa-chevron-down': true,
          rotate: selectIsOpen
        }"
        aria-hidden="true"
      ></i>
    </div>
  </section>

  <section class="verdict-options" *ngIf="selectIsOpen">
      <span
        class="verdict-option"
        (click)="handleSelectOption(option)"
        *ngFor="let option of renderOptions"
      >
        <p>{{ option.label }}</p>
      </span>
  </section>

</div>
