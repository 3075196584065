import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";

import * as LMap from "leaflet";

interface GeoJSON {
  type: string;
  coordinates: number[][] | number[];
  radius?: number;
  properties?: object;
}

@Component({
  selector: "app-leaflet-map-beta",
  templateUrl: "./laeflet-map-beta.component.html",
  styleUrls: ["./leaflet-map-beta.component.scss"],
})
export class LeafletMapBetaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // map size settings
  @Input() mapHeight = "300px";

  // map reference
  private map;

  private mapaInicializado: boolean = false;

  // initial map lat and long
  private currentUserLat: number = -7.1219366;
  private currentUserLon: number = -36.7246845;

  // current zoom
  private zoom: number = 6;

  // current boundingbox
  private latMin: number = -8.302955;
  private latMax: number = -38.7656034;
  private longMin: number = -6.0259119;
  private longMax: number = -34.5995314;

  // Map drawings references (called as layers)
  private layers = [];

  public static LAYERTYPES = {
    MARKER: LMap.Marker,
    POLYGON: LMap.Polygon,
    Circle: LMap.Circle,
  };

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.mapaInicializado =  false;
    if (this.map) {
      this.map.remove();
    }
  }

  /**
   * Creates an collection of drawings in the map and returns it.
   */
  private createFeatureCollection(configs: GeoJSON[]) {
    const featureCollection = {
      type: "FeatureCollection",
      features: [],
    };

    configs.forEach((config) => {
      const properties = { ...config.properties || {}};

      const featureObject = {
        type: "Feature",
        properties: properties,
        geometry: {
          type: config.type,
          coordinates: config.coordinates,
        } as GeoJSON,
      };

      featureCollection.features.push(featureObject);
    });

    return featureCollection;
  }

  /**
   * Draw circles in the map giving the coordinates and the radius
   */
  public drawCircles(
    circleModel: { lat: number; lon: number; radius: number }[]
  ) {
    const circles = [];

    for (let i = 0; i < circleModel.length; i++) {
      const { lat, lon, radius } = circleModel[i];

      const circle = LMap.circle([lat, lon], {
        color: "var(--primary)",
        fillColor: "var(--primary)",
        fillOpacity: 0.2,
        radius: radius,
      });

      circles.push(circle);
    }

    if (circles.length) {
      const layer = LMap.layerGroup(circles);
      layer.addTo(this.map);

      this.layers.push(layer);
    }
  }

  /**
   * Draw polygons or pointers in the map giving a GeoJSON config. This method follow some steps:
   * 1 - A featureCollection is created. A feature collection is a collection of drawings which can be added to the map.
   * 2 - Insert styles at the drawings, for now the default is the purple color for polygons.
   */
  public drawMarkers(configs: GeoJSON[]) {
    if (configs.length) {
      const featureCollection = this.createFeatureCollection(configs);

      const layer = LMap.geoJSON(featureCollection, {
        style: (_) => {
          return {
            color: "var(--primary)",
          };
        },
        pointToLayer: function (feature, latlng) {
          const pointIcon = LMap.icon({
            className: 'custom-icon-map',
            iconUrl: "../../../../../assets/icons/map-marker.svg",
            iconSize: [25, 35],
          });

          return LMap.marker(latlng, { icon: pointIcon });
        },
      });

      layer.addTo(this.map);
      this.layers.push(layer);
    }
  }

  // função para renderizar um único ponto no mapa
  drawPointMarker(lat: number, long: number) {
    const pointIcon = LMap.icon({
      className: 'custom-icon-map',
      iconUrl: "../../../../../assets/icons/map-marker.svg",
      iconSize: [25, 35],
    });

    const point = LMap.marker([lat, long], { icon:  pointIcon});
    point.addTo(this.map)
    this.layers.push(point)
  }

  /**
   * removes all drawings in the map
   */
  public removeLayers() {
    this.layers.forEach((layer) => {
      if (layer.clearLayers) layer.clearLayers();
    });

    // reset array
    this.layers.length = 0;
  }

  /**
   * Adjusts the map's zoom level and position to fit the given bounds.
   * This method ensures that the specified bounds are fully visible within the map's viewport.
   * It calculates the optimal zoom level and centering of the map to display the bounds.
   * The map will be zoomed in or out as necessary to fit the bounds.
   * @param {LatLngBounds} bounds - The bounds to fit within the map's viewport.
   */
  public fitBounds(boundingBox: number[]) {
    return new Promise<void>((resolve) => {
      if (this.map) {
        this.latMin = boundingBox[0];
        this.latMax = boundingBox[1];
        this.longMin = boundingBox[2];
        this.longMax = boundingBox[3];

        const boundingBoxCoords = [
          [this.latMin, this.longMin],
          [this.latMax, this.longMax],
        ];

        const bounds = LMap.latLngBounds(boundingBoxCoords);

        this.map.once("zoomend", () => resolve());
        this.map.fitBounds(bounds);
      } else {
        resolve();
      }
    });
  }

  public fitBoundsByLinearCoords(coordinates: { lat: number; lon: number }[]) {
    return new Promise<void>((resolve) => {
      if (this.map) {
        const bounds = LMap.latLngBounds(coordinates.map(coord => [coord.lat, coord.lon]));

        this.map.once("zoomend", () => resolve());
        this.map.fitBounds(bounds);
      } else {
        resolve();
      }
    });
  }

  /**
   * Sets the center and zoom level of the map view.
   */
  public setLocation(lat: number, long: number) {
    return new Promise<void>((resolve) => {
      if (this.map) {
        this.currentUserLat = lat;
        this.currentUserLon = long;

        this.map.once("moveend", () => {
          resolve();
        });

        // Removes previous layers added to the map
        this.removeLayers();
        // Updates map at given lat, long and zoom
        this.mapaInicializado && this.map.setView([this.currentUserLat, this.currentUserLon], this.zoom);
      } else {
        resolve();
      }
    });
  }

  public addGlobalEvent(eventConfig: { eventType: string; handler: Function }) {
    const { eventType, handler } = eventConfig;

    this.map.on(eventType, handler.bind(this.map));
  }

  public addEventToLayer(eventConfig: { eventType: string; LayerType: any; handler: Function }) {
    const {
      eventType,
      LayerType,
      handler,
    } = eventConfig;

    if ([LMap.Circle, LMap.Marker, LMap.Polygon].includes(LayerType) && this.layers.length) {
      this.layers.forEach(collection => {
        const layer = collection.getLayers();

        layer.forEach(L => {
          if (L instanceof LayerType) {
            L.on(eventType, handler);
          }
        });
      });
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {

    this.mapaInicializado =  true;

    this.map = LMap.map("map", {
      center: [this.currentUserLat, this.currentUserLon],
      zoom: this.zoom,
    });

    const tiles = LMap.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
  }
}
