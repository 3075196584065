export const lgpdErrors = {
  getDocuments: {
    clientError: {
      title: "Erro ao recuperar documentos LGPD",
      message: "Ocorreu um erro ao recuperar os documentos LGPD, por favor, aguarde..."
    },
    serverError: {
      title: "Erro interno do servidor",
      message: "Ocorreu uma falha no nosso sistema, por favor, conecte-se novamente."
    }
  },
  signatures: {
    title: "Erro ao assinar documentos",
    message: "Ocorreu um erro ao assinar os documentos LGPD. Por favor, tente novamente."
  }
}
