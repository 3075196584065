import { Router } from '@angular/router';

export abstract class AbstractCanDeactivateGuard {

  constructor(protected router: Router) { }

  /**
  * Se a mudança de rota seja por ordem do próprio
  * sistema, o usuário não será questionado sobre
  * sua ação
  */
  ignoreRedirectRules() {
    const redirect = this.router.getCurrentNavigation().finalUrl.queryParamMap.get('redirect');
    return redirect;
  }

}
