import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { IAlternative } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";

type RenderTypes = "checkbox" | "radio";


@Component({
  selector: "app-alternativa-aberta-texto",
  templateUrl: "./aberta-texto.component.html",
  styleUrls: ["./aberta-texto.component.scss"],
})
export class AlternativaAbertaTextoComponent implements OnInit, OnChanges {
  @Input() renderType: RenderTypes = "radio";
  @Input() alternativa = {} as IAlternative;
  @Input() value: any = "";
  @Input() preSelected: boolean = false;
  @Input() selectable: boolean = true;
  @Output() onCheck = new EventEmitter<{ id: number, value: string }>(null);
  @Output() onBlur = new EventEmitter<{ id: number, value: string }>(null);
  @Output() onChange = new EventEmitter<{ id: number, value: string }>(null);

  @Input() isChecked: boolean = false;

  @ViewChild("textareaRef") textAreaRef: ElementRef<HTMLElement> = null;


  constructor() {}

  handleCheck(altId: number) {
    this.onCheck.emit({ id: altId, value: this.value });
  }

  handleInputFocus(altId: number) {
    if (this.isChecked) return;
    this.handleCheck(altId);
  }

  resizeTextArea() {
    // o usuário limpou a caixa de texto
    if (!this.value.length) {
      this.textAreaRef.nativeElement.style.height = "21px";
    }
    // atribuição automática com base no scrollHeight
    else {
      this.textAreaRef.nativeElement.style.height =
        this.textAreaRef.nativeElement.scrollHeight + "px";
    }
  }

  handleInput($event) {
    if (this.textAreaRef.nativeElement) {
      this.resizeTextArea();
    }

    this.onChange.emit({ id: this.alternativa.id, value: this.value });
  }

  ngOnInit(): void {}

  ngOnChanges(simpleChanges: SimpleChanges) {
   
  }
  
}
