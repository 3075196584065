import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export class ProdutividadeFiltro {

  idPesquisa: string;
  idOperador?: string;
  dataInicial?: string;
  dataFinal?: string;

  static getControl(): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      idPesquisa: new UntypedFormControl(),
      idOperador: new UntypedFormControl(),
      dataInicial: new UntypedFormControl(),
      dataFinal: new UntypedFormControl(),
    });

    return formGroup;
  }

}
