import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Localidade } from "../../localidade";
import { ModalCreadUpdateService } from "../modal-create-update.service";

@Component({
  selector: "app-localidades-list",
  templateUrl: "./localidades-list.component.html",
  styleUrls: ["./localidades-list.component.scss"],
})
export class LocalidadesListComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() getLocalidadesFilhas: Localidade[];
  @Input() getLocalidadePai: Localidade[];
  @Input() isShowingForm: boolean = false;
  @Input() whoIsEditing: string;

  localidadePaiToList = null;
  localidadesFilhas = null;
  localidadesFilhasFiltered = null;

  constructor(private modalCreateUpdateService: ModalCreadUpdateService) { }

  ngOnInit() {
    this.localidadePaiToList = this.modalCreateUpdateService.getLocalidadePai();
    this.localidadesFilhasFiltered = this.getLocalidadesFilhas;
    this.localidadesFilhas = this.getLocalidadesFilhas;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (this.whoIsEditing === "CHILDREN") {
          setTimeout(() => {
            this.ngAfterViewInit();
          }, 500);
        }

        switch (propName) {
          case "getLocalidadesFilhas":
            this.localidadesFilhas = this.getLocalidadesFilhas;
            break;
          case "getLocalidadePai":
            this.localidadePaiToList = this.getLocalidadePai;
            break;
          default:
            break;
        }
      }
    }
  }

  ngAfterViewInit() {
    const hightLightScroll = document.getElementById("hightlight-scroll");

    if (hightLightScroll) {
      hightLightScroll.scrollIntoView();
    }

    this.localidadePaiToList = this.modalCreateUpdateService.getLocalidadePai();
  }

  removeSpecialCharacters(text: string): string {
    let finalText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return finalText;
  }

  filteredLocation(event: any) {
    const eventWithoutSpecial = this.removeSpecialCharacters(event.target.value);
    if (event.target.value === "") {
      this.localidadesFilhasFiltered = this.localidadesFilhas;
    } else {
      this.localidadesFilhasFiltered = this.localidadesFilhas.filter((item) => {
        const itemWithoutSpecial = this.removeSpecialCharacters(item.nome);
        if (
          itemWithoutSpecial
            .toUpperCase()
            .includes(eventWithoutSpecial.toUpperCase())
        ) {
          return item;
        }
      });
    }
  }

  handleGetCanShowForm(): boolean {
    return this.modalCreateUpdateService.getCanShowForm();
  }

  handleGetWhichIdShouldILook(): number {
    return this.modalCreateUpdateService.whichIdShouldILook;
  }

  handleDeleteFatherLocalidade(idApi: number) {
    this.modalCreateUpdateService.deleteLocalidadePai(idApi);
  }

  handleEditFatherLocalidade(idApi: number) {
    this.modalCreateUpdateService.editLocalidadePai(idApi);
  }

  handleDeleteChildLocalidade(idList: number, idApi: number) {
    this.modalCreateUpdateService.deleteLocalidadeFilha(idList, idApi);
  }

  handleEditChildLocalidade(idList: number, idApi: number) {
    this.modalCreateUpdateService.editLocalidadeFilha(idList, idApi);
  }
}
