import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JWTService } from 'app/infraestrutura/security/service/jwtService';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestErrorHandler {

  constructor(private jwtService: JWTService, private router: Router) {

  }

  /**
   * Função que manipula erros capturados por uma requisição, caso seja um status diferente
   * de 401, caso exista uma mensagem de erro, ela eh extraída e formatada,
   * caso contrario, uma padrão eh retornada. Caso o erro seja um status 401, a 'sessão' do
   * usuário eh limpa e então o mesmo eh redirecionado para a pagina de login
   * @param error Erro que sera manipulado
   */
  handleError(error: any, mapErrorToString: boolean = true): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      const errorResponse = <HttpErrorResponse>error;
      if (errorResponse.status === 401) {
        this.jwtService.removeToken();
        this.router.navigate(['']);
      }

      if (errorResponse.status === 403) {
        return throwError(() => ({ parsedError: 'Usuário sem acesso', error: error.error }));
      }

      if (!mapErrorToString) {
        return throwError(() => ({ parsedError: null, error: errorResponse }));
      }

      if (typeof errorResponse.error === 'string') {
        return throwError(() => ({ parsedError: errorResponse.error, error: errorResponse }));
      }

      const errorArray = errorResponse.error ? errorResponse.error.errors : [];
      const errorsString = JSON.stringify(errorArray);

      if (errorsString) {
        const errorMessage = errorsString.replace(/\[*\]*/g, '');

        return throwError(() => ({ parsedError: errorMessage, error: errorResponse }));
      }
    }

    const defaultError = 'Houve um problema na sua solicitação, verifique a conexão e tente novamente';
    return throwError(() => ({ parsedError: defaultError, error: error }));
  }

}
