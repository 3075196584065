<div class="container-privacy">
  <div class="content-privacy">
    <h1 class="title">POLÍTICA DE PRIVACIDADE</h1>

    <p>Nós, da <strong>TENSAI</strong> (“Empresa” ou “nós”), inscrita no CNPJ/ME sob o nº 45.432.415/0001-72 e
      com sede na Cidade de João Pessoa, Estado da Paraíba, levamos a sua privacidade e a
      proteção dos seus dados a sério. Este documento explica como e porque seus dados são
      tratados, quais seus direitos e como você pode exercê-los.</p>

    <p>Mas antes, precisamos te explicar como funciona o ECOSSISTEMA TENSAI.</p>

    <p>O Ecossistema Tensai é um sistema de gerenciamento, coleta, análise e demonstração de
      dados oriundos de pesquisa - de qualquer natureza - que tem como premissa gerar
      informações confiáveis e inteligentes para guiar a tomada de decisão por pessoas jurídicas
      de qualquer segmento, público ou privado.</p>

    <p>É assim definido, pois cada etapa é gerenciada por uma aplicação específica, sendo formado
      pelo: <strong>TENSAI WEB, TENSAI APP, TENSAI FORM e ANALYZE.</strong></p>

    <p><strong>TENSAI WEB</strong></p>

    <p>É a área administrativa na qual a empresa que contrata o Ecossistema irá gerenciar:</p>

    <ul>
      <li>Pesquisas;</li>
      <li>Clientes;</li>
      <li>Operadores (entrevistadores de campo);</li>
      <li>Funcionários;</li>
      <li>Localidades (locais onde serão realizadas as coletas);</li>
      <li>Produtividade (análise da produtividade de cada operador);</li>
      <li>Auditoria;</li>
      <li>Permissões de usuários;</li>
    </ul>

    <p><strong>TENSAI APP</strong></p>
    <p>É o nosso aplicativo para realização de coletas presenciais, por intermédio de um
      <strong>OPERADOR</strong>. Além do objetivo principal que é a coleta em si, o aplicativo possui vários
      instrumentos que irão auxiliar na qualidade, confiabilidade e integridade dos dados
      coletados.
    </p>

    <p><strong>TENSAI FORMS</strong></p>
    <p>Nosso gerador de formulário de pesquisa para resposta online, sem a necessidade da
      presença de um operador de campo para mediar a coleta com o entrevistado.</p>

    <p><strong>ANALYZE</strong></p>
    <p>É o Dashboard para análise dos dados coletados. É nele onde todos os dados consolidados e
      auditados poderão ser manejados para extrair as informações necessárias para
      entendimento e tomada de decisões das empresas que contratam o ecossistema.</p>

    <p>Agora que você já sabe o que cada aplicação faz, vamos ao que interessa:</p>

    <p><strong>1. CONCEITOS BÁSICOS: O QUE VOCÊ PRECISA SABER PARA
        COMPREENDER ESTA POLÍTICA?</strong></p>
    <p>A fim de descomplicar a sua leitura deste documento, apresentamos algumas definições úteis
      para a sua interpretação. Estes termos serão utilizados em letra maiúscula:</p>

    <table>
      <tr>
        <th style="width: 150px;">Termo</th>
        <th>Conceito</th>
      </tr>
      <tr>
        <td>
          <p>DADO PESSOAL</p>
        </td>
        <td>
          <p>
            É uma informação relacionada a uma pessoa física e que
            seja capaz de identificar a pessoa ou tornar possível a sua
            identificação.
          </p>

          <p>
            São exemplos de dados pessoais que podem permitir a sua
            identificação: Nome, CPF, telefone, e-mail, placa do seu veículo
            etc.
          </p>
        </td>
      </tr>

      <tr>
        <td>
          <p>TRATAMENTO</p>
        </td>
        <td>
          <p>É toda forma de uso que podemos fazer dos seus Dados
            Pessoais, incluindo, mas não se limitando às seguintes
            atividades: coleta, armazenamento, consulta, uso,
            compartilhamento, transmissão, classificação, reprodução,
            exclusão e avaliação.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>TITULAR</p>
        </td>
        <td>
          <p>É você, a pessoa física a quem os Dados Pessoais se
            referem.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>EMPRESA</p>
        </td>
        <td>
          <p>Somos nós, a TENSAI.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>POLÍTICA</p>
        </td>
        <td>
          <p>É esta Política de Privacidade.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>OPERADORES</p>
        </td>
        <td>
          <p>Entrevistadores de campo que serão responsáveis por
            abordar o público alvo da pesquisa a ser realizada.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>CLIENTES</p>
        </td>
        <td>
          <p>Pessoas físicas ou jurídicas que contratam uma empresa
            Contratante para realizar a pesquisa.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>CONTRATANTE</p>
        </td>
        <td>
          <p>Pessoa física ou jurídica que compra uma licença do Tensai
            para realizar as pesquisas e análises de dados dos seus
            Clientes.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>FUNCIONÁRIOS</p>
        </td>
        <td>
          <p>São os colaboradores da Contratante que são responsáveis
            por atuar administrativamente no Tensai Web e Analyze, na
            criação de pesquisas, auditorias, realização de análises e
            afins.</p>
        </td>
      </tr>
    </table>

    <p><strong>2. QUE TIPOS DE DADOS PESSOAIS A TENSAI TRATA?</strong></p>

    <p>Os tipos de Dados Pessoais e a forma como coletamos dependem de <strong>como você se
        relaciona com a</strong> CONTRATANTE <strong>da Empresa e por quê.</strong> Por exemplo, os dados pessoais
      coletados serão diferentes caso você seja um usuário do tipo <strong>FUNCIONÁRIO</strong> ou
      <strong>OPERADOR</strong>, onde só acessará as informações pertinentes a execução da sua função.
    </p>

    <p>Quando você trabalha para um dos nossos CONTRATANTES, como <strong>FUNCIONÁRIO,</strong> e for
      responsável pelo <strong>TENSAI WEB</strong>, será necessária a realização de um cadastro composto por
      Login e senha, além da inserção do seu CPF como fator de identificação de vínculo com a
      Contratante.</p>

    <p>Se você atua como <strong>OPERADOR</strong>, realizando as entrevistas de campo com o <strong>TENSAI
        APP</strong>,
      também será necessário realizar um cadastro, mediante login e senha, para acessar o
      painel de gerenciamento de pesquisas e coleta, além, do seu CPF e sua geolocalização,
      para evitar entrevistas, por mais de um operador, em uma mesma área, comprometendo a
      amostragem.</p>

    <p>O <strong>Tensai WEB</strong> e o <strong>APP</strong> só tratam dados pessoais para viabilizar o acesso e
      executar os
      serviços ofertados ao nosso CONTRATANTE.</p>

    <p>Listamos abaixo algumas situações em que nós podemos tratar seus Dados Pessoais no
      contexto das aplicações do Ecossistema Tensai:</p>

    <table>
      <tr>
        <th style="width: 250px;">Finalidades de Tratamento</th>
        <th>Tipos de Dados Pessoais Utilizados</th>
      </tr>
      <tr>
        <td>
          <p>Cumprir com determinação legal</p>
        </td>
        <td>
          <p>O Tensai coleta automaticamente
            registros de acesso à aplicação, que
            incluem o endereço IP, com data e hora,
            utilizado para acessar a Alfa Group. Esses
            dados são de coleta obrigatória, de
            acordo com a Lei 12.965/2014, mas
            somente serão fornecidos para terceiros
            com a sua autorização expressa ou por
            meio de demanda judicial.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Comunicação com o Tensai</p>
        </td>
        <td>
          <p>Informações sobre sua comunicação,
            incluindo metadados como data, IP e hora
            da comunicação e o conteúdo.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Características do equipamento para
            viabilizar o funcionamento</p>
        </td>
        <td>
          <p>Através da coleta automática sobre as
            características do aparelho utilizado,
            incluindo o sistema operacional, versão,
            informação de hardware, idioma, sinal de
            internet e bateria.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Identificar a obediência aos limites da
            pesquisa de campo determinada pelo
            Contratante para correta coleta da
            pesquisa</p>
        </td>
        <td>
          <p>Geolocalização do dispositivo conectado
            durante a realização das pesquisas.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Permitir que você acesse as
            funcionalidades do Tensai Web e APP, a
            partir das suas permissões por função</p>
        </td>
        <td>
          <p>Login, senha e CPF</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Análise de tráfego dos usuários do
            Tensai</p>
        </td>
        <td>
          <p>Dados de GPS (quando logado na
            plataforma), usuário (nome do usuário e
            matrícula)</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Detecção e prevenção de fraudes, spam
            e incidentes de segurança </p>
        </td>
        <td>
          <p>Login, senha, CPF, Dados de localização</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Verificar a autenticidade das
            informações de acesso </p>
        </td>
        <td>
          <p>Login, Senha e CPF, dados de localização</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Coleta de dados de pesquisa dos
            entrevistados via TENSAI APP</p>
        </td>
        <td>
          <p>Nome, idade, renda, posicionamento político,
            sexo, orientação sexual, dentre outros
            especificados e parametrizados pela
            contratante no TENSAI FORM.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Validação de amostra de pesquisa,
            Detecção e prevenção de fraudes, spam
            e incidentes de segurança</p>
        </td>
        <td>
          <p>Vídeo, foto e áudio</p>
        </td>
      </tr>
    </table>

    <p><strong>3. COM QUEM A EMPRESA COMPARTILHA OS SEUS DADOS PESSOAIS?</strong></p>

    <p>Apenas com as empresas CONTRATANTES e nosso provedor de serviço em nuvem, onde
      armazenamos nossa base de dados.</p>

    <p><strong>4. A TENSAI TRANSFERE DADOS PARA OUTROS PAÍSES?</strong></p>

    <p>Para realizar algumas das atividades envolvidas nos serviços da Plataforma, como o
      armazenamento na nuvem do nosso banco de dados, a TENSAI realiza transferências
      internacionais de dados para outros países.</p>

    <p><strong>5. QUAIS SÃO OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?</strong></p>

    <p><strong>Os Dados Pessoais são seus</strong> e a Lei Geral de Proteção de Dados (Lei nº 13.709/18) lhe
      garante uma série de direitos relacionados. Nós estamos comprometidos em respeitá-los e
      permitir que você, quando quiser, possa exercê-los. Veja a seguir:</p>

    <table>
      <tr>
        <th style="width: 250px;">Seus Direitos</th>
        <th>Explicação</th>
      </tr>

      <tr>
        <td>
          <p>Confirmação e Acesso</p>
        </td>
        <td>
          <p>Você pode solicitar à EMPRESA a confirmação sobre a
            existência de tratamento dos seus Dados Pessoais para
            que, em caso positivo, você possa acessá-los, inclusive por
            meio de solicitação de cópias dos registros que temos sobre
            você.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Correção</p>
        </td>
        <td>
          <p>Você pode solicitar a correção dos seus Dados Pessoais
            caso estes estejam incompletos, inexatos ou
            desatualizados.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Anonimização, bloqueio
            ou eliminação</p>
        </td>
        <td>
          <p>Você pode solicitar (a) a anonimização dos seus Dados
            Pessoais, de forma que eles não possam mais ser
            relacionados a você e, portanto, deixem de ser Dados
            Pessoais; (b) o bloqueio dos seus Dados Pessoais,
            suspendendo temporariamente a sua possibilidade de os
            tratarmos para certas finalidades; e (c) a eliminação dos
            seus Dados Pessoais, caso em que deveremos apagar todos
            os seus Dados Pessoais sem possibilidade de reversão.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Portabilidade</p>
        </td>
        <td>
          <p>Você pode solicitar que a EMPRESA forneça os seus Dados
            Pessoais em formato estruturado e interoperável visando à
            sua transferência para um terceiro, desde que essa
            transferência não viole nossa propriedade intelectual ou
            segredo de negócios.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Informação sobre o
            compartilhamento</p>
        </td>
        <td>
          <p>Você tem o direito de saber quais são as entidades públicas
            e privadas com as quais a EMPRESA realiza uso
            compartilhado dos seus Dados Pessoais. Manteremos, no
            item 3 desta Política, uma indicação das nossas relações
            com terceiros que podem envolver o compartilhamento de
            Dados Pessoais. Em todo caso, se você tiver dúvidas ou
            quiser mais detalhes, você tem o direito de nos solicitar
            essas informações. A depender do caso, podemos limitar as
            informações fornecidas a você caso a sua divulgação possa
            violar a propriedade intelectual ou segredo de negócios.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Informação sobre a
            possibilidade de não
            consentir</p>
        </td>
        <td>
          <p>Você tem o direito de receber informações claras e
            completas sobre a possibilidade e as consequências de não
            fornecer consentimento, quando ele for solicitado pela
            EMPRESA. O seu consentimento, quando necessário, deve
            ser livre e informado. Portanto, sempre que pedirmos seu
            consentimento, você será livre para negá-lo – nesses
            casos, é possível que alguns serviços não possam ser
            prestados.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Revogação do
            consentimento</p>
        </td>
        <td>
          <p>Caso você tenha consentido com alguma finalidade de
            tratamento dos seus Dados Pessoais, você pode sempre
            optar por retirar o seu consentimento. No entanto, isso não
            afetará a legalidade de qualquer Tratamento realizado
            anteriormente à revogação. Se você retirar o seu
            consentimento, é possível que fiquemos impossibilitados de
            lhe prestar certos serviços, mas iremos avisá-lo quando
            isso ocorrer.</p>
        </td>
      </tr>

      <tr>
        <td>
          <p>Oposição</p>
        </td>
        <td>
          <p>A lei autoriza o tratamento de Dados Pessoais mesmo sem
            o seu consentimento ou um contrato conosco. Nessas
            situações, somente trataremos seus Dados Pessoais se
            tivermos motivos legítimos para tanto. Caso você não
            concorde com alguma finalidade de tratamento dos seus
            Dados Pessoais, você poderá apresentar oposição,
            solicitando a sua interrupção.</p>
        </td>
      </tr>
    </table>

    <p><strong>AVISOS IMPORTANTES</strong></p>

    <p>Para sua segurança, sempre que você apresentar uma requisição para exercer seus direitos,
      <strong>a Empresa poderá solicitar algumas informações e/ou documentos
        complementares para que possamos comprovar a sua identidade</strong>, buscando impedir
      fraudes. Fazemos isso para garantir a segurança e a privacidade de todos.
    </p>

    <p>Em alguns casos, <strong>a Empresa pode ter motivos legítimos para deixar de atender a
        uma solicitação de exercício de direitos.</strong> Essas situações incluem, por exemplo, casos
      em que uma revelação de informações específicas poderia violar direitos de propriedade
      intelectual ou segredos de negócio ou de terceiros; bem como casos em que pedidos de
      exclusão de dados não possam ser atendidos em razão da existência de obrigação legal de
      retenção de dados; seja, ainda, para cumprir obrigações legais, regulatórias ou para
      possibilitar a defesa da Empresa ou de terceiros em disputas de qualquer natureza.</p>

    <p>Caso você tenha alguma dúvida sobre essas questões ou sobre como você pode exercer seus
      direitos, fique à vontade para entrar em contato conosco por meio dos canais informados ao
      final desta POLÍTICA.</p>

    <p><strong>6. POR QUANTO TEMPO OS DADOS PESSOAIS SERÃO ARMAZENADOS?</strong></p>

    <p>A Empresa possui uma política de retenção de Dados Pessoais alinhada com a lei aplicável.
      Dados Pessoais são armazenados somente pelo tempo que forem necessários para cumprir
      com as finalidades para as quais foram coletados, salvo se houver qualquer outra razão para
      sua manutenção como, por exemplo, cumprimento de quaisquer obrigações legais,
      regulatórias, contratuais, entre outras permitidas sob a lei.</p>

    <p>Sempre fazemos uma análise técnica para determinar o período de retenção adequado para
      cada tipo de Dado Pessoal coletado, considerando a sua natureza, necessidade de coleta e
      finalidade para a qual ele será tratado, bem como eventuais necessidades de retenção para o
      cumprimento de obrigações ou o resguardo de direitos.</p>

    <p><strong>7. COMO A EMPRESA PROTEGE SEUS DADOS PESSOAIS?</strong></p>

    <p><strong>Nossa responsabilidade é cuidar dos seus Dados Pessoais e utilizá-los somente
        para as finalidades descritas nesta Política.</strong></p>

    <p>Para garantir a sua privacidade e a proteção dos seus Dados Pessoais, adotamos medidas
      administrativas e técnicas para mitigar que fatores internos e externos possam de qualquer
      forma violá-los, dentre as quais, capacitação de nossos time e a implementação de controles
      de acesso a sistemas e ambientes de tratamento de dados, técnicas de criptografia e a
      instalação de barreiras contra o acesso indevido às bases de dados (incluindo firewalls),
      entre outros controles de segurança da informação.</p>

    <p><strong>E você também!</strong></p>

    <p>Entradas e usos não autorizados de terceiros com informações suas, falhas de hardware ou
      software que não estejam sob nosso controle e outros fatores externos podem comprometer
      a segurança dos seus Dados Pessoais.</p>

    <p>Por isso, sua atuação é fundamental para a manutenção de um ambiente seguro para todos.</p>

    <p>Caso você identifique ou tome conhecimento de qualquer fator que comprometa a segurança
      dos seus dados na sua relação com a TENSAI, por favor entre em contato conosco por meio
      das informações de contato indicadas abaixo.</p>

    <p><strong>8. QUANDO E COMO FALAR SOBRE DADOS PESSOAIS COM A TENSAI?</strong></p>

    <p>Sempre que você concluir que seus Dados Pessoais foram tratados de maneira incompatível
      com esta Política ou com as suas escolhas enquanto Titular;</p>

    <p>Se você tiver dúvidas, comentários ou sugestões relacionadas a esta Política e à forma como
      tratamos seus Dados Pessoais, pode entrar em contato conosco. Nós temos um Encarregado
      que está à disposição nos seguintes endereços de contato:</p>

    <p><strong>Encarregado:</strong> Franklin Sampaio</p>
    <p><strong>E-mail para contato:</strong> privacidade@tensai.com.br</p>

    <p><strong>9. MUDANÇAS NA POLÍTICA DE PRIVACIDADE</strong></p>

    <p>Como estamos sempre buscando melhorar os nossos serviços e a forma como operamos,
      esta Política de Privacidade pode passar por atualizações para refletir as melhorias
      realizadas. Desta forma, recomendamos a visita periódica desta página para que você tenha
      conhecimento sobre as modificações efetivadas.</p>

    <p style="text-align: center;"><strong>DATA DA ÚLTIMA ATUALIZAÇÃO:</strong> 17 de agosto de 2023.</p>
  </div>
</div>
