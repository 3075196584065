<section class="main-container">
  <h3>{{ questionData.ordem }} - {{ questionData.enunciado }}</h3>
  <app-questionnaire-badge [model]="badgeModel"></app-questionnaire-badge>

  <div class="question-wrapper">
    <div
      class="alternative-wrapper"
      *ngFor="let alternativa of gerenciador.state.respostas; index as i"
    >
      <app-alternativa-fechada
        *ngIf="alternativa.isFechada"
        [renderType]="
          gerenciador.state.isRespostaMultipla ? 'checkbox' : 'radio'
        "
        (onCheck)="handleCheckProxy($event)"
        [alternativa]="alternativa"
        [actionsBlockedBySituation]="actionsBlockedBySituation"
      ></app-alternativa-fechada>

      <div class="group-answers" *ngIf="alternativa.isAbertaTexto">
        <app-alternativa-aberta-texto
          [renderCheckbox]="!gerenciador.state.isRespostaAbertaTextoUnica"
          [renderType]="
            gerenciador.state.isRespostaMultipla ? 'checkbox' : 'radio'
          "
          (onChange)="
            handleChangeAlternativaAberta(
              $event
            )
          "
          (onCheck)="handleCheckProxy($event)"
          [alternativa]="alternativa"
          [selecionavel]="usuarioPodeSelecionarAlternativa"
          [value]="alternativa.texto"
          [actionsBlockedBySituation]="actionsBlockedBySituation"
        ></app-alternativa-aberta-texto>
      </div>

      <div
        class="group-answers"
        *ngIf="alternativa.isAbertaNumero"
      >
        <app-alternativa-aberta-numero
          (onChange)="
            handleChangeAlternativaAberta(
              $event
            )
          "
          (onCheck)="handleCheckProxy($event)"
          [alternativa]="alternativa"
          [selecionavel]="usuarioPodeSelecionarAlternativa"
          [value]="alternativa.texto"
          [actionsBlockedBySituation]="actionsBlockedBySituation"
        ></app-alternativa-aberta-numero>
      </div>
    </div>
  </div>
  <app-questionnaire-answer-reset
    *ngIf="gerenciador.state.possuiAlteracaoDoAuditor"
    (reset)="resetar()"
  ></app-questionnaire-answer-reset>
</section>
