import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuditoriaObservationsModalService } from "app/componentes/audit-observations/modal-observations.service";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { IFilterSelectEvent } from "app/componentes/filter-select/filter-select";
import { mapCollectionCriteriaToColorObject } from "app/modulos/auditoria-beta/utils/parsers/collectionCriteriaTo";
import { NotificatorService } from "app/notificador/notificator.service";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import {
  CollectionsListSituation,
  CollectionsListSortingAttributes,
  ListagemDeColetasMenu,
  SituacaoListagemDeColetas,
  SurveyListOrderTypes,
  auditSurveyStatus,
  orderTypeEnum,
} from "../../../../../constant";
import {
  IAuditCollections,
  ICriterio,
  IFilterSelect,
  IOptionMeatball,
  ISelectedItems,
} from "../../../../../interfaces";

@Component({
  selector: "app-audit-collections-list",
  templateUrl: "./audit-collections-list.component.html",
  styleUrls: ["./audit-collections-list.component.scss"],
})
export class AuditCollectionsListComponent implements OnInit {
  @Input() data: IAuditCollections[];
  @Input() surveyId: number = null;
  @Output() changeFilter = new EventEmitter<{
    status: IFilterSelect[];
    keywords: string;
    sorting: IFilterSelect[];
  }>(null);

  orderType = orderTypeEnum;

  hasOnlineCollection: boolean = false;

  // Controle dos itens da listagem selecionadas, será feito o controle pelo id.
  selectedItems: ISelectedItems[] = [];

  // Placeholder do seletor de ordenação
  selectedSortingPlaceholder: string = "Ordenar";

  filterConf = {
    icon: FilterIcon.FUNNEL,
    type: FilterTypes.CHECK,
  };

  // Propriedades para o componente de filtro (sorting)
  sortingFilter = {
    icon: FilterIcon.SORTING,
    type: FilterTypes.RADIO,
  };

  // opções do filtro (sorting)
  sortingFilterOptions: IFilterSelect[] = [
    {
      id: 1,
      label: "ID por ordem crescente",
      key: `${CollectionsListSortingAttributes.ID}-${SurveyListOrderTypes.ASC}`,
    },
    {
      id: 2,
      label: "ID por ordem decrescente",
      key: `${CollectionsListSortingAttributes.ID}-${SurveyListOrderTypes.DESC}`,
    },
    {
      id: 3,
      label: "Entrevistadores de A a Z",
      key: `${CollectionsListSortingAttributes.OPERADORES}-${SurveyListOrderTypes.ASC}`,
    },
    {
      id: 4,
      label: "Entrevistadores de Z a A",
      key: `${CollectionsListSortingAttributes.OPERADORES}-${SurveyListOrderTypes.DESC}`,
    },
    {
      id: 5,
      label: "Localidades de A a Z",
      key: `${CollectionsListSortingAttributes.LOCALIDADES}-${SurveyListOrderTypes.ASC}`,
    },
    {
      id: 6,
      label: "Localidades de Z a A",
      key: `${CollectionsListSortingAttributes.LOCALIDADES}-${SurveyListOrderTypes.DESC}`,
    },
  ];

  // opções do filtro
  filterOptions: IFilterSelect[] = [
    {
      id: 1,
      label: "Não auditada",
      key: CollectionsListSituation.NAO_AUDITADA,
    },
    { id: 2, label: "Reprovada", key: CollectionsListSituation.REPROVADA },
    { id: 3, label: "Aprovada", key: CollectionsListSituation.APROVADA },
    { id: 4, label: "Em análise", key: CollectionsListSituation.EM_ANALISE },
  ];

  // controla exibição do mapa
  showMap: boolean = false;
  collectIdMap = null;

  // Estado da modal de observações.
  observationsModal: ModalData = null;

  allowComments: boolean = false;

  textInputTimeoutId: number = null;

  /**
   * Filtros gerais para persistência
   * Inicia ordenado pelo ID por ordem crescente
   */
  filters: {
    status: IFilterSelect[];
    keywords: string;
    sorting: IFilterSelect[];
  } = {
    status: [],
    sorting: [
      {
        id: 1,
        label: "ID por ordem crescente",
        key: "ID-ASC",
      },
    ],
    keywords: "",
  };

  constructor(
    private router: Router,
    private observationsModalService: AuditoriaObservationsModalService,
    private notificatorService: NotificatorService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.initObservationsModal();
  }

  /**
   * retorna o ENUM para ser usado no html
   */
  get surveySituation() {
    return SituacaoListagemDeColetas;
  }

  // função que recebe o texto emitido pelo componente de input de texto
  public handleTextChange($event: string) {
    if (this.textInputTimeoutId !== null) {
      window.clearTimeout(this.textInputTimeoutId);
    }

    this.textInputTimeoutId = window.setTimeout(() => {
      this.filters.keywords = $event;

      this.changeFilter.emit({
        ...this.filters,
        keywords: $event,
      });
    }, 2000);
  }

  public handleChangeStatusFilter($event: IFilterSelectEvent) {
    this.filters.status = [...$event.currentState];
    this.changeFilter.emit({
      ...this.filters,
      status: this.filters.status,
    });
  }

  public handleChangeSorting($event: IFilterSelect[]) {
    const [item] = $event;
    this.filters.sorting = [item];
    this.selectedSortingPlaceholder = item.label;
    this.changeFilter.emit({
      ...this.filters,
      sorting: this.filters.sorting,
    });
  }

  disableButtonArchive(): boolean {
    const findSituationArchive = this.selectedItems.filter(
      (item) => item.situacao === "Arquivada"
    );
    // caso não selecione nenhum item com a situação em arquivado, não desabilita o botão
    if (!findSituationArchive.length) return false;

    // caso tenha items arquivados e items em outras situações, desabilita o botão de arquivar
    if (this.selectedItems.length > findSituationArchive.length) return true;

    return false;
  }

  // define as ações do botão, caso vai arquivar ou desarquivar
  handleActionButton() {
    if (
      !!this.selectedItems.filter((item) => item.situacao === "Arquivada")
        .length
    )
      return true;

    return false;
  }

  // função para arquivar a pesquisa
  public archiveSurvey($event: MouseEvent) {
    $event.stopPropagation();

    this.notificatorService.showInfo("", "Coletas  arquivadas com sucesso!");
  }

  // função para desarquivar a pesquisa
  public unarchiveSurvey($event: MouseEvent) {
    $event.stopPropagation();

    this.notificatorService.showInfo("", "Coletas desarquivadas com sucesso!");
  }

  getCollectionStatusById(id: number) {
    const collection = this.data.find((collection) => collection.id === id);

    return (collection.situacao as SituacaoListagemDeColetas) || null;
  }

  // função responsável por saber qual o item foi clicado no menu meatball para renderizar o mapa da coleta
  getClickOptionMeatball({ id, type }: IOptionMeatball) {
    // id da coleta
    // TODO: responsável mudar para "collectionId" ou um nome mais genérico, o id da coleta não é atrelado apenas ao mapa.
    this.collectIdMap = id;

    // abre o modal do mapa
    if (type === ListagemDeColetasMenu.VER_NO_MAPA) this.showMap = true;
    // navega o usuário para a página de detalhes
    if (type === ListagemDeColetasMenu.VER_COLETA)
      this.router.navigate([
        `/auditoria-beta/detalhes/${this.surveyId}/questionario/${this.collectIdMap}`,
      ]);
    // abre a modal de observações
    if (type === ListagemDeColetasMenu.VER_OBSERVACOES) {
      this.onRequestShowObservations();
    }
  }

  // Inicializando os dados da modal
  initObservationsModal() {
    this.observationsModal = this.observationsModalService.modalData;
  }

  /**
   * Disparado quando o usuário requisita a abertura da modal
   * de observações.
   */
  onRequestShowObservations() {
    /**
     * set flag: indica se a modal de observações
     * deve permitir a inserção de comentários pelo usuário.
     */
    this.allowComments = ![
      SituacaoListagemDeColetas.APROVADA,
      SituacaoListagemDeColetas.REPROVADA,
    ].includes(this.getCollectionStatusById(this.collectIdMap));

    this.observationsModalService.allowComments(this.allowComments);
    this.observationsModalService.show();
  }

  // função repassada ao modal para fechar ele
  handleCloseModal($event: boolean): void {
    this.showMap = $event;
  }

  /**
   * Obtem o status da coleta e retorna a cor do mesmo.
   * @param status: status da coleta
   * @returns variavel com a cor do status
   */
  getCollectionStatusColor(status: string) {
    return auditSurveyStatus.find((s) => s.key === status).color;
  }

  /**
   * Metódo responsável por renderizar os critérios da coleta
   *
   * @param criterion = array de objetos contendo o critério e o status do mesmo [critério | atendido]
   * @returns critérios que serão renderizados
   */
  renderCriteria(criteria: ICriterio[]) {
    // valida se a coleta é 100% online
    this.hasOnlineCollection = criteria.some(
      (criterion) =>
        criterion.nome === "COLETA_ONLINE" && criterion.atendido === true
    );

    return mapCollectionCriteriaToColorObject(criteria);
  }
}
