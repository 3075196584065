import { Injectable } from '@angular/core';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { CrudService } from 'app/servico/requestService/crudService';
import { Grupo } from '../grupo';

@Injectable({
  providedIn: 'root',
})
export class GrupoService extends CrudService<Grupo> {

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/grupos`);
  }

}
