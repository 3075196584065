<div class="container-header">
  <div class="header-left">
    <div class="content">
      <h1 class="title">Nova avaliação</h1>
      <p class="description">
        Preencha todos os campos para cadastrar uma nova avaliação
      </p>
    </div>
  </div>

  <div class="header-right">
    <ul>
      <li *ngFor="let buttonHeader of menuSteps">
        <button
          (click)="handleStepClick(buttonHeader.id)"
          [ngClass]="
            activeStepHeader == buttonHeader.id
              ? 'active'
              : activeStepHeader > buttonHeader.id
              ? 'completed'
              : 'disabled'
          "
        >
          <div class="header">
            <div
              *ngIf="buttonHeader.id < activeStepHeader"
              [ngClass]="pulouMarcacaoDePulo &&
                buttonHeader.id === 3 ? 'singleCheck' : 'doubleCheck'"
              alt="Passo completado"
            ></div>
            <!-- <i
              *ngIf="buttonHeader.id < activeStepHeader"
              class="fa fa-check"
              aria-hidden="true"
            ></i> -->

            <p class="subtitle" *ngIf="buttonHeader.id == activeStepHeader">
              Passo atual
            </p>
            <p class="subtitle" *ngIf="buttonHeader.id > activeStepHeader">
              Próximo
            </p>

            <span class="count">0{{ buttonHeader.id }}</span>
          </div>

          <div class="title">
            <h2>{{ buttonHeader.title }}</h2>
          </div>
          <span class="line"></span>
          <p class="description">{{ buttonHeader.description }}</p>
        </button>
      </li>
    </ul>
  </div>
</div>
