// tslint:disable-next-line:max-line-length
import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ChartConfiguration } from './model/chartConfiguration';
import { Subject, Subscription } from 'rxjs';
import { NgChartjsService, NgChartjsDirective } from 'ng-chartjs';
declare var Chart: any;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {

  /**
   * Referência ao objeto Chart do chartjs
   */
  chart: any;
  @ViewChild(NgChartjsDirective, {static: true}) set appChart(directive: NgChartjsDirective) {
    this.chart = directive;
  }

  // @ViewChild('chart', { read: ElementRef }) chartElRef: ElementRef;

  @Input() chartConfigurationSubject: Subject<ChartConfiguration>;
  private chartConfigurationSubscription: Subscription;

  chartConfiguration;

  constructor(private ngChartjsService: NgChartjsService) { }

  ngOnInit() {

    this.chartConfigurationSubject.subscribe((chartConfiguration) => {
      this.chartConfiguration = chartConfiguration;

      /**
       * Infelizmente o Chartjs não atualiza automaticamente
       * os plugins, então, caso o chart tenha algun plugin,
       * removemos ele pela nova implementação fornecida.
       */
      if (this.chart.chart) {
        if (this.chartConfiguration.inlinePlugin) {
          // tslint:disable-next-line:max-line-length
          this.substituirPlugins(this.chart.chart._plugins._init, this.chartConfiguration.inlinePlugin);
        }
        setTimeout(() => this.chart.refresh(), 0);
      }
    });
  }

  /**
   * Substitui plugins de determinado chart baseado no seu
   * id e pela implementação do lifecycle hook q ele utiliza
   * @param originalPluginsWrapper Wrapper que encapsula os plugins antigos
   * @param plugins array de novos plugins a serem utilizados
   */
  substituirPlugins(originalPluginsWrapper, plugins: []) {

    plugins.forEach((newPlugin: any) => {

      originalPluginsWrapper.forEach((oldPluginWrapper) => {

        if (newPlugin.id === oldPluginWrapper.plugin.id) {
          const newPluginCallBacksName = Object.keys(newPlugin);

          newPluginCallBacksName.forEach((newPluginCallBackName: string) => {
            oldPluginWrapper.plugin[newPluginCallBackName] = newPlugin[newPluginCallBackName];
          });
        }

      });
    });
  }

  ngOnDestroy() {
    if (this.chartConfigurationSubscription) {
      this.chartConfigurationSubscription.unsubscribe();
    }
  }

}
