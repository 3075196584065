import { Component, OnInit } from "@angular/core";
import { ModalData } from "./modal";
import { ModalService } from "./modal.service";

enum CloseEventTarget {
  HEADER = 'HEADER',
  ACTION_NEGATIVE = 'ACTION_NEGATIVE',
  ACTION_POSITIVE = 'ACTION_POSITIVE',
}

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  // estado vazio do modalData
  modalData: ModalData;
  // se o modal está aberto ou não
  show: boolean = false;
  // model do checkbox
  checkbox: boolean = false;
  // model do input
  inputValue: string = null;
  // Array de erros
  inputErros: Array<string> = [];
  // informa se um comportamento de loading deve ser atrelado à modal
  isLoading: boolean = false;
  // controla a exibição da senha no input
  hide = true;

  constructor(private modalService: ModalService) {}

  get closeEventType() {
    return CloseEventTarget;
  }

  // usando o OnInit para se inscrever nos métodos assim que o componente é inicializado
  ngOnInit() {
    // subscribe que retorna se o modal ta aberto ou não
    this.modalService.getShowModal().subscribe({
      next: (curValue) => (this.show = curValue),
    });

    // subscribe que retorna os valores da modal
    this.modalService.getDataModal().subscribe({
      next: (curValue) => (this.modalData = curValue),
    });

    // subscribe que atribui o comportamento de loading
    this.modalService.getLoading().subscribe({
      next: (nextValue) => (this.isLoading = nextValue),
    });

    // subscribe que atribui o novo valor do checkbox
    this.modalService.getCheckbox().subscribe({
      next: (nextValue) => (this.checkbox = nextValue),
    });

    // subscribe que atribui o novo valor do input
    this.modalService.getInput().subscribe({
      next: (nextValue) => (this.inputValue = nextValue),
    });

    // subscribe que retorna os erros do input
    this.modalService.getInputErrors().subscribe({
      next: (nextValue) => (this.inputErros = nextValue),
    });
  }

  // função que fecha o modal
  close(target?: CloseEventTarget) {
    // Verifica se a ação de fechar foi disparada através do header e executa a possível callback repassada.
    if (target === CloseEventTarget.HEADER && this.modalData.closeFromHeaderCallback) {
      this.modalData.closeFromHeaderCallback();
    }

    this.modalService.handleCloseModal();
  }

  // função que pega o valor do checkbox
  handleCheckbox(checkValue: boolean) {
    this.modalService.handleCheckbox(checkValue);
  }

  // função que pega o valor do input
  handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement
    this.modalService.handleInput(value);
  }

  // função que é chamada no clique do botão positivo
  handlePositiveCallback() {
    if (this.modalData.positiveCallback) {
      this.modalData.positiveCallback();
    }
    if (this.modalData.close) {
      this.close(CloseEventTarget.ACTION_POSITIVE);
    }
  }

  // função chamada no botão negativo
  handleNegativeCallback() {
    if (this.modalData.negativeCallback) {
      this.modalData.negativeCallback();
    }

    this.close(CloseEventTarget.ACTION_NEGATIVE);
  }

  /**
    * Toggle para o atributi hide que controla a exibição da senha no input
    */
  toggleHidePassword(): void {
    this.hide = !this.hide;
  }

}
