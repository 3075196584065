import { ValidationErrors, UntypedFormControl } from '@angular/forms';
import Big from 'big.js';

/**
 * Validador de cotas, deve ser passado como parametro o FormArray
 * que contem os formControl com as porcentagens e o limite da soma das
 * porcentagens, assim poderemos verificar se a soma das cotas de cada control
 * equivale a 100%.
 */
export default (cotaControlFormArray: UntypedFormControl[], totalSomaPorcentagens: number = 100) => {

  return (cotaControl: UntypedFormControl): ValidationErrors => {

    if (cotaControlFormArray.length === 0) {
      return {
        cotaInvalida: true,
      };
    }

    const somaCotas = cotaControlFormArray.map((cotaControlToSum) => {
      // tslint:disable-next-line: max-line-length
      const cotaPercentual = String(cotaControlToSum.value ? cotaControlToSum.value : '0');
      const cotaPercentualNumber = Number(cotaPercentual.replace(',', '.').replace(' ', ''));

      return cotaPercentualNumber;
    })
    .map((cotaControlValue: number) => {
      return Big(cotaControlValue);
    })
    .reduce((prev, next) => prev.plus(next));

    if (cotaControl.dirty) {
      cotaControlFormArray.forEach((cotaControlToUpdate) => {
        if (cotaControlToUpdate.pristine) {
          cotaControlToUpdate.updateValueAndValidity();
        }
      });
    }
    cotaControl.markAsPristine();

    // tslint:disable-next-line: max-line-length
    return !somaCotas.eq(100) ? { cotaInvalida: true } : null;
  };
};
