/**
 * @class padronização do processamento de statusCode nas requests
 */
export class StatusCode {

  private statusCode: number;
  private order: string = "";
  private callbackResult: any;

  constructor(statusCode: number) {
     this.statusCode = statusCode;
     this.order = this.getErrorOrder();
  }

  /**
   * Realizada chamada do método de acordo com statusCode passado no construtor
   * @returns
   */
  private getErrorOrder() {
    if (this.statusCode >= 100 && this.statusCode < 200) {
        return "1xx";
    } else if (this.statusCode >= 200 && this.statusCode < 300) {
        return "2xx";
    } else if (this.statusCode >= 300 && this.statusCode < 400) {
        return "3xx";
    } else if (this.statusCode >= 400 && this.statusCode < 500) {
        return "4xx";
    } else if (this.statusCode >= 500 && this.statusCode < 600) {
        return "5xx";
    } else {
        return "";
    }
  }

  /**
   * Método que para processamento em caso de status_code 1xx
   */
  public process1xx(callback: Function): StatusCode {
    if (this.order === "1xx") {
        this.callbackResult = callback();
    }
    return this;
  }

  /**
   * Método que para processamento em caso de status_code 2xx
   */
  public process2xx(callback: Function): StatusCode {
      if (this.order === "2xx") {
          this.callbackResult = callback();
      }
      return this;
  }

  /**
   * Método que para processamento em caso de status_code 3xx
   */
  public process3xx(callback: Function): StatusCode {
      if (this.order === "3xx") {
          this.callbackResult = callback();
      }
      return this;
  }

  /**
   * Método que para processamento em caso de status_code 4xx
   */
  public process4xx(callback: Function): StatusCode {
      if (this.order === "4xx") {
          this.callbackResult = callback();
      }
      return this;
  }

  /**
   * Método que para processamento em caso de status_code 5xx
   */
  public process5xx(callback: Function): StatusCode {
      if (this.order === "5xx") {
          this.callbackResult = callback();
      }
      return this;
  }

  /**
   * Realiza a execução do callBack passado
   */
  public build(): any {
      return this.callbackResult;
  }

  /**
   * @returns instância da class StatusCode
   */
  public static builder(statusCode: number): StatusCode {
      return new StatusCode(statusCode);
  }

}
