<section class="interviewConfig container-white">
  <div class="headerConfig">
    <span class="fa fa-object-group"></span>
    <h3>Múltipla ordem</h3>
  </div>
  <div class="contentConfig">
    <div class="section sg-input-group">
      <div class="campo">
        <label>Título da pergunta</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="título da pergunta" title="título principal" type="text" formControlName="nome"  maxlength="250">
        <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
          A pergunta deve possuir um titulo de até 250 caracteres
        </span>
      </div>
      <div class="campo">
        <label>Descrição</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="descrição da pergunta" title="descrição da pergunta do tipo múltipla ordem" type="text" formControlName="descricao"  maxlength="150">
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
          A pergunta deve possuir uma descrição de até 150 caracteres
        </span>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">
          <h4>Alternativas</h4>
        </div>
        <div class="subSectionContent row">
          <div class="col-12" *ngFor="let alternativa of getControlsFromAbstractFormArray(controls.alternativas); let i = index">
            <!-- <app-alternativa-tipada-marcacao
            [formGroup]="alternativa"
            (cotaChange)="calcularCotaNumerica()"
            ></app-alternativa-tipada-marcacao> -->
          </div>
        </div>
        <span class="error-message" [hidden]="!(showErrors && controls.alternativas.errors)">
          A pergunta deve possuir pelo menos uma alternativa
        </span>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">
          <h4>Quantidade (min e max)</h4>
        </div>
        <div class="subSectionContent row">
          <div class="col-6">
            <div class="campo">
              <input title="quantidade mínina de alternativas" type="text" mascara-numero formControlName="qtdMinima"  maxlength="1" />
              <span class="error-message"
                [hidden]="!(showErrors && controls.qtdMinima.errors && controls.qtdMinima.errors.maiorQueDeveria)">
                A quantidade minima deve ser menor que a quantidade máxima.
              </span>
              <span class="error-message"
                [hidden]="!(showErrors && controls.qtdMinima.errors && controls.qtdMinima.errors.valorMaximoPermitido)">
                A quantidade mínima deve ser menor ou igual ao total de alternativas adicionadas.
              </span>
            </div>
          </div>
          <div class="col-6">
            <div class="campo">
              <input title="quantidade máxima de alternativas" type="text" mascara-numero formControlName="qtdMaxima"  maxlength="1" />
              <span class="error-message"
                [hidden]="!(showErrors && controls.qtdMaxima.errors && controls.qtdMaxima.errors.menorQueDeveria)">
                A quantidade máxima deve ser maior que a quantidade mínima.
              </span>
              <span class="error-message"
                [hidden]="!(showErrors && controls.qtdMaxima.errors && controls.qtdMaxima.errors.valorMaximoPermitido)">
                A quantidade máxima deve ser menor ou igual ao total de alternativas adicionadas.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="actionConfig">
        <button class="sg-btn sg-alternate" (click)="cancelar()">Cancelar</button>
        <button class="sg-btn sg-secondary" (click)="onChange()" *ngIf="modificacaoPermitida && !passoBloqueado">Salvar</button>
      </div>
    </div>
  </div>
</section>
