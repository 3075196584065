import { IAnswaresList } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { PerguntaQuestionarioAuditadoManager } from "../..";
import { AlternativaAuditada } from "../../entidade/alternativa";
import { TipoAlternativa } from "app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa";
import { Processor } from "../ProcessorInterface";

interface RespostasMapeadasPorOrigem {
  [key: number]: IAnswaresList;
}

/**
 * Processador de respostas auditadas para pergunta do tipo simples;
 * Basicamente lida com a parte de inicialização de respostas do auditor/respostas de
 * origem do entrevistador e externalizando os outputs processados
 */
export class RespostaQuestionarioTipoSimplesProcessor implements Processor {
  constructor(context: PerguntaQuestionarioAuditadoManager) {
    this.context = context;
  }

  private context: PerguntaQuestionarioAuditadoManager;

  private respostasProcessadas: AlternativaAuditada[] = [];

  private respostasAuditorMapeadas: RespostasMapeadasPorOrigem = null;
  private respostasEntrevistadorMapeadas: RespostasMapeadasPorOrigem = null;

  public processar() {
    this.iniciarRespostasPorOrigem();
    this.inicializarRespostaAlternativas();
  }

  /**
   * TODO: Melhorar esse código depois
   */
  public inicializarRespostaAlternativas(
    utilizarRespostasOriginais: boolean = false
  ) {
    let ordemSelecao = 0;

    const respostasProcessadas = this.context
      .getAlternativasOriginais()
      .map((alternativaOriginal) => {
        let respostasPorOrigem: RespostasMapeadasPorOrigem;

        if (utilizarRespostasOriginais || !this.respostasAuditorMapeadas) {
          respostasPorOrigem = this.respostasEntrevistadorMapeadas;
        } else {
          respostasPorOrigem = this.respostasAuditorMapeadas;
        }

        const resposta = respostasPorOrigem[alternativaOriginal.id];

        const isSelecionada = !!resposta;

        if (isSelecionada) {
          ordemSelecao += 1;
        }

        const respostaEntrevistador = this.respostasEntrevistadorMapeadas[alternativaOriginal.id];;

        const respostaOriginal = this.context.utils.isPerguntaRespostaUnica()
          ? Object.values(this.respostasEntrevistadorMapeadas).shift()
          : respostaEntrevistador;
        
        const isRespostaOriginal = respostaOriginal?.id_alternativa === alternativaOriginal.id

        const alternativaAuditada = AlternativaAuditada.ofSimples(
          alternativaOriginal.id,
          respostaOriginal?.id_alternativa,
          isRespostaOriginal,
          alternativaOriginal.tipo_alternativa as TipoAlternativa,
          isSelecionada,
          alternativaOriginal.ordem,
          ordemSelecao,
          resposta?.texto,
          alternativaOriginal.enunciado
        );

        return alternativaAuditada;
      });

    this.respostasProcessadas.push(...respostasProcessadas);
  }

  private iniciarRespostasPorOrigem() {
    const respostasAuditor = this.context.getRespostasAuditor();
    const respostasEntrevistador = this.context.getRespostasEntrevistador();

    if (Array.isArray(respostasAuditor) && respostasAuditor.length) {
      this.respostasAuditorMapeadas =
        this.mapearRespostaPorIdAlternativa(respostasAuditor);
    }

    if (Array.isArray(respostasEntrevistador)) {
      this.respostasEntrevistadorMapeadas = this.mapearRespostaPorIdAlternativa(
        respostasEntrevistador
      );
    }
  }

  private mapearRespostaPorIdAlternativa(respostas: IAnswaresList[]) {
    const relacaoRespostaPorIdAlternativa = {} as RespostasMapeadasPorOrigem;

    for (let i = 0; i < respostas.length; i++) {
      const respostaIterada = respostas[i];
      relacaoRespostaPorIdAlternativa[respostaIterada.id_alternativa] =
        respostaIterada;
    }

    return relacaoRespostaPorIdAlternativa;
  }

  public atualizarRespostaAberta(
    alternativa: AlternativaAuditada,
    valor: string
  ) {
    if (alternativa instanceof AlternativaAuditada) {
      alternativa.setTexto(valor);
    }
  }

  public getRespostas() {
    return this.respostasProcessadas;
  }

  public resetarParaOriginal(): void {
    this.respostasProcessadas.length = 0;

    this.inicializarRespostaAlternativas(true);
  }

}
