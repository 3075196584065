<!-- ICONES DO MATERIAL, DEPENDENCIA DO STEPPER -->
<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"> -->

<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-pesquisas"></i>
      <h2 [routerLink]="'/pesquisa'">Pesquisas</h2>
      <span>/</span>
      <h4>{{ tituloPesquisa }}</h4>
    </div>
    <div class="action-header">
      <button
        class="sg-btn sg-secondary"
        disabled
        title="listar todos os operadores"
      >
        ADICIONAR NOVO
      </button>
    </div>
  </div>
  <!-- <button (click)="replayActions()">REPLAY!</button> -->
  <form
    class=""
    #form="ngForm"
    [formGroup]="formGroup"
    (keydown.enter)="$event.preventDefault()"
  >
    <mat-horizontal-stepper
      labelPosition="bottom"
      [linear]="true"
      #stepper
      (selectionChange)="atualizarPosicaoStep($event)"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>
      <!-- CARACTERISTICAS -->
      <mat-step [stepControl]="caracteristicasStep">
        <ng-template matStepLabel> Características </ng-template>
        <app-caracteristicas> </app-caracteristicas>
      </mat-step>

      <!-- QUESTIONARIO -->
      <mat-step [stepControl]="questionarioStep">
        <ng-template matStepLabel>
          <div
            class="stepper-click-zone"
            (click)="solicitarExibicaoErrosPasso(1)"
          ></div>
          Questionário
        </ng-template>
        <app-questionario [indicadorMudancaStatus]="subjectChangeStatus">
        </app-questionario>
      </mat-step>

      <!-- MARCACOES -->
      <mat-step [stepControl]="marcacoesStep">
        <ng-template matStepLabel>
          <div
            class="stepper-click-zone"
            (click)="solicitarExibicaoErrosPasso(2)"
          ></div>
          Marcações
        </ng-template>
        <app-marcacoes> </app-marcacoes>
      </mat-step>

      <!-- LOCALIZACAO -->
      <mat-step
        [stepControl]="localidadesStep"
        *ngIf="verificaDisponibilidadeDoPasso4()"
      >
        <ng-template matStepLabel>
          <div
            class="stepper-click-zone"
            (click)="solicitarExibicaoErrosPasso(3)"
          ></div>
          Vínculos das localidades
        </ng-template>

        <app-vinculos></app-vinculos>
      </mat-step>

      <!-- <mat-step >
        <ng-template matStepLabel >
          <div class="stepper-click-zone" (click)="solicitarExibicaoErrosPasso(4)"></div>
          Gerenciador de cotas
        </ng-template>

        <app-gerenciador-cotas></app-gerenciador-cotas>
      </mat-step> -->
    </mat-horizontal-stepper>
  </form>

  <div class="stepNavigation">
    <button
      class="sg-btn sg-alternate"
      (click)="voltarPasso()"
      *ngIf="this.stepper.selectedIndex === 0"
      [routerLink]="'/pesquisa'"
    >
      CANCELAR
    </button>
    <button
      class="sg-btn sg-alternate"
      (click)="voltarPasso()"
      *ngIf="this.stepper.selectedIndex !== 0"
    >
      ANTERIOR
    </button>
    <button
      class="sg-btn sg-secondary"
      (click)="solicitarExibicaoErrosPassoAndAvancarPasso()"
      *ngIf="!exibirOpcaoSalvar && !salvarPasso3"
    >
      PRÓXIMO
    </button>
    <button
      class="sg-btn sg-secondary"
      (click)="prepararParaSalvar()"
      *ngIf="(exibirOpcaoSalvar && !isEdicao) || (salvarPasso3 && !isEdicao)"
    >
      CONCLUIR
    </button>
    <button
      class="sg-btn sg-secondary"
      (click)="prepararParaSalvar()"
      *ngIf="(exibirOpcaoSalvar && isEdicao) || (salvarPasso3 && isEdicao)"
    >
      SALVAR
    </button>
  </div>
  <div
    class="draftContainer"
    (click)="prepararParaSalvar()"
    title="Salvar rascunho"
  >
    <span class="fa fa-floppy-o"></span>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>
