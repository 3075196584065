import { Endereco } from './endereco';
import { Pessoa } from './pessoa';
import { Contato } from './contato';
import { Usuario } from './usuario';
import { FotoEnviada } from './fotoEnviada';

export abstract class PessoaBasica {

  id: number;
  nome: string;
  pessoa: Pessoa;
  contato: Contato;
  usuario: Usuario;
  foto: string;
  fotoEnviada: FotoEnviada;
  endereco: Endereco;

  constructor() {
    this.pessoa = new Pessoa();
    this.contato = new Contato();
    this.usuario = new Usuario();
    this.endereco = new Endereco();
    this.fotoEnviada = new FotoEnviada();
  }

}
