import { Directive, HostListener } from "@angular/core";
import { stringFormatter } from "../misc/stringFormatter";
import * as moment from "moment";

@Directive({
  selector: "[mascara-data]",
})
export class MascaraDataDirective {
  constructor() {}
  /**
   * Aplica a máscara de data no input
   * @param event input onde a acontece o event
   */
  @HostListener("input", ["$event"]) aplicarMascara(event) {
    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(
      event.target.value
    );

    // adicionando primeira barra entre dias e meses
    const valorPrimeiraFormatacao = numberString.replace(/(\d{2})/, "$1/");

    // adicionando segundo barra entre meses e anos
    const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(
      /(\/\d{2})/,
      "$1/"
    );
    event.target.value = valorSegundaFormatacao.slice(0, 10);
  }

  /**
   * Valida se o valor da data é válido e se o formato é válido
   * @param event input onde a acontece o event
   */
  @HostListener("blur", ["$event"]) validateDate(event) {
    const inputValue: string = `${event.target.value}`;
    const isValid =
      !!inputValue.length &&
      moment(inputValue, "DD-MM-YYYY").isValid() &&
      inputValue.length >= 8 &&
      inputValue.length === 10;
    if (!isValid) {
      event.target.value = "";
    }
  }
}
