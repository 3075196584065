<div class="menu-container">
  <i class="fa-solid fa-ellipsis"></i>

  <div class="kebab" [ngClass]="isBottomMenuKebab ? 'has-bottom' : 'has-top'" *ngIf="isMenuVisible">
    <!-- body -->
    <div class="menu">
      <!-- row 1 -->
      <button class="menu--item" (click)="handleMethod('link-open')">
        <i class="fa-regular fa-eye"></i>
        <p>Ver Questionário</p>
      </button>
      <!-- row 2 -->
      <button class="menu--item" (click)="
          !(estadoExecucao || estadoArquivada || estadoConcluida)
            ? handleMethod('editar')
            : handleMethod('information')
        ">
        <i *ngIf="!(estadoExecucao || estadoArquivada || estadoConcluida)" class="fa-regular fa-pen-to-square"></i>
        <i *ngIf="(estadoExecucao || estadoArquivada || estadoConcluida)" class="fa-regular fa-square-info"></i>
        <p>{{ !(estadoExecucao || estadoArquivada || estadoConcluida) ? "Editar" : "Informações gerais" }}</p>
      </button>
      <!-- row 3 -->
      <button class="menu--item" [disabled]="!possuiAmostrasOnline || !estadoExecucao"
        (click)="possuiAmostrasOnline ? handleMethod('link-copy') : null">
        <i class="fa-regular fa-link"></i>
        <p>Link para coleta online</p>
      </button>
      <button class="menu--item" (click)="handleMethod('duplicar')">
        <i class="fa-regular fa-clone"></i>
        <p>Duplicar</p>
      </button>
      <!-- row 4 -->
      <button class="menu--item" [disabled]="!possuiLocalidadeVinculo" (click)="
          possuiLocalidadeVinculo ? handleMethod('view-allocations') : null
        ">
        <i class="fa-regular fa-file-lines"></i>
        <p>Distribuição da Amostra</p>
      </button>
      <!-- row 5 -->
      <button class="menu--item" [disabled]="estadoExecucao" (click)="
          estadoArquivada
            ? handleMethod('desarquivar')
            : handleMethod('arquivar')
        ">
        <i [ngClass]="{
          'fa-light fa-box-archive': !estadoArquivada,
          'fa-thin fa-boxes-packing': estadoArquivada
        }"></i>
        <p>{{ estadoArquivada ? "Desarquivar" : "Arquivar" }}</p>
      </button>
      <!-- row 6 -->
      <button class="menu--item" [disabled]="!estadoRascunho" (click)="handleMethod('executar')">
        <i class="fa-light fa-play"></i>
        <p>Executar</p>
      </button>
      <!-- row 7 -->
      <button class="menu--item" [disabled]="!estadoExecucao"
        (click)="estadoExecucao ? handleMethod('concluir') : null">
        <i class="fa-sharp fa-regular fa-trophy"></i>
        <p>Concluir</p>
      </button>
      <!-- row 8 -->
      <button class="menu--item" [disabled]="!(estadoExecucao || estadoConcluida || estadoArquivada)" (click)="
          estadoExecucao || estadoConcluida || estadoArquivada
            ? handleMethod('exportar')
            : null
        ">
        <i class="fa-light fa-file-export"></i>
        <p>Exportar Dados</p>
      </button>
      <!-- row 9 -->
      <button class="menu--item" [disabled]="!(estadoRascunho)" (click)="
        estadoRascunho
          ? handleMethod('excluir')
          : null
      ">
        <i class="fa-sharp fa-regular fa-trash"></i>
        <p>Excluir</p>
      </button>
    </div>
  </div>
</div>
