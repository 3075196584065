import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { formsLocation } from "app/infraestrutura/apiLocation";
import { PesquisaService } from "app/modulos/pesquisa-beta/listagem/servico/pesquisas";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { ClipboardService } from "ngx-clipboard";
import { Status } from "../../../../../modulos/pesquisa-beta/listagem/pesquisas.component";
import { PesquisaRequest } from "../../pesquisas-cadastro.model";
import { SafeUrl } from "@angular/platform-browser";

interface SelectEvent {
  target: {
    value: Status;
  };
}

@Component({
  selector: "app-pesquisas-conclusao",
  templateUrl: "./pesquisas-conclusao.component.html",
  styleUrls: ["./pesquisas-conclusao.component.scss"],
})
export class PesquisasConclusaoComponent implements OnInit, OnChanges {
  //  Input para receber o titulo da pesquisa
  @Input() surveyTitle: string;
  @Input() research: PesquisaRequest;
  @Output() selectedTitle: EventEmitter<string> = new EventEmitter();
  @Output() onChange: EventEmitter<void> = new EventEmitter();

  // Estado usado para controlar valor do seletor de status da pesquisa
  selectStatusOption: Status = "RASCUNHO";

  // Estado para controlar a visibilidade da informação adicional do icone de informação
  isInfoVisible: boolean = false;

  // Estado temporário fake para representar a informção se a pesquisa tem coletas online ou não
  pesquisaTemColetasPresenciais: boolean = true;

  // Link do formulário online
  formLink: string = `${formsLocation}?token=`;

  // Variável para verificar se pesquisa não tem amostra online
  isOnlyPresencial: boolean = false;

  qrCodeDownloadLink: SafeUrl = "";

  constructor(
    private pesquisaService: PesquisaService,
    private clipboardService: ClipboardService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  /**
   * Recupera o token para criar o link amigavel
   */
  ngOnChanges(): void {
    const token = this.research.configuracaoPesquisa.token
    if (this.research && token) {
      this.pesquisaService.getFriendlyToken(token).subscribe({
        next: (token) => {
          this.formLink = `${formsLocation}/${token.token_amigavel}`;
          this.clipboardService.copy(this.formLink);
        },
        error: (err) => {
          this.errorHandlerService.handleError(err, "Erro ao copiar link");
        },
      });
    }
  }

  ngOnInit() {
    if (
      this.research &&
      this.research.configuracaoPesquisa.amostrasOnline === 0
    ) {
      this.isOnlyPresencial = true;
    }
  }

  /**
   * Obtem a url para download do arquivo de qrcode
   * @param url: url da pesquisa
   */
  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  // Função para alterar o evento de seleção de uma nova opção do select
  handleSelectOption(event: any) {
    this.selectStatusOption = event.target.value;
    if (this.selectStatusOption === "EXECUCAO") {
      this.onChange.emit();
    }
    this.selectedTitle.emit(this.selectStatusOption);
  }

  // Função para alterar visibilidade da informação
  toggleInfoVisibility() {
    this.isInfoVisible = !this.isInfoVisible;
  }

  // função usada para copiar o link
  copyToClipboard() {
    this.clipboardService.copy(this.formLink);
    this.notificatorService.showInfo(
      "Link copiado",
      "Compartilhe sua pesquisa"
    );
  }
}
