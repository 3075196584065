<form class="form-inline" #form="ngForm" [formGroup]="formGroup" (ngSubmit)="form.valid && salvar(form.value, form)">
  <div class="row form sg-input-group section">
    <div class="col-lg-4 col-md-6">
      <div class="campo" formGroupName="localidadePai">
        <label for="">Localidade pai</label>
        <input title="filtro de busca por localidade" placeholder="Selecione um pai já cadastrado" type="text"
          class="auto-complete" (input)="carregarLocalidades($event)" formControlName="nome" matInput
          [matAutocomplete]="auto" maxlength="50">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option class="teste" (onSelectionChange)="localidadePaiSelecionada(localidade.id)"
            (click)="localidadePaiSelecionada(localidade.id)" *ngFor="let localidade of localidades"
            [value]="localidade.nome">
            {{localidade.nome}}
          </mat-option>
        </mat-autocomplete>
        <span class="error-message"></span>
      </div>
    </div>
    <div class="col-lg-2 col-md-6">
      <div class="campo">
        <label for="">Nova localidade *</label>
        <input mascara-primeiro-digito-sem-espaco type="text" title="nome da localidade" placeholder="Ex.:Brasília"
          formControlName="nome" (input)="carregarLocalidadeComDetalhe($event)" maxlength="50" matInput
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option class="teste" (onSelectionChange)="carregarLocalidadeComDetalhe(localidade.id)"
            (click)="selecionarLocalidade(localidadeSelecionada.place_id)"
            *ngFor="let localidadeSelecionada of localidadesDetalhadas" [value]="localidadeSelecionada.address.city">
            <!-- Shopping Manaira -->
            <!-- UPA Cruz das Armas -->
            <!-- Rua Adauto Francisco da Silva, Gramame, João Pessoa - PB -->
            <!-- Gramame, João Pessoa - PB -->
            <!-- João Pessoa - PB -->
            <!-- {{ localidadeSelecionada.address?.road }}
              {{ localidadeSelecionada.address?.town }}
              {{ localidadeSelecionada.address?.city }} -->
            {{localidadeSelecionada.address?.road ? localidadeSelecionada.address?.road
            : localidadeSelecionada.address?.city
            ? localidadeSelecionada.address?.city
            : localidadeSelecionada.address?.town}}
            - {{localidadeSelecionada.address?.state}}
          </mat-option>
        </mat-autocomplete>
        <span class="error-message" *ngIf="formControls.nome.errors">
          Deve ser informado um nome de até 50 caracteres
        </span>
      </div>
    </div>

    <div class="col-lg-2 col-md-4">
      <div class="campo">
        <label for="">Latitude *</label>
        <input title="latitude da localidade" type="text" mascara-lat-long formControlName="latitude"
          placeholder="Ex.: -7.1194518" min="0">
        <span class="error-message" *ngIf="formControls.latitude.errors">
          Deve ser informada uma latitude de valor entre -90 e 90 e de até 15 dígitos.
        </span>
      </div>
    </div>
    <div class="col-lg-2 col-md-4">
      <div class="campo">
        <label for="">Longitude *</label>
        <input type="text" title="longitude da localidade" mascara-lat-long formControlName="longitude"
          placeholder="Ex.: -34.8685103" min="0">
        <span class="error-message" *ngIf="formControls.longitude.errors">
          Deve ser informada uma longitude de valor entre -180 e 180 e de até 15 dígitos.
        </span>
      </div>
    </div>
    <div class="col-lg-2 col-md-4">
      <div class="campo">
        <label for="">Raio *</label>
        <input #inputRaio title="raio da localidade" type="text" mascara-raio formControlName="raio" placeholder="Mts"
          maxlength="7">
        <span class="error-message" *ngIf="formControls.raio.errors">
          O raio informado deve ter até 7 dígitos
        </span>
      </div>
    </div>

    <!-- FILHOS SELECIONADOS -->
    <div *ngIf="!edicao" class="col-lg-12 campo">
      <div class="cloudTags">
        <label for="">Filhos</label>
        <div class="tags">
          <div class="tagItem" *ngFor="let localidade of localidadesFilhas"
            [title]="buildLocalidadeFilhaTitle(localidade)">
            <span>{{localidade.nome}} </span>
            <span class="tagExcluir" title="excluir localidades filhas"
              (click)="alterarSelecaoFilho(localidade)">&times;</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 btns-actions">
      <button *ngIf="!edicao" class="sg-btn sg-secondary" [disabled]="!form.valid"
        title="adicionar localidade">ADICIONAR</button>
      <button *ngIf="!edicao" class="sg-btn sg-alternate" (click)="$event.preventDefault();encerrarCadastro()"
        title="Concluir">CONCLUIR</button>
      <button *ngIf="edicao && getAuthenticatedUserAuthorities().includes(authority.ATUALIZAR_LOCALIDADE)"
        class=" sg-btn sg-secondary" [disabled]="!form.valid" title="Atualizar localidade">ATUALIZAR</button>
      <button *ngIf="edicao" class="sg-btn sg-alternate" (click)="$event.preventDefault();cancelarEdicao()"
        title="Cancelar">CANCELAR</button>
    </div>

  </div>
</form>
<app-loading *ngIf="isLoading"></app-loading>
