<div [formGroup]="alternativasSelecionadasFormGroup" class="sg-input-group">

  <div formArrayName="alternativasSelecionadas">

    <div *ngFor="let alternativa of alternativasSelecionadas.controls">

      <div class="sg-checkbox-full">
        <input id="{{ alternativa.value.esquema.id }}" name="idAlternativaSelecionada"
          value="{{ alternativa.value.esquema.id }}" type="radio"
          [formControl]="alternativasSelecionadasFormGroup.controls.idAlternativaSelecionada" />

        <label for="{{ alternativa.value.esquema.id }}" (click)="onSelecionadoRespostaFechada()"
          *ngIf="alternativa.value.esquema.tipoAlternativa === 'FECHADA'">
          {{ alternativa.value.esquema.descricao }}
        </label>

        <label for="{{ alternativa.value.esquema.id }}" (click)="onSelecionadoRespostaAberta()"
          *ngIf="alternativa.value.esquema.tipoAlternativa === 'ABERTA_TEXTO'">
          {{ alternativa.value.esquema.descricao }}
        </label>

        <label for="{{ alternativa.value.esquema.id }}" (click)="onSelecionadoRespostaAberta()"
          *ngIf="alternativa.value.esquema.tipoAlternativa === 'ABERTA_NUMERO'">
          {{ alternativa.value.esquema.descricao }}
        </label>
      </div>

      <div *ngIf="isAberta(alternativa) && alternativaSelecionada(alternativa)">
        <input [type]="isAbertaTexto(alternativa) ? 'text' : 'number'" placeholder="Insira a resposta"
          [formControl]="alternativa.controls.respostaAberta" />
        <span class="error-message" *ngIf="alternativasSelecionadasFormGroup.errors?.respostaAbertaVazia">
          Uma alternativa do tipo resposta aberta está marcada. Você deve preencher o campo textual.
        </span>
      </div>

    </div>

  </div>

</div>