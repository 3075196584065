import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


interface IState {
  data: string;
  visible: boolean;
  x: number;
  y: number;
}

@Injectable({
  providedIn: 'root'
})
export class TooltipTextService {

  constructor() {}

  private state: BehaviorSubject<IState> = new BehaviorSubject<IState>(null);
  public state$: Observable<IState> = this.state.asObservable();

  private data: string = null; // text
  private isVisible: boolean = false; // controls the tooltip visibility
  private x: number = 0; // X axis
  private y: number = 0; // y Axis

  /**
   * responsible for displaying the tooltip
   * @param x // X axis
   * @param y // y Axis
   */
  showAt(x: number, y: number) {
    this.isVisible = true;
    this.x = x + 10;
    this.y = y;

    this.state.next({ data: this.data, x: this.x, y: this.y, visible: this.isVisible });
  }

  /**
   * Assign the text to tooltip
   * @param data: tooltip text
   */
  setData(data: string) {
    this.data = data;
  }

  /**
   * Responsible for hiding the tooltip
   */
  hide() {
    this.isVisible = false;
    this.state.next({ ...this.state.getValue(), visible: this.isVisible });
  }
}
