import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { PerfilEntrevistado } from "../../../../../../pesquisa-old/cadastro/model/perfilEntrevistado";
import { CadastroPesquisaStoreState } from "../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState";
import {
  AjusteCotaAction,
  SolicitacaoAjusteCota,
} from "../../../../../../pesquisa-old/cadastro/store/gerenciadorCotas/ajuste/ajusteCotaAction";
import { createMaxAjusteValidator } from "./validator/maximoAjusteValidatorFactory";

@Component({
  selector: "app-ajuste-cota-modal",
  templateUrl: "./ajuste.cota.modal.component.html",
  styleUrls: ["./ajuste.cota.modal.component.scss"],
})
export class AjusteCotaModalComponent implements OnInit {
  /**
   * Para validar os inputs
   */
  formGroup: UntypedFormGroup;

  /**
   * Marcador que indica se o ajuste ja foi
   * submetido
   */
  submited: boolean = false;

  /**
   * Perfis q serao ignorados pelo seletor de perfil destino
   */
  perfilDestinoIgnorar: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<AjusteCotaModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { idVinculoOperador: number },
    private store: Store<CadastroPesquisaStoreState>
  ) {}

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.formGroup = new UntypedFormGroup({
      idVinculoOperador: new UntypedFormControl(this.data.idVinculoOperador),
      perfilOrigem: new UntypedFormControl(null, [Validators.required]),
      perfilDestino: new UntypedFormControl(null, [Validators.required]),
      quantidadeTransferencia: new UntypedFormControl(0, [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }

  /**
   * Atualiza os validadores do control da quantidade
   * que sera tranferida, pois estes validadores sao
   * dinamicos e dependem do perfil de origem
   */
  updateQuantidadeTransferenciaValidators() {
    const maxAjusteValidator = createMaxAjusteValidator(this.formGroup);
    // tslint:disable-next-line: max-line-length
    this.formGroup.controls.quantidadeTransferencia.setValidators([
      Validators.required,
      Validators.min(1),
      maxAjusteValidator,
    ]);
    this.formGroup.controls.quantidadeTransferencia.updateValueAndValidity();
  }

  /**
   * Hanlder que trata eventos de selecao de um novo perfil
   * de origem
   */
  onPerfilOrigemChange(perfil: PerfilEntrevistado) {
    this.perfilDestinoIgnorar = [perfil.id];
    this.formGroup.controls.perfilOrigem.patchValue(perfil);
    this.updateQuantidadeTransferenciaValidators();
  }

  /**
   * Handler que trata eventos de selecao de um novo perfil
   * de destino
   */
  onPerfilDestinoChange(perfil: PerfilEntrevistado) {
    this.formGroup.controls.perfilDestino.patchValue(perfil);
  }

  cancelar() {
    this.dialogRef.close();
  }

  ajustar() {
    this.submited = true;

    if (this.formGroup.valid) {
      const positiveCallback = () => {
        const fgValue = this.formGroup.value;

        // tslint:disable-next-line: max-line-length
        const solicitacaoAjusteCota = new SolicitacaoAjusteCota(
          fgValue.idVinculoOperador,
          Number(fgValue.quantidadeTransferencia),
          fgValue.perfilOrigem.id,
          fgValue.perfilDestino.id
        );
        const ajusteCotaAction = new AjusteCotaAction(solicitacaoAjusteCota);
        this.store.dispatch(ajusteCotaAction);
        this.formGroup.controls.quantidadeTransferencia.patchValue(0);
        this.showAlertaDataSucesso(() => this.cancelar());
      };
      this.showAlertaDataConfirmacaoAjuste(positiveCallback);
    } else {
      this.showAlertaDadosInvalidos();
    }
  }

  showAlertaDataSucesso(positiveCallback) {}

  showAlertaDataConfirmacaoAjuste(positiveCallback) {}

  showAlertaDadosInvalidos() {}
}
