import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientNavigateService {

  private parametro: number;

  /**
   * Obtem o id do cliente, parametro será utilizado para aplicar o focus na tabela
   * após o cadastro/edição
   */
  setClientId(valor: number): void {
    this.parametro = valor;
  }

  /**
   * Obtem o id do cliente, parametro será utilizado para aplicar o focus na tabela
   * após o cadastro/edição
   */
  getClientId(): number {
    const clientId = this.parametro
    this.setClientId(null)
    return clientId;
  }

}
