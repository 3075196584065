import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { ListReadService, LocalidadeToTable } from "../list-read.service";

@Component({
  selector: "app-localidade-table",
  templateUrl: "./localidade-table.component.html",
  styleUrls: ["./localidade-table.component.scss"],
})
export class LocalidadeTableComponent implements OnInit, OnChanges {
  @Input() tableData: LocalidadeToTable[] = [];
  @Input() mainCheck: boolean;

  @Output() showEditLocalidade = new EventEmitter();
  @Output() showEditLocalidadeFilha = new EventEmitter();

  constructor(private listReadService: ListReadService) {}

  ngOnInit() {}

  // Função para deletar uma localidade pai
  handleDeleteLocalidadePai(idApi: number): void {
    this.listReadService.deleteLocalidadePai(idApi);
  }

  // Função para abrir o modal de edição de uma localidade
  handleOpenLocalidadePai(idApi: number): void {
    this.showEditLocalidade.emit(idApi);
  }

  handleOpenEditLocalidadeFilha(
    idPaiApi: number,
    idFilhaApi: number,
    idFilhaList: number
  ): void {
    this.showEditLocalidadeFilha.emit({ idPaiApi, idFilhaApi, idFilhaList });
  }

  // Função para expandir e mostrar as localidades filhas de uma localidade
  handleToShowFilhas(idList: number): void {
    this.listReadService.setOpenOrCloseToShowFilhas(idList);
  }

  // Função para setar apenas um checkbox por localidade
  handleSetCheck(idList: number): void {
    this.listReadService.setCheckeboxChecked(idList);
  }

  // Função para setar o checkbox de uma localidade filhas
  handleSetFilhaCheck(paiId: number, filhaId: number): void {
    this.listReadService.setFilhaCheckboxChecked(paiId, filhaId);
  }

  // Função para setar todos os checks
  handleSetAllCheck(event: any): void {
    this.listReadService.setAllChecked(event.target.checked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "tableData":
            this.tableData = this.tableData;
        }
      }
    }
  }
}
