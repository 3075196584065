import { surveyListSituationView } from "../../constant";
import { ISurveyListModel, ISurveyListResponse } from "../../interfaces";

export const adaptSurveyListRespToModels = function (
  surveyListResponse: ISurveyListResponse[]
): ISurveyListModel[] {
  if (!surveyListResponse) {
    return [];
  }
  return surveyListResponse.map((data) => ({
    id: data.id,
    titulo: data.titulo,
    situacao: surveyListSituationView[data.situacao],
    cliente: data.cliente.nome,
    dataInicial: data.data_inicial,
    dataFinal: data.data_final,
  }));
};
