import { Directive, Input } from '@angular/core';
import { MascaraBase } from './mascaraBase';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-numero]',
})
export class MascaraNumeroDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 255);
  }

  aplicarMascara(valor: string): string {
    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);
    return numberString;
  }

}
