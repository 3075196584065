import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Perfil } from '../../localidades/localidade';

@Component({
  selector: 'app-perfil-card',
  templateUrl: './perfil.card.component.html',
  styleUrls: ['./perfil.card.component.scss'],
})
export class PerfilCardComponent {

  /**
   * Perfil a ser representado
   */
  @Input('perfil') perfil: Perfil;

  @Output() onTransferenciaButtonClick = new EventEmitter<Perfil>();

  /**
   * Indice que indica a posicao do perfil
   */
  @Input('perfilIndex') perfilIndex: number;

  emitEvent() {
    this.onTransferenciaButtonClick.emit(this.perfil);
  }

}
