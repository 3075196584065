import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { IQuestion } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { BadgeModel } from "../components/badge/badge.component.";
import { AudioPlayerService } from "app/modulos/auditoria-beta/components/audit-audio-player/audit-audio-player.service";

interface VideoState {
  // general video settings
  currentProgress: number;
  totalTime: number;
  paused: boolean;
  expanded: boolean;
  // speed
  showSpeedOptions: boolean;
  currentSpeed: number;
  // volume
  volume: number;
  showVolume: boolean;
}

@Component({
  selector: "app-video-question",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoQuestionComponent implements OnInit, OnDestroy {
  constructor(private audioPlayerService: AudioPlayerService) {
    this.bindKeyEvents();
  }

  @ViewChild("videoRef") videoRef: ElementRef<HTMLVideoElement> = null;

  @Input() model = {} as IQuestion;

  badgeModel = {} as BadgeModel;

  videoState = {
    currentProgress: 0,
    totalTime: 0,
    paused: true,
    expanded: false,
    showSpeedOptions: false,
    showVolume: false,
    currentSpeed: 1,
    volume: 1,
  } as VideoState;

  showControls: boolean = true;

  speedOptions: number[] = [1, 1.5, 2];

  ngOnInit(): void {
    this.initFlags();
  }

  ngOnDestroy(): void {
    this.destroyKeyEvents();
  }

  /**
   * Aciona um bind nos eventos do teclado para 
   */
  private bindKeyEvents() {
    this.onEscPress = this.onEscPress.bind(this);
  }

  public onMouseEnter() {
    this.showControls = true;
  }

  public onMouseLeave() {
    if (!this.videoState.paused) {
      this.showControls = false;
    }
  }

  public handleAudioSpeed(speed: number) {
    this.videoState.currentSpeed = speed;
  }

  public toggleVolumeState(): void {
    this.videoState.showVolume = !this.videoState.showVolume;
  }

  /**
   * Controls the display of the speed menu
   */
  public toggleSpeedOptions(): void {
    this.videoState.showSpeedOptions = !this.videoState.showSpeedOptions;
  }

  public get videoSource() {
    return (
      ((this.model.respostas &&
        this.model.respostas.entrevistador) as string) || null
    );
  }

  private get isRequired() {
    return this.model && this.model.obrigatoria;
  }

  private get videoElement() {
    return this.videoRef.nativeElement;
  }

  private initFlags() {
    this.badgeModel.isRequired = this.isRequired;
  }

  public splitTime(time: number): { minutes: string; seconds: string } {
    const rounded = Math.round(time);
    const result = new Date(rounded * 1000).toISOString().substr(11, 8);

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  }

  public convertToFullTime(time: number): string {
    const { minutes, seconds } = this.splitTime(time);

    return `${minutes}:${seconds}`;
  }

  private onEscPress(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.toggleExpand();
    }
  }

  private destroyKeyEvents() {
    window.removeEventListener("keydown", this.onEscPress);
  }

  private activateExitOnEscPress() {
    if (this.videoState.expanded) {
      window.addEventListener("keydown", this.onEscPress);
    } else {
      this.destroyKeyEvents();
    }
  }

  public toggleExpand() {
    this.videoState.expanded = !this.videoState.expanded;
    this.activateExitOnEscPress();
  }

  private play() {
    this.videoState.paused = false;
    this.videoElement.play();

    // RN
    if (this.audioPlayerService.isPlaying) {
      this.audioPlayerService.pauseAudio();
    }
  }

  private pause() {
    this.videoState.paused = true;
    this.videoElement.pause();
  }

  public togglePlay() {
    if (this.videoState.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  public initializeVideo() {
    const videoDuration = Math.round(this.videoElement.duration);
    this.videoState.totalTime = videoDuration;
  }

  public updateTimeElapsed($event: Event) {
    this.videoState.currentProgress = this.videoElement.currentTime;
  }

  public skipAhead($event: Event) {
    const target = $event.target as HTMLInputElement;

    this.videoState.currentProgress = Number(target.value);
    this.videoElement.currentTime = this.videoState.currentProgress;
  }

  public handleChangeVolume($event: Event) {
    const target = $event.target as HTMLInputElement;
    this.videoState.volume = Number(target.value);
  }

  public onEnd() {
    if (!this.videoState.paused) {
      this.videoState.paused = true;
    }
  }
}
