import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { IFilterSelectEvent } from "app/componentes/filter-select/filter-select";
import { NotificatorService } from "app/notificador/notificator.service";
import {
  SurveyListSituations,
  auditSurveyStatus
} from "../../../../constant";
import { SORTING_FILTER, SORTING_FILTER_DEFINITIONS } from "../../../../constant/filters";
import {
  IFilterSelect,
  ISelectedItems,
  ISurveyListModel,
} from "../../../../interfaces";
import { AuditoriaBetaService } from "app/modulos/auditoria-beta/services/auditoria-beta.service";

@Component({
  selector: "app-audit-survey-list",
  templateUrl: "./audit-survey-list.component.html",
  styleUrls: ["./audit-survey-list.component.scss"],
})
export class AuditSurveyList implements OnInit {
  @Input() models: ISurveyListModel[] = null;
  @Output() changeFilter = new EventEmitter<{
    status: IFilterSelect[];
    keywords: string;
    sorting: IFilterSelect[];
  }>(null);

  public isLoading: boolean = false;

  // Controle dos itens da listagem selecionadas, será feito o controle pelo id.
  selectedItems: ISelectedItems[] = [];
  //
  selectAll: boolean = false;

  // opções do filtro
  filterOptions: IFilterSelect[] = [
    { id: 1, label: "Não auditada", key: SurveyListSituations.NOTAUDITED },
    { id: 2, label: "Em auditoria", key: SurveyListSituations.INAUDIT },
    { id: 3, label: "Auditada", key: SurveyListSituations.AUDITADA },
    { id: 4, label: "Arquivada", key: SurveyListSituations.ARCHIVED },
  ];

  filterConf = {
    icon: FilterIcon.FUNNEL,
    type: FilterTypes.CHECK,
  };

  // opções do filtro (sorting)
  sortingFilterOptions = [...SORTING_FILTER];

  // Propriedades para o componente de filtro (sorting)
  sortingFilter = { ...SORTING_FILTER_DEFINITIONS };

  textInputTimeoutId: number = null;

  // Filtros gerais para persistência
  filters: { status: IFilterSelect[]; keywords: string, sorting: IFilterSelect[] } = {
    status: [],
    sorting: [],
    keywords: "",
  };

  constructor(
    private notificatorService: NotificatorService,
    private router: Router,
    private auditService: AuditoriaBetaService
  ) {}

  ngOnInit(): void {}


  /**
   * @returns titulo do botao de arquivamento
   */
  getArchiveBtnTitle(): string {
    if (!!this.selectedItems.filter((item) => item.situacao === "Arquivada").length) {
      return `Desarquivar pesquisa${this.selectedItems.length > 1 ? 's' : ''}`
    } else {
      return `Arquivar pesquisa${this.selectedItems.length > 1 ? 's' : ''}`
    }
  }

  // função que recebe o texto emitido pelo componente de input de texto
  public handleTextChange($event: string) {
    if (this.textInputTimeoutId !== null) {
      window.clearTimeout(this.textInputTimeoutId);
    }

    this.textInputTimeoutId = window.setTimeout(() => {
      this.filters.keywords = $event;

      this.changeFilter.emit({
        ...this.filters,
        keywords: $event,
      });
    }, 2000);
  }

  public handleOnSelectAllItems($event: MouseEvent) {
    const target = $event.target as HTMLInputElement;

    this.selectAll = target.checked;

    // select all
    if (this.selectAll) {
      this.selectedItems = this.models.map((v) => {
        return { id: v.id, situacao: v.situacao };
      });
    }
    // unselect all
    else if (this.selectedItems.length) {
      this.selectedItems.length = 0;
    }
  }

  public handleOnSelectItem(
    $event: MouseEvent,
    itemId: number,
    itemSituation?: string
  ) {
    $event.stopPropagation();

    const index = this.selectedItems.findIndex((item) => item.id === itemId);

    if (index === -1) {
      this.selectedItems.push({ id: itemId, situacao: itemSituation });
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  public handleChangeStatusFilter($event: IFilterSelectEvent) {
    this.filters.status = [...$event.currentState];

    this.changeFilter.emit({
      ...this.filters,
      status: this.filters.status,
    });
  }

  public handleChangeSorting($event: IFilterSelect[]) {
    const [item] = $event;

    this.filters.sorting = [item];

    this.changeFilter.emit({
      ...this.filters,
      sorting: this.filters.sorting,
    });
  }

  // desabilita o botão que arquivar ou não
  disableButtonArchive(): boolean {
    const findSituationArchive = this.selectedItems.filter(
      (item) => item.situacao === "Arquivada"
    );
    // caso não selecione nenhum item com a situação em arquivado, não desabilita o botão
    if (!findSituationArchive.length) return false;

    // caso tenha items arquivados e items em outras situações, desabilita o botão de arquivar
    if (this.selectedItems.length > findSituationArchive.length) return true;

    return false;
  }

  // define as ações do botão, caso vai arquivar ou desarquivar
  handleActionButton($event) {
    const selectedIds = this.selectedItems.map(item => item.id)
    if (!!this.selectedItems.filter((item) => item.situacao === "Arquivada").length) {
      this.unarchiveSurvey($event, selectedIds);
    } else {
      this.archiveSurvey($event, selectedIds);
    }
  }

  public isChecked(itemId: number) {
    return !!this.selectedItems.filter((item) => itemId === item.id).length;
  }

  public getSurveyStatusColor(status: string) {
    return auditSurveyStatus.find((s) => s.status === status).color;
  }

  public redirectToSurveyDetails(surveyId: number) {
    this.router.navigate(["/auditoria-beta/detalhes", surveyId]);
  }

  // função para arquivar a pesquisa
  public archiveSurvey($event: MouseEvent, surveyIds: number[]) {
    $event.stopPropagation();
    this.isLoading = true;
    this.auditService.archiveAuditSurvey(surveyIds).subscribe({
      complete: () => {
        this.isLoading = false;
        this.changeFilter.emit({
          ...this.filters
        });
        this.notificatorService.showInfo("", "Pesquisas  arquivadas com sucesso!");
      },
      error: ({err}) => {
        console.error(err)
        this.isLoading = false;
      }
    })
  }

  // função para desarquivar a pesquisa
  public unarchiveSurvey($event: MouseEvent, surveyIds: number[]) {
    $event.stopPropagation();

    this.notificatorService.showInfo("", "Pesquisas desarquivadas com sucesso!");
  }
}
