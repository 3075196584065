export function removeSpecialCharacters(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function formatToMaxDecimalPlaces(n, decimalPlaces = 0) {
  if (typeof n !== "string") return n;

  const [int, frag = ""] = n.split(".");
  return `${int}.${frag.slice(0, decimalPlaces) || 0}`;
}

export function formatDataToResultAccordeonSelect(data) {
  return data.map(
    ({ data_inicial, data_final, cliente, titulo_curto, id_pesquisa }) => ({
      label: `${titulo_curto} | ${cliente} | ${data_inicial
        .split("-")
        .reverse()
        .join("/")}
            a ${data_final.split("-").reverse().join("/")}`,
      value: id_pesquisa,
    })
  );
}
