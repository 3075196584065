import { AbstractControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

/**
   * sincroniza um formGroup passado com os correspondentes controls
   */
function syncFormGroup(formGroup: UntypedFormGroup, controls: { [key: string]: AbstractControl }) {
  const formGroupControls = formGroup.controls;
  const formGroupControlsKey = Object.keys(formGroupControls);

  formGroupControlsKey.forEach((formGroupControlKey) => {
    const control = formGroupControls[formGroupControlKey];
    syncControl(formGroupControlKey, control, controls);
  });
}

// sincroniza um control passado com o correspondente control da pesquisa
// tslint:disable-next-line:max-line-length
function syncControl(controlName: string, control: AbstractControl, controls: { [key: string]: AbstractControl }) {
  const finalControl = controls[controlName];
  if (finalControl) {
    updateFormControlPatchValue(finalControl, control);
  }
}

// atualiza o pathValue de um control baseado no valor do segundo control
// tslint:disable-next-line:max-line-length
function updateFormControlPatchValue(controlTarget: AbstractControl, controlOrigin: AbstractControl) {
  if (controlOrigin instanceof UntypedFormArray) {
    const controlTargetArray: UntypedFormArray = <UntypedFormArray>controlTarget;
    controlTargetArray.controls.splice(0);
    controlTargetArray.patchValue([]);

    const originValues: AbstractControl[] = controlOrigin.controls;
    if (originValues) {
      originValues.forEach((originValue) => {
        controlTargetArray.push(originValue);
      });
    }
  } else {
    controlTarget.patchValue(controlOrigin.value);
  }
}

export { syncControl, syncFormGroup };
