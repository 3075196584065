import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

export interface RadioSelectItem {
  value: string;
  label: string;
  checked: boolean;
}

@Component({
  selector: "app-radio-select",
  templateUrl: "./radio-select.component.html",
  styleUrls: ["./radio-select.component.scss"],
})
export class RadioSelectComponent implements OnChanges {
  // referências
  @ViewChild("base", {static: true}) elemContainer;
  // propriedades
  @Input() usedBy: "CHEVRON" | "TOOLTIP";
  @Input() label: string;
  @Input() placeholder: string;
  @Input() options: RadioSelectItem[];
  @Input() selected: RadioSelectItem;
  @Input() withCheck: boolean = true;
  @Input() kind: string;
  @Input() disabled: boolean = false;
  // evento
  @Output() onSelectTypeOfSurvey = new EventEmitter();
  // local
  showBody: boolean = false;

  isLoadingOptions: boolean = false;

  // manipula apresentação do corpo do select
  toogleShowBody() {
    this.showBody = !this.showBody;
  }

  // manipula o clique no input/selector
  handleShowBody() {
    if (!this.disabled) {
      if (this.usedBy === "TOOLTIP") {
        this.isLoadingOptions = true;

        setTimeout(() => {
          this.toogleShowBody();
          this.isLoadingOptions = false;
        }, 250);

      } else {
        this.isLoadingOptions = false;
        this.toogleShowBody();
      }

    }
  }

  // manipulando a seleção de um item
  handleSelectItem(item: RadioSelectItem) {
    if (!this.disabled) {
      this.selected = item;
      this.onSelectTypeOfSurvey.emit(item.value);
      this.toogleShowBody();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "options":
            this.options = this.options;
        }
      }
    }
  }

  // verificar se o clique foi dentro do componente ou fora.
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.elemContainer.nativeElement.contains(event.target)) {
      if (this.showBody) {
        this.toogleShowBody();
      }
    }
  }
}
