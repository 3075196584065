import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/componentes/filterable-select/filterable-select-component";
import { ResultsService } from "./services/results.service";

import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { formatDataToResultAccordeonSelect } from "../../util/formatter";

interface LastSurveys {
  id_pesquisa: number;
  tipo_pesquisa: string;
  status: string;
  titulo_curto: string;
}

enum Status {
  CONCLUIDO = "Concluída",
  EXECUCAO = "Em execução",
}

@Component({
  selector: "app-resultados",
  templateUrl: "./resultados.component.html",
  styleUrls: ["./resultados.component.scss"],
})
export class ResultadosComponent implements OnInit {
  constructor(private resultService: ResultsService, private router: Router) {}

  surveysList: SelectDataItem[] = [];
  lastSurveys: LastSurveys[] = [];
  hasNextPage = true;
  filterSurveyKeywords = [""];
  page = 0;

  ngOnInit(): void {
    this.getData();
  }

  // Recupera dos dados para o menu acordeon e para a lista das ultimas pesquisas
  getData() {
    this.resultService.getSurveyList().subscribe((resp: any[]) => {
      // Recupera e formata os dados para visualização no menu
      this.surveysList = formatDataToResultAccordeonSelect(resp);
      // Recupera as 4 primeiras pesquisas da lista
      this.lastSurveys = resp.slice(0, 4);
    });
  }

  filterSurveys() {
    this.resultService
      .getSurveyList(this.page, this.filterSurveyKeywords)
      .subscribe((resp: any[]) => {
        // Recupera e formata os dados para visualização no menu
        this.surveysList = formatDataToResultAccordeonSelect(resp);
      });
  }

  changeInputText($event: string) {
    this.page = 0;
    this.hasNextPage = true;
    this.filterSurveyKeywords = $event.split(" ");
    this.filterSurveys();
  }

  onScrollBottom({ target: { scrollTop, scrollHeight, clientHeight } }) {
    if (scrollHeight - scrollTop === clientHeight && this.hasNextPage) {
      this.page++;
      this.resultService
        .getSurveyList(this.page, this.filterSurveyKeywords)
        .subscribe((resp: any[]) => {
          // Recupera e formata os dados para visualização no menu
          this.surveysList = [
            ...this.surveysList,
            ...formatDataToResultAccordeonSelect(resp),
          ];
          this.hasNextPage = resp.length === 16;
        });
    }
  }

  formmatStatus(status: string) {
    return Status[status];
  }

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Resultados",
      itemLink: "/resultados",
      active: true,
    },
  ];

  handleChange({ item: { value } }: any) {
    this.router.navigate([`/resultados/resultado-pesquisa/${value}`]);
  }

}
