import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  isValidHourAndMinute,
  isValidTimeFormat,
  padStartTime,
  parseSingleTime,
  replaceBadInputs,
  splitTime,
  toFullTime,
  toValidFormat,
} from "./utils";

import { AnswerOutput } from "../questions.component";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { IQuestion } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { BadgeModel } from "../components/badge/badge.component.";

@Component({
  selector: "app-pergunta-horario",
  templateUrl: "./pergunta-horario.component.html",
  styleUrls: ["./pergunta-horario.component.scss"],
})
export class PerguntaHorarioComponent implements OnInit {
  @Input() questionData = {} as IQuestion;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);
  @ViewChild("fullTimeInput") fullTimeInput: ElementRef<HTMLElement> = null;
  @ViewChild("containerRef") containerRef: ElementRef<HTMLElement> = null;
  @ViewChild("hourInput") hourInputRef: ElementRef<HTMLElement> = null;
  @ViewChild("minuteInput") minuteInputRef: ElementRef<HTMLElement> = null;

  timePickerIsVisible: boolean = false;
  hourSelectorIsVisible: boolean = false;
  minuteSelectorIsVisible: boolean = false;

  hours: string[] = Array(24)
    .fill(0)
    .map((_, i) => i.toString());
  minutes: string[] = Array(60)
    .fill(0)
    .map((_, i) => i.toString());

  value: string = "";

  selectedHour: string = "";
  selectedMinute: string = "";

  badgeModel = {} as BadgeModel;

  hasChanged: boolean = false;

  ngOnInit(): void {
    this.initAnswers();
    this.updateBadge();

    if (typeof this.auditorAnswer !== null) {
      this.setHasChangedState(true);
    }
  }

  private isRequired() {
    return this.questionData.obrigatoria;
  }

  private updateBadge() {
    this.badgeModel = {
      isRequired: this.isRequired(),
      questionType: TipoPergunta.HORARIO,
      requiredError: this.isRequired() && this.value === '',
    };
  }

  private emitAnswer() {
    this.onChange.emit({
      type: TipoPergunta.HORARIO,
      questionId: this.questionData.id,
      answer: this.value,
    });
  }

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  public resetAnswer() {
    this.initAnswers(this.interviewerAnswer as string);
    this.setHasChangedState(false);
  }

  private initAnswers(defaultAnswer?: string) {
    this.value = defaultAnswer !== undefined ? defaultAnswer : this.getAnswer();

    this.updateHourAndMinute();
    this.emitAnswer();
  }

  private getAnswer() {
    return (this.auditorAnswer || this.interviewerAnswer) as string;
  }

  // remove os segundos do horário
  private parseAnswer(fullTime: string): string {
    if(!!fullTime) {
      return fullTime.slice(0, 5); // hh:mm
    } else {
      return ""
    }
  }

  public get interviewerAnswer() {
    return (
      (!!this.questionData && this.parseAnswer(this.questionData.respostas.entrevistador as string))
    );
  }

  public get auditorAnswer() {
    return (!!this.questionData && this.parseAnswer(this.questionData.respostas.auditor as string));
  }

  private updateHourAndMinute() {
    [this.selectedHour = "", this.selectedMinute = ""] = splitTime(this.value);
  }

  private updateTimeInput() {
    if (isValidHourAndMinute(this.selectedHour, this.selectedMinute)) {
      this.value = toFullTime(this.selectedHour, this.selectedMinute);
      this.setHasChangedState(true);
      this.emitAnswer();
    } else {
      this.updateHourAndMinute();
    }
  }

  public checkTimeConsistence() {
    if (!isValidTimeFormat(this.value)) {
      this.value = "";
      this.updateHourAndMinute();
    } else {
      let consistentTime: string = toValidFormat(this.value);

      if (this.value !== consistentTime) {
        this.value = consistentTime;
      }
    }

    this.emitAnswer();
    this.updateBadge();
  }

  public handleDateInputChange() {
    let lastLength: number;

    do {
      lastLength = this.value.length;
      this.value = replaceBadInputs(this.value);
    } while (this.value.length > 0 && lastLength !== this.value.length);

    this.updateHourAndMinute();
    this.setHasChangedState(true);
  }

  public handleOpenSelector() {
    this.changePickerVisibility(true);
  }

  private changeHourSelectorVisibility(visible: boolean) {
    this.hourSelectorIsVisible = visible;
  }

  private changeMinuteSelectorVisibility(visible: boolean) {
    this.minuteSelectorIsVisible = visible;
  }

  private changePickerVisibility(visible: boolean) {
    this.timePickerIsVisible = visible;
  }

  public handleHourInputFocus() {
    if (!this.hourSelectorIsVisible) {
      this.changeHourSelectorVisibility(true);
    }
  }

  public handleMinuteInputFocus() {
    if (!this.minuteSelectorIsVisible) {
      this.changeMinuteSelectorVisibility(true);
    }
  }

  public handleInputBlurProxy(
    $event: FocusEvent,
    inputType: "hour" | "minute"
  ) {
    const callback = {
      hour: this.changeHourSelectorVisibility.bind(this),
      minute: this.changeMinuteSelectorVisibility.bind(this),
    }[inputType];

    callback(false);
  }

  public handleOnSelectHour($event, hour: string) {
    this.selectedHour = padStartTime(hour);
  }

  public handleMinuteInput() {
    this.selectedMinute = parseSingleTime(this.selectedMinute, 59);
  }

  public handleHourInput() {
    this.selectedHour = parseSingleTime(this.selectedHour);
  }

  public handleOnSelectMinute($event, minute: string) {
    this.selectedMinute = padStartTime(minute);
  }

  public handleConfirm() {
    this.changePickerVisibility(false);
    this.updateTimeInput();
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    // Controla a visibilidade do seletor geral
    if (
      this.timePickerIsVisible &&
      !this.containerRef.nativeElement.contains(target)
    ) {
      this.changePickerVisibility(false);
    }
  }
}
