import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlterarTituloGrade } from '../../../../../../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../../../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../../../../../../pesquisa-old/cadastro/store/passoCadastro';

@Component({
  selector: 'app-titulo-grade',
  templateUrl: './titulo-grade.component.html',
  styleUrls: ['./titulo-grade.component.scss'],
})
export class TituloGradeComponent implements OnInit, OnDestroy {

  /**
   * Ordem do titulo grade na pergunta
   */
  @Input() ordem: number;
  @Input() formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };

  /**
  * Marcador para saber se os erros de validacao
  * devem ser exibidos
  */
  showErrors: boolean = false;

  /**
  * Subscriptions que deverão ser encerradas
  * junto com a destruição do componente
  */
  subscriptions: Subscription[] = [];

  /**
   * Marcação que simboliza se o titulo é editável
   */
  @Input() editavel: boolean = true;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
  }

  /**
   * @override
   */
  ngOnInit() {
    this.controls = this.formGroup.controls;

    if (!this.editavel) {
      this.formGroup.disable();
    }

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    /**
     * Verificando se deve-se exibir as mensagens de validação
     * no passo atual, caso positivo, é feito o unsubscribe
     * no canal
     */
    const dadosPassoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso),
      )
      .subscribe((dadosPasso) => {
        const dados = dadosPasso.get(PassoCadastro.QUESTIONARIO);
        // tslint:disable-next-line: max-line-length
        this.showErrors = dados.exibirValidacao;
        if (this.showErrors && dadosPassoSubscription) {
          dadosPassoSubscription.unsubscribe();
        }
      });

    this.subscriptions.push(dadosPassoSubscription);
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onChange() {
    this.store.dispatch(new AlterarTituloGrade(this.formGroup.value));
  }

  remover() {
    /**
    * Os titulos grade sao removidas localmente, só devem
    * ser enviadas à store quando clicar em 'Salvar'
    */
    const titulosGradeFormArray = <UntypedFormArray>this.formGroup.parent;
    titulosGradeFormArray.removeAt(this.ordem - 1);
    // this.store.dispatch(new RemoverTituloGrade(this.formGroup.value));
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(abstractFormArray: AbstractControl): AbstractControl[] {

    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }

}
