import { ValidatorFn, UntypedFormArray } from "@angular/forms";

function minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelecionado = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
  
      // if the total is not greater than the minimum, return the error message
      return totalSelecionado >= min ? null : { required: true };
    };
    return validator;
  }

  const minCheckBoxSelecionado = {
    minSelectedCheckboxes,
  };
  
  export { minCheckBoxSelecionado };
  