const createElement = () => {
  return document.createElement("canvas");
};

const measureTextWidth = (
  canvas: HTMLCanvasElement,
  text: string,
  ctxFont: string
): number => {
  if (canvas) {
    const context = canvas.getContext("2d");
    context.font = ctxFont;

    const { width } = context.measureText(text);
    return width;
  }

  return 0.0;
};

export default { createElement, measureTextWidth };
