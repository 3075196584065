import { Injectable, OnInit, ComponentFactoryResolver, ComponentFactory } from '@angular/core';
import { TipoElementoQuestionario } from './tipoElementoQuestionario';
import { SecaoComponent } from '../../passos/questionario/componentes/secao/secao.component';
import { TipoElementoSecao } from './tipoElementoSecao';
import { TipoPergunta } from './tipoPergunta';
// tslint:disable-next-line:max-line-length
import { SelecaoUnicaComponent } from '../../passos/questionario/componentes/secao/pergunta/selecao/selecao-unica/selecao-unica.component';
// tslint:disable-next-line:max-line-length
import { SelecaoMultiplaComponent } from '../../passos/questionario/componentes/secao/pergunta/selecao/selecao-multipla/selecao-multipla.component';
// tslint:disable-next-line:max-line-length
import { SelecaoMultiplaOrdemCitacaoComponent } from '../../passos/questionario/componentes/secao/pergunta/selecao/selecao-multipla-ordem-citacao/selecao-multipla-ordem-citacao.component';
// tslint:disable-next-line:max-line-length
import { EscalaNumericaComponent } from '../../passos/questionario/componentes/secao/pergunta/escala-numerica/escala-numerica.component';
// tslint:disable-next-line:max-line-length
import { FotoComponent } from '../../passos/questionario/componentes/secao/pergunta/foto/foto.component';
// tslint:disable-next-line:max-line-length
import { VideoComponent } from '../../passos/questionario/componentes/secao/pergunta/video/video.component';
// tslint:disable-next-line:max-line-length
import { DataComponent } from '../../passos/questionario/componentes/secao/pergunta/data/data.component';
// tslint:disable-next-line:max-line-length
import { HorarioComponent } from '../../passos/questionario/componentes/secao/pergunta/horario/horario.component';
// tslint:disable-next-line:max-line-length
import { GradeUnicaComponent } from '../../passos/questionario/componentes/secao/pergunta/grade/grade-unica/grade-unica.component';
// tslint:disable-next-line:max-line-length
import { GradeMultiplaComponent } from '../../passos/questionario/componentes/secao/pergunta/grade/grade-multipla/grade-multipla.component';
// tslint:disable-next-line:max-line-length
import { TipoElementoQuestionarioMarcacao } from '../../passos/marcacoes/tipoElementoQuestionarioMarcacao';
// tslint:disable-next-line:max-line-length
import { TipoConteudo } from '../../passos/questionario/componentes/secao/conteudo/tipoConteudo';
import { SecaoContentComponent } from '../../passos/questionario/componentes/secao/conteudo/secao-content/secao-content.component';
import { PerguntaFotoContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-foto-content/pergunta.foto.content.component';
import { PerguntaDataContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-data-content/pergunta.data.content.component';
import { PerguntaHorarioContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-horario-content/pergunta.horario.content.component';
import { PerguntaVideoContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-video-content/pergunta-video-content.component';
import { PerguntaSelecaoUnicaContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-selecao-unica-content/pergunta-selecao-unica-content.component';
import { PerguntaSelecaoMultiplaContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-selecao-multipla-content/pergunta-selecao-multipla-content.component';
import { PerguntaEscalaNumericaContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-escala-numerica-content/pergunta-escala-numerica-content.component';
import { PerguntaGradeUnicaContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-grade-unica-content/pergunta-grade-unica-content.component';
import { PerguntaGradeMultiplaContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-grade-multipla-content/pergunta-grade-multipla-content.component';
import { PerguntaSelecaoMultiplaOrdemCitacaoContentComponent } from '../../passos/questionario/componentes/secao/conteudo/pergunta-selecao-multipla-ordem-citacao-content/pergunta-selecao-multipla-ordem-citacao-content.component';
import { SelecaoUnicaConteudoMarcacaoComponent } from '../../passos/marcacoes/componentes/selecao-unica/selecao-unica-conteudo-marcacao/selecao-unica-conteudo-marcacao.component';
import { SelecaoMultiplaMarcacaoComponent } from '../../passos/marcacoes/componentes/selecao-multipla/selecao-multipla-marcacao/selecao-multipla-marcacao.component';
import { SelecaoMultiplaConteudoMarcacaoComponent } from '../../passos/marcacoes/componentes/selecao-multipla/selecao-multipla-conteudo-marcacao/selecao-multipla-conteudo-marcacao.component';
import { SelecaoMultiplaOrdemCitacaoConteudoMarcacaoComponent } from '../../passos/marcacoes/componentes/selecao-multipla/selecao-multipla-ordem-citacao-conteudo-marcacao/selecao-multipla-ordem-citacao-conteudo-marcacao.component';
import { EscalaNumericaConteudoMarcacaoComponent } from '../../passos/marcacoes/componentes/escala-numerica/escala-numerica-conteudo-marcacao/escala-numerica-conteudo-marcacao.component';
import { MarcacaoPerguntaConteudoComponent } from '../../passos/marcacoes/componentes/marcacao-pergunta-conteudo/marcacao-pergunta-conteudo.component';

/**
 * Serviço responsavel por prover a instancia correta do ComponentFactory
 * de inputs baseado no seu tipo
 */
@Injectable({
  providedIn: 'root',
})
export class ComplexInputFactoryService {

  componentMap: Map<string, any>;

  constructor(private resolver: ComponentFactoryResolver) {
    this.componentMap = new Map();
    // Q U E S T I O N A R I O
    this.componentMap.set(TipoElementoQuestionario.SECAO, SecaoComponent);
    this.componentMap.set(TipoConteudo.CONTEUDO_SECAO, SecaoContentComponent);
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_DATA, PerguntaDataContentComponent);
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_FOTO, PerguntaFotoContentComponent);
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_HORARIO, PerguntaHorarioContentComponent);
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_VIDEO, PerguntaVideoContentComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_GRADE_MULTIPLA, PerguntaGradeMultiplaContentComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_UNICA, PerguntaSelecaoUnicaContentComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_FOTO_MARCACAO, MarcacaoPerguntaConteudoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_VIDEO_MARCACAO, MarcacaoPerguntaConteudoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_DATA_MARCACAO, MarcacaoPerguntaConteudoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_HORARIO_MARCACAO, MarcacaoPerguntaConteudoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_MULTIPLA, PerguntaSelecaoMultiplaContentComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_ESCALA_NUMERICA, PerguntaEscalaNumericaContentComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_GRADE_UNICA, PerguntaGradeUnicaContentComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO, PerguntaSelecaoMultiplaOrdemCitacaoContentComponent);
    //  P E R G U N T A
    this.componentMap.set(TipoPergunta.UNICA, SelecaoUnicaComponent);
    this.componentMap.set(TipoPergunta.MULTIPLA, SelecaoMultiplaComponent);
    // tslint:disable-next-line:max-line-length
    this.componentMap.set(TipoPergunta.MULTIPLA_ORDEM_CITACAO, SelecaoMultiplaOrdemCitacaoComponent);
    this.componentMap.set(TipoPergunta.ESCALA_NUMERICA, EscalaNumericaComponent);
    this.componentMap.set(TipoPergunta.FOTO, FotoComponent);
    this.componentMap.set(TipoPergunta.VIDEO, VideoComponent);
    this.componentMap.set(TipoPergunta.DATA, DataComponent);
    this.componentMap.set(TipoPergunta.HORARIO, HorarioComponent);
    this.componentMap.set(TipoPergunta.GRADE_UNICA, GradeUnicaComponent);
    this.componentMap.set(TipoPergunta.GRADE_MULTIPLA, GradeMultiplaComponent);
    /**
     * M A R C A Ç Ã O
     */
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_UNICA_MARCACAO, SelecaoUnicaConteudoMarcacaoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_MARCACAO, SelecaoMultiplaConteudoMarcacaoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO_MARCACAO, SelecaoMultiplaOrdemCitacaoConteudoMarcacaoComponent);
    // tslint:disable-next-line: max-line-length
    this.componentMap.set(TipoConteudo.CONTEUDO_PERGUNTA_ESCALA_NUMERICA_MARCACAO, EscalaNumericaConteudoMarcacaoComponent);
  }

  // tslint:disable-next-line:max-line-length
  getComponetFactory(tipoInput: string): ComponentFactory<any> {
    const componentType = this.componentMap.get(tipoInput);
    const componentCactory = this.resolver.resolveComponentFactory(componentType);

    return componentCactory;
  }

}
