import { AbstractAction } from './actions/abstractAction';

/**
 * Queue das acoes lançadas durante o cadastro/edição da pesquisa.
 * As ações são gravadas no sessionStorage da aplicação portanto,
 * esta não é uma queue thread-safe, use-a com isto em mente.
 */
export class ActionQueue {

  private readonly ACTION_QUEUE_NAME = 'PESQUISA_ACTION_QUEUE';

  /**
   * Emfileira actions relacionada a pesquisa. Podem
   * ser actions de cadastro ou edição
   */
  enqueueAction(action: AbstractAction) {

    const actionQueueString = sessionStorage.getItem(this.ACTION_QUEUE_NAME);

    // tslint:disable-next-line: max-line-length
    const actions = actionQueueString ? <AbstractAction[]>JSON.parse(sessionStorage.getItem(this.ACTION_QUEUE_NAME)) : [];
    actions.push(action);

    sessionStorage.setItem(this.ACTION_QUEUE_NAME, JSON.stringify(actions));
  }

  /**
   * Desenfileira as ações relacionadas a pesquisa. Podem
   * ser actions de cadastro e edição
   */
  dequeueAction(): AbstractAction | undefined {

    let action;
    const actions = <AbstractAction[]>JSON.parse(sessionStorage.getItem(this.ACTION_QUEUE_NAME));
    if (actions.length > 0) {
      action = actions.shift();
      sessionStorage.setItem(this.ACTION_QUEUE_NAME, JSON.stringify(actions));
    }
    return action;
  }

  /**
   * Verifica se existem mais actions na queue
   */
  isEmpty() {

    const actionQueueString = sessionStorage.getItem(this.ACTION_QUEUE_NAME);
    return !actionQueueString || actionQueueString.length <= 2;
  }

  clear() {

    sessionStorage.setItem(this.ACTION_QUEUE_NAME, '[]');
  }

}
