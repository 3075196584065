<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<div class="audit-container">
  <app-info-card
    [data]="infoCardData.data"
    [title]="infoCardData.title"
    [text]="infoCardData.text"
  ></app-info-card>

  <div class="audit-content">
    <!-- Listagem de pesquisas -->
    <app-audit-survey-list
      [models]="surveyListData"
      (changeFilter)="handleChangeFilter($event)"
    ></app-audit-survey-list>

    <app-pagination
      [placeholder]="totalOfSurveys === 1 ? 'Pesquisa' :'Pesquisas'"
      [entriesPerPage]="surveysPerPage"
      [totalOfEntries]="totalOfSurveys"
      [currentPage]="currentPage"
      [totalPages]="totalPages"
      (changePage)="changePage($event)"
    ></app-pagination>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>
