<div class="contractor-container" [formGroup]="formGroup">

  <section class="inputs">

    <span>
      <div class="input-wrapper">
        <label>Nome do Administrador*</label>
        <input
          type="text"
          [ngClass]="(formGroup.get('nome')?.invalid && (formGroup.get('nome')?.dirty || formGroup.get('nome')?.touched)) ? 'inputs name-input error-input ' : 'inputs name-input'"
          formControlName="nome"
          placeholder="Nome do administrador"
          maxlength="101"
          mascara-primeiro-digito-sem-espaco
        />
        <span class="error-message" *ngIf="formGroup.get('nome')?.invalid && (formGroup.get('nome')?.dirty || formGroup.get('nome')?.touched)">
          <span *ngIf="formGroup.get('nome')?.errors.required">Campo obrigatório</span>
          <span *ngIf="formGroup.get('nome')?.errors.maxlength">Limite máximo de 100 caracteres</span>
        </span>
      </div>
    </span>

    <span>
      <div class="input-wrapper">
        <label>Email*</label>
        <input
          type="email"
          [ngClass]="(formGroup.get('login')?.invalid && (submitted || !formGroup.get('login')?.value?.length) &&
          (formGroup.get('login')?.dirty || formGroup.get('login')?.touched)) ? 'inputs email-input error-input ' : 'inputs email-input'"
          formControlName="login"
          placeholder="email@email.com"
          [pattern]="emailRegex"
        />
        <span class="error-message" *ngIf="formGroup.get('login')?.invalid && (formGroup.get('login')?.dirty || formGroup.get('login')?.touched)">
          <span *ngIf="formGroup.get('login')?.errors.required">Campo obrigatório</span>
          <span *ngIf="formGroup.get('login')?.errors.pattern && submitted">E-mail inválido</span>
          <span *ngIf="formGroup.get('login')?.errors.alreadyExists && submitted">{{ loginError }}</span>
        </span>
      </div>
    </span>

    <span>
      <div class="input-wrapper">
        <label>CPF*</label>
        <input
          type="text"
          [ngClass]="(formGroup.get('cpf')?.invalid && (submitted || !formGroup.get('cpf')?.value?.length) &&
          (formGroup.get('cpf')?.dirty || formGroup.get('cpf')?.touched)) ? 'inputs cpf-input error-input ' : 'inputs cpf-input'"
          formControlName="cpf"
          placeholder="000.000.000-00"
          maxlength="14"
          mascara-document
          [initialValue]="formGroup.get('cpf').value"
        />
        <span class="error-message" *ngIf="formGroup.get('cpf')?.invalid && (formGroup.get('cpf')?.dirty || formGroup.get('cpf')?.touched)">
          <span *ngIf="formGroup.get('cpf')?.errors.required">Campo obrigatório</span>
          <span *ngIf="!formGroup.get('cpf')?.errors.required && formGroup.get('cpf')?.errors && submitted">CPF inválido</span>
        </span>
      </div>
    </span>

  </section>

</div>
