import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

type Status = 'ATIVO' | 'INATIVO';

interface FilterSelector {
  statusActive: Status;
}

@Component({
  selector: 'app-new-select',
  templateUrl: './new-select-component.html',
  styleUrls: ['./new-select-component.scss']
})
export class NewSelect implements OnInit {
  @Output() handleFilter = new EventEmitter<string>();

  // Estado para controlar a visibilidade das opções do seletor
  selectOptionsAreVisible: boolean = false;

  // Estado para controlar opcoes do filtro
  filterSelectorState: FilterSelector = {
    statusActive: null as Status,
  }

  // Estado para armazenar a informação se o seletor do filtro está vazio
  // selectStateIsEmpty: boolean = this.filterSelectorState.statusActive.length === 0;

  // Estado para controlar label do seletor
  selectLabel: string = 'Selecione';

  @Output() output: EventEmitter<string> = new EventEmitter()

  constructor(private elem: ElementRef) { }

  ngOnInit() {
  }

  // Função para alterar a visibilidade das opções do seletor
  toggleSelectOptionsVisibility() {
    this.selectOptionsAreVisible = !this.selectOptionsAreVisible;
  }

  // Função para alterar o tipo de filtro por status
  changeOptionItem(type: Status): void {
    if (this.filterSelectorState.statusActive === type) {
      this.filterSelectorState.statusActive = null;
      this.selectLabel = 'Selecione';
    } else {
      this.filterSelectorState.statusActive = type;
      this.selectLabel = this.convertStatusToLabel(type);
    }

    this.toggleSelectOptionsVisibility();
    this.handleFilter.emit(this.selectLabel);
  }

  handleClick(value: string) {
    this.output.emit(value);
    this.toggleSelectOptionsVisibility();
  }

  // Função que converte status para versão legível
  convertStatusToLabel(status: Status) {
    if (status === 'ATIVO') return 'ativo';
    else if (status === 'INATIVO') return 'inativo';
  }

  // verificar se o clique foi dentro do componente ou fora.
  @HostListener('document:click', ['$event'])
  documentClick(event: Event) {
    if (!this.elem.nativeElement.contains(event.target)) {
      if (this.selectOptionsAreVisible) {
        this.toggleSelectOptionsVisibility();
      }
    }
  }
}
