import { Injectable } from "@angular/core";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";

@Injectable({
  providedIn: "root",
})
export class AuditoriaObservationsModalService {
  constructor() {
    this.initData();
  }
  // Estado da modal de observações.
  public modalData: ModalData = null;
  // Flag: indica se os comentários do auditor deverá estar habilitado.
  private commentsAllowed: boolean = false;

  public allowComments(state: boolean) {
    this.commentsAllowed = state;
  }

  /**
   * Inicializa as opções comuns da modal de observações.
   */
  private initData() {
    this.modalData = new ModalData();

    this.modalData.type = "information";
    this.modalData.icon = "fa-regular fa-message-lines";
    this.modalData.titleDialog = "Observações";
    this.modalData.showLineHeader = false;
    this.modalData.showActions = false;
    this.modalData.closeIconConfig = {
      className: "fa-regular fa-xmark",
      size: "12px",
    };
    this.modalData.actionNegative = "";
  }

  /**
   * Disparado quando o usuário requisita a abertura da modal
   * de observações.
   */
  public show() {
    if (!this.commentsAllowed) {
      this.modalData.showActions = true;
    } else {
      this.modalData.showActions = false;
    }

    this.modalData.actionPositive = "Sair";
    this.modalData.show = true;
  }
}
