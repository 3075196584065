import { AbstractAction } from './abstractAction';
import { Secao } from '../../model/secao';
import { TipoElemento } from '../questionario/tipoElemento';
import { Pergunta } from '../../model/pergunta';
import { Alternativa } from '../../model/alternativa';
import { TipoConteudo } from '../../passos/questionario/componentes/secao/conteudo/tipoConteudo';
import { TituloGrade } from '../../model/tituloGrade';
import { PassoCadastro } from '../passoCadastro';
import { ActionType } from '../actionType';

/**
 * S E C A O
 */
export class AdicionarSecao extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ADICIONAR;

  constructor(estado: Secao) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.SECAO,
    };
    this.isReplayable = true;
  }

}

export class AlterarSecao extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ALTERAR;

  constructor(estado: Secao) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.SECAO,
    };
    this.isReplayable = true;
  }

}

export class RemoverSecao extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.REMOVER;

  constructor(estado: Secao) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.SECAO,
    };
    this.isReplayable = true;
  }

}

/**
 * P E R G U N T A
 */

 export class MoverPergunta extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.MOVER_PERGUNTA;

  constructor(secaoOrigem: any, secaoDestino: any, indiceOrigem: number, indiceDestino: number) {
    super();
    this.payload = {
      secaoOrigem, secaoDestino, indiceOrigem, indiceDestino,
    };
    this.isReplayable = true;
  }

}

export class ReordenarPergunta extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.REORDENAR_PERGUNTA;

  constructor(indicePergunta: number, indiceAtual: number, novoIndice: number) {
    super();
    this.payload = {
      indicePergunta, indiceAtual, novoIndice,
    };
    this.isReplayable = true;
  }

}

export class ReordenarPerguntaCota extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.REORDENAR_PERGUNTA_COTA;

  constructor(indiceAtual: number, novoIndice: number) {
    super();
    this.payload = {
      indiceAtual, novoIndice,
    };
    this.isReplayable = true;
  }

}

export class AdicionarPergunta extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ADICIONAR;

  constructor(estado: Pergunta) {
    super();
    this.payload = {
      estado: {
        ...estado,
        obrigatoria: true,
      },
      tipoElemento: TipoElemento.PERGUNTA,
    };
    this.isReplayable = true;
  }

}

export class AlterarPergunta extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ALTERAR;

  constructor(estado: Pergunta) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.PERGUNTA,
    };
    this.isReplayable = true;
  }

}

export class RemoverPergunta extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.REMOVER;

  constructor(estado: Pergunta) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.PERGUNTA,
    };
    this.isReplayable = true;
  }

}

/**
 * A L T E R N A T I V A
 */
export class AdicionarAlternativa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ADICIONAR;

  constructor(estado: Alternativa) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.ALTERNATIVA,
    };
    this.isReplayable = true;
  }

}

export class AlterarAlternativa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ALTERAR;

  constructor(estado: Alternativa) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.ALTERNATIVA,
    };
    this.isReplayable = true;
  }

}

export class RemoverAlternativa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.REMOVER;

  constructor(estado: Alternativa) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.ALTERNATIVA,
    };
    this.isReplayable = true;
  }

}

export class Selecionar extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.SELECIONAR;

  constructor(item: Secao | Pergunta, tipoConteudo: TipoConteudo) {
    super();
    this.payload = {
      item,
      tipoConteudo,
    };
    this.isReplayable = true;
  }
}

export class SelecionarPergunta extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.SELECIONAR_PERGUNTA;

  constructor(item: Secao | Pergunta, tipoConteudo: TipoConteudo) {
    super();
    this.payload = {
      item,
      tipoConteudo,
    };
    this.isReplayable = true;
  }
}

/**
 * TITULOS GRADE
 */

export class AdicionarTituloGrade extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ADICIONAR;

  constructor(estado: TituloGrade) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.TITULO_GRADE,
    };
    this.isReplayable = true;
  }

}

export class AlterarTituloGrade extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.ALTERAR;

  constructor(estado: AlterarTituloGrade) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.TITULO_GRADE,
    };
    this.isReplayable = true;
  }

}

export class RemoverTituloGrade extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.REMOVER;

  constructor(estado: RemoverTituloGrade) {
    super();
    this.payload = {
      estado,
      tipoElemento: TipoElemento.TITULO_GRADE,
    };
    this.isReplayable = true;
  }

}

export class SwitchOrdenacaoCotas extends AbstractAction {

  readonly passoCadastro = PassoCadastro.QUESTIONARIO;
  readonly type = ActionType.SWITCH_ORDENACAO_COTAS;

  constructor() {
    super();
    this.payload = {};
    this.isReplayable = true;
  }
}
