import { IQuestion } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { PerguntaQuestionarioAuditadoUtils } from "./utils";
import { DefinicoesPerguntaProcessor } from "./entidade/definicoes";
import { AlternativaAuditada } from "./entidade/alternativa";
import { SelecaoRespostaProcessor } from "./selecao/processor";
import { RespostaProcessorManager } from "./resposta/processor";

interface State {
  possuiAlteracaoDoAuditor?: boolean;
  perguntaFoiResetada?: boolean;
  isRespostaAbertaTextoUnica?: boolean;
  isRespostaMultipla?: boolean;
  respostas?: AlternativaAuditada[];
}

/**
 * Gerenciador principal: Encapsula uma PerguntaAuditada,
 * atribui a responsabilidade individual de processar
 * cada fragmento desses dados à processadores/gerenciadores
 * especificos, e externaliza métodos necessários para a integração
 * com a view;
 */
export class PerguntaQuestionarioAuditadoManager {
  constructor(dadosPergunta: IQuestion) {
    this.dadosPergunta = { ...dadosPergunta };
    this.utils = new PerguntaQuestionarioAuditadoUtils(this);

    this.definicoesPerguntaProcessor = new DefinicoesPerguntaProcessor(this);

    this.respostaPerguntaManager = new RespostaProcessorManager(this);

    this.selecaoRespostaProcessor = new SelecaoRespostaProcessor(this);

    this.inicializarState();
  }

  private respostaPerguntaManager: RespostaProcessorManager;
  private definicoesPerguntaProcessor: DefinicoesPerguntaProcessor;
  private selecaoRespostaProcessor: SelecaoRespostaProcessor;

  private dadosPergunta: IQuestion;

  public utils: PerguntaQuestionarioAuditadoUtils;

  public state: State = {
    possuiAlteracaoDoAuditor: false,
    perguntaFoiResetada: false,
    isRespostaAbertaTextoUnica: false,
    isRespostaMultipla: false,
    respostas: [] as AlternativaAuditada[],
  };

  private inicializarState() {
    this.state.isRespostaAbertaTextoUnica =
      this.utils.isRespostaAbertaTextoUnica();

    this.state.isRespostaMultipla = this.utils.isRespostaMultipla();

    this.state.possuiAlteracaoDoAuditor =
      this.checkPayloadPossuiRespostasDoAuditor();

    this.state.respostas =
      this.respostaPerguntaManager.activeProcessor.getRespostas();
  }

  public getDefinicoesPergunta() {
    return this.definicoesPerguntaProcessor;
  }

  public getRespostaPerguntaManager() {
    return this.respostaPerguntaManager;
  }

  public getDadosPergunta() {
    return this.dadosPergunta;
  }

  public resetarParaRespostasOriginais() {
    this.selecaoRespostaProcessor.resetarSelecao();
    this.atualizarState({ possuiAlteracaoDoAuditor: false, perguntaFoiResetada: true });
  }

  public getRespostas() {
    return this.respostaPerguntaManager.activeProcessor.getRespostas();
  }

  public getRespostasSelecionadas() {
    return this.selecaoRespostaProcessor.getSelecionadas();
  }

  public getAlternativasOriginais() {
    return this.dadosPergunta.alternativas;
  }

  public getRespostasAuditor() {
    return this.dadosPergunta.respostas.auditor;
  }

  public getRespostasEntrevistador() {
    return this.dadosPergunta.respostas.entrevistador;
  }

  public getQuantidadeAlternativasSelecionadas() {
    return this.selecaoRespostaProcessor.getQuantidadeSelecionadas();
  }

  public atualizarSelecaoAlternativa(alternativa: AlternativaAuditada): boolean {
    const estadoSelecao = !alternativa.isSelecionada;

    let operacaoRealizadaComSucesso = false;

    if (estadoSelecao) {
      operacaoRealizadaComSucesso = this.selecaoRespostaProcessor.select(alternativa);
    } else {
      operacaoRealizadaComSucesso = this.selecaoRespostaProcessor.unselect(alternativa);
    }

    if (operacaoRealizadaComSucesso) {
      this.atualizarState({ possuiAlteracaoDoAuditor: true, perguntaFoiResetada: false });
    }

    return operacaoRealizadaComSucesso;
  }

  public atualizarRespostaAlternativaAberta(
    alternativa: AlternativaAuditada,
    novoValor: string
  ) {
    this.respostaPerguntaManager.activeProcessor.atualizarRespostaAberta(
      alternativa,
      novoValor
    );

    this.atualizarState({ possuiAlteracaoDoAuditor: true, perguntaFoiResetada: false });
  }

  public getPossuiAlteracaoDoAuditor() {
    return this.state.possuiAlteracaoDoAuditor;
  }

  public getPerguntaIsResetada() {
    return this.state.perguntaFoiResetada;
  }

  public checkPayloadPossuiRespostasDoAuditor() {
    if (this.utils.isPerguntaComAlternativas()) {
      const existeRespostasDoAuditor =
        Array.isArray(this.getRespostasAuditor()) &&
        this.getRespostasAuditor().length > 0;

      return existeRespostasDoAuditor;
    }

    return typeof this.getRespostasAuditor() === "string";
  }

  public atualizarState(valores: State) {
    if (valores !== null) {
      for (let chave in valores) {
        if (this.state.hasOwnProperty(chave)) {
          if (this.state[chave] !== valores[chave]) {
            this.state[chave] = valores[chave];
          }
        }
      }
    }
  }
}
