import { Component, OnInit } from '@angular/core';
import { NotificatorService } from 'app/notificador/notificator.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-movidesk-chat',
  templateUrl: './movidesk-chat.component.html',
  styleUrls: ['./movidesk-chat.component.scss']
})
export class MovideskChatComponent implements OnInit {
  private mdChatClientCode: string = environment.movidesk.mdChatClientCode;
  private movideskUrl: string = environment.movidesk.path;

  ngOnInit() {
    this.loadMovideskChat();
  }

  constructor(
    private notify: NotificatorService,
  ) {}

  loadMovideskChat() {
    // Defina a variável global antes de carregar o script
    window['mdChatClient'] = this.mdChatClientCode;

    // Crie e adicione o script dinamicamente
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.movideskUrl
    script.onerror = () => {
      this.notify.showError('Erro ao carregar o chat', null)
    };
    document.body.appendChild(script);
  }
}
