import { HttpResponse } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ModalService } from "app/componentes/modal/modal.service";
import { ContratanteService } from "app/modulos/contratante-beta/services/contratante.service";
import { ValidateImageService } from "app/modulos/contratante-beta/services/validate-image.service";
import { ISelectedColor } from "../../../interfaces";
import { DefaultImagem } from "styles/constant";

enum StateImage {
  Novo = "NEW",
  Editar = "EDIT",
  Carregando = "LOADING",
}

@Component({
  selector: "app-customization",
  templateUrl: "./customization.component.html",
  styleUrls: ["./customization.component.scss"],
})
export class CustomizationComponent implements OnInit, OnChanges {
  @Input() formGroup!: FormGroup;
  @Input() isEdicao: boolean;
  @Input() themeName: string;

  colors: ISelectedColor = {
    default: false,
    verde: false,
    amarelo: false,
    laranja: false,
    vermelho: false,
  };

  /**
   * Objeto de controle de visualização dos inputs de imagem
   * Os possíveis valores serão Novo onde ele espera uma nova imagem, Carregando onde ele realiza as validações, salva a imagem
   * no bucket e retorna a url de visualização e Editar, em que o usuário pode mudar a imagem ou excluir.
   */
  imageManager = {
    logotipo_light: StateImage.Novo,
    login: StateImage.Novo,
  };

  fileOptions = {
    name: "",
    size: 0,
  };

  constructor(
    private contratanteService: ContratanteService,
    private imageService: ValidateImageService,
    private modalService: ModalService
  ) {}

  ngOnChanges({ themeName }: SimpleChanges): void {
    if (
      !!themeName &&
      this.isEdicao &&
      themeName.currentValue !== themeName.previousValue
    ) {
      Object.keys(this.colors).forEach((key) => {
        this.colors[key] = false;
      });
      this.colors[themeName.currentValue] = true;
    }
  }

  ngOnInit(): void {
    if (this.isEdicao) {
      this.imageManager = {
        logotipo_light: StateImage.Editar,
        login: StateImage.Editar,
      };
    }
  }

  handleCheck(selectedColor: string): void {
    this.formGroup.get("tema")?.setValue(selectedColor); // Atribui o valor a chave theme do formGroup
    for (let color in this.colors) {
      if (this.colors.hasOwnProperty(color)) {
        // Verifica se a chave existe no objeto
        this.colors[color] = false; // Limpa todas os valores para retirar a seleção anterior
      }
    }
    this.colors[selectedColor] = true; // Seleciona o input radius correspondente a cor
  }

  /**
   * Metódo responsável por pegar o arquivo importado pelo usuário e mandar para backend
   * Location = path da imagem (local de salvamento no banco)
   * Após obter o path acessa o atributo do forum e atribui o caminho da imagem
   *
   * @param formControlName = nome do atributo de acordo com o formBuilder
   * @param event = File (imagem selecionada pelo usuário)
   */
  getImagePath(formControlName, event) {
    this.imageManager[formControlName] = StateImage.Carregando;
    const file: File = event.target.files[0];
    const [type] = formControlName.split("_");

    this.fileOptions = {
      name: file.name,
      size: parseFloat((file.size / (1024 * 1024)).toFixed(2)),
    };

    /**
     * Validando se a imagem carregada está de acordo com as regras de dimensão, extensão e tamanho.
     */
    this.imageService.validarImagem(file, type).subscribe({
      next: () => {
        this.contratanteService.storeMedia(file).subscribe({
          // Em caso de sucesso a imagem é salva e uma prévia é carregada para o usuário
          next: (response: HttpResponse<any>) => {
            const location = response.headers.get("Location");
            this.formGroup.get(formControlName)?.setValue(location);
            this.imageManager[formControlName] = StateImage.Editar;
          },
          // Exibe a mensagem genérica para erros na rede
          error: () => {
            this.showModalError(formControlName);
          },
        });
      },
      // Exibe as mensagens específicas para erro na imagem e nas regras de dimensão, extensão e tamanho
      error: (err) => {
        this.showModalError(formControlName, err);
      },
    });
  }

  showModalError(
    controlName,
    dialog = "Parece que ocorreu um erro ao carregar a imagem. Selecione outra imagem ou tente novamente mais tarde"
  ) {
    this.modalService.showModal({
      title: "Upload de imagem",
      messageModal: dialog,
      btnTitlePositive: "Entendi",
      icon: "fa-regular fa-cloud-arrow-up",
      isOnlyConfirmation: true,
      positiveCallback: () => {
        this.removeImage(controlName);
      },
    });
  }

  /**
   * remove a imagem adicionada e define o estado do card como nova imagem
   * @param controlName Nome do campo cujo valor será restaurado para padrão;
   */
  removeImage(controlName) {
    this.formGroup.get(controlName)?.setValue(DefaultImagem[controlName]);
    this.imageManager[controlName] = StateImage.Novo;
  }

  /**
   * Renderiza a imagem no HTML
   *
   * @param formControlName
   * @returns path da imagem que será renderizada no html
   */
  renderImage(formControlName) {
    return this.formGroup.get(formControlName)?.value;
  }
}
