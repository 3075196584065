import { v4 as uuid } from 'uuid';
import { UntypedFormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';

export class TituloGrade {

  descricao: string;
  ordem: number;
  hash: string = uuid();
  hashPergunta: string;
  hashSecao: string;

  static getControl(): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line:max-line-length
      descricao: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_GRADE_TITULO)),
      ordem: new UntypedFormControl(''),
      hash: new UntypedFormControl(uuid()),
      hashPergunta: new UntypedFormControl(),
      hashSecao: new UntypedFormControl(),
    });

    return formGroup;
  }

  /**
     * Cria um FormGroup preenchido representando a entidade
     */
  static getInitializedControl(tituloGrade: TituloGrade): AbstractControl {

    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line:max-line-length
      descricao: new UntypedFormControl(tituloGrade.descricao, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_GRADE_TITULO)),
      ordem: new UntypedFormControl(tituloGrade.ordem),
      hash: new UntypedFormControl(tituloGrade.hash),
      hashPergunta: new UntypedFormControl(tituloGrade.hashPergunta),
      hashSecao: new UntypedFormControl(tituloGrade.hashSecao),
    });

    return formGroup;
  }

}
