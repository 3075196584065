import { Pipe, PipeTransform } from '@angular/core';
/*
 * Filtra, de uma lista de elementos secao, apenas as perguntas com cota.
*/
@Pipe({ name: 'filtrarEOrdenarPorCota' })
export class FiltrarEOrdenarPorCota implements PipeTransform {
  transform(value: any[], filter: boolean = true): any[] {

    console.log('[ElementoSecaoComCotaPipe] transform value: ', value);

    if (!filter) return value;
    return value.filter(elementoSecaoFG => elementoSecaoFG.value.pergunta.possuiCota)
      .sort((first, second) => {
        return first.value.pergunta.ordemCota - second.value.pergunta.ordemCota;
      });
  }
}