<section class="interviewConfig container-white">
  <div class="headerConfig">
    <span class="fa fa-sort-numeric-asc"></span>
    <h3>Escala numérica</h3>
  </div>
  <div class="contentConfig" [formGroup]="formGroup">
    <div class="section sg-input-group">
      <div class="campo">
        <label>Título da pergunta*</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="título da pergunta" title="título principal" type="text" formControlName="nome"  maxlength="250">
        <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
          A pergunta deve possuir um titulo de até 250 caracteres
        </span>
      </div>
      <div class="campo">
        <label>Orientação</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="Orientação ao Operador" title="Orientação para pergunta do tipo escala numérica" type="text" formControlName="descricao"  maxlength="150">
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
          A pergunta deve possuir uma orientação de até 150 caracteres
        </span>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">
          <h4>Escala*</h4>
        </div>
        <div class="subSectionContent row" formGroupName="faixaPergunta">
          <div class="campo col-6">
            <select formControlName="intervaloInicio" >
              <option *ngFor="let valorInicialMinimo of valoresIniciaisMinimos" [ngValue]="valorInicialMinimo">
                {{valorInicialMinimo}}
              </option>
            </select>
          </div>
          <div class="campo col-6">
            <select formControlName="intervaloFim" >
              <option *ngFor="let valorFinalMaximo of valoresFinaisMaximos" [ngValue]="valorFinalMaximo">
                {{valorFinalMaximo}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="actionConfig">
        <button title="Cancelar pergunta de escala numérica" class="sg-btn sg-alternate" (click)="cancelar()">Cancelar</button>
        <button title="Salvar pergunta de escala numérica" class="sg-btn sg-secondary" (click)="onChange()" *ngIf="modificacaoPermitida && !passoBloqueado">Salvar</button>
      </div>
    </div>
  </div>
</section>
<!-- <h1>Conteudo pergunta escala numerica</h1>
<div [formGroup]="formGroup" class="section">
  <label>
    Titulo da pergunta
    <input type="text" formControlName="nome"  maxlength="30">
    <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
      A pergunta deve possuir um titulo de até 250 caracteres
    </span>
  </label>
  <label>
    Descrição
    <input type="text" formControlName="descricao"  maxlength="150">
    <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
      A pergunta deve possuir uma descrição de até 150 caracteres
    </span>
  </label>

  <div formGroupName="faixaPergunta">

    <label>Valor inicial mínimo</label>
    <select formControlName="intervaloInicio" (change)="onChange()">
      <option *ngFor="let valorInicialMinimo of valoresIniciaisMinimos" [ngValue]="valorInicialMinimo">
        {{valorInicialMinimo}}
      </option>
    </select>

    <label>Valor final máximo</label>
    <select formControlName="intervaloFim" (change)="onChange()">
      <option *ngFor="let valorFinalMaximo of valoresFinaisMaximos" [ngValue]="valorFinalMaximo">
        {{valorFinalMaximo}}
      </option>
    </select><br />

    <label>
      Menor número permitido
      <input type="text" mascara-numero formControlName="menor"  maxlength="1" /><br />
      <span class="error-message"
        [hidden]="!(showErrors && controls.menor.errors && controls.menor.errors.maiorQueDeveria)">
        O menor número deve ser menor que o maior número permitido
      </span>
      <span class="error-message"
        [hidden]="!(showErrors && controls.menor.errors && controls.menor.errors.valorMaximoPermitido)">
        O menor número deve ser menor ou igual ao valor máximo final
      </span>
    </label>

    <label>
      Maior número permitido
      <input type="text" mascara-numero formControlName="maior"  maxlength="1" />
      <span class="error-message"
        [hidden]="!(showErrors && controls.maior.errors && controls.maior.errors.maiorQueDeveria)">
        O maior número deve ser maior que o menor número permitido
      </span>
      <span class="error-message"
        [hidden]="!(showErrors && controls.maior.errors && controls.maior.errors.valorMaximoPermitido)">
        O maior número deve ser menor ou igual ao valor máximo final
      </span>
    </label>

  </div>


</div> -->
