import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { RadioSelectItem } from "app/componentes/radio-select/radio-select.component";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { createRange } from "app/util/numbers/range";
import { zeroLeft } from "app/util/numbers/zeroleft";
import { ValidatorImpl } from "app/util/validator";
import * as moment from "moment";
import { CalendarData } from "../../../../../componentes/calendar/calendar.component";
import {
  SelectDataItem,
  SelectedItemEvent,
} from "../../../../../componentes/filterable-select/filterable-select-component";
import { ICanNotSaveButton } from "../../pesquisas-cadastro.model";
import {
  ConfiguracoesModel,
  CustomerModel,
  ScheduleModel,
  SchedulesModel,
} from "./pesquisas-configuracoes.model";
import { PesquisaConfiguracoesService } from "./servico/pesquisa-configuracoesservice";
import { TypesOfSurveysOptions } from "./tipo-pesquisa";

// metadados da ação de salvar dentro de configurações
type SaveMetaData = {
  metadata: { name: string; seq: number };
  model: ConfiguracoesModel;
};

@Component({
  selector: "app-pesquisas-configuracoes",
  templateUrl: "./pesquisas-configuracoes.component.html",
  styleUrls: ["./pesquisas-configuracoes.component.scss"],
})
export class PesquisasConfiguracoesComponent implements OnInit, OnChanges {
  // timeouts ids
  totalSamplesTimeoutId = 0;
  preseSamplesTimeoutId = 0;
  onlinSamplesTimeoutId = 0;
  // propriedade do componente
  // colocando any temporariamente
  @Input() stepModel: any;
  // saída dos eventos
  @Output() onChange: EventEmitter<void> = new EventEmitter();
  @Output() onDone: EventEmitter<void> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSave: EventEmitter<SaveMetaData> = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> =
    new EventEmitter();

  // função que emite para o pai os dados a serem salvos
  @Output() onNewSave: EventEmitter<any> = new EventEmitter();

  // atualizados por inicialização
  customerItems: SelectDataItem[];
  typesOfSurveys: RadioSelectItem[] = TypesOfSurveysOptions.map((o) => ({
    ...o,
    checked: false,
  }));

  minTimesItems: RadioSelectItem[] = createRange(1, 60).map((value) => {
    const v = `${zeroLeft(value, 2)}:00`;
    return {
      label: v,
      value: v,
      checked: value === 0,
    };
  });

  // locais
  withOnlineSamples: boolean = false;
  withTotalSamples: boolean = false;
  withPresencialSamples: boolean = false;
  disabledBtnSave: boolean = true;
  selectedCustomerItem: SelectDataItem;
  selectedTypeOfSurvey: RadioSelectItem;
  selectedMinTimesItem: RadioSelectItem;
  // configurações de acordo com o modelo de dados
  surveyId: number;
  customerId: number;
  title: string;
  maxInputLength = {
    title: 250,
    orientation: 500,
    objective: 150,
  };
  orientation: string = "";
  objective: string;
  surveyKind: string;
  totalSamples: string;
  presencialSamples: string;
  onlineSamples: string;
  distance: string;
  minTime: string;
  allowAudio: boolean = false;
  onlineToken: string = "";
  viewToken: string = "";
  schedules: SchedulesModel;
  selectedCustomer: CustomerModel;
  isDateValid: boolean;

  constructor(
    private service: PesquisaConfiguracoesService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit() {
    // listar todos os clientes para seleção
    this.service.listarTodosClientes().subscribe({
      next: (customers) => {
        // inicializa a lista de clientes
        this.customerItems = [];
        // converte em itens do selector
        customers.forEach((c, i) => {
          // adiciona todos os itens ao selector
          this.customerItems.push({
            label: c.nome,
            value: c.id,
            hidden: false,
            checked: false, // TODO: como fazer para vir marcado?
          });
        });
        // seleciona um item, caso já exista
        this.selectCustomer();
      },
      error: (err) => {
        //TODO: tratar o erro
        console.error(err);
        this.errorHandlerService.handleError(
          err,
          "Erro ao carregar os clientes"
        );
      },
    });
    // inicializa variáveis auxiliares
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stepModel) {
      // inicializa variáveis auxiliares
      this.init();
    }
  }

  // Formata o documento (cpf/cnpj) para renderizar no html
  documentFormatter(document: string): string {
    document = document.replace(/\D/g, '');

    if (document.length === 11) { // Formata como CPF: xxx.xxx.xxx-xx
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (document.length === 14) { // Formata como CNPJ: xx.xxx.xxx/xxxx-xx
      return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return document; // Retorna o documento sem formatação se não for um CPF ou CNPJ válido
    }
  }

  // Altera o textarea do titulo de acordo com o tamanho do titulo
  checkInitInputsHeight(): void {
    const wdTitle = document.querySelector("#title") as HTMLElement;
    if (wdTitle.scrollHeight) {
      wdTitle.style.height = `${
        this.title.length < 30
          ? 48
          : wdTitle.scrollHeight > 48
          ? wdTitle.scrollHeight
          : 48
      }px`;
    }

    const wdOrientation = document.querySelector("#orientation") as HTMLElement;
    if (wdOrientation.scrollHeight) {
      wdOrientation.style.height = `${
        this.orientation.length < 30
          ? 48
          : wdOrientation.scrollHeight > 48
          ? wdOrientation.scrollHeight
          : 48
      }px`;
    }

    const wdObjective = document.querySelector("#objective") as HTMLElement;
    if (wdObjective.scrollHeight) {
      wdObjective.style.height = `${
        this.objective.length < 30
          ? 48
          : wdObjective.scrollHeight > 48
          ? wdObjective.scrollHeight
          : 48
      }px`;
    }
  }

  // Verifica se o titulo existe e se ele é maior do que o valor máximo permitido (100 caracteres)
  checkInitInputsLength(): boolean {
    if (
      (this.title && this.title.length > this.maxInputLength.title) ||
      (this.orientation &&
        this.orientation.length > this.maxInputLength.orientation) ||
      (this.objective && this.objective.length > this.maxInputLength.objective)
    ) {
      this.verifyRequiredFields();
      return false;
    } else {
      return true;
    }
  }

  // Recebe o evento da mudança nos períodos do calendário que verifica se os dados inputados para date são válidos
  changeDateInputValidate(value: boolean) {
    this.isDateValid = value;
    // chama a verificação dos campos para bloquear ou ativar o botão "avançar" com base nas validações
    setTimeout(() => {
      this.verifyRequiredFields();
      this.onChange.emit();
    }, 500);
  }

  // inicializa algumas variáveis auxiliares
  init() {
    if (this.stepModel) {
      this.surveyId = this.stepModel.id ? this.stepModel.id : null;
      this.title = this.stepModel.descricaoPesquisa.titulo
        ? this.stepModel.descricaoPesquisa.tituloCurto
        : "";
      this.orientation = this.stepModel.textoInicial
        ? this.stepModel.textoInicial
        : "";
      this.objective = this.stepModel.descricaoPesquisa.objetivo
        ? this.stepModel.descricaoPesquisa.objetivo
        : "";
      this.surveyKind = this.stepModel.descricaoPesquisa.tipoPesquisa
        ? this.stepModel.descricaoPesquisa.tipoPesquisa
        : "";
      this.presencialSamples = this.stepModel.configuracaoPesquisa
        .amostrasPresenciais
        ? this.stepModel.configuracaoPesquisa.amostrasPresenciais
        : 0;
      this.onlineSamples = this.stepModel.configuracaoPesquisa.amostrasOnline
        ? this.stepModel.configuracaoPesquisa.amostrasOnline
        : 0;
      this.distance = this.stepModel.configuracaoPesquisa.distancia
        ? this.stepModel.configuracaoPesquisa.distancia
        : Number("");
      this.minTime = this.stepModel.configuracaoPesquisa.tempoMinimo
        ? this.stepModel.configuracaoPesquisa.tempoMinimo
        : "";
      this.allowAudio = this.stepModel.descricaoPesquisa.permitirGravacao
        ? this.stepModel.descricaoPesquisa.permitirGravacao
        : false;
      this.schedules = this.toSchedulesModel(
        this.stepModel.configuracaoPesquisa.agendamentos
      );
      this.customerId = this.stepModel.cliente
        ? this.stepModel.cliente.id
        : null;
      this.onlineToken = this.stepModel.configuracaoPesquisa.token
        ? this.stepModel.configuracaoPesquisa.token
        : "";
      this.viewToken = this.stepModel.configuracaoPesquisa.token
        ? this.stepModel.configuracaoPesquisa.token
        : "";
      this.totalSamples =
        this.stepModel.configuracaoPesquisa.amostrasOnline +
        this.stepModel.configuracaoPesquisa.amostrasPresenciais;
      // tratamento das variáveis locais baseado nos dados recebidos
      this.resetSamples();
      this.selectCustomer();
      this.selectTypeOfSurvey();
      this.selectMinTime();
      setTimeout(() => {
        this.checkInitInputsHeight();
      }, 0);
      // confirma o estado deste componente
      if (this.surveyId && this.checkInitInputsLength()) {
        // caso inicialize para edição sem dados faltando
        // está pronto para seguir, mas com botão
        this.onDone.emit();
      } else {
        // caso inicialize para inserção ou
        // caso inicialize para edição, mas com dados faltando
        // não está pronto para seguir
        this.onChange.emit();
      }

      // propaga os dados que devem ser salvos pelo botão avançar
      const model = this.stepModel;
      this.onNewSave.emit({
        metadata: { name: "cadastrar-pesquisa", seq: null },
        model,
      });

    }
  }

  // cria o modelo de saída
  createModel(): ConfiguracoesModel {
    return {
      id: this.surveyId,
      cliente: {
        id: this.customerId,
      },
      descricaoPesquisa: {
        titulo: this.title,
        objetivo: this.objective,
        tipoPesquisa: this.surveyKind,
        tituloCurto: this.title,
        permitirGravacao: this.allowAudio,
      },
      textoInicial: this.orientation,
      configuracaoPesquisa: {
        amostrasPresenciais: Number(this.presencialSamples),
        amostrasOnline: Number(this.onlineSamples),
        distancia:
          Number(this.presencialSamples) === 0 ||
          Number(this.distance) === Number("")
            ? 0
            : Number(this.distance),
        tempoMinimo:
          Number(this.presencialSamples) === 0 || !this.minTime
            ? "00:00"
            : this.minTime,
        agendamentos: this.schedules.toModelArray(),
        token: this.onlineToken,
        tokenVisualizacao: this.viewToken,
      },
    };
  }

  // converte para schedules model
  toSchedulesModel(values: any[]): SchedulesModel {
    const sm = values.map(
      (v) =>
        ({
          ...v,
        } as ScheduleModel)
    );
    return new SchedulesModel(sm);
  }

  // função que busca a informação do cliente para exibir quando selecionado
  assignCustomer(cid: number) {
    this.service.encontrarCliente(cid).subscribe({
      next: (c) => {
        this.selectedCustomer = {
          cpfCnpj: c.pessoa ? this.documentFormatter(c.pessoa.cpf || c.pessoa.cnpj) : null,
          email: c.usuario ? c.usuario.login : null,
          nome: c.pessoa ? c.pessoa.nome : null,
        };
      },
      error: (e) => console.error(e),
    });
  }

  // função boolean que valida se o e-mail do cliente selecionado contém 24
  // (FIXME: 24 ou 20??) caracteres ou mais
  getLengthOfEmail(): boolean {
    if (this.selectedCustomer) {
      const length = this.selectedCustomer.email?.length;
      return length > 20 ? true : false;
    }
    return false;
  }

  // reseta os verificadores de existência de amostras
  resetSamples() {
    this.withTotalSamples = Number(this.totalSamples) > 0;
    this.withPresencialSamples = Number(this.presencialSamples) > 0;
    this.withOnlineSamples = Number(this.onlineSamples) > 0;
    if (!this.minTime) {
      this.selectedMinTimesItem = undefined;
    }
  }

  // seleciona um cliente na lista com base no valor do customer id
  selectCustomer() {
    // define o valor selecionado, caso exista
    if (!!this.customerId && Array.isArray(this.customerItems)) {
      for (let index = 0; index < this.customerItems.length; index++) {
        if (this.customerItems[index].value === this.customerId) {
          this.selectedCustomerItem = this.customerItems[index];
          // chamada para atualizar a variável que possue os dados
          // do cliente quando selecionado
          this.assignCustomer(this.customerItems[index].value);
          return;
        }
      }
    }
  }

  // seleciona um tipo de pesquisa com base no model
  selectTypeOfSurvey() {
    // define o valor selecionado, caso exista
    if (!!this.surveyKind && Array.isArray(this.typesOfSurveys)) {
      for (let index = 0; index < this.typesOfSurveys.length; index++) {
        if (this.typesOfSurveys[index].value === this.surveyKind) {
          this.selectedTypeOfSurvey = {
            ...this.typesOfSurveys[index],
            checked: true,
          };
          break;
        }
      }
    }
  }

  // seleciona um tempo mínimo para a pesquisa
  selectMinTime() {
    // define o valor selecionado, caso exista
    if (!!this.minTime && Array.isArray(this.minTimesItems)) {
      for (let index = 0; index < this.minTimesItems.length; index++) {
        if (this.minTimesItems[index].value === this.minTime) {
          this.selectedMinTimesItem = {
            ...this.minTimesItems[index],
            checked: true,
          };
          break;
        }
      }
    }
  }

  // verifica se os campos obrigatórios estão preenchidos
  verifyRequiredFields() {
    // validação
    const validator = new ValidatorImpl();
    const validSpecifications = validator
      .validateTrue(!!this.title, "O campo de título não pode ser vazio")
      .validateTrue(
        this.title && this.title.length <= this.maxInputLength.title,
        "O campo de título não pode conter mais de 250 caracteres"
      )
      // .validateTrue(!!this.orientation, "O campo de orientação não pode ser vazio")
      .validateTrue(
        this.orientation.length <= this.maxInputLength.orientation,
        "O campo de orientação não pode conter mais de 500 caracteres"
      )
      .validateTrue(!!this.objective, "O campo de objetivo não pode ser vazio")
      .validateTrue(
        !!this.objective &&
          this.objective.length <= this.maxInputLength.objective,
        "O campo de objetivo não pode conter mais de 150 caracteres"
      )
      .validateIsNotNull(
        this.selectedCustomerItem,
        "O campo cliente precisa de um cliente selecionado"
      )
      .validateIsNotNull(
        this.selectedTypeOfSurvey,
        "O campo tipo de pesquisa precisa de um cliente selecionado"
      )
      .validateTrue(
        Number(this.presencialSamples) > 0 ? Number(this.distance) >= 0 : true,
        "O campo de distância não pode ser menor que zero"
      )
      .validateTrue(
        Number(this.presencialSamples) > 0
          ? !!this.minTime && this.minTime !== "00:00"
          : !!this.minTime && this.minTime === "00:00",
        "O campo de tempo mínimo da entrevista não pode ser vazio"
      )
      .validateIsNotZero(
        Number(this.totalSamples),
        "O campo do total de amostras não pode ser zero"
      )
      .validateTrue(
        Number(this.totalSamples) ===
          Number(this.onlineSamples) + Number(this.presencialSamples),
        "A soma das amostras presenciais e online precisam ser igual ao total de amostras. Calc: " +
          this.onlineSamples +
          "+" +
          this.presencialSamples +
          "=" +
          (this.onlineSamples + this.presencialSamples)
      )
      .validateTrue(
        this.schedules && !this.schedules.isEmpty(),
        "O campo de período da entrevista precisa ter uma definição de datas"
      )
      .validateTrue(
        this.isDateValid,
        "O campo de período da entrevista precisa ter uma data válida"
      )
      .withErrors();
    // habilita ou disabilita o botão salvar
    this.disabledBtnSave = validSpecifications;
    // propaga se pode habilitar o botão de avançar ou não
    this.onCanNotSaveButton.emit({
      metadata: { canNotSaveButton: validSpecifications },
    });

    // new save
    if (!validSpecifications) {
      // propaga dados para fora
      const model = this.createModel();
      // notificar o mudança no modo da pesquisa
      const actionName = !!model.id ? "editar-pesquisa" : "cadastrar-pesquisa";
      // propaga os dados que devem ser salvos pelo botão avançar
      this.onNewSave.emit({ metadata: { name: actionName, seq: null }, model });
    }
    // log de erros
    validator.errors().map((e) => console.error(e.message));
  }

  // manipula o evento de alteração do total de amostras
  handleTotalSamplesChange(event: string) {
    window.clearTimeout(this.totalSamplesTimeoutId);
    this.totalSamplesTimeoutId = window.setTimeout(() => {
      // convertendo texto para número (problema com s-app-input)
      const vlr = Number.parseInt(event, 10);

      // voltando os valores dos inputs caso seja null o campo
      if (!vlr) {
        this.distance = null;
        this.minTime = "00:00";
        this.selectedMinTimesItem = undefined;
        this.allowAudio = false;
        this.onlineSamples = null;
        this.presencialSamples = null;
      }

      // definindo valores iniciais
      this.totalSamples = String(vlr);
      this.presencialSamples = vlr !== 0 ? String(vlr) : null;
      this.onlineSamples = vlr === 0 || !vlr ? null : String(0);
      // reseta as verificações de amostras
      this.resetSamples();
      // verifica se está pronto para  salvar
      this.verifyRequiredFields();
      // notificar o mudança na pesquisa
      this.onChange.emit();
    }, 100);
  }

  // manipula o evento de alteração das amostras presenciais
  handlePresencialSamplesChange(event: string) {
    window.clearTimeout(this.preseSamplesTimeoutId);
    this.preseSamplesTimeoutId = window.setTimeout(() => {
      // convertendo texto para número (problema com s-app-input)
      const vlr = Number.parseInt(event, 10);
      // calcular o resultado
      let resultOnline = Number(this.totalSamples) - vlr;
      // verificações
      if (resultOnline > Number(this.totalSamples) || resultOnline < 0) {
        this.distance = null;
        this.minTime = "00:00";
        this.selectedMinTimesItem = undefined;
        this.allowAudio = false;
        this.onlineSamples = this.totalSamples;
        this.presencialSamples = String(0);
      } else if (!vlr) {
        this.distance = null;
        this.minTime = "00:00";
        this.selectedMinTimesItem = undefined;
        this.allowAudio = false;
        this.onlineSamples = this.totalSamples;
        this.presencialSamples = String(0);
      } else {
        this.onlineSamples = String(resultOnline);
        this.presencialSamples = String(vlr);
      }
      // redefine os valores do presencial se não tiver presencial
      if (Number(this.presencialSamples) === 0) {
        this.distance = null;
        this.minTime = "00:00";
        this.selectedMinTimesItem = undefined;
        this.allowAudio = false;
      }
      // reseta as verificações de amostras
      this.resetSamples();
      // verifica se está pronto para  salvar
      this.verifyRequiredFields();
      // notificar o mudança no modo da pesquisa
      this.onChange.emit();
    }, 100);
  }

  // manipula o evento de alteração das amostras presenciais
  handleOnlineSamplesChange(event: string) {
    window.clearTimeout(this.onlinSamplesTimeoutId);
    this.onlinSamplesTimeoutId = window.setTimeout(() => {
      // convertendo texto para número (problema com s-app-input)
      const vlr = Number.parseInt(event, 10);
      // calculando o resultado presencial
      let resultPresencial = Number(this.totalSamples) - vlr;
      // verificação das amostras online
      if (
        resultPresencial > Number(this.totalSamples) ||
        resultPresencial < 0
      ) {
        this.onlineSamples = String(0);
        this.presencialSamples = this.totalSamples;
        this.distance = null;
        this.minTime = "00:00";
        this.selectedMinTimesItem = undefined;
      } else if (!vlr) {
        this.onlineSamples = String(0);
        this.presencialSamples = this.totalSamples;
        // this.distance = null;
        // this.minTime = '00:00';
        // this.selectedMinTimesItem = undefined
      } else {
        this.onlineSamples = String(vlr);
        this.presencialSamples = String(resultPresencial);
      }
      // redefine os valores do presencial se não tiver presencial
      if (Number(this.presencialSamples) === 0) {
        this.distance = null;
        this.minTime = "00:00";
        this.selectedMinTimesItem = undefined;
        this.allowAudio = false;
      }
      // reseta as verificações de amostras
      this.resetSamples();
      // verifica se está pronto para  salvar
      this.verifyRequiredFields();
      // notificar o mudança no modo da pesquisa
      this.onChange.emit();
    }, 100);
  }

  // manipula o evento de alteração do total de amostras
  handleDistanceChange(event: string) {
    if (!event) {
      this.distance = null;
    } else {
      const vlr = Number.parseInt(event, 10);
      this.distance = String(vlr);
    }
    // verifica se está pronto para  salvar
    this.verifyRequiredFields();
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  // manipula a alteração do cliente
  handleCustomerChange(event: SelectedItemEvent) {
    this.customerId = event.item.value;
    this.selectCustomer();
    // chamada para atualizar a variável que possue
    // os dados do cliente quando selecionado
    this.assignCustomer(event.item.value);
    // verifica se está pronto para  salvar
    this.verifyRequiredFields();
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  // manipula a alteração do tempo mínimo da pesquisa
  handleMinTimeChange(event: string) {
    this.minTime = event;
    this.selectMinTime();
    // verifica se está pronto para  salvar
    this.verifyRequiredFields();
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  // lida com mudanças dos inputs e select
  handleChange(event: any, attr: string) {
    // define valores para o
    this[attr] = attr !== "allowAudio" ? event : !this.allowAudio;
    // verifica se está pronto para  salvar
    this.verifyRequiredFields();
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  // manipula alterações no calendário
  handleCalendarChanges(date: CalendarData) {
    const schedules: ScheduleModel[] = date.dates.map((item) => {
      return {
        id: null,
        dataColeta: moment(item).format("DD/MM/YYYY"),
      };
    });
    this.schedules = new SchedulesModel(schedules);
    // verifica se está pronto para  salvar
    this.verifyRequiredFields();
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  // função para atualizar o check das opções de tipo de pesquisa
  handleTypeOfSurveySelect(value: string) {
    this.surveyKind = value;
    this.selectTypeOfSurvey();
    // verifica se está pronto para  salvar
    this.verifyRequiredFields();
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  // função do clique de salvar pesquisa
  handleSaveClick() {
    if (this.disabledBtnSave) {
      return;
    }
    // propaga dados para fora
    const model = this.createModel();
    // notificar o mudança no modo da pesquisa
    const actionName = !!model.id ? "editar-pesquisa" : "cadastrar-pesquisa";
    this.onSave.emit({ metadata: { name: actionName, seq: null }, model });
    // propaga os dados que devem ser salvos pelo botão avançar
    this.onNewSave.emit({ metadata: { name: actionName, seq: null }, model });
    // desabilita o botão salvar
    this.disabledBtnSave = true;
  }

  // não possui implementação
  handleStepCancel() {}
}
