<div
  [ngClass]="{
    alternative: true,
    selected: alternativa.isSelecionada,
    blockActions: actionsBlockedBySituation
  }"
>
  <p>
    {{ alternativa.enunciado }}
  </p>
  <input
    #numberInput
    type="number"
    class="number-input"
    maxlength="9"
    step="1"
    pattern="/^-?\d+\.?\d*$/"
    (input)="handleInput($event)"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    [disabled]="!alternativa.isSelecionada && !selecionavel"
    [(ngModel)]="value"
  />
</div>
