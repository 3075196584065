import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ThemeService } from "styles/services/theme.service";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { CURRENT_HIRER } from "app/app.config";
import { BehaviorSubject } from "rxjs";
import { themeMatching } from "styles/constant";

const DEFAULT_THEME: string = 'default-theme';

@Component({
  selector: "app-theme-listener",
  templateUrl: "./theme-listener.component.html",
})
export class ThemeListenerComponent implements OnInit, OnDestroy {
  private hirerSubscription: Subscription;
  private theme: string = DEFAULT_THEME;
  private lastHirer: string = '';

  constructor(
    private themeService: ThemeService,
    private securityService: SecurityService,
    @Inject(CURRENT_HIRER) private currentHirer: BehaviorSubject<string>  // Injeção de CURRENT_HIRER
  ) {}

  ngOnInit() {
    this.listenToHirerChanges();
  }

  /**
   * Inscreve-se para ouvir mudanças no BehaviorSubject CURRENT_HIRER
   * Atualiza o tema sempre que o hirer mudar
   */
  private listenToHirerChanges() {
    this.hirerSubscription = this.currentHirer.subscribe((newHirer) => {
      if (newHirer && (this.lastHirer !== newHirer)) {
        this.lastHirer = newHirer;
        this.getThemeByTenant();
      }
    });
  }

  /**
   * Realiza a chamada ao backend para obter o tema atual
   */
  getThemeByTenant() {
    const userSubtenant = this.securityService.getAuthenticatedUserSubtenant();
    const hirer = userSubtenant ? userSubtenant : this.currentHirer.value;  // Usa o valor atual de currentHirer

    this.themeService.getThemeByHirer(hirer).subscribe({
      next: (config) => {
        this.themeService.setImage(config)
        if(this.theme !== config.tema) {
          this.theme = config.tema
          this.currentHirer.next(hirer);
          this.updateTheme(this.theme);
        }
      },
      error: () => {
        this.updateTheme(DEFAULT_THEME);
      },
    });
  }

  /**
   * Atualiza o tema atual
   */
  private updateTheme(newTheme) {
    document.body.className = `${themeMatching[newTheme]} neutros alertas status medals`
  }

  /**
   * Se desinscreve quando componente é destruido
   */
  ngOnDestroy() {
    if (this.hirerSubscription) {
      this.hirerSubscription.unsubscribe();
    }
  }
}
