<app-breadcrumb
  [data]="dataBreadcrumb"
  [goBackText]="'Voltar à visão geral'"
></app-breadcrumb>

<app-header-produtividade
  [tituloHeader]="header.titulo"
  [item1]="header.periodo"
  [item2]="header.resumo"
  [progresso]="header.progresso"
></app-header-produtividade>

<main class="operator-list-container">

  <h3>Avaliação de produtividade</h3>

  <app-generic-table
    [tHead]="tHeadOperator"
    [data]="tableData"
    [tableConfig]="tableConfig"
    [enableFilters]="true"
    [filters]="tableFilter"
    [enableSearch]="true"
    [searchInputConf]="searchConfig"
    [paginationLabel]="'Operadores'"
    [currentPage]="pagination.current"
    (events)="handleEventTable($event)"
    [totalOfEntries]="totalOperatos"
  ></app-generic-table>
</main>

<app-loading *ngIf="isLoading"></app-loading>
