<section>
  <div class="filter">
    <app-input-search-auditoria
      [dropdownMode]="true"
      [dropdownOptions]="operatorDropdownOptions"
      (handleChange)="handleTextChange($event)"
      placeholder="Busque por entrevistadores"
    ></app-input-search-auditoria>
    <app-filter-select
      [icon]="filterLocationConf.icon"
      [type]="filterLocationConf.type"
      placeholder="Localidades"
      [options]="locationOption"
      (changeFilter)="handleChangeLocations($event)"
    ></app-filter-select>
    <app-filter-select
      *ngIf="surveyIntevalCollection.startDate && surveyIntevalCollection.endDate"
      [icon]="filterDateRangeConf.icon"
      [type]="filterDateRangeConf.type"
      [placeholder]="filterDateRangeConf.placeholder"
      [selectConfig]="{ intervalDate: surveyIntevalCollection }"
      (changeFilter)="handleChangeDateRange($event)"
    ></app-filter-select>
    <app-filter-select
      [icon]="filterStatusConf.icon"
      [type]="filterStatusConf.type"
      placeholder="Filtrar por Situação"
      [options]="statusOptions"
      (changeFilter)="handleChangeStatusFilter($event)"
    ></app-filter-select>
  </div>

  <div class="map-container">
    <app-leaflet-map-beta [mapHeight]="'768px'"></app-leaflet-map-beta>
  </div>

  <app-survey-map-tooltip [surveyId]="surveyId"></app-survey-map-tooltip>
</section>
<app-loading *ngIf="isLoading"></app-loading>
