import { colorPalette } from "../colors"
import { gridTooltipFormatter } from "../utils/tooltipFormatter"
import { EChartsOption } from 'echarts';

export function toEchartOption(chartData, series): EChartsOption {
  const chartOption: EChartsOption = {
    grid: {
      left: "0%",
      width: "100%"
    },
    xAxis: {
      type: 'category',
      axisTick: {},
      axisLabel: {
        fontWeight: 700,
      },
      axisLine: {
        lineStyle: {
          color: '#610082',
        }
      }
    },
    yAxis: {
      axisLabel: {
        show: false // não exibir os valores do eixo y
      },
      splitLine: {
        show: false, // não exibir as linhas no background
      },
    },
    color: colorPalette,
    series: series,
    dataset: chartData,
    label: {
      show: true, // exibir a label
      position: 'top', // posição da label na barra
      formatter: (v) => `${v.value[v.seriesName]}%`,
      color: '#610082',
      fontWeight: 700,
      fontSize: '12px',
    },
    legend: { // posição da legenda
      orient: 'horizontal',
      type: 'scroll',
      top: 'top',
      left: 'left',
      icon: 'circle'
    },
    tooltip: {
      trigger: 'item',
      confine: true,
      position: 'top',
      axisPointer: {
        type: 'shadow',
      },
      borderColor: '#610082',
      formatter: (params) => gridTooltipFormatter(params)
    }
  }

  return chartOption as EChartsOption;
}
