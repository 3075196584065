import { IOperatorPostPayload } from "../interfaces";
import { IOperatorEditPayload, IOperatorFormData } from "../interfaces/operatorForm";

/**
 * Converte os dados do formulário para o modelo de payload esperado
 * na crição de um novo operador.
 * @param operatorData: Dados do operador
 * @returns payload de criação de um novo operador
 */
export function createOperatorPayload(operatorData: IOperatorFormData): IOperatorPostPayload {
  return {
    periodoInicial: operatorData.vinculoInicial,
    periodoFinal: operatorData.vinculoFinal,
    pessoa: {
      id: null,
      nome: operatorData.name,
      cpf: operatorData.cpf
    },
    usuario: {
      id: null,
      ativo: operatorData.status
    },
    contato: {
      email: operatorData.email
    },
    fotoEnviada: operatorData.photo
    ? { arquivo: operatorData.photo }
    : null,
  }
}

/**
 * Converte os dados do formulário para o modelo de payload esperado
 * na atualização do operador.
 * @param operatorData: Dados do operador
 * @returns payload de update do operador
 */
export function updateOperatorPayload(operatorData: IOperatorFormData, personAndUserIds): IOperatorEditPayload {
  return {
    id: operatorData.id,
    periodoInicial: operatorData.vinculoInicial,
    periodoFinal: operatorData.vinculoFinal,
    pessoa: {
      id: personAndUserIds.personId,
      nome: operatorData.name,
      cpf: operatorData.cpf
    },
    usuario: {
      id: personAndUserIds.userId,
      ativo: operatorData.status
    },
    contato: {
      email: operatorData.email
    },
    fotoEnviada: operatorData.photo
    ? { arquivo: operatorData.photo }
    : null,
  }
}
