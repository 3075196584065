<section>
  <header>
    <h3>Passo 2</h3>
    <p>Crie citações ou selecione um grupo já criado para consolidar</p>
  </header>
  <hr />
  <article>
    <label>Corrigir respostas selecionas por</label>
    <div class="input-container">
      <input
        (input)="onChange($event)"
        type="text"
        [ngClass]="{ error: errorStatus || groupTitle.length > 140 }"
        maxlength="141"
        [value]="groupTitle"
      />
      <div
        *ngIf="groupTitle.length && !choosedGroup && handleFilteredGroups().length"
        class="groups-suggest"
      >
        <div
          *ngFor="let group of handleFilteredGroups()"
          class="group"
          (click)="setTitleOfGroup(group)"
        >
          <p>{{ group.title }}</p>
          <label> {{ group.citation.length }} citações</label>
        </div>
      </div>
    </div>
    <div class="info-container">
      <span
        [ngClass]="{ 'span-error': errorStatus || groupTitle.length > 140 }"
        [style.visibility]="
          errorStatus || groupTitle.length > 140 ? 'visible' : 'hidden'
        "
      >
        {{ errorMessage }}
      </span>
      <span>{{ groupTitle.length }}/140</span>
    </div>
    <button
      [ngClass]="{ 'disabled-btn': groupTitle.length > 140 || blockBtnByTitle()}"
      (click)="handleMatch()"
    >
      Consolidar
    </button>
  </article>
  <footer>
    <svg viewBox="0 0 633 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3108_10183"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="633"
        height="260"
      >
        <rect width="633" height="260" />
      </mask>
      <g mask="url(#mask0_3108_10183)">
        <path
          class="rect-one"
          d="M469.727 193.4C382.772 215.991 278.581 192.78 172.363 125.13C116.379 89.4592 58.5476 62.3456 0.000482893 44.0459L0.000457764 331.039L477.779 331.039C471.853 285.429 469.131 239.19 469.731 193.404L469.727 193.4Z"
          fill-opacity="0.3"
        />
        <path
          class="rect-two"
          d="M665.051 2.9754L637.256 2.9754C632.966 65.2893 600.744 122.619 546.161 160.775C522.814 177.098 497.252 188.788 469.954 195.869C469.367 240.837 472.037 286.25 477.86 331.039L665.051 331.039L665.051 2.9754Z"
          fill-opacity="0.1"
        />
        <path
          class="rect-three"
          d="M469.873 195.869C474.162 133.555 506.384 76.2259 560.972 38.0691C584.318 21.7462 609.881 10.0612 637.179 2.97585C637.174 3.41226 637.166 3.84441 637.162 4.28082C632.516 66.0899 600.363 122.884 546.161 160.776C522.814 177.099 497.252 188.784 469.954 195.869L469.873 195.869Z"
          fill-opacity="0.3"
        />
      </g>
    </svg>
  </footer>
</section>
