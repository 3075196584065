<dp-day-calendar theme="calendario-tensai" #datePicker
        [config]="calendarConfig"
        [(ngModel)]="diasSelecionados"
        (onSelect)="onCalendarioChange()" 
        >       
</dp-day-calendar>

   
<!-- <dp-day-calendar *ngIf="datasPreviamenteSelecionadas"
        [(ngModel)]="diasSelecionados"
        [config]="config"
        [displayDate]="month"
        (onSelect)="ngOnChanges()">

</dp-day-calendar> -->

<!-- <multiple-date-picker *ngIf="datasPreviamenteSelecionadas && datasPreviamenteSelecionadas.length > 0" [month]="month" #calendario 
        id="calendarPesquisa"
        [showDaysOfSurroundingMonths]="false" 
        [matIcons]="true" 
        [disallowBackPastMonths]="false" 
        [sundayFirstDay]="true" 
        [disableDaysBefore]="false"
        [disableDaysAfter]="false"
        [(ngModel)]="diasSelecionados"
        (ngModelChange)="onChange()"
        >
</multiple-date-picker>

<multiple-date-picker *ngIf="!(datasPreviamenteSelecionadas && datasPreviamenteSelecionadas.length > 0)" #calendario 
        id="calendarPesquisa"
        [showDaysOfSurroundingMonths]="false" 
        [matIcons]="true" 
        [disallowBackPastMonths]="false" 
        [sundayFirstDay]="true" 
        [disableDaysBefore]="false"
        [disableDaysAfter]="false"
        [(ngModel)]="diasSelecionados"
        (ngModelChange)="onChange()"
        >
</multiple-date-picker> -->


