import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  TipoPergunta,
} from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { BadgeModel } from "../components/badge/badge.component.";
import {
  IAnswaresList,
  IQuestion,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { AnswerOutput } from "../questions.component";
import { PerguntaQuestionarioAuditadoManager } from "../gerenciador";
import { AlternativaAuditada } from "../gerenciador/entidade/alternativa";

@Component({
  selector: "app-generic-response-question",
  templateUrl: "./generic-response.component.html",
  styleUrls: ["./generic-response.component.scss"],
})
export class GenericResponseQuestionComponent implements OnInit {
  @Input() questionData = {} as IQuestion;
  @Input() actionsBlockedBySituation: boolean = false;

  @Output() onChange = new EventEmitter<AnswerOutput>(null);

  badgeModel = {} as BadgeModel;
  usuarioPodeSelecionarAlternativa: boolean = true;

  gerenciador: PerguntaQuestionarioAuditadoManager;

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.gerenciador = new PerguntaQuestionarioAuditadoManager(
      this.questionData
    );

    this.notificarAtualizacaoRespostas();
    this.atualizarRequisitosDaPergunta();
  }

  private notificarAtualizacaoRespostas() {  
    this.onChange.emit({
      type: this.gerenciador.utils.getTipoPergunta(),
      questionId: this.gerenciador.utils.getIdPergunta(),
      answer: this.buildAnswerOutput(),
      respondida: this.gerenciador.getPossuiAlteracaoDoAuditor() || !this.gerenciador.getPerguntaIsResetada(),
    });

    console.log(999, {
      type: this.gerenciador.utils.getTipoPergunta(),
      questionId: this.gerenciador.utils.getIdPergunta(),
      answer: this.buildAnswerOutput(),
      respondida: this.gerenciador.getPossuiAlteracaoDoAuditor() || !this.gerenciador.getPerguntaIsResetada()
    })
  }

  /**
   * Adapta a estrutura de output do componente
   */
  private buildAnswerOutput() {
    const output = this.gerenciador.getRespostasSelecionadas()
      .map(respostaAuditada => ({
        id_alternativa: respostaAuditada.getIdRespostaOriginal(),
        id_alternativa_auditada: respostaAuditada.getIdAlternativa(),
        texto: respostaAuditada.getTexto(),
        ordem: respostaAuditada.getOrdemSelecao(),
        tipo: respostaAuditada.getTipoAlternativa()
      }));

    return output;
  }

  private atualizarRequisitosDaPergunta() {
    const defaultBadge = {
      questionType: TipoPergunta.UNICA,
      isRequired: this.gerenciador.utils.isObrigatoria(),
      requiredError:
        this.gerenciador.utils.isObrigatoria() &&
        this.gerenciador.getQuantidadeAlternativasSelecionadas() === 0,
    } as BadgeModel;

    if (!this.gerenciador.utils.isRespostaMultipla()) {
      this.badgeModel = defaultBadge;
    } else {
      this.badgeModel = {
        ...defaultBadge,
        questionType: TipoPergunta.MULTIPLA,
        minLength: this.gerenciador.getDefinicoesPergunta().getMinimo(),
        maxLength: this.gerenciador.getDefinicoesPergunta().getMaximo(),
        requiredError:
          this.gerenciador.utils.isObrigatoria() &&
          this.gerenciador.getQuantidadeAlternativasSelecionadas() === 0,
        minRequiredError:
          this.gerenciador.getQuantidadeAlternativasSelecionadas() <
          this.gerenciador.getDefinicoesPergunta().getMinimo(),
        maxRequiredError:
          this.gerenciador.getQuantidadeAlternativasSelecionadas() >
          this.gerenciador.getDefinicoesPergunta().getMaximo(),
      };

      this.usuarioPodeSelecionarAlternativa = !(
        this.gerenciador.getQuantidadeAlternativasSelecionadas() >= this.gerenciador.getDefinicoesPergunta().getMaximo()
      );
    }
  }

  public handleCheckProxy($event: { alternativa: AlternativaAuditada }) {
    const { alternativa } = $event;

    const operacaoBemSucedida = this.gerenciador.atualizarSelecaoAlternativa(alternativa);

    if (operacaoBemSucedida) {
      this.notificarAtualizacaoRespostas();
      this.atualizarRequisitosDaPergunta();
    }
  }

  public handleChangeAlternativaAberta($event: {
    alternativa: AlternativaAuditada;
    value: string;
  }) {
    const { alternativa, value } = $event;

    this.gerenciador.atualizarRespostaAlternativaAberta(alternativa, value);

    this.notificarAtualizacaoRespostas();
  }

  public resetar() {
    this.gerenciador.resetarParaRespostasOriginais();
    this.notificarAtualizacaoRespostas();
    this.atualizarRequisitosDaPergunta();
  }

}
