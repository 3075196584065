import { StatusPesquisa } from './statusPesquisa';

/**
 * Estrutura de dados que define o destino de um status da pesquisa
 */
export class StatusPesquisaDestino {

  /**
   * Status de destino de outro status
   */
  destino: StatusPesquisa;

  /**
   * Marcacao que indica se eh necessaria uma
   * validacao para a alteracao do status
   */
  precisaValidar: boolean;

  constructor(destino: StatusPesquisa, precisaValidar: boolean) {

    this.destino = destino;
    this.precisaValidar = precisaValidar;
  }

}
