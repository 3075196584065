<div class="container-loading-save">
  <div class="content-loading">
    <span class="icon"></span>
    <img src="../../../assets/icons/loader-icon.svg" alt="">
    <p>
      {{ title }}
      <span class="circle">.</span>
      <span class="circle">.</span>
      <span class="circle">.</span>
    </p>
    <span class="line"></span>
  </div>
</div>
