import { colorPalette } from "../colors";
import { multipleGridTooltipFormatter } from "../utils/tooltipFormatter";
import { EChartsOption } from "echarts";

export function toEchartOption(chartData, series): EChartsOption {
  const chartOption: EChartsOption = {
    xAxis: {
      type: "value",
      axisLabel: {
        show: false, // não exibir os valores do eixo y
      },
      splitLine: {
        lineStyle: {
          color: "#D5BADE",
        },
      },
    },
    yAxis: {
      type: "category",
      axisLabel: {
        color: "#610082",
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#D5BADE",
        },
      },
    },
    color: colorPalette,
    series: series,
    dataset: chartData,
    label: {
      show: true, // exibir a label
      position: "inside", // posição da label na barra
      formatter: (v) => `${v.value[`${v.seriesName}_percent`]}%`,
      color: "#FFF",
      fontWeight: 700,
      fontSize: "12px",
    },
    legend: {
      // posição da legenda
      orient: "horizontal",
      type: "scroll",
      top: "top",
      left: "left",
      icon: "circle",
    },
    tooltip: {
      trigger: "item",
      confine: true,
      position: 'top',
      axisPointer: {
        type: "shadow",
      },
      borderColor: "#610082",
      formatter: (params) => multipleGridTooltipFormatter(params),
    },
  };

  return chartOption as EChartsOption;
}
