import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import {
  SurveyListOrderTypes,
  SurveyListSituations,
  SurveyListSortingAttributes,
} from "app/modulos/auditoria-beta/constant";
import {
  IFilterSurveyResults,
  IOverviewData,
  ISurveyListResponse,
} from "app/modulos/auditoria-beta/interfaces";
import { PesquisaCadastro } from "app/modulos/pesquisa-old/cadastro/model/pesquisaCadastro";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { Observable } from "rxjs";
import {
  ICollectionsDateFilter,
  IFilterTotalOperators,
  IInfoCardPayload,
  IOperator,
  IOperatorCollection,
  OperatorSortFilter,
  ProdutividadeSituacaoOperadorColetas,
} from "../interfaces/listagem";
import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";
import { QueryParamsResolver } from "app/util/formatter/queryParams";
import { IGeneralVisionReponse, IOperadorData } from "../interfaces";
import { PODIUM_LENGTH } from "../constants/visao-geral";

@Injectable({
  providedIn: "root",
})
export class ProdutividadeBetaService extends CrudService<PesquisaCadastro> {
  public baseUrl = this.resourcePath;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}`);
  }

  filterSurveyBy(
    currentPage: number = 0,
    size: number = 10,
    sort?: SurveyListSortingAttributes | "",
    order?: SurveyListOrderTypes | "",
    keywords: string[] = [],
    status: SurveyListSituations[] = []
  ): Observable<ISurveyListResponse[]> {
    const pageParam = `page=${currentPage}`;
    const sizeParam = `&size=${size}`;
    const sortParam = sort ? `&sort=${sort}` : "";
    const directionParam = order ? `&direction=${order}` : "";

    const requestUrl = `${this.baseUrl}/pesquisas/auditorias/filtrar/palavra-chave?${pageParam}${sizeParam}${sortParam}${directionParam}`;

    const requestPayload = {
      palavrasChave: keywords,
      situacoes: status,
    };

    return this.requestService.post(requestUrl, requestPayload) as Observable<
      ISurveyListResponse[]
    >;
  }

  /**
   * Obtem o total de pesquisas cadastradas baseado no filtro informado
   * @param filters: filtros utiliizados pelo usuário
   * @returns Inteiro que representa o valor do total de pesquisas cadastradas
   */
  getTotalSurveys(filters: IFilterSurveyResults): Observable<number> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/filtrar/palavra-chave/total-registros`;

    const response = <Observable<number>>(
      this.requestService.post(requestUrl, filters)
    );
    return response;
  }

  /**
   * Retorna os dados do header
   */
  getInfoCardData(): Observable<IInfoCardPayload> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/resumo`;
    const response = <Observable<IInfoCardPayload>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Recupera a lista de operadores para renderizar
   * @param surveyId: id da pesquisa
   * @param currentPage: página atual
   * @param size: resultados por página
   * @param sort: filtro de operadores
   * @param order: ordenação (ASC/DESC)
   * @param keywords: filtro por palavra-chave
   */
  getOperatorsList(
    surveyId: number,
    currentPage: number = 0,
    sort?: OperatorSortFilter | "",
    order?: DirectionOrderTypes | "",
    keywords: string[] = [],
    size: number = 10
  ): Observable<IOperator[]> {
    const queryParams = new QueryParamsResolver({
      page: currentPage,
      size,
      sort,
      direction: order,
    }).toString();

    const requestUrl = `${this.baseUrl}/pesquisas/auditorias/${surveyId}/produtividades/operadores/filtrar/palavra-chave${queryParams}`;
    const requestPayload = { palavrasChave: keywords };

    return this.requestService.post(requestUrl, requestPayload) as Observable<
      IOperator[]
    >;
  }

  /**
   * Obtem o total de operadores baseado no filtro informado
   * @param filters: filtro de palavraChave
   * @returns Inteiro que representa o valor do total de operadores
   */
  getTotalOperatos(
    surveyId: number,
    filters: IFilterTotalOperators
  ): Observable<number> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/${surveyId}/produtividades/operadores/filtrar/palavra-chave/total-registros`;

    const response = <Observable<number>>(
      this.requestService.post(requestUrl, filters)
    );
    return response;
  }

  /**
   * Obtem os dados do operador com base no id informado
   * @param surveyId: id de pesquisa
   * @param operatorId: id do operator
   * @returns dados do operador
   */
  getOperatorData(
    surveyId: number,
    operatorId: number
  ): Observable<IOperadorData> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/${surveyId}/produtividades/operadores/${operatorId}`;

    const response = <Observable<IOperadorData>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Recupera a lista de operadores para renderizar
   * @param surveyId: id da pesquisa
   * @param operatorId: id do operador
   * @param currentPage: página atual
   * @param keywords: filtro por palavra-chave
   * @param situation: situacoes da coleta
   * @param sort: filtro de operadores
   * @param order: ordenação (ASC/DESC)
   * @param size: resultados por página
   */
  getOperatorCollectionsList(
    surveyId: number,
    operatorId: number,
    currentPage: number = 0,
    keywords: string[] = [],
    date: ICollectionsDateFilter,
    situation: ProdutividadeSituacaoOperadorColetas[] = [],
    sort?: OperatorSortFilter | "",
    order?: DirectionOrderTypes | "",
    size: number = 10
  ): Observable<IOperatorCollection[]> {
    const {periodo_inicio, periodo_fim} = date
    const queryParams = new QueryParamsResolver({
      page: currentPage,
      size,
      sort,
      periodo_inicio,
      periodo_fim,
      direction: order,
    }).toString();

    const payload = {
      palavrasChave: keywords,
      situacoes: situation
    }

    const requestUrl = `${this.baseUrl}/pesquisas/auditorias/${surveyId}/produtividades/operadores/${operatorId}/coletas/filtrar/palavra-chave${queryParams}`;

    return this.requestService.post(requestUrl, payload) as Observable<IOperatorCollection[]>;
  }

  /*
   * Recupera as informações da tela de visão geral
   * @param surveyId: id da pesquisa
   */
  getGeneralVisionData(surveyId: number | string): Observable<IGeneralVisionReponse> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/${surveyId}/produtividades?podium=${PODIUM_LENGTH}`;

    const response = <Observable<IGeneralVisionReponse>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Retorna os dados do header
   */
  getHeaderData(surveyId: number): Observable<IOverviewData> {
    const requestUrl: string = `${this.baseUrl}/pesquisas/auditorias/${surveyId}/resumo`;
    const response = <Observable<IOverviewData>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

}
