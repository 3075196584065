import { Component } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { RespostaSelecaoMultiplaComponent } from "../selecao-multipla/selecao-multipla.component";

@Component({
  selector: "app-multipla-ordem-citacao",
  templateUrl: "./multipla-ordem-citacao.component.html",
  styleUrls: ["./multipla-ordem-citacao.component.scss"],
})
export class RespostaMultiplaOrdemCitacaoComponent extends RespostaSelecaoMultiplaComponent {
  ngOnInit() {
    super.ngOnInit();

    this.alternativasSelecionadasFormGroup.valueChanges.subscribe((val) => {});
  }

  /**
   * Retorna o maior valor do atributo "ordem" das alternativas selecionadas.
   */
  recuperarMaiorOrdem(): number {
    const ordemValues: number[] = this.alternativasSelecionadas.value.map(
      (a) => a.ordem
    );
    return ordemValues.length === 0 ? 0 : Math.max(...ordemValues);
  }

  /**
   * Recupera o valor da ordem do próximo atributo selecionado.
   */
  calcularProximaOrdem() {
    return this.recuperarMaiorOrdem() + 1;
  }

  /**
   * recupera o hash de cada alternativa ordenado pelo atributo "ordem".
   */
  getOrderedHashes(): string[] {
    return this.alternativasSelecionadas.value
      .filter((alternativa) => alternativa.ordem)
      .sort((prev, next) => prev.ordem - next.ordem)
      .map((alternativa) => {
        return alternativa.hash;
      });
  }

  /**
   * recupera o FormGroup da alternativa de acordo com o hash da mesma.
   * @param hash
   */
  getAlternativaControlByHash(hash: string): UntypedFormGroup | any {
    const selecionadas = this.alternativasSelecionadas.controls.filter(
      (a) => a.value.hash === hash
    );
    return selecionadas.length === 0 ? null : selecionadas[0];
  }

  /**
   * itera sobre as alternativas selecionadas, setando como valor do atributo "ordem",
   * seu índice+1 de acordo com array de alternativas selecionadas ordenadas por "ordem".
   */
  resetarOrdemDasAlternativas() {
    const orderedHashes = this.getOrderedHashes();

    orderedHashes.forEach((hash: string, index: number) => {
      const alternativaControl = this.getAlternativaControlByHash(hash);
      if (alternativaControl) {
        alternativaControl.controls.ordem.patchValue(index + 1);
      }
    });
  }

  /**
   * @override
   * Evento disparado sempre que uma alternativa for marcada ou desmarcada.
   * @param event
   */
  onAlternativaChange(event: any, alternativaHash: string): void {
    super.onAlternativaChange(event, alternativaHash);

    const selecionado: boolean = event.target.checked;
    const alternativa = this.getAlternativaControlByHash(alternativaHash);

    if (!selecionado) {
      alternativa.controls.ordem.patchValue(null);
      this.resetarOrdemDasAlternativas();
      return;
    }

    alternativa.controls.ordem.patchValue(this.calcularProximaOrdem());
    this.resetarOrdemDasAlternativas();
  }
}
