import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  IAnswaresList,
  IQuestion,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { AnswerOutput } from "../questions.component";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements OnInit, AfterViewInit {
  @Input() questionData: IQuestion;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);
  @ViewChild("gridQuestion") grid: ElementRef<HTMLElement>;

  constructor() {}

  ngAfterViewInit(): void {
    const { clientHeight, scrollHeight, clientWidth, scrollWidth } =
      this.grid.nativeElement;
    this.isOverflown = {
      height: !(scrollWidth > clientWidth),
      width: !(scrollHeight > clientHeight),
    };
  }

  selected: IAnswaresList[] = [];
  limitLineError = false;

  hasChanged: boolean = false;

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  public resetAnswers() {
    this.initAnswers(this.interviewerAnswer as IAnswaresList[]);
    this.setHasChangedState(false);
  }

  private get auditorAnswer() {
    return this.questionData.respostas.auditor;
  }

  private get interviewerAnswer() {
    return this.questionData.respostas.entrevistador;
  }

  private getAnswer(from?: IAnswaresList[]) {
    return from || (this.auditorAnswer || this.interviewerAnswer);
  }

  private emitAnswer() {
    this.onChange.emit({
      type: this.isSingleGrid()
        ? TipoPergunta.GRADE_UNICA
        : TipoPergunta.GRADE_MULTIPLA,
      questionId: this.questionData.id,
      answer: this.selected,
    });
  }

  private initAnswers(from?: IAnswaresList[]) {
    this.validateGridType();
    this.selected = [...this.getAnswer(from)] as IAnswaresList[];

    this.emitAnswer();
  }

  ngOnInit(): void {
    this.initAnswers();

    if (this.auditorAnswer?.length) {
      this.setHasChangedState(true);
    }
  }

  isOverflown = {
    height: true,
    width: true,
  };

  private isSingleGrid() {
    return this.questionData.tipo_pergunta === TipoPergunta.GRADE_UNICA;
  }

  /**
   * Metódo de selecão da grade baseado no regra de quantidade máxima de alterantivas selecionadas
   * @param idRow id da linha selecionada
   * @param idColumn id da coluna
   */
  handleGridSelect(idRow: number, idColumn: number) {
    const alternativaPreviamenteSelecionadaIndex = this.getIndex(
      idColumn,
      idRow
    );

    if (alternativaPreviamenteSelecionadaIndex === -1 || this.isSingleGrid()) {
      this.selected.push({
        id_alternativa: idColumn,
        id_titulo_alternativa: idRow,
      });

      // pega os itens da linha atual
      const linhaAtual = this.getActualSelectedRow(idRow);

      // guarda os valores das outras linhas
      const outrasLinhas = this.selected.filter(
        (item) => item.id_titulo_alternativa !== idRow
      );

      if (
        linhaAtual.length > this.questionData.definicoes.numero_maximo_respostas
      ) {
        // é mesclado os arrays
        // removendo a primeira posição da linha selecionada
        const novasAlternativasSelecionadas = [
          ...linhaAtual.filter((el) => ![linhaAtual[0]].includes(el)),
          ...outrasLinhas,
        ];
        this.selected = [...novasAlternativasSelecionadas];
      }
    } else {
      this.selected = [
        ...this.selected.filter(
          (_, index) => index !== alternativaPreviamenteSelecionadaIndex
        ),
      ];
    }
    this.validateRows();
    this.emitAnswer();
    this.setHasChangedState(true);
  }

  /**
   * devolve o indice para comparação de acordo com o array selecionado
   * @param idColumn id da alternativa
   * @param idRow id do titulo da alternativa
   * @param arrayKey rótulo do array a ser iterado
   * @returns retorna o indice
   */
  getIndex(idColumn: number, idRow: number, arrayKey = "selected"): number {
    const arrayList = {
      ["selected"]: this.selected,
      ["anwares-selected"]: this.interviewerAnswer,
    };
    return arrayList[arrayKey].findIndex(
      ({
        id_titulo_alternativa: idRowSelected,
        id_alternativa: idColumnSelected,
      }) => idRow === idRowSelected && idColumn === idColumnSelected
    );
  }

  /**
   * @param idRow linha atual
   * @returns array com as alternativas selecionadas daquela linha
   */
  getActualSelectedRow(idRow: number) {
    return this.selected.filter(
      ({ id_titulo_alternativa: rowId }) => rowId === idRow
    );
  }

  /**
   * Valida se a quantidade de items por linha é menor que o limite mínimo
   */
  validateRows() {
    this.limitLineError = this.questionData.definicoes.titulo_alternativas
      .map(({ id: idRow }) => this.getActualSelectedRow(idRow).length)
      .some((i) => i < this.questionData.definicoes.numero_minimo_respostas);
  }

  /**
   * Garante que se caso o tipo da pergunta seja grade única o máximo de alterantivas seja 1
   */
  validateGridType() {
    this.questionData.definicoes.numero_maximo_respostas;
    if (this.isSingleGrid()) {
      this.questionData.definicoes = {
        ...this.questionData.definicoes,
        numero_maximo_respostas: 1,
        numero_minimo_respostas: 1,
      };
    }
  }
}
