<div class="pesquisas-configuracoes-container">
  <div class="general-info-content">
    <header>
      <div>
        <div class="default-icon informacoes-gerais-icon" alt="Informações Gerais"></div>
        <h4>Informações gerais</h4>
      </div>
    </header>
    <main>
      <div class="options-field" [ngClass]="getLengthOfEmail() && 'expanded-client-info'">
        <div class="options-field-section-selector">
          <div class="content-width">
            <app-filterable-select label="Cliente*" placeholder="Selecione um cliente" [data]="customerItems"
              [selectedItem]="selectedCustomerItem" (changeSelectedItem)="handleCustomerChange($event)"
              [usingBy]="'CLIENTE'">
            </app-filterable-select>
            <div class="verticalbar" *ngIf="!!selectedCustomer"></div>
            <div class="client-info" *ngIf="!!selectedCustomer">
              <p>{{ selectedCustomer.nome }}</p>
              <p>{{ selectedCustomer.cpfCnpj }}</p>
              <p class="email-content" [title]="selectedCustomer.email">
                {{ selectedCustomer.email }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="devider"></div>
      <div class="input-fields">
        <app-s-input mascara-primeiro-digito-sem-espaco string-no-break-line [id]="'title'" kind="input-light"
          label="Título da pesquisa*" placeholder="Informe o título da pesquisa"
          (changedText)="handleChange($event, 'title')" [inputSelected]="title" [maxlength]="251" [required]="true">
        </app-s-input>
        <app-s-input [id]="'orientation'" kind="input-light" label="Orientação de pesquisa"
          placeholder="Informe uma orientação para responder o questionário"
          (changedText)="handleChange($event, 'orientation')" [inputSelected]="orientation" [maxlength]="501">
        </app-s-input>
        <app-s-input [id]="'objective'" kind="input-light" label="Objetivo da pesquisa*"
          placeholder="Descreva o objetivo da sua pesquisa" (changedText)="handleChange($event, 'objective')"
          [inputSelected]="objective" [maxlength]="151" [required]="true"></app-s-input>
        <app-radio-select [usedBy]="'CHEVRON'" [label]="'Tipo da pesquisa*'"
          [placeholder]="'Selecione o tipo de pesquisa'" [options]="typesOfSurveys" [selected]="selectedTypeOfSurvey"
          (onSelectTypeOfSurvey)="handleTypeOfSurveySelect($event)"></app-radio-select>
      </div>
    </main>
  </div>
  <div class="samples-content">
    <div class="samples--reduce">
      <header>
        <div>
          <div class="default-icon amostras-icon" alt="Amostras"></div>
          <h4>Amostras</h4>
        </div>
      </header>
      <main>
        <div class="info-in">
          <app-s-input kind="input-light" label="Amostra total*" placeholder="Informe o número total de coletas"
            (changedText)="handleTotalSamplesChange($event)" [inputSelected]="totalSamples" type="number" [min]="0"
            [required]="true">
          </app-s-input>
        </div>
        <div class="vertical-bar bigger"></div>
        <div class="info">
          <div class="default-icon total-icon" alt="Amostras"></div>
          <p>
            Amostra total é a quantidade total de respostas que devem ser
            captadas. Sendo distribuídas nos campos amostra presencial e online
            dependendo do objetivo da pesquisa
          </p>
        </div>
      </main>
    </div>
    <div class="samples--face-to-face">
      <app-s-input type="number" [min]="0" kind="{{ withTotalSamples ? 'input-light' : 'input-dark' }}"
        label="Amostras presenciais*" placeholder="Informe o número de coletas"
        [disabled]="withTotalSamples ? 'false' : 'true'" [inputSelected]="presencialSamples"
        (changedText)="handlePresencialSamplesChange($event)">
      </app-s-input>

      <app-s-input kind="{{ withPresencialSamples ? 'input-light' : 'input-dark' }}"
        [disabled]="withPresencialSamples ? 'false' : 'true'" label="Distância entre entrevistas (Metros)*"
        placeholder="Informe a distância em metros" (changedText)="handleDistanceChange($event)"
        [inputSelected]="distance" type="number" [min]="0" [required]="!withPresencialSamples ? true : false">
      </app-s-input>

      <app-radio-select [usedBy]="'TOOLTIP'" kind="{{ withPresencialSamples ? 'input-light' : 'input-dark' }}"
        [label]="'Tempo mínimo da entrevista (Minutos)*'" [withCheck]="false" [placeholder]="'Selecione uma opção'"
        [options]="minTimesItems" [selected]="selectedMinTimesItem" [disabled]="withPresencialSamples ? false : true"
        (onSelectTypeOfSurvey)="handleMinTimeChange($event)"></app-radio-select>

      <div [class]="
          withPresencialSamples ? 'allow-recording' : 'allow-recording disabled'
        ">
        <app-checkbox [isChecked]="allowAudio" (click)="handleChange($event, 'allowAudio')"
          [isDisabled]="withPresencialSamples ? false : true">
        </app-checkbox>
        <p>Permitir captação de áudio</p>
        <div class="tooltip" [ngClass]="withPresencialSamples && 'canSeeTooltip'">
          <i class="fa-regular fa-circle-info gray-icon"></i>
          <span class="tooltiptext" [ngClass]="withPresencialSamples && 'canSee'">
            A captação de áudio serve para a realização de auditoria das
            pesquisas realizadas em campo
          </span>
        </div>
      </div>
    </div>

    <div class="samples--online">
      <main>
        <div class="info-in">
          <app-s-input [min]="0" type="number" label="Amostras online*"
            kind="{{ withTotalSamples ? 'input-light' : 'input-dark' }}" placeholder="Informe o número de coletas"
            [disabled]="withTotalSamples ? 'false' : 'true'" [inputSelected]="onlineSamples"
            (changedText)="handleOnlineSamplesChange($event)">
          </app-s-input>
        </div>

        <div class="vertical-bar half"></div>

        <div class="info">
          <div class="default-icon online-icon" alt="Amostra online é a quantidade de pesquisas que devem ser realizadas
            através de um formulário disponibilizado na web"></div>
          <p>
            Amostra online é a quantidade de pesquisas que devem ser realizadas
            através de um formulário disponibilizado na web
          </p>
        </div>
      </main>
    </div>
  </div>

  <div class="period-content">
    <header>
      <div>
        <div class="default-icon periodo-icon" alt="Período"></div>
        <h4>Período</h4>
      </div>
    </header>
    <main>
      <section class="periodo">
        <section class="periodoData calendar">
          <app-calendar [model]="schedules" (changeDateInputValidate)="changeDateInputValidate($event)"
            [usedBy]="'PESQUISA'" (onRangeChange)="handleCalendarChanges($event)">
          </app-calendar>
        </section>
        <section class="periodoDicas">
          <div class="periodoDicasInfo">
            <div class="title">
              <h2>Dicas</h2>
            </div>
            <div class="list">
              <div class="item">
                <div class="icon"><span></span></div>
                <div class="text">
                  <p>
                    Selecione a data inicial e final que a sua pesquisa deve ser executada
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="icon"><span></span></div>
                <div class="text">
                  <p>
                    Caso seja necessário desabilitar um dia especifico no conjunto que foi selecionado, basta clicar no
                    dia que as respostas não podem ser computadas
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="icon"><span></span></div>
                <div class="text">
                  <p>
                    Confira se a quantidade de dias(s) de ciclo está correta no canto inferior direito do calendário
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="periodoDicasImage">
            <img src="../../../../../assets/images/tips-people.png" alt="People" />
          </div>
        </section>
      </section>
    </main>
  </div>
</div>
