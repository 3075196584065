<div class="header-border">
  <div class="sublocalidade-box">
    <div class="location-container">
      <!-- <div class="bar-float"></div> -->
      <div *ngFor="let sublocation of data; let subIndex = index" class="flex column justify-between">
        <span class="flex justify-between width-size" *ngIf="sublocation.value !== 0 && sublocation.value !== null">
          <div class="place-container-sub flex">
            <p class="place">{{ sublocation.nome }}</p>
          </div>
          <div class="sample-insert-sub flex center">
            <label [for]="sublocation.id" class="input-wrapper setDisabled">
              <input [id]="sublocation.id" placeholder="Inserir amostra" [style.width.ch]="
                  getInputValueLength(inputOptions[subIndex].displayValue)
                " [style.text-align]="'right'" type="number" [value]="inputOptions[subIndex].displayValue" disabled />
              <span [hidden]="inputOptions[subIndex].displayValue === 15">%</span>
            </label>
          </div>
          <div class="bar-sub">
            <p *ngIf="sublocation.operators.length === 0">00</p>
            <h4 *ngIf="sublocation.operators.length >= 10">
              {{ sublocation.operators.length }}
            </h4>
            <h4 *ngIf="
                sublocation.operators.length !== 0 &&
                sublocation.operators.length < 10
              ">
              0{{ sublocation.operators.length }}
            </h4>
          </div>
          <div class="switch-container flex center">
            <img [src]="
                sublocation.operators.length > 0
                  ? 'assets/images/vinculo-editar.svg'
                  : 'assets/images/vinculo.svg'
              " alt="" class="pointer" (click)="handleClick(sublocation, subIndex)" />
          </div>
        </span>
      </div>
    </div>
  </div>
</div>