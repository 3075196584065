<div class="container-page">

  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-dashboard"></i>
      <div routerLinkActive="active" [routerLink]="'/auditoria/pesquisas'">
        <h2>Auditoria</h2>
      </div>
      <span>/</span>
      <h4>Entrevista {{ idEntrevista }}</h4>
    </div>
    <div class="action-header">
      <app-audio-player *ngIf="entrevistaPerguntas?.audio" [audioParaReproduzir]="entrevistaPerguntas.audio"
        [desabilitarAdiantamento]="true" (tentativaAdiantamento)="catchTentativaAdiantamento($event)">
      </app-audio-player>
    </div>
  </div>
  <div class="motivo-rejeicao" *ngIf="entrevistaPerguntas?.rejeitada">Pesquisa reprovada:
    {{entrevistaPerguntas.causaRejeicao}}</div>
  <app-resposta-pergunta-listagem-tabela #tabela [entrevistaService]="entrevistaService" [exibirOpcaoDownload]="true"
    (eventEmitting)="manipularEventoTabela($event)" [idEntrevista]="idEntrevista"
    (entrevistaPerguntasChange)="onEntrevistaPerguntasChange($event)">
  </app-resposta-pergunta-listagem-tabela>

  <!-- <button (click)="salvarAlteracoes()">Salvar Alterações</button> -->
</div>

<div class="actions-list sg-input-group">
  <div [formGroup]="entrevistaFormGroup" class="campo-inline">
    <mat-slide-toggle formControlName="auditada" (change)="onToggleAuditada($event)"></mat-slide-toggle>
    <label>Entrevista auditada</label>
  </div>

  <div class="btn-actions">
    <button class="sg-btn sg-alternate mr-2" (click)="goBack()">Voltar</button>
    <button (click)="salvarAlteracoes()" class="sg-btn sg-secondary">Salvar Alterações</button>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
