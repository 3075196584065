import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { NotificatorComponent } from "./notificator.component";
import { NotificatorService } from "./notificator.service";

@NgModule({
    declarations: [
        NotificatorComponent,
    ],
    imports: [
        CommonModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            maxOpened: 2,
            autoDismiss: true,
            toastComponent: NotificatorComponent,
        }),
    ],
    providers: [
        NotificatorService,
    ]
})
export class NotificatorModule { }
