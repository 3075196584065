import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validador de campos por comparação genérico, o baseControl define quem sera o alvo da
 * comparação, o toCompareControl eh aquele que sera submetido a comparacao,
 * a função compareTo define como será a comparação do baseControl com o toCompareControl
 * retornando null se estiver válido e um objeto de erro caso esteja inválido.
 */
// tslint:disable-next-line:max-line-length
export default (compareTo : (baseControlValue : any, toCompareControlValue : any) => {}) => {

  return (baseControl: AbstractControl) => {

    return (toCompareControl: AbstractControl): ValidationErrors => {

      /**
        * se o toCompareControl for modificado pelo usuario, ele dispara a validacao
        * do baseControl, garantindo assim  validacao em duas vias
       */
      if (toCompareControl.dirty) {
        if (baseControl.pristine) {
          baseControl.updateValueAndValidity();
          baseControl.markAsPristine();
        }
        toCompareControl.markAsPristine();
      }

      return compareTo(baseControl.value, toCompareControl.value);
    };
  }
};
