import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export default (formGroup: UntypedFormGroup): ValidationErrors => {

  const controlNames: string[] = Object.keys(formGroup.controls);

  if (controlNames.length === 0) {
    return {
      noControls: true,
    };
  }
};
