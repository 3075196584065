import { Injectable } from "@angular/core";
import { ICitation, IGroup, IRemoveAnswerOrChild, ISelectedAvulsesAndGroup } from "../../interfaces/audit-open-answer";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

export interface IEvents {
  onUpdateAvulseTitle?: (data) => void;
  onUpdateGroupTitle?: (data) => void;
  onUpdateSelectedCitationAndGroup?: (data) => void;
  onAutoCheckStepOneGroups?: (data) => void;
  onUpdateConsolidationElementRemoved?: (data) => void;
}

@Injectable({
  providedIn: "root",
})
export class MatchOperationsService {
  constructor() {}

  private subjects: Subscription[] = [];
  private avulseTitleSubject = new BehaviorSubject<{ id: number; newTitle: string } | null>(null);
  private groupTitleSubject = new BehaviorSubject<{ id: number | string; newTitle: string } | null>(null);
  private selectedCitationAndGroupSubject = new BehaviorSubject<ISelectedAvulsesAndGroup>(null); // respostas selecionadas
  private stepTwoSelectedGroupSubject = new BehaviorSubject<IGroup>(null); // grupo selecionado no passo 2
  private consolidationElementRemovedSubject = new BehaviorSubject<IRemoveAnswerOrChild>(null);

  public avulseTitle$: Observable<{ id: number; newTitle: string } | null> = this.avulseTitleSubject.asObservable();
  public groupTitle$: Observable<{ id: number | string; newTitle: string } | null> = this.groupTitleSubject.asObservable();
  public selectedCitationAndGroupSubject$: Observable<ISelectedAvulsesAndGroup> = this.selectedCitationAndGroupSubject.asObservable();
  public stepTwoSelectedGroupSubject$: Observable<IGroup> = this.stepTwoSelectedGroupSubject.asObservable();
  public consolidationElementRemoved$: Observable<IRemoveAnswerOrChild> = this.consolidationElementRemovedSubject.asObservable();

  initSubscriptions(param: IEvents) {
    const subscriptionStrategies: {[key: string]: Observable<any>} = {
      onUpdateAvulseTitle: this.avulseTitle$,
      onUpdateGroupTitle: this.groupTitle$,
      onUpdateSelectedCitationAndGroup: this.selectedCitationAndGroupSubject$,
      onAutoCheckStepOneGroups: this.stepTwoSelectedGroupSubject$,
      onUpdateConsolidationElementRemoved: this.consolidationElementRemoved$
    };

    for (let eventName in param) {
      if(!!param[eventName] && !!subscriptionStrategies[eventName]) {
        const subscription = subscriptionStrategies[eventName].subscribe((data) => {
          param[eventName](data)
        });
        this.subjects.push(subscription);
      }
    }
  }

  /**
   * Realiza a desinscrição dos observables
   */
  public unsubscribe() {
    this.subjects.forEach(subject => subject.unsubscribe());
  }

  /**
   * Atualiza o titulo de uma resposta avulsa
   */
  public updateAvulseTitle(id: number, newTitle: string): void {
    this.avulseTitleSubject.next({ id, newTitle });
  }

  /**
   * Atualiza o titulo de uma consolidação
   */
  public updateGroupTitle(id: number | string, newTitle: string): void {
    this.groupTitleSubject.next({ id, newTitle });
  }

  /**
   * Atualiza as resposta selecionadas
   */
  public updateSelectedCitationAndGroup(citationsAndGroups: ISelectedAvulsesAndGroup): void {
    this.selectedCitationAndGroupSubject.next(citationsAndGroups);
  }

  /**
   * Atualiza o grupo selecionado no passo 2
   */
  public updateStepTwoSelectedGroup(group: IGroup): void {
    this.stepTwoSelectedGroupSubject.next(group);
  }

  /**
   * Atualiza o objeto com a citação ou consolidação que será removida de um grupo
   */
  public updateConsolidationElementRemoved(element: IRemoveAnswerOrChild): void {
    this.consolidationElementRemovedSubject.next(element);
  }

  // Remove as respostas abertas que pertencem a um grupo já criado
  public removeAvulseMatchedWithinGroups(
    avulsesArray: ICitation[],
    groupArray: IGroup[]
  ) {
    return [
      ...avulsesArray.filter(
        (objeto) => !groupArray.some((grupo) => grupo.citation.includes(objeto))
      ),
    ];
  }

  // Remove os grupos que foram algutinados em um novo grupo
  public removeGroups(groupArray: IGroup[], selectedAvulsesAndGroups: ISelectedAvulsesAndGroup) {
    const compSet = new Set(
      selectedAvulsesAndGroups.groups.map(({ id }) => id)
    );

    const compareGroup = [...Array.from(compSet)];
    let groups = new Array<IGroup>(...groupArray);
    return groupArray = [
      ...groups.filter(({ id }) => !compareGroup.includes(id)),
    ];
  }
}
