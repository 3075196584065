import { Injectable } from '@angular/core';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { Router, CanActivate } from '@angular/router';

/**
 * Servico responsavel por verificar se o acesso à uma
 * rota de edição pode ocorrer
 */
@Injectable({
  providedIn: 'root',
})
export class UpdateGuard implements CanActivate {

  constructor(
    private securityService: SecurityService,
    private router: Router,
  ) { }

  canActivate(): boolean {

    const idToUpdate = Number(this.router.getCurrentNavigation().finalUrl.queryParamMap.get('id'));

    /**
     * Se o usuário tentar acessar uma rota de
     * edição sem fornecer o id ele é redirecionado
     * para a home
     */
    if (!idToUpdate || idToUpdate < 0) {
      this.router.navigate(['']);
      return false;
    }

    return true;
  }

}
