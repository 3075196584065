<div class="list-read-container">
  <header>
    <div class="path-location">
      <i class="icon-localidades"></i>
      <h2 [routerLink]="'/localidade'" routerLinkActive="active">
        Localidades
      </h2>
      <span>/</span>
      <h4>localidades cadastradas</h4>
    </div>
    <button
      class="add-location"
      type="button"
      (click)="handleChangeOpenModal()"
    >
      ADICIONAR NOVO
    </button>
  </header>
  <main>
    <app-localidade-table-header
      [hasSomeLocalidadeChecked]="handleGetIfHasSomeLocalidadeChecked()"
    ></app-localidade-table-header>

    <app-localidade-table
      [tableData]="handleGetLocalidades()"
      [mainCheck]="handleGetMainCheck()"
      (showEditLocalidade)="handleOpenEditModal($event)"
      (showEditLocalidadeFilha)="handleOpenEditFilhaModal($event)"
    ></app-localidade-table>
  </main>
  <footer>
    <app-localidade-table-pagination
      [totalOfLocalidades]="handleGetTotalOfLocalidades()"
      [startOf]="handleGetStartOf()"
      [oneOf]="handleGetOneOf()"
    ></app-localidade-table-pagination>
  </footer>
  <app-modal-cread-update
    *ngIf="modalVisibility"
    [editMode]="isEdicao"
    [fatherLocationId]="localidadePaiSelectedById"
    [childrenLocationId]="localidadeFilhaSelectedById"
    [childrenListLocationId]="localidadeFilhaListSelectedById"
    (handleShowModal)="handleChangeCloseModal()"
  >
  </app-modal-cread-update>
</div>
