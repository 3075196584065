<div [formGroup]="formGroup" (click)="notificarSelecao($event)" [ngClass]="selecionado ? 'selecionado' : ''">

    <app-pergunta-card 
    [icone]="'fa fa-object-group'" 
    [titulo]="ordem + '- MÚLTIPLA ORDEM'" 
    [nomePlaceHolder]="'Título da pergunta de múltipla ordem?'" 
    [formGroup]="formGroup">
    
    </app-pergunta-card>

</div>