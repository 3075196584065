<section class="modal-container" *ngIf="showModal" [formGroup]="formGroup">
  <div class="modal-content">
    <div class="modal-header">
      <div class="left-header">
        <i class="fa-regular fa-envelope"></i>
        <h3>Redefinição de e-mail</h3>
      </div>

      <button type="button" class="button-close" (click)="handleCloseModal()">
        <i class="fa-light fa-xmark"></i>
      </button>
    </div>
    <div class="modal-info">

      <div class="modal-input-email" formGroupName="usuario">
        <label for="email">E-mail atual</label>
        <input
          id="email"
          formControlName="login"
          type="email"
          readonly
        />
      </div>

      <div class="modal-input-email" formGroupName="usuario">
        <label for="newEmail">Novo e-mail*</label>
        <input
          id="newEmail"
          type="email"
          formControlName="newEmail"
          [ngClass]="formGroup.get('usuario.newEmail')?.invalid && (formGroup.get('usuario.newEmail')?.dirty || formGroup.get('usuario.newEmail')?.touched) ? 'error-input' : ''"
        />
        <span class="error-message" [hidden]="!(formGroup.get('usuario.newEmail')?.invalid && (formGroup.get('usuario.newEmail')?.dirty || formGroup.get('usuario.newEmail')?.touched))">
          Email inválido
        </span>
      </div>

      <div class="modal-input-email" formGroupName="usuario">
        <label for="senha_atual">Senha atual*</label>
        <app-input-password [parentFormGroup]="formGroup" [controlName]="'senha_atual'"
        [hasError]="(controls.usuario.get('senha_atual')?.touched && controls.usuario.get('senha_atual').errors)">></app-input-password>
        <span class="error-message" [hidden]="!(controls.usuario.get('senha_atual')?.touched && controls.usuario.get('senha_atual').errors
        && (controls.usuario.get('senha_atual').errors.required || controls.usuario.get('senha_atual').invalid))">
          Senha incorreta
        </span>
      </div>

    </div>

    <div class="modal-buttons">
      <button class="cancel-btn" type="button" (click)="handleCloseModal()">Cancelar</button>
      <button type="submit" (click)="handleSubmit()">Salvar</button>
    </div>
  </div>
</section>
