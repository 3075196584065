<div class="s-input-container">
  <!-- input -->
  <div
    [class]="kind === 'input-light' ? 'input-fields__input' : 'input-dark'"
    *ngIf="kind !== 'select' && !kind.includes('date')"
  >
    <div style="display: flex; justify-content: space-between; width: 100%">
      <label for="">{{ label }}</label>
      <span *ngIf="maxLengthError" class="error-message">Máximo {{ this.maxlength - 1 }} caracteres</span>
      <span *ngIf="requiredError" class="error-message">Campo obrigatório</span>
    </div>
    <input
      *ngIf="type !== 'text'"
      [disabled]="disabled"
      [required]="required"
      [type]="type ? type : 'text'"
      [placeholder]="placeholder"
      [class]="kind === 'input-dark' ? 'dark' : 'purple'"
      [placeholder]="placeholder"
      [ngModel]="inputSelected"
      (ngModelChange)="handleChange($event)"
      [min]="min ? min : null"
      [max]="max ? max : null"
      [maxlength]="maxlength ? maxlength : null"
      [ngClass]="(maxLengthError || requiredError) ? 'input-error' : ''"
    />

    <textarea
      [id]="id"
      *ngIf="type === 'text'"
      [disabled]="disabled"
      [ngStyle]="{'height.px': inputHeight}"
      [placeholder]="placeholder"
      [class]="kind === 'input-dark' ? 'dark' : 'purple'"
      [placeholder]="placeholder"
      [maxlength]="maxlength ? maxlength : null"
      [ngModel]="inputSelected"
      (ngModelChange)="handleChange($event)"
      [ngClass]="(maxLengthError || requiredError) ? 'input-error' : ''"
    ></textarea>
  </div>
  <!-- date to calendário -->
  <div
    [class]="kind === 'date-light' ? 'date-input-fields__input' : 'date-input-dark'"
    *ngIf="kind !== 'select' && kind.includes('date')"
  >
    <label for="">{{ label }}</label>
    <input
      [disabled]="disabled"
      [type]="type ? type : 'text'"
      [placeholder]="placeholder"
      [class]="kind === 'input-dark' ? 'raw-input dark' : 'raw-input purple'"
      [placeholder]="placeholder"
      [ngModel]="inputSelected"
      (ngModelChange)="handleChange($event)"
      [min]="min ? min : null"
      [max]="max ? max : null"
    />
  </div>
  <!-- select -->
  <div class="input-fields__input" *ngIf="kind === 'select'">
    <label for="">{{ label }}</label>
    <select [(ngModel)]="selected" (ngModelChange)="handleChange($event)">
      <option *ngFor="let item of data" [value]="item.value">
        {{ item.label }}
      </option>
    </select>
  </div>
</div>
