import { Directive } from '@angular/core';
import { MascaraBase } from './mascaraBase';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-cnpj]',
})
export class MascaraCnpjDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 18);
  }

  aplicarMascara(valor: string): string {

    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

    // Adicionando o ponto entre o segundo e terceiro digito
    const valorPrimeiraFormatacao = numberString.replace(/(\d{2})(\d)/, '$1.$2');

    // Adicionando o ponto entre o quinto e sexto digito
    const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(/(\d{3})(\d)/, '$1.$2');

    // Adicionando a barra entre o oitavo e nono digito
    const valorTerceiraFormatacao = valorSegundaFormatacao.replace(/(\d{3})(\d)/, '$1/$2');

    // Adicionando o hifen entre o decimo segundo e decimo terceiro digito
    const valorQuartaFormatacao = valorTerceiraFormatacao.replace(/(\d{4})(\d)/, '$1-$2');

    return valorQuartaFormatacao;
  }

}
