import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';

export class AnalisePesquisa {

  possuiTracking: boolean = false;
  qtdDiariaEntrevistas: number = undefined;
  qtdEntrevistasDescarte: number = undefined;
  qtdMinimaConsolidacao: number = undefined;

  static getControl(): UntypedFormGroup {

    return new UntypedFormGroup({
      possuiTracking: new UntypedFormControl(false),
      qtdDiariaEntrevistas: new UntypedFormControl(),
      qtdMinimaConsolidacao: new UntypedFormControl(),
      qtdEntrevistasDescarte: new UntypedFormControl(),
    });
  }

  static getInitializedControl(analisePesquisa: AnalisePesquisa): UntypedFormGroup {

    if (analisePesquisa.possuiTracking) {
      return new UntypedFormGroup({
        possuiTracking: new UntypedFormControl(analisePesquisa.possuiTracking),
        // tslint:disable-next-line: max-line-length
        qtdDiariaEntrevistas: new UntypedFormControl(analisePesquisa.qtdDiariaEntrevistas, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_QTD_DIARIA_ENTREVISTAS)),
        // tslint:disable-next-line: max-line-length
        qtdMinimaConsolidacao: new UntypedFormControl(analisePesquisa.qtdMinimaConsolidacao, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_QTD_ENTREVISTAS_DESCARTE)),
        // tslint:disable-next-line: max-line-length
        qtdEntrevistasDescarte: new UntypedFormControl(analisePesquisa.qtdEntrevistasDescarte, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_QTD_MINIMA_CONSOLIDACAO)),
      });
    }

    return AnalisePesquisa.getControl();
  }

}
