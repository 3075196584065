import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { colorPalette } from '../colors';
import { toEchartOption } from './toEchartOption';
import { sortChartData } from '../utils/sortChartData';
import { IResultAlternative } from '../../results-interface';
import { DEFAULT_BAR_WIDTH, GAP_BETWEEN_AXIS, GAP_BETWEEN_BARS, GAP_BETWEEN_LEGENDS } from './constants';

@Component({
  selector: 'app-single-grid',
  templateUrl: './single-grid.component.html',
  styleUrls: ["./single-grid.component.scss"],
})
export class SingleGridComponent implements OnInit, OnChanges {

  @Input() alternatives;
  @Input() showModal: boolean;
  @Input() layout: string;
  chartOption: EChartsOption = {};
  echartsInstance: ECharts;
  seriesData: IResultAlternative[];
  columns: Object[];
  lines: Object[];

  enableScrolling: boolean = true;
  estimatedWidth: number = 850;

  constructor() { }

  ngOnInit(): void {
    if(this.alternatives) {
      this.lines = [...new Set(this.alternatives.map((value) => value.linha))];
      this.columns = [...new Set(this.alternatives.map((value) => value.coluna))];

      // Tratamento dos dados antes da plotagem no gráfico
      this.seriesData = sortChartData(this.alternatives, this.lines, this.columns);

      this.getChartsValue();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if((changes.showModal && changes.showModal.currentValue !== changes.showModal.previousValue)
      || (changes.layout && changes.layout.currentValue !== changes.layout.previousValue)) {
      this.calculateEstimatedChartWidth();
    }
  }

  getChartContainer(): HTMLElement {
    if (this.echartsInstance) {
      return this.echartsInstance.getDom();
    }
  }

  /**
   * Força que o container pai do gráfico sempre ocupe o tamanho real do card, isso pode ser melhorado, mas foi utilizado aqui
   * para evitar bug de dimensionamento da grid ao mudar o layout para "row"
   */
  resizeChartContainer() {
    const container = this.getChartContainer();

    if (container.children[0] instanceof HTMLElement) {
      container.children[0].style.width = '100%';
    }
  }

  /**
   * Calcula o comprimento estimado que o gráfico terá com base na quantidade de alternativas ou no comprimento
   * das legendas e aplica um efeito de scroll horizontal caso esse valor ultrapasse o comprimento do card.
   */
  calculateEstimatedChartWidth() {
    if (Array.isArray(this.alternatives) && this.echartsInstance) {
      const container = this.getChartContainer();
      const cardWidth = container.parentElement.clientWidth;

      let estimatedWidth = cardWidth;

      let estimatedWidthForLegends = 0;
      let estimatedWidthForLines = 0;
      let estimatedWidthForBars = 0;

      /**
       * O cálculo de comprimento estimado para as barras ocuparem totalmente o gráfico se dá pelo seguinte raciocínio:
       * 
       * bW - comprimento default de cada barra
       * cL - Quantidade de legendas
       * lL - Quantidade de linhas
       * gBB - Gap estimado entre cada barra
       * 
       * então, em um gráfico com um dataset, temos que cada linha representa um grupo de respostas para cada alternativa (coluna)
       * seguindo esse raciocínio, para calcular o comprimento médio que as barras ocupam para um único grupo, temos:
       * 
       * bW * cL * 1
       * 
       * para o cálculo considerar todas as linhas, ao invés de multiplicar por 1, multiplicamos por (lL). 
       * 
       * Além disso, é necessário somar esse resultado com os gaps estimados entre cada barra do gráfico, a quantidade de gaps sempre
       * será a quantidade de legendas (cL) - 1, para observar isso, imagine que tenho quatro barras em um grupo (I_I_I_I) e o caractere
       * "_" representa o gap.
       * 
       * Então o cálculo final ficará (bW * cL * lL) + (((cL - 1) * gBB) * lL)
       */

      const estimatedWidthForBarGaps = (((this.columns.length - 1) * GAP_BETWEEN_BARS) * this.lines.length);

      estimatedWidthForBars = (
          DEFAULT_BAR_WIDTH 
            * this.columns.length 
            * this.lines.length
      ) + estimatedWidthForBarGaps;

      /**
       * Se o valor estimado ocupado pelas barras (considerando gaps) for menor que o comprimento do card, então não fazemos nada
       * e não habiltamos o scroll.
       */
      if (estimatedWidthForBars < cardWidth) {
        this.enableScrolling = false;
        return;
      }

      /**
       * Calcula o comprimento estimado considerando as legendas
       */

      const tempCanvas = document.createElement("canvas");
      const ctx = tempCanvas.getContext("2d");

      ctx.font = "14px Arial";

      this.columns.forEach((colName: string) => {
        const { width } = ctx.measureText(colName)
        estimatedWidthForLegends +=  width + GAP_BETWEEN_LEGENDS;
      });

      /**
       * calcula o comprimento estimado considerando as perguntas (linhas)
       */
      let biggestLineWidth: number = 0;

      this.lines.forEach((lineName: string, index) => {
        const { width } = ctx.measureText(lineName);

        if (width > biggestLineWidth) {
          biggestLineWidth = width;
        }
      });
      
      estimatedWidthForLines = (biggestLineWidth * this.lines.length) + (2 * this.lines.length * GAP_BETWEEN_AXIS);

      const barWidthBiggerThanLegends = estimatedWidthForBars > estimatedWidthForLegends;
      const linesWidthBiggerThanLegends = estimatedWidthForLegends < estimatedWidthForLines;
      
      /**
       * Comprimento estimado para barras e linhas > comprimento estimado para legendas
       */
      if (barWidthBiggerThanLegends && linesWidthBiggerThanLegends) {
        // Comprimento estimado para barras é maior que comprimento estimado para linhas, utilizamos comprimento estimado para barras
        // uma vez que o comprimento estimado para barras é sempre o valor mínimo de comprimento que o gráfico deve suportar.
        if (estimatedWidthForBars > estimatedWidthForLines) {
          estimatedWidth = estimatedWidthForBars;
        } else {
          // Se for menor, significa que o comprimento estimado para as linhas ultrapassa o que o gráfico pode renderizar somente com o
          // comprimento estimado para as barras, então temos que setá-lo como valor estimado final.
          estimatedWidth = estimatedWidthForLines;
        }
      } else if (barWidthBiggerThanLegends && !linesWidthBiggerThanLegends) {
        estimatedWidth = estimatedWidthForBars;
      } else if (!barWidthBiggerThanLegends && linesWidthBiggerThanLegends) {
        estimatedWidth = estimatedWidthForLines;
      } else {
        estimatedWidth = estimatedWidthForLegends;
      }

      this.enableScrolling = true;
      this.estimatedWidth = estimatedWidth;

      this.echartsInstance.resize({ width: this.estimatedWidth });
      
      this.getChartsValue();
    }
  }

  // Pega a instancia do echarts para manipulação do resize
  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;

    this.echartsInstance.on("rendered", () => {
      this.resizeChartContainer();
    });

    this.calculateEstimatedChartWidth();
  }

  /**
    @returns dataset :Objeto de configuração do gráfico, contendo as
    colunas e seus respectivos valores percentuais e númericos
  */
  buildDataset(): Object {
    const dataset = {
      dimensions: ['items', ...this.columns],
      source: (() => {
        const sourceItems: Array<{ [key: string]: string | number }> = [];

        this.seriesData.forEach((serieItem) => {
          let sourceItem = sourceItems.find((sourceObj) => sourceObj.items === serieItem.linha);
          if (!sourceItem) {
            sourceItem = {
              items: serieItem.linha,
            };
            sourceItems.push(sourceItem);
          }
          sourceItem[serieItem.coluna] = serieItem.resultado_percentual;
          sourceItem[`${serieItem.coluna}_numeric`] = serieItem.resultado_numerico;
        });

        /**
         * Appending properties in sourceList which percentual value is 0 (TC-2405)
         */
        sourceItems.forEach((sourceItem) => {
          const elegibleColumnNames = this.columns.filter((colName: string) => !sourceItem.hasOwnProperty(colName));

          const objFromCols = elegibleColumnNames.reduce((acc, colName: string) => {
            acc[colName] = 0;
            acc[`${colName}_numeric`] = 0;
            return acc;
        }, {});

          Object.assign(sourceItem, objFromCols);
        });
    
        return sourceItems;
      })(),
    };

    return dataset
  }

  /**
   * @returns series: objeto deconfiguração das barras do gráfico,
   * como tamanho e legendas
   */
  buildSeries(): Object {
    const series = this.columns.map(legend => {
      return {
        type: 'bar',
        name: legend,
        barCategoryGap: "5%",
        barWidth: DEFAULT_BAR_WIDTH,
      };
    });
    return series;
  }

  /**
   * Passa o dataset e a series para o toEchartOption e renderiza o gráfico
   */
  getChartsValue() {
    if(this.columns) {
      const series = this.buildSeries();
      const dataset = this.buildDataset();

      this.chartOption = toEchartOption(dataset, series);
    }

  }

}


