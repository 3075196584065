<section class="interviewConfig container-white">
  <div class="headerConfig">
    <span class="fa fa-square-o"></span>
    <h3>Grade única</h3>
  </div>
  <div class="contentConfig" [formGroup]="formGroup">
    <div class="section sg-input-group">
      <div class="campo">
        <label>Título da pergunta*</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="título da pergunta" title="título principal" type="text" formControlName="nome"  maxlength="250">
        <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
          A pergunta deve possuir um titulo de até 250 caracteres
        </span>
      </div>
      <div class="campo">
        <label>Orientação</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="Orientação ao Operador" title="Orientação para pergunta do tipo grade única" type="text" formControlName="descricao"  maxlength="150">
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
          A pergunta deve possuir uma descrição de até 150 caracteres
        </span>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">
          <h4>Linhas (Títulos)*</h4>
        </div>
        <div class="subSectionContent row">
          <div class="col-12" *ngFor="let titulo of getControlsFromAbstractFormArray(controls.titulosGrade); let ordem = index">
            <app-titulo-grade
              [ordem]="ordem+1"
              [editavel]="modificacaoPermitida && !passoBloqueado"
              >
            </app-titulo-grade>
          </div>
          <span class="error-message" [hidden]="!(showErrors && controls.titulosGrade.errors)">
            A pergunta deve possuir pelo menos uma linha (título)
          </span>
          <div class="addAlternative" *ngIf="modificacaoPermitida && !passoBloqueado">
            <span class="btnCircle fa fa-plus" (click)="adicionarTituloGrade()"></span>
            <span title="Adicionar título grade" (click)="adicionarTituloGrade()"> Adicionar Titulo Grade</span>
          </div>
        </div>
      </div>
      <div class="subSection">
          <div class="subSectionTitle">
            <h4>Colunas (Alternativas)*</h4>
          </div>
          <div class="subSectionContent row" >
            <div class="col-12" *ngFor="let alternativa of getControlsFromAbstractFormArray(controls.alternativas); let ordem = index">
              <app-alternativa-tipada
                [ordem]="ordem+1"
                [editavel]="modificacaoPermitida && !passoBloqueado"
              ></app-alternativa-tipada>
            </div>
            <span class="error-message" [hidden]="!(showErrors && controls.alternativas.errors)">
              A pergunta deve possuir pelo menos uma alternativa
            </span>
            <div class="addAlternative" *ngIf="modificacaoPermitida">
              <span title="Adicionar uma nova alternativa" class="btnCircle fa fa-plus"></span>
              <span title="Adicionar uma nova alternativa"> Adicionar Alternativa</span>
            </div>
          </div>
        </div>
      <div class="actionConfig">
        <button title="Cancelar pergunta de grade única" class="sg-btn sg-alternate" (click)="cancelar()">Cancelar</button>
        <button title="Adicionar pergunta de grade única" class="sg-btn sg-secondary" (click)="onChange()" *ngIf="modificacaoPermitida && !passoBloqueado">Salvar</button>
      </div>
    </div>
  </div>
</section>

<!-- <h1>Conteudo pergunta grade unica</h1> -->
<!-- <div [formGroup]="formGroup" class="section">
  <div>
    <div class="">
      <h1>Alternativas</h1>
      <div *ngFor="let alternativa of controls.alternativas.controls; let i = index">
        <app-alternativa-tipada
          [formGroup]="alternativa"
        ></app-alternativa-tipada>
      </div>
      <button (click)="adicionarAlternativa()">Adicionar Alternativa</button>
    </div>
  </div>
</div> -->
