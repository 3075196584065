import { Component, OnInit } from "@angular/core";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { infoCardDefaultValue } from "../../constant";
import { errorMessages } from "../../constant/errors";
import { IFilterSelect, ISurveyListFilterConfigs, ISurveyListModel } from "../../interfaces";
import { AuditoriaBetaService } from "../../services/auditoria-beta.service";
import { adaptSurveyListRespToModels } from "../../utils/parsers";

@Component({
  selector: "app-audit-listing",
  templateUrl: "./audit-listing.component.html",
  styleUrls: ["./audit-listing.component.scss"],
})
export class AuditListingComponent implements OnInit {
  public isLoading: boolean = false;

  totalOfSurveys: number = 0;
  currentPage: number = 1;
  surveysPerPage: number = 10;
  totalPages: number = 5;

  surveyListFilterConfigs: ISurveyListFilterConfigs = {
    filtrableAttributes: "",
    filtrableOrder: "",
    keywords: [],
    status: [],
  };

  constructor(
    private auditService: AuditoriaBetaService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  // dados que deverão ser passados para o info-card
  infoCardData = {
    data: [],
    title: "Auditoria",
    text: "Audite coletas e garanta confiabilidade nos dados das suas avaliações",
  };

  // Dados da listagem de pesquisa atual.
  surveyListData: ISurveyListModel[] = [];

  // what is it? comment pls
  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Auditoria",
      itemLink: "/auditoria-beta",
      active: true,
    },
  ];

  ngOnInit(): void {
    this.initializeInfoCardData();
    this.fetchSurveyListData();
  }

  public handleChangeFilter($event: { status: IFilterSelect[], keywords: string, sorting: IFilterSelect[] }) {
    const [selectedSortingOption] = $event.sorting;
    const [sortingType = "", direction = ""] = (selectedSortingOption?.key || "").split('-');

    this.surveyListFilterConfigs.status = [...$event.status.map(v => v.key)];
    this.surveyListFilterConfigs.keywords = [$event.keywords];
    this.surveyListFilterConfigs.filtrableAttributes = sortingType;
    this.surveyListFilterConfigs.filtrableOrder = direction;

    // reset pagination
    this.currentPage = 1;

    // recall request
    this.fetchSurveyListData();
  }

  /**
   * Inicializa o número total de páginas utilizado no componente de paginação
   * com base no número total de pesquisas e a quantidade de itens por página.
   */
  private initializePaginationAttributes() {
    this.totalPages = Math.ceil(this.totalOfSurveys / this.surveysPerPage);

    if (this.totalPages === 0) {
      this.totalPages = 1;
    }
  }

  /**
   * formata o atributo do back para exibir o atributo e o valor
   * @param title string
   * @returns titulo do info card formatado
   */
  private replaceTitle(title: string): string {
    switch (title) {
      case 'nao_auditada':
        return 'Não Auditada'
      case 'pesquisas':
        return 'Pesquisas'
      case 'em_auditoria':
        return 'Em Auditoria'
      case 'auditadas':
        return 'Auditadas'
      default:
        break;
    }
  }

  /**
   * Inicializa os dados do banner de auditoria
   * Caso ocorra um erro os dados serão preenchidos com 0 e o usuário será notificado
   */
  private initializeInfoCardData(): void {
    this.auditService.getInfoCardData().subscribe({
      next: (data) => {
        Object.entries(data).forEach((auditObject) => {
          if (auditObject) {
            this.infoCardData["data"].push({
              title: this.replaceTitle(auditObject[0]),
              value: Number(auditObject[1]),
            });
          }
        });
      },
      error: () => {
        this.notificatorService.showError(
          "Algo deu errado",
          "Não foi possível obter os dados de auditoria"
        );
        this.infoCardData["data"].push(...infoCardDefaultValue);
      },
    });
  }

  /**
   * Obtem o total de pesquisas cadastradas baseado no filtro informado
   * @param keywords: palavras chave
   * @param status: situação da pesquisa
   */
  getTotalSurvey(keywords: string[], status: string[]): void {

    const filters = {
      palavrasChave: [ ...keywords ],
      situacoes: [ ...status ]
    }

    this.auditService.getTotalSurveys(filters).subscribe({
      next: (data) => {
        if (typeof data === 'number') {
          this.totalOfSurveys = data;
          this.initializePaginationAttributes();
        }
      }
    })
  }

  private fetchSurveyListData(): void {
    this.isLoading = true;

    const { filtrableAttributes, filtrableOrder, keywords, status } =
      this.surveyListFilterConfigs;

    this.getTotalSurvey(keywords, status);

    // Infelizmente o valor inicial para paginação no back-end começa em 0.
    const backEndPagination = this.currentPage - 1;

    this.auditService
      .getSurveyListData(
        backEndPagination,
        this.surveysPerPage,
        filtrableAttributes,
        filtrableOrder,
        keywords,
        status
      )
      .subscribe({
        next: (data) => {
          this.surveyListData = adaptSurveyListRespToModels(data);
        },
        error: (error) => {
          this.errorHandlerService.handleError(
            error,
            errorMessages.surveyList.title,
            errorMessages.surveyList.message,
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  // navega entre as páginas
  changePage($event: number) {
    // Só irá realizar a requisição e atribuir a nova página caso a paginação clicada seja diferente da atual.
    if (this.currentPage !== $event) {
      this.currentPage = $event;

      // re-call survey list data fetching after update pagination
      this.fetchSurveyListData();
    }

  }
}
