import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-document]',
})
export class MascaraDocumentDirective implements OnChanges {

  constructor(private el: ElementRef) {}

  @Input('initialValue') set initialValue(value: string) {
    const newDocument = this.callDocumentValidation(value);
    this.el.nativeElement.value = newDocument;
  }

  ngOnChanges(): void {
    const newDocument = this.callDocumentValidation(this.el.nativeElement.value);
    this.el.nativeElement.value = newDocument;
  }

  @HostListener('input', ['$event'])
  onInput(event) {
    const newDocument = this.callDocumentValidation(event.target.value);
    event.target.value = newDocument;
  }

  callDocumentValidation(value: string): string {
    if (value?.length > 14) {
      const cnpj = this.applyCnpjMask(value);
      return cnpj

    } else {
      const cpf = this.applyCpfMask(value);
      return cpf
    }
  }

  applyCpfMask(valor: string): string {

    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

    // Coloca um ponto entre o terceiro e o quarto dígitos
    const valorPrimeiraFormatacao = numberString.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um hífen entre o terceiro e o quarto dígitos
    const valorTerceiraFormatacao = valorSegundaFormatacao.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return valorTerceiraFormatacao;
  }

  applyCnpjMask(valor: string): string {

    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

    // Adicionando o ponto entre o segundo e terceiro digito
    const valorPrimeiraFormatacao = numberString.replace(/(\d{2})(\d)/, '$1.$2');

    // Adicionando o ponto entre o quinto e sexto digito
    const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(/(\d{3})(\d)/, '$1.$2');

    // Adicionando a barra entre o oitavo e nono digito
    const valorTerceiraFormatacao = valorSegundaFormatacao.replace(/(\d{3})(\d)/, '$1/$2');

    // Adicionando o hifen entre o decimo segundo e decimo terceiro digito
    const valorQuartaFormatacao = valorTerceiraFormatacao.replace(/(\d{4})(\d)/, '$1-$2');

    return valorQuartaFormatacao;
  }


}
