import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractPerguntaConteudo } from '../abstractPerguntaConteudo';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';

@Component({
  selector: 'app-pergunta-horario-content',
  templateUrl: './pergunta.horario.content.component.html',
  styleUrls: ['./pergunta.horario.content.component.scss'],
})
export class PerguntaHorarioContentComponent extends AbstractPerguntaConteudo {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
              protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
