import { Injectable } from '@angular/core';
import { RequestService } from 'app/servico/request.service';
import { PalavraChave } from 'app/util/componente/tabela/tabela-filtravel/filter/palavraChave';
import { DadosPaginacao } from 'app/util/componente/paginacao/dadosPaginacao';
import { Observable, of } from 'rxjs';
import { InstanciaSistema } from './instancia-cadastro/InstanciaSistema';
import { apiLocation } from 'app/infraestrutura/apiLocation';
// tslint:disable-next-line: max-line-length
import { SimpleFilterService } from 'app/util/componente/tabela/tabela-filtravel/filter/filterService';
import { CrudService } from 'app/servico/requestService/crudService';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line: max-line-length
export class ManagementService extends CrudService<InstanciaSistema> implements SimpleFilterService<InstanciaSistema>{

  protected resourcePath = `${apiLocation}/management`;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/management`);
  }

  salvar(instancia: InstanciaSistema): Observable<InstanciaSistema> {

    const requestUrl = `${this.resourcePath}/instance`;

    const request = <Observable<InstanciaSistema>>this.requestService.post(requestUrl, instancia);

    return request;
  }

  atualizar(instancia: InstanciaSistema): Observable<InstanciaSistema> {
    const requestUrl = `${this.resourcePath}/instance`;
    const {
      friendlyName,
      ativo,
      admin,
      name
    } = instancia

    const request = <Observable<InstanciaSistema>>this.requestService.put(requestUrl, {
      friendlyName,
      ativo,
      admin,
      name
    });

    return request;
  }

  findInstanciaByName(name: String): Observable<InstanciaSistema> {
    const requestUrl = `${this.resourcePath}/instance/${name}`;

    const request = <Observable<InstanciaSistema>>this.requestService.get(requestUrl);

    return request;
  }

  // tslint:disable-next-line: max-line-length
  aplicarFiltroInstancia(palavraChave: PalavraChave, dadosPaginacao?: DadosPaginacao): Observable<InstanciaSistema[]> {
    const requestUrl = `${this.resourcePath}/filtrar/palavra-chave`;

    // tslint:disable-next-line:max-line-length
    const response = <Observable<InstanciaSistema[]>>this.requestService.post(requestUrl, palavraChave, true, dadosPaginacao);

    return response;
  }

  getTotalEntidades(palavraChave?: PalavraChave): Observable<number> {
    const requestUrl = `${this.resourcePath}/instance/total-registros`;

    return <Observable<number>>this.requestService.post(requestUrl, palavraChave);
  }

  // tslint:disable-next-line: max-line-length
  aplicarFiltro(palavrasChave: PalavraChave, dadosPaginacao?: DadosPaginacao): Observable<InstanciaSistema[]> {
    const requestUrl = `${this.resourcePath}/instance/filtrar/palavra-chave`;

    // tslint:disable-next-line:max-line-length
    const response = <Observable<InstanciaSistema[]>>this.requestService.post(requestUrl, palavrasChave, true, dadosPaginacao);

    return response;
  }

}
