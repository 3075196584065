import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IFilterSelect } from "../../modulos/auditoria-beta/interfaces";
import { FilterTypes } from "./constants";
import { IComposedFilterSelect, ISelectConfig } from "./filter-select";

@Component({
  selector: "app-filter-select",
  templateUrl: "./filter-select.component.html",
  styleUrls: ["./filter-select.component.scss"],
})
export class FilterSelectComponent implements OnInit {
  @ViewChild("selectFilter", { static: true }) selectFilter;

  @Input() type = FilterTypes.DATE;
  @Input() icon = "";
  @Input() placeholder = "Selecione uma opção";
  @Input() options: IFilterSelect[] = [];
  @Input() selectConfig: ISelectConfig = null;
  @Input() selectedOptions: IFilterSelect[] = [];
  @Input() composedOptions: IComposedFilterSelect[] = []
  @Input() disabledOptions: Array<number> = [];

  @Output() changeFilter = new EventEmitter<any>();

  filterSelectIsOpen = false;
  selectedFilter = "";

  defaultValues = {
    icon: "",
    placeholder: "Selecione uma opção"
  };

  constructor() {}

  ngOnInit(): void {
    this.setDefaultValues();
  }

  setDefaultValues() {
    if (!this.icon) this.icon = this.defaultValues.icon;
    if (!this.placeholder) this.placeholder = this.defaultValues.placeholder;
  }

  // controla a abertura do modal
  toggleFilterSelect() {
    this.filterSelectIsOpen = !this.filterSelectIsOpen;
  }

  /**
   * Emite o evento com valor da seleção
   * @param $event Evento cujo valor será repassado para o componente que instancia o filter-select
   */
  handleSelect($event) {
    // função para setar o placeholder de acordo com a seleção
    this.changeUpdatePlaceholderSelectedFilter($event);

    this.changeFilter.emit($event);
  }

  changeUpdatePlaceholderSelectedFilter($event) {
    // quando é seleção múltipla
    if ($event.currentState) {
      // passo por todos os states correntes e deixo apenas o primeiro com a letra maiúscula.
      this.selectedFilter =
        $event.currentState.length !== 0
          ? $event.currentState
              .map(({ label }, index) =>
                index === 0 ? label : label.toLowerCase()
              )
              .join(", ")
          : "";
    }
    // quando é seleção única
    if ($event.length === 1) {
      this.selectedFilter = $event[0].label;
    }

    //quando limpa a seleção.
    if (!$event.length && !$event.currentState) {
      this.selectedFilter = "";
    }
  }

  /**
   * Escuta evento de click e fecha select quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.selectFilter.nativeElement.contains(event.target)) {
      if (this.filterSelectIsOpen) {
        this.toggleFilterSelect();
      }
    }
  }
}
