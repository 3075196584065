import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { apiLocation, viaCepUrl } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { IResponseClientModel } from "../interfaces/list";
import { QueryParamsResolver } from "app/util/formatter/queryParams";
import { DirectionOrderTypes } from "../constants/listagem";
import { IClientCreateOrEditPayload, IClientGetDataPayload } from "../interfaces/clientForm";

@Injectable({
  providedIn: "root",
})
export class ClienteService extends CrudService<any> {
  public baseUrl = this.resourcePath;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}`);
  }

  getClientesList(
    keywords = [""],
    status = true,
    sorting: { direction: DirectionOrderTypes, sort: string },
    page = 0,
    size = 10,
  ): Observable<IResponseClientModel[]> {

    const { direction, sort } = sorting;

    const queryParams = new QueryParamsResolver({
      ativo: status,
      page,
      size,
      direction,
      sort
    }).toString();

    const url = `${this.baseUrl}/clientes/filtrar/palavra-chave${queryParams}`; //ativo=${status}&page=${page}&size=${size}`;

    const response = <Observable<IResponseClientModel[]>>(
      this.requestService.post(url, { palavrasChave: keywords })
    );
    return response;
  }

  getTotalClients(keywords = [""], status): Observable<number> {

    const queryParams = new QueryParamsResolver({
      ativo: status
    }).toString();

    const url = `${this.baseUrl}/clientes/total-registros${queryParams}`;

    const response = <Observable<number>>(
      this.requestService.post(url, { palavrasChave: keywords })
    );

    return response;
  }

  setStatusClients(id: number, status: boolean): Observable<any> {
    const url = `${this.baseUrl}/clientes/${id}/ativo`;

    const response = <Observable<any>>this.requestService.put(url, {ativo: status});
    return response
  }

  /**
   * Obtem os dados do header da página de listagem de clientes
   */
  getClientHeader() {
    const url = `${this.baseUrl}/clientes/resumo`;

    const response = this.requestService.get(url);
    return response
  }

  /**
   * Obtem os dados de endereço do cliente
   */
  BuscaCep(cep: string) {
    const viaCep = viaCepUrl(cep);

    const response = fetch(viaCep)
      .then((data) => data.json())
      .then((result) => result)
      .catch((error) => console.error(error));

    return response;
  }

  /**
   * Cria um novo cliente
   */
  createClient(data: IClientCreateOrEditPayload): Observable<any> {
    const url = `${this.baseUrl}/clientes`;

    const response = this.requestService.post(url, data);
    return response;
  }

  /**
   * Atualiza os dados do cliente
   * @param data: dados do cliente
   */
  updateClient(data: IClientCreateOrEditPayload): Observable<any>  {
    const url = `${this.baseUrl}/clientes/${data.id}`
    const response = this.requestService.put(url, data);
    return response;
  }

  /**
   * Recupera os dados do cliente na tela de cadastro
   */
  getClientData(id): Observable<IClientGetDataPayload> {
    const url = `${this.baseUrl}/clientes/${id}`;
    const response = this.requestService.get(url);
    return response;
  }
}
