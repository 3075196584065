import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilterSelect, IFilterSelectEvent } from "../../filter-select";

@Component({
  selector: "app-check-select",
  templateUrl: "./check-select.component.html",
  styleUrls: ["./check-select.component.scss"],
})
export class CheckSelectComponent implements OnInit {
  @Output() changeFilter = new EventEmitter<IFilterSelectEvent>();
  @Input() options: IFilterSelect[] = [];
  @Input() selected: IFilterSelect[] = [];
  @Input() disabledOptions: Array<number>;

  constructor() {}

  ngOnInit(): void {
    this.initSelectedOptions();
  }

  selectedOptions: IFilterSelect[] = [];

  /**
   * Inicializa as opções selecionadas caso tenham sido repassadas através do componente pai.
   */
  initSelectedOptions() {
    if (this.selected.length) {
      this.selectedOptions = [...this.selected];
    }
  }

  /**
   * Verifica a opção que deverá ser desabilitada
   */
  isOptionDisabled(item): boolean {
    return this.disabledOptions.includes(item.id);
  }

  /**
   * Verifica se a opção está selecionada, para mostrar visualmente
   * @param option Pode ser qualquer valor que esteja dentro do array de options;
   * @returns Se a opção estiver selecionada retorna true caso contrário false
   */
  hasSelected(option: IFilterSelect): boolean {
    return (
      this.selectedOptions.findIndex(
        ({ id }: IFilterSelect) => id === option.id
      ) !== -1
    );
  }

  /**
   * Seleciona um item dos listados do array, mantendo o estado dessa seleção, caso o item clicado ja estaja selecionado é feita um desseleção
   * @param option Pode ser qualquer valor que esteja dentro do array de options;
   */
  selectFilterOption(option: IFilterSelect) {
    if (!this.hasSelected(option)) {
      this.selectedOptions.push(option);
    } else {
      const deleteindex = this.selectedOptions.findIndex(
        ({ id }: IFilterSelect) => id === option.id
      );
      this.selectedOptions.splice(deleteindex, 1);
    }

    // Emitindo o estado atual de seleção
    this.changeFilter.emit({
      clickedOption: option,
      currentState: this.selectedOptions,
    });
  }
}
