<div class="header-border">
  <div class="sublocalidade-box">
    <div [ngSwitch]="typeOfChildrenMessageRef">
      <div class="cabecalho" *ngSwitchCase="0">
        <img src="assets/icons/info-icon.svg" alt="" />
        <p>
          Amostras distribuídas de forma automática. Redistribua as amostras
          editando os campos se necessário.
        </p>
      </div>

      <div class="cabecalho" *ngSwitchCase="1">
        <img src="assets/icons/info-icon.svg" alt="" />
        <p>
          Resta(m) {{ leftToComplete }}% amostra(s) a ser(em) distribuída(s).
        </p>
      </div>

      <div class="cabecalho" *ngSwitchCase="2">
        <img src="assets/icons/info-icon.svg" alt="" />
        <p>O número de coletas foi distribuído com sucesso.</p>
      </div>

      <div class="cabecalho error-message" *ngSwitchCase="5">
        <img src="assets/icons/info-red.svg" alt="" />

        <p>
          Você excedeu a quantidade máxima de coletas. Redistribua as amostras.
        </p>
      </div>
      <div class="cabecalho" *ngSwitchDefault>
        <img src="assets/icons/info-icon.svg" alt="" />
        <p>
          Distribua a amostra acima definida entre as sublocalidades filhas.
        </p>
      </div>
    </div>
    <div class="location-container">
      <div class="bar-float"></div>
      <div *ngFor="let sublocation of data; let subIndex = index" class="flex column justify-between">
        <span class="flex justify-between width-size">
          <div class="place-container-sub flex">
            <app-checkbox [isChecked]="sublocation.checked" (click)="handleSubCheckClick(sublocation.id)"
              [isDisabled]="!sublocation.toggleChecked"></app-checkbox>
            <p class="place">{{ sublocation.nome }}</p>
          </div>
          <div class="sample-insert-sub flex center">
            <label [for]="sublocation.id" class="input-wrapper" [ngClass]="
                (fatherSampleRef <= 0 && 'out-of-range') ||
                (!sublocation.toggleChecked && 'setDisabled')
              ">
              <input type="number" [id]="sublocation.id" placeholder="Inserir amostra" min="0" max="100"
                [(ngModel)]="inputOptions[subIndex].displayValue" [style.width.ch]="inputOptions[subIndex].width"
                [style.text-align]="inputOptions[subIndex].width > 5 ? 'center' : 'right'"
                (input)="handleSubChange($event, sublocation.id, subIndex)" step="0.01" (blur)="
                  handleRoundValue(
                    inputOptions[subIndex].realValue,
                    sublocation.id,
                    subIndex
                  )
                " [disabled]="fatherSampleRef <= 0 || !sublocation.toggleChecked" />
              <span [hidden]="inputOptions[subIndex].width>5 || !sublocation.toggleChecked">%</span>
            </label>
          </div>
          <div class="bar-sub"></div>
          <div class="switch-container flex center">
            <app-toggle (isChecked)="
                handleSubToggleCheckClick(
                  sublocation.id,
                  sublocation.toggleChecked,
                  subIndex
                )
              " [checked]="sublocation.toggleChecked"></app-toggle>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>