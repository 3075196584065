<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">
    
    <app-pergunta-card
    [icone]="'fa fa-picture-o'"
    [titulo]="ordem + '- FOTO'"
    [nomePlaceHolder]="'Título da pergunta de foto?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>
