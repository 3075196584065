// responsável por renderizar as legendas no topo do gráfico de barras
export const barTooltipFormatter = (params, numberResult) => {
  const circleLabel = params.marker; // circulo da legenda
  const itemName = params.seriesName;
  const percentual = `${params.data}%`; // valor percentual
  const numerico = numberResult[params.componentIndex]; //valor númerico

  return `${circleLabel} ${itemName} <br> ${percentual} ${numerico} respostas`;
};

export const pieTooltipFormatter = (params, numberResult) => {
  const circleLabel = params.marker;
  const itemName = params.name;
  const percentual = `${params.value}%`;
  const numerico = numberResult[params.dataIndex];

  return `${circleLabel} ${itemName} <br> ${percentual} ${numerico} respostas`;
};

export const gridTooltipFormatter = (params) => {
  const circleLabel = params.marker; // circulo da legenda
  const itemName = params.seriesName;
  const percentual = `${params.data[params.seriesName]}%`; // valor percentual
  const numerico = params.data[`${params.seriesName}_numeric`]; //valor númerico

  return `${circleLabel} ${itemName} <br> ${percentual} ${numerico} respostas`;
};

export const multipleGridTooltipFormatter = (params) => {
  const circleLabel = params.marker; // circulo da legenda
  const itemName = params.seriesName;
  const percentual = `${params.data[`${params.seriesName}_percent`]}%`; // valor percentual
  const numerico = params.data[`${params.seriesName}_numeric`]; //valor númerico

  return `${circleLabel} ${itemName} <br> ${percentual} ${numerico} respostas`;
};
