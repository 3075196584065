import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[mascara-remove-accents]'
})
export class RemoveAccentsDirective {

  removeAccents: boolean = false;
  newValue: string;

  @Input('removeAllAcents') set removeAllAcents(value: boolean) {
    this.removeAccents = value;
  }

  constructor() { }

  @HostListener('keyup', ['$event'])
  onInput(event) {
    const inputValue = event.target.value;

    if(this.removeAccents) {
      this.newValue = inputValue.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[~^´`¨]/g, '');
    } else {
      this.newValue = inputValue.replace(/[~^´`¨]/g, '');
    }

    event.target.value = this.newValue;
  }
}
