import { OnInit, Component } from "@angular/core";
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import { RecuperarSenhaService } from "../recuperarSenhaService";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-alteracao-senha",
  templateUrl: "./alteracao-senha.component.html",
  styleUrls: ["./alteracao-senha.component.scss"],
})
export class AlteracaoSenhaComponent implements OnInit {
  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };
  senhaRedefinida: boolean = false;

  token: string = "";
  tokenValido: boolean = false;

  showApiError: boolean = false;
  apiError: string = "";

  isLoading: boolean = false;

  // tslint:disable-next-line: max-line-length
  constructor(
    private recuperarSenhaService: RecuperarSenhaService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.activatedRouter.queryParams.subscribe((paramMap) => {
      this.token = paramMap.token;
    });

    this.isLoading = true;

    this.recuperarSenhaService
      .testarValidadeDoToken({ token: this.token })
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.tokenValido = true;
        },
        error: (error) => {
          this.exibirErroDaValidacaoDoToken(error);
          this.isLoading = false;
          //this.isLoading = false;
          //this.tokenValido = true;
        },
      });

    this.initFormGroup();
  }

  private initFormGroup() {
    this.formGroup = new UntypedFormGroup(
      {
        token: new UntypedFormControl(""),
        // tslint:disable-next-line: max-line-length
        senha: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(30),
        ]),
        senhaConfirmar: new UntypedFormControl("", [Validators.required]),
      },
      {
        validators: function validator(formValues) {
          return formValues &&
            formValues.value.senha.length > 0 &&
            formValues.value.senhaConfirmar.length > 0 &&
            formValues.value.senha !== formValues.value.senhaConfirmar
            ? {
                senhasDiferentes: true,
              }
            : {};
        },
      }
    );
    this.controls = this.formGroup.controls;

    this.activatedRouter.queryParams.subscribe((paramMap) => {
      const token = paramMap.token;
      this.controls.token.patchValue(token);
    });
  }

  formularioComSenhasDiferentes() {
    return this.formGroup.errors && this.formGroup.errors.senhasDiferentes;
  }

  alterar() {
    if (this.senhaRedefinida) {
      return;
    }
    this.isLoading = true;

    const formData = {
      senha: this.formGroup.value.senha,
      token: this.formGroup.value.token,
    };

    this.recuperarSenhaService.alterarSenha(formData).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.senhaRedefinida = true;
      },
      error: (error) => {
        this.apiError =
          "Seu token já expirou ou não existe. Solicite a alteração de senha novamente";
        this.showApiError = true;
        this.isLoading = false;
      },
    });
  }

  irParaTelaDeLogin() {
    this.router.navigate(["/login"]);
  }

  private exibirErroDaValidacaoDoToken(error) {
    this.modalService.showModal({
      title: "Falha na sua solicitação",
      messageModal: error,
      btnTitlePositive: "Ir para tela de login",
      positiveCallback: () => this.router.navigate(["/login"]),
      isOnlyConfirmation: true,
    });
  }
}
