
<div class="containerInputs">
  <div class="inputs" #inputs>
    <app-s-input
      class="s-input"
      type="date"
      label="Início"
      placeholder="DD/MM/YYYY"
      [kind]="usedBy === 'PESQUISA' ? 'date-light' : 'date-dark'"
      (changedText)="handleInputChange($event, 'initialDate')"
      [inputSelected]="initialDate !== 0 ? toMoment(initialDate) : null"
      [min]="getMinDate('initialDate')"
      [max]="getMaxDate('initialDate')"
      >
    </app-s-input>
    <img src="assets/images/arrowRight.svg" alt="">
    <app-s-input
      class="s-input"
      label="Fim"
      placeholder="DD/MM/YYYY"
      [kind]="usedBy === 'PESQUISA' ? 'date-light' : 'date-dark'"
      type="date"
      (changedText)="handleInputChange($event, 'endDate')"
      [inputSelected]="endDate !== 0 ? toMoment(endDate) : null"
      [min]="getMinDate('endDate')"
      [max]="getMaxDate('endDate')"
      >
    </app-s-input>
    <span *ngIf="!isDateValid" class="error-message">Intervalo de data é inválida</span>
  </div>
  <div class="containerCicle" *ngIf="usedBy !== 'INFO'">
    <div class="calendarImg" alt="Calendario"></div>
    <span *ngIf="cylclesDays >= 2 || cylclesDays < -1"> {{cylclesDays}} dias de ciclo</span>
    <span *ngIf="cylclesDays === 1 || cylclesDays === -1"> {{cylclesDays}} dia de ciclo</span>
  </div>
</div>
