import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemProtegido } from '../../security/itemProtegido';
import { PesquisaAuthorityService } from '../../security/pesquisaAuthorityService';
import { SetPreparadoParaSalvar } from '../../store/actions/controleGeralAction';
import { CadastroPesquisaStoreState } from '../../store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../store/passoCadastro';
import { DefinicoesCota } from '../../store/vinculos/definicoesCota';
import { PassoComponent } from '../passoComponent';

@Component({
  selector: 'app-gerenciador-cotas',
  templateUrl: './gerenciador-cotas.component.html',
  styleUrls: ['./gerenciador-cotas.component.scss'],
})
export class GerenciadorCotasComponent extends PassoComponent {

  formGroup: UntypedFormGroup = new UntypedFormGroup({});

  /**
   * Definicao de qual passo se está
   */
  passoCadastro: PassoCadastro = PassoCadastro.GERENCIADOR_COTAS;

  /**
   * Definicoes de cota
   */
  definicoesCota: DefinicoesCota[] = [];

  /**
   * Marcador que indica se modificações
   * estão habilitadas no passo. No caso, se o
   * botão de transferência estará ativo
   */
  modificacaoPermitida = false;

  /**
   * Marcador que indica se a pesquisa possui perfis
   * de cota a serem distribuidos. Caso negativo,
   * não há trabalho a ser realizado nesta etapa da pesquisa
   */
  pesquisaPossuiPerfisCota = false;

  filtrarLocalidadesOperadoresSubject: Subject<string> = new Subject();

  // tslint:disable-next-line: max-line-length
  constructor(private pesquisaAuthorityService: PesquisaAuthorityService, protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.verificarPermissaoAlteracao();
    this.listenToPrepararParaSalvar();
    this.verificarPesquisaPossuiPerfisCota();
  }

  filtrarLocalidadesOperadores(queryText: string) {
    this.filtrarLocalidadesOperadoresSubject.next(queryText);
  }

  /**
   * Verifica se a pesquisa possui perfis de cota
   * e atualiza o atributo do componente com essa informacao
   */
  verificarPesquisaPossuiPerfisCota() {

    const perfisEntrevistadoObservable: Observable<any[]> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(x => x['pesquisa']),
        map(x => x['perfisEntrevistados']),
      );

    // tslint:disable-next-line: max-line-length
    const perfisEntrevistadoSubscription = perfisEntrevistadoObservable.subscribe((perfisEntrevistado) => {
      this.pesquisaPossuiPerfisCota = perfisEntrevistado && perfisEntrevistado.length > 0;
    });
    this.subscriptions.push(perfisEntrevistadoSubscription);
  }

  listenToPrepararParaSalvar() {

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    const storeObservableSubscription = pesquisaStoreObservable.subscribe((store) => {

      const iniciarPreparacaoParaSalvar = store.dadosGeraisCadastro.prepararParaSalvar;
      const preparado = store.dadosPasso.get(PassoCadastro.GERENCIADOR_COTAS).preparadoParaSalvar;
      if (iniciarPreparacaoParaSalvar && !preparado) {
        this.store.dispatch(new SetPreparadoParaSalvar(PassoCadastro.GERENCIADOR_COTAS, true));
      }
    });
    this.subscriptions.push(storeObservableSubscription);
  }

  verificarPermissaoAlteracao() {

    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.VINCULOS);
  }

}
