import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-input-password",
  templateUrl: "./input-password.component.html",
  styleUrls: ["./input-password.component.scss"],
})
export class InputPasswordComponent implements OnInit {
  hide = true;
  @Input() controlName: string;
  @Input() parentFormGroup: FormGroup<any>;
  @Input() isAlteracaoSenha: boolean = false;
  @Input() isEdicao: boolean = false;
  @Input() hasError: boolean = false;

  // evento passado pelo pai para receber o valor digitado pelo o usuário
  @Output() canUpdatePassword = new EventEmitter<string>();

  ngOnInit() {}

  /**
   * função que recebe o valor do input digitado pelo
   * usuário e propaga o valor para o componente pai
   *
   * @param $event input value
   */
  handleCheckUpdatePassword($event: any): void {
    this.canUpdatePassword.emit($event.target.value);
  }
}
