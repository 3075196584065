<div>

  <!-- HAS PICTURE -->
  <div
    *ngIf="hasFoto()"
    class="containerImage"
    >
    <span>
      <img src={{this.fotoResposta}} />
    </span>
  </div>

  <!-- HAS NOT PICTURE -->
  <div class="containerImage" *ngIf="!hasFoto()">
    <div class="nofoto">
      Nenhuma foto fornecida
    </div>
  </div>

</div>

