<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
  [ngClass]="selecionado ? 'selecionado' : ''">
  
  <app-pergunta-card
    [icone]="'fa fa-calendar'"
    [titulo]="ordem + ' - DATA'"
    [nomePlaceHolder]="'Título da pergunta de data?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>
