function data() {
  /*const entrevista = {
    audio: '',
    auditada: false,
    respostasPerguntas: [
      {
        pergunta: {
          id: 80,
          descricao: 'Qual sua cor de roupa preferida',
          tipo: 'MULTIPLA_ORDEM_CITACAO',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 4,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 176,
              codigoMarcacao: null,
              descricao: 'preto',
              ordem: 1,
              tipoAlternativa: 'FECHADA',
              marcacao: null
            },
            {
              id: 177,
              codigoMarcacao: null,
              descricao: 'branco',
              ordem: 2,
              tipoAlternativa: 'FECHADA',
              marcacao: null
            },
            {
              id: 178,
              codigoMarcacao: null,
              descricao: 'rosa',
              ordem: 3,
              tipoAlternativa: 'FECHADA',
              marcacao: null
            },
            {
              id: 179,
              codigoMarcacao: null,
              descricao: 'azul',
              ordem: 4,
              tipoAlternativa: 'FECHADA',
              marcacao: null
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 90,
            idAlternativa: 177,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 2,
            tipo: 'SELECAO_TEXTO'
          },
          {
            id: 89,
            idAlternativa: 176,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 1,
            tipo: 'SELECAO_TEXTO'
          }
        ]
      },
      {
        pergunta: {
          id: 81,
          descricao: 'Data de entrevista',
          tipo: 'DATA',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: []
        },
        alternativasSelecionadas: [
          {
            id: 91,
            idAlternativa: null,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: '19/06/2019',
            horario: null,
            ordem: 0,
            tipo: 'DATA'
          }
        ]
      },
      {
        pergunta: {
          id: 82,
          descricao: 'foto do local',
          tipo: 'FOTO',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: []
        },
        alternativasSelecionadas: [
          {
            id: 92,
            idAlternativa: null,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: 'https://alfa-tensai-teste.s3.amazonaws.com/temcertezabixow/pesquisas/gravacoes/9fRzxcNPun_4.jpeg',
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'FOTO'
          }
        ]
      },
      {
        pergunta: {
          id: 83,
          descricao: 'horaria da entrevista',
          tipo: 'HORARIO',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: []
        },
        alternativasSelecionadas: [
          {
            id: 93,
            idAlternativa: null,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: '16:59:09',
            ordem: 0,
            tipo: 'HORARIO'
          }
        ]
      },
      {
        pergunta: {
          id: 84,
          descricao: 'vídeo de registro do local',
          tipo: 'VIDEO',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: []
        },
        alternativasSelecionadas: [
          {
            id: 97,
            idAlternativa: null,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: 'https://alfa-tensai-teste.s3.amazonaws.com/temcertezabixow/pesquisas/videos/d75c013797f6f31e.mp4',
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'VIDEO'
          }
        ]
      },
      {
        pergunta: {
          id: 85,
          descricao: 'QUEM É SEU ESTAGIARIO FAVORITO',
          tipo: 'UNICA',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: false,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 180,
              codigoMarcacao: null,
              descricao: 'WILLIAM',
              ordem: 1,
              tipoAlternativa: 'FECHADA',
              marcacao: null
            },
            {
              id: 181,
              codigoMarcacao: null,
              descricao: 'THALYA',
              ordem: 2,
              tipoAlternativa: 'FECHADA',
              marcacao: null
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 94,
            idAlternativa: 181,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'SELECAO_TEXTO'
          }
        ]
      },
      {
        pergunta: {
          id: 86,
          descricao: 'FOTO ',
          tipo: 'FOTO',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: []
        },
        alternativasSelecionadas: [
          {
            id: 95,
            idAlternativa: null,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: 'https://alfa-tensai-teste.s3.amazonaws.com/temcertezabixow/pesquisas/gravacoes/8iU5Sh9PhX_5.jpeg',
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'FOTO'
          }
        ]
      },
      {
        pergunta: {
          id: 87,
          descricao: 'ABERTA NÚMERO ',
          tipo: 'UNICA',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: false,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 182,
              codigoMarcacao: null,
              descricao: 'DIGITE UM NUMERO',
              ordem: 1,
              tipoAlternativa: 'ABERTA_NUMERO',
              marcacao: null
            },
            {
              id: 183,
              codigoMarcacao: null,
              descricao: 'DOIS NÚMEROS',
              ordem: 2,
              tipoAlternativa: 'ABERTA_NUMERO',
              marcacao: null
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 96,
            idAlternativa: 183,
            idTituloGrade: null,
            respostaAberta: '10',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'SELECAO_TEXTO'
          }
        ]
      },
      {
        pergunta: {
          id: 76,
          descricao: 'QUAL SEU SEXO ',
          tipo: 'UNICA',
          discoInducao: false,
          possuiCota: true,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 164,
              codigoMarcacao: null,
              descricao: 'MASCULINO',
              ordem: 1,
              tipoAlternativa: 'FECHADA',
              marcacao: {
                id: 59,
                perguntaDestino: null,
                cotaPercentual: 50,
                cotaValor: 25,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            },
            {
              id: 165,
              codigoMarcacao: null,
              descricao: 'FEMININO ',
              ordem: 2,
              tipoAlternativa: 'FECHADA',
              marcacao: {
                id: 60,
                perguntaDestino: null,
                cotaPercentual: 50,
                cotaValor: 25,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 76,
            idAlternativa: 165,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'SELECAO_TEXTO'
          }
        ]
      },
      {
        pergunta: {
          id: 77,
          descricao: 'Qual seu estilo musical favorito',
          tipo: 'MULTIPLA',
          discoInducao: false,
          possuiCota: true,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 4,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 166,
              codigoMarcacao: null,
              descricao: 'rock',
              ordem: 1,
              tipoAlternativa: 'FECHADA',
              marcacao: {
                id: 61,
                perguntaDestino: null,
                cotaPercentual: 20,
                cotaValor: 10,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            },
            {
              id: 167,
              codigoMarcacao: null,
              descricao: 'blues',
              ordem: 2,
              tipoAlternativa: 'FECHADA',
              marcacao: {
                id: 62,
                perguntaDestino: null,
                cotaPercentual: 30,
                cotaValor: 15,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            },
            {
              id: 168,
              codigoMarcacao: null,
              descricao: 'jazz',
              ordem: 3,
              tipoAlternativa: 'FECHADA',
              marcacao: {
                id: 63,
                perguntaDestino: null,
                cotaPercentual: 40,
                cotaValor: 20,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            },
            {
              id: 169,
              codigoMarcacao: null,
              descricao: 'outros',
              ordem: 4,
              tipoAlternativa: 'ABERTA_TEXTO',
              marcacao: {
                id: 64,
                perguntaDestino: null,
                cotaPercentual: 10,
                cotaValor: 5,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 78,
            idAlternativa: 167,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'SELECAO_TEXTO'
          },
          {
            id: 77,
            idAlternativa: 166,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'SELECAO_TEXTO'
          }
        ]
      },
      {
        pergunta: {
          id: 78,
          descricao: 'qual tipo de filme você curte ',
          tipo: 'GRADE_UNICA',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [
            {
              id: 66,
              descricao: 'comedia ',
              ordem: 1
            },
            {
              id: 67,
              descricao: 'ficção',
              ordem: 2
            },
            {
              id: 68,
              descricao: 'documentário ',
              ordem: 3
            },
            {
              id: 69,
              descricao: 'terror',
              ordem: 4
            }
          ],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 170,
              codigoMarcacao: null,
              descricao: 'bom',
              ordem: 1,
              tipoAlternativa: 'GRADE',
              marcacao: null
            },
            {
              id: 171,
              codigoMarcacao: null,
              descricao: 'muito bom',
              ordem: 2,
              tipoAlternativa: 'GRADE',
              marcacao: null
            },
            {
              id: 172,
              codigoMarcacao: null,
              descricao: 'pessimo',
              ordem: 3,
              tipoAlternativa: 'GRADE',
              marcacao: null
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 79,
            idAlternativa: 171,
            idTituloGrade: 66,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 80,
            idAlternativa: 171,
            idTituloGrade: 67,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 81,
            idAlternativa: 172,
            idTituloGrade: 68,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 82,
            idAlternativa: 172,
            idTituloGrade: 69,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          }
        ]
      },
      {
        pergunta: {
          id: 79,
          descricao: 'o quanto de musica você escuta nos períodos do dia?',
          tipo: 'GRADE_MULTIPLA',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [
            {
              id: 70,
              descricao: 'manhã',
              ordem: 1
            },
            {
              id: 71,
              descricao: 'tarde',
              ordem: 2
            },
            {
              id: 72,
              descricao: 'noite',
              ordem: 3
            }
          ],
          qtdMaximaSelecionada: 2,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 173,
              codigoMarcacao: null,
              descricao: 'nada',
              ordem: 1,
              tipoAlternativa: 'GRADE',
              marcacao: null
            },
            {
              id: 174,
              codigoMarcacao: null,
              descricao: 'pouco',
              ordem: 2,
              tipoAlternativa: 'GRADE',
              marcacao: null
            },
            {
              id: 175,
              codigoMarcacao: null,
              descricao: 'muito',
              ordem: 3,
              tipoAlternativa: 'GRADE',
              marcacao: null
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 85,
            idAlternativa: 175,
            idTituloGrade: 70,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 84,
            idAlternativa: 174,
            idTituloGrade: 70,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 83,
            idAlternativa: 173,
            idTituloGrade: 70,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 86,
            idAlternativa: 174,
            idTituloGrade: 71,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 88,
            idAlternativa: 175,
            idTituloGrade: 72,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          },
          {
            id: 87,
            idAlternativa: 174,
            idTituloGrade: 72,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'GRADE'
          }
        ]
      }
    ]
  };*/

  const entrevista = {
    audio: 'https://alfa-tensai-teste.s3.amazonaws.com/alfapesquisas/pesquisas/gravacoes/6A27UQDPV0_56.mp3',
    auditada: false,
    respostasPerguntas: [
      {
        pergunta: {
          id: 55,
          descricao: 'UNICAaAA\n',
          tipo: 'UNICA',
          discoInducao: false,
          possuiCota: false,
          obrigatorio: true,
          titulosGrades: [],
          qtdMaximaSelecionada: 1,
          qtdMinimaSelecionada: 1,
          faixaNumerica: null,
          alternativas: [
            {
              id: 91,
              codigoMarcacao: null,
              descricao: 'UNICA',
              ordem: 1,
              tipoAlternativa: 'FECHADA',
              marcacao: {
                id: 1,
                perguntaDestino: null,
                cotaPercentual: 0,
                cotaValor: 0,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            },
            {
              id: 129,
              codigoMarcacao: null,
              descricao: 'Numeri',
              ordem: 2,
              tipoAlternativa: 'ABERTA_NUMERO',
              marcacao: {
                id: 3,
                perguntaDestino: null,
                cotaPercentual: 0,
                cotaValor: 0,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            },
            {
              id: 130,
              codigoMarcacao: null,
              descricao: 'Texto',
              ordem: 3,
              tipoAlternativa: 'ABERTA_TEXTO',
              marcacao: {
                id: 4,
                perguntaDestino: null,
                cotaPercentual: 0,
                cotaValor: 0,
                perguntaDiferenteComMarcacao: null,
                perguntaDestinoComMarcacao: null,
                perguntasDiferente: null
              }
            }
          ]
        },
        alternativasSelecionadas: [
          {
            id: 504,
            idAlternativa: 91,
            idTituloGrade: null,
            respostaAberta: '',
            arquivo: null,
            data: null,
            horario: null,
            ordem: 0,
            tipo: 'SELECAO_TEXTO'
          }
        ]
      }
    ]
  };

  return entrevista;
}

export default { data };