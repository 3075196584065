import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { AbstractPerguntaConteudoMarcacao } from '../../../abstractPerguntaConteudoMarcacao';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';

@Component({
  selector: 'app-escala-numerica-conteudo-marcacao',
  templateUrl: './escala-numerica-conteudo-marcacao.component.html',
  styleUrls: ['./escala-numerica-conteudo-marcacao.component.scss'],
})
export class EscalaNumericaConteudoMarcacaoComponent extends AbstractPerguntaConteudoMarcacao {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
              protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
