import { LocalidadeVinculo } from '../../store/vinculos/model';

// tslint:disable-next-line: max-line-length
function subSequentSearchFromANode(root: any, childrenField: string = 'children', searchField: string, searchValue: any): any {

  if (root[searchField] === searchValue) {
    return root;
  }

  if (!root.children || root.children.length === 0) {
    return;
  }

  for (let child of root.children) {

    const found = subSequentSearchFromANode(child, childrenField, searchField, searchValue);
    if (found) {
      return found;
    }
  }
}

function findAllLeafs(root: any, childrenField: string = 'children'): any[] {

  const children = root[childrenField];

  if (!children || children.length === 0) {
    return [root];
  }

  const allLeafs = [];

  for (const child of children) {
    const childLeafs = findAllLeafs(child, childrenField);
    allLeafs.push(...childLeafs);
  }

  return allLeafs;
}

/**
 * calcula o nível hierarquico de cada nó de uma árvore. 
 * 
 * @param roots os elementos topos da hierarquia, os que não pais.
 * @param parentHierarchy o valor que precede o nível hirarquico dos elementos topos da hierarquia.
 * @param idField o nome do campo que identifica um elemento na árvore.
 * @param childrenField o nome do campo que representa os filhos de um item da árvore.
 */
// tslint:disable-next-line: max-line-length
function calcularHierarquias(roots: any[], parentHierarchy: number = 0, idField = 'hash', childrenField = 'children'): { [key: string]: number } {

  var result: { [key: string]: number } = {};

  if (roots.length === 0) {
    return result;
  }

  for (let root of roots) {

    const currentHierarchy = parentHierarchy + 1;
    result[root[idField]] = currentHierarchy;

    // tslint:disable-next-line: max-line-length
    const childrenHierarchies = calcularHierarquias(root[childrenField], currentHierarchy, idField, childrenField);
    const childrenHashes = Object.keys(childrenHierarchies);

    // tslint:disable-next-line: max-line-length
    childrenHashes.forEach(childrenHash => result[childrenHash] = childrenHierarchies[childrenHash]);
  }

  return result;
}

/**
 * Converte a lista de localidades vinculos para um formato em árvore.
 * Para este método funcionar, a lista deve estar ordenada da seguinte forma:
 * 
 * localidadePai1
 * filho1DoPai1
 * filho2DoPai1
 * filho1DoFilho2DoPai1
 * filho2DoFilho2DoPai1
 * ...
 * @param list 
 */
function toTreeStructure(list: LocalidadeVinculo[]) {

  const newList: LocalidadeVinculo[] = list.slice();

  var map: { [key: string]: number } = {};
  var node: LocalidadeVinculo;
  var roots: LocalidadeVinculo[] = [];
  var i: number;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].hash] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  /**
   * list = [ { hash1 }, { hashA, hash1 }, { hash2 }, { hashA, hash2 } ]
   * 
   * 
   * map = {
   *    hash1: 0,
   *    hash2: 1,
   *    hash3: 2,
   *    ...
   * };
   */

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.hashVinculoPai) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.hashVinculoPai]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
}

/**
 * Realiza uma busca na árvore por um identificador, caso o elemento identificado seja encontrado,
 * ele é retornado.
 * 
 * @param roots referencia para os elementos no topo da hierarquia da árvore
 * @param childrenField nome do campo de um elemento da árvore que guarda
 * a referência para seus filhos
 * @param idField nome do campo de um elemento da árvore que informa o seu identificador
 * @param idValue identificador a ser procurado na árvore passada
 */
function findInTree(roots: any[], childrenField: string, idField: string, idValue: any): any {

  if (!roots || roots.length === 0) {
    return;
  }

  for (let root of roots) {

    if (root[idField] === idValue) {
      return root;
    }

    const children = root[childrenField];

    const found = findInTree(children, childrenField, idField, idValue);
    if (found) {
      return found;
    }
  }
}

/**
 * Recupera todos os valores dos campos ${propName} dos filhos, netos, bisnetos... de root.
 * @param root o elemento no qual vc quer obter as propriedades filhas
 * @param childrenField valor em string representando o nome da propriedade
 * que armazena os elementos filhos de um
 * nó da árvore.
 * @param propName o nome da propriedade a ser obtida de cada nó. 
 */
function getCascadedChildrenProperty(root: any, childrenField: string = 'children', propName: string): any[] {

  var values = [];
  if (!root[propName]) {
    return values;
  }

  for (let child of root[childrenField]) {
    values.push(child[propName]);

    const childNestedProps = getCascadedChildrenProperty(child, childrenField, propName);
    values.push(...childNestedProps);
  }

  return values;
}

function mapByField(data: any[], field: string) {

  if (!data || data.length === 0) return {};

  const map = data.map((item: any) => {
    const entry = {};
    entry[item[field]] = item;
    return entry;
  })
  .reduce((reduced, next) => ({ ...reduced, ...next }));

  return map;
}

function doMergeTwoSubTrees(
  incomingParent: any,
  incomingRoots: any[],
  incomingRootsIdField: string = 'id',
  incomingRootsChildrenField: string = 'children',
  existingParent: any,
  existingRoots: any[],
  existingRootsIdField: string = 'id',
  existingRootsChildrenField: string = 'children',
  profundidade: number,
  processIncomingNode: (context: { profundidade: number; existingParent: any }, incomingData: any) => any = newData => newData,
): any[] {

  if (!incomingRoots || incomingRoots.length === 0) {
    return;
  }

  const existingRootsById = mapByField(existingRoots, existingRootsIdField);

  incomingRoots.forEach((incomingRoot: any) => {

    const existingEqual = existingRootsById[incomingRoot[incomingRootsIdField]];

    if (!existingEqual) {

      const processorContext = {
        profundidade,
        existingParent,
      };

      const processedIncomingRoot = processIncomingNode(processorContext, incomingRoot);

      existingRoots.push(processedIncomingRoot);
      return;
    }

    return doMergeTwoSubTrees(
      incomingRoot,
      incomingRoot[incomingRootsChildrenField],
      incomingRootsIdField,
      incomingRootsChildrenField,
      existingEqual,
      existingEqual[existingRootsChildrenField],
      existingRootsIdField,
      existingRootsChildrenField,
      profundidade + 1,
      processIncomingNode,
    );
  });
}

/**
 * Realiza a operação de merging entre duas árvores
 * @param incomingRoots conjunto de sub-árvores que será
 * adicionada ao conjunto "existingRoots" de sub-árvores
 * @param existingRoots conjunto de sub-árvores existentes que receberá
 * os nós não existentes nesta.
 * @param childrenField campo do nó que aponta para os filhos do mesmo.
 * @param discriminatorField o campo que será utilizado para verificar
 * a igualdade entre dois nós da árvore.
 * @param processIncomingNode processador do nó que eh chamado antes do mesmo
 * ser adicionado na sub-árvore existente. (Este processador só é chamado
 * se for identificado um nó em alguma das sub-arvores incoming que nao tenha
 * na existing e é chamado exatamente antes do nó ser adicionado na sub-arvore
 * existente)
 */
function mergeTwoSubTrees(
  incomingRoots: any[],
  incomingRootsIdField: string = 'id',
  incomingRootsChildrenField: string = 'children',
  existingRoots: any[],
  existingRootsIdField: string = 'id',
  existingRootsChildrenField: string = 'children',
  processIncomingNode: (existingData: any, incomingData: any) => any = newData => newData,
  ): any[] {

  return doMergeTwoSubTrees(
    null,
    incomingRoots,
    incomingRootsIdField,
    incomingRootsChildrenField,
    null,
    existingRoots,
    existingRootsIdField,
    existingRootsChildrenField,
    0,
    processIncomingNode,
  );
}

/**
 * Converte uma árvore em uma estrutura flat ordenada.
 *
 * ex:
 *
 * 1
 *  1.1
 *  1.2
 *   1.2.1
 *   1.2.2
 *  1.3
 * 2
 *
 * será convertido em:
 *
 * 1
 * 1.1
 * 1.2
 * 1.2.1
 * 1.2.2
 * 1.3
 * 2
 *
 * @param roots coleção dos itens topos da árvore, aqueles que não possuem pais.
 * @param childrenField nome do campo do nó da árvore que aponta para os seus elementos filhos.
 */
function toFlatStructure(roots: any[], childrenField: string = 'children'): any[] {

  var flat: any[] = [];

  if (!roots || roots.length === 0) {
    return flat;
  }

  for (let root of roots) {

    const flatRepresentation = {
      ...root,
    };

    flatRepresentation[childrenField] = [];

    flat.push(flatRepresentation);

    const filhosFlat = toFlatStructure(root[childrenField]);

    flat = [...flat, ...filhosFlat];
  }

  return flat;
}

export { toTreeStructure, findInTree, getCascadedChildrenProperty,
  toFlatStructure, calcularHierarquias, findAllLeafs, mergeTwoSubTrees };