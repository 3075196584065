<header>

  <section class="content-header" *ngIf="hasCollections">
    <h1>Estes entrevistadores fizeram a diferença</h1>
    <p>
      Confira as maiores notas de desempenho
      <span (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false">
        <span class="tooltip-container" *ngIf="showTooltip">
          As notas de desempenho dos entrevistadores são baseadas na aprovação em auditoria, tempo médio de coleta e médias diárias, com pesos variáveis para cada fator.
        </span>
        <i class="fa-regular fa-circle-info"></i>
      </span>
    </p>
    <a (click)="navigate()">Ver todos os entrevistadores<i class="fa-solid fa-angle-right"></i></a>
  </section>

  <section class="content-operator" *ngIf="hasCollections">
    <ng-container *ngFor="let operator of podiumData;let index = index">
      <app-operator-card
        [operadorData]="podiumData[index]"
        [position]="index"
        [surveyId]="surveyId"
      ></app-operator-card>
    </ng-container>
  </section>

  <section class="content-no-collections" *ngIf="!hasCollections">
    <h3>A avaliação ainda não possui coletas</h3>
    <h4>As maiores notas de desempenho aparecerão aqui</h4>
    <a (click)="navigate()">Ver todos os entrevistadores<i class="fa-solid fa-angle-right"></i></a>
  </section>

</header>
