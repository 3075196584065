import { PessoaBasica } from 'app/entidade/pessoaBasica';

export class Cliente extends PessoaBasica {

  constructor() {
    super();
    this.pessoa.tipoPessoa = 'F';
  }

}
