import { Component, OnInit, OnDestroy } from "@angular/core";
import { ListagemGenerica } from "../../listagem/listagemGenerica";
import { Grupo } from "../grupo";
import { GrupoService } from "../servico/grupo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { Authority } from "app/infraestrutura/security/authority";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-grupo-listagem",
  templateUrl: "./grupo.listagem.component.html",
  styleUrls: ["./grupo.listagem.component.scss"],
})
export class GrupoListagemComponent
  extends ListagemGenerica<Grupo>
  implements OnDestroy, OnInit
{
  nomeAtributosTabela = ["nome", "qtdPermissoes", "qtdFuncionarios"];
  nomeColunasTabela = ["Id", "Nome", "Qtd. Permissões", "Qtd. Funcionários"];
  placeholder: string = "Ex.: administrador";
  initialSearchValue: string = "";

  /**
   * Permissões necessárias para realizar a remoção do item
   * da tabela
   */
  grupoRemovePermissions = [Authority.REMOVER_GRUPO];

  // tslint:disable-next-line: max-line-length
  constructor(
    protected securityService: SecurityService,
    public grupoService: GrupoService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: ModalService
  ) {
    super(securityService, "grupo", grupoService, router, modalService);
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("search"))
      this.initialSearchValue = this.route.snapshot.paramMap.get("search");
  }
}
