import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IComposedFilterSelect, IFilterSelect, IFilterSelectEvent } from "../../filter-select";

@Component({
  selector: "app-composed-check-select",
  templateUrl: "./composed-check-select.component.html",
  styleUrls: ["./composed-check-select.component.scss"],
})
export class ComposedCheckSelectComponent implements OnInit {
  @Output() changeFilter = new EventEmitter<IFilterSelectEvent>();
  @Input() composedOptions: IComposedFilterSelect[] = [];

  selectedOptions: IComposedFilterSelect[] = [];

  constructor() {}

  ngOnInit(): void {
    this.selectedOptions = this.composedOptions.map(composedOption => ({
      title: composedOption.title,
      data: []
    }));
  }

  /**
   * Verifica se a opção está selecionada, para mostrar visualmente
   * @param option Pode ser qualquer valor que esteja dentro do array de options;
   * @returns Se a opção estiver selecionada retorna true caso contrário false
   */
  hasSelected(option: IFilterSelect, groupIndex: number): boolean {
    return (
      this.selectedOptions[groupIndex]?.data.findIndex(
        ({ id }: IFilterSelect) => id === option.id
      ) !== -1);
  }

  /**
   * Seleciona um item dos listados do array, mantendo o estado dessa seleção, caso o item clicado ja estaja selecionado é feita um desseleção
   * @param option Pode ser qualquer valor que esteja dentro do array de options;
   */
  selectFilterOption(option: IFilterSelect, groupIndex: number) {
    const selectedGroup = this.selectedOptions[groupIndex];

    // Verifica se a opção está selecionada
    const isSelected = this.hasSelected(option, groupIndex);

    if (!isSelected) {
      // Se a opção não estiver selecionada, adiciona ao grupo selecionado
      if (!selectedGroup) {
        this.selectedOptions[groupIndex] = { title: this.composedOptions[groupIndex].title, data: [option] };
      } else {
        selectedGroup.data.push(option);
      }
    } else {
      // Se a opção estiver selecionada, remove do grupo selecionado
      const selectedIndex = selectedGroup.data.findIndex(({ id }: IFilterSelect) => id === option.id);
      selectedGroup.data.splice(selectedIndex, 1);
    }

    // Cria um array único com todos os itens selecionados
    const selectedItems: IFilterSelect[] = [];
    this.selectedOptions.forEach(group => {
      if (group) {
        selectedItems.push(...group.data);
      }
    });

    // Emite o array de IFilterSelect[]
    this.changeFilter.emit({
      clickedOption: option,
      currentState: selectedItems
    });
  }
}
