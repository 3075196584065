<div class="table">
  <div class="thead">
    <div class="hirer-th th">
      <h4>Contratantes</h4>
    </div>
    <div class="survey-th th">
      <h4>Pesquisas</h4>
    </div>
    <div class="license-th th">
      <h4>Tipo Licença</h4>
    </div>
    <div class="status-th th">
      <h4>Situação</h4>
    </div>
    <div class="edit-th th"></div>
  </div>
  <div class="tbody">
    <div *ngFor="let hirer of hirerList; let i = index" class="row">
      <div class="hirer-td td">
        <h4 appTooltipIfEllipsis="{{ hirer.nome }}">{{ hirer.nome }}</h4>
      </div>
      <div class="survey-td td">
        <h4
          [attr.data-tooltip]="
            hirer.contagem_pesquisas.concluidas +
            ' Concluídas \n' +
            hirer.contagem_pesquisas.rascunhos +
            ' Rascunhos'
          "
        >
          {{ hirer.contagem_pesquisas.total }}
        </h4>
      </div>
      <div class="license-td td">
        <h4>{{ hirer.tipo_licenca }}</h4>
      </div>
      <div class="status-td td">
        <div class="item-status-wrapper">
          <span
            class="status"
            [ngClass]="{ ativo: hirer.ativo, inativo: !hirer.ativo }"
          ></span>
          {{ hirer.ativo ? "Ativo" : "Inativo" }}
        </div>
      </div>

      <div class="edit-td td">
        <app-contratante-meatball
          [id]="hirer.schema_name"
          [status]="hirer.ativo"
          (handleItemClicked)="getClickOptionMeatball($event, hirer.ativo, i)"
        ></app-contratante-meatball>
      </div>
    </div>
  </div>
</div>

<app-loading-save *ngIf="isLoading"></app-loading-save>
