<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card-marcacao
    [icone]="'fa fa-picture-o'"
    [titulo]="'PERGUNTA DE FOTO'"
    [nomePlaceHolder]="'Título da pergunta de foto?'"
    [formGroup]="formGroup"
  ></app-pergunta-card-marcacao>

</div>