import {
  ISurveyResumoColetaResponse,
  ISurveyCollectionSummary,
} from "../../interfaces";

export const adaptCollectionSummaryRespToModel = function (
  resp: ISurveyResumoColetaResponse
): ISurveyCollectionSummary {
  return {
    id: resp.id,
    horaInicio: resp.hora_inicio,
    horaFim: resp.hora_fim,
    data: resp.data,
    situacao: resp.situacao,
    operador: resp.operador.nome,
    criterios: resp.criterios,
    localidade: resp.localidade.nome,
    pesquisa: resp.pesquisa?.titulo_curto || "",
  };
};
