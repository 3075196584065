import { Event } from "app/modulos/pesquisa-beta/eventbus";

/**
 * Modo de tela dos formulários das pesquisas.
 * - Editing: quando a tela encontra-se com uma modificação e precisa
 * ser resolvida em termos de itens obrigatórios
 * - Done: quado a tela encontra-se pronta para salvar, sem modificações ou
 * quando é cancelada essa modificação
 */
export enum FormMode {
    // o cadastro encontra-se em modificação
    Editing = 0x01,
    // o cadastro encontra-se pronto para salvar
    Done = 0x02
}

/**
 * Representa o evento que informa mudanças no modo da pesquisa.
 */
export class ModeChangeEvent implements Event<FormMode>{
    static TYPE = 'pesquisa.cadastro.modo';

    constructor(private payload: FormMode){}

    getType(): string {
        return ModeChangeEvent.TYPE;
    }

    getPayload(): FormMode {
        return this.payload;
    }
}
