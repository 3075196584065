import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { EventoInput } from './evento/eventoInput';
import { TipoEventoInput } from './evento/tipoEventoInput';

/**
 * Classe base de um componente que serve como Input de um ou vários
 * componentes. Todos os componentes de Input devem herdar esta classe
 */
@Directive()
export abstract class InputComponent implements OnInit, OnDestroy {

  /**
   * marcador para a exibição das mensagens
   * de validações do input
   */
  private showErrors: boolean = false;

  /**
   * Utilizado como auxiliar para agregar multiplos
   * controls[]
   */
  controls: { [key: string]: AbstractControl } = {};
  /**
   * Utilizado para manipular um unico control
   */
  control: AbstractControl;
  formGroup: UntypedFormGroup;

  // Emite eventos relacionados ao próprio input
  @Output() private inputUpdate: EventEmitter<EventoInput> = new EventEmitter<EventoInput>();

  // Publisher que notifica inputs aninhados
  nestedInputPublisher: Subject<EventoInput> = new ReplaySubject<EventoInput>();

  // receptor de eventos lancados pelo componente pai
  @Input() private parentEventPublisher: Subject<EventoInput>;
  parentEventSubscription: Subscription;

  ngOnInit(): void {
    this.initFormGroup();

    if (this.parentEventPublisher) {
      this.parentEventSubscription = this.parentEventPublisher.subscribe(this.handleParentEvent());
    }
  }

  ngOnDestroy(): void {
    if (this.parentEventSubscription) {
      this.parentEventSubscription.unsubscribe();
    }
  }

  /**
   * Lifecycle Hook executado durante o ngOnIit destinado à
   * inicialização do formGroup e controls do componente
   */
  initFormGroup() {

  }

  /**
   * Executa estratégias de tratamento para os eventos lançados
   * pelo componente pai
   */
  private handleParentEvent() {

    const strategyMap: Map<TipoEventoInput, Function> = new Map();
    strategyMap.set(TipoEventoInput.CHANGE, this.onParentChange);
    strategyMap.set(TipoEventoInput.DELETE, this.removeInput);
    strategyMap.set(TipoEventoInput.SHOW_ERRORS, this.setShowErrors);

    return (evento: EventoInput) => {
      const strategyToAplly = strategyMap.get(evento.tipo);
      strategyToAplly.call(this, evento.payload);
    };
  }

  /**
   * Lifecycle hook executado quando o componente pai
   * quer exibir ou esconder suas mensagens de validação
   */
  setShowErrors(newValue) {
    // console.log('[inputComponent] setShowErrors', newValue);

    this.showErrors = newValue;
    /**
     * repassando o evento para os filhos para que estes
     * também exibam ou escondam suas mensagens
     * de erro
     */
    const evento = new EventoInput(TipoEventoInput.SHOW_ERRORS, this.showErrors);
    this.nestedInputPublisher.next(evento);
  }

  /**
   * Executa estratégias de tratamento para os eventos lançados
   * por inputs aninhados
   */
  private handleNestedInputEvent() {

    const strategyMap: Map<TipoEventoInput, Function> = new Map();
    strategyMap.set(TipoEventoInput.CHANGE, this.onNestedInputChange);
    strategyMap.set(TipoEventoInput.DELETE, this.onNestedInputRemoveInput);

    return (evento: EventoInput) => {

      // console.log('[input.component] handling nested input event: ', evento);

      const strategyToAplly = strategyMap.get(evento.tipo);
      strategyToAplly.call(this, evento.payload);
    };
  }

  /**
   * Filecycle Hook executado quando um input aninhado
   * é modificado
   */
  onNestedInputChange(payload: any) {

  }

  /**
   * Filecycle Hook executado quando um input aninhado
   * solicita remoção
   */
  onNestedInputRemoveInput(payload: any) {

  }

  /**
   * Lifecycle Hook executado quando o componente pai solicita a
   * remoção do input ou de um filho do input
   */
  removeInput(payload: any) {

  }

  /**
   * Lifecycle Hook executado quando o componente pai alterou algum dado que pode
   * alterar o estado do input
   */
  onParentChange(payload: any) {

  }

  /**
   * Notifica o componente pai das alterações que ocorreram no input
   */
  notifyParent(evento: EventoInput) {
    this.inputUpdate.emit(evento);
  }

  /**
   * Notifica os inputs aninhados
   */
  notifyNestedInputs(evento: EventoInput) {
    this.nestedInputPublisher.next(evento);
  }

}
