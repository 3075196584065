import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ListagemGenerica } from "../../listagem/listagemGenerica";
import { Funcionario } from "../funcionario";
import { FuncionarioService } from "../servico/funcionario.service";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-funcionario-listagem",
  templateUrl: "./funcionario.listagem.component.html",
  styleUrls: ["./funcionario.listagem.component.scss"],
})
export class FuncionarioListagemComponent
  extends ListagemGenerica<Funcionario>
  implements OnDestroy, OnInit
{
  nomeAtributosTabela = ["nome", "matricula", "cpf"];
  nomeColunasTabela = ["Id", "Nome", "Matrícula", "CPF"];
  placeholder: string = "Ex.: joão";
  initialSearchValue: string = "";

  /**
   * Permissões necessárias para realizar a remoção do item
   * da tabela
   */
  funcionarioRemovePermissions = [Authority.REMOVER_FUNCIONARIO];

  constructor(
    protected securityService: SecurityService,
    public funcionarioService: FuncionarioService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "funcionario",
      funcionarioService,
      router,
      modalService
    );
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("search"))
      this.initialSearchValue = this.route.snapshot.paramMap.get("search");
  }
}
