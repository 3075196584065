import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IGeneralVisionPodium } from 'app/modulos/produtividade-beta/interfaces';

@Component({
  selector: 'app-podium',
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss']
})
export class PodiumComponent implements OnInit {
  @Input() podiumData: IGeneralVisionPodium[];
  surveyId: number;
  showTooltip: boolean = false;
  hasCollections: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params["surveyId"];
  }

  navigate() {
    this.router.navigate([`/produtividade-beta/visao-geral/${this.surveyId}/entrevistadores`]);
  }

}
