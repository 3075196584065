import { IFuncionarioEditPayload, IFuncionarioPayload, IPermissoes } from "../interfaces";

/**
 * Converte os dados do funcionário para o formato esperado pelo backend
 * @returns paylod de funcionário
 */
export function registerPayload(employeeData): IFuncionarioPayload {
  return {
    cargo: employeeData.role,
    contato: {
      email: employeeData.email,
    },
    fotoEnviada: employeeData.photo
      ? { arquivo: employeeData.photo }
      : null,
    pessoa: {
      cpf: employeeData.cpf,
      id: null,
      nome: employeeData.name,
    },
    usuario: {
      grupos: employeeData.groups,
      ativo: employeeData.status,
      id: null,
      login: employeeData.email,
      permissoes: updatePermissionsToPayload(employeeData.permissions)
    }
  }
}

/**
 * Converte os dados do funcionário para o formato esperado pelo backend
 * @returns paylod de funcionário
 */
export function updatePayload(employeeData, personAndUserIds): IFuncionarioEditPayload {
  return {
    cargo: employeeData.role,
    contato: {
      email: employeeData.email,
    },
    fotoEnviada: employeeData.photo
      ? { arquivo: employeeData.photo }
      : null,
    id: employeeData.id,
    pessoa: {
      cpf: employeeData.cpf,
      id: personAndUserIds.personId,
      nome: employeeData.name,
    },
    usuario: {
      grupos: employeeData.groups,
      ativo: employeeData.status,
      id: personAndUserIds.userId,
      login: employeeData.email,
      permissoes: updatePermissionsToPayload(employeeData.permissions)
    }
  }
}

/**
 * Remove os atributos que são utilizados somente para controle de seleção no front-end
 * Esses atributos não são esperados pelo back-end.
 * @param permissions
 */
function updatePermissionsToPayload(permissions: IPermissoes[]): IPermissoes[] {
  return permissions.map(p => {
    // Cria uma cópia profunda do objeto usando Object.assign (impede de alterar o array original)
    const updatedPermission: IPermissoes = Object.assign({}, p);

    // Remove os atributos de controle
    delete updatedPermission.disabled;
    delete updatedPermission.groupsIds;

    return updatedPermission;
  });
}
