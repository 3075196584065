import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-card',
  templateUrl: './global-card.component.html',
  styleUrls: ['./global-card.component.scss']
})
export class GlobalCardComponent implements OnInit {

  @Input() link: string;
  @Input() title: string;
  @Input() firstColumnData: number;
  @Input() firstColumnTitle: string;
  @Input() activatedColumnData: number;
  @Input() activatedColumnTitle: string;
  @Input() inactivatedColumnData: number;
  @Input() inactivatedColumnTitle: string;
  @Input('show-add-button') showAddButton: boolean;

  ngOnInit() {
    this.showAddButton = this.showAddButton !== undefined;
  }

}
