<div class="sb-header">
  <div class="sb-header-info flex flex-center">
    <div
      class="localidades-place-icon"
      alt=""
    ></div>
    <div>
      <p>Local da avaliação</p>
      <h4>{{ location }}</h4>
    </div>
  </div>

  <div class="sb-header-info border-template">
    <p *ngIf="type == 'localidade'">Amostra Presencial</p>
    <div class="sb-header-info flex flex-center" *ngIf="type == 'vinculo'">
      <div
        class="localidades-people-icon"
        alt=""
      ></div>
      <div>
        <p>Entrevistadores Vinculados</p>
        <h4>
          {{
          operatorsQuantity > 9 ? operatorsQuantity : "0" + operatorsQuantity
          }}
        </h4>
      </div>
    </div>
    <h4>{{ samples }}</h4>
  </div>

  <app-sample-progress *ngIf="type == 'localidade'" [progress]="progress" [samples]="samples">
  </app-sample-progress>
  <div class="sb-header-info flex flex-center" *ngIf="type == 'vinculo'">
    <div
      [ngClass]="getAmountOfLocationsWithValue() === locationsWithOperators ? 'localidades-square-star-icon' : 'localidades-square-icon'"
    ></div>
    <div>
      <p>Localidades com entrevistadores</p>
      <h4>{{ getStringTemplateForDistribution() }}</h4>
    </div>
  </div>

  <div class="sb-header-v" *ngIf="type == 'vinculo'">
    <app-icon-button title="Ver distribuição" [disabled]="locationsWithOperators === 0"
      [distributionMode]="'DISTRIBUTION'" (click)="handleSeeDistribution()">
    </app-icon-button>
  </div>
</div>
<div *ngIf="seeDistribution">
  <app-distribution [survey]="survey" [onCloseModal]="closeDistribuitionModal" [locations]="distribuitionsLocations"
    [operators]="operators">
  </app-distribution>
</div>
<div class="sb-body" *ngIf="type == 'localidade'">
  <div class="sb-body-i">
    <app-toggle [checked]="toggleChecked" (isChecked)="handleToggleCheckClick($event)">
    </app-toggle>
  </div>
  <div class="sb-body-ii">
    <div class="sb-body-ii-search">
      <input type="text" name="search" placeholder="Busque uma localidade pelo nome" (ngModelChange)="handleTextChange($event)"
        [ngModel]="filterText" />
      <i class="fa fa-search"></i>
    </div>
    <div class="sb-body-ii-e">
      <i class="fa-regular fa-circle-info fa-lg" alt="Desabilite a(s) localidade(s) aonde não serão realizadas coletas"></i>
      <span class="info-text">Aplicamos regras de arrendodamento para as Amostras numéricas, uma vez que, não é
        possível ter coletas com casas decimais, ex.: 13,4 ou 9,5</span>
    </div>
    <div class="sb-body-ii-sort" (click)="handleSortAZ()">
      <img src="assets/images/filterA-Z.svg" alt="" />
    </div>
    <app-new-select (handleFilter)="handleStatusFilterChange($event)" (output)="handleSortActive($event)">
    </app-new-select>
  </div>
</div>
<div class="sb-body flex-end" *ngIf="type == 'vinculo'">
  <div class="sb-body-ii-search">
    <input type="text" name="search" placeholder="Busque uma localidade pelo nome" (ngModelChange)="handleTextChange($event)"
      [ngModel]="filterText" />
    <i class="fa fa-search"></i>
  </div>
</div>
<app-sublocal
  [canUpdate]="updateSubLocations"
  [toggleRef]="toggleChecked"
  [textFilter]="filterText"
  [statusFilter]="filterStatus"
  [data]="locations"
  [sortAZ]="sortAZ"
  [operators]="operators"
  [progress]="progress"
  (changeSelectedItem)="handleChange($event)"
  (handleToggleChange)="handleToggleCheckClickBySubLocation($event)"
  (onCanNotSaveButton)="onCanNotSaveButtonNew($event)"
  >
</app-sublocal>
