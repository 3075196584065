import { HttpClientModule } from "@angular/common/http";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ComplexComponentFactoryComponent } from "app/infraestrutura/componente/complex-component-factory/complex-component-factory.component";
import { RefreshTokenMapper } from "app/infraestrutura/security/refreshTokenMapper";
import { AlreadyAuthenticatedAuthorizationGuard } from "app/infraestrutura/security/routGuard/alreadyAuthenticatedAuthorizationGuard";
import { AuthenticationGuardService } from "app/infraestrutura/security/routGuard/authenticationGuardService";
import { JWTService } from "app/infraestrutura/security/service/jwtService";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { AreaInteresseCadastroComponent } from "app/modulos/area-interesse/cadastro/area-interesse.cadastro.component";
import { AreaInteresseListagemComponent } from "app/modulos/area-interesse/listagem/area-interesse.listagem.component";
import { AudioPlayerComponent } from "app/modulos/audio-player/audio-player.component";
import { EntrevistaListagemComponent } from "app/modulos/auditoria/entrevista-listagem/entrevista-listagem.component";
import { RespostaListagemTabelaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta-pergunta-listagem-tabela/resposta-pergunta-listagem-tabela.component";
import { RespostaListagemComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta-pergunta-listagem.component";
import { ContainerRespostaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/container-resposta.component";
import { RespostaSelecaoMultiplaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/selecao-multipla/selecao-multipla.component";
import { RespostaSelecaoUnicaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/selecao-unica/selecao-unica.component";
import { ClienteCadastroComponent } from "app/modulos/cliente/cadastro/cliente.cadastro.component";
import { ClienteListagemComponent } from "app/modulos/cliente/listagem/cliente.listagem.component";
import { ClienteService } from "app/modulos/cliente/servico/cliente.service";
import { FuncionarioCadastroComponent } from "app/modulos/funcionario/cadastro/funcionario.cadastro.component";
import { FuncionarioListagemComponent } from "app/modulos/funcionario/listagem/funcionario.listagem.component";
import { FuncionarioService } from "app/modulos/funcionario/servico/funcionario.service";
import { GrupoCadastroComponent } from "app/modulos/grupo/cadastro/grupo.cadastro.component";
import { GrupoListagemComponent } from "app/modulos/grupo/listagem/grupo.listagem.component";
import { LocalidadeCadastroComponent } from "app/modulos/localidade/cadastro/localidade.cadastro.component";
import { LocalidadeListagemComponent } from "app/modulos/localidade/listagem/localidade.listagem.component";
import { LoginPageComponent } from "app/modulos/login-page/login-page.component";
import { OperadorCadastroComponent } from "app/modulos/operador/cadastro/operador.cadastro.component";
import { OperadorListagemComponent } from "app/modulos/operador/listagem/operador.listagem.component";
import { OperadorService } from "app/modulos/operador/servico/operador.service";
import { CalendarioComponent } from "app/modulos/pesquisa-old/cadastro/inputs/calendarioInput/calendario.component";
import { ClienteCadastroInputComponent } from "app/modulos/pesquisa-old/cadastro/inputs/clienteInput/cliente.cadastro.input.component";
import { ComplexInputFactoryComponent } from "app/modulos/pesquisa-old/cadastro/inputs/complex-input/complex.input.factory.component";
import { TipoPerguntaInputComponent } from "app/modulos/pesquisa-old/cadastro/inputs/tipo-pergunta-input/tipo-pergunta-input.component";
import { CaracteristicasComponent } from "app/modulos/pesquisa-old/cadastro/passos/caracteristicas/caracteristicas.component";
import { MarcacoesComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/marcacoes.component";
import { QuestionarioSeletorAdicaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/questionario-seletor-adicao/questionario-seletor-adicao.component";
import { PerguntaDataContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-data-content/pergunta.data.content.component";
import { PerguntaFotoContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-foto-content/pergunta.foto.content.component";
import { PerguntaHorarioContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-horario-content/pergunta.horario.content.component";
import { PerguntaVideoContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-video-content/pergunta-video-content.component";
import { AlternativaTipadaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/alternativa/alternativa-tipada/alternativa-tipada.component";
import { AlternativaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/alternativa/alternativa.component";
import { TipoRespostaInputComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/alternativa/tipo-resposta-input/tipo-resposta-input.component";
import { DataComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/data/data.component";
import { EscalaNumericaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/escala-numerica/escala-numerica.component";
import { FotoComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/foto/foto.component";
import { AlternativasComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/grade/componentes/alternativas/alternativas.component";
import { TituloGradeComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/grade/componentes/titulos-grade/titulo-grade/titulo-grade.component";
import { GradeMultiplaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/grade/grade-multipla/grade-multipla.component";
import { GradeUnicaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/grade/grade-unica/grade-unica.component";
import { HorarioComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/horario/horario.component";
import { SelecaoMultiplaOrdemCitacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/selecao/selecao-multipla-ordem-citacao/selecao-multipla-ordem-citacao.component";
import { SelecaoUnicaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/selecao/selecao-unica/selecao-unica.component";
import { VideoComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/video/video.component";
import { QuestionarioComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/questionario.component";
import { PesquisaCadastroComponent } from "app/modulos/pesquisa-old/cadastro/pesquisa.cadastro.component";
import { PesquisaSelectComponent } from "app/modulos/pesquisa-old/pesquisa-select/pesquisa-select.component";
import { ProdutividadeComponent } from "app/modulos/produtividade/produtividade.component";
import { ProdutividadeService } from "app/modulos/produtividade/service/produtividade.service";
import { RequestErrorHandler } from "app/servico/requestService/requestErrorHandler";
import { ChartComponent } from "app/util/componente/chart/chart.component";
import { FotoUploaderComponent } from "app/util/componente/foto-uploader/foto-uploader.component";
import { InputPasswordComponent } from "app/util/componente/input-password/input-password.component";
import { LoadingComponent } from "app/util/componente/loading/loading.component";
import { NewFotoUploaderComponent } from "app/util/componente/new-foto-uploader/new-foto-uploader.component";
import { CustomMatPaginatorIntl } from "app/util/componente/paginacao/customMatPaginatorIntl";
import { PaginacaoComponent } from "app/util/componente/paginacao/paginacao.component";
import { PromptModalComponent } from "app/util/componente/prompt-modal/prompt-modal.component";
import { TabelaFiltravelComponent } from "app/util/componente/tabela/tabela-filtravel/tabela.filtravel.component";
import { MascaraCelularDirective } from "app/util/mascara/mascara.celular.directive";
import { MascaraCepDirective } from "app/util/mascara/mascara.cep.directive";
import { MascaraCnpjDirective } from "app/util/mascara/mascara.cnpj.directive";
import { MascaraCpfDirective } from "app/util/mascara/mascara.cpf.directive";
import { MascaraDataDirective } from "app/util/mascara/mascara.data.directive";
import { MascaraLatLongDirective } from "app/util/mascara/mascara.lat.long.directive";
import { MascaraNumeroDirective } from "app/util/mascara/mascara.numero.directive";
import { MascaraRgDirective } from "app/util/mascara/mascara.rg.directive";
import { MascaraTelefoneDirective } from "app/util/mascara/mascara.telefone.directive";
import { MascaraMinutoSegundo } from "app/util/mascara/mascaraMinutoSegundo";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { NgChartjsModule } from "ng-chartjs";
import { DpDatePickerModule } from "ng2-date-picker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuditCollectionMap } from "./modulos/auditoria-beta/components/audit-collection-map/audit-collection-map.component";
import { ResultadosComponent } from "./modulos/resultados/resultados.component";
// import { PesquisaListagemComponent } from "app/modulos/pesquisa-old/listagem/pesquisa.listagem.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { StoreModule } from "@ngrx/store";
import { AuditoriaComponent } from "app/modulos/auditoria/auditoria.component";
import { RejeicaoDialogComponent } from "app/modulos/auditoria/entrevista-listagem/rejeicao-dialog/rejeicao-dialog.component";
import { ColunaRespostaResumoComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta-pergunta-listagem-tabela/coluna-resposta-resumo/coluna-resposta-resumo.component";
import { RespostaVisualizacaoDialogComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta-visualizacao-dialog/resposta-visualizacao-dialog.component";
import { RespostaDataComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/data/data.component";
import { RespostaEscalaNumericaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/escala-numerica/escala-numerica.component";
import { RespostaFotoComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/foto/foto.component";
import { RespostaGradeMultiplaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/grade-multipla/grade-multipla.component";
import { RespostaGradeUnicaComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/grade-unica/grade-unica.component";
import { RespostaHorarioComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/horario/horario.component";
import { RespostaMultiplaOrdemCitacaoComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/multipla-ordem-citacao/multipla-ordem-citacao.component";
import { RespostaVideoComponent } from "app/modulos/auditoria/resposta-pergunta-listagem/resposta/video/video.component";
import { PerguntaRespostaMultiSelectorComponent } from "app/modulos/auditoria/respostas-abertas/pergunta-resposta-multi-selector/pergunta-resposta-multi-selector.component";
import { PerguntaSelectorComponent } from "app/modulos/auditoria/respostas-abertas/pergunta-selector/pergunta-selector.component";
import { RespostaEdicaoComponent } from "app/modulos/auditoria/respostas-abertas/resposta-edicao/resposta-edicao.component";
import { RespostasAbertasComponent } from "app/modulos/auditoria/respostas-abertas/respostas-abertas.component";
import { AlternativaTipadaMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/alternativa-tipada-marcacao/alternativa-tipada-marcacao.component";
import { PerguntaCardMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/pergunta-card-marcacao/pergunta-card-marcacao.component";
import { SecaoMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/secao-marcacao/secao-marcacao.component";
import { SelecaoUnicaMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/selecao-unica/selecao-unica-marcacao/selecao-unica-marcacao.component";
import { PerguntaEscalaNumericaContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-escala-numerica-content/pergunta-escala-numerica-content.component";
import { PerguntaGradeMultiplaContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-grade-multipla-content/pergunta-grade-multipla-content.component";
import { PerguntaGradeUnicaContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-grade-unica-content/pergunta-grade-unica-content.component";
import { PerguntaSelecaoMultiplaContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-selecao-multipla-content/pergunta-selecao-multipla-content.component";
import { PerguntaSelecaoMultiplaOrdemCitacaoContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-selecao-multipla-ordem-citacao-content/pergunta-selecao-multipla-ordem-citacao-content.component";
import { PerguntaSelecaoUnicaContentComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/pergunta-selecao-unica-content/pergunta-selecao-unica-content.component";
import { PerguntaCardComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/pergunta-card/pergunta-card.component";
import { SelecaoMultiplaComponent } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/selecao/selecao-multipla/selecao-multipla.component";
import { SelecaoLocalidadeComponent } from "app/modulos/pesquisa-old/cadastro/passos/vinculos/selecao-localidade/selecao-localidade.component";
import { VinculosComponent } from "app/modulos/pesquisa-old/cadastro/passos/vinculos/vinculos.component";
import { ModalGenericoComponent } from "app/util/componente/modal-generico/modal-generico.component";

import { CotaPercentualComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/cota/cota-percentual/cota-percentual.component";
import { EscalaNumericaMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/escala-numerica/escala-numerica-marcacao/escala-numerica-marcacao.component";
import { SelecaoMultiplaMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/selecao-multipla/selecao-multipla-marcacao/selecao-multipla-marcacao.component";
import { SelecaoMultiplaOrdemCitacaoMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/selecao-multipla/selecao-multipla-ordem-citacao-marcacao/selecao-multipla-ordem-citacao-marcacao.component";
import { SelecaoOperadorComponent } from "app/modulos/pesquisa-old/cadastro/passos/vinculos/selecao-operador/selecao-operador.component";
import { VinculosOperadoresComponent } from "app/modulos/pesquisa-old/cadastro/passos/vinculos/vinculos-operadores/vinculos-operadores.component";
import { MascaraInscricaoEstadualDirective } from "app/util/mascara/mascara-inscricao-estadual.directive";
import { MascaraPorcentagemDirective } from "app/util/mascara/mascaraPorcentagem";
//
import { ClienteConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/clienteDeactivateQuard";
import { FuncionarioConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/funcionarioDeactiveteGuard";
import { GrupoConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/grupoDeactivateQuard";
import { LocalidadeConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/LocalidadeDeactivateQuard";
import { OperadorConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/operadorDeactivateQuard";
import { MascaraLimiteCaracteresDirective } from "app/util/mascara/mascara.limite.caracteres.directive";
import { MascaraLocalidadeNomeDirective } from "app/util/mascara/mascara.localidade.nome.directive";
import { MascaraPrimeiroDigitoSemEspaco } from "app/util/mascara/mascara.primeiro.digito.sem.espaco.directive";
import { MascaraRaioDirective } from "app/util/mascara/mascara.raio.directive";

import { InstanciaConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/instanciaDeactivateQuard";
import { RespostaPerguntaConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/respostaPerguntaDeactivateGuard";

import { AuditoriaDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/auditoriaDeactivateQuard";
import { PesquisaConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/pesquisaDeactivateGuard";
import { AdministradorComponentBeta } from "app/modulos/administrador-beta/administrador.component";
import { DownloadModalComponent } from "app/modulos/auditoria/entrevista-listagem/download-modal/download-modal.component";
import { RespostasFechadasComponent } from "app/modulos/auditoria/respostas-fechadas/respostas-fechadas.component";
import { ManagementService } from "app/modulos/management/instance.service";
import { InstanciaCadastroComponent } from "app/modulos/management/instancia-cadastro/instancia-cadastro.component";
import { ManagementComponent } from "app/modulos/management/management.component";
import { DataMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/data/data-marcacao/data-marcacao.component";
import { FotoMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/foto/foto-marcacao/foto-marcacao.component";
import { HorarioMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/horario/horario-marcacao/horario-marcacao.component";
import { MarcacaoPerguntaConteudoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/marcacao-pergunta-conteudo/marcacao-pergunta-conteudo.component";
import { VideoMarcacaoComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/video/video-marcacao/video-marcacao.component";
import { EntrevistasDesconformidadeModalComponent } from "app/modulos/produtividade/entrevistas-desconformidade/entrevistas-desconformidade-modal/entrevistas-desconformidade-modal.component";
import { EntrevistasDesconformidadeTableComponent } from "app/modulos/produtividade/entrevistas-desconformidade/entrevistas-desconformidade-table/entrevistas-desconformidade-table.component";
import { EntrevistasDesconformidadeService } from "app/modulos/produtividade/entrevistas-desconformidade/entrevistas-desconformidade.service";
import { DisableControlDirective } from "app/util/misc/disableControlDirective";
import { SafeHtmlPipe } from "app/util/pipe/safe-html.pipe";
// import { PesquisaTabelaFiltravelComponent } from "app/modulos/pesquisa-old/listagem/pesquisa-tabela-filtravel/pesquisa-tabela-filtravel.component";
import { PesquisaDependencyConflictComponent } from "app/modulos/pesquisa-old/cadastro/messages/pesquisa-dependency-conflict/pesquisa-dependency-conflict.component";
import { GerenciadorCotasComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/gerenciador-cotas.component";
import { LocalidadeSpecificationComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/localidades/localidade/localidade-specification/localidade-specification.component";
import { LocalidadeComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/localidades/localidade/localidade.component";
import { LocalidadesWrapperComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/localidades/localidades-wrapper/localidades-wrapper.component";
import { OperadorComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/operadores/operador/operador.component";
import { PerguntaComponent } from "app/modulos/pesquisa-old/cadastro/passos/marcacoes/componentes/pergunta/pergunta.component";
import { FiltrarEOrdenarPorCota } from "app/modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/filtrarEOrdenarPorCotaPipe";
import { AlteracaoSenhaComponent } from "app/modulos/recuperar-senha/alteracao/alteracao-senha.component";
import { RecuperarSenhaService } from "app/modulos/recuperar-senha/recuperarSenhaService";
import { SolicitacaoTrocaSenhaComponent } from "app/modulos/recuperar-senha/solicitacao/solicitacao-troca-senha.component";
import { EnumToArrayPipe } from "app/util/pipe/enumToArrayPipe";

import { OperadorCardWrapperComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/operador-card-wrapper/operador-card-wrapper.component";
import { SeletorOperadorVinculadoComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/operadores/seletor-operador-vinculado/seletor-operador-vinculado.component";
import { AjusteCotaModalComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/perfilCota/ajuste/ajuste.cota.modal.component";
import { PerfilCaracteristicaComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/perfilCota/cardPerfil/caracteristicas/perfil.caracteristica.component";
import { PerfilCardComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/perfilCota/cardPerfil/perfil.card.component";
import { SeletorPerfilComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/perfilCota/seletorPerfil/seletor.perfil.component";
import { TransferidorCotaModalComponent } from "app/modulos/pesquisa-old/cadastro/passos/gerenciador-cotas/perfilCota/transferidor-cota-modal/transferidor-cota-modal.component";
import { FilterableSelectComponent } from "app/util/componente/filterable-select/filterable-select.component";
import { SortByPipe } from "app/util/pipe/sortByPipe";

// novas páginas
import { HeaderStepComponent } from "./componentes/header-step/header-step.component";
import { DashboardBetaComponent } from "./modulos/dashboard-beta/dashboard.component";
import { PesquisasCadastroComponent } from "./modulos/pesquisa-beta/cadastro/pesquisas-cadastro.component";
import { PesquisasComponent } from "./modulos/pesquisa-beta/listagem/pesquisas.component";

// novos components
import { NgxEchartsModule } from "ngx-echarts";
import { AnswerItemComponent } from "./componentes/answer-item/answer-item.component";
import { BannerCardComponent } from "./componentes/banner-card/banner-card.component";
import { BreadcrumbComponent } from "./componentes/breadcrumb/breadcrumb.component";
import { CalendarDatesComponent } from "./componentes/calendar/calendar-dates/calendar-dates.component";
import { CalendarInputsComponent } from "./componentes/calendar/calendar-inputs/calendar-inputs.components";
import { CalendarComponent } from "./componentes/calendar/calendar.component";
import { CheckboxComponent } from "./componentes/checkbox/checkbox.component";
import { ChildrenLocationOperatorComponent } from "./componentes/children-location-operator/children-location.-operator.component";
import { ChildrenLocationComponent } from "./componentes/children-location/children-location.component";
import { CreateOperatorComponent } from "./componentes/create-operator/create-operator.component";
import { NewFilterableSelect } from "./componentes/filterable-select/filterable-select-component";
import { GlobalCardComponent } from "./componentes/global-card/global-card.component";
import { GradeInputComponent } from "./componentes/grade-input/grade-input.component";
import { HeaderProfileComponent } from "./componentes/header-profile/header-profile.component";
import { HeaderComponent } from "./componentes/header/header.component";
import { IconButtonComponent } from "./componentes/icon-button/icon-button.component";
import { KebabParaPesquisaComponent } from "./componentes/kebab-para-pesquisa/kebab-para-pesquisa.component";
import { LocationsListComponent } from "./componentes/locations-list/locationslist.component";
import { MolduraCredenciaisComponent } from "./componentes/moldura-telas-de-acesso/moldura-telas-de-acesso.component";
import { PesquisaComponent } from "./componentes/pesquisa/pesquisa.component";
import { RadioSelectComponent } from "./componentes/radio-select/radio-select.component";
import { RadioComponent } from "./componentes/radio/radio.component";
import { SInputComponent } from "./componentes/s-input/s-input.component";
import { SampleProgressComponent } from "./componentes/sampleProgress/sampleProgress.component";
import { SectionListComponent } from "./componentes/section-list/section-list.component";
import { SeeDistribution } from "./componentes/see-distributions/see-distributions.component";
import { SelectOperator } from "./componentes/select-operator/select-operator.component";
import { NewSelect } from "./componentes/select/new-select-component";
import { SidebarComponent } from "./componentes/sidebar/sidebar.component";
import { SublocalComponent } from "./componentes/sublocal/sublocal-component";
import { SystemSearchComponent } from "./componentes/system-search/system-search.component";
import { ToggleComponent } from "./componentes/toggle/toggle.component";
import { TooltipComponent } from "./componentes/tooltip/tooltip.component";
import { PesquisasConclusaoComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-conclusao/pesquisas-conclusao.component";
import { PesquisasConfiguracoesComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-configuracoes/pesquisas-configuracoes.component";
import { PesquisasLocalidadesComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-localidades/pesquisas-localidades.component";
import { EditarPerguntaComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-logica/editar-pergunta/editar-pergunta.component";
import { PesquisasLogicaComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-logica/pesquisas-logica.component";
import { PesquisasQuestionarioSecoesCadastroComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-cadastro/pesquisas-questionario-secoes-cadastro.component";
import { PesquisasQuestionarioSecoesComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-listagem/pesquisas-questionario-secoes-listagem.component";
import { ListagemDeSecoesComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-listagem/secao-listagem/secao-listagem.component";
import { CadastroPerguntaComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.component";
import { PesquisasQuestionarioComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario.component";
import { PesquisasVinculosComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-vinculos/pesquisas-vinculos.component";
import { ResultsChartCardComponent } from "./modulos/resultados/components/results-chart-card/results-chart-card.component";
import { SearchComponent } from "./modulos/search/search.component";
import { RouterComponent } from "./router/router.component";

// svg
import { Step1SVGComponent } from "./svg/step1-image/step1.component";

// barramento de eventos de aplicação
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import {
  SortSectionElemItemPipe,
  SortSectionElemPipe,
} from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-listagem/secao-listagem/secao-listagem.pipe";
import { NotificatorModule } from "./notificador/notificator.module";

// Localidades
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { MatDialogModule } from "@angular/material/dialog";
import { NgxMaskModule } from "ngx-mask";
import { BehaviorSubject } from 'rxjs';
import { BaseHrefGenerator, CURRENT_HIRER, ROUTER_HIRER } from './app.config';
import { ButtonComponent } from "./componentes/button/button.component";
import { ChangePasswordComponent } from "./componentes/change-password/change-password.component";
import { CheckSelectComponent } from "./componentes/filter-select/components/check-select/check-select.component";
import { ListSelectComponent } from "./componentes/filter-select/components/list-select/list-select.component";
import { SelectFilterRadioComponent } from "./componentes/filter-select/components/radio-select/radio-select.component";
import { HeaderDateSelectComponent } from "./componentes/filter-select/components/range-date/header-date-select/header-date-select.component";
import { RangeDateComponent } from "./componentes/filter-select/components/range-date/range-date.component";
import { FilterSelectComponent } from "./componentes/filter-select/filter-select.component";
import { InfoCardComponent } from "./componentes/info-card/info-card.component";
import { LeafletMapBetaComponent } from "./componentes/leaflet-map-beta/leaflet-map-beta.component";
import { LoadingSaveComponent } from "./componentes/loading-save/loading-save.component";
import { ModalSelectOptionsComponent } from "./componentes/modal-select-options/modal-select-options.component";
import { ModalComponent } from "./componentes/modal/modal.component";
import { PaginationComponent } from "./componentes/pagination/pagination.component";
import { StatusSelectComponent } from "./componentes/status-select/status-select.component";
import { TooltipTextComponent } from "./componentes/tooltip-text/tooltip-text.component";
import { EmailResetComponent } from './modulos/administrador-beta/components/email-reset/email-reset.component';
import { PasswordResetComponent } from './modulos/administrador-beta/components/password-reset/password-reset.component';
import { AuditAudioPlayerComponent } from "./modulos/auditoria-beta/components/audit-audio-player/audit-audio-player.component";
import { AuditCollectionsMeatballComponent } from "./modulos/auditoria-beta/components/audit-collections-meatball/audit-collections-meatball.component";
import { AuditJustificationModalComponent } from "./modulos/auditoria-beta/components/audit-justification-modal/audit-justification-modal.component";
import { AuditVerdictComponent } from "./modulos/auditoria-beta/components/audit-verdict/audit-verdict.component";
import { AuditedCollectionsHeaderComponent } from "./modulos/auditoria-beta/components/audited-collections-header/audited-collections-header.component";
import { InputSearchAuditoriaComponent } from "./modulos/auditoria-beta/components/input-search/input-search.component";
import { SelectableDropdownComponent } from "./modulos/auditoria-beta/components/selectable-dropdown/selectable-dropdown.component";
import { AuditDetailsComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/audit-details.component";
import { AuditCollectionsSectionComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/listagem-coletas/audit-collections-section.component";
import { AuditCollectionsListComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/listagem-coletas/components/audit-collections-list/audit-collections-list.component";
import { OpenAnswersComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/open-answers/open-answers.component";
import { StepOneComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/open-answers/step-one/step-one.component";
import { StepTwoComponent } from './modulos/auditoria-beta/pages/detalhes-auditoria/open-answers/step-two/step-two.component';
import { AuditOverviewComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/overview/audit-overview.component";
import { AuditProgressComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/overview/components/audit-progress/audit-progress.component";
import { AuditSurveyDataComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/overview/components/audit-survey-data/audit-survey-data.component";
import { AuditPieChartComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/overview/components/chart/pie/audit-pie-chart-component";
import { SurveyMapComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/survey-map/survey-map.component";
import { AuditSurveyMapTooltipComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/survey-map/tooltip-coletas/tooltip-coletas.component";
import { AuditListingComponent } from "./modulos/auditoria-beta/pages/listagem-pesquisas/audit-listing.component";
import { AuditSurveyList } from "./modulos/auditoria-beta/pages/listagem-pesquisas/components/audit-survey-list/audit-survey-list.component";
import { QuestionnaireAnswerResetComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/components/answer-reset/answer-reset.component";
import { QuestionnaireBadgeComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/components/badge/badge.component.";
import { DateComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/date/date.component";
import { AlternativaAbertaNumeroComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/generic-response-selection/alternatives/abertaNumero/aberta-numero.component";
import { AlternativaAbertaTextoComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/generic-response-selection/alternatives/abertaTexto/aberta-texto.component";
import { AlternativaFechadaComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/generic-response-selection/alternatives/fechada/fechada.component";
import { GenericResponseQuestionComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/generic-response-selection/generic-response.component";
import { GridComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/grid/grid.component";
import { PerguntaHorarioComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/horario/pergunta-horario.component";
import { NumericScaleQuestionComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/numeric-scale-question/numeric-scale-question.component";
import { PhotoComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/photo/photo.component";
import { QuestionsComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/questions.component";
import { VideoQuestionComponent } from "./modulos/auditoria-beta/pages/questionario/perguntas/video/video.component";
import { QuestionnaireComponent } from "./modulos/auditoria-beta/pages/questionario/questionnaire.component";
import { EntrevistaListagemTabelaComponent } from "./modulos/auditoria/entrevista-listagem/entrevista-listagem-tabela/entrevista-listagem-tabela.component";
import { LocalidadesMapaComponent } from "./modulos/auditoria/entrevista-listagem/localidades-mapa/localidades-mapa.component";
import { LocalidadesModalComponent } from "./modulos/auditoria/entrevista-listagem/localidades-modal/localidades-modal.component";
import { CadastroLGPDComponent } from './modulos/cadastro-lgpd/cadastro-lgpd.component';
import { ListagemClienteComponent } from './modulos/cliente-beta/listagem-cliente/listagem-cliente.component';
import { TableListClientComponent } from './modulos/cliente-beta/listagem-cliente/table-list-client/table-list-client.component';
import { CadastroContratanteComponent } from "./modulos/contratante-beta/cadastro-contratante/cadastro-contratante.component";
import { AdminInformationComponent } from "./modulos/contratante-beta/cadastro-contratante/components/admin-information/admin-information.component";
import { BasicInformationComponent } from "./modulos/contratante-beta/cadastro-contratante/components/basic-information/basic-information.component";
import { ChangeAdminComponent } from "./modulos/contratante-beta/cadastro-contratante/components/change-admin/change-admin.component";
import { CustomizationComponent } from "./modulos/contratante-beta/cadastro-contratante/components/customization/customization.component";
import { ListagemContratanteComponent } from "./modulos/contratante-beta/listagem-contratante/listagem-contratante.component";
import { TabelaContratanteComponent } from "./modulos/contratante-beta/listagem-contratante/tabela-contratante/tabela-contratante.component";
import { ListReadComponent } from "./modulos/localidade-beta/list-read/list-read.component";
import { ListReadService } from "./modulos/localidade-beta/list-read/list-read.service";
import { LocalidadeTableHeaderComponent } from "./modulos/localidade-beta/list-read/localidade-table-header/localidade-table-header.component";
import { LocalidadeTablePaginationComponent } from "./modulos/localidade-beta/list-read/localidade-table-pagination/localidade-table-pagination.component";
import { LocalidadeTableComponent } from "./modulos/localidade-beta/list-read/localidade-table/localidade-table.component";
import { LocalidadeListagemBetaComponent } from "./modulos/localidade-beta/listagem/localidade-listagem-beta.component";
import { LeafletMapComponent } from "./modulos/localidade-beta/modal-cread-update/leaflet-map/leaflet-map.component";
import { LocalidadesListComponent } from "./modulos/localidade-beta/modal-cread-update/localidades-list/localidades-list.component";
import { ManualFormComponent } from "./modulos/localidade-beta/modal-cread-update/manual-form/manual-form.component";
import { ModalCreadUpdateComponent } from "./modulos/localidade-beta/modal-cread-update/modal-cread-update.component";
import { ModalCreadUpdateService } from "./modulos/localidade-beta/modal-cread-update/modal-create-update.service";
import { NominatimFormComponent } from "./modulos/localidade-beta/modal-cread-update/nominatim-form/nominatim-form.component";
import { ModalOrdenacaoQuestionarioComponent } from "./modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-listagem/modal-ordenacao-questionario/modal-ordenacao-questionario.component";
import { BondSectionComponent } from "./modulos/pesquisa-beta/informacoes/bond-section/bond-section.component";
import { OperadorCardComponent } from "./modulos/pesquisa-beta/informacoes/bond-section/operador-card/operador-card.component";
import { HeaderInformacoesComponent } from "./modulos/pesquisa-beta/informacoes/header-informacoes/header-informacoes.component";
import { InfoSectionComponent } from "./modulos/pesquisa-beta/informacoes/info-section/info-section.component";
import { InformacoesComponent } from "./modulos/pesquisa-beta/informacoes/informacoes.component";
import { QuestionCardComponent } from "./modulos/pesquisa-beta/informacoes/questionnaire-section/question-card/question-card.component";
import { QuestionnaireSectionComponent } from "./modulos/pesquisa-beta/informacoes/questionnaire-section/questionnaire-section.component";
import { SecaoContentComponent } from "./modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/conteudo/secao-content/secao-content.component";
import { SecaoComponent } from "./modulos/pesquisa-old/cadastro/passos/questionario/componentes/secao/secao.component";
import { DetalhesOperadorComponent } from "./modulos/produtividade-beta/detalhes-operador/detalhes-operador.component";
import { LIstagemOperadoresComponent } from "./modulos/produtividade-beta/listagem-operadores/listagem-operadores.component";
import { CardInfoMediaComponent } from "./modulos/produtividade-beta/visao-geral/components/card-info-media/card-info-media.component";
import { VisaoGeralComponent } from "./modulos/produtividade-beta/visao-geral/visao-geral.component";
import { BarComponent } from "./modulos/resultados/charts/bar/bar.component";
import { ListComponent } from "./modulos/resultados/charts/list/list.component";
import { MultipleGridComponent } from "./modulos/resultados/charts/multiple-grid/multiple-grid.component";
import { PieComponent } from "./modulos/resultados/charts/pie/pie.component";
import { SingleGridComponent } from "./modulos/resultados/charts/single-grid/single-grid.component";
import { ResultadoPesquisaComponent } from "./modulos/resultados/resultado-pesquisa/resultado-pesquisa.component";
import { ContextLoadingComponent } from "./util/componente/context-loading/context-loading.component";
import { MascaraDocumentDirective } from "./util/mascara/mascara-document.directive";
import { MascaraDynamicRegexDirective } from "./util/mascara/mascara.dynamic.regex.directive";
import { MaskOnlyLettersDirective } from "./util/mascara/mascara.only.letters.directive";
import { MaskStringNoBreakLine } from "./util/mascara/mascara.string.no.break.line.directive";
import { RemoveAccentsDirective } from "./util/mascara/remove-accents-mask.directive";
// Tabela genérica
import { HeaderOperadorComponent } from './modulos/produtividade-beta/detalhes-operador/components/header-operador/header-operador.component';
import { GenericTableCell } from "./util/componente/genericTable/components/cell/cell.component";
import { GenericTableHeader } from "./util/componente/genericTable/components/header/header.component";
import { GenericTableInputSearchComponent } from "./util/componente/genericTable/components/input-search/input-search.component";
import { GenericTableRow } from "./util/componente/genericTable/components/row/table-row.component";
import { GenericTableComponent } from "./util/componente/genericTable/generic-table.component";
// end
import { ModalObservationsComponent } from "./componentes/audit-observations/modal-observations.component";
import { KebabClienteComponent } from './modulos/cliente-beta/listagem-cliente/kebab-cliente/kebab-cliente.component';
import { ListagemContratantesMeatballComponent } from "./modulos/contratante-beta/listagem-contratante/tabela-contratante/meatball/meatball.component";
import { CadastroFuncionarioBetaComponent } from "./modulos/funcionario-beta/cadastro/cadastro.component";
import { FuncionarioListagemBetaComponent } from "./modulos/funcionario-beta/listagem/listagem-funcionarios.component";
import { CadastroOperadorBetaComponent } from "./modulos/operador-beta/cadastro/cadastro.component";
import { OperadorListagemBetaComponent } from "./modulos/operador-beta/listagem/listagem-operadores.component";
import { HeaderProdutividadeComponent } from "./modulos/produtividade-beta/components/header-produtividade/header-produtividade.component";
import { GeneralEvaluationComponent } from './modulos/produtividade-beta/detalhes-operador/components/general-evaluation/general-evaluation.component';
import { ProdutividadeBetaComponent } from "./modulos/produtividade-beta/listagem-pesquisas/listagem-pesquisas.component";
import { OperatorCardComponent } from "./modulos/produtividade-beta/visao-geral/components/operator-card/operator-card.component";
import { PodiumComponent } from "./modulos/produtividade-beta/visao-geral/components/podium/podium.component";
import { QRCodeModule } from 'angularx-qrcode';
import { MovideskChatComponent } from "./componentes/movidesk-chat/movidesk-chat.component";
import { BadgesComponent } from "./util/componente/genericTable/components/customCell/badge/badges.component";
import { GenericTableMeatballComponent } from "./util/componente/genericTable/components/meatball/generic-meatball.component";
import { MultiselectPermissionComponent } from "./modulos/funcionario-beta/components/multiselect-permission/multiselect-permission.component";
import { MultiselectGroupComponent } from './modulos/funcionario-beta/components/multiselect-group/multiselect-group.component';
import { CustomTagComponent } from './util/componente/genericTable/components/customCell/custom-tag/custom-tag.component';
import { ComposedCheckSelectComponent } from "./componentes/filter-select/components/composed-check-select/composed-check-select.component";
import { InputRangeDateComponent } from './componentes/input-range-date/input-range-date.component';
import { CadastroComponent } from './modulos/cliente-beta/cadastro/cadastro.component';
import { TooltipIfEllipsisDirective } from "./util/mascara/show-tooltip-when-ellipsis.directive";
import { PasswordValidatorComponent } from './componentes/change-password/components/validatior-container/password-validator.component';
import { MaskStringWithoutSemicolonDirective } from "./util/mascara/mascara.string.without.semicolon.directive";
import { ThemeListenerComponent } from "styles/theme-listener/theme-listener.component";

@NgModule({
  declarations: [
    // pipe
    SortSectionElemPipe,
    SortSectionElemItemPipe,

    // entrypoint
    RouterComponent,

    // novos componentes - LOCALIDADES
    LocalidadeListagemBetaComponent,

    // novos componentes - DASHBOARD
    // NotificatorComponent,
    SearchComponent,
    SystemSearchComponent,
    MolduraCredenciaisComponent,
    ChildrenLocationOperatorComponent,
    CalendarInputsComponent,
    CalendarDatesComponent,
    CalendarComponent,
    SeeDistribution,
    SInputComponent,
    NewSelect,
    SInputComponent,
    NewSelect,
    SelectOperator,
    BadgesComponent,
    CreateOperatorComponent,
    CheckboxComponent,
    ToggleComponent,
    SublocalComponent,
    SampleProgressComponent,
    NewFilterableSelect,
    IconButtonComponent,
    ListagemContratantesMeatballComponent,
    LocationsListComponent,
    BreadcrumbComponent,
    PesquisaComponent,
    GradeInputComponent,
    PesquisaComponent,
    GradeInputComponent,
    ListagemDeSecoesComponent,
    SidebarComponent,
    HeaderComponent,
    DashboardBetaComponent,
    GlobalCardComponent,
    BannerCardComponent,
    HeaderStepComponent,
    SectionListComponent,
    EditarPerguntaComponent,
    AnswerItemComponent,
    PesquisaComponent,
    PesquisasComponent,
    PesquisasCadastroComponent,
    PesquisasConfiguracoesComponent,
    PesquisasQuestionarioComponent,
    PesquisasQuestionarioSecoesComponent,
    PesquisasQuestionarioSecoesCadastroComponent,
    CadastroPerguntaComponent,
    PesquisasLogicaComponent,
    PesquisasLocalidadesComponent,
    PesquisasVinculosComponent,
    PesquisasConclusaoComponent,
    ChildrenLocationComponent,
    TooltipComponent,
    KebabParaPesquisaComponent,
    RadioSelectComponent,
    SelectFilterRadioComponent,
    RadioComponent,
    HeaderProfileComponent,
    ContextLoadingComponent,

    // svg
    Step1SVGComponent,

    // componentes antigos
    EnumToArrayPipe,
    FotoMarcacaoComponent,
    MarcacaoPerguntaConteudoComponent,
    VideoMarcacaoComponent,
    DataMarcacaoComponent,
    HorarioMarcacaoComponent,
    // PesquisaTabelaFiltravelComponent,
    SecaoComponent,
    AlteracaoSenhaComponent,
    SolicitacaoTrocaSenhaComponent,
    AppComponent,
    ClienteCadastroComponent,
    DisableControlDirective,
    MascaraCpfDirective,
    MaskOnlyLettersDirective,
    MascaraRgDirective,
    MascaraCepDirective,
    MascaraCnpjDirective,
    MascaraNumeroDirective,
    MascaraTelefoneDirective,
    MascaraCelularDirective,
    MascaraMinutoSegundo,
    MascaraPorcentagemDirective,
    MascaraLocalidadeNomeDirective,
    MascaraLimiteCaracteresDirective,
    MascaraRaioDirective,
    ClienteListagemComponent,
    TabelaFiltravelComponent,
    ClienteListagemComponent,
    OperadorCadastroComponent,
    OperadorListagemBetaComponent,
    CadastroOperadorBetaComponent,
    OperadorListagemComponent,
    LocalidadeCadastroComponent,
    LocalidadeListagemComponent,
    // TempoRealComponent,
    ProdutividadeComponent,
    AreaInteresseCadastroComponent,
    AreaInteresseListagemComponent,
    FuncionarioCadastroComponent,
    FuncionarioListagemComponent,
    FuncionarioListagemBetaComponent,
    CadastroFuncionarioBetaComponent,
    MultiselectPermissionComponent,
    GrupoCadastroComponent,
    GrupoListagemComponent,
    PromptModalComponent,
    PaginacaoComponent,
    LoadingComponent,
    MascaraDataDirective,
    MascaraLatLongDirective,
    FotoUploaderComponent,
    NewFotoUploaderComponent,
    LoginPageComponent,
    InputPasswordComponent,
    ChartComponent,
    EntrevistaListagemComponent,
    EntrevistaListagemTabelaComponent,
    // CercaEletronicaComponent,
    AudioPlayerComponent,
    RespostaListagemComponent,
    RespostaListagemTabelaComponent,
    RespostaSelecaoUnicaComponent,
    RespostaSelecaoMultiplaComponent,
    ModalGenericoComponent,
    ContainerRespostaComponent,
    RespostaSelecaoUnicaComponent,
    RespostaSelecaoMultiplaComponent,
    RespostaEscalaNumericaComponent,
    RespostaGradeUnicaComponent,
    RespostaMultiplaOrdemCitacaoComponent,
    RespostaGradeMultiplaComponent,
    RespostaFotoComponent,
    RespostaVideoComponent,
    RespostaDataComponent,
    RespostaHorarioComponent,
    ColunaRespostaResumoComponent,
    RespostaVisualizacaoDialogComponent,
    RejeicaoDialogComponent,
    AuditoriaComponent,
    RespostasAbertasComponent,
    PerguntaSelectorComponent,
    PerguntaRespostaMultiSelectorComponent,
    RespostaEdicaoComponent,
    RespostaSelecaoUnicaComponent,
    RespostaSelecaoMultiplaComponent,
    ModalGenericoComponent,
    MascaraInscricaoEstadualDirective,
    MascaraPrimeiroDigitoSemEspaco,
    MaskStringNoBreakLine,
    LocalidadesMapaComponent,
    LocalidadesModalComponent,
    RespostasFechadasComponent,
    DownloadModalComponent,
    EntrevistasDesconformidadeModalComponent,
    EntrevistasDesconformidadeTableComponent,
    ManagementComponent,
    InstanciaCadastroComponent,
    AdministradorComponentBeta,
    SafeHtmlPipe,
    SortByPipe,
    FilterableSelectComponent,
    PesquisaCadastroComponent,
    CaracteristicasComponent,
    ClienteCadastroInputComponent,
    CalendarioComponent,
    QuestionarioComponent,
    ComplexInputFactoryComponent,
    TipoPerguntaInputComponent,
    TipoRespostaInputComponent,
    AlternativaComponent,
    SelecaoUnicaComponent,
    SelecaoMultiplaComponent,
    SelecaoMultiplaOrdemCitacaoComponent,
    EscalaNumericaComponent,
    AlternativasComponent,
    TituloGradeComponent,
    GradeUnicaComponent,
    AlternativaTipadaComponent,
    FotoComponent,
    VideoComponent,
    DataComponent,
    HorarioComponent,
    GradeMultiplaComponent,
    MarcacoesComponent,
    PesquisaSelectComponent,
    QuestionarioSeletorAdicaoComponent,
    SecaoContentComponent,
    ComplexComponentFactoryComponent,
    PerguntaDataContentComponent,
    PerguntaFotoContentComponent,
    PerguntaHorarioContentComponent,
    PerguntaVideoContentComponent,
    // PesquisaListagemComponent,
    PerguntaSelecaoUnicaContentComponent,
    PerguntaSelecaoMultiplaContentComponent,
    PerguntaEscalaNumericaContentComponent,
    PerguntaGradeUnicaContentComponent,
    PerguntaGradeMultiplaContentComponent,
    PerguntaSelecaoMultiplaOrdemCitacaoContentComponent,
    PerguntaCardComponent,
    // PesquisaListagemComponent,
    PerguntaSelecaoUnicaContentComponent,
    PerguntaSelecaoMultiplaContentComponent,
    PerguntaEscalaNumericaContentComponent,
    PerguntaGradeUnicaContentComponent,
    PerguntaGradeMultiplaContentComponent,
    PerguntaSelecaoMultiplaOrdemCitacaoContentComponent,
    PerguntaCardComponent,
    VinculosComponent,
    PerguntaCardMarcacaoComponent,
    SelecaoUnicaMarcacaoComponent,
    SecaoMarcacaoComponent,
    // SelecaoUnicaConteudoMarcacaoComponent,
    AlternativaTipadaMarcacaoComponent,
    SelecaoLocalidadeComponent,
    CotaPercentualComponent,
    ChangePasswordComponent,
    SelecaoMultiplaMarcacaoComponent,
    // SelecaoMultiplaConteudoMarcacaoComponent,
    SelecaoMultiplaOrdemCitacaoMarcacaoComponent,
    // SelecaoMultiplaOrdemCitacaoConteudoMarcacaoComponent,
    EscalaNumericaMarcacaoComponent,
    // EscalaNumericaConteudoMarcacaoComponent,
    VinculosOperadoresComponent,
    SelecaoOperadorComponent,
    GerenciadorCotasComponent,
    FiltrarEOrdenarPorCota,
    PesquisaDependencyConflictComponent,
    PerguntaComponent,
    PerfilCaracteristicaComponent,
    PerfilCardComponent,
    LocalidadesWrapperComponent,
    LocalidadeComponent,
    LocalidadeSpecificationComponent,
    OperadorComponent,
    SeletorPerfilComponent,
    AjusteCotaModalComponent,
    TransferidorCotaModalComponent,
    SeletorOperadorVinculadoComponent,
    OperadorCardWrapperComponent,
    ModalCreadUpdateComponent,
    LocalidadesListComponent,
    NominatimFormComponent,
    LeafletMapComponent,
    ManualFormComponent,
    ListReadComponent,
    LocalidadeTableComponent,
    LocalidadeTableHeaderComponent,
    LocalidadeTablePaginationComponent,
    InformacoesComponent,
    HeaderInformacoesComponent,
    InfoSectionComponent,
    QuestionnaireSectionComponent,
    BondSectionComponent,
    QuestionCardComponent,
    OperadorCardComponent,
    ModalSelectOptionsComponent,
    ModalOrdenacaoQuestionarioComponent,
    LoadingSaveComponent,
    ResultadosComponent,
    ResultsChartCardComponent,
    ResultadoPesquisaComponent,
    BarComponent,
    PieComponent,
    ListComponent,
    MultipleGridComponent,
    SingleGridComponent,
    QuestionnaireBadgeComponent,
    QuestionnaireAnswerResetComponent,
    VideoQuestionComponent,
    CadastroContratanteComponent,
    ListagemContratanteComponent,
    BasicInformationComponent,
    AdminInformationComponent,
    CustomizationComponent,
    TabelaContratanteComponent,
    StatusSelectComponent,
    ChangeAdminComponent,
    MascaraDynamicRegexDirective,
    RemoveAccentsDirective,
    MascaraDocumentDirective,
    // Auditoria beta
    AuditListingComponent,
    AuditDetailsComponent,

    // componente de modal
    ModalComponent,

    AuditSurveyDataComponent,
    AuditCollectionMap,
    AuditOverviewComponent,
    AuditPieChartComponent,

    //
    InputSearchAuditoriaComponent,
    SurveyMapComponent,
    LeafletMapBetaComponent,
    AuditSurveyMapTooltipComponent,
    AuditedCollectionsHeaderComponent,
    PaginationComponent,
    AuditSurveyList,
    InfoCardComponent,
    FilterSelectComponent,
    AuditCollectionsListComponent,
    AuditCollectionsSectionComponent,
    OpenAnswersComponent,
    HeaderDateSelectComponent,
    RangeDateComponent,
    CheckSelectComponent,
    ComposedCheckSelectComponent,
    ListSelectComponent,
    AuditCollectionsMeatballComponent,
    QuestionnaireComponent,
    ModalObservationsComponent,
    ButtonComponent,
    QuestionsComponent,
    NumericScaleQuestionComponent,
    GridComponent,
    GenericResponseQuestionComponent,
    AlternativaFechadaComponent,
    AlternativaAbertaTextoComponent,
    AlternativaAbertaNumeroComponent,
    PerguntaHorarioComponent,
    AuditProgressComponent,
    TooltipTextComponent,
    AuditAudioPlayerComponent,
    AuditVerdictComponent,
    AuditJustificationModalComponent,
    DateComponent,
    PhotoComponent,
    StepOneComponent,
    SelectableDropdownComponent,
    StepTwoComponent,
    ListagemClienteComponent,
    TableListClientComponent,
    PasswordResetComponent,
    EmailResetComponent,
    CadastroLGPDComponent,
    PodiumComponent,
    OperatorCardComponent,
    CardInfoMediaComponent,
    VisaoGeralComponent,
    HeaderProdutividadeComponent,
    LIstagemOperadoresComponent,
    DetalhesOperadorComponent,
    ProdutividadeBetaComponent,
    // tabela genérica
    GenericTableComponent,
    GenericTableInputSearchComponent,
    GenericTableHeader,
    GenericTableRow,
    GenericTableCell,
    GenericTableMeatballComponent,
    // end
    HeaderOperadorComponent,
    MovideskChatComponent,
    KebabClienteComponent,
    GeneralEvaluationComponent,
    MultiselectGroupComponent,
    CustomTagComponent,
    InputRangeDateComponent,
    CadastroComponent,
    TooltipIfEllipsisDirective,
    MovideskChatComponent,
    PasswordValidatorComponent,
    MaskStringWithoutSemicolonDirective,
    ThemeListenerComponent,
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    MatDialogModule,
    MatAutocompleteModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatIconModule,
    DpDatePickerModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    StoreModule.forRoot(
      {
        // cadastroPesquisa: cadastroPesquisaReducer,
      }
      // { metaReducers }
    ),
    // AgmCoreModule.forRoot({
    //   apiKey: mapsApiKey,
    // }),
    NgChartjsModule.registerPlugin([ChartAnnotation]),
    MatSliderModule,
    MatNativeDateModule,
    NotificatorModule,
    CommonModule,
    StoreModule.forRoot({}, {}),
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"), // or import('./path-to-my-custom-echarts')
    }),
    NgxMaskModule.forRoot(),
    QRCodeModule,
  ],
  providers: [
    // PesquisaAuthorityService,
    // StatusPesquisaManager,
    AppRoutingModule,
    RecuperarSenhaService,
    ClienteService,
    ProdutividadeService,
    AlreadyAuthenticatedAuthorizationGuard,
    OperadorService,
    FuncionarioService,
    JWTService,
    AuthenticationGuardService,
    SecurityService,
    ManagementService,
    RefreshTokenMapper,
    RequestErrorHandler,
    RouterModule,
    FuncionarioConfirmDeactivateGuard,
    PesquisaConfirmDeactivateGuard,
    GrupoConfirmDeactivateGuard,
    RespostaPerguntaConfirmDeactivateGuard,
    OperadorConfirmDeactivateGuard,
    ClienteConfirmDeactivateGuard,
    LocalidadeConfirmDeactivateGuard,
    RespostaListagemComponent,
    InstanciaConfirmDeactivateGuard,
    AuditoriaDeactivateGuard,
    EntrevistasDesconformidadeService,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    EventBus,
    ModalCreadUpdateService,
    ListReadService,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    { provide: ROUTER_HIRER, useValue: ROUTER_HIRER },
    { provide: CURRENT_HIRER, useValue: new BehaviorSubject(ROUTER_HIRER) },
    { provide: APP_BASE_HREF,
      useFactory : BaseHrefGenerator,
      deps: [CURRENT_HIRER],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
