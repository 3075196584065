import { Component, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import validadorEmptyControls from "app/util/validador/validadorEmptyControls";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LocalidadeTree } from "../../../../localidade/localidadeTree";
import { LocalidadeService } from "../../../../localidade/servico/localidade.service";
import { EventoInput } from "../../inputs/evento/eventoInput";
import { ItemProtegido } from "../../security/itemProtegido";
import { PesquisaAuthorityService } from "../../security/pesquisaAuthorityService";
import { SetPreparadoParaSalvar } from "../../store/actions/controleGeralAction";
import {
  AdicionarLocalidade,
  AdicionarLocalidades,
  AdicionarLocalidadesPayload,
  AlterarVinculoLocalidadeCotaPercentual,
  CarregarFilhos,
  ExpandirFilhos,
  RemoverVinculoLocalidade,
  SelecionarVinculoLocalidade,
  SincronizarPassoLocalidades,
  VinculoLocalidadeTree,
} from "../../store/actions/vinculosAction";
import { CadastroPesquisaStoreState } from "../../store/cadastroPesquisaStoreState";
import { PassoCadastro } from "../../store/passoCadastro";
import {
  LocalidadesVinculos,
  LocalidadeVinculo,
  OperadorVinculo,
} from "../../store/vinculos/model";
import { PassoComponent } from "../passoComponent";
import { calcularHierarquias, toTreeStructure } from "./localidadesUtils";

@Component({
  selector: "app-vinculos",
  templateUrl: "./vinculos.component.html",
  styleUrls: ["./vinculos.component.scss"],
})
export class VinculosComponent extends PassoComponent implements OnInit {
  passoCadastro: PassoCadastro = PassoCadastro.LOCALIDADES;

  /**
   * Referencia para localidades vinculos obtido da store
   */
  localidadesVinculos: LocalidadeVinculo[] = [];

  /**
   * Referencia para operadores vinculos obtido da store
   */
  public operadoresVinculos: { [key: string]: OperadorVinculo[] } = {};

  /**
   * Referencia para quantidade de amostrar da pesquisa em processo de cadastro obtido
   * a partir do store.
   */
  public qtdAmostras: number = 0;

  /**
   * Referencia para o formGroup de localidadesVinculos criadas a partir das localidadesVinculos
   * presentes no store.
   */
  localidadesFormGroup: UntypedFormGroup = new UntypedFormGroup({});

  /**
   * Localidade selecionada do seletor de localidades
   */
  public localidadeSelecionada: { id: number; nome: string; versao: string };

  /**
   * atributo booleano que indica se este componente está preparado para submeter o formulário
   */
  public preparadoParaSalvar: boolean = false;

  /**
   * atributo auxiliar que aponta para o hash (identificador) da localidade vinculo selecionada,
   * este atributo espelha o localidadeVinculoSelecionadaHash lá no store.
   * sempre que ele é modificado,
   * este atributo é atualizado.
   */
  localidadeVinculoSelecionadaHash: string = undefined;

  /**
   * Marcacao que indica se trata-se de uma edicao
   */
  isEdicao: boolean = false;
  /**
   * Marcador que indica se modificações
   * estão habilitadas no passo
   */
  modificacaoPermitida = false;

  /**
   * Marcador que indica se o passo esta bloqueado
   */
  passoBloqueado = false;

  // tslint:disable-next-line: max-line-length
  constructor(
    private pesquisaAuthorityService: PesquisaAuthorityService,
    protected store: Store<CadastroPesquisaStoreState>,
    private localidadeService: LocalidadeService
  ) {
    super(store);
  }

  passoShowErrors() {
    return true;
  }

  setarHierarquias(treeStructure: LocalidadeVinculo[]) {
    const hierarquiasMap: { [key: string]: number } =
      calcularHierarquias(treeStructure);

    this.localidadesVinculos = this.localidadesVinculos.map((l) => {
      return {
        ...l,
        hierarquia: hierarquiasMap[l.hash],
      };
    });
  }
  /*
  editar(event) {

    const carregarLocalidadesVinculos = new CarregarLocalidadesVinculos({
      vinculos: [
        {
          cotaPercentual: 100,
          cotaValor: 1000,
          id: 1,
          localidade: {
            id: 1,
            nome: 'João Pessoa',
            possuiFilho: true,
          },
          filhos: [
            {
              cotaPercentual: 100,
              cotaValor: 1000,
              filhos: [],
              id: 2,
              localidade: {
                id: 2,
                nome: 'Bancáŕios',
                possuiFilho: false,
              },
              operadores: [
                {
                  cotaPercentual: 100,
                  id: 1,
                  cotaValor: 1000,
                  idOperador: 4,
                }
              ]
            }
          ],
          operadores: []
        }
      ]
    });

    this.store.dispatch(carregarLocalidadesVinculos);
  }*/

  /**
   * Método auxiliar para parar a propagação de um evento
   * de um elemento dom filho para o elemento pai.
   * @param event
   */
  stopPropagation(event: any) {
    event.stopPropagation();
  }

  ngOnInit() {
    super.ngOnInit();

    const pesquisaObservable: Observable<CadastroPesquisaStoreState> =
      this.store.pipe(
        map((x) => x["cadastroPesquisa"]),
        map((pesquisa) =>
          pesquisa ? pesquisa : new CadastroPesquisaStoreState()
        )
      );

    pesquisaObservable.subscribe((store) => {
      // tslint:disable-next-line: max-line-length
      this.passoBloqueado = store.dadosGeraisCadastro.passosBloqueados.includes(
        PassoCadastro.LOCALIDADES
      );

      this.qtdAmostras = Number(
        store.pesquisa.configuracaoPesquisa.amostrasPresenciais
      );
      const passoVinculos = store.dadosPasso.get(PassoCadastro.LOCALIDADES);

      // seta as localidades vinculos quando modificadas
      const localidadesVinculos = passoVinculos.localidadesVinculos;
      this.localidadesVinculos = localidadesVinculos.data;

      /**
       * representação das localidades em formato de árvore.
       */
      const localidadesVinculosTree = toTreeStructure(this.localidadesVinculos);

      /**
       * seta as hierarquias de cada localidade vinculo
       */
      this.setarHierarquias(localidadesVinculosTree);

      // seta os operadores vinculos sempre que uma mudança no store ocorre.
      this.operadoresVinculos = passoVinculos.operadoresVinculos;
      console.log("OIIII operadores vinculos: ", this.operadoresVinculos);
      this.localidadeVinculoSelecionadaHash =
        passoVinculos.localidadeVinculoSelecionadaHash;

      /**
       * inicializa o formulário e seus validadores de cotas
       * percentuais de todas as localidades respeitando a hierarquia.
       */
      this.initFormGroup();

      // -------------------------------
      const iniciarPreparacaoParaSalvar =
        store.dadosGeraisCadastro.prepararParaSalvar;
      this.preparadoParaSalvar = passoVinculos.preparadoParaSalvar;

      // console.log('preparado vinculos', iniciarPreparacaoParaSalvar, this.preparadoParaSalvar);

      if (iniciarPreparacaoParaSalvar && !this.preparadoParaSalvar) {
        this.sincronizar();
        // this.atualizarFormGroupStore();
        this.store.dispatch(
          new SetPreparadoParaSalvar(PassoCadastro.LOCALIDADES, true)
        );
      }
    });
  }

  hasLocalidadesVinculosCotasErro(localidadeVinculoHash: string) {
    return this.localidadesFormGroup
      .get(localidadeVinculoHash)
      .get("cotaPercentual").errors;
  }

  getTitleCotaPercentualLocalidade(localidadeVinculoHash: string) {
    // tslint:disable-next-line: max-line-length
    const cotaPercentualControl: AbstractControl = this.localidadesFormGroup
      .get(localidadeVinculoHash)
      .get("cotaPercentual");

    if (cotaPercentualControl.errors) {
      // console.log('errors: ', cotaPercentualControl.errors);

      const menorQueValorMinimoErr =
        cotaPercentualControl.errors.menorQueValorMinimo;
      if (menorQueValorMinimoErr) {
        const valorMinimo = cotaPercentualControl.errors.valorMinimo;
        return `A cota percentual deve ser maior que ${valorMinimo}`;
      }

      const somaCotasDifere = cotaPercentualControl.errors.cotaInvalida;

      if (somaCotasDifere) {
        return "A soma de todas as cotas percentuais das localidades devem ser 100%";
      }
    }

    return "Porcentagem da cota para esta localidade";
  }

  hasNenhumOperadorVinculadoErro(localidadeVinculoHash: string) {
    // tslint:disable-next-line: max-line-length
    const errors = this.localidadesFormGroup
      .get(localidadeVinculoHash)
      .get("operadoresVinculos").errors;
    return errors && errors.noControls;
  }

  possuiErroNosVinculosOperadores(localidadeVinculoHash: string) {
    // tslint:disable-next-line: max-line-length
    return (
      !this.hasNenhumOperadorVinculadoErro(localidadeVinculoHash) &&
      // tslint:disable-next-line: max-line-length
      this.localidadesFormGroup
        .get(localidadeVinculoHash)
        .get("operadoresVinculos").status === "INVALID"
    );
  }

  /* atualizarFormGroupStore() {
    // tslint:disable-next-line: max-line-length
    const action = new SincronizarVinculoLocalidadesFormGroup({ formGroup: this.localidadesFormGroup });
    this.store.dispatch(action);
    console.log('FORMULARIO SINCRONZIADO!');
  }*/

  /**
   *
   * Inicializa o formulário de localidades vinculos com seus operadores vinculos.
   * A estrutura do formulário aqui é da seguinte forma:
   *
   * localidadesFormGroup = {
   *
   *   localidade_vinculo_hash_1: new FormGroup({
   *      cotaPercentual: new FormControl(localidadeVinculo.cotaPercentual),
   *      operadoresVinculos: new FormGroup({
   *        operador_vinculo_hash_1: new FormGroup({
   *          cotaPercentual: new FormControl(0),
   *          idOperador: new FormControl(1),
   *        }),
   *        operador_vinculo_hash_2: new FormGroup({
   *          cotaPercentual: new FormControl(0),
   *          idOperador: new FormControl(1),
   *        }),
   *      }),
   *   }),
   *
   *   localidade_vinculo_hash_2: new FormGroup({
   *      cotaPercentual: new FormControl(localidadeVinculo.cotaPercentual),
   *      operadoresVinculos: new FormGroup({
   *        operador_vinculo_hash_1: new FormGroup({
   *          cotaPercentual: new FormControl(0),
   *          idOperador: new FormControl(1),
   *        }),
   *        operador_vinculo_hash_2: new FormGroup({
   *          cotaPercentual: new FormControl(0),
   *          idOperador: new FormControl(1),
   *        }),
   *      })
   *   }),
   *
   * }
   */
  initFormGroup() {
    // tslint:disable-next-line: max-line-length
    this.localidadesFormGroup =
      LocalidadesVinculos.criaLocalidadesVinculosFormGroup(
        this.localidadesVinculos,
        this.operadoresVinculos
      );
    this.localidadesFormGroup.setValidators([validadorEmptyControls]);
    this.localidadesFormGroup.updateValueAndValidity();
    // this.localidadesFormGroup.setValidators([validadorEmptyControls]);
    // this.localidadesFormGroup.updateValueAndValidity();
    // tslint:disable-next-line: max-line-length
    // console.log('[VinculosComponent.initFormGroup] localidadesFormGroup: ', this.localidadesFormGroup);

    this.verificarPermissaoAlteracao();
    if (
      !this.modificacaoPermitida ||
      this.passosBloqueados.includes(this.passoCadastro)
    ) {
      this.localidadesFormGroup.disable();
    }
  }

  /**
   * Callback executado quando uma localidade é selecionada,
   * o FormControl da localidadeSelecionada é setado como uma
   * propriedade interna de VinculosComponent para posteriormente ser utilizado
   * na ação "AdicionarLocalidade".
   */
  onLocalidadeSelect(eventoSelecaoLocalidade: EventoInput) {
    const eventPayload = eventoSelecaoLocalidade.payload;
    // console.log('[VinculoComponent.onLocalidadeSelect] localidade selecionada: ', eventPayload);
    this.localidadeSelecionada = {
      id: eventPayload.id,
      nome: eventPayload.nome,
      versao: eventPayload.versao,
    };
  }

  /**
   * Lista todas as localidades filhas dado o identificador de uma localidade pai.
   * @param idLocalidadePai identificador da localidade pai.
   */
  listarLocalidadesFilhas(idLocalidadePai: number) {
    return this.localidadeService.listarLocalidadesFilhas(idLocalidadePai);
  }

  /**
   * Adiciona a localidade selecionada.
   * Cria uma action do tipo AdicionarLocalidade com o identificador
   * da localidade previamente selecionada.
   */
  adicionarLocalidade() {
    if (this.localidadeSelecionada) {
      const adicionarLocalidadeAction = new AdicionarLocalidade({
        idLocalidade: this.localidadeSelecionada.id,
        nomeLocalidade: this.localidadeSelecionada.nome,
        versaoLocalidade: this.localidadeSelecionada.versao,
        possuiFilho: true,
      });

      // Enviando ação de adicionar localidade apenas com a localidade selecionada,
      // para, por ex, setar um isLoading = true.
      this.store.dispatch(adicionarLocalidadeAction);

      this.listarLocalidadesFilhas(this.localidadeSelecionada.id).subscribe(
        (localidadesFilhas: LocalidadeTree[]) => {
          /**
           * mapeando as localidades filhas para vinculos localidade
           * (de maneira recursiva)
           */
          const vinculoLocalidadesTree: VinculoLocalidadeTree[] =
            localidadesFilhas.map((localidadeFilha: LocalidadeTree) => {
              return VinculoLocalidadeTree.from(
                this.qtdAmostras,
                this.localidadeSelecionada.nome,
                localidadeFilha
              );
            });

          const payload: AdicionarLocalidadesPayload =
            vinculoLocalidadesTree.length > 0
              ? {
                  vinculoLocalidadesTree,
                }
              : {
                  vinculoLocalidadesTree: [
                    VinculoLocalidadeTree.from(
                      this.qtdAmostras,
                      this.localidadeSelecionada.nome,
                      this.localidadeSelecionada as LocalidadeTree,
                      false
                    ),
                  ],
                };
          // Enviando ação após carregamento das localidades
          const adicionarLocalidadesAction = new AdicionarLocalidades(payload);
          this.store.dispatch(adicionarLocalidadesAction);
        }
      );
    }
  }

  /**
   * Callback executado sempre que uma localidade é expandida.
   * @param localidadePai referencia para a localidade que deve ser expandida.
   */
  onExpansaoLocalidadesFilhas(localidadePai: LocalidadeVinculo) {
    // console.log('expanding localidades filhas do pai: ', localidadePai.nomeLocalidade);
    // { idLocalidadePai: number, localidadePaiHash: string, nomeLocalidadePai?: string }
    const payload = {
      idLocalidadePai: localidadePai.idLocalidade,
      localidadePaiHash: localidadePai.hash,
      nomeLocalidadePai: localidadePai.nomeLocalidade,
    };

    const expandirLocalidadesFilhas = new ExpandirFilhos(payload);

    this.store.dispatch(expandirLocalidadesFilhas);

    // fazendo consulta à API somente se os filhos não estiverem sido carregado.
    if (!localidadePai.childrenLoaded) {
      this.listarLocalidadesFilhas(payload.idLocalidadePai).subscribe(
        (localidadesFilhas) => {
          // tslint:disable-next-line: max-line-length
          // {idLocalidade: number, nomeLocalidade: string; localidadePaiHash: string, nomeLocalidadePai?: string, cotaNumericaPai: number }
          const localidadesFilhasPayload = localidadesFilhas.map(
            (localidadeFilha) => {
              return {
                idLocalidade: localidadeFilha.id,
                nomeLocalidade: localidadeFilha.nome,
                possuiFilhos: localidadeFilha.filhas.length > 0,
                versaoLocalidade: localidadeFilha.versao,
              };
            }
          );

          const carregarLocalidadesFilhasPayload = {
            filhos: localidadesFilhasPayload,
            parent: {
              localidadePaiHash: payload.localidadePaiHash,
              nomeLocalidadePai: payload.nomeLocalidadePai,
              cotaNumericaPai: localidadePai.cotaNumerica,
            },
          };

          const carregarLocalidadesFilhas = new CarregarFilhos(
            carregarLocalidadesFilhasPayload
          );
          this.store.dispatch(carregarLocalidadesFilhas);
        }
      );
    }
  }

  /**
   * Callback disparado quado uma cota percentual é modificada,
   * isso disparada uma ação do tipo "AlterarVinculoLocalidadeCotaPercentual"
   * onde o reducer responsável por trata-la irá modificar a cota
   * numerica de acordo com a nova cota percentual de acordo com a cota numerica do pai.
   * Além disso, o reducer irá alterar recursivammente a cota numerica de todos os
   * filhos, pois a cota numerica dos filhos sempre são relativas a cota
   * numerica dos pais.
   */
  onCotaPercentualChange(localidadeVinculoHash: string) {
    // console.log('[VinculosComponent] onCotaPercentualChange', localidadeVinculoHash);

    const localidadeFormGroup = <UntypedFormGroup>(
      this.localidadesFormGroup.get(localidadeVinculoHash)
    );
    const novaCotaPercentual = localidadeFormGroup.get("cotaPercentual").value;

    // tslint:disable-next-line: max-line-length
    const alterarVinculoLocalidadeCotaPercentual =
      new AlterarVinculoLocalidadeCotaPercentual({
        localidadeVinculoHash,
        novaCotaPercentual,
      });
    this.store.dispatch(alterarVinculoLocalidadeCotaPercentual);
  }

  /**
   * Callback executado quando o usuário clica numa localidade vinculo.
   * Esta rotina envia uma action do tipo SelecionarVinculoLocalidade, onde
   * o reducer responsável por tratar esta ação irá modificar a store
   * setando a nova localidade selecionada.
   */
  onLocalidadeVinculoSelect(localidadeVinculo: LocalidadeVinculo) {
    // tslint:disable-next-line: max-line-length
    const selecionarVinculoLocalidade = new SelecionarVinculoLocalidade({
      localidadeVinculoHash: localidadeVinculo.hash,
    });
    this.store.dispatch(selecionarVinculoLocalidade);
  }

  /**
   * Callback executado quando o usuário aciona o botão "Excluir"
   * uma ação do tipo "RemoverVinculoLocalidade" com o hash do vinculo localidade é enviada.
   * @param localidadeVinculo localidadeVinculo a ser removida.
   */
  removerLocalidadeVinculo(localidadeVinculo: LocalidadeVinculo) {
    // tslint:disable-next-line: max-line-length
    const removerCalback = () => {
      const removerVinculoLocalidade = new RemoverVinculoLocalidade({
        localidadeVinculoHash: localidadeVinculo.hash,
      });
      this.store.dispatch(removerVinculoLocalidade);
    };
  }

  sincronizar() {
    const sincronizar = new SincronizarPassoLocalidades();
    this.store.dispatch(sincronizar);
  }

  /**
   * @override
   */
  verificarPermissaoAlteracao() {
    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida =
      this.pesquisaAuthorityService.usuarioPodeModificar(
        ItemProtegido.VINCULOS
      );
  }
}
