import { Component, Input } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-horario",
  templateUrl: "./horario.component.html",
  styleUrls: ["./horario.component.scss"],
})
export class RespostaHorarioComponent extends RespostaComponent {
  @Input() private pergunta: PerguntaEsquema;
  @Input() private respostaPergunta: RespostaPergunta;
  @Input() horarioFormGroup: UntypedFormGroup;

  ngOnInit() {
    super.ngOnInit();

    const respostas = this.respostaPergunta.alternativasSelecionadas.map(
      (a: AlternativaSelecionada) => {
        return a;
      }
    );

    const resposta =
      respostas.length > 0
        ? {
          id: respostas[0].id,
          horario: respostas[0].horario,
          tipo: TipoRespostaPergunta.HORARIO,
        }
        : { id: null, horario: "", tipo: "" };

    this.horarioFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(resposta.id),
      horario: new UntypedFormControl(resposta.horario, [Validators.required]),
      tipo: new UntypedFormControl(resposta.tipo),
    });

    this.horarioFormGroup.valueChanges.subscribe((val) => {
    });
  }

  hasErrors() {
    return this.horarioFormGroup.invalid;
  }

  /**
   * @override
   */
  onProximaPergunta() {
    if (this.hasErrors()) {
      return;
    }

    const idResposta = this.horarioFormGroup.value.id;
    const horarioEditado = this.horarioFormGroup.value.horario;

    const alternativasSelecionadas: AlternativaSelecionada[] = [
      {
        id: idResposta,
        horario:
          horarioEditado.length === 5 ? horarioEditado + ":00" : horarioEditado,
        tipo: TipoRespostaPergunta.HORARIO,
      },
    ];

    const respostaPergunta: RespostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativasSelecionadas,
      this.respostaPergunta.num
    );

    this.notifyRespostaPergunta(respostaPergunta);
  }
}
