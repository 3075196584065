<app-audit-collections-list
  [data]="collectionsListData"
  (changeFilter)="handleChangeFilter($event)"
  [surveyId]="surveyId"
></app-audit-collections-list>

  <app-pagination
  [placeholder]="totalCollections === 1 ? 'Coleta' :'Coletas'"
  [totalOfEntries]="totalCollections"
  [entriesPerPage]="collectionsQueryParams.surveysPerPage"
  [currentPage]="collectionsQueryParams.currentPage"
  [totalPages]="totalPages"
  (changePage)="changePage($event)"
  ></app-pagination>
<app-loading-save
  *ngIf="isLoading"
  [title]="'Carregando'"
></app-loading-save>
