import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUser } from 'app/infraestrutura/security/authUser';
import { Authority } from 'app/infraestrutura/security/authority';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { AdministradorService } from 'app/modulos/administrador-beta/service/administrador.service';
import { ClienteService } from 'app/modulos/cliente/servico/cliente.service';
import { OperadorService } from 'app/modulos/operador/servico/operador.service';
import { EventoTabela } from 'app/util/componente/tabela/evento/eventoTabela';
import { TipoEventoTabela } from 'app/util/componente/tabela/evento/tipoEventoTabela';
import { TipoUsuarioSistema } from 'app/util/usuario/tipoUsuario';
import { ChangePasswordService } from '../change-password/change-password.service';
import { HeaderService } from '../header/servico/header-service';
import { ModalService } from '../modal/modal.service';
import { LgpdSignatureService } from 'app/servico/lgpdService/lgpd-signature.service';
import { FuncionarioService } from 'app/modulos/funcionario-beta/services/funcionario.service';
import { ErrorHandlerService } from 'app/servico/requestService/error-handler.service';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit {
  // variável para exibir o menu de opções
  showMenu: boolean = false

  userLogado: AuthUser;

  userPhoto: string;
  userName: string;

  isLoading: boolean = false;
  nomeEntidade: any;

  activated: boolean = false;

  needSignDocuments: boolean = true;

  // variável que controla se todas as request já foram executadas
  allRequest: boolean = false;

  constructor(
    private securityService: SecurityService,
    private router: Router,
    private operadorService: OperadorService,
    private administradorService: AdministradorService,
    private clienteService: ClienteService,
    private funcionarioService: FuncionarioService,
    private elem: ElementRef,
    private modalService: ModalService,
    private changePasswordService: ChangePasswordService,
    private lgpdSignatureService: LgpdSignatureService,
    private errorHandleService: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.needSignLgpdDocument();

    /**
     * Intercepta a mudança de dados na tela de edição de perfil e chama o get
     *
     * TODO: se futuramente houver a necessidade de fazer isso para outros tipos de usuário
     * (além do admin) será necessária criar uma verificação da role do usuário logado e
     * chamar o serviço correspodente.
     *
     * Obs: caso o back-end comece atualizar o token e haja necessidade de voltar a usa-lo para
     * renderizar as informações do cliente, basta voltar a utilizar a variavel "this.userLogado"
     * e excluir essa chamada ao serviço de administrador.
     */
    this.administradorService.handleUpdateInfo().subscribe({
      next: (data) =>  data && this.getUserInfo()
    })
  }

  /**
   * Verifica se o usuário precisa assinar o documento lgpd
   * Essa verificação será usada para impedir a request com as informações
   * do administrador
  */
  needSignLgpdDocument() {
    this.lgpdSignatureService.needSignDocument.subscribe({
      next: (needSignDocuments: boolean) => {
        this.needSignDocuments =  needSignDocuments;
        this.updateRequestsState();
      }
    })
  }

  updateRequestsState() {
    if (
      !this.needSignDocuments &&
      !this.allRequest
    ) {
      this.handleAllRequest();
    }
  }

  // função que engloba todas as request do header
  handleAllRequest() {
    this.allRequest = true;
    this.userLogado = this.getUsuarioLogado();
    this.getUserInfo();
  }

  populateAdministradorInfo() {
    this.administradorService.getAdministrator().subscribe({
      next: (data) => {
        this.userPhoto = data.foto.arquivo,
        this.userName = data.pessoa.nome
      }
    })
  }

  /**
   * Obtém as informações do usuário atualmente logado
   */
  getUserInfo() {
    const userData = this.getUsuarioLogado();
    this.userPhoto = userData.photo;
    this.userName = userData.name;

    if (userData.tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR) {
      // A request para alterar os dados só é feita caso o usuário altere a sua seleção
      this.changePasswordService.changePassword.subscribe({
        next: (changePassword) => changePassword === false && this.populateAdministradorInfo(),
      })
    }
  }

  /**
   * toggle para abrir o menu de opções
   */
  toggleOpenMenu(): void {
    this.showMenu = !this.showMenu
  }

  // verificar se o clique foi dentro do componente ou fora.
  @HostListener('document:click', ['$event'])
  documentClick(event: Event) {
    if (!this.elem.nativeElement.contains(event.target)) {
      if (this.showMenu) {
        this.toggleOpenMenu();
      }
    }
  }

  //  Função que retorna a informação em formato de boolean se o usuario é admnistrador ou não
  userIsAdm(): boolean {
    return this.userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR;
  }

  // pega o usuário que está logado
  getUsuarioLogado(): AuthUser {
    const user: AuthUser = this.securityService.getAuthenticatedUser();
    return user;
  }

  /**
   * função que realiza o logout do usuário
   */
  doLogout() {
    this.securityService.logout();
    this.router.navigate(['login'], { queryParams: { redirect: true } });
  }

  perfilFuncionario(idUsuario: string) {
    this.isLoading = true;
    this.nomeEntidade = 'funcionario-beta';
    this.funcionarioService.getIdFuncionarioPorIdContaUsuario(idUsuario).subscribe({
      next: (id) => this.manipularEventoEdicaoPerfil(id),
      error: (err) => this.errorHandleService.handleError(err, 'Ocorreu um erro' ,'erro ao recuperar o id do funcionario')
    });
    this.isLoading = false;
  }

  perfilAdministrador(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = 'administrador-beta';
    this.administradorService.getIdAdministradorPorIdContaUsuario(payload)
      .subscribe((result) => {
        result !== null ? result : null;

        const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, result);
        this.manipularEventoEdicao(eventoEdicao);
        this.isLoading = false;
      });
  }

  perfilCliente(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = 'cliente';

    this.clienteService.getIdClientePorIdContaUsuario(payload)
      .subscribe((result) => {

        result !== null ? result : null;

        const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, result);
        this.manipularEventoEdicao(eventoEdicao);

        this.isLoading = false;
      });
  }

  perfilOperador(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = 'operador';

    this.operadorService.getIdOperadorPorIdContaUsuario(payload)
      // tslint:disable-next-line: ter-arrow-parens
      .subscribe(result => {
        result !== null ? result : null;

        const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, result);
        this.manipularEventoEdicao(eventoEdicao);
        this.isLoading = false;
      });
  }

  manipularEventoEdicao(eventoTabela: EventoTabela) {
    // verifica se a rota atual é a mesma que o usuario será redirecionado
    if (this.router.url.includes(`${this.nomeEntidade}/atualizar`)) {
      this.activated = true;
      return;
    }
    this.activated = false;
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.nomeEntidade, 'atualizar'], { queryParams: { id: eventoTabela.payload } },);
  }

  manipularEventoEdicaoPerfil(id: number) {
    // verifica se a rota atual é a mesma que o usuario será redirecionado
    // if (this.router.url.includes(`${this.nomeEntidade}/perfil`)) {
    //   this.activated = true;
    //   return;
    // }
    this.activated = false;
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.nomeEntidade, 'atualizar'], {queryParams: {id: id}},);
  }

  emitirAlertaSemPermissao() {
    const redirect = () => {
      this.router.navigate(['']);
    };

    this.modalService.showModal({
      title: 'Meu perfil',
      messageModal: 'Você não tem permissão para acessar esta página',
      imgFile: 'icons/icon-warning.svg',
      btnTitleNegative: 'Cancelar',
      btnTitlePositive: 'Ok',
      close: true,
      positiveCallback: () => redirect
    })
  }

  /**
   * redireciona o usuario logado para sua página de edição
   * caso o usuário não tenha permissão para editar seu próprio perfil, será emitido um alerta
   */
  getPerfilUsuarioLogadoToEdicao() {

    const userLogado = this.getUsuarioLogado();

    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_FUNCIONARIO) {

      const listagemAuthorities = [Authority.EDICAO_PERFIL_FUNCIONARIO];
      const temPermissao = this.securityService.userHasAuthorities(listagemAuthorities);
      if (temPermissao) {
        this.perfilFuncionario(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR) {

      const listagemAuthorities = [Authority.ATUALIZAR_FUNCIONARIO];
      const temPermissao = this.securityService.userHasAuthorities(listagemAuthorities);

      if (temPermissao) {
        this.perfilAdministrador(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_CLIENTE) {

      const listagemAuthorities = [Authority.ATUALIZAR_CLIENTE];
      const temPermissao = this.securityService.userHasAuthorities(listagemAuthorities);

      if (temPermissao) {
        this.perfilCliente(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();

    }
    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_OPERADOR) {
      const listagemAuthorities = [Authority.ATUALIZAR_OPERADOR];
      const temPermissao = this.securityService.userHasAuthorities(listagemAuthorities);

      if (temPermissao) {
        this.perfilOperador(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
  }
}
