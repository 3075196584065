import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { CardQuestionario } from '../../../questionario/card/cardQuestionario';
// tslint:disable-next-line: max-line-length
import { map } from 'rxjs/operators';
import { Pergunta } from '../../../../../../pesquisa-old/cadastro/model/pergunta';
import { Selecionar } from '../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../pesquisa-old/cadastro/store/passoCadastro';
import { TipoConteudo } from '../../../questionario/componentes/secao/conteudo/tipoConteudo';

@Component({
  selector: 'app-secao-marcacao',
  templateUrl: './secao-marcacao.component.html',
  styleUrls: ['./secao-marcacao.component.scss'],
})
export class SecaoMarcacaoComponent implements CardQuestionario, OnInit, OnDestroy {

  @Input() ordem: number;
  /**
   * FormGroup da seção
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Acesso rápido aos controls do formGroup
   */
  controls: { [key: string]: AbstractControl };

  /**
   * Marcador se a seção está selecionada
   */
  selecionado: boolean = false;

  perguntaSelecionada: boolean = false;

  hashItemSelecionado: boolean = false;
  /**
   * Subscriptions que deverão ser encerradas
   * junto com a destruição do componente
   */
  subscriptions: Subscription[] = [];

  /**
   * Marcador que indica se a redinificação da ordem das cotas
   * está habilitada.
   */
  ordenacaoCotasHabilitada = false;

  @Input() showErrors: boolean;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
  }

  /**
   * @override
   */
  ngOnInit() {
    this.controls = this.formGroup.controls;

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    /**
     * Observando itens selecionados
     */
    const itemSelecionadoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso.get(PassoCadastro.QUESTIONARIO)),
        map((dadosPasso) => {
          this.ordenacaoCotasHabilitada = dadosPasso.ordenandoCotas;
          return dadosPasso.itemSelecionado;
        }),
      )
      .subscribe((itemSelecionado) => {
        if (!itemSelecionado.item) {
          return;
        }
        this.selecionado = this.controls.hash.value === itemSelecionado.item.hash;
        this.hashItemSelecionado = itemSelecionado.item.hash;
      });

    this.subscriptions.push(itemSelecionadoSubscription);
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  getNestedInputParams(elementoSecao: UntypedFormGroup) {
    const params = {
      formGroup: elementoSecao.controls.pergunta,
    };

    return params;
  }

  notificarSelecao() {
    this.store.dispatch(new Selecionar(this.formGroup.getRawValue(), TipoConteudo.CONTEUDO_SECAO));
  }

  perguntaPossuiMarcacao(pergunta: Pergunta) {
    // tslint:disable-next-line: max-line-length
    return pergunta.possuiCota || pergunta.possuiDiferenteDe || pergunta.possuiPulo || pergunta.possuiPuloPergunta;
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(abstractFormArray: AbstractControl): AbstractControl[] {

    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }

}
