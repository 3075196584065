<div
  [formGroup]="formGroup"
  (click)="notificarSelecao()"
  [ngClass]="selecionado ? 'selecionado' : ''"
>
  <div
    class="sectionInterview"
    [ngClass]="
      showErrors && (controls.nome.invalid || controls.descricao.invalid)
        ? 'possui-erros'
        : ''
    "
  >
    <!-- <div class="secao-erros-icon" title="Esta seção não está configurada corretamente" *ngIf="showErrors && (controls.nome.invalid || controls.descricao.invalid)">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    </div> -->
    <div class="iconSection">
      <span class="fa fa-flag-o"></span>
    </div>
    <div class="infoSection">
      <span class="sectionOrder">SEÇÃO - {{ ordem }}</span>
      <h3>
        {{ controls.nome.value ? controls.nome.value : "Nome da seção*" }}
      </h3>
      <p>
        {{ controls.descricao.value ? controls.descricao.value : "Descrição" }}
      </p>
      <i
        title="Adicionar pergunta"
        *ngIf="formGroup.enabled"
        (click)="selecionarPergunta($event)"
        class="add fa fa-plus-square-o"
      ></i>
      <i
        title="Remover uma seção"
        *ngIf="formGroup.enabled"
        (click)="removerSecao($event)"
        class="trash fa fa-trash-o"
      ></i>
    </div>
  </div>
  <span class="error-message" *ngIf="controls.elementosSecao.errors">
    Você deve adicionar pelo menos uma pergunta à seção.
  </span>

  <!--
    Para permitir o drag and drop das perguntas, é necesessário
    que as perguntas estejam com *ngIf, a fabrica de componentes
    nao funciona
  -->
  <div cdkDropList [cdkDropListData]="formGroup">
    <div
      cdkDrag
      class="interviewItem"
      [ngClass]="{
        'possui-erros': showErrors && elementoSecao.invalid,
        selecionado: hashItemSelecionado === elementoSecao.get('hash').value
      }"
      *ngFor="
        let elementoSecao of getControlsFromAbstractFormArray(
          formGroup.get('elementosSecao')
        );
        let ordem = index
      "
    >
      <div
        title="Arraste para alterar a ordem"
        class="moveIcon"
        cdkDragHandle
        [hidden]="formGroup.disabled"
      >
        <span class="fa fa-th"></span>
      </div>
      <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente" *ngIf="showErrors && elementoSecao.invalid">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div> -->

      <app-selecao-unica-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'UNICA'"
      ></app-selecao-unica-questionario>

      <app-selecao-multipla-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'MULTIPLA'"
      ></app-selecao-multipla-questionario>

      <app-selecao-multipla-ordem-citacao-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'MULTIPLA_ORDEM_CITACAO'"
      >
      </app-selecao-multipla-ordem-citacao-questionario>

      <app-escala-numerica-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'ESCALA_NUMERICA'"
      ></app-escala-numerica-questionario>

      <app-foto-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'FOTO'"
      ></app-foto-questionario>

      <app-video-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'VIDEO'"
      ></app-video-questionario>

      <app-grade-unica-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'GRADE_UNICA'"
      ></app-grade-unica-questionario>

      <app-grade-multipla-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'GRADE_MULTIPLA'"
      ></app-grade-multipla-questionario>

      <app-data-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'DATA'"
      ></app-data-questionario>

      <app-horario-questionario
        *ngIf="elementoSecao.value.pergunta.tipo === 'HORARIO'"
      ></app-horario-questionario>
    </div>
    <div class="interviewAddSection" *ngIf="formGroup.enabled">
      <img
        [hidden]="statusAtualPesquisa === 'EXECUCAO'"
        (click)="selecionarPergunta($event)"
        src="assets/images/add.svg"
        alt=""
      />
      <span
        [hidden]="statusAtualPesquisa === 'EXECUCAO'"
        (click)="selecionarPergunta($event)"
        >Adicionar pergunta</span
      >
      {{ statusAtualPesquisa }}
      <div *ngIf="perguntaSelecionada && selecionado">
        <app-questionario-seletor-adicao> </app-questionario-seletor-adicao>
      </div>
    </div>
  </div>
</div>
