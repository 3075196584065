import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import * as LMap from "leaflet";
import { ModalCreadUpdateService } from "../modal-create-update.service";

interface ICoordinateArray {
  module: [number, number];
}

interface ICoordinatesArray {
  group: ICoordinateArray[];
}

interface ICoordinates {
  arrayOfCoordinates: ICoordinateArray[];
}

interface IMarkedArea {
  type: string;
  properties: {
    popupContent: string;
    style: {
      weight: number;
      color: string;
      opacity: number;
      fillColor: string;
      fillOpacity: number;
    };
  };
  geometry: {
    type: string;
    coordinates?: ICoordinates;
  };
}

@Component({
  selector: "app-leaflet-map",
  templateUrl: "./leaflet-map.component.html",
  styleUrls: ["./leaflet-map.component.scss"],
})
export class LeafletMapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() canMountMap: boolean = false;

  markedArea: IMarkedArea = {
    type: "Feature",
    properties: {
      popupContent: "This is Default Text",
      style: {
        weight: 2,
        color: "#999",
        opacity: 1,
        fillColor: "#B0DE5C",
        fillOpacity: 0.8,
      },
    },
    geometry: {
      type: "MultiPolygon",
    },
  };

  // locations latitude
  currentUserLat: number = -7.1219366;
  currentUserLon: number = -36.7246845;

  currentLatLong: Array<number> = null;

  // atributo que recebe o zoom
  zoom: number = 6;

  // dados da boundingBox selecionada
  latMin: number = -8.302955;
  latMax: number = -38.7656034;
  longMin: number = -6.0259119;
  longMax: number = -34.5995314;

  constructor(private modalCreateUpdateService: ModalCreadUpdateService) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "canMountMap":
            if (this.canMountMap) {
              const responseOfDataToMountMap =
                this.modalCreateUpdateService.handlePreMountMap();

              this.currentUserLat = responseOfDataToMountMap.latAndLong.lat;
              this.currentUserLon = responseOfDataToMountMap.latAndLong.lon;

              this.latMin = responseOfDataToMountMap.boundingBox.latMin;
              this.latMax = responseOfDataToMountMap.boundingBox.latMax;
              this.longMax = responseOfDataToMountMap.boundingBox.lonMax;
              this.longMin = responseOfDataToMountMap.boundingBox.lonMin;

              this.map.remove();
              this.initMap();

              if (responseOfDataToMountMap.canMountBoundary) {
                switch (responseOfDataToMountMap.type) {
                  case "Polygon":
                    LMap.geoJSON({
                      type: "Polygon",
                      coordinates: responseOfDataToMountMap.data,
                    })
                      .setStyle({ color: "#610082" })
                      .addTo(this.map);

                    setTimeout(() => {
                      this.modalCreateUpdateService.handleUnmountMap();
                    }, 2000);
                    break;
                  case "LineString":
                    LMap.geoJSON([{
                      type: "LineString",
                      coordinates: responseOfDataToMountMap.data,
                    }])
                      .setStyle({ color: "#610082" })
                      .addTo(this.map);

                    setTimeout(() => {
                      this.modalCreateUpdateService.handleUnmountMap();
                    }, 2000);
                    break;
                  case "Point":
                    const myIcon = LMap.icon({
                      iconUrl: "../../../../../assets/icons/map-marker.svg",
                      iconSize: [30, 85],
                      iconAnchor: [8, 55],
                      // popupAnchor: [-3, -76],
                      // shadowSize: [68, 95],
                      // shadowAnchor: [22, 94]
                    });
                    LMap.marker([this.currentUserLat, this.currentUserLon], {
                      icon: myIcon,
                    }).addTo(this.map);

                    setTimeout(() => {
                      this.modalCreateUpdateService.handleUnmountMap();
                    }, 2000);
                    break;
                  // Renderização do mapa personalizado
                  case "Circle":
                    const myIcon2 = LMap.icon({
                      iconUrl: "../../../../../assets/icons/map-marker.svg",
                      iconSize: [30, 85],
                      iconAnchor: [8, 55],
                    });
                    // Adicionando um marcador nas coordenadas definidas pelo usuário
                    LMap.marker([this.currentUserLat, this.currentUserLon], {
                      icon: myIcon2,
                    }).addTo(this.map);
                    // Adicionando uma circunferência que representa o raio definido pelo usuário
                    LMap.circle([this.currentUserLat, this.currentUserLon], {
                      color: "#610082",
                      fillColor: "#610082",
                      fillOpacity: 0.2,
                      radius: responseOfDataToMountMap.radius
                  }).addTo(this.map);

                  setTimeout(() => {
                    this.modalCreateUpdateService.handleUnmountMap();
                  }, 2000);
                  break;
                  default: break;
                }
              }
            }
        }
      }
    }
  }

  // Maps
  private map;

  initMap(): void {
    this.map = LMap.map("map", {
      center: [this.currentUserLat, this.currentUserLon],
      zoom: this.zoom,
    });

    const tiles = LMap.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    // Só desejamos centralizar o mapa em uma região específica caso
    // as propriedades minimas e maximas para longitude e latitude
    // tenham sido recuperadas através da api do nominatim.
    if (this.latMin && this.latMax && this.longMax && this.longMin) {
      this.map.fitBounds([
        [this.latMin, this.latMax],
        [this.longMin, this.longMax],
      ]);
    }

    tiles.addTo(this.map);
  }
}
