import { stringFormatter } from "app/util/misc/stringFormatter";
import { IClientCreateOrEditPayload } from "../interfaces/clientForm";

/**
 * Converte os dados do formulário para o modelo de payload esperado
 * na criação de um novo cliente.
 * @param clientData: Dados do cliente
 * @returns payload de criação de um novo cliente
 */
export function createClientPayload(clientData, isPJ: boolean): IClientCreateOrEditPayload {

  let agent = null; // responsavel (so existe em cliente PJ)
  let type = 'F'; // F - pessoa fisica | J - pessoa juridica

  if(isPJ) {
    agent = {
      nome: clientData.responsible,
      telefone: !!clientData.phone?.length ? stringFormatter.onlyNumbers(clientData.phone) : null
    }

    type = 'J'
  }

  return {
    representante: agent,
    pessoa: {
      cpf: isPJ ? null : clientData.document,
      cnpj: isPJ ? clientData.document : null,
      endereco: {
        cep: clientData.cep,
        complemento: clientData.complement,
        logradouro: clientData.address,
        numero: clientData.number,
      },
      id: null,
      nome: clientData.name,
      tipoPessoa: type
    },
    usuario: {
      ativo: clientData.status,
      id: null,
      login: clientData.email,
    }
  }
}


/**
 * Converte os dados do formulário para o modelo de payload esperado
 * na atualização de um cliente.
 * @param clientData: Dados do cliente
 * @returns payload de edição de um cliente
 */
export function updateClientPayload(clientData, isPJ: boolean, personAndUserIds): IClientCreateOrEditPayload {

  let agent = null; // responsavel (so existe em cliente PJ)
  let type = 'F'; // F - pessoa fisica | J - pessoa juridica

  if(isPJ) {
    agent = {
      nome: clientData.responsible,
      telefone: !!clientData.phone?.length ? stringFormatter.onlyNumbers(clientData.phone) : null
    }

    type = 'J'
  }

  return {
    id: clientData.id,
    representante: agent,
    pessoa: {
      cpf: isPJ ? null : clientData.document,
      cnpj: isPJ ? clientData.document : null,
      endereco: {
        cep: clientData.cep,
        complemento: clientData.complement,
        logradouro: clientData.address,
        numero: clientData.number,
        id: personAndUserIds.addresId
      },
      id: personAndUserIds.personId,
      nome: clientData.name,
      tipoPessoa: type
    },
    usuario: {
      ativo: clientData.status,
      id: personAndUserIds.userId,
      login: clientData.email,
    }
  }
}
