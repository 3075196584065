<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>

<form class="funcionario-container" [formGroup]="form" (ngSubmit)="handleSubmit()">

  <section class="funcionario-section">

    <div class="header-options">
      <span class="funcionario-title">
        <i class="fa-regular fa-users"></i>
        <h2>{{isEdicao? 'Editar' : 'Adicionar'}} funcionário</h2>
      </span>

      <span class="funcionario-options" *ngIf="isEdicao">
        <div (click)="handleChangeEmail()">
          <i class="fa-regular fa-envelope"></i>
          <p>Alterar e-mail</p>
        </div>
      </span>
    </div>

    <main class="funcionario-wrapper">
      <div class="img-container">
        <app-new-foto-uploader
          [newSize]="photoSize"
        ></app-new-foto-uploader>
        <h4 *ngIf="this.isEdicao">Matrícula:</h4>
        <span *ngIf="this.isEdicao">{{ matricula }}</span>
      </div>

      <div class="section-wrapper">
        <section class="form-section">
          <div class="input-wrapper">
            <label>Nome do funcionário*</label>
            <input
              type="text"
              [ngClass]="(form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)) ? 'inputs name-input error-input ' : 'inputs name-input'"
              formControlName="name"
              placeholder="Nome do funcionário"
              maxlength="71"
              mascara-primeiro-digito-sem-espaco
            />
            <span class="error-message" *ngIf="form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)">
              <span *ngIf="form.get('name')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('name')?.errors.maxlength">Limite máximo de 70 caracteres</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>E-mail*</label>
            <input
              type="email"
              [ngClass]="(form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)) ? 'inputs email-input error-input ' : 'inputs email-input'"
              formControlName="email"
              placeholder="email@email.com"
              [pattern]="emailRegex"
              [attr.disabled]="isEdicao ? '' : null"
            />
            <span class="error-message" *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)">
              <span *ngIf="form.get('email')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('email')?.errors.pattern">Formato de e-mail inválido</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>CPF*</label>
            <input
              type="text"
              [ngClass]="(form.get('cpf')?.invalid && (form.get('cpf')?.dirty || form.get('cpf')?.touched)) ? 'inputs cpf-input error-input ' : 'inputs cpf-input'"
              formControlName="cpf"
              placeholder="000.000.000-00"
              maxlength="14"
              mascara-document
              [initialValue]="form.get('cpf').value"
              [attr.disabled]="isEdicao ? '' : null"
            />
            <span class="error-message" *ngIf="form.get('cpf')?.invalid && (form.get('cpf')?.dirty || form.get('cpf')?.touched)">
              <span *ngIf="form.get('cpf')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('cpf')?.errors && !form.get('cpf')?.errors.required">CPF inválido</span>
            </span>
          </div>
        </section>

        <section class="form-section">
          <div class="input-wrapper">
            <label>Cargo*</label>
            <input
              type="text"
              [ngClass]="(form.get('role')?.invalid && (form.get('role')?.dirty || form.get('role')?.touched)) ? 'inputs role-input error-input ' : 'inputs role-input'"
              formControlName="role"
              placeholder="Ex. Analista"
              maxlength="71"
              mascara-primeiro-digito-sem-espaco
            />
            <span class="error-message" *ngIf="form.get('role')?.invalid && (form.get('role')?.dirty || form.get('role')?.touched)">
              <span *ngIf="form.get('role')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('role')?.errors.maxlength">Limite máximo de 70 caracteres</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>Situação</label>
            <div
              #statusDropdown
              class="select status-select"
              (click)="toggleStatus()"
            >
              <div
                class="select-header"
                [ngClass]="statusIsOpen ? 'open-select' : ''"
              >
                <span class="selected-option">
                  <div
                    class="circle"
                    [ngClass]="
                      this.form.get('status')?.value
                        ? 'circle-active'
                        : 'circle-inactive'
                    "
                  ></div>
                  {{ this.form.get("status")?.value ? "Ativo" : "Inativo" }}
                </span>
                <div class="select-data-icon">
                  <span [class]="!statusIsOpen ? 'arrow' : 'arrow-rotate'"></span>
                </div>
              </div>
              <div class="options" *ngIf="statusIsOpen">
                <!--<hr class="row"/>-->
                <div
                  *ngIf="!this.form.get('status')?.value"
                  class="option"
                  (click)="handleStatusClick(true)"
                >
                  <span class="span-option">
                    <div class="circle circle-active"></div>
                    Ativo
                  </span>
                </div>
                <div
                  *ngIf="this.form.get('status')?.value"
                  class="option"
                  (click)="handleStatusClick(false)"
                >
                  <span class="span-option">
                    <div class="circle circle-inactive"></div>
                    Inativo
                  </span>
                </div>
              </div>
            </div>
            <input type="hidden" formControlName="status" />
          </div>
        </section>
      </div>
    </main>

  </section>

  <section class="permissions-section" *ngIf="!employeeIsHimself">
    <div class="header-options">
      <span class="funcionario-title">
        <i class="fa-regular fa-key"></i>
        <h2>Permissões</h2>
      </span>
    </div>

    <main class="permissions-wrapper">
      <div>
        <p>Grupo de permissões</p>
        <app-multiselect-group
          *ngIf="allGroups.length"
          [groups]="allGroups"
          [form]="form"
          (getOrRemoveGroupPermissions)="handleGroupFlow($event)"
        ></app-multiselect-group>
      </div>
      <div class="avulse">
        <p>Permissões extras*</p>
        <app-multiselect-permission
          *ngIf="allPermissions.length"
          [permissions]="allPermissions"
          [form]="form"
        ></app-multiselect-permission>
        <span class="error-message" *ngIf="form.get('permissions')?.invalid && (form.get('permissions')?.dirty || form.get('permissions')?.touched)">
          <span *ngIf="form.get('permissions')?.errors.required">Selecione pelo menos uma permissão</span>
        </span>
      </div>
    </main>
  </section>

  <div class="footer-buttons">
    <button
      type="button"
      class="footer-cancel-button"
      (click)="handleClose()"
    >
      Cancelar
    </button>

    <button type="submit" class="footer-save-button">
      {{ isEdicao ? 'Salvar' : 'Adicionar' }}
    </button>
  </div>
</form>
