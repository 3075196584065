import { Injectable, OnInit } from '@angular/core';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { ItemProtegido } from './itemProtegido';
import itemProtegidoAuthorityMap from './itemProtegidoAuthorityMap';

/**
 * Serviço que trata as autoridades necessárias
 * para o cadastro/edição de pesquisa. Verificando
 * se o usuario logado pode alterar determiado
 * passo ou atributo, etc.
 */
@Injectable({
  providedIn: 'root',
})
export class PesquisaAuthorityService {

  /**
   * Mapa que serve como base para tomada de
   * decisões sobre autorizações em uma pesquisa
   */
  readonly itemProtegidoAuthorityMap = itemProtegidoAuthorityMap;

  constructor(private securityService: SecurityService) {

  }

  public usuarioPodeModificar(itemProtegido: ItemProtegido): boolean {

    const autoridadesNecessarias = this.itemProtegidoAuthorityMap.get(itemProtegido);

    // tslint:disable-next-line: max-line-length
    const usuarioTemAutoridadeSuficiente = this.securityService.userHasAuthorities(autoridadesNecessarias);

    return usuarioTemAutoridadeSuficiente;
  }

}
