<div *ngIf="showMensagem">
  Não há entrevistas em desconformidade
</div>
<div *ngIf="trBodies.length > 0">
  <table class="sg-table">
    <thead>
      <th scope="col">Id</th>
      <th scope="col">Tempo de duração</th>
      <th scope="col">Localidade</th>
      <th scope="col">Operador</th>
      <th scope="col">Visualizar</th>
    </thead>
    <tbody>
      <tr *ngFor="let tr of trBodies">
        <td title="Id">
          {{ tr.id }}
        </td>
        <td title="Tempo de duração">
          {{ tr.tempoDuracao }}
        </td>
        <td title="Localidade">
          {{ tr.nomeLocalidade }}
          <span *ngIf="!tr.nomeLocalidade">Inválida</span>
        </td>
        <td title="Operador">
          {{ tr.nomeOperador }}
        </td>
        <td title="Visualizar">
          <a routerLink="auditoria/pesquisas/{{entrevistaEmDesconformidadeQuery.idPesquisa}}/entrevistas/{{tr.id}}">
            <i class="fa fa-eye"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="paginacao-wrapper sg-input-group">
    <app-paginacao [indicadorReinicializacao]="subjectPaginacao" (onPaginationChange)="onPaginationChange($event)">
    </app-paginacao>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>
