export class ChartDataset {

  data: number[];
  label: string;
  pointRadius: number;
  pointHoverRadius: number;
  pointBorderWidth: number;
  borderWidth: number;

}
