<div [formGroup]="formGroup" class="alternativeTyped sg-input-group">
  <div class="row">
    <div class="campo col-6">
      <input type="text" formControlName="descricao" placeholder="Nome da opção">
      <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
        Deve ser informado um nome de até 250 caracteres para a alternativa
      </span>
    </div>
    <div class="campo col-6">
      <app-tipo-resposta-input [formGroup]="formGroup" [editavel]="false">
      </app-tipo-resposta-input>
    </div>
  </div>
  <!-- M A R C A Ç Õ E S -->
  <div formGroupName="marcacao">
    <!-- PULAR PARA -->
    <div formGroupName="perguntaDestino" *ngIf="perguntaFg.controls.possuiPulo.value" class="campo">
      <label>Pular para</label>
      <select title="pular para outra pergunta" formControlName="codigoMarcacao">
        <option [ngValue]="null" disabled>Continuar entrevista</option>
        <option *ngFor="let pergunta of perguntasDisponiveis" [ngValue]="pergunta.hash">{{pergunta.nome}}</option>
      </select>
      <span class="error-message" [hidden]="!(showErrors && formGroup.get('marcacao').get('perguntaDestino').errors)">
        O pulo deve ser aplicado em pelo menos 1 alternativa
      </span>
    </div>
    <!-- DIFERENTE DE -->
    <div formGroupName="perguntasDiferente" *ngIf="perguntaFg.controls.possuiDiferenteDe.value" class="campo">
      <label> Diferente de</label>
      <select title="diferente de outra alternativa" formControlName="codigoMarcacao">
        <option [ngValue]="null" disabled>Selecione</option>
        <option *ngFor="let pergunta of perguntasDisponiveis" [ngValue]="pergunta.hash">{{pergunta.nome}}</option>
      </select>
      <span class="error-message" [hidden]="!(showErrors && formGroup.get('marcacao').get('perguntasDiferente').errors)">
        A diferença deve ser configurada em pelo menos 1 alternativa
      </span>
    </div>
    <!-- COTA -->
    <div *ngIf="perguntaFg.controls.possuiCota.value" class="row" >
      <div class="col-12 campo">
        <label>Cota %</label>
        <app-cota-percentual class="cotaPercentual" (cotaPercentualChange)="onCotaPercentualChange($event)" ngDefaultControl></app-cota-percentual>
        <span class="error-message" [hidden]="!(showErrors && formGroup.get('marcacao').get('cotaPercentual').errors)">
            A soma de todas as cotas da pergunta deve corresponder a 100%
        </span>
      </div>
      <div class="col-12 campo">
        <label>Valor</label>
        <input type="text" formControlName="cotaValor">
      </div>

    </div>
  </div>
</div>
