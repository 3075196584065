import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifcationType, buildNotificationSuccess } from "../../utils/notify";
import { FormControl, FormGroup, Validators } from "@angular/forms";

const EMAIL_REGEX = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
@Component({
  selector: "app-email-reset",
  templateUrl: "./email-reset.component.html",
  styleUrls: ["./email-reset.component.scss"],
})
export class EmailResetComponent implements OnInit {


  @Input() formGroup;
  @Input() controls;
  @Input() showModal;
  @Input() notifyObject;
  @Output() handleShowModal = new EventEmitter();

  emailRegex = EMAIL_REGEX;

  constructor() {}

  ngOnInit(): void {
    this.notifyObject = buildNotificationSuccess(NotifcationType.EMAIL);
    this.buildNewAttribute();
  }

  /**
   * Adiciona novo atributo para receber o novo e-mail digitado pelo usuário
   * O atributo será removido ao final
   */
  buildNewAttribute(): void {
    if(this.formGroup) {
      this.formGroup.get('usuario').addControl('newEmail', new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]));
    }
  }

  /**
   * Remove o atributo 'newEmail' criado no inicio do ciclo de vida da modal
   */
  clearNewAttribute(): void {
    this.formGroup.get('usuario').removeControl('newEmail');
  }


  /**
   * Realiza a limpeza de valores e validações do campo de senha ao fechar a modal
   */
  handleCloseModal(): void {
    const password = this.formGroup.get('usuario.senha_atual');
    const controlPassword = this.controls.usuario.get('senha_atual');

    password.setValue(null);
    controlPassword.clearValidators();
    controlPassword.reset();
    this.clearNewAttribute();

    this.handleShowModal.emit();
  }


  /**
   * "Toca" todos os inputs para exibir possiveis erros antes da request.
   * Atribui o valor da variaveel newEmail para o login do administrador
   */
  handleSubmit(): void {
    this.formGroup.markAllAsTouched();
    if(this.formGroup.get('usuario.newEmail').value) {
      this.formGroup.get('usuario.login').setValue(this.formGroup.get('usuario.newEmail').value);
      this.clearNewAttribute();
    }
  }
}
