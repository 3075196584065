<section class="container-section">
  <div class="title-section">
    <h1>Dados da avaliação</h1>
  </div>

  <div class="data-section">
    <div class="survey-card-data">
      <h1 class="title-card">Coletas</h1>

      <div class="info-row">
        <div class="info-column">
          <h4 class="info-title">{{ overviewData?.resumo_coletas.efetuadas || 0}}</h4>
          <p>Efetuadas</p>
        </div>

        <div class="info-column">
          <h4 class="info-title">{{ overviewData?.resumo_coletas.contratadas || 0 }}</h4>
          <p>Contratadas</p>
        </div>
      </div>

      <div class="info-column">
        <h4 class="info-title">{{ overviewData?.resumo_coletas.meta_auditoria || 0 }}</h4>
        <p>Meta de auditoria</p>
      </div>
    </div>

    <div class="survey-card-data">
      <h1 class="title-card">Distribuição de coletas</h1>

      <div class="info-row">
        <span class="icon-info">
          <i class="fa-light fa-location-dot"></i>
        </span>
        <h4 class="info-title">{{ overviewData?.configuracao.amostras_presenciais || 0 }}</h4>
        <p>Presencial</p>
      </div>

      <div class="info-row">
        <span class="icon-info">
          <i class="fa-regular fa-globe"></i>
        </span>
        <h4 class="info-title">{{ overviewData?.configuracao.amostras_online || 0 }}</h4>
        <p>online</p>
      </div>
    </div>

    <div class="survey-card-data">
      <h1 class="title-card">Resumo da auditoria</h1>

      <app-audit-pie-chart-component
        *ngIf="overviewData"
        [totalCollections]="overviewData?.resumo_coletas.efetuadas"
        [chartData]="overviewData?.resumo_coletas.resumo_auditoria"
      ></app-audit-pie-chart-component>
    </div>

    <div class="survey-card-data">
      <h1 class="title-card">Período da avaliação</h1>

      <div class="info-row">
        <span class="icon-info">
          <i class="fa-sharp fa-regular fa-calendar-arrow-up"></i>
        </span>
        <p>Início : {{ overviewData?.data_inicial || '00/00/00' }}</p>
      </div>
      <div class="info-row">
        <span class="icon-info">
          <i class="fa-sharp fa-regular fa-calendar-arrow-down"></i>
        </span>
        <p>Fim : {{ overviewData?.data_final || '00/00/00' }}</p>
      </div>
    </div>
  </div>
</section>
