import { Component } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { TipoConteudo } from '../../../../questionario/componentes/secao/conteudo/tipoConteudo';
import { AbstractPerguntaMarcacaoComponent } from '../../../abstractPerguntaMarcacaoComponent';

@Component({
  selector: 'app-foto-marcacao',
  templateUrl: './foto-marcacao.component.html',
  styleUrls: ['./foto-marcacao.component.scss'],
})
export class FotoMarcacaoComponent extends AbstractPerguntaMarcacaoComponent {

  /**
   * @override
   */
  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_FOTO_MARCACAO;

  /**
   * @override
   */
  tipoPergunta: TipoPergunta = TipoPergunta.FOTO;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
