import { SituacaoListagemDeColetas } from "../constant";
import { ICriterio } from "./audit-collections-section";

export interface ISurveyCollectionSummary {
  id: number;
  horaInicio: string;
  horaFim: string;
  data: string;
  situacao: string;
  operador: string;
  localidade: string;
  criterios: ICriterio[];
  pesquisa?: string;
}

export interface IQuestionResponse {
  audio: string;
  perguntas: IQuestion;
  // Só estará disponível após o review da TC-2298
  situacao_coleta?: SituacaoListagemDeColetas
}

export interface IQuestion {
  id: number;
  ordem: number;
  enunciado: string;
  obrigatoria: boolean;
  tipo_pergunta: string;
  alternativas?: IAlternative[];
  definicoes?: ISettings;
  respostas: IAnswares;
}

export interface IAlternative {
  id: number;
  enunciado: string;
  ordem: number;
  tipo_alternativa: string;
}

export interface ISettings {
  numero_maximo_respostas: number;
  numero_minimo_respostas: number;
  titulo_alternativas: IAlternativeTitle[];
}

export interface IAlternativeTitle {
  id: number;
  enunciado: string;
  ordem: number;
}

export interface IAnswares {
  auditor: IAnswaresList[] | string;
  entrevistador: IAnswaresList[] | string;
}

export interface IAnswaresList {
  id_alternativa: number;
  id_titulo_alternativa?: number;
  numero?: number;
  texto?: string;
}
