import { Component, OnInit, OnDestroy } from "@angular/core";
import { OperadorService } from "../servico/operador.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ListagemGenerica } from "../../listagem/listagemGenerica";
import { Operador } from "../operador";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { Authority } from "app/infraestrutura/security/authority";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-operador-listagem",
  templateUrl: "./operador.listagem.component.html",
  styleUrls: ["./operador.listagem.component.scss"],
})

// tslint:disable-next-line:max-line-length
export class OperadorListagemComponent
  extends ListagemGenerica<Operador>
  implements OnDestroy, OnInit
{
  nomeAtributosTabela = ["nome", "matricula", "periodoFinal"];
  nomeColunasTabela = ["Id", "Nome", "Matrícula", "Fim do vínculo"];
  placeholder: string = "Ex.: joão";
  initialSearchValue: string = "";

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("search")) {
      this.initialSearchValue = this.route.snapshot.paramMap.get("search");
    }
  }

  /**
   * Permissões necessárias para realizar a remoção do item
   * da tabela
   */
  operadorRemovePermissions = [Authority.REMOVER_OPERADOR];

  constructor(
    public operadorService: OperadorService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected securityService: SecurityService,
    protected modalService: ModalService
  ) {
    super(securityService, "operador", operadorService, router, modalService);
  }
}
