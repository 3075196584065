import { ChangeDetectorRef, Component } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../../environments/environment";
import { Cliente } from "../../../../cliente/cliente";
import { PesquisaCadastro } from "../../model/pesquisaCadastro";
import { CadastroPesquisaStoreState } from "../../store/cadastroPesquisaStoreState";
// tslint:disable-next-line: max-line-length
import { RequestService } from "app/servico/request.service";
import * as moment from "moment/moment";
import {
  StatusPesquisa,
  StatusPesquisaNomeAmigavel,
} from "../../../../pesquisa-old/status/statusPesquisa";
import { StatusPesquisaDestino } from "../../../../pesquisa-old/status/statusPesquisaDestino";
import { StatusPesquisaManager } from "../../../../pesquisa-old/status/statusPesquisaManager";
import { AgendamentoColeta } from "../../model/agendamentoColeta";
import { ItemProtegido } from "../../security/itemProtegido";
import { PesquisaAuthorityService } from "../../security/pesquisaAuthorityService";
import {
  AlterarAnalisePesquisa,
  AlterarCliente,
  AlterarConfiguracaoPesquisa,
  AlterarDescricaoPesquisa,
  AlterarStatusPesquisa,
  AlterarTitulos,
} from "../../store/actions/caracteristicasAction";
import { SetPreparadoParaSalvar } from "../../store/actions/controleGeralAction";
import { PassoCadastro } from "../../store/passoCadastro";
import { PassoComponent } from "../passoComponent";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-caracteristicas",
  templateUrl: "./caracteristicas.component.html",
  styleUrls: ["./caracteristicas.component.scss"],
})
export class CaracteristicasComponent extends PassoComponent {
  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl } = {};
  passoCadastro: PassoCadastro = PassoCadastro.CARACTERISTICAS;

  statusPesquisaDestino: StatusPesquisaDestino[] = [];
  /**
   * Status da pesquisa que podem
   * ser selecionados
   */
  statusPesquisaSelecao: StatusPesquisa[] = <StatusPesquisa[]>(
    Object.keys(StatusPesquisa)
  );
  /**
   * Mapa para o nome amigável do status
   */
  statusPesquisaNomeAmigavel = StatusPesquisaNomeAmigavel;

  /**
   * Datas de coleta previamente selecionadas
   */
  datasPreviamenteSelecionadas: Date[] = [];

  /**
   * Marcacao que indica se trata-se de uma edicao
   */
  isEdicao: boolean = false;

  /**
   * Utilizado como axuliar no tratamento
   * assíncrono dos status e da pesquisa
   * que pode ser editada
   */
  pesquisaToBeInitialized: PesquisaCadastro;
  statusOriginalPesquisa: StatusPesquisa;

  /**
   * Marcador que indica se modificações
   * estão habilitadas no passo
   */
  modificacaoPermitida = false;
  /**
   * Marcador que indica se o passo
   * esta bloqueado
   */
  passoBloqueado = false;
  /**
   * Marcador que indica se o usuário
   * pode realizar alterações no status
   * da pesquisa
   */
  modificarStatusPermitido = false;
  /**
   * Marcador que indica se o usuario pode
   * adicionar um novo cliente ao sistema
   */
  adicionarClientePermitido = false;

  // tslint:disable-next-line: max-line-length
  // Objeto responsável por notificar ao componente de calendário sempre que houve uma mudança de status da pesquisa
  // tslint:disable-next-line: max-line-length
  subjectMudancaCalendar: BehaviorSubject<StatusPesquisa> =
    new BehaviorSubject<StatusPesquisa>(undefined);

  // tslint:disable-next-line: max-line-length
  constructor(
    private requestService: RequestService,
    private pesquisaAuthorityService: PesquisaAuthorityService,
    protected store: Store<CadastroPesquisaStoreState>,
    private statusPesquisaManager: StatusPesquisaManager,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService
  ) {
    super(store);
    moment.locale("pt-br");
  }

  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit();

    this.initFormGroup(new PesquisaCadastro());

    const pesquisaObservable: Observable<CadastroPesquisaStoreState> =
      this.store.pipe(
        // select('cadastroPesquisa'),
        map((x) => x["cadastroPesquisa"]),
        map((pesquisa) =>
          pesquisa ? pesquisa : new CadastroPesquisaStoreState()
        )
      );

    const storeObservableSubscription = pesquisaObservable.subscribe(
      (store) => {
        this.handlePrepararParaSalvar(store);

        const dadosPassoCaracteristicas = store.dadosPasso.get(
          PassoCadastro.CARACTERISTICAS
        );

        this.isEdicao = store.isEdicao;

        const pesquisa = store.pesquisa;
        this.prepararDatasColetaPreviamenteSelecionadas(pesquisa);

        this.statusOriginalPesquisa = dadosPassoCaracteristicas.statusOriginal;

        if (this.statusOriginalPesquisa) {
          this.prepararStatusParaSelecao(this.statusOriginalPesquisa, pesquisa);
        }

        this.initFormGroup(pesquisa);
      }
    );
    this.subscriptions.push(storeObservableSubscription);
  }

  initFormGroup(pesquisa: PesquisaCadastro) {
    this.formGroup = PesquisaCadastro.getInitializedControl(pesquisa);
    this.controls = this.formGroup.controls;

    this.verificarPermissaoAlteracao();
    this.passoBloqueado = this.passosBloqueados.includes(this.passoCadastro);
    if (!this.modificacaoPermitida || this.passoBloqueado) {
      this.changeDetectorRef.detectChanges();
      this.formGroup.disable();
      if (this.isEdicao && this.modificarStatusPermitido) {
        this.controls.status.enable();
      }
    } else if (this.modificacaoPermitida) {
      this.changeDetectorRef.detectChanges();
      this.formGroup.enable();
      if (!this.isEdicao || !this.modificarStatusPermitido) {
        this.controls.status.disable();
      }
    }
  }

  /**
   *
   */
  generateFormUrl() {
    const base = environment.formsUrl;
    const token = this.controls.configuracaoPesquisa.get("token").value;
    return token ? `${base}?token=${token}` : null;
  }

  /**
   * copy forms url with token
   */
  copyToClipboard() {
    // forms url
    const formsUrlWithToken = this.generateFormUrl();
    // copy
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = formsUrlWithToken;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    // show message
  }

  /**
   * @override
   */
  verificarPermissaoAlteracao() {
    // tslint:disable-next-line: max-line-length
    this.adicionarClientePermitido =
      this.pesquisaAuthorityService.usuarioPodeModificar(
        ItemProtegido.CADASTRAR_CLIENTE
      );

    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida =
      this.pesquisaAuthorityService.usuarioPodeModificar(
        ItemProtegido.CARACTERISTICAS
      ) || !this.isEdicao;

    // tslint:disable-next-line: max-line-length
    this.modificarStatusPermitido =
      this.pesquisaAuthorityService.usuarioPodeModificar(
        ItemProtegido.MUDANCA_STATUS
      );
  }

  /**
   * Manipula o caso de preparacao para salvar/atualizar
   * a pesquisa
   */
  handlePrepararParaSalvar(store: CadastroPesquisaStoreState) {
    const dadosPassoCaracteristicas = store.dadosPasso.get(
      PassoCadastro.CARACTERISTICAS
    );

    const iniciarPreparacaoParaSalvar =
      store.dadosGeraisCadastro.prepararParaSalvar;
    const preparado = dadosPassoCaracteristicas.preparadoParaSalvar;
    if (iniciarPreparacaoParaSalvar && !preparado) {
      this.store.dispatch(
        new SetPreparadoParaSalvar(PassoCadastro.CARACTERISTICAS, true)
      );
    }
  }

  /**
   * Prepara os itens de status que estarão
   * disponíveis para seleção
   */
  // tslint:disable-next-line: max-line-length
  prepararStatusParaSelecao(
    statusOriginalPesquisa: StatusPesquisa,
    pesquisaAtual: PesquisaCadastro
  ) {
    /**
     * Pesquisas em execucao com entrevistas realizadas, nao podem ter seu status alterado
     */
    // tslint:disable-next-line: max-line-length
    if (
      statusOriginalPesquisa === StatusPesquisa.EXECUCAO &&
      pesquisaAtual.entrevistasRealizadas > 0
    ) {
      this.statusPesquisaSelecao = [
        StatusPesquisa.EXECUCAO,
        StatusPesquisa.CONCLUIDO,
      ];
      return;
    }

    // tslint:disable-next-line: max-line-length
    this.statusPesquisaDestino =
      this.statusPesquisaManager.getDestinosPossiveis(
        this.statusOriginalPesquisa
      );
    console.log("Buscando destinos possíveis", this.statusPesquisaDestino);

    // tslint:disable-next-line: max-line-length
    const destinosPossiveis = this.statusPesquisaManager.getDestinosPossiveis(
      statusOriginalPesquisa
    );
    this.statusPesquisaSelecao = destinosPossiveis.map(
      (statusDestino) => statusDestino.destino
    );
  }

  /**
   * Prepara as datas de coleta previamente selecionadas, convertendo
   * de string dd/MM/aaaa para um objeto Date
   */
  prepararDatasColetaPreviamenteSelecionadas(pesquisa: PesquisaCadastro) {
    const datasString = pesquisa.configuracaoPesquisa.agendamentos.map(
      (agendamentoColeta) => {
        return agendamentoColeta.dataColeta;
      }
    );

    const datasConvertidas = datasString.map((dataString) => {
      const momentDate = moment(dataString, "DD/MM/YYYY");
      const date = momentDate.toDate();
      return date;
    });

    this.datasPreviamenteSelecionadas = datasConvertidas;
  }

  /**
   * Metodo chamado quando um cliente é selecionado
   */
  onClienteSelecionado(cliente: Cliente) {
    this.controls.cliente.setValue(cliente);
    this.store.dispatch(new AlterarCliente(cliente));
  }

  /**
   * Callback executado quando o status da pesquisa é alterado via seleção no template.
   * É verificado se o novo status da pesquisa necessita que a pesquisa esteja em um estado válido,
   * caso esteja a alteração eh propagada para a store, caso contrario, um alerta sera exibido para
   * o usuario informando da impossibilidade da alteracao de status
   */
  onStatusPesquisaChange(novoStatus: StatusPesquisa) {
    this.store.dispatch(new AlterarStatusPesquisa(this.controls.status.value));
    // a cada mudança de status o Calendário é notificado
    this.subjectMudancaCalendar.next(novoStatus);
  }

  onAlterarDescricaoPesquisa() {
    this.store.dispatch(
      new AlterarDescricaoPesquisa(this.controls.descricaoPesquisa.value)
    );
  }

  onAlterarConfiguracaoPesquisa() {
    this.store.dispatch(
      new AlterarConfiguracaoPesquisa(this.controls.configuracaoPesquisa.value)
    );
  }

  onAlterarAnalisePesquisa() {
    this.store.dispatch(
      new AlterarAnalisePesquisa(this.controls.analisePesquisa.value)
    );
  }

  onAlterarTitulos() {
    const descricaoPesquisaFg = <UntypedFormGroup>(
      this.controls.descricaoPesquisa
    );
    // tslint:disable-next-line: max-line-length
    this.store.dispatch(
      new AlterarTitulos(
        descricaoPesquisaFg.controls.titulo.value,
        descricaoPesquisaFg.controls.tituloCurto.value,
        this.controls.textoInicial.value
      )
    );
  }

  onCalendarioChange(datas: Date[]) {
    const configuracaoPesquisaFg = <UntypedFormGroup>(
      this.controls.configuracaoPesquisa
    );
    const agendamentosFormArray = <UntypedFormArray>(
      configuracaoPesquisaFg.controls.agendamentos
    );
    agendamentosFormArray.controls.splice(0);
    agendamentosFormArray.patchValue([]);

    datas.forEach((data) => {
      const agendamentoColeta = new AgendamentoColeta(
        moment(data).format("DD/MM/YYYY")
      );
      const agendamentoColetaFg =
        AgendamentoColeta.getInitializedControl(agendamentoColeta);

      agendamentosFormArray.push(agendamentoColetaFg);
    });

    this.store.dispatch(
      new AlterarConfiguracaoPesquisa(configuracaoPesquisaFg.value)
    );
  }
}
