import { Grupo } from 'app/modulos/grupo/grupo';
import { Permissao } from 'app/modulos/permissao/permissao';

export class Usuario {

  id: number;
  login: string;
  senha: string;
  senha_nova: string;
  senhaConfirmar: string;
  senha_atual: string;
  mudarPassword: string;
  ativo: boolean = true;
  permissoes: Permissao[];
  grupos: Grupo[];
}
