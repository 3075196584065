<div class="table-header">
  <div class="checkbox-wrapper">
    <app-checkbox
      [isChecked]="selectAll"
      name="select-all"
      (click)="handleOnSelectAllItems($event)"
    ></app-checkbox>
  </div>
  <div class="header-wrapper">
    <div class="header-item">
      <span>Nome</span>
    </div>
    <div class="header-item">
      <span>CPF/CNPJ</span>
    </div>
    <div class="header-item">Situação</div>
    <div class="header-item"></div>
  </div>
</div>
<div class="table-body">
  <div
    class="table-item"
    [ngClass]="isChecked(item.id) && 'checked'"
    *ngFor="let item of clientes; let i = index"
  >
    <div class="checkbox-wrapper">
      <app-checkbox
        [isChecked]="isChecked(item.id)"
        name="select-item"
        (click)="handleOnSelectItem($event, item.id, item.situacao)"
      ></app-checkbox>
    </div>
    <div class="item-wrapper">
      <div class="item-content">
        <p>{{ item.nome }}</p>
      </div>
      <div class="item-content">
        <p>{{ item.cpfCnpj }}</p>
      </div>
      <div class="item-content">
        <div class="item-content">
          <div class="status-type">
            <div class="item-status-wrapper">
              <span
                class="status"
                [ngClass]="{ ativo: item.situacao, inativo: !item.situacao }"
              ></span>
              {{ item.situacao ? "Ativo" : "Inativo" }}
            </div>
          </div>
        </div>
      </div>
      <div class="item-content">
        <app-kebab-cliente
          [situacao]="item.situacao"
        ></app-kebab-cliente>
      </div>
    </div>
  </div>
</div>
