// tslint:disable-next-line: max-line-length
import { Directive } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from "@ngrx/store";
import cotasValidator from 'app/util/validador/alternativa/cotasValidator';
import Big from 'big.js';
import { Marcacao } from '../../model/marcacao';
import { Pergunta } from '../../model/pergunta';
import { PerguntaIdentificador } from '../../model/perguntaIdentificador';
import { ItemProtegido } from '../../security/itemProtegido';
import { PesquisaAuthorityService } from "../../security/pesquisaAuthorityService";
import { AlterarPergunta } from '../../store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from "../../store/cadastroPesquisaStoreState";
import { PassoCadastro } from "../../store/passoCadastro";
import addPuloEDiferenteValidators from '../../util/addPuloEDiferenteValidators';
import distribuirCotasNumericas from '../../util/distribuirCotasNumericas';
import { AbstractPerguntaConteudo } from '../questionario/componentes/secao/conteudo/abstractPerguntaConteudo';

@Directive()
export abstract class AbstractPerguntaConteudoMarcacao extends AbstractPerguntaConteudo {
  constructor(pesquisaAuthorityService: PesquisaAuthorityService, store: Store<CadastroPesquisaStoreState>, passoCadastro?: PassoCadastro) {
    super(pesquisaAuthorityService, store)
  }
  /**
   * @override
   */
  ngOnInit() {

    /**
     * Caso a pergunta possua 'pulo' ou 'diferente de', o formGroup
     * com esses tipos de marcação serão adicionados mesmo que
     * a pergunta não os possua;
     *
     * OBS: Quando a operação for finalizada, será verificado se
     * os pulos e diferenças foram configurados, se sim, eles são
     * enviados à store, caso negativo, terão a marcação removida
     * da alternativa e então enviados para a store;
     */
    this.adicionarMarcacoesCasoNecessario(this.source);

    super.ngOnInit();
    this.addCotaValidators();
    this.addPuloEDiferenteValidators();

    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.MARCACOES);
    if (!this.modificacaoPermitida) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
    this.disableQuestionarioControls();
  }

  /**
  * Verifica se as alternativas possuem as marcacoes necessarias
  * para a operação;
  *
  * Perguntas de pulo e diferença são adicionadas para configuração
  * e caso não sejam configuradas, são removidas;
  */
  public adicionarMarcacoesCasoNecessario(pergunta: Pergunta) {
    const temp = JSON.parse(JSON.stringify(pergunta));
    this.source = temp;

    // tslint:disable-next-line: max-line-length
    const adicionarPerguntaIdentificador = (marcacoes: Marcacao[], atributoMarcacao: string, indicador: string) => {
      marcacoes.forEach((marcacao) => {
        if (!marcacao[atributoMarcacao]) {
          marcacao[atributoMarcacao] = new PerguntaIdentificador();
          marcacao[indicador] = true;
        }
      });
    };

    const adicionarCotas = (marcacoes: Marcacao[]) => {
      marcacoes.forEach((marcacao) => {
        if (!marcacao.cotaPercentual) {
          marcacao.cotaPercentual = '00,00';
          marcacao.cotaValor = '0';
          marcacao.possuiCota = true;
        }
      });
    };

    if (temp.possuiPulo || temp.possuiDiferenteDe || temp.possuiCota) {
      const marcacoes = temp.alternativas.map((alternativa) => {

        if (!alternativa.marcacao) {
          alternativa.marcacao = new Marcacao();
        }

        return alternativa.marcacao;
      });

      if (temp.possuiPulo) {
        adicionarPerguntaIdentificador(marcacoes, 'perguntaDestino', 'possuiPulo');
      }

      if (temp.possuiDiferenteDe) {
        adicionarPerguntaIdentificador(marcacoes, 'perguntasDiferente', 'possuiDiferenteDe');
      }

      if (temp.possuiCota) {
        adicionarCotas(marcacoes);
      }
    }
  }

  public removerPuloEDiferencaCasoNecessario(pergunta: Pergunta) {

    // tslint:disable-next-line: max-line-length
    const removerPerguntaIdentificador = (marcacoes: Marcacao[], atributoMarcacao: string, indicador: string) => {
      marcacoes.forEach((marcacao) => {
        if (marcacao[atributoMarcacao] && !marcacao[atributoMarcacao].codigoMarcacao) {
          marcacao[atributoMarcacao] = null;
          marcacao[indicador] = false;
        }
      });
    };

    if (pergunta.possuiPulo || pergunta.possuiDiferenteDe) {
      const marcacoes = pergunta.alternativas.map(alternativa => alternativa.marcacao);

      if (pergunta.possuiPulo) {
        removerPerguntaIdentificador(marcacoes, 'perguntaDestino', 'possuiPulo');
      }

      if (pergunta.possuiDiferenteDe) {
        removerPerguntaIdentificador(marcacoes, 'perguntasDiferente', 'possuiDiferenteDe');
      }
    }
  }

  /**
   * Metodo que adiciona os validadores de pulo e diferente ao formGroup
   */
  addPuloEDiferenteValidators() {
    addPuloEDiferenteValidators(this.formGroup);
  }

  /**
    * Metodo que adiciona os validadores de cota ao formGroup
    */
  addCotaValidators() {

    if (this.controls.possuiCota.value) {

      const alternativasFormArray = <UntypedFormArray>this.formGroup.get('alternativas');

      const cotaPercentualControls: UntypedFormControl[] = [];
      alternativasFormArray.controls.forEach((alternativaControl: UntypedFormGroup) => {

        const marcacaoFg = <UntypedFormGroup>alternativaControl.controls.marcacao;

        const cotaPercentualControl = <UntypedFormControl>marcacaoFg.controls.cotaPercentual;

        /**
         * Coletando os controls das cotas percentuais
         */
        cotaPercentualControls.push(cotaPercentualControl);
      });

      /**
       * Adicionando os validadores de cota para cada
       * alternativa
       */
      alternativasFormArray.controls.forEach((alternativaControl: UntypedFormGroup) => {

        const marcacaoFg = <UntypedFormGroup>alternativaControl.controls.marcacao;
        const cotaPercentualControl = <UntypedFormControl>marcacaoFg.controls.cotaPercentual;

        /**
         * Na inicializacao do componente uma pesquisa vazia
         * é criada, por isso pode nao ter a cotaPercentualControl
         */
        if (cotaPercentualControl) {
          cotaPercentualControl.setValidators([cotasValidator(cotaPercentualControls)]);
        }
      });
    }
  }

  disableQuestionarioControls() {
    this.controls.nome.disable();
    this.controls.descricao.disable();
  }

  /**
   * Calcula e distribui cotas numéricas entre as
   * alternativas da pergunta.
   *
   * Quando 100% das cotas percentuais são distribuidas, é verificado
   * se a soma das cotas numericas correspondem ao total de amostras da pesquisa,
   * caso contrário, é feita a compensação para que os palores fiquem igualados.
   *
   * Essa compensação é necessária por conta do arredondamento de valores
   * durante o calculo das cotas.
   */
  calcularCotaNumerica() {
    /**
     * Recuperando os controladores das marcações
     */
    const alternativasFormArray = <UntypedFormArray>this.controls.alternativas;
    const marcacoesControls = alternativasFormArray
      .controls
      .map(alternativaControl => alternativaControl.get('marcacao'))
      .filter(marcacaoControl => marcacaoControl != null && marcacaoControl !== undefined);

    /**
     * Extraindo as cotas percentuais em valor numérico
     */
    const cotasPercentuais = marcacoesControls
      .map(marcacaoControl => marcacaoControl.get('cotaPercentual').value)
      .map((cotaPercentualString: string) => cotaPercentualString.replace(',', '.'))
      .map(cotaPercentualString => Big(cotaPercentualString));

    /**
     * A compensação só deve ocorrer caso a distribuição das cotas
     * tenha atingido 100%
     */
    // tslint:disable-next-line: max-line-length
    const totalPercentualDistribuido = Number(cotasPercentuais.reduce((parcelas, total) => parcelas.plus(total)).toFixed())
    if (totalPercentualDistribuido === 100) {
      /**
       * Calculando e distribuindo as cotas numericas
       */

      // convertendo para um array de números
      const newCotas = cotasPercentuais.map(item => Number(item))

      const cotasNumericas = distribuirCotasNumericas(this.totalAmostrasPesquisa, newCotas);
      for (let i = 0; i < marcacoesControls.length; i += 1) {
        const marcacaoControl = marcacoesControls[i];
        const cotaNumerica = cotasNumericas[i];
        marcacaoControl.get('cotaValor').patchValue(cotaNumerica);
      }
    }
  }

  /**
   * @override
   */
  onChange() {

    /**
     * Removendo pulos e diferenças caso estas não tenham sido configuradas
     */
    const perguntaConfigurada: Pergunta = this.formGroup.getRawValue();
    this.removerPuloEDiferencaCasoNecessario(perguntaConfigurada);

    this.store.dispatch(new AlterarPergunta(perguntaConfigurada));
  }

}
