import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from 'app/servico/request.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiLocation } from '../../apiLocation';
import { AuthUser } from '../authUser';
import { Authority } from '../authority';
import { ChangePasswordService } from './../../../componentes/change-password/change-password.service';
import { JWTService } from './jwtService';
import { IVerifyLoginCredentials, LoginCredentials } from './loginCredentials';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(
    private changePasswordService: ChangePasswordService,
    private requestService: RequestService,
    private jwtService: JWTService,
  ) { }

  login(credentials: LoginCredentials): Observable<HttpResponse<Object>> {
    const authorizationHeaderName = 'Authorization';
    const changePasswordToken = 'Change-Password-Token'

    const requestUrl = `${apiLocation}/security/login`;
    return this.requestService.post(requestUrl, {
      login: credentials.login,
      password: credentials.password
    }, false)
      .pipe(
        map((response: HttpResponse<Object>) => {
          const token = response.headers.get(authorizationHeaderName);
          const responseChangePasswordToken = response.headers.get(changePasswordToken)
          /**
           * Parâmetro de persistência alterado para true devido a nova funcionalidade
           * que permite o usuário abrir os menus do tensai em uma nova guia
           */
          this.jwtService.setToken(token, credentials.keepConnected);

          // caso exista o token de alteração de senha é repassado para o serviço
          if(responseChangePasswordToken) {
            this.changePasswordService.setChangePasswordToken(responseChangePasswordToken)
          }

          return response;
        }),
      );
  }

  /**
   * Realiza uma nova requisição de login para verificar credentials está correta.
   * @param credentials
   * @returns
   */
  verifyPassword(credentials: IVerifyLoginCredentials): Observable<any> {
    const data: IVerifyLoginCredentials = {
      login: credentials.login,
      password: credentials.password
    }

    const requestUrl = `${apiLocation}/security/login`;
    return this.requestService.post(requestUrl, data);
  }

  /**
   * Realiza a autenticação no tensai web através do token de integração enviado pelo serviço do sgmt.
   * @param token JWT token advindo da url para posterior autenticação no tensai
   */
  sgmtAuthenticate(token: string): Observable<HttpResponse<object>> {
    const authorizationHeaderName = 'Authorization';
    const requestUrl = `${apiLocation}/security/integration`;
    return this.requestService.post(requestUrl, { token }, false)
      .pipe(
        map((response: HttpResponse<object>) => {
          const resultToken = response.headers.get(authorizationHeaderName);
          this.jwtService.setToken(resultToken, true);

          return response;
        })
      )
  }

  /**
   * Realiza a requisição de autenticação à instância de um contratante específico, caso tenha sucesso,
   * a resposta retornará o token do contratante alvo.
   * @param schemaName Nome do esquema do contratante alvo da autenticação
   */
  switchContractorAuthenticate(schemaName: string): Observable<HttpResponse<object>> {
    const authorizationHeaderName = 'Authorization';
    const requestUrl = `${apiLocation}/security/contratantes/${schemaName}/switch`;
    return this.requestService.post(requestUrl, null, false)
      .pipe(
        map((response: HttpResponse<object>) => {
          const resultToken = response.headers.get(authorizationHeaderName);

          this.jwtService.setActiveAuthUserIdentifier(schemaName);
          this.jwtService.setToken(resultToken, false);

          return response;
        })
      )
  }

  logout() {
    this.jwtService.removeToken();
  }

  /**
   * Metodo para recuperar o token do localStorage.
   * @returns token
   */
  getJwt(): string {
    return this.jwtService.getToken();
  }

  /**
   * Retorna o usuario autenticado (logado) ou NULL caso nenhum esteja disponivel
   */
  getAuthenticatedUser(): AuthUser | null {
    const token = this.jwtService.getToken();

    if (!token) {
      return null;
    }

    const claims = this.jwtService.getTokenClaims(token);

    const authUser = new AuthUser();
    authUser.id = claims.id;
    authUser.login = claims.sub;
    authUser.name = claims.sub_name;
    authUser.photo = claims.sub_photo;
    authUser.tipoUsuario = claims.sub_tipo_usuario;
    authUser.authorities = claims.sub_permissions;
    authUser.subTenant = claims.sub_tenant;
    authUser.needChangePassword = claims.need_change_password;
    // FIXME: deixando sempre false, pois está impedido pelo back
    authUser.needSignDocuments = claims.need_sign_documents;

    return authUser;
  }

  /**
   * Retorna um booleano indicando se o usuário autenticado tem pendência
   * de assinatura em algum dos documentos LGPD do sistema tensai.
   */
  getAuthenticatedUserNeedSignDocuments(): boolean {
    const authenticatedUser = this.getAuthenticatedUser();

    if (authenticatedUser) {
      return authenticatedUser.needSignDocuments;
    }

    return false;
  }

  /**
   * Recupera as autorities do usuario logado
   */
  getAuthenticatedUserAuthorities(): Authority[] {
    const authenticatedUser = this.getAuthenticatedUser();
    if (authenticatedUser) {
      return authenticatedUser.authorities;
    }
    return [];
  }

  getAuthenticatedUserSubtenant(): string {
    const authenticatedUser = this.getAuthenticatedUser();
    if (authenticatedUser) {
      return authenticatedUser.subTenant;
    }
    return '';
  }

  // retorna se o usuário logado precisa trocar a senha
  getAuthenticatedUserNeedChangePassword(): boolean | null {
    const authenticatedUser = this.getAuthenticatedUser();
    if (authenticatedUser) {
      return authenticatedUser.needChangePassword;
    }
    return null
  }

  /**
   * Verifica se o usuario logado possui alguma
   * das autoridades passadas
   */
  userHasAuthorities(necessaryAuthorities: Authority[]) {

    const userAuthorities = this.getAuthenticatedUserAuthorities();

    const hasNecessaryAuthority = userAuthorities.some(userAuthority => necessaryAuthorities.includes(userAuthority));
    return hasNecessaryAuthority;
  }

}
