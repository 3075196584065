<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">
    
    <app-pergunta-card
    [icone]="'fa fa-video-camera'"
    [titulo]="ordem + ' - VÍDEO'"
    [nomePlaceHolder]="'Título da pergunta de vídeo?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>
