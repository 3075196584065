import { colorPalette } from "../colors"
import { barTooltipFormatter } from "../utils/tooltipFormatter"
import { EChartsOption } from 'echarts';
import { DEFAULT_BAR_WIDTH } from "./constants";
import { renderModes } from "./model";

function buildGridConfig(renderMode: renderModes) {
  if (!renderMode || renderMode === "DEFAULT") return {};

  if (renderMode === "EXPANSIBLE") {
    return {
      grid: {
        left: "0%",
        bottom: "20px",
        width: "100%"
      }
    }
  }
};

function buildLegendConfig(renderMode: renderModes) {
  const defaultConfig = { // posição da legenda
    width: '100%',
    orient: 'horizontal',
    type: 'scroll',
    top: 'top',
    left: "center",
    icon: 'circle',
  };

  if (renderMode === "EXPANSIBLE") {
    defaultConfig.left = "left";
  }

  return defaultConfig;
};

export function toEchartOption(chartData, renderMode: renderModes, resultadosNumericos: number[]): EChartsOption {
  const chartOption = {
    ...buildGridConfig(renderMode),
    xAxis: {
      data: [],
      axisLine: {
        show: false // define a propriedade show como false
      }
    },
    yAxis: {
      axisLabel: {
        show: false // não exibir os valores do eixo y
      },
      splitLine: {
        show: false, // não exibir as linhas no background
      },
    },
    color: colorPalette,
    series: chartData,
    label: {
      show: true, // exibir a label
      position: 'top', // posição da label na barra
      formatter: '{c}%', // adiciona % ao valor
      color: '#610082',
      fontWeight: 700,
      fontSize: '12px',
    },
    legend: buildLegendConfig(renderMode),
    tooltip: {
      trigger: 'item',
      confine: true,
      position: 'top',
      axisPointer: {
        type: 'shadow',
      },
      borderColor: '#610082',
      formatter: (params) => barTooltipFormatter(params, resultadosNumericos)
    }
  }

  return chartOption as EChartsOption;
}
