import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Cliente } from '../../../cliente/cliente';
import { StatusPesquisa } from '../../status/statusPesquisa';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';
import { AnalisePesquisa } from './analisePesquisa';
import { ConfiguracaoPesquisa } from './configuracaoPesquisa';
import { DescricaoPesquisa } from './descricaoPesquisa';
import { ElementoQuestionario } from './elementoQuestionario';
import { CotaPerfil, PerfilEntrevistado, PerfilEntrevistadoCaracteristica } from './perfilEntrevistado';
import { Vinculo } from './vinculo';
import { Vinculo as VinculoEdicao } from './vinculoEdicao';

export class PesquisaCadastro {

  id: number;
  cliente: Cliente;
  descricaoPesquisa: DescricaoPesquisa = new DescricaoPesquisa();
  configuracaoPesquisa: ConfiguracaoPesquisa = new ConfiguracaoPesquisa();
  analisePesquisa: AnalisePesquisa = new AnalisePesquisa;
  elementosQuestionario: ElementoQuestionario[] = [];
  textoInicial: string;
  tituloCurto: string;
  vinculos: Vinculo[] | VinculoEdicao[] = [];
  status: StatusPesquisa = StatusPesquisa.RASCUNHO;
  entrevistasRealizadas: number = 0;
  perfisEntrevistados: PerfilEntrevistado[] = [];

  static getControl(): UntypedFormGroup {

    return new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      cliente: new UntypedFormControl(undefined, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_CLIENTE)),
      descricaoPesquisa: DescricaoPesquisa.getControl(),
      configuracaoPesquisa: ConfiguracaoPesquisa.getControl(),
      analisePesquisa: AnalisePesquisa.getControl(),
      elementosQuestionario: new UntypedFormArray([], [Validators.required]),
      // tslint:disable-next-line: max-line-length
      textoInicial: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_TEXTO_INICIAL)),
      status: new UntypedFormControl(''),
      entrevistasRealizadas: new UntypedFormControl(0),
      perfisEntrevistados: new UntypedFormControl(),
    });
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(pesquisa: PesquisaCadastro): UntypedFormGroup {

    // tslint:disable-next-line: max-line-length
    const elementosQuestionarioFgs = pesquisa.elementosQuestionario.map(elementoQuestionario => ElementoQuestionario.getInitializedControl(elementoQuestionario));

    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      cliente: new UntypedFormControl(pesquisa.cliente, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_CLIENTE)),
      descricaoPesquisa: DescricaoPesquisa.getInitializedControl(pesquisa.descricaoPesquisa),
      // tslint:disable-next-line: max-line-length
      configuracaoPesquisa: ConfiguracaoPesquisa.getInitializedControl(pesquisa.configuracaoPesquisa),
      analisePesquisa: AnalisePesquisa.getInitializedControl(pesquisa.analisePesquisa),
      // tslint:disable-next-line: max-line-length
      elementosQuestionario: new UntypedFormArray(elementosQuestionarioFgs, [Validators.required]),
      // tslint:disable-next-line: max-line-length
      textoInicial: new UntypedFormControl(pesquisa.textoInicial, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_TEXTO_INICIAL)),
      status: new UntypedFormControl(pesquisa.status),
      entrevistasRealizadas: new UntypedFormControl(pesquisa.entrevistasRealizadas),
      perfisEntrevistados: new UntypedFormControl(pesquisa.perfisEntrevistados),
    });

    return formGroup;
  }

}

export function getPerfisTeste() {

  /**
   * caracteristicas
   */
  const caracteristica1 = new PerfilEntrevistadoCaracteristica();
  caracteristica1.id = 1;
  caracteristica1.alternativa = { id: 1, descricao: 'Masculino' };

  const caracteristica2 = new PerfilEntrevistadoCaracteristica();
  caracteristica2.id = 2;
  caracteristica2.alternativa = { id: 3, descricao: '18 - 25' };

  const caracteristica3 = new PerfilEntrevistadoCaracteristica();
  caracteristica3.id = 3;
  caracteristica3.alternativa = { id: 5, descricao: 'Pardo' };

  const caracteristica4 = new PerfilEntrevistadoCaracteristica();
  caracteristica4.id = 4;
  caracteristica4.alternativa = { id: 7, descricao: 'Gostou do festival' };

  const caracteristica5 = new PerfilEntrevistadoCaracteristica();
  caracteristica5.id = 5;
  caracteristica5.alternativa = { id: 9, descricao: 'Voltaria ano que vem' };

  /**
   * CotaPerfil
   */
  const cotaPerfil1 = new CotaPerfil();
  cotaPerfil1.id = 1;
  cotaPerfil1.cotaNumericaPrevista = 131;
  cotaPerfil1.cotaNumericaExecutada = 2;
  cotaPerfil1.vinculoOperador = { id: 1 };

  const cotaPerfil2 = new CotaPerfil();
  cotaPerfil2.id = 2;
  cotaPerfil2.cotaNumericaPrevista = 107;
  cotaPerfil2.cotaNumericaExecutada = 5;
  cotaPerfil2.vinculoOperador = { id: 2 };

  const perfil = new PerfilEntrevistado();
  perfil.id = 1;
  perfil.cotaNumerica = 20;
  // tslint:disable-next-line: max-line-length
  perfil.perfilEntrevistadoCaracteristicas = [caracteristica1, caracteristica2, caracteristica3, caracteristica4, caracteristica5];
  perfil.cotasPerfil = [cotaPerfil1, cotaPerfil2];

  return [perfil];
}
