import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGeneralVisionPodium } from 'app/modulos/produtividade-beta/interfaces';

@Component({
  selector: 'app-operator-card',
  templateUrl: './operator-card.component.html',
  styleUrls: ['./operator-card.component.scss']
})
export class OperatorCardComponent implements OnInit {

  @Input() operadorData: IGeneralVisionPodium | null;
  @Input() surveyId: number;
  @Input() position: number;

  defaultCard: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.operadorData === null) {
      this.defaultCard = true;
    }
  }

  defineAssessmentColor(): string {
    if(this.operadorData.avaliacao_geral > 7.9) {
      return 'green-assessment';
    } else if (this.operadorData.avaliacao_geral > 5.9) {
      return 'yellow-assessment';
    } else {
      return 'red-assessment';
    }
  }

  handleNavigate(operatorId): void {
    this.router.navigate([`/produtividade-beta/visao-geral/${this.surveyId}/entrevistadores/entrevistador/${operatorId}`]);
  }

}
