import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DadosPaginacao } from './dadosPaginacao';
import { Observable } from 'rxjs';
import { ConfiguracaoPaginacao } from './configuracaoPaginacao';

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.scss']
})
export class PaginacaoComponent implements OnInit {

  @ViewChild('paginacao', { static: true }) paginacao: MatPaginator;
  totalPaginas: number;
  tamanhoPagina: number = DadosPaginacao.DEFAULT_SIZE;
  indicePagina: number = DadosPaginacao.INITIAL_PAGE;

  /**
   * Lancador de eventos quando a paginacao sofre alguma alteracao
   */
  @Output() onPaginationChange: EventEmitter<DadosPaginacao> = new EventEmitter();

  /**
   * Indicador para avisar que a paginação deve ser reiniciada
   */
  @Input() indicadorReinicializacao: Observable<ConfiguracaoPaginacao>;

  ngOnInit() {
    this.indicadorReinicializacao.subscribe((configuracaoPaginacao) => {


      if (!configuracaoPaginacao) {
        return;
      }
      this.totalPaginas = configuracaoPaginacao.totalPaginas;
      this.indicePagina = configuracaoPaginacao.paginaAtual;

      if (this.indicePagina === 0) {
        this.paginacao.firstPage();
      }

      if (configuracaoPaginacao.emit) {

        // inicializando paginacao
        const pageEvent = new PageEvent();
        pageEvent.pageIndex = this.indicePagina;
        pageEvent.pageSize = this.tamanhoPagina;
        this.onPagination(pageEvent);
      }

    });

  }

  onPagination(evt: PageEvent) {
    this.tamanhoPagina = evt.pageSize;
    this.indicePagina = evt.pageIndex;

    const dadosPaginacao = new DadosPaginacao(this.tamanhoPagina, this.indicePagina);

    this.onPaginationChange.emit(dadosPaginacao);
  }

}
