import { Injectable } from "@angular/core";
import { ModalService } from "app/componentes/modal/modal.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import numberFormatter from "app/util/misc/numberFormatter";
import { Localidade } from "../localidade";
import { LocalidadePai } from "../localidadePai";
import { LocalidadeService } from "../servico/localidade.service";

interface AddressBody {
  geoId: string | null;
  cep: string | null;
  pais: string | null;
  estado: string | null;
  cidade: string | null;
  enderecoCompleto: string | null;
  tipo?: string | null;
}

interface LocalidadeProps {
  nome: string;
  latitude: string;
  longitude: string;
  raio: number;
  endereco?: AddressBody;
}

interface LocalidadeParentProps {
  id: number;
  nome: string;
}

interface ICoordinateArray {
  module: [number, number];
}

interface ICoordinates {
  arrayOfCoordinates: ICoordinateArray[];
}

interface IMarkedArea {
  coordinates?: ICoordinates;
}

interface IResponseToMountMap {
  latAndLong: {
    lat: number;
    lon: number;
  };
  boundingBox: {
    latMin: number;
    latMax: number;
    lonMin: number;
    lonMax: number;
  };
  data: IMarkedArea;
  canMountBoundary: boolean;
  type: string;
  radius: number,
}

type EditModeENumProps = "FATHER" | "CHILDREN";

type WhoIsToEdit = "NONE" | "FATHER" | "CHILDREN";

@Injectable()
export class ModalCreadUpdateService {
  localidadePai = null;
  localidadeFilhas = [];
  localidadeFilhaId = null;
  localidadeFilhaData;

  currentUserLat: number;
  currentUserLon: number;
  currentUserRadius: number;

  latMin: number;
  latMax: number;
  longMin: number;
  longMax: number;

  mapDataToMount: IMarkedArea;

  canUpdateMap: boolean = false;

  canMountMap: boolean = true;

  typeMap: string = 'Polygon' || 'Point' || 'LineString' || 'Circle';

  // PROPRIEDADES CONTROLADORAS REFERENTE AOS COMPONENTES QUE SERÃO EXIBIDOS EM TELA

  editMode: boolean = false;

  isToEditFilha: boolean = false;

  editModeEnum: EditModeENumProps = "FATHER";

  createChildren: boolean = false;

  canShowChildren: boolean = false;

  canStretchContainer: boolean = false;

  canShowGoBackButton: boolean = false;

  canShowForm: boolean = true;

  canShowManualForm: boolean = false;

  // PROPRIEDADES PARA O FORMULÁRIO OUVIR
  whichIdShouldILook: number = null;
  whoIsToEdit: WhoIsToEdit = "NONE";

  idFilhaListNumber: number = null;
  idFilhaApiNumber: number = null;

  constructor(
    private localidadeService: LocalidadeService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: ModalService
  ) {}

  // Função responsável por preparar a variável para ser enviada a API
  prepararEntidadeParaPersistencia(localidade: Localidade): Localidade {
    // se o input do form de localidade pai for vazio, seta a localidade pai como null
    if (this.editMode) {
      localidade.localidadesFilhas = localidade.localidadesFilhas;
    } else {
      if (
        localidade.localidadePai.nome == null ||
        localidade.localidadePai.nome === ""
      ) {
        localidade.localidadePai = new LocalidadePai();
      }
    }

    const longitudeFormatada = numberFormatter.replacePerPoint(
      localidade.longitude
    );
    const latitudeSFormatada = numberFormatter.replacePerPoint(
      localidade.latitude
    );

    localidade.longitude = parseFloat(longitudeFormatada);
    localidade.latitude = parseFloat(latitudeSFormatada);

    // caso o usuario digite um nome de uma localidade  que nao existe,
    // entao a localidade atual não possuirá pai
    if (
      localidade.localidadePai.id === null ||
      localidade.localidadePai === null
    ) {
      localidade.localidadePai = new LocalidadePai();
    }

    return localidade;
  }

  handleUpdateLocalidadePaiDataAfterAnPostOrPut(idApi: number) {
    const requestObservable = this.localidadeService.listarLocalidadeById(
      String(idApi)
    );
    requestObservable.subscribe((response) => {
      this.localidadePai = response;
    });
  }

  // CADASTRAR LOCALIDADE FILHA NA API
  saveLocalidade(data: LocalidadeProps, parent: LocalidadeParentProps): void {
    //
    const localidadeToSave = {
      id: null,
      nome: data.nome,
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
      raio: data.raio,
      localidadePai: {
        id: parent ? parent.id : null,
        nome: parent ? parent.nome : null,
      },
      isFilho: parent !== null,
      isPai: parent === null,
      localidadesFilhas: [],
      versao: null,
      endereco: {
        geoId: data.endereco.geoId,
        cep: data.endereco.cep ? data.endereco.cep : null,
        pais: data.endereco.pais,
        estado: data.endereco.estado,
        cidade: data.endereco.cidade,
        enderecoCompleto: data.endereco.enderecoCompleto,
        tipo: data.endereco.tipo
      },
    };
    //
    const localidadeParaSalvar =
      this.prepararEntidadeParaPersistencia(localidadeToSave);
    //
    const requestObservable = this.localidadeService
      .salvar(localidadeParaSalvar)
      .subscribe({
        next: (response) => {
          if (parent !== null) {
            this.localidadeFilhas.push(response);
            this.notificatorService.showInfo(
              "Localidade cadastrada",
              "A localidade foi cadastrada com sucesso!"
            );
            this.createChildren = true;
            this.canShowChildren = true;
            this.canStretchContainer = true;
            this.canShowForm = false;
            this.canShowManualForm = false;
            this.canShowGoBackButton = false;
            this.handleUpdateLocalidadePaiDataAfterAnPostOrPut(
              this.localidadePai.id
            );
          } else {
            this.localidadePai = response;
            this.notificatorService.showInfo(
              "Localidade cadastrada",
              "A localidade foi cadastrada com sucesso!"
            );
            this.createChildren = true;
            this.canShowChildren = true;
            this.canShowForm = false;
            this.canShowManualForm = false;
            this.canShowGoBackButton = false;
          }
        },
        error: (error) => {
          if (error) {
            this.errorHandlerService.handleError(error, "Localidade não cadastrada");
          }
        },
      });
  }

  // CADASTRAR LOCALIDADE PAI NA API
  saveLocalidadePai(data: LocalidadeProps): void {
    this.saveLocalidade(data, null);
  }

  // CADASTRAR LOCALIDADE FILHA NA API
  saveLocalidadeFilha(data: LocalidadeProps): void {
    const parent = {
      id: this.localidadePai.id,
      nome: this.localidadePai.nome,
    } as LocalidadeParentProps;
    this.saveLocalidade(data, parent);
  }

  // EDITAR LOCALIDADES
  editLocalidadeFilha(idList: number, idApi: number): void {
    this.canShowChildren = false;
    this.canShowForm = true;

    this.canShowGoBackButton = true;
    this.createChildren = true;
    this.whoIsToEdit = "CHILDREN";
    this.whichIdShouldILook = idApi;
    this.localidadeFilhaId = idList;

    let requestObservable;

    requestObservable = this.localidadeService.listarLocalidadeById(
      String(idApi)
    );

    requestObservable.subscribe({
      next: (response) => {
        this.localidadeFilhaData = response;
      },
      error: (error) => {
        if (error) {
          this.errorHandlerService.handleError(error, "Localidade não cadastrada");
        }
      },
    });
  }

  updateLocalidadeFilha(data: LocalidadeProps): void {
    //
    const localidadeToUpdate = {
      id: this.localidadeFilhaData.id,
      nome: data.nome,
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
      raio: data.raio,
      localidadePai: {
        id: this.localidadePai.id,
      },
      localidadesFilhas: this.localidadeFilhaData.localidadesFilhas.map(
        (filha) => ({
          id: filha.id,
        })
      ),
      isFilho: this.localidadeFilhaData.isFilho,
      isPai: this.localidadeFilhaData.isPai,
      versao: this.localidadeFilhaData.versao,
      endereco: {
        geoId: data.endereco.geoId,
        cep: data.endereco.cep ? data.endereco.cep : null,
        pais: data.endereco.pais,
        estado: data.endereco.estado,
        cidade: data.endereco.cidade,
        enderecoCompleto: data.endereco.enderecoCompleto,
      },
    };
    //
    const requestObservable = this.localidadeService.atualizarLocalidade(
      localidadeToUpdate,
      false
    );
    requestObservable.subscribe({
      next: (response) => {
        this.localidadeFilhas[this.localidadeFilhaId] = response;
        this.notificatorService.showInfo(
          "Localidade atualizada",
          "A localidade foi atualizada com sucesso!"
        );
        this.handleUpdateLocalidadePaiDataAfterAnPostOrPut(
          this.localidadePai.id
        );
      },
      error: (error) => {
        if (error) {
          this.errorHandlerService.handleError(error, "Localidade não cadastrada")
        }
      },
    });
  }

  editLocalidadePai(idApi: number): void {
    this.canShowChildren = false;
    this.canShowGoBackButton = true;
    this.canShowForm = true;
    this.createChildren = false;
    this.whoIsToEdit = "FATHER";
    this.whichIdShouldILook = idApi;
  }

  updateLocalidadePai(data: LocalidadeProps): void {
    const localidadeToUpdate = {
      id: this.localidadePai.id,
      nome: data.nome,
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
      raio: data.raio,
      localidadePai: {
        id: null,
      },
      localidadesFilhas: this.localidadePai.localidadesFilhas.map((filha) => ({
        id: filha.id,
      })),
      isFilho: this.localidadePai.isFilho,
      isPai: this.localidadePai.isPai,
      versao: this.localidadePai.versao,
      endereco: {
        geoId: data.endereco.geoId,
        cep: data.endereco.cep ? data.endereco.cep : null,
        pais: data.endereco.pais,
        estado: data.endereco.estado,
        cidade: data.endereco.cidade,
        enderecoCompleto: data.endereco.enderecoCompleto,
        tipo: data.endereco.tipo
      },
    };
    //
    const requestObservable = this.localidadeService.atualizarLocalidade(
      localidadeToUpdate,
      false
    );
    requestObservable.subscribe({
      next: (response) => {
        this.localidadePai = response;
        this.notificatorService.showInfo(
          "Localidade atualizada",
          "A localidade foi atualizada com sucesso!"
        );
        this.handleUpdateLocalidadePaiDataAfterAnPostOrPut(
          this.localidadePai.id
        );
      },
      error: (error) => {
        if (error) {
          this.errorHandlerService.handleError(error, "Localidade não cadastrada")
        }
      },
    });
  }

  // EXCLUIR LOCALIDADES
  deleteLocalidadePai(idApi: number) {
    this.modalService.showModal({
      title: 'Excluir Localidade',
      messageModal: 'Ao excluir a localidade pai você perderá todas as localidades filhas, sendo necessário cadastrar novamente',
      btnTitlePositive: 'Excluir',
      btnTitleNegative: 'Cancelar',
      imgFile: 'icons/icon-warning.svg',
      positiveCallback: () => {
        let requestObservable;

        requestObservable = this.localidadeService.deletarLocalidade(idApi);

        requestObservable.subscribe(() => {
          this.setResetModal();
          return;
        });
        this.notificatorService.showInfo(
          "Exclusão realizada",
          "Localidade filha excluída com sucesso!"
        );
      }
    })
  }

  deleteLocalidadeFilha(idList: number, idApi: number): void {
    this.modalService.showModal({
      title: 'Excluir Localidade',
      messageModal: 'Ao excluir a localidade você perderá os dados referentes a mesma',
      btnTitlePositive: 'Excluir',
      btnTitleNegative: 'Cancelar',
      imgFile: 'cons/icon-warning.svg',
      positiveCallback: () => {
        let requestObservable;

        requestObservable = this.localidadeService.deletarLocalidade(idApi);

        requestObservable.subscribe(() => {
          this.localidadeFilhas.splice(idList, 1);
          return;
        });
        this.notificatorService.showInfo(
          "Exclusão realizada",
          "Localidade filha excluída com sucesso!"
        );
      }
    })
  }

  // CARREGAR LOCALIDADE FILHAS
  mountStructureOfLocalidadesFilhas(): void {
    let requestObservable;

    this.localidadePai.localidadesFilhas.map((filha) => {
      requestObservable = this.localidadeService.listarLocalidadeById(
        String(filha.id)
      );

      requestObservable.subscribe((response) => {
        this.localidadeFilhas.push(response);

        if (response.id === this.idFilhaApiNumber) {
          this.editLocalidadeFilha(
            this.idFilhaListNumber,
            this.idFilhaApiNumber
          );
        }
      });
    });
  }

  // CARREGAR MODO EDIÇÃO
  mountStructureToEdit(
    idApi: number,
    whoToEdit?: WhoIsToEdit,
    idFilhaList?: number,
    idFilhaApi?: number
  ): void {
    let requestObservable;

    requestObservable = this.localidadeService.listarLocalidadeById(
      String(idApi)
    );

    requestObservable.subscribe((response) => {
      this.localidadePai = response;

      this.notificatorService.showAlert(
        "Informativo",
        "Estamos montando os dados para você poder editar, aguarde..."
      );

      // Editando localidade filha
      this.idFilhaListNumber = idFilhaList ? idFilhaList : null;
      this.idFilhaApiNumber = idFilhaApi ? idFilhaApi : null;
      this.isToEditFilha = whoToEdit === "CHILDREN" ? true : false;

      // Editando localidade pai
      this.canStretchContainer = true;
      this.canShowGoBackButton = true;
      this.canShowForm = true;
      this.canShowChildren = false;
      this.createChildren = false;
      this.whoIsToEdit = whoToEdit === "CHILDREN" ? "NONE" : "FATHER";
      this.whichIdShouldILook = idApi;

      this.mountStructureOfLocalidadesFilhas();
    });
  }

  // PEGAR VALOR DE LOCALIDADE PAI
  getLocalidadePai() {
    return this.localidadePai;
  }

  // PEGAR LISTA DE LOCALIDADES FILHAS
  getLocalidadesFilhas() {
    return this.localidadeFilhas;
  }

  // VERIFICAR SE O CADASTRO É DE LOCALIDADE PAI OU FILHA
  getCanCreateChildren(): boolean {
    return this.createChildren;
  }

  // LIDANDO COM MAPAS
  getCanUpdateMap(): boolean {
    return this.canUpdateMap;
  }

  setDownUpdateMap(): void {
    this.canUpdateMap = false;
  }

  setUpUpdateMap(): void {
    this.canUpdateMap = true;
  }

  saveCurrentLatAndLon(lat: number, lon: number): void {
    this.currentUserLat = lat;
    this.currentUserLon = lon;
  }

  saveCurrentRadius(radius: number) {
    this.currentUserRadius = radius;
  }

  // ARMAZENANDO AS LAT (MIN E MAX) E LON (MIN E MAX) PARA SEREM USADAS COMO PONTO DE REFERÊNCIA
  saveBoundingBox(
    latMin: number,
    latMax: number,
    lonMin: number,
    lonMax: number
  ): void {
    this.latMin = latMin;
    this.latMax = latMax;
    this.longMin = lonMin;
    this.longMax = lonMax;
  }

  // SALVANDO AS COORDENADAS DE ÁREA DA LOCALIDADE ESCOLHIDA
  savingGeoJsonCoordinates(coordinates: IMarkedArea): void {
    this.mapDataToMount = coordinates;
  }

  // SALVANDO FLAG PARA VERIFICAR SE PODE MONTAR O MAPA
  canCreateBounds(canMount: boolean, typeMap: string = 'Polygon'): void {
    this.canMountMap = canMount;
    this.typeMap = typeMap;
  }

  // MONTAGEM DO MAPA
  handlePreMountMap(): IResponseToMountMap {
    const responseData: IResponseToMountMap = {
      latAndLong: {
        lat: this.currentUserLat,
        lon: this.currentUserLon,
      },
      boundingBox: {
        latMin: this.latMin,
        latMax: this.latMax,
        lonMin: this.longMin,
        lonMax: this.longMax,
      },
      data: this.mapDataToMount,
      canMountBoundary: this.canMountMap,
      type: this.typeMap,
      radius: this.currentUserRadius,
    };

    return responseData;
  }

  // DESMONTANDO OS DADOS PARA UMA NOVA MONTAGEM DO MAPA, SE NECESSÁRIO
  handleUnmountMap(): void {
    this.currentUserLat = null;
    this.currentUserLon = null;
    this.mapDataToMount = null;
    this.currentUserRadius = null;
    this.latMax = null;
    this.latMin = null;
    this.longMax = null;
    this.longMin = null;
    this.canUpdateMap = false;
  }

  // PROPRIEDADES DE CONTROLE PARA ALERTAR OS COMPONENTES FILHOS O MOMENTO DO MODAL E REAGIR A ESSAS INFORMAÇÕES
  getCanShowForm(): boolean {
    return this.canShowForm;
  }

  setCanShowForm(isToEditFilha?: boolean): void {
    this.canShowForm = true;
    this.canShowChildren = false;
    this.createChildren = isToEditFilha ? true : false;
  }

  getCanShowButton(): boolean {
    return this.canShowChildren;
  }

  getCanStretchContainer(): boolean {
    return this.canStretchContainer;
  }

  //
  getWhoIsEditing(): string {
    return this.whoIsToEdit;
  }

  getWhoIsTheId(): number {
    return this.whichIdShouldILook;
  }

  // BOTÃO DE RETORNAR CASO O USUÁRIO ESCOLHA UMA LOCALIDADE PAI/FILHA PARA EDITAR E CADASTRO MANUAL DE LOCALIDADE
  getCanShowGoBackButton(): boolean {
    return this.canShowGoBackButton;
  }

  setGoBackButton(): void {
    if (this.canShowManualForm && !this.getLocalidadePai()) {
      this.whichIdShouldILook = null;
      this.setCanShowManualForm();
    } else {
      // this.setCanShowManualForm();
      this.canShowForm = false;
      this.canShowChildren = true;
      this.canShowGoBackButton = false;
      this.canShowManualForm = false;
      this.whoIsToEdit = "NONE";
      this.whichIdShouldILook = null;
    }
  }

  // CONTROLADORES DO FORM
  getCanShowManualForm(): boolean {
    return this.canShowManualForm;
  }

  setCanShowManualForm(): void {
    this.canShowGoBackButton = !this.canShowGoBackButton;
    this.canShowForm = !this.canShowForm;
    this.canShowManualForm = !this.canShowManualForm;
    this.whoIsToEdit = "NONE";
  }

  // RESET MODAL
  setResetModal(): void {
    this.localidadePai = null;
    this.localidadeFilhas = [];

    this.canUpdateMap = false;

    this.canMountMap = true;

    this.editMode = false;

    this.isToEditFilha = false;

    this.createChildren = false;

    this.canShowChildren = false;

    this.canShowGoBackButton = false;

    this.canShowForm = true;

    this.canStretchContainer = false;

    this.canShowManualForm = false;

    this.whichIdShouldILook = null;

    this.whoIsToEdit = "NONE";

    this.idFilhaListNumber = null;

    this.idFilhaApiNumber = null;
  }
}
