import * as moment from "moment/moment";
import numberFormatter from "../../../app/util/misc/numberFormatter";

/**
 * cria uma instancia de moment representando a expressao temporal passada em string.
 * @param dateTimeStr expressao temporal
 * @param pattern formato da data. Caso nenhum formato seja fornecido,
 * o que será utilizado será "DD/MM/YYYY hh:mm:ss".
 */
function parse(dateTimeStr: string, pattern = "DD/MM/YYYY hh:mm:ss"): any {
  return moment(dateTimeStr, pattern);
}

/**
 * Retorna o período de tempo entre a data inicial e a data final.
 *
 * @param dateTimeInicio data tempo inicial
 * @param dateTimeInicioFormat formato da data inicial
 * @param dateTimeFim data tempo final
 * @param dateTimeFimFormat formato da data final
 * @returns período temporal no formato hh:mm:ss
 */
function periodBetween(
  dateTimeInicio: string,
  dateTimeInicioFormat: string,
  dateTimeFim: string,
  dateTimeFimFormat: string
): string {
  const dataInicio = moment(dateTimeInicio, dateTimeInicioFormat);
  const dataFim = moment(dateTimeFim, dateTimeFimFormat);
  const periodo = dataFim.diff(dataInicio);

  const horas = moment.duration(periodo).hours();
  const minutosSegundos = moment(dataFim.diff(dataInicio)).format("mm:ss");

  const horasFormatada = numberFormatter.addZeroBeforeIfLessThan10(horas);
  return `${horasFormatada}:${minutosSegundos}`;
}

function onlyDate(dateTime: string, inputFormat: string): string {
  const dataInicio = moment(dateTime, inputFormat);
  return dataInicio.format("DD/MM/YYYY");
}

/**
 * Retorna o tempo formatado em string
 */
function timeFormatter(averageTime: string): string {
  const time: string = averageTime;

  let hours: string = time.split(":")[0];
  let minutes: string = time.split(":")[1];
  let seconds: string = time.split(":")[2];

  if (hours !== "00") {
    return `${hours}h ${minutes}min ${seconds}s`;
  } else if (minutes !== "00") {
    return `${minutes}min ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

/**
 * Recebe uma data e um separador e retorna a data formatada
 * @param date data
 * @param sapper separador
 * @returns data formatada
 */
function formatDateToString(date: Date, sapper: string = "/"): string {
  const newDate = moment(date).format("DD" + sapper + "MM" + sapper + "YYYY");

  return newDate;
}

/**
 * Recebe uma data no formato dd/MM/yyyy e retorna o unix correspondente
 */
function dateStrToUnix(dateString: string): number {
  const date = moment(dateString, "DD/MM/YYYY", true);
  if (!date.isValid()) {
    return null;
  }

  return date.unix();
}

// Função para converter a string de data no formato dd/mm/yyyy para um objeto Date
function converterData(dataString: string, separador: string = "/"): Date {
  const partesData = dataString.split(separador);
  if (partesData) {
    const [day, month, year] = partesData

    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day)
    );
  }
  return null;
}

const dates = {
  periodBetween,
  parse,
  timeFormatter,
  formatDateToString,
  dateStrToUnix,
  converterData,
};

export default dates;
