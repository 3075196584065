import { Injectable, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, iif, of, MonoTypeOperatorFunction } from 'rxjs';
import { map, catchError, retryWhen, concatMap, delay } from 'rxjs/operators';
//import { Response } from '@angular/http';
import { DadosPaginacao } from 'app/util/componente/paginacao/dadosPaginacao';
import { JWTService } from 'app/infraestrutura/security/service/jwtService';
import { RefreshTokenMapper } from 'app/infraestrutura/security/refreshTokenMapper';
import { RequestErrorHandler } from './requestService/requestErrorHandler';

@Injectable({
  providedIn: 'root',
})
/**
 * Servico responsavel por manipular as requisicoes da aplicacao
 */
export class RequestService implements OnInit {

  constructor(private http: HttpClient,
              private jwtService: JWTService,
              private refreshTokenMapper: RefreshTokenMapper,
              private requestErrorHandler: RequestErrorHandler) { }

  ngOnInit() {
  }

  /**
   * Adiciona os cabecalhos a requisicao, aqui deve-se ser inseridos
   * por exemplo, os cabecalhos de Authorization
   */
  getDefaultHeader(): HttpHeaders | { [header: string]: string | string[]; } {
    const headers = {};

    const authorizationToken = this.jwtService.getToken();

    if (authorizationToken) {
      headers['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
    }

    return headers;
  }

  /**
   * Manipula os erros gerados no processo da requisicao e mapping
   */
  handleError(error: any, mapErrorToString: boolean = true): Observable<never> {
    const handledError = this.requestErrorHandler.handleError(error, mapErrorToString);

    return handledError;
  }

  /**
   * Caso queira acessar os headers da request é importante passar false como terceiro parâmetro da request
   * O valor default é [true] e ele faz com que o mapeamento sempre sobrescreva os headers
   */
  // tslint:disable-next-line:max-line-length
  post(url, payload, mapBody: boolean = true, dadosPaginacao?: DadosPaginacao, queryParams: {} = {}, mapErrorToString: boolean = true): Observable<Object | HttpResponse<Object>> {
    const headers = this.getDefaultHeader();

    const params = { ...queryParams, ...this.dadosPaginacaoToQueryParam(dadosPaginacao) };

    return this.http.post(url, payload, {
      params,
      headers,
      observe: 'response',
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      map(response => mapBody ? response.body : response),
      catchError(error => this.handleError(error, mapErrorToString)),
    );
  }

  // tslint:disable-next-line: max-line-length
  put(url, payload = {}, queryParams: {} = {}, mapErrorToString: boolean = true, mapBody: boolean = true): Observable<any> {
    const headers = this.getDefaultHeader();

    return this.http.put(url, payload, {
      headers,
      observe: 'response',
      params: queryParams,
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      map(response => mapBody ? response.body : response),
      catchError(error => this.handleError(error, mapErrorToString)),
    );
  }

  patch(url, payload = {}): Observable<any> {
    const headers = this.getDefaultHeader();

    return this.http.patch(url, payload, {
      headers,
      observe: 'response',
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      map(response => response),
      catchError(error => this.handleError(error)),
    );
  }

  delete(url, payload = {}, mapBody: boolean = true): Observable<any> {
    const headers = this.getDefaultHeader();

    return this.http.request('delete', url, {
      headers,
      body: payload,
      observe: 'response',
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      map(response => mapBody ? response.body : response),
      catchError(error => this.handleError(error)),
    );

    /*return this.http.delete(url, {
      headers,
      body: payload,
      observe: 'response',
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      map(response => response.body),
      catchError(error => this.handleError(error)),
    ); */
  }

  // tslint:disable-next-line:max-line-length
  get(url, dadosPaginacao?: DadosPaginacao, mapBody: boolean = true, queryParams: {} = {}): Observable<any> {
    const headers = this.getDefaultHeader();

    const dadosPaginacaoQueryParams = this.dadosPaginacaoToQueryParam(dadosPaginacao);
    const requestQueryParams = { ...queryParams, ...dadosPaginacaoQueryParams };

    return this.http.get(url, {
      headers,
      observe: 'response',
      params: requestQueryParams,
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      map(response => mapBody ? response.body : response),
      catchError(error => this.handleError(error)),
    );
  }

  getBlob(url) {
    const headers = this.getDefaultHeader();

    return this.http.get(url, {
      headers,
      observe: 'response',
      responseType: 'blob',
    }).pipe(
      map(response => this.refreshTokenMapper.mapRefreshToken()(response)),
      catchError(error => this.handleError(error)),
    );
  }

  dadosPaginacaoToQueryParam(dadosPaginacao: DadosPaginacao): { [key: string]: string } {
    const paginacaoQueryParam = {};

    if (dadosPaginacao) {
      paginacaoQueryParam['page'] = dadosPaginacao.page;
      paginacaoQueryParam['size'] = dadosPaginacao.size;
    }

    return paginacaoQueryParam;
  }

}
