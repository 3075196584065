<div class="hirer-container">
    <app-info-card
      [title]="infoCardData?.title"
      [text]="infoCardData?.text"
      [data]="infoCardData?.data"
    ></app-info-card>
    <main class="list-hirer">
        <div class="filter">
          <section class="filter-wrapper">
            <div class="search-container">
              <input (input)="handleSearch($event)" placeholder="Busque por nomes de contratantes" />
              <button>
                  <i class="fa-regular fa-search"></i>
              </button>
            </div>

            <app-filter-select
              [type]="filterConf.type"
              [icon]="filterConf.icon"
              placeholder="Situação"
              [options]="filterOptions"
              [selectedOptions]="selectedFilterOptions"
              (changeFilter)="handleSelectOptionClick($event)"
            ></app-filter-select>

          </section>

          <button *ngIf="withRegisterAuthorities" class="add-btn" type="button" routerLink="/contratante-beta/cadastro">

            <i class="fa-regular fa-plus"></i>

            <h3>Novo contratante</h3>
          </button>
        </div>
        <app-tabela-contratante
          [hirerList]="hirerList"
          (updateHeader)="onStatusChange($event)"
        ></app-tabela-contratante>
        <div class="pagination">
            <app-pagination
              [placeholder]="'Contratantes'"
              [totalOfEntries]="totalHirer"
              [currentPage]="currentPage"
              [totalPages]="totalPages"
              [entriesPerPage]="entriesPerPage"
              (changePage)="changePage($event)"
            ></app-pagination>
        </div>
    </main>
</div>
<app-loading *ngIf="isLoading"></app-loading>
