import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { OperadorCadastroComponent } from 'app/modulos/operador/cadastro/operador.cadastro.component';
import { AbstractCanDeactivateGuard } from './abstractCanDeactivateGuard';

/**
 * Intercepta uma determinada rota e mostra um popup de confirmação antes da rota ser execultada
 */
@Injectable({
  providedIn: 'root',
})
export class OperadorConfirmDeactivateGuard extends AbstractCanDeactivateGuard implements CanDeactivate <OperadorCadastroComponent> {
  constructor(protected router: Router) {
    super(router);
  }

  async canDeactivate(component: OperadorCadastroComponent) {

    if (this.ignoreRedirectRules()) {
      return true;
    }

    const deactivate: boolean = await component.canDeactivate();
    return deactivate;
  }

}
