<form class="sg-input-group" [formGroup]="formGroup" #form="ngForm"
  (ngSubmit)="form.valid && modificar(form.value, form)">
  <div class="headerRespostaEdicao">
    <img src="assets/images/check-mark.svg" alt="">
    <h3>Corrigir citações selecionadas por</h3>
  </div>

  <div class="campo">
    <input type="text" formControlName='citacao' placeholder='Nova citação' (input)="carregarCitacoes($event)" matInput
      [matAutocomplete]="auto" maxlength="251"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateCounter($event)">
      <mat-option class="teste" *ngFor="let citacao of citacoesBusca" [value]="citacao.citacao">{{citacao.citacao}}"
      </mat-option>
    </mat-autocomplete>
    <span class="counter" [ngStyle]="{'display': ultimaBusca.length > 250 ? 'none' : 'block'}">{{ultimaBusca.length}} / 250</span>
    <span class="error-message" *ngIf="formGroup.get('citacao').touched && formGroup.get('citacao').hasError('required')">
      Este campo é obrigatório
    </span>
    <mat-error class="error-message" *ngIf="formGroup.get('citacao').touched && formGroup.get('citacao').hasError('maxlength')">
      Máximo de 250 caracteres
    </mat-error>
  </div>

  <div class="actionRespostaEdicao">
    <button class="sg-btn sg-secondary" type="submit"
      [disabled]="perguntaRespostasSelecionadas.length === 0">Modificar</button>
  </div>
</form>
<app-loading *ngIf="isLoading"></app-loading>
