import { ChartColor } from "app/util/componente/chart/model/chartColor";
import { ChartConfiguration } from "app/util/componente/chart/model/chartConfiguration";
import { ChartDataset } from "app/util/componente/chart/model/chartDataset";
import { calcularPorcentagem } from "app/util/misc/porcentagem";
import * as moment from "moment/moment";
import { Produtividade } from "../model/produtividade";

/**
 * Classe responsável por criar ChartConfiguration a partir
 * de Produtividade
 */
export class GraficosProdutividadeGenerator {
  corAzul = "#00BEC1";
  corAzulTransparente = "rgba(0, 190, 193, 0.1)";
  corRoxa = "#632782";
  corBranca = "#fff";
  corBrancaTransparente = "rgba(0, 190, 193, 0.8)";

  constructor() {
    moment.locale("pt-BR");
  }

  gerarGraficoProducaoDiaria(produtividade: Produtividade): ChartConfiguration {
    /**
     * recuperando os dados formatados
     */
    const coletasDiarias = this.getColetasDiarias(produtividade);
    const dataColetas = this.getDataColetas(produtividade);

    /**
     * criando os datasets e adicionando labels nas mesmas
     */
    const chartDataset = new ChartDataset();
    chartDataset.data = [...coletasDiarias];
    chartDataset.label = "Realizadas";
    chartDataset.pointHoverRadius = 8;
    chartDataset.pointRadius = 8;
    chartDataset.borderWidth = 2.2;

    /**
     * criando o marcador da media da pesquisa
     * ATENÇÃO, quando a altura do gráfico for alterada, é necessário
     */
    // tslint:disable-next-line:max-line-length
    const marcadorPrevisao = this.gerarAnotacaoEntrevistasPrevistas(
      produtividade.producaoDiaria.previsao
    );

    /**
     * adicionando a cor do gráfico
     */
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");

    const gradientStroke = canvasContext.createLinearGradient(0, 200, 0, 0);
    gradientStroke.addColorStop(0.2, this.corAzulTransparente);
    // gradientStroke.addColorStop(0.2, 'red');
    // gradientStroke.addColorStop(0.2, '#fff');
    gradientStroke.addColorStop(0.5, this.corBrancaTransparente);

    const chartColor = new ChartColor();
    chartColor.backgroundColor = gradientStroke;
    chartColor.borderColor = this.corAzul;
    chartColor.pointBackgroundColor = this.corBranca;
    chartColor.pointBorderColor = this.corAzul;
    chartColor.pointHoverBackgroundColor = this.corBranca;
    chartColor.pointHoverBorderColor = this.corAzul;
    const cores = [chartColor, chartColor];

    /**
     * descobrindo a altura máxima do gráfico,
     * ou seja, a maior coleta registrada ou a meta
     * a ser alcancada. E entao, adicionamos 20% a esse valor
     */
    const maiorColeta = Math.max(...coletasDiarias);
    // tslint:disable-next-line:max-line-length
    const coeficienteAlturaMaxima =
      produtividade.producaoDiaria.previsao > maiorColeta
        ? produtividade.producaoDiaria.previsao
        : maiorColeta;
    const alturaMaximaGrafico = Math.ceil(coeficienteAlturaMaxima * 1.2);

    // Definindo uma altura padrão caso a alturaMaxima seja 0
    const alturaGrafico = alturaMaximaGrafico === 0 ? 8 : alturaMaximaGrafico;

    /**
     * criando os dados do grafico
     */
    const chartConfiguration = new ChartConfiguration();
    chartConfiguration.datasets = [chartDataset];
    chartConfiguration.labels = dataColetas;
    chartConfiguration.colors = cores;

    chartConfiguration.type = "line";
    chartConfiguration.options = {
      responsive: true,
      annotation: {
        drawTime: "afterDatasetsDraw",
        annotations: marcadorPrevisao,
      },
      scales: {
        yAxes: [
          {
            type: "linear",
            id: "y-axis-0",
            ticks: {
              beginAtZero: true,
              suggestedMax: alturaGrafico,
            },
          },
        ],
      },
    };

    return chartConfiguration;
  }

  gerarGraficoEspacamento(produtividade: Produtividade): ChartConfiguration {
    const dataset = new ChartDataset();
    dataset.borderWidth = 0;
    // tslint:disable-next-line:max-line-length
    dataset.data = [
      produtividade.estatisticasEntrevista.conformidade,
      produtividade.estatisticasEntrevista.desconformidade,
    ];

    const chartColor = new ChartColor();
    chartColor.borderColor = this.corAzul;
    chartColor.backgroundColor = [this.corRoxa, this.corAzul];
    const cores = [chartColor, chartColor];

    /**
     * criando os dados do grafico
     */
    const chartConfiguration = new ChartConfiguration();
    chartConfiguration.datasets = [dataset];
    chartConfiguration.labels = ["Em conformidade", "Em desconformidade"];
    chartConfiguration.colors = cores;

    chartConfiguration.type = "doughnut";
    chartConfiguration.options = {
      responsive: true,
      rotation: 1 * Math.PI * 0.9,
      circumference: 1 * Math.PI * 1.2,
      cutoutPercentage: 90,
      legend: {
        display: false,
      },
    };

    return chartConfiguration;
  }

  gerarGraficoTempoMinimo(produtividade: Produtividade): ChartConfiguration {
    const dataset = new ChartDataset();
    dataset.borderWidth = 0;
    // tslint:disable-next-line:max-line-length
    dataset.data = [
      produtividade.estatisticasEntrevista.abaixoTempoMinimo,
      produtividade.estatisticasEntrevista.acimaTempoMinimo,
    ];

    const chartColor = new ChartColor();
    chartColor.borderColor = this.corAzul;
    chartColor.backgroundColor = [this.corAzul];
    const cores = [chartColor];

    /**
     * criando os dados do grafico
     */
    const chartConfiguration = new ChartConfiguration();
    chartConfiguration.datasets = [dataset];
    chartConfiguration.labels = [
      "Abaixo do tempo mínimo",
      "Acima do tempo mínimo",
    ];
    chartConfiguration.colors = cores;
    // tslint:disable-next-line:max-line-length
    const porcentagemAbaixoTempoMinimo = calcularPorcentagem(
      produtividade.estatisticasEntrevista.abaixoTempoMinimo,
      produtividade.estatisticasEntrevista.entrevistasRealizadas
    );
    // tslint:disable-next-line:max-line-length
    chartConfiguration.inlinePlugin = [
      this.gerarPluginTextoCentro(porcentagemAbaixoTempoMinimo),
    ];

    chartConfiguration.type = "doughnut";
    chartConfiguration.options = {
      responsive: true,
      cutoutPercentage: 95,
      legend: {
        display: false,
      },
    };

    return chartConfiguration;
  }

  /**
   * Gera um inlinePlugin do Chartjs que adiciona um texto
   * no centro do gráfico,pode ser usado por exemplo, para
   * adicionar a porcentagem do do gráfico de tempoMinimo
   */
  gerarPluginTextoCentro(texto: string): any {
    const plugin = {
      id: "centerTextPlugin",
      afterDatasetsDraw(chart: any): any {
        // console.log('[graficoGenerator] gerarPluginTextoCentro texto', texto);
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        ctx.restore();

        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em fira sans`;
        ctx.textBaseline = "middle";
        const text = texto;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillStyle = "rgb(123,123,123)";
        ctx.fillText(text, textX, textY);

        ctx.save();
      },
    };

    return plugin;
  }

  /**
   * recupera as coletas diarias, caso nenhuma coleta seja feita,
   * um array [0]  sera retornado
   */
  getColetasDiarias(produtividade: Produtividade): number[] {
    // tslint:disable-next-line:max-line-length
    const coletasDiarias = produtividade.producaoDiaria.resultadosDiarios.map(
      (resultadoDiario) => resultadoDiario.coletas
    );
    if (coletasDiarias.length === 0) {
      coletasDiarias.push(0);
    }

    return coletasDiarias;
  }

  /**
   * Retorna as datas das coletas no formato DD/MM/YYYY,
   * caso nenhum dia de coleta seja fornecido, o
   * dia atual será retornado
   */
  getDataColetas(produtividade: Produtividade): string[] {
    // tslint:disable-next-line:max-line-length
    const dataColetas = produtividade.producaoDiaria.resultadosDiarios.map(
      (resultadoDiario) => resultadoDiario.data
    );

    // adicionando um dia, caso nenhum exista
    if (dataColetas.length === 0) {
      const today = moment(new Date()).format("DD/MM/YYYY");
      dataColetas.push(today);
    }

    return dataColetas;
  }

  /**
   * Gera uma função para a renderização das entrevistas
   * previstas no grafico de produção diária. Essa função
   * atualmente é utilizada junto com o plugin de anotações
   * do chartJs <https://github.com/chartjs/chartjs-plugin-annotation>
   */
  gerarAnotacaoEntrevistasPrevistas(previsao: number): any[] {
    const marcacao = {
      type: "line",
      id: "producao_diaria_meta",
      mode: "horizontal",
      scaleID: "y-axis-0",
      value: previsao,
      borderColor: this.corRoxa,
      borderWidth: 2,
      label: {
        enabled: true,
        position: "left",
        content: "",
      },
    };

    return [marcacao];
  }
}
