<div class="options">
  <div
    class="option"
    *ngFor="let item of options"
    (click)="selectFilterOption(item)"
    [ngClass]="selectedOption && item.id === selectedOption.id && 'selected'"
  >
    <div class="checkbox"></div>
    <label for="">{{ item.label }}</label>
  </div>
  <button [disabled]="!selectedOption" class="clean-btn" (click)="clearFilterOption()">Limpar</button>
</div>