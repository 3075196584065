import { HttpResponse } from "@angular/common/http";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { RequestService } from "../request.service";
// tslint:disable-next-line:max-line-length
import { SimpleFilterService } from "app/util/componente/tabela/tabela-filtravel/filter/filterService";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";

/**
 * Interface que expoe os metodos obrigatorios de um servico que
 * realiza operações de CRUD
 */
// tslint:disable-next-line:max-line-length
export abstract class CrudService<T extends { id: any }>
  implements SimpleFilterService<T>
{
  /**
   * @param requestService Servico que será utilizado para realização de requisicoes
   * @param resourcePath Path para o recurso desejado
   */
  constructor(
    protected requestService: RequestService,
    protected resourcePath: string
  ) {}

  findById(id: any): Observable<T> {
    const requestPath = `${this.resourcePath}/${id}`;
    return this.requestService.get(requestPath);
  }

  listar(dadosPaginacao?: DadosPaginacao): Observable<T[]> {
    return this.requestService.get(this.resourcePath, dadosPaginacao);
  }

  salvar(entidade: T, mapErrorToString: boolean = true): Observable<T> {
    // tslint:disable-next-line: max-line-length
    return <Observable<T>>(
      this.requestService.post(
        this.resourcePath,
        entidade,
        true,
        null,
        {},
        mapErrorToString
      )
    );
  }

  atualizar(entidade: T, mapErrorToString: boolean = true): Observable<T> {
    const requestPath = `${this.resourcePath}/${entidade.id}`;
    return this.requestService.put(requestPath, entidade, {}, mapErrorToString);
  }

  deletar(id: any, mapBody: boolean = true): Observable<T> {
    const requestPath = `${this.resourcePath}/${id}`;
    return this.requestService.delete(requestPath, {}, mapBody);
  }

  aplicarFiltro(
    palavraChave: PalavraChave,
    dadosPaginacao?: DadosPaginacao
  ): Observable<T[]> {
    const requestUrl = `${this.resourcePath}/filtrar/palavra-chave`;
    // tslint:disable-next-line:max-line-length
    const response = <Observable<T[]>>(
      this.requestService.post(requestUrl, palavraChave, true, dadosPaginacao)
    );
    return response;
  }

  getTotalEntidades(palavraChave?: PalavraChave): Observable<number> {
    const requestUrl = `${this.resourcePath}/total-registros`;

    return <Observable<number>>(
      this.requestService.post(requestUrl, palavraChave)
    );
  }

  downloadListagem(): Subject<boolean> {
    const requestUrl = `${this.resourcePath}/exportar`;

    const showLoadingSubject: Subject<boolean> = new ReplaySubject();
    showLoadingSubject.next(true);

    this.requestService.getBlob(requestUrl).subscribe({
      next: (response: HttpResponse<any>) => {
        const blob = new Blob([response.body], {
          type: response.headers.get("Content-Type"),
        });

        const contentDisposition = response.headers
          .get("Content-Disposition")
          .split("=");
        const fileName = contentDisposition[1];

        const url = window.URL.createObjectURL(blob);
        const aElement = document.createElement("a");
        document.body.appendChild(aElement);
        aElement.href = url;
        aElement.download = "itens_listagem.pdf";
        aElement.click();
        window.URL.revokeObjectURL(url);
        aElement.remove();

        showLoadingSubject.next(false);
        // tslint:disable-next-line:align
      },
      error: (error) => {
        showLoadingSubject.next(false);
        throw new Error(`Erro no download para o resourcePath ${requestUrl}`);
      },
    });

    return showLoadingSubject;
  }
}
