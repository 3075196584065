import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const DEFAULT_ROUTE_HIRER = 'tensai';

// rotas que devem ser evitadas no processo de reatribuição do href atual.
const ROUTES_BLACKLIST = [
  'auth/contratante',
  'auth/integration',
  'login',
  'recuperar-senha',
  'redifinir-senha',
  'privacy',
];

const extractPathNames = () => {
  return window.location.pathname.split('/').slice(1);
};

const isPublicRoute = (): boolean => {
  const currentPath = extractPathNames().join('/');
  return !ROUTES_BLACKLIST.some(r => currentPath === r);
};

const getDefaultHirer = (hirer: string) => {
  if (!isPublicRoute()) {
    return DEFAULT_ROUTE_HIRER;
  } else if (!hirer.length) {
    return DEFAULT_ROUTE_HIRER;
  }

  return hirer;
};

const pathnames = extractPathNames();
const hirer = getDefaultHirer(`${pathnames[0]}`);

// subtenant default
// Caso não tenha um "hirer", o subtenant default será utilizado aqui.
export const ROUTER_HIRER = hirer;
export const CURRENT_HIRER  = new InjectionToken<BehaviorSubject<string>>('CURRENT_HIRER');

export const BaseHrefGenerator = (currentHirer: BehaviorSubject<string>) => {
  let baseHref : string;
  currentHirer.subscribe((value: string) => {
    baseHref = value;

    // Caso a rota acessada esteja no array ROUTES_BLACKLIST, o basehref será setado para o valor padrão '/', isso evitará
    // que o usuário seja redirecionado incorretamente e o subtenant correto seja recuperado quando o token de acesso for
    // validado.
    if (!isPublicRoute()) {
      baseHref = '';
      return;
    }

    if (value !== ROUTER_HIRER) {
      window.location.href = `${value}`;
    }
  });

  return `/${baseHref}`;
};
