<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<main>
  <header>
    <div class="content-header">
      <h1>Comunicação</h1>
      <p>Carregue e gerencie os arquivos e notificações do sistema.</p>
    </div>
    <div class="image">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="619"
        height="131"
        fill="none"
        viewBox="0 0 619 131"
      >
        <path
          class="path-one"
          fill-opacity=".1"
          d="M197.876 49.295c106.651-27.787 234.442.764 364.719 83.972C631.261 177.142 702.191 210.491 774 233v-353H188c7.269 56.1 10.607 112.974 9.871 169.29l.005.005Z"
        />
        <path
          class="path-two"
          fill-opacity=".3"
          d="M198 45c-5.256 76.239-44.738 146.38-111.623 193.064C57.77 258.035 26.448 272.331-7 281c.005-.534.016-1.063.021-1.597 5.692-75.621 45.09-145.108 111.503-191.467C133.13 67.965 164.452 53.669 197.9 45h.1Z"
        />
      </svg>
    </div>
  </header>
  <section>
    <div class="title">
      <i class="fa-regular fa-file-lines"></i>
      <h4>Termo e condições</h4>
    </div>
    <div class="main-content">
      <!-- Termos de Uso -->
      <div class="terms-of-usage">
        <h5>Termos de uso</h5>
        <div class="card">
          <!-- Adicionar novo Termo de Uso -->
          <ng-container *ngIf="lgpdDocState.terms === 'NEW'" #addTerm>
            <i class="fa-regular fa-cloud-arrow-up"></i>
            <label for="terms-id">Enviar arquivo</label>
            <input
              id="terms-id"
              type="file"
              accept=".pdf"
              style="display: none"
              (change)="getLgpdTerm($event)"
            />
            <span
              ><p>Arquivos suportados: PDF.</p>
              <p>Tamanho máximo: 5 MB</p></span
            >
          </ng-container>

          <!-- Estado de carregamento -->
          <ng-container *ngIf="lgpdDocState.terms === 'LOADING'" #loadTerm>
            <div class="loading-doc">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p>Carregando...</p>
            </div>
          </ng-container>

          <!-- Atualizar Termos de Uso -->
          <ng-container *ngIf="lgpdDocState.terms === 'EDIT'" #editTerm>
            <i class="fa-regular fa-file"></i>
            <a id="file-name" target="_blank" [href]="termsOfUsageData.location">
              {{ termsOfUsageData.name }} {{ termsOfUsageData.size }}
            </a>
            <p>Atualizado em: {{ termsOfUsageData.date }}</p>
            <label for="terms-id">Substituir</label>
            <input
              id="terms-id"
              type="file"
              accept=".pdf"
              style="display: none"
              (change)="getLgpdTerm($event)"
            />
          </ng-container>
        </div>
      </div>

      <!-- Políticas de Privacidade -->
      <div class="privacy-policy">
        <h5>Política de privacidade</h5>
        <div class="card">
          <!-- Adicionar nova política de privacidade -->
          <ng-container *ngIf="lgpdDocState.privacy === 'NEW'" #addPrivacy>
            <i class="fa-regular fa-cloud-arrow-up"></i>
            <label for="privacy-id">Enviar arquivo</label>
            <input
              id="privacy-id"
              type="file"
              accept=".pdf"
              style="display: none"
              (change)="getLgpdPrivacy($event)"
            />
            <span
              ><p>Arquivos suportados: PDF.</p>
              <p>Tamanho máximo: 5 MB</p></span
            >
          </ng-container>

          <!-- Estado de carregamento -->
          <ng-contanier *ngIf="lgpdDocState.privacy === 'LOADING'" #loadPrivacy>
            <div class="loading-doc">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p>Carregando...</p>
            </div>
          </ng-contanier>

          <!-- Atualizar Políticas de Pivacidade -->
          <ng-container *ngIf="lgpdDocState.privacy === 'EDIT'" #editPrivacy>
            <i class="fa-regular fa-file"></i>
            <a id="file-name" target="_blank" [href]="privacyPolicyData.location">
              {{ privacyPolicyData.name }} {{ privacyPolicyData.size }}
            </a>
            <p>Atualizado em: {{ privacyPolicyData.date }}</p>
            <label for="privacy-id">Substituir</label>
            <input
              id="privacy-id"
              type="file"
              accept=".pdf"
              style="display: none"
              (change)="getLgpdPrivacy($event)"
            />
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</main>
