import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mask-only-letters]',
})
export class MaskOnlyLettersDirective  {

  // Escuta o evento keyPress e captura o valor do mesmo
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const charCode = event.which || event.keyCode;
    const charStr = String.fromCharCode(charCode);
    this.validateInput(charStr, event)
  }

  // Escuta o evento de paste e captura o valor do mesmo
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text/plain');
    this.validateInput(pastedText, event)
  }

  // Aplica o regex para validação do dado inputado
  private validateInput(input: string, event: Event): void {
    if (!/^[a-zA-Z\sÀ-ÿ]*$/.test(input)) {
      event.preventDefault();
    }
  }

}
