import { Component, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import validadorCamposIguais from "app/util/validador/validadorCamposIguais";
import validadorCnpj from "app/util/validador/validadorCnpj";
import validadorCpf from "app/util/validador/validadorCpf";
import { CadastroBasico } from "../../cadastro/cadastroBasico";
import { Cliente } from "../cliente";
import { ClienteService } from "../servico/cliente.service";
// tslint:disable-next-line:max-line-length
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { NotificatorService } from "app/notificador/notificator.service";
import { FotoUploaderComponent } from "app/util/componente/foto-uploader/foto-uploader.component";
import { ToastrService } from "ngx-toastr";
import { NewFotoUploaderComponent } from "app/util/componente/new-foto-uploader/new-foto-uploader.component";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-cliente-cadastro",
  templateUrl: "./cliente.cadastro.component.html",
  styleUrls: ["./cliente.cadastro.component.scss"],
})
export class ClienteCadastroComponent extends CadastroBasico<Cliente> {
  tipoPessoa: string;

  /**
   * caso seja um cadastro o cliente vazio eh criado, caso contrario,
   * os dados previos serao carregados. Esta instancia de cliente serve apenas
   * para setar o patchValue dos formControls
   */
  cliente: Cliente;
  url: string | ArrayBuffer;
  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  constructor(
    protected clienteService: ClienteService,
    protected securityService: SecurityService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "cliente",
      clienteService,
      activatedRoute,
      router,
      toastr,
      notificatorService,
      modalService
    );
  }

  ngOnInit() {
    this.cliente = new Cliente();

    // Cria o formgroup
    this.initFormGroup();

    // Inicializa o componente como pessoa física
    const typeInitial = { target: { value: "F" } };
    this.onTipoPessoaChange(typeInitial);

    super.ngOnInit();

    this.tituloFormulario = "adicionar clientes";

    if (this.isEdicao) {
      this.tituloFormulario = "alterar clientes";
    }
  }

  entidadeEdicaoCallback(clienteEdicao: Cliente) {
    this.cliente = clienteEdicao;
    if (this.cliente.foto) {
      this.newFotoUploaderComponent.loadImageByUrl(this.cliente.foto);
    }
    this.initFormGroup();
    // Inicializa o componente
    const typeInitial = { target: { value: this.cliente.pessoa.tipoPessoa } };

    this.onTipoPessoaChange(typeInitial);
  }

  // Criando o fromGroup
  initFormGroup() {
    this.formGroup = new UntypedFormGroup({
      pessoa: this.createPessoaFormGroup(),
      usuario: this.createUsuarioFormGroup(),
      contato: this.createContatoFormGroup(),
      fotoEnviada: this.createFotoEnviadaFormGroup(),
    });
  }

  // Criando o fromGroup para FotoEnviada
  private createFotoEnviadaFormGroup(): UntypedFormGroup {
    const fotoEnviadaFormGroup = new UntypedFormGroup({
      arquivo: new UntypedFormControl(),
    });

    return fotoEnviadaFormGroup;
  }

  // Criando o fromGroup para Contato
  private createContatoFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["email"] = new UntypedFormControl(
      this.cliente.contato.email,
      [Validators.required, Validators.email, Validators.maxLength(50)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["telefone"] = new UntypedFormControl(
      this.cliente.contato.telefone,
      [Validators.maxLength(14), Validators.minLength(14)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["celular"] = new UntypedFormControl(
      this.cliente.contato.celular,
      [Validators.maxLength(15)]
    );

    const contatoFormGroup = new UntypedFormGroup({
      email: this.formControls.email,
      telefone: this.formControls.telefone,
      celular: this.formControls.celular,
    });

    return contatoFormGroup;
  }

  // Criando o fromGroup para Usuario
  private createUsuarioFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["login"] = new UntypedFormControl(
      this.cliente.usuario.login
    );
    this.formControls["mudarPassword"] = new UntypedFormControl();
    this.formControls["ativo"] = new UntypedFormControl(
      this.cliente.usuario.ativo,
      [Validators.required]
    );

    this.formControls["senha"] = new UntypedFormControl();
    this.formControls["senhaConfirmar"] = new UntypedFormControl();

    if (!this.isEdicao) {
      const senhaCamposIguaisValidator = validadorCamposIguais(
        this.formControls.senha
      );
      // tslint:disable-next-line:max-line-length
      const senhaConfirmarCamposIguaisValidator = validadorCamposIguais(
        this.formControls.senhaConfirmar
      );

      // tslint:disable-next-line:max-line-length
      this.formControls.senha.setValidators([
        Validators.required,
        Validators.maxLength(30),
        Validators.minLength(8),
        senhaConfirmarCamposIguaisValidator,
      ]);
      this.formControls.senhaConfirmar.setValidators(
        senhaCamposIguaisValidator
      );
    }

    const usuarioFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.cliente.usuario.id),
      login: this.formControls.login,
      senha: this.formControls.senha,
      senhaConfirmar: this.formControls.senhaConfirmar,
      mudarPassword: this.formControls.mudarPassword,
      ativo: this.formControls.ativo,
    });

    return usuarioFormGroup;
  }

  /**
   * Criando o fromGroup para Pessoa
   * Os validadores de cpf, rg, cnpj, inscricao estadual, responsavel e site
   * estão no método updatePessoaValidators pois os mesmos são dinâmicos
   */
  private createPessoaFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["nome"] = new UntypedFormControl(
      this.cliente.pessoa.nome,
      [Validators.required, Validators.maxLength(50)]
    );
    this.formControls["cpf"] = new UntypedFormControl(this.cliente.pessoa.cpf);
    this.formControls["rg"] = new UntypedFormControl(this.cliente.pessoa.rg);
    this.formControls["cnpj"] = new UntypedFormControl(
      this.cliente.pessoa.cnpj
    );
    this.formControls["inscricaoEstadual"] = new UntypedFormControl(
      this.cliente.pessoa.inscricaoEstadual
    );
    // this.formControls["site"] = new UntypedFormControl(
    //   this.cliente.pessoa.site
    // );
    this.formControls["responsavel"] = new UntypedFormControl(
      this.cliente.pessoa.responsavel
    );
    this.formControls["tipoPessoa"] = new UntypedFormControl(
      this.cliente.pessoa.tipoPessoa
    );

    if (this.isEdicao) {
      if (this.formControls.tipoPessoa.value === "J") {
        // passando o valor no formato esperado
        const value = { target: { value: "J " } };
        this.onTipoPessoaChange(value);
      }
    }

    const pessoaFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.cliente.pessoa.id),
      nome: this.formControls.nome,
      cpf: this.formControls.cpf,
      rg: this.formControls.rg,
      cnpj: this.formControls.cnpj,
      inscricaoEstadual: this.formControls.inscricaoEstadual,
      // site: this.formControls.site,
      responsavel: this.formControls.responsavel,
      tipoPessoa: this.formControls.tipoPessoa,
      endereco: this.createEnderecoFormGroup(),
    });

    return pessoaFormGroup;
  }

  //  Função para pegar o changeHandler do input de email pessoal e fazer com que
  //  o email de acesso tenha o mesmo valor e seja enviado no payload da API
  //  uma vez que não se faz necessário ter a duplicidade do campo email no formulário
  //  de cadastro de usuario (em termos de layout e UX).
  onEmailChange(eventValue: string) {
    this.formGroup.get("usuario").get("login").setValue(eventValue);
  }

  // Criando o fromGroup para Endereco
  private createEnderecoFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["cep"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.cep,
      [Validators.maxLength(9)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["logradouro"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.logradouro,
      [Validators.maxLength(60)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["numero"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.numero,
      [Validators.max(99999)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["bairro"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.bairro,
      [Validators.maxLength(60)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["cidade"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.cidade,
      [Validators.maxLength(60)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["estado"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.estado,
      [Validators.maxLength(60)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["complemento"] = new UntypedFormControl(
      this.cliente.pessoa.endereco.complemento,
      [Validators.maxLength(60)]
    );

    const enderecoFormGroup = new UntypedFormGroup({
      cep: this.formControls.cep,
      logradouro: this.formControls.logradouro,
      numero: this.formControls.numero,
      bairro: this.formControls.bairro,
      cidade: this.formControls.cidade,
      estado: this.formControls.estado,
      complemento: this.formControls.complemento,
    });

    return enderecoFormGroup;
  }
  /**
   * Quando o tipo da pessoa muda, o marcador de tipoPessoa e atualizado e
   * junto a ele os validadores que estao ativos para aquele tipo especifico
   */
  onTipoPessoaChange(tipoPessoa: any) {
    this.tipoPessoa = tipoPessoa.target.value;
    this.formGroup.get("pessoa").get("tipoPessoa").patchValue(this.tipoPessoa);

    // atualizando os validadores que estao ativos
    this.updatePessoaValidators(this.tipoPessoa);
  }

  // atualiza os validadores que estao ativos dependendo do tipo de pessoa
  updatePessoaValidators(tipoPessoa: string) {
    const pessoaFisicaFormControls = {
      cpf: [Validators.required, validadorCpf],
      rg: [Validators.required, Validators.maxLength(10)],
    };

    const pessoaJuridicaFormControls = {
      inscricaoEstadual: [Validators.maxLength(15)],
      cnpj: [Validators.required, validadorCnpj],
      responsavel: [Validators.maxLength(50)],
      // site: [Validators.maxLength(40)],
    };

    const pessoaFisicaFormControlNames = Object.keys(pessoaFisicaFormControls);
    const pessoaJuridicaFormControlNames = Object.keys(
      pessoaJuridicaFormControls
    );
    const pessoaFormGroup: UntypedFormGroup = <UntypedFormGroup>(
      this.formGroup.get("pessoa")
    );

    if (tipoPessoa === "F") {
      // Limpando os validadores que não são de pessoa física
      this.clearFormControlNameFromFormGroup(
        pessoaFormGroup,
        pessoaJuridicaFormControlNames
      );

      // Adicionando os validadores que são de pessoa física
      pessoaFisicaFormControlNames.forEach((formControlName) => {
        pessoaFormGroup
          .get(formControlName)
          .setValidators(pessoaFisicaFormControls[formControlName]);

        pessoaFormGroup.get(formControlName).updateValueAndValidity();
      });
    } else {
      // Limpando os validadores que não são de pessoa juridica
      this.clearFormControlNameFromFormGroup(
        pessoaFormGroup,
        pessoaFisicaFormControlNames
      );

      // Adicionando os validadores que são de pessoa juridica
      pessoaJuridicaFormControlNames.forEach((formControlName) => {
        pessoaFormGroup
          .get(formControlName)
          .setValidators(pessoaJuridicaFormControls[formControlName]);

        pessoaFormGroup.get(formControlName).updateValueAndValidity();
      });
    }
  }

  // reseta e remove validadores de formControls
  private clearFormControlNameFromFormGroup(
    formGroup: UntypedFormGroup,
    formControlNames: string[]
  ) {
    formControlNames.forEach((formControlName: string) => {
      const formControlAlvo = formGroup.get(formControlName);
      formControlAlvo.clearValidators();
      formControlAlvo.reset();
    });
  }

  /**
   * Prepara a entidade para persistência, qualquer ajuste fino deve ser realizado aqui
   */
  prepararEntidadeParaPersistencia(cliente: Cliente): Cliente {
    const clienteToSave = { ...cliente };

    // se o cliente nao possuir fotos novas, removemos o atributo FotoEnviada
    if (
      this.newFotoUploaderComponent.defaultImage ===
      this.newFotoUploaderComponent.url
    ) {
      clienteToSave.fotoEnviada = null;
    } else {
      clienteToSave.fotoEnviada.arquivo = this.newFotoUploaderComponent.url;
    }

    return clienteToSave;
  }

  /**
   * @override
   */
  cancelarFluxo(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    // tslint:disable-next-line: max-line-length
    if (
      (this.isEdicao &&
        this.getAuthenticatedUserAuthorities().includes(
          Authority.ATUALIZAR_CLIENTE
        )) ||
      (!this.isEdicao &&
        this.getAuthenticatedUserAuthorities().includes(
          Authority.CADASTRAR_CLIENTE
        ))
    ) {
      super.cancelarFluxo(event);
    } else {
      this.router.navigate(["cliente-beta"]);
    }
  }

  /**
   * @override
   */
  getRedirectPath(): string[] {
    const listagemAuthorities = [
      Authority.ATUALIZAR_CLIENTE,
      Authority.LISTAR_CLIENTE,
    ];
    if (this.securityService.userHasAuthorities(listagemAuthorities)) {
      return ["cliente-beta"];
    }
    return [""];
  }
}
