import { EventTypes } from "./../../../util/componente/genericTable/interfaces/table";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { IFilterSelect } from "app/componentes/filter-select/filter-select";
import { InfoCardDataProps } from "app/componentes/info-card/info-card.component";
import { SurveyListSituations } from "app/modulos/auditoria-beta/constant";
import { SORTING_FILTER } from "app/modulos/auditoria-beta/constant/filters";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import { TableGlobalConfig } from "app/util/componente/genericTable/interfaces/table";
import { ProdutividadeBetaService } from "../services/produtividade-beta.service";
import {
  ISurveyListFilterConfigs,
  ISurveyListResponse,
} from "app/modulos/auditoria-beta/interfaces";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import {
  iconCellFactory,
  statusCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "app/modulos/auditoria-beta/constant/errors";
import { NotificatorService } from "app/notificador/notificator.service";
import { ProdutividadeSituacaoPesquisas, ProdutividadeSituacaoPesquisasFriendlyNames } from "../interfaces/listagem";

enum ESituacao {
  NAO_AUDITADA = "Não Auditada",
  AUDITADA = "Auditada",
  EM_AUDITORIA = "Em Auditoria",
}

enum ESituacaoColor {
  NAO_AUDITADA = "var(--gray200)",
  AUDITADA = "var(--status-done)",
  EM_AUDITORIA = "var(--status-in-progress)",
}

const infoCardDefaultValue = [
  {
    title: "Pesquisas",
    value: 0
  },
  {
    title: "Auditadas",
    value: 0
  },
  {
    title: "Em auditoria",
    value: 0
  },
  {
    title: "Não auditada",
    value: 0
  }
];

@Component({
  selector: "app-produtividade-beta",
  templateUrl: "./listagem-pesquisas.component.html",
  styleUrls: ["./listagem-pesquisas.component.scss"],
})
export class ProdutividadeBetaComponent implements OnInit {
  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "Início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Produtividade",
      itemLink: `/produtividade-beta`,
      active: true,
    },
  ];

  isLoading = false;

  totalOfSurveys: number = 10;
  pagination = {
    current: 1,
    previous: 1,
  };

  // info card data model
  infoCardData: InfoCardDataProps = {
    data: [],
    title: "Auditoria",
    text: "Audite coletas e garanta confiabilidade nos dados das suas pesquisas",
  };

  tableHead: TableHead[] = [
    {
      type: "text",
      label: "Pesquisa",
    },
    {
      type: "text",
      label: "Cliente",
      cellStyles: {
        display: "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
      },
    },
    {
      type: "text",
      label: "Período",
    },
    {
      type: "text",
      label: "Situação",
    },
    {
      type: "text",
      label: "Visualizar",
      alignTypes: "center",
    },
  ];

  tableConfig: TableGlobalConfig = {
    colWidths: [".3", ".3", ".15", ".17", ".08"],
    rowStyles: { "font-size": "14px" },
  };

  tableData: TableRow[] = [];

  filters: FilterConfigs[] = [
    {
      type: FilterTypes.CHECK,
      icon: FilterIcon.FUNNEL,
      placeholder: "Situação",
      options: [
        { id: 1, label: "Auditada", key: SurveyListSituations.AUDITADA },
        { id: 2, label: "Em auditoria", key: SurveyListSituations.INAUDIT },
        { id: 3, label: "Não auditada", key: SurveyListSituations.NOTAUDITED },
      ],
    },
    {
      type: FilterTypes.RANGE_DATE,
      icon: FilterIcon.CALENDAR,
      placeholder: "Período",
      selectConfig: {
        intervalDate: {
          startDate: new Date(2023, 11, 1),
          endDate: new Date(2024, 1, 29),
        },
      },
    },
    {
      type: FilterTypes.RADIO,
      icon: FilterIcon.SORTING,
      placeholder: "Ordenar",
      options: SORTING_FILTER,
    },
  ];

  searchInputConf: SearchInputConfig = {
    placeholder: "Busque por nome de pesquisa ou cliente",
  };

  filterOptions: ISurveyListFilterConfigs = {
    filtrableAttributes: "",
    filtrableOrder: "",
    status: [],
    keywords: [],
  };
  constructor(
    private router: Router,
    private produtividadeBetaService: ProdutividadeBetaService,
    private errorHandlerService: ErrorHandlerService,
    private notificatorService: NotificatorService
  ) {}

  ngOnInit(): void {
    this.initializeInfoCardData();
    this.getSurveyData();
  }

  /**
   * Inicializa os dados do header
   * Caso ocorra um erro os dados serão preenchidos com 0 e o usuário será notificado
   */
  private initializeInfoCardData(): void {
    this.produtividadeBetaService.getInfoCardData().subscribe({
      next: (data) => {
        Object.entries(data).forEach(([ key, value ]) => {
          this.infoCardData.data.push({
            title: this.replaceTitle(key as ProdutividadeSituacaoPesquisas),
            value: value,
          });
        });
      },
      error: () => {
        /**
         * TODO: isolar essas mensagens de erro em constantes
         */
        this.notificatorService.showError(
          "Algo deu errado",
          "Não foi possível obter os dados de auditoria"
        );
        this.infoCardData.data.push(...infoCardDefaultValue);
      },
    });
  }

  /**
   * formata o atributo do back para exibir o atributo e o valor
   * @param title string
   * @returns titulo do info card formatado
   */
  private replaceTitle(title: ProdutividadeSituacaoPesquisas): ProdutividadeSituacaoPesquisasFriendlyNames {
    return ProdutividadeSituacaoPesquisasFriendlyNames[title];
  }

  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["PAGINATION_CHANGED"]: () => this.changePage($event),
      ["CELL_ICON_CLICK"]: () => this.handleClickView($event),
      ["SEARCH_FILTER_CHANGED"]: () => this.changeText($event),
      ["RADIO_FILTER_CHANGED"]: () => this.selectOrder($event),
      ["CHECK_FILTER_CHANGED"]: () => this.selectStatus($event),
      ["DATE_FILTER_CHANGED"]: () => this.selectRange($event),
      ["CELL_CLICK"]: () => this.handleClickTitle($event),
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  handleClickTitle({ cellId, rowId }) {
    this.handleClickView({ rowId });
  }

  changePage({ requestedPage, previousPage }) {
    this.pagination.current = requestedPage;
    this.pagination.previous = previousPage;
    this.getSurveyData();
  }

  handleClickView({ rowId }) {
    this.router.navigate(["/produtividade-beta/visao-geral", rowId]);
  }

  changeText({ filterValue }) {
    this.filterOptions.keywords = [filterValue];
    this.resetPagination();
    this.getSurveyData();
  }

  selectOrder($event: { filterValue: IFilterSelect[] }) {
    const [selectedSortingOption] = $event.filterValue;
    const [sortingType = "", direction = ""] = (
      selectedSortingOption?.key || ""
    ).split("-");
    this.filterOptions.filtrableAttributes = sortingType;
    this.filterOptions.filtrableOrder = direction;
    this.getSurveyData();
  }

  selectStatus({ filterValue: { currentState } }) {
    this.filterOptions.status = [...currentState.map((v) => v.key)];
    this.resetPagination();
    this.getSurveyData();
  }

  selectRange({ filterValue }) {
    console.log(filterValue);
  }

  /**
   * Obtem o total de pesquisas cadastradas baseado no filtro informado
   * @param keywords: palavras chave
   * @param status: situação da pesquisa
   */
  getTotalSurvey(keywords: string[], status: string[]): void {
    const filters = {
      palavrasChave: [...keywords],
      situacoes: [...status],
    };

    this.produtividadeBetaService.getTotalSurveys(filters).subscribe({
      next: (data) => {
        if (typeof data === "number") {
          this.totalOfSurveys = data || 0;
        }
      },
    });
  }

  getSurveyData() {
    this.isLoading = true;

    const { keywords, status, filtrableAttributes, filtrableOrder } =
      this.filterOptions;

    this.getTotalSurvey(keywords, status);

    const formatedPage = this.pagination.current - 1;

    this.produtividadeBetaService
      .filterSurveyBy(
        formatedPage,
        10,
        filtrableAttributes,
        filtrableOrder,
        keywords,
        status
      )
      .subscribe({
        next: (response) => {
          const allSurveys = response && this.buildTableRow(response);
          this.tableData = allSurveys || [];
        },
        error: (err) => {
          this.errorHandlerService.handleError(
            err,
            errorMessages.surveyList.title,
            errorMessages.surveyList.message
          );
          this.pagination.current = this.pagination.previous;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  buildTableRow(surveys: ISurveyListResponse[]): TableRow[] {
    return surveys.map((survey) =>
      tRowFactory(
        //Coluna de título
        textCellFactory(survey.titulo)
          .cellStyleBehavior("UNDERLINE_ON_HOVER")
          .build(),
        //Coluna do nome do cliente
        textCellFactory(survey.cliente.nome).customStyles({"pointer-events": "none"}).build(),
        //Coluna de período
        textCellFactory(
          `${survey.data_inicial} - ${survey.data_final}`
        ).customStyles({"pointer-events": "none"}).build(),
        //Coluna de situação da pesquisa em relação a auditoria
        statusCellFactory(
          ESituacaoColor[survey.situacao],
          ESituacao[survey.situacao],
          "var(--white)"
        ).build(),
        //Coluna de ícone
        iconCellFactory(["fa fa-eye"], "button", {
          label: "Visualizar",
          position: "left",
        })
          .customStyles({ "font-size": "18px" })
          .alignType("center")
          .build()
      )
        .id(survey.id)
        .build()
    );
  }

  resetPagination() {
    this.pagination.current = 1;
    this.pagination.previous = 1;
  }
}
