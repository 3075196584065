import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import {
  ScheduleModel,
  SchedulesModel,
} from "../../cadastro/steps/pesquisas-configuracoes/pesquisas-configuracoes.model";
import { PesquisaConfiguracoesService } from "../../cadastro/steps/pesquisas-configuracoes/servico/pesquisa-configuracoesservice";

interface DateIntervalByMonth {
  days: string[];
  month: string | number;
  year: number;
}

@Component({
  selector: "app-info-section",
  templateUrl: "./info-section.component.html",
  styleUrls: ["./info-section.component.scss"],
})
export class InfoSectionComponent implements OnInit {
  @Input() pesquisaBody;

  // nome do cliente
  client: string = "";
  // Datas
  schedules: SchedulesModel;
  // data de inicio
  startDate: string = "";
  // data de fim
  endDate: string = "";
  // array de intervalos (datas faltantes no range)
  dataIntervalArray = [];

  monthsToLook: number[] = [];

  dateIntervalByMonths: DateIntervalByMonth[] = [];
  // array de datas montadas
  dataToCompareArray;

  // Variaveis de controle
  isHibrid: boolean = false;
  isOnlyPresencial: boolean = false;
  isOnlyOnline: boolean = false;

  constructor(private clienteService: PesquisaConfiguracoesService) {}

  ngOnInit() {
    if (this.pesquisaBody) {
      if (
        this.pesquisaBody.configuracaoPesquisa.amostrasOnline > 0 &&
        this.pesquisaBody.configuracaoPesquisa.amostrasPresenciais > 0
      ) {
        this.isHibrid = true;
      } else if (
        this.pesquisaBody.configuracaoPesquisa.amostrasOnline === 0 &&
        this.pesquisaBody.configuracaoPesquisa.amostrasPresenciais > 0
      ) {
        this.isOnlyPresencial = true;
      } else {
        this.isOnlyOnline = true;
      }
      const { collectStart, collectEnd } = this.getCollectInterval();
      this.startDate = this.handleConvertDate(collectStart);

      this.endDate = this.handleConvertDate(collectEnd);

      this.schedules = this.toSchedulesModel(
        this.pesquisaBody.configuracaoPesquisa.agendamentos
      );

      this.dataToCompareArray =
        this.pesquisaBody.configuracaoPesquisa.agendamentos;

      this.handleMountIntervalDate(
        this.pesquisaBody.configuracaoPesquisa.agendamentos
      );

      this.clienteService
        .findById(String(this.pesquisaBody.cliente.id))
        .subscribe((response) => {
          this.client = response.pessoa.nome;
        });
    }
  }

  getCollectInterval() {
    // converte o array de datas em string para objeto Date;
    const dates = this.pesquisaBody.configuracaoPesquisa.agendamentos.map(
      ({ dataColeta }) => {
        const [day, month, year] = dataColeta.split("/");
        return new Date(year, month - 1, day);
      }
    );
    // retorna a menor data do array
    const startDate = dates.reduce((acc, cur) => (acc < cur ? acc : cur));
    // retorna a mais data do array
    const endDate = dates.reduce((acc, cur) => (acc > cur ? acc : cur));

    // retorna como objeto a data de inicio e fim no formato que o método handleConvertDate(...arg) espera (dd/mm/yyyy)
    return {
      collectStart: startDate.toLocaleDateString(),
      collectEnd: endDate.toLocaleDateString(),
    };
  }

  handleCalendarChanges() {}

  // converte para schedules model
  toSchedulesModel(values: any[]): SchedulesModel {
    const sm = values.map(
      (v) =>
        ({
          ...v,
        } as ScheduleModel)
    );
    return new SchedulesModel(sm);
  }

  handleConvertString(tipo: string): string {
    switch (tipo) {
      case "opinião":
        return "Opinião";
      case "mercado":
        return "Mercado";
      case "científica":
        return "Científica";
      default:
        return "Eleitoral";
    }
  }

  // Função para somar o total a partir de amostra presencial e online
  handleSumTotal(amostraPresencial: number, amostraOnline: number): number {
    return amostraPresencial + amostraOnline;
  }

  // Função para converter a data em template string literal
  handleConvertDate(data: string, alreadyDated?: boolean): string {
    let dataToReturn;

    if (!alreadyDated) {
      const convertData = new Date(data.split("/").reverse().join("-"))
        .toISOString()
        .split("T");

      dataToReturn = moment(convertData[0]).format("DD [de] MMMM [de] YYYY");
    } else {
      dataToReturn = moment(data).format("DD [de] MMMM [de] YYYY");
    }

    return dataToReturn;
  }

  // Função que irá criar o array de datas entre o intervalo desejado
  handleCreateDates(startDate, endDate): void {
    const date = startDate;

    date.setDate(date.getDate() + 1);
    endDate.setDate(endDate.getDate() + 1);

    this.dataToCompareArray = [];

    while (date <= endDate) {
      this.dataToCompareArray.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
  }

  // Função responsável por montar o array de intervalos de datas
  handleMountIntervalDate(datas): void {
    const startDate = new Date(
      datas[0].dataColeta.split("/").reverse().join("-")
    );
    const endDate = new Date(
      datas[datas.length - 1].dataColeta.split("/").reverse().join("-")
    );

    this.handleCreateDates(startDate, endDate);

    // criando um novo array com as datas de agendamento para estarem no formado
    // adequado para ser comparado com o array de datas criandos com o intervalo de inicio e fim
    const convertDatas = datas.map((item) => {
      const dataItem = new Date(item.dataColeta.split("/").reverse().join("-"));

      dataItem.setDate(dataItem.getDate() + 1);

      return dataItem;
    });

    // Loop para percorrer todas as datas criadas entre o intervalo e a que não
    // for encontrada no array de datas de agendamento, será adicionada no array
    // que será exibido como intervalos
    for (let i = 0; i < this.dataToCompareArray.length; i++) {
      const result = convertDatas.find(
        (item) => item.getTime() === this.dataToCompareArray[i].getTime()
      );

      if (
        !this.monthsToLook.find(
          (item) => item === this.dataToCompareArray[i].getMonth()
        )
      ) {
        this.monthsToLook.push(this.dataToCompareArray[i].getMonth());
      }

      if (!result) {
        this.dataIntervalArray.push(this.dataToCompareArray[i]);
      }
    }

    this.handleCreateNewDataArrayByMonth();
  }

  // Função para agrupar por meses
  handleCreateNewDataArrayByMonth(): void {
    const newArray = this.dataIntervalArray.reduce((acc, cur) => {
      if (acc && acc.find((item) => item.month === cur.getMonth())) {
        for (let i = 0; i < acc.length; i++) {
          if (acc[i].month === cur.getMonth()) {
            acc[i].days.push(cur.getDate());
          }
        }
      } else {
        acc.push({
          month: cur.getMonth(),
          days: [cur.getDate()],
          year: cur.getFullYear(),
        });
      }
      return acc;
    }, []);

    this.dateIntervalByMonths = newArray;

    this.handleModifyTemplateOfArrayByMonth();
  }

  handleGetLiteralMonth(monthByNumber: number): string {
    switch (monthByNumber) {
      case 0:
        return "Janeiro";
      case 1:
        return "Fevereiro";
      case 2:
        return "Março";
      case 3:
        return "Abril";
      case 4:
        return "Maio";
      case 5:
        return "Junho";
      case 6:
        return "Julho";
      case 7:
        return "Agosto";
      case 8:
        return "Setembro";
      case 9:
        return "Outubro";
      case 10:
        return "Novembro";
      case 11:
        return "Dezembro";
      default:
        return "";
    }
  }

  handleModifyTemplateOfArrayByMonth(): void {
    this.dateIntervalByMonths = this.dateIntervalByMonths.map((item) => {
      item.month = this.handleGetLiteralMonth(+item.month);

      return item;
    });
  }
}
