import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Authority } from '../../authority';
import { RouterItemData } from '../../routerItemData';
import { SecurityService } from '../../service/securityService';

/**
 * Classe responsavel por tratar a autorizacao que usuarios autenticados
 * tem a determinadas rotas
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuardService implements CanActivate {

  constructor(
    protected securityService: SecurityService,
    protected router: Router,
  ) { }

  /**
   * Verifica se o usuario autenticado possui as autoridades
   * necessarias para adentrar em determinada rota, caso contrario
   * ele sera redirecionado para o callback passado e caso
   * este não exista, voltará ao dashboard
   */
  canActivate(route: ActivatedRouteSnapshot): boolean {

    const routerItemData = <RouterItemData>route.data;

    if (this.userHasAuthorities(route)) {
      return true;
    }

    if (routerItemData.failureRedirectTo.length > 0) {
      this.router.navigate([routerItemData.failureRedirectTo], { queryParams: { redirect: true } });
      return true;
    }

    this.router.navigate(['']);
    return false;
  }

  /**
   * Metodo que verifica se o usuario tem as autoridades necessarias
   * para ativar a rota
   */
  protected userHasAuthorities(route: ActivatedRouteSnapshot): boolean {

    const routerItemData = <RouterItemData>route.data;
    const expectedAuthorities: Authority[] = routerItemData.necessaryAuthorities;
    const authenticatedUser = this.securityService.getAuthenticatedUser();

    if (expectedAuthorities.length === 0) {
      return true;
    }

    if (authenticatedUser && authenticatedUser.authorities) {
      const hasNecessaryAuthority = authenticatedUser
        .authorities
        .some(authority => expectedAuthorities.includes(authority));

      if (hasNecessaryAuthority) {
        return true;
      }
    }
  }

}
