import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/componentes/filterable-select/filterable-select-component";
import { IQuestionOpenAnswer } from "../../interfaces/open-answers";

export function parseQuestionToAccordenModel(
  quesionList: Array<IQuestionOpenAnswer>
): Array<SelectDataItem> {
  return orderQuestionList(quesionList).map(({ nome, id }) => ({
    label: nome,
    value: id,
  }));
}

function orderQuestionList(
  quesionList: Array<IQuestionOpenAnswer>
): Array<IQuestionOpenAnswer> {
  return quesionList.sort(
    ({ ordem: ordemA }, { ordem: ordemB }) => ordemA - ordemB
  );
}
