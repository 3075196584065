import { TipoAlternativa } from "app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa";

/**
 * Representa uma alternativa no módulo do
 * questionário auditado
 */
export class AlternativaAuditada {
  constructor(
    idAlternativa: number,
    idRespostaOriginal: number,
    possuiRespostaOriginal: boolean,
    tipo: TipoAlternativa,
    texto: string,
    checked: boolean,
    ordem: number,
    ordemSelecao: number,
    enunciado: string
  ) {
    this.id_alternativa = idAlternativa;
    this.id_resposta_original = idRespostaOriginal;
    this.isRespostaOriginal = possuiRespostaOriginal;
    this.tipo = tipo;
    this.texto = texto;
    this.checked = checked;
    this.ordem_renderizacao = ordem;
    this.ordem_selecao = ordemSelecao;
    this.enunciado = enunciado;

    this.atualizarFlags();
  }

  id_alternativa: number;
  /**
   * Opcional para pergunta sem alternativas
   */
  id_resposta_original?: number;

  /**
   * Opcional para alternativa aberta
   */
  texto?: string;

  /**
   * Opcional
   */
  enunciado?: string;

  checked: boolean;

  tipo: TipoAlternativa;

  ordem_renderizacao: number;
  ordem_selecao?: number;

  isSelecionada: boolean = false;
  isAbertaTexto: boolean = false;
  isAbertaNumero: boolean = false;
  isEscalaNumerica: boolean = false;
  isFechada: boolean = false;
  isRespostaOriginal: boolean = false;

  private atualizarFlags() {
    this.isSelecionada = this.checkIsSelecionada();
    this.isAbertaTexto = this.checkIsAbertaTexto();
    this.isAbertaNumero = this.checkIsAbertaNumero();
    this.isEscalaNumerica = this.checkIsEscalaNumerica();
    this.isFechada = this.checkIsFechada();
  }

  public checkIsSelecionada() {
    return this.checked;
  }

  public checkIsAbertaTexto() {
    return this.tipo === TipoAlternativa.ABERTA_TEXTO;
  }

  public checkIsAbertaNumero() {
    return this.tipo === TipoAlternativa.ABERTA_NUMERO;
  }

  public checkIsAbertaTextoOuNumero() {
    return this.checkIsAbertaTexto() || this.checkIsAbertaNumero();
  }

  public checkIsFechada() {
    return this.tipo === TipoAlternativa.FECHADA;
  }

  public checkIsEscalaNumerica() {
    return this.tipo === TipoAlternativa.ESCALA_NUMERICA;
  }

  public getTexto() {
    return this.texto;
  }

  public getIdAlternativa() {
    return this.id_alternativa;
  }

  public getIdRespostaOriginal() {
    return this.id_resposta_original;
  }

  public getOrdemSelecao() {
    return this.ordem_selecao;
  }

  public getTipoAlternativa() {
    return this.tipo;
  }

  public setSelecionada(estado: boolean) {
    this.checked = estado;
    this.isSelecionada = estado;
  }

  public setOrdemSelecao(valor: number) {
    this.ordem_selecao = valor;
  }

  public setTexto(valor: string) {
    this.texto = valor;
  }

  public setIdRespostaOriginal(id: number) {
    this.id_resposta_original = id;
  }

  public static ofSimples(
    idAlternativa: number,
    idRespostaOriginal: number,
    possuiRespostaOriginal: boolean,
    tipoAlternativa: TipoAlternativa,
    checked: boolean,
    ordem: number,
    ordemSelecao: number,
    texto: string,
    enunciado: string
  ) {
    return new AlternativaAuditada(
      idAlternativa,
      idRespostaOriginal || null,
      possuiRespostaOriginal,
      tipoAlternativa,
      texto || null,
      checked,
      ordem,
      ordemSelecao || null,
      enunciado
    );
  }
}
