import { LgpdTypes } from "app/servico/lgpdService/lgpd-interface";
const anchorStyle = `style="text-decoration: underline; font-weight: 600; color: var(--gray500);"`;

export interface IPromptTexts {
  title: string;
  body: string;
}

export const promptTexts = {
  default: {
    title: "Termos e condições",
    body(hrefPrivacyPolicy: string, hrefTermsOfUse: string) {
      return `Para iniciar o Tensai, você precisa concordar com nossos <a ${anchorStyle} target="_blank" href="${hrefTermsOfUse}">Termos de Uso</a> e <a ${anchorStyle} target="_blank" href="${hrefPrivacyPolicy}">Política de Privacidade</a>`;
    },
  },
  [LgpdTypes.PRIVACY_POLICY]: {
    title: "Termos e condições",
    body(hrefPrivacyPolicy: string) {
      return `Para iniciar o Tensai, você precisa concordar com nossa <a ${anchorStyle} target="_blank" href="${hrefPrivacyPolicy}">Política de Privacidade</a>`;
    },
  },
  [LgpdTypes.TERMS_OF_USAGE]: {
    title: "Termos e condições",
    body(hrefTermsOfUse: string) {
      return `Para iniciar o Tensai, você precisa concordar com nossos <a ${anchorStyle} target="_blank" href="${hrefTermsOfUse}">Termos de Uso</a>`;
    },
  },
};

export const cancelPromptTexts = {
  default: {
    title: "Termos e condições",
    body(hrefPrivacyPolicy: string, hrefTermsOfUse: string) {
      return `Rejeitando nossos <a ${anchorStyle} target="_blank" href="${hrefTermsOfUse}">Termos de Uso</a> e <a ${anchorStyle} target="_blank" href="${hrefPrivacyPolicy}">Política de Privacidade</a> você não terá acesso ao Tensai. Deseja mesmo rejeitar?`;
    },
  },
  [LgpdTypes.PRIVACY_POLICY]: {
    title: "Termos e condições",
    body(hrefPrivacyPolicy: string) {
      return `Rejeitando nossa <a ${anchorStyle} target="_blank" href="${hrefPrivacyPolicy}">Política de Privacidade</a> você não terá acesso ao Tensai. Deseja mesmo rejeitar?`;
    },
  },
  [LgpdTypes.TERMS_OF_USAGE]: {
    title: "Termos e condições",
    body(hrefTermsOfUse: string) {
      return `Rejeitando nossos <a ${anchorStyle} target="_blank" href="${hrefTermsOfUse}">Termos de Uso</a> você não terá acesso ao Tensai. Deseja mesmo rejeitar?`;
    },
  }
}
