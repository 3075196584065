import { IGroups, IPermissoes } from "../interfaces";

/**
 * Atualiza as permissões da lista "allPermissions" toda vez que um grupo novo for selecionado
 * Caso a permissão seja inserida através da seleção de um novo grupo o atributo disabled será = true;
 * @param allPermissions: lista com todas permissões retornadas pelo back-end
 * @param selectedPermissions: permissões selecionadas (manualmente ou através dos grupos)
 * @returns novo array de permissões
 */
export function updatePermissionsListWhenAGroupIsAdd(allPermissions, selectedPermissions): IPermissoes[] {
  let updatedPermissions = [...allPermissions];

  selectedPermissions.forEach(selectedPermission => {
    const index = updatedPermissions.findIndex(permission => permission.nomeReal === selectedPermission.nomeReal && selectedPermission.disabled);
    if (index !== -1) {
      updatedPermissions[index].selecionado = true;
      updatedPermissions[index].disabled = true;
    }
  });

  return updatedPermissions;
}


/**
 * Atualiza as permissões da lista "allPermissions" toda vez que um grupo novo for removido
 * @param allPermissions: lista com todas permissões retornadas pelo back-end
 * @param selectedPermissions: permissões selecionadas (manualmente ou através dos grupos)
 * @returns novo array de permissões
 */
export function updatePermissionsListWhenAGroupIsRemoved(allPermissions, selectedPermissions): IPermissoes[] {
  let updatedPermissions = [...allPermissions];

  updatedPermissions.forEach(permission => {
    if(!selectedPermissions.includes(permission)) {
     permission.selecionado = false;
     permission.disabled = false;
    }
  });

  return updatedPermissions;
}


/**
 * Atualiza os grupos da lista "allGroups" toda vez que um grupo novo for selecionado de forma não manual
 * @param allGroups: lista com todas grupos retornados pelo back-end
 * @param selectedGroups: grupos selecionadas
 * @returns novo array de grupos
 */
export function updateAllGroupsListOnEdit(allGroups, selectedGroups): IGroups[] {
  let updatedGroups = [...allGroups];

  selectedGroups.forEach(selectedGroup => {
    const index = updatedGroups.findIndex(group => group.nome === selectedGroup.nome);
    if (index !== -1) {
      updatedGroups[index].selecionado = true;
    }
  });

  return updatedGroups;
}


  /**
 * Atualiza as permissões da lista "allPermissions" toda vez que os dados forem retornados
 * pelo back-end no modo edição
 * @returns novo array de permissões
 */
export function updateAllPermissionsListOnEdit(allPermissions, selectedPermissions): IPermissoes[] {
  let updatePermissions = [...allPermissions];

  selectedPermissions.forEach(selectedPermission => {
    const index = updatePermissions.findIndex(permission => permission.nomeReal === selectedPermission.nomeReal);
    if (index !== -1) {
      updatePermissions[index].selecionado = true;
    }
  });

  return updatePermissions;
}
