import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {
  private audioIsPlaying: boolean = false;
  private audioPlaySubject = new Subject<boolean>();

  get isPlaying(): boolean {
    return this.audioIsPlaying;
  }

  public playAudio(): void {
    this.audioIsPlaying = true;
    this.audioPlaySubject.next(this.audioIsPlaying);
  }

  public pauseAudio(): void {
    this.audioIsPlaying = false;
    this.audioPlaySubject.next(this.audioIsPlaying);
  }

  public reset(): void {
    this.audioIsPlaying = false;
  }

  public get audioPlay$() {
    return this.audioPlaySubject.asObservable();
  }
}