import {
  EGroupType,
  ICitation,
} from "../../../../interfaces/audit-open-answer";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { IGroup } from "app/modulos/auditoria-beta/interfaces/audit-open-answer";
import { MatchOperationsService } from "app/modulos/auditoria-beta/services/consolidacao/match-operations.service";
import { MatchPersistenceService } from "app/modulos/auditoria-beta/services/consolidacao/match-persistance.service";
import { getIndexByList } from "app/modulos/auditoria-beta/utils/getIndexByArray";

enum ArraySelection {
  AVULSES = "AVULSES",
  GROUP = "GROUP",
}

@Component({
  selector: "app-step-one",
  templateUrl: "./step-one.component.html",
  styleUrls: ["./step-one.component.scss"],
})
export class StepOneComponent implements OnInit, OnChanges {
  @Input() citationList: ICitation[] = [];
  @Input() groupList: IGroup[] = [];
  @Input() surveyId: number = null;
  @Input() questionId: number = null;

  newGroup = null;

  constructor(
    private matchOperationsService: MatchOperationsService,
    private matchPersistenceService: MatchPersistenceService
  ) {}

  ngOnInit(): void {
    this.matchOperationsService.initSubscriptions({
      onAutoCheckStepOneGroups: this.autoSelectGroup.bind(this),
    });
  }

  ngOnChanges({ citationList, groupList }: SimpleChanges): void {
    if (!citationList?.firstChange && !groupList?.firstChange) {
      this.avulsesSelected = [];
      this.groupSelected = [];

      // recupera o id do novo grupo que foi inserido para destacar na tela para o usuário
      const group = groupList.currentValue.filter(
        (x) => groupList.previousValue.indexOf(x) === -1
      );

      this.newGroup = group.length ? group.pop().id : "";
    }
  }

  avulsesSelected: Array<ICitation> = [];
  groupSelected: Array<IGroup> = [];

  avulseToEdit: number = 0;
  newAvulseTitle: string = "";
  avulseOrigemResposta: string = "";
  searchText = "";

  orderByType(list: Array<IGroup>) {
    return [...list].sort(
      ({ typeGroup: typeGroupA }, { typeGroup: typeGroupB }) => {
        if (
          typeGroupA === EGroupType.MANUAL &&
          typeGroupB === EGroupType.AUTOMATICA
        ) {
          return -1; // "A" vem antes de "B"
        }
        if (
          typeGroupA === EGroupType.AUTOMATICA &&
          typeGroupB === EGroupType.MANUAL
        ) {
          return 1; // "B" vem depois de "A"
        }
        return 0;
      }
    );
  }

  get totalSelected() {
    return this.avulsesSelected.length + this.groupSelected.length;
  }

  // Retorna para fins de exibição em tela a lista filtrada por texto de respostas abertas
  get avulseFilteredList(): Array<ICitation> {
    return this.citationList.filter(({ title }) =>
      title.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  // Retorna para fins de exibição em tela a lista filtrada por texto dos grupos
  get groupFilteredList(): Array<IGroup> {
    return this.orderByType(
      this.groupList.filter(({ title }) =>
        title.toLowerCase().includes(this.searchText.toLowerCase())
      )
    );
  }

  filterByText(value) {
    this.searchText = value;
  }

  /**
   * controla se uma reposta está selecionada
   * @param item Citação ou grupo
   * @param type Qual array será iterado respostas ou de grupo de respostas
   * @returns true ou false
   */
  hasSelected(item: ICitation | IGroup, type = "AVULSES"): boolean {
    const list = {
      ["AVULSES"]: [...this.avulsesSelected],
      ["GROUP"]: [...this.groupSelected],
    };

    return getIndexByList(item.id, list[type]) !== -1;
  }

  /**
   * Adiciona ou remove uma seleção de respostas abertas
   * @param avulse Uma resposta aberta
   */
  selectAvulses(avulse: ICitation) {
    if (!this.hasSelected(avulse, ArraySelection.AVULSES)) {
      this.avulsesSelected.push(avulse);
    } else {
      const deleteindex = this.avulsesSelected.findIndex(
        ({ id }: ICitation) => id === avulse.id
      );
      this.avulsesSelected.splice(deleteindex, 1);
    }
    this.matchOperationsService.updateSelectedCitationAndGroup({
      avulses: this.avulsesSelected,
      groups: this.groupSelected,
    });
  }

  /**
   * Adiciona ou remove uma seleção de grupos
   * @param group grupo de respostas
   */
  selectGroup(group: IGroup) {
    if (!this.hasSelected(group, ArraySelection.GROUP)) {
      this.groupSelected.push(group);
    } else {
      const deleteindex = this.groupSelected.findIndex(({ id }: IGroup) => {
        return id === group.id;
      });
      this.groupSelected.splice(deleteindex, 1);
    }
    this.matchOperationsService.updateSelectedCitationAndGroup({
      avulses: this.avulsesSelected,
      groups: this.groupSelected,
    });
  }

  /**
   * Escuta se houver seleção do grupo no passo 2 para seleciona-lo
   * caso o grupo já esteja selecionado, nada acontecerá
   */
  autoSelectGroup(group: IGroup) {
    if (group !== null && !this.hasSelected(group, ArraySelection.GROUP)) {
      this.selectGroup(group);
    }
  }

  /**
   * Habilita a edição do titulo de uma resposta aberta
   * @param avulseId id da resposta que terá seu titulo alterado
   */
  handleOpenEditTitleAvulse(avulseId: number) {
    const editingIndex = getIndexByList(avulseId, this.citationList);
    this.avulseToEdit = avulseId;
    this.newAvulseTitle = this.citationList[editingIndex].title;
    this.avulseOrigemResposta = this.citationList[editingIndex].origem_resposta;
  }

  // Cancela a edição do titulo de uma alternativa
  handleCancelEditTitleAvulse() {
    this.newAvulseTitle = "";
    this.avulseToEdit = 0;
    this.avulseOrigemResposta = null
  }

  // Salva a alteração do titulo de uma alternativa
  handleSaveEditTitleAvulse() {
    this.matchPersistenceService.handleEditAvulseTitle(
      this.surveyId, this.questionId, this.avulseToEdit, this.newAvulseTitle, this.avulseOrigemResposta
    );
    this.handleCancelEditTitleAvulse();
  }
}
