import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { ModalService } from "app/componentes/modal/modal.service";
import { IVerifyLoginCredentials } from "app/infraestrutura/security/service/loginCredentials";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { IFilterSelect } from "app/modulos/auditoria-beta/interfaces";
import { NotificatorService } from "app/notificador/notificator.service";
import { ActionButtonConfig } from "app/util/componente/genericTable/interfaces/actionButton";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { MeatballConfig } from "app/util/componente/genericTable/interfaces/meatball";
import { RowCheckboxConfig } from "app/util/componente/genericTable/interfaces/rowCheckbox";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import { TableEvents, TableGlobalConfig } from "app/util/componente/genericTable/interfaces/table";
import {
  checkboxCellFactory,
  componentCellFactory,
  meatballCellFactory,
  statusCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import {
  IResponseClientModel,
  ISwitchStatus
} from "../interfaces/list";
import { ClienteService } from "../services/cliente.service";
import { ClientFilterName, ClientStatusHeaderIndex, DirectionOrderTypes } from "../constants/listagem";
import { MEATBALL_OPTIONS } from "../constants/meatball";
import { Authority } from "app/infraestrutura/security/authority";
import { ClientNavigateService } from "../services/cliente-navigate.service";
import { GenericTableService } from "app/util/componente/genericTable/service/generic-table.service";
import { CustomTagComponent } from "app/util/componente/genericTable/components/customCell/custom-tag/custom-tag.component";

const STATUS_FILTER_ID = 1;
const SORTING_FILTER_ID = 2;

@Component({
  selector: "app-listagem-cliente",
  templateUrl: "./listagem-cliente.component.html",
  styleUrls: ["./listagem-cliente.component.scss"],
})
export class ListagemClienteComponent implements OnInit {

  // Filtros gerais para persistência
  filters: {
    status: boolean;
    keywords: string;
    sorting: {
      direction: DirectionOrderTypes;
      sort: ClientFilterName,
    };
  } = {
    status: null,
    sorting: { direction: null, sort: null },
    keywords: "",
  };

  filterConf = {
    icon: FilterIcon.FUNNEL,
    type: FilterTypes.RADIO,
  };

  filtersConfig: FilterConfigs[] = [
    {
      id: STATUS_FILTER_ID,
      type: FilterTypes.RADIO,
      icon: FilterIcon.FUNNEL,
      placeholder: "Situação",
      options: [
        { id: 1, label: "Ativo", key: true },
        { id: 2, label: "Inativo", key: false },
      ],
    },
    {
      id: SORTING_FILTER_ID,
      type: FilterTypes.RADIO,
      icon: FilterIcon.SORTING,
      placeholder: "Ordenar",
      options: [
        { id: 1, label: "Nome de A a Z", key: DirectionOrderTypes.ASC },
        { id: 2, label: "Nome de Z a A", key: DirectionOrderTypes.DESC },
      ],
    },
  ];

  sortingConf = {
    icon: FilterIcon.SORTING,
    type: FilterTypes.RADIO,
  };

  sortingOptions: IFilterSelect[] = [
    {
      id: 1,
      label: "Nome de A a Z",
      key: "NOME-ASC",
    },
    {
      id: 2,
      label: "Nome de Z a A",
      key: "NOME-DESC",
    },
  ];
  isLoading: boolean;
  totalClients = 0;
  pagination = {
    current: 1,
    previus: 1,
  };
  checkboxModalState: boolean;
  rowCustomStyleId: number = null;

  constructor(
    private clienteService: ClienteService,
    private notificator: NotificatorService,
    private modalService: ModalService,
    private router: Router,
    private securityService: SecurityService,
    private clientNavigateService: ClientNavigateService,
    private genericTableService: GenericTableService,
  ) {}

  showActionButton: boolean = false;

  credentials: IVerifyLoginCredentials = {
    login: this.securityService.getAuthenticatedUser().login,
    password: ''
  }

  infoCardDefaultValue = [];

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Cliente",
      itemLink: "/cliente-beta",
      active: true,
    },
  ];

  clientes: IResponseClientModel[] = [];

  ngOnInit(): void {
    this.getClientes();
    this.getHeaderdata();
    // captura o valor do checkbox do modal e repassa para os componentes filhos
    this.modalService.getCheckbox().subscribe({
      next: (currValue) => (this.checkboxModalState = currValue),
    });

    this.modalService.getInput().subscribe({
      next: (inputValue) => {
        this.credentials.password = inputValue

        if(inputValue !== null && !inputValue.length) {
          this.modalService.handleInputErrors(['Senha obrigatória']);
        } else {
          this.modalService.cleanInputErrors();
        }
      }
    });

    this.initAuthorityConfigs();
  }

  /**
   * Configura as opções visiveis e acessíveis para o usuário com base em suas permissões
   */
  initAuthorityConfigs() {
    const authorities = this.securityService.getAuthenticatedUserAuthorities();

    this.showActionButton = authorities.some(authority => authority === Authority.CADASTRAR_CLIENTE);

    const canEditUser = authorities.some(authority => [Authority.ATUALIZAR_CLIENTE].includes(authority));

    this.meatballConfig.disabledItemsIds = [
      ...(!canEditUser ? [MEATBALL_OPTIONS.EDIT_OPTION_ID, MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID] : []),
    ];
  }

  /**
   * Adiciona um estilo customizado na linha correspondente ao cliente
   * cadastro ou editado
   */
  setFocusOnNewClient() {
    this.rowCustomStyleId = 66;
    const employeeId = this.clientNavigateService.getClientId()
    if(employeeId !== null) {
      setTimeout(() => {
        this.genericTableService.insertStyles({
          type: "ROW",
          rowId: employeeId,
          styles: {
            border: '1px solid var(--secondary)'
          },
          styleId: this.rowCustomStyleId
        })
      }, 150)
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick() {
    if(this.rowCustomStyleId !== null) {
      this.genericTableService.clearStyles({
        type: "ROW",
        styleId: this.rowCustomStyleId
      });
      this.rowCustomStyleId = null;
    }
  }

  // Recupera os dados do header
  getHeaderdata() {
    this.clienteService.getClientHeader().subscribe({
      next: (data) => {
        this.infoCardDefaultValue = [
          {
            title: "Clientes",
            value: data.total,
          },
          {
            title: "Ativos",
            value: data.ativos,
          },
          {
            title: "Inativos",
            value: data.inativos,
          }
        ]
      },
      error: (err) => {console.error(err)}
    })
  }

  tableHead: TableHead[] = [
    {
      type: "checkbox",
      checkable: true,
    },
    {
      type: "text",
      label: "Nome",
    },
    {
      type: "text",
      label: "CPF/CNPJ",
    },
    {
      type: "text",
      label: "Situação",
    },
    {
      type: "text",
      label: "",
    },
  ];

  tableConfig: TableGlobalConfig = {
    colWidths: [".04", ".4", ".3", ".2", ".06"],
    rowStyles: { "font-size": "14px" },
  };

  serchConfig: SearchInputConfig = {
    placeholder: "Busque por nome de Cliente",
    delay: 2000,
  };

  tableData: TableRow[] = [];

  checkConfig: RowCheckboxConfig = {
    disableOnHeader: false,
    headerNotSelectable: false,
    selectable: () => true,
  };

  meatballConfig: MeatballConfig = {
    type: "GENERIC",
    options: [
      { id: MEATBALL_OPTIONS.EDIT_OPTION_ID, icon: 'fa-regular fa-pen-to-square', label: "Editar" },
      { id: MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID, icon: (rowRef) => rowRef.metadata.ativo ? 'fa-toggle-large-on fa-regular' : 'fa-toggle-large-off fa-regular', label: (rowRef) => rowRef.metadata.ativo ? "Inativar" : "Ativar" },
    ],
  };

  actionButton: ActionButtonConfig = {
    label: "Novo cliente",
    icon: "fa-light fa-plus",
  };

  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["PAGINATION_CHANGED"]: this.changePage.bind(this),
      ["SEARCH_FILTER_CHANGED"]: this.handleTextChange.bind(this),
      ["CELL_CLICK"]: this.handleRedirectToEdit.bind(this),
      ["ACTION_BUTTON_CLICK"]: this.handleRedirectToAdd.bind(this),
      ["HEADER_CHECKBOX_CLICK"]: () => {},
      ["CELL_CHECKBOX_CLICK"]: () => {},
      ["RADIO_FILTER_CHANGED"]: this.handleChangeRadioFilter.bind(this),
      ["CHECK_FILTER_CHANGED"]: () => {},
      ["MEATBALL_OPTION_SELECTED"]: this.handleMeatballOptionClick.bind(this)
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  /**
   * Lida com eventos de clique que ocorrem no meatball genérico
   */
  handleMeatballOptionClick(evt: TableEvents) {
    const { selectedMeatballOption, rowId, rowMetadata } = evt;

    switch (selectedMeatballOption.id) {
      case MEATBALL_OPTIONS.EDIT_OPTION_ID:
        this.handleRedirectToEdit({ rowId });
        break;
      case MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID:
        this.toggleStatus({ idClient: rowId, status: !rowMetadata.ativo, nome: evt.rowMetadata.nome }, rowMetadata.existePesquisaEmExecucao);
        break;
      default:
        break;
    }
  }

  // função que recebe o texto emitido pelo componente de input de texto
  public handleTextChange({ filterValue }) {
    this.filters.keywords = filterValue;
    this.getClientes();
  }

  handleRedirectToEdit({ rowId }) {
    this.router.navigate(["/cliente-beta/atualizar"], {
      queryParams: { id: rowId },
    });
  }

  handleRedirectToAdd() {
    this.router.navigate(["/cliente-beta/cadastro"]);
  }

  changePage({ requestedPage, previousPage }) {
    this.pagination.current = requestedPage;
    this.pagination.previus = previousPage;
    this.getClientes();
  }

  public handleChangeRadioFilter($event: TableEvents) {
    switch ($event.filterId) {
      case STATUS_FILTER_ID:
        this.handleChangeStatusFilter($event);
        break;
      case SORTING_FILTER_ID:
        this.handleChangeSortingFilter($event);
        break;
      default:
        break;
    }
  }

  public handleChangeStatusFilter({ filterValue }: TableEvents) {
    const [item] = filterValue;
    this.filters.status = filterValue.length ? item.key : null;

    this.getClientes();
  }

  public handleChangeSortingFilter({ filterValue }: TableEvents) {
    const [item] = filterValue;

    this.filters.sorting.direction = filterValue.length ? item.key : null;
    this.filters.sorting.sort = filterValue.length ? ClientFilterName.NOME : null;
    this.getClientes();
  }

  getClientes() {
    this.isLoading = true;
    const { keywords, status, sorting } = this.filters;

    this.getTotalClients();

    this.clienteService
      .getClientesList([keywords], status, sorting, this.pagination.current - 1)
      .subscribe({
        next: (clientes) => {
          const allClients = clientes && this.buildClientRow(clientes);
          this.tableData = allClients || [];
          this.clientes = clientes;
        },
        error: (err) => {
          this.isLoading = false;
          this.notificator.showError(
            "Houve um problema",
            "Houve um problema na requisição"
          );
          console.error(err);
        },
        complete: () => {
          this.isLoading = false;
          this.setFocusOnNewClient();
        },
      });
  }

  buildClientRow(clientes: IResponseClientModel[]): TableRow[] {
    return clientes.map((cliente) =>
      tRowFactory(
        checkboxCellFactory().checkable(true).build(),
        this.renderTableCel(cliente),
        textCellFactory(cliente.cpf_cnpj_formatado)
          .customStyles({ "pointer-events": "none" })
          .build(),
        statusCellFactory(
          cliente.ativo ? "var(--status-done)" : "var(--status-error)",
          cliente.ativo ? "Ativo" : "Inativo",
          "var(--white)"
        ).build(),
        meatballCellFactory().build()
      )
        .id(cliente.id)
        .metadata({ ativo: cliente.ativo, nome: cliente.nome, existePesquisaEmExecucao: cliente.existe_pesquisa_em_execucao })
        .build()
    );
  }

  renderTableCel(cliente) {
    if(cliente.existe_pesquisa_em_execucao) {
      return componentCellFactory()
      .component(CustomTagComponent)
      .data({name: cliente.nome, tag: 'Pesquisa em execução'})
      .build()
    } else {
      return textCellFactory(cliente.nome)
        .cellStyleBehavior("UNDERLINE_ON_HOVER")
        .build()
    }
  }

  /**
   * Obtem o total de pesquisas cadastradas baseado no filtro informado
   */
  getTotalClients() {
    const { keywords, status } = this.filters;

    this.clienteService.getTotalClients([keywords], status).subscribe({
      next: (data) => {
        if (typeof data === "number") {
          this.totalClients = data;
        }
      },
    });
  }

  toggleStatus({ idClient, status, nome }: ISwitchStatus, existePesquisaEmExecucao: boolean = false) {
    if (existePesquisaEmExecucao && !status) {
      this.blockUserFromDeleteOrDeactivate();
      return;
    }

    if (!this.checkboxModalState) {
      this.modalService.showModal({
        title: `${status ? "Ativar" : "Inativar"} cliente`,
        icon: `fa-regular fa-toggle-large-${status ? "off" : "on"}`,
        messageModal: `Deseja mesmo ${
          status ? "ativar" : "inativar"
        } ${nome}`,
        close: true,
        checkbox: true,
        btnTitlePositive: `${status ? "Ativar" : "Inativar"}`,
        positiveCallback: () => this.toggleStatusRequest(idClient, status),
      });
    } else {
      this.toggleStatusRequest(idClient, status)
    }
  }

  /**
   * Exibe um prompt impedindo o usuário de inativar ou excluir um cliente que possui pesquisas em execução.
   */
  blockUserFromDeleteOrDeactivate() {
    this.modalService.showModal({
      title: "Não foi possível inativar o cliente",
      icon: "fa-regular fa-circle-info",
      messageModal: "O cliente possui pesquisas em execução.",
      isOnlyConfirmation: true,
      close: true,
      btnTitlePositive: "Entendi",
      positiveCallback: () => {},
    });
  }

  toggleStatusRequest(clientId: number, status: boolean) {
    this.isLoading = true;
    this.clienteService.setStatusClients(clientId, status).subscribe({
      error: ({error}) => {
        this.isLoading = false;
        if(error.status === 422) {
          this.blockUserFromDeleteOrDeactivate();
        } else {
          this.notificator.showError(
            'Erro ao salvar!',
            null
          );
        }
      },
      complete: () => {
        this.getClientes();
        this.updateHeaderData(status);
      },
    });
  }

  /**
   * Atualiza os dados do header ao mudar o status de um cliente
   */
  updateHeaderData(status: boolean) {
    if(status) {
      this.infoCardDefaultValue[ClientStatusHeaderIndex.ATIVOS].value++
      this.infoCardDefaultValue[ClientStatusHeaderIndex.INATIVOS].value--
    } else {
      this.infoCardDefaultValue[ClientStatusHeaderIndex.INATIVOS].value++
      this.infoCardDefaultValue[ClientStatusHeaderIndex.ATIVOS].value--
    }
  }
}
