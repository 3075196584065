
/**
 * Adiciona zero a esquerda na quantidade de 
 * dígitos totais igual a zerosQt. Sendo zerosQt
 * obtido com a seguinte formulação:
 *  
 *  zerosQt = places - string(value).length
 * 
 * @param value valor inteiro usado para obter o resultado
 * @param places quantidade de digitos terá o resultado
 * @returns 
 */
export function zeroLeft(value: number, places: number): string {
  const intValue = Math.round(value);
  return String(intValue).padStart(places, '0');
}