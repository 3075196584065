export const backendErrors = {
  prefixoUrlAlreadyExists: 'O prefixo url já está associado a outro Contratante',
  emailAlreadyExists: 'O login informado já está associado a outro usuário ativo'
};

export const errorMessages = {
  prefixoUrlAlreadyExists: 'Nome do domínio já cadastrado',
  emailAlreadyExists: 'E-mail já cadastrado',
  createNewHirer: {title: 'Erro de cadastro!', message: 'Não foi possível concluir a operação'},
  updateHirer: {title: 'Erro ao editar!', message: 'Não foi possível concluir a operação'}
}
