<div [ngClass]="{'results-overlay': showModal}">
  <div class="result-card-container" [ngClass]="{'show-modal': showModal}">
    <header class="header">
      <h4 class="question-title" title={{questionTitle}}>
        <label class="question-number">{{ questionNumber }} - </label>
        {{ questionTitle }}
      </h4>

      <div class="tips-wrapper">
        <div class="select" (click)="toggleSelect()" *ngIf="renderSelect()">
          <div class="select-header" [ngClass]="{'select-open': isOpen}">
            <span class="selected-option">{{ selectedType ? selectedType : graphTypes[0] }}</span>
            <div class="select-data-icon">
              <span [class]="!isOpen ?  'arrow' : 'arrow-rotate' "></span>
            </div>
          </div>
          <div class="options" *ngIf="isOpen">
            <div class="option" *ngFor="let option of graphTypes[questionData.tipo]"
              (click)="handleOptionClick(option)">
              {{ option }}
            </div>
          </div>
        </div>
        <span class="expand-container" (click)="handleOpenModal()">
          <i class="fa-solid fa-down-left-and-up-right-to-center expand-icon" *ngIf="showModal"></i>
          <i class="fa-sharp fa-solid fa-arrows-maximize expand-icon" *ngIf="!showModal"></i>
        </span>
      </div>
    </header>
    <hr [ngClass]="showModal ? 'line-open' : 'line-close'">
    <container-element class="render-container" [ngSwitch]="selectedType">
      <ng-container *ngSwitchCase="'Barra'">
        <app-bar [alternatives]="renderValue" [showModal]="showModal" [layout]="layout"></app-bar>
      </ng-container>
      <some-element *ngSwitchCase="'Pizza'">
        <app-pie [alternatives]="renderValue" [showModal]="showModal" [layout]="layout"></app-pie>
      </some-element>
      <some-element *ngSwitchCase="'Lista'">
        <app-list [questionType]="questionData.tipo" [answer]="renderValue"
          [showModal]="showModal"></app-list>
      </some-element>
      <some-element *ngSwitchCase="'GRADE_UNICA'">
        <app-single-grid [alternatives]="renderValue" [showModal]="showModal"
          [layout]="layout"></app-single-grid>
      </some-element>
      <some-element *ngSwitchCase="'GRADE_MULTIPLA'">
        <app-single-grid [alternatives]="renderValue" [showModal]="showModal"
          [layout]="layout"></app-single-grid>
      </some-element>
    </container-element>
  </div>
</div>
