import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';


/**
 * Mascara criada para controlar a entrada de dados baseado no regex informado
*
* Para ser utilizada é necessário chamar a diretiva normalmente e em seguida adicionar o atributo allowedChars passando o regex desejado
*
* Ex: <input mascara-dynamic-regex [allowedChars]="'meuRegex'"/>
*
*/
@Directive({
  selector: '[mascara-dynamic-regex]'
})
export class MascaraDynamicRegexDirective {
  private regex: RegExp;

  @Input('allowedChars') set allowedChars(value: string) {
    this.regex = new RegExp(value);
  }

  constructor(private ngControl: NgControl) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const inputChar = event.key;

    if (!this.regex.test(inputChar) && inputChar !== 'Backspace') {
      event.preventDefault(); // Bloqueia a digitação
    }
  }


/**
 * Verifica se existe algum valor inválido (baseado no regex) dentro do texto colado
 * @param event: valor colado
 */
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const value = event.clipboardData;
    const pastedText = value?.getData('text');

    if (!this.regex.test(pastedText)) {
      event.preventDefault(); // Bloqueia a colagem
      this.ngControl.control?.setErrors({ invalidChars: true }); //Seta o erro no FormControl
    }
  }

}
