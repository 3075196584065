export class EntrevistaTableHeaders {

  static COD_ENTREVISTA = {
    key: 'COD_ENTREVISTA',
    friendlyName: 'Cod. Entrevista',
    fieldPath: 'id',
    sorts: true,
  }
  static OPERADOR_NOME = {
    key: 'OPERADOR_NOME',
    friendlyName: 'Operador',
    fieldPath: 'vinculoOperador.operador.contaUsuario.dadosPessoais.nome',
    sorts: true,
  };
  static LOCALIDADE_NOME = {
    key: 'LOCALIDADE_NOME',
    friendlyName: 'Localidade',
    fieldPath: 'localidade.nome',
    sorts: true,
  };
  static DURACAO = {
    key: 'DURACAO',
    friendlyName: 'Duração',
    fieldPath: 'tempoDuracao',
    sorts: true,
  };
  static DATA_COLETA = {
    key: 'DATA_COLETA',
    friendlyName: 'Data',
    fieldPath: 'inicioColeta',
    sorts: false,
  };

  static INICIO_COLETA = {
    key: 'INICIO_COLETA',
    friendlyName: 'Início da Coleta',
    fieldPath: 'inicioColeta',
    sorts: true,
  };
  static FIM_COLETA = {
    key: 'FIM_COLETA',
    friendlyName: 'Fim da Coleta',
    fieldPath: 'dataRegistro',
    sorts: true,
  };
  static VISUALIZAR_BUTTON = {
    key: 'VISUALIZAR_BUTTON',
    friendlyName: 'Visualizar',
    fieldPath: 'vinculoOperador.operador.contaUsuario.dadosPessoais.nome',
    sorts: false,
  };
  static AUDICAO = {
    key: 'AUDICAO',
    friendlyName: 'Audição',
    fieldPath: 'auditada',
    sorts: true,
  };
  static APROVADA_TOGGLE = {
    key: 'APROVADA_TOGGLE',
    friendlyName: 'Aprovada',
    fieldPath: 'aprovada',
    sorts: false,
  };

}
