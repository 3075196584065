<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card-marcacao
    [icone]="'fa fa-clock-o'"
    [titulo]="'PERGUNTA DE HORÁRIO'"
    [nomePlaceHolder]="'Título da pergunta de horário?'"
    [formGroup]="formGroup"
  ></app-pergunta-card-marcacao>

</div>