import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperadorVinculado } from '../../localidades/localidade';

@Component({
  selector: 'app-seletor-operador-vinculado',
  templateUrl: './seletor-operador-vinculado.component.html',
  styleUrls: ['./seletor-operador-vinculado.component.scss'],
})
export class SeletorOperadorVinculadoComponent implements OnInit {

  operadorVinculadoSelecionado: OperadorVinculado = null;

  @Input('operadoresVinculados')
  operadoresVinculados: OperadorVinculado[] = [];

  @Output() readonly operadorVinculadoSelecionadoEvt = new EventEmitter<OperadorVinculado>();

  ngOnInit() { }

  onOperadorVinculadoSelecionado(operadorVinculadoSelecionado: OperadorVinculado) {
    this.operadorVinculadoSelecionadoEvt.emit(operadorVinculadoSelecionado);
  }

}
