import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TipoPergunta } from "app/modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { NotificatorService } from "app/notificador/notificator.service";


interface EventsProps {
  sectionId: number;
  questionId: number;
}

class ElementoExapandido {
  id: number;
  ordem: number;
}

import {
  Pergunta,
  Secao,
} from "../../modulos/pesquisa-beta/cadastro/steps/pesquisas-logica/pesquisas-logica.component";

import { SectionElem } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-listagem/secao-listagem/secao-listagem.component";
interface DataProps {
  question: Pergunta;
  section: Secao;
}

type UsingBy = "REGULAR" | "LOGIC";

@Component({
  selector: "app-section-list",
  templateUrl: "./section-list.component.html",
  styleUrls: ["./section-list.component.scss"],
})
export class SectionListComponent implements OnInit {
  // Variável responsável por expor as perguntas de uma seção
  private _dropDownActive: boolean = false;

  private _dropDownActiveAlternativas: boolean = false;

  private _alternativasExpandidas: boolean = false;
 
  _itemExpandido = new ElementoExapandido();

  _itemsExpandidos: ElementoExapandido[] = [] as ElementoExapandido[];

  _classDropAlternativa: boolean = false;

  @Input() tpyeOfUse: UsingBy;

  // Prop responsável por passar todas as seções e suas perguntas pra validar
  // A flag de lógica
  @Input() secoes: Secao[];

  @Input() sectionElem: SectionElem = {} as SectionElem;
 
  // Prop responsável por receber o Array de Seções
  @Input() data: Secao = {} as Secao;

  // Prop responsável por receber a pergunta selecionada
  @Input() selectedQuestion: Pergunta;

  // Prop responsável por receber a Seção selecionada
  @Input() selectedSection: Secao;

  // Prop responsável por receber a pergunta que está listando as seções
  @Input() questionRef: Pergunta = {} as Pergunta;

  // Prop responsável por receber a Seção Pai da pergunta detalhada que está listando as seções
  @Input() sectionRef: Secao = {} as Secao;

  // Prop que valida se o componente está sendo utilizado para um pulo de pergunta
  @Input() isSelectable: boolean = false;

  @Input() pesquisaRealizada: boolean;

  /**
   * Função que vem do PAI permitindo que seja modificado a exibição da listagem
   * de seções para a pergunta que foi clicada no icone da engrenagem do card
   * de pergunta.
   */
  @Output() setChildrenShowDetails = new EventEmitter<boolean>();

  /**
   * Função que vem do PAI para identificar qual pergunta foi clicada e também
   * a qual seção a pergunta pertence para exibir em detalhes.
   */
  @Output() getChooseSectionAndQuestion = new EventEmitter<EventsProps>();

  /**
   * Função que vem do PAI para selecionar uma questão para destacar no clique
   */
  @Output() setSelectedQuestion = new EventEmitter<DataProps>();

  constructor(private notificatorService: NotificatorService) {}

  ngOnInit() {
  }

  /**
   * Função responsável por exibir as perguntas de cada seção através do dropdown
   */
  setDropDownActive(): boolean {
    return (this._dropDownActive = !this._dropDownActive);
  }

  /**
   * Função que retorna o valor do DropDownActive
   */
  getDropDownActive(): boolean {
    return this._dropDownActive || this.pesquisaRealizada;
  }

  /**
   * Função responsável por realizar uma chamada a função no componente pai
   * que irá modificar o conteúdo da página para exibir em detalhes a pergunta
   *  selecionada
   */
  changeShowDetails() {
    this.setChildrenShowDetails.emit();
  }

  /**
   * Função responsável por realizar uma chamada a função no componente pai
   * que buscar a pergunta clicada na listagem de seções e perguntas
   */
  getQuestionBySectionId(sectionId: number, questionId: number): void {
    this.getChooseSectionAndQuestion.emit({ sectionId, questionId });

    this.changeShowDetails();
  }

  // Verificar se alguma pergunta tem lógica
  handleIsSomeQuestionHasLogic(data): boolean {
    const result = data.find((item) => {
      if (item.alternativas.length > 0) {
        return item.alternativas.find(
          (alternativa) =>
            !!alternativa.marcacao || !!alternativa.marcacaoPergunta || alternativa.comportamento === 'FINALIZAR_ENTREVISTA'
        );
      } else {
        return item.marcacaoPergunta ? item.marcacaoPergunta : false;
      }
    });

    return result ? true : false;
  }

  handleSelectedQuestion(
    questionRef: Pergunta,
    sectionRef: Secao,
    sectionRefId: number,
  ): void {
    if (this.isSelectable) {
      if (sectionRefId === this.sectionRef.id) {
        if (questionRef.id === this.questionRef.id) {
          this.notificatorService.showAlert(
            "Pulo não configurado",
            "Não é possível pular para a mesma pergunta"
          );
          return;
        }
      }

      if (questionRef.ordem <= this.questionRef.ordem) {
        this.notificatorService.showAlert(
          "Pulo não configurado",
          "Não é possível pular uma pergunta anterior"
        );
        return;
      }

      const data = {
        question: questionRef,
        section: sectionRef,
      };

      this.setSelectedQuestion.emit(data);
    }
    /**
     * Validação de que primeiro o componente está sendo renderizado no select
     * de uma pergunta detalhada, caso seja verdadeiro, então ele:
     * Verifica se o id da pergunta selecionada no select é o mesmo da pergunta
     * que está sendo exibida em detalhes, se for verdadeiro ele mantém o estado
     * para o padrão que é zero, se não, faz uma nova verificação. Se o id da
     * pergunta listada é o mesmo da que já está selecionada mantém, se não ele
     * pega o id novo e muda
     */
  }

  // Função que formata tipo de pergunta para o placeholder do select
  formatTipo(tipo: TipoPergunta): string {
    return tipo === TipoPergunta.DATA
      ? "Data"
      : tipo === TipoPergunta.ESCALA_NUMERICA
      ? "Escala Numérica"
      : tipo === TipoPergunta.FOTO
      ? "Foto"
      : tipo === TipoPergunta.GRADE_MULTIPLA
      ? "Grade Múltipla"
      : tipo === TipoPergunta.GRADE_UNICA
      ? "Grade Única"
      : tipo === TipoPergunta.HORARIO
      ? "Horário"
      : tipo === TipoPergunta.MULTIPLA
      ? "Múltipla"
      : tipo === TipoPergunta.UNICA
      ? "Única"
      : "Vídeo";
  }

  perguntaTemMarcacao(pergunta: Pergunta): boolean {
    return (
      !!pergunta.marcacaoPergunta ||
      (pergunta.alternativas &&
        pergunta.alternativas.some(
          (alternativa) => !!alternativa.marcacaoPergunta
        )) || pergunta.alternativas.some(
          (alternativa) => alternativa.comportamento === 'FINALIZAR_ENTREVISTA'
        )
    );
  }

  isVisibleCard(): boolean {
    if (this.tpyeOfUse === "REGULAR") {
      return true;
    } else {
      return this.data.index >= this.sectionRef.index ? true : false;
    }
  }

  isQuestionClickable(perguntaID: number): string {
    if (this.tpyeOfUse === "LOGIC") {
      const indexOfPerguntaById = this.data.perguntas.find(
        (item) => item.id === perguntaID
      );

      if (this.data.index === this.sectionRef.index) {
        // TODO: Verificar quando o último elemento da seção é o selecionado
        // neste caso a seção toda deve ser excluída (função que lida com está parte de seções é)
        // ISVISIBLECARD
        return indexOfPerguntaById.ordem <= this.questionRef.ordem
          ? "block-click"
          : "click-pointer";
      } else {
        return "click-pointer";
      }
    } else {
      return "";
    }
  }

  perguntaIsSimple(tipo: TipoPergunta): boolean {
    return [
      TipoPergunta.GRADE_MULTIPLA,
      TipoPergunta.GRADE_UNICA,
      TipoPergunta.ESCALA_NUMERICA,
      TipoPergunta.UNICA,
      TipoPergunta.MULTIPLA,
    ].includes(tipo);  
  }

  handleExpandAlternativesButtonClick(elementoSecao){
    if(this._itemsExpandidos.some(
      (elementoSecaoExpandido) => 
        elementoSecaoExpandido.id === elementoSecao.id && 
        elementoSecaoExpandido.ordem === elementoSecao.ordem
    )){
      this._itemsExpandidos.filter(
        (elementoSecaoExpandido) => 
        elementoSecaoExpandido.id !== elementoSecao.id ||
        elementoSecaoExpandido.ordem !== elementoSecao.ordem
      )
    } else {
      this._itemsExpandidos = [
        ...this._itemsExpandidos, elementoSecao
      ];
    }

    this.setDropDownAlternativas(elementoSecao);
    this.setclassDropAlternativa();
  }

  perguntaTemAlternativas(tipo: TipoPergunta): boolean{
    return [TipoPergunta.UNICA, TipoPergunta.MULTIPLA].includes(tipo);
  }

  perguntaIsEscalaNumerica(tipo: TipoPergunta): boolean{
    return TipoPergunta.ESCALA_NUMERICA === tipo;
  }

  isItGrade(tipo: TipoPergunta): boolean{
    return [TipoPergunta.GRADE_UNICA, TipoPergunta.GRADE_MULTIPLA].includes(tipo);
  }

  
  setDropDownAlternativas(elementoSecao){
    this._itemExpandido.id = elementoSecao.id;
    this._itemExpandido.ordem = elementoSecao.ordem

    if(this.perguntaIsSimple(elementoSecao.tipo)){
      this.setalternativasExpandidas();
    }
  }


  getDropDownActiveAlternativas(): boolean {
    return this._dropDownActiveAlternativas || this.pesquisaRealizada;
  }

  setalternativasExpandidas(): boolean {
    return (this._alternativasExpandidas = !this._alternativasExpandidas);
  }
  getalternativasExpandidas(elementoSecao): boolean {
    if(this._itemExpandido.id == elementoSecao.id) {
      return this._alternativasExpandidas || this.pesquisaRealizada;
    }
  }

  setclassDropAlternativa(): boolean{
    return (this._classDropAlternativa = !this._classDropAlternativa);
  }

  getclassDropAlternativa(elementoSecao){
    if(this.getalternativasExpandidas(elementoSecao)){
      return this._classDropAlternativa;
    }
  }

  esteElementoSecaoTemAlternativasExpandidas(elementoSecao): boolean{
    return this._itemsExpandidos.some(
      (elementoSecaoExpandido) => elementoSecaoExpandido.id === elementoSecao.id &&
                                  elementoSecaoExpandido.ordem === elementoSecao.ordem
    )
  }

}
