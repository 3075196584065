<div class="local-container">
  <!-- localidade -->
  <div *ngIf="data?.type === 'localidade'" class="flex justify-between">
    <div class="place-container flex tooltip-container">
      <app-checkbox *ngIf="data?.type === 'localidade'" [isChecked]="allChecked ? true : false"
        (click)="handleAllCheck()">
      </app-checkbox>
      <h4 class="title">Localidade filha</h4>
      <div class="tooltip">
        <i class="fa-regular fa-circle-info fa-lg" alt="Desabilite a(s) localidade(s) aonde não serão realizadas coletas"></i>
        <span class="tooltiptext">Desabilite a(s) localidade(s) aonde não serão realizadas
          coletas</span>
      </div>
    </div>
    <div class="sample-insert flex center tooltip-container">
      <h4 class="title">Amostra percentual</h4>
    </div>
    <div class="sample-percent flex center">
      <h4 class="title">Amostra numérica</h4>
    </div>
    <div class="switch-container flex center tooltip-container">
      <h4 class="title">Ações</h4>
      <div class="tooltip" *ngIf="data.type === 'localidade'">
        <i class="fa-regular fa-circle-info fa-lg" alt="Localidade filha corresponde a  uma localidade que existe dentro da localidade da avaliação . Por exemplo: Um bairro de uma cidade"></i>
        <span class="tooltiptext">
          Localidade filha corresponde a uma localidade que existe dentro da
          localidade da avaliação . Por exemplo: Um bairro de uma cidade
        </span>
      </div>
    </div>
  </div>
  <!-- vinculo -->
  <div *ngIf="data?.type === 'vinculo'" class="flex justify-between">
    <div class="place-container flex tooltip-container">
      <app-checkbox *ngIf="data?.type === 'localidade'" [isChecked]="allChecked ? true : false"
        (click)="handleAllCheck()">
      </app-checkbox>
      <h4 class="title">Localidade filha</h4>
      <div class="tooltip">
        <i class="fa-regular fa-circle-info fa-lg" alt="Desabilite a(s) localidade(s) aonde não serão realizadas coletas"></i>
        <span class="tooltiptext">Desabilite a(s) localidade(s) aonde não serão realizadas
          coletas</span>
      </div>
    </div>
    <div class="sample-insert flex center tooltip-container">
      <h4 class="title">Amostra percentual</h4>
    </div>
    <div class="sample-percent flex center">
      <h4 class="title">Entrevistadores</h4>
    </div>
    <div class="switch-container flex center">
      <h4 class="title">Vincular entrevistador</h4>
    </div>
  </div>
</div>
<div *ngFor="let item of filteredData; let i = index">
  <!-- localidade -->
  <div *ngIf="!item.hidden && data?.type === 'localidade'"
    [ngClass]="{
      'local-items-container': true,
      'disabled': !isToggleChecked(i),
      'row-active': isChecked(i)
    }">
    <span *ngIf="data?.type === 'localidade'" class="flex justify-between">
      <div class="place-container flex">
        <app-checkbox [isChecked]="isChecked(i)" (click)="isToggleChecked(i) && handleCheckClick(i)"></app-checkbox>
        <button class="button-dropdown" *ngIf="item.sublocations.length > 0"
          (click)="openSublocations(item.sublocations.length, i, item.toggleChecked)" [disabled]="!item?.canStretch">
          <i *ngIf="item.openSublocation" class="fas fa-chevron-down"></i>
          <i *ngIf="!item.openSublocation" class="fas fa-chevron-right"></i>
        </button>
        <p class="place" [ngClass]="!isToggleChecked(i) && 'disabled'" [title]="item.label">
          {{item.label}}
        </p>
      </div>
      <div class="sample-insert flex center">
        <label [for]="item.id" class="input-wrapper"
          [ngClass]="(progress>data?.value && 'out-of-range') || (!isToggleChecked(i) && 'setDisabled')">
          <input #inputSamples type="number" placeholder="Inserir amostra" min="0" max="100" step="0.01" [id]="item.id"
            [style.width.ch]="isToggleChecked(i)?inputOptions[i].width:15"
            [style.text-align]="inputOptions[i].width>5?'center':'right'" [(ngModel)]="inputOptions[i].displayValue"
            [disabled]="!isToggleChecked(i)" (input)="handleChange($event, i)"
            (blur)="onBlur(inputOptions[i].realValue, i)" />
          <span [hidden]="inputOptions[i].width>5||(!isToggleChecked(i))">%</span>
        </label>
      </div>
      <div
        [class]="isToggleChecked(i) || item.value > 0 ? 'sample-percent flex center' : 'sample-percent disabled flex center'">
        <h4 *ngIf="getProgressValue(item.value) != 0">
          {{getProgressValue(item.value)}}
        </h4>
        <p *ngIf="getProgressValue(item.value) == 0">00</p>
      </div>
      <div class="switch-container flex center">
        <app-toggle (isChecked)="handleToggleCheckClick(i, $event)" [checked]="item.toggleChecked">
        </app-toggle>
      </div>
    </span>
    <!-- sublocalidades -->
    <app-children-location *ngIf="item.openSublocation" [data]="item.sublocations" [hasFatherChecked]="item.checked"
      [indexFatherRef]="i" [fatherSampleRef]="getProgressValue(item.value)"
      [typeOfChildrenMessageRef]="item.typeOfChildrenMessage" [sampleRange]="grandsonSampleValueRef"
      [leftToComplete]="howManyleft" [dataLength]="item.sublocations.length"
      [numericFatherSample]="getProgressValue(item.value)" [sampleValue]="data.value" [sampleLocationValue]="progress"
      (updateGrandSonCheckById)="updateGrandSonCheck($event)"
      (updateGrandSonToggleById)="handleGrandSonToggleChecked($event)"
      (onSendGrandsonInputs)="handleUpdateGrandsonInputs($event)" (handleUpdateSampleRef)="handleUpdateSample($event)"
      (getSampleFatherMaxRange)="updateProgressSampleRef($event)"
      (getInputInsert)="handleCanAutomaticInsertonGrandSon($event)" (openAlertPopup)="openAlertPopup($event)"
      (onCanNotSaveButton)="onCanNotSaveButtonNew($event)">
    </app-children-location>
  </div>
  <!-- vinculo -->
  <div *ngIf="!item.hidden && data?.type === 'vinculo' && (item.toggleChecked && item.value > 0)"
    class="local-items-container">
    <span class="flex justify-between">
      <div class="place-container flex">
        <app-checkbox [isChecked]="isChecked(i)" (click)="handleCheckClick(i)" *ngIf="data?.type !== 'vinculo'">
        </app-checkbox>
        <button class="button-dropdown" *ngIf="item.sublocations.length > 0"
          (click)="openSublocations(item.sublocations.length, i, item.toggleChecked)">
          <i *ngIf="item.openSublocation" class="fas fa-chevron-down"></i>
          <i *ngIf="!item.openSublocation" class="fas fa-chevron-right"></i>
        </button>
        <p class="place" [title]="item.label"
          [ngClass]="{'place-vinculo': data?.type === 'vinculo', 'location-highlight' : item.operators.length > 0}">
          {{item.label}}
        </p>
      </div>
      <div class="sample-insert flex center" [title]="item.value">
        <label [for]="item.id" class="input-wrapper setDisabled">
          <input placeholder="Inserir amostra" [style.width.ch]="getInputValueLength(inputOptions[i].displayValue)"
            [style.text-align]="'right'" type="number" [value]="inputOptions[i].displayValue" disabled />
          <span [hidden]="inputOptions[i].width===15">%</span>
        </label>
      </div>
      <!-- LOCALIDADES NETAS NÃO EXISTEM -->
      <div *ngIf="item.sublocations.length === 0" class="sample-percent flex center"
        [title]="item.operators.length === 0 ? '00' : item.operators.length >= 10 ? item.operators.length : '0'+(item.operators.length)">
        <p *ngIf="item.operators.length === 0">00</p>
        <h4 *ngIf="item.operators.length >= 10">{{item.operators.length}}</h4>
        <h4 *ngIf="item.operators.length !== 0 && item.operators.length < 10">
          0{{item.operators.length}}
        </h4>
      </div>
      <div class="switch-container flex center" title="Vincular operador" *ngIf="item.sublocations.length === 0">
        <button class="button-add-edit-operator"
          (click)="handleShowModal(item, i)">
          <i [ngClass]="item.operators.length > 0 ? 'fa-user-pen' : 'fa-user-plus'" class="fa-regular fa-xl"></i>
        </button>
      </div>
      <!-- LOCALIDADES NETAS EXISTEM -->
      <div *ngIf="item.sublocations.length > 0" class="sample-percent flex center card-info">
        <img src="assets/icons/purple-operator.svg" alt="" />
        <div>
          <p class="vinculos">Entrevistadores Vinculados</p>
          <div>
            <h4 *ngIf="item.operators.length === 0">00</h4>
            <h4 *ngIf="item.operators.length >= 10">
              {{item.operators.length}}
            </h4>
            <h4 *ngIf="item.operators.length !== 0 && item.operators.length < 10">
              0{{item.operators.length}}
            </h4>
          </div>
        </div>
      </div>
      <div *ngIf="item.sublocations.length > 0" class="sample-percent flex center card-info">
        <img id="bigger-img" [src]="
      item.sublocations.length > 0
          ? 'assets/icons/purple-group-finished.svg'
          : 'assets/icons/purple-group.svg'
      " alt="" />
        <div>
          <p class="operadores">Localidades com entrevistadores</p>
          <div>
            <h4 *ngIf="item.subLocationsNameWithOperators.length === 0">00/</h4>
            <h4 *ngIf="item.subLocationsNameWithOperators.length >= 10">
              {{item.subLocationsNameWithOperators.length}}/
            </h4>
            <h4
              *ngIf="item.subLocationsNameWithOperators.length !== 0 && item.subLocationsNameWithOperators.length < 10">
              0{{item.subLocationsNameWithOperators.length}}/
            </h4>

            <h4 *ngIf="item.sublocations.length === 0">00</h4>
            <h4 *ngIf="item.sublocations.length >= 10">
              {{item.sublocations.length}}
            </h4>
            <h4 *ngIf="item.sublocations.length !== 0 && item.sublocations.length < 10">
              0{{item.sublocations.length}}
            </h4>
          </div>
        </div>
      </div>
    </span>
    <app-children-location-operator *ngIf="item.openSublocation" [data]="item.sublocations"
      (output)="handleShowModalGrandchild($event, i)">
    </app-children-location-operator>
  </div>
</div>
<!-- modal -->
<div *ngIf="showModal" class="flex justify-end">
  <app-create-operator [values]="selectedOperator" (selectedItems)="handleOperatorChange($event)"
    [callbackFunction]="closeModal" [operators]="operators">
  </app-create-operator>
</div>
