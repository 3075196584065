import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-card',
  templateUrl: './banner-card.component.html',
  styleUrls: ['./banner-card.component.scss']
})
export class BannerCardComponent {
  @Input() firstColumnData: number;
  @Input() firstColumnTitle: string;
  @Input() secondColumnData: number;
  @Input() secondColumnTitle: string;
  @Input() thirdColumnData: number;
  @Input() thirdColumnTitle: string;
}
