/**
 * Classe que encapsula as palavras chaves que serão utilizadas
 * na filtragem
 */
export class PalavraChave {

  palavrasChave: string[];

  constructor(palavrasChave: string[] = ['']) {
    this.palavrasChave = palavrasChave;
  }

}
