import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilterSelect } from "../../filter-select";

@Component({
  selector: "app-filter-select-radio",
  templateUrl: "./radio-select.component.html",
  styleUrls: ["./radio-select.component.scss"],
})
export class SelectFilterRadioComponent implements OnInit {
  @Output() changeFilter = new EventEmitter<IFilterSelect[]>();
  @Input() options: IFilterSelect[] = [];

  constructor() {}

  ngOnInit(): void {
  }

  selectedOption: IFilterSelect;

  /**
   * Seleciona um item dos listados do array, mantendo o estado dessa seleção, caso o item clicado ja estaja selecionado é feita um desseleção
   * @param option Pode ser qualquer valor que esteja dentro do array de options;
   */
  selectFilterOption(option: IFilterSelect) {
    this.selectedOption = { ...option };

    // Emitindo o estado atual de seleção
    this.changeFilter.emit([this.selectedOption]);
  }

  clearFilterOption() {
    this.selectedOption = null;
    this.changeFilter.emit([])
  }
}
