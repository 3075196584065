import { TipoEventoTabela } from './tipoEventoTabela';

export class EventoTabela {

  tipo: TipoEventoTabela;
  payload: any;
  selecionado?:any;

  constructor(tipo, payload) {
    this.tipo = tipo;
    this.payload = payload;
  }
}
