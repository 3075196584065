import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { Observable } from "rxjs";
import {
  IResultRequest,
  IResultResponse,
  IResultSurveysResponse,
} from "../results-interface";

@Injectable({
  providedIn: "root",
})
export class ResultsService extends CrudService<IResultRequest> {
  public baseUrl = this.resourcePath;

  constructor(
    protected requestService: RequestService,
    private http: HttpClient
  ) {
    super(requestService, `${apiLocation}/pesquisas`);
  }
  /**
   * Traz os resultados de uma única pesquisa
   * @param surveyId id da pesquisa selecionada para ver os resultados
   * @returns retorna um objeto observable de resultado de uma pesquisa
   * OBS: no momento os dados retornados são dados mockados
   */
  getSurveyResults(surveyId): Observable<IResultResponse> {
    const url = `${this.baseUrl}/${surveyId}/resultados/gerais/`;
    const response = <Observable<IResultResponse>>(
      this.requestService.get(url)
    );
    return response
  }
  /**
   * Retorna a lista de pesquisas para o menu acordean da pagina principal de resultados e da pagina de resultado de uma pesquisa
   * @param status lista de status sendo 'CONCLUIDO' ou 'EXECUCAO'
   * @param page pagina atual
   * @param pageSize quantidade de itens por página
   * @param keyword palavras chaves para filtragem
   * @returns retorna um objeto observable de resultado de uma pesquisa
   * OBS: no momento os dados retornados são dados mockados
   */
  getSurveyList(page = 0, keyword = [""]): Observable<IResultSurveysResponse[]> {
    const pageSize = 16;
    const filter = { palavrasChave: keyword };
    const url = `${this.baseUrl}/resultados/filtragem?page=${page}&size=${pageSize}`;
    const response = <Observable<IResultSurveysResponse[]>>(
      this.requestService.post(url, filter)
    );
    return response;
  }

  getMockSurveyResults(): Observable<IResultResponse> {
    const moockUrl: string = `/assets/mocks/results-mocks.json`;
    return this.http.get<IResultResponse>(moockUrl);
  }
  getMockSurveys(): Observable<IResultSurveysResponse[]> {
    const mockUrl: string = `/assets/mocks/surveys-result-mock.json`;
    return this.http.get<IResultSurveysResponse[]>(mockUrl);
  }
}
