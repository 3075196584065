<div class="container-modal">
  <div class="content-modal" [ngClass]="modalData.type && modalData.type" [style.width]="modalData.textDialog ? '536px' : 'max-content'">
    <div class="header-modal">
      <div class="close-modal">
        <span *ngIf="modalData.showLineHeader" class="line-header"></span>
        <button *ngIf="!modalData.isOnlyConfirmation" class="button-close-header" (click)="handleNegativeCallback(); handleCloseModal()">
          <i [ngClass]="modalData.closeIconConfig.className" [style.fontSize]="modalData.closeIconConfig.size"></i>
        </button>
      </div>

      <div class="title-modal">
        <i [class]="modalData.icon" *ngIf="modalData.icon"></i>
        <img [src]="'../../../../assets/' + modalData.imgFile" alt="" *ngIf="!modalData.icon">
        <h3>{{ modalData.titleDialog }}</h3>
      </div>
    </div>

    <div *ngIf="modalData.textDialog" class="message-modal" [innerHTML]="modalData.textDialog | safeHtml">
    </div>
    <div *ngIf="!modalData.textDialog" class="message-modal">
      <ng-content></ng-content>
    </div>

    <div *ngIf="modalData.type==='alert'" class="checkbox-container">
      <input class="checkbox-element" type="checkbox" [(ngModel)]="localCheckboxState" id="input-checkbox" />
      <label class="label-checkbox" for="input-checkbox">Não quero ver essa mensagem novamente</label>
    </div>

    <div class="modal-actions" *ngIf="modalData.showActions; else showInlineFooter">
      <button *ngIf="modalData.actionNegative && !modalData.isOnlyConfirmation" class="cancel-button"
        (click)="handleNegativeCallback(); handleCloseModal()">
        {{ modalData.actionNegative }}
      </button>

      <button class="positive-button" (click)="handlePositiveCallback();">
        {{ modalData.actionPositive }}
      </button>
    </div>

    <ng-template #showInlineFooter>
      <div class="inline-footer"></div>
    </ng-template>
  </div>
</div>
