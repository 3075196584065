import { ICardDataDefault, IGeneralVisionPodium } from "../interfaces/visao-geral";
import { IMessages } from '../../auditoria-beta/interfaces';

export const PODIUM_LENGTH = 3;

export enum CardInfoType {
  COLETAS_POR_DIA = "COLETAS_POR_DIA",
  APROVACOES = "APROVACOES",
  TEMPO_MEDIO_COLETA = "TEMPO_MEDIO_COLETA"
}


export const errorMessages: IMessages = {
  generalVisionError: {
    title: 'Erro ao recuperar os dados de visão geral',
    message: null
  },
  headerDataError: {
    title: 'Erro ao recuperar os dados do header',
    message: null
  }
}

export const cardDataDefault: ICardDataDefault = {
  collectionsPerDay: {
    id: "-",
    nome: "-",
    valor: "0 Coletas",
  },
  approvals: {
    id: "-",
    nome: "-",
    valor: "0",
  },
  averageTime: {
    id: "-",
    nome: "-",
    valor: "00:00:00",
  }
}

export const iCardResultsLength = 5;

export const emptyPodium: IGeneralVisionPodium = {
  id: -1,
  nome: null,
  foto: {arquivo: null},
  avaliacao_geral: null,
};
