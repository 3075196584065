import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JWTService } from "app/infraestrutura/security/service/jwtService";
import {reporterUrl} from '../../../../infraestrutura/apiLocation';

export interface OperatorData {
  id: number;
  nome?: string;
  localidades?: string[];
}

interface LocalidadeOperador {
  id: number;
  nome: string;
  amostra: number;
}

export interface OperatorProps {
  operador: {
    id: number;
    nome: string;
  };
  localidades: LocalidadeOperador[];
}

interface Localidade {
  id: number;
  nome: string;
  possuiFilhos: boolean;
  idLocalidadePai: number;
  nomeLocalidadePai: string;
  versao: string;
}

interface Operadores {
  idOperador: number;
  idVinculoOperador: number;
  cotaPercentual: number;
  cotaValor: number;
}

interface Vinculos {
  id: number;
  localidade: Localidade;
  filhos: any[];
  idVinculosFilhos: any[];
  cotaPercentual: number;
  cotaValor: number;
  operadores: Operadores[];
  habilitado: boolean;
  hash: string;
  parentHash?: any;
}

@Component({
  selector: "app-bond-section",
  templateUrl: "./bond-section.component.html",
  styleUrls: ["./bond-section.component.scss"],
})
export class BondSectionComponent implements OnInit {
  @Input() vinculosBody: Vinculos[];

  @Input() operatorData;

  showBody: boolean = false;

  operators = [];

  tokenRef: string = "";
  idRef: string = "";

  constructor(private route: ActivatedRoute, private jwtService: JWTService) {}

  ngOnInit() {
    this.idRef = this.route.snapshot.queryParams["id"];
    this.handleMapOperatorsToCreateUniqueList();
  }

  handleShowBody(): void {
    this.showBody = !this.showBody;
  }

  // Função para mapear os operadores
  handleMapOperatorsToCreateUniqueList(): void {
    /**
     * Reduce irá percorrer o array onde irá verificar se o operador já existe no array principal [acc]
     * caso exista, apenas irá adicionar a localidade,
     * caso não exista, irá inserir o operador e a localidade e também irá adicionar o nome do operador
     */
    const operadores = this.vinculosBody.reduce((acc, vinculo) => {
      vinculo.operadores.forEach((operador) => {
        const index = acc.findIndex(
          (item) => item.operador.id === operador.idOperador
        );

        if (index !== -1) {
          acc[index] = {
            ...acc[index],
            localidades: [
              ...(acc[index].localidades
                ? acc[index].localidades
                  ? acc[index].localidades
                  : []
                : []),
              ...(acc[index].localidades.find(
                (item) => item.nome === vinculo.localidade.nome
              )
                ? []
                : [
                    {
                      id: vinculo.localidade.id,
                      nome: vinculo.localidade.nome,
                      amostra: vinculo.cotaValor,
                    },
                  ]),
            ],
          };
        } else {
          acc = [
            ...acc,
            {
              operador: {
                id: operador.idOperador,
                nome: this.operatorData[operador.idOperador]
                  ? this.operatorData[operador.idOperador]
                  : "",
              },
              localidades: [
                {
                  id: vinculo.localidade.id,
                  nome: vinculo.localidade.nome,
                  amostra: vinculo.cotaValor,
                },
              ],
            },
          ];
        }
      });
      return acc;
    }, [] as OperatorProps[]);

    this.operators = operadores;

    this.operators.sort((a, b) =>
      a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0
    );
  }

  // Função para direcionar o usuário para o reporter
  handleGoToReporter(): void {
    const tokenString = this.jwtService.getToken();
    const urlLink = `${reporterUrl}/?id=${this.idRef}&token=${tokenString}`;
    window.open(urlLink, "_blank");
  }
}
