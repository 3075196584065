import { Injectable } from '@angular/core';
import { RequestService } from 'app/servico/request.service';
import { EntrevistasEmDesconformidadeQuery } from './entrevistasEmDesconformidadeQuery';
import { EntrevistaEmDesconformidade } from './entrevistaEmDesconformidade';
import { Observable } from 'rxjs';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { DadosPaginacao } from 'app/util/componente/paginacao/dadosPaginacao';
import { map } from 'rxjs/operators';
import { TipoDesconformidade } from './tipoDesconformidade';

@Injectable({
  providedIn: 'root',
})
export class EntrevistasDesconformidadeService {

  constructor(private requestService: RequestService) { }

  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  buildTiposDesconformidadeQueryParam(tiposDesconformidade: TipoDesconformidade[]): string | null {

    if (!tiposDesconformidade || tiposDesconformidade.length === 0) {
      return null;
    }

    const tiposDesconformidades: string = tiposDesconformidade
        .map(tipo => tipo.toString())
        .reduce((prev, next) => {
          return prev.concat(',').concat(next);
        });

    return tiposDesconformidades;
  }

  toRequestServiceQueryParams(query: EntrevistasEmDesconformidadeQuery) {
    // tslint:disable-next-line: max-line-length
    const tiposDesconformidades = this.buildTiposDesconformidadeQueryParam(query.tiposDesconformidade);

    const queryParams = {
      idOperador: query.idOperador,
      dataInicio: query.dataInicio,
      dataFim: query.dataFinal,
      tiposDesconformidade: tiposDesconformidades,
    };

    this.clean(queryParams);

    return queryParams;
  }

  /**
   * Busca por entrevistas em desconformidade baseado
   * nos parametros passados.
   * @param query parametros de busca para entrevistas em desconformidade.
   * @param dadosPaginacao parametros da paginação, contém a pagina que deve ser
   * retonada e a quantidade de elementos da página.
   */
  // tslint:disable-next-line: max-line-length
  buscarEntrevistasEmDesconformidade(query: EntrevistasEmDesconformidadeQuery, dadosPaginacao: DadosPaginacao): Observable<EntrevistaEmDesconformidade[]> {

    const uri = `${apiLocation}/pesquisas/${query.idPesquisa}/entrevistas/em_desconformidade`;

    const queryParams = this.toRequestServiceQueryParams(query);

    return this.requestService.get(uri, dadosPaginacao, true, queryParams)
    .pipe(
      map((bodyResponse: any[]) => {

        // tslint:disable-next-line: max-line-length
        const entrevistasEmDesconformidade: EntrevistaEmDesconformidade[] = bodyResponse.map((bodyEntry) => {
          return <EntrevistaEmDesconformidade> {
            id: bodyEntry.idRespostaPesquisa,
            idLocalidade: bodyEntry.idLocalidade,
            nomeLocalidade: bodyEntry.nomeLocalidade,
            idOperador: bodyEntry.idOperador,
            nomeOperador: bodyEntry.nomeOperador,
            tempoDuracao: bodyEntry.tempoDuracao,
          };
        });

        return entrevistasEmDesconformidade;
      }),
    );
  }

  /**
   * Busca total de resultados que a busca por entrevistas em desconformidade irá retornar
   * basedo nos parametros de busca passados.
   * @param query parametros de busca por entrevistas em desconformidade.
   */
  // tslint:disable-next-line: max-line-length
  buscarEntrevistasEmDesconformidadeCount(query: EntrevistasEmDesconformidadeQuery): Observable<number> {

    const uri = `${apiLocation}/pesquisas/${query.idPesquisa}/entrevistas/em_desconformidade/total`;

    const queryParams = this.toRequestServiceQueryParams(query);

    return this.requestService.get(uri, null, true, queryParams);
  }
}
