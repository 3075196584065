import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { Modal } from "app/util/componente/modal-generico/modal-generico.component";
import { ConfiguracaoPaginacao } from "app/util/componente/paginacao/configuracaoPaginacao";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { TipoEventoTabela } from "app/util/componente/tabela/evento/tipoEventoTabela";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import { stringFormatter } from "app/util/misc/stringFormatter";
import { BehaviorSubject } from "rxjs";
import { ListagemGenerica } from "../../listagem/listagemGenerica";
import { LocalidadeCadastroEvent } from "../cadastro/localidadeCadastroEvent";
import { Localidade } from "../localidade";
import { LocalidadeTr } from "../localidadeTr";
import { LocalidadeService } from "../servico/localidade.service";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-localidade-listagem",
  templateUrl: "./localidade.listagem.component.html",
  styleUrls: ["./localidade.listagem.component.scss"],
})

// tslint:disable-next-line: max-line-length
export class LocalidadeListagemComponent
  extends ListagemGenerica<Localidade>
  implements OnDestroy, OnInit
{
  localidadesTr: LocalidadeTr[] = [];

  localidadesTrSelecionadas: number[] = [];

  selecionarTodos: boolean = false;

  ultimaBusca: string = "";

  alertaModal: Modal;

  ultimoDadoPaginacao: DadosPaginacao;

  // tslint:disable-next-line: max-line-length
  subjectPaginacao: BehaviorSubject<ConfiguracaoPaginacao> =
    new BehaviorSubject<ConfiguracaoPaginacao>(undefined);

  localidadeEdicao: Localidade;

  isEdicao: boolean = false;
  // Marcação que denota se uma localidade é de primeiro nivel(Pai)
  isPaiPrimeiroNivel: boolean = true;

  exibirExclusao: boolean = false;
  palavraChaveVazia: boolean;

  constructor(
    protected localidadeService: LocalidadeService,
    protected router: Router,
    protected securityService: SecurityService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "localidade",
      localidadeService,
      router,
      modalService
    );
  }

  ngOnInit() {
    this.listarLocalidades();
    this.localidadeEdicao = new Localidade();
    this.isEdicao = false;
    this.localidadesTrSelecionadas = [];
    this.selecionarTodos = false;
    this.palavraChaveVazia = false;

    this.exibirExclusao = this.securityService.userHasAuthorities([
      Authority.REMOVER_LOCALIDADE,
    ]);
  }
  /**
   * @override
   * @description  Life cycle hook que é chamado toda vez que uma exclusão é
   * realizada com sucesso. Ele remove do array as localidades que foram excluidas do banco de dados
   * A cada exclusão com sucesso os elementos  do array serao excluídos recursivamente
   */
  onExclusaoSucesso(ids: any) {
    this.ngOnInit();
  }

  // listagem de localidades pais
  listarLocalidades(
    input: any = this.ultimaBusca,
    isEventoPaginacao: boolean = false
  ) {
    this.ultimaBusca = input.target.value ? input.target.value : "";

    const palavraChave = new PalavraChave(this.ultimaBusca.trim().split(" "));

    this.palavraChaveVazia =
      palavraChave.palavrasChave.length === 1 &&
      palavraChave.palavrasChave[0] === "";

    this.localidadeService
      .aplicarFiltro(palavraChave, this.ultimoDadoPaginacao)
      .subscribe((dados) => {
        const localidadesTr: LocalidadeTr[] = dados.map((dado) => {
          const dadoMapeado =
            !this.palavraChaveVazia && !!dado.localidadePai
              ? {
                  ...dado.localidadePai,
                  filtrarApenasSearchResult: true,
                  localidadesFilhas: [{ ...dado }],
                }
              : dado;

          const localidadeTrSalva = this.montarLocalidadeTr(dadoMapeado);
          localidadeTrSalva.localidade.isPai = true;

          return localidadeTrSalva;
        });
        this.localidadesTr = localidadesTr;
        this.atualizarSelecaoLocalidadeTr();
      });

    if (!isEventoPaginacao) {
      this.getTotalLocalidades(palavraChave);
    }
  }
  atualizarSelecaoLocalidadeTr() {
    this.localidadesTr.forEach((localidadeTr) => {
      const isSelecionado = this.localidadesTrSelecionadas.includes(
        localidadeTr.localidade.id
      );

      if (isSelecionado) {
        localidadeTr.checkBoxSelected = true;
      }
    });
  }

  esconderLocalidadesFilhas(id: any) {
    const indice = this.localidadesTr.findIndex(
      (localidadeTr) => localidadeTr.localidade.id === id
    );
    if (this.localidadesTr[indice].localidadesFilhasTr.length === 0) return;

    const localidadesArrayTemporario = [...this.localidadesTr];

    const idsDasLocalidadesFilhas = localidadesArrayTemporario[
      indice
    ].localidadesFilhasTr.map(
      (localidadeFilha) => localidadeFilha.localidade.id
    );

    localidadesArrayTemporario[indice] = {
      ...localidadesArrayTemporario[indice],
      localidadesFilhasTr: [],
    };

    this.localidadesTr = localidadesArrayTemporario.filter(
      (localidadeTr) =>
        !idsDasLocalidadesFilhas.includes(localidadeTr.localidade.id)
    );
  }

  listarLocalidadesFilhas(id: any) {
    const indice = this.localidadesTr.findIndex(
      (localidadeTr) => localidadeTr.localidade.id === id
    );

    const idsDosFilhosASeremExibidos = this.localidadesTr[
      indice
    ].localidade.localidadesFilhas.map((localidadeFilha) => localidadeFilha.id);

    // if (this.localidadesTr[indice].localidadesFilhasTr.length > 0) return

    // se uma localidade for selecionada, lista suas localidades filhas
    if (this.localidadesTr[indice].selected) {
      this.localidadeService.listarLocalidades(id).subscribe((dados) => {
        this.isLoading = true;
        const dadosASeremListados = this.palavraChaveVazia
          ? dados
          : dados.filter((dado) =>
              this.localidadesTr[indice].filtrarApenasSearchResult
                ? idsDosFilhosASeremExibidos.includes(dado.id)
                : true
            );
        dadosASeremListados.map((dado) => {
          const localidadeTrBody = this.montarLocalidadeTr(dado);

          if (this.localidadesTr[indice].checkBoxSelected) {
            // localidadeTrBody.checkBoxSelected = true
            this.selectLocalidadesTr(localidadeTrBody);
          }

          this.localidadesTr.splice(indice + 1, 0, localidadeTrBody);
          this.localidadesTr[indice].localidadesFilhasTr.push(localidadeTrBody);
          // adiciona o padding de 20 pixel dinamicamente a cada nivel de localidade
          this.localidadesTr[indice + 1].hierarquia =
            this.localidadesTr[indice].hierarquia + 20;
        });
        this.isLoading = false;
      });
      // remove as localidades filhas do array
    } else if (!this.localidadesTr[indice].selected) {
      const totalToRemove =
        this.localidadesTr[indice].localidadesFilhasTr.length;
      this.removerFilhosArrayRecursivo(indice, totalToRemove, 1);
      this.localidadesTr[indice].localidadesFilhasTr = [];
      this.isPaiPrimeiroNivel = true;
    }
  }

  /**
   // tslint:disable-next-line: max-line-length
   * @description função recursiva que remove os filhos de um array a partir de um determinado indice
   * @param indice posição de um elemento do array selecionado
   * @param totalToRemove recebe o total a ser removido
   * @param nextIndex eh usado quando se deseja excluir um filho a partir de um index depois,ou seja
   * quando se adiciona o valor 1 siginifica que sera removido do array apenas os filhos
   * pois os filhos sempre estarao a uma posição abaixo do pai
   */
  removerFilhosArrayRecursivo(
    indice: number,
    totalToRemove: number,
    nextIndex: number = 0
  ) {
    if (this.localidadesTr[indice].localidadesFilhasTr.length > 0) {
      this.localidadesTr[indice].localidadesFilhasTr.filter((filho) => {
        // retona o indice de cada filho
        // tslint:disable-next-line: max-line-length
        const indiceInicio = this.localidadesTr.findIndex(
          (localidadeTr) => localidadeTr.localidade.id === filho.localidade.id
        );
        if (indiceInicio > -1) {
          // total de filhos a ser removidos
          const totalToRemove =
            this.localidadesTr[indiceInicio].localidadesFilhasTr.length;

          this.removerIndiceArray(this.localidadesTr[indiceInicio]);

          if (totalToRemove != null || totalToRemove !== undefined) {
            this.removerFilhosArrayRecursivo(indiceInicio, totalToRemove);
            this.localidadesTr[indiceInicio].localidadesFilhasTr = [];
          }
        }
      });
    }
    // tslint:disable-next-line: max-line-length
    // remove so localidades filhas ou remove pais e filhas do array dependendo do valor de nextIndex
    this.localidadesTr.splice(indice + nextIndex, totalToRemove);
  }

  // localidadeTr é uma abstração que representa uma linha(tr) de uma table
  montarLocalidadeTr(dado: any): LocalidadeTr {
    if (dado === null) {
      return new LocalidadeTr();
    }
    const localidadeTrBody = new LocalidadeTr();
    localidadeTrBody.filtrarApenasSearchResult = dado.filtrarApenasSearchResult;
    localidadeTrBody.localidade = dado;
    localidadeTrBody.hierarquia = 20;
    localidadeTrBody.selected = false;
    localidadeTrBody.possuiFilho = dado.possuiFilho;

    return localidadeTrBody;
  }

  excluirLocalidade(ids: number[]) {
    const payload = Array.isArray(ids) ? ids : [ids];
    const eventoExclusao = new EventoTabela(TipoEventoTabela.EXCLUIR, payload);
    this.manipularEventoTabela(eventoExclusao);
  }

  // seleciona uma localidade e vice-versa
  selectLocalidadesTr(localidadeTr: LocalidadeTr, value: boolean = undefined) {
    // tslint:disable-next-line: max-line-length
    if (value === undefined) {
      localidadeTr.checkBoxSelected = !localidadeTr.checkBoxSelected;
    } else {
      localidadeTr.checkBoxSelected = value;
    }

    // verifica se as localidades selecionadas são pais de primeiro nivel
    const filho = this.localidadesTr
      .map((localidadeTr) => {
        if (localidadeTr.checkBoxSelected) {
          if (!localidadeTr.localidade.isPai) {
            return localidadeTr;
          }
        }
      })
      .filter((local) => local);

    if (filho.length > 0) {
      this.isPaiPrimeiroNivel = false;
    } else {
      this.isPaiPrimeiroNivel = true;
    }

    if (localidadeTr.checkBoxSelected) {
      this.localidadesTrSelecionadas.push(localidadeTr.localidade.id);
      if (!localidadeTr.localidade.isPai) {
        this.isPaiPrimeiroNivel = localidadeTr.localidade.isPai;
      }
      return;
    }

    const indexToRemove = this.localidadesTrSelecionadas.indexOf(
      localidadeTr.localidade.id
    );
    if (indexToRemove > -1) {
      this.localidadesTrSelecionadas.splice(indexToRemove, 1);
    }

    if (this.localidadesTrSelecionadas.length === 0) {
      this.selecionarTodos = false;
    }
  }
  // Remove um indice do array de localidadesTrSelecionadas dado uma localidadeTr
  removerIndiceArray(localidadeTr: LocalidadeTr) {
    const indexToRemove = this.localidadesTrSelecionadas.indexOf(
      localidadeTr.localidade.id
    );
    if (indexToRemove > -1) {
      this.localidadesTrSelecionadas.splice(indexToRemove, 1);
    }
  }
  // evento eh chamado toda vez q houver um ou mais itens selcionados
  // tslint:disable-next-line: function-name
  EventoExclusaoItensSelecionados() {
    // this.isPaiPrimeiroNivel = true;
    const idsLocalidadesSelecionadas = this.localidadesTrSelecionadas;
    this.excluirLocalidade(idsLocalidadesSelecionadas);
  }
  // seleciona todos os itens da tabela
  acionarSelecionarTodos() {
    this.selecionarTodos = !this.selecionarTodos;
    this.localidadesTrSelecionadas = [];
    // tslint:disable-next-line: max-line-length
    this.localidadesTr.forEach((localidadeTr) =>
      this.selectLocalidadesTr(localidadeTr, this.selecionarTodos)
    );
  }

  editarLocalidade(id: number) {
    this.isEdicao = true;
    this.crudService.findById(id).subscribe((entidadeEdicao) => {
      this.mostrarModalCadastro(null);
      this.localidadeEdicao = entidadeEdicao;
    });
  }

  onPaginationChange(eventoPaginacao: DadosPaginacao) {
    this.ultimoDadoPaginacao = eventoPaginacao;
    this.listarLocalidades(this.ultimaBusca, true);
  }
  // recebe o evento de fechar o modal e atualiza a listagem
  // caso n seja emitido um evento, significa que o modal será aberto
  mostrarModalCadastro(event) {
    if (event != null || event != undefined) {
      this.alertaModal = event;
      this.ngOnInit();
      return;
    }
    this.alertaModal = new Modal();
    this.alertaModal.show = true;
  }

  handleCadastroEvents(event: LocalidadeCadastroEvent) {
    this.alertaModal = new Modal();
    this.alertaModal.show = false;
    this.ngOnInit();
  }

  getTotalLocalidades(palavraChave: PalavraChave) {
    this.localidadeService
      .getTotalEntidades(palavraChave)
      .subscribe((total) => {
        const configuracaoPaginacao = new ConfiguracaoPaginacao();
        configuracaoPaginacao.totalPaginas = total;
        configuracaoPaginacao.paginaAtual = 0;

        this.subjectPaginacao.next(configuracaoPaginacao);
      });
  }
  // duplica uma localidade e suas respectivas filhas
  duplicarLocalidade() {
    this.isLoading = true;
    // tslint:disable-next-line: ter-arrow-parens
    this.localidadesTr.forEach((localidadeTr) => {
      if (localidadeTr.checkBoxSelected) {
        this.localidadeService
          .postDuplicarPesquisa(localidadeTr.localidade.id)
          .subscribe((dado) => {
            this.ngOnInit();
            this.localidadesTrSelecionadas = [];

            this.isLoading = false;
          });
      }
    });
  }

  /**
   * @override
   * A exclusão das localidades pode retornar itens que nao puderam
   * ser excluidos pois ja estao vinculados a pesquisas, portanto,
   * uma nova mensagem deve ser exibida informando o usuario sobre o
   * acontecido
   * @param eventoTabela
   */
  manipularEventoExclusao(eventoTabela: EventoTabela) {
    const excluirCallback = () => {
      if (eventoTabela.payload && eventoTabela.payload.length > 0) {
        this.isLoading = true;

        this.crudService.deletar(eventoTabela.payload).subscribe({
          next: (resultado) => {
            this.isLoading = false;

            const refreshCallback = () => {
              this.router.navigate([this.nomeEntidade]);
              // evento so eh lancado se for uma entidade localidade
              if (this.nomeEntidade === "localidade") {
                this.onExclusaoSucesso(eventoTabela.payload);
              }
            };

            /**
             * Verificando se existem localidades não removidas
             */
            const localidadesNaoRemovidas = <Localidade[]>(<any>resultado);
            if (localidadesNaoRemovidas && localidadesNaoRemovidas.length > 0) {
              // tslint:disable-next-line: max-line-length
              const listaHTMLLocalidadesNaoRemovidas = localidadesNaoRemovidas
                .map(
                  (localidadeNaoRemovida) =>
                    `<li>${localidadeNaoRemovida.nome}</li>`
                )
                .reduce((prev, current) => `${prev} ${current}`);

              // tslint:disable-next-line:max-line-length
              const nomeEntidadeCapitalizado =
                stringFormatter.capitalizeFirstLetter(this.nomeEntidade);

              this.modalService.showModal({
                title: `Excluir ${this.nomeEntidade}`,
                messageModal: `Alguns registros não puderam ser excluídos pois já estão vinculados à pesquisas: <br> <ul>${listaHTMLLocalidadesNaoRemovidas}<ul>`,
                btnTitlePositive: "Entendi",
                positiveCallback: () => refreshCallback(),
                isOnlyConfirmation: true,
              });

              this.isPaiPrimeiroNivel = true;
            } else {
              // tslint:disable-next-line:max-line-length
              const nomeEntidadeCapitalizado =
                stringFormatter.capitalizeFirstLetter(this.nomeEntidade);

              this.modalService.showModal({
                title: `Excluir ${this.nomeEntidade}`,
                messageModal: `Registro(s) de ${nomeEntidadeCapitalizado} excluído com sucesso!`,
                btnTitlePositive: "Entendi",
                positiveCallback: () => refreshCallback(),
                isOnlyConfirmation: true,
              });
            }
          },
          // tslint:disable-next-line:align
          error: (error) => {
            this.isLoading = false;

            this.modalService.showModal({
              title: `Excluir ${this.nomeEntidade}`,
              messageModal: `Não foi possível excluir o(s) ${this.nomeEntidade}(es): ${error}`,
              btnTitlePositive: "Entendi",
              isOnlyConfirmation: true,
            });
          },
        });
      }
    };
    if (eventoTabela.payload.length === 0) {
      const entidadeCapitalizada = stringFormatter.capitalizeLastLetter(
        this.nomeEntidade
      );
      this.modalService.showModal({
        title: `Excluir ${this.nomeEntidade}`,
        messageModal: `Selecione um ou mais  ${entidadeCapitalizada}`,
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
      });
    } else {
      const entidadeCapitalizada = stringFormatter.capitalizeLastLetter(
        this.nomeEntidade
      );
      this.modalService.showModal({
        title: `Excluir ${this.nomeEntidade}`,
        messageModal: `Deseja realmente excluir o(s) registro(s) de ${entidadeCapitalizada}?`,
        btnTitlePositive: "Excluir",
        positiveCallback: () => excluirCallback(),
        btnTitleNegative: "Cancelar",
      });
    }
  }
}
