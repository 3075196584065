import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Secao } from '../../../../../../../../pesquisa-old/cadastro/model/secao';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemProtegido } from '../../../../../../../../pesquisa-old/cadastro/security/itemProtegido';
import { PesquisaAuthorityService } from '../../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';
import { AlterarSecao, Selecionar } from '../../../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../../../pesquisa-old/cadastro/store/passoCadastro';

/**
 * Componente responsável por renderizar o conteudo interno
 * de uma sessão
 */
@Component({
  selector: 'app-secao-content',
  templateUrl: './secao-content.component.html',
  styleUrls: ['./secao-content.component.scss'],
})
export class SecaoContentComponent implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };

  @Input() source: Secao;

  /**
   * Marcador para saber se os erros de validacao
   * devem ser exibidos
   */
  showErrors: boolean = false;

  /**
  * Subscriptions que deverão ser encerradas
  * junto com a destruição do componente
  */
  subscriptions: Subscription[] = [];

  /**
   * Marcação que indica se o componente
   * está em fase de edição
   */
  isEdicao = false;

  /**
   * Marcador que indica se modificações
   * estão habilitadas
   */
  modificacaoPermitida = false;

  passoBloqueado: boolean = false;

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
    private store: Store<CadastroPesquisaStoreState>) {
  }

  /**
   * @override
   */
  ngOnInit() {
    this.formGroup = Secao.getInitializedControl(this.source);
    this.controls = this.formGroup.controls;

    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.QUESTIONARIO);
    if (!this.modificacaoPermitida) {
      this.formGroup.disable();
    }

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    /**
     * Verificando se deve-se exibir as mensagens de validação
     * no passo atual, caso positivo, é feito o unsubscribe
     * no canal
     */
    const dadosPassoSubscription = pesquisaStoreObservable
      .subscribe((store) => {
        // tslint:disable-next-line: max-line-length
        this.passoBloqueado = store.dadosGeraisCadastro.passosBloqueados.includes(PassoCadastro.QUESTIONARIO);

        const dados = store.dadosPasso.get(PassoCadastro.QUESTIONARIO);
        // tslint:disable-next-line: max-line-length
        this.showErrors = dados.exibirValidacao;
        if (this.showErrors && dadosPassoSubscription) {
          dadosPassoSubscription.unsubscribe();
        }
      });

    this.subscriptions.push(dadosPassoSubscription);
  }

  onChange() {
    this.store.dispatch(new AlterarSecao(this.formGroup.value));
  }

  /**
   * Função que fecha o conteudo que está
   * sendo exibido
   */
  cancelar() {
    this.store.dispatch(new Selecionar(null, null));
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
