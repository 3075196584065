import { Injectable } from '@angular/core';
import { apiLocation, nominatimLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { CrudService } from 'app/servico/requestService/crudService';
import { PalavraChave } from 'app/util/componente/tabela/tabela-filtravel/filter/palavraChave';
import { Observable } from 'rxjs';
import { Localidade } from '../localidade';
import { LocalidadeMapeada } from '../localidadeMapeada';
import { LocalidadeTree } from '../localidadeTree';


@Injectable({
  providedIn: 'root',
})
export class LocalidadeService extends CrudService<Localidade> {
  requestUrl: string;
  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/localidades`);
  }

  atualizarLocalidade(localidade: LocalidadeMapeada, mapErrorToString: boolean = true): Observable<LocalidadeMapeada> {

    this.requestUrl = `${this.resourcePath}/${localidade.id}`;

    return this.requestService.put(this.requestUrl, localidade, {}, mapErrorToString);
  }

  listarLocalidades(id?: string): Observable<any[]> {

    this.requestUrl = `${this.resourcePath}/listar-filhos?codigo=${id}`;

    const response = <Observable<any[]>>this.requestService.get(this.requestUrl);

    return response;
  }

  /**
   * lista todas as localidades filhas a partir do identificador
   * de uma localidade pai.
   *
   * Obs: este metodo retorna, para cada filha, toda a arvore de localidades.
   * @param id identificador da localidade pai
   */
  listarLocalidadesFilhas(id: number): Observable<LocalidadeTree[]> {

    this.requestUrl = `${this.resourcePath}/${id}/filhas`;

    const responseData = <Observable<LocalidadeTree[]>> this.requestService.get(this.requestUrl);
    return responseData;
  }

  listarTodos(palavraChave: PalavraChave): Observable<Localidade[]> {
    const requestUrl = `${this.resourcePath}/filtrar/palavra-chave?page=0&size=10`;

    // tslint:disable-next-line:max-line-length
    const response = <Observable<Localidade[]>>this.requestService.post(requestUrl, palavraChave, true);

    return response;
  }

  deletarLocalidade(id: any): Observable<Localidade[]> {
    const requestPath = `${this.resourcePath}/${id}`;
    return this.requestService.delete(requestPath);
  }

  /**
   * duplica uma Localidade, dado o identificador da pesquisa original
   * @param id identificador da localidade a ser duplicada
   */
  postDuplicarPesquisa(id: number): Observable<Localidade> {
    const requestUrl = `${apiLocation}/localidades/copia/${id}`;

    return <Observable<Localidade>>this.requestService.post(requestUrl, true);
  }

  // Conexão com API externa para obter informações de uma referência
  // obtendo informações como latitude, longitude, entre outros.

  listarLocalidadesComDetalhes(busca: string) {
    let valor = [];
    const sogoURLNominatim = nominatimLocation(busca);
    fetch(sogoURLNominatim)
      .then(data => data.json())
      .then(data => data.map(item => valor.push(item)))
      .catch(error => console.error(error))

    return valor;
  }



  // findById(id: any): Observable<Localidade> {
  //   return of({
  //     "id": 1,
  //     "nome": "pai1",
  //     "longitude": 123.0,
  //     "latitude": 123.0,
  //     "raio": 123.0,
  //     "localidadePai": null,
  //     "localidadesFilhas": [
  //       {
  //         "id": 2,
  //         "nome": "filho1",
  //         "longitude": 0.0,
  //         "latitude": 321.0,
  //         "raio": 321.0,
  //         "localidadePai": null,
  //         "localidadesFilhas": []
  //       }
  //     ]
  //   });
  // }

}

