import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoPergunta } from '../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { Pergunta } from '../../../../../../pesquisa-old/cadastro/model/pergunta';
import { Secao } from '../../../../../../pesquisa-old/cadastro/model/secao';
import { TipoConteudo } from '../secao/conteudo/tipoConteudo';
// tslint:disable-next-line: max-line-length
import { AdicionarPergunta, AdicionarSecao } from '../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../pesquisa-old/cadastro/store/passoCadastro';

@Component({
  selector: 'app-questionario-seletor-adicao',
  templateUrl: './questionario-seletor-adicao.component.html',
  styleUrls: ['./questionario-seletor-adicao.component.scss'],
})
export class QuestionarioSeletorAdicaoComponent implements OnInit, OnDestroy {

  /**
   * Marcador para informar se as perguntas devem
   * ou não serem exibidas
   */
  exibirPerguntas: boolean = false;

  tipoPergunta = TipoPergunta;

  /**
   * Subscriptions que deverão ser encerradas
   * junto com a destruição do componente
   */
  subscriptions: Subscription[] = [];

  constructor(protected store: Store<CadastroPesquisaStoreState>) {

  }

  /**
   * @override
   */
  ngOnInit(): void {

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    /**
     * Observando itens selecionados
     */
    const itemSelecionadoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso.get(PassoCadastro.QUESTIONARIO)),
        map(dadosPasso => dadosPasso.itemSelecionado),
      )
      .subscribe((itemSelecionado) => {
        this.exibirPerguntas = itemSelecionado.tipoConteudo === TipoConteudo.CONTEUDO_SECAO;
      });

    this.subscriptions.push(itemSelecionadoSubscription);
  }

  ngOnDestroy(): void {
  }

  adicionarSecao() {
    const secao = new Secao();
    this.store.dispatch(new AdicionarSecao(secao));
  }

  adicionarPergunta(tipoPergunta: TipoPergunta) {
    const pergunta = new Pergunta(tipoPergunta);
    this.store.dispatch(new AdicionarPergunta(pergunta));
  }

}
