<div class="sg-input-group">
  <div class="container-white">
    <div class="row">
      <div class="col-12 title-step-section">
        <h3>4 de 4: Vínculos</h3>
        <span class="error-message" *ngIf="!modificacaoPermitida">
          Você não tem permissão para modificar esse menu
        </span>
      </div>
    </div>
    <div class="filterVinculo" *ngIf="modificacaoPermitida && !passoBloqueado">
      <div class="campo">
        <label>1º Selecione a localidade da pesquisa</label>
        <app-selecao-localidade
          (inputUpdate)="onLocalidadeSelect($event)"
        ></app-selecao-localidade>
      </div>
      <button
        title="adicionar localidade"
        class="sg-btn sg-secondary"
        (click)="adicionarLocalidade()"
        *ngIf="localidadeSelecionada"
      >
        Adicionar Localidade
      </button>
      <!-- <button class="sg-btn sg-alternate">Visualizar Localidade</button> -->
    </div>
  </div>
  <div class="row">
    <div class="col-12 header-section">
      <h3 *ngIf="modificacaoPermitida">
        Defina cotas e víncule as localidades selecionadas
      </h3>
      <h3 *ngIf="!modificacaoPermitida">Localidades e cotas selecionadas</h3>
      <span
        class="error-message"
        *ngIf="localidadesFormGroup.errors?.noControls"
      >
        Você precisa adicionar pelo menos uma localidade.
      </span>
    </div>
  </div>
  <div class="row section">
    <div class="col-lg-8">
      <table class="table-vinculos">
        <thead>
          <th></th>
          <th>Localidade</th>
          <th>Vínculo</th>
          <th>Cota %</th>
          <th>Cota numérica</th>
          <th *ngIf="modificacaoPermitida && !passoBloqueado">Excluir</th>
          <th *ngIf="modificacaoPermitida && !passoBloqueado">Operadores</th>
        </thead>
        <tbody [formGroup]="localidadesFormGroup">
          <tr
            *ngFor="let localidadeVinculo of localidadesVinculos"
            [hidden]="localidadeVinculo.hidden"
            class="localidade-tr"
            [ngClass]="{
              'is-active':
                localidadeVinculo.hash === localidadeVinculoSelecionadaHash,
              inputError: localidadesFormGroup
                .get(localidadeVinculo.hash)
                .get('operadoresVinculos').errors
            }"
            (click)="
              !localidadeVinculo.possuiFilho &&
                onLocalidadeVinculoSelect(localidadeVinculo)
            "
            formGroupName="{{ localidadeVinculo.hash }}"
            [class.lastOption]="!localidadeVinculo.possuiFilho"
            title=""
          >
            <td class="td-vinculo-erros">
              <!-- <div class="vinculos-erros-icon"
                [title]="hasNenhumOperadorVinculadoErro(localidadeVinculo.hash) ? 'Você precisa vincular pelo menos um operador a esta localidade' : 'Existem erros de configurações nos operadores vinculados à localidade'"
                *ngIf="possuiErroNosVinculosOperadores(localidadeVinculo.hash) || hasNenhumOperadorVinculadoErro(localidadeVinculo.hash)">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </div> -->
              <span
                [ngClass]="{
                  'fa-angle-right': localidadeVinculo.expanded === false
                }"
                class="fa"
                *ngIf="localidadeVinculo.possuiFilho"
                (click)="onExpansaoLocalidadesFilhas(localidadeVinculo)"
              ></span>
              <span
                [ngClass]="{
                  'fa-angle-down': localidadeVinculo.expanded === true
                }"
                class="fa"
                *ngIf="localidadeVinculo.possuiFilho"
                (click)="onExpansaoLocalidadesFilhas(localidadeVinculo)"
              ></span>
            </td>

            <td [style.padding-left.px]="[localidadeVinculo.hierarquia * 20]">
              {{ localidadeVinculo.nomeLocalidade }}
            </td>
            <td>
              {{
                localidadeVinculo.nomeLocalidadePai === null
                  ? "-"
                  : localidadeVinculo.nomeLocalidadePai
              }}
            </td>

            <td
              [ngClass]="{
                inputError:
                  localidadesFormGroup
                    .get(localidadeVinculo.hash)
                    .get('cotaPercentual').status === 'INVALID'
              }"
            >
              <app-cota-percentual
                (cotaPercentualChangeBlur)="
                  onCotaPercentualChange(localidadeVinculo.hash)
                "
                [title]="
                  getTitleCotaPercentualLocalidade(localidadeVinculo.hash)
                "
                (click)="stopPropagation($event)"
                ngDefaultControl
              >
              </app-cota-percentual>
            </td>
            <td>
              {{ localidadeVinculo.cotaNumerica }}
            </td>
            <td *ngIf="modificacaoPermitida && !passoBloqueado">
              <span
                class="fa fa-trash-o"
                (click)="removerLocalidadeVinculo(localidadeVinculo)"
              ></span>
            </td>
            <td *ngIf="modificacaoPermitida && !passoBloqueado">
              <span
                class="fa fa-user-o adicaoOperador"
                [class.color-error]="
                  hasNenhumOperadorVinculadoErro(localidadeVinculo.hash)
                "
              >
                <span>+</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg-4 operadorContainer">
      <app-vinculos-operadores
        *ngIf="localidadeVinculoSelecionadaHash"
        []="localidadesFormGroup.get(localidadeVinculoSelecionadaHash).get('operadoresVinculos')"
      >
      </app-vinculos-operadores>
    </div>
  </div>
</div>
