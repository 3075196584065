import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { EventoInput } from '../../../inputs/evento/eventoInput';
import { TipoEventoInput } from '../../../inputs/evento/tipoEventoInput';
import { InputComponent } from '../../../inputs/inputComponent';
import { OperadorItem } from '../../../store/vinculos/model';

@Component({
  selector: 'app-selecao-operador',
  templateUrl: './selecao-operador.component.html',
  styleUrls: ['./selecao-operador.component.scss'],
})
export class SelecaoOperadorComponent extends InputComponent {

  operadoresMap: {[key: number]: OperadorItem} = {};
  operadores: OperadorItem[] = [];

  private requestService: RequestService;

  isLoading: boolean = false;

  @Input() selecionarPrimeiro: boolean;
  @Input() idOperadorSelecionado: number;
  @Input() editavel: boolean = true;
  @Input() idsOperadoresIgnorados: number[] = [];

  @Output() eventEmitter: EventEmitter<OperadorItem> = new EventEmitter();

  /**
   * @override
   * Construtor responsável por criar uma instância de SelecaooperadorComponent.
   * Injeta o service RequestService.
   * @param requestService serviço responsável por realizar requisições à API do back-end.
   */
  constructor(requestService: RequestService) {
    super();
    this.requestService = requestService;
  }

  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit(); // chama initFormGroup na classe super
    this.carregarOperadores();
  }

  /**
   * @override
   */
  initFormGroup() {
    this.control = new UntypedFormControl(this.idOperadorSelecionado ? this.idOperadorSelecionado : null);
    this.formGroup = new UntypedFormGroup({
      operador: this.control,
    });
    if (!this.editavel) {
      this.formGroup.disable();
    }
  }

  atualizarFormGroup(idOperadorSelecionado: number): void {

    this.formGroup.patchValue({
      operador: idOperadorSelecionado,
    });
    this.onOperadorSelect();
  }

  carregarOperadores() {

    this.isLoading = true;
    const uri = `${apiLocation}/operadores`;

    this.requestService.get(uri).subscribe((data: OperadorItem[]) => {

      this.operadores = data.filter((operador: OperadorItem) => {
        return !this.idsOperadoresIgnorados.includes(operador.id);
      });

      if (this.operadores.length > 0) {
        this.operadoresMap = this.operadores.map((operador: OperadorItem) => {
          const mapEntry = {};
          mapEntry[operador.id] = operador;
          return mapEntry;
        }).reduce((reduced, next) => {
          return { ...reduced, ...next };
        });
      } else {
        this.operadores = [];
      }

      this.isLoading = false;
    });
  }

  onOperadorSelect() {

    const idOperadorSelecionado = this.control.value;
    const operadorSelecionado: OperadorItem = this.operadoresMap[idOperadorSelecionado];

    const eventoInput: EventoInput = {
      tipo: TipoEventoInput.CHANGE,
      payload: {
        id: idOperadorSelecionado,
        nome: operadorSelecionado.nome,
      },
    };

    // console.log('[SelecaooperadorComponent.onOperadorSelect] evento: ', eventoInput);
    this.eventEmitter.emit(operadorSelecionado);
    this.notifyParent(eventoInput);
  }

}
