<div class="containerOptionsMenu">
  <ul>
    <!-- <li class="item-selecao-questionario" title="Adicionar seção" (click)="adicionarSecao()"><i class="fa fa-flag-o"></i>Adicionar seção</li> -->
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.UNICA)" title="Pergunta Única"><i class="fa fa-comment-o"></i>Pergunta Única</li>
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.MULTIPLA)" title="Multipla escolha"><i class="fa fa-list"></i>Multipla escolha</li>
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.DATA)" title="Data"><i class="fa fa-calendar-o"></i>Data</li>
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.ESCALA_NUMERICA)" title="Escala numérica"><i class="fa fa-sort-numeric-asc"></i>Escala numérica</li>
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.FOTO)" title="Foto"><i class="fa fa-picture-o"></i>Foto</li>
    <!--
      
      PERGUNTAS COMENTADAS ABAIXO SÃO PERGUNTAS NÃO NECESSÁRIAS PARA A RELEASE
      TENSAI_BASIC
    
    -->
    <!-- <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.GRADE_UNICA)" title="Grade única"><i class="fa fa-square-o"></i>Grade única</li> -->
    <!-- <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.GRADE_MULTIPLA)" title="Grade múltipla"><i class="fa fa-clone"></i>Grade múltipla</li> -->
    <!-- <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.MULTIPLA_ORDEM_CITACAO)" title="Múltipla ordem"><i class="fa fa-object-group"></i>Múltipla ordem</li> -->
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.HORARIO)" title="Horário"><i class="fa fa-clock-o"></i>Horário</li>
    <li class="item-selecao-questionario" [ngClass]="exibirPerguntas ? '' : 'disabled'" (click)="exibirPerguntas && adicionarPergunta(tipoPergunta.VIDEO)" title="Vídeo"><i class="fa fa-video-camera"></i>Vídeo</li>
  </ul>
</div>
 