import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { TipoConteudo } from '../../../conteudo/tipoConteudo';
import { GradeComponent } from '../grade.component';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-grade-multipla-questionario',
  templateUrl: './grade-multipla.component.html',
  styleUrls: ['./grade-multipla.component.scss'],
})
export class GradeMultiplaComponent extends GradeComponent {

  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_GRADE_MULTIPLA;
  tipoPergunta: TipoPergunta = TipoPergunta.GRADE_MULTIPLA;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
