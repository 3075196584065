<div class="breadcrumb-container">
  <div class="breadcrumb-wrapper">
    <div *ngFor="let item of data; let i = index">
      <i *ngIf="i > 0" class="fa-sharp fa-light fa-chevron-right"></i>
      <a [routerLink]="item.itemLink" [ngClass]="{'breadcrumb-item': true, 'current': item.active}">{{ item.itemName }}</a>
    </div>
  </div>

  <button class="go-back-btn" *ngIf="hasGoBackText" (click)="goBack()">
    <i class="fa-regular fa-arrow-left"></i>
    <span>{{ goBackText }}</span>
  </button>
</div>
