/**
 * Representa um erro de validação
 */
export class ValidateError {
  constructor(
    public message: string
  ){}
}

/**
 * Validador que encapsula os esquemas de validação usando Yup
 */
interface Validator {
  validateIsNotZero(value: number, message: string): Validator
  validateIsNotNull(value: any, message: string): Validator;
  validateIsNull(value: any, message: string): Validator;
  validateIsNotEmpty(value: string, message: string): Validator;
  validateTrue(expr: boolean, message: string): Validator;
  withErrors(): boolean;
  errors(): ValidateError[];
}

/**
 * Validador que mapeia a validação de acordo com um nome de entrada usando
 * Validator
 */
interface MappedValidator {
  validateIsNotNull(name: string, value: any, message: string): Validator;
  validateIsNull(name: string, value: any, message: string): Validator;
  validateIsNullOrEmpty(name: string, value: string, message: string): Validator;
  errors(): ValidateError[];
}

/**
 * 
 */
export class ValidatorImpl implements Validator {
  private errs: ValidateError[] = [];

  public validateTrue(expr: boolean, message: string): Validator {
    if (!expr) {
      this.errs.push(new ValidateError(message));
    }
    return this;
  }

  public validateIsNotZero(value: number, message: string): Validator {
    if ((typeof value === 'number' && value === 0) || !value) {
      this.errs.push(new ValidateError(message));
    }
    return this;
  }

  public validateIsNotNull(value: any, message: string): Validator {
    if (!value) {
      this.errs.push(new ValidateError(message));
    }
    return this;
  }

  public validateIsNull(value: any, message: string): Validator {
    if (!!value) {
      this.errs.push(new ValidateError(message));
    }
    return this;
  }

  public validateIsNotEmpty(value: string, message: string): Validator {
    return this.validateIsNull(value, message);
  }

  public withErrors(): boolean {
    return this.errs.length > 0;
  }

  public errors(): ValidateError[] {
    const result = [...this.errs];
    this.errs = [];
    return result;
  }

}