import { EstadoValidacao } from '../cadastro/store/cadastroPesquisaStoreState';
import { StatusPesquisa, StatusPesquisaNomeAmigavel } from './statusPesquisa';
import { StatusPesquisaManager } from './statusPesquisaManager';
import { Injectable } from '@angular/core';

/**
 * Servico responsavel por validar a troca de status da pesquisa
 */
@Injectable({
  providedIn: 'root',
})
export class StatusChangeService {

  validationStrategies: Map<StatusPesquisa, Function>;

  constructor(private statusPesquisaManager: StatusPesquisaManager) {

    this.validationStrategies = new Map();
    this.validationStrategies.set(StatusPesquisa.RASCUNHO, validateStatusChangeFromRascunho);
    this.validationStrategies.set(StatusPesquisa.EXECUCAO, validateStatusChangeFromExecucao);
    this.validationStrategies.set(StatusPesquisa.CONCLUIDO, validateStatusChangeFromConcluido);
  }

  validateStatusChange(payload: StatusChangePayload) {
    const statusChangeHandler = this.validationStrategies.get(payload.statusOriginalPesquisa);
    statusChangeHandler.call(this, payload);
  }

}

export class StatusChangePayload {

  estadoValidacao: EstadoValidacao;
  statusOriginalPesquisa: StatusPesquisa;
  statusAtualPesquisa: StatusPesquisa;

  // tslint:disable-next-line: max-line-length
  constructor(estadoValidacao: EstadoValidacao, statusOriginalPesquisa: StatusPesquisa, statusAtualPesquisa: StatusPesquisa) {

    this.estadoValidacao = estadoValidacao;
    this.statusOriginalPesquisa = statusOriginalPesquisa;
    this.statusAtualPesquisa = statusAtualPesquisa;
  }
}

export class StatusChangeError extends Error {

  destinoCorreto: StatusPesquisa;

  constructor(message: string, destinoCorreto: StatusPesquisa) {
    super(message);
    this.destinoCorreto = destinoCorreto;
  }
}

/**
 * Handler que trata as trocas de status de uma pesquisa originalmente
 * de RASCUNHO
 */
function validateStatusChangeFromRascunho(payload: StatusChangePayload) {

  // tslint:disable-next-line: max-line-length
  const destinosPossiveis = this.statusPesquisaManager.getDestinosPossiveis(StatusPesquisa.RASCUNHO);

  // tslint:disable-next-line: max-line-length
  const statusDestino = destinosPossiveis.find(statusDestino => statusDestino.destino === payload.statusAtualPesquisa);
  const isValid = payload.estadoValidacao.validaSemVinculos
  ? payload.estadoValidacao.caracteristicas &&
    payload.estadoValidacao.marcacoes &&
    payload.estadoValidacao.questionario
  : payload.estadoValidacao.geral;
  if(statusDestino.precisaValidar && payload.estadoValidacao.amostrasMaiorQueZero){
    // tslint:disable-next-line: max-line-length
    throw new StatusChangeError(`Uma pesquisa só pode permanecer no status '${StatusPesquisaNomeAmigavel[statusDestino.destino]}' caso o número de amostras online ou amostras presenciais sejam maior que 0.`, StatusPesquisa.RASCUNHO);
  } else if (statusDestino.precisaValidar && !isValid) {
    // tslint:disable-next-line: max-line-length
    throw new StatusChangeError(`Uma pesquisa só pode permanecer no status '${StatusPesquisaNomeAmigavel[statusDestino.destino]}' caso ela esteja válida.`, StatusPesquisa.RASCUNHO);
  }

}

/**
 * Handler que trata as trocas de status de uma pesquisa originalmente de rascunho
 */
function validateStatusChangeFromExecucao(payload: StatusChangePayload) {

  const statusAtual = payload.statusAtualPesquisa;
  // tslint:disable-next-line: max-line-length
  if (statusAtual === StatusPesquisa.EXECUCAO || statusAtual === StatusPesquisa.CONCLUIDO) {

    // tslint:disable-next-line: max-line-length
    const passosNecessariosValidos = payload.estadoValidacao.caracteristicas && payload.estadoValidacao.questionario && payload.estadoValidacao.marcacoes;

    if (!passosNecessariosValidos) {
      // tslint:disable-next-line: max-line-length
      throw new StatusChangeError(`Uma pesquisa só pode permanecer no status '${statusAtual}' caso ela esteja válida.`, StatusPesquisa.RASCUNHO);
    }
    return;
  }
}

function validateStatusChangeFromConcluido(payload: StatusChangePayload) {

}
