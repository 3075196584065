import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ChangePasswordService } from "app/componentes/change-password/change-password.service";
import { LoginCredentials } from "app/infraestrutura/security/service/loginCredentials";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { StatusCode } from "app/util/httpUtils/StatusCode";
import { Subscription } from "rxjs";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnDestroy, OnInit, OnDestroy {
  isLoading: boolean = false;
  formGroup: UntypedFormGroup;
  showApiFeedback: boolean = false;
  errorMessage: string;
  hide = true;

  /**
   * Subscriptions a serem fechadas com a morte do componente
   */
  subscriptions: Subscription[] = [];

  constructor(
    private securityService: SecurityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changePasswordService: ChangePasswordService
  ) {}

  onLoginFail(error) {
    this.showApiFeedback = true;

    this.errorMessage = StatusCode.builder(error.status)
      .process4xx(() => "Dados incorretos, verifique os campos de e-mail e senha")
      .process5xx(() => "Sistema temporariamente indisponível. Por favor tente novamente mais tarde.")
      .build();
  }

  onLoginSuccess() {
    if(this.securityService.getAuthenticatedUserNeedChangePassword()) {
      this.router.navigate(["/nova-senha"]);
    } else {
      this.router.navigate([""]);
      window.location.reload();
    }
  }

  doLogin(credentials: LoginCredentials) {
    if (this.formGroup.invalid) return;
    const loginSubscription = this.securityService
      .login(credentials)
      .subscribe({
        next: () => {
          this.onLoginSuccess()
        },
        error: ({ error }) => this.onLoginFail(error),
      });

    this.subscriptions.push(loginSubscription);
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});
    // tslint:disable-next-line:max-line-length
    this.formGroup.addControl(
      "login",
      new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(50),
      ])
    );
    // tslint:disable-next-line:max-line-length
    this.formGroup.addControl(
      "password",
      new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
      ])
    );
    this.formGroup.addControl("keepConnected", new UntypedFormControl(false));

    const queryParamMapSubscription =
      this.activatedRoute.queryParamMap.subscribe(
        this.tratarRedirecionamentoPorNaoAutorizacao.bind(this)
      );

    this.subscriptions.push(queryParamMapSubscription);
  }

  /**
   *  Esta função altera o valor pega o evento de clique do usuario no checkbox
   *  para alterar o valor anterior para o contrário dele mesmo.
   *  Então, quando a variavel do controlador de keepConnected estiver setado para 'true'
   *  e o usuario clicar no checkbox, espera-se que seja sobrescrito por false
   */
  handleCheckboxClick(): void {
    this.formGroup
      .get("keepConnected")
      .setValue(!this.formGroup.get("keepConnected").value);
  }

  /**
   * Metodo que trata o acesso à pagina de login
   * caso tenha sido por conta de um redirecionamento por
   * falta de autorização.
   *
   */
  tratarRedirecionamentoPorNaoAutorizacao(paramMap: ParamMap) {
    const isUnauthorizedRedirect = paramMap.get("unauthorized");

    if (isUnauthorizedRedirect) {
      /**
       * Redirecionando para si mesmo apenas para limpar o queryParams
       */
      this.router.navigate(["login"]);

      alert("Seu usuário não tem autorização para acessar o Tensai Web");
    }
  }

  /**
   * Controla a exibição da senha no input
   */
  toggleHidePassword(): void {
    this.hide = !this.hide;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
