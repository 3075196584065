import { ChartDataset } from './chartDataset';
import { ChartColor } from './chartColor';

export class ChartConfiguration {

  datasets: ChartDataset[];
  colors: ChartColor[];
  labels: string[];
  options: {};
  plugins: any[];
  type: string;
  legend: boolean;
  inlinePlugin: any[];

}
