import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { IAlternative } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { AlternativaAuditada } from "../../../gerenciador/entidade/alternativa";

@Component({
  selector: "app-alternativa-aberta-numero",
  templateUrl: "./aberta-numero.component.html",
  styleUrls: ["./aberta-numero.component.scss"],
})
export class AlternativaAbertaNumeroComponent implements OnInit {
  @Input() alternativa = {} as AlternativaAuditada;
  @Input() value: any = null;
  @Input() selecionavel: boolean = true;
  @Input() actionsBlockedBySituation: boolean = false;

  @Output() onChange = new EventEmitter<{
    alternativa: AlternativaAuditada;
    value: string;
  }>(null);
  @Output() onCheck = new EventEmitter<{
    alternativa: AlternativaAuditada;
    value: string;
  }>(null);

  @ViewChild("numberInput") numberInputRef: ElementRef<HTMLElement> = null;

  private maxLength: number = 9;

  constructor() {}

  ngOnInit(): void {}

  public get valueToString() {
    if (isNaN(this.value) || this.value === null) {
      return "";
    }
    return String(this.value);
  }

  private clear() {
    this.value = "";
  }

  private isEmpty() {
    return !this.value;
  }

  private usuarioAlterouValor: boolean = false;

  private checkInputConsistence() {
    if (this.valueToString.search(/[eE]/) !== -1) {
      this.value = this.valueToString.replace(/[eE]/g, "") || null;
    }

    if (this.valueToString.length > this.maxLength) {
      this.value = this.valueToString.slice(0, 8) || null;
    }
  }

  public handleInput($event) {
    this.checkInputConsistence();
    this.onChange.emit({ alternativa: this.alternativa, value: this.value });

    this.usuarioAlterouValor = true;

    if (this.isEmpty()) {
      this.notifyCheckRequest();
    } else if(!this.alternativa.isSelecionada) {
      this.notifyCheckRequest();
    }
  }

  private notifyCheckRequest() {
    this.onCheck.emit({ alternativa: this.alternativa, value: this.value });
  }

  public handleFocus() {
    if (!this.alternativa.isSelecionada) {
      this.notifyCheckRequest();
    }
  }

  public handleBlur() {
    if (!this.usuarioAlterouValor && this.isEmpty()) {
      this.notifyCheckRequest();
      this.clear();
    }

    this.usuarioAlterouValor = false;
  }

}
