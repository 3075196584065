import { zeroLeft } from "./zeroleft";

/**
 * Cria um array de números da faixa de 'start' a 'end', inclusive.
 *
 * @param start valor inicial esperando (inclusive)
 * @param end valor final esperado (inclusive)
 * @returns
 */
export function createRange(start: number, end: number): number[] {
  const result = [];

  for (let i = start; i <= end; i += 1) {
    result.push(i);
  }
  return result;
}
