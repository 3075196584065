import { TableCell } from "./tCell";

type Events = "click" | "hover";

export interface RowCustomStyles {
  [key: string]: string | number;
}

export class RowEvents {
  type: Events;
}

export class TableRow {
  id?: number;
  cells: TableCell[];
  className?: string;
  metadata?: any;
  customStyles?: RowCustomStyles;
}
