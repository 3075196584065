<article>
  <div
    [ngClass]="{ 'new-group': isNewGroup === group.id }"
    class="group-header"
  >
    <div *ngIf="!editMode" class="visualization-group">
      <div class="item">
        <div
          class="checkbox"
          (click)="selectGroups(group)"
          [ngClass]="{
            'selected-item': selected
          }"
        >
          <i class="fa-regular fa-check"></i>
        </div>
        <div *ngIf="!editMode" class="title">
          <label>{{ group.title }}</label>
          <span
            class="tooltip"
            [ngClass]="{
              'group-user': group.typeGroup === 'MANUAL',
              'default-grupo': group.typeGroup === 'AUTOMATICA'
            }"
          >
            {{ group.title }}
          </span>
          <i
            *ngIf="group.typeGroup === 'MANUAL'"
            (click)="toggleEditMode()"
            class="fa-regular fa-pen"
          ></i>
        </div>
      </div>
      <div *ngIf="group.typeGroup === 'MANUAL'" class="actions">
        <p>{{ citationLength }} citações | {{ group.porcentagem }}%</p>
        <i
          (click)="toogleDropdown()"
          class="fa-regular arrow"
          [ngClass]="{
            'fa-chevron-down': !showDropdown,
            'fa-chevron-up': showDropdown
          }"
        ></i>
      </div>
      <div
        *ngIf="group.typeGroup === 'AUTOMATICA'"
        class="deafault-groups"
      >
        <p>{{ group.citation.length }}</p>
      </div>
    </div>
    <div *ngIf="editMode" class="edit-group">
      <input maxlength="140" type="text" [(ngModel)]="newTitle" />
      <button [attr.data-tooltip]="'Cancelar'" (click)="toggleEditMode()">
        <i class="fa-regular fa-xmark"></i>
      </button>
      <button [attr.data-tooltip]="'Confirmar'" (click)="handleChangeGroup()">
        <i class="fa-regular fa-check"></i>
      </button>
    </div>
  </div>

  <ul
    class="group-body"
    [ngClass]="{
      'visible-body': showDropdown
    }"
  >
  <li *ngFor="let item of group.groups">
    <p>{{ item.title }} {{ groupChildLength(item) }}</p>
    <label (click)="handleRemoveChildGroup(group.id, item.id)"
      >Desfazer</label
    >
  </li>
    <li *ngFor="let item of group.citation">
      <p>{{ item.title }}</p>
      <label (click)="handleDeleteCitationFromGroup(group.id, item.id)"
        >Desfazer</label
      >
    </li>
  </ul>
</article>
