import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-status-select",
  templateUrl: "./status-select.component.html",
  styleUrls: ["./status-select.component.scss"],
})
export class StatusSelectComponent implements OnChanges {
  @ViewChild("statusSelect", { static: true }) statusSelect;

  @Input() selectedStatus;

  @Output() changeStatus = new EventEmitter();

  status;

  constructor() {}

  statusIsOpen = false;

  ngOnChanges(): void {
    this.status = this.selectedStatus.ativo ? "Ativo" : "Inativo";
  }

  toggleStatus() {
    this.statusIsOpen = !this.statusIsOpen;
  }

  handleStatusClick(ativo: boolean) {
    this.changeStatus.emit({ ativo, index: this.selectedStatus.index });
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.statusSelect.nativeElement.contains(event.target)) {
      if (this.statusIsOpen) {
        this.toggleStatus();
      }
    }
  }
}
