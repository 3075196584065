import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validador de campos iguais, o baseControl define quem sera o alvo da comparação,
 * o toCompareControl eh aquele que sera submetido a comparacao
 */
export default (baseControl: AbstractControl) => {

  return (toCompareControl: AbstractControl): ValidationErrors => {

    /**
     * se o toCompareControl for modificado pelo usuario, ele dispara a validacao do baseControl,
     * garantindo assim  validacao em duas vias
     */
    if (toCompareControl.dirty) {
      if (baseControl.pristine) {
        baseControl.updateValueAndValidity();
        baseControl.markAsPristine();
      }
      toCompareControl.markAsPristine();
    }

    if (toCompareControl.value === baseControl.value) {
      return null;
    }

    return {
      desiguais: true,
    };
  };
};
