<div class="main-container">
  <button
    (click)="toggleMeatballVisibility()"
    [ngClass]="
      showMeatball ? 'view-container meatball-active' : 'view-container'
    "
  >
    <i class="fa-solid fa-ellipsis"></i>
  </button>
  <div *ngIf="showMeatball" class="meatball-container">
    <button
      class="option"
      [disabled]="disabledOptionsIds.includes(option.id)"
      [ngClass]="{ disabled: disabledOptionsIds.includes(option.id) }"
      *ngFor="let option of internalMeatballOptions"
      (click)="handleSelectOption(option)"
    >
      <i [className]="option.icon"></i>
      <h4 class="option-name">{{ option.label }}</h4>
  </button>
  </div>
</div>
