import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { PalavraChave } from 'app/util/componente/tabela/tabela-filtravel/filter/palavraChave';

export interface Cliente {
  id: number;
  nome: string;
  cpfCnjp: string;
  tipoPessoa: string;
  login: string;
  ativo: boolean;
  cpfCnjpFormatado: string;
}

export interface Pesquisa {
  id: number,
  cliente: string;
  tituloCurto: string;
  status: string;
  amostrasPresenciais: number;
  amostrasOnline: number;
  title: string;
  statusTexto: string;
}

export interface Operador {
  id: number;
  nome: string;
  matricula: string;
  cpf: string;
  rg: string;
  periodoInicial: string;
  periodoFinal: string;
  ativo: boolean;
  diasFimVinculo: string;
  cpfFormatado: string;
}

export interface Localidade {
  id: number;
  nome: string;
  longitude: number;
  latitude: number;
  raio: number;
  possuiFilho: false,
  versao: string;
  localidadePai: number;
  localidadesFilhas: any;
}

export interface Grupo {
  id: number;
  nome: string;
  qtdPermissoes: number;
  qtdFuncionarios: number;
  ativo: boolean;
}

export interface Funcionario {
  id: number;
  nome: string;
  matricula: string;
  cpf: string;
  rg: string;
  ativo: boolean;
  cpfFormatado: string;
}

// =====> Início da tipagens da nova versão da busca global
interface Instancia {
  id: number;
  nome: string;
}

type PesquisaV4 = Instancia;
type LocalidadeV4 = Instancia;
type GrupoV4 = Instancia;

interface OperadorV4 extends Instancia {
  fotoperfil: string;
}

type FuncionarioV4 = OperadorV4;
type ClienteV4 = OperadorV4;


export interface HeaderSearchDataV4 {
  cliente: ClienteV4[];
  funcionario: FuncionarioV4[];
  grupo: GrupoV4[];
  localidade: LocalidadeV4[];
  operador: OperadorV4[];
  pesquisa: PesquisaV4[];
  instancia: Instancia[];
}

export interface HeaderSearchDataAmountV4 {
  cliente: boolean;
  funcionario: boolean;
  grupo: boolean;
  localidade: boolean;
  operador: boolean;
  pesquisa: boolean;
  instancia: boolean;
  quantidadeResultados: number;
}
// ==========> Fim da tipagem da nova versão da busca global
export interface HeaderSearchData {
  clients: Cliente[];
  researches: Pesquisa[];
  operators: Operador[];
  locations: Localidade[];
  groups: Grupo[];
  employees: Funcionario[];
}


@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(protected requestService: RequestService) {
  }

  private toQueryParams(statuses: string[] = [], arquivada: boolean = false) {

    const queryParams: any = { arquivada };
    if (statuses !== null || statuses.length !== 0) {
      queryParams.status = statuses;
    }

    return queryParams;
  }

  // Clientes
  buscarTodosClientesPorPalavrasChaves(palavrasChaves: PalavraChave): Observable<Cliente[]> {
    const requestUrl = `${apiLocation}/clientes/filtrar/palavra-chave`;
    const response = <Observable<Cliente[]>>this.requestService.post(requestUrl, palavrasChaves);
    return response;
  }

  // Pesquisa
  buscarTodasPesquisasPorPalavrasChaves(palavrasChaves: PalavraChave): Observable<Pesquisa[]> {
    const requestUrl = `${apiLocation}/pesquisas/filtrar/palavra-chave`;
    const response = <Observable<Pesquisa[]>>this.requestService.post(requestUrl, palavrasChaves);
    return response;
  }

  // Operador
  buscarTodosOperadoresPorPalavrasChaves(palavrasChaves: PalavraChave): Observable<Operador[]> {
    const requestUrl = `${apiLocation}/operadores/filtrar/palavra-chave`;
    const response = <Observable<Operador[]>>this.requestService.post(requestUrl, palavrasChaves);

    return response
  }

  // Localidade
  buscarTodasLocalidadesPorPalavrasChaves(palavrasChaves: PalavraChave): Observable<Localidade[]> {
    const requestUrl = `${apiLocation}/localidades/filtrar/palavra-chave`;
    const response = <Observable<Localidade[]>>this.requestService.post(requestUrl, palavrasChaves);

    return response
  }

  // Grupo
  buscarTodosGruposPorPalavrasChaves(palavrasChaves: PalavraChave): Observable<Grupo[]> {
    const requestUrl = `${apiLocation}/grupos/filtrar/palavra-chave`;
    const response = <Observable<Grupo[]>>this.requestService.post(requestUrl, palavrasChaves);

    return response
  }

  // Funcionario
  buscarTodosFuncionariosPorPalavrasChaves(palavrasChaves: PalavraChave): Observable<Funcionario[]> {
    const requestUrl = `${apiLocation}/funcionarios/filtrar/palavra-chave`;
    const response = <Observable<Funcionario[]>>this.requestService.post(requestUrl, palavrasChaves);

    return response
  }

  //  Retorna quantidade de resultados da busca
  async returnSearchAmount(palavraChave: PalavraChave): Promise<number> {
    const headerSearchDataAmount = await this.getSearchAmount(palavraChave).toPromise()
    return headerSearchDataAmount.quantidadeResultados
  }

  // Busca v4 - retorna quantidade de resultados
  getSearchAmount(palavrasChaves: PalavraChave): Observable<HeaderSearchDataAmountV4> {
    const requestUrl = `${apiLocation}/buscar/status?arquivada=false`;
    const response = <Observable<HeaderSearchDataAmountV4>>this.requestService.post(requestUrl, palavrasChaves);

    return response
  }
   // Busca v4 (retorna busca geral na versão 4)
   buscar(palavrasChaves: PalavraChave): Observable<HeaderSearchDataV4> {
    const requestUrl = `${apiLocation}/buscar/resultados?arquivada=false&limit=100&offset=0`;
    const response = <Observable<HeaderSearchDataV4>>this.requestService.post(requestUrl, palavrasChaves);

    return response
  }

  // buscar todos - v4
  async buscarTodosV4(palavraChave: PalavraChave): Promise<HeaderSearchDataV4> {
    // const
    const headerSearchData = await this.buscar(palavraChave).toPromise()
    return ({
      cliente: !!headerSearchData.cliente ? headerSearchData.cliente : [],
      funcionario: !!headerSearchData.funcionario ? headerSearchData.funcionario : [],
      grupo: !!headerSearchData.grupo ? headerSearchData.grupo : [],
      instancia: !!headerSearchData.instancia ? headerSearchData.instancia : [],
      localidade: !!headerSearchData.localidade ? headerSearchData.localidade : [],
      operador: !!headerSearchData.operador ? headerSearchData.operador : [],
      pesquisa: !!headerSearchData.pesquisa ? headerSearchData.pesquisa : [],
    })
  }

  // todos
  async buscarTodos(palavrasChaves: PalavraChave): Promise<HeaderSearchData> {
    const headerSearchData: HeaderSearchData = {
      clients: await this.buscarTodosClientesPorPalavrasChaves(palavrasChaves).toPromise(),
      researches: await this.buscarTodasPesquisasPorPalavrasChaves(palavrasChaves).toPromise(),
      operators: await this.buscarTodosOperadoresPorPalavrasChaves(palavrasChaves).toPromise(),
      // locations: await this.buscarTodasLocalidadesPorPalavrasChaves(palavrasChaves).toPromise(),
      // groups: await this.buscarTodosGruposPorPalavrasChaves(palavrasChaves).toPromise(),
      // employees: await this.buscarTodosFuncionariosPorPalavrasChaves(palavrasChaves).toPromise(),

      //TODO: RESOLVER

      locations: [],
      groups: [],
      employees: []
    };
    return headerSearchData;
  }

}
