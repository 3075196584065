/**
 * Representa um evento para um tipo 'type' obtido em getType
 */
export interface Event<T> {
  getType(): string;
  getPayload(): T;
}

/**
 * Representa um evento para um tipo 'void' obtido em getType
 */
export abstract class VoidEvent implements Event<void> {
  abstract getType(): string;

  getPayload(): void{
    return;
  }
}
