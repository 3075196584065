import { registerPayload, updatePayload } from './buildEmployeePayload';
import { updatePermissionsListWhenAGroupIsAdd, updatePermissionsListWhenAGroupIsRemoved, updateAllGroupsListOnEdit, updateAllPermissionsListOnEdit } from './permissions';

export {
  registerPayload,
  updatePayload,
  updatePermissionsListWhenAGroupIsAdd,
  updatePermissionsListWhenAGroupIsRemoved,
  updateAllGroupsListOnEdit,
  updateAllPermissionsListOnEdit
}
