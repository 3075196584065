import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Renderer2,
} from "@angular/core";
import { removeSpecialCharacters } from "app/util/formatter";
import { Subject, Subscription } from "rxjs";
import { containText } from "app/util/search/search";
import { OperatorData } from "../create-operator/create-operator.component";

export interface SelectDataItem {
  value: number;
  label: string;
  hidden?: boolean;
}

export interface SelectedItemEvent {
  item: SelectDataItem;
  index: number;
}

function defaultFilterCallback(
  data: SelectDataItem[],
  query: string
): SelectDataItem[] {
  return data.filter((entry) => {
    const includes = removeSpecialCharacters(entry.label)
      .trim()
      .toLowerCase()
      .includes(removeSpecialCharacters(query.toLowerCase()).trim());
    return includes;
  });
}

@Component({
  selector: "app-select-operator",
  templateUrl: "./select-operator.component.html",
  styleUrls: ["./select-operator.component.scss"],
})
export class SelectOperator implements OnInit {
  @Input() label: string = "Label";
  @Input() placeholder: string = "Selecione um item";
  @Input() filterPlaceholder: string = "Busque por itens";
  @Input() selectedValue: number = null;
  @Input() maxRange: number;
  @Input() howManyLeft: number;
  @Input() changedByInput: boolean;
  @Input() closeOnSelect: boolean = true;
  @Input() errorMessage: string =
    "Não há dados para serem exibidos e/ou filtrados";
  @Input() backgroundColor: string = "rgb(248, 249, 253)";
  @Input() width: string = "400px";
  @Input() operadoresSelecionados: OperatorData[];

  @Input() data: SelectDataItem[];
  @Input() selected: SelectedItemEvent;

  @Input() maxValue: number = 0;
  @Input() totalValue: number = 0;

  @Input() filterCallback: (
    data: SelectDataItem[],
    query: string
  ) => SelectDataItem[] = defaultFilterCallback;

  @Output() changeSelectedItem: EventEmitter<SelectedItemEvent> =
    new EventEmitter();
  @Output() inputValue: EventEmitter<number> = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  @ViewChild("select", {static: true}) selectElement: ElementRef;
  @ViewChild("chevronButton", {static: true}) chevronElement: ElementRef;
  @ViewChild("selectorbody", {static: true}) selectorBodyElement: ElementRef;

  minRange: number = 0;

  public showBody: boolean = false;
  public selectedItem: SelectDataItem | null = null;
  public selectedItemIdx: number | null = null;
  public filter: string = "";
  public filteredData: SelectDataItem[];

  private filterSubject: Subject<string> = new Subject();

  constructor(private renderer: Renderer2) {
    this.renderer.listen("window", "click", (e: Event) => {
      if (
        this.selectElement.nativeElement.contains(e.target) &&
        this.chevronElement.nativeElement.contains(e.target)
      ) {
        if (this.chevronElement.nativeElement.contains(e.target)) {
          this.showBody = this.showBody ? false : true;
          // if (!this.showBody) {
          //   this.handleShowBody();
          // } else {
          //   // this.handleShowBody();
          // }
        }
      } else {
        if (this.selectorBodyElement.nativeElement.contains(e.target)) {
          return;
        } else {
          if (this.showBody) {
            this.handleShowBody();
          }
        }
      }
    });
  }

  ngOnInit() {
    this.filteredData = this.data;
    if (this.selected) {
      this.selectedItem = this.selected.item;
      this.selectedItemIdx = this.selected.index;
    }
  }

  // manipula apresentação do corpo do select
  handleShowBody() {
    this.showBody = !this.showBody;
  }

  /**
   * handle item select events
   * @param item selected item
   * @param itemIdx selected item index
   */
  handleItemSelect(item: SelectDataItem, itemIdx: number) {
    this.selectedItem = item;
    this.selectedItemIdx = itemIdx;
    const selectedItemEvent: SelectedItemEvent = {
      item: this.selectedItem,
      index: this.selectedItemIdx,
    };
    this.changeSelectedItem.emit(selectedItemEvent);
    this.cleanFilter();
    if (this.closeOnSelect) {
      this.showBody = false;
    }
    this.filteredData = this.data;
  }

  // propação ação de deletar operador
  handleDeleteSelectedItem() {
    this.onDelete.emit();
  }

  //
  cleanFilter() {
    this.filter = "";
    this.filterSubject.next("");
  }

  // verificar quando input foi modificado por clique no input checkbox
  onCheckboxChange() {
    this.changedByInput = true;
  }

  // lida com mudanças no texto no filtro
  handleChange(searchText: string) {
    if (searchText) {
      this.filteredData = this.data.filter(
        (item) => item.label && containText(item.label, searchText)
      );
    } else {
      this.filteredData = this.data;
    }
  }

  itemIsSelected(itemListagem: SelectDataItem): boolean {
    return (
      this.operadoresSelecionados.length > 0 &&
      !!this.operadoresSelecionados.find((operadorSelecionado) => {
        return operadorSelecionado.value === itemListagem.value;
      })
    );
  }

  // verifica checkbox marcado
  isChecked(item: SelectDataItem) {
    if (this.selectedItem) {
      if (this.selectedItem.value === item.value) {
        if (this.changedByInput) {
          setTimeout(() => {
            this.changedByInput = false;
          }, 0);
          return;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  // alteração do input
  handleInputChange(event: number) {
    this.inputValue.emit(event);
  }
}
