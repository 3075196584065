import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[string-no-break-line]",
})
export class MaskStringNoBreakLine {
  /**
   * Função detecta o pressionar da tecla ENTER no teclado para impedir
   * @param event recebe o evento de digitação do teclado
   */
  @HostListener("keydown", ["$event"]) onInputChange(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
}
