import {
  CellAlignTypes,
  CellStyleBehavior,
  CellTypes,
  CustomStyles,
  IconCellValue,
  ImageCellValue,
  MultiTagValue,
  SelectableStatusCellValue,
  StatusCellValue,
  TableCell,
  TooltipData,
} from "./tCell";

export type HeadTypes = "text" | "checkbox";

export type HeadAlignTypes = "flex-start" | "center" | "flex-end";

export class TableHead {
  type: HeadTypes;
  label?: string;
  customStyles?: CustomStyles;
  className?: string | ((cellRef: TableCell) => string);
  checkable?: boolean;
  checked?: boolean;
  alignTypes?: HeadAlignTypes;
  // Caso o programador queira configurar o tipo ou valores default das células de uma coluna específica globalmente.
  cellType?: CellTypes;
  cellStyles?: CustomStyles | ((cellRef: TableCell) => CustomStyles);
  cellAlign?: CellAlignTypes;
  cellDataToolTip?: TooltipData;
  defaultCellIconValue?: IconCellValue;
  defaultCellHtmlTemplateValue?: string;
  defaultCellStatusValue?: StatusCellValue;
  defaultCellTextValue?: string;
  defaultCellSelectableStatusValue?: SelectableStatusCellValue;
  defaultCellMultiTagValue?: MultiTagValue[];
  defaultCellImageValue?: ImageCellValue;
  cellStyleBehavior?: CellStyleBehavior;
}
