import { Component, OnInit, Input } from '@angular/core';
import { KeyValue } from 'app/util/misc/keyValue';
import { TipoResposta } from './tipoResposta';
import { Alternativa } from '../../../../../../../../../pesquisa-old/cadastro/model/alternativa';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { UntypedFormGroup } from '@angular/forms';
import { VinculoOperador } from '../../../../../../../../../pesquisa-old/cadastro/model/vinculoOperador';
import { AlterarAlternativa } from '../../../../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';

/**
 * O tipo de resposta corresponde ao tipo da Alternativa
 */
@Component({
  selector: 'app-tipo-resposta-input',
  templateUrl: './tipo-resposta-input.component.html',
  styleUrls: ['./tipo-resposta-input.component.scss'],
})
export class TipoRespostaInputComponent implements OnInit {

  /**
   * formGroup da alternativa
   */
  @Input() formGroup: UntypedFormGroup;
  tiposResposta: KeyValue[];

  @Input() editavel: boolean = true;

  enumsToIgnore = ['GRADE', 'ESCALA_NUMERICA'];

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
  }

  /**
   * @override
   */
  ngOnInit() {
    this.tiposResposta = this.getTiposResposta();

    if (!this.editavel) {
      this.formGroup.get('tipoAlternativa').disable();
    }
  }

  getTiposResposta() {

    const tipoRespostaEnumKeys: string[] = Object.keys(TipoResposta);
    const validEnumsKeys = tipoRespostaEnumKeys.filter(key => !this.enumsToIgnore.includes(key));

    const tiposResposta: KeyValue[] = validEnumsKeys.map((key: string) => {
      return new KeyValue(key, TipoResposta[key]);
    });

    return tiposResposta;
  }

  getTipoRespostaById(key: string) {
    const matchedResults = this.tiposResposta.filter(tipoPergunta => tipoPergunta.key === key);
    // tslint:disable-next-line:max-line-length
    const respostaSelecionada = matchedResults && matchedResults.length > 0 ? matchedResults[0] : null;

    return respostaSelecionada;
  }

  onChange() {
    this.store.dispatch(new AlterarAlternativa(this.formGroup.value));
  }

}
