import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ISelectedItems, IClientListModel } from "../../interfaces/list";
import { NotificatorService } from "app/notificador/notificator.service";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-table-list-client",
  templateUrl: "./table-list-client.component.html",
  styleUrls: ["./table-list-client.component.scss"],
})
export class TableListClientComponent implements OnInit {
  @Input() clientes: IClientListModel[] = [];
  @Output() removeClient: EventEmitter<any> = new EventEmitter<any>();

  meatballOpened: number;

  selectAll: boolean = false;
  selectedItems: ISelectedItems[] = [];
  modalData = new ModalData();

  constructor(private notificatorService: NotificatorService, private router: Router) {}

  ngOnInit(): void {}

  public handleOnSelectAllItems($event: MouseEvent) {
    const target = $event.target as HTMLInputElement;

    this.selectAll = target.checked;

    // select all
    if (this.selectAll) {
      this.selectedItems = this.clientes.map((v) => {
        return { id: v.id, situacao: v.situacao };
      });
    }
    // unselect all
    else if (this.selectedItems.length) {
      this.selectedItems.length = 0;
    }
  }

  public handleOnSelectItem(
    $event: MouseEvent,
    itemId: number,
    itemSituation?: boolean
  ) {
    $event.stopPropagation();

    const index = this.selectedItems.findIndex((item) => item.id === itemId);

    if (index === -1) {
      this.selectedItems.push({ id: itemId, situacao: itemSituation });
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  public isChecked(itemId: number) {
    return !!this.selectedItems.filter((item) => itemId === item.id).length;
  }

  /**
   * Função que dispara o modal de verificação para ativação de um contratante, disparado mediante a mudança no status
   * do contratante;
   * @param ativo valor do status que será atribuído
   * @param index indice do array que será feita mudança de status
   */
  onActiveStatusModal(ativo, index) {
    this.modalData.show = true;
    this.modalData.icon = "fa-regular fa-plug-circle-plus";
    this.modalData.type = "information";
    this.modalData.titleDialog = "Ativação de Contratante";
    this.modalData.textDialog =
      "Um e-mail será enviado ao administrador para definição de senha. Deseja continuar?";
    this.modalData.actionPositive = "Continuar";
    this.modalData.positiveCallback = () => this.setClientStatus(ativo, index);
  }

  /**
   * Função que dispara o modal de verificação para inativação de um contratante, disparado mediante a mudança no status
   * do contratante;
   * @param ativo valor do status que será atribuído
   * @param index indice do array que será feita mudança de status
   */
  onInactiveStatus(ativo, index) {
    this.modalData.show = true;
    this.modalData.icon = "fa-regular fa-plug-circle-xmark";
    this.modalData.type = "information";
    this.modalData.titleDialog = "Inativar Contratante";
    this.modalData.textDialog = `Deseja mesmo inativar o Cliente <b><<Cliente>></b>?`;
    this.modalData.actionPositive = "Inativar";
    this.modalData.positiveCallback = () => this.setClientStatus(ativo, index);
  }

  /**
   * Intercepta qualquer mudança de status
   * @param ativo novo status
   * @param index indice do array onde o novo status vai ser alterado
   */
  handleStatusChange({ ativo, index }) {
    if (ativo) {
      this.onActiveStatusModal(ativo, index);
    } else {
      this.onInactiveStatus(ativo, index);
    }
  }

  /**
   * Esse método tem a responsabilidade mudar o status de uma pesquisa,
   * e enviar requisição para o back-end fazer o msmo
   * @param ativo novo status
   * @param index lugar onde dever ser alterado o status
   */
  setClientStatus(ativo, index) {
    // TODO: request para back alterar esse valor
    const title = `Contratante ${ativo ? "Ativado" : "Inativado"}`;
    const message = `Contratante ${
      ativo ? "ativado" : "inativado"
    } com sucesso`;
    this.notificatorService.showInfo(title, message);
  }

  public navigateToEditClient(clientId: number) {
    this.router.navigate(["/cliente/atualizar"], {queryParams: {id: clientId}});
  }

  deleteClient(id: number, nome: string){
    this.removeClient.emit({idClient: id, nameClient: nome})
  }
}
