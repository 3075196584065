<div class="contractor-container" [formGroup]="formGroup">
  <div class="title-container">
    <span class="title-wrapper">
      <i class="fa-regular fa-clipboard-list fa-lg"></i>
      <h3>Administrador</h3>
    </span>

    <div class="edit-options-wrapper">
      <span
        class="edit-options"
        (click)="handleChangeAdmin()"
        *ngIf="isEdicao"
      >
        <i class="fa-regular fa-user-pen fa-lg"></i>
        <h4>Mudar administrador</h4>
      </span>

      <span
        class="edit-options"
        (click)="handleChangePass()"
        *ngIf="isEdicao"
      >
        <i class="fa-regular fa-lock-keyhole fa-lg"></i>
        <h4>Redefinir senha</h4>
      </span>
    </div>

  </div>

  <hr class="line" />

  <section class="inputs">

    <div class="input-wrapper">
      <label>Nome do Administrador*</label>
      <input
        type="text"
        [ngClass]="(formGroup.get('nome')?.invalid && (formGroup.get('nome')?.dirty || formGroup.get('nome')?.touched)) ? 'inputs name-input error-input ' : 'inputs name-input'"
        formControlName="nome"
        placeholder="João Victor de Sousa Pinheiros Barbosa Filho"
        maxlength="101"
        mascara-primeiro-digito-sem-espaco
      />
      <span class="error-message" *ngIf="formGroup.get('nome')?.invalid && (formGroup.get('nome')?.dirty || formGroup.get('nome')?.touched)">
        <span *ngIf="formGroup.get('nome')?.errors.required">Campo obrigatório</span>
        <span *ngIf="formGroup.get('nome')?.errors.maxlength">Limite máximo de 100 caracteres</span>
      </span>
    </div>

    <div class="input-wrapper">
      <label>CPF*</label>
        <input
          type="text"
          [ngClass]="(formGroup.get('cpf')?.invalid && (submitted || !formGroup.get('cpf')?.value.length) &&
          (formGroup.get('cpf')?.dirty || formGroup.get('cpf')?.touched)) ? 'inputs cpf-input error-input ' : 'inputs cpf-input'"
          formControlName="cpf"
          placeholder="000.000.000-00"
          [attr.disabled]="isEdicao ? '' : null"
          maxlength="14"
          mascara-document
          [initialValue]="formGroup.get('cpf').value"
        />
      <span class="error-message" *ngIf="formGroup.get('cpf')?.invalid && (formGroup.get('cpf')?.dirty || formGroup.get('cpf')?.touched)">
        <span *ngIf="formGroup.get('cpf')?.errors.required">Campo obrigatório</span>
        <span *ngIf="!formGroup.get('cpf')?.errors.required && formGroup.get('cpf')?.errors && submitted">CPF inválido</span>
      </span>
    </div>

    <div class="input-wrapper">
      <label>E-mail*</label>
      <input
        type="email"
        [ngClass]="(formGroup.get('login')?.invalid && (submitted || !formGroup.get('login')?.value.length) &&
        (formGroup.get('login')?.dirty || formGroup.get('login')?.touched)) ? 'inputs email-input error-input ' : 'inputs email-input'"
        formControlName="login"
        placeholder="email@email.com.br"
        [pattern]="emailRegex"
      />
      <span class="error-message" *ngIf="formGroup.get('login')?.invalid && (formGroup.get('login')?.dirty || formGroup.get('login')?.touched)">
        <span *ngIf="formGroup.get('login')?.errors.required">Campo obrigatório</span>
        <span *ngIf="formGroup.get('login')?.errors.pattern && submitted">E-mail inválido</span>
        <span *ngIf="formGroup.get('login')?.errors.alreadyExists && submitted">{{ loginError }}</span>
      </span>
    </div>
  </section>

</div>

<app-prompt-modal *ngIf="this.modalData.show" [modalData]="modalData">
  <app-change-admin [formGroup]="newAdmin" [submitted]="submitted"></app-change-admin>
</app-prompt-modal>
