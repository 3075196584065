<app-audit-progress
  [surveyStatus]="surveyStatus"
  [collectionsObjective]="collectionsObjective"
  [auditedCollections]="auditedCollections"
  [surveyId]="surveyId"
  (updateSurveyStatus)="updateHeaderSurveyStatus()"
>
</app-audit-progress>

<app-audit-survey-data
  [overviewData]="overviewData"
></app-audit-survey-data>
