import { Component, OnInit } from '@angular/core';
import { ModalService } from 'app/componentes/modal/modal.service';
import { NotificatorService } from 'app/notificador/notificator.service';
import { LgpdTypes } from 'app/servico/lgpdService/lgpd-interface';
import { LgpdService } from 'app/servico/lgpdService/lgpd.service';

interface IItemBreadcrumb {
  itemName: string;
  itemLink: string;
  active: boolean;
}

interface ILgpdData {
  name: string
  date: string
  size: string
  location: string
}

enum StateLGPDDoc {
  Novo = "NEW",
  Editar = "EDIT",
  Carregando = "LOADING",
}

@Component({
  selector: 'app-cadastro-lgpd',
  templateUrl: './cadastro-lgpd.component.html',
  styleUrls: ['./cadastro-lgpd.component.scss']
})
export class CadastroLGPDComponent implements OnInit {

  constructor(
    private lgpdService: LgpdService,
    protected modalService: ModalService,
    private notificatorService: NotificatorService,
  ) { }

  privacyPolicyData: ILgpdData = {
    name: '',
    date: '',
    size: '',
    location: '#'
  }

  termsOfUsageData: ILgpdData = {
    name: '',
    date: '',
    size: '',
    location: '#'
  }

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Comunicação",
      itemLink: "/comunicacao",
      active: true,
    },
  ];

  lgpdDocState = {
    terms:  StateLGPDDoc.Novo,
    privacy:  StateLGPDDoc.Novo
  }

  ngOnInit(): void {
    this.getDocumentInfo();
  }

  getDocumentInfo(): void {
    this.lgpdService.getLgpdDocuments().subscribe({
      next: (data) => {
        data && data.forEach(({ type, location, nome, data_cadastro, tamanho }) => {
          if(type === LgpdTypes.PRIVACY_POLICY) {
            this.privacyPolicyData = {
              name: decodeURIComponent(nome),
              date: data_cadastro,
              size: this.documentSizeValidation(tamanho),
              location: location
            }
          } else {
            this.termsOfUsageData = {
              name: decodeURIComponent(nome),
              date: data_cadastro,
              size: this.documentSizeValidation(tamanho),
              location: location
            }
          }
        })
      },
      complete: () =>  { this.checkDocumentExists(); },
      error: (err) => {
        this.notificatorService.showError(
          "Erro ao recuperar!",
          "Ocorreu um erro ao recuperar os arquivos"
        );
      }
    })
  }

  /**
   * Verifica o tamanho em bytes e devolve a conversão para Kb ou Mb
   * @param bytes
   * @returns converted value in Kb or Mb
   */
  documentSizeValidation(bytes: number): string {
    if (bytes < 100000) {
      // Converter para KB
      const kbValue = bytes / 1024;
      return `(${kbValue.toFixed(2)}Kb)`;
    } else {
      // Converter para MB
      const mbValue = bytes / (1024 * 1024);
      return `(${mbValue.toFixed(2)}Mb)`;
    }
  }

  /**
   * Verifica se os documentos já foram cadastrados
   */
  checkDocumentExists(): void {
    this.privacyPolicyData.location.length > 1 ? this.lgpdDocState.privacy = StateLGPDDoc.Editar : StateLGPDDoc.Novo;
    this.termsOfUsageData.location.length > 1 ? this.lgpdDocState.terms = StateLGPDDoc.Editar : StateLGPDDoc.Novo;
  }

  getLgpdTerm(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file: File = input.files[0];
      const FILE_MAX_LENGTH = file.size > 5 * 1024 * 1024;
      if (FILE_MAX_LENGTH) {
        this.showModal();
      } else {
        this.saveTermDocument(file);
      }
    }
  }

  getLgpdPrivacy(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file: File = input.files[0];
      const FILE_MAX_LENGTH = file.size > 5 * 1024 * 1024;
      if (FILE_MAX_LENGTH) {
        this.showModal();
      } else {
        this.savePrivacyDocument(file);
      }
    }
  }

  saveTermDocument(file: File) {
    this.lgpdDocState.terms = StateLGPDDoc.Carregando;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', LgpdTypes.TERMS_OF_USAGE);

    this.lgpdService.saveDocument(formData).subscribe({
      complete: () => {
        this.lgpdDocState.terms = StateLGPDDoc.Editar;
        this.getDocumentInfo();
        this.notificatorService.showInfo(
          "Arquivo adicionado!",
          "Arquivo adicionado com sucesso"
        );
      },
      error: () => {
        this.lgpdDocState.terms = StateLGPDDoc.Novo;
        this.notificatorService.showError(
          "Erro ao adicionar!",
          "Erro ao adicionar arquivo"
        );
      }
    })
  }

  savePrivacyDocument(file: File) {
    this.lgpdDocState.privacy = StateLGPDDoc.Carregando;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', LgpdTypes.PRIVACY_POLICY);

    this.lgpdService.saveDocument(formData).subscribe({
      complete: () => {
        this.lgpdDocState.privacy = StateLGPDDoc.Editar;
        this.getDocumentInfo();
        this.notificatorService.showInfo(
          "Arquivo adicionado!",
          "Arquivo adicionado com sucesso"
        );
      },
      error: () => {
        this.lgpdDocState.privacy = StateLGPDDoc.Novo;
        this.notificatorService.showError(
          "Erro ao adicionar!",
          "Erro ao adicionar arquivo"
        );
      }
    })
  }

  showModal() {
    this.modalService.showModal({
      title: "Envio de arquivo",
      messageModal: '<span style="color: var(--status-error)">O tamanho do arquivo é superior a 5Mb.</span> <br> Por favor, selecione outro arquivo e tente novamente.',
      btnTitlePositive: "Tentar novamente",
      btnTitleNegative: "Cancelar",
      icon: "fa-regular fa-file-lines",
    });
  }

}
