<div [formGroup]="formGroup" class="" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">
  
  <app-pergunta-card
    [icone]="'fa fa-sort-numeric-asc'"
    [titulo]="ordem + ' - ESCALA NUMÉRICA'"
    [nomePlaceHolder]="'Título da pergunta de escala numérica?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>






<!-- <div [formGroup]="formGroup" class="escala-numerica">

  <div formGroupName="faixaPergunta">
  
    <label>Valor inicial mínimo</label>
    <select formControlName="intervaloInicio" (change)="onIntervaloInicioChange()">
      <option *ngFor="let valorInicialMinimo of valoresIniciaisMinimos" 
        [ngValue]="valorInicialMinimo">
        {{valorInicialMinimo}}
      </option>
    </select>
    
    <label>Valor final máximo</label>
    <select formControlName="intervaloFim" (change)="onIntervaloFimChange()">
      <option *ngFor="let valorFinalMaximo of valoresFinaisMaximos" 
        [ngValue]="valorFinalMaximo">
        {{valorFinalMaximo}}
      </option>
    </select><br/>

    <label>
      Menor número permitido
      <input type="text"
        mascara-numero
        formControlName="menor" 
        (blur)="onMenorChange()"
        maxlength="1"/><br/>
        <span class="error-message" [hidden]="!(showErrors && controls.menor.errors && controls.menor.errors.maiorQueDeveria)">
          O menor número deve ser menor que o maior número permitido
        </span>
        <span class="error-message" [hidden]="!(showErrors && controls.menor.errors && controls.menor.errors.valorMaximoPermitido)">
          O menor número deve ser menor ou igual ao valor máximo final
        </span>
    </label>

    <label>
      Maior número permitido
      <input type="text"
        mascara-numero 
        formControlName="maior" 
        (blur)="onMaiorChange()"
        maxlength="1"/>
      <span class="error-message" [hidden]="!(showErrors && controls.maior.errors && controls.maior.errors.maiorQueDeveria)">
        O maior número deve ser maior que o menor número permitido
      </span>
      <span class="error-message" [hidden]="!(showErrors && controls.maior.errors && controls.maior.errors.valorMaximoPermitido)">
        O maior número deve ser menor ou igual ao valor máximo final
      </span>
    </label>

  </div>

</div> -->
