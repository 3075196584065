import { Injectable } from '@angular/core';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { CrudService } from 'app/servico/requestService/crudService';
import { Observable } from 'rxjs';
import { Cliente } from '../cliente';

@Injectable({
  providedIn: 'root',
})
export class ClienteService extends CrudService<Cliente> {

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/clientes`);
  }

  getTotalClientes(): Observable<any> {

    const requestUrl = `${apiLocation}/clientes/total-com-status`;

    return this.requestService.get(requestUrl);
  }

  getIdClientePorIdContaUsuario(id: string): Observable<any> {

    const requestUrl = `${apiLocation}/clientes/buscarporidConta?id=${id}`;

    return this.requestService.get(requestUrl);
  }

}
