import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ModalService } from "app/componentes/modal/modal.service";
import { EntrevistaService } from "../../service/entrevista.service";
import { TipoExportacao } from "../../tipoExportacao";
import { DownloadType } from "./downloadType";

export interface DownloadModalInputData {
  idPesquisa: number;
  idsEntrevistas: number[];
  all: boolean;
}

interface DownloadContext {
  idsEntrevistas: number[];
  all: boolean;
}

interface CSVDownloadContext extends DownloadContext {
  idPesquisa: number;
  tipoExportacao: TipoExportacao;
}

type DownloadFunction = (context: DownloadContext) => void;

@Component({
  selector: "app-download-modal",
  templateUrl: "./download-modal.component.html",
  styleUrls: ["./download-modal.component.scss"],
})
export class DownloadModalComponent implements OnInit {
  form: UntypedFormGroup;
  tipoDownload = DownloadType;
  isLoading: boolean = false;

  tipoExportacao = TipoExportacao;

  downloadStrategies: Map<DownloadType, DownloadFunction> = new Map();

  constructor(
    private dialogRef: MatDialogRef<DownloadModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DownloadModalInputData,
    private entrevistaService: EntrevistaService,
    private dialog: MatDialog,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      /**
       * SETANDO OPÇÃO DEFAULT COMO tipoDownload#CSV,
       * Só iremos retornar a tipoDownload#GRAVACOES
       * quando o lançamento de uma release
       * com tipoDownload#GRAVACOES fizer sentido.
       */
      tipoDownload: new UntypedFormControl(this.tipoDownload.CSV),
      tipoExportacao: new UntypedFormControl(this.tipoExportacao.CODIGO_TEXTO),
    });
    this.initDownloadStrategies();
  }

  initDownloadStrategies(): void {
    const downloadCSVCallback = this.downloadCSVCallback.bind(this);
    const downloadGravacoesCallback = this.downloadGravacoesCallback.bind(this);

    this.downloadStrategies.set(
      this.tipoDownload.GRAVACOES,
      downloadGravacoesCallback
    );
    this.downloadStrategies.set(this.tipoDownload.CSV, downloadCSVCallback);
  }

  isExportacaoCSV() {
    return this.form.controls.tipoDownload.value === this.tipoDownload.CSV;
  }

  /**
   * Callback executado caso o tipo do donwload seja tipoDownload.GRAVACOES.
   * Manda requisição para o servidor para realizar o processamento e o download
   * das gravações das entrevistas selecionadas.
   * @param context
   */
  downloadGravacoesCallback(context: DownloadContext) {
    this.isLoading = true;

    this.entrevistaService.downloadGravacoes(context.idsEntrevistas).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        const data: any = response.body;

        const blobData = new Blob([data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blobData);

        const aElement = document.createElement("a");
        document.body.appendChild(aElement);
        aElement.href = url;
        aElement.download = "gravacoes.zip";
        aElement.click();
        window.URL.revokeObjectURL(url);
        aElement.remove();
      },
      error: (err) => {
        this.isLoading = false;

        this.modalService.showModal({
          title: "Baixar gravações",
          messageModal: `Não foi possível baixar o áudio de gravação da(s) entrevista(s) selecionada(s): ${err}`,
          btnTitlePositive: "Entendi",
          isOnlyConfirmation: true,
          icon: "fa-light fa-circle-info",
        });
      },
    });
  }

  /**
   * Callback executado caso o tipo do download seja tipoDownload.CSV.
   * Manda requisição para o servidor para realizar o processamento e o download
   * das entrevistas selecionadas (ou todas) no formato CSV.
   */
  downloadCSVCallback(context: CSVDownloadContext): void {
    this.isLoading = true;

    const idsEntrevistas = context.all ? [] : context.idsEntrevistas;
    try {
      this.entrevistaService
        .exportarEmCSV(
          context.idPesquisa,
          context.tipoExportacao,
          idsEntrevistas
        )
        .subscribe((showLoading) => {
          this.isLoading = showLoading;
        });
    } catch (error) {
      this.isLoading = false;

      this.modalService.showModal({
        title: "Baixar exportação em CSV",
        messageModal: `Não foi possível o arquivo CSV das entrevistas selecionada(s): ${error}`,
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
        icon: "fa-light fa-circle-info",
      });
    }
  }

  download(): void {
    const downloadFunction = this.downloadStrategies.get(
      this.form.controls.tipoDownload.value
    );
    const downloadData: CSVDownloadContext = {
      ...this.data,
      tipoExportacao: this.form.controls.tipoExportacao.value,
    };

    downloadFunction(downloadData);
  }

  cancelDownload(): void {
    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
