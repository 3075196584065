import { Perfil } from '../../../localidades/localidade';

/**
 * filtra os perfis passados, setando hidden = true os perfis que não possui caracteristicas
 * que atendem à query passada. Um perfil atende a uma argumento de busca quando
 * pelo menos uma de suas caracteristicas possui uma alternativa cujo nome inclui (includes
 * ou contains) a query passada, ignorando case e acentos.
 *
 * @param query criterio de busca
 * @param perfis perfis que serão filtrados
 */
function filter(query: string, perfis: Perfil[]): Perfil[] {

  const lowerCaseQueryWithoutAccents = removeAccents(query.toLowerCase());
  console.log('lowerCaseQueryWithoutAccents: ', lowerCaseQueryWithoutAccents);

  return perfis.map((perfil: Perfil) => {

    const matchesProfile = perfil.caracteristicas
      .some((caracteristica) => {
        // tslint:disable-next-line: max-line-length
        const caracteristicaToBeVerified = removeAccents(caracteristica.nomeAlternativa.toLowerCase());
        console.log('caracteristicaToBeVerified: ', caracteristicaToBeVerified);
        return caracteristicaToBeVerified.includes(lowerCaseQueryWithoutAccents);
      });

    if (matchesProfile) {
      perfil.hidden = false;
    } else {
      perfil.hidden = true;
    }

    return perfil;
  });
}

function removeAccents(text: string): string {
  return text.replace(/[áàãâä]/gi, 'a')
    .replace(/[éè¨ê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/[ç]/gi, 'c')
    .replace(/[ñ]/gi, 'n')
    .replace(/[^a-zA-Z0-9]/g, ' ');
}

const perfisSearchService = {
  filter,
};

export default perfisSearchService;
