import { SelectData } from '../../../../../componentes/s-input/s-input.component';

/**
 * Lista estática do tipo de pesquisa retirado do Figma
 */
export const TypesOfSurveysOptions: SelectData[] = [
  {
    label: "Mercado",
    value: "mercado"
  },
  {
    label: "Opinião",
    value: "opinião"
  },
  {
    label: "Científica",
    value: "científica"
  },
  {
    label: "Eleitoral",
    value: "eleitoral"
  }
];
