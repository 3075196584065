import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TipoAlternativa } from "app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa";

import {
  Alternativa,
  Pergunta,
  Secao
} from "../../modulos/pesquisa-beta/cadastro/steps/pesquisas-logica/pesquisas-logica.component";

@Component({
  selector: "app-answer-item",
  templateUrl: "./answer-item.component.html",
  styleUrls: ["./answer-item.component.scss"],
})
export class AnswerItemComponent implements OnInit {
  public _changeSelectedQuestionRef: Pergunta = {} as Pergunta;

  public _changeSelectedSectionRef: Secao = {} as Secao;

  /**
   * Variável responsável por realizar o drop das opções de Pulo
   */
  public _answerItemDropDownActive = false;

  /**
   * Propriedade com as alternativas da pergunta escolhida
   * E o objeto da pergunta completa
   */
  @Input() answer: Alternativa = {} as Alternativa;
  @Input() ordemAlternativa: number = 1;
  @Input() fatherAnswer: Pergunta = {} as Pergunta;
  @Input() sectionRef: Secao = {} as Secao;
  @Input() sectionsData: Secao[];
  @Input() retornaReferenciasDaSecaoEDaPerguntaDaAlternativaComPulo: {
    pergunta: Pergunta;
    secao: Secao;
  };

  @Output() removerMarcacaoDaAlternativa = new EventEmitter();
  @Output() adicionarMarcacaoAAlternativa = new EventEmitter();

  //  Variavel criada para filtrar as secoes pelo titulo da pergunta
  inputText: string = "";

  secoesFiltradas: Secao[];

  inputTextChangeHandler(event: any) {
    this.inputText = event.target.value;
    this.secoesFiltradas = [
      ...this.sectionsData
        .filter((section) =>
          section.perguntas.some((pergunta) =>
            pergunta.titulo.toLowerCase().includes(this.inputText.toLowerCase())
          )
        )
        .map((section) => ({
          ...section,
          perguntas: section.perguntas.filter((pergunta) =>
            pergunta.titulo.toLowerCase().includes(this.inputText.toLowerCase())
          ),
        })),
    ];
  }

  handleRemoverMarcacaoDaAlternativa(): void {
    this.removerMarcacaoDaAlternativa.emit();
  }

  handleAdicionarMarcacaoAAlternativa(payload: {
    question: Pergunta;
    section: Secao;
  }): void {

    this._answerItemDropDownActive = false;
    this._changeSelectedSectionRef = payload.section;
    this._changeSelectedQuestionRef = payload.question;

    this.adicionarMarcacaoAAlternativa.emit(
      {
        codigoMarcacao: payload.question.codigoMarcacao,
        comportamento: 'PULAR_PARA_PERGUNTA'
      });
  }

  //Este metodo que adiciona a string FINALIZAR_ENTREVISTA ao comportamento
  handleFinalizarEntrevista(payload: {
    question: Pergunta;
    section: Secao;
  }): void {

    this._answerItemDropDownActive = false;
    this._changeSelectedSectionRef = payload.section;
    this._changeSelectedQuestionRef = payload.question;

    this.adicionarMarcacaoAAlternativa.emit(
      {
        codigoMarcacao: payload.question.codigoMarcacao,
        comportamento: 'FINALIZAR_ENTREVISTA'
      });
  }

  returnSelectedQuestion(): Pergunta {
    return this._changeSelectedQuestionRef;
  }

  ngOnInit() {
    this.secoesFiltradas = [...this.sectionsData];
    this._changeSelectedSectionRef =
      this.retornaReferenciasDaSecaoEDaPerguntaDaAlternativaComPulo.secao;
    this._changeSelectedQuestionRef =
      this.retornaReferenciasDaSecaoEDaPerguntaDaAlternativaComPulo.pergunta;
  }

  /**
   * Função responsável por exibir ou tirar o dropdown de pulo de pergunta
   */
  setAnswerItemDropDownActive(): boolean {
    return (this._answerItemDropDownActive = !this._answerItemDropDownActive);
  }

  formatTipoAlternativa(tipo: TipoAlternativa) {
    return tipo === TipoAlternativa.ABERTA_NUMERO
      ? "Aberta Número"
      : tipo === TipoAlternativa.ABERTA_TEXTO
        ? "Aberta Texto"
        : tipo === TipoAlternativa.ESCALA_NUMERICA
          ? "Escala Numérica"
          : tipo === TipoAlternativa.FECHADA
            ? "Fechada"
            : tipo === TipoAlternativa.GRADE
              ? "Grade"
              : "";
  }
}
