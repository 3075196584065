<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-auditada"></i>
      <h2 [routerLink]="'/auditoria/pesquisas'" routerLinkActive="active">Auditoria</h2>
    </div>
    <div class="action-header sg-input-group">
      <div class="campo">
        <app-pesquisa-select [pesquisaSelecionada]="pesquisaSelecionada" (inputUpdate)="onPesquisaSelect($event)"
          [selecionarPrimeiro]="false" [idPesquisaSelecionada]="idPesquisa"></app-pesquisa-select>
      </div>
    </div>
  </div>
  <div id="options-respostas-auditoria" *ngIf="idPesquisa">

    <mat-horizontal-stepper labelPosition="bottom" #stepper [selectedIndex]="selectedStepIndex">

      <mat-step label="Respostas Abertas" #passoRespostasAbertas *ngIf="renderRespostasAbertas">
        <app-respostas-abertas *ngIf="renderRespostasAbertas && pesquisaSelecionada"
          [pesquisaSelecionada]="pesquisaSelecionada">
        </app-respostas-abertas>
      </mat-step>

      <mat-step label="Respostas Fechadas" #passoRespostasFechadas *ngIf="renderRespostasFechadas">
        <app-respostas-fechadas *ngIf="renderRespostasFechadas && pesquisaSelecionada"
          [pesquisaSelecionada]="pesquisaSelecionada" (paginacaoChange)="atualizarURLPaginacao($event)"
          [pagina]="paginacaoAtual">
        </app-respostas-fechadas>
      </mat-step>

    </mat-horizontal-stepper>
  </div>
</div>
