<div class="pesquisa-questionario-wrapper">
  <header>
    <div class="page-label">
      <div class="questionnaire-icon"></div>
      <h3>Questionário</h3>
    </div>
    <div class="page-buttons">
      <button class="ver-formulario" [disabled]="!verFormularioHabilitado()" (click)="handleShowQuizPreview()">
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 3H19L17.5 5L16 3ZM2 0H20C20.5304 0 21.0391 0.210714 21.4142 0.585786C21.7893 0.960859 22 1.46957 22 2V6C22 7.11 21.11 8 20 8H15V18C15 19.11 14.11 20 13 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 0.9 0.9 0 2 0ZM2 2V6H13V2H2ZM20 6V2H15V6H20ZM2 18H13V8H2V18ZM4 10H11V12H4V10ZM4 14H11V16H4V14Z"
            fill="#A2A1A1" />
        </svg>

        <h6>VER QUESTIONÁRIO</h6>
      </button>

      <!-- <app-icon-button
        title="SALVAR"
        icon="assets/images/cloud.svg"
        [disabled]="isBusy || !verifyRequired()"
        (click)="verifyRequired() ? handleSaveClick() : null"
      >
      </app-icon-button> -->
    </div>
  </header>
  <div class="questionario-bem-vindo-content questionario-content" *ngIf="models.length === 0">
    <div class="content-header">
      <div class="titles">
        <h4 class="titulo-pesquisa-label">Título da pesquisa</h4>
        <h3 class="titulo-pesquisa">
          {{ surveyTitle }}
        </h3>
      </div>
    </div>
    <h1 class="page-title">Novo Questionário</h1>
    <h2 class="page-subtitle">
      Para começar a cadastrar um questionário é necessário cadastrar uma seção
    </h2>
    <button class="nova-secao" (click)="handleAddSectionClick()">
      <div class="icon-wrapper">
        <i class="fa fa-plus"></i>
      </div>
      <h6>Nova seção</h6>
    </button>
  </div>
  <div class="questionario-listagem-content questionario-content" *ngIf="models.length !== 0">
    <div class="content-header">
      <div class="titles">
        <h4 class="titulo-pesquisa-label">Título da avaliação</h4>
        <h3 class="titulo-pesquisa">
          {{ surveyTitle }}
        </h3>
      </div>
      <button class="ordenar-questionario" (click)="handleCanShowModalOrdenacao()" [disabled]="!ordenacaoHabilitada()">
        <i class="fa fa-list-ol"></i>
        <span>Ordenar questionário</span>
      </button>
    </div>
    <!-- listagem de seções -->
    <!-- (handleResetEstadoDeNovoElementoSecao)="resetEstadoDeNovoElementoSecao()" -->
    <secao-listagem [codigoMarcacaoDaPerguntaCopia]="codigoMarcacaoDaPerguntaCopia" [models]="models"
      [getModels]="getModelsForSecaoListagem()" [activeSectionElementData]="activeSectionElementData"
      (onAddSectionElem)="handleAddSectionElemClick($event)" (onEditSectionElem)="handleEditSectionElemClick($event)"
      (onDeleteSectionElem)="handleDeleteSectionElemClick($event)"
      (onDuplicateSectionElem)="handleDuplicateSectionElemClick($event)"
      (onDeleteSection)="handleDeleteSectionClick($event)" (onEditSection)="handleEditSectionClick($event)"
      (onMoveSectionElem)="handleMoveSectionElemClick()"></secao-listagem>
    <!-- adicionar seção -->
    <button class="adicionar-secao" (click)="handleAddSectionClick()">
      <i class="fas fa-plus"></i>
      <h6>Nova Seção</h6>
    </button>
  </div>
</div>
<app-modal-ordenacao-questionario *ngIf="canShowModalOrdenacao" [secao]="getModelsForSecaoListagem()"
  (closeModal)="handleCanShowModalOrdenacao()"
  (saveReOrder)="handleUpdateSectionAndQuestion($event)"></app-modal-ordenacao-questionario>
