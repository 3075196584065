import { Component } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';
import { AbstractPerguntaConteudoMarcacao } from '../../../abstractPerguntaConteudoMarcacao';

@Component({
  selector: 'app-selecao-multipla-ordem-citacao-conteudo-marcacao',
  templateUrl: './selecao-multipla-ordem-citacao-conteudo-marcacao.component.html',
  styleUrls: ['./selecao-multipla-ordem-citacao-conteudo-marcacao.component.scss'],
})

// tslint:disable-next-line: max-line-length
export class SelecaoMultiplaOrdemCitacaoConteudoMarcacaoComponent extends AbstractPerguntaConteudoMarcacao {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
              protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
