<div class="wrapper-input-search">
  <div class="container-input-search">
    <input
      type="text"
      [(ngModel)]="currentValue"
      [ngClass]="showDropdown && 'dropdownMode'"
      [placeholder]="placeholder"
      (input)="handleTextChange($event)"
    />
    <i class="fa-light fa-magnifying-glass"></i>
  </div>
  <div class="input-search-dropdown-container" *ngIf="showDropdown">
    <div
      class="option"
      (click)="handlePickDropdownOption(option)"
      *ngFor="let option of filteredOptions; index as i"
    >
      {{ option.label }}
    </div>
  </div>
</div>
