import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ModalCreadUpdateService } from "./modal-create-update.service";

@Component({
  selector: "app-modal-cread-update",
  templateUrl: "./modal-cread-update.component.html",
  styleUrls: ["./modal-cread-update.component.scss"],
})
export class ModalCreadUpdateComponent implements OnInit, OnDestroy {
  @Output() handleShowModal = new EventEmitter();

  @Input() editMode: boolean = false;

  @Input() fatherLocationId: number = null;
  @Input() childrenLocationId: number = null;
  @Input() childrenListLocationId: number = null;

  constructor(private modalCreateUpdateService: ModalCreadUpdateService) {}

  ngOnInit() {
    if (this.editMode && this.childrenLocationId === null) {
      this.modalCreateUpdateService.mountStructureToEdit(this.fatherLocationId);
    } else if (this.editMode && this.childrenLocationId) {
      this.modalCreateUpdateService.mountStructureToEdit(
        this.fatherLocationId,
        "CHILDREN",
        this.childrenListLocationId,
        this.childrenLocationId
      );
    }
  }

  ngOnDestroy() {
    this.modalCreateUpdateService.setResetModal();
  }

  handleSeeFatherData(): boolean {
    const data = this.modalCreateUpdateService.getLocalidadePai();

    return data ? true : false;
  }

  handleGetLocalidadePai() {
    return this.modalCreateUpdateService.getLocalidadePai();
  }

  handlegetLocalidadesFilhas() {
    return this.modalCreateUpdateService.getLocalidadesFilhas();
  }

  handleCanCreateChildren(): boolean {
    return this.modalCreateUpdateService.getCanCreateChildren();
  }

  handleCanMountMap(): boolean {
    return this.modalCreateUpdateService.getCanUpdateMap();
  }

  // CONTROLE
  handleCanShowForm(): boolean {
    return this.modalCreateUpdateService.getCanShowForm();
  }

  handleGetWhichIdShouldILook(): boolean {
    return this.modalCreateUpdateService.whichIdShouldILook ? true : false;
  }

  handleCanShowManualForm(): boolean {
    return this.modalCreateUpdateService.getCanShowManualForm();
  }

  handleSetCanShowForm(): void {
    this.modalCreateUpdateService.setCanShowForm();
  }

  handleCanShowGoBackButton(): boolean {
    return (
      this.modalCreateUpdateService.getCanShowGoBackButton() ||
      this.handleCanShowManualForm()
    );
  }

  // MOSTRAR O BOTÃO DE ADICIONAR LOCALIDADE
  handleCanShowButton(): boolean {
    return (
      this.modalCreateUpdateService.getCanShowButton() &&
      this.modalCreateUpdateService.getLocalidadePai()
    );
  }

  handleShowChildrenLocationForm(): void {
    this.modalCreateUpdateService.setCanShowForm(true);
  }

  handleCanStretchContainer(): boolean {
    return this.modalCreateUpdateService.getCanStretchContainer();
  }

  //
  handleGetWhoId(): number {
    return this.modalCreateUpdateService.getWhoIsTheId();
  }

  handleGetWhoIsEditing(): string {
    return this.modalCreateUpdateService.getWhoIsEditing();
  }

  // FUNÇÕES DO COMPONENTE
  handleGoBack() {
    this.modalCreateUpdateService.setGoBackButton();
  }

  handleCloseModal() {
    this.handleShowModal.emit();
  }

  handleFinishCreateLocation() {
    this.handleShowModal.emit();
  }
}
