import { Injectable } from '@angular/core';
import { CrudService } from 'app/servico/requestService/crudService';
import { Funcionario } from '../funcionario';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FuncionarioService extends CrudService<Funcionario> {

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/funcionarios`);
  }

  getIdFuncionarioPorIdContaUsuario(id:string):  Observable<any>{
    const requestUrl = `${apiLocation}/funcionarios/buscarporidConta?id=${id}`;

    return this.requestService.get(requestUrl);
  }
  
  getPerfilUsuario(): Observable<any> {
    const requestUrl = `${apiLocation}/funcionarios/buscarPerfil`
    
    return this.requestService.get(requestUrl);
  }

  updatePerfilFuncionario(funcionario: Funcionario): Observable<any>  {
    const requestUrl = `${apiLocation}/funcionarios`
    return this.requestService.put(requestUrl, funcionario);
  }

}
