import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pergunta } from '../../model/pergunta';
import { PesquisaCadastro } from '../../model/pesquisaCadastro';
import { Secao } from '../../model/secao';
import { ItemProtegido } from '../../security/itemProtegido';
import { PesquisaAuthorityService } from '../../security/pesquisaAuthorityService';
import { AlterarTitulos } from '../../store/actions/caracteristicasAction';
import { SetPreparadoParaSalvar } from '../../store/actions/controleGeralAction';
import { ReordenarPerguntaCota, SwitchOrdenacaoCotas } from '../../store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../store/passoCadastro';
import { PassoComponent } from '../passoComponent';
import { TipoConteudo } from '../questionario/componentes/secao/conteudo/tipoConteudo';
import { pesquisaToMarcacoesFormGroup } from './pesquisaToMarcacoesFormGroup';

@Component({
  selector: 'app-marcacoes',
  templateUrl: './marcacoes.component.html',
  styleUrls: ['./marcacoes.component.scss'],
})
export class MarcacoesComponent extends PassoComponent {

  passoCadastro: PassoCadastro = PassoCadastro.MARCACOES;

  /**
   * Parametros que serao utilizados no componente
   * que renderiza o conteudo das secoes/perguntas
   */
  conteudoComponentParams: any = {};

  /**
   * Marcador para exibir ou nao o seletor de adicao
   */
  showQuestionarioSeletorAdicao: boolean = false;
  /**
   * Item que foi selecionado no questionario (secao ou pergunta)
   */
  itemSelecionado: { item: Secao | Pergunta, tipoConteudo: TipoConteudo };

  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl } = {};

  /**
  * Subscriptions que deverão ser encerradas
  * junto com a destruição do componente
  */
  subscriptions: Subscription[] = [];

  /**
   * Marcacao que indica se trata-se de uma edicao
   */
  isEdicao: boolean = false;

  /**
   * Marcador que indica se o usuario autenticado possui permissao
   * de realizar modificacoes nesta etapa.
   */
  modificacaoPermitida = false;

  /**
   * Flag que indica se as modificações estão bloqueadas nesta etapa
   * do cadastro da pesquisa.
   */
  modificacoesBloqueadas = false;

  /**
   * Marcador que indica se a redinificação da ordem das cotas
   * está habilitada.
   */
  ordenacaoCotasHabilitada = false;

  pesquisa: PesquisaCadastro;

  // tslint:disable-next-line: max-line-length
  constructor(private pesquisaAuthorityService: PesquisaAuthorityService, protected store: Store<CadastroPesquisaStoreState>, private changeDetectorRef: ChangeDetectorRef) {
    super(store);
  }

  /**
   * @override
   */
  ngOnInit() {

    super.ngOnInit();

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    const storeObservableSubscription = pesquisaStoreObservable.subscribe((store) => {

      this.pesquisa = store.pesquisa;

      this.isEdicao = store.isEdicao;
      const iniciarPreparacaoParaSalvar = store.dadosGeraisCadastro.prepararParaSalvar;
      const preparado = store.dadosPasso.get(PassoCadastro.MARCACOES).preparadoParaSalvar;
      if (iniciarPreparacaoParaSalvar && !preparado) {
        this.store.dispatch(new SetPreparadoParaSalvar(PassoCadastro.MARCACOES, true));
      }

      this.modificacoesBloqueadas = this.modificacoesEstaoBloqueadas();
    });
    this.subscriptions.push(storeObservableSubscription);

    /**
     * Observando itens selecionados
     */
    const itemSelecionadoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso.get(PassoCadastro.QUESTIONARIO)),
        map((dadosPasso) => {
          this.ordenacaoCotasHabilitada = dadosPasso.ordenandoCotas;
          return dadosPasso.itemSelecionado;
        }),
      )
      .subscribe((itemSelecionado) => {

        // tslint:disable-next-line: max-line-length
        // console.log('[MarcacoesComponent.itemSelecionadoSubscription.subscribe] itemSelecionado: ', itemSelecionado);

        /**
         * Quando a seleção é alterada, o campo
         * para adicionar novos itens deve ser
         * escondido
         */
        if (itemSelecionado.item && this.conteudoComponentParams.source) {
          if (this.conteudoComponentParams.source.hash !== itemSelecionado.item.hash) {
            this.showQuestionarioSeletorAdicao = false;
          }
        }

        this.itemSelecionado = itemSelecionado;
        this.conteudoComponentParams = {
          source: this.itemSelecionado.item,
        };
      });

    /**
     * carregando pesquisa
     */
    const pesquisaSubscription = pesquisaStoreObservable
      .pipe(
        // tslint:disable-next-line: max-line-length
        map(cadastroPesquisaStoreState => cadastroPesquisaStoreState.pesquisa),
      )
      .subscribe((pesquisa) => {
        this.initFormGroup(pesquisa);
      });

    this.subscriptions.push(pesquisaSubscription);
    this.subscriptions.push(itemSelecionadoSubscription);

    this.initFormGroup(new PesquisaCadastro());
  }

  modificacoesEstaoBloqueadas() {
    console.log('[MarcacoesComponent] passos bloqueados: ', this.passosBloqueados);
    const marcacoesEstaBloqueado = this.passosBloqueados.includes(PassoCadastro.MARCACOES);

    console.log(`[MarcacoesComponent] Marcações${marcacoesEstaBloqueado ? ' ' : ' não '}estão bloqueadas.`);

    return this.passosBloqueados.includes(PassoCadastro.MARCACOES);
  }

  /**
   * Inicializa os formGroups do passo de marcação
   */
  initFormGroup(pesquisa: PesquisaCadastro) {

    this.formGroup = pesquisaToMarcacoesFormGroup(pesquisa);
    this.controls = this.formGroup.controls;

    this.changeDetectorRef.detectChanges();
    this.formGroup.disable();

    /**
     * Desabilitando controls caso necessário
     */
    this.verificarPermissaoAlteracao();
    if (this.modificacaoPermitida && !this.passosBloqueados.includes(this.passoCadastro)) {
      this.changeDetectorRef.detectChanges();
      this.formGroup.enable();
    }
  }

  verificarPermissaoAlteracao() {
    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.MARCACOES);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Metodo chamado quando um input de titulo (que é compártilhado)
   * é modificado
   */
  onTituloChange() {
    // tslint:disable-next-line: max-line-length
    this.store.dispatch(new AlterarTitulos(this.controls.titulo.value, this.controls.tituloCurto.value, this.controls.textoInicial.value));
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(abstractFormArray: AbstractControl): AbstractControl[] {

    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }

  /**
   * metodo chamado ao clicar no botao "ordenar perguntas com cotas"
   */
  habilitarOrdenacaoCotas() {
    const switchOrdenacaoCotas = new SwitchOrdenacaoCotas();
    this.store.dispatch(switchOrdenacaoCotas);
  }

  print(any) {
    return JSON.stringify(any.value);
  }

  log(any) {
    console.log('[MarcacoesComponent.log]', any);
    return '';
  }

  getElementosSecaoFlat(formGroup: UntypedFormGroup): AbstractControl[] {
    return this.getControlsFromAbstractFormArray(formGroup.get('elementosQuestionario'))
      .map(elementoQuestionario => elementoQuestionario.get('secao'))
      .map(secao => (<UntypedFormArray>secao.get('elementosSecao')).controls)
      .reduce((reduced, next) => [...reduced, ...next]);
  }

  // função que verifica se uma lista possui perguntas com cotas
  possuiCota(): boolean {

    if (this.formGroup.get('elementosQuestionario').value.length === 0) {
      return false;
    }

    const elementosSecao: AbstractControl[] = this.getElementosSecaoFlat(this.formGroup);

    const perguntasComCota = elementosSecao
      .map(elementoSecao => elementoSecao.get('pergunta').value)
      .filter(pergunta => pergunta.possuiCota);

    if (perguntasComCota.length > 0) {
      return true;
    }

    return false;
  }

  onDropPerguntaCota(evento: CdkDragDrop<any, any>) {
    /**
     * Solicitando a reordenação da pergunta
     */
    // tslint:disable-next-line: max-line-length
    this.store.dispatch(new ReordenarPerguntaCota(evento.previousIndex, evento.currentIndex));
  }

}
