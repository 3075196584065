
function yMdToDmY(dateString: string): string | null {

  if (!dateString || dateString.length !== 10
    || (!dateString.includes('/') && !dateString.includes('-'))) {

    return null;
  }

  const isIso8601 = dateString.includes('-');

  if (!isIso8601) {
    return dateString;
  }

  const datePiecesArray = dateString.split('-');
  // tslint:disable-next-line:max-line-length
  const dMyString = `${datePiecesArray[2]}/${datePiecesArray[1]}/${datePiecesArray[0]}`;

  return dMyString;
}

function dMyToYmD(dateString: string): string | null {
  if (!dateString || dateString.length !== 10
    || (!dateString.includes('/') && !dateString.includes('-'))) {

    return null;
  }

  const isIso8601 = dateString.includes('-');

  if (isIso8601) {
    return dateString;
  }

  const datePiecesArray = dateString.split('/');
  // tslint:disable-next-line:max-line-length
  const yMdString = `${datePiecesArray[2]}-${datePiecesArray[1]}-${datePiecesArray[0]}`;

  return yMdString;
}

const dateFormatter = {
  dMyToYmD,
  yMdToDmY,
};

export { dateFormatter };
