<app-moldura-telas-de-acesso
  [title]="'Bem-vindo de volta!'"
  [subtitle]="'Faça login na sua conta para continuar'"
>
  <form action="" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="form.valid && doLogin(form.value)">
    <div class="title-wrapper">
    </div>
    <h5 *ngIf="showApiFeedback">{{ errorMessage }}</h5>
    <div class="input-wrapper">
      <div [ngClass]="form.submitted && this.formGroup.get('login').errors ? 'login-input error': 'login-input'">
        <input matInput type="text" placeholder="Seu e-mail" formControlName="login" name="login" id="loginInput"
          maxlength="50">
      </div>
      <h6 *ngIf="form.submitted && formGroup.controls.login.errors">Campo obrigatório</h6>
    </div>
    <div class="input-wrapper">
      <div [ngClass]="form.submitted && this.formGroup.get('password').errors ? 'login-input error': 'login-input'">
        <input matInput placeholder="Senha" formControlName="password" name="password" id="passwordInput" maxlength="30"
        type="{{ hide ? 'password' : 'text' }}">
        <i class="fa-sharp fa-light fa-eye eye-icon" *ngIf="!hide" (click)="toggleHidePassword()"></i>
        <i class="fa-sharp fa-light fa-eye-slash eye-icon" *ngIf="hide" (click)="toggleHidePassword()"></i>
      </div>
      <h6 *ngIf="form.submitted && formGroup.controls.password.errors">Campo obrigatório</h6>
    </div>
    <div class="remember">
      <div class="checkbox">
        <div class="checkbox-container" [ngClass]="this.formGroup.get('keepConnected').value && 'active'"
          (click)="handleCheckboxClick()">
          <i class="fas fa-check" *ngIf="this.formGroup.get('keepConnected').value"></i>
        </div>
        <label for="remember">Lembrar de mim</label>
      </div>
      <a [routerLink]="'/recuperar-senha'">Esqueci minha senha</a>
    </div>
    <button type="submit">Entrar</button>
  </form>
</app-moldura-telas-de-acesso>
<app-loading *ngIf="isLoading"></app-loading>
