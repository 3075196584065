<section>
  <app-loading *ngIf="!allRequestIsComplete"></app-loading>
  <div class="disabled-info" *ngIf="actionsBlockedBySituation">
    <i class="fa-regular fa-circle-info"></i>
    <span>Essa pesquisa já foi auditada e não pode mais ser alterada</span>
  </div>
  <article class="questionarie-header" [ngClass]="scrollIsTop() ? '' : 'shadow-effect-top'">
    <ng-container *ngIf="allRequestIsComplete">
      <h4>
        {{ headerModel.pesquisa }}
      </h4>
      <span>
        <h5>Entrevistador</h5>
        <p>{{ headerModel.operador }}</p>
      </span>
      <span>
        <h5>ID da coleta</h5>
        <p>{{ headerModel.id }}</p>
      </span>
      <span>
        <h5>Critérios</h5>
        <div class="badges">
          <small *ngFor="let criterio of headerCriterios; index as i"
            [style.backgroundColor]="criterio['backgroundColor']" [style.color]="criterio['color']">
            {{ criterio["text"] }}
          </small>
        </div>
      </span>
      <span class="actions-btn">
        <button (click)="onRequestShowObservations()">
          <i class="fa-regular fa-message-lines"></i>Observações
        </button>
        <button *ngIf="!isOnlineCollection" (click)="handleShowMap()">
          <i class="fa-regular fa-location-dot"></i>Mapa da coleta
        </button>
      </span>
    </ng-container>
  </article>
  <article class="questionarie-body" [ngClass]="actionsBlockedBySituation ? 'block-info-showed' : 'block-info-hidden'">
    <div class="questions">
      <app-questions [actionsBlockedBySituation]="actionsBlockedBySituation"
        [questionList]="questionList"></app-questions>
    </div>
    <footer [ngClass]="scrollIsBottom() ? '' : 'shadow-effect-bottom'">
      <div class="footer-wrapper">
        <ng-container *ngIf="allRequestIsComplete && auditAudio">
          <app-audit-audio-player [audioToPlay]="auditAudio"></app-audit-audio-player>
        </ng-container>
        <span class="btn-wrapper">
          <button type="button" class="outline-btn" (click)="handleCancel()">Cancelar</button>
          <app-audit-verdict *ngIf="allRequestIsComplete" [surveyId]="surveyId" [collectionId]="collectionId"
            [actionsBlockedBySituation]="actionsBlockedBySituation"
            (updateBlockStatus)="updateBlockStatusAfterSubmit($event)"></app-audit-verdict>
        </span>
      </div>
    </footer>
  </article>
</section>

<app-prompt-modal *ngIf="this.modalData.show" [modalData]="modalData">
</app-prompt-modal>

<app-audit-collection-map *ngIf="showMap" [showMap]="showMap" [collectIdMap]="collectionId" [surveyId]="surveyId"
  (closeModal)="handleCloseModal($event)">
</app-audit-collection-map>

<!-- Modal de observações -->
<app-prompt-modal *ngIf="observationsModal.show" [modalData]="observationsModal">
  <app-modal-observations [surveyId]="surveyId" [collectionId]="collectionId"
    [allowComments]="allowComments"></app-modal-observations>
</app-prompt-modal>