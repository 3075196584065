import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TabelaComponent } from "app/util/componente/tabela/tabela.component";
import { TrBody } from "app/util/componente/tabela/trbody/trBody";
import numberFormatter from "app/util/misc/numberFormatter";
import { Observable, of, ReplaySubject, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { TipoPergunta } from "../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { TipoPergunta as PrettyTipoPergunta } from "../../../pesquisa-old/cadastro/inputs/tipo-pergunta-input/tipoPergunta";
import { EntrevistaService } from "../../service/entrevista.service";
import { RespostaVisualizacaoDialogComponent } from "../resposta-visualizacao-dialog/resposta-visualizacao-dialog.component";
import { TipoPerguntaIconFactoryService } from "../tipoPerguntaIconFactory.service";
import { EntrevistaPerguntas } from "./model/entrevistaPerguntas";
import { RespostaPergunta } from "./model/respostaPergunta";
import { RespostaTrBody } from "./model/respostaTrBody";
import { RespostaDescricaoFactoryService } from "./resposta-descricao-factory.service";

@Component({
  selector: "app-resposta-pergunta-listagem-tabela",
  templateUrl: "./resposta-pergunta-listagem-tabela.component.html",
  styleUrls: ["./resposta-pergunta-listagem-tabela.component.scss"],
})
export class RespostaListagemTabelaComponent extends TabelaComponent {
  @Input() entrevistaService: EntrevistaService;
  @Input() idEntrevista: number;

  /**
   * Modelo que representa o conjunto de respostas perguntas de uma entrevista.
   * Sempre que a resposta de uma pergunta é alterada, este modelo é atualizado.
   */
  private entrevistaPerguntas: EntrevistaPerguntas;

  /**
   * Subject responsável por receber notificações de novas respostasPerguntas.
   * (e.g. quando um usuário edita uma resposta de uma pergunta e passa para a próxima)
   */
  private novaRespostaPerguntaSubject: Subject<RespostaPergunta>;
  private novaRespostaPerguntaSubscription: Subscription;

  /**
   * EventEmitter que notifica componentes pais sobre um novo estado de EntrevistaPerguntas
   */
  @Output()
  entrevistaPerguntasChange: EventEmitter<EntrevistaPerguntas> =
    new EventEmitter();

  tipoPergunta = TipoPergunta;

  constructor(
    private tipoPerguntaIconFactory: TipoPerguntaIconFactoryService,
    private respostaDescricaoFactory: RespostaDescricaoFactoryService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.novaRespostaPerguntaSubject = new ReplaySubject();
    this.novaRespostaPerguntaSubscription =
      this.novaRespostaPerguntaSubject.subscribe((respostaPergunta) =>
        this.onProximaPerguntaCallback(respostaPergunta)
      );
  }

  ngOnDestroy() {
    this.novaRespostaPerguntaSubscription.unsubscribe();
  }

  reRenderEntrevista() {
    const trBodies: TrBody[] = this.entrevistaPerguntas.respostasPerguntas.map(
      (resposta) => {
        const trBody = this.buildTrBody(resposta);
        return trBody;
      }
    );
    this.trBodies = trBodies;
  }

  buildTrBody(resposta: RespostaPergunta): TrBody {
    const trBody = new RespostaTrBody();
    trBody.numero = resposta.pergunta.ordem;
    trBody.selected = false;

    trBody.pergunta = resposta.pergunta.descricao;
    trBody.tipoPergunta = resposta.pergunta.tipo;

    trBody.resposta =
      this.respostaDescricaoFactory.getRespostaDescricao(resposta);
    trBody.respostaPergunta = resposta;
    return trBody;
  }

  /**
   * @override
   * carrega as perguntas em uma lista de trBody
   */
  carregarTrBodies(): Observable<TrBody[]> {
    return this.entrevistaService.buscarEntrevista(this.idEntrevista).pipe(
      map((entrevista: EntrevistaPerguntas) => {
        this.entrevistaPerguntas = entrevista;

        // const respostas = this.mockedRespostas();
        const respostas = this.entrevistaPerguntas.respostasPerguntas;
        this.entrevistaPerguntas.respostasPerguntas = respostas;
        const trBodies: TrBody[] = respostas.map(
          (resposta: RespostaPergunta, index: number) => {
            // resposta.num = index + 1;
            return this.buildTrBody(resposta);
          }
        );
        return trBodies;
      })
    );
  }

  /**
   * Método auxiliar que recupera um ícone baseado no tipo da pergunta passada.
   * Utiliza-se o tipoPerguntaIconFactory para obter o ícone.
   * @param tipoPergunta tipo da pergunta em string.
   */
  getIcon(tipoPergunta: string): string {
    return this.tipoPerguntaIconFactory.getIcon(tipoPergunta);
  }

  /**
   * Método auxiliar para recuperar o tipo da pergunta de forma entendível. "prettified".
   * @param tipoPergunta tipo de pergunta
   */
  getTipoPerguntaPretty(tipoPergunta: string): string {
    return PrettyTipoPergunta[tipoPergunta];
  }

  /**
   * Método auxiliar que insere um 0 antes de números menores que 10.
   * @param num
   */
  getNumberWithZeroBefore(num: number): string {
    return numberFormatter.addZeroBeforeIfLessThan10(num);
  }

  /**
   * @override
   * recupera a quantidade total de resultados baseado nos filtros selecionados
   */
  getTotalResultados() {
    return of(0);
  }

  requestVisualizacaoRespostas(respostaPergunta: RespostaPergunta) {
    const respostaPerguntaIndex =
      this.entrevistaPerguntas.respostasPerguntas.findIndex(
        (element, index, array) => {
          return element.pergunta.id === respostaPergunta.pergunta.id;
        }
      );

    const proximaRespostaPerguntaIndex = respostaPerguntaIndex + 1;

    const ehUltimaPergunta: boolean =
      proximaRespostaPerguntaIndex ===
      this.entrevistaPerguntas.respostasPerguntas.length;

    this.visualizarRespostas(respostaPergunta, ehUltimaPergunta);
  }

  visualizarRespostas(
    respostaPergunta: RespostaPergunta,
    ehUltimaPergunta: boolean = false
  ): void {
    this.dialog
      .open(RespostaVisualizacaoDialogComponent, {
        data: {
          respostaPergunta: respostaPergunta,
          numPergunta: respostaPergunta.num,
          novaRespostaPerguntaSubject: this.novaRespostaPerguntaSubject,
          ehUltimaPergunta: ehUltimaPergunta,
        },
      })
      .afterClosed()
      .subscribe((ev) => {
        this.reRenderEntrevista();
      });
  }

  /**
   * método que atualiza a UI com as novas respostas auditadas.
   * @param respostaPergunta
   */
  atualizarRespostas(respostaPergunta: RespostaPergunta) {
    const idAlternativasMarcadas =
      respostaPergunta.alternativasSelecionadas.map(
        (alternativaSelecionada) => alternativaSelecionada.idAlternativa
      );

    const alternativasMarcadas = respostaPergunta.pergunta.alternativas.filter(
      (alternativa) => idAlternativasMarcadas.includes(alternativa.id)
    );

    const perguntasMarcadasTemMarcacao = alternativasMarcadas.some(
      (alternativa) => !!alternativa.marcacao
    );

    const novasRespostasPerguntas =
      this.entrevistaPerguntas.respostasPerguntas.map((r) =>
        r.pergunta.id === respostaPergunta.pergunta.id ? respostaPergunta : r
      );

    if (perguntasMarcadasTemMarcacao) {
      const idPerguntasDestino = alternativasMarcadas.map(
        (alternativaMarcada) => alternativaMarcada.marcacao.perguntaDestino.id
      );

      const indicePerguntaOrigem =
        this.entrevistaPerguntas.respostasPerguntas.findIndex(
          (resposta) => resposta.num === respostaPergunta.num
        );

      const indicePerguntaDestino = this.entrevistaPerguntas.respostasPerguntas
        .map((resposta, index) =>
          idPerguntasDestino.includes(resposta.pergunta.id) ? index : -1
        )
        .reduce(
          (acumulador, indiceAtual) =>
            acumulador > indiceAtual ? acumulador : indiceAtual,
          -1
        );

      const numeroDasPerguntasPuladas =
        this.entrevistaPerguntas.respostasPerguntas
          .filter(
            (item, index) =>
              index > indicePerguntaOrigem && index < indicePerguntaDestino
          )
          .map((resposta) => resposta.num);

      novasRespostasPerguntas.forEach((item, index) => {
        if (numeroDasPerguntasPuladas.includes(item.num))
          novasRespostasPerguntas[index] = {
            ...item,
            alternativasSelecionadas: null,
          } as RespostaPergunta;
      });
    }

    this.entrevistaPerguntas.respostasPerguntas = novasRespostasPerguntas;

    this.entrevistaPerguntasChange.emit(this.entrevistaPerguntas);

    //this.reRenderEntrevista();
  }

  /**
   * Callback a ser chamado quando o usuário realiza a edição da resposta de uma pergunta e passa para a próxima.
   * @param idPergunta
   */
  onProximaPerguntaCallback(respostaPergunta: RespostaPergunta) {
    this.atualizarRespostas(respostaPergunta);

    const idPergunta = respostaPergunta.pergunta.id;

    const respostaPerguntaIndex =
      this.entrevistaPerguntas.respostasPerguntas.findIndex(
        (element, index, array) => {
          return element.pergunta.id === idPergunta;
        }
      );

    const naoExistePergunta: boolean = respostaPerguntaIndex < 0;

    this.dialog.closeAll();

    if (naoExistePergunta) {
      return;
    }

    const proximaRespostaPerguntaIndex = respostaPerguntaIndex + 1;

    const fecharAoSalvar = true;

    const naoExisteProxima: boolean =
      proximaRespostaPerguntaIndex >=
      this.entrevistaPerguntas.respostasPerguntas.length;

    if (naoExisteProxima || fecharAoSalvar) {
      return;
    }

    const proximaRespostaPergunta =
      this.entrevistaPerguntas.respostasPerguntas[proximaRespostaPerguntaIndex];

    const ehUltimaPergunta: boolean =
      proximaRespostaPerguntaIndex ===
      this.entrevistaPerguntas.respostasPerguntas.length - 1;

    this.visualizarRespostas(proximaRespostaPergunta, ehUltimaPergunta);
  }
}
