/**
 * Representacao dos status possiveis em uma pesquisa
 */
export enum StatusPesquisa {

  RASCUNHO = 'RASCUNHO',
  EXECUCAO = 'EXECUCAO',
  CONCLUIDO = 'CONCLUIDO',

}

export enum StatusPesquisaNomeAmigavel {

  RASCUNHO = 'Rascunho',
  EXECUCAO = 'Execução',
  CONCLUIDO = 'Concluído',

}
