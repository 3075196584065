import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { PerguntaQuestionarioAuditadoManager } from ".";
import { TipoAlternativa } from "app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa";

/**
 * Externaliza uma diversidade de métodos úteis
 * para o gerenciamento/processamento de uma Pergunta/Alternativa
 * no módulo do questionário auditado
 */
export class PerguntaQuestionarioAuditadoUtils {
  constructor(managerContext) {
    this.managerContext = managerContext;
  }

  managerContext: PerguntaQuestionarioAuditadoManager;

  /**
   * Getters gerais
   */
  public getTipoPergunta() {
    return this.managerContext.getDadosPergunta().tipo_pergunta as TipoPergunta;
  }

  public getOrdem() {
    return this.managerContext.getDadosPergunta().ordem;
  }

  public getIdPergunta() {
    return this.managerContext.getDadosPergunta().id;
  }

  public getEnunciado() {
    return this.managerContext.getDadosPergunta().enunciado;
  }

  /**
   * Booleans utéis
   */
  public isRespostaMultipla() {
    return this.getTipoPergunta() === TipoPergunta.MULTIPLA;
  }

  public isRespostaUnica() {
    return this.getTipoPergunta() === TipoPergunta.UNICA;
  }

  public isHorario() {
    return this.getTipoPergunta() === TipoPergunta.HORARIO;
  }

  public isGrade() {
    return this.isGradeUnica() || this.isGradeMultipla();
  }

  public isGradeUnica() {
    return this.getTipoPergunta() === TipoPergunta.GRADE_UNICA;
  }

  public isGradeMultipla() {
    return this.getTipoPergunta() === TipoPergunta.GRADE_MULTIPLA;
  }

  public isEscalaNumerica() {
    return this.getTipoPergunta() === TipoPergunta.ESCALA_NUMERICA;
  }

  public isFoto() {
    return this.getTipoPergunta() === TipoPergunta.FOTO;
  }

  public isVideo() {
    return this.getTipoPergunta() === TipoPergunta.VIDEO;
  }

  public isData() {
    return this.getTipoPergunta() === TipoPergunta.DATA;
  }

  public getAlternativas() {
    return this.managerContext.getDadosPergunta().alternativas;
  }

  public isRespostaAbertaTextoUnica() {
    const alternativas = this.getAlternativas();

    if (alternativas.length === 1) {
      const alternativa = alternativas[0];

      return (
        (alternativa.tipo_alternativa as TipoAlternativa) ===
        TipoAlternativa.ABERTA_TEXTO
      );
    }

    return false;
  }

  public isRespostaAbertaNumeroUnica() {
    const alternativas = this.getAlternativas();

    if (alternativas.length === 1) {
      const alternativa = alternativas[0];

      return (
        (alternativa.tipo_alternativa as TipoAlternativa) ===
        TipoAlternativa.ABERTA_NUMERO
      );
    }

    return false;
  }

  public isPerguntaComAlternativas() {
    return (
      this.isRespostaUnica() ||
      this.isRespostaMultipla() ||
      this.isEscalaNumerica() ||
      this.isGradeUnica() ||
      this.isGradeMultipla()
    );
  }

  public isPerguntaSimplesComAlternativas() {
    return (
      this.isRespostaUnica() ||
      this.isRespostaMultipla() ||
      this.isEscalaNumerica()
    );
  }

  public isPerguntaRespostaUnica() {
    return this.isRespostaUnica() || this.isEscalaNumerica();
  }

  /**
   * Obrigatoriedade
   */
  public isObrigatoria() {
    return this.managerContext.getDadosPergunta().obrigatoria;
  }
}
