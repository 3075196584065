<div>

  <agm-map
    id="localidades-map"
    #agmap
    [zoom]="zoom"
    [disableDefaultUI]="false"
    [zoomControl]="false"
    [latitude]="lat"
    [longitude]="lng">
    <!-- (boundsChange)="changeBoundsToFitMarkers($event)"> -->

    <agm-marker
        *ngFor="let entrevista of entrevistas; let i = index"
        [latitude]="entrevista.coordenadas.latitude"
        [longitude]="entrevista.coordenadas.longitude"
        [markerDraggable]="false">
        
      <!-- 
        [iconUrl]="{ url: m.iconUrl, scaledSize: { width: 40, height: 70 } }"

        Por enquanto não é necessário uma janela aberta ao click no marker,
        mas pode ser que seja necessário posteriormente.
    
    
        
        Duração da entrevista, Data do inicio e fim, Operador
      -->

      <agm-info-window>
        <ul>
          <li><strong>Operador:</strong> {{ entrevista.nomeOperador }} </li>
          <li><strong>Duração da entrevista:</strong> {{entrevista.duracao}} </li>
          <li><strong>Data de início:</strong>  {{entrevista.inicioColeta}} </li>
          <li><strong>Data de fim:</strong> {{entrevista.fimColeta}} </li>
          <li><a routerLink="/auditoria/pesquisas/{{idPesquisa}}/entrevistas/{{entrevista.id}}">VISUALIZAR ENTREVISTA</a></li>
        </ul>
      </agm-info-window> 
      
    </agm-marker>
    
    <!-- <agm-circle 
        *ngFor="let c of circles"
        [latitude]="c.lat" [longitude]="c.lng" 
        [radius]="c.raioMetros"
        [fillColor]="c.cor"
        [circleDraggable]="false"
        [editable]="false">
    </agm-circle> -->

  </agm-map>
</div>