import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Adiantamento } from "./model/adiantamento";

@Component({
  selector: "app-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.scss"],
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {
  @ViewChild("audioPlayer") public audioPlayer: ElementRef;
  public nativeAudioPlayer: any;
  public ultimoTempoRegistrado: number;
  public tempoJaReproduzido: number = 0;
  public canPlay: boolean = false;

  /**
   * Event emitter para notificar aos componentes interessados, as tentativas
   * de adiantamento, caso o adiantamento esteja desabilitado.
   */
  @Output() tentativaAdiantamento: EventEmitter<Adiantamento> =
    new EventEmitter();

  /**
   * Propriedade indicando se o adiantamento deve ser desabilitado ou não.
   */
  @Input() public desabilitarAdiantamento: boolean = true;

  /**
   * Conteúdo a ser reproduzido pelo player
   */
  @Input() audioParaReproduzir: string;

  ngOnInit() {}

  ngAfterViewInit() {
    this.nativeAudioPlayer = this.audioPlayer.nativeElement;

    this.nativeAudioPlayer.addEventListener("timeupdate", () =>
      this.onTimeUpdate()
    );

    if (this.desabilitarAdiantamento) {
      this.nativeAudioPlayer.addEventListener("seeking", () =>
        this.onSeeking()
      );
    }
  }

  /**
   * Verifica se o audio pode ser reproduzido
   *
   * Caso o audio seja válido, altera a flag para true impedindo a renderização
   * da tooltip que informa a indisponibilidade do audio
   */
  verifyCanPlay(): void {
    this.canPlay = true;
  }

  /**
   * Callback executado sempre que o tempo de reprodução do player muda.
   * Caso o player não esteja sendo adiantado/retrocedido (a.k.a. seeking),
   * É mantido estado do tempo de reprodução antes da alteração manual do progresso
   * acontecer (seeking), De maneira que através dele, possa ser verificado
   * se o usuário tentou adiantar o player. Além disso, é sempre mantido estado do
   * tempo total já reproduzido pelo player para permitir que o usuário possa adiantar
   * somente até onde ele já reproduziu.
   */
  onTimeUpdate() {
    if (!this.nativeAudioPlayer.seeking) {
      this.ultimoTempoRegistrado = Number(this.nativeAudioPlayer.currentTime);

      if (this.tempoJaReproduzido < this.ultimoTempoRegistrado) {
        this.tempoJaReproduzido = this.ultimoTempoRegistrado;
      }
    }
  }

  /**
   * Callback executado sempre que o usuário tenta alterar o tempo de reprodução do player
   * através da barra de progresso.
   *
   * Este método verifica se o usuário está tentando adiantar o tempo de reprodução e se
   * o tempo no qual ele tentou adiantar é maior do que o tempo total já reproduzido.
   * Caso as duas condições sejam verdadeiras, o adiantamento é impedido e um evento
   * de Adiantamento é emitido para os callbacks registrados no EventEmitter "tentativaAdiantamento".
   */
  onSeeking() {
    const currentTime: number = Number(this.nativeAudioPlayer.currentTime);
    const diferencaAdiantamento: number =
      currentTime - this.ultimoTempoRegistrado;

    if (diferencaAdiantamento > 0.01 && currentTime > this.tempoJaReproduzido) {
      console.log("[AudioPlayerComponent.seeking] Seeking is disabled.");
      this.nativeAudioPlayer.currentTime = this.ultimoTempoRegistrado;

      this.tentativaAdiantamento.next({
        tempoMaximoReproduzido: this.tempoJaReproduzido,
        tempoAdiantamento: currentTime,
        tempoAtual: this.ultimoTempoRegistrado,
      });
    }
  }
}
