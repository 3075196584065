import { CdkDrag, CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component, EventEmitter,
  HostListener, Input, OnChanges, OnInit, Output, SimpleChanges
} from "@angular/core";
import { ModalService } from "app/componentes/modal/modal.service";
import { TipoPergunta } from "app/modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { ActiveSectionElementData } from "../../pesquisas-questionario.component";

// criando modelo de dados local para remover referências circulares
export interface Section {
  id: number;
  ordem: number;
  hash: string;
  nome: string;
  descricao: string;
  elementosSecao: SectionElem[];
}

// criando modelo de dados local para remover referências circulares
export interface SectionElem {
  id: number;
  seq: number;
  nome: string;
  descricao: string;
  ordem: number;
  pergunta: {
    obrigatoria: boolean;
    codigoMarcacao: string;
    nome: string;
    tipo: any;
    alternativas?: SectionElemItem[];
    faixaPergunta?: {
      intervaloInicio: number;
      intervaloFim: number;
    };
    titulosGrade?: TitulosGradeItem[];
  };
}

export interface TitulosGradeItem {
  id: number;
  ordem: number;
  descricao: string;
}

export interface SectionElemItem {
  id: number;
  ordem: number;
  descricao: string;
}

// Tipagem de Código Marcação
export interface SectionElementItemOrdenacao extends SectionElemItem {
  comportamento: string;
  marcacao: {
    id: number;
    perguntaDestino: {
      id: number;
      codigoMarcacao: string;
    };
  };
}

export interface QuestionVinculo {
  id: number;
  perguntaDestino: {
    id: number;
    codigoMarcacao: string;
  };
}

export interface SectionElementOrdenacao {
  id: number;
  seq: number;
  nome: string;
  descricao: string;
  ordem: number;
  pergunta: {
    comportamento: string;
    marcacaoPergunta: QuestionVinculo;
    codigoMarcacao: string;
    nome: string;
    tipo: any;
    alternativas?: SectionElementItemOrdenacao[];
    faixaPergunta?: {
      intervaloInicio: number;
      intervaloFim: number;
    };
    titulosGrade?: TitulosGradeItem[];
  };
}

export interface SectionOrdenacao {
  id: number;
  ordem: number;
  hash: string;
  nome: string;
  descricao: string;
  elementosSecao: SectionElementOrdenacao[];
}

@Component({
  selector: "secao-listagem",
  templateUrl: "./secao-listagem.component.html",
  styleUrls: ["./secao-listagem.component.scss"],
})
export class ListagemDeSecoesComponent implements OnChanges, OnInit {
  @Input() activeSectionElementData: ActiveSectionElementData;
  //  Recebe o codigo da marcacao da pergunta resultante da operação de cópia
  @Input() codigoMarcacaoDaPerguntaCopia: string;
  // Section
  @Input() models: Section[];
  @Input() getModels: Section[];
  //  Evento de clique do checkbox do item da listagem de pesquisa
  @Output() onDeleteSection: EventEmitter<Section> = new EventEmitter();
  //  Evento de clique do checkbox do item da listagem de pesquisa
  @Output() onEditSection: EventEmitter<Section> = new EventEmitter();
  //  Evento de clique do botao de criar nova pergunta
  @Output() onAddSectionElem: EventEmitter<number> = new EventEmitter();
  //  Evento de clique de deletar elementoSecao
  @Output() onDeleteSectionElem: EventEmitter<{
    sectionId: number;
    sectionElemOrdem: number;
  }> = new EventEmitter();
  //  Evento de clique para duplicar elementoSecao
  @Output() onDuplicateSectionElem: EventEmitter<{
    sectionId: number;
    sectionElemOrdem: number;
  }> = new EventEmitter();
  //  Evento de clique do botão para criar nova pergunta
  @Output() onEditSectionElem: EventEmitter<{
    sectionId: number;
    sectionElemOrdem: number;
  }> = new EventEmitter();
  //  Evento de onMoveSectionElem
  @Output() onMoveSectionElem: EventEmitter<void> = new EventEmitter();
  //  Emissor de evento para resetar estado de novo elemento criado para undefined
  @Output() handleResetEstadoDeNovoElementoSecao = new EventEmitter();

  codigoMarcacaoDaPerguntaCopiada: string;

  //  Estado para armazenar as perguntas com alternativas expandidas na tela de listagem
  elementosSecaoComAlternativasExpandidas: SectionElem[] = [] as SectionElem[];
  // Estado para verificar se está ocorrendo evento de drag e drop
  algumElementoEstaSendoArrastado: boolean = false;

  //  Estado para armazenar as secoes que tem suas perguntas expandidas
  secoesComPerguntasExpandidas: Section[] = [] as Section[];

  payloadInputData: Section[];

  // Propriedade Criada devido a mudanças de ordenação
  ordemNewQuestion: number = 0;

  constructor(
    private modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.activeSectionElementData &&
      changes.activeSectionElementData
    ) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    this.payloadInputData = this.models;

    if (!!this.activeSectionElementData.sectionElementOrdem) {
      const sectionRef = this.models.find(
        (section) => section.id === this.activeSectionElementData.sectionId
      );

      if (sectionRef) {
        const sectionElementRef =
          sectionRef.elementosSecao[sectionRef.elementosSecao.length - 1];

        this.ordemNewQuestion = sectionElementRef.ordem;

        if (
          this.secoesComPerguntasExpandidas.findIndex(
            (secao) => secao.id === this.activeSectionElementData.sectionId
          ) === -1
        )
          this.secoesComPerguntasExpandidas = [
            ...this.secoesComPerguntasExpandidas,
            sectionRef,
          ];

        if (!this.perguntaIsSimple(sectionElementRef.pergunta.tipo))
          this.elementosSecaoComAlternativasExpandidas = [
            ...this.elementosSecaoComAlternativasExpandidas,
            sectionElementRef,
          ];
        setTimeout(() => {
          const element = document.getElementById(
            `pergunta-${sectionElementRef.ordem}`
          );
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }, 300);
      }
    }
  }

  perguntaTemMarcacao(pergunta: any): boolean {
    return (
      !!pergunta.marcacaoPergunta ||
      (pergunta.alternativas &&
        pergunta.alternativas.some((alternativa) => !!alternativa.marcacao))
    );
  }

  // Função que formata tipo de pergunta para o placeholder do select
  formatTipo(tipo: TipoPergunta): string {
    return tipo === TipoPergunta.DATA
      ? "Data"
      : tipo === TipoPergunta.ESCALA_NUMERICA
        ? "Escala Numérica"
        : tipo === TipoPergunta.FOTO
          ? "Foto"
          : tipo === TipoPergunta.GRADE_MULTIPLA
            ? "Grade Múltipla"
            : tipo === TipoPergunta.GRADE_UNICA
              ? "Grade Única"
              : tipo === TipoPergunta.HORARIO
                ? "Horário"
                : tipo === TipoPergunta.MULTIPLA
                  ? "Resposta Múltipla"
                  : tipo === TipoPergunta.UNICA
                    ? "Resposta Única"
                    : "Vídeo";
  }

  //  Função para retornar se esta secao tem perguntas expandidas na listagem
  estaSecaoTemPerguntasExpandidas(secao: Section) {
    if (this.secoesComPerguntasExpandidas) {
      return this.secoesComPerguntasExpandidas.some(
        (secaoExpandida) =>
          secaoExpandida.descricao === secao.descricao &&
          secaoExpandida.nome === secao.nome
      );
    }
    return [];
  }

  //  Função que retorna a informação se as perguntas tem alternativas visiveis na tela de listagem de perguntas
  esteElementoSecaoTemAlternativasExpandidas(elementoSecao: SectionElem) {
    return this.elementosSecaoComAlternativasExpandidas.some(
      (elementoSecaoExpandido) =>
        elementoSecaoExpandido.pergunta.nome === elementoSecao.pergunta.nome &&
        elementoSecaoExpandido.pergunta.tipo === elementoSecao.pergunta.tipo
    );
  }

  //  Função que retorna a informação se a pergunta contem apenas os atributos nome, descricao, tipo e obrigatoria
  perguntaIsSimple(tipo: TipoPergunta): boolean {
    return [
      TipoPergunta.DATA,
      TipoPergunta.FOTO,
      TipoPergunta.HORARIO,
      TipoPergunta.VIDEO,
    ].includes(tipo);
  }

  esteTipoDePerguntaExpande(tipo: TipoPergunta): boolean {
    return [
      TipoPergunta.GRADE_MULTIPLA,
      TipoPergunta.GRADE_UNICA,
      TipoPergunta.ESCALA_NUMERICA,
      TipoPergunta.UNICA,
      TipoPergunta.MULTIPLA,
    ].includes(tipo);
  }

  perguntaIsEscalaNumerica(tipo: TipoPergunta): boolean {
    return TipoPergunta.ESCALA_NUMERICA === tipo;
  }

  // Função que retorna se a pergunta é do tipo grade
  isItGrade(tipo: TipoPergunta): boolean {
    return [TipoPergunta.GRADE_UNICA, TipoPergunta.GRADE_MULTIPLA].includes(
      tipo
    );
  }

  //  Função que retorna a informação se a pergunta tem alternativas
  perguntaTemAlternativas(tipo: TipoPergunta): boolean {
    return [TipoPergunta.UNICA, TipoPergunta.MULTIPLA].includes(tipo);
  }

  //  Função que retorna a informação se a pergunta é do tipo escala numérica
  isItEscalaNumerica(tipo: TipoPergunta): boolean {
    return TipoPergunta.ESCALA_NUMERICA === tipo;
  }

  // verifica se há elementos na seção
  verifySectionElments(secao: Section): boolean {
    if (!secao || !secao.elementosSecao || secao.elementosSecao.length === 0) {
      return false;
    }
    return true;
  }

  // Função que executa ao pegar um elemento draggable da lista de seções
  drag(event: CdkDrag<string[]>, secao: Section) {
    if (this.estaSecaoTemPerguntasExpandidas(secao))
      this.handleExpandButtonClick(secao);
    if (event) {
      this.algumElementoEstaSendoArrastado = true;
      this.secoesComPerguntasExpandidas = [];
    }
    this.onMoveSectionElem.emit();
  }

  // Função executada ao largar uma seção na lista de seções
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    this.algumElementoEstaSendoArrastado = false;
    this.onMoveSectionElem.emit();
  }

  //  Handler do botão de expandir perguntas da seção
  handleExpandButtonClick(secao: Section) {
    if (
      this.secoesComPerguntasExpandidas &&
      this.secoesComPerguntasExpandidas.some(
        (secaoExpandida) =>
          secaoExpandida.descricao === secao.descricao &&
          secaoExpandida.nome === secao.nome
      )
    ) {
      this.secoesComPerguntasExpandidas =
        this.secoesComPerguntasExpandidas.filter(
          (secaoExpandida) =>
            secaoExpandida.descricao !== secao.descricao ||
            secaoExpandida.nome !== secao.nome
        );
    } else {
      this.secoesComPerguntasExpandidas = [
        ...this.secoesComPerguntasExpandidas,
        secao,
      ];
    }
  }

  //  Função para alterar o estado de visibilidade de perguntas na listagem de secoes
  handleExpandAlternativesButtonClick(elementoSecao: SectionElem) {
    if (
      this.elementosSecaoComAlternativasExpandidas.some(
        (elementoSecaoExpandido) =>
          elementoSecaoExpandido.pergunta.nome ===
          elementoSecao.pergunta.nome &&
          elementoSecaoExpandido.pergunta.tipo === elementoSecao.pergunta.tipo
      )
    ) {
      this.elementosSecaoComAlternativasExpandidas =
        this.elementosSecaoComAlternativasExpandidas.filter(
          (elementoSecaoExpandido) =>
            elementoSecaoExpandido.pergunta.nome !==
            elementoSecao.pergunta.nome ||
            elementoSecaoExpandido.pergunta.tipo !== elementoSecao.pergunta.tipo
        );
    } else {
      this.elementosSecaoComAlternativasExpandidas = [
        ...this.elementosSecaoComAlternativasExpandidas,
        elementoSecao,
      ];
    }
  }

  //  Função que cria nova pergunta
  handleAddSectionElem(sectionId: number) {
    this.onAddSectionElem.emit(sectionId);
  }

  //  Função que exibe formulário de edição de pergunta do questionário
  handleEditSectionElem(sectionId: number, sectionElemOrdem: number) {
    this.onEditSectionElem.emit({ sectionId, sectionElemOrdem });
  }

  //  Função que exclui elementoSecao
  handleDeleteSectionElem(
    sectionId: number,
    sectionElemOrdem: number,
    sectionItemName: string
  ) {
    this.modalService.showModal({
      title: 'Excluir Pergunta',
      messageModal: `Ao excluir a pergunta <b>${sectionItemName}</b> você perderá todas as alternativas referente ao seu cadastro`,
      btnTitlePositive: 'Excluir',
      btnTitleNegative: 'Cancelar',
      close: true,
      imgFile: 'icons/icon-warning.svg',
      positiveCallback: () => this.onDeleteSectionElem.emit({ sectionId, sectionElemOrdem })
    })
  }

  idElementoSecaoResultanteDaAcaoDeCopia: number;

  //  Função que duplica elementoSecao
  handleDuplicateSectionElem(
    sectionId: number,
    sectionElemOrdem: number,
    elementoSecao: SectionElem
  ) {
    this.modalService.showModal({
      title: 'Duplicar Pergunta',
      messageModal: 'Ao realizar está ação será gerada uma cópia desta pergunta, que poderá ser editada em seguida',
      btnTitlePositive: 'Duplicar',
      btnTitleNegative: 'Cancelar',
      close: true,
      imgFile: 'icons/icon-duplicar-pergunta.svg',
      positiveCallback: () => {
        this.onDuplicateSectionElem.emit({ sectionId, sectionElemOrdem });
        if (
          !this.elementosSecaoComAlternativasExpandidas.some(
            (elementoSecaoExpandido) => elementoSecaoExpandido.id === sectionId
          ) &&
          this.esteTipoDePerguntaExpande(elementoSecao.pergunta.tipo)
        )
          this.handleExpandAlternativesButtonClick(elementoSecao);

        setTimeout(() => {
          const elementoSecaoCopia = this.models
            .find((elementoQuestionario) => elementoQuestionario.id === sectionId)
            .elementosSecao.find(
              (elementoSecao) =>
                elementoSecao.pergunta.codigoMarcacao ===
                this.codigoMarcacaoDaPerguntaCopia
            );
          if (elementoSecaoCopia) {
            this.idElementoSecaoResultanteDaAcaoDeCopia = elementoSecaoCopia.id;
          }
        }, 600);
      }
    })
  }

  //  Função de deletar secao
  handleDeleteSection(secao: Section) {
    this.onDeleteSection.emit(secao);
  }

  // propaga o evento de editar seção
  handleEditSection(section: Section) {
    this.onEditSection.emit(section);
  }

  // função que lida com o retorno de classe para o dropdown
  handleClassStringFormat(elementoSecao: SectionElem): string {
    const hasAlternatives =
      this.esteElementoSecaoTemAlternativasExpandidas(elementoSecao);
    const isExpansive = this.esteTipoDePerguntaExpande(
      elementoSecao.pergunta.tipo
    );

    if (hasAlternatives) {
      return "fas fa-chevron-down";
    } else {
      if (isExpansive) {
        return "fas fa-chevron-right";
      } else {
        return "fas fa-chevron-right disabled-chevron";
      }
    }
  }

  // Para remover destaque da pergunta recem
  // criada/editada/duplicada ao clicar em qualque rcoisa na tela de listagem
  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.activeSectionElementData.sectionElementOrdem) {
      this.activeSectionElementData = {} as ActiveSectionElementData;
      this.ordemNewQuestion = 0;
    }
  }
}
