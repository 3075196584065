<div class="card-container">
  <header>
    <a id="card-title">
      <p>
        {{ title }}
      </p>
      <span></span>
    </a>

    <a [routerLink]="link" id="card-action-button" *ngIf="showAddButton">
      <i class="fa-regular fa-plus plus-icon"></i>
      <p>Novo</p>
    </a>
  </header>
  <main>
    <div class="first-column">
      <h1>{{ firstColumnData }}</h1>
      <p>{{ firstColumnTitle }}</p>
    </div>
    <div class="activated-column">
      <h1>{{ activatedColumnData }}</h1>
      <p>{{ activatedColumnTitle }}</p>
    </div>
    <div class="inactivated-column">
      <h1>{{ inactivatedColumnData }}</h1>
      <p>{{ inactivatedColumnTitle }}</p>
    </div>
  </main>
</div>
