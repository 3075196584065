import { TrBody } from 'app/util/componente/tabela/trbody/trBody';

export class EntrevistaEmDesconformidadeTrBody extends TrBody {

  tempoDuracao: string;
  idOperador: number;
  nomeOperador: string;
  idLocalidade: number;
  nomeLocalidade: string;
}
