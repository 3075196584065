import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Alternativa } from '../../../../../../../../pesquisa-old/cadastro/model/alternativa';
import { AbstractPerguntaComponent } from '../abstractPerguntaComponent';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

export abstract class SelecaoComponent extends AbstractPerguntaComponent {

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

  getAlternativaById(alternativaHash: string): { index: number, control: UntypedFormGroup } {

    const resultadoBusca = (<UntypedFormArray>this.controls.alternativas).controls
      .filter(alternativaControl => alternativaControl.value.hash === alternativaHash)
      .map((control: UntypedFormGroup, index) => ({
        index,
        control,
      }))[0];

    return resultadoBusca;
  }

  addAlternativa() {
    const alternativa = Alternativa.getControl();
    const alternativasFormArray = <UntypedFormArray>this.controls.alternativas;
    alternativasFormArray.push(alternativa);
  }

}
