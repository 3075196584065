import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import * as moment from "moment/moment";
import { UsedByRef } from "../calendar.component";

@Component({
  selector: "app-calendar-dates",
  templateUrl: "./calendar-dates.component.html",
  styleUrls: ["./calendar-dates.component.scss"],
})
export class CalendarDatesComponent implements OnInit {
  calendar: [] = [];
  weekDays: string[] = ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"];
  calendarValue: moment.Moment = moment().subtract(1, "day");
  monthName: string = "asdadas";

  flagLoading: boolean = false;
  limiteCallDatesLength: number = 0;

  @Input() usedBy: UsedByRef = "PESQUISA";

  @Input()
  initialDate: number;
  @Input()
  endDate: number;
  @Input()
  dates: number[] = [];

  @Output()
  output: EventEmitter<number> = new EventEmitter();

  ngOnInit(): void {
    moment.locale("pt-BR");
    this.calendar = this.buildCalendar(this.calendarValue) as [];
    this.currMonthName();
  }

  ngOnChanges({ dates }: SimpleChanges): void {
    this.calendarValue = moment(dates.currentValue[0]).subtract(1, "day");
    this.ngOnInit();
  }

  buildCalendar(v) {
    // clone do momento para configurar o inicio do mes
    const startDay = v.clone().startOf("month").startOf("week");
    // clone do momento para configurar o fim do mes
    const endDay = v.clone().endOf("month").endOf("week");
    // clone de startDay e subtraímos 1 dia que possamos mostrar o dia anterior ao inicio do mês
    const day = startDay.clone().subtract(1, "day");
    // definição do calendário
    const calendarDate = [];
    // enquanto não tiver chegado o fim do mês adicione mais 1 dia
    while (calendarDate.length < 6) {
      calendarDate.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone())
      );
    }
    return calendarDate;
  }

  prevMonth() {
    this.calendarValue = this.calendarValue.clone().subtract(1, "month");
    this.ngOnInit();
  }

  nextMonth() {
    this.calendarValue = this.calendarValue.clone().add(1, "month");
    this.ngOnInit();
  }

  currentMonth() {
    return this.calendarValue.isSame(new Date(), "month");
  }

  currMonthName() {
    this.monthName = this.calendarValue.locale("pt-br").format("MMMM YYYY");
  }

  isBetween(date: moment.Moment): boolean {
    const newDate = date.valueOf();
    if (this.initialDate && this.endDate) {
      if (newDate > this.initialDate && newDate < this.endDate) {
        return true;
      }
    }
    return false;
  }

  background(date: moment.Moment): string {
    const newDate = date.valueOf();
    if (this.dates.find((obj) => obj === newDate)) {
      if (this.initialDate === newDate || this.endDate === newDate) {
        return this.usedBy === "PESQUISA" ? "var(--primary)" : "var(--gray400)";
      }
      return this.usedBy === "PESQUISA" ? "var(--tertiary)" : "var(--gray150)";
    }

    return "none";
  }

  textColor(date: moment.Moment): string {
    const newDate = date.valueOf();
    if (this.dates.find((obj) => obj === newDate)) {
      if (this.initialDate === newDate || this.endDate === newDate) {
        return "var(--white)";
      } else if (date.isBetween(this.initialDate, this.endDate)) {
        return "var(--gray400)";
      }
    }
    if (date.isSameOrAfter(moment(), "day")) {
      return "var(--gray400)";
    }
    return "var(--gray200)";
  }

  fontWeight(date: moment.Moment): string {
    const newDate = date.valueOf();
    if (this.dates.find((obj) => obj === newDate)) {
      if (
        this.initialDate === newDate ||
        this.endDate === newDate ||
        date.isBetween(this.initialDate, this.endDate)
      ) {
        return "700";
      }
    }
    if (date.isBefore(moment(), "day")) {
      return "400";
    }
    if (date.isSame(moment(), "month")) {
      return "700";
    }
    return "normal";
  }

  isInitialDateOrEndDate(date: Date): string {
    const newDate = date.valueOf();
    if (newDate === this.initialDate && this.initialDate === this.endDate) {
      return "var(--white)";
    }
    if (newDate === this.initialDate) {
      return this.usedBy === "PESQUISA"
        ? "linear-gradient(to right, transparent 0%, transparent 50%, var(--tertiary) 51%, var(--tertiary) 100%)"
        : "linear-gradient(to right, transparent 0%, transparent 50%, var(--gray150) 51%, var(--gray150) 100%)";
    }
    if (newDate === this.endDate) {
      return this.usedBy === "PESQUISA"
        ? "linear-gradient(to left, transparent 0%, transparent 50%, var(--tertiary) 51%, var(--tertiary) 100%)"
        : "linear-gradient(to left, transparent 0%, transparent 50%, var(--gray150) 51%, var(--gray150) 100%)";
    }

    return this.background(moment(newDate));
  }

  handleDayClick(date: moment.Moment) {
    if (this.usedBy !== "INFO") {
      this.output.emit(date.valueOf());
    }
  }
}
