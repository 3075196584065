import { Component, HostListener, OnInit } from "@angular/core";
import { InfoCardDataProps } from "app/componentes/info-card/info-card.component";
import { OperadorService } from "../services/operador-beta.service";
import { ActionButtonConfig } from "app/util/componente/genericTable/interfaces/actionButton";
import { FilterConfigs, SearchInputConfig } from "app/util/componente/genericTable/interfaces/filters";
import { FilterIcon, FilterTypes } from "app/componentes/filter-select/constants";
import { Router } from "@angular/router";
import { IFilterSelect } from "app/componentes/filter-select/filter-select";
import { TableEvents, TableGlobalConfig } from "app/util/componente/genericTable/interfaces/table";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import { MeatballConfig } from "app/util/componente/genericTable/interfaces/meatball";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import { checkboxCellFactory, componentCellFactory, meatballCellFactory, statusCellFactory, textCellFactory } from "app/util/componente/genericTable/utils/cellFactory";
import { formatToMaxDecimalPlaces } from 'app/util//formatter'
import { getFirstAndLastDate } from "../utils";
import dates from "app/util/misc/dates";
import { CustomTagComponent } from "app/util/componente/genericTable/components/customCell/custom-tag/custom-tag.component";
import { IFilterOptions, IOperator, IPagination, ISwitchStatus } from "../interfaces";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { OperatorNavigateService } from "../services/operator-navigate.service";
import { GenericTableService } from "app/util/componente/genericTable/service/generic-table.service";
import { ModalService } from "app/componentes/modal/modal.service";
import { IVerifyLoginCredentials } from "app/infraestrutura/security/service/loginCredentials";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { NotificatorService } from "app/notificador/notificator.service";
import { MEATBALL_OPTIONS } from "../interfaces/meatball";
import { Authority } from "app/infraestrutura/security/authority";
import { OperatorStatusHeaderIndex } from "../constants";

@Component({
  selector: "app-listagem-operadores-beta",
  templateUrl: "./listagem-operadores.component.html",
  styleUrls: ["./listagem-operadores.component.scss"],
})
export class OperadorListagemBetaComponent implements OnInit {

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "Início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Entrevistadores",
      itemLink: `/operadores-beta`,
      active: true,
    },
  ];

  actionButton: ActionButtonConfig = {
    label: "Novo entrevistador",
    icon: "fa-light fa-plus",
  };

  filters: FilterConfigs[];
  filtersFlag: boolean = false;

  searchConfig: SearchInputConfig = {
    delay: 2000,
    placeholder: "Busque por nome ou matrícula de entrevistador",
  };

  filterOptions: IFilterOptions = {
    sorting: { direction: null, sort: null },
    status: null,
    in_field: null,
    keywords: [""],
    date: {periodo_inicial: null, periodo_final: null}
  };

  pagination: IPagination = {
    totalOfEntries: 1,
    current: 1,
    previous: 1,
  };

  appInfoData: InfoCardDataProps;
  operadores: IOperator[] = [];
  isLoading: boolean = false;
  tableData: TableRow[] = [];
  rowCustomStyleId: number = null;
  checkboxModalState: boolean;

  tableHead: TableHead[] = [
    {
      type: "checkbox",
      checkable: true,
      customStyles: {
        color: 'var(--gray200)'
      }
    },
    {
      type: "text",
      label: "Matrícula",
    },
    {
      type: "text",
      label: "Entrevistador",
    },
    {
      type: "text",
      label: "Desempenho",
      alignTypes: "center",
      className: (cell) => this.perfomanceStyle(cell.textValue),
    },
    {
      type: "text",
      label: "Período de vínculo",
    },
    {
      type: "text",
      label: "Situação",
    },
    {
      type: "text",
      label: "",
    },
  ];

  tableConfig: TableGlobalConfig = {
    colWidths: [".02", ".1", ".25", ".2", ".25", ".15", ".04"],
    rowStyles: { "font-size": "14px" },
  };

  credentials: IVerifyLoginCredentials = {
    login: this.securityService.getAuthenticatedUser().login,
    password: ''
  }

  meatballConfig: MeatballConfig = {
    type: "GENERIC",
    options: [
      { id: MEATBALL_OPTIONS.EDIT_OPTION_ID, icon: 'fa-regular fa-pen-to-square', label: "Editar" },
      { id: MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID, icon: (rowRef) => rowRef.metadata.ativo ? 'fa-toggle-large-on fa-regular' : 'fa-toggle-large-off fa-regular', label: (rowRef) => rowRef.metadata.ativo ? "Inativar" : "Ativar" },
    ],
  };

  showActionButton: boolean = false;

  constructor(
    private router: Router,
    private operadorService: OperadorService,
    private genericTableService: GenericTableService,
    private operatorNavigateService: OperatorNavigateService,
    private modalService: ModalService,
    private securityService: SecurityService,
    private notificator: NotificatorService
  ) {}

  ngOnInit(): void {
    this.getInfoCardData();
    this.getOperatorData();

    this.modalService.getCheckbox().subscribe({
      next: (currValue) => (this.checkboxModalState = currValue),
    });

    this.modalService.getInput().subscribe({
      next: (inputValue) => {
        this.credentials.password = inputValue

        if(inputValue !== null && !inputValue.length) {
          this.modalService.handleInputErrors(['Senha obrigatória']);
        } else {
          this.modalService.cleanInputErrors();
        }
      }
    })

    this.initAuthorityConfigs();
  }

  /**
   * Configura as opções visiveis e acessíveis para o usuário com base em suas permissões
   */
  initAuthorityConfigs() {
    const authorities = this.securityService.getAuthenticatedUserAuthorities();

    this.showActionButton = authorities.includes(Authority.CADASTRAR_OPERADOR);

    const canEditUser = authorities.includes(Authority.ATUALIZAR_OPERADOR);

    this.meatballConfig.disabledItemsIds = [
      ...(!canEditUser ? [MEATBALL_OPTIONS.EDIT_OPTION_ID, MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID] : []),
    ];
  }

  setFocusOnNewOperator() {
    this.rowCustomStyleId = 66;
    const operatorId = this.operatorNavigateService.getOperatorId()
    if(operatorId !== null) {
      setTimeout(() => {
        this.genericTableService.insertStyles({
          type: "ROW",
          rowId: operatorId,
          styles: {
            border: '1px solid var(--secondary)'
          },
          styleId: this.rowCustomStyleId
        })
      }, 150)
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick() {
    if(this.rowCustomStyleId !== null) {
      this.genericTableService.clearStyles({
        type: "ROW",
        styleId: this.rowCustomStyleId
      });
      this.rowCustomStyleId = null;
    }
  }

  /**
   * Recupera a listagem de operadores
   */
  getOperatorData() {
    const { keywords, status, sorting, date, in_field } = this.filterOptions;
    this.getTotal();
    this.isLoading = true;
    this.operadorService.getOperatorList(keywords, status, sorting, this.pagination.current - 1, date, in_field).subscribe({
      next: (resp: IOperator[]) => {
        const allOperators = resp && this.buildOperatorRow(resp);
        this.tableData = allOperators || [];
        this.operadores = resp
      },
      error: (err) => {
        this.isLoading = false;
        this.pagination.current = this.pagination.previous;
        console.error(err);
      },
      complete: () => {
        this.isLoading = false;
        this.setFocusOnNewOperator();
        if(!this.filtersFlag) {
          this.startFilters();
        }
      },
    })
  }

  /**
   * Inicializa os filtros da tabela de operadores
   */
  startFilters() {
    const {inicial, final} = getFirstAndLastDate(this.operadores);
    this.filters = [
      {
        type: FilterTypes.COMPOSED_CHECK,
        icon: FilterIcon.FUNNEL,
        placeholder: "Filtrar por",
        composedOptions: [
          {
            title: 'Situação',
            data: [
              { id: 1, label: "Ativo", key: true },
              { id: 2, label: "Inativo", key: false }
            ]
          },
          {
            title: 'Operadores',
            data: [
              { id: 3, label: "Em campo", key: 'em_campo' },
            ]
          },
          ,
        ],
      },
      {
        type: FilterTypes.RANGE_DATE,
        icon: FilterIcon.CALENDAR,
        placeholder: "Período",
        selectConfig: {
          intervalDate: {
            startDate: inicial,
            endDate: final,
          },
        },
      },
      {
        type: FilterTypes.RADIO,
        icon: FilterIcon.SORTING,
        placeholder: "Ordenar",
        options: [
          {
            id: 1,
            label: "Nome de A a Z",
            key: "NOME-ASC",
          },
          {
            id: 2,
            label: "Nome de Z a A",
            key: "NOME-DESC",
          },
          {
            id: 3,
            label: "Vínculo mais antigo",
            key: "VINCULO-DESC"
          },
          {
            id: 4,
            label: "Vínculo mais recente",
            key: "VINCULO-ASC"
          },
          {
            id: 5,
            label: "Maior desempenho",
            key: "DESEMPENHO-DESC"
          },
          {
            id: 6,
            label: "Menor desempenho",
            key: "DESEMPENHO-ASC"
          },
        ],
      },
    ];
    this.filtersFlag = true;
  }

  /**
   * Obtem o total de operadores
   */
  getTotal() {
    const { keywords, status, date, in_field } = this.filterOptions;
    this.operadorService.getTotalRegistros(keywords, date, in_field, status).subscribe({
      next: (resp) => {
        this.pagination.totalOfEntries = resp;
      },
    });
  }

  /**
   * Constrói as linhas da tabela baseado no array de operadores
   */
  buildOperatorRow(operators: any[]): TableRow[] {
    return operators.map((operator) =>
      tRowFactory(
        checkboxCellFactory().checkable(true).build(),
        textCellFactory(operator.matricula)
        .customStyles({
          "pointer-events": "none",
        })
        .build(),
        this.renderTableCel(operator),
        textCellFactory(this.perfomanceFormat(operator.desempenho))
          .alignType("center")
          .build(),
          textCellFactory(operator.periodo_inicial + ' a ' + operator.periodo_final)
          .build(),
        statusCellFactory(
          operator.ativo ? "var(--status-done)" : "var(--status-error)",
          operator.ativo ? "Ativo" : "Inativo",
          "var(--white)"
        ).build(),
        meatballCellFactory().build()
      )
        .id(operator.id)
        .metadata({ ativo: operator.ativo, nome: operator.nome, emCampo: !!operator.em_campo, vencimentoDoVinculo: operator.periodo_final })
        .build()
    );
  }

  /**
   * Define qual será a celular renderizada de acordo com o valor
   * do atributo em_campo retornado pelo back-end
   */
  renderTableCel(operator) {
    if(operator.em_campo) {
      return componentCellFactory()
      .component(CustomTagComponent)
      .data({name: operator.nome, tag: 'Em campo'})
      .build()
    } else {
      return textCellFactory(operator.nome)
        .cellStyleBehavior("UNDERLINE_ON_HOVER")
        .build()
    }
  }

  /**
   * Formata o desempenho e o retorna com 1 casa decimal
   */
  perfomanceFormat(perfomance) {
    if(perfomance === null) {
      return '-'
    } else {
      return formatToMaxDecimalPlaces(perfomance.toString(), 1)
    }
  }

  /**
   * Aplica estilização nos valores da coluna de desempenho baseado na nota
   * do operador
   */
  perfomanceStyle(textValue): string {
    if(textValue === '-') {
      return null;
    } else if (+textValue > 7.0) {
      return 'green-background';
    } else if (+textValue > 5.0) {
      return 'yellow-background';
    } else {
      return 'red-background';
    }
  }

  /**
   * Essa modal é disparada quando o usuário tenta deletar ou
   * inativar um operador que esta em campo
   */
  operatorInFieldModal(operation: string) {
    this.modalService.showModal({
      title: `Não foi possível ${operation} o entrevistador`,
      icon: 'fa-regular fa-circle-exclamation',
      messageModal: `Não é possível ${operation} entrevistadores vinculados a uma ou mais pesquisas em execução.`,
      btnTitlePositive: 'Entendi',
      isOnlyConfirmation: true,
    });
  }

  /**
   * Verifica se o periodo de vinculo do operador esta valido
   * @param bondDate: data limite do vinculo
   * @returns bool
   */
  checkBondIsValid(bondDate: string): boolean {
    const finalDate = dates.dateStrToUnix(bondDate)
    const today = dates.dateStrToUnix(dates.formatDateToString(new Date()))
    return finalDate < today ? true : false
  }

  /**
   * Controla a mudança de status do operador
   */
  toggleStatus({ operatorId, status, nome, emCampo, vencimentoDoVinculo }: ISwitchStatus) {
    if (!this.checkboxModalState) {
      if(status && this.checkBondIsValid(vencimentoDoVinculo)) {
        this.modalService.showModal({
          title: 'Não foi possível ativar o entrevistador',
          icon: 'fa-regular fa-circle-exclamation',
          messageModal: 'Para ativar este entrevistador, você precisará renovar seu período de vínculo para uma data posterior a atual.',
          btnTitlePositive: 'Editar',
          btnTitleNegative: 'Cancelar',
          positiveCallback: () => {
            this.router.navigate(["entrevistador/atualizar"], {queryParams: { id: operatorId } })
          }
        });
      } else if(emCampo) {
        this.operatorInFieldModal('inativar');
      }
      else {
        this.modalService.showModal({
          title: `${status ? "Ativar" : "Inativar"} operador`,
          icon: `fa-regular fa-toggle-large-${status ? "off" : "on"}`,
          messageModal: `Deseja mesmo ${
            status ? "ativar" : "inativar"
          } ${nome}`,
          close: true,
          checkbox: true,
          btnTitlePositive: `${status ? "Ativar" : "Inativar"}`,
          positiveCallback: () => this.toggleStatusRequest(operatorId, status),
        });
      }

    } else {
      this.toggleStatusRequest(operatorId, status)
    }
  }

  /**
   * Realiza a request para mudar o status do operador
   * @param operatorId: id do operador
   * @param status: novo status
   */
  toggleStatusRequest(operatorId: number, status: boolean) {
    this.isLoading = true;
    this.operadorService.changeOperatorStatus(operatorId, status).subscribe({
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
      complete: () => {
        this.notificator.showInfo(
          `Entrevistador ${status ? 'ativado' : 'inativado'}!`,
          null
        )
        this.getOperatorData();
        this.updateHeaderData(status);
      },
    });
  }

  /**
   * Atualiza os dados do header ao mudar o status de um operador
   */
  updateHeaderData(status: boolean) {
    if(status) {
      this.appInfoData.data[OperatorStatusHeaderIndex.ATIVOS].value++
      this.appInfoData.data[OperatorStatusHeaderIndex.INATIVOS].value--
    } else {
      this.appInfoData.data[OperatorStatusHeaderIndex.INATIVOS].value++
      this.appInfoData.data[OperatorStatusHeaderIndex.ATIVOS].value--
    }
  }

  /**
   * Lida com eventos de clique que ocorrem no meatball genérico
   */
  handleMeatballOptionClick(evt: TableEvents) {
    const { selectedMeatballOption, rowId, rowMetadata } = evt;
    // TODO: implementar switch de situação
    switch (selectedMeatballOption.id) {
      case MEATBALL_OPTIONS.EDIT_OPTION_ID:
        this.rediretTo({ eventType: "CELL_CLICK", rowId: rowId });
        break;
      case MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID:
        this.toggleStatus({ operatorId: rowId, status: !rowMetadata.ativo, nome: rowMetadata.nome,
          emCampo: rowMetadata.emCampo, vencimentoDoVinculo: rowMetadata.vencimentoDoVinculo });
      default:
        break;
    }
  }

  /**
   * Controla todos os eventos da tabela
   */
  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["PAGINATION_CHANGED"]: this.changePage.bind(this),
      ["SEARCH_FILTER_CHANGED"]: this.handleTextChange.bind(this),
      ["ACTION_BUTTON_CLICK"]: this.rediretTo.bind(this),
      ["RADIO_FILTER_CHANGED"]: this.selectOrder.bind(this),
      ["COMPOSED_CHECK_FILTER_CHANGE"]: this.selectStatus.bind(this),
      ["DATE_FILTER_CHANGED"]: this.selectRange.bind(this),
      ["CELL_CLICK"]: this.rediretTo.bind(this),
      ["MEATBALL_OPTION_SELECTED"]: this.handleMeatballOptionClick.bind(this)
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  /**
   * Controla a paginação
   */
  changePage({ requestedPage, previousPage }) {
    this.pagination.current = requestedPage;
    this.pagination.previous = previousPage;
    this.getOperatorData();
  }

  /**
   * Controla o redirect para tela de cadastro/edição
   */
  rediretTo({ eventType, rowId }) {
    const redirectEvent = {
      ["ACTION_BUTTON_CLICK"]: () =>
        this.router.navigate(["entrevistador/cadastro"]),
      ["CELL_CLICK"]: () =>
        this.router.navigate(["entrevistador/atualizar"], {
          queryParams: { id: rowId },
        }),
    };
    redirectEvent[eventType]();
  }

  /**
   * Controla o input de busca da tabela
   */
  handleTextChange({ filterValue }) {
    this.filterOptions.keywords = [filterValue];
    this.resetPagination();
    this.getOperatorData();
  }

  /**
   * Controla o input de ordenação
   */
  selectOrder($event: { filterValue: IFilterSelect[] }) {
    const [selectedSortingOption] = $event.filterValue;
    const [sortingType = "", direction = ""] = (
      selectedSortingOption?.key || ""
    ).split("-");
    this.filterOptions.sorting.sort = sortingType;
    this.filterOptions.sorting.direction = direction;
    this.getOperatorData();
  }

  /**
   * Define o status selecionado pelo usuário
   * Se o usuário selecionar ambos status o valor atribuido será null, pois
   * entende-se que ele quer mostrar todos os resultados
   */
  selectStatus({ filterValue: { currentState } }) {
    const inFieldIndex = currentState.findIndex(field => field.key === 'em_campo');
    const statusIndex = currentState.findIndex(field => field.key !== 'em_campo')

    this.controlFilterStatus(currentState, statusIndex);
    this.controlFilterInField(inFieldIndex);

    this.resetPagination();
    this.getOperatorData();
  }

  /**
   * Controla a persistência do grupo de status do composedFilter
   */
  controlFilterStatus(currentState, statusIndex: number) {
    if(currentState.some(c => c.label.includes('Inativo')) && currentState.some(c => c.label.includes('Ativo'))) {
      this.filterOptions.status = null
    } else {
      if(statusIndex !== -1) {
        this.filterOptions.status = currentState[statusIndex].key;
      } else {
        this.filterOptions.status = null;
      }
    }
  }

  /**
   * Controla a persistência do grupo de em_campo do composedFilter
   */
  controlFilterInField(inFieldIndex: number) {
    if(inFieldIndex !== -1) {
      this.filterOptions.in_field = true;
    } else {
      this.filterOptions.in_field = null;
    }
  }

  /**
   * Obtem a data inicial e final selecionada pelo usuário no filtro range_date
   */
  selectRange({ filterValue: {start, end} }) {
    if(start && end) {
      this.filterOptions.date.periodo_inicial = dates.formatDateToString(start);
      this.filterOptions.date.periodo_final = dates.formatDateToString(end);
    } else {
      this.filterOptions.date.periodo_inicial = null;
      this.filterOptions.date.periodo_final = null;
    }

    this.resetPagination();
    this.getOperatorData();
  }

  /**
   * Reseta os dados de paginação
   */
  resetPagination() {
    this.pagination.current = 1;
    this.pagination.previous = 1;
  }

  // Recupera os dados do header
  getInfoCardData() {
    this.operadorService.getHeaderData().subscribe({
      next: (data) => {
        this.appInfoData = {
          title: 'Entrevistadores',
          text: 'Cadastre e gerencie os entrevistadores que farão parte de suas avaliações',
          data: [
            {
              title: 'Entrevistadores',
              value: data.total
            },
            {
              title: 'Ativos',
              value: data.ativos
            },
            {
              title: 'Inativos',
              value: data.inativos
            }
          ]
        }
      },
      error: (err) => { console.log(err) }
    });
  }

}
