import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ChangePasswordService } from "./componentes/change-password/change-password.service";
import { LgpdSignatureService } from "./servico/lgpdService/lgpd-signature.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private securityService: SecurityService,
    private changePasswordService: ChangePasswordService,
    private signatureService: LgpdSignatureService,
  ) {}

  ngOnInit() {
    // detector de eventos de navegação: Lida com a necessidade de atualização de senha
    // e de assinatura de termos lgpd.
    this.handleRouterEvents();
  }

  private blockClientFromNavigation(event: NavigationEnd, needChangePassword: boolean, needSignDocuments: boolean) {
    if (event.url !== "/" && needSignDocuments && !needChangePassword) {
      this.router.navigate(["/"]);
    }
  }

  // detecta caso o usuário ainda precise trocar a senha
  private detectNeedChangePassword(needChangePassword: boolean) {
    // se a rota acessada for diferente de '/' e o serviço retornar que ainda precisa trocar a senha
    if (needChangePassword) {
      // passo para o serviço de alterar senha para exibir a senha
      this.changePasswordService.setChangePassword(true);
    }
    // caso o usuário saia da aplicação volto para o estado inicial pra evitar request desnecessárias no dashboard
    else if (this.securityService.getAuthenticatedUser() === null) {
      this.changePasswordService.setChangePassword(true);
    }
    else {
      this.changePasswordService.setChangePassword(false);
    }
  }

  private detectNeedSignLgpdDocuments(needSignDocuments: boolean) {
    // Caso o usuário precise assinar algum dos documentos LGPD
    this.signatureService.setNeedSignDocument(needSignDocuments);
  }

  handleRouterEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const needChangePassword = this.securityService.getAuthenticatedUserNeedChangePassword();
        const needSignDocuments = this.securityService.getAuthenticatedUserNeedSignDocuments();

        this.blockClientFromNavigation.call(this, event, needChangePassword, needSignDocuments);
        this.detectNeedChangePassword.call(this, needChangePassword);
        this.detectNeedSignLgpdDocuments.call(this, needSignDocuments);
      }
    })
  }
}
