import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SecurityService } from '../../service/securityService';
import { AuthorizationGuardService } from './authorizationGuardService';

/**
 * Classe responsavel por tratar a autorizacao que usuarios autenticados
 * tem a determinadas rotas
 */
@Injectable({
  providedIn: 'root',
})
  export class ApplicationAuthorizationGuardService extends AuthorizationGuardService {

  constructor(
      protected securityService: SecurityService,
      protected router: Router,
    ) {
    super(securityService, router);
  }

    /**
     * Verifica se o usuario possui permissão para
     * acessar a aplicação, caso possua ele segue em frente,
     * caso não tenha, ele deve ser deslogado e alertado
     * que não possui permissão para utilização do sistema
     * web
     */
  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (this.securityService.getAuthenticatedUser()) {

      if (this.userHasAuthorities(route)) {
        return true;
      }

      this.securityService.logout();
      this.router.navigate(['login'], { queryParams: { unauthorized: true } });
    }

    return false;
  }

}
