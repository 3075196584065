import { Component, Input } from "@angular/core";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-foto",
  templateUrl: "./foto.component.html",
  styleUrls: ["./foto.component.scss"],
})
export class RespostaFotoComponent extends RespostaComponent {
  @Input() private pergunta: PerguntaEsquema;
  @Input() private respostaPergunta: RespostaPergunta;

  fotoResposta: string = "";

  hasFoto() {
    return this.fotoResposta.length > 0;
  }

  ngOnInit() {
    super.ngOnInit();

    const respostaFotos = this.respostaPergunta.alternativasSelecionadas.map(
      (a: AlternativaSelecionada) => {
        return a.arquivo;
      }
    );

    this.fotoResposta =
      respostaFotos.length > 0 ? respostaFotos[0] : this.fotoResposta;
  }

  hasErrors() {
    return false;
  }

  /**
   * @override
   */
  onProximaPergunta() {
    super.notifyRespostaPergunta(this.respostaPergunta);
  }
}
