import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IPermissoes,
} from "../../interfaces/cadastroFuncionario";

@Component({
  selector: "app-multiselect-permission",
  templateUrl: "./multiselect-permission.component.html",
  styleUrls: ["./multiselect-permission.component.scss"],
})
export class MultiselectPermissionComponent implements OnInit {
  @ViewChild("combobox", { static: true }) combobox: ElementRef<any>;
  @Input() permissions: IPermissoes[] = [];
  @Input() form;

  comboboxOpened = false;
  inputSearch = "";

  ngOnInit(): void {}

  toggleCombobox() {
    this.comboboxOpened = !this.comboboxOpened;
  }

  /**
   * Retorna as opções de permissões
   */
  get getItems() {
    const textSearch = this.inputSearch.toLowerCase();
    return this.permissions.filter((item) =>
      item.nomeAmigavel.toLowerCase().includes(textSearch)
    );
  }

  /**
   * Toggle para booleano que controla a seleção de uma permissão
   */
  selectPermission(nomeAmigavel) {
    const index = this.permissions.findIndex(permission => permission.nomeAmigavel === nomeAmigavel);
    if(index !== -1 && !this.permissions[index].disabled) {
      this.permissions[index].selecionado = !this.permissions[index].selecionado;
      if(this.permissions[index].selecionado) {
        this.form.patchValue({
          permissions: [...this.form.get('permissions').value, this.permissions[index]]
        })
      } else {
        const removedPermissionsArray = [...this.form.get('permissions').value].filter(permission => permission.nomeReal !== this.permissions[index].nomeReal);
        this.form.patchValue({
          permissions: removedPermissionsArray
        })
      }
    }
  }

  /**
   * Retorna os itens selecionados
   */
  get selected() {
    return this.form.get('permissions').value;
  }

  /**
   * Escuta evento de click e fecha o combobox quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (!this.combobox.nativeElement.contains(event.target)) {
      if (this.comboboxOpened) {
        this.toggleCombobox();
      }
    }
  }

}
