import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { RespostaProcessorManager } from "../resposta/processor";
import { PerguntaQuestionarioAuditadoManager } from "..";
import { AlternativaAuditada } from "../entidade/alternativa";

/**
 * Gerencia e processa as interações do usuário com uma AlternativaAuditada
 * do questionário.
 * 
 * TODO: Um nome melhor poderia ser SelecaoRespostaManager
 */
export class SelecaoRespostaProcessor {
  constructor(context: PerguntaQuestionarioAuditadoManager) {
    this.context = context;
    this.respostaManager = context.getRespostaPerguntaManager();

    this.inicializarContadorSelecao();
    this.inicializarRespostasOriginaisRemovidas();
  }

  context: PerguntaQuestionarioAuditadoManager;
  respostaManager: RespostaProcessorManager;
  quantidadeSelecionadas: number = 0;

  /**
   * Histórico de alternativas originais removidas pelo auditor.
   * Será utilizado para compor regras relacionadas à múltipla seleção
   * e seus impactos na atribuição da resposta original associada à uma
   * alternativa
   */
  private idsAlternativasOriginaisRemovidas: number[] = [];

  private inicializarContadorSelecao() {
    const respostas = this.respostaManager.activeProcessor.getRespostas();
    const resultados = respostas.filter(
      (alternativa) => alternativa.isSelecionada
    );

    this.setQuantidadeSelecionadas(resultados.length);
  }

  private limparEstadoSelecaoAlternativas() {
    const respostas = this.respostaManager.activeProcessor.getRespostas();

    let res: AlternativaAuditada;
    for (let i = 0; i < respostas.length; i++) {
      res = respostas[i];

      res.setSelecionada(false);
      res.setOrdemSelecao(null);
      res.setTexto(null);
    }

    this.setQuantidadeSelecionadas(0);
  }

  private selecionarTipoPerguntaRespostaUnica(
    alternativa: AlternativaAuditada
  ): boolean {
    if (alternativa instanceof AlternativaAuditada) {
      if (alternativa && !alternativa.isSelecionada) {
        this.limparEstadoSelecaoAlternativas();

        alternativa.setSelecionada(true);
        alternativa.setOrdemSelecao(1);

        this.setQuantidadeSelecionadas(1);

        return true;
      }
    }

    return false;
  }

  private selecionarTipoPerguntaRespostaMultipla(
    alternativa: AlternativaAuditada
  ): boolean {
    if (alternativa instanceof AlternativaAuditada) {
      const podeSelecionar = !(
        this.getQuantidadeSelecionadas() >=
        this.context.getDefinicoesPergunta().getMaximo()
      );

      if (podeSelecionar) {
        if (alternativa && !alternativa.isSelecionada) {
          alternativa.setSelecionada(true);

          if (!alternativa.isRespostaOriginal) {
            if (this.idsAlternativasOriginaisRemovidas.length) {
              const idRespostaOriginal =
                this.recuperarPrimeiraRespostaOriginalEmDesuso();

              if (idRespostaOriginal !== null) {
                alternativa.setIdRespostaOriginal(idRespostaOriginal);
              }
            }
          } else {
            const index = this.idsAlternativasOriginaisRemovidas.indexOf(
              alternativa.getIdAlternativa()
            );

            if (index !== -1) {
              this.idsAlternativasOriginaisRemovidas.splice(index, 1);
            }

            this.context.getRespostasSelecionadas().forEach((alt) => {
              if (
                !alt.isRespostaOriginal &&
                alt.getIdRespostaOriginal() === alternativa.getIdAlternativa()
              ) {
                const idRespostaOriginal =
                  this.recuperarPrimeiraRespostaOriginalEmDesuso();

                alt.setIdRespostaOriginal(idRespostaOriginal);
              }
            });
          }

          this.incrementarQuantidadeSelecionadas(+1);

          const proximaOrdemSelecao = this.getQuantidadeSelecionadas();

          alternativa.setOrdemSelecao(proximaOrdemSelecao);
        }

        return true;
      }
    }
    return false;
  }

  public unselectTipoPerguntaRespostaUnica(
    alternativa: AlternativaAuditada
  ): boolean {
    if (alternativa instanceof AlternativaAuditada) {
      if (this.getQuantidadeSelecionadas() === 1) {
        return false;
      }

      alternativa.setSelecionada(false);
      alternativa.setTexto(null);
      alternativa.setOrdemSelecao(null);

      this.incrementarQuantidadeSelecionadas(-1);

      return true;
    }

    return false;
  }

  public unselectTipoPerguntaRespostaMultipla(
    alternativa: AlternativaAuditada
  ): boolean {
    if (alternativa instanceof AlternativaAuditada) {
      alternativa.setSelecionada(false);
      alternativa.setTexto(null);

      if (alternativa.isRespostaOriginal) {
        this.idsAlternativasOriginaisRemovidas.push(
          alternativa.getIdAlternativa()
        );
      } else {
        if (alternativa.getIdRespostaOriginal() !== null) {
          alternativa.setIdRespostaOriginal(null);
        }
      }

      this.realocarRespostasOriginais();
      
      this.recalcularOrdemSelecaoPosRemocaoAlternativa(
        alternativa.getOrdemSelecao()
      );
  
      alternativa.setOrdemSelecao(null);

      this.incrementarQuantidadeSelecionadas(-1);

      return true;
    }

    return false;
  }

  private estrategiasSelecao = {
    [TipoPergunta.UNICA]: this.selecionarTipoPerguntaRespostaUnica,
    [TipoPergunta.ESCALA_NUMERICA]: this.selecionarTipoPerguntaRespostaUnica,
    [TipoPergunta.MULTIPLA]: this.selecionarTipoPerguntaRespostaMultipla,
  };

  private estrategiasDesselecao = {
    [TipoPergunta.UNICA]: this.unselectTipoPerguntaRespostaUnica,
    [TipoPergunta.ESCALA_NUMERICA]: this.unselectTipoPerguntaRespostaUnica,
    [TipoPergunta.MULTIPLA]: this.unselectTipoPerguntaRespostaMultipla,
  };

  public select(alternativa: AlternativaAuditada): boolean {
    const estrategiaSelecao =
      this.estrategiasSelecao[this.context.utils.getTipoPergunta()];

    if (estrategiaSelecao) {
      return estrategiaSelecao.call(this, alternativa);
    }

    return false;
  }

  public unselect(alternativa: AlternativaAuditada): boolean {
    const estrategiasDesselecao =
      this.estrategiasDesselecao[this.context.utils.getTipoPergunta()];

    if (estrategiasDesselecao) {
      return estrategiasDesselecao.call(this, alternativa);
    }

    return false;
  }

  private recalcularOrdemSelecaoPosRemocaoAlternativa(
    ordemAlternativaRemovida: number
  ) {
    const alternativasSelecionadas = this.context.getRespostasSelecionadas();

    let alt: AlternativaAuditada;

    for (let i = 0; i < alternativasSelecionadas.length; i++) {
      alt = alternativasSelecionadas[i];

      if (alt.getOrdemSelecao() > ordemAlternativaRemovida) {
        alt.setOrdemSelecao(alt.getOrdemSelecao() - 1);
      }
    }
  }

  public resetarSelecao() {
    this.respostaManager.activeProcessor.resetarParaOriginal();

    if (this.context.utils.isPerguntaComAlternativas()) {
      this.inicializarContadorSelecao();
    }
  }

  public getQuantidadeSelecionadas() {
    return this.quantidadeSelecionadas;
  }

  public getSelecionadas() {
    const respostas = this.respostaManager.activeProcessor.getRespostas();

    if (Array.isArray(respostas)) {
      return respostas.filter((resposta) => resposta.isSelecionada);
    }
  }

  private incrementarQuantidadeSelecionadas(direcao: number = +1) {
    this.quantidadeSelecionadas = this.quantidadeSelecionadas + direcao;
  }

  private setQuantidadeSelecionadas(valor: number) {
    this.quantidadeSelecionadas = valor;
  }

  private recuperarPrimeiraRespostaOriginalEmDesuso(): number {
    for (let i = 0; i < this.idsAlternativasOriginaisRemovidas.length; i++) {
      const idRespostaOriginal = this.idsAlternativasOriginaisRemovidas[i];

      const existeRespostaOriginalEmUso = this.context
        .getRespostasSelecionadas()
        .some(
          (alt) =>
            !alt.isRespostaOriginal &&
            alt.getIdRespostaOriginal() === idRespostaOriginal
        );

      if (!existeRespostaOriginalEmUso) {
        return idRespostaOriginal;
      }
    }

    return null;
  }

  private realocarRespostasOriginais() {
    const selecionadas = this.context.getRespostasSelecionadas();

    if (this.idsAlternativasOriginaisRemovidas.length === 0) {
      return;
    }

    for (let i = 0; i < selecionadas.length; i++) {
      const resposta = selecionadas[i];

      if (
        !resposta.isRespostaOriginal &&
        resposta.getIdRespostaOriginal() === null
      ) {
        const idRespostaOriginal =
          this.recuperarPrimeiraRespostaOriginalEmDesuso();

        if (idRespostaOriginal === null) {
          break;
        }

        resposta.setIdRespostaOriginal(idRespostaOriginal);
      }
    }
  }

  private inicializarRespostasOriginaisRemovidas() {
    if (this.context.utils.isRespostaMultipla()) {
      const respostas = this.context.getRespostas();

      let resposta: AlternativaAuditada;

      for (let i = 0; i < respostas.length; i++) {
        resposta = respostas[i];

        if (resposta.isRespostaOriginal && !resposta.isSelecionada) {
          this.idsAlternativasOriginaisRemovidas.push(
            resposta.getIdAlternativa()
          );
        }
      }
    }
  }
}
