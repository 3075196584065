/**
 * Formata o número adicionando um zero antes do número, caso o número seja menor que 10.
 * Ex: 1 => 01, 9 => 09, 10 => 10.
 * @param num 
 */
function addZeroBeforeIfLessThan10(num : number) : string {
    return ('0' + num).slice(-2);
}

//substitui vírgula por ponto
function replacePerPoint(numero: number){
  if (numero === null) {
    return ;
  }
  var numberWithPoint = numero.toString().replace(',','.');
  return numberWithPoint;
}

const numberFormatter = {
  replacePerPoint,
  addZeroBeforeIfLessThan10
};

export default numberFormatter;
