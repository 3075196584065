import { Directive, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { TipoEventoTabela } from "app/util/componente/tabela/evento/tipoEventoTabela";
import { TabelaComponent } from "app/util/componente/tabela/tabela.component";
import { stringFormatter } from "app/util/misc/stringFormatter";
import { Observable, of, Subscription } from "rxjs";

@Directive()
export abstract class ListagemAbstrata implements OnInit, OnDestroy {
  @ViewChild("tabela", { static: true }) tabela: TabelaComponent;
  navigationSubscription: Subscription;

  // marcador de loading
  isLoading: boolean = false;

  constructor(
    protected nomeEntidade,
    protected router: Router,
    protected modalService: ModalService
  ) {}

  ngOnInit() {
    // reinicializando a tabela caso o componente de listagem seja atualizado
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.tabela.ngOnDestroy();
        this.tabela.ngOnInit();
        this.onNavigationEnd();
      }
    });
  }

  /**
   * Life cycle hook que é disparado caso a rota seja atualizada.
   */
  onNavigationEnd() {}

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  manipularEventoTabela(eventoTabela: any) {
    switch (eventoTabela.tipo) {
      case TipoEventoTabela.EDITAR:
        {
          this.manipularEventoEdicao(eventoTabela);
        }
        break;
      case TipoEventoTabela.EXCLUIR:
        {
          this.manipularEventoExclusao(eventoTabela);
        }
        break;
      case TipoEventoTabela.DOWNLOAD: {
        this.manipularEventoDownload(eventoTabela);
      }
      case TipoEventoTabela.PAGINACAO: {
        this.manipularEventoPaginacao(eventoTabela);
      }
    }
  }

  manipularEventoEdicao(eventoTabela: EventoTabela) {
    this.router.navigate([this.nomeEntidade, "cadastro"], {
      queryParams: { editar: eventoTabela.payload },
    });
  }

  /**
   * life cycle hook que é executado sempre que uma exclusão é efetuada com sucesso.
   * Por default, este método navega para {nomeEntidade}/listagem, mas ele pode ser sobrescrito
   * para implementações de refresh específicas.
   */
  refreshCallback() {
    this.router.navigate([this.nomeEntidade, "listagem"]);
  }

  /**
   * lifecycle hook que realiza a exclusão de dados de acordo com
   * evento e seu payload passado.
   * @param evento evento de exclusão contendo, em payload, o que deverá ser removido.
   */
  abstract excluirDados(evento: EventoTabela): Observable<{}>;

  manipularEventoExclusao(eventoTabela: EventoTabela) {
    const excluirCallback = () => {
      if (eventoTabela.payload && eventoTabela.payload.length > 0) {
        this.isLoading = true;

        this.excluirDados(eventoTabela).subscribe({
          next: () => {
            this.isLoading = false;
            const refreshCallback = () => {
              this.refreshCallback();
            };

            // tslint:disable-next-line:max-line-length
            const nomeEntidadeCapitalizado =
              stringFormatter.capitalizeFirstLetter(this.nomeEntidade);

            this.modalService.showModal({
              title: `Excluir ${this.nomeEntidade}`,
              messageModal: `${nomeEntidadeCapitalizado} excluído com sucesso!`,
              positiveCallback: () => refreshCallback(),
              btnTitlePositive: "Entendi",
              isOnlyConfirmation: true,
            });
          },
          // tslint:disable-next-line:align
          error: (error) => {
            this.isLoading = false;

            this.modalService.showModal({
              title: `Excluir ${this.nomeEntidade}`,
              messageModal: `Não foi possível excluir o(s) ${this.nomeEntidade}(es): ${error}`,
              btnTitlePositive: "Entendi",
              isOnlyConfirmation: true,
            });
          },
        });
      }
    };
    if (eventoTabela.payload.length === 0) {
      const entidadeCapitalizada = stringFormatter.capitalizeLastLetter(
        this.nomeEntidade
      );

      this.modalService.showModal({
        title: `Excluir ${this.nomeEntidade}`,
        messageModal: `Selecione um ou mais  ${entidadeCapitalizada}`,
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
      });
    } else {
      const entidadeCapitalizada = stringFormatter.capitalizeLastLetter(
        this.nomeEntidade
      );
      this.modalService.showModal({
        title: `Excluir ${this.nomeEntidade}`,
        messageModal: "Deseja realmente excluir o(s) itens",
        btnTitlePositive: "Excluir",
        positiveCallback: () => excluirCallback(),
        btnTitleNegative: "Cancelar",
      });
    }
  }

  /**
   * lifecycle hook que deve realizar o download dos dados requisitados
   * pelo payload do evento.
   * @param evento evento contendo os parâmetros do download em seu payload.
   */
  baixar(evento: EventoTabela): Observable<boolean> {
    return of(true);
  }

  manipularEventoDownload(eventoTabela: EventoTabela) {
    try {
      this.baixar(eventoTabela).subscribe((showLoading) => {
        this.isLoading = showLoading;
      });
    } catch (error) {
      this.modalService.showModal({
        title: "Donwload da listagem",
        messageModal:
          "Houve um problema no processamento de sua requisição, tente novamente",
        btnTitlePositive: "Excluir",
        isOnlyConfirmation: true,
      });
    }
  }

  /**
   * Hook para extensões de TabelaAbstrata implementar sua própria manipulação do evento
   * de mudança na paginação
   *
   * @param eventoTabela
   */
  manipularEventoPaginacao(eventoTabela: EventoTabela) {}
}
