import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { Observable } from "rxjs";
import { IQuestionResponse } from "../interfaces/audit-question-collections";

@Injectable({
  providedIn: "root",
})
/**
 * Serviço responsável pelas requisições relacionadas ao questionário no módulo
 * de auditoria-beta
 */
export class AuditoriaBetaQuestionarioService extends CrudService<any> {
  public baseUrl: string = this.resourcePath;

  constructor(
    protected requestService: RequestService,
  ) {
    super(requestService, `${apiLocation}/pesquisas/auditorias`);
  }

  /**
   * Recupera a lista de perguntas de uma coleta e suas auditorias
   */
  getQuestionCollectionList(auditedSurveyId: number, auditedCollectionId: number) {
    const reqUrl: string = `${this.baseUrl}/${auditedSurveyId}/coletas/${auditedCollectionId}`;
    const response = <Observable<IQuestionResponse>>this.requestService.get(reqUrl)

    return response;
  }
}
