import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';
import { MascaraBase } from './mascaraBase';

@Directive({
  selector: '[mascara-inscricaoEstadual]',
})
export class MascaraInscricaoEstadualDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 255);
  }

  aplicarMascara(valor: string): string {
    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);
    return numberString;
  }

}
