/**
 * Convert date format
 * @param dateString: date yyyy-mm-dd
 * @returns date: dd/mm/yyyy
 */
export function parseDate(dateString) {
  const [, year, month, day] = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);

  return `${day}/${month}/${year}`;
}

export function orderNumericScale(alternatives) {
  const escala = [...alternatives];
  return [
    ...escala.sort(
      ({ descricao: descricaoA }, { descricao: descricaoB }) =>
        descricaoA - descricaoB
    ),
  ];
}
