<div class="pesquisa-questionario-pergunta-cadastro-wrapper">
  <header>
    <div class="page-label">
      <i class="fas fa-clipboard-list"></i>
      <h3>Questionário - Nova Pergunta</h3>
    </div>
  </header>
  <h4 class="titulo-pesquisa-label">Título da pesquisa</h4>
  <h3 class="titulo-pesquisa">{{ surveyTitle }}</h3>
  <form class="form-container" #form="ngForm" [formGroup]="formGroup" (ngSubmit)="handleSave()">
    <div class="form-container-header">
      <h2 class="title">
        Seção {{ sectionSeq }}:
        <b>{{ sectionName }}</b>
      </h2>
      <button class="mostrar-glossario">
        <i class="fas fa-book"></i>
        <div class="button-label">
          <h3>Glossário</h3>
          <h4>Tipos de perguntas</h4>
        </div>
      </button>
    </div>
    <div class="form-content">
      <div class="inputs-container column">
        <div class="field">
          <div style="display: flex; justify-content: space-between">
            <label for="name">Pergunta*</label>
          </div>
          <!-- <div [ngClass]="!(form.dirty && formControls.nome.errors?.maxlength) ? 'input-body' : 'input-body ng-dirty ng-invalid'"> -->
          <textarea string-no-break-line string-without-semicolon type="text" name="nome" formControlName="nome" id="nome"
            placeholder="Escreva a sua pergunta" (input)="handleInputChange($event, 'nome')" maxlength="251" [ngClass]="
                (
                  !(form.dirty && formControls.nome.errors?.maxlength) &&
                  !(form.dirty && formControls.nome.errors?.required)
                ) ? 'input-body textarea' : 'input-body textarea ng-dirty ng-invalid'
              "></textarea>
          <!-- </div> -->
          <span class="error-message" [hidden]="!(form.dirty && formControls.nome.errors?.maxlength)">
            Máximo de 250 caracteres
          </span>
          <span class="error-message" [hidden]="!(form.dirty && formControls.nome.errors?.required)">
            Campo obrigatório
          </span>
        </div>
        <div class="field">
          <div style="display: flex; justify-content: space-between">
            <label for="orientacao">Orientação para responder a pergunta</label>
          </div>
          <textarea type="text" name="orientacao" maxlength="251" formControlName="orientacao" id="orientacao"
            (input)="handleInputChange($event, 'orientacao')" class="input-body"
            placeholder="Defina uma orientação para o pesquisador de campo"></textarea>
          <span class="error-message" [hidden]="
                !(form.dirty && formControls.orientacao.errors?.maxlength)
              ">
            Máximo de 250 caracteres
          </span>
        </div>
      </div>
      <div class="inputs-container space-between">
        <div class="field tipo-pergunta">
          <label for="tipo">Tipo de pergunta*</label>
          <div [ngClass]="
              tiposDePerguntaVisiveis ? 'select-body active' : 'select-body'
            " (click)="toggleVisibilidadeDePerguntas()" (mouseleave)="
              tiposDePerguntaVisiveis && toggleVisibilidadeDePerguntas()
            ">
            <input type="text" name="tipo" formControlName="tipo" id="tipo" placeholder="Selecione um tipo de pergunta"
              [ngClass]="
                formControls?.tipo?.value ? 'actived-text' : 'regular-text'
              " [value]="
                formControls?.tipo?.value
                  ? formatTipo(formControls.tipo.value)
                  : 'Selecione um tipo de pergunta'
              " readonly />
            <i class="fas fa-chevron-down"></i>
            <div class="opcoes" *ngIf="tiposDePerguntaVisiveis">
              <div class="opcao-selecionada">
                <h3 [ngClass]="
                    formControls?.tipo?.value ? 'actived-text' : 'regular-text'
                  ">
                  {{
                  formControls?.tipo?.value
                  ? formatTipo(formControls.tipo.value)
                  : "Selecione um tipo de pergunta"
                  }}
                </h3>
                <i class="fas fa-chevron-down"></i>
              </div>
              <div class="lista-de-opcoes">
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.UNICA)">
                  <div class="image">
                    <div id="resposta-unica" alt="imagem de tipo de pergunta"> </div>
                  </div>
                  <h3>Resposta única</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.MULTIPLA)">
                  <div class="image">
                    <div id="resposta-multipla" alt="imagem de tipo de pergunta"> </div>
                  </div>
                  <h3>Resposta Múltipla</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.DATA)">
                  <div class="image">
                    <div id="data" alt="imagem de tipo de pergunta"></div>
                  </div>
                  <h3>Data</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.ESCALA_NUMERICA)">
                  <div class="image">
                    <div id="escala-numerica" alt="imagem de tipo de pergunta"></div>
                  </div>
                  <h3>Escala Numérica</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.FOTO)">
                  <div class="image">
                    <div id="foto" alt="imagem de tipo de pergunta"></div>
                  </div>
                  <h3>Foto</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.HORARIO)">
                  <div class="image">
                    <div id="horario" alt="imagem de tipo de pergunta">
                    </div>
                  </div>
                  <h3>Horário</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.VIDEO)">
                  <div class="image">
                    <div id="video" alt="imagem de tipo de pergunta">
                    </div>
                  </div>
                  <h3>Vídeo</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.GRADE_UNICA)">
                  <div class="image">
                    <div id="grade-unica" alt="imagem de tipo de pergunta"></div>
                  </div>
                  <h3>Grade única</h3>
                </div>
                <div class="opcao" (click)="handleSelecionarTipoPergunta(typesPer.GRADE_MULTIPLA)">
                  <div class="image">
                    <div id="grade-multipla" alt="imagem de tipo de pergunta"></div>
                  </div>
                  <h3>Grade Múltipla</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="
            formGroup.value.obrigatoria
              ? 'required-checkbox checked'
              : 'required-checkbox'
          ">
          <div class="checkbox-square" (click)="handleRequiredCheckboxClick()">
            <i class="fas fa-check"></i>
          </div>
          <h3>Esta pergunta deve ser obrigatoriamente respondida</h3>
        </div>
      </div>
      <section class="pergunta pergunta-wrapper" *ngIf="ehPerguntaMultiplaOuPerguntaUnica()">
        <h3>Alternativas</h3>
        <div [ngClass]="
            formGroup.value.alternativas.length > 1
              ? 'lista-de-alternativas multiple'
              : 'lista-de-alternativas'
          " cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="fields" *ngFor="
              let alternativa of formGroup.value.alternativas;
              let indexOfAlternativa = index
            " cdkDrag cdkDragLockAxis="y">
            <div class="alternativa-input-wrapper">
              <div style="display: flex; justify-content: space-between">
                <label for="alternativa">Alternativa {{ indexOfAlternativa + 1 }}*</label>
                <span class="error-message" [hidden]="
                    !alternativasFormArray.controls[indexOfAlternativa]?.errors
                      ?.maxlength
                  ">
                  {{ maxLengthErrorMessage(alternativa.tipoAlternativa) }}
                </span>
                <span class="error-message"
                  [hidden]="!(alternativasFormArray.controls[indexOfAlternativa]?.errors?.required)">
                  Campo obrigatório
                </span>
              </div>
              <textarea type="text" (keyup)="handlePerguntaDescricaoChange($event, indexOfAlternativa)"
                (input)="handleInputChange($event, 'alternativa' + indexOfAlternativa)"
                [id]="'alternativa' + indexOfAlternativa" [name]="'alternativa' + indexOfAlternativa"
                [ngClass]="handleAlternativaClassName(alternativa.tipoAlternativa, indexOfAlternativa)"
                [value]="alternativa.descricao"
                [attr.maxlength]="alternativa.tipoAlternativa === 'ABERTA_TEXTO' ? 41 : 251"
                placeholder="Informe a alternativa"
                string-no-break-line>
              </textarea>
            </div>
            <div class="resposta-select-wrapper">
              <label for="select-header">Tipo de alternativa*</label>
              <!--<div class="select-header active">-->
              <div [ngClass]="
                  isMenuTipoAlternativaVisivel(indexOfAlternativa)
                    ? 'select-header active'
                    : 'select-header'
                " (click)="
                  alterarIndiceDaAlternativaComMenuTipoAlternativaVisivel(
                    indexOfAlternativa
                  )
                " (mouseleave)="
                  alterarIndiceDaAlternativaComMenuTipoAlternativaVisivel(-1)
                ">
                <h3>
                  {{ formatTipoAlternativa(alternativa.tipoAlternativa) }}
                </h3>
                <i class="fas fa-chevron-down"></i>
                <div class="opcoes" *ngIf="isMenuTipoAlternativaVisivel(indexOfAlternativa)">
                  <div class="opcoes-header">
                    <h3>
                      {{ formatTipoAlternativa(alternativa.tipoAlternativa) }}
                    </h3>
                    <i class="fas fa-chevron-down"></i>
                  </div>
                  <div class="lista-de-opcoes">
                    <div [ngClass]="
                        alternativa.tipoAlternativa === 'FECHADA'
                          ? 'opcao checked'
                          : 'opcao'
                      " (click)="
                        handleAlternativaTipoChange(
                          typesAlt.FECHADA,
                          indexOfAlternativa
                        )
                      ">
                      <i class="fas fa-check"></i>
                      <h6>Fechada</h6>
                    </div>
                    <div [ngClass]="
                        alternativa.tipoAlternativa === 'ABERTA_TEXTO'
                          ? 'opcao checked'
                          : 'opcao'
                      " (click)="
                        handleAlternativaTipoChange(
                          typesAlt.ABERTA_TEXTO,
                          indexOfAlternativa
                        )
                      ">
                      <i class="fas fa-check"></i>
                      <h6>Aberta</h6>
                    </div>
                    <div [ngClass]="
                        alternativa.tipoAlternativa === 'ABERTA_NUMERO'
                          ? 'opcao checked'
                          : 'opcao'
                      " (click)="
                        handleAlternativaTipoChange(
                          typesAlt.ABERTA_NUMERO,
                          indexOfAlternativa
                        )
                      ">
                      <i class="fas fa-check"></i>
                      <h6>Aberta Número</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <img class="center" src="assets/images/click-and-drag.svg" alt=""> -->
            <div class="drag" cdkDragHandle></div>
            <span class="sparate"></span>
            <div class="close" (click)="removerAlternativa(indexOfAlternativa)"></div>
          </div>
          <button class="adicionar-alternativa" type="button" (click)="adicionarAlternativa()">
            <i class="fas fa-plus"></i>
            <h6>Adicionar alternativa</h6>
          </button>
        </div>

        <div class="quantidade-pergunta-wrapper" *ngIf="perguntaDoMesmoTipoQue(typesPer.MULTIPLA)">
          <div class="group-inputs-wrapper">
            <h3>Quantidade de respostas</h3>
            <div class="validation-wrapper">
              <div class="group-inputs">
                <div class="input--body">
                  <label for="qtd_minima_respostas">Mínima*</label>
                  <input [class]="!verifyIfMinAlternativesAreOk() ? 'on-error' : ''" formControlName="qtdMinima"
                    type="number" id="qtd_minima_respostas" [min]="1" [max]="getInputMultiplaQtdMaxima()" />
                </div>
                <div class="input--body">
                  <label for="qtd_maxima_respostas">Máxima*</label>
                  <input [class]="!verifyIfMaxAlternativesAreOk() ? 'on-error' : ''" formControlName="qtdMaxima"
                    type="number" id="qtd_maxima_respostas" [min]="getInputMultiplaQtdMinima()"
                    [max]="getGradeInputMaxNumberOfColumns()" />
                </div>
              </div>
              <span class="error" *ngIf="!verifyIfAnswerQuantityIsOk()">
                {{
                formControls.alternativas.value.length < formControls.qtdMaxima.value
                  ? "Quantidade máxima maior que quantidade de alternativas" : formControls.alternativas.value.length <
                  formControls.qtdMinima.value ? "Quantidade mínima maior que quantidade de alternativas" :
                  formControls.qtdMinima.value < 1 ? "Quantidade mínima deve ser maior ou igual a 1" :
                  formControls.qtdMinima.value>
                  formControls.qtdMaxima.value
                  ? "Quantidade máxima deve ser maior que mínima"
                  : ""
                  }}
              </span>
            </div>
          </div>
          <div class="dica">
            <div class="dica-content">
              <h3>DICA</h3>
              <h4>
                <b>Mínima</b> é a menor quantidade de respostas aceitas nesta
                pergunta. <b>Máxima</b> é a maior quantidade de respostas
                aceitas nesta pergunta.
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section class="escala-numerica" *ngIf="perguntaDoMesmoTipoQue(typesPer.ESCALA_NUMERICA)">
        <div class="selects-wrapper">
          <h3>Escala numérica</h3>
          <div class="seletores">
            <div class="select--body">
              <label for="intervaloInicio">Valor inicial*</label>
              <i class="fas fa-chevron-down"></i>
              <select name="intervaloInicio" id="intervaloInicio" (click)="handleSelectInicioOption($event)"
                [value]="formGroup.value.faixaPergunta.intervaloInicio">
                <option value="0">0</option>
                <option value="1">1</option>
              </select>
            </div>
            <div class="select--body">
              <label for="intervaloFim">Valor final*</label>
              <i class="fas fa-chevron-down"></i>
              <select name="intervaloFim" id="intervaloFim" (click)="handleSelectFimOption($event)"
                [value]="formGroup.value.faixaPergunta.intervaloFim">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        </div>
        <div class="dica">
          <div class="dica-content">
            <h3>DICA</h3>
            <h4>
              O valor inicial da escala fica ao lado esquerdo, já o valor final
              ao lado direito. Ao definir o valor inicial como 0 e o valor final
              como 10, teremos uma escala do número 0 até 10.
            </h4>
          </div>
        </div>
      </section>
      <grade-input *ngIf="perguntaDoMesmoTipoQue(typesPer.GRADE_UNICA)" (addGridRow)="handleAddGridRow()"
        (addGridColumn)="handleAddGridColumn()" (deleteGridRow)="handleDeleteGridRow($event)"
        (deleteGridColumn)="handleDeleteGridColumn($event)" (editGridColumnItemDescription)="
          handleEditGridColumnItemDescription($event)
        " (editGridRowItemDescription)="handleEditGridRowItemDescription($event)"
        [titulosGrade]="formGroup?.value?.titulosGrade" [alternativas]="formGroup?.value?.alternativas"
        [validationControls]="alternativasFormArray.controls" [rowValidationControls]="gradeLinhasFormArray.controls"
        string-no-break-line>
      </grade-input>
      <grade-input *ngIf="perguntaDoMesmoTipoQue(typesPer.GRADE_MULTIPLA)" (addGridRow)="handleAddGridRow()"
        (addGridColumn)="handleAddGridColumn()" (deleteGridRow)="handleDeleteGridRow($event)"
        (deleteGridColumn)="handleDeleteGridColumn($event)" (editGridColumnItemDescription)="
          handleEditGridColumnItemDescription($event)
        " (editGridRowItemDescription)="handleEditGridRowItemDescription($event)"
        [titulosGrade]="formGroup.value.titulosGrade" [alternativas]="formGroup.value.alternativas"
        (handleQuantidadeMinimaChange)="handleQuantidadeMinimaChange($event)"
        (handleQuantidadeMaximaChange)="handleQuantidadeMaximaChange($event)" [type]="typesPer.GRADE_MULTIPLA"
        [qtdMinima]="formGroup.value.qtdMinima" [qtdMaxima]="formGroup.value.qtdMaxima"
        [validationControls]="alternativasFormArray.controls" [rowValidationControls]="gradeLinhasFormArray.controls"
        string-no-break-line>
      </grade-input>
      <div class="quantidade-pergunta-wrapper" *ngIf="perguntaDoMesmoTipoQue(typesPer.GRADE_MULTIPLA)">
        <div class="group-inputs-wrapper">
          <h3>Quantidade de respostas</h3>
          <div class="validation-wrapper">
            <div class="group-inputs">
              <div class="input--body">
                <label for="qtd_minima_respostas">Mínima*</label>
                <input [class]="!verifyIfMinAlternativesAreOk() ? 'on-error' : ''" formControlName="qtdMinima"
                  type="number" id="qtd_minima_respostas" [min]="1" [max]="getGradeInputMultiplaQtdMaxima()" />
              </div>
              <div class="input--body">
                <label for="qtd_maxima_respostas">Máxima*</label>
                <input [class]="!verifyIfMaxAlternativesAreOk() ? 'on-error' : ''" formControlName="qtdMaxima"
                  type="number" id="qtd_maxima_respostas" [min]="getGradeInputMultiplaQtdMinima()"
                  [max]="getGradeInputMaxNumberOfColumns()" />
              </div>
            </div>
            <span class="error" *ngIf="!verifyIfAnswerQuantityIsOk()">
              {{
              formControls.alternativas.value.length < formControls.qtdMaxima.value
                ? "Quantidade máxima maior que quantidade de alternativas" : formControls.alternativas.value.length <
                formControls.qtdMinima.value ? "Quantidade mínima maior que quantidade de alternativas" :
                formControls.qtdMinima.value < 1 ? "Quantidade mínima deve ser maior ou igual a 1" :
                formControls.qtdMinima.value> formControls.qtdMaxima.value
                ? "Quantidade máxima deve ser maior que mínima"
                : ""
                }}
            </span>
          </div>
        </div>
        <div class="dica">
          <div class="dica-content">
            <h3>DICA</h3>
            <h4>
              <b>Mínima</b> é a menor quantidade de respostas aceitas nesta
              pergunta. <b>Máxima</b> é a maior quantidade de respostas aceitas
              nesta pergunta. Caso a pergunta seja obrigatória o valor mínimo dever maior ou igual a 1.
            </h4>
          </div>
        </div>
      </div>
      <div class="buttons-container">
        <button class="cancelar" type="button" (click)="handleCancelClick()">
          Cancelar
        </button>
        <button class="salvar" type="button" (click)="handleSave()" [disabled]="!formIsValid()">
          Salvar
        </button>
      </div>
    </div>
  </form>
</div>
