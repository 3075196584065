import { Injectable } from "@angular/core";
import { EGroupType, ICitation, IGroup, ISelectedAvulsesAndGroup } from "../../interfaces/audit-open-answer";
import { MatchPersistenceService } from "./match-persistance.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConsolidationService {
  constructor(private matchPersistenceService: MatchPersistenceService) {}

  /**
   * Seleciona o fluxo de combinação manual
   * __   CASE 1: Caso não haja grupos manuais, apenas consolida as respostas avulsas
   * __   CASE 2: Se não há grupo com o mesmo título, desmembrar os grupos manuais
   *      e desfazer as consolidações
   * __   CASE 3: Se há um grupo com o mesmo título (groupWithSameTitle) ele deve
   *      ser persistido e caso hajam outros grupos, estes devem ser desmembrados
   *      e inseridos dentro do groupWithSameTitle
   *
   * @param newGroup: novo grupo que será persistido
   * @param selectedAvulsesAndGroups: respostas avulsas e grupos selecionados
   */
  public async chooseMatchFlow(newGroup: IGroup, selectedAvulsesAndGroups: ISelectedAvulsesAndGroup, surveyId: number, questionId: number): Promise<IGroup> {
    const { avulses, groups } = selectedAvulsesAndGroups;
    const groupWithSameTitle = groups.find(g => g.title === newGroup.title); // Verifica se há um grupo com o mesmo título

    if (!groups.length) {
      return this.processManualMatch(newGroup, selectedAvulsesAndGroups);
    } else {
      if (!groupWithSameTitle) {
        const newAvulsesAndGroups = await this.undoGroupsForNewConsolidation(
          groups,
          avulses,
          surveyId,
          questionId
        )
        return this.processManualMatch(newGroup, newAvulsesAndGroups);
      } else {
        const groupsWithoutGroupWithSameTitle = groups.filter(g => g.title !== groupWithSameTitle.title) // Excluir o grupo com mesmo título
        const newAvulsesAndGroups = await this.undoGroupsForNewConsolidation(
          groupsWithoutGroupWithSameTitle,
          avulses,
          surveyId,
          questionId
        );
        return this.processManualMatch(groupWithSameTitle, newAvulsesAndGroups);
      }
    }
  }

  // desmembra os grupos para criar uma nova consolidação
  private async undoGroupsForNewConsolidation(groups: IGroup[], avulses: ICitation[], surveyId: number, questionId: number): Promise<ISelectedAvulsesAndGroup> {
    const avulsesAndGroups: ISelectedAvulsesAndGroup = {
      avulses: [...avulses],
      groups: []
    };

    const deletePromises = groups.map(async (g) => {
      if (g.typeGroup === EGroupType.MANUAL) {
        avulsesAndGroups.avulses.push(...g.citation);
        avulsesAndGroups.groups.push(...g.groups);
        return await this.matchPersistenceService.undoGroupById(surveyId, questionId, g.backId);
      } else {
        avulsesAndGroups.groups.push(g);
        return Promise.resolve();
      }
    });

    // Aguarda todas as deleções serem concluídas
    await Promise.all(deletePromises);

    return avulsesAndGroups;
  }

  private processManualMatch(group, selectedAvulsesAndGroups) {
    const { avulses, citationFromGroups, defaultGroups } =
      this.getSelectAvulseAndGroups(group, selectedAvulsesAndGroups);

    const newCitationArray = this.buildNewCitationArray(
      avulses,
      citationFromGroups,
    );

    const newGroup: IGroup = this.buildNewGroup(
      group,
      newCitationArray,
      defaultGroups,
      avulses
    );

    return newGroup;
  }

  /**
   * Obtem os grupos e avulsas que foram selecionadas
   */
  private getSelectAvulseAndGroups(group: IGroup, selectedAvulsesAndGroups) {
    const { avulses, groups } = selectedAvulsesAndGroups || {
      avulses: [],
      groups: [],
    };
    if (group.id) {
      groups.push(group);
    }
    //Recupera as alternativas que compõe grupos que foram criados manualmente
    const citationFromGroups = groups
      .filter(({ typeGroup }) => typeGroup === EGroupType.MANUAL)
      .reduce((acc, curr) => acc.concat(curr.citation), []);

    const defaultGroups: IGroup[] = this.createChildrenGroup(groups);

    return {
      avulses,
      citationFromGroups,
      defaultGroups,
    };
  }

  /**
   * Recupera os grupos criados por automatch para compor o array de filhas
   * retorna um IGroup[]
   */
  private createChildrenGroup(groups): IGroup[] {
    const uniqueDefaultGroups = groups.reduce((acc, group) => {
      if (group.typeGroup === EGroupType.AUTOMATICA) {
        acc.add(group);
      }

      group.groups?.forEach(subGroup => {
        if (subGroup.typeGroup === EGroupType.AUTOMATICA) {
          acc.add(subGroup);
        }
      });

      return acc;
    }, new Set<typeof groups[]>());

    return Array.from(uniqueDefaultGroups);
  }

  /**
   * Cria um novo array de citações sem dados repetidos
   */
  private buildNewCitationArray(
    avulses: ICitation[],
    citationFromGroups: ICitation[],
  ) {
    const newCitationSet = new Set([
      ...avulses,
      ...citationFromGroups,
    ]);
    return Array.from(newCitationSet);
  }

  /**
   * Cria uma nova consolidação manual
   */
  private buildNewGroup(group: IGroup, newCitation, defaultGroups: IGroup[], avulses: ICitation[]) {
    return {
      ...group,
      // Esse reduce combina os ids para criar id unico para o grupo
      id: newCitation.reduce(
        (acc, cur) => (!!acc ? `${acc}_${cur.id}` : cur.id),
        ""
      ),
      citation: newCitation,
      avulses: [...avulses],
      groups: [...defaultGroups],
    };
  }
}
