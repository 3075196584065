import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { TipoConteudo } from '../../conteudo/tipoConteudo';
import { AbstractPerguntaComponent } from '../abstractPerguntaComponent';

@Component({
  selector: 'app-escala-numerica-questionario',
  templateUrl: './escala-numerica.component.html',
  styleUrls: ['./escala-numerica.component.scss'],
})
export class EscalaNumericaComponent extends AbstractPerguntaComponent {

  /**
   * @override
   */
  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_ESCALA_NUMERICA;

  /**
   * @override
   */
  tipoPergunta: TipoPergunta = TipoPergunta.ESCALA_NUMERICA;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
