<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>

<form #form="ngForm" [formGroup]="formGroup"
  (ngSubmit)="handleSubmit()">
  <div class="content-form">
    <div class="form-info">

      <div class="header-content">
        <i class="fa-light fa-clipboard-list"></i>
        <h3>Meu perfil</h3>
      </div>

      <div class="header-content">
        <button type="button" (click)="handleShowModal('email')">
          <i class="fa-light fa-envelope"></i>
          <span>Redefinir e-Mail</span>
        </button>

        <button type="button" (click)="handleShowModal('password')">
          <i class="fa-light fa-lock"></i>
          <span>Redefinir senha</span>
        </button>
      </div>

    </div>

    <div class="form-content form-content-top">

      <span class="form-foto">
        <app-new-foto-uploader></app-new-foto-uploader>
        <h4>Administrador</h4>
      </span>

      <div class="form-nome" formGroupName="pessoa">
        <label for="">Nome*</label>
        <input
          mascara-primeiro-digito-sem-espaco placeholder="Ex.:manoel soares da silva"
          title="nome do administrador"
          id="nome"
          formControlName="nome"
          type="text"
          maxlength="50"
        >
        <span class="error-message" *ngIf="form.submitted && controls.pessoa.get('nome').errors">Insira o nome</span>
      </div>

      <div class="form-cpf" formGroupName="pessoa">
        <label for="">CPF*</label>
        <input
          placeholder="Ex.:111.222.333-96"
          title="cpf do administrador"
          type="text"
          mascara-cpf
          formControlName="cpf"
          readonly
        >
        <span class="error-message" [hidden]="!(form.submitted && controls.pessoa.get('cpf').errors)">CPF
          inválido</span>
      </div>

      <div class="form-email" formGroupName="usuario">
        <label for="">E-mail</label>
        <input
          id="email"
          formControlName="login" type="email"
          readonly
        >
      </div>

      <div class="form-telefone" formGroupName="contato">
        <label for="">Telefone</label>
        <input
          placeholder="Ex.:933221144"
          title="celular do administrador"
          formControlName="telefone"
          mascara-celular
          id="celular"
          type="text"
        >
        <span class="error-message" [hidden]="!(form.submitted && controls.contato.get('telefone').errors)">Insira um
          telefone válido</span>
      </div>
    </div>

  </div>

  <!-- modal de alterar a senha -->
  <app-password-reset
    *ngIf="showModal && modalType === 'password'"
    [formGroup]="formGroup"
    [controls]="controls"
    [isAlteracaoSenha]="isAlteracaoSenha"
    [showModal]="showModal"
    [notifyObject]="notifyObject"
    (handleShowModal)="handleShowModal()"
  >
  </app-password-reset>

  <!-- modal de alterar o e-mail -->
  <app-email-reset
    *ngIf="showModal && modalType === 'email'"
    [formGroup]="formGroup"
    [controls]="controls"
    [showModal]="showModal"
    [notifyObject]="notifyObject"
    (handleShowModal)="handleShowModal()"
  >
  </app-email-reset>

  <div class="buttons-form">
    <button type="button" (click)="handleBackPage()">Cancelar</button>
    <button type="submit">Salvar</button>
  </div>
</form>

<app-loading *ngIf="isLoading"></app-loading>
