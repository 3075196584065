import { IMessages } from "../interfaces";

export const successNotify: IMessages = {
  removeCitation: {
    title: "Citação removida com sucesso!",
    message: null,
  },
  persistUserConsolidation: {
    title: "Citações consolidadas com sucesso!",
    message: null,
  },
  undoConsolidation: {
    title: "Citações desconsolidadas com sucesso!",
    message: null,
  }
}
