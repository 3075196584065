import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  ElementRef,
} from "@angular/core";
import { removeSpecialCharacters } from "app/util/formatter";
import { containText } from "app/util/search/search";

// textos constantes
const withoutEntriesInfoMessage =
  "Não há dados para serem exibidos e/ou filtrados";

type componentRefName =
  | "CLIENTE"
  | "LOCALIDADE"
  | "RESULTADOS"
  | "AUDITORIA"
  | "DEFAULT";

// Representa um item selecionado
export interface SelectDataItem {
  value: number;
  label: string;
  hidden?: boolean;
  checked?: boolean;
}

// Representa um evento de seleção de um item
export interface SelectedItemEvent {
  // item selecionado
  item: SelectDataItem;
  // índice do ítem selecionado
  index: number;
}

function defaultFilterCallback(
  data: SelectDataItem[],
  query: string
): SelectDataItem[] {
  return data.filter((entry) => {
    const includes = removeSpecialCharacters(entry.label)
      .trim()
      .toLowerCase()
      .includes(removeSpecialCharacters(query.toLowerCase()).trim());
    return includes;
  });
}

@Component({
  selector: "app-filterable-select",
  templateUrl: "./filterable-select-component.html",
  styleUrls: ["./filterable-select-component.scss"],
})
export class NewFilterableSelect implements OnInit, OnChanges {
  @Input() usingBy: componentRefName = "DEFAULT";

  // label do seletor
  @Input() label: string = "Label";
  // placeholder para o seletor
  @Input() placeholder: string = "Selecione um item";
  // placeholder para o filtro interno do seletor
  @Input() filterPlaceholder: string = "Busque por itens";
  // mensagem de erro
  @Input() errorMessage: string = withoutEntriesInfoMessage;
  // lista de itens
  @Input() data: SelectDataItem[];
  // índice do item selecionado
  @Input() selectedItem: SelectDataItem;
  // com fechamento depois do seleção
  @Input() autoClose: boolean = true;
  // FIXME: não é assim que se faz
  @Input() filterCallback: (
    data: SelectDataItem[],
    query: string
  ) => SelectDataItem[] = defaultFilterCallback;
  //TODO: estilização como propriedade é inadequado
  @Input() backgroundColor: string = "rgb(248, 249, 253)";
  @Input() width: string = "400px";

  @Output() changeSelectedItem: EventEmitter<SelectedItemEvent> =
    new EventEmitter();

  @Output() changeInputText: EventEmitter<string> = new EventEmitter();

  @Output() onScrollBottom: EventEmitter<any> = new EventEmitter();

  // locais
  filteredData: SelectDataItem[] = [];
  searchText: string = "";
  showBody: boolean = false;
  changedByInput: boolean = false;

  constructor(private elem: ElementRef) {}

  ngOnInit() {
    this.refreshItems();
  }

  ngOnChanges() {
    this.refreshItems();
  }

  refreshItems() {
    if (this.data && Array.isArray(this.data)) {
      this.filteredData = this.data.map((v) => ({ ...v }));
    }
  }

  getClassSelector() {
    switch (this.usingBy) {
      case "CLIENTE":
        return "select-pesquisa";
      case "LOCALIDADE":
        return "select-pesquisa";
      case "RESULTADOS":
        return "select-default";
      case "AUDITORIA":
        return "select-auditoria";
      default:
        return "select-default";
    }
  }

  filterItems(txt: string) {
    this.filteredData = this.data.filter(
      (item) => item.label && containText(item.label, txt)
    );
  }

  // manipula apresentação do corpo do select
  handleShowBody() {
    this.showBody = !this.showBody;
  }

  /**
   * handle item select events
   * @param item selected item
   * @param itemIdx selected item index
   */
  handleItemSelect(item: SelectDataItem, itemIdx: number) {
    // seleção dos itens
    this.selectedItem = item;
    // criar e propagar o evento de seleção
    const selectedItemEvent: SelectedItemEvent = {
      item: this.selectedItem,
      index: itemIdx,
    };
    // propaga evento
    this.changeSelectedItem.emit(selectedItemEvent);
    // trata o fechamento da listagem se for permitido
    if (this.autoClose) {
      // reconstroe a lista
      this.refreshItems();
      // limpa os filtros
      this.cleanFilter();
      // fecha a listagem
      this.handleShowBody();
    }
  }

  cleanFilter() {
    this.searchText = null;
  }

  // // verificar quando input foi modificado por clique no input checkbox
  // onCheckboxChange() {
  //   this.changedByInput = true
  // }

  // lida com mudanças no texto no filtro
  handleChange(text: string) {
    this.searchText = text;
    setTimeout(() => {
      if (this.usingBy !== "RESULTADOS") {
        if (this.searchText) {
          this.filterItems(this.searchText);
        } else {
          this.refreshItems();
        }
      } else {
        this.changeInputText.emit(text);
      }
    }, 200);
  }

  getScrollBottom($event) {
    // console.log($event);
    this.onScrollBottom.emit($event);
  }

  // verifica checkbox marcado
  isChecked(item: SelectDataItem) {
    if (this.selectedItem) {
      if (this.selectedItem.value === item.value) {
        if (this.changedByInput) {
          setTimeout(() => {
            this.changedByInput = false;
          }, 0);
          return;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  // verificar se o clique foi dentro do componente ou fora.
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.elem.nativeElement.contains(event.target)) {
      if (this.showBody) {
        this.handleShowBody();
      }
    }
  }
}
