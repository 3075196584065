export enum TipoInput {
  // D E S C R I C A O
  DESCRICAO_CLIENTE,
  DESCRICAO_TITULO,
  DESCRICAO_TITULO_CURTO,
  DESCRICAO_OBJETIVO,
  DESCRICAO_TIPO,
  DESCRICAO_PERMITIR_GRAVACAO,
  DESCRICAO_DISTANCIA,
  DESCRICAO_AMOSTRAS,
  DESCRICAO_TEMPO_MINIMO,
  DESCRICAO_QTD_DIARIA_ENTREVISTAS,
  DESCRICAO_QTD_MINIMA_CONSOLIDACAO,
  DESCRICAO_QTD_ENTREVISTAS_DESCARTE,
  // Q U E S T I O N A R I O
  QUESTIONARIO_TEXTO_INICIAL,
  QUESTIONARIO_SECAO_NOME,
  QUESTIONARIO_SECAO_DESCRICAO,
  QUESTIONARIO_SECAO_PERGUNTA,
  QUESTIONARIO_SECAO_PERGUNTA_DESCRICAO,
  QUESTIONARIO_SECAO_PERGUNTA_TIPO,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_SELECAO_UNICA,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_SELECAO_MULTIPLA,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_SELECAO_MULTIPLA_ORDEM_CITACAO,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_ESCALA_NUMERICA,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_GRADE_SELECAO_UNICA,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_GRADE_SELECAO_MULTIPLA,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_RESPOSTA_TIPO,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_ALTERNATIVA_DESCRICAO,
  QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_GRADE_TITULO,
  // M A R C A C A O
  MARCACAO_SECAO_PERGUNTA_ALTERNATIVA_CODIGO_MARCACAO,
  MARCACAO_COTA_PERCENTUAL,
  MARCACAO_COTA_VALOR,
}
