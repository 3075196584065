<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card-marcacao
    [icone]="'fa fa-video-camera'"
    [titulo]="'PERGUNTA DE VÍDEO'"
    [nomePlaceHolder]="'Título da pergunta de vídeo?'"
    [formGroup]="formGroup"
  ></app-pergunta-card-marcacao>

</div>