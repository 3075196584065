import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-operador-card-wrapper',
  templateUrl: './operador-card-wrapper.component.html',
  styleUrls: ['./operador-card-wrapper.component.scss']
})
export class OperadorCardWrapperComponent implements OnInit {
  //Falta ser definido o modelo de operador
  @Input() operador: any;

  ngOnInit() {
  }

}
