import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ModalService } from "app/componentes/modal/modal.service";
import { SectionOrdenacao } from "../secao-listagem/secao-listagem.component";

interface QuestionAlternatives {
  id: number;
  ordem: number;
  descricao: string;
  orderJumpedQuestion: number;
  comportamento: string;
}

interface ControllerQuestion {
  id: number;
  idPergunta: number;
  codigoMarcacao?: number;
  ordem: number;
  nome: string;
  hasFlag: boolean;
  orderJumpedQuestion: number;
  alternativas: QuestionAlternatives[];
}

interface ControllerSection {
  id: number;
  canOpen: boolean;
  ordem: number;
  nome: string;
  descricao: string;
  hasFlag: boolean;
  perguntas: ControllerQuestion[];
}

interface Marcacao {
  id?: number;
  codigoMarcacao?: {
    id: number;
  };
  perguntaDestino?: {
    id: number;
  };
}

@Component({
  selector: "app-modal-ordenacao-questionario",
  templateUrl: "./modal-ordenacao-questionario.component.html",
  styleUrls: ["./modal-ordenacao-questionario.component.scss"],
})
export class ModalOrdenacaoQuestionarioComponent implements OnInit {
  // Seções
  @Input() secao: SectionOrdenacao[];

  // Evento emitido para o componente pai onde permite fechar o modal
  @Output() closeModal = new EventEmitter();
  //
  @Output() saveReOrder = new EventEmitter<SectionOrdenacao[]>();

  // Controlador das Seções
  controllerSection: ControllerSection[] = [];

  // payload final para expor ao componente pai
  payloadSectionData: SectionOrdenacao[];

  backupSecao: SectionOrdenacao[];

  // Controller to Cancel Action
  hasBeenOrderBySection: boolean = false;

  hasBeenOrderByQuestion: boolean = false;

  // Controller to Save Action
  hasBeenOrderBySectionWithFlag: boolean = false;

  hasBeenOrderByQuestionWithFlag: boolean = false;

  // Botão salvar - handler
  canSaveDragAndDropChanges: boolean = false;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    if (this.secao) {
      this.backupSecao = this.secao;
      this.secao.map((item: SectionOrdenacao, itemIndex) => {
        if (
          !this.controllerSection.find(
            (itemSection) => itemSection.id === item.id
          )
        ) {
          this.controllerSection.push({
            id: item.id,
            canOpen: false,
            ordem: itemIndex + 1,
            nome: item.nome,
            descricao: item.descricao,
            perguntas: [],
            hasFlag: item.elementosSecao.some(
              (item) =>
                item.pergunta.alternativas.some(
                  (alternativa) =>
                    alternativa.comportamento === "PULAR_PARA_PERGUNTA" ||
                    alternativa.comportamento === "FINALIZAR_ENTREVISTA"
                ) ||
                item.pergunta.comportamento === "PULAR_PARA_PERGUNTA" ||
                item.pergunta.comportamento === "FINALIZAR_ENTREVISTA"
            ),
          });

          // Mapeando as perguntas
          item.elementosSecao.map((perguntas, perguntasIndex) => {
            this.controllerSection[itemIndex] = {
              ...this.controllerSection[itemIndex],
              perguntas: [
                ...this.controllerSection[itemIndex].perguntas,
                {
                  id: perguntas.id,
                  ordem: perguntas.ordem,
                  nome: perguntas.pergunta.nome,
                  idPergunta: +perguntas.pergunta.codigoMarcacao,
                  codigoMarcacao: +perguntas.pergunta.codigoMarcacao,
                  alternativas: [],
                  orderJumpedQuestion: !!perguntas.pergunta.marcacaoPergunta
                    ? this.getOrderJumpByQuestionLabel(
                        perguntas.pergunta.marcacaoPergunta.perguntaDestino.id
                      )
                    : 0,
                  hasFlag:
                    perguntas.pergunta.comportamento ===
                      "FINALIZAR_ENTREVISTA" ||
                    perguntas.pergunta.comportamento ===
                      "PULAR_PARA_PERGUNTA" ||
                    (perguntas.pergunta.alternativas &&
                      perguntas.pergunta.alternativas.some(
                        (alternativa) =>
                          alternativa.comportamento ===
                            "FINALIZAR_ENTREVISTA" ||
                          alternativa.comportamento === "PULAR_PARA_PERGUNTA"
                      )),
                },
              ],
            };

            // Mapeando as alternativas
            perguntas.pergunta.alternativas.map((alternativa) => {
              this.controllerSection[itemIndex].perguntas[
                perguntasIndex
              ].alternativas = [
                ...this.controllerSection[itemIndex].perguntas[perguntasIndex]
                  .alternativas,
                {
                  orderJumpedQuestion:
                    alternativa.marcacao &&
                    alternativa.comportamento !== "FINALIZAR_ENTREVISTA"
                      ? this.getOrderJumpByQuestionLabel(
                          alternativa.marcacao.perguntaDestino.id
                        )
                      : 0,
                  id: alternativa.id,
                  ordem: alternativa.ordem,
                  descricao: alternativa.descricao,
                  comportamento: alternativa.comportamento,
                },
              ];
            });
          });
        }
      });
    }
  }

  /**
   * Função responsável por colocar a ordem de pulo das perguntas
   */
  getOrderJumpByQuestionLabel(marcacao: number): number {
    const elements = this.secao.filter((secao) =>
      secao.elementosSecao.find(
        (elemento) => Number(elemento.pergunta.codigoMarcacao) === marcacao
      )
    )[0];

    const order = elements.elementosSecao[0].ordem;

    return order;
  }

  /**
   * Função responsável por enviar um evento para o componente pai
   * onde solicita que o modal seja fechado
   */
  handleCloseModal(): void {
    const label =
      this.hasBeenOrderByQuestion && this.hasBeenOrderBySection
        ? "uma pergunta e uma seção"
        : this.hasBeenOrderByQuestion && !this.hasBeenOrderBySection
        ? "uma pergunta"
        : this.hasBeenOrderBySection &&
          !this.hasBeenOrderByQuestion &&
          "uma seção";

    if (this.canSaveDragAndDropChanges) {
      this.modalService.showModal({
        title: "Perda na configuração",
        messageModal: `Você reordernou ${label}, <b class="info-purple">continuar</b> com esta ação impacta na perda da configuração`,
        btnTitlePositive: "Continuar",
        btnTitleNegative: "Cancelar",
        imgFile: "icons/icon-warning.svg",
        positiveCallback: () => this.closeModal.emit(),
      });
    } else {
      this.closeModal.emit();
    }
  }

  /**
   * Função responsável por abrir e fechar a listagem de perguntas
   * A função requer um index para buscar no array de seções e realizar a mudança
   * do atributo canOpen
   */
  handleShowQuestions(index) {
    this.controllerSection[index].canOpen =
      !this.controllerSection[index].canOpen;
  }

  // Função para verificar se alguma pergunta da seção como pergunta e a ordem é menor
  // Está função é necessária para detectar se a mudança afeta a lógica para
  // mostrar a mensagem correta (exsitem dois tipos em caso de ordenação).
  isQuestionHaveOrderSmallerThanMarcacao(marcacao, ordem): boolean {
    const elements = this.backupSecao.filter((secao) =>
      secao.elementosSecao.find(
        (elemento) =>
          Number(elemento.pergunta.codigoMarcacao) ===
          marcacao.perguntaDestino.id
      )
    )[0];

    const marcacaoOrdem = elements.elementosSecao[0].ordem;

    if (ordem <= marcacaoOrdem) {
      this.hasBeenOrderBySectionWithFlag = true;
      this.hasBeenOrderByQuestionWithFlag = true;
      return true;
    } else {
      this.hasBeenOrderBySectionWithFlag = this.hasBeenOrderBySectionWithFlag
        ? this.hasBeenOrderBySectionWithFlag
        : false;
      this.hasBeenOrderByQuestionWithFlag = this.hasBeenOrderByQuestionWithFlag
        ? this.hasBeenOrderByQuestionWithFlag
        : false;

      return false;
    }
  }

  // Função responsável por realizar o drag and drop das seções
  dropSecoes(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.controllerSection,
      event.previousIndex,
      event.currentIndex
    );

    this.hasBeenOrderBySection = true;

    // this.hasBeenOrderBySectionWithFlag = this.controllerSection[
    //   event.currentIndex
    // ].hasFlag
    //   ? true
    //   : false;

    this.handleReOrderQuestionsOrdemNumber();
  }

  // Função responsável por realizar o drag and drop das perguntas de uma seção
  dropPerguntas(event: CdkDragDrop<string[]>, index: number) {
    moveItemInArray(
      this.controllerSection[index].perguntas,
      event.previousIndex,
      event.currentIndex
    );

    this.hasBeenOrderByQuestion = true;
    // this.hasBeenOrderByQuestionWithFlag = this.controllerSection[index]
    //   .perguntas[event.currentIndex].hasFlag
    //   ? true
    //   : false;
    this.handleReOrderQuestionsOrdemNumber();
  }

  /**
   * Função responsável por atribuir os valores corretos da ordem nas perguntas
   * Ela será chamada após o dropSecoes ou dropPerguntas ser executado e modificar o array
   * Irá percorrer cada seção, e dentro delas modificar o valor da ordem da pergunta
   */
  handleReOrderQuestionsOrdemNumber(): void {
    let contador = 1;
    this.payloadSectionData = [];

    for (let secaoI = 0; secaoI < this.controllerSection.length; secaoI++) {
      const originalSection = this.secao.find(
        (item) => item.id === this.controllerSection[secaoI].id
      );

      this.payloadSectionData.push({
        ...originalSection,
        hash: "",
        ordem: secaoI + 1,
      });

      this.payloadSectionData[secaoI].elementosSecao = [];

      for (
        let perguntaI = 0;
        perguntaI < this.controllerSection[secaoI].perguntas.length;
        perguntaI++
      ) {
        this.controllerSection[secaoI].perguntas[perguntaI] = {
          ...this.controllerSection[secaoI].perguntas[perguntaI],
          ordem: contador,
        };

        // Pegando o elementoSecao para adicionar no array
        const elementoSecao = this.secao
          .find((secao) => secao.id === this.controllerSection[secaoI].id)
          .elementosSecao.find(
            (pergunta) =>
              pergunta.id ===
              this.controllerSection[secaoI].perguntas[perguntaI].id
          );

        const changedAlternativas = elementoSecao.pergunta.alternativas.map(
          (item) => ({
            ...item,
            marcacao:
              item.marcacao && item.comportamento !== "FINALIZAR_ENTREVISTA"
                ? this.isQuestionHaveOrderSmallerThanMarcacao(
                    item.marcacao,
                    this.controllerSection[secaoI].perguntas[perguntaI].ordem
                  )
                  ? null
                  : null
                : null,
            comportamento:
              item.comportamento === "PULAR_PARA_PERGUNTA"
                ? "CONTINUAR_ENTREVISTA"
                : item.comportamento,
          })
        );

        this.payloadSectionData[secaoI].elementosSecao.push({
          ...elementoSecao,
          pergunta: {
            ...elementoSecao.pergunta,
            alternativas: changedAlternativas,
            marcacaoPergunta: elementoSecao.pergunta.marcacaoPergunta
              ? this.isQuestionHaveOrderSmallerThanMarcacao(
                  elementoSecao.pergunta.marcacaoPergunta,
                  this.controllerSection[secaoI].perguntas[perguntaI].ordem
                )
                ? null
                : null
              : null,
          },
          ordem: this.controllerSection[secaoI].perguntas[perguntaI].ordem,
        });

        contador++;
      }
    }

    this.backupSecao = this.payloadSectionData;

    this.canSaveDragAndDropChanges = true;
  }

  handleSaveReOrderedSectionAndQuestions() {
    let title = "";
    let label = "";
    let finalDescription = "";

    if (
      !this.hasBeenOrderBySectionWithFlag &&
      !this.hasBeenOrderByQuestionWithFlag
    ) {
      if (!this.hasBeenOrderByQuestion && this.hasBeenOrderBySection) {
        label = "uma seção";
        finalDescription = "na mudança da ordem das seções";
      } else if (this.hasBeenOrderByQuestion && !this.hasBeenOrderBySection) {
        label = "uma pergunta";
        finalDescription = "na mudança da ordem das perguntas";
      } else {
        label = "uma seção e uma pergunta";
        finalDescription = "na mudança da ordem das seções e perguntas";
      }

      title = "Reordenação do questionário";
    } else {
      if (!this.hasBeenOrderByQuestion && this.hasBeenOrderBySection) {
        label = "uma seção com lógica configurada";
        finalDescription =
          "na perda da configuração e reordenação do questionário";
      } else if (this.hasBeenOrderByQuestion && !this.hasBeenOrderBySection) {
        label = "uma pergunta com lógica configurada";
        finalDescription =
          "na perda da configuração e reordenação do questionário";
      } else {
        label = "uma seção e uma pergunta com lógica configurada";
        finalDescription =
          "na perda da configuração e reordenação do questionário";
      }

      title = "Excluir lógica";
    }

    this.modalService.showModal({
      title,
      messageModal: `Você reordenou ${label}, <b class="info-purple">continuar</b> com está ação impacta ${finalDescription}`,
      btnTitlePositive: "Continuar",
      btnTitleNegative: "Cancelar",
      imgFile: "icons/icon-warning.svg",
      positiveCallback: () => {
        this.saveReOrder.emit(this.payloadSectionData);
        this.canSaveDragAndDropChanges = false;
        this.handleCloseModal();
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "secao":
            this.ngOnInit();
        }
      }
    }
  }

  // ngOnDestroy(): void {

  //   this.secao = [];
  //   this.controllerSection = [];
  //   this.canSaveDragAndDropChanges = false;
  // }
}
