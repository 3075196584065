<label
  for="upload-photo"
  title="selecionar foto"
  [ngStyle]="{'height': newSize['height'], 'width': newSize['width']}"
>
  <img
    *ngIf="updatedImage"
    [src]="url"
    [ngClass]="'image_selected'"
    alt="imagem de upload"
    [ngStyle]="{'max-height': newSize['height'], 'max-width': newSize['width'], 'border-width': newSize['border']}"
  />

  <i *ngIf="!updatedImage" class="fa-duotone fa-user"></i>

  <input id="upload-photo" type="file" accept="image/jpeg" #fileInput (change)="onFileSelected($event);">

  <div class="button-options">
    <button [ngClass]="{'remove-image': true, 'show': updatedImage}" (click)="clearSelectedImage()" type="button">
      <i class="fa-light fa-trash"></i>
    </button>

    <i [ngClass]="'fa-sharp fa-light fa-pen'" [ngStyle]="{'height': newSize['iconHeight'], 'width': newSize['iconWidth'], 'font-size': newSize['iconFontSize']}"></i>
  </div>

</label>
