import { Cliente } from '../../../../cliente/cliente';
import { AnalisePesquisa } from '../../model/analisePesquisa';
import { ConfiguracaoPesquisa } from '../../model/configuracaoPesquisa';
import { DescricaoPesquisa } from '../../model/descricaoPesquisa';
import { TipoStatusPesquisa } from '../../model/statusPesquisa';
import { ActionType } from '../actionType';
import { PassoCadastro } from '../passoCadastro';
import { AbstractAction } from './abstractAction';

export class AlterarStatusPesquisa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.CARACTERISTICAS;
  readonly type = ActionType.ALTERAR_STATUS_PESQUISA;

  constructor(status: TipoStatusPesquisa) {
    super();
    this.payload = {
      status,
    };
    this.isReplayable = true;
  }
}

export class AlterarCliente extends AbstractAction {

  readonly passoCadastro = PassoCadastro.CARACTERISTICAS;
  readonly type = ActionType.ALTERAR_CLIENTE;

  constructor(estado: Cliente) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }

}

export class AlterarDescricaoPesquisa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.CARACTERISTICAS;
  readonly type = ActionType.ALTERAR_DESCRICAO_PESQUISA;

  constructor(estado: DescricaoPesquisa) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class AlterarConfiguracaoPesquisa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.CARACTERISTICAS;
  readonly type = ActionType.ALTERAR_CONFIGURACAO_PESQUISA;

  constructor(estado: ConfiguracaoPesquisa) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class AlterarAnalisePesquisa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.CARACTERISTICAS;
  readonly type = ActionType.ALTERAR_ANALISE_PESQUISA;

  constructor(estado: AnalisePesquisa) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }

}

export class AlterarTitulos extends AbstractAction {

  readonly passoCadastro = PassoCadastro.CARACTERISTICAS;
  readonly type = ActionType.ALTERAR_TITULOS;

  constructor(titulo: string, tituloCurto: string, textoInicial: string) {
    super();
    this.payload = {
      titulo,
      tituloCurto,
      textoInicial,
    };
    this.isReplayable = true;
  }

}
