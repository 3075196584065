import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IItemBreadcrumb } from 'app/componentes/breadcrumb/breadcrumb.interface';
import validadorCpf from 'app/util/validador/validadorCpf';
import { EMAIL_REGEX, PHONE_MIN_LENGTH } from '../constants/cadastro';
import { ModalService } from 'app/componentes/modal/modal.service';
import { ClienteService } from '../services/cliente.service';
import validadorCnpj from 'app/util/validador/validadorCnpj';
import { createClientPayload, updateClientPayload } from '../utils/buildClientPayload';
import { IClientCreateOrEditPayload, IClientGetDataPayload } from '../interfaces/clientForm';
import { NotificatorService } from 'app/notificador/notificator.service';
import { ErrorHandlerService } from 'app/servico/requestService/error-handler.service';
import { ClientNavigateService } from '../services/cliente-navigate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { stringFormatter } from 'app/util/misc/stringFormatter';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {
  @ViewChild("statusDropdown", { static: true }) statusDropdown;

  emailRegex = EMAIL_REGEX;

  dataBreadcrumb: IItemBreadcrumb[];
  form: FormGroup;
  isEdicao: boolean = false;
  previousValue;
  isLoading: boolean = false;
  submitted: boolean = false;
  editCheckboxValue: boolean = false;
  statusIsOpen = false;
  cancelCheckboxValue: boolean = false;
  clientId: number;
  hasChange: boolean = false;
  cepErrorControl: boolean = false;
  newEmail: string;
  invalidPhone: boolean = false;

  personAndUserIds = {
    userId: null,
    personId: null,
    addresId: null
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private clienteService: ClienteService,
    private notificator: NotificatorService,
    private errorHandlerService: ErrorHandlerService,
    private clientNavigateService: ClientNavigateService
  ) { }

  ngOnInit(): void {
    this.form = this.buildForm();
    this.isEdicao = this.activatedRoute.snapshot.queryParams.hasOwnProperty('id') ? true : false;

    if(this.isEdicao) {
      this.clientId = this.activatedRoute.snapshot.queryParams?.id
      this.buildFormEditMode();
    }

    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Clientes",
        itemLink: "/cliente-beta",
        active: false,
      },
      {
        itemName: `${this.isEdicao ? "Editar cliente" : "Adicionar cliente"}`,
        itemLink: `/cliente-beta/${this.isEdicao ? "atualizar" : "cadastro"}`,
        active: true,
      },
    ];

    this.onValueChange();
    this.modalInputControl();
  }

  /**
   * Controla o valor do input da modal e atribui as devidas validações
   */
  modalInputControl() {
    this.modalService.getInput().subscribe({
      next: (inputValue) => {
          this.newEmail = inputValue;

          if(inputValue !== null && !inputValue.length) {
            this.modalService.handleInputErrors(['Campo obrigatório']);
          } else {
            this.modalService.cleanInputErrors();
          }
        }
    })
  }

  /**
   * Realiza a construção do formulário com os dados e validações inciais
   */
  buildForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      name: ["", [Validators.required, Validators.maxLength(70)]],
      document: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
      cep: [""],
      address: [""],
      number: [""],
      complement: [""],
      responsible: [null, [Validators.required, Validators.maxLength(70)]],
      phone: [null],
      status: [true, [Validators.required]],
    });
  }

  /**
   * Realiza a construção do formulário com os dados retornados do backend
   */
  buildFormEditMode() {
    this.isLoading = true;
    this.clienteService.getClientData(this.clientId).subscribe({
      next: (data: IClientGetDataPayload) => {
        const clientIsPj = !!data.pessoa.cnpj;
        const clientAdress = data.pessoa.endereco;
        this.form.setValue({
          id: data.id,
          name: data.pessoa.nome,
          document: clientIsPj ? data.pessoa.cnpj : data.pessoa.cpf,
          email: data.usuario.login,
          cep: clientAdress.cep,
          address: clientAdress.logradouro,
          number: clientAdress.numero,
          complement: clientAdress.complemento,
          responsible: clientIsPj ? data.representante.nome : null,
          phone: clientIsPj ? data.representante.telefone : null,
          status: data.usuario.ativo
        });
        this.personAndUserIds = {
          userId: data?.usuario.id,
          personId: data?.pessoa.id,
          addresId: data?.pessoa.endereco.id
        }
        this.previousValue = this.form.value;
      },
      error: (err) => {
        this.isLoading = false;
        this.errorHandlerService.handleError(err, 'Ocorreu um erro ao recuperar o cliente');
      },
      complete: () => {
        this.isLoading = false;
        this.onValueChange()
        this.hasChange = false;
      }
    })
  }

  /**
   * Verifica se o cliente é do tipo PJ
   * @returns true or false
   */
  isPJ(): boolean {
    return this.form?.get('document').value.length === 18;
  }

  /**
   * Intercepta as mudanças no formulario para saber se o usuário alterou algo durante o cadastro/edição
   */
  onValueChange(){
    const initialValue = this.form.value;
    this.form.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(initialValue).some(key => this.form.value[key] != initialValue[key])
    });
  }

  /**
   * Aplica copy de erro ou chama função de validação do CEP
   * de acordo com tamanho do parâmetro (length)
   */
  handleInputCep() {
    const cep = this.form.get('cep')?.value
    switch (cep.length) {
      case 0: this.cepErrorControl = false;
      break
      case 9: this.cepValidator(cep)
      break
      default: this.cepErrorControl = true;
    }
  }

  /**
   * Se CEP for valido preenche o campo de endereço
   */
  async cepValidator(cep: string) {
    try {
      const result = await this.clienteService.BuscaCep(cep)

      this.cepErrorControl = !!result.erro;
      if(!this.cepErrorControl) {
        this.form.get('address')?.setValue(result.logradouro);
      } else {
        this.form.get('address')?.setValue('');
      }

    } catch (err) {
      this.errorHandlerService.handleError(err, 'Ocorreu um erro ao buscar o CEP');
    }
  }

  /**
   * Captura o evento de entrada no input de documento (cpf/cnpj)
   * Identifica se houve o event "paste" durante a inserção dos dados
   * @param event
   */
  handleInputDoc(event) {
    let pasteControl = false;
    if (event.type == "paste") {
      pasteControl = true;
    }
    setTimeout(() => {
      this.changeDocInput({
        value: event.target.value,
        paste: pasteControl
      })
    }, 100)
  }

  /**
   * Adiciona e/ou remove a validação de CPF/CNPJ de acordo com o tamanho do valor digitado no input
   *
   * Toda vez que for chamada remove a validação de CPF e CNPJ e atualiza essas remoções, após isso verifica o tamanho e adiciona a validação correspondente
   * A formatação visual é realizada através da propriedade mask="CPF_CNPJ" da lib ngx-mask incluida diretamente no input
   */
  changeDocInput(event) {
    const documentLengthControl = event["value"].length;
    const paste = event["paste"];
    if (paste) {
      this.form.get("document").removeValidators(validadorCpf);
      this.form.get("document").removeValidators(validadorCnpj);
      this.form.get("document").updateValueAndValidity();
    }
    if (documentLengthControl > 14) {
      this.form.get("document").removeValidators(validadorCpf);
      this.form.get("document").setValidators(validadorCnpj);
    } else {
      this.form.get("document").removeValidators(validadorCnpj);
      this.form.get("document").setValidators(validadorCpf);
    }
  }

  // Controla o valor do campo status
  handleStatusClick(type: boolean): void {
    this.form.get("status")?.setValue(type);
  }

  // realiza o toggle do select de status
  toggleStatus(): void {
    this.statusIsOpen = !this.statusIsOpen;
  }

  /**
   * Escuta evento de click e fecha select quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.statusDropdown.nativeElement.contains(event.target)) {
      if (this.statusIsOpen) {
        this.toggleStatus();
      }
    }
  }

  /**
   * Encerra o cadastro
   */
  handleClose(): void {
    this.modalService.getCheckbox().subscribe({next: (data) => this.cancelCheckboxValue = data})
    if(this.hasChange && !this.cancelCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-door-open',
        title: 'Sair sem salvar',
        messageModal: 'Ao sair, as informações inseridas serão descartadas. Deseja mesmo continuar?',
        btnTitlePositive: 'Sair sem salvar',
        btnTitleNegative: 'Voltar',
        checkbox: true,
        positiveCallback: () => this.router.navigate(["/cliente-beta"])
      })
    } else {
      this.router.navigate(["/cliente-beta"]);
    }
  }

  /**
   * Toca todos os campos do formulario para indicar os erros para o usuário
   * @param form: Atributo que representa o formulario
   */
  markFieldsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);

      if (control instanceof FormGroup) {
        this.markFieldsAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
   * Retorna o texto da modal baseado na situação do operador (ativo/inativo)
   * @returns mensagem da modal
   */
  getRegisterModalText(): string {
    if(this.form.get('status')?.value) {
      return `Deseja também ativar o cadastro de ${this.form.get('name')?.value}?<br> Um e-mail de definição de senha será enviado para finalização da ativação.`
    } else {
      return 'Você está adicionando um cliente com a situação <b>Inativo</b>. Deseja mesmo continuar?'
    }
  }

  /**
   * Remove as validações do campo 'responsible' caso o cliente não seja PJ
   */
  updateResponsibleField(): void {
    if (!this.isPJ()) {
      this.form.get('responsible').clearValidators();
      this.form.get('responsible').updateValueAndValidity();
    }
  }

  /**
   * Realiza a requisição ao backend para persistir os dados do novo cliente
   */
  createNewClient(): void {
    const clientPayload: IClientCreateOrEditPayload = createClientPayload(this.form.value, this.isPJ())
    this.isLoading = true;

    this.clienteService.createClient(clientPayload).subscribe({
      next: (data) => {
        this.clientNavigateService.setClientId(data.id)
      },
      error: (err) => {
        this.isLoading = false;
        const requestInfo = err?.error;
        if(requestInfo.status === 400) {
          this.notificator.showError('Ocorreu um erro ao cadastrar o cliente', requestInfo.error.errors[0])
        } else {
          this.errorHandlerService.handleError(err, 'Ocorreu um erro ao cadastrar o cliente');
        }
      },
      complete: () => {
        this.isLoading = false;
        this.notificator.showInfo(
          'Cliente adicionado!',
          this.form.get('status')?.value ? 'E-mail de definição de senha enviado!' : ''
        );
        this.router.navigate(["/cliente-beta"]);
      }
    })
  }

  /**
   * CASE: Edita o cliente e o ativa
   */
  editWithActivation() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-toggle-large-off',
        title: 'Ativar cliente',
        messageModal: `Deseja mesmo alterar a situação de ${this.form.get('name')?.value} para ativo?`,
        btnTitlePositive: 'Ativar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.clientUpdate()
      });
    } else {
      this.clientUpdate()
    }
  }

  /**
   * CASE: Edita o cliente e o inativa
   */
  editWithInactivation() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-circle-xmark',
        title: 'Inativar cliente',
        messageModal: `Deseja mesmo inativar o cliente <b>${this.form.get('name')?.value}</b>?`,
        btnTitlePositive: 'Inativar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.clientUpdate()
      });
    } else {
      this.clientUpdate()
    }
  }

  /**
   * Controla todos os casos de salvamento numa edição de cliente
   */
  editCases() {
    if(this.previousValue.status !== this.form.get('status').value) {
      if(this.form.get('status').value === true) {
        this.editWithActivation(); //ativando cliente
      } else {
        this.editWithInactivation(); //inativando cliente
      }
    } else {
      this.onlyEdit();
    }
  }

  /**
   * CASE: Apenas edição sem alteração no status do cliente
   */
  onlyEdit() {
    this.modalService.getCheckbox().subscribe({next: (data) => this.editCheckboxValue = data})
    if(this.hasChange && !this.editCheckboxValue) {
      this.modalService.showModal({
        icon: 'fa-regular fa-pen-to-square',
        title: 'Salvar edição',
        messageModal: 'Deseja mesmo salvar as alterações realizadas neste cliente?',
        btnTitlePositive: 'Salvar',
        btnTitleNegative: 'Cancelar',
        checkbox: true,
        positiveCallback: () => this.clientUpdate()
      });
    } else {
      this.clientUpdate()
    }
  }

  /**
   * Realiza a requisição para persistir as alterações realizadas no cliente
   */
  clientUpdate() {
    const payload: IClientCreateOrEditPayload = updateClientPayload(this.form.value, this.isPJ(), this.personAndUserIds);
    this.isLoading = true;
    this.clienteService.updateClient(payload).subscribe({
      complete: () => {
        this.isLoading = false;
        this.notificator.showInfo(
          'Alterações salvas!',
          null
        );
        this.clientNavigateService.setClientId(payload.id)
        this.router.navigate(["/cliente-beta"]);
      },
      error: (err) => {
        this.isLoading = false;
        this.errorHandlerService.handleError(err, 'Erro ao salvar!');
      }
    })
  }


  /**
   * Verifica se o campo de telefone está valido
   * A validação só será aplicada caso o usuário insira algum valor no input e tente realizar um submit
   * após o submit a validação estara sempre ativa
   */
  validatePhoneInput() {
    const phoneValue = this.form.get('phone')?.value;

    if(phoneValue && this.isPJ() && this.submitted) {
      const phoneOnlyNumbers = String(stringFormatter.onlyNumbers(phoneValue));

      if(phoneOnlyNumbers.length < PHONE_MIN_LENGTH) {
        this.invalidPhone = true;
      } else if (phoneOnlyNumbers.length > PHONE_MIN_LENGTH) {
        this.invalidPhone = false;
      }
    }
  }

  /**
   * Envia os dados de cadastro do cliente ao back-end
   */
  handleSubmit(): void  {
    this.submitted = true;
    this.updateResponsibleField();
    this.validatePhoneInput();
    this.markFieldsAsTouched(this.form);
    if(this.form.status === 'VALID' && !this.cepErrorControl && !this.invalidPhone) {
      if(this.isEdicao) {
        this.editCases();
      } else {
        this.modalService.showModal({
          icon: 'fa-regular fa-clipboard-user',
          title: 'Adicionar cliente',
          messageModal: this.getRegisterModalText(),
          btnTitlePositive: this.form.get('status')?.value ? 'Ativar' :  'Continuar',
          btnTitleNegative: 'Cancelar',
          positiveCallback: () => this.createNewClient()
        });
      }
    }
  }


  /**
   * Chama a modal para troca do e-mail de um funcionário cadastrado.
   */
  handleChangeEmail() {
    this.modalService.showModal({
      icon: 'fa-regular fa-envelope',
      title: 'Alteração de e-mail',
      messageModal:
      `Uma mensagem de confirmação será enviada para o novo e-mail do cliente. <br><br>
      Novo e-mail*:`,
      btnTitlePositive: 'Alterar',
      btnTitleNegative: 'Cancelar',
      close: false,
      inputConfig: {
        show: true,
        placeholder: 'mario@email.com',
        eyeIcon: false,
      },
      positiveCallback: () =>
      this.newEmail?.length
        ? this.changeEmail()
        : this.modalService.handleInputErrors(['Campo obrigatório']),
    });
  }

  /**
   * Atualiza o formulário com o novo email alterado
   */
  updateEmailExchange() {
    this.form.patchValue({
      email: this.newEmail
    })
  }

  /**
   * Realiza a requisição para a mudança de e-mail do cliente
   */
  changeEmail() {
    this.previousValue.email = this.newEmail;
    const payload: IClientCreateOrEditPayload = updateClientPayload(this.previousValue, this.isPJ(), this.personAndUserIds);
    const regex = new RegExp(this.emailRegex)

    if (this.newEmail?.length && !regex.test(this.newEmail)) {
      this.modalService.handleInputErrors(['Formato de e-mail inválido']);
    } else {
      this.modalService.cleanInputErrors();

      this.modalService.setLoading(true);
      this.clienteService.updateClient(payload).subscribe({
        complete: () => {
          this.notificator.showInfo(
            'Alterações salvas!',
            'Confirmação de mudança de e-mail enviada!'
          );
          this.modalService.setLoading(false);
          this.updateEmailExchange();
          this.modalService.handleCloseModal();
        },
        error: (err) => {
          this.modalService.setLoading(false);
          if(err.error.status === 400) {
            this.modalService.handleInputErrors(['E-mail já cadastrado']);
          } else {
            this.errorHandlerService.handleError(err, 'Erro ao salvar!');
          }
        }
      })
    }
  }

}
