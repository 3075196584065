import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { ChangePasswordService } from "./change-password.service";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  // guarda as configs do formulário
  form: FormGroup<any>;
  loading: boolean = false;
  passwordVisibily: boolean = true;
  confirmPasswordVisibily: boolean = true;
  submitted: boolean = false;
  userName: string = "";
  passwordInputIsValid: boolean = false;

  constructor(
    private router: Router,
    private changePasswordService: ChangePasswordService,
    private formBuilder: FormBuilder,
    private securityService: SecurityService,
    private cdr: ChangeDetectorRef,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.userName = this.securityService.getAuthenticatedUser().name;
  }

  // inicializando o form
  initFormGroup() {
    this.form = this.formBuilder.group({
      password: [""],
      confirmPassword: [""],
    });
  }

  // Recupera o estado do input de senha
  checkInputIsValid(inputStatus: boolean) {
    this.passwordInputIsValid = inputStatus;
    this.cdr.detectChanges();
  }

  // Verifica se as senhas são iguais
  get checkPasswordIsEqual() {
    return this.form.get("password").value 
      === this.form.get("confirmPassword").value
  }

  // Controla a exibição da senha no input
  toggleHidePassword(): void {
    this.passwordVisibily = !this.passwordVisibily;
  }

  // Controla a exibição da senha no input
  toggleHideConfirmPassword(): void {
    this.confirmPasswordVisibily = !this.confirmPasswordVisibily;
  }

  // submit do form
  handleSubmit() {
    this.loading = true;
    this.changePasswordService
      .updateNewPassword(this.form.get("password")?.value)
      .subscribe({
        next: () => {
          this.changePasswordService.setChangePassword(false);
          this.router.navigate([""]);
        },
        error: (err) => {
          this.errorHandlerService.handleError(err, "Erro ao redefinir senha");
        },
        complete: () => this.loading = false,
      });
  }
}
