<div class="localidades-list-container">
  <div class="input-to-search-localidade" *ngIf="localidadesFilhas.length > 0 && whoIsEditing === 'FATHER'">
    <i class="fa fa-search" aria-hidden="true"></i>
    <input type="text" class="input-to-search-localidade" placeholder="Busque por uma localidade filha"
      (input)="filteredLocation($event)" />
  </div>
  <div class="localidades-cadastradas" [ngClass]="whoIsEditing === 'FATHER' ? 'add-margin-top' : ''">
    <div class="localidade-pai animate-up delay-2" *ngIf="whoIsEditing === 'CHILDREN' || whoIsEditing === 'NONE'">
      <div class="content">
        <div class="localidade-description">
          <span>Localidade pai</span>
          <h4 title="test">{{ localidadePaiToList?.nome }}</h4>
        </div>
        <div class="options-box">
          <button type="button" (click)="handleEditFatherLocalidade(localidadePaiToList.id)">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <button type="button" (click)="handleDeleteFatherLocalidade(localidadePaiToList.id)">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="localidade-filhas" [ngClass]="isShowingForm ? 'fixed-height' : 'large-height'">
      <div class="content animate-up delay-2" [ngClass]="
          filha.id === handleGetWhichIdShouldILook() && 'hightlight-border'
        " [id]="filha.id === handleGetWhichIdShouldILook() && 'hightlight-scroll'"
        *ngFor="let filha of localidadesFilhasFiltered; let i = index">
        <div class="localidade-description">
          <span>Localidade Filha</span>
          <h4>{{ filha.nome }}</h4>
        </div>
        <div class="options-box">
          <button type="button" (click)="handleEditChildLocalidade(i, filha.id)">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <button type="button" (click)="handleDeleteChildLocalidade(i, filha.id)">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
