<div
  echarts
  class="chart"
  [options]="chartOption"
  (chartInit)="onChartInit($event)"
></div>

<div class="total-amount" *ngIf="totalCollections">
  <h2>{{ totalCollections }}</h2>
  <span>coletas</span>
</div>
