<div>
  <div class="interviewItem" [class.selecionado]="selected"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'UNICA'">

    <app-selecao-unica-marcacao [formGroup]="elementoSecao.controls.pergunta">
      <ng-content select="[perguntaUnica]"></ng-content>
    </app-selecao-unica-marcacao>

  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'MULTIPLA'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-selecao-multipla-marcacao [formGroup]="elementoSecao.controls.pergunta">
      <ng-content select="[perguntaMultiplaEscolha]"></ng-content>
    </app-selecao-multipla-marcacao>
  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'MULTIPLA_ORDEM_CITACAO'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-selecao-multipla-ordem-citacao-marcacao [formGroup]="elementoSecao.controls.pergunta">
      <ng-content select="[perguntaMultiplaOrdemCitacao]"></ng-content>

    </app-selecao-multipla-ordem-citacao-marcacao>
  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'ESCALA_NUMERICA'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-escala-numerica-marcacao [formGroup]="elementoSecao.controls.pergunta">
      <ng-content select="[perguntaEscalaNumerica]"></ng-content>
    </app-escala-numerica-marcacao>
  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'DATA'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-data-marcacao [formGroup]="elementoSecao.controls.pergunta"></app-data-marcacao>
  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'HORARIO'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-horario-marcacao [formGroup]="elementoSecao.controls.pergunta"></app-horario-marcacao>
  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'FOTO'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-foto-marcacao [formGroup]="elementoSecao.controls.pergunta"></app-foto-marcacao>
  </div>

  <div class="interviewItem"
    *ngIf="perguntaPossuiMarcacao(elementoSecao.controls.pergunta.value) && elementoSecao.value.pergunta.tipo === 'VIDEO'">
    <!-- <div class="pergunta-erros-icon" title="Esta pergunta não está configurada corretamente"
              *ngIf="showErrors && elementoSecao.invalid">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div> -->
    <app-video-marcacao [formGroup]="elementoSecao.controls.pergunta"></app-video-marcacao>
  </div>
</div>
