import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ListagemDeColetasMenu } from '../../constant';
import { IOptionMeatball } from '../../interfaces';

@Component({
  selector: 'app-audit-collections-meatball',
  templateUrl: './audit-collections-meatball.component.html',
  styleUrls: ['./audit-collections-meatball.component.scss']
})
export class AuditCollectionsMeatballComponent implements OnInit {

  showMeatball: boolean; // Control the meatball is open or closed

  @Input() showMap: boolean // Control a map button exibition

  @Input() id: string | number; // Id do item selecionado

  // retorna o item selecionado pelo menu meatball
  @Output() handleItemClicked = new EventEmitter<IOptionMeatball>()

  menuItems = ListagemDeColetasMenu

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }

  // Method that deals with the meatball manipulation
  handleShowMeatball() {
    this.showMeatball = !this.showMeatball;
  }

  /**
   * Open collection
   */
  handleOpenCollection() {
    // propaga o id do item e o tipo de botão clicado
    this.handleItemClicked.emit({
      id: this.id,
      type: this.menuItems.VER_COLETA
    })
  }

  /**
   * Open observation list
   */
  handleOpenObservation() {
    // propaga o id do item e o tipo de botão clicado
    this.handleItemClicked.emit({
      id: this.id,
      type: this.menuItems.VER_OBSERVACOES
    })
  }

  /**
   * Open the map modal
   */
  handleOpenMapModal() {
    // fecha o meatball
    this.handleShowMeatball()
    // propaga o id do item e o tipo de botão clicado
    this.handleItemClicked.emit({
      id: this.id,
      type: this.menuItems.VER_NO_MAPA
    })
  }

  // Verify where was the click
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    if (this.el.nativeElement.contains(event.target)) {
      if (this.showMeatball) {
        return;
      } else {
        this.handleShowMeatball();
      }
    } else {
      if (!this.showMeatball) {
        return;
      } else {
        this.handleShowMeatball();
      }
    }
  }

}
