import { Component, Input, OnInit } from '@angular/core';
import { Localidade } from '../localidade';

@Component({
  selector: 'app-localidade',
  templateUrl: './localidade.component.html',
  styleUrls: ['./localidade.component.scss']
})
export class LocalidadeComponent implements OnInit {
  @Input() localidade: Localidade;

  ngOnInit() {
    console.log('[LocalidadeComponent.ngOnInit] localidade: ', this.localidade);
  }
}
