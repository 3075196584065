export class TrBody {
  id: string;
  title: string;
  selected: boolean;
  status: boolean;
  customData: string[];
  idContaUsuario: string;
  pergunta?: string;
  numero?: number;
  tipoPergunta?: any;
  respostaPergunta?: any;

  operador?: any
  duracao?: any
  localidade?: any
  dataColeta?: any
  inicioColeta?: any
  fimColeta?: any
  auditado?: any
  aprovado?: any
  ordem?: any
}
