import { PerguntaIdentificador } from './perguntaIdentificador';
import { UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';

/**
 * Classe que representa a marcação no
 * sistema, por padrão, a marcação é inicializada
 * sem os valores, eles devem ser configurados pelo usuário
 */
export class Marcacao {

  id: number;
  possuiPulo: boolean = false;
  possuiDiferenteDe: boolean = false;
  possuiCota: boolean = false;
  perguntasDiferente: PerguntaIdentificador;
  perguntaDestino: PerguntaIdentificador;
  cotaPercentual: number | string;
  cotaValor: number | string;

  static getControl(): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(),
      // perguntasDiferente: PerguntaIdentificador.getControl(),
      // perguntaDestino: PerguntaIdentificador.getControl(),
      // tslint:disable-next-line: max-line-length
      cotaPercentual: new UntypedFormControl(undefined, InputValidatorFactory.getValidadores(TipoInput.MARCACAO_COTA_PERCENTUAL)),
      // tslint:disable-next-line: max-line-length
      cotaValor: new UntypedFormControl(undefined, InputValidatorFactory.getValidadores(TipoInput.MARCACAO_COTA_VALOR)),
      possuiPulo: new UntypedFormControl(false),
      possuiDiferenteDe: new UntypedFormControl(false),
      possuiCota: new UntypedFormControl(false),
    });

    return formGroup;
  }

  static getInitializedControl(marcacao: Marcacao): UntypedFormGroup {

    if (!marcacao) {
      return Marcacao.getControl();
    }

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(marcacao.id),
      possuiPulo: new UntypedFormControl(marcacao.possuiPulo),
      possuiDiferenteDe: new UntypedFormControl(marcacao.possuiDiferenteDe),
      possuiCota: new UntypedFormControl(marcacao.possuiCota),
    });

    if (marcacao.possuiDiferenteDe) {
      // tslint:disable-next-line: max-line-length
      formGroup.addControl('perguntasDiferente', PerguntaIdentificador.getInitializedControl(marcacao.perguntasDiferente));
    }

    if (marcacao.possuiPulo) {
      // tslint:disable-next-line: max-line-length
      formGroup.addControl('perguntaDestino', PerguntaIdentificador.getInitializedControl(marcacao.perguntaDestino));
    }

    // tslint:disable-next-line: max-line-length
    if (marcacao.possuiCota || marcacao.cotaPercentual !== null && marcacao.cotaPercentual !== undefined) {
      // tslint:disable-next-line: max-line-length
      formGroup.addControl('cotaPercentual', new UntypedFormControl(marcacao.cotaPercentual, InputValidatorFactory.getValidadores(TipoInput.MARCACAO_COTA_PERCENTUAL)));

      // tslint:disable-next-line: max-line-length
      const cotaValorControl = new UntypedFormControl(marcacao.cotaValor, InputValidatorFactory.getValidadores(TipoInput.MARCACAO_COTA_VALOR));
      formGroup.addControl('cotaValor', cotaValorControl);
    }

    return formGroup;
  }

}
