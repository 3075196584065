import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';

export class PerguntaIdentificador {

  codigoMarcacao: string = null;

  static getControl(): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line:max-line-length
      codigoMarcacao: new UntypedFormControl(null, InputValidatorFactory.getValidadores(TipoInput.MARCACAO_SECAO_PERGUNTA_ALTERNATIVA_CODIGO_MARCACAO)),
    });

    return formGroup;
  }

  static getInitializedControl(perguntaIdentificador: PerguntaIdentificador): AbstractControl {

    if (!perguntaIdentificador) {
      return PerguntaIdentificador.getControl();
    }

    return new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      codigoMarcacao: new UntypedFormControl(perguntaIdentificador.codigoMarcacao, InputValidatorFactory.getValidadores(TipoInput.MARCACAO_SECAO_PERGUNTA_ALTERNATIVA_CODIGO_MARCACAO)),
    });
  }

}
