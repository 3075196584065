<div>
  
  <!-- HAS VIDEO TO SHOW -->
  <div class="videoCenter" *ngIf="hasVideo()">
    <video max-height="320" controls>
      <source [src]="videoResposta" type="video/mp4" />
      Seu navegador não possui implementação nativa de player de vídeos
    </video>
  </div>


  <!-- HAS NO VIDEO TO SHOW -->
  <div class="novideo" *ngIf="!hasVideo()">
    <span class="text">Nenhum vídeo fornecido</span>
  </div>
</div>
<!-- <div>

  <video width="320" height="240" controls>
    <source [src]="videoResposta" type="video/mp4" />
    Seu navegador não possui implementação nativa de player de vídeos
  </video>
  
</div> -->
