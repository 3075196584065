import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { JWTService } from "app/infraestrutura/security/service/jwtService";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { BehaviorSubject, Observable, map } from "rxjs";

interface IRequest {
  id: number;
}

@Injectable({ providedIn: "root" })
export class ChangePasswordService extends CrudService<IRequest> {
  //
  private tokenName = "Change-Password-Token";
  // variável que guarda se precisa trocar a senha
  public changePassword: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private jwtService: JWTService,
    protected requestService: RequestService
  ) {
    super(requestService, `${apiLocation}`);
  }

  // recebe o valor se irá exibir o modal de alterar a senha ou não
  public setChangePassword(vl: boolean) {
    this.changePassword.next(vl);
  }

  // passa o token de alteração de senha
  public setChangePasswordToken(token: string) {
    this.setToken(token);
  }

  // guarda o token de troca de senha no localStorage
  private setToken(token: string) {
    localStorage.setItem(this.tokenName, token);
  }

  // recupera o token do localStorage
  private getToken() {
    return localStorage.getItem(this.tokenName) ?? "";
  }

  // request para alterar a senha e setar o novo token de autenticação
  updateNewPassword(password: string): Observable<HttpResponse<Object>> {
    const baseUrl = `${this.resourcePath}/redefinir-senha/alterar?login=true`;
    const response = <Observable<any>>this.requestService
      .post(baseUrl, {
        token: this.getToken(),
        senha: password,
      }, false )
      .pipe(
        map((response: HttpResponse<Object>) => {
          const token = response.headers.get("Authorization");
          this.jwtService.setToken(token, true);
        })
      );
    return response;
  }
}
