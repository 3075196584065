import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { aplicarMascaraPorcentagem } from 'app/util/mascara/mascaraPorcentagem';

@Component({
  selector: 'app-cota-percentual',
  templateUrl: './cota-percentual.component.html',
  styleUrls: ['./cota-percentual.component.scss'],
})
export class CotaPercentualComponent {

  @Input() title = 'Porcentagem da cota da alternativa';
  /**
   * formControl da cota
   */
  @Input() formControl: UntypedFormControl = new UntypedFormControl({});

  /**
   * Evento que dispara a cota selecionada
   */
  @Output() cotaPercentualChange: EventEmitter<string> = new EventEmitter();

  /**
   * Evento que dispara a cota selecionada quando é dado blur no componente
   */
  @Output() cotaPercentualChangeBlur: EventEmitter<string> = new EventEmitter();

  @Output() click: EventEmitter<any> = new EventEmitter();

  /**
   * Atualiza o valor do control da cota utilizando-se
   * da mascara de porcentagem e marcando a mesma como dirty
   * de forma a possibilitar a ação do validador de cotas
   */
  setCotaPercentual(evento) {
    const novoValor = evento.target.value;
    const valorFormatado = this.processarValor(novoValor);
    this.cotaPercentualChange.emit(valorFormatado);
  }

  /**
   * Callback executado quando o evento blur é disparado no input.
   * Atualiza o valor do control da cota utilizando-se da mascara
   * de porcentagem e marcando a mesma como dirty de forma a possibilitar a ação do validador de cotas.
   * @param evento
   */
  setCotaPercentualBlur(evento) {
    const novoValor = evento.target.value;
    const valorFormatado = this.processarValor(novoValor);
    this.cotaPercentualChangeBlur.emit(valorFormatado);
  }

  processarValor(valor): string {
    const valorFormatado = aplicarMascaraPorcentagem(valor);

    this.formControl.markAsDirty();
    this.formControl.setValue(valorFormatado);

    return valorFormatado;
  }

  handleClickEvent(event: any) {
    this.click.emit(event);
  }

}
