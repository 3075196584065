import { NewFotoUploaderComponent } from "./../../../util/componente/new-foto-uploader/new-foto-uploader.component";
import { Component, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificatorService } from "app/notificador/notificator.service";
import { dateFormatter } from "app/util/misc/dateFormatter";
import validadorCampoMaior from "app/util/validador/validadorCampoMaior";
import validadorCampoMenor from "app/util/validador/validadorCampoMenor";
import validadorCamposIguais from "app/util/validador/validadorCamposIguais";
import validadorCpf from "app/util/validador/validadorCpf";
import validadorData from "app/util/validador/validadorData";
import { CadastroBasico } from "../../cadastro/cadastroBasico";
import { Operador } from "../operador";
import { OperadorService } from "../servico/operador.service";
// tslint:disable-next-line: max-line-length
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-operador-cadastro",
  templateUrl: "./operador.cadastro.component.html",
  styleUrls: ["./operador.cadastro.component.scss"],
})
export class OperadorCadastroComponent extends CadastroBasico<Operador> {
  /**
   * caso seja um cadastro o operador vazio eh criado, caso contrario,
   * os dados previos serao carregados. Esta instancia de operador serve apenas
   * para setar o patchValue dos formControls
   */
  operador: Operador;
  url: string | ArrayBuffer;
  // tituloFormulario: string = "adicionar operadores"
  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  constructor(
    protected securityService: SecurityService,
    protected operadorService: OperadorService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "operador",
      operadorService,
      activatedRoute,
      router,
      toastr,
      notificatorService,
      modalService
    );
  }

  ngOnInit() {
    this.operador = new Operador();
    // Cria o formgroup
    this.initFormGroup();
    super.ngOnInit();

    this.tituloFormulario = "adicionar operadores";

    if (this.isEdicao) {
      this.tituloFormulario = "alterar operadores";
    }
  }

  /**
   * @override
   * sobrescrita do life cycle hook que é disparado quando a requisição do cadastroBasico
   * resulta em erro.
   */
  onSalvarError(isEdicao: boolean, errorResponse) {
    if (errorResponse.status === 409) {
      const pesquisasIncompletas = errorResponse.error;
      this.modalService.showModal({
        title: this.getAlertaDataTitleDialog(),
        messageModal: `O operador não pôde ser inativado por estar vinculado a ${pesquisasIncompletas.length} pesquisas e não ter completado suas cotas.`,
        isOnlyConfirmation: true,
        btnTitlePositive: "Entendi",
      });
      this.isLoading = false;
      // tslint:disable-next-line: max-line-length
    } else {
      super.onSalvarError(isEdicao, errorResponse);
    }
  }

  entidadeEdicaoCallback(operadorEdicao: Operador) {
    this.operador = operadorEdicao;
    this.operador.periodoInicial = dateFormatter.dMyToYmD(
      this.operador.periodoInicial
    );
    this.operador.periodoFinal = dateFormatter.dMyToYmD(
      this.operador.periodoFinal
    );

    if (this.operador.foto) {
      this.newFotoUploaderComponent.loadImageByUrl(this.operador.foto);
    }

    this.initFormGroup();
  }

  // Criando o fromGroup
  initFormGroup() {
    // this.formControls['alteracaoSenha'] = new FormControl(this.isAlteracaoSenha);
    this.formControls["periodoInicial"] = new UntypedFormControl(
      this.operador.periodoInicial
    );
    this.formControls["periodoFinal"] = new UntypedFormControl(
      this.operador.periodoFinal
    );

    // gerando o validador de campo maior baseado no formControl dos periodos
    const periodoInicialMenorValidator = validadorCampoMenor(
      this.formControls.periodoFinal
    );
    const periodoFinalMaiorValidator = validadorCampoMaior(
      this.formControls.periodoInicial
    );

    /**
     * NOVA REGRA: A única validação para datas eh que o período final não deve ser inferior ao inicial
     *Outras datas podem ser inseridas
     */

    // if (!this.isEdicao) {
    //   /**
    //    * quando não se eh edicao, o periodo minimo que pode ser definido e a partir da data atual
    //    */
    //   // tslint:disable-next-line:max-line-length
    //   this.formControls.periodoFinal.setValidators([Validators.required, periodoFinalMaiorValidator, validadorData]);
    //   // tslint:disable-next-line:max-line-length
    //   this.formControls.periodoInicial.setValidators([Validators.required, periodoInicialMenorValidator, validadorData, validadorDataMaiorQue(new Date(new Date().toDateString()))]);
    // } else {

    // tslint:disable-next-line:max-line-length
    this.formControls.periodoFinal.setValidators([
      Validators.required,
      periodoFinalMaiorValidator,
      validadorData,
    ]);
    // tslint:disable-next-line:max-line-length
    this.formControls.periodoInicial.setValidators([
      Validators.required,
      periodoInicialMenorValidator,
      validadorData,
    ]);

    this.formGroup = new UntypedFormGroup({
      matricula: new UntypedFormControl({
        value: this.operador.matricula,
        disabled: true,
      }),
      periodoInicial: this.formControls.periodoInicial,
      periodoFinal: this.formControls.periodoFinal,
      // alteracaoSenha: this.formControls.alteracaoSenha,
      pessoa: this.createPessoaFormGroup(),
      usuario: this.createUsuarioFormGroup(),
      contato: this.createContatoFormGroup(),
      fotoEnviada: this.createFotoEnviadaFormGroup(),
    });

    this.formControls["periodoInicial"] = <UntypedFormControl>(
      this.formGroup.get("periodoInicial")
    );
    this.formControls["periodoFinal"] = <UntypedFormControl>(
      this.formGroup.get("periodoInicial")
    );
  }

  // Criando o fromGroup para FotoEnviada
  private createFotoEnviadaFormGroup(): UntypedFormGroup {
    const fotoEnviadaFormGroup = new UntypedFormGroup({
      arquivo: new UntypedFormControl(),
    });

    return fotoEnviadaFormGroup;
  }

  // Criando o fromGroup para Contato
  private createContatoFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["email"] = new UntypedFormControl(
      this.operador.contato.email,
      [Validators.required, Validators.email, Validators.maxLength(50)]
    );
    // tslint:disable-next-line:max-line-length
    // this.formControls["telefone"] = new UntypedFormControl(
    //   this.operador.contato.telefone,
    //   [Validators.maxLength(14), Validators.minLength(14)]
    // );
    // // tslint:disable-next-line:max-line-length
    // this.formControls["celular"] = new UntypedFormControl(
    //   this.operador.contato.celular,
    //   [Validators.maxLength(15), Validators.minLength(15)]
    // );

    const contatoFormGroup = new UntypedFormGroup({
      email: this.formControls.email,
      // telefone: this.formControls.telefone,
      // celular: this.formControls.celular,
    });

    return contatoFormGroup;
  }

  // Criando o fromGroup para Usuario
  private createUsuarioFormGroup(): UntypedFormGroup {
    this.formControls["mudarPassword"] = new UntypedFormControl();
    // tslint:disable-next-line:max-line-length
    this.formControls["ativo"] = new UntypedFormControl(
      this.operador.usuario.ativo,
      [Validators.required]
    );

    this.formControls["senha"] = new UntypedFormControl();
    this.formControls["senhaConfirmar"] = new UntypedFormControl();

    if (!this.isEdicao) {
      // gerando o validador de campos iguais baseado no formControl de senha
      const senhaCamposIguaisValidator = validadorCamposIguais(
        this.formControls.senha
      );
      // tslint:disable-next-line:max-line-length
      const senhaConfirmarCamposIguaisValidator = validadorCamposIguais(
        this.formControls.senhaConfirmar
      );

      this.formControls.senha.setValidators([
        Validators.required,
        Validators.maxLength(30),
        Validators.minLength(8),
        senhaConfirmarCamposIguaisValidator,
      ]);
      this.formControls.senhaConfirmar.setValidators(
        senhaCamposIguaisValidator
      );
    }

    const usuarioFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.operador.usuario.id),
      senha: this.formControls.senha,
      senhaConfirmar: this.formControls.senhaConfirmar,
      mudarPassword: this.formControls.mudarPassword,
      ativo: this.formControls.ativo,
    });
    return usuarioFormGroup;
  }

  private createPessoaFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["nome"] = new UntypedFormControl(
      this.operador.pessoa.nome,
      [Validators.required, Validators.maxLength(50)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["cpf"] = new UntypedFormControl(
      this.operador.pessoa.cpf,
      [Validators.required, validadorCpf]
    );
    // tslint:disable-next-line:max-line-length
    // this.formControls["rg"] = new UntypedFormControl(this.operador.pessoa.rg, [
    //   Validators.maxLength(10),
    // ]);

    const pessoaFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.operador.pessoa.id),
      nome: this.formControls.nome,
      cpf: this.formControls.cpf,
      // rg: this.formControls.rg,
      // endereco: this.createEnderecoFormGroup(),
    });

    return pessoaFormGroup;
  }

  // Criando o fromGroup para Endereco
  // private createEnderecoFormGroup(): UntypedFormGroup {
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["cep"] = new UntypedFormControl(
  //     this.operador.pessoa.endereco.cep,
  //     [Validators.minLength(9), Validators.maxLength(9)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["logradouro"] = new UntypedFormControl(
  //     this.operador.pessoa.endereco.logradouro,
  //     [Validators.maxLength(60)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["numero"] = new UntypedFormControl(
  //     this.operador.pessoa.endereco.numero,
  //     [Validators.max(99999), Validators.min(0)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["bairro"] = new UntypedFormControl(
  //     this.operador.pessoa.endereco.bairro,
  //     [Validators.maxLength(60)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["cidadeEstado"] = new UntypedFormControl(
  //     this.operador.pessoa.endereco.cidadeEstado,
  //     [Validators.maxLength(60)]
  //   );

  //   const enderecoFormGroup = new UntypedFormGroup({
  //     cep: this.formControls.cep,
  //     logradouro: this.formControls.logradouro,
  //     numero: this.formControls.numero,
  //     bairro: this.formControls.bairro,
  //     cidadeEstado: this.formControls.cidadeEstado,
  //   });

  //   return enderecoFormGroup;
  // }

  /**
   * Prepara a entidade para persistência, qualquer ajuste fino deve ser realizado aqui
   */
  prepararEntidadeParaPersistencia(operador: Operador): Operador {
    const operadorToSave = { ...operador };

    // Necessário converter as datas para o formato dd/MM/YYYY antes de enviar ao servidor
    operadorToSave.periodoInicial = dateFormatter.yMdToDmY(
      operadorToSave.periodoInicial
    );
    operadorToSave.periodoFinal = dateFormatter.yMdToDmY(
      operadorToSave.periodoFinal
    );

    // se o operador nao possuir fotos novas, removemos o atributo FotoEnviada
    if (
      this.newFotoUploaderComponent.defaultImage ===
      this.newFotoUploaderComponent.url
    ) {
      operadorToSave.fotoEnviada = null;
    } else {
      operadorToSave.fotoEnviada.arquivo = this.newFotoUploaderComponent.url;
    }

    return operadorToSave;
  }

  /**
   * @override
   */
  getRedirectPath(): string[] {
    const listagemAuthorities = [
      Authority.ATUALIZAR_OPERADOR,
      Authority.LISTAR_OPERADOR,
    ];
    if (this.securityService.userHasAuthorities(listagemAuthorities)) {
      return ["operador"];
    }
    return [""];
  }
}
