<div class="foto-upload-wrapper">
  <div class="campo-upload-foto">
    <img [src]="url" alt="imagem de upload" />
    <label for="upload-photo" title="selecionar foto">Escolher foto</label>
    <input
      id="upload-photo"
      type="file"
      accept="image/jpeg"
      #fileInput
      (change)="onFileSelected($event)"
    />
  </div>

  <div class="upload-loading" *ngIf="showLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>
