import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { ListagemAbstrata } from "../../listagem/listagemAbstrata";
import { PesquisaItem } from "../../pesquisa-old/pesquisa-select/pesquisaItem";
import { PesquisaService } from "../../pesquisa-old/servico/pesquisa.service";
import { EntrevistaRejeicao } from "../model/entrevistaRejeicao";
import { Rejeicao } from "../model/rejeicao";
import { EntrevistaService } from "../service/entrevista.service";
import { TipoPasso } from "../tipoPasso";
import { DownloadModalComponent } from "./download-modal/download-modal.component";
import EventoTabelaEntrevista from "./entrevista-listagem-tabela/eventoTabelaEntrevista";
import { TipoEventoTabelaEntrevista } from "./entrevista-listagem-tabela/tipoEventoTabelaEntrevista";
import { RejeicaoDialogComponent } from "./rejeicao-dialog/rejeicao-dialog.component";

@Component({
  selector: "app-entrevistas-listagem",
  templateUrl: "./entrevista-listagem.component.html",
  styleUrls: ["./entrevista-listagem.component.scss"],
})
export class EntrevistaListagemComponent extends ListagemAbstrata {
  public idPesquisa: number;
  public onRejeicaoConfirmada: Subject<Rejeicao>;

  @Input() pesquisaSelecionada: PesquisaItem;

  public _pesquisaSelecionada: PesquisaItem;

  // @ViewChild(EntrevistaListagemTabelaComponent, {static: true}) tabelaListagem: EntrevistaListagemTabelaComponent;

  @Input() pagina: number = 0;
  @Output() paginacaoChange: EventEmitter<DadosPaginacao> = new EventEmitter();

  nomeAtributosTabela: string[] = [];
  nomeColunasTabela: string[] = [];

  /**
   * event bus responsável por trafegar eventos de ré-atualização da tabela de entrevistas,
   * o dado trafegado é apenas um boolean indicando se a paginação deve ser mantida ou não.
   */
  public atualizarTabelaSubject: Subject<boolean> = new ReplaySubject();

  constructor(
    public entrevistaService: EntrevistaService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public pesquisaService: PesquisaService,
    router: Router,
    protected modalService: ModalService
  ) {
    super("auditoria", router, modalService);
  }

  ngOnInit() {
    // super.ngOnInit();

    this.idPesquisa = this.pesquisaSelecionada.id;
    this.onRejeicaoConfirmada = new ReplaySubject();
    this.onRejeicaoConfirmada.subscribe((rejeicao: Rejeicao) => {
      this.tratarRejeicaoConfirmada(rejeicao);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pesquisaSelecionada) {
      const novaPesquisaChange: SimpleChange = changes.pesquisaSelecionada;
      this._pesquisaSelecionada = novaPesquisaChange.currentValue;
      // tslint:disable-next-line: max-line-length
    }
  }

  /**
   * @override
   * @param evento
   */
  excluirDados(eventoTabela: EventoTabela): Observable<{}> {
    const idsARemover: string[] = eventoTabela.payload;
    // tslint:disable-next-line:max-line-length
    return this.entrevistaService.removerEntrevistas(idsARemover);
  }

  /**
   * Callback executado quando um evento de visualização de entrevista é disparado na tabela
   * @param idEntrevista
   */
  visualizarEntrevista(evento: EventoTabelaEntrevista) {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(["entrevistas", evento.payload], {
      relativeTo: this.route,
      queryParams: { redirect: true, order: evento.ordem },
    });
  }

  recarregarPagina() {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(
      [
        "auditoria/pesquisas/",
        this._pesquisaSelecionada.id,
        TipoPasso.RESPOSTAS_FECHADAS,
      ],
      { queryParams: { pagina: this.pagina } }
    );
  }

  /**
   * @override
   * sobrescrita do comportamento realizado quando uma exclusão de um ou mais itens da listagem
   * é efetuada com sucesso.
   */
  refreshCallback() {
    this.recarregarPagina();
  }

  /**
   * Callback executado sempre que uma pesquisa é selecionada.
   * @param idPesquisa
   */
  handlePesquisaSelecionada(idPesquisa: number) {
    // tslint:disable-next-line: max-line-length
    this.router.navigate([
      "auditoria/pesquisas/",
      idPesquisa,
      TipoPasso.RESPOSTAS_FECHADAS,
    ]);
  }

  requisitarRejeicao(idEntrevista: number) {
    this.dialog
      .open(RejeicaoDialogComponent, {
        data: {
          idEntrevista,
          onRejeicaoConfirmada: this.onRejeicaoConfirmada,
        },
      })
      .afterClosed()
      .subscribe(() => {
        // this.recarregarPagina();
        this.atualizarTabelaSubject.next(true);
      });
  }

  exibirAlertaTemCerteza(message: string) {
    const temCerteza = message;

    return new Promise((accept, reject) => {
      this.modalService.showModal({
        title: "Confirmação",
        messageModal: temCerteza,
        btnTitlePositive: "Sim, tenho",
        btnTitleNegative: "Cancelar",
        icon: "fa-light fa-circle-info",
        positiveCallback: () => accept(true),
        negativeCallback: () => reject(),
      });
    });
  }

  exibirAlertaAudicaoSucesso(
    entrevistaRejeicao: EntrevistaRejeicao,
    refreshCallback: () => void
  ) {
    this.modalService.showModal({
      title: "Entrevista rejeitada",
      messageModal: `A entrevista ${entrevistaRejeicao.idEntrevista} foi rejeitada com sucesso!\nCausa: ${entrevistaRejeicao.causa}.`,
      btnTitlePositive: "Entendi",
      isOnlyConfirmation: true,
      icon: "fa-light fa-circle-info",
      positiveCallback: () => refreshCallback(),
    });
  }

  /**
   * Callback executado quando a rejeição de uma entrevista é confirmada.
   * @param causa motivo do usuário ter rejeitado a entrevista.
   */
  tratarRejeicaoConfirmada(rejeicao: Rejeicao) {
    // tslint:disable-next-line: max-line-length
    const msg = `Tem certeza que deseja rejeitar a entrevista ${rejeicao.idEntrevista} com o motivo: ${rejeicao.causa} ?`;

    this.exibirAlertaTemCerteza(msg)
      .then(() => {
        this.entrevistaService
          .rejeitarEntrevista(rejeicao.idEntrevista, rejeicao.causa)
          .subscribe({
            next: (entrevistaRejeicao: EntrevistaRejeicao) => {
              this.exibirAlertaAudicaoSucesso(entrevistaRejeicao, () => {
                // this.handlePesquisaSelecionada(this._pesquisaSelecionada.id);
                this.atualizarTabelaSubject.next(true);
              });
            },
            error: (err) => {
              this.modalService.showModal({
                title: "Atualização da Entrevista",
                messageModal:
                  "Um erro ocorreu durante a rejeição da entrevista. Por favor, tente novamente mais tarde.",
                btnTitlePositive: "Entendi",
                isOnlyConfirmation: true,
                icon: "fa-light fa-circle-info",
              });
            },
          });
      })
      .catch(() => {
        this.atualizarTabelaSubject.next(true);
      });
  }

  aprovarEntrevista(idEntrevista: number) {
    const msg = `Tem certeza que deseja aprovar a entrevista ${idEntrevista} ?`;

    this.exibirAlertaTemCerteza(msg)
      .then(() => {
        this.entrevistaService.aprovarEntrevista(idEntrevista).subscribe({
          next: (responseBody) => {
            this.modalService.showModal({
              title: "Entrevista aprovada",
              messageModal: `A entrevista ${idEntrevista} foi aprovada com sucesso.`,
              btnTitlePositive: "Entendi",
              icon: "fa-light fa-circle-info",
              isOnlyConfirmation: true,
              positiveCallback: () => this.atualizarTabelaSubject.next(true),
            });
          },
          error: (err) => {
            this.modalService.showModal({
              title: "Atualização da Entrevista",
              messageModal:
                "Um erro ocorreu durante a aprovação da entrevista. Por favor, tente novamente mais tarde.",
              btnTitlePositive: "Entendi",
              icon: "fa-light fa-circle-info",
              isOnlyConfirmation: true,
            });
          },
        });
      })
      .catch(() => {
        this.atualizarTabelaSubject.next(true);
      });
  }

  /**
   * Baixa gravações das entrevistas utilizando o serviço
   * EntrevistaService.
   *
   * @param idEntrevistas identificadores das entrevistas
   */
  baixarEntrevistas(
    idPesquisa: number,
    idsEntrevistas: number[],
    all: boolean
  ) {
    // this.isLoading = true;

    this.dialog.open(DownloadModalComponent, {
      data: {
        idPesquisa,
        idsEntrevistas,
        all,
      },
    });
  }

  auditarPesquisa(payload: { auditada: boolean }) {
    // tslint:disable-next-line: max-line-length
    const msg = `Tem certeza que deseja alterar o estado de auditoria da pesquisa para ${
      payload.auditada ? '"Auditado"' : '"Não auditado"'
    } ?`;
    this.exibirAlertaTemCerteza(msg)
      .then(() => {
        this.isLoading = true;

        this.pesquisaService
          .auditarPesquisa(this._pesquisaSelecionada.id, payload.auditada)
          .subscribe((res) => {
            this.isLoading = false;

            this.modalService.showModal({
              title: "Pesquisa atualizada",
              messageModal: "O estado da pesquisa foi alterado com sucesso.",
              btnTitlePositive: "Entendi",
              icon: "fa-light fa-circle-info",
              isOnlyConfirmation: true,
              positiveCallback: () =>
                this.handlePesquisaSelecionada(this._pesquisaSelecionada.id),
            });
          });
      })
      .catch(() => {
        this.atualizarTabelaSubject.next(true);
      });
  }

  onEventoTabelaEntrevista(evento: any) {
    switch (evento.tipo) {
      case TipoEventoTabelaEntrevista.APROVAR_ENTREVISTA:
        this.aprovarEntrevista(evento.payload);
        break;
      case TipoEventoTabelaEntrevista.REJEITAR_ENTREVISTA:
        this.requisitarRejeicao(evento.payload);
        break;
      case TipoEventoTabelaEntrevista.VISUALIZAR_ENTREVISTA:
        this.visualizarEntrevista(evento);
        break;
      case TipoEventoTabelaEntrevista.DOWNLOAD_ENTREVISTAS:
        this.baixarEntrevistas(
          this.idPesquisa,
          evento.payload.idsEntrevistas,
          evento.payload.all
        );
        break;
      case TipoEventoTabelaEntrevista.AUDITAR_PESQUISA:
        this.auditarPesquisa(evento.payload);
        break;
    }
  }

  manipularEventoPaginacao(eventoTabela: EventoTabela) {
    const dadosPaginacao: DadosPaginacao = eventoTabela.payload;
    // tslint:disable-next-line: max-line-length
    this.pagina = dadosPaginacao.page;
    this.paginacaoChange.emit(dadosPaginacao);
  }
}
