<div [formGroup]="formGroup" class="sg-input-group">
  <div class="container-white">
    <div class="row">
      <div class="col-8 title-step-section">
        <h3>5 de 5: Gerenciador de cotas</h3>
        <span class="error-message" *ngIf="!modificacaoPermitida">
          Você não tem permissão para modificar esse menu
        </span>
        <span class="error-message" *ngIf="!pesquisaPossuiPerfisCota">
          Sua pesquisa não possui perfis definidos para distribuição de cotas
        </span>
      </div>
      <div *ngIf="pesquisaPossuiPerfisCota" class="busca-wrapper col-4">
        <input type="text" placeholder="Filtrar localidade ou operador" />
        <div class="icon-busca-wrapper">
          <i class="icon-busca"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="row section" *ngIf="pesquisaPossuiPerfisCota">
    <div class="col-12 header-section">
      <h3>Ajuste e distribua as cotas dos seus perfis</h3>
    </div>
  </div>

  <div class="row section positionRelative" *ngIf="pesquisaPossuiPerfisCota">
    <div class="col-lg-12">
      <app-localidades-wrapper
        [filtrarLocalidadesOperadoresSubject]="
          filtrarLocalidadesOperadoresSubject
        "
      ></app-localidades-wrapper>
    </div>
  </div>
</div>
