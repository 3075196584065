<div class="alert">
  <i class="fa fa-bell-o"></i>
</div>

<div class="user-name">
  <strong>Olá,</strong>
  <p>{{ userName }}</p>
</div>

<img (click)="toggleOpenMenu()" [src]="userPhoto" alt="Logo user" />

<div
  class="options-user"
  *ngIf="showMenu"
>
  <ul (click)="toggleOpenMenu()">
    <li (click)="getPerfilUsuarioLogadoToEdicao()">
      <a>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.06087 14.5786 10.0783 13.8284 10.8284C13.0783 11.5786 12.0609 12 11 12C9.93913 12 8.92172 11.5786 8.17157 10.8284C7.42143 10.0783 7 9.06087 7 8C7 6.93913 7.42143 5.92172 8.17157 5.17157C8.92172 4.42143 9.93913 4 11 4C12.0609 4 13.0783 4.42143 13.8284 5.17157C14.5786 5.92172 15 6.93913 15 8ZM13 8C13 8.53043 12.7893 9.03914 12.4142 9.41421C12.0391 9.78929 11.5304 10 11 10C10.4696 10 9.96086 9.78929 9.58579 9.41421C9.21071 9.03914 9 8.53043 9 8C9 7.46957 9.21071 6.96086 9.58579 6.58579C9.96086 6.21071 10.4696 6 11 6C11.5304 6 12.0391 6.21071 12.4142 6.58579C12.7893 6.96086 13 7.46957 13 8Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM2 11C2 13.09 2.713 15.014 3.908 16.542C4.74723 15.4399 5.8299 14.5467 7.07143 13.9323C8.31297 13.3179 9.67974 12.9988 11.065 13C12.4323 12.9987 13.7819 13.3095 15.0109 13.9088C16.2399 14.508 17.316 15.3799 18.157 16.458C19.0234 15.3216 19.6068 13.9952 19.8589 12.5886C20.111 11.182 20.0244 9.73553 19.6065 8.36898C19.1886 7.00243 18.4512 5.75505 17.4555 4.73004C16.4598 3.70503 15.2343 2.93186 13.8804 2.47451C12.5265 2.01716 11.0832 1.88877 9.66986 2.09997C8.25652 2.31117 6.91379 2.85589 5.75277 3.68905C4.59175 4.52222 3.64581 5.61987 2.99323 6.8912C2.34065 8.16252 2.00018 9.57097 2 11ZM11 20C8.93395 20.0031 6.93027 19.2923 5.328 17.988C5.97293 17.0647 6.83134 16.3109 7.83019 15.7907C8.82905 15.2705 9.93879 14.9992 11.065 15C12.1772 14.9991 13.2735 15.2636 14.2629 15.7714C15.2524 16.2793 16.1064 17.0159 16.754 17.92C15.1393 19.2667 13.1026 20.0029 11 20Z"/>
        </svg>
        <p>Editar perfil</p>
      </a>
    </li>
    <!-- TODO: faltando designers concluírem a tela de FAQ -->
    <!-- <li>
      <a
        ><i class="far fa-question-circle"></i>
        <p>Ajuda</p></a
      >
    </li> -->
    <!-- <li *ngIf="userIsAdm()">
      <a [routerLink]="'/management'" routerLinkActive="active"
        ><i class="fas fa-tasks"></i>
        <p>Gerenciamento</p></a
      >
    </li> -->
    <li (click)="doLogout()">
      <a>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 10.4384C0.0842912 9.82895 0.14751 9.21615 0.257931 8.61094C1.06628 4.16121 4.95801 0.535846 9.45916 0.072245C13.6493 -0.359326 17.0091 1.14611 19.5513 4.49585C19.6441 4.61891 19.7351 4.74535 19.8084 4.88021C20.0267 5.27975 19.848 5.63293 19.403 5.64811C18.8416 5.66749 18.2769 5.68604 17.7197 5.63631C17.5005 5.61692 17.2485 5.45424 17.0951 5.28313C15.6807 3.70182 13.935 2.77209 11.8311 2.47033C7.73793 1.88451 3.60261 4.73439 2.69732 8.79301C1.62682 13.5917 4.66552 18.1561 9.519 18.9155C12.4793 19.3783 14.9988 18.4106 17.0277 16.2073C17.3092 15.9021 17.589 15.7335 18.008 15.7614C18.4134 15.7883 18.8248 15.7394 19.2285 15.7782C19.4426 15.7993 19.7444 15.8836 19.8261 16.0362C19.9087 16.1904 19.8346 16.509 19.7174 16.6793C18.1487 18.9729 16.0263 20.4893 13.3138 21.1425C12.7052 21.2892 12.0815 21.3701 11.4653 21.4814H10.1166C10.0113 21.4535 9.90674 21.4106 9.79969 21.4004C5.22437 20.9967 1.36046 17.6461 0.343908 13.1719C0.182069 12.4681 0.112107 11.7432 0 11.0284V10.4384Z"/>
          <path d="M22 10.7755C21.8407 10.9467 21.6999 11.1414 21.5187 11.2863C20.5106 12.0947 19.5016 12.9014 18.4716 13.6802C18.2979 13.8117 17.981 13.9019 17.8023 13.8303C17.6582 13.7729 17.5688 13.4484 17.5503 13.2335C17.5123 12.8036 17.5393 12.3678 17.5393 11.8713C17.3421 11.8713 17.1794 11.8713 17.0167 11.8713C14.8251 11.8713 12.6336 11.8713 10.4428 11.8713C9.8351 11.8713 9.71456 11.7457 9.71287 11.1262C9.71287 10.8168 9.7095 10.5083 9.71287 10.199C9.72046 9.69998 9.88399 9.54152 10.4201 9.54152C12.5973 9.53815 14.7746 9.53983 16.9518 9.53983C17.1305 9.53983 17.3092 9.53983 17.5393 9.53983C17.5393 9.03324 17.5115 8.57133 17.552 8.11615C17.5688 7.92313 17.6868 7.66604 17.8385 7.5809C17.9684 7.50757 18.2575 7.57922 18.3991 7.68711C19.5025 8.53171 20.5898 9.39654 21.673 10.2664C21.8087 10.3752 21.8921 10.5488 22 10.6921V10.7764V10.7755Z"/>
        </svg>
        <p>Sair</p>
      </a>
    </li>
  </ul>
</div>
