import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SecurityService } from '../service/securityService';
import { ModalService } from 'app/componentes/modal/modal.service';

/**
 * Servico responsavel por verificar se o usuario esta autenticado no sistema,
 * ou seja, se deve ser redirecionado ou nao para a tela de login
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuardService implements CanActivate {

  constructor(
    private securityService: SecurityService,
    public router: Router,
    private modalService: ModalService,
  ) { }

  userIsAutenticated: boolean;

  showLogoutModal() {
    this.modalService.showModal({
      icon: 'fa-regular fa-left-from-bracket',
      title: 'Sessão expirada',
      messageModal: 'Por favor faça login novamente para continuar usando o Tensai.',
      isOnlyConfirmation: true,
      btnTitlePositive: 'Entendi',
      positiveCallback: () => {
        this.router.navigate(['/login']);
        this.userIsAutenticated = false;
      },
    });
  }

  canActivate(): boolean {
    const isAuthenticated = !!this.securityService.getAuthenticatedUser();

    if (isAuthenticated) {
      this.userIsAutenticated = true;
      return true;
    } else {
      if (this.userIsAutenticated) {
        this.showLogoutModal();
      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }
}
