import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OptionChoose } from "app/modulos/pesquisa-beta/listagem/pesquisas.component";

@Component({
  selector: "app-modal-select-options",
  templateUrl: "./modal-select-options.component.html",
  styleUrls: ["./modal-select-options.component.scss"],
})
export class ModalSelectOptionsComponent implements OnInit {
  @Input() data: OptionChoose[];

  @Output() handleChangeById = new EventEmitter();
  @Output() handleCloseModal = new EventEmitter();
  @Output() handleExportSurvey = new EventEmitter();

  ngOnInit() { }

  handleChangeCheckedValue(id: number) {
    this.handleChangeById.emit(id);
  }

  handleCloseThisModal(): void {
    this.handleCloseModal.emit();
  }

  handleThisExport(): void {
    this.handleExportSurvey.emit();
  }

  isValid(): boolean {
    return this.data.some((item) => item.checked);
  }
}
