
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { TipoPergunta } from '../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { CardQuestionario } from '../../../card/cardQuestionario';
import { TipoConteudo } from '../conteudo/tipoConteudo';
// tslint:disable-next-line: max-line-length
import { map } from 'rxjs/operators';
import { AlterarPergunta, Selecionar } from '../../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../../pesquisa-old/cadastro/store/passoCadastro';

/**
 * Classe base que todo componente de pergunta
 * deve extender
 */
@Component({
  template: ''
})
export abstract class AbstractPerguntaComponent implements CardQuestionario {

  /**
   * Marcador se a seção está selecionada
   */
  selecionado: boolean = false;

  /**
   * Subscriptions que deverão ser encerradas
   * junto com a destruição do componente
   */
  subscriptions: Subscription[] = [];

  /**
   * FormGroup da pergunta
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Ordem da pergunta, deve ser igual à ordem do elemento seção
   */
  @Input() ordem: number;

  /**
   * Acesso rápido aos controls do formGroup
   */
  controls: { [key: string]: AbstractControl };

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
  }

  /**
   * Tipo do conteudo da pergunta, sera utilizado para saber
   * qual conteudo exibir na componente de questionario
   */
  abstract tipoConteudo: TipoConteudo;
  /**
   * Tipo da pergunta, esta variavel devera ser definida
   * por suas implementacoes
   */
  abstract tipoPergunta: TipoPergunta;

  /**
   * @override
   */
  ngOnInit() {
    /**
     * Inserindo o tipo de conteudo da pergunta
     */
    this.formGroup.controls.tipoConteudo.setValue(this.tipoConteudo);
    this.controls = this.formGroup.controls;

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    /**
     * Observando itens selecionados
     */
    const itemSelecionadoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso.get(PassoCadastro.QUESTIONARIO)),
        map(dadosPasso => dadosPasso.itemSelecionado),
      )
      .subscribe((itemSelecionado) => {
        if (!itemSelecionado.item) {
          return;
        }
        this.selecionado = this.controls.hash.value === itemSelecionado.item.hash;
      });

    this.subscriptions.push(itemSelecionadoSubscription);
  }

  /**
   * @override
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  onChange() {
    this.store.dispatch(new AlterarPergunta(this.formGroup.value));
  }

  notificarSelecao(event: Event) {
    // console.log('estou notificando a seleção da pergunta :))))))');
    event.stopPropagation();

    const itemSelecionado = this.formGroup.getRawValue();
    this.store.dispatch(new Selecionar(itemSelecionado, this.tipoConteudo));
  }

}
