import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { ModalService } from "app/componentes/modal/modal.service";
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { v4 as uuid } from "uuid";
import {
  CompletedLoadingEvent,
  FailedLoadingEvent,
  PendingLoadingEvent,
} from "./events/load";
import { FormMode } from "./events/mode";
import {
  CompletedSavingEvent,
  FailedSavingEvent,
  PendingSavingEvent,
} from "./events/save";
import {
  ICanNotSaveButton,
  LocalidadeRequest,
  OperadorRequest,
  PesquisaRequest,
  PesquisaResponse,
  VinculoRequest,
  VinculoResponse,
} from "./pesquisas-cadastro.model";
import { PesquisaCadastroService } from "./servico/pesquisa-cadastro.service";
import { ConfiguracoesModel } from "./steps/pesquisas-configuracoes/pesquisas-configuracoes.model";
import {
  PageLocationOutput,
  PesquisasLocalidadesComponent,
} from "./steps/pesquisas-localidades/pesquisas-localidades.component";
import { PesquisaLocalidadeService } from "./steps/pesquisas-localidades/servico/pesquisa-localidades.service";
import { PesquisasLogicaComponent } from "./steps/pesquisas-logica/pesquisas-logica.component";
import {
  ActiveSectionElementData,
  PesquisasQuestionarioComponent,
} from "./steps/pesquisas-questionario/pesquisas-questionario.component";
import { SectionConfigModel } from "./steps/pesquisas-questionario/pesquisas-questionario.model";
import {
  PVinculoOut,
  PesquisasVinculosComponent,
} from "./steps/pesquisas-vinculos/pesquisas-vinculos.component";
import { PesquisaVinculosService } from "./steps/pesquisas-vinculos/servico/pesquisa-vinculos.service";

const maxTimeout: number = 30000; // em segundos

// estrutura criada para receber metadados relacionados a ação de salvar
type SaveMetaData = { name: string; payload: { ordem: number } | undefined };

@Component({
  selector: "app-pesquisas-cadastro",
  templateUrl: "./pesquisas-cadastro.component.html",
  styleUrls: ["./pesquisas-cadastro.component.scss"],
})
export class PesquisasCadastroComponent implements AfterViewChecked, OnInit {
  private _activeStep: number = 1;

  @ViewChild(PesquisasQuestionarioComponent, { static: true })
  questionario: PesquisasQuestionarioComponent;
  @ViewChild(PesquisasVinculosComponent, { static: true })
  vinculos: PesquisasVinculosComponent;
  @ViewChild(PesquisasLocalidadesComponent, { static: true })
  localidades: PesquisasLocalidadesComponent;
  @ViewChild(PesquisasLogicaComponent, { static: true })
  logicas: PesquisasLogicaComponent;

  // models
  pLocationOtp: PageLocationOutput;
  survey: PesquisaRequest;

  //  Estado para armazenar as perguntas resultantes de ações do usuario (copia, edicao e criacao)
  //  Para fazer uma animação de foco na listagem de pesquisa
  activeSectionElementData: ActiveSectionElementData =
    {} as ActiveSectionElementData;

  // define se a tela está ocupada (equivalente ao isLoading)
  isBusy: boolean = false;
  // status da pesquisa
  surveyStatus: string = "RASCUNHO";
  // controle de habilitação dos botões anterior e próximo
  previousButtonEnabled: boolean = false;
  nextButtonEnabled: boolean = false;
  // modo da pesquisa
  surveyMode: FormMode = FormMode.Done;
  // controle de timeout para a operação de salvar
  saveTimeoutId: number = 0;
  loadTimeoutId: number = 0;

  // Varíavel para obter localidades com valor
  locationsRef;

  // Variável
  requestSurvey;

  // data to be Reorder
  payloadSectionData: SectionConfigModel[] = [];

  loadingSave: boolean = false;

  // variável que verifica se pode salvar e avançar no passo 1
  canNotSaveButton: boolean = true;

  // variável para guardar o passo de configurações
  initialNewDataSurveyToSave;

  checkboxStateLocationStep: boolean = false;

  constructor(
    private router: Router,
    private eventBus: EventBus,
    private route: ActivatedRoute,
    private notificatorService: NotificatorService,
    private pesquisaCadastroService: PesquisaCadastroService,
    private localidadeService: PesquisaLocalidadeService,
    private vinculoService: PesquisaVinculosService,
    private cdr: ChangeDetectorRef,
    private errorHandlerService: ErrorHandlerService,
    private modalService: ModalService
  ) {}

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "Pesquisas",
      itemLink: "/pesquisa-beta",
      active: false,
    },
    {
      itemName: "Nova Pesquisa",
      itemLink: "/pesquisa-beta/cadastro-beta",
      active: true,
    },
  ];

  /**
   * inicializa o componente
   */
  ngOnInit() {
    // registrar ouvintes para eventos
    this.eventBus.on(PendingLoadingEvent.TYPE, () => {
      this.isBusy = true;
    });
    this.eventBus.on(PendingSavingEvent.TYPE, () => {
      this.isBusy = true;
    });
    this.eventBus.on(CompletedLoadingEvent.TYPE, () => {
      this.isBusy = false;
    });
    this.eventBus.on(CompletedSavingEvent.TYPE, () => {
      this.isBusy = false;
    });
    this.eventBus.on(FailedLoadingEvent.TYPE, () => {
      this.isBusy = false;
      this.showError("Ocorreu um erro ao carregar dados do servidor");
    });
    this.eventBus.on(FailedSavingEvent.TYPE, () => {
      this.isBusy = false;
      this.showError("Ocorreu um erro ao enviar dados para o servidor");
    });
    // verifica se está editando ou inserindo
    if (this.route.snapshot.queryParams.id) {
      // recupera os dados para edição
      this.refreshSurvey();
    } else {
      // cria o modelo inicial
      this.survey = null;
      this.setSurveyStatus("RASCUNHO");
      this.editing();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  // propaga função pra saber se habilita ou desabilita o botão de avançar com o mesmo comportamento do botão salvar
  handleCanNotSaveButton($event: ICanNotSaveButton) {
    this.canNotSaveButton = $event.metadata.canNotSaveButton;
  }

  /**
   * função que busca os dados para salvar com o clique do botão avançar
   * @param $event
   */
  handleNewDataSurveyToSave($event) {
    this.initialNewDataSurveyToSave = $event;
  }

  /**
   * nova função de salvar do click do botão de avançar
   *
   * parâmetro do step que deve ser salvo ou avançado
   * @param stepSave
   */
  handleSave(stepSave: number) {
    switch (stepSave) {
      case 1:
        this.handleStep1Save(this.initialNewDataSurveyToSave);
        this.setCurrentStep(2);
        break;

      case 2:
        this.setCurrentStep(3);
        break;

      case 3:
        /**
         * verificação se a pesquisa é 100% online
         * se for o usuário é redirecionado para o passo 6
         * caso tiver amostras presenciais segue o fluxo para
         * para o passo 4
         */
        if (this.isOnlineOnly()) this.setCurrentStep(6);
        else this.setCurrentStep(4);
        break;

      case 4:
        this.handleStep4Save(this.initialNewDataSurveyToSave);
        this.setCurrentStep(5);
        break;

      case 5:
        this.handleStep5Save(this.initialNewDataSurveyToSave);
        this.setCurrentStep(6);
        break;

      default:
        break;
    }
  }

  // Prevenção de reload da página
  @HostListener("window:beforeunload", ["$event"])
  cannotReloadPage(event: Event) {
    // Prevenção de reload da página
    event.preventDefault();
    return false;
  }

  isOnlineOnly(): boolean {
    if (this.survey && this.survey.configuracaoPesquisa) {
      const config = this.survey.configuracaoPesquisa;
      return config.amostrasPresenciais === 0;
    }
    return false;
  }

  /**
   * TODOS: Refazer a lógica de validação dos passos 4 e 5, embora habilite o botão
   * no clique de salvar, o currentStep, não avança
   *
   */

  /**
   * caso exista pesquisa presencial, será permitirá mover para frente
   * do passo 5 somente se tiver pelo menos 1 operador vinculado a cada
   * pesquisa.
   */
  allowNextInStep5(): boolean {
    if (this.allowNextInStep4()) {
      const cotasTotal = this.survey.vinculos.reduce((acc, vinculo) => {
        if (vinculo.filhos.length > 0) {
          const totalFilhos = vinculo.filhos.reduce((accFilho, filho) => {
            if (
              Array.isArray(filho.operadores) &&
              filho.operadores.length > 0
            ) {
              acc += filho.operadores
                .map((operadores) => operadores.cotaValor)
                .reduce((acc, cota) => acc + cota);
            }

            return accFilho;
          }, 0);

          acc += totalFilhos;
        } else {
          if (
            Array.isArray(vinculo.operadores) &&
            vinculo.operadores.length > 0
          ) {
            acc += vinculo.operadores
              .map((operadores) => operadores.cotaValor)
              .reduce((acc, cota) => acc + cota);
          }
        }

        return acc;
      }, 0);
      return (
        cotasTotal === this.survey.configuracaoPesquisa.amostrasPresenciais
      );
    } else {
      return false;
    }
  }

  /**
   * caso exista pesquisa presencial, será permitirá mover para frente
   * do passo 4 somente se tiver pelo menos 1 vínculo associado.
   */
  allowNextInStep4(): boolean {
    if (this.survey && this.survey.configuracaoPesquisa) {
      if (this.survey.configuracaoPesquisa.amostrasPresenciais > 0) {
        return (
          Array.isArray(this.survey.vinculos) && this.survey.vinculos.length > 0
        );
      }
      return true;
    }
    return false;
  }

  addAttrToSurvey(survey: PesquisaRequest): PesquisaRequest {
    return {
      ...survey,
      vinculos: Array.isArray(survey.vinculos)
        ? survey.vinculos.map((s) => ({
            ...s,
            hash: !s.hash ? uuid() : s.hash,
          }))
        : [],
    };
  }

  manipulateButtons() {
    const step = this.getCurrentStep();
    // generical
    this.previousButtonEnabled =
      step !== 1 && this.surveyMode === FormMode.Done;
    this.nextButtonEnabled = this.surveyMode === FormMode.Done;
    if (step === 4) {
      this.nextButtonEnabled =
        this.nextButtonEnabled && this.allowNextInStep4();
    }
    if (step === 5) {
      this.nextButtonEnabled =
        this.nextButtonEnabled && this.allowNextInStep5();
    }
  }

  // cria um ponto único de apresentação de erro (não recomendado, mas por hora é o que temos)
  showError(msg: string) {
    this.notificatorService.showError("Atenção", msg);
  }

  /**
   * cria a estrutura inicial de uma pesquisa
   */
  emptySurvey(): PesquisaRequest {
    return {
      id: null,
      descricaoPesquisa: {
        titulo: "",
        objetivo: "",
        tipoPesquisa: "",
        tituloCurto: "",
        permitirGravacao: false,
      },
      textoInicial: "",
      configuracaoPesquisa: {
        amostrasPresenciais: 0,
        amostrasOnline: 0,
        distancia: 0,
        tempoMinimo: "",
        agendamentos: [],
        token: "",
        tokenVisualizacao: "",
        cercaEletronica: false,
      },
      elementosQuestionario: [],
      vinculos: [],
      status: "RASCUNHO",
    } as PesquisaRequest;
  }

  adaptVinculoRespToReq(resp: VinculoResponse): VinculoRequest {
    //
    if (resp !== null) {
      // converter response to request
      const req = {
        ...resp,
        localidade: (resp.localidade
          ? {
              id: resp.localidade.id,
              versao: resp.localidade.versao,
            }
          : null) as LocalidadeRequest,
        filhos: Array.isArray(resp.filhos)
          ? resp.filhos.map((f) => this.adaptVinculoRespToReq(f))
          : [],
        operadores: Array.isArray(resp.operadores)
          ? resp.operadores.map(
              (o) =>
                ({
                  id: o.idVinculoOperador,
                  operador: {
                    id: o.idOperador,
                  },
                  cotaValor: o.cotaValor,
                  cotaPercentual: o.cotaPercentual,
                } as OperadorRequest)
            )
          : [],
      } as VinculoRequest;
      // resultado da conversão
      return req;
    }
    // resultado no caso de não haver dados
    return null;
  }

  // função que transforma dados que vem da requisição para o tipo de dados usados
  adaptPesquisaRespToReq(resp: PesquisaResponse): PesquisaRequest {
    if (resp !== null) {
      // converter response to request
      const req = {
        ...resp,
        vinculos: Array.isArray(resp.vinculos)
          ? resp.vinculos.map((v) => this.adaptVinculoRespToReq(v))
          : [],
      } as PesquisaRequest;
      // resultado da conversão
      return req;
    }
    // resultado no caso de não haver dados
    return this.emptySurvey();
  }

  setSurveyStatus(status: string) {
    if (status === "EXECUCAO") {
      this.surveyStatus = "EXECUCAO";
    } else {
      this.surveyStatus = "RASCUNHO";
    }
  }

  // extrai o modelo do questionário a partir da pesquisa
  extractQuestionnaireModelFromSurvey(
    survey: PesquisaRequest
  ): SectionConfigModel[] {
    if (!survey) {
      return null;
    }
    const result: SectionConfigModel[] = [];
    for (let index = 0; index < survey.elementosQuestionario?.length; index++) {
      const e = survey.elementosQuestionario[index];
      const r = {
        ...e,
      } as SectionConfigModel;
      result.push(r);
    }
    return result;
  }

  forwardToExecution() {
    this.pesquisaCadastroService
      .encontrar(+this.route.snapshot.queryParams.id)
      .subscribe((item) => {
        // transforma o resultado da pesquisa (backend) no modelo desejado (frontend)
        this.survey = {
          ...this.adaptPesquisaRespToReq(item),
          status: "EXECUCAO",
        };
        this.setSurveyStatus(this.survey.status);
      });
  }

  /**
   *
   * @param cotaPercentual Valor da amostra percentual
   * @param cotaValor Valor da amostra numérica
   * @param amostrasReferencia Referência numerica para usar como verificador de consistência, pode ser
   * a quantidade de amostras presenciais totais da pesquisa, e se for de uma localidade neta, a quantidade
   * de amostras numericas da propria localidade filha.
   * @returns True caso a amostra percentual testada para a localidade corresponda à seu valor numerico. False
   * caso contrário.
   */
  cotaPercentualIsConsistent(
    cotaPercentual: number,
    cotaValor: number,
    amostrasReferencia: number
  ): boolean {
    const cp = Number(((cotaValor * 100) / amostrasReferencia).toPrecision(15));
    return Number(cotaPercentual.toPrecision(15)) === cp;
  }

  /**
   * recupera a lista de pesquisa baseado no id da rota
   */
  refreshSurvey() {
    // informa para todas os passos que está ficando ocupado
    this.eventBus.fire(new PendingLoadingEvent());
    // busca uma pesquisa por id
    this.pesquisaCadastroService
      .encontrar(+this.route.snapshot.queryParams.id)
      .subscribe((item) => {
        // transforma o resultado da pesquisa (backend) no modelo desejado (frontend)
        this.survey = {
          ...this.adaptPesquisaRespToReq(item),
        };

        // caso queira editar uma pesquisa em execução/arquivada/concluída via URL
        if (
          this.survey.status === "EXECUCAO" ||
          this.survey.status === "CONCLUIDO"
        ) {
          // notifica que não editar uma pesquisa em execução
          this.showError(
            `Não é possível editar uma pesquisa com status de ${this.survey.status.toLowerCase()}`
          );
          // redireciono para a listagem o usuário (caso acesse uma pesquisa via URL)
          this.router.navigate(["/pesquisa-beta"]);
        }

        this.setSurveyStatus(this.survey.status);
        // informa para todas os passos que está ficando ocupado
        this.eventBus.fire(new CompletedLoadingEvent());
        // verifica se a pesquisa tem vínculos
        if (item.vinculos && item.vinculos[0]) {
          // informa para todas os passos que está ficando ocupado
          this.eventBus.fire(new PendingLoadingEvent());
          // lista todas localidades baseada na localidade pai para atribuir os valores
          const childrenObservable =
            this.localidadeService.listarLocalidadesFilhas(
              item.vinculos[0].localidade.idLocalidadePai
            );
          // inscrever para receber a lista de localidades filhas
          childrenObservable.subscribe({
            next: (children) => {
              // listar todos os operadores
              this.vinculoService.listarTodos().subscribe({
                next: (operators) => {
                  // modelo
                  this.pLocationOtp = {
                    selectedItemEvent: {
                      index: null,
                      item: {
                        label: item.vinculos[0].localidade.nomeLocalidadePai,
                        value: item.vinculos[0].localidade.idLocalidadePai,
                        hidden: false,
                      },
                    },
                    locationOtp: {
                      alteredLocations: {
                        type: "localidade",
                        value: +item.configuracaoPesquisa.amostrasPresenciais,
                        // atribui valores as localidades que vem dados na rota
                        locations: children.map((vinculo) => {
                          const existsBond = item.vinculos.find(
                            (v) => v.localidade.id == vinculo.id
                          );

                          const amostrasPresenciais =
                            +item.configuracaoPesquisa.amostrasPresenciais;
                          // Retorna a quantidade de amostras percentuais de maneira consistente
                          // isso significa que, se uma pesquisa antiga, que usa a lógica antiga
                          // de distribuição de forma numérica, tiver dados de cotaValor ou
                          // cotaPercentual incorretos, essa garantia de consistência irá lidar
                          // com essa inconsistência e apresenta o valor relativo às amostras
                          // presenciais totais. O mesmo vale para localidades netas.
                          const locationValue = existsBond
                            ? (this.cotaPercentualIsConsistent(
                                existsBond.cotaPercentual,
                                existsBond.cotaValor,
                                amostrasPresenciais
                              ) &&
                                existsBond.cotaPercentual) ||
                              (existsBond.cotaValor / amostrasPresenciais) * 100
                            : null;
                          return {
                            id: vinculo.id,
                            label: vinculo.nome,
                            operators: existsBond
                              ? existsBond.operadores.map((o) => {
                                  return {
                                    id: o.idOperador,
                                    value: o.cotaValor,
                                    label: operators.find(
                                      (v) => v.id === o.idOperador
                                    ).nome,
                                  };
                                })
                              : [],
                            value: locationValue,
                            version: vinculo.versao,
                            sublocations: vinculo.filhas.map((grandchild) => {
                              const existsGrandChildBond = existsBond
                                ? existsBond.filhos.find(
                                    (v) => v.localidade.id === grandchild.id
                                  )
                                : null;

                              const subAmostrasPresenciais =
                                existsBond && existsBond.cotaValor;
                              const subLocationValue = existsGrandChildBond
                                ? (this.cotaPercentualIsConsistent(
                                    existsGrandChildBond.cotaPercentual,
                                    existsGrandChildBond.cotaValor,
                                    subAmostrasPresenciais
                                  ) &&
                                    existsGrandChildBond.cotaPercentual) ||
                                  (existsGrandChildBond.cotaValor /
                                    subAmostrasPresenciais) *
                                    100
                                : null;
                              return {
                                operators: existsGrandChildBond
                                  ? existsGrandChildBond.operadores.map((o) => {
                                      return {
                                        id: o.idOperador,
                                        value: o.cotaValor,
                                        label: operators.find(
                                          (v) => v.id === o.idOperador
                                        ).nome,
                                      };
                                    })
                                  : [],
                                id: grandchild.id,
                                nome: grandchild.nome,
                                versao: grandchild.versao,
                                value: subLocationValue,
                              };
                            }),
                          };
                        }),
                      },
                    },
                  };

                  // informa para todas os passos que está ficando desocupado
                  this.eventBus.fire(new CompletedLoadingEvent());
                },
                error: (err) => {
                  // informa para todas os passos que está ficando
                  // desocupado, mas que a operação falhou
                  this.eventBus.fire(new FailedLoadingEvent());
                },
              });
            },
            error: (err) => {
              // informa para todas os passos que está ficando
              // desocupado, mas que a operação falhou
              this.eventBus.fire(new FailedLoadingEvent());
            },
          });
        }
      });
    //
    // elimina a possibilidade de bloqueio de tela
    clearTimeout(this.loadTimeoutId);
    this.loadTimeoutId = window.setTimeout(() => {
      // informa para todas os passos que está ficando
      // desocupado, mas que a operação falhou
      if (this.isBusy) this.eventBus.fire(new FailedLoadingEvent());
    }, maxTimeout);
  }

  /**
   * retorna a informação de que o usuario ao cadastrar a pesquisa e passar os passos, pulou o passo de logica
   * sem configurar lógica.
   * @returns boolean
   */
  naoPulouMarcacaoDePulos(): boolean {
    return (
      this._activeStep > 3 &&
      this.survey.elementosQuestionario.some((elementoQuestionario) =>
        elementoQuestionario.secao.elementosSecao.some(
          (elementoSecao) =>
            !!elementoSecao.pergunta.marcacaoPergunta ||
            (elementoSecao.pergunta.alternativas &&
              elementoSecao.pergunta.alternativas.some(
                (alternativa) => !!alternativa.marcacaoPergunta
              ))
        )
      )
    );
  }

  /**
   * para passar para o próximo step, que não pode ser maior que 5
   * @returns number
   */
  nextStep(): number {
    // condição de salto para o caso de pesquisa online
    if (this._activeStep === 3 && this.isOnlineOnly()) {
      return 6;
    }
    // se for requerido vínculo
    if (this._activeStep === 4 && !this.allowNextInStep4()) {
      return 4;
    }
    // se for requerido vínculo
    if (this._activeStep === 5 && !this.allowNextInStep5()) {
      return 5;
    }
    // condição de passo final
    if (this._activeStep === 6) {
      return this._activeStep;
    }
    // calculo do passo anterior
    return (this._activeStep += 1);
  }

  /**
   * para voltar um step, que não pode ser menor que 1
   * @returns number
   */
  previousStep(): number {
    // condição de salto para o caso de pesquisa online
    if (this.isOnlineOnly() && this._activeStep === 6) {
      return 3;
    }
    // condição de passo inicial
    if (this._activeStep === 1) {
      return this._activeStep;
    }
    // calculo do passo anterior
    return (this._activeStep -= 1);
  }

  /**
   * pegar o step atual
   * @returns number
   */
  getCurrentStep(): number {
    return this._activeStep;
  }

  /**
   * define o step atual
   * @returns number
   */
  setCurrentStep(index: number) {
    this._activeStep = index;
    this.manipulateButtons();
  }

  /**
   * define que a pesquisa está pronta para ser salva
   */
  done() {
    this.surveyMode = FormMode.Done;
    this.manipulateButtons();
  }

  /**
   * define que a pesquisa está pronta para ser salva
   */
  editing() {
    this.surveyMode = FormMode.Editing;
    this.manipulateButtons();
  }

  /**
   * salvar dados
   */
  save(metadata: SaveMetaData) {
    this.loadingSave = true;
    // informa para todas os passos que está ficando ocupado
    this.eventBus.fire(new PendingSavingEvent());
    // altera o status da pesquisa
    this.survey = this.addAttrToSurvey(this.survey);
    this.survey.status = this.surveyStatus;

    /**
     * Variável para ser enviada a API com a formatação dos vinculos
     * Para caso a pesquisa possua localidades filhas com localidades netas
     * Atender o request esperado pela API para retornar o Array de filhos
     * preenchidos
     */
    const newVinculos = this.handleFormattedSurveyVinculos(
      this.survey.vinculos
    );

    if (!this.survey.id) {
      this.requestSurvey = {
        ...this.survey,
        // verificação para remover os vínculos caso a pesquisa seja 100% online
        vinculos: this.isOnlineOnly() ? [] : newVinculos,
      };

      // salvar
      this.pesquisaCadastroService
        .salvarPesquisa(this.requestSurvey)
        .subscribe({
          next: (item) => {
            // convertendo resp to req
            this.survey = this.adaptPesquisaRespToReq(item);
            this.setSurveyStatus(this.survey.status);
            const notObj = this.createNotificationMessage(
              metadata.name,
              metadata.payload
            );
            this.notificatorService.showInfo(
              notObj.tituloNotificacao,
              notObj.mensagemNotificacao
            );
            // informa para todas os passos que está ficando desocupado e que teve sucesso
            this.eventBus.fire(new CompletedSavingEvent());
            this.loadingSave = false;
          },
          error: (err) => {
            // notificação de erro
            this.errorHandlerService.handleError(
              err,
              "Erro ao criar uma nova pesquisa"
            );

            this.eventBus.fire(new FailedSavingEvent());
            this.loadingSave = false;
          },
        });
    } else {
      if (this.getCurrentStep() !== 5) {
        this.requestSurvey = {
          ...this.survey,
          // verificação para remover os vínculos caso a pesquisa seja 100% online
          vinculos: this.isOnlineOnly() ? [] : newVinculos,
        };
      } else {
        this.requestSurvey = this.survey;
      }

      // log
      this.pesquisaCadastroService
        .atualizarPesquisa(this.requestSurvey)
        .subscribe({
          next: (item) => {
            // convertendo resp to req
            this.survey = this.adaptPesquisaRespToReq(item);
            this.setSurveyStatus(this.survey.status);
            const notObj = this.createNotificationMessage(
              metadata.name,
              metadata.payload
            );
            this.notificatorService.showInfo(
              notObj.tituloNotificacao,
              notObj.mensagemNotificacao
            );
            // informa para todas os passos que está ficando desocupado e que teve sucesso
            this.eventBus.fire(new CompletedSavingEvent());
            this.loadingSave = false;
          },
          error: (err) => {
            // notificação de erro
            this.errorHandlerService.handleError(
              err,
              "Erro ao alterar uma pesquisa"
            );
            // log
            console.error("Erro ao alterar pesquisa: ", err);
            // informa para todas os passos que está ficando desocupado e que teve falha
            this.eventBus.fire(new FailedSavingEvent());
            this.loadingSave = false;
          },
        });
    }
    // elimina a possibilidade de bloqueio de tela
    clearTimeout(this.saveTimeoutId);
    this.saveTimeoutId = window.setTimeout(() => {
      // informa para todas os passos que está ficando desocupado e que teve falha
      if (this.isBusy) this.eventBus.fire(new FailedSavingEvent());
    }, maxTimeout);
  }

  //  Função que retorna mensagens das notificações de feedback da API para ações de
  //  CRUD de seções e perguntas no segundo passo do cadastro de pesquisa
  createNotificationMessage(
    acao: string,
    payload: { ordem: number } | undefined
  ) {
    switch (acao) {
      case "cadastrar-pesquisa": //ok
        return {
          tituloNotificacao: "Pesquisa salva",
          mensagemNotificacao: "Progresso salvo com sucesso",
        };
      case "editar-pesquisa": //ok
        return {
          tituloNotificacao: "Pesquisa editada",
          mensagemNotificacao: "Progresso salvo com sucesso",
        };
      case "salvar-secao": //ok
        return {
          tituloNotificacao: "Seção cadastrada",
          mensagemNotificacao: "Continue o cadastro da pesquisa",
        };
      case "deletar-secao": //ok
        return {
          tituloNotificacao: "Seção excluída",
          mensagemNotificacao: "Sua seção foi excluída com sucesso!",
        };
      case "cadastrar-pergunta": //ok
        return {
          tituloNotificacao: `Pergunta cadastrada`,
          mensagemNotificacao: "Continue cadastrando perguntas",
        };
      case "editar-pergunta": //ok
        return {
          tituloNotificacao: `Pergunta alterada`,
          mensagemNotificacao: "Continue cadastrando perguntas",
        };
      case "duplicar-pergunta": //ok
        return {
          tituloNotificacao: `Cópia realizada`,
          mensagemNotificacao: `Pergunta duplicada`,
        };
      case "deletar-pergunta": //ok
        return {
          tituloNotificacao: `Pergunta excluída`,
          mensagemNotificacao: `Sua pergunta foi excluída com sucesso!`,
        };
      case "salvar-logica":
        return {
          tituloNotificacao: `Lógica cadastrada`,
          mensagemNotificacao: `Configuração de lógica realizada`,
        };
      case "executar-pesquisa":
        return {
          tituloNotificacao: "Status modificado",
          mensagemNotificacao: "Pesquisa em execução",
        };
      default:
        return {
          tituloNotificacao: "Pesquisa salva",
          mensagemNotificacao: "Progresso salvo com sucesso",
        };
    }
  }

  // reseta todas lógicas das perguntas
  resetLogicQuestions() {
    // percorre os elementos do questionário
    for (let i = 0; i < this.survey.elementosQuestionario.length; i++) {
      const section = this.survey.elementosQuestionario[i];
      // percorre os elementos da secao
      for (
        let index = 0;
        index < section.secao.elementosSecao.length;
        index++
      ) {
        const sectionElement = section.secao.elementosSecao[index];
        // reseta para null a lógica
        sectionElement.pergunta.marcacaoPergunta = null;
        // percore as alternativas
        for (
          let sectionElementIndex = 0;
          sectionElementIndex < sectionElement.pergunta.alternativas.length;
          sectionElementIndex++
        ) {
          const alternative =
            sectionElement.pergunta.alternativas[sectionElementIndex];
          alternative.marcacao = null;
          alternative.marcacaoPergunta = null;
        }
      }
    }
  }

  /**
   * manipula o botão de finalizar os passos
   */
  handleFinishSteps() {
    // verifica se o status da página de conclusão continua como rascunho na página de conclusão
    if (this.surveyStatus === "RASCUNHO") {
      this.router.navigate(["/pesquisa-beta"]);
    }
    // no último passo verifica, caso acione o botão se o status da pesquisa foi salva como execucao
    else {
      // verificar se está editando
      let timeout = 0;
      if (this.surveyMode === FormMode.Editing) {
        timeout = 2000;
        this.save({ name: "executar-pesquisa", payload: null });
        this.manipulateButtons();
      }
      // delay adicionado para que possa ser reflita a alteração na listagem
      //  Condição acrescentada para o usuario não ser redirecionado para tela de listagem antes de copiar
      //  o link do tensai forms ui. E ao clicar em executar, ele troque o status do formulário para concluido
      const doNotRedirectYet =
        this.surveyMode === FormMode.Editing &&
        this.survey.status === "EXECUCAO";
      setTimeout(() => {
        if (!doNotRedirectYet) this.router.navigate(["/pesquisa-beta"]);
        if (doNotRedirectYet) this.done();
      }, timeout);
    }
  }

  // lida com mudança do status da pesquisa
  handleSurveyStatusChange(event: string) {
    this.setSurveyStatus(event);
    this.manipulateButtons();
  }

  // manipula mudanças na página
  handlePagesChanges(event: any) {
    this.survey = {
      ...this.survey,
      ...event,
    };
    this.setSurveyStatus(this.survey.status);
    this.manipulateButtons();
  }

  // Ordena as seções e os elementos das seções de acordo com a ordem estabelecida
  handleReOrderQuestionsByOrdemNumber(
    models: SectionConfigModel[]
  ): SectionConfigModel[] {
    this.payloadSectionData = [...models];

    for (
      let modelIndex = 0;
      modelIndex < this.payloadSectionData.length;
      modelIndex++
    ) {
      const currentModel = this.payloadSectionData[modelIndex];

      const elementosSecaoArray = currentModel.secao.elementosSecao;

      if (
        !elementosSecaoArray.find(
          (elSecao) => elSecao.ordem === undefined || elSecao.ordem === 0
        )
      ) {
        let orderedElementosSecaoArray = [];

        elementosSecaoArray.forEach((item) => {
          orderedElementosSecaoArray[item.ordem - 1] = item;
        });

        // Garante que não existirá ocorrencias empty x N no array resultante.
        orderedElementosSecaoArray = orderedElementosSecaoArray.filter(String);

        if (orderedElementosSecaoArray.length === elementosSecaoArray.length) {
          currentModel.secao.elementosSecao = orderedElementosSecaoArray;

          currentModel.secao.elementosSecao.forEach((perguntaSecao) => {
            const alternativasPergunta = perguntaSecao.pergunta.alternativas;

            if (
              Array.isArray(alternativasPergunta) &&
              alternativasPergunta.length
            ) {
              let orderedAlternativasArray = [];

              alternativasPergunta.forEach((alternativa) => {
                orderedAlternativasArray[alternativa.ordem - 1] = alternativa;
              });

              orderedAlternativasArray =
                orderedAlternativasArray.filter(String);
            }
          });
        }
      }
    }

    let orderedModels = [];
    for (
      let payloadDataIndex = 0;
      payloadDataIndex < this.payloadSectionData.length;
      payloadDataIndex++
    ) {
      const currentModel = this.payloadSectionData[payloadDataIndex];
      orderedModels[currentModel.ordem - 1] = currentModel;
    }

    // Garante que não existirá ocorrencias empty x N no array resultante.
    orderedModels = orderedModels.filter(String);

    if (
      orderedModels.length === this.payloadSectionData.length &&
      orderedModels.filter((item) => this.payloadSectionData.includes(item))
        .length === this.payloadSectionData.length
    ) {
      // Garante que a ordem das seções será sempre consistente
      let sectionQuestionsOrderAcumulator = 0;
      orderedModels.forEach((secaoItem, secaoIndex) => {
        secaoItem.ordem = 1 + secaoIndex;
        // Garante que para uma seção, o atributo ordem da pergunta está correto.
        secaoItem.secao.elementosSecao.forEach((perguntaSecao) => {
          sectionQuestionsOrderAcumulator += 1;
          perguntaSecao.ordem = sectionQuestionsOrderAcumulator;

          const alternativasPergunta = perguntaSecao.pergunta.alternativas;

          if (
            Array.isArray(alternativasPergunta) &&
            alternativasPergunta.length
          ) {
            alternativasPergunta.forEach((alternativa, alternativaIndex) => {
              alternativa.ordem = alternativaIndex + 1;
            });
          }
        });
      });

      return orderedModels;
    }

    return this.payloadSectionData;
  }

  /**
   * Função responsável por formatar os vinculos para quando alguma localidade
   * possuir netas, transformar em uma localidade filha
   */
  handleFormattedSurveyVinculos(vinculos: VinculoRequest[]) {
    const result = vinculos.reduce((acc, vinculo) => {
      acc.push(vinculo);

      if (vinculo.filhos && vinculo.filhos.length > 0) {
        vinculo.filhos.forEach((item) => {
          acc.push(item);
        });
      }

      return acc;
    }, []);

    return result;
  }

  /**
   * manipulando o passo de salvar configurações
   * @param model modelo de dados de configurações
   */
  handleStep1Save($event: {
    metadata: { name: string; seq: number };
    model: ConfiguracoesModel;
  }) {
    const model = $event.model;
    this.survey = {
      ...this.survey,
      cliente: model.cliente,
      descricaoPesquisa: model.descricaoPesquisa,
      configuracaoPesquisa: model.configuracaoPesquisa,
      textoInicial: model.textoInicial,
    };
    this.setSurveyStatus(this.survey.status);
    // save
    this.save({
      name: $event.metadata.name,
      payload: { ordem: $event.metadata.seq },
    });
  }

  /**
   * manipulando o passo de salvar configurações
   * @param model modelo de dados de configurações
   */
  handleStep2Save($event: any) {
    const models = this.handleReOrderQuestionsByOrdemNumber($event.models);

    this.survey = {
      ...this.survey,
      elementosQuestionario: Array.isArray(models)
        ? models.map((m) => ({
            ...m,
            secao: {
              ...m.secao,
              elementosSecao:
                m.secao && m.secao.elementosSecao
                  ? m.secao.elementosSecao.map((e) => ({
                      ...e,
                      pergunta: {
                        ...e.pergunta,
                        nome: e.pergunta.nome.replace(/(\r\n|\n|\r)/gm, ""),
                        codigoMarcacao:
                          typeof e.pergunta.codigoMarcacao !== "undefined" &&
                          !(Number(e.pergunta.codigoMarcacao) >= 0) &&
                          e.pergunta.id &&
                          e.pergunta.id >= 0
                            ? String(e.pergunta.id)
                            : e.pergunta.codigoMarcacao,
                        possuiPuloPergunta:
                          (e.pergunta.marcacaoPergunta && true) || false,
                        possuiPulo:
                          (e.pergunta.alternativas &&
                            e.pergunta.alternativas.find(
                              (alt) => alt.marcacao
                            ) &&
                            true) ||
                          false,
                        alternativas: e.pergunta.alternativas.length
                          ? e.pergunta.alternativas.map((a) => ({
                              ...a,
                              descricao: a.descricao.replace(
                                /(\r\n|\n|\r)/gm,
                                ""
                              ),
                            }))
                          : e.pergunta.alternativas,
                        titulosGrade: e.pergunta.titulosGrade.length
                          ? e.pergunta.titulosGrade.map((tG) => ({
                              ...tG,
                              descricao: tG.descricao.replace(
                                /(\r\n|\n|\r)/gm,
                                ""
                              ),
                            }))
                          : e.pergunta.titulosGrade,
                      },
                    }))
                  : [],
            },
          }))
        : [],
    };

    this.setSurveyStatus(this.survey.status);
    // save
    this.save({
      name: $event.metadata.name,
      payload: { ordem: $event.metadata.seq },
    });
    if (
      $event.metadata.newSectionElementData &&
      $event.metadata.newSectionElementData.sectionElementOrdem
    ) {
      this.activeSectionElementData = $event.metadata.newSectionElementData;
    } else {
      this.activeSectionElementData = {} as ActiveSectionElementData;
    }
  }

  /**
   * manipulando o passo de salvar configurações
   * @param model modelo de dados de configurações
   */
  handleStep3Save($event: any) {
    this.save({ name: $event.metadata.name, payload: null });
  }

  handleIncreasePercentageValue(value): number {
    return 0;
  }

  // manipula alterações de localidade
  handleStep4Save(item: PageLocationOutput) {
    let localidadeFilhaPercentageSum = 0;
    let localidadeNetaPercentageSum = 0;
    let howMuchToAddLocalidadeNeta = 0;
    const newVinculo = [];
    const locations = item.locationOtp.alteredLocations.locations.filter(
      (obj) => obj.value > 0
    );

    if (locations.length > 0) {
      // variavel para agir como indice total de vinculo
      // percorre localidades
      for (let i = 0; i < locations.length; i++) {
        const parentHash = uuid();

        // Cálculos de consistência para as amostras percentuais e numericas.
        const cotaValorNumerica =
          (locations[i].value * item.locationOtp.alteredLocations.value) / 100;
        const cotaValorNumericaComPrecisao = Number(
          cotaValorNumerica.toPrecision(15)
        );
        const cotaValorNumericaConsistente = Number.isInteger(
          cotaValorNumericaComPrecisao
        )
          ? cotaValorNumericaComPrecisao
          : Math.round(cotaValorNumericaComPrecisao);

        const cotaPercentualValue = locations[i].value;
        // atribui a novo vinculo
        newVinculo.push({
          id: this.survey?.vinculos[i]?.id || null,
          localidade: {
            id: locations[i].id,
            // idLocalidadePai: item.selectedItemEvent.item.value,
            versao: locations[i].version,
          },
          cotaValor: cotaValorNumericaConsistente || 0,
          cotaPercentual: cotaPercentualValue || 0,
          hash: parentHash,
          parentHash: null,
          // reseta operadores
          operadores: [...this.survey?.vinculos[i]?.operadores?.map(op => ({...op})) || []] || [],
        });

        // Contador para somatório de porcentagem
        localidadeFilhaPercentageSum += locations[i].value
          ? Number(
              (
                (locations[i].value * 100) /
                item.locationOtp.alteredLocations.value
              ).toFixed(2)
            )
          : 0;
        // caso exista sublocalidades
        if (locations[i].sublocations.length > 0) {
          // percorre lista de localidades filhas
          for (
            let index = 0;
            index < locations[i].sublocations.length;
            index++
          ) {
            const element = locations[i].sublocations[index];
            // verifica se ele tem valor: o valor aqui é a porcentagem distribuida
            if (element.toggleChecked && element.value > 0) {
              // Verificar se o cáculo da última posição irá fechar em 100%
              if (index === locations[i].sublocations.length - 1) {
                const howManyLeft =
                  100 - Number(localidadeNetaPercentageSum.toFixed(2));

                const lastVinculoToAdd = Number(
                  ((element.value * 100) / locations[i].value).toFixed(2)
                );

                if (lastVinculoToAdd === howManyLeft) {
                  howMuchToAddLocalidadeNeta = lastVinculoToAdd;
                } else {
                  howMuchToAddLocalidadeNeta = howManyLeft;
                }
              }

              const subCotaValorNumerica =
                (element.value * cotaValorNumericaConsistente) / 100;
              const subCotaValorNumericaComPrecisao = Number(
                subCotaValorNumerica.toPrecision(15)
              );
              const subCotaValorNumericaConsistente = Number.isInteger(
                subCotaValorNumericaComPrecisao
              )
                ? subCotaValorNumericaComPrecisao
                : Math.round(subCotaValorNumericaComPrecisao);

              const subCotaPercentual = element.value;
              // atribui a vinculo
              newVinculo.push({
                localidade: {
                  id: element.id,
                  // idLocalidadePai: locations[i].id,
                  versao: element.versao,
                },
                cotaValor: subCotaValorNumericaConsistente || 0,
                cotaPercentual: subCotaPercentual || 0,
                hash: uuid(),
                parentHash: parentHash,
                // reseta operadores
                operadores: [],
              });
              // Contador para somatório de porcentagem
              localidadeNetaPercentageSum += element.value
                ? Number(
                    ((element.value * 100) / locations[i].value).toFixed(2)
                  )
                : 0;
              //
            }
          }
        }
      }
      this.survey.vinculos = newVinculo;
    }
    this.pLocationOtp = {
      ...item,
    };
    // save
    this.save({ name: "salvar-localidades", payload: null });
  }

  /**
   * Função responsável por formatar e que prepara os dados de vinculos para o request no passo 5
   */
  handleFormattedDataToVinculos(locations) {
    const newVinculos = [];
    // percorre localidades
    for (let i = 0; i < locations.length; i++) {
      // novos operadores
      const newOperators: OperadorRequest[] = [];
      if (locations[i].sublocations.length === 0) {
        for (
          let opIndex = 0;
          opIndex < locations[i].operators.length;
          opIndex++
        ) {
          const oldOperatorLinkId: number = this.survey.vinculos[i].operadores[opIndex]?.operador.id || null;
          const newOperatorLinkId: number = locations[i].operators[opIndex]?.id || null;
          newOperators.push({
            cotaValor: locations[i].operators[opIndex].value,
            cotaPercentual: Number(
              (locations[i].operators[opIndex].value /
                this.survey.vinculos[i].cotaValor) *
                100
            ),
            id: oldOperatorLinkId === newOperatorLinkId
              ? this.survey.vinculos[i].operadores[opIndex]?.id || null
              : null,
            operador: {
              id: locations[i].operators[opIndex].id,
            },
          });
        }
        // novos vínculos
        newVinculos.push({
          ...this.survey.vinculos[i],
          hash: uuid(),
          operadores: newOperators,
        });
      } else {
        // novos vínculos
        newVinculos.push({
          ...this.survey.vinculos[i],
          // hash: uuid(),
          // operadores: newOperators,
        });
      }

      if (
        this.survey &&
        this.survey.vinculos[i].filhos &&
        this.survey.vinculos[i].filhos.length > 0
      ) {
        /**
         * TODO: Trabalhar com cotas nas localidades netas.
         * i => locations array
         * f => sublocations array
         * o => operetors array
         */
        for (let f = 0; f < this.survey.vinculos[i].filhos.length; f++) {
          const newOperatorsGrand: OperadorRequest[] = [];

          for (
            let o = 0;
            o < locations[i].sublocations[f].operators.length;
            o++
          ) {
            newOperatorsGrand.push({
              cotaValor: locations[i].sublocations[f].operators[o].value,
              cotaPercentual: Number(
                (
                  (locations[i].sublocations[f].operators[o].value /
                    locations[i].sublocations[f].value) *
                  100
                ).toFixed(2)
              ),
              id: this.survey.vinculos[i].operadores[o].id || null,
              operador: {
                id: locations[i].sublocations[f].operators[o].id,
              },
            });
          }

          newVinculos.push({
            ...this.survey.vinculos[i].filhos[f],
            operadores: newOperatorsGrand,
          });
        }
      }
    }
    return newVinculos;
  }

  // manipula alterações em vínculo
  handleStep5Save(item: PVinculoOut) {
    this.locationsRef = item.locationOtp.alteredLocations.locations.filter(
      (obj) => obj.value > 0
    );
    const newVinculos = this.handleFormattedDataToVinculos(this.locationsRef);

    this.survey = {
      ...this.survey,
      vinculos: newVinculos,
    };
    //
    this.pLocationOtp = {
      ...this.pLocationOtp,
      locationOtp: item.locationOtp,
    };
    // save
    this.save({ name: "salvar-vinculos", payload: null });
  }

  /**
   * manipulando a modificação do estado de pronto da pesquisa
   */
  handleStepDone() {
    this.done();
  }

  /**
   * manipulando a modificação do estado de edição da pesquisa
   */
  handleStepChange() {
    this.editing();
  }

  /**
   * manipulando a modificação do estado de editando para pronto da pesquisa
   */
  handleStepCancel() {
    this.done();
  }

  /**
   * manipula a movimentação entre telas observando apenas se é
   * para frente ou para trás.
   *
   * @param isNext (true se for para frente)
   */
  handleSeekStep(isNext: boolean) {
    // verificar se está em modo de edição
    if (this.surveyMode === FormMode.Editing) {
      this.showError(
        "Não pode ir para o próximo passo antes de concluir o que está fazendo"
      );
      return;
    }
    // verificar se está ocupado
    else if (this.isBusy) {
      this.showError(
        "Aguarde a operação de comunicação com o servidor concluir"
      );
      return;
    }
    // movimenta
    if (!isNext) {
      const r = this.previousStep();
      this.setCurrentStep(r);
    } else {
      const r = this.nextStep();
      this.setCurrentStep(r);
    }
  }

  /**
   * manipula a movimentação entre telas para trás
   */
  handlePreviousStep() {
    this.handleSeekStep(false);
  }

  /**
   * manipula a movimentação entre telas para frente
   */
  handleNextStep() {
    this.handleSeekStep(true);
  }
}
