import { Component, Input } from "@angular/core";
import { IOverviewData, IResumoAuditoria } from "app/modulos/auditoria-beta/interfaces";

@Component({
  selector: "app-audit-survey-data",
  templateUrl: "./audit-survey-data.component.html",
  styleUrls: ["./audit-survey-data.component.scss"],
})
export class AuditSurveyDataComponent  {

  @Input() overviewData: IOverviewData;

  constructor() {}

  ngOnInit(): void {}

}
