<div class="table-container">
  <div class="tableActions bg-white no-print">
    <div class="actions" *ngIf="!apenasListagem">
      <div class="icon-action" *ngIf="exibirExclusao" [class.icon-disabled]="!(trBodiesSelecionados.length > 0)"
        (click)="enviarEventoExclusaoItensSelecionados()">
        <span class="icon-lixeira" title="Excluir selecionado(s)"> </span>
      </div>
      <div *ngIf="!exibirOpcaoDuplicacao && exibirOpcaoDownload && trBodies.length > 0" class="icon-action"
        (click)="enviarEventoDownload()">
        <span class="icon-baixar" title="Baixar selecionado(s)"></span>
      </div>
      <div *ngIf="exibirOpcaoDuplicacao" [class.icon-disabled]="!(trBodiesSelecionados.length > 0)"
        (click)="enviarEventoDuplicacao()" class="icon-action">
        <span class="fa fa-clone" title="Duplicar selecionado(s)"></span>
      </div>
      <div *ngIf="exibirOpcaoArquivar" [class.icon-disabled]="!(trBodiesSelecionados.length > 0)"
        (click)="enviarEventoArquivar()" class="icon-action">
        <span class="fa fa-inbox" title="Arquivar selecionado(s)"></span>
      </div>
    </div>

    <div class="sg-input-group">
      <div class="campo-pesquisa">
        <label for="">Filtrar por palavras-chave:</label>
        <input title="filtro por palavra-chave" (input)="aplicarFiltro($event)" maxlength="50"
          placeholder="{{placeholder}}" type="text" [ngModel]="initialValue">
      </div>
    </div>

  </div>
  <div class="tabelaContent">
    <table class="sg-table">
      <thead>
        <th *ngIf="!ocultarCheckbox" class="table-checkbox-wrapper" scope="col">
          <label class="clickable" title="Marcar itens da página">
            <input type="checkbox" [checked]="trBodiesSelecionados.length > 0 && selecionarTodos"
              (click)="acionarSelecionarTodos()">
          </label>
        </th>
        <th *ngFor="let nomeColuna of nomeColunas" scope="col">{{nomeColuna}}</th>
        <th *ngIf="!apenasListagem && !entidadeSemStatus" scope="col"> Status </th>
        <th *ngIf="!apenasListagem" scope="col"> Visualizar </th>
        <th *ngIf="!apenasListagem && exibirExclusao" scope="col"> Excluir </th>
      </thead>
      <tbody>
        <tr [title]="trBody.title !== undefined ? trBody.title :''" *ngFor="let trBody of trBodies">
          <td *ngIf="!ocultarCheckbox" title="Selecionar" class="table-checkbox-wrapper">
            <input title="selecionar item" class="sg-check" type="checkbox" [checked]="trBody.selected"
              (click)="selectTrBody(trBody)">
          </td>
          <td title="id" *ngIf="!apenasListagem && trBody.id != null">
            {{trBody.id}}
          </td>
          <td title={{nomeColunas[i+1]}} *ngFor="let customData of trBody.customData; let i = index">
            <span>{{customData}}</span>
          </td>
          <td title="Status" *ngIf="!apenasListagem && !entidadeSemStatus">
            <i class="fa fa-circle"
              [ngClass]="{ 'status-habilitado' : trBody.status, 'status-desabilitado' : !trBody.status }"></i>
          </td>
          <td [title]="verifyPositiveId(trBody.id) ? 'Editar' : 'Não é permitido editar'" *ngIf="!apenasListagem"
            [ngClass]="!verifyPositiveId(trBody.id) && 'not-allow-click'" (click)="enviarEventoEdicao(trBody.id)">
            <i [ngClass]="verifyPositiveId(trBody.id) ? 'fa-eye clickable' : 'fa-eye-slash'" class="fa-regular"
              [title]="verifyPositiveId(trBody.id) ? 'Visualizar/Editar' : 'Não é permitido editar ou vizualizar'"></i>
          </td>
          <td title="Excluir" *ngIf="!apenasListagem && exibirExclusao" (click)="enviarEventoExclusao([trBody.id])">
            <i class="fa fa-trash-o clickable" title="Excluir"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginacao-wrapper no-print">
      <app-paginacao [indicadorReinicializacao]="subjectPaginacao" (onPaginationChange)="onPaginationChange($event)">
      </app-paginacao>
    </div>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>