<section class="interviewConfig container-white">
  <div class="headerConfig">
    <span class="fa fa-comment-o"></span>
    <h3>Múltipla escolha</h3>
  </div>
  <div class="contentConfig">
    <div class="section sg-input-group">
      <div class="campo">
        <label>Título da pergunta</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="título da pergunta" title="título principal"  type="text" formControlName="nome" maxlength="250">
        <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
          A pergunta deve possuir um titulo de até 250 caracteres
        </span>
      </div>
      <div class="campo">
        <label>Descrição</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="descrição da pergunta" title="descrição" type="text" formControlName="descricao" maxlength="150">
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
          A pergunta deve possuir uma descrição de até 150 caracteres
        </span>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">
          <h4>Opções</h4>
        </div>
        <div class="subSectionContent">
          <div *ngFor="let alternativa of getControlsFromAbstractFormArray(controls.alternativas); let ordem = index">
            <!-- <app-alternativa-tipada-marcacao
            [formGroup]="alternativa"
            (cotaChange)="calcularCotaNumerica()"
            ></app-alternativa-tipada-marcacao> -->
          </div>
        </div>
      </div>
      <div class="actionConfig">
        <button class="sg-btn sg-alternate" (click)="cancelar()">Cancelar</button>
        <button class="sg-btn sg-secondary" (click)="onChange()" *ngIf="modificacaoPermitida && !passoBloqueado">Salvar</button>
      </div>
    </div>
  </div>
</section>
