import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { LeafletMapBetaComponent } from "app/componentes/leaflet-map-beta/leaflet-map-beta.component";
import { ISurveyResumoColetaResponse } from "../../interfaces";
import { AuditoriaBetaService } from "../../services/auditoria-beta.service";

@Component({
  selector: "app-audit-collection-map",
  templateUrl: "./audit-collection-map.component.html",
  styleUrls: ["./audit-collection-map.component.scss"],
})
export class AuditCollectionMap implements OnInit {
  // controla a exibição
  @Input() showMap: boolean = false;

  // id da coleta pra renderizar no mapa
  @Input() collectIdMap = null;

  // id da pesquisa
  @Input() surveyId: number = null;

  // propaga o evento para fechar o modal
  @Output() handleModal = new EventEmitter<any>();

  // Referência para o componente mapa
  @ViewChild(LeafletMapBetaComponent) leafletMap!: LeafletMapBetaComponent;

  // informações exibidas no modal do mapa
  collectionInfo = {
    id: 0,
    hora_inicio: "",
    hora_fim: "",
    data: "",
  };

  constructor(private auditoriaService: AuditoriaBetaService) {}

  ngOnInit(): void {
    this.auditoriaService
      .getCollectionSumary({
        surveyId: this.surveyId,
        collectionId: this.collectIdMap,
      })
      .subscribe({
        next: (data) => {
          this.handleMountMap(data);
          this.handleSetInfo(data);
        },
      });
  }

  // seta as informações
  handleSetInfo({
    id,
    hora_inicio,
    hora_fim,
    data,
  }: {
    id: number;
    hora_inicio: string;
    hora_fim: string;
    data: string;
  }) {
    this.collectionInfo.id = id;
    this.collectionInfo.hora_inicio = hora_inicio;
    this.collectionInfo.hora_fim = hora_fim;
    this.collectionInfo.data = data;
  }

  // monta o mapa em tela
  handleMountMap({ localizacao, ...rest }: ISurveyResumoColetaResponse) {
    // aplicando a localização no mapa
    this.leafletMap.drawPointMarker(
      localizacao.latitude,
      localizacao.longitude
    );
    this.leafletMap.fitBoundsByLinearCoords([
      { lat: localizacao.latitude, lon: localizacao.longitude },
    ]);
  }

  closeModal() {
    this.handleModal.emit(false);
  }
}
