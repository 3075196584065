<div class="modal-select-options-container">
  <div class="box-content">
    <div class="header">
      <div class="label">
        <i class="fa-regular fa-cloud-arrow-down"></i>
        <h4>Exportar dados</h4>
      </div>
      <button class="close-option" (click)="handleCloseThisModal()">
        <i class="fa-light fa-circle-xmark"></i>
      </button>
    </div>

    <div class="menu">
      <button *ngFor="let format of data; let i = index" (click)="handleChangeCheckedValue(i)"
        [ngClass]="format.disabled ? 'menu-item disabled' : (format.checked ? 'menu-item checked' : 'menu-item')"
        [disabled]="format.disabled">
        <app-checkbox [isChecked]="format.checked" [isDisabled]="format.disabled" [typeOfCheck]="'radioWithBorder'">
        </app-checkbox>
        <span>{{ format.nameDescription }}</span>
      </button>
    </div>

    <div class="footer">
      <button id="cancel-action" type="button" (click)="handleCloseThisModal()">
        Cancelar
      </button>
      <button id="export-action" type="button" (click)="handleThisExport()" [disabled]="!isValid()">
        Exportar
      </button>
    </div>
  </div>
</div>
