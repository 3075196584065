import { Component, Input, OnInit } from '@angular/core';
import { Localidade } from '../../localidade';

@Component({
  selector: 'app-localidade-specification',
  templateUrl: './localidade-specification.component.html',
  styleUrls: ['./localidade-specification.component.scss'],
})
export class LocalidadeSpecificationComponent implements OnInit {
  @Input() private localidade: Localidade;

  itens: { label: string; focus: boolean; }[];

  ngOnInit() {

    this.itens = this.localidade.parents.sort((p1, p2) => p2.nivel - p1.nivel)
      .map(parent => ({ label: parent.nome, focus: false }));

    this.itens.push({
      label: this.localidade.nome,
      focus: true,
    });

    console.log('[LocalidadeSpecificationComponent.ngOnInit] itens: ', this.itens);
  }
}
