import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { BehaviorSubject, Observable } from "rxjs";
import { IDocumentInfo } from "./lgpd-interface";

@Injectable({
  providedIn: "root",
})
export class LgpdService extends CrudService<any> {
  public baseUrl: string = this.resourcePath;

  constructor(
    protected requestService: RequestService,
  ) {
    super(requestService, `${apiLocation}`);
  }

  public getLgpdDocuments(): Observable<IDocumentInfo[]> {
    const url = `${this.baseUrl}/configurations/lgpd/documents`;
    const response = this.requestService.get(url)

    return response
  }

  public getAuthenticatedUserLgpdDocuments(): Observable<IDocumentInfo[]> {
    const url = `${this.baseUrl}/configurations/lgpd/documents/sign`;
    const response = this.requestService.get(url)

    return response;
  }

  public signDocuments(ids: number[]) {
    const url = `${this.baseUrl}/configurations/lgpd/documents/sign`;
    const responseObservable = this.requestService.post(url, ids);

    return responseObservable;
  }

  /**
   * Salva um documento lgpd
   * @param file: Arquivo
   * @param type: Tipos @LgpdTypes
   * @returns
   */
  public saveDocument(formData: FormData) {
    const url = `${this.baseUrl}/configurations/lgpd/documents`

    const response = this.requestService.post(url, formData);

    return response;
  }

}
