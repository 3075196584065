import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  EGroupType,
  IGroup,
} from "app/modulos/auditoria-beta/interfaces/audit-open-answer";
import { MatchOperationsService } from "app/modulos/auditoria-beta/services/consolidacao/match-operations.service";

enum Error {
  EMPTY_TITLE = "Informe o titulo da consolidação",
  OVERFLOW = "A resposta excede o tamanho permitido",
}

@Component({
  selector: "app-step-two",
  templateUrl: "./step-two.component.html",
  styleUrls: ["./step-two.component.scss"],
})
export class StepTwoComponent implements OnInit {
  @Input() groups: Array<IGroup> = [];
  @Output() matchAnswers: EventEmitter<any> = new EventEmitter<any>();

  groupTitle = "";
  chooseTilte = false;
  filteredGroup: IGroup[] = [];
  choosedGroup: IGroup = null;
  errorStatus = false;
  errorMessage = Error.EMPTY_TITLE;

  constructor(private matchOperationsService: MatchOperationsService) {}

  ngOnInit(): void {}

  // Grupos filtrados pelo titulo
  handleFilteredGroups() {
    this.filteredGroup = this.groups
      .filter(
        ({ title, typeGroup }) =>
          title.toLowerCase().includes(this.groupTitle.toLowerCase()) &&
          typeGroup === EGroupType.MANUAL
      )
      .splice(0, 10);
    return this.filteredGroup;
  }

  // Define o grupo para onde irão as respostas e/ou grupos selecionados
  setTitleOfGroup(group: IGroup) {
    this.groupTitle = group.title;
    this.choosedGroup = this.groupTitle.length ? group : null;
    this.updateSelectedGroup();
  }

  onChange($event) {
    const title = $event.target.value;
    this.errorStatus = false;
    this.chooseTilte = false;
    this.choosedGroup = null;
    this.groupTitle = title;
    if (title.length > 140) {
      this.errorMessage = Error.OVERFLOW;
    } else {
      this.errorMessage = Error.EMPTY_TITLE;
    }
  }

  /**
   * Realiza o bloqueio do botão se titulo for igual a um grupo existente.
   * Remove o bloqueio apos seleção.
   */
  blockBtnByTitle(): boolean {
    const group = this.groups.find(group => group.title === this.groupTitle);
    return this.filteredGroup.includes(group) && !this.choosedGroup;
  }

  // recupera o valor do grupo selecionado para notificar o step1
  updateSelectedGroup() {
    if(this.choosedGroup) {
      this.matchOperationsService.updateStepTwoSelectedGroup(this.choosedGroup);
    }
  }

  // Emite o valor do grupo para respostas abertas
  handleMatch() {
    if (this.groupTitle.length && this.groupTitle.length <= 140) {
      this.choosedGroup = {
        ...this.choosedGroup,
        typeGroup: EGroupType.MANUAL,
        title: this.groupTitle,
      };
      this.matchAnswers.emit(this.choosedGroup);
      this.choosedGroup = null;
      this.groupTitle = "";
      this.errorStatus = false;
    } else {
      this.errorStatus = true;
    }
  }
}
