import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ListReadService } from "../list-read.service";

@Component({
  selector: "app-localidade-table-pagination",
  templateUrl: "./localidade-table-pagination.component.html",
  styleUrls: ["./localidade-table-pagination.component.scss"],
})
export class LocalidadeTablePaginationComponent implements OnInit, OnChanges {
  @Input() totalOfLocalidades: number = 0;
  @Input() startOf: number = 1;
  @Input() oneOf: number = 10;

 canClickNextPage: boolean = true;
 canClickPreviousPage: boolean = false;

  constructor(private listReadService: ListReadService) {}

  ngOnInit() {}

  // Função para avançar na paginação
  handleNextPage(): void {
    this.listReadService.setNextPage();
    this.canClickNextPage = this.listReadService.getCanSetNextPage();
    this.canClickPreviousPage = this.listReadService.getCanSetPrevPage();
  }

  // Função para voltar a pagina anterior
  handlePreviousPage(): void {
    this.listReadService.setPrevPage();
    this.canClickNextPage = this.listReadService.getCanSetNextPage();
    this.canClickPreviousPage = this.listReadService.getCanSetPrevPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "startOf":
            this.canClickNextPage = this.canClickNextPage;
        }
      }
    }
  }
}
