<!-- <div [formGroup]="formGroup">
  <h1>Produtividade</h1>
  <select formControlName="idPesquisa" (change)="onPesquisaChanged()">
    <option [ngValue]="null" disabled>Selecione</option>
    <option *ngFor="let pesquisa of pesquisas" [ngValue]="pesquisa.id">{{pesquisa.nome}}</option>
  </select>

  <div class="col-md-2">
    <div class="campo">
      <label for="">Início do vínculo</label>
      <input formControlName="dataInicial" type="date" (change)="onDataChange()">
      <span class="error-message" [hidden]="!(controls.dataInicial.errors)">
        Deve ser informada uma data válida a partir do dia de hoje e menor que o Período Final
      </span>
    </div>
  </div>
  <div class="col-md-2">
    <div class="campo">
      <label for="">Fim do vínculo</label>
      <input formControlName="dataFinal" type="date" (change)="onDataChange()">
      <span class="error-message" [hidden]="!(controls.dataFinal.errors)">
        Deve ser informada uma data válida a partir do dia de hoje e maior que o Período Inicial
      </span>
    </div>
  </div>

  <select formControlName="idOperador" (change)="onOperadorChanged()">
    <option [ngValue]="null" disabled>Selecione</option>
    <option *ngFor="let operador of operadores" [ngValue]="operador.id">{{operador.nome}}</option>
  </select> -->

<!-- CONTEUDO DA PAGINA -->
<!-- <div *ngIf="produtividade"> -->
<!-- AMOSTRA GERAL -->
<!-- <div class="temp-space">
      <span>Amostra Geral</span>
      <span>{{produtividade.dadosPesquisa.totalAmostras}}</span>
    </div> -->
<!-- ENTREVISTAS REALIZADAS -->
<!-- <div class="temp-space">
      <span>Entrevistas realizadas</span>
      <span>{{produtividade.estatisticasEntrevista.entrevistasRealizadas}}/{{produtividade.dadosPesquisa.totalAmostras}}</span>
    </div> -->
<!-- ENTREVISTAS AUDITADAS -->
<!-- <div class="temp-space">
      <span>Entrevistas auditadas</span>
      <span>{{produtividade.estatisticasEntrevista.entrevistasAuditadas}}/{{produtividade.estatisticasEntrevista.entrevistasRealizadas}}</span>
    </div> -->
<!-- ENTREVISTAS REJEITADAS -->
<!-- <div class="temp-space">
      <span>Entrevistas rejeitasdas</span>
      <span>{{produtividade.estatisticasEntrevista.entrevistasRejeitadas}}/{{produtividade.estatisticasEntrevista.entrevistasRealizadas}}</span>
    </div> -->
<!-- ENTREVISTAS APROVADAS -->
<!-- <div class="temp-space">
      <span>Entrevistas aprovadas</span>
      <span>{{produtividade.estatisticasEntrevista.entrevistasAprovadas}}/{{produtividade.estatisticasEntrevista.entrevistasRealizadas}}</span>
    </div>
    <h1>olça</h1> -->
<!-- GRAFICO PRODUCAO DIARIA -->
<!-- <div style="width:400px;">
      <app-chart [chartConfigurationSubject]="subjectGraficoProducaoDiaria"></app-chart>
    </div> -->
<!-- GRÁFICO ESPAÇAMENTOS -->
<!-- <div style="width:400px; display: flex; flex-direction: row" >
      <div>
        <ul>
          <li>Em conformidade</li>
        </ul> -->
<!-- VALOR BRUTO -->
<!-- {{produtividade.estatisticasEntrevista.conformidade}}
        <br> -->
<!-- PERCENTUAL -->
<!-- {{calcularPorcentagem(produtividade.estatisticasEntrevista.conformidade,
        produtividade.estatisticasEntrevista.entrevistasRealizadas)}}
      </div>
      <app-chart style="width:200px;" [chartConfigurationSubject]="subjectGraficoEspacamento"></app-chart>
      <div>
        <ul>
          <li>Em desconformidade</li>
        </ul> -->
<!-- VALOR BRUTO -->
<!-- {{produtividade.estatisticasEntrevista.desconformidade}}
        <br> -->
<!-- PERCENTUAL -->
<!-- {{calcularPorcentagem(produtividade.estatisticasEntrevista.desconformidade,
        produtividade.estatisticasEntrevista.entrevistasRealizadas)}}
      </div>
    </div> -->

<!-- GRÁFICO TEMPO MÍNIMO -->
<!-- <div style="width:300px;">
      <app-chart [chartConfigurationSubject]="subjectGraficoTempoMinimo"></app-chart> -->
<!-- VALORES BRUTOS -->
<!-- <div>
        <h2>{{produtividade.estatisticasEntrevista.abaixoTempoMinimo}}</h2>
        entrevista(s)
        <br>
        ABAIXO DO TEMPO MÍNIMO
      </div>
    </div> -->

<!-- MAPA CERCA ELETRÔNICA -->
<!-- <div class="temp-space">
      <span>Cerca Eletrônica</span>
      <app-cerca-eletronica [novaProdutividadeSubject]="produtividadeSubject">
      </app-cerca-eletronica>
    </div> -->

<!-- REAL TIME -->
<!-- <app-tempo-real [novaProdutividadeFiltroSubject]="novaProdutividadeFiltroSubject"></app-tempo-real>

  </div>

</div>

<app-loading *ngIf="isLoading"></app-loading> -->

<div class="container-page">
  <div class="headerControle">
    <div class="headerTitle">
      <span class="icon-produtividade"></span>
      <h2>Produtividade</h2>
    </div>
    <div class="headerFilter sg-input-group" [formGroup]="formGroup">
      <div class="campo">
        <app-filterable-select-old
          [closeOnSelect]="true"
          (changeSelectedItem)="handleSelectedPesquisa($event)"
          [filterPlaceholder]="'Digite o nome de uma pesquisa'"
          [data]="pesquisas"
        >
        </app-filterable-select-old>
      </div>
      <div class="campo">
        <input
          formControlName="dataInicial"
          type="date"
          (change)="onDataChange()"
        />
        <span class="error-message" [hidden]="!controls.dataInicial.errors">
          Deve ser informada uma data válida a partir do dia de hoje e menor que
          o Período Final
        </span>
      </div>
      <div class="campo">
        <input
          formControlName="dataFinal"
          type="date"
          (change)="onDataChange()"
        />
        <span class="error-message" [hidden]="!controls.dataFinal.errors">
          Deve ser informada uma data válida a partir do dia de hoje e maior que
          o Período Inicial
        </span>
      </div>
      <div class="campo">
        <select formControlName="idOperador" (change)="onOperadorChanged()">
          <option [ngValue]="null">Selecionar Entrevistador</option>
          <option *ngFor="let operador of operadores" [ngValue]="operador.id">
            {{ operador.nome }}
          </option>
        </select>
      </div>
      <div class="">
        <span *ngIf="produtividade"
          >Amostra geral:
          <strong>{{ produtividade.dadosPesquisa.totalAmostras }}</strong></span
        >
      </div>
    </div>
  </div>
  <div *ngIf="produtividade" class="">
    <div [formGroup]="formGroup">
      <div *ngIf="produtividade" class="containerCards">
        <!-- ENTREVISTAS REALIZADAS -->
        <div class="card">
          <span class="cardTitle">Entrevistas realizadas</span>
          <span class="cardValue"
            ><span>{{
              produtividade.estatisticasEntrevista.entrevistasRealizadas
            }}</span
            >/{{ produtividade.dadosPesquisa.totalAmostras }}</span
          >
        </div>
        <!-- ENTREVISTAS AUDITADAS -->
        <div class="card">
          <span class="cardTitle">Entrevistas auditadas</span>
          <span class="cardValue"
            ><span>{{
              produtividade.estatisticasEntrevista.entrevistasAuditadas
            }}</span
            >/{{
              produtividade.estatisticasEntrevista.entrevistasRealizadas
            }}</span
          >
        </div>
        <!-- ENTREVISTAS REJEITADAS -->
        <div class="card">
          <span class="cardTitle">Entrevistas rejeitadas</span>
          <span class="cardValue"
            ><span>{{
              produtividade.estatisticasEntrevista.entrevistasRejeitadas
            }}</span
            >/{{
              produtividade.estatisticasEntrevista.entrevistasRealizadas
            }}</span
          >
        </div>
        <!-- ENTREVISTAS APROVADAS -->
        <div class="card">
          <span class="cardTitle">Entrevistas aprovadas</span>
          <span class="cardValue"
            ><span>{{
              produtividade.estatisticasEntrevista.entrevistasAprovadas
            }}</span
            >/{{
              produtividade.estatisticasEntrevista.entrevistasRealizadas
            }}</span
          >
        </div>
      </div>

      <div *ngIf="produtividade" class="containerCharts">
        <!--

          COMENTANDO FUNCIONALIADE:
            * PRODUÇÃO DIÁRIA

          Pois não serão contempladas na release básica
          do tensai prevista para 15/12.

        <div class="chart" [class.chartExpanded]="isExpand && id == 'menu-diaria'">
          <div class="chartHeader">
            <h3 class="chartTitle">Produção diária</h3>
            <div class="chartMenu menuDiaria">
              <span class="legendaPrevistas">PREVISTAS</span>
              <span class="legendaRealizadas">REALIZADAS</span>
              <img id="menu-diaria" (click)="handleExpand($event)" class="expandChart"
                src="assets/images/expand.svg" />
            </div>
          </div>
          <div class="chartContent">
            <app-chart id="chartDiaria" [chartConfigurationSubject]="subjectGraficoProducaoDiaria"></app-chart>
          </div>
        </div>
        -->

        <!-- ESTADO DE ACEITAÇÃO -->
        <div
          class="chart"
          [class.chartExpanded]="isExpand && id == 'aceitacao'"
        >
          <div class="chartHeader">
            <h3 class="chartTitle">Estado de aceitação</h3>
            <div class="chartMenu">
              <a
                (click)="
                  visualizarEntrevistasEmDesconformidadeMenosTempoMinimo()
                "
                title="Visualizar entrevistas em desconformidade"
                class="link"
              >
                VER ENTREVISTAS
              </a>
              <img
                id="aceitacao"
                (click)="handleExpand($event)"
                class="expandChart"
                src="assets/images/expand.svg"
              />
            </div>
          </div>
          <div class="chartContent conformidade">
            <div class="conformidade">
              <span class="label">Em conformidade</span>
              <!-- VALOR BRUTO -->
              <span class="valorBruto">{{
                produtividade.estatisticasEntrevista.conformidade
              }}</span>
              <!-- PERCENTUAL -->
              <span class="percentual">{{
                calcularPorcentagem(
                  produtividade.estatisticasEntrevista.conformidade,
                  produtividade.estatisticasEntrevista.entrevistasRealizadas
                )
              }}</span>
            </div>
            <app-chart
              [chartConfigurationSubject]="subjectGraficoEspacamento"
            ></app-chart>
            <div class="desconformidade">
              <span class="label">Em desconformidade</span>
              <!-- VALOR BRUTO -->
              <span class="valorBruto">{{
                produtividade.estatisticasEntrevista.desconformidade
              }}</span>
              <!-- PERCENTUAL -->
              <span class="percentual">{{
                calcularPorcentagem(
                  produtividade.estatisticasEntrevista.desconformidade,
                  produtividade.estatisticasEntrevista.entrevistasRealizadas
                )
              }}</span>
            </div>
          </div>
        </div>

        <!-- GRÁFICO TEMPO MÍNIMO -->
        <div
          class="chart"
          [class.chartExpanded]="isExpand && id == 'tempo-minimo'"
        >
          <div class="chartHeader">
            <h3 class="chartTitle">Tempo mínimo de entrevista</h3>
            <div class="chartMenu">
              <a
                (click)="
                  visualizarEntrevistasEmDesconformidadeAbaixoDoTempoMinimo()
                "
                title="Visualizar entrevistas em desconformidade abaixo do tempo mínimo estabelecido"
                class="link"
              >
                VER ENTREVISTAS
              </a>
              <img
                id="tempo-minimo"
                (click)="handleExpand($event)"
                class="expandChart"
                src="assets/images/expand.svg"
              />
            </div>
          </div>
          <div class="chartContent tempo">
            <app-chart
              [chartConfigurationSubject]="subjectGraficoTempoMinimo"
            ></app-chart>
            <!-- VALORES BRUTOS -->
            <div class="valorBruto">
              <h2>
                {{ produtividade.estatisticasEntrevista.abaixoTempoMinimo }}
              </h2>
              <span>entrevistas</span>
              <div>
                <span class="fa fa-long-arrow-down"></span> ABAIXO DO TEMPO
                MÍNIMO
              </div>
            </div>
          </div>
        </div>

        <!--

          COMENTANDO FUNCIONALIDADES:
          * CERCA ELETRÔNICA
          * REALTIME

          Pois não serão contempladas na release básica
          do tensai prevista para 15/12.

        -->

        <!-- MAPA CERCA ELETRÔNICA -->
        <!-- <div class="chart" [class.chartExpanded]="isExpand && id == 'cerca-eletronica'">
          <div class="chartHeader">
            <h3 class="chartTitle">Cerca Eletrônica</h3>
            <div class="chartMenu">
              <a (click)="visualizarEntrevistasEmDesconformidadeForaCerca()"
                title="Visualizar entrevistas em desconformidade que foram realizadas fora da cerca eletrônica estabelecida"
                class="link">
                VER ENTREVISTAS
              </a>
              <img id="cerca-eletronica" (click)="handleExpand($event)" class="expandChart"
                src="assets/images/expand.svg" />
            </div>
          </div>
          <div class="chartContent">
            <app-cerca-eletronica [novaProdutividadeSubject]="produtividadeSubject"
              [novaProdutividadeFiltroSubject]="novaProdutividadeFiltroSubject">
            </app-cerca-eletronica>
          </div>
        </div> -->

        <!-- MAPA REALTIME -->
        <!-- <div class="chart" [class.chartExpanded]="isExpand && id == 'tempo-real'">
          <div class="chartHeader">
            <h3 class="chartTitle">Tempo Real</h3>
            <div class="chartMenu">
                <img id="tempo-real" (click)="handleExpand($event)" class="expandChart"
                src="assets/images/expand.svg" />
            </div>
          </div>
          <div class="chartContent">
            <app-tempo-real [novaProdutividadeFiltroSubject]="novaProdutividadeFiltroSubject"></app-tempo-real>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- <div class="chart">
          <div class="chartHeader">
            <h3 class="chartTitle">Cerca eletrônica</h3>
            <div class="chartMenu">
              <a>VER ENTREVISTAS</a>
            </div>
          </div>
          <div class="chartContent">
            <app-cerca-eletronica [novaProdutividadeSubject]="produtividadeSubject"></app-cerca-eletronica>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 -->
<!-- <app-loading *ngIf="isLoading"></app-loading> -->
