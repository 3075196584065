<div [formGroup]="alternativasSelecionadasGrade">
  <div class="containerGradeMultipla sg-input-group" *ngFor="let tituloGrade of pergunta.titulosGrades">
    <div class="campo-inline">
      <label>{{ tituloGrade.descricao }}</label>
      <select formControlName="{{ tituloGrade.id }}" multiple>
        <option *ngFor="let alternativa of pergunta.alternativas" [value]="alternativa.id">
          {{ alternativa.descricao }}
        </option>
      </select>
    </div>

    <span class="error-message" *ngIf="hasErrors(tituloGrade.id)">
      Você deve selecionar entre {{ pergunta.qtdMinimaSelecionada }} e {{ pergunta.qtdMaximaSelecionada }} alternativas.
    </span>
  </div>
</div>

  
<!-- <div [formGroup]="alternativasSelecionadasGrade">
    <div *ngFor="let tituloGrade of pergunta.titulosGrades">

      <div class="row">

        <div class="col-md-2">
          {{ tituloGrade.descricao }}
        </div>

        <div class="col-md-2">
          
          <mat-form-field>
            
            <mat-select
              formControlName="{{tituloGrade.id}}"
              multiple>
              <mat-option 
                *ngFor="let alternativa of pergunta.alternativas" 
                [value]="alternativa.id" >
                {{ alternativa.descricao }}
              </mat-option>
            </mat-select>
          
          </mat-form-field>

        </div>
      </div>

    </div>
  
  </div> -->
