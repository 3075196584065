
/**
 * Takes a value in seconds, gets the minutes of that value, and the residual seconds
 *
 * @param: total seconds
 * @returns Returns minutes and seconds formatted "00:00"
 */
export const getAndFormatTime = (value: number): string => {

  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  // Formata os minutos e segundos para terem sempre 2 dígitos (por exemplo, 09 em vez de 9)
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}
