import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TipoAlternativa } from 'app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa';

/**
 * Validador que verifica se uma determinada resposta pergunta
 * está com o campo respostaAberta vazia caso a alternativa relacionada
 * seja do tipo ABERTA_TEXTO ou ABERTA_NUMERO.
 */
export default (toCompareControl: AbstractControl): ValidationErrors => {

  if ((estaSelecionada(toCompareControl.value)
    && alternativaEhAberta(toCompareControl.value))
    && respostaAbertaEstaVazia(toCompareControl.value)) {

    return {
      respostaAbertaVazia: true,
    };
  }
};

/**
 * verifica se a respostaPergunta está selecionada ou não.
 * @param resposta
 */
function estaSelecionada(resposta: any): boolean {
  return resposta.selecionada;
}

/**
 * Verifica se a alternativa referenciada
 * na respost trata-se de uma alternativa do tipo ABERTA_TEXTO
 * ou ABERTA_NUMERO.
 * @param resposta
 */
function alternativaEhAberta(resposta: any): boolean {

// tslint:disable-next-line: max-line-length

  const tipoAlternativa = resposta.esquema.tipoAlternativa;

  return tipoAlternativa === TipoAlternativa.ABERTA_TEXTO
    || tipoAlternativa === TipoAlternativa.ABERTA_NUMERO;
}

/**
 * Verifica se o atributo "respostaAberta" da resposta
 * encontra-se undefined ou vazio.
 * @param resposta
 */
function respostaAbertaEstaVazia(resposta: any): boolean {
  const respostaAberta = resposta.respostaAberta;

  return respostaAberta === undefined || respostaAberta === '';
}
