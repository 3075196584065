<section class="main-container">
  <h3> {{model.ordem}} - {{ model.enunciado }}</h3>
  <app-questionnaire-badge [model]="badgeModel"></app-questionnaire-badge>

  <div class="question-wrapper">
    <div
      [ngClass]="{
        expanded: videoState.expanded
      }"
      class="video-container"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
    >
      <!-- <div class="overlay"></div>-->
      <video
        (timeupdate)="updateTimeElapsed($event)"
        (loadedmetadata)="initializeVideo()"
        (ended)="onEnd()"
        [playbackRate]="videoState.currentSpeed"
        [volume]="videoState.volume"
        #videoRef
      >
        <source [src]="videoSource" type="video/mp4" />

        <source [src]="videoSource" type="video/ogg" />

        <source [src]="videoSource" type="video/webm" />

        Your browser does not support the video tag.
      </video>
      <div class="video-controls" [style.opacity]="showControls ? 1 : 0">
        <div class="buttons">
          <i
            (click)="togglePlay()"
            *ngIf="videoState.paused; else unpaused"
            class="fa-solid fa-play"
          ></i>
          <ng-template #unpaused>
            <i class="fa-solid fa-pause" (click)="togglePlay()"></i>
          </ng-template>

          <span class="time-labels"
            >{{ convertToFullTime(videoState.currentProgress) }} /
            {{ convertToFullTime(videoState.totalTime) }}</span
          >

          <div class="video-options">
            <!-- audio control -->
            <div
              class="volume-control-container"
              (mouseenter)="toggleVolumeState()"
              (mouseleave)="toggleVolumeState()"
            >
              <i
                *ngIf="videoState.volume !== 0; else muted"
                class="fa-sharp fa-regular fa-volume"
              ></i>
              <ng-template #muted>
                <i class="fa-regular fa-volume-xmark"></i>
              </ng-template>

              <div class="volume-progress" *ngIf="videoState.showVolume">
                <input
                  [style.--volume-value]="videoState.volume"
                  class="volume-seek"
                  [(ngModel)]="videoState.volume"
                  max="1"
                  min="0"
                  type="range"
                  step="0.1"
                />
              </div>
            </div>

            <!-- speed control -->
            <div class="speed-buttons" (mouseleave)="toggleSpeedOptions()">
              <button
                [ngClass]="videoState.showSpeedOptions && 'speed-btn-none'"
                (click)="handleAudioSpeed(videoState.currentSpeed)"
                (mouseenter)="toggleSpeedOptions()"
              >
                <span class="selected-btn">{{ videoState.currentSpeed }}x</span>
              </button>
              <div class="options" *ngIf="videoState.showSpeedOptions">
                <button
                  *ngFor="let speed of speedOptions"
                  (click)="handleAudioSpeed(speed)"
                >
                  <span
                    [ngClass]="
                      speed === videoState.currentSpeed ? 'selected-btn' : ''
                    "
                    >{{ speed }}x</span
                  >
                </button>
              </div>
            </div>

            <a [href]="videoSource" download class="download-action">
              <i class="fa-light fa-arrow-down-to-line"></i>
            </a>

            <i
              class="fa-regular fa-expand"
              (click)="toggleExpand()"
              *ngIf="!videoState.expanded; else compress"
            ></i>
            <ng-template #compress>
              <i class="fa-regular fa-compress" (click)="toggleExpand()"></i>
            </ng-template>
          </div>
        </div>

        <div class="progress-bar">
          <input
            class="seek"
            value="0"
            min="0"
            type="range"
            step="1"
            (input)="skipAhead($event)"
            [value]="videoState.currentProgress"
            [max]="videoState.totalTime"
          />
          
          <progress
            id="progress-bar"
            [value]="videoState.currentProgress"
            min="0"
            [max]="videoState.totalTime"
          ></progress>
        </div>
      </div>
    </div>
  </div>
</section>
