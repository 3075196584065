import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { TipoInput } from '../inputs/tipoInput';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { v4 as uuid } from 'uuid';
import { Marcacao } from './marcacao';

export class Alternativa {

  id: number;
  descricao: string;
  ordem: number;
  tipoAlternativa: string;
  hash: string = uuid();
  hashPergunta: string;
  hashSecao: string;
  marcacao: Marcacao;

  static getControl(tipoAlternativa: string = null): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      // marcacao: null,
      id: new UntypedFormControl(),
      // tslint:disable-next-line:max-line-length
      descricao: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_ALTERNATIVA_DESCRICAO)),
      ordem: new UntypedFormControl(''),
      hash: new UntypedFormControl(uuid()),
      hashPergunta: new UntypedFormControl(),
      hashSecao: new UntypedFormControl(),
      // tslint:disable-next-line:max-line-length
      tipoAlternativa: new UntypedFormControl(tipoAlternativa, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_RESPOSTA_TIPO)),
    });

    return formGroup;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(alternativa: Alternativa): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(alternativa.id),
      // tslint:disable-next-line:max-line-length
      descricao: new UntypedFormControl(alternativa.descricao, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_ALTERNATIVA_DESCRICAO)),
      ordem: new UntypedFormControl(alternativa.ordem),
      hash: new UntypedFormControl(alternativa.hash),
      hashPergunta: new UntypedFormControl(alternativa.hashPergunta),
      hashSecao: new UntypedFormControl(alternativa.hashSecao),
      // tslint:disable-next-line:max-line-length
      tipoAlternativa: new UntypedFormControl(alternativa.tipoAlternativa, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_RESPOSTA_TIPO)),
    });

    if (alternativa.marcacao) {
      formGroup.addControl('marcacao', Marcacao.getInitializedControl(alternativa.marcacao));
    }

    return formGroup;

  }

}
