<div class="thead-container" [style.height]="height">
  <div
    [ngStyle]="getDynamicStyles(col.customStyles, col.alignTypes, widths[i])"
    class="thead-col"
    *ngFor="let col of data; let i = index"
  >
    <ng-container *ngIf="col.type === 'text'">
      {{ col.label }}
    </ng-container>

    <ng-container *ngIf="col.type === 'checkbox'">
      <div class="checkbox-type">
        <app-checkbox
          [isChecked]="col.checked"
          [isDisabled]="!col.checkable"
          (click)="handleCheck(col)"
        ></app-checkbox>
      </div>
    </ng-container>
  </div>
</div>
