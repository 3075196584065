<section>
  <header>
    <h3>Passo 1</h3>
    <p>Selecione as citações que deseja consolidar</p>
  </header>
  <hr />
  <article>
    <app-input-search-auditoria
      (handleChange)="filterByText($event)"
    ></app-input-search-auditoria>
    <div class="answer-list">
      <div class="header-list">
        <label>Todas as citações</label>
        <label *ngIf="!!totalSelected"
          >{{ totalSelected }} selecionada{{ totalSelected > 1 ? "s" : "" }}
        </label>
      </div>
      <div id="listing">
        <ul class="group">
          <li *ngFor="let group of groupFilteredList">
            <app-selectable-dropdown
              [group]="group"
              [selected]="hasSelected(group, 'GROUP')"
              [isNewGroup]="newGroup"
              (selectGroup)="selectGroup($event)"
            ></app-selectable-dropdown>
          </li>
        </ul>
        <ul class="avulses">
          <li *ngFor="let avulse of avulseFilteredList">
            <div *ngIf="avulseToEdit !== avulse.id" class="visualization-vulse">
              <div class="item">
                <div
                  class="checkbox"
                  (click)="selectAvulses(avulse)"
                  [ngClass]="{
                    'selected-item': hasSelected(avulse)
                  }"
                >
                  <i class="fa-solid fa-check"></i>
                </div>
                <div class="title">
                  <label>{{ avulse.title }}</label>
                  <i
                    (click)="handleOpenEditTitleAvulse(avulse.id)"
                    class="fa-regular fa-pen"
                  ></i>
                </div>
              </div>
              <div class="actions">
                <p>1</p>
              </div>
            </div>
            <div *ngIf="avulseToEdit === avulse.id" class="edit-avulse">
              <input [(ngModel)]="newAvulseTitle" type="text" />
              <button
                (click)="handleCancelEditTitleAvulse()"
                [attr.data-tooltip]="'Cancelar'"
              >
                <i class="fa-regular fa-xmark"></i>
              </button>
              <button
                (click)="handleSaveEditTitleAvulse()"
                [attr.data-tooltip]="'Confirmar'"
              >
                <i class="fa-regular fa-check"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </article>
</section>
