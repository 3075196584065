import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-kebab-para-pesquisa',
  templateUrl: './kebab-para-pesquisa.component.html',
  styleUrls: ['./kebab-para-pesquisa.component.scss']
})
export class KebabParaPesquisaComponent {
  @Input() estadoArquivada: boolean = false;
  @Input() estadoRascunho: boolean = false;
  @Input() estadoExecucao: boolean = false;
  @Input() estadoConcluida: boolean = false;
  @Input() possuiAmostrasOnline: boolean = false;
  @Input() possuiLocalidadeVinculo: boolean = false;
  // events
  @Output() onMethodClick = new EventEmitter();

  // Estado para armazenar informação se o menu lateral direito está sendo exibido ou não
  isMenuVisible: boolean = false;
  // Estado para identificar se se o kebab terá bottom igual a zero ou top igual a zero
  isBottomMenuKebab: boolean;

  constructor(private elem: ElementRef) { }
  handleCheckIfComponentIsInViewport() {
    const elementYValue = this.elem.nativeElement.getBoundingClientRect();
    //Valor estático da altura do card do menu kebab
    const kebabHeight = 407

    /*
    * A posição do card de menu kebab é definido pegando a posição do icone onde ele foi acionado somado
    * a altura do card; caso o valor seja maior que a altura da pagina o card deve ser exibido de baixo para cima
    * se não ele é exibido de cima para baixo.
    */
    this.isBottomMenuKebab = (elementYValue.y+kebabHeight > document.documentElement.clientHeight);
  }

  // Função que lida com a manipulação do kebab
  handleShowKebab() {
    this.isMenuVisible = !this.isMenuVisible;
    if (this.isMenuVisible) {
      this.handleCheckIfComponentIsInViewport();
    }
  }

  handleMethod(method: string) {
    this.onMethodClick.emit(method);
    this.isMenuVisible = false;
  }

  // verificar se o clique foi dentro do componente ou fora.
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    if (this.elem.nativeElement.contains(event.target)) {
      this.handleShowKebab();
    } else {
      if (!this.isMenuVisible) {
        return;
      } else {
        this.handleShowKebab();
      }
    }
  }
}
