import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formsLocation } from "app/infraestrutura/apiLocation";
import { PesquisaService } from "app/modulos/pesquisa-beta/listagem/servico/pesquisas";
import { NotificatorService } from 'app/notificador/notificator.service';
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { SelectDataItem } from "../../../../../componentes/filterable-select/filterable-select-component";
import { LocationListOutput } from "../../../../../componentes/locations-list/locationslist.component";
import { ICanNotSaveButton, PesquisaRequest } from "../../pesquisas-cadastro.model";
import { PageLocationOutput } from "../pesquisas-localidades/pesquisas-localidades.component";
import { PesquisaVinculosService } from "./servico/pesquisa-vinculos.service";

export interface PVinculoOut {
  locationOtp: LocationListOutput;
}

@Component({
  selector: "app-pesquisas-vinculos",
  templateUrl: "./pesquisas-vinculos.component.html",
  styleUrls: ["./pesquisas-vinculos.component.scss"],
})
export class PesquisasVinculosComponent implements OnInit {
  @Input() localidade: PageLocationOutput;
  @Output() onSave: EventEmitter<PVinculoOut> = new EventEmitter();
  @Input() survey: PesquisaRequest;
  @Output() isReadyToSave: EventEmitter<boolean> = new EventEmitter();
  // saída dos eventos
  @Output() onChange: EventEmitter<void> = new EventEmitter();
  @Output() onDone: EventEmitter<void> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter()

  // função que emite para o pai os dados a serem salvos
  @Output() onNewSave: EventEmitter<any> = new EventEmitter()

  // references
  changeListTimeout = 0;

  internal: PVinculoOut;
  operators: SelectDataItem[] = [];
  isComplete: boolean = false;
  tokenRef: string = "";

  constructor(
    private operatorService: PesquisaVinculosService,
    public pesquisaService: PesquisaService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit() {
    if (this.localidade) {
      this.tokenRef = this.survey.configuracaoPesquisa.tokenVisualizacao;
      this.operatorService
        .listarTodos()
        .toPromise()
        .then((result) => {
          result.map((item) => {
            this.operators.push({
              label: item.nome,
              value: item.id,
            });
          });
        });
    }
  }

  handleSaveClick() {
    if (this.isComplete) {
      this.onSave.emit(this.internal);
    }
  }

  handleLocationListOutput(event: LocationListOutput) {
    this.internal = { locationOtp: event };

    clearTimeout(this.changeListTimeout);

    this.changeListTimeout = window.setTimeout(() => {
      this.isComplete = this.verifyData();
      if (!this.isComplete) {
        this.onChange.emit();
      } else {
        this.onDone.emit();
        // propaga os dados que devem ser salvos pelo botão avançar
        this.onNewSave.emit(this.internal)
      }

      // propaga se pode habilitar o botão de avançar ou não
      this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: !this.isComplete } });
    }, 100);
  }

  verifyData(): boolean {
    const locations = this.internal ? this.internal.locationOtp.alteredLocations.locations.filter((loc) => loc.value > 0 ) : [];

    if (locations.length === 0) {
      return false;
    }

    /**
     * TODO: Algoritmo para validar se a vinculação de operadores foi feita com
     * sucesso
     * 1. Preciso percorrer todas as localidades
     * 2. Tratamento condicional para localidades filhas & localidades filhas
     * que possuam netas:
     * 2.1 Se tiver localidades netas
     *     Preciso garantir que o somatório dos operadores da localidade neta
     *     seja igual ao de amostras na localidade neta
     *     E que o somatório das localidades netas seja igual ao de amostras na
     *     localidade filha
     * 2.2 Se não tiver localidades netas
     *     Preciso garantir que o somatório das localidades filhas sejam igual
     *     ao de amostras presenciais
     */
    /**
     * percorre todos os operadores, realizando a soma distribuída para cada operador, seja igual a amostra total
     */
    const count = locations.reduce((acc, location) => {
      location.operators.forEach(item => acc += item.value)

      return acc
    }, 0)

    // verifica contagem
    if (count !== this.internal.locationOtp.alteredLocations.value) {
      return false;
    }

    return true;
  }

  // função que irá abrir uma nova aba para mostrar o questionário
  handleShowQuizPreview() {
    let linkUrl = `${formsLocation}/${this.tokenRef}`;

    this.pesquisaService
      .getFriendlyToken(this.tokenRef)
      .toPromise()
      .then((token) => {
        linkUrl = `${formsLocation}/${token.token_amigavel}`
        window.open(linkUrl, '_blank');
      })
      .catch((err) => this.errorHandlerService.handleError(err, 'Erro ao copiar link'));
  }
}
