import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  IAnswaresList,
  IQuestion,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { AnswerOutput } from "../questions.component";
import { PerguntaQuestionarioAuditadoManager } from "../gerenciador";
import { AlternativaAuditada } from "../gerenciador/entidade/alternativa";

@Component({
  selector: "app-numeric-scale-question",
  templateUrl: "./numeric-scale-question.component.html",
  styleUrls: ["./numeric-scale-question.component.scss"],
})
export class NumericScaleQuestionComponent implements OnInit {
  gerenciador: PerguntaQuestionarioAuditadoManager;

  @Input() questionData: IQuestion = null;
  @Input() actionsBlockedBySituation: boolean = false;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.gerenciador = new PerguntaQuestionarioAuditadoManager(
      this.questionData
    );

    this.notificarAtualizacaoResposta();
  }

  private notificarAtualizacaoResposta() {
    this.onChange.emit({
      type: this.gerenciador.utils.getTipoPergunta(),
      questionId: this.gerenciador.utils.getIdPergunta(),
      answer: this.buildRespostaPayload(),
    });
  }

  /**
   * Controls user selection
   * @param index: clicked question index
   */
  handleCheck(alternativa: AlternativaAuditada): void {
    const operacaoBemSucedida = this.gerenciador.atualizarSelecaoAlternativa(alternativa);

    if (operacaoBemSucedida) {
      this.notificarAtualizacaoResposta();
    }
  }

  buildRespostaPayload() {
    const [resposta] = this.gerenciador.getRespostasSelecionadas();

    if (resposta) {
      return {
        id_alternativa: resposta.getIdRespostaOriginal(),
        id_alternativa_auditada: resposta.getIdAlternativa(),
        ordem: resposta.getOrdemSelecao(),
        respondida:
          this.gerenciador.getPossuiAlteracaoDoAuditor() ||
          !this.gerenciador.getPerguntaIsResetada(),
      } as IAnswaresList;
    }

    return null;
  }

  handleReset() {
    this.gerenciador.resetarParaRespostasOriginais();
    this.notificarAtualizacaoResposta();
  }

}
