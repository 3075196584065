<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card
    [icone]="'fa fa-list'"
    [titulo]="ordem + ' - MÚLTIPLA ESCOLHA'"
    [nomePlaceHolder]="'Título da pergunta de múltipla escolha?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>

<!-- <div [formGroup]="formGroup" class="selecao-multipla">
  
  <div *ngFor="let alternativa of controls.alternativas.controls">
    <app-alternativa-tipada
      (inputUpdate)="handleNestedInputEvent()($event)"
      [parentEventPublisher]="nestedInputPublisher"
      [hashPergunta]="hashPergunta"
      [hashSecao]="hashSecao"
      [hash]="alternativa.value.hash"
    ></app-alternativa-tipada>
  </div>

  <label>
    Mínimo selecionado*
    <input type="text"
      mascara-numero
      formControlName="qtdMinima" 
      (blur)="onQtdMinimaChange()"
      maxlength="1"/>
    <span class="error-message" [hidden]="!(showErrors && controls.qtdMinima.errors && controls.qtdMinima.errors.maiorQueDeveria)">
      A quantidade minima deve ser menor que a quantidade máxima
    </span>
    <span class="error-message" [hidden]="!(showErrors && controls.qtdMinima.errors && controls.qtdMinima.errors.valorMaximoPermitido)">
      A quantidade mínima deve ser menor ou igual ao total de alternativas adicionadas
    </span>
  </label>
  <label>
    Máximo selecionado
    <input type="text" 
      mascara-numero
      formControlName="qtdMaxima" 
      (blur)="onQtdMaximaChange()"
      maxlength="1"/>
    <span class="error-message" [hidden]="!(showErrors && controls.qtdMaxima.errors && controls.qtdMaxima.errors.menorQueDeveria)">
      A quantidade máxima deve ser maior que a quantidade mínima
    </span>
    <span class="error-message" [hidden]="!(showErrors && controls.qtdMaxima.errors && controls.qtdMaxima.errors.valorMaximoPermitido)">
      A quantidade máxima deve ser menor ou igual ao total de alternativas adicionadas
    </span>
  </label>

  <button (click)="addAlternativa()">Adicionar Alternativa</button>
</div> -->
