import { Component } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { AbstractPerguntaConteudoMarcacao } from '../../../abstractPerguntaConteudoMarcacao';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';

@Component({
  selector: 'app-selecao-multipla-conteudo-marcacao',
  templateUrl: './selecao-multipla-conteudo-marcacao.component.html',
  styleUrls: ['./selecao-multipla-conteudo-marcacao.component.scss'],
})
export class SelecaoMultiplaConteudoMarcacaoComponent extends AbstractPerguntaConteudoMarcacao {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
              protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
