import * as moment from 'moment';

// representa uma unidade de agendamento
export class ScheduleModel {
  id?: number;
  dataColeta: string;
}

// representa o modelo de dados de agendamento deste passo
export class SchedulesModel{
  constructor(
    private schedules: ScheduleModel[],
  ){ }

  // função auxiliar que converte agendamentos em moment
  public toDates(): Date[] {
    const temp = this.schedules.map((item) => (
      moment(item.dataColeta, 'DD/MM/YYYY').toDate()
    ))
    return temp
  }

  // função auxiliar que converte agendamentos em moment
  public toUnixDates(): number[] {
    const temp = this.schedules.map((item) => (
      moment(item.dataColeta, 'DD/MM/YYYY').valueOf()
    ))
    return temp
  }

  public isEmpty(): boolean {
    return !(Array.isArray(this.schedules) && this.schedules.length > 0);
  }

  public toModelArray(): ScheduleModel[] {
    return this.schedules;
  }
};

// representa o modelo de dados do cliente deste passo
export class CustomerModel {
  nome: string;
  cpfCnpj: string;
  email: string;
}

// representa
export class ConfiguracoesModel {
  // id da pesquisa
  id: number;
  // cliente
  cliente: {
    id: number,
  };
  // descritivos da pesquisa
  descricaoPesquisa: {
    titulo: string;
    objetivo: string;
    tipoPesquisa: string;
    tituloCurto: string;
    permitirGravacao: boolean;
  };
  // orientação inicial
  textoInicial: string;
  // configurações da pesquisa
  configuracaoPesquisa: {
    amostrasPresenciais: number;
    amostrasOnline: number;
    distancia: number;
    tempoMinimo: string;
    token: string;
    tokenVisualizacao: string;
    agendamentos: ScheduleModel[];
  }
}

