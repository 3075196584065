import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
    @Input() checked: boolean = false;

    /**
     * 0 - inactive
     * 1 - active
     * 2 - first time
     */
    // @Input() statusToStyle: number = 2;
    @Output() isChecked: EventEmitter<boolean> = new EventEmitter();

    handleChange(e) {
        this.checked = e;
        this.isChecked.emit(this.checked);
    }
}
