import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ECharts, EChartsOption } from "echarts";
import { toEchartOption } from "./toEchartOption";
import { sortChartData } from "../utils/sortChartData";
import { IResultAlternative } from "../../results-interface";
import { round } from "../utils/rounder";

@Component({
  selector: "app-multiple-grid",
  templateUrl: "./multiple-grid.component.html",
  styleUrls: ["./multiple-grid.component.scss"],
})
export class MultipleGridComponent implements OnInit, OnChanges {
  @Input() alternatives;
  @Input() showModal: boolean;
  @Input() layout: string;
  chartOption: EChartsOption = {};
  echartsInstance: ECharts;
  seriesData: IResultAlternative[];
  columns: Object[];
  totalLines = {};
  disbledLegends = [];

  constructor() {}

  ngOnInit(): void {
    if (this.alternatives) {
      const lines = [...new Set(this.alternatives.map((value) => value.linha))];
      this.columns = [
        ...new Set(this.alternatives.map((value) => value.coluna)),
      ];

      // Tratamento dos dados antes da plotagem no gráfico
      this.seriesData = sortChartData(this.alternatives, lines, this.columns);
      this.totalLines = this.getTotalNumberRsult(lines);

      this.getChartsValue();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.showModal &&
        changes.showModal.currentValue !== changes.showModal.previousValue) ||
      (changes.layout &&
        changes.layout.currentValue !== changes.layout.previousValue)
    ) {
      this.resizeChart();
      this.getChartsValue();
    }
  }

  // Pega a instancia do echarts para manipulação do resize
  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
  }
  // Redimensionar as barras
  // onChangeLegend(params) {
  //   const series: any = this.chartOption.series;
  //   const source = this.chartOption.dataset["source"];
  //   const disabledNames = series
  //     .filter((serie, index) => {
  //       if (params.selected[series[index].name] === false) {
  //         return serie;
  //       }
  //     })
  //     .map(({ name }) => name);
  //   source.map((item) => {
  //     disabledNames.map((name) => {
  //       item[name] = 0;
  //     });
  //   });
  //   this.chartOption.dataset["source"] = [...source];
  //   this.echartsInstance.setOption(this.chartOption);
  // }

  // Força o resize do gráfico quando muda o tamanho da tela
  resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  /**
    @returns dataset :Objeto de configuração do gráfico, contendo as
    colunas e seus respectivos valores percentuais e númericos
  */
  buildDataset(): Object {
    const dataset = {
      dimensions: ["items", ...this.columns],
      source: (() => {
        const sourceItems = [];

        this.seriesData.forEach((serieItem) => {
          let sourceItem = sourceItems.find(
            (sourceObj) => sourceObj.items === serieItem.linha
          );
          if (!sourceItem) {
            sourceItem = {
              items: serieItem.linha,
            };
            sourceItems.push(sourceItem);
          }

          sourceItem[serieItem.coluna] = round(
            (serieItem.resultado_numerico / this.totalLines[serieItem.linha]) *
              100,
            2
          );

          sourceItem[`${serieItem.coluna}_percent`] =
            serieItem.resultado_percentual;
          sourceItem[`${serieItem.coluna}_numeric`] =
            serieItem.resultado_numerico;
        });

        return sourceItems;
      })(),
    };

    return dataset;
  }

  /**
   * @returns series: objeto deconfiguração das barras do gráfico,
   * como tamanho e legendas
   */
  buildSeries(): Object {
    const series = this.columns.map((legend) => {
      return {
        type: "bar",
        name: legend,
        legend: {},
        stack: "total",
        barMaxWidth: this.showModal || this.layout === "COLUMN" ? 92 : 52,
      };
    });
    return series;
  }
  /** Soma todos os resultados núméricos de cada linha do gráfcio por exemplo: Flamengo (Gosto, Odeio, Neutro)
   * o método vai somar todos os valores de gosto + odeio + neutro;
   */
  getTotalNumberRsult(lines) {
    const totalsFromLine = {};
    if (this.seriesData && this.seriesData.length) {
      lines.forEach((line) => {
        const totalFromLine = this.seriesData
          .filter((value) => value.linha === line)
          .map((value) => value.resultado_numerico)
          .reduce((a, b) => a + b, 0);

        totalsFromLine[line] = totalFromLine;
      });
    }
    return totalsFromLine;
  }
  /**
   * Passa o dataset e a series para o toEchartOption e renderiza o gráfico
   */
  getChartsValue() {
    if (this.columns) {
      const series = this.buildSeries();
      const dataset = this.buildDataset();

      this.chartOption = toEchartOption(dataset, series);
    }
  }
}
