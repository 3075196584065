<div class="selector"
  (click)="handleShowBody()"
  [style.width]="width"
  [style.background-color]="backgroundColor">
  <div class="selector-header">
    <div class="selector-header-text-area">
      <span class="selector-header-text" *ngIf="!selectedItem">{{placeholder}}</span>
      <span class="selector-header-text" *ngIf="selectedItem">{{selectedItem.label}}</span>
    </div>
  </div>

  <!--   -->
  <div class="selector-body" [style.display]="showBody ? 'block' : 'none'" (click)="$event.stopPropagation()">
    
    <div *ngIf="!hasDataEntries()">
      {{errorMessage}}
    </div>

    <div *ngIf="hasDataEntries()">

      <div class="selector-body-filter">
        <input type="text" placeholder="{{filterPlaceholder}}" (input)="handleFilter($event)" [value]="filter"/>
      </div>

      <div class="selector-body-options">
        <div *ngFor="let item of data; let itemIdx = index">
          <div
            class="option"
            (click)="handleItemSelect(item, itemIdx)"
            [class.selected]="selectedItem && item.value === selectedItem.value"
            *ngIf="!item.hidden">
            {{item.label}}
          </div>
        </div>
      </div>

    </div>

  </div>
</div>