import { AbstractControl, ValidationErrors } from '@angular/forms';

// validador de obrigatoriedade de perguntaDestino

export default (control: AbstractControl): ValidationErrors => {

  const perguntaDestino = control.value;

  if (!perguntaDestino || !perguntaDestino.codigoMarcacao) {
    return {
      perguntaDestinoInvalida: true,
    };
  }

};
