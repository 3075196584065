import { Component } from '@angular/core';
import { AbstractPerguntaConteudo } from '../abstractPerguntaConteudo';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';

@Component({
  selector: 'app-pergunta-video-content',
  templateUrl: './pergunta-video-content.component.html',
  styleUrls: ['./pergunta-video-content.component.scss'],
})
export class PerguntaVideoContentComponent extends AbstractPerguntaConteudo {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
              protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
