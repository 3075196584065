export enum LgpdTypes {
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_OF_USAGE = "TERMS_OF_USAGE",
}

export interface IDocumentInfo {
  id: number;
  location: string;
  type: LgpdTypes;
  nome: string
  data_cadastro: string
  tamanho: number
}
