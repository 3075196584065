export enum FilterIcon {
  FUNNEL = "fa-regular fa-filter",
  MARKER = "fa-regular fa-map-location-dot",
  CALENDAR = "fa-regular fa-calendar-minus",
  SORTING = "fa-solid fa-arrow-down-arrow-up"
}

export enum FilterTypes {
  LIST = "list",
  DATE = "date",
  RANGE_DATE = "rangeDate",
  CHECK = "check",
  COMPOSED_CHECK = 'composedCheck',
  RADIO = "radio"
}

export const months = [
  { id: 0, label: "Janeiro" },
  { id: 1, label: "Fevereiro" },
  { id: 2, label: "Março" },
  { id: 3, label: "Abril" },
  { id: 4, label: "Maio" },
  { id: 5, label: "Junho" },
  { id: 6, label: "Julho" },
  { id: 7, label: "Agosto" },
  { id: 8, label: "Setembro" },
  { id: 9, label: "Outubro" },
  { id: 10, label: "Novembro" },
  { id: 11, label: "Dezembro" },
];

export const CollectionInterval = {
  month: [0, 11],
  year: [2023, 2034],
};
