import { UntypedFormControl } from "@angular/forms";
import arrays from "app/util/misc/arrays";
import { v4 as uuid } from "uuid";
import { TipoPergunta } from "../../../../../modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { AlternativaSelecionadaControl } from "../../resposta/alternativaSelecionadaControl";
import { TipoAlternativa } from "../../tipoAlternativa";
import { Alternativa } from "./alternativa";
import { AlternativaSelecionada } from "./alternativaSelecionada";
import { PerguntaEsquema } from "./perguntaEsquema";
import { TituloGrade } from "./tituloGrade";

export class RespostaPergunta {
  num?: number;
  pergunta: PerguntaEsquema;
  alternativasSelecionadas: AlternativaSelecionada[];

  constructor(
    pergunta: PerguntaEsquema,
    alternativasSelecionadas: AlternativaSelecionada[] = [],
    num: number = 0
  ) {
    this.pergunta = {
      ...pergunta,
      alternativas: this.ordenarAlternativas(pergunta.alternativas),
    };

    this.alternativasSelecionadas = alternativasSelecionadas;

    this.num = num;
  }

  isGrade() {
    return (
      this.pergunta.tipo === TipoPergunta.GRADE_UNICA ||
      this.pergunta.tipo === TipoPergunta.GRADE_MULTIPLA
    );
  }

  /**
   * Retorna as alternativas passadas ordenadas pelo atributo "ordem".
   * @param alternativas alternativas a serem ordenadas
   */
  private ordenarAlternativas(alternativas: Alternativa[]): Alternativa[] {
    return alternativas.sort((prev: Alternativa, next: Alternativa) => {
      return prev.ordem - next.ordem;
    });
  }

  /**
   * Recupera uma lista de alternativas, onde cada alternativa
   * é mapeada para a sua seleção, caso a mesma esteja selecionada.
   */
  getAlternativasSelecionadas(): AlternativaSelecionadaControl[] {
    const alternativas: Alternativa[] = this.pergunta.alternativas;
    const all = alternativas.map((alternativa: Alternativa) => {
      const alternativasSelecionadas = this.alternativasSelecionadas
        .filter(
          (alternativaSelecionada: AlternativaSelecionada) =>
            alternativaSelecionada.idAlternativa === alternativa.id
        )
        .map((selecionada) => {
          return {
            id: new UntypedFormControl(selecionada.id),
            arquivo: new UntypedFormControl(selecionada.arquivo),
            data: new UntypedFormControl(selecionada.data),
            horario: new UntypedFormControl(selecionada.horario),
            idAlternativa: new UntypedFormControl(selecionada.idAlternativa),
            idTituloGrade: new UntypedFormControl(selecionada.idTituloGrade),
            ordem: new UntypedFormControl(selecionada.ordem),
            respostaAberta: new UntypedFormControl(selecionada.respostaAberta),
            tipo: new UntypedFormControl(selecionada.tipo),
          };
        });

      if (alternativasSelecionadas.length > 1) {
        return alternativasSelecionadas.map((alternativaSelecionada) => ({
          hash: uuid(),
          esquema: alternativa,
          ...alternativaSelecionada,
          selecionada: new UntypedFormControl(true),
        }));
      }

      // tslint:disable-next-line: max-line-length
      const alternativaSelecionada =
        alternativasSelecionadas.length > 0
          ? alternativasSelecionadas[0]
          : {
              hash: uuid(),
              id: null,
              ordem: null,
              respostaAberta: this.isAlternativaAberta(alternativa)
                ? new UntypedFormControl("")
                : null,
            };

      return [
        {
          hash: uuid(),
          esquema: alternativa,
          ...alternativaSelecionada,
          selecionada: new UntypedFormControl(
            alternativaSelecionada.id != null
          ),
        },
      ];
    });

    return all.reduce((prev, next) => prev.concat(next), []);
  }

  /**
   * Verifica se a alternativa passada é do tipo Aberta,
   * ou seja se seu tipoAlternativa é ABERTA_TEXTO ou ABERTA_NUMERO.
   * @param alternativa
   */
  isAlternativaAberta(alternativa: Alternativa) {
    return (
      alternativa.tipoAlternativa === TipoAlternativa.ABERTA_TEXTO ||
      alternativa.tipoAlternativa === TipoAlternativa.ABERTA_NUMERO
    );
  }

  /**
   * Recupera o identificador o número da escala numérica selecionado
   */
  getEscalaNumericaSelecionada(): number {
    if (this.alternativasSelecionadas.length === 0) {
      console.error(
        "[RespostaPergunta.getEscalaNumericaSelecionada] não há alternativas selecionadas para esta pergunta."
      );
      return 1;
    }

    const idAlternativa: number =
      this.alternativasSelecionadas[0].idAlternativa;

    return Number(
      this.pergunta.alternativas.filter((a) => a.id === idAlternativa)[0]
        .descricao
    );
  }

  getDescricaoAlternativasSelecionadas(): string {
    const alternativasSelecionadas = this.getAlternativasSelecionadas();
    if (!alternativasSelecionadas || alternativasSelecionadas.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }

    const alternativasTitulos = alternativasSelecionadas
      .filter((a) => a.selecionada.value)
      .map((a) =>
        !!a.respostaAberta.value ? a.respostaAberta.value : a.esquema.descricao
      );

    if (alternativasTitulos.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }
    return alternativasTitulos.reduce((prev, next) =>
      prev.concat(", ").concat(next)
    );
  }

  getTituloGradeById(idTituloGrade: number): TituloGrade {
    const titulosGrades = this.pergunta.titulosGrades.filter(
      (t: TituloGrade) => t.id === idTituloGrade
    );

    return titulosGrades.length > 0 ? titulosGrades[0] : null;
  }

  logWarningSemAlternativasSelecionadas() {
    // tslint:disable-next-line: max-line-length
    console.warn(
      "Não há nenhuma alternativa selecionada na pergunta de identificador",
      this.pergunta.id
    );
    console.warn("respostaPergunta: ", this.alternativasSelecionadas);
    // tslint:disable-next-line: max-line-length
    console.warn(
      "verificar se todas as respostasPerguntas estão referenciando as alternativas de maneira apropriada."
    );
  }

  getDescricaoAlternativasSelecionadasGrade(): string {
    const alternativasSelecionadas = this.getAlternativasSelecionadas();
    if (!alternativasSelecionadas || alternativasSelecionadas.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }

    const alternativasSelecionadasComTituloGrade = alternativasSelecionadas.map(
      (a) => {
        return {
          ...a,
          tituloGrade: a.idTituloGrade
            ? this.getTituloGradeById(a.idTituloGrade.value)
            : null,
        };
      }
    );

    const descricoesAlternativas = alternativasSelecionadasComTituloGrade
      .filter((a) => a.selecionada.value && a.tituloGrade)
      .sort((prev, next) => prev.tituloGrade.ordem - next.tituloGrade.ordem)
      .map((a) =>
        a.tituloGrade.descricao.concat(": ").concat(a.esquema.descricao)
      );

    if (!descricoesAlternativas || descricoesAlternativas.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }
    return descricoesAlternativas.reduce((prev, next) =>
      prev.concat(", ").concat(next)
    );
  }

  getDescricaoAlternativasSelecionadasGradeMultipla(): string {
    // recuperando alternativas selecionadas e as não selecionadas mapeadas para seu esquema
    const alternativasSelecionadas = this.getAlternativasSelecionadas();
    if (!alternativasSelecionadas || alternativasSelecionadas.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }

    // mapeando as alternativas selecionadas e não selecionadas para seu tituloGrade correspondente
    const alternativasSelecionadasComTituloGrade = alternativasSelecionadas.map(
      (a) => {
        return {
          ...a,
          tituloGrade: a.idTituloGrade
            ? this.getTituloGradeById(a.idTituloGrade.value).descricao
            : null,
        };
      }
    );

    // fitlrando das alternativas apenas as realmente selecionadas que são tituloGrade
    const apenasSelecionadasComTituloGrade =
      alternativasSelecionadasComTituloGrade.filter(
        (a) => a.selecionada.value && a.tituloGrade
      );

    // agrupando alternativas pela descricao do seu titulo grade correspondente
    const groupedByTituloGrade = arrays.groupBy(
      apenasSelecionadasComTituloGrade,
      "tituloGrade"
    );

    // iterando sobre cada titulo grade, criando uma descricao do
    // titulo grade => alternativas selecionadas e dps concatenando tudo.
    const descricoes = Object.keys(groupedByTituloGrade).map(
      (tituloGrade: string) => {
        const alternativas = groupedByTituloGrade[tituloGrade];

        const alternativasDescricoes = alternativas.map(
          (a) => a.esquema.descricao
        );

        let alternativasDescricao = "";

        if (alternativasDescricoes && alternativasDescricoes.length > 0) {
          alternativasDescricao = alternativasDescricoes.reduce((prev, next) =>
            prev.concat(", ").concat(next)
          );
        }

        return tituloGrade.concat(": ").concat(alternativasDescricao);
      }
    );

    if (!descricoes || descricoes.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }
    return descricoes.reduce((prev, next) => prev.concat(" ").concat(next));
  }

  getDescricaoAlternativasOrdemCitacao(): string {
    // tslint:disable-next-line: max-line-length
    const alternativasSelecionadas: AlternativaSelecionadaControl[] =
      this.getAlternativasSelecionadas();
    if (!alternativasSelecionadas || alternativasSelecionadas.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }

    const alternativasDescricoes = alternativasSelecionadas
      .filter((a) => a.selecionada.value && a.ordem.value)
      .sort((prev, next) => prev.ordem.value - next.ordem.value)
      .map((a) => `${a.ordem.value}. `.concat(a.esquema.descricao));

    if (!alternativasDescricoes || alternativasDescricoes.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }
    return alternativasDescricoes.reduce((prev, next) =>
      prev.concat(" ").concat(next)
    );
  }

  getDescricaoResposta(fieldName: string): string {
    const respostas = this.alternativasSelecionadas.map((a) => a[fieldName]);
    return respostas.length > 0 ? respostas[0] : "";
  }

  getDescricaoRespostaData(): string {
    return this.getDescricaoResposta("data");
  }

  getDescricaoRespostaHorario(): string {
    return this.getDescricaoResposta("horario");
  }

  getDescricaoRespostaAberta(): string {
    return this.getDescricaoResposta("respostaAberta");
  }

  getAlternativasSelecionadasIds(): string {
    if (this.alternativasSelecionadas.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }
    const alternativasIds = this.alternativasSelecionadas.map((a) =>
      a.id.toString()
    );

    if (!alternativasIds || alternativasIds.length === 0) {
      this.logWarningSemAlternativasSelecionadas();
      return "";
    }
    alternativasIds.reduce((prev, next) => prev.concat(", ").concat(next));
  }

  /**
   * Verifica se a alternativa selecionada corresponde ao id passado por parãmetro
   * @param idAlternativa identificador da alternativa que deseja-se verificar
   */
  isSelected(idAlternativa: number): boolean {
    return (
      this.alternativasSelecionadas.filter(
        (a) => a.idAlternativa === idAlternativa
      ).length > 0
    );
  }

  /**
   * Recupera a alternativa selecionada baseada no identificador da alternativa
   * @param idAlternativa identificador de uma alternativa
   */
  getAlternativaSelecionada(
    idAlternativa: number
  ): AlternativaSelecionada | {} {
    const results = this.alternativasSelecionadas.filter(
      (a) => a.idAlternativa === idAlternativa
    );
    if (results.length === 0) {
      return {};
    }
    return results[0];
  }

  /**
   * Recupera o id da alternativa selecionada para a grade identificada passada por parâmetro.
   * @param idGrade identificador da grade
   */
  getIdAlternativaSelecionadaPorIdGrade(idGrade: number): number {
    return this.alternativasSelecionadas
      .filter((a) => a.idTituloGrade === idGrade)
      .map((a) => a.idAlternativa)[0];
  }
}
