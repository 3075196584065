import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from "@angular/core";
import { ICanNotSaveButton } from "app/modulos/pesquisa-beta/cadastro/pesquisas-cadastro.model";
import { InputOptions } from "../sublocal/sublocal-component";

export interface SubLocation {
  id: number;
  nome: string;
  value?: number;
  checked?: boolean;
  toggleChecked?: boolean;
  versao: string;
}

@Component({
  selector: "app-children-location",
  templateUrl: "./children-location.component.html",
  styleUrls: ["./children-location.component.scss"],
})
export class ChildrenLocationComponent implements OnInit, OnChanges {
  @Input() data: SubLocation[];
  @Input() hasFatherChecked: boolean;
  @Input() typeOfChildrenMessageRef: number = 5;
  @Input() indexFatherRef: number;
  @Input() fatherSampleRef: number;
  @Input() dataLength: number;
  @Input() sampleRange: number;
  @Input() leftToComplete: number;
  @Input() numericFatherSample: number;
  @Input() sampleValue: number;
  @Input() sampleLocationValue: number;

  @Output() updateGrandSonCheckById = new EventEmitter();
  @Output() updateGrandSonToggleById = new EventEmitter();
  @Output() handleUpdateSampleRef = new EventEmitter();
  @Output() handleSetTypeOfMessage = new EventEmitter();
  @Output() getSampleFatherMaxRange = new EventEmitter();
  @Output() getInputInsert = new EventEmitter();
  @Output() openAlertPopup = new EventEmitter();
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> =
    new EventEmitter();
  @Output() onSendGrandsonInputs = new EventEmitter();

  inputOptions: Array<InputOptions> = [];

  progress: number = 0;
  // Realiza a sumatória dos valores de cada input das sublocalidades, para controlar o progresso segundo o valor de cada campo habilitado;
  updateProgress() {
    this.progress = 0;

    this.progress = this.data
      .map(({ value }) => Number(value))
      .reduce((acc, at) => acc + at, 0);
  }
  // Função que calcula e retorna a quantidade de caracteres do valor de cada input, se o valor for 0 ele retorna o tamanho funciona;
  getInputWidth(value: number): number {
    value = Number(value);
    if (value) return `${value}`.length === 0 ? 15 : `${value}`.length;
    else return 15;
  }

  // Quando o componente é carregado o valor de cada input é somado e guadado na variável progress
  ngOnInit() {
    this.updateProgress();
    this.verifyRequiredFields();

    // Resetando o array de inputs caso o ngOnInit seja executado mais de uma vez.
    this.inputOptions.length = 0;

    this.data.map(({ value }: SubLocation) => {
      value = Number(value);
      const displayValue = value ? Number(value.toFixed(2)) : null
      let valueLength = displayValue ? `${displayValue}`.length : 0;
      valueLength = valueLength > 0 ? valueLength : 15
      const realValue = value
      const width = valueLength
      this.inputOptions.push({ displayValue, realValue, width });
    });

    this.sendGrandsonInputsToParent();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  sendGrandsonInputsToParent() {
    this.onSendGrandsonInputs.emit(this.inputOptions);
  }

  verifyRequiredFields(): void {
    let totalOfDistribuition = 0;

    this.data.forEach((item) => {
      totalOfDistribuition += Number(item.value);
    });

    totalOfDistribuition = Number(totalOfDistribuition.toPrecision(15)); // NEW LINE - GARANTIR PRECISÃO

    if (totalOfDistribuition !== 100 && this.sampleValue === this.sampleLocationValue) {
      // caso reste algum valor a ser distribuído, o botão de avançar é desabilitado
      this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: true } });
    }

    if (totalOfDistribuition === 100 && this.sampleValue === this.sampleLocationValue) {
      // caso não reste nenhum valor a ser distribuído, o botão de avançar é habilitado
      this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: false } });
    }
  }

  /* Ao ritirar o focu de um input essa função é chamada e trata de verificar se é preciso fazer um arredondamento com base no calculo
  de conversão de amostra percentual para amostra numérica, caso haja resto nesse calculo a função tratará de arredondar para que haja
  consistência entre a amostra numérica e percentual, assim tanto a amostra numérica é arredondada quanto a amostra percentual. A seguir
  o popup é chamado, em seguida o tamanho do elemento input do html é recalculado e salvo no array "inputWidth" posição "index" */
  convertToNumericalSample(value: number) {
    return Math.round((value * 100 * this.fatherSampleRef) / 10000);
  }

  // essa função calcula e retorna o resto do valor do input convertido para amostra numérica.
  handleRoundValue(value: number, grandsonId: number, index: number) {
    this.updateProgress();
    this.verifyRequiredFields();
    const numericProgress = this.convertToNumericalSample(this.progress);

    const haveRest = () => {
      const numericSample = ((value / 100) * this.fatherSampleRef).toPrecision(
        15
      );

      return parseFloat(numericSample) % 1;
    };

    if (haveRest()) {
      const oldValue = value;
      const percentValueRounded =
        (this.convertToNumericalSample(value) * 100) / this.fatherSampleRef;

      const howManyLeft = Math.abs(this.progress - 100 - percentValueRounded);
      value =
        numericProgress > this.numericFatherSample
          ? (this.convertToNumericalSample(howManyLeft) / this.fatherSampleRef) * 100
          : (this.convertToNumericalSample(value) / this.fatherSampleRef) * 100;
      this.openAlertPopup.emit({
        oldValue,
        actualValue: value,
        numericOld: (oldValue * 100 * this.fatherSampleRef) / 10000,
        numericAtual: this.convertToNumericalSample(value),
      });
    } else {
      value =
        this.progress > 100 ? Math.abs(this.progress - 100 - value) : value;
    }

    this.handleUpdateSampleRef.emit({
      value: Number(value),
      indexFather: this.indexFatherRef,
      indexGrandSon: grandsonId,
    });
    this.inputOptions[index].realValue = Number(value);
    this.inputOptions[index].displayValue = this.inputOptions[index].realValue ? Number(this.inputOptions[index].realValue.toFixed(2)) : null;
    this.inputOptions[index].width = this.getInputWidth(this.inputOptions[index].displayValue);

  }

  // Função para atualizar e reportar a localidade filha do checkbox da neta através do id
  handleSubCheckClick(grandsonId: number) {
    this.updateGrandSonCheckById.emit({
      fatherId: this.indexFatherRef,
      subLocationId: grandsonId,
    });

    this.handleUpdateInsertAutomatic();
  }

  // Função para atualiar e reportar a localidade filha o valor da localidade neta
  handleSubChange(value: any, grandsonId: number, index: number) {
    this.inputOptions[index].realValue = Number(value);
    this.handleUpdateSampleRef.emit({
      value: Number(value.currentTarget.value),
      indexFather: this.indexFatherRef,
      indexGrandSon: grandsonId,
    });

    this.handleUpdateMaxRange();
  }

  // Função responsável por atualizar o max range do input baseando-se no valor da localidade filha
  handleUpdateMaxRange() {
    this.getSampleFatherMaxRange.emit(this.indexFatherRef);
  }

  // Função que lida com a validação de inputs netas
  async handleUpdateInsertAutomatic() {
    await this.getInputInsert.emit(this.indexFatherRef);
    this.data.map(({ value }, index) => {
      value = Number(value)
      this.inputOptions[index].displayValue = value ? Number(value.toFixed(2)) : null;
      this.inputOptions[index].realValue = Number(value);
      this.inputOptions[index].width = this.getInputWidth(
        this.inputOptions[index].displayValue
      );
    })
  }

  // função que lida com a emissão do toggle de uma localidade neta para a localidade filha (parente acima)
  handleSubToggleCheckClick(subId: number, event: boolean, index: number) {
    this.verifyRequiredFields();
    this.updateGrandSonToggleById.emit({
      fatherId: this.indexFatherRef,
      subIndex: subId,
      event,
    });
    this.handleUpdateInsertAutomatic();
    if (!this.data[index].toggleChecked) {
      this.inputOptions[index].displayValue = null;
      this.inputOptions[index].realValue = 0;
      this.data[index].value = 0;

    }
  }
}
