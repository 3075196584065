<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card-marcacao
    [icone]="'fa fa-object-group'"
    [titulo]="'MÚLTIPLA ORDEM'"
    [nomePlaceHolder]="'Título da pergunta de múltipla ordem?'"
    [formGroup]="formGroup"
  >
  <ng-content></ng-content>
</app-pergunta-card-marcacao>

</div>