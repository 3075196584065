import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ConfiguracaoPaginacao } from "../../paginacao/configuracaoPaginacao";
import { DadosPaginacao } from "../../paginacao/dadosPaginacao";
import { EventoTabela } from "../evento/eventoTabela";
import { TipoEventoTabela } from "../evento/tipoEventoTabela";
import { TrBody } from "../trbody/trBody";
import { TrBodyFactory } from "../trbody/trBodyFactory";
import { SimpleFilterService } from "./filter/filterService";
import { PalavraChave } from "./filter/palavraChave";

@Component({
  selector: "app-tabela-filtravel",
  templateUrl: "./tabela.filtravel.component.html",
  styleUrls: ["./tabela.filtravel.component.scss"],
})
export class TabelaFiltravelComponent implements OnInit, OnDestroy {
  trBodies: TrBody[] = [];
  trBodiesSelecionados: string[] = [];
  selecionarTodos: boolean = false;
  ultimaBusca: string = "";
  ultimoDadoPaginacao: DadosPaginacao;
  isLoading: boolean = false;

  @Input() backgroundFilter: string = "white";

  // tslint:disable-next-line:max-line-length
  subjectPaginacao: BehaviorSubject<ConfiguracaoPaginacao> =
    new BehaviorSubject<ConfiguracaoPaginacao>(undefined);

  @Input() apenasListagem: boolean = false;

  @Input() tableEventProvider: Observable<EventoTabela>;
  tableEventProviderSubscription: Subscription;

  @Input() placeholder: string = "";

  arquivada: boolean = false;

  /**
   * Exibir opcao download
   */
  @Input() exibirOpcaoDownload: boolean = false;

  /**
   * Nome das colunos da tabela, em suma, os <th> do <thead>
   */
  @Input() nomeColunas: string[] = [];
  /**
   * Servico que implementa a interface SimpleFilterService,
   * o servico sera utilizado para todas as operacoes de exibicao da tabela
   */
  @Input() filterService: SimpleFilterService<any>;

  /**
   * Nome dos atributos do objeto que serao exibidos na tabela,
   * na ordem em que serao exibidos
   */
  @Input() nomeAtributosExibidos: string[];

  @Input() title: string;

  /**
   * Atributo passado caso o ID da entidade seja diferente do id padrão da TrBody
   * Neste caso o Id da @type TrBody será substituido pelo novo valor do @type nomeAtributoIdCustomizado
   */
  @Input() nomeAtributoIdCustomizado: string;

  @Input() exibirOpcaoDuplicacao: boolean = false;

  /**
   * Flag que indica se a opção de arquivar a entidade
   * será exibida ou não.
   */
  @Input() exibirOpcaoArquivar: boolean = false;

  /**
   * A execucoes solicitadas nas tarefas serao
   * repassadas na forma de eventos
   */
  @Output() eventEmitter: EventEmitter<EventoTabela> = new EventEmitter();
  /**
   * não exibe a coluna status caso seja uma entidade sem status
   */
  @Input() entidadeSemStatus: boolean = false;

  /**
   * Permissões necessárias para exclusão
   */
  @Input() removePermissions: Authority[];

  // @Input() edicaoPermissions: Authority[];

  /**
   * Marcações que indicam se a exclusão deve ser exibida
   */
  exibirExclusao: boolean = false;

  @Input() ocultarCheckbox: boolean = false;

  @Input()
  initialValue: string;

  // exibirEdicao: boolean = false;

  constructor(private securityService: SecurityService) {}

  ngOnInit() {
    // Inicializando a tabela
    const initialValue = { target: { value: this.initialValue } };
    this.aplicarFiltro(initialValue);
    this.trBodiesSelecionados = [];
    if (this.tableEventProvider) {
      // tslint:disable-next-line:max-line-length
      this.tableEventProviderSubscription = this.tableEventProvider.subscribe(
        this.handleTableEventProvider()
      );
    }

    if (this.removePermissions) {
      this.exibirExclusao = this.securityService.userHasAuthorities(
        this.removePermissions
      );
    }

    this.selecionarTodos = false;
  }
  /**
   * Função que verifica se o id é positivo ou negativo indicando se um item é do sistema ou criado pelo usuário
   * @param id valor positivo indica items criados pelo usuário e negativos indicam items padrão do sistema
   * @returns o valor retornado para veridicação de pemissão de edição
   *
   */
  verifyPositiveId(id: any): boolean {
    if(typeof id === 'number'){
      return id > 0;
    }
    return true
  }

  /**
   * Função responsavel por selecionar qual a estrategia adequada para
   * manipular os eventos de tabela que foram disparados
   */
  handleTableEventProvider() {
    return (eventoTabela: EventoTabela) => {
      switch (eventoTabela.tipo) {
        case TipoEventoTabela.SELECAO:
          this.handleSelectionTableEvent(eventoTabela);
          break;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.tableEventProviderSubscription) {
      this.tableEventProviderSubscription.unsubscribe();
    }
  }

  /**
   * Função que trata eventos de seleção na tabela,
   * neste caso, marcando os checkbox caso o id contido
   * no payload do evento estaja presente na tabela
   */
  handleSelectionTableEvent(eventoTabela: EventoTabela) {
    const idsSelecionados = eventoTabela.payload;
    this.trBodiesSelecionados.push(...idsSelecionados);

    this.atualizarSelecaoTrBodies();
  }

  /**
   * Atualiza os checkbox dos trBodies, util pois todos os fluxos sao assincronos
   */
  atualizarSelecaoTrBodies() {
    this.trBodies.forEach((trBody) => {
      const isSelecionado = this.trBodiesSelecionados.includes(trBody.id);

      if (isSelecionado) {
        trBody.selected = true;
      }
    });
  }
  interpretarFiltroTabela() {
    return (dados) => {
      this.isLoading = true;
      // dados.id = dados[]
      const novoTrBodies = TrBodyFactory.buildTrBodies(
        dados,
        this.nomeAtributosExibidos,
        this.title,
        this.nomeAtributoIdCustomizado
      );
      this.trBodies.length = 0;
      this.trBodies.push(...novoTrBodies);

      this.atualizarSelecaoTrBodies();
      this.isLoading = false;
    };
  }

  aplicarFiltro(
    input: any = this.ultimaBusca,
    isEventoPaginacao: boolean = false
  ) {
    this.ultimaBusca = input.target.value ? input.target.value : "";

    const palavraChave = new PalavraChave(this.ultimaBusca.trim().split(" "));

    this.filterService
      .aplicarFiltro(palavraChave, this.ultimoDadoPaginacao)
      .subscribe(this.interpretarFiltroTabela());

    /**
     * Se eh uma nova busca e nao uma navegacao de paginacao, uma nova configuracao
     * para a paginacao deve ser enviada
     */
    if (!isEventoPaginacao) {
      this.filterService.getTotalEntidades(palavraChave).subscribe((total) => {
        const configuracaoPaginacao = new ConfiguracaoPaginacao();
        configuracaoPaginacao.totalPaginas = total;
        configuracaoPaginacao.paginaAtual = 0;

        this.subjectPaginacao.next(configuracaoPaginacao);
      });
    }
  }

  enviarEventoEdicao(id: string) {
    /** Só dispara o evento de edição da tabela caso o id seja positivo, ou seja, se for um id de um item gerado pelo usuário,
    pois ids negativos denotam items que são padrão do sistema*/
    if (this.verifyPositiveId(id)) {
      const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, id);
      this.eventEmitter.emit(eventoEdicao);
    }
  }

  enviarEventoExclusao(ids: string[]) {
    const payload = Array.isArray(ids) ? ids : [ids];
    const eventoExclusao = new EventoTabela(TipoEventoTabela.EXCLUIR, payload);
    this.eventEmitter.emit(eventoExclusao);
    // this.selecionarTodos = false
  }

  enviarEventoDownload() {
    const eventoDownload = new EventoTabela(TipoEventoTabela.DOWNLOAD, null);
    this.eventEmitter.emit(eventoDownload);
  }

  enviarEventoDuplicacao() {
    this.trBodies.forEach((trBody) => {
      if (trBody.selected) {
        const eventoDuplicacao = new EventoTabela(
          TipoEventoTabela.DUPLICACAO,
          trBody.id
        );
        this.eventEmitter.emit(eventoDuplicacao);
      }
    });
  }

  enviarEventoArquivar() {
    const idItensSelecionados = this.trBodiesSelecionados;
    if (idItensSelecionados.length > 0) {
      const eventoArquivar = new EventoTabela(
        TipoEventoTabela.ARQUIVAR,
        idItensSelecionados
      );
      this.eventEmitter.emit(eventoArquivar);
    }
  }

  enviarEventoExclusaoItensSelecionados() {
    const idItensSelecionados = this.trBodiesSelecionados;
    this.enviarEventoExclusao(idItensSelecionados);
  }

  enviarEventoSelecao() {
    // tslint:disable-next-line:max-line-length
    const eventoTabela = new EventoTabela(
      TipoEventoTabela.SELECAO,
      this.trBodiesSelecionados
    );
    this.eventEmitter.emit(eventoTabela);
  }
  acionarSelecionarTodos() {
    this.selecionarTodos = !this.selecionarTodos;
    // toda vez que esse evento for chamado, a lista de trBodiesSelecionados eh zerada,
    // para não correr o risco de adicionar um mesmo id na lista
    this.trBodiesSelecionados = [];

    this.trBodies.forEach((trBody) => {
      this.selectTrBody(trBody, this.selecionarTodos);
    });
  }
  /**
   * Seleciona ou desceleciona uma linha da tabela
   */
  selectTrBody(trBody: TrBody, value: boolean = undefined) {
    if (value === undefined) {
      trBody.selected = !trBody.selected;
    } else {
      trBody.selected = value;
    }

    if (trBody.selected) {
      this.trBodiesSelecionados.push(trBody.id);
    } else {
      const indexToRemove = this.trBodiesSelecionados.indexOf(trBody.id);
      if (indexToRemove > -1) {
        this.trBodiesSelecionados.splice(indexToRemove, 1);
      }
      if (this.trBodiesSelecionados.length === 0) {
        this.selecionarTodos = false;
      }
    }

    this.enviarEventoSelecao();
  }

  /**
   * Quando ocorre um evento de paginacao, a mesma eh armazenada em this.ultimoDadoPaginacao
   * a fim de  aplicar a configuracao de paginacao (basicamente o tamanho da página)
   * no caso de uma busca no filtro
   */
  onPaginationChange(eventoPaginacao: DadosPaginacao) {
    this.ultimoDadoPaginacao = eventoPaginacao;
    const ultimaBusca = { target: { value: this.ultimaBusca } };
    this.aplicarFiltro(ultimaBusca, true);
  }
}
