import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import {
  CadastroPesquisaStoreState,
  EstadoValidacao,
} from "../store/cadastroPesquisaStoreState";
import { PassoCadastro } from "../store/passoCadastro";

/**
 * Classe base de um componente que compõe um formulário complexo.
 * Por exemplo, Passos do cadastro de pesquisa.
 */
@Component({
  template: "",
})
export abstract class PassoComponent implements OnInit, OnDestroy {
  /**
   * Subscriptions que deverão ser encerradas
   * junto com a destruição do componente
   */
  subscriptions: Subscription[] = [];

  /**
   * Marcador para saber se os erros de validacao
   * devem ser exibidos
   */
  showErrors: boolean = false;

  /**
   * Marcador que indica se os inputs necessarios para a concretização
   * de um rascunho devem exibir seus erros de validação
   */
  exibirErrosValidacaoRascunho: boolean = false;

  abstract passoCadastro: PassoCadastro;

  /**
   * Estado de validação da pesquisa atual
   */
  estadoValidacao: EstadoValidacao;

  /**
   * Passos que por alguma razao estao bloqueados
   */
  passosBloqueados: PassoCadastro[] = [];

  constructor(protected store: Store<CadastroPesquisaStoreState>) {}

  /**
   * @override
   */
  ngOnInit(): void {
    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> =
      this.store.pipe(
        map((x) => x["cadastroPesquisa"]),
        map((pesquisa) =>
          pesquisa ? pesquisa : new CadastroPesquisaStoreState()
        )
      );

    const dadosPassoSubscription = pesquisaStoreObservable.subscribe(
      (store) => {
        this.verificarExibirErrosValidacaoRascunho(store);
        this.verificarShowErrors(store);
        this.extrairEstadoValidacao(store);
      }
    );

    this.subscriptions.push(dadosPassoSubscription);
  }

  /**
   * Método que verifica se os erros de validação dos itens que compoe
   * o rascunho devem ou nao serem exibidos
   */
  verificarExibirErrosValidacaoRascunho(store: CadastroPesquisaStoreState) {
    this.exibirErrosValidacaoRascunho =
      store.dadosGeraisCadastro.exibirValidacoesRascunho;
    this.passosBloqueados = store.dadosGeraisCadastro.passosBloqueados;
  }

  /**
   * Método que verifica se os erros de validação dos inputs comuns devem
   * ou não serem exibidos
   */
  private verificarShowErrors(store: CadastroPesquisaStoreState) {
    const dadosPasso = store.dadosPasso;
    const dados = dadosPasso.get(this.passoCadastro);
    this.showErrors = dados.exibirValidacao;
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Verifica se o usuario possui permissoes
   * para modificar o passo atual
   */
  abstract verificarPermissaoAlteracao();

  extrairEstadoValidacao(store: CadastroPesquisaStoreState) {
    this.estadoValidacao = store.dadosGeraisCadastro.estadoValidacao;
  }
}
