import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NotificatorService } from "app/notificador/notificator.service";
import { Localidade } from "../../localidade";
import { LocalidadeService } from "../../servico/localidade.service";
import { LeafletMapManager } from "../leaflet-map-manager";
import { ModalCreadUpdateService } from "../modal-create-update.service";
import { formatToMaxDecimalPlaces } from "../../../../util/formatter";

interface AddressBody {
  geoId: string | null;
  cep: string | null;
  pais: string | null;
  estado: string | null;
  cidade: string | null;
  enderecoCompleto: string | null;
  tipo?: string | null;
}

@Component({
  selector: "app-nominatim-form",
  templateUrl: "./nominatim-form.component.html",
  styleUrls: ["./nominatim-form.component.scss"],
})
export class NominatimFormComponent implements OnInit, OnChanges {
  // Referências
  @ViewChild("selectinputaddress", {static: true}) inputSelectLocationAddress;

  @Input() isChildrenLocation: boolean = false;
  @Input() whoId: number;
  @Input() whoIsEditing: string = "NONE";

  // Atributo para receber o valor digitado no input de busca
  searchInput: string = "";

  // Propriedades
  isSearchResultOpenAddress: boolean = false;

  // Formulário de localdiade
  locationForm: UntypedFormGroup;

  debounceTime: ReturnType<typeof setTimeout>;

  // objeto de facil acesso paara os formControls
  formControls: { [key: string]: UntypedFormControl } = {} as {
    [key: string]: UntypedFormControl;
  };

  // Nominatim
  // Atributos relativos a Localidade (array de localidades, referencia como backup do array de localidades) e o id da localidade selecionada
  localidadesNominatim = [];
  localidadesApi = [];

  // atributos com a referência do ID da localidade PAI
  localidadePaiSelectedById: number = null;
  localidadeSelectedById: number = null;

  // Localidade como backup caso o usuário "reset" os inputs e não salve algo novo.
  localidadesApiRef;
  localidadesNominatimRef;

  // Montagem para envio ao serviço
  latMin: number;
  latMax: number;
  longMin: number;
  longMax: number;

  // CEP da localidade
  localidadeAddress: AddressBody = {
    geoId: "",
    cidade: "",
    pais: "",
    cep: "",
    enderecoCompleto: "",
    estado: null,
    tipo: null
  };

  // EDIT MODE - VIA CADASTRO OU EDIÇÃO
  localidadeToUpdate: Localidade;

  // Loading
  isLoadingLocations: boolean = false;

  // Map manager
  leafletMapManager: LeafletMapManager;

  constructor(
    private localidadeService: LocalidadeService,
    private modalCreateUpdateService: ModalCreadUpdateService,
    private notificatorService: NotificatorService,
  ) {
    this.leafletMapManager = new LeafletMapManager(localidadeService, modalCreateUpdateService, notificatorService);
  }

  ngOnInit() {
    this.initFormGroup();
  }

  // Inicialização do formulário
  initFormGroup() {
    this.formControls["fatherLocation"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["childrenLocation"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["address"] = new UntypedFormControl(null, [Validators.required]);
    this.formControls["latitude"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["longitude"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["raio"] = new UntypedFormControl(null, [Validators.required]);

    this.locationForm = new UntypedFormGroup({
      fatherLocation: this.formControls.fatherLocation,
      childrenLocation: this.formControls.childrenLocation,
      address: this.formControls.address,
      latitude: this.formControls.latitude,
      longitude: this.formControls.longitude,
      raio: this.formControls.raio,
    });

    this.locationForm.reset();
  }

  // Função para validar o formulário
  // Quando cadastro é de localidade PAI OU FILHA
  formIsValid(): boolean {
    if (!this.isChildrenLocation) {
      return (
        this.locationForm.get("fatherLocation").value !== null &&
        this.locationForm.get("address").value !== null &&
        this.locationForm.get("latitude").value !== null &&
        this.locationForm.get("longitude").value !== null &&
        this.locationForm.get("raio").value !== null
      );
    } else {
      return (
        this.locationForm.get("childrenLocation").value !== null &&
        this.locationForm.get("address").value !== null &&
        this.locationForm.get("latitude").value !== null &&
        this.locationForm.get("longitude").value !== null &&
        this.locationForm.get("raio").value !== null
      );
    }
  }

  // Função que cancela ação de atualização
  handleCancelUpdate(): void {
    this.modalCreateUpdateService.setGoBackButton();
  }

  // Função para deixar o botão de salvar desabilitados
  canShowCancelFormButton(): boolean {
    return (
      this.modalCreateUpdateService.getCanCreateChildren() ||
      this.modalCreateUpdateService.getWhoIsEditing() !== "NONE"
    );
  }

  onSubmit() {
    let resultRaio = this.handleConvertToMeters(
      String(this.locationForm.get("raio").value)
    );

    const dataToSave = {
      nome: this.isChildrenLocation
        ? this.locationForm.get("childrenLocation").value
        : this.locationForm.get("fatherLocation").value,
      latitude: formatToMaxDecimalPlaces(this.locationForm.get("latitude").value, 15),
      longitude: formatToMaxDecimalPlaces(this.locationForm.get("longitude").value, 15),
      raio: resultRaio,
      endereco: this.localidadeAddress,
    };

    this.locationForm.reset();

    if (this.whoIsEditing === "FATHER") {
      this.modalCreateUpdateService.updateLocalidadePai(dataToSave);
    } else if (this.whoIsEditing === "CHILDREN") {
      this.modalCreateUpdateService.updateLocalidadeFilha(dataToSave);
    } else {
      if (!this.isChildrenLocation) {
        this.modalCreateUpdateService.saveLocalidadePai(dataToSave);
      } else {
        this.modalCreateUpdateService.saveLocalidadeFilha(dataToSave);
      }
    }

    // Verifica se a localidade é custom e monta o mapa
    if (dataToSave.endereco.geoId === 'xxxx') {
      this.leafletMapManager.updateInternals(
        dataToSave.latitude,
        dataToSave.longitude,
        dataToSave.raio
      );

      this.leafletMapManager.resolveMap();
    }

    this.handleCancelUpdate();
  }

  // Função responsável por converter de KM para M
  handleConvertToMeters(raio: string): number {
    const raioToSplit = `${raio}`.split(".");
    if (raioToSplit.length > 1) {
      const splitDecimal = raioToSplit[1].split("");
      const finalTemplateRaio = `${raioToSplit[0]}${splitDecimal[0]}${splitDecimal[1]}${splitDecimal[2]}`;
      return Number(finalTemplateRaio);
    }
    return Number(raio);
  }

  // Funções auxiliadoras para realizar cálculosc
  calculateCenter(coor1: number, coor2: number) {
    return (coor1 + coor2) / 2;
  }

  // Funções auxiliadoras para realizar cálculos e converter para radiano
  convertToRadius(value: number) {
    return (Number(value) * Math.PI) / 180;
  }

  // Funções auxiliadoras para realizar cálculos o raio de uma região
  calculateRadius(
    latMin: number,
    latMax: string,
    lonMin: number,
    lonMax: string
  ) {
    const earthRadius = 6371; // in kilometers
    const dLat = this.convertToRadius(Number(latMax) - Number(latMin));
    const dLon = this.convertToRadius(Number(lonMax) - Number(lonMin));
    const latSeno = Math.sin(dLat / 2);
    const lonSeno = Math.sin(dLon / 2);

    const a =
      Math.pow(latSeno, 2) +
      Math.pow(lonSeno, 2) *
        Math.cos(this.convertToRadius(Number(latMin))) *
        Math.cos(this.convertToRadius(Number(latMax)));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const dist = earthRadius * c;

    return dist;
  }

  // Função para formatar string no template correto no input de endereço
  formatToTemplateLocations(localidade): string {
    let finalLabelPlace = "";

    localidade.display_name
      .split(", ")
      .filter((item) => !item.toLowerCase().includes("região"))
      .map((item, index, array) =>
        index === array.length - 1
          ? (finalLabelPlace += `${item}`)
          : (finalLabelPlace += `${item}, `)
      );

    return finalLabelPlace;
  }

  // Função responsável por atribuir automaticamente os valores de latitude, longitude e do raio no formulário
  setarFormControlAutoComplete(
    latitude: string,
    longitude: string,
    distance: number
  ) {
    this.locationForm.get("latitude").setValue(latitude);
    this.locationForm.get("longitude").setValue(longitude);
    this.locationForm.get("raio").setValue(this.handleConvertToMeters(`${distance}`));
  }

  // Nominatim
  // Função responsável por buscar no nomiatim as possíveis localidades
  // que se encaixam no que o usuário está digitando
  handleLoadLocationsByInput(locationToSearch: any) {
    this.isLoadingLocations = true;
    this.searchInput = locationToSearch.target.value ? locationToSearch.target.value : this.searchInput;
    this.searchInput = this.searchInput
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      this.localidadesNominatim = this.localidadeService.listarLocalidadesComDetalhes(this.searchInput);
      setTimeout(() => {
        this.isLoadingLocations = false;
      }, 3000);
    }, 500); //
  }

  // Função responsável por atribuir a localidade selecionada pelo usuário
  // a partir do id e preencher os demais campos automaticamente
  // Além de atualizar o mapa com a localidade escolhida e a área (criando um entorno de pontos enviados pelo nominatim)
  handleSelectLocation(place_id: number, localidadeRef) {
    this.isSearchResultOpenAddress = false;
    this.localidadeSelectedById = place_id;
    this.localidadesNominatimRef = localidadeRef;

    // A função irá percorrer cada localidade e validar qual place_id é igual ao selecionado pelo usuário na listagem
    this.localidadesNominatim.map((localidade) => {
      if (localidade.place_id === this.localidadeSelectedById) {
        // Montagem do corpo de endereço pra ser enviado a API
        this.localidadeAddress = {
          geoId: `${localidade.osm_type[0].toUpperCase()}${localidade.osm_id}`,
          cidade: localidade.address.city
            ? localidade.address.city
            : localidade.address.town
            ? localidade.address.town
            : null,
          pais: localidade.address.country
            ? localidade.address.country.replace("z", "s")
            : "",
          cep: localidade.address.postcode
            ? localidade.address.postcode
            : null,
          estado: localidade.address.state ? localidade.address.state : null,
          enderecoCompleto: localidade.display_name
            ? localidade.address.postcode
              ? this.handleReplacePostCode(
                  localidade.display_name,
                  localidade.address.postcode
                )
              : this.handleReplacePostCode(localidade.display_name)
            : this.formatToTemplateLocations(localidade),
          tipo: localidade.type || null
        };

        this.handleMountMap(localidade);
      }
    });
  }

  // FUNÇÃO PARA FORMATAR O DISPLAY NAME - ALTERAR O CEP COLOCANDO HIFEN
  handleReplacePostCode(displayName: string, postcode?: string): string {
    if (postcode) {
      const cepFormatted = postcode;

      const finalDisplayName = displayName.replace(postcode, cepFormatted);

      return finalDisplayName;
    } else {
      return displayName;
    }
  }

  handleMountMap(localidade): void {
    const [latmin, latmax, lonmin, lonmax] = localidade.boundingbox;

    // pegando a boundingBox da localidade selecionada
    this.latMin = latmin;
    this.latMax = lonmin;
    this.longMin = latmax;
    this.longMax = lonmax;

    const latitudeAvegare = this.calculateCenter(
      Number(latmax),
      Number(latmin)
    );

    const longitudeAvegare = this.calculateCenter(
      Number(lonmax),
      Number(lonmin)
    );

    const distance = this.calculateRadius(
      latitudeAvegare,
      latmax,
      longitudeAvegare,
      lonmax
    );

    // Chamada da função auxiliar para que seja formatado a string de melhor apresentação possível para o usuário
    // Evitando assim que "undefined" seja mostrado
    const finalLabelPlace = this.formatToTemplateLocations(localidade);

    // Atualizando o campo de address com a label formatada
    this.locationForm.get("address").setValue(finalLabelPlace);

    // Realizando o autocomplete dos demais campos, como latitude e longitude
    this.setarFormControlAutoComplete(localidade.lat, localidade.lon, distance);

    // Atualizando a posição da latitude e longitude No Serviço
    const currentUserLat = Number(this.locationForm.get("latitude").value);
    const currentUserLon = Number(this.locationForm.get("longitude").value);

    this.modalCreateUpdateService.saveBoundingBox(
      this.latMin,
      this.latMax,
      this.longMin,
      this.longMax
    );

    // Atualizando os dados no Serviço do componente Pai
    this.modalCreateUpdateService.saveCurrentLatAndLon(
      currentUserLat,
      currentUserLon
    );

    if (localidade.geojson.coordinates) {
      this.modalCreateUpdateService.savingGeoJsonCoordinates(
        localidade.geojson.coordinates
      );

      if (
        localidade.geojson &&
        (localidade.geojson.type === "Polygon" ||
          localidade.geojson.type === "Point" || localidade.geojson.type === "LineString")
      ) {
        this.modalCreateUpdateService.canCreateBounds(true, localidade.geojson.type);
      }
      else {
        this.notificatorService.showAlert(
          "Montagem do mapa",
          "Não foi possível criar as demarcações em volta da localidade"
        );
        this.modalCreateUpdateService.canCreateBounds(false);
      }
    }

    this.modalCreateUpdateService.setUpUpdateMap();
  }

  // verificar se o clique foi dentro do componente ou fora.
  @HostListener("focusin", ["$event"])
  @HostListener("document:click", ["$event"])
  onBlur(event: Event) {
    if (
      this.inputSelectLocationAddress &&
      this.inputSelectLocationAddress.nativeElement.contains(event.target)
    ) {
      this.isSearchResultOpenAddress = true;
    } else {
      this.isSearchResultOpenAddress = false;
    }
  }

  // Função responsável por buscar no nomiatim as possíveis localidades
  // que se encaixam no que o usuário está digitando
  async handleLoadLocationsByEditMode(locationToSearch: string) {
    // Renderiza o mapa de maneira default (quando existe a localidade cadastrada na api do nominatim)
    if (this.localidadeToUpdate?.endereco.geoId !== 'xxxx') {
      this.searchInput = locationToSearch ? locationToSearch : this.searchInput;

      this.searchInput = this.searchInput
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      this.localidadesNominatim = await this.localidadeService.listarLocalidadesComDetalhesAsync(this.searchInput);

      if (this.localidadesNominatim.length > 0) {
        this.localidadesNominatim.map((localidade) => {
          if (
            Number(localidade.lon) === this.localidadeToUpdate.longitude ||
            Number(localidade.lat) === this.localidadeToUpdate.latitude
          ) {
            this.handleMountMap(localidade);
          }
        });
      } else {
        this.notificatorService.showAlert(
          "Montagem do mapa",
          "Não foi possível demarcar a localidade"
        );
      }
    } else {
      // Renderiza o mapa custom
      this.leafletMapManager.updateInternals(
        this.localidadeToUpdate.latitude,
        this.localidadeToUpdate.longitude,
        this.localidadeToUpdate.raio
      );

      this.leafletMapManager.resolveMap();
    }

    // Montagem do corpo de endereço pra ser enviado a API
    this.localidadeAddress = {
      ...this.localidadeToUpdate.endereco,
    };

    this.locationForm
        .get("address")
        .setValue(
          this.localidadeToUpdate.endereco.enderecoCompleto
            ? this.localidadeToUpdate.endereco.enderecoCompleto
            : this.localidadeToUpdate.nome
        );
      this.locationForm
        .get("latitude")
        .setValue(this.localidadeToUpdate.latitude);
      this.locationForm
        .get("longitude")
        .setValue(this.localidadeToUpdate.longitude);
      this.locationForm.get("raio").setValue(this.handleConvertToMeters(`${this.localidadeToUpdate.raio}`));
  }

  handleChangeViewerOfForm() {
    this.modalCreateUpdateService.setCanShowManualForm();
  }

  refresh(field: string) {
    const requestObservable = this.localidadeService.listarLocalidadeById(String(this.whoId));
    requestObservable.subscribe((response) => {
      this.localidadeToUpdate = response;
      this.locationForm.get(field).setValue(this.localidadeToUpdate.nome);
      // MONTANDO O CAMPO DE ADDRESS
      this.handleLoadLocationsByEditMode(
        this.localidadeToUpdate.endereco.enderecoCompleto
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "whoId":
            switch (this.whoIsEditing) {
              case "FATHER":
                this.refresh("fatherLocation");
                break;
              case "CHILDREN":
                this.refresh("childrenLocation");
                break;
              default:
                break;
            }
        }
      }
    }
  }
}
