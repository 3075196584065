import { Component, Input, OnInit } from "@angular/core";
import {
  IAuditObservationsModels,
  IAuditObservationsResponse,
} from "../../modulos/auditoria-beta/interfaces";
import { AuditoriaBetaService } from "../../modulos/auditoria-beta/services/auditoria-beta.service";
import { adaptCollectionObservationsRespToModel } from "../../modulos/auditoria-beta/utils/parsers";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "app/modulos/auditoria-beta/constant/errors";

@Component({
  selector: "app-modal-observations",
  templateUrl: "./modal-observations.component.html",
  styleUrls: ["./modal-observations.component.scss"],
})
export class ModalObservationsComponent implements OnInit {
  constructor(
    private auditoriaService: AuditoriaBetaService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  @Input() collectionId: number = null;
  @Input() surveyId: number = null;
  @Input() allowComments: boolean = false;

  withFocus: boolean = false;

  publishMessage: string = "";
  observations = {} as IAuditObservationsModels;

  publishErrors = {
    maxlength: {
      value: false,
      message: "Limite de caractéres excedido",
    },
    empty: {
      value: false,
      message: "Campo obrigatório",
    },

    anyError(): boolean {
      return this.maxlength.value || this.empty.value;
    },

    getErrorMessage() {
      return (
        (this.maxlength.value && this.maxlength.message) || this.empty.message
      );
    },
  };

  isLoading: boolean = false;

  ngOnInit(): void {
    this.fetchObservations();
  }

  /**
   * Recebe a resposta do GET ou POST de observações e converte-a para a model
   * consumida no componente.
   *
   * Regra de negócio: Caso as observações tenham uma justificativa de rejeição,
   * dada a RN, essa resposta deve aparecer no topo do histórico de mensagens.
   */
  populateObservations(observationsResponse: IAuditObservationsResponse) {
    if (observationsResponse) {
      const observationsModel =
        adaptCollectionObservationsRespToModel(observationsResponse);

      if (observationsModel.justificativaRejeicao) {
        observationsModel.comentarios.unshift({
          ...observationsModel.justificativaRejeicao,
        });
      }

      this.observations = observationsModel;
    }
  }

  /**
   * Realiza a requisição de busca da lista de observações de uma coleta.
   */
  fetchObservations() {
    if (![this.surveyId, this.collectionId].includes(null)) {
      this.isLoading = true;

      const onError = (err) => {
        this.isLoading = false;

        const { title, message } = errorMessages.getObservations;

        this.errorHandlerService.handleError(err, title, message);
      };

      const onSuccess = () => {
        this.isLoading = false;
      };

      this.auditoriaService
        .getCollectionObservations({
          collectionId: this.collectionId,
          surveyId: this.surveyId,
        })
        .subscribe({
          next: this.populateObservations.bind(this),
          complete: onSuccess.bind(this),
          error: onError.bind(this),
        });
    }
  }

  /**
   * Recupera o id do auditor relativo à mensagem anterior, por ordem de
   * renderização no template.
   *
   * Utilidade: Esse método é utilizado para evitar que as "informações" do usuário
   * (foto de perfil e nome) sejam renderizadas múltiplas vezes.
   *
   */
  getPrevMessageAuditorId(currentMessageIndex: number) {
    const prevMessage = this.observations.comentarios[currentMessageIndex - 1];

    return !prevMessage ? null : prevMessage.auditor.id;
  }

  /**
   * Atribui os possíveis erros de usuário que podem acontecer
   * como:
   *
   * - O usuário passa do limite de caracteres de uma mensagem (255)
   * - O usuário tenta publicar uma mensagem vazia
   */
  checkPublishErrors(set: boolean = false) {
    if (set && !this.publishMessage.length) {
      this.publishErrors.empty.value = true;
    } else if (this.publishErrors.empty.value) {
      this.publishErrors.empty.value = false;
    }

    if (this.publishMessage.length > 255) {
      this.publishErrors.maxlength.value = true;
    } else if (this.publishErrors.maxlength.value) {
      this.publishErrors.maxlength.value = false;
    }

    return this.publishErrors.anyError();
  }

  handleMessageInput($event) {
    this.checkPublishErrors();
  }

  resetMessage() {
    this.publishMessage = "";
  }

  handlePublish() {
    // do request
    if (!this.checkPublishErrors(true)) {
      const payload = {
        conteudo: this.publishMessage,
      };

      const onError = (err) => {
        const { title, message } = errorMessages.updateObservations;

        this.errorHandlerService.handleError(err, title, message);
      };

      const onSuccess = () => {
        this.fetchObservations();
      };

      this.isLoading = true;

      this.auditoriaService
        .createCollectionObservations(
          { surveyId: this.surveyId, collectionId: this.collectionId },
          payload
        )
        .subscribe({
          complete: onSuccess.bind(this),
          error: onError.bind(this),
        });

      this.resetMessage();
    }
  }

  changeFocus() {
    this.withFocus = !this.withFocus;
  }
}
