/**
 * Enum contendo as Authorities disponpiveis
 * para os usuários do sistema
 *
 * @author Victor Hugo
 *
 */
/**
 * Enum contendo as Authorities disponpiveis
 * para os usuários do sistema
 *
 * @author Victor Hugo
 *
 */
export enum Authority {
  /**
   * ROLES COM ACESSO AO SISTEMA WEB
   */
  ROLE_FUNCIONARIO = 'ROLE_FUNCIONARIO',
  ROLE_ADMINISTRADOR = 'ROLE_ADMINISTRADOR',
  /**
   * Cadastro geral
   */
  CADASTRAR_INSTANCIA = 'CADASTRAR_INSTANCIA',

  // TODO: Essa authority existe? validar isso
  CADASTRAR_CONTRATANTE = 'CADASTRAR_CONTRATANTE',
  
  CADASTRAR_CLIENTE = 'CADASTRAR_CLIENTE',
  CADASTRAR_OPERADOR = 'CADASTRAR_OPERADOR',
  CADASTRAR_LOCALIDADE = 'CADASTRAR_LOCALIDADE',
  CADASTRAR_FUNCIONARIO = 'CADASTRAR_FUNCIONARIO',
  CADASTRAR_GRUPO = 'CADASTRAR_GRUPO',
  /**
   * Listagem geral
   */
  LISTAR_CLIENTE = 'LISTAR_CLIENTE',
  LISTAR_OPERADOR = 'LISTAR_OPERADOR',
  LISTAR_LOCALIDADE = 'LISTAR_LOCALIDADE',
  LISTAR_FUNCIONARIO = 'LISTAR_FUNCIONARIO',
  LISTAR_GRUPO = 'LISTAR_GRUPO',
  LISTAR_PESQUISA = 'LISTAR_PESQUISA',
  LISTAR_INSTANCIA = 'LISTAR_INSTANCIA',
  /**
   * Atualização geral
   */
  ATUALIZAR_CLIENTE = 'ATUALIZAR_CLIENTE',
  ATUALIZAR_OPERADOR = 'ATUALIZAR_OPERADOR',
  ATUALIZAR_LOCALIDADE = 'ATUALIZAR_LOCALIDADE',
  ATUALIZAR_FUNCIONARIO = 'ATUALIZAR_FUNCIONARIO',
  ATUALIZAR_GRUPO = 'ATUALIZAR_GRUPO',
  EDICAO_PERFIL_FUNCIONARIO = 'EDICAO_PERFIL_FUNCIONARIO',
  /**
   * Produtividade
   */
  CONTROLE_PRODUTIVIDADE = 'CONTROLE_PRODUTIVIDADE',
  /**
   * Cadastro Pesquisa
   */
  CADASTRAR_PESQUISA_COMPLETA = 'CADASTRAR_PESQUISA_COMPLETA',
  CADASTRAR_PESQUISA_CARACTERISTICAS = 'CADASTRAR_PESQUISA_CARACTERISTICAS',
  CADASTRAR_PESQUISA_QUESTIONARIO = 'CADASTRAR_PESQUISA_QUESTIONARIO',
  CADASTRAR_PESQUISA_LOCALIDADES = 'CADASTRAR_PESQUISA_LOCALIDADE',

  /**
   * Auditoria
   */
  AUDITORIA_PASSO_1 = 'AUDITORIA_PASSO_1',
  AUDITORIA_PASSO_2 = 'AUDITORIA_PASSO_2',
  /**
   * Resultado
   */
  ACESSO_RESULTADO = 'ACESSO_RESULTADO',
  /**
   * Mudança de status da pesquisa
   */
  MUDANCA_STATUS_PESQUISA = 'MUDANCA_STATUS_PESQUISA',
  /**
   * Remoção geral
   */
  REMOVER_CLIENTE = 'REMOVER_CLIENTE',
  REMOVER_OPERADOR = 'REMOVER_OPERADOR',
  REMOVER_LOCALIDADE = 'REMOVER_LOCALIDADE',
  REMOVER_FUNCIONARIO = 'REMOVER_FUNCIONARIO',
  REMOVER_GRUPO = 'REMOVER_GRUPO',
  REMOVER_PESQUISA = 'REMOVER_PESQUISA',


  /**
   * LGPD
   */
  CADASTRAR_DOC_LGPD = 'CADASTRAR_DOC_LGPD',

}
