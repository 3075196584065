<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<form class="operador-container" [formGroup]="form" (ngSubmit)="handleSubmit()">
  <section class="operador-section">
    <div class="header-options">
      <span class="operador-title">
        <i class="fa-regular fa-clipboard-user"></i>
        <h2>{{isEdicao? 'Editar' : 'Adicionar'}} entrevistador</h2>
      </span>

      <span class="operador-options" *ngIf="isEdicao">
        <div (click)="handleChangeEmail()">
          <i class="fa-regular fa-envelope"></i>
          <p>Alterar e-mail</p>
        </div>
      </span>
    </div>

    <main class="operador-wrapper">
      <div class="img-container">
        <app-new-foto-uploader
        [newSize]="photoSize"
      ></app-new-foto-uploader>
        <h4 *ngIf="this.isEdicao">Matrícula:</h4>
        <span *ngIf="this.isEdicao">{{ matricula }}</span>
      </div>

      <div class="section-wrapper">
        <section class="form-section">
          <div class="input-wrapper">
            <label>Nome do entrevistador*</label>
            <input
              type="text"
              [ngClass]="(form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)) ? 'inputs name-input error-input ' : 'inputs name-input'"
              formControlName="name"
              placeholder="Digite o nome do entrevistador"
              maxlength="71"
              mascara-primeiro-digito-sem-espaco
            />
            <span class="error-message" *ngIf="form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)">
              <span *ngIf="form.get('name')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('name')?.errors.maxlength">Limite máximo de 70 caracteres</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>E-mail*</label>
            <input
              type="email"
              [ngClass]="(form.get('email')?.invalid && (submitted || !form.get('email')?.value.length) &&
              (form.get('email')?.dirty || form.get('email')?.touched)) ? 'inputs email-input error-input ' : 'inputs email-input'"
              formControlName="email"
              placeholder="email@email.com"
              [pattern]="emailRegex"
              [attr.disabled]="isEdicao ? '' : null"
            />
            <span class="error-message" *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)">
              <span *ngIf="form.get('email')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('email')?.errors.pattern && submitted">Formato de e-mail inválido</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>CPF*</label>
            <input
              type="text"
              [ngClass]="(form.get('cpf')?.invalid && (submitted || !form.get('cpf')?.value.length) &&
              (form.get('cpf')?.dirty || form.get('cpf')?.touched)) ? 'inputs cpf-input error-input ' : 'inputs cpf-input'"
              formControlName="cpf"
              placeholder="000.000.000-00"
              maxlength="14"
              mascara-document
              [initialValue]="form.get('cpf').value"
              [attr.disabled]="isEdicao ? '' : null"
            />
            <span class="error-message" *ngIf="form.get('cpf')?.invalid && (form.get('cpf')?.dirty || form.get('cpf')?.touched)">
              <span *ngIf="form.get('cpf')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('cpf')?.errors && !form.get('cpf')?.errors.required && submitted">CPF inválido</span>
            </span>
          </div>
        </section>

        <section class="form-section">
          <div class="input-wrapper">
            <app-input-range-date
              *ngIf="isEdicao ? completeRequest : true"
              [label]="'Início do vínculo*'"
              [inputDate]="form.get('vinculoInicial')?.value"
              (onChange)="selectInitialDate($event)"
              [isDisabled]="isEdicao"
              [hasError]="form.get('vinculoInicial')?.invalid && (form.get('vinculoInicial')?.dirty ||
              form.get('vinculoInicial')?.touched) && (form.get('vinculoInicial')?.errors.required || form.get('vinculoInicial')?.errors.dateError)"
            ></app-input-range-date>
            <span class="error-message" *ngIf="form.get('vinculoInicial')?.invalid && (form.get('vinculoInicial')?.dirty || form.get('vinculoInicial')?.touched)">
              <span *ngIf="form.get('vinculoInicial')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('vinculoInicial')?.errors.dateError">Intervalo inválido</span>
            </span>
          </div>

          <div class="input-wrapper">
            <app-input-range-date
              *ngIf="isEdicao ? completeRequest : true"
              [label]="'Fim do vínculo*'"
              (onChange)="selectFinalDate($event)"
              [inputDate]="form.get('vinculoFinal')?.value"
              [hasError]="form.get('vinculoFinal')?.invalid && (form.get('vinculoFinal')?.dirty ||
              form.get('vinculoFinal')?.touched) && (form.get('vinculoFinal')?.errors.required || form.get('vinculoFinal')?.errors.dateError)"
            ></app-input-range-date>
            <span class="error-message" *ngIf="form.get('vinculoFinal')?.invalid && (form.get('vinculoFinal')?.dirty || form.get('vinculoFinal')?.touched)">
              <span *ngIf="form.get('vinculoFinal')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('vinculoFinal')?.errors.dateError">Intervalo inválido</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>Situação</label>
            <div
              #statusDropdown
              class="select status-select"
              (click)="toggleStatus()"
            >
              <div
                class="select-header"
                [ngClass]="statusIsOpen ? 'open-select' : ''"
              >
                <span class="selected-option">
                  <div
                    class="circle"
                    [ngClass]="
                      this.form.get('status')?.value
                        ? 'circle-active'
                        : 'circle-inactive'
                    "
                  ></div>
                  {{ this.form.get("status")?.value ? "Ativo" : "Inativo" }}
                </span>
                <div class="select-data-icon">
                  <span [class]="!statusIsOpen ? 'arrow' : 'arrow-rotate'"></span>
                </div>
              </div>
              <div class="options" *ngIf="statusIsOpen">
                <!--<hr class="row"/>-->
                <div
                  *ngIf="!this.form.get('status')?.value"
                  class="option"
                  (click)="handleStatusClick(true)"
                >
                  <span class="span-option">
                    <div class="circle circle-active"></div>
                    Ativo
                  </span>
                </div>
                <div
                  *ngIf="this.form.get('status')?.value"
                  class="option"
                  (click)="handleStatusClick(false)"
                >
                  <span class="span-option">
                    <div class="circle circle-inactive"></div>
                    Inativo
                  </span>
                </div>
              </div>
            </div>
            <input type="hidden" formControlName="status" />
          </div>
        </section>
      </div>
    </main>
  </section>

  <div class="footer-buttons">
    <button
      type="button"
      class="footer-cancel-button"
      (click)="handleClose()"
    >
      Cancelar
    </button>

    <button type="submit" class="footer-save-button">
      {{ isEdicao ? 'Salvar' : 'Adicionar' }}
    </button>
  </div>

</form>
