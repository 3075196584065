import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[mascara-primeiro-digito-sem-espaco]",
})
/**
 * Mascara que não permite que o primeiro digito de um
 * imput seja um espaço em branco ' '
 */
export class MascaraPrimeiroDigitoSemEspaco {
  @HostListener("input", ["$event"]) onInputChange(event) {
    const initialValue = event.target.value;
    setTimeout(() => {
      event.target.value = initialValue.replace(/^\ */g, "");

      if (initialValue !== event.target.value) {
        event.stopPropagation(); // Método impede que a propagação do mesmo evento seja chamada
      }
    }, 0);
  }
  // constructor(private ngControl: NgControl) {
  // }

  // onKeyUp(event): void {
  //   const newValue = event.target.value;
  //   const formattedValue = this.aplicarMascara(newValue);

  //   this.ngControl.control.patchValue(formattedValue);
  // }

  // aplicarMascara(valor: string): string {
  //   const stringFormatada = valor.replace(/^\ */g, '');
  //   return stringFormatada;
  // }
}
