<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<section>
  <app-loading *ngIf="!allRequestIsComplete"></app-loading>

  <article class="questionarie-header">
    <ng-container *ngIf="allRequestIsComplete">
      <h4>
        {{ headerModel.pesquisa }}
      </h4>
      <span>
        <h5>Operador</h5>
        <p>{{ headerModel.operador }}</p>
      </span>
      <span>
        <h5>ID da coleta</h5>
        <p>{{ headerModel.id }}</p>
      </span>
      <span>
        <h5>Localidade</h5>
        <p>{{ headerModel.localidade }}</p>
      </span>
      <span>
        <h5>Critérios</h5>
        <div class="badges">
          <small
            *ngFor="let criterio of headerCriterios; index as i"
            [style.backgroundColor]="criterio['backgroundColor']"
            [style.color]="criterio['color']"
          >
            {{ criterio["text"] }}
          </small>
        </div>
      </span>
    </ng-container>
  </article>
  <article class="questionarie-body">
    <header [ngClass]="scrollIsTop() ? '' : 'shadow-effect-top'">
      <h4>Questionário da coleta</h4>
      <ng-container *ngIf="allRequestIsComplete">
        <span>
          <button (click)="onRequestShowObservations()">
            <i class="fa-regular fa-message-lines"></i>Observações
          </button>
          <button *ngIf="!isOnlineCollection">
            <i class="fa-regular fa-location-dot"></i>Mapa da coleta
          </button>
        </span>
      </ng-container>
    </header>
    <div class="questions">
      <app-questions [questionList]="questionList"></app-questions>
    </div>
    <footer [ngClass]="scrollIsBottom() ? '' : 'shadow-effect-bottom'">
      <div class="footer-wrapper">
        <ng-container *ngIf="allRequestIsComplete && !isOnlineCollection">
          <app-audit-audio-player
            [audioToPlay]="auditAudio"
          ></app-audit-audio-player>
        </ng-container>
        <span class="btn-wrapper">
          <app-button
            textContent="Cancelar"
            btnStyle="outline"
            [btnStructure]="{
              padding: '15px 24px',
              fontSize: '16px'
            }"
            (clickEvent)="handleCancel()"
          ></app-button>
          <app-audit-verdict></app-audit-verdict>
        </span>
      </div>
    </footer>
  </article>
</section>

<app-prompt-modal *ngIf="this.modalData.show" [modalData]="modalData">
</app-prompt-modal>

<!-- Modal de observações -->
<app-prompt-modal
  *ngIf="observationsModal.show"
  [modalData]="observationsModal"
>
  <app-modal-observations
    [surveyId]="surveyId"
    [collectionId]="collectionId"
    [allowComments]="allowComments"
  ></app-modal-observations>
</app-prompt-modal>
