<form [formGroup]="locationForm" (ngSubmit)="onSubmit()" autocomplete="off" class="animate-up delay-2">
  <div *ngIf="!isChildrenLocation">
    <label [ngClass]="
        !locationForm.get('fatherLocation').valid &&
        locationForm.get('fatherLocation').touched &&
        'error-format'
      ">
      Nome da Localidade pai
    </label>
    <input [ngClass]="
        !locationForm.get('fatherLocation').valid &&
        locationForm.get('fatherLocation').touched &&
        'error-format'
      " class="just-input" type="text" placeholder="Informe um nome para a localidade" id="fatherLocation"
      formControlName="fatherLocation" />
    <span class="error-input" *ngIf="
        !locationForm.get('fatherLocation').valid &&
        locationForm.get('fatherLocation').touched
      ">
      Campo obrigatório
    </span>
  </div>
  <div class="form-input" *ngIf="isChildrenLocation">
    <label [ngClass]="
        !locationForm.get('childrenLocation').valid &&
        locationForm.get('childrenLocation').touched &&
        'error-format'
      ">
      Nome da Localidade filha
    </label>
    <input [ngClass]="
        !locationForm.get('childrenLocation').valid &&
        locationForm.get('childrenLocation').touched &&
        'error-format'
      " class="just-input" type="text" placeholder="Informe um nome para a localidade" id="childrenLocation"
      formControlName="childrenLocation" />
    <span class="error-input" *ngIf="
        !locationForm.get('childrenLocation').valid &&
        locationForm.get('childrenLocation').touched
      ">
      Campo obrigatório
    </span>
  </div>
  <div class="search-box" [ngClass]="isSearchResultOpenAddress && 'position-transition'">
    <label [ngClass]="
        !locationForm.get('address').valid &&
        locationForm.get('address').touched &&
        'error-format'
      ">
      Endereço
    </label>
    <div #selectinputaddress class="search-box-content" [ngClass]="
        !locationForm.get('address').valid &&
        locationForm.get('address').touched &&
        'error-format'
      ">
      <div class="input">
        <input type="text" placeholder="Busque o endereço da localidade" class="input-fake" id="address"
          formControlName="address" (input)="handleLoadLocationsByInput($event)" />

        <div class="box-search" *ngIf="isSearchResultOpenAddress">
          <i class="fa fa-search" *ngIf="!isLoadingLocations"></i>
          <div class="loader" *ngIf="isLoadingLocations"></div>
        </div>
      </div>
      <div class="search-result" *ngIf="isSearchResultOpenAddress">
        <span>{{
          isSearchResultOpenAddress && localidadesNominatim.length > 0
          ? "Resultados"
          : "Localidade não encontrada"
          }}</span>
        <button *ngIf="
            isSearchResultOpenAddress &&
            localidadesNominatim.length === 0 &&
            searchInput !== ''
          " class="add-new-location-button" type="button" (click)="handleChangeViewerOfForm()">
          <span>Criar uma localidade personalizada</span>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <ul>
          <li *ngFor="let localidade of localidadesNominatim"
            (click)="handleSelectLocation(localidade.place_id, localidade)" [ngClass]="
              localidade.place_id === localidadeSelectedById &&
              'location-selected'
            ">
            <span [title]="formatToTemplateLocations(localidade)">
              {{ formatToTemplateLocations(localidade) }}
            </span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </li>
          <button *ngIf="localidadesNominatim.length > 0" class="add-new-location-button" type="button"
            (click)="handleChangeViewerOfForm()">
            <span>Criar uma localidade personalizada</span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
        </ul>
      </div>
    </div>
    <span class="error-input" *ngIf="
        !locationForm.get('address').valid &&
        locationForm.get('address').touched &&
        !isSearchResultOpenAddress
      ">
      Campo obrigatório
    </span>
  </div>

  <div class="form-group">
    <div class="form-input">
      <label [ngClass]="
          !locationForm.get('latitude').valid &&
          locationForm.get('latitude').touched &&
          'error-format'
        ">
        Latitude
      </label>
      <input [ngClass]="
          !locationForm.get('latitude').valid &&
          locationForm.get('latitude').touched &&
          'error-format'
        " class="just-input" type="text" placeholder="-0.0000000" id="latitude" formControlName="latitude" />
      <span class="error-input" *ngIf="
          !locationForm.get('latitude').valid &&
          locationForm.get('latitude').touched
        ">
        Campo obrigatório
      </span>
    </div>
    <div class="form-input">
      <label [ngClass]="
          !locationForm.get('longitude').valid &&
          locationForm.get('longitude').touched &&
          'error-format'
        ">
        Longitude
      </label>
      <input [ngClass]="
          !locationForm.get('longitude').valid &&
          locationForm.get('longitude').touched &&
          'error-format'
        " class="just-input" type="text" placeholder="-00.0000000" id="longitude" formControlName="longitude" />
      <span class="error-input" *ngIf="
          !locationForm.get('longitude').valid &&
          locationForm.get('longitude').touched
        ">
        Campo obrigatório
      </span>
    </div>
  </div>

  <label>Raio</label>
  <input [ngClass]="
      !locationForm.get('raio').valid &&
      locationForm.get('raio').touched &&
      'error-format'
    " type="text" placeholder="Informe o raio a ser considerado" class="input-radio just-input" id="raio"
    formControlName="raio" />
  <span class="error-input" *ngIf="!locationForm.get('raio').valid && locationForm.get('raio').touched">
    Campo obrigatório
  </span>
  <div class="group-action-buttons">
    <button class="buttonSalvar" type="submit" [disabled]="!formIsValid()">
      Salvar
    </button>
    <button class="buttonCancel" type="button" (click)="handleCancelUpdate()" *ngIf="canShowCancelFormButton()">
      Cancelar
    </button>
  </div>
</form>
