import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { PlatformLocation } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { filter, map, pairwise } from "rxjs/operators";
import { PesquisaItem } from "../pesquisa-old/pesquisa-select/pesquisaItem";
import { PesquisaSelecao } from "../pesquisa-old/pesquisaSelecao";
import { PesquisaService } from "../pesquisa-old/servico/pesquisa.service";
import { TipoPasso } from "./tipoPasso";

@Component({
  selector: "app-auditoria",
  templateUrl: "./auditoria.component.html",
  styleUrls: ["./auditoria.component.scss"],
})
export class AuditoriaComponent implements OnInit, AfterViewInit {
  idPesquisa: number | null;
  pesquisaSelecionada: PesquisaItem;
  public passosLinear = false;

  @ViewChild("stepper")
  public stepper: MatStepper;

  @ViewChild("passoRespostasAbertas")
  public passoRespostasAbertas: MatStep;

  @ViewChild("passoRespostasFechadas")
  public passoRespostasFechadas: MatStep;

  public tipo = TipoPasso;

  public novaPesquisaSelecionadaSubject: Subject<PesquisaItem> =
    new ReplaySubject();
  public navigationSubscription: Subscription;

  public renderRespostasAbertas = false;
  public renderRespostasFechadas = false;

  public RESPOSTAS_ABERTAS_INDEX = 0;
  public RESPOSTAS_FECHADAS_INDEX = 1;
  public selectedStepIndex: number = 0;

  public paginacaoAtual: number = 0;

  public previousUrl: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pesquisaService: PesquisaService,
    private securityService: SecurityService,
    private location: PlatformLocation,
    private modalService: ModalService
  ) {}

  /**
   * implementação de ngOnInit.
   *
   * recuperando o idPesquisa da URL da rota atual e setando-a
   * como atributo do componente, tornando-a mais acessível.
   */
  ngOnInit() {
    const idPesquisa = this.getIdPesquisaFromRoute();
    this.idPesquisa = Number(idPesquisa);

    if (this.idPesquisa) {
      this.getPesquisaSelected().subscribe((p) => {
        this.pesquisaSelecionada = p;
      });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        this.previousUrl = e[0].urlAfterRedirects;
      });

    this.route.queryParams.subscribe((queryParams) => {
      const urlPaginacaoAtual = Number(queryParams.pagina) - 1;
      // tslint:disable-next-line: max-line-length
      if (this.paginacaoAtual !== urlPaginacaoAtual) {
        this.paginacaoAtual = urlPaginacaoAtual;
      }
    });

    this.renderRespostasAbertas = this.renderEtapa1();
    this.renderRespostasFechadas = this.renderEtapa2();

    const url = this.getCurrentUrlWithoutParams();

    if (this.renderRespostasAbertas && this.renderRespostasFechadas) {
      if (url.endsWith(TipoPasso.RESPOSTAS_ABERTAS)) {
        this.selectedStepIndex = this.RESPOSTAS_ABERTAS_INDEX;
      } else if (url.endsWith(TipoPasso.RESPOSTAS_FECHADAS)) {
        this.selectedStepIndex = this.RESPOSTAS_FECHADAS_INDEX;
      }
    } else {
      this.selectedStepIndex = this.RESPOSTAS_ABERTAS_INDEX;
    }

    this.location.onPopState(() => {
      const beforeBackUrl = this.getUrlWithoutParams(this.router.url);
      const previousRoute = this.getPreviousRoute();

      if (this.sameRoute(this.router.url, previousRoute)) {
        this.router.navigateByUrl(previousRoute);
        return;
      }

      if (beforeBackUrl.endsWith(TipoPasso.RESPOSTAS_ABERTAS)) {
        this.selectedStepIndex = this.RESPOSTAS_FECHADAS_INDEX;
        this.passoRespostasFechadas.select();
      } else if (beforeBackUrl.endsWith(TipoPasso.RESPOSTAS_FECHADAS)) {
        this.selectedStepIndex = this.RESPOSTAS_ABERTAS_INDEX;
        this.passoRespostasAbertas.select();
      }
    });
  }

  private getPreviousRoute() {
    return location.href.replace(location.origin, "");
  }

  private sameRoute(firstUrl: string, secondUrl: string) {
    const firstUrlWithoutParams = this.getUrlWithoutParams(firstUrl);
    const secondUrlWithoutParams = this.getUrlWithoutParams(secondUrl);

    return firstUrlWithoutParams === secondUrlWithoutParams;
  }

  private getPesquisaSelected(): Observable<PesquisaItem> {
    return this.pesquisaService.getPesquisasSelecao().pipe(
      map((pesquisas) => {
        // tslint:disable-next-line: max-line-length
        const pesquisaSelectedIndex = pesquisas.findIndex(
          (p: PesquisaSelecao) => p.id === this.idPesquisa
        );
        return pesquisas[pesquisaSelectedIndex];
      })
    );
  }

  ngAfterViewInit() {
    if (this.idPesquisa) {
      this.stepper.selectionChange.subscribe((ev: StepperSelectionEvent) => {
        this.onPassoSelecionadoAtualizarRota(ev);
      });
    }
  }

  renderBoth() {
    return this.renderEtapa1() && this.renderEtapa2();
  }

  /**
   * Callback chamado quando o usuário seleciona manualmente um passo.
   * Este callback serve para atualizar a URL, inserindo um queryParam referenciando
   * um identificador para o passo selecionado.
   *
   * @param ev evento de seleção do passo
   */
  onPassoSelecionadoAtualizarRota(ev: StepperSelectionEvent) {
    if (ev.selectedStep === this.passoRespostasAbertas) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`./${TipoPasso.RESPOSTAS_ABERTAS}`], {
        relativeTo: this.route,
      });
      return;
    }

    if (ev.selectedStep === this.passoRespostasFechadas) {
      this.router.navigate([`./${TipoPasso.RESPOSTAS_FECHADAS}`], {
        relativeTo: this.route,
        queryParams: { pagina: "1" },
      });
    }
    // tslint:disable-next-line: max-line-length
  }

  getIdPesquisaFromRoute() {
    const idPesquisa: string = this.route.snapshot.paramMap.get("idPesquisa");
    return idPesquisa;
  }

  /**
   * Callback chamado sempre que uma pesquisa for selecionada.
   * @param pesquisaSelectEvent evento de seleção de pesquisa.
   */
  onPesquisaSelect(pesquisaSelectEvent: any) {
    this.pesquisaSelecionada = pesquisaSelectEvent.payload;

    if (!this.pesquisaSelecionada.id) {
      return;
    }

    if (this.renderEtapa1()) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([
        "auditoria/pesquisas/",
        this.pesquisaSelecionada.id,
        TipoPasso.RESPOSTAS_ABERTAS,
      ]);
    } else {
      // tslint:disable-next-line: max-line-length
      this.router.navigate(
        [
          "auditoria/pesquisas/",
          this.pesquisaSelecionada.id,
          TipoPasso.RESPOSTAS_FECHADAS,
        ],
        { queryParams: { page: "1" } }
      );
    }

    this.novaPesquisaSelecionadaSubject.next(this.pesquisaSelecionada);
  }

  /**
   * Retorna a rota corrente sem parametros de busca
   */
  getCurrentUrlWithoutParams(): string {
    const url = this.router.url;
    return this.getUrlWithoutParams(url);
  }

  getUrlWithoutParams(url: string): string {
    const urlTree = this.router.parseUrl(url);
    const urlWithoutParams = urlTree.root.children["primary"].segments
      .map((it) => it.path)
      .join("/");
    return urlWithoutParams;
  }

  /**
   * Verifica se este componente deveria renderizar a etapa 1 de auditoria,
   * baseado nas permissões do usuário;
   */
  renderEtapa1() {
    const renderEtapa1 = this.securityService.userHasAuthorities([
      Authority.AUDITORIA_PASSO_1,
    ]);
    return renderEtapa1;
  }

  /**
   * Verifica se este componente deveria renderizar a etapa 2 de auditoria,
   * baseado nas permissões do usuário;
   */
  renderEtapa2() {
    const renderEtapa2 = this.securityService.userHasAuthorities([
      Authority.AUDITORIA_PASSO_2,
    ]);
    return renderEtapa2;
  }

  atualizarURLPaginacao(dadosPaginacao: DadosPaginacao) {
    if (this.stepper.selectedIndex === this.RESPOSTAS_FECHADAS_INDEX) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`./${TipoPasso.RESPOSTAS_FECHADAS}`], {
        relativeTo: this.route,
        queryParams: { pagina: dadosPaginacao.page + 1 },
      });
    }
  }

  async canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.modalService.showModal({
        title: "Sair da tela de auditoria",
        messageModal: "Deseja sair da tela de auditoria?",
        btnTitlePositive: "Sim",
        btnTitleNegative: "Não",
        icon: "fa-light fa-circle-info",
        positiveCallback: () => resolve(true),
        negativeCallback: () => resolve(false),
      });
    });
  }
}
