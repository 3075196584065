import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { PesquisaService } from "../servico/pesquisa.service";
import {
  StatusChangePayload,
  StatusChangeService,
} from "../status/statusChangeService";
import {
  StatusPesquisa,
  StatusPesquisaNomeAmigavel,
} from "../status/statusPesquisa";
import { EventoInput } from "./inputs/evento/eventoInput";
import { TipoEventoInput } from "./inputs/evento/tipoEventoInput";
import { PesquisaCadastro } from "./model/pesquisaCadastro";
import { Vinculo } from "./model/vinculo";
import { Localidade } from "./passos/gerenciador-cotas/localidades/localidade";
import { LocalidadesService } from "./passos/gerenciador-cotas/localidades/localidades.service";
import { pesquisaToMarcacoesFormGroup } from "./passos/marcacoes/pesquisaToMarcacoesFormGroup";
import { AlterarStatusPesquisa } from "./store/actions/caracteristicasAction";
import {
  BloquearPassosCadastro,
  CleanStore,
  DefinirEstadoDeValidacao,
  DefinirExibicaoValidacoes,
  DefinirPassoAtual,
  DefinirPesquisa,
  ExibirValidacoesRascunho,
  PrepararParaSalvar,
  SetPreparadoParaSalvar,
} from "./store/actions/controleGeralAction";
import { Selecionar } from "./store/actions/questionarioAction";
import { CarregarLocalidadesVinculos } from "./store/actions/vinculosAction";
import {
  CadastroPesquisaStoreState,
  EstadoValidacao,
} from "./store/cadastroPesquisaStoreState";
import { ResolucaoConflitoService } from "./store/conflito/resolucaoConflitoService";
import { CarregarLocalidadesAction } from "./store/gerenciadorCotas/localidades/carregarLocalidadesAction";
import { mapaPassoCadastroNumber, PassoCadastro } from "./store/passoCadastro";
import { LocalidadesVinculos } from "./store/vinculos/model";

@Component({
  selector: "app-pesquisa-cadastro",
  templateUrl: "./pesquisa.cadastro.component.html",
  styleUrls: ["./pesquisa.cadastro.component.scss"],
})
export class PesquisaCadastroComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  // variavel que oferece acesso facilitado aos controls
  formGroup: UntypedFormGroup;

  /**
   * Referencia para o ultimo estado valido da pesquisa,
   * ele sempre será atualizado junto com a store
   */
  pequisaInstace: PesquisaCadastro;

  controls: { [key: string]: AbstractControl } = {};

  /**
   * FormGroup que marcam se um passo está
   * concluído
   */
  caracteristicasStep: UntypedFormGroup;
  marcacoesStep: UntypedFormGroup;
  localidadesStep: UntypedFormGroup;
  questionarioStep: UntypedFormGroup;

  /**
   * Referencia para a ultima instancia da store
   * que foi gerada por uma action. Por hora está
   * sendo útil visto que ainda n foi implementado
   * imutabilidade e uso adequado de 'select' da RxStore
   */
  lastStore: CadastroPesquisaStoreState = new CadastroPesquisaStoreState();

  mapaPassoFormGroup: Map<PassoCadastro, UntypedFormGroup> = new Map();

  /**
   * Marcação para decidir se o botão de 'Próximo' ou 'Salvar'
   * será exibido. Quando se está no ultimo passo, entao devemos
   * salvar a pesquisa, caso contrário, devemos ir para o próximo
   * passo
   */
  exibirOpcaoSalvar: boolean = false;
  /**
   * Marcação que indica se o loading da pagina esta ativo
   */
  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  /**
   * Marcacao para saber se a pesquisa esta
   * sendo salva ou nao
   */
  salvando: boolean = false;

  /**
   * Marcação se a pesquisa está sendo editada
   */
  isEdicao: boolean = false;

  tituloPesquisa: string = "adicionar pesquisas";

  /**
   * Armazena o estado de validacao atual da pesquisa
   */
  estadoValidacao: EstadoValidacao;
  /**
   * Armazena qual o status original da pesquisa
   */
  statusOriginalPesquisa = StatusPesquisa.RASCUNHO;

  // tslint:disable-next-line: max-line-length
  subjectChangeStatus: BehaviorSubject<StatusPesquisa> =
    new BehaviorSubject<StatusPesquisa>(undefined);

  /**
   * corpo de um erro customizado que pode ocorrer durante o cadastro da pesquisa.
   */
  customError: any;

  /**
   * Marcador que indica se um salvamento automatico da pesquisa esta ocorrendo
   */
  isOcorrendoSalvamentoAutomatico: boolean;

  salvarPasso3: boolean;

  // securityService: SecurityService;

  // tslint:disable-next-line: max-line-length
  constructor(
    private location: Location,
    private resolucaoConflitoSercice: ResolucaoConflitoService,
    private statusChangeService: StatusChangeService,
    protected store: Store<CadastroPesquisaStoreState>,
    private pesquisaService: PesquisaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private localidadeService: LocalidadesService,
    protected securityService: SecurityService
  ) {}

  /**
   * @override
   */
  ngOnInit() {
    /**
     * Quando o componente é criado, garantimos que não há nenhuma alteração
     * armazenada
     */
    this.resolucaoConflitoSercice.limparAlteracoes();
    const permissoes = this.getAuthenticatedUserAuthorities();
    this.exibirOpcaoSalvar =
      permissoes.includes(Authority.CADASTRAR_PESQUISA_CARACTERISTICAS) &&
      !permissoes.includes(Authority.CADASTRAR_PESQUISA_COMPLETA);
    this.salvarPasso3 = false;
    this.activatedRoute.url.subscribe((urlSegment) => {
      if (urlSegment && urlSegment.length <= 0) {
        return;
      }

      if (urlSegment[0].path !== "atualizar") {
        return;
      }

      // verificando se trata-se de uma edicao e nao cadastro
      const activeRouteSubscription =
        this.activatedRoute.queryParamMap.subscribe((paramMap) => {
          const idPesquisa = paramMap.get("id");

          this.isEdicao =
            idPesquisa === null || idPesquisa === undefined ? false : true;
          if (this.isEdicao) {
            this.isLoading = true;

            this.tituloPesquisa = "alterar pesquisas";

            const buscarEditarSubscription = this.pesquisaService
              .buscarEditar(idPesquisa)
              .subscribe({
                next: (pesquisa: PesquisaCadastro) => {
                  /**
                   * Definindo a pesquisa atual
                   */
                  this.store.dispatch(new DefinirPesquisa(pesquisa));
                  /**
                   * Como a estrutura de dados do back-end(tree)
                   * difere da estrutura do front-end(flat),
                   * precisamos fazer esse parser
                   */
                  // tslint:disable-next-line: max-line-length
                  this.store.dispatch(
                    new CarregarLocalidadesVinculos({
                      vinculos: <Vinculo[]>pesquisa.vinculos,
                    })
                  );
                  this.isLoading = false;
                  // tslint:disable-next-line: align
                },
                error: () => {},
              });

            this.subscriptions.push(buscarEditarSubscription);
          }
        });
      this.subscriptions.push(activeRouteSubscription);
    });

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> =
      this.store.pipe(
        map((x) => x["cadastroPesquisa"]),
        map((pesquisa) =>
          pesquisa ? pesquisa : new CadastroPesquisaStoreState()
        )
      );

    const storeSubscription = pesquisaStoreObservable.subscribe((store) => {
      this.lastStore = store;
      const prepararParaSalvar = store.dadosGeraisCadastro.prepararParaSalvar;

      /**
       * Verificando se tudo esta pronto para salvar a pesquisa
       */

      const dadosPasso = Array.from(store.dadosPasso.values());
      const passosProntos = !dadosPasso.some(
        (dadoPasso) => !dadoPasso.preparadoParaSalvar
      );

      if (prepararParaSalvar && passosProntos && !this.salvando) {
        this.salvarPesquisa();
      }

      /**
       * Quando houver mudanças no store, recupere o
       * estado atual do formGroup do passo 4 do cadastro de pesquisa.
       */
      this.localidadesStep = this.getLocalidadesFormGroup(store);
      this.mapaPassoFormGroup.set(
        PassoCadastro.LOCALIDADES,
        this.localidadesStep
      );

      // tslint:disable-next-line: max-line-length
      this.statusOriginalPesquisa = store.dadosPasso.get(
        PassoCadastro.CARACTERISTICAS
      ).statusOriginal;

      console.log("verificando status pesquisa", store.pesquisa.status);

      // subject responsável por notificar a quem estiver escrito, a mudança de status
      this.subjectChangeStatus.next(store.pesquisa.status);
    });
    this.subscriptions.push(storeSubscription);

    const pesquisaSubscription = pesquisaStoreObservable
      .pipe(select("pesquisa"))
      .subscribe((pesquisa) => {
        this.pequisaInstace = pesquisa;
        // console.log('[MUDOU] pesquisaInstance: ', this.pequisaInstace);
        this.initFormGroup(this.lastStore);
        this.validateStatusChange();
        this.bloquearPassosBaseadoNoStatus(pesquisa);
      });

    this.subscriptions.push(pesquisaSubscription);

    this.initFormGroup(this.lastStore);
  }

  solicitarExibicaoErrosPassoAndAvancarPasso() {
    this.solicitarExibicaoErrosPasso();
    this.avancarPasso();
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    /**
     * Removendo as alteracoes armazenadas ate entao
     */
    this.resolucaoConflitoSercice.limparAlteracoes();

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.store.dispatch(new CleanStore());
    this.prepararParaSalvar(false);
  }

  /**
   * Metodo que inicializa o formGroup da pesquisa e a
   * divide entre os formGroups que controlam os
   * acessos aos passos do cadastro
   */
  initFormGroup(pesquisaCadastroStoreState: CadastroPesquisaStoreState) {
    const pesquisaCadastro = pesquisaCadastroStoreState.pesquisa;
    this.formGroup = PesquisaCadastro.getInitializedControl(pesquisaCadastro);
    this.controls = this.formGroup.controls;

    this.buildCaracteristicasFg();
    this.buildQuestionarioFg();
    this.buildMarcacoesFg(pesquisaCadastro);
    this.localidadesStep = this.getLocalidadesFormGroup(
      pesquisaCadastroStoreState
    );

    this.mapaPassoFormGroup.set(
      PassoCadastro.CARACTERISTICAS,
      this.caracteristicasStep
    );
    this.mapaPassoFormGroup.set(
      PassoCadastro.QUESTIONARIO,
      this.questionarioStep
    );
    this.mapaPassoFormGroup.set(PassoCadastro.MARCACOES, this.marcacoesStep);

    /**
     * Atualizando a store com o estado de validacao da pesquisa geral
     * bem como de seus passos individuais
     */
    // tslint:disable-next-line: max-line-length
    const allValid =
      this.caracteristicasStep.valid &&
      this.questionarioStep.valid &&
      this.marcacoesStep.valid &&
      this.localidadesStep.valid;
    // tslint:disable-next-line: max-line-length
    this.estadoValidacao = new EstadoValidacao(
      allValid,
      this.caracteristicasStep.valid,
      this.questionarioStep.valid,
      this.marcacoesStep.valid,
      this.localidadesStep.valid,
      false,
      false
    );
    this.store.dispatch(new DefinirEstadoDeValidacao(this.estadoValidacao));
  }

  /**
   * Constroi o formGroup do passo de questionario
   */
  buildQuestionarioFg() {
    /**
     * QuestionarioFg
     */
    const descricaoPesquisaFg = <UntypedFormGroup>(
      this.controls.descricaoPesquisa
    );
    const textoInicialControl = this.controls.textoInicial;

    /**
     * Desabilitando marcacoes no questionario
     */
    // tslint:disable-next-line: max-line-length
    const elementosQuestionariFormArray = <UntypedFormArray>(
      this.controls.elementosQuestionario
    );
    // tslint:disable-next-line: max-line-length
    const secoesFg: UntypedFormGroup[] =
      elementosQuestionariFormArray.controls.map(
        (elementoQuestionarioFg: UntypedFormGroup) => {
          return <UntypedFormGroup>elementoQuestionarioFg.controls.secao;
        }
      );

    /**
     * Ativando validação de ter no mínimo uma pergunta por seção.
     */
    /*secoesFg.forEach((secaoFg: FormGroup) => {
      secaoFg.controls.elementosSecao.setValidators([Validators.required]);
      secaoFg.controls.elementosSecao.updateValueAndValidity();
    });*/

    const perguntasFgMatriz = secoesFg.map((secaoFg: UntypedFormGroup) => {
      const elementosSecaoFormArray = <UntypedFormArray>(
        secaoFg.controls.elementosSecao
      );
      const perguntasFg = elementosSecaoFormArray.controls.map(
        (elementoSecao: UntypedFormGroup) => {
          return <UntypedFormGroup>elementoSecao.controls.pergunta;
        }
      );
      return perguntasFg;
    });
    const perguntasFgArray: UntypedFormGroup[] = [].concat(
      ...perguntasFgMatriz
    );

    perguntasFgArray.forEach((perguntaFg) => {
      const alternativasFormArray = <UntypedFormArray>(
        perguntaFg.controls.alternativas
      );
      alternativasFormArray.controls.forEach(
        (alternativaFg: UntypedFormGroup) => {
          const marcacaoFg = alternativaFg.controls.marcacao;
          if (marcacaoFg) {
            marcacaoFg.disable();
          }
        }
      );
    });

    this.questionarioStep = new UntypedFormGroup({
      textoInicial: textoInicialControl,
      elementosQuestionario: this.controls.elementosQuestionario,
      titulo: descricaoPesquisaFg.controls.titulo,
      tituloCurto: descricaoPesquisaFg.controls.tituloCurto,
    });

    /**
     * Ativando validação de ter no mínimo uma seção no questionário.
     */
    // this.questionarioStep.controls.elementosQuestionario.setValidators([Validators.required]);
    // this.questionarioStep.controls.elementosQuestionario.updateValueAndValidity();

    this.questionarioStep.updateValueAndValidity();
  }

  /**
   * Constroi o formGroup do passo de marcacoes
   */
  buildMarcacoesFg(pesquisa: PesquisaCadastro) {
    this.marcacoesStep = pesquisaToMarcacoesFormGroup(pesquisa);
  }

  /**
   * Constroi o formGroup do passo de caracteristicas
   */
  buildCaracteristicasFg() {
    this.caracteristicasStep = new UntypedFormGroup({
      cliente: this.controls.cliente,
      descricaoPesquisa: this.controls.descricaoPesquisa,
      configuracaoPesquisa: this.controls.configuracaoPesquisa,
      analisePesquisa: this.controls.analisePesquisa,
    });
  }

  /**
   * Solicita exibição de erros para o publisher
   * selecionado
   */
  solicitarExibicaoErros(publisherSelecionado: Subject<EventoInput>) {
    const evento = new EventoInput(TipoEventoInput.SHOW_ERRORS, true);
    publisherSelecionado.next(evento);
  }

  verificaDisponibilidadeDoPasso4(): boolean {
    return !(
      this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0"
    );
  }

  /**
   * Tenta avançar o step para o proximo passo
   */
  avancarPasso() {
    /**
     * Resetando a seleção
     */
    this.store.dispatch(new Selecionar(null, null));

    /**
     * Verificando se foi possível avançar para o próximo passo,
     * se o meu currentStep for igual ao passo atual,
     * então nao foi possivel avançar, logo, o passo está
     * inválido
     */
    this.stepper.next();
    if (this.formGroup) {
      this.salvarPasso3 =
        this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0" &&
        this.stepper.selectedIndex === 2;
    }
  }

  /**
   * Tenta avançar o step para o passo anterior
   */
  voltarPasso() {
    /**
     * Resetando a seleção
     */
    this.store.dispatch(new Selecionar(null, null));
    const stepToGo = this.stepper.selectedIndex - 1;
    if (this.formGroup) {
      this.salvarPasso3 =
        this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0" &&
        stepToGo === 2;
    }
    if (stepToGo >= 0) {
      this.stepper.selectedIndex = stepToGo;
    }
  }

  /**
   * Solicita que o passo atual exiba
   * as mensagens de validações contidas
   * em seus inputs
   * As mensagens de validação so serão ativadas caso o usuário avançe para o próximo passo,
   * caso contrário a navegação é permitida para passos anteriores sem gerar a mensagem
   * @param indexPassoSelecionado indice do step ao ser clicado
   */
  solicitarExibicaoErrosPasso(indexPassoSelecionado?: number) {
    const passoAtual = mapaPassoCadastroNumber.get(this.stepper.selectedIndex);
    this.salvarPasso3 =
      this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0" &&
      indexPassoSelecionado === 2;
    if (
      indexPassoSelecionado > this.stepper.selectedIndex ||
      indexPassoSelecionado === undefined
    ) {
      if (passoAtual !== PassoCadastro.LOCALIDADES) {
        this.store.dispatch(new DefinirExibicaoValidacoes(true, passoAtual));

        const formGroupPassoAtual = this.mapaPassoFormGroup.get(passoAtual);
        if (formGroupPassoAtual.invalid) {
          // tslint:disable-next-line: max-line-length
          this.toastr.error(
            "Este passo contém erros que devem ser corrigidos antes de avançar",
            "Problema no passo atual!",
            {
              positionClass: "toast-bottom-right",
            }
          );
        }
      }
    }
  }

  /**
   * retorna o ultimo passo
   *
   * OBS: Neste momento o ultimo passo é LOCALIDADES
   * por quê a funcionalidade de GERENCIAMENTO DE COTAS
   * não entra na release BASIC
   */
  getUltimoPasso() {
    return PassoCadastro.LOCALIDADES;
  }

  /**
   * Evento disparado quando o passo selecionado muda
   */
  atualizarPosicaoStep(evento: StepperSelectionEvent) {
    /**
     * resetando o item selecionado
     */
    this.store.dispatch(new Selecionar(null, null));

    const passoAtual = mapaPassoCadastroNumber.get(evento.selectedIndex);
    this.store.dispatch(new DefinirPassoAtual(passoAtual));

    /**
     * Atualizando qual dos botões de footer deve ser exibido
     */
    this.exibirOpcaoSalvar = passoAtual === this.getUltimoPasso();
    this.salvamentoAutomatico(passoAtual);
  }

  /**
   * Verifica se o passo atual necessita de salvamento automatico
   * da pesquisa e o faz.
   */
  salvamentoAutomatico(passoAtual: PassoCadastro): any {
    const passosNecessitamSalvamento = [PassoCadastro.GERENCIADOR_COTAS];

    if (passosNecessitamSalvamento.includes(passoAtual)) {
      this.isOcorrendoSalvamentoAutomatico = true;
      this.prepararParaSalvar(true, false);
    }
  }

  showNotificationSuccess() {
    const operacao = this.isEdicao ? "Alterar" : "Salvar";
    // tslint:disable-next-line:max-line-length
    const alertaDataTitleDialog = `${operacao} pesquisa`;
    // tslint:disable-next-line: max-line-length
    this.toastr.success(
      "Operação concluída com sucesso!",
      `${alertaDataTitleDialog}`,
      {
        positionClass: "toast-bottom-right",
      }
    );
  }
  /**
   * Estee método não é chamado diretamente, ele será
   * executado quando todos os passos estão preparados
   * para salvar
   */
  salvarPesquisa() {
    this.salvando = true;
    this.prepararParaSalvar(false);

    if (this.isEdicao) {
      console.log("[editando a pesquisa]", this.pequisaInstace);

      // tslint:disable-next-line: max-line-length
      this.pesquisaService.atualizar(this.pequisaInstace, false).subscribe({
        next: (pesquisaAtualizada: PesquisaCadastro) => {
          this.isLoading = false;
          this.salvando = false;

          /**
           * Caso n seja um salvamento automatico, devemos ser redirecionados
           */
          if (!this.isOcorrendoSalvamentoAutomatico) {
            this.router.navigate(["pesquisa"], {
              queryParams: { redirect: true },
            });
            this.showNotificationSuccess();
          } else {
            /**
             * Se for um salvamento automatico, atualizamos o estado da pesquisa com a resposta
             * do servidor
             */
            this.isOcorrendoSalvamentoAutomatico = false;
            /**
             * Definindo a pesquisa atual
             */
            this.store.dispatch(new DefinirPesquisa(pesquisaAtualizada));

            /**
             * após atualizar a pesquisa, é feito a requisição para carregar as localidades na API,
             * e uma action é enviada para atualizar a store com as novas localidades.
             * (Essas localidades servem para popular as localidades e vinculos_operador da
             * etapa do gerenciamento de cotas do cadastro da pesquisa)
             */
            this.localidadeService
              .findAllLocalidadesByIdPesquisa(pesquisaAtualizada.id)
              .subscribe((localidades: Localidade[]) => {
                const carregarLocalidadesAction = new CarregarLocalidadesAction(
                  localidades
                );
                this.store.dispatch(carregarLocalidadesAction);
              });

            /**
             * Como a estrutura de dados do back-end(tree)
             * difere da estrutura do front-end(flat),
             * precisamos fazer esse parser
             */
            // tslint:disable-next-line: max-line-length
            this.store.dispatch(
              new CarregarLocalidadesVinculos({
                vinculos: <Vinculo[]>pesquisaAtualizada.vinculos,
              })
            );
          }

          // tslint:disable-next-line: align
        },
        error: (error) => this.handleAtualizacaoError(error),
      });
    } else {
      delete this.pequisaInstace.configuracaoPesquisa["token"];
      // tslint:disable-next-line: max-line-length
      this.pesquisaService.salvar(this.pequisaInstace).subscribe({
        next: (pesquisaSalva: PesquisaCadastro) => {
          this.isLoading = false;
          this.salvando = false;

          /**
           * Caso n seja um salvamento automatico, devemos ser redirecionados
           */
          if (!this.isOcorrendoSalvamentoAutomatico) {
            this.router.navigate(["pesquisa"], {
              queryParams: { redirect: true },
            });
            this.showNotificationSuccess();
          } else {
            /**
             * Se for um salvamento automatico, atualizamos o estado da pesquisa com a resposta
             * do servidor
             */
            this.isOcorrendoSalvamentoAutomatico = false;
            /**
             * Definindo a pesquisa atual
             */
            this.store.dispatch(new DefinirPesquisa(pesquisaSalva));
            /**
             * Como a estrutura de dados do back-end(tree)
             * difere da estrutura do front-end(flat),
             * precisamos fazer esse parser
             */
            // tslint:disable-next-line: max-line-length
            this.store.dispatch(
              new CarregarLocalidadesVinculos({
                vinculos: <Vinculo[]>pesquisaSalva.vinculos,
              })
            );

            /**
             * A partir do momento que salvamos automaticamente,
             * comecamos a editar a pesquisa
             */
            this.isEdicao = true;
            /**
             * Alterando a url para que seja explicito que agora trata-se
             * de uma edicao
             */
            this.location.go(`pesquisa/atualizar?id=${pesquisaSalva.id}`);
          }

          // tslint:disable-next-line: align
        },
        error: (error) => {
          this.isLoading = false;
          this.salvando = false;
        },
      });
    }
  }

  /**
   * Inicia o processo de salvar a pesquisa, solicita para os passos
   * ques se preparem e quando estes estiverem prontos, a requisição
   * para a API será feita
   * @param preparar - indica se a action de preparacao deve ser lancada
   * @param exibirConfirmacao - indica se uma mensagem de confirmacao deve ser exivida
   */
  prepararParaSalvar(preparar: boolean = true, exibirConfirmacao = true) {
    // console.log('estaValida', this.isPesquisaValida());
    if (preparar && this.isPesquisaValida()) {
      if (exibirConfirmacao) {
      } else {
        this.isLoading = preparar;
        this.store.dispatch(new PrepararParaSalvar(preparar));
      }
    } else if (preparar && !this.isPesquisaValida()) {
      this.store.dispatch(
        new ExibirValidacoesRascunho(true, PassoCadastro.CARACTERISTICAS)
      );
      // tslint:disable-next-line: max-line-length
      this.toastr.error(
        "A pesquisa contém erros que precisam ser concertados antes de salvá-la",
        "Problemas na configuração da pesquisa!",
        {
          positionClass: "toast-bottom-right",
        }
      );
    } else if (!preparar) {
      this.store.dispatch(new PrepararParaSalvar(false));
      this.store.dispatch(
        new SetPreparadoParaSalvar(PassoCadastro.CARACTERISTICAS, false)
      );
      this.store.dispatch(
        new SetPreparadoParaSalvar(PassoCadastro.QUESTIONARIO, false)
      );
      this.store.dispatch(
        new SetPreparadoParaSalvar(PassoCadastro.MARCACOES, false)
      );
      this.store.dispatch(
        new SetPreparadoParaSalvar(PassoCadastro.LOCALIDADES, false)
      );
    }
  }
  saveState() {
    this.store.dispatch(new PrepararParaSalvar(true));
  }
  /**
   * Recupera o FormGroup do passo 4 do cadastro da pesquisa do store.
   * @param store store
   */
  getLocalidadesFormGroup(store: CadastroPesquisaStoreState) {
    const passoLocalidades = store.dadosPasso.get(PassoCadastro.LOCALIDADES);

    const localidadesVinculos = passoLocalidades.localidadesVinculos;

    if (!localidadesVinculos) {
      return new UntypedFormGroup({});
    }

    const operadoresVinculos = passoLocalidades.operadoresVinculos;
    // criando form group baseado em dados da store
    // tslint:disable-next-line: max-line-length
    const localidadesFormGroup =
      LocalidadesVinculos.criaLocalidadesVinculosFormGroup(
        localidadesVinculos.data,
        operadoresVinculos
      );
    // localidadesFormGroup.setValidators([validadorEmptyControls]);
    // localidadesFormGroup.updateValueAndValidity();

    // tslint:disable-next-line: max-line-length
    // console.log('[PesquisaCadastroComponent.getLocalidadesFormGroup] localidadesFormGroup: ', localidadesFormGroup);

    return localidadesFormGroup;
  }

  /**
   * Caso trate-se de um rascunho, devemos garantir que o tituloCurto foi informado.
   * Caso contrário, verificamos se todos os passos estão em ordem
   */
  isPesquisaValida(): boolean {
    const pesquisaStatus = this.formGroup.get("status").value;

    if (pesquisaStatus === "RASCUNHO") {
      // tslint:disable-next-line: max-line-length
      const tituloCurtoControl = this.caracteristicasStep
        .get("descricaoPesquisa")
        .get("tituloCurto");
      return tituloCurtoControl.valid;
    }

    // tslint:disable-next-line: max-line-length
    return (
      this.caracteristicasStep.valid &&
      this.questionarioStep.valid &&
      this.marcacoesStep.valid &&
      (this.localidadesStep.valid ||
        this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0")
    );
  }

  /**
   * O angular não garante a atualização de estado do
   * formGroup em algumas situações. Este metodo
   * auxiliar varre os controls do formGroup em busca
   * de erros ou invalidações para determinar
   * se o formGroup está VALID
   */
  isValidFormGroup(formGroup: UntypedFormGroup): boolean {
    if (!formGroup) {
      return false;
    }

    const controlsName = Object.keys(formGroup.controls);
    // tslint:disable-next-line: max-line-length
    const controls: AbstractControl[] = controlsName.map(
      (controlName) => <AbstractControl>formGroup.controls[controlName]
    );

    const invalid = controls.some((control) => {
      return control.invalid;
    });

    return !invalid;
  }

  /**
   * Retorna uma nova instancia de AbstractControl
   * que tem como objetivo representar
   * a validade do formGroup passado por parametro.
   *
   * O angular não garante a atualização de estado do
   * formGroup em algumas situações. Este metodo
   * auxiliar detecta se o formGroup passado está
   * valido ou não (levando em conta o estado de seus abstractControl filhos)
   * e retorna uma nova instancia com o estado correto
   *
   * @param formGroup FormGroup a ser analisado
   */
  getArbtraryFormGroup(formGroup: UntypedFormGroup): AbstractControl {
    if (!this.isValidFormGroup(formGroup)) {
      return new UntypedFormControl(null, Validators.required);
    }

    /**
     * Returns a new and VALID formGroup
     */
    return new UntypedFormGroup({});
  }

  async canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {});
  }

  /**
   * Valida se a mudanca de status da pesquisa (caso tenha ocorrido) está valida
   */
  validateStatusChange() {
    if (
      !this.estadoValidacao ||
      !this.statusOriginalPesquisa ||
      !this.pequisaInstace.status
    ) {
      return;
    }

    // tslint:disable-next-line: max-line-length
    const statusChangePayload = new StatusChangePayload(
      this.estadoValidacao,
      this.statusOriginalPesquisa,
      this.pequisaInstace.status
    );

    try {
      if (
        this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0" &&
        this.formGroup.value.configuracaoPesquisa.amostrasOnline == "0"
      ) {
        this.statusChangeService.validateStatusChange({
          ...statusChangePayload,
          estadoValidacao: {
            ...statusChangePayload.estadoValidacao,
            amostrasMaiorQueZero: true,
          },
        });
      } else if (
        this.formGroup.value.configuracaoPesquisa.amostrasPresenciais == "0"
      ) {
        this.statusChangeService.validateStatusChange({
          ...statusChangePayload,
          estadoValidacao: {
            ...statusChangePayload.estadoValidacao,
            validaSemVinculos: true,
          },
        });
      } else {
        this.statusChangeService.validateStatusChange(statusChangePayload);
      }
    } catch (error) {
      const statusCorreto = error.destinoCorreto;

      this.controls.status.patchValue(this.statusOriginalPesquisa);
      this.store.dispatch(new AlterarStatusPesquisa(statusCorreto));
    }
  }

  /**
   * Verifica se a pesquisa a ser editada deve possuir algum de seus
   * passos bloqueados baseado no seu status
   */
  bloquearPassosBaseadoNoStatus(pesquisa: PesquisaCadastro) {
    if (pesquisa.status === StatusPesquisa.EXECUCAO) {
      // tslint:disable-next-line: max-line-length
      this.store.dispatch(
        new BloquearPassosCadastro([
          PassoCadastro.CARACTERISTICAS,
          PassoCadastro.QUESTIONARIO,
          PassoCadastro.MARCACOES,
        ])
      );
      return;
    }

    if (pesquisa.status === StatusPesquisa.CONCLUIDO) {
      // tslint:disable-next-line: max-line-length
      this.store.dispatch(
        new BloquearPassosCadastro([
          PassoCadastro.CARACTERISTICAS,
          PassoCadastro.QUESTIONARIO,
          PassoCadastro.MARCACOES,
          PassoCadastro.LOCALIDADES,
        ])
      );
      return;
    }

    if (pesquisa.status === StatusPesquisa.RASCUNHO) {
      this.store.dispatch(new BloquearPassosCadastro([]));
      return;
    }
  }

  replayActions() {
    this.resolucaoConflitoSercice.reexecutarAlteracoes();
  }

  getConflictPositiveCallback(currentPesquisa) {
    return () => {
      this.isLoading = true;
      const novaPesquisa = currentPesquisa;
      this.store.dispatch(new DefinirPesquisa(novaPesquisa));
      // tslint:disable-next-line: max-line-length
      this.store.dispatch(
        new CarregarLocalidadesVinculos({
          vinculos: <Vinculo[]>novaPesquisa.vinculos,
        })
      );
      this.resolucaoConflitoSercice.reexecutarAlteracoes();
      this.isLoading = false;

      this.customError = null;
    };
  }

  handleAtualizacaoError(error: HttpErrorResponse) {
    this.prepararParaSalvar(false);

    if (error.status === 409) {
      const apiError = error.error;

      this.isLoading = false;
      this.salvando = false;

      if (apiError.code === 1001) {
        if (this.isOcorrendoSalvamentoAutomatico) {
          this.voltarPasso();
        } else {
        }

        return;
      }

      if (apiError.code === 1002) {
        if (!this.isOcorrendoSalvamentoAutomatico) {
          this.customError = apiError.payload;
          // tslint:disable-next-line: max-line-length
          console.log("apiError: ", apiError);

          // tslint:disable-next-line: max-line-length
        } else {
          this.customError = apiError.payload;
          // tslint:disable-next-line: max-line-length
          console.log("apiError: ", apiError);

          // tslint:disable-next-line: max-line-length

          this.voltarPasso();
        }

        return;
      }
    }

    this.isLoading = false;
    this.salvando = false;

    if (!this.isOcorrendoSalvamentoAutomatico) {
    } else {
      this.isOcorrendoSalvamentoAutomatico = false;

      this.voltarPasso();
    }
  }

  /**
   * Retorna o conjunto de autoridades (Authorities) do usuário autenticado.
   */
  getAuthenticatedUserAuthorities() {
    const authenticatedUser = this.securityService.getAuthenticatedUser();
    return authenticatedUser.authorities;
  }
}
