import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[string-without-semicolon]'
})
export class MaskStringWithoutSemicolonDirective {

  constructor(private el: ElementRef) {}

  /**
   * Função detecta e bloqueia a inserção da tecla ;
   * @param event recebe o evento de digitação do teclado
   */
  @HostListener("keydown", ["$event"]) onInputChange(event) {
    if (event.key === ";") {
      event.preventDefault();
    }
  }

  /**
   * Substitui ; por , em eventos de colagem
   * @param event recebe o evento de colagem
   */
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedText = clipboardData.getData('text');

    const sanitizedText = pastedText.replace(/;/g, ',');

    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = sanitizedText;

    inputElement.dispatchEvent(new Event('input'));
  }

}
