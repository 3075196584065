import { Injectable } from '@angular/core';
import { TipoPergunta } from '../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { TipoMarcacao } from '../questionario/componentes/secao/pergunta/pergunta-card/tipoMarcacao';

@Injectable({
  providedIn: 'root',
})
export class MarcacaoPerguntaService {

  /**
   * Mapa contendo quais marcações cada tipo
   * de pergunta pode possuir
   */
  marcacoesPerguntaMap: Map<TipoPergunta, TipoMarcacao[]>;

  constructor() {
    this.initializeMarcacoesPerguntaMap();
  }

  /**
   * Inicializa o mapa que contem as marcações
   * disponíveis para o tipo especifico de
   * pergunta
   */
  initializeMarcacoesPerguntaMap() {
    this.marcacoesPerguntaMap = new Map();

    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.DATA, [TipoMarcacao.OBRIGATORIA, TipoMarcacao.PULO_PERGUNTA]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.ESCALA_NUMERICA, [TipoMarcacao.COTA, TipoMarcacao.PULO, TipoMarcacao.DIFERENTE, TipoMarcacao.OBRIGATORIA]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.FOTO, [TipoMarcacao.OBRIGATORIA, TipoMarcacao.PULO_PERGUNTA]);
    this.marcacoesPerguntaMap.set(TipoPergunta.GRADE_MULTIPLA, [TipoMarcacao.OBRIGATORIA]);
    this.marcacoesPerguntaMap.set(TipoPergunta.GRADE_UNICA, [TipoMarcacao.OBRIGATORIA]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.HORARIO, [TipoMarcacao.OBRIGATORIA, TipoMarcacao.PULO_PERGUNTA]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.MULTIPLA, [TipoMarcacao.COTA, TipoMarcacao.OBRIGATORIA, TipoMarcacao.DISCO, TipoMarcacao.PULO]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.MULTIPLA_ORDEM_CITACAO, [TipoMarcacao.COTA, TipoMarcacao.OBRIGATORIA, TipoMarcacao.DISCO]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.UNICA, [TipoMarcacao.COTA, TipoMarcacao.PULO, TipoMarcacao.DIFERENTE, TipoMarcacao.OBRIGATORIA, TipoMarcacao.DISCO]);
    // tslint:disable-next-line: max-line-length
    this.marcacoesPerguntaMap.set(TipoPergunta.VIDEO, [TipoMarcacao.OBRIGATORIA, TipoMarcacao.PULO_PERGUNTA]);
  }

  /**
   * Metodo que informa se determinada tipo de pergunta
   * possui o tipo de marcação informado.
   */
  possuiMarcacao(tipoPergunta: TipoPergunta, tipoMarcacao: TipoMarcacao): boolean {

    const marcacoes = this.marcacoesPerguntaMap.get(tipoPergunta);
    const possuiMarcacao = marcacoes.includes(tipoMarcacao);

    return possuiMarcacao;
  }

  /**
   * Retorna os tipos de marcações que o tipo da pergunta
   * informada possui.
   *
   * @param tipoPergunta
   */
  getMarcacoes(tipoPergunta: TipoPergunta): TipoMarcacao[] {
    return this.marcacoesPerguntaMap.get(tipoPergunta);
  }
}
