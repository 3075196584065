import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

type TipoImagem = 'login' | 'logotipo';

@Injectable({
  providedIn: 'root'
})
export class ValidateImageService {

  /**
   *
   * @param arquivo Arquivo que foi carregado pelo usuário
   * @param tipo 'login' | 'logotipo' | 'simbolo', cada tipo tem suas restrições próprias de tamanho, dimensão e extensão
   * @returns um objeto observavel que está semmpre escutando alterações para validar as regras de cada imagem
   */
  validarImagem(arquivo: File, tipo: TipoImagem): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.onload = () => {
        const imagem = new Image();
        imagem.src = reader.result as string;

        imagem.onload = () => {
          // const { width, height } = imagem;
          // else if (!this.validarDimensoes(tipo, arquivo, width, height)) {
          //   observer.error(`As dimensões da imagem não são suportadas. Utilize uma imagem com dimensões entre ${this.obterDimensoesPermitidas(tipo)}.`);
          // }

          if (!this.validarExtensao(arquivo, tipo)) {
            observer.error(`Este tipo de arquivo não é suportado. Utilize arquivos de imagem nos formatos: ${this.obterExtensaoPermitida(tipo)}.`);
          } else if (!this.validarTamanho(arquivo)) {
            observer.error('O tamanho da imagem é superior a 5Mb. Por favor, selecione outra imagem e tente novamente.');
          } else {
            observer.next('A imagem é válida.');
            observer.complete();
          }
        };

        imagem.onerror = () => {
          observer.error('Parece que ocorreu um erro ao carregar a imagem. Selecione outra imagem ou tente novamente mais tarde.');
        };
      };

      reader.onerror = () => {
        observer.error('Parece que ocorreu um erro ao carregar a imagem. Selecione outra imagem ou tente novamente mais tarde.');
      };

      reader.readAsDataURL(arquivo);
    });
  }

  /**
   * Verifica se a extensão do arquivo que o usuário informou é esperada pelo sistema
   * @param arquivo Arquivo que foi carregado pelo usuário
   * @param tipo 'login' | 'logotipo' | 'simbolo', cada tipo tem suas restrições próprias de tamanho, dimensão e extensão
   * @returns retorna de acordo com o tipo se o arquivo é da extensão esperada pelo input.
   */
  private validarExtensao(arquivo: File, tipo: TipoImagem): boolean {
    const extensoesPermitidas: Record<TipoImagem, string[]> = {
      login: ['png', 'jpg', 'jpeg'],
      logotipo: ['png', 'jpg'],
    };

    const extensao = arquivo.name.split('.').pop()?.toLowerCase();
    const extensoesPermitidasTipo = extensoesPermitidas[tipo];

    return !!extensao && extensoesPermitidasTipo.includes(extensao);
  }

  /**
   * Valida o tamanho da imagem carregado pelo usuário
   * @param arquivo Arquivo que foi carregado pelo usuário
   * @returns Caso o tamanho da imagem seja maior que 5MB ele retorna falso
   */
  private validarTamanho(arquivo: File): boolean {
    const tamanhoMaximoBytes = 5 * 1024 * 1024; // 5MB
    return arquivo.size <= tamanhoMaximoBytes;
  }

  /**
   * De acordo com o tipo o sistema valida se a imagem está nos tamanhos esperados pelo sistema
   * @param arquivo Arquivo que foi carregado pelo usuário
   * @param tipo 'login' | 'logotipo' | 'simbolo', cada tipo tem suas restrições próprias de tamanho, dimensão e extensão
   * @param width largura da imagem
   * @param height altura da imagem
   * @returns caso esteja dentro das dimensões esperadas retorna true
   */
  private validarDimensoes(tipo: TipoImagem, arquivo: File, width: number, height: number): boolean {
    if (this.validarExtensao(arquivo, tipo) && arquivo.type === 'image/svg+xml') {
      return true; // Para SVG, não há necessidade de validar as dimensões
    }

    const dimensoesPermitidas: Record<TipoImagem, [number, number, number, number]> = {
      login: [670, 910, 1340, 1820],
      logotipo: [40, 40, 400, 80],
    };

    const [minWidth, minHeight, maxWidth, maxHeight] = dimensoesPermitidas[tipo];

    return width >= minWidth && width <= maxWidth && height >= minHeight && height <= maxHeight;
  }

  /**
   * Devolve, de acordo com o tipo as extensões permitidas.
   * @param tipo 'login' | 'logotipo' | 'simbolo', cada tipo tem suas restrições próprias de tamanho, dimensão e extensão
   * @returns de acordo com o tipo retorna o texto dos formatos suportandos
   */
  private obterExtensaoPermitida(tipo: TipoImagem): string {
    const extensoesPermitidas: Record<TipoImagem, string> = {
      login: 'PNG ou JPG',
      logotipo: 'PNG',
    };

    return extensoesPermitidas[tipo];
  }
 /**
   * Devolve, de acordo com o tipo as dimensões permitidas.
   * @param tipo 'login' | 'logotipo' | 'simbolo', cada tipo tem suas restrições próprias de tamanho, dimensão e extensão
   * @returns de acordo com o tipo retorna o texto das dimensões suportandos
   */
  private obterDimensoesPermitidas(tipo: TipoImagem): string {
    const dimensoesPermitidas: Record<TipoImagem, string> = {
      login: '670x910 pixels e 1340x1820 pixels',
      logotipo: '40x40 pixels e 400x80 pixels',
    };

    return dimensoesPermitidas[tipo];
  }
}
