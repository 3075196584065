import { Injectable } from '@angular/core';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { CrudService } from 'app/servico/requestService/crudService';
import { Observable } from 'rxjs/internal/Observable';
import { Operador } from '../operador';
import { OperadorSelecao } from '../operadorSelecao';

@Injectable({
  providedIn: 'root',
})
export class OperadorService extends CrudService<Operador> {

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/operadores`);
  }

  /**
   * Recupera os operadores de determinada pesquisa
   * que serão utilizadas para fins de seleção, como em um select
   */
  getOperadorSelecao(idPesquisa:number): Observable<OperadorSelecao[]> {

    const requestUrl = `${apiLocation}/operadores/pesquisa/${idPesquisa}`;

    return this.requestService.get(requestUrl);
  }

  getTotalOperadores(): Observable<any> {

    const requestUrl = `${apiLocation}/operadores/total-com-status`;

    return this.requestService.get(requestUrl);
  }

  getIdOperadorPorIdContaUsuario(id:string):  Observable<any>{

    const requestUrl = `${apiLocation}/operadores/buscarporidConta?id=${id}`;

    return this.requestService.get(requestUrl);
  }

}
