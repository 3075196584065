import { Permissao } from '../permissao/permissao';

export class Grupo {

  id: number;
  nome: string;
  // Permissao[] | string[]
  permissoes:  any = [];
  // pode ser um numero (id) ou entao um { id: number }
  usuariosAdicionados: any = [];
  ativo: boolean = true;
  usuariosRemovidos: any = [];
  // apenas para tratamento de checkboxes
  selecionado: boolean;

}
