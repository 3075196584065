import { AbstractAction } from './abstractAction';
import { Vinculo } from '../../model/vinculo';
import { UntypedFormGroup } from '@angular/forms';
import { PassoCadastro } from '../passoCadastro';
import { ActionType } from '../actionType';
import { LocalidadeTree } from 'app/modulos/localidade/localidadeTree';

export class AdicionarLocalidade extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.ADICIONAR_LOCALIDADE;

  // tslint:disable-next-line: max-line-length
  constructor(estado: { idLocalidade: number, nomeLocalidade: string, possuiFilho: boolean, versaoLocalidade: string; }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class VinculoLocalidadeTree {

  idLocalidade: number;
  nomeLocalidade: string;
  versaoLocalidade: string;
  possuiFilho: boolean;
  nomeLocalidadePai?: string;
  cotaNumericaPai: number;
  childrenLoaded: boolean;

  children: VinculoLocalidadeTree[];

  static from(
    cotaNumericaPai: number,
    nomeLocalidadePai: string,
    localidade: LocalidadeTree,
    comPai: boolean = true): VinculoLocalidadeTree {

    const filhas = localidade.filhas;

    const vinculo: VinculoLocalidadeTree = {
      nomeLocalidadePai: comPai? nomeLocalidadePai: null,
      cotaNumericaPai,
      idLocalidade: localidade.id,
      nomeLocalidade: localidade.nome,
      possuiFilho: filhas && filhas.length > 0,
      versaoLocalidade: localidade.versao,
      children: [],
      childrenLoaded: true,
    };

    if (!vinculo.possuiFilho) {
      return vinculo;
    }

    const children = filhas.map((filha: LocalidadeTree) => {
      return VinculoLocalidadeTree.from(0, '', filha);
    });

    vinculo.children = children;

    return vinculo;
  }
}

export class AdicionarLocalidadesPayload {

  vinculoLocalidadesTree: VinculoLocalidadeTree[];
}

export class AdicionarLocalidades extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.ADICIONAR_LOCALIDADES;

  // tslint:disable-next-line: max-line-length
  constructor(payload: AdicionarLocalidadesPayload) {
    super();
    this.payload = payload;
    this.isReplayable = true;
  }
}

export class ExpandirFilhos extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.EXPANDIR_FILHOS;

  // tslint:disable-next-line: max-line-length
  constructor(estado: { idLocalidadePai: number, localidadePaiHash: string, nomeLocalidadePai?: string }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class CarregarFilhos extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.CARREGAR_FILHOS;

  constructor(estado: {
    // tslint:disable-next-line: max-line-length
    filhos: { idLocalidade: number, nomeLocalidade: string, versaoLocalidade: string; possuiFilhos: boolean }[],
    parent: { localidadePaiHash: string, nomeLocalidadePai?: string, cotaNumericaPai: number },
  }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class AlterarVinculoLocalidadeCotaPercentual extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.ALTERAR_VINCULO_LOCALIDADE_COTA_PERCENTUAL;

  constructor(estado: { localidadeVinculoHash: string, novaCotaPercentual: number }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class SelecionarVinculoLocalidade extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.SELECIONAR_VINCULO_LOCALIDADE;

  constructor(estado: { localidadeVinculoHash: string }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class RemoverVinculoLocalidade extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.REMOVER_VINCULO_LOCALIDADE;

  constructor(estado: { localidadeVinculoHash: string }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class AdicionarVinculoOperador extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.ADICIONAR_VINCULO_OPERADOR;

  constructor(estado: { localidadeVinculoHash: string }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class SelecionarOperador extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.SELECIONAR_OPERADOR;

  // tslint:disable-next-line: max-line-length
  constructor(estado: { localidadeVinculoHash: string, operadorVinculoHash: string, idOperadorSelecionado: number }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class AlterarVinculoOperadorCotaPercentual extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.ALTERAR_VINCULO_OPERADOR_COTA_PERCENTUAL;

  // tslint:disable-next-line: max-line-length
  constructor(estado: { localidadeVinculoHash: string, operadorVinculoHash: string, novaCotaPercentual: string }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class RemoverVinculoOperador extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.REMOVER_VINCULO_OPERADOR;

  constructor(estado: { localidadeVinculoHash: string, operadorVinculoHash: string }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class SincronizarPassoLocalidades extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.SINCRONIZAR_PASSO_LOCALIDADES;

  constructor() {
    super();
    this.payload = {};
    this.isReplayable = true;
  }
}

export class CarregarLocalidadesVinculos extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.CARREGAR_LOCALIDADES_VINCUOS;

  constructor(estado: { vinculos: Vinculo[] }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}

export class SincronizarVinculoLocalidadesFormGroup extends AbstractAction {

  readonly passoCadastro = PassoCadastro.LOCALIDADES;
  readonly type = ActionType.SINCRONIZAR_VINCULO_LOCALIDADES_FORM_GROUP;

  constructor(estado: { formGroup: UntypedFormGroup }) {
    super();
    this.payload = {
      estado,
    };
    this.isReplayable = true;
  }
}
