import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
// tslint:disable-next-line: max-line-length
import { TipoMarcacao } from '../../../questionario/componentes/secao/pergunta/pergunta-card/tipoMarcacao';
import { MarcacaoPerguntaService } from '../../marcacaoPergunta.service';

@Component({
  selector: 'app-pergunta-card-marcacao',
  templateUrl: './pergunta-card-marcacao.component.html',
  styleUrls: ['./pergunta-card-marcacao.component.scss'],
})
export class PerguntaCardMarcacaoComponent implements OnInit {

  @Input() icone: string;
  @Input() titulo: string;
  @Input() nomePlaceHolder: string;

  @Input() formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };

  tipoMarcacao = TipoMarcacao;

  constructor(private store: Store<CadastroPesquisaStoreState>,
    private marcacaoPerguntaService: MarcacaoPerguntaService) { }

  ngOnInit(): void {
    this.controls = this.formGroup.controls;
    // console.log('[PerguntaCardMarcacaoComponent.ngOnInit] controls: ', this.controls);
  }

  possuiMarcacao(tipoMarcacao: TipoMarcacao) {

    const tipoPergunta = this.controls.tipo.value;
    return this.marcacaoPerguntaService.possuiMarcacao(tipoPergunta, tipoMarcacao);
  }

}
