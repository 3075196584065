import { Directive } from '@angular/core';
import { MascaraBase } from './mascaraBase';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-celular]',
})
export class MascaraCelularDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 15);
  }

  aplicarMascara(valor: string): string {
    return mascaraCelular(valor);
  }

}

export function mascaraCelular(valor: string) {

  const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

  // adicionando primeiro parentese do DDD
  const valorPrimeiraFormatacao = numberString.replace(/(\d)/, '($1');

  // adicionando segundo parentese do DDD
  const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(/(\d{2})(\d)/, '$1)$2');

  // adicionando espaço entre o DDD e o número
  const valorTerceiraFormatacao = valorSegundaFormatacao.replace(/(\))(\d)/, '$1 $2');

  // adicionando hífen entre os 5 primeiros digitos do telefone
  const valorQuartaFormatacao = valorTerceiraFormatacao.replace(/(\d{5})(\d)/, '$1-$2');

  return valorQuartaFormatacao;
}



