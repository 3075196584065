import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { TipoConteudo } from '../../conteudo/tipoConteudo';
import { AbstractPerguntaComponent } from '../abstractPerguntaComponent';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-video-questionario',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent extends AbstractPerguntaComponent {

  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_VIDEO;
  tipoPergunta: TipoPergunta = TipoPergunta.VIDEO;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
