import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-header-produtividade",
  templateUrl: "./header-produtividade.component.html",
  styleUrls: ["./header-produtividade.component.scss"],
})
export class HeaderProdutividadeComponent implements OnInit {
  @Input()tituloHeader = "Titulo"
  @Input() item1 = {
    titulo: "Item 2",
    inicio: "04/02/23",
    fim: "06/02/23",
  };

  @Input() item2 = {
    titulo: "Item 2",
    aprovadas: 56,
    reprovadas: 24,
  };

  @Input() progresso = {
    total: 400,
    realizadas: 200,
    porcentagem: 50,
  };

  constructor() {}

  ngOnInit(): void {}
}
