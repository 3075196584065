import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { HeaderService, HeaderSearchDataV4 } from "./servico/header-service";
import { IImageTheme } from "styles/interface";
import { ThemeService } from "styles/services/theme.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  //
  searchText: string;
  isSearching: boolean = false;
  closeButton: boolean = false;
  //
  timer = null;
  // dados da pesquisa
  researchData: HeaderSearchDataV4;
  searchAmount: number;
  // texto da pesquisa
  researchTitle: string = "";
  contractorImages: IImageTheme;
  // controle de loading externo
  @Output()
  loading: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private service: HeaderService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.router.url === "/pesquisar") {
      const localStorageValue = localStorage.getItem("tensai:surveysearch");

      this.researchTitle = localStorageValue ? localStorageValue : "";
    }
  }

  handleChanges(event: string) {
    const eventWithEndSpace = event.replace(/\s+/g, ' ').trim();

    this.saveLocalStorageSearchValue(eventWithEndSpace);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      // verifica se o campo de pesquisa está diferente de vazio
      this.researchTitle = eventWithEndSpace;
      // verifica se tem algo escrito para mudar estado do ícone
      if (eventWithEndSpace.length > 0) {
        this.isSearching = true;
        this.closeButton = false;
      } else {
        this.isSearching = false;
      }

      if (this.researchTitle !== "") {
        // entra em loading
        this.loading.emit(true);
        //  Retorna número de resultados da busca geral
        this.service
          .returnSearchAmount({
            palavrasChave: this.researchTitle.split(" "),
          })
          .then((item) => {
            this.searchAmount = item;
          });
        //  Retorna busca geral
        this.service
          .buscarTodosV4({
            palavrasChave: this.researchTitle.split(" "),
          })
          .then((item) => {
            // sai do loading
            this.loading.emit(false);
            this.researchData = item;
          });
      } else {
        // reseta os dados do resultado da pesquisa
        this.researchData = null;
      }
      //
    }, 300);
  }

  saveLocalStorageSearchValue(value: string): void {
    localStorage.removeItem("tensai:surveysearch");

    localStorage.setItem("tensai:surveysearch", value);
  }

  toggleClick() {
    if (this.closeButton) {
      this.closeButton = false;
    } else {
      this.closeButton = true;
      this.isSearching = false;
    }
  }

  handleClose() {
    this.toggleClick();
    this.isSearching = false;
    this.researchTitle = "";
  }

  ngOnDestroy(): void {
    if (this.router.url !== "/pesquisar") {
      this.researchTitle = "";

      localStorage.removeItem("tensai:surveysearch");
    }
  }
}
