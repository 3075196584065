import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TipoPergunta } from 'app/modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';

interface Column {
  descricao: string;
  ordem: number;
}

interface Row {
  descricao: string;
  ordem: number;
}

@Component({
  selector: 'grade-input',
  templateUrl: './grade-input.component.html',
  styleUrls: ['./grade-input.component.scss']
})

export class GradeInputComponent implements OnInit, OnChanges {
  // Quantidade limite de caracteres, apenas utilizado para facilitar a mudança da copy.
  characterLimit: number = 250;

  ngOnInit() {
    this.updateHeightInput()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateHeightInput()
  }

  // função que pega os valores iniciais tanto das linhas como das
  // colunas e setam a altura de acordo com a quantidade de caracteres
  updateHeightInput() {
    // aplicando altura dinâmica as alternativas [columas]
    this.alternativas.map((alt, index) => {
      // utilizando o setTimeout para o código esperar um pouco e ter tudo renderizado em tela,
      // não foi possível realizar essa ação utilizando os próprios hooks do angular
      setTimeout(() => {
        this.handleUpdateHeightInput(alt.descricao, index, 'column')
      }, 50)
    })

    // aplicando altura dinâmica as títulos [linhas]
    this.titulosGrade.map((line, index) => {
      // utilizando o setTimeout para o código esperar um pouco e ter tudo renderizado em tela,
      // não foi possível realizar essa ação utilizando os próprios hooks do angular
      setTimeout(() => {
        this.handleUpdateHeightInput(line.descricao, index, 'line')
      }, 50)
    })

  }

  // Representa as linhas da grade
  @Input() titulosGrade: Row[] = [] as Row[];

  // Representa as Colunas da Grade
  @Input() alternativas: Column[] = [] as Column[];

  @Input() qtdMinima: number = 0;

  @Input() qtdMaxima: number = 1;

  // Recebe o tipo de grade (unica ou multipla)
  @Input() type: TipoPergunta.GRADE_UNICA | TipoPergunta.GRADE_MULTIPLA = TipoPergunta.GRADE_UNICA;

  // Recebe os controles de validação
  @Input() validationControls;
  @Input() rowValidationControls;

  // Modificando input de quantidade minima
  @Output() handleQuantidadeMinimaChange = new EventEmitter<Event>();

  handleQuantidadeMinimaChangeInput(quantidade: Event): void {
    this.handleQuantidadeMinimaChange.emit(quantidade);
  }

  // Modificando input de quantidade máxima
  @Output() handleQuantidadeMaximaChange = new EventEmitter<Event>();

  handleQuantidadeMaximaChangeInput(quantidade: Event): void {
    this.handleQuantidadeMaximaChange.emit(quantidade);
  }

  //  Recebendo função do pai através de um event emitter
  @Output() addGridRow = new EventEmitter();

  //  Função que adiciona nova linha nas alternativas da pergunta do tipo grade
  handleAddGridRow(): void {
    this.addGridRow.emit();
  }

  //  Recebendo função do pai através de um event emitter
  @Output() addGridColumn = new EventEmitter();

  //  Função que adiciona nova coluna nas alternativas da pergunta do tipo grade
  handleAddGridColumn(): void {
    this.addGridColumn.emit();
  }

  //  Recebendo função do pai através de um event emitter
  @Output() deleteGridRow = new EventEmitter<number>();

  //  Função que deleta linha das alternativas da pergunta do tipo grade
  handleDeleteGridRow(rowIndex: number): void {
    this.deleteGridRow.emit(rowIndex)
  }

  //  Recebendo função do pai através de um event emitter
  @Output() deleteGridColumn = new EventEmitter<number>();


  //  Função que deleta coluna das alternativas da pergunta do tipo grade
  handleDeleteGridColumn(columnIndex: number): void {
    this.deleteGridColumn.emit(columnIndex);
  }

  //  Outputs para receber as funções como parametro através de emissão de eventos
  @Output() editGridColumnItemDescription = new EventEmitter<{ newDescription: string, columnIndex: number }>();

  //  Outputs para receber as funções como parametro através de emissão de eventos
  @Output() editGridRowItemDescription = new EventEmitter<{ newDescription: string, columnIndex: number }>();

  @Output() editColumnDescriptionValidation
  //  Função que altera valor da descrição de um item da coluna de uma alternativa de uma pergunta do tipo grade
  handleEditGridColumnItemDescription(newDescription: string, columnIndex: number): void {
    this.handleUpdateHeightInput(newDescription, columnIndex, 'column');
    this.editGridColumnItemDescription.emit({ newDescription, columnIndex });
  }

  //  Função que altera valor da descrição de um item da linha de uma alternativa de uma pergunta do tipo grade
  handleEditGridRowItemDescription(newDescription: string, columnIndex: number): void {
    this.handleUpdateHeightInput(newDescription, columnIndex, 'line');
    this.editGridRowItemDescription.emit({ newDescription, columnIndex });
  }

  /**
   * função que aplica comportamento de altura dinâmica ao inputs
   *
   * @param event texto digitado pelo usuário
   * @param inputID id do input
   * @param type para dizer se é linha ou coluna
   */
  handleUpdateHeightInput(event: string, inputID: number, type: string) {
    let element = (document.querySelector(`#${type}${inputID}`) as HTMLElement)
    element.style.height = `${(event.length < 15 ? 48 : (element.scrollHeight > 48 ? element.scrollHeight : 48))}px`

    // caso o máximo de caracteres seja atingido
    // é colocado um marginTop para aparecer a
    // mensagem de erro pra n quebrar o layout
    if (event.length > this.characterLimit && event.length < (this.characterLimit + 2)) {
      element.style.marginTop = '26px'
    } else {
      element.style.marginTop = '0px'
    }

    // caso o input for do tipo linha,
    // todos os [td] da linha devem sofrer a
    // alteração de altura, para acompanhar a altura do input
    if (type === 'line') {
      setTimeout(() => {
        const linesNodeList: NodeListOf<HTMLElement> = document.querySelectorAll(`.line-item${inputID}`)
        const lines = Array.from(linesNodeList)

        lines.map(line => {
          line.style.height = event.length > this.characterLimit ? `${element.scrollHeight + 46}px` : `${element.scrollHeight + 22}px`
        })
      }, 50)
    }
  }
}
