import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * cria um validador que verifica se o lengh do value do control passado
 * é maior ou menor que o valor passado por parâmetro.
 * maior = true, menor = false.
 */
export default (value: number, maiorOuMenor: boolean) => {

  return (toCompareControl: AbstractControl): ValidationErrors => {

    if (maiorOuMenor) {
      return toCompareControl.value.length > value ? { maiorQueDeveria: true } : null;
    }
    return toCompareControl.value.length < value ? { menorQueDeveria: true } : null;
  }
};