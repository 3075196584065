import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  TipoAlternativa,
  TipoPergunta,
} from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { BadgeModel } from "../components/badge/badge.component.";
import {
  IAnswaresList,
  IQuestion,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { AnswerOutput } from "../questions.component";

export interface ICheckedItems {
  id_alternativa: number;
  tipo: TipoAlternativa;
  texto?: string;
  numero?: number;
}

enum AnswerType {
  INTERVIEWER = "INTERVIEWER",
  AUDITOR = "AUDITOR",
}

@Component({
  selector: "app-generic-response-question",
  templateUrl: "./generic-response.component.html",
  styleUrls: ["./generic-response.component.scss"],
})
export class GenericResponseQuestionComponent implements OnInit {
  @Input() questionData = {} as IQuestion;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);

  checkedItems: ICheckedItems[] = [];

  renderType: TipoPergunta = TipoPergunta.UNICA;

  definicoes = {
    numero_maximo_respostas: 1,
    numero_minimo_respostas: 1,
  };

  isOnlyText: boolean = false;
  isOnlyNumber: boolean = false;

  badgeModel = {} as BadgeModel;

  hasChanged: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initDefinitions();
    this.initAnswers();
    this.initFlags();
    this.updateBadge();

    if (this.auditorAnswers?.length) {
      this.setHasChangedState(true);
    }
  }

  private updateAnswer(answer: ICheckedItems[]) {
    this.checkedItems = [...answer];
    this.updateBadge();
    this.onChange.emit({
      type: this.currentTipoPergunta,
      questionId: this.questionData.id,
      answer: this.checkedItems,
    });
  }

  public resetAnswers() {
    this.initAnswers(this.interviewerAnswers as IAnswaresList[]);
    this.setHasChangedState(false);
  }

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  private initDefinitions() {
    const { definicoes, tipo_pergunta } = this.questionData;

    // set render type (unica | multipla)
    this.renderType = tipo_pergunta as TipoPergunta;

    // set definitions
    if (this.isMultiAnswers()) {
      this.definicoes = { ...definicoes };

      if (!this.isRequired()) {
        this.definicoes.numero_minimo_respostas = 0;
      }
    }
  }

  private initFlags() {
    if (this.questionData.alternativas) {
      const alts = this.questionData.alternativas;
      this.isOnlyText =
        alts.length === 1 &&
        alts[0].tipo_alternativa === this.tipoAlternativa.ABERTA_TEXTO;
      this.isOnlyNumber =
        alts.length === 1 &&
        alts[0].tipo_alternativa === this.tipoAlternativa.ABERTA_NUMERO;
    }
  }

  public initAnswers(from?: IAnswaresList[]) {
    const answers = from || (this.auditorAnswers.length
      ? this.auditorAnswers
      : this.interviewerAnswers);

    const mappedAnswers = (answers as IAnswaresList[]).map((alt) => ({
      id_alternativa: alt.id_alternativa,
      texto: alt.texto || null,
      numero: alt.numero || null,
      tipo: this.questionData.alternativas.find(
        (a) => a.id === alt.id_alternativa
      ).tipo_alternativa as TipoAlternativa,
    }))

    this.updateAnswer(mappedAnswers);
  }

  private updateBadge() {
    const defaultBadge = { isRequired: this.isRequired(), questionType: TipoPergunta.UNICA } as BadgeModel;

    if (!this.isMultiAnswers()) {
      this.badgeModel = defaultBadge;
    } else {
      this.badgeModel = {
        ...defaultBadge,
        questionType: TipoPergunta.MULTIPLA,
        minLength: this.definicoes.numero_minimo_respostas,
        maxLength: this.definicoes.numero_maximo_respostas,
        requiredError: this.isRequired() && this.checkedItems.length === 0,
        minRequiredError:
          this.checkedItems.length < this.definicoes.numero_minimo_respostas,
        maxRequiredError:
          this.checkedItems.length > this.definicoes.numero_maximo_respostas,
      };
    }
  }

  private get auditorAnswers() {
    return (this.questionData && this.questionData.respostas.auditor) || [];
  }

  private get interviewerAnswers() {
    return (
      (this.questionData && this.questionData.respostas.entrevistador) || []
    );
  }

  public get answerType() {
    return AnswerType;
  }

  public get tipoPergunta() {
    return TipoPergunta;
  }

  public get tipoAlternativa() {
    return TipoAlternativa;
  }

  private get currentTipoPergunta() {
    return this.isMultiAnswers() ? TipoPergunta.MULTIPLA : TipoPergunta.UNICA;
  }

  public withDefaultAnswer(altId: number, tipoAlternativa: string): boolean {
    let answers = this.interviewerAnswers;

    const answerObject = (answers as IAnswaresList[]).find(
      (answer) => answer.id_alternativa === altId
    );

    if (answerObject) {
      switch (tipoAlternativa) {
        case this.tipoAlternativa.ABERTA_TEXTO:
          return !!answerObject.texto;
        case this.tipoAlternativa.ABERTA_NUMERO:
          return answerObject.numero !== null && typeof answerObject.numero  === 'number';
        case this.tipoAlternativa.FECHADA:
          return !!answerObject.id_alternativa;
        default:
          return false;
      }
    }

    return false;
  }

  public getAnswer(
    altId: number,
    tipoAlternativa: string
  ) {
    const answerObject = this.checkedItems.find(item => item.tipo === tipoAlternativa && item.id_alternativa === altId);

    if (answerObject) {
      switch (tipoAlternativa) {
        case this.tipoAlternativa.ABERTA_TEXTO:
          return answerObject.texto as string;
        case this.tipoAlternativa.ABERTA_NUMERO:
          return this.convertAlternativeValueToNumber(answerObject.texto) as number;
        case this.tipoAlternativa.FECHADA:
          return answerObject.id_alternativa;
        default:
          return null;
      }
    }
    return null;
  }

  /**
   * O valor da alternativa ABERTA_NUMERO está retornando do back-end como
   * string e este metódo realiza a conversão para number
   * @param stringValue: string recebida pelo back-end
   * @returns numberValue
   */
  convertAlternativeValueToNumber(value: string): number {
    if(value !== null && value !== undefined) return +value;

    return null;
  }

  public getCheckedItemValue(item: ICheckedItems) {
    switch (item.tipo) {
      case TipoAlternativa.ABERTA_TEXTO:
        return item.texto || null;
      case TipoAlternativa.ABERTA_NUMERO:
        return isNaN(item.numero) ? null : item.numero;
      default:
        return item.id_alternativa;
    }
  }

  public isMultiAnswers() {
    return this.renderType === TipoPergunta.MULTIPLA;
  }

  private isRequired() {
    return this.questionData.obrigatoria;
  }

  public isSelectable(tipoAlternativa: string): boolean {
    return [
      this.tipoAlternativa.ABERTA_TEXTO,
      this.tipoAlternativa.FECHADA,
    ].includes(tipoAlternativa as TipoAlternativa);
  }

  public isChecked(id: number) {
    return !!this.checkedItems.find((o) => o.id_alternativa === id);
  }

  private resetProxy() {
    if (!this.isMultiAnswers()) {
      this.updateAnswer([]);
    } else {
      const validItems = this.checkedItems.filter(
        (item) => this.getCheckedItemValue(item) !== null
      );

      this.updateAnswer(validItems);
    }
  }

  private handleUncheck(id: number) {
    if (this.isMultiAnswers()) {
      this.updateAnswer(this.checkedItems.filter(alt => alt.id_alternativa !== id));
    }
  }

  public handleCheckProxy($event, tipoAlternativa: TipoAlternativa) {
    this.setHasChangedState(true);

    switch (tipoAlternativa) {
      case TipoAlternativa.FECHADA:
        return this.handleCheckClosedAlternative($event);
      case TipoAlternativa.ABERTA_TEXTO:
        return this.handleCheckTextAlternative($event);
      case TipoAlternativa.ABERTA_NUMERO:
        return this.handleCheckNumberAlternative($event);
      default:
        return;
    }
  }

  private handleCheckClosedAlternative($event: { id: number }) {
    if (!this.isChecked($event.id)) {
      this.resetProxy();
      this.updateAnswer([...this.checkedItems, { id_alternativa: $event.id, tipo: TipoAlternativa.FECHADA }]);
    } else {
      this.handleUncheck($event.id);
    }
  }

  private handleCheckTextAlternative($event: { id: number; value: string }) {
    if (!this.isChecked($event.id)) {
      this.resetProxy();
      this.updateAnswer([...this.checkedItems, {
        id_alternativa: $event.id,
        texto: $event.value,
        tipo: TipoAlternativa.ABERTA_TEXTO,
      }]);
    } else {
      this.handleUncheck($event.id);
    }
  }

  private handleCheckNumberAlternative($event: { id: number; value: number }) {
    if (!this.isChecked($event.id)) {
      this.resetProxy();
      this.updateAnswer([...this.checkedItems, {
        id_alternativa: $event.id,
        numero: $event.value,
        tipo: TipoAlternativa.ABERTA_NUMERO,
      }])
    }
  }

  public handleChangeOpenAlternativeProxy($event, tipoAlternativa: TipoAlternativa) {
    this.setHasChangedState(true);

    if (tipoAlternativa === TipoAlternativa.ABERTA_TEXTO) {
      return this.handleChangeText($event);
    }

    return this.handleChangeNumber($event);
  }

  private handleChangeText($event: { id: number; value: string }) {
    this.checkedItems.find((alt) => alt.id_alternativa === $event.id).texto = $event.value;
  }

  private handleChangeNumber($event: { id: number; value: number }) {
    this.checkedItems.find((alt) => alt.id_alternativa === $event.id).numero = $event.value;
  }
}
