// tslint:disable-next-line: max-line-length
import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentRef, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { ComplexInputFactoryService } from 'app/modulos/pesquisa-old/cadastro/inputs/complex-input/complex-input-factory.service';

@Component({
  selector: 'app-complex-component-factory',
  templateUrl: './complex-component-factory.component.html',
  styleUrls: ['./complex-component-factory.component.scss']
})
export class ComplexComponentFactoryComponent implements OnInit {

  /**
   * Publisher de re-renderização, caso deseje-se a re-renderização
   * manual do componente criado pela fabrica
   */
  @Input() reRenderPublisher: Subject<{}>;
  reRenderSubscription: Subscription;

  @ViewChild('container',  {static: true}) container: ViewContainerRef;
  @Input() tipoInput: string;

  @Input() params: { [key: string]: string };

  childElementRef: ComponentRef<any>;

  constructor(protected complexInputFactory: ComplexInputFactoryService) { }

  ngOnInit() {

    if (this.reRenderPublisher) {
      this.reRenderSubscription = this.reRenderPublisher.subscribe((ev: {}) => {
        this.rebuild();
      });
    }

    this.render();
  }

  /**
   * Re-renderiza o componente criado pela fabrica
   */
  ngOnChanges(changes?: SimpleChanges) {
    this.rebuild();
  }

  ngOnDestroy() {
    if (this.reRenderSubscription) {
      this.reRenderSubscription.unsubscribe();
    }
  }

  rebuild() {
    if (this.childElementRef) {
      this.childElementRef.destroy();
      this.render();
    }
  }

  render() {

    if (!this.tipoInput) {
      return;
    }

    // tslint:disable-next-line:max-line-length
    const componentFactory = this.complexInputFactory.getComponetFactory(this.tipoInput);

    this.childElementRef = this.container.createComponent(componentFactory);
    const elementInstance = this.childElementRef.instance;

    if (this.params) {
      const paramsKeys = Object.keys(this.params);

      paramsKeys.forEach((paramKey) => {
        elementInstance[paramKey] = this.params[paramKey];
      });
    }
  }

}
