import dates from "app/util/misc/dates";
import { IDateObject, IOperatorCollection } from "../interfaces/listagem";

export function getFirstAndLastDate(collections: IOperatorCollection[]): IDateObject {
  // Encontrar a data inicial mais antiga
  const dataInicialMaisAntiga = collections.reduce((dataAntiga, item) => {
    const dataAtual = dates.converterData(item.data_registro);
    return dataAtual < dataAntiga ? dataAtual : dataAntiga;
  }, dates.converterData(collections[0].data_registro));

  // Encontrar a data final mais distante
  const dataFinalMaisDistante = collections.reduce((dataDistante, item) => {
    const dataAtual = dates.converterData(item.data_registro);
    return dataAtual > dataDistante ? dataAtual : dataDistante;
  }, dates.converterData(collections[0].data_registro));

  return {inicial: dataInicialMaisAntiga, final: dataFinalMaisDistante}
}

