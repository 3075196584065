<div class="question" [formGroup]="formGroup">
  <div class="questionHeader">
    <div class="iconQuestion">
      <span class="{{ icone }}"></span>
    </div>
    <div class="titleQuestion">
      <span>{{ titulo }}</span>
      <h3>{{ controls.nome.value ? controls.nome.value : nomePlaceHolder }}</h3>
    </div>
  </div>
  <div class="questionContent">
    <div class="questionProperties">
      <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.PULO)">
        <mat-slide-toggle
          formControlName="possuiPulo"
          (click)="perguntaComMarcacaoPulo($event)"
          (change)="onChangePulo()"
        >
        </mat-slide-toggle>
        <label>Pulo</label>
      </div>
      <div
        class="campo-toggle"
        *ngIf="possuiMarcacao(tipoMarcacao.PULO_PERGUNTA)"
      >
        <mat-slide-toggle
          formControlName="possuiPuloPergunta"
          (click)="perguntaComMarcacaoPulo($event)"
          (change)="onChangePulo()"
        ></mat-slide-toggle>
        <label>Pulo</label>
      </div>
      <!--
      
      CONFIGURAÇÕES DE PERGUNTA COMENTADAS ABAIXO SÃO AS QUE NÃO SERÃO NECESSÁRIAS
      PARA A RELEASE TENSAI_BASIC
    
      -->
      <!-- <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.DISCO)">
        <mat-slide-toggle formControlName="discoInducao" (click)="$event.stopPropagation();" (change)="onChange()">
        </mat-slide-toggle>
        <label for="">Disco</label>
      </div> -->
      <!-- <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.COTA)">
        <mat-slide-toggle formControlName="possuiCota" (click)="$event.stopPropagation();" (change)="onChange()">
        </mat-slide-toggle>
        <label for="">Cotas</label>
      </div> -->
      <!-- <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.DIFERENTE)">
        <mat-slide-toggle type="checkbox" formControlName="possuiDiferenteDe" (click)="perguntaComMarcacaoDiferenteDe($event);"
          (change)="onChangePerguntaDiferenteDe()"></mat-slide-toggle>
        <label for="">Diferente de</label>
      </div> -->
      <div
        class="campo-toggle"
        *ngIf="possuiMarcacao(tipoMarcacao.OBRIGATORIA)"
      >
        <mat-slide-toggle
          type="checkbox"
          formControlName="obrigatoria"
          (click)="$event.stopPropagation()"
          (change)="onChange()"
        ></mat-slide-toggle>
        <label for="">Obrigatório</label>
      </div>
    </div>
    <div class="questionActions" *ngIf="formGroup.enabled">
      <span
        class="fa fa-trash-o"
        title="Remover pergunta"
        (click)="remover($event)"
      ></span>
    </div>
  </div>
</div>
