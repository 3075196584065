import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusPesquisa } from '../../../status/statusPesquisa';
import { InputValidatorFactory } from '../../inputs/inputValidatorFactory';
import { TipoInput } from '../../inputs/tipoInput';
import { ElementoQuestionario } from '../../model/elementoQuestionario';
import { Pergunta } from '../../model/pergunta';
import { PesquisaCadastro } from '../../model/pesquisaCadastro';
import { Secao } from '../../model/secao';
import { ItemProtegido } from '../../security/itemProtegido';
import { PesquisaAuthorityService } from '../../security/pesquisaAuthorityService';
import { AlterarTitulos } from '../../store/actions/caracteristicasAction';
import { SetPreparadoParaSalvar } from '../../store/actions/controleGeralAction';
import { AdicionarSecao } from '../../store/actions/questionarioAction';
import { CadastroPesquisaStoreState } from '../../store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../store/passoCadastro';
import { PassoComponent } from '../passoComponent';
import { TipoConteudo } from './componentes/secao/conteudo/tipoConteudo';

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html',
  styleUrls: ['./questionario.component.scss'],
})
export class QuestionarioComponent extends PassoComponent {

  passoCadastro: PassoCadastro = PassoCadastro.QUESTIONARIO;

  /**
   * Parametros que serao utilizados no componente
   * que renderiza o conteudo das secoes/perguntas
   */
  conteudoComponentParams: any = {};

  /**
   * Marcador para exibir ou nao o seletor de adicao
   */
  showQuestionarioSeletorAdicao: boolean = false;
  /**
   * Item que foi selecionado no questionario (secao ou pergunta)
   */
  itemSelecionado: { item: Secao | Pergunta, tipoConteudo: TipoConteudo };

  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl } = {};

  /**
   * Marcacao que indica se trata-se de uma edicao
   */
  isEdicao: boolean = false;

  /**
   * Marcador que indica se modificações
   * estão habilitadas no passo
   */
  modificacaoPermitida = false;

  /**
  * Subscriptions que deverão ser encerradas
  * junto com a destruição do componente
  */
  subscriptions: Subscription[] = [];

  statusAtualPesquisa: StatusPesquisa;

  // Observable responsável por escultar mudanças no status da pesquisa
  @Input() indicadorMudancaStatus: Observable<StatusPesquisa>;

  // tslint:disable-next-line: max-line-length
  constructor(private pesquisaAuthorityService: PesquisaAuthorityService, protected store: Store<CadastroPesquisaStoreState>, private changeDetectorRef: ChangeDetectorRef) {
    super(store);
  }
  /**
   * @override
   */
  ngOnInit() {

    super.ngOnInit();

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    const storeObservableSubscription = pesquisaStoreObservable.subscribe((store) => {

      this.isEdicao = store.isEdicao;
      const iniciarPreparacaoParaSalvar = store.dadosGeraisCadastro.prepararParaSalvar;
      const preparado = store.dadosPasso.get(PassoCadastro.QUESTIONARIO).preparadoParaSalvar;
      if (iniciarPreparacaoParaSalvar && !preparado) {
        this.store.dispatch(new SetPreparadoParaSalvar(PassoCadastro.QUESTIONARIO, true));
      }
    });
    this.subscriptions.push(storeObservableSubscription);

    /**
     * Observando itens selecionados
     */
    const itemSelecionadoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso.get(PassoCadastro.QUESTIONARIO)),
        map(dadosPasso => dadosPasso.itemSelecionado),
      )
      .subscribe((itemSelecionado) => {
        /**
         * Quando a seleção é alterada, o campo
         * para adicionar novos itens deve ser
         * escondido
         */
        if (itemSelecionado.item && this.conteudoComponentParams.source) {
          if (this.conteudoComponentParams.source.hash !== itemSelecionado.item.hash) {
            this.showQuestionarioSeletorAdicao = false;
          }
        }

        this.itemSelecionado = itemSelecionado;
        this.conteudoComponentParams = {
          source: this.itemSelecionado.item,
        };
      });

    /**
     * carregando pesquisa
     */
    const pesquisaSubscription = pesquisaStoreObservable
      .pipe(
        // tslint:disable-next-line: max-line-length
        map(cadastroPesquisaStoreState => cadastroPesquisaStoreState.pesquisa),
      )
      .subscribe((pesquisa) => {
        this.initFormGroup(pesquisa);
      });

    /**
  * Observando itens selecionados
  */

    this.subscriptions.push(pesquisaSubscription);
    this.subscriptions.push(itemSelecionadoSubscription);

    this.initFormGroup(new PesquisaCadastro());

    this.indicadorMudancaStatus.subscribe(statusAtual => {
      if (!statusAtual) {
        return;
      }

      this.statusAtualPesquisa = statusAtual;
      console.log('[Status atual da pesquisa]', statusAtual);
    });

  }

  /**
   * @override
   */
  verificarPermissaoAlteracao() {
    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.QUESTIONARIO);
  }

  initFormGroup(pesquisa: PesquisaCadastro) {

    this.controls = {};

    // tslint:disable-next-line:max-line-length
    this.controls['titulo'] = new UntypedFormControl(pesquisa.descricaoPesquisa.titulo, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO));
    // tslint:disable-next-line:max-line-length
    this.controls['tituloCurto'] = new UntypedFormControl(pesquisa.descricaoPesquisa.tituloCurto, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO_CURTO));
    // tslint:disable-next-line:max-line-length
    this.controls['textoInicial'] = new UntypedFormControl(pesquisa.textoInicial, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_TEXTO_INICIAL));
    this.controls['elementosQuestionario'] = new UntypedFormArray([], [Validators.required]);

    this.formGroup = new UntypedFormGroup({});
    this.formGroup = new UntypedFormGroup({
      titulo: this.controls.titulo,
      tituloCurto: this.controls.tituloCurto,
      textoInicial: this.controls.textoInicial,
      elementosQuestionario: this.controls.elementosQuestionario,
    });

    pesquisa.elementosQuestionario = pesquisa.elementosQuestionario.sort((elemento1, elemento2) => {
      return elemento1.ordem - elemento2.ordem;
    });

    pesquisa.elementosQuestionario.forEach((elementoQuestionario) => {
      // tslint:disable-next-line: max-line-length
      const elementoQuestioarioFg = ElementoQuestionario.getInitializedControl(elementoQuestionario);
      (<UntypedFormArray>this.controls.elementosQuestionario).push(elementoQuestioarioFg);
    });

    this.changeDetectorRef.detectChanges();
    this.formGroup.disable();

    /**
     * Desabilitando controls caso necessário
     */
    this.verificarPermissaoAlteracao();
    if (this.modificacaoPermitida && !this.passosBloqueados.includes(this.passoCadastro)) {
      this.formGroup.enable();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Exibe/Esconde o seletor de adição de seção e perguntas
   */
  toggleShowQuestionarioSeletorAdicao() {
    this.showQuestionarioSeletorAdicao = !this.showQuestionarioSeletorAdicao;
  }

  /**
   * Metodo chamado quando um input de titulo (que é compártilhado)
   * é modificado
   */
  onTituloChange() {
    // tslint:disable-next-line: max-line-length
    this.store.dispatch(new AlterarTitulos(this.controls.titulo.value, this.controls.tituloCurto.value, this.controls.textoInicial.value));
  }

  adicionarSecao() {
    const secao = new Secao();
    this.store.dispatch(new AdicionarSecao(secao));
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(abstractFormArray: AbstractControl): AbstractControl[] {

    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }

}
