import { AbstractControl, ValidationErrors } from '@angular/forms';

export default (control: AbstractControl): ValidationErrors => {

  const cpf = control.value;

  if (isCpfValido(cpf)) {
    return null;
  }

  return {
    invalid: true,
  };

};

function isCpfValido(cpf): boolean {
  if (!cpf) {
    return false;
  }

  const cpfApenasNumeros = cpf.replace(/\D/g, '');

  if (
        cpfApenasNumeros === '00000000000' ||
        cpfApenasNumeros === '11111111111' ||
        cpfApenasNumeros === '22222222222' ||
        cpfApenasNumeros === '33333333333' ||
        cpfApenasNumeros === '44444444444' ||
        cpfApenasNumeros === '55555555555' ||
        cpfApenasNumeros === '66666666666' ||
        cpfApenasNumeros === '77777777777' ||
        cpfApenasNumeros === '88888888888' ||
        cpfApenasNumeros === '99999999999'
    ) { return false; }

  let soma;
  let resto;
  let i = 0;
  soma = 0;

  for (i = 1; i <= 9; i += 1) {
    soma = soma + parseInt(cpfApenasNumeros.substring(i - 1, i), 10) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }

  if (resto !== parseInt(cpfApenasNumeros.substring(9, 10), 10)) {
    return false;
  }

  soma = 0;
  
  for (i = 1; i <= 10; i += 1) {
    soma = soma + parseInt(cpfApenasNumeros.substring(i - 1, i), 10) * (12 - i);
  }
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpfApenasNumeros.substring(10, 11), 10)) return false;

  return true;
}
