import { VoidEvent } from "app/modulos/pesquisa-beta/eventbus/event";

/**
 * Evento lançado quando a pesquisa tiver qualquer dos seus passos
 * enviados para salvar no servidor
 */
export class PendingSavingEvent extends VoidEvent {
  static TYPE = "pesquisa.cadastro.save.running";

  getType(): string {
    return PendingSavingEvent.TYPE;
  }
}

/**
 * Evento lançado quando a pesquisa tiver concluído com
 * sucesso as alterações no servidor
 */
export class CompletedSavingEvent extends VoidEvent {
  static TYPE = "pesquisa.cadastro.save.success";

  getType(): string {
    return CompletedSavingEvent.TYPE;
  }
}

/**
 * Evento lançado quando a pesquisa tiver concluído com
 * falha as alterações no servidor
 */
export class FailedSavingEvent extends VoidEvent {
  static TYPE = "pesquisa.cadastro.save.failed";

  getType(): string {
    return FailedSavingEvent.TYPE;
  }
}
