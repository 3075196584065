<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<main>
  <app-info-card
    [data]="infoCardDefaultValue"
    title="Clientes"
    text="Cadastre e gerencie os clientes que farão parte de suas pesquisas"
  ></app-info-card>

  <section>
    <app-generic-table
      [tableConfig]="tableConfig"
      [tHead]="tableHead"
      [data]="tableData"
      [enableFilters]="true"
      [filters]="filtersConfig"
      [enableSearch]="true"
      [searchInputConf]="serchConfig"
      [currentPage]="pagination.current"
      [totalOfEntries]="totalClients"
      paginationLabel="Clientes"
      [enableCheckbox]="true"
      [enableMeatball]="true"
      [meatballConfig]="meatballConfig"
      [checkboxConfig]="checkConfig"
      [enableActionButton]="showActionButton"
      [actionButtonConfig]="actionButton"
      (events)="handleEventTable($event)"
    ></app-generic-table>
  </section>
</main>
<app-loading *ngIf="isLoading"></app-loading>
