/**
 * Math.pow(10, decimal): Essa função do JavaScript retorna o resultado da potência de 10 elevado a decimal. 
 * Por exemplo, se decimal for 2, então Math.pow(10, decimal) será igual a 100.

 * value * Math.pow(10, decimal): Essa expressão multiplica o valor a ser arredondado (value) pela potência 
 * de 10 elevado a decimal. Isso significa que o número decimal será transformado em um número inteiro, com 
 * decimal zeros após a vírgula. Por exemplo, se value for igual a 3.14159 e decimal for igual a 2, então a 
 * expressão será igual a 314.159.

 * Math.round(value * Math.pow(10, decimal)): Essa função do JavaScript arredonda o número obtido na etapa 
 * anterior para o número inteiro mais próximo. Por exemplo, se o número obtido na etapa anterior for igual a
 * 314.159, então a expressão será igual a 314.
 * Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal): Essa expressão divide o número obtido na
 * etapa anterior pela potência de 10 elevado a decimal. Isso transforma o número arredondado de volta em um
 * número decimal com decimal casas decimais. Por exemplo, se o número arredondado for igual a 314 e decimal for
 * igual a 2, então a expressão será igual a 3.14.


 * 
 * @param value valor a ser arredondado
 * @param decimal quantas casas decimais vão ser arredondadas
 * @returns retorna o value arredondado com 2 casas após o ponto flutuante
 * 
 * OBS: Durante o desenvolvimento foi visto que a função round(), responsavel por arredondar seguindo a regra: 
 * * * n >= 5 = arredonda para cima
 * * * n < 5 = arredonda para baixo,
 * causava no final um valor maior que 100, fazendo com que gráfico tivesse um espaço em branco.
 * Para resolver esse problema a função floor, que arrendonda sempre para baixo, foi utilizada com valor decimal de 2
 * pontos flutuantes.
 */
export function round(value, decimal) {
  return Math.floor(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
