<div class="select--content">
  <!-- label -->
  <label *ngIf="!!label">{{label}}</label>
  <!-- selector -->
  <div
    [ngClass]="getClassSelector()"
    class="select"
    [style.height]="'auto'"
    [class.expanded]="showBody"
  >
    <!-- selected data -->
    <div class="select-data" (click)="handleShowBody()">
      <div class="select-data-text">
        <span
          class="selector-header-placeholder"
          *ngIf="!selectedItem || showBody"
          >{{placeholder}}</span
        >
        <span class="selector-header-selected" *ngIf="selectedItem && !showBody"
          >{{selectedItem.label}}</span
        >
      </div>
      <div class="select-data-icon">
        <span [class]="!showBody ?  'chevron' : 'chevron-rotate' ">
          <i class="fa-regular fa-chevron-down"></i>
        </span>
      </div>
    </div>
    <!-- options -->
    <div class="select-options" [style.display]="showBody ? 'block' : 'none'">
      <!-- filter -->
      <div class="select-options-input">
        <div class="selector-body-input">
          <input
            [placeholder]="filterPlaceholder"
            [(ngModel)]="searchText"
            type="text"
            (ngModelChange)="handleChange($event)"
            *ngIf="data"
          />
          <i class="fa-regular fa-magnifying-glass"></i>
        </div>
      </div>
      <!-- list -->
      <div class="select-options-list" (click)="handleShowBody()">
        <div
          (scroll)="getScrollBottom($event)"
          class="select-options-list-itens"
        >
          <div class="selector-body-options">
            <div *ngFor="let item of filteredData; let itemIdx = index">
              <div
                class="option"
                [class.selected]="selectedItem && selectedItem.value === item.value"
                *ngIf="!item.hidden"
                (click)="handleItemSelect(item, itemIdx)"
              >
                <!-- <app-checkbox
                  *ngIf="usingBy === 'LOCALIDADE'"
                  [typeOfCheck]="usingBy === 'CLIENTE' || usingBy === 'LOCALIDADE' ? 'radio' : 'checkbox'"
                  [isChecked]="isChecked(item)"
                  (change)="onCheckboxChange()"
                ></app-checkbox> -->
                {{item.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- other action -->
  <div
    class="text-for-action"
    [style.display]="!showBody ? 'block' : 'none'"
    *ngIf="usingBy === 'CLIENTE'"
  >
    Deseja <a [routerLink]="'/cliente/cadastro'"><span>cadastrar</span></a> um
    novo cliente?
  </div>
</div>
