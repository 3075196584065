import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { ClienteCadastroComponent } from 'app/modulos/cliente/cadastro/cliente.cadastro.component';
import { AbstractCanDeactivateGuard } from './abstractCanDeactivateGuard';

/**
 * Intercepta uma determinada rota e mostra um popup de confirmação antes da rota ser execultada
 */
@Injectable({
  providedIn: 'root',
})
export class ClienteConfirmDeactivateGuard extends AbstractCanDeactivateGuard implements CanDeactivate<ClienteCadastroComponent> {
  constructor(protected router: Router) {
    super(router);
  }

  async canDeactivate(component: ClienteCadastroComponent) {

    if (this.ignoreRedirectRules()) {
      return true;
    }

    const deactivate: boolean = await component.canDeactivate();
    return deactivate;
  }

}
