/**
 * Tipo de menus listados no sistema
 */
export enum TipoMenu {
  CLIENTE = "CLIENTE",
  FUNCIONARIO = "FUNCIONARIO",
  LOCALIDADE = "LOCALIDADE",
  OPERADOR = "OPERADOR",
  GRUPO = "GRUPO",
  DASHBOARD = "DASHBOARD",
  DASHBOARDBETA = "DASHBOARDBETA",
  PESQUISA = "PESQUISA",
  PESQUISABETA = "PESQUISABETA",
  PRODUTIVIDADE = "PRODUTIVIDADE",
  AUDITORIA = "AUDITORIA",
  GERENCIAMENTO = "GERENCIAMENTO",
  ANALYZE = "ANALYZE",
  RESULTADOS = "RESULTADOS",
  CONTRATANTE = "CONTRATANTE",
  COMUNICACAO = 'COMUNICACAO'
}
