<button [ngClass]="{
    'generic-button': true,
    'default': btnStyle === 'default',
    'outline': btnStyle === 'outline'
  }"
  [style]="btnStructure"
  (click)="handleClick($event)"
  [disabled]="disabled"
>
  {{ textContent }}
</button>
