<div [formGroup]="formGroup">
  <div *ngIf="pesquisas.length > 0">
    <app-filterable-select-old
      [closeOnSelect]="true"
      (changeSelectedItem)="handleSelectedPesquisa($event)"
      [filterPlaceholder]="'Digite o nome de uma pesquisa'"
      [data]="pesquisas"
    >
    </app-filterable-select-old>
  </div>
</div>
