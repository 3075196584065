import { ItemProtegido } from './itemProtegido';
import { Authority } from 'app/infraestrutura/security/authority';

/**
 * Mapa que serve como base para tomada de
 * decisões sobre autorizações em uma pesquisa
 */

const itemProtegidoAuthorityMap: Map<ItemProtegido, Authority[]> = new Map();

// tslint:disable-next-line: max-line-length
itemProtegidoAuthorityMap.set(ItemProtegido.CARACTERISTICAS, [Authority.CADASTRAR_PESQUISA_CARACTERISTICAS, Authority.CADASTRAR_PESQUISA_COMPLETA]);
// tslint:disable-next-line: max-line-length
itemProtegidoAuthorityMap.set(ItemProtegido.QUESTIONARIO, [Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_COMPLETA]);
// tslint:disable-next-line: max-line-length
itemProtegidoAuthorityMap.set(ItemProtegido.MARCACOES, [Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_COMPLETA]);
// tslint:disable-next-line: max-line-length
itemProtegidoAuthorityMap.set(ItemProtegido.VINCULOS, [Authority.CADASTRAR_PESQUISA_LOCALIDADES, Authority.CADASTRAR_PESQUISA_COMPLETA]);
// tslint:disable-next-line: max-line-length
itemProtegidoAuthorityMap.set(ItemProtegido.MUDANCA_STATUS, [Authority.MUDANCA_STATUS_PESQUISA]);
// tslint:disable-next-line: max-line-length
itemProtegidoAuthorityMap.set(ItemProtegido.CADASTRAR_CLIENTE, [Authority.CADASTRAR_CLIENTE]);

export default itemProtegidoAuthorityMap;
