import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PesquisaAuthorityService } from '../../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { AbstractPerguntaConteudo } from '../abstractPerguntaConteudo';

@Component({
  selector: 'app-pergunta-escala-numerica-content',
  templateUrl: './pergunta-escala-numerica-content.component.html',
  styleUrls: ['./pergunta-escala-numerica-content.component.scss']
})
export class PerguntaEscalaNumericaContentComponent extends AbstractPerguntaConteudo {

  valoresIniciaisMinimos: number[] = [1, 0];
  valoresFinaisMaximos: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
    protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
