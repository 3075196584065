// tslint:disable-next-line: max-line-length
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Administrador } from './Administrador';

export class InstanciaSistema {
  id: string;
  friendlyName: string;
  ativo: boolean;
  admin: Administrador;
  name: string;

  constructor(friendlyName: string, ativo: boolean) {
    this.friendlyName = friendlyName;
    this.ativo = ativo;
  }

  static getControl() {
    return new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      friendlyName: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]),
      ativo: new UntypedFormControl(false, [Validators.required]),
      admin: Administrador.getControl(),
    });
  }

  static getInitializedControl(cadastroRequest: InstanciaSistema) {
    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      friendlyName: new UntypedFormControl(cadastroRequest.friendlyName, [Validators.required, Validators.maxLength(255)]),
      ativo: new UntypedFormControl(cadastroRequest.ativo, [Validators.required]),
      admin: Administrador.getInitializedControl(cadastroRequest.admin),
    });
    return formGroup;
  }

}

const nomeValidator = (control: AbstractControl): ValidationErrors => {

  const controlValue = control.value;

  const pattern = /^[a-z_]{1,40}$/g;

  const isValid = pattern.test(controlValue);

  if (isValid) {
    return null;
  }

  return {
    invalid: true,
  };
};
