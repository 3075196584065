import {
  Component,
  EventEmitter,
  Input, OnChanges, OnInit,
  Output
} from "@angular/core";
import { formsLocation } from "app/infraestrutura/apiLocation";
import {
  CompletedSavingEvent,
  FailedSavingEvent,
  PendingSavingEvent
} from "app/modulos/pesquisa-beta/cadastro/events/save";
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import { PesquisaService } from "app/modulos/pesquisa-beta/listagem/servico/pesquisas";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { ICanNotSaveButton } from "../../../pesquisas-cadastro.model";
import { ActiveSectionElementData } from "../pesquisas-questionario.component";
import { SectionModelWithElems as SectionModel } from "./pesquisas-questionario-secoes-listagem.model";
import {
  Section,
  SectionElem,
  SectionOrdenacao
} from "./secao-listagem/secao-listagem.component";

@Component({
  selector: "app-pesquisas-questionario-secoes-listagem",
  templateUrl: "./pesquisas-questionario-secoes-listagem.component.html",
  styleUrls: ["./pesquisas-questionario-secoes-listagem.component.scss"],
})
export class PesquisasQuestionarioSecoesComponent implements OnInit, OnChanges {
  // Novo Elemento Secao na listagem de secao (paga ganhar foco na listagem de seções)
  @Input() activeSectionElementData: ActiveSectionElementData;

  //  Recebe o codigo da marcacao da pergunta resultante da operação de cópia
  @Input() codigoMarcacaoDaPerguntaCopia: string;
  // token de visualização
  @Input() viewToken: string = "";
  // título da pesquisa
  @Input() surveyTitle: string = "Título da Pesquisa";
  // array de secoes
  @Input() models: any[] = [] as Section[];
  // estado utilizado para armazenar a informação do novo elemento secao criado
  @Input() newSectionElem: SectionElem;

  @Output() isReady: EventEmitter<boolean> = new EventEmitter(false);
  // evento de salvar
  @Output() onSaveAll = new EventEmitter<SectionModel[]>();
  // Evento de clique do checkbox do item da listagem de pesquisa
  @Output() onNewSection = new EventEmitter();
  // evento da listagem de secoes para deletar uma secao
  @Output() onEditSection: EventEmitter<SectionModel> = new EventEmitter();
  // evento da listagem de secoes para deletar uma secao
  @Output() onDeleteSection: EventEmitter<SectionModel> = new EventEmitter();
  //  Evento de clique do botao adicionar pergunta para exibir formulário da nova pergunta a ser cadastrada
  @Output() onNewSectionElem: EventEmitter<number> = new EventEmitter();
  //  Evento de clique do botao adicionar pergunta para exibir formulário da pergunta a ser editada
  @Output() onEditSectionElem: EventEmitter<{
    sectionId: number;
    sectionElemOrdem: number;
  }> = new EventEmitter();
  //  Evento de clique do botao duplicar pergunta
  @Output() onDuplicateSectionElem: EventEmitter<{
    sectionId: number;
    sectionElemOrdem: number;
  }> = new EventEmitter();
  //  Evento de clique para remover elementoSecao
  @Output() onDeleteSectionElem: EventEmitter<{
    sectionId: number;
    sectionElemOrdem: number;
  }> = new EventEmitter();
  //  Emissor de evento para resetar estado de novo elemento criado para undefined (FIXME: revisar)
  @Output() handleResetEstadoDeNovoElementoSecao = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter()

  // diz que a tela está ocupada (pode ser usado para desabilitar ações)
  isBusy: boolean = false;

  // Mostrar modal de ordenação de seção e perguntas
  canShowModalOrdenacao: boolean = false;

  // models: Section[] = [];

  constructor(
    private eventBus: EventBus,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService,
    public pesquisaService: PesquisaService,
  ) { }

  ngOnInit() {
    // this.modelData = this.models;
    // register in eventBus
    this.eventBus.on(PendingSavingEvent.TYPE, () => {
      this.isBusy = true;
    });
    this.eventBus.on(CompletedSavingEvent.TYPE, () => {
      this.isBusy = false;
    });
    this.eventBus.on(FailedSavingEvent.TYPE, () => {
      this.isBusy = false;
      this.notificatorService.showError(
        "Atenção",
        "Ocorreu uma falha ao tentar salvar a pesquisa"
      );
    });

    this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: (this.isBusy || !this.verifyRequired()) } })
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  handleCanShowModalOrdenacao(): void {
    this.canShowModalOrdenacao = !this.canShowModalOrdenacao;
  }

  handleUpdateSectionAndQuestion(section: SectionOrdenacao[]) {
    this.models = section;

    this.handleSaveClick();
  }

  getModelsForSecaoListagem() {
    return this.models;
  }

  verifyRequired() {
    const sections = this.models;
    if (sections.length > 0) {
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (
          section.nome === "" ||
          !section.elementosSecao ||
          section.elementosSecao.length === 0
        ) {
          this.isReady.emit(false);
          return false;
        }
      }
      this.isReady.emit(true);
      return true;
    }
    this.isReady.emit(false);
    return false;
  }

  ordenacaoHabilitada(): boolean {
    // A ordenação de elementos só acontece quando existem pelo menos duas secoes, ou pelo menos mais de uma pergunta
    return (
      this.models.length > 1 ||
      this.models.reduce(
        (acc, secao) =>
          secao.elementosSecao ? [...acc, ...secao.elementosSecao] : acc,
        []
      ).length > 1
    );
  }

  verFormularioHabilitado(): boolean {
    // Só é possível visualizar os formulários se tiver pelo menos uma seção e que todas as seções cadastradas tenham ao menos uma pergunta cadastrada
    return (
      this.models &&
      this.models.length > 0 &&
      this.models.every(
        (secao) => secao.elementosSecao && secao.elementosSecao.length > 0
      )
    );
  }

  resetEstadoDeNovoElementoSecao(): void {
    Promise.resolve().then(() => {
      this.handleResetEstadoDeNovoElementoSecao.emit();
    });
  }

  // Função que faz o repasse do evento para o elemento filho
  handleDeleteSectionClick(section: SectionModel) {
    this.onDeleteSection.emit(section);
  }

  // Função para repassar evento do filho para o pai
  handleDeleteSectionElemClick(payload: {
    sectionId: number;
    sectionElemOrdem: number;
  }) {
    this.onDeleteSectionElem.emit(payload);
  }

  //  Função para repassar evento do filho para o pai
  handleDuplicateSectionElemClick(payload: {
    sectionId: number;
    sectionElemOrdem: number;
  }) {
    this.onDuplicateSectionElem.emit(payload);
  }

  // Função para repassar para componente filho
  handleAddSectionElemClick(sectionId: number) {
    this.onNewSectionElem.emit(sectionId);
  }

  //  Função para repassar para componente filho
  handleEditSectionElemClick(payload: {
    sectionId: number;
    sectionElemOrdem: number;
  }) {
    this.onEditSectionElem.emit(payload);
  }

  // função que irá abrir uma nova aba para mostrar o questionário
  handleShowQuizPreview() {
    let linkUrl = `${formsLocation}/${this.viewToken}`;

    this.pesquisaService
      .getFriendlyToken(this.viewToken)
      .toPromise()
      .then((token) => {
        linkUrl = `${formsLocation}/${token.token_amigavel}`
        window.open(linkUrl, '_blank');
      })
      .catch((err) => this.errorHandlerService.handleError(err, 'Erro ao copiar link'));
  }

  // manipula o acionamento de adicionar seção
  handleAddSectionClick() {
    this.onNewSection.emit();
  }

  // manipula o acionamento de edição da seção
  handleEditSectionClick(section: SectionModel) {
    this.onEditSection.emit(section);
  }

  // manipula o acionamento de salvar tudo
  handleSaveClick() {
    const payloadData = this.models.map((item, index) => {
      return {
        ...item,
        ordem: index + 1,
      };
    });

    this.onSaveAll.emit(payloadData);
  }

  // TODO: ???
  // manipula a manipulação de mover seção
  handleMoveSectionElemClick() { }

  // manipula o acionamento de duplicação de perguntas
  handleDuplicateSectionClick() {
    this.notificatorService.showError(
      "Atenção",
      "Esta funcionalidade encontra-se em desenvolvimento"
    );
  }
}
