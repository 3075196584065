import { Component, Input, OnInit } from "@angular/core";
import { IResultAlternative, IResultAnswer, IResultQuestion } from "../../results-interface";
import { adaptAnswerByType } from "./adapter/answers";

@Component({
  selector: "app-results-chart-card",
  templateUrl: "./results-chart-card.component.html",
  styleUrls: ["./results-chart-card.component.scss"],
})
export class ResultsChartCardComponent implements OnInit {
  @Input() questionData: IResultQuestion;
  @Input() layout;
  questionTitle: string;
  questionNumber: number;
  renderValue: IResultAlternative[] | IResultAnswer[];

  selectedType: string;
  isOpen = false;
  showModal = false;

  // De acordo com o tipo da pergunta são dispostos as forma de visualização do resultado;
  graphTypes = {
    ["FOTO"]: ["Lista"],
    ["VIDEO"]: ["Lista"],
    ["DATA"]: ["Lista"],
    ["HORARIO"]: ["Lista"],
    ["UNICA"]: ["Barra", "Pizza", "Lista"],
    ["MULTIPLA"]: ["Barra", "Pizza", "Lista"],
    ["MULTIPLA_ORDEM_CITACAO"]: ["Barra", "Pizza", "Lista"],
    ["ESCALA_NUMERICA"]: ["Barra", "Pizza", "Lista"],
    ["GRADE_UNICA"]: ["GRADE_UNICA"],
    ["GRADE_MULTIPLA"]: ["GRADE_MULTIPLA"],
  };

  constructor() {}

  ngOnInit(): void {
    this.questionNumber = this.questionData.ordem;
    this.questionTitle = this.questionData.nome;

    this.CorrectValueAssignment();
    this.firstRenderingData();
  }

  // Controla a abertura do select dos tipos de visualização
  toggleSelect(): void {
    this.isOpen = !this.isOpen;
  }

  // Muda o modo de visulização do gráfico
  handleOptionClick(type: string) {
    this.selectedType = type;
  }

  // Controla o estado do modal de expansão do card
  handleOpenModal(): void {
    this.showModal = !this.showModal;
  }

  //Impede a renderização do select em perguntas do tipo grade
  renderSelect(): boolean {
    const types: string[] = ['GRADE_UNICA', 'GRADE_MULTIPLA'];
    return !types.some(item => this.questionData.tipo.includes(item))
  }

  // Determina quais são os primeiros valores renderizados de acordo com o tipo da pergunta e alternativa.
  firstRenderingData(): void {
    /**
     * Neste primeiro bloco condicional é verificado se a pergunta UNICA possui somente alternativas ABERTAS / ABERTAS_NUMERO
     * Se for o caso a renderização inicial é de LISTA e as opções são de LISTA e Gráfico de BARRAS
     */
    if(this.questionData.tipo === 'UNICA' && this.questionData.alternativas.every(alt => alt.tipo === 'ABERTA_NUMERO' || alt.tipo === 'ABERTA_TEXTO')) {
      this.selectedType = this.graphTypes[this.questionData.tipo][2];
      this.graphTypes[this.questionData.tipo] = this.graphTypes[this.questionData.tipo].filter(type => type !== "Pizza");
    }
    else if((this.questionData.tipo === "UNICA" || this.questionData.tipo === "MULTIPLA" || this.questionData.tipo === "MULTIPLA_ORDEM_CITACAO"
      || this.questionData.tipo === "ESCALA_NUMERICA") && this.questionData.alternativas.length <= 6)
    {
      this.selectedType = this.graphTypes[this.questionData.tipo][1];
    } else {
      this.selectedType = this.graphTypes[this.questionData.tipo][0];
    }
  }

  // Atribui o valor de acordo com o tipo da pergunta
  CorrectValueAssignment(): void {
    if(this.questionData.tipo === 'DATA' || this.questionData.tipo === 'HORARIO'
    || this.questionData.tipo === 'VIDEO' || this.questionData.tipo === 'FOTO') {
      this.renderValue = this.questionData.respostas;
    } else {
      this.renderValue = adaptAnswerByType(this.questionData.alternativas);
    }
  }

}
