import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncionarioNavigateService {

  private parametro: number;

  /**
   * Obtem o id do funcionário, parametro será utilizado para aplicar o focus na tabela
   * após o cadastro/edição
   */
  setEmployeeId(valor: number): void {
    this.parametro = valor;
  }

  /**
   * Obtem o id do funcionário, parametro será utilizado para aplicar o focus na tabela
   * após o cadastro/edição
   */
  getEmployeeId(): number {
    const employeeId = this.parametro
    this.setEmployeeId(null)
    return employeeId;
  }

}
