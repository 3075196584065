<section [ngClass]="type === 'GRADE_UNICA' ? 'grade unica' : 'grade'">
  <table [ngClass]="type === 'GRADE_UNICA' ? 'bigger-distance' : 'smaller-distance'">
    <thead>
      <tr>
        <th></th>
        <th *ngFor="let headerColumn of alternativas; let headerColumnIndex=index">
          <span class="error-message" [hidden]="!validationControls[headerColumnIndex]?.errors?.maxlength">
            Máximo de {{characterLimit}} caracteres
          </span>
          <textarea [placeholder]="'Coluna ' +  (headerColumnIndex + 1)" [id]="'column' + headerColumnIndex"
            [ngModel]="headerColumn.descricao"
            [ngClass]="validationControls[headerColumnIndex]?.errors?.maxlength ? 'error' : '' "
            (ngModelChange)="handleEditGridColumnItemDescription($event, headerColumnIndex)" maxlength="251"></textarea>
        </th>
        <th>
          <button (click)="handleAddGridColumn()" type="button">
            <i class="fas fa-plus"></i>
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let line of titulosGrade; let leftLineInputsIndex = index;"
          [ngClass]="'line-item' + leftLineInputsIndex">
          <span class="error-message" [hidden]="!rowValidationControls[leftLineInputsIndex]?.errors?.maxlength">
            Máximo de {{characterLimit}} caracteres
          </span>

          <textarea [placeholder]="'Linha ' +  (leftLineInputsIndex + 1)" [id]="'line' + leftLineInputsIndex"
            [ngModel]="line.descricao"
            [ngClass]="rowValidationControls[leftLineInputsIndex]?.errors?.maxlength ? 'error' : '' "
            (ngModelChange)="handleEditGridRowItemDescription($event, leftLineInputsIndex)" maxlength="251"></textarea>
        </td>
        <td>
          <button class="add-line-button" (click)="handleAddGridRow()" type="button">
            <i class="fas fa-plus"></i>
          </button>
        </td>
      </tr>
      <tr *ngFor="let column of alternativas; let columnIndex = index">
        <td *ngFor="let line of titulosGrade; let lineIndex = index;" [ngClass]="'line-item' + lineIndex">
          <div [ngClass]="type === 'GRADE_UNICA' ? 'radio-square' : 'checkbox-circle'"></div>
        </td>
        <td>
          <!-- Exclui coluna -->
          <button class="exclude-data" type="button" (click)="handleDeleteGridColumn(columnIndex)">
            <i class="fas fa-times"></i>
          </button>
        </td>
      </tr>
      <tr class="exclude-data-wrapper">
        <td *ngFor="let line of titulosGrade; let deleteLineIndex = index"
          [ngClass]="'exclude-data-rows-wrapper line-item' + deleteLineIndex">
          <button class="exclude-data" type="button" (click)="handleDeleteGridRow(deleteLineIndex)">
            <i class="fas fa-times"></i>
          </button>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</section>
