import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CrudService } from "app/servico/requestService/crudService";
import { RequestService } from "app/servico/request.service";
import { apiLocation } from "app/infraestrutura/apiLocation";

export interface Operator {
  id: number;
  nome: string;
  matricula: string;
  cpf: string;
  rg: string;
  periodoInicial: string;
  periodoFinal: string;
  ativo: boolean;
  cpfFormatado: string;
  diasFimVinculo: number;
}

export interface OperatorResponse {
  id: number;
  pessoa: {
    nome: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class PesquisaVinculosService extends CrudService<Operator> {
  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/operadores`);
  }
  requestUrl: string;
  listarTodos(): Observable<Operator[]> {
    this.requestUrl = `${this.resourcePath}`;
    const response = <Observable<Operator[]>>(
      this.requestService.get(this.requestUrl)
    );
    return response;
  }

  listarPorId(id: number): Observable<OperatorResponse> {
    this.requestUrl = `${this.resourcePath}/${id}`;
    const response = <Observable<OperatorResponse>>(
      this.requestService.get(this.requestUrl)
    );
    return response;
  }
}
