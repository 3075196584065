import { Component, Input, OnInit } from "@angular/core";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";

export interface BadgeModel {
  minRequiredError?: boolean;
  maxRequiredError?: boolean;
  minLength?: number;
  maxLength?: number;
  requiredError?: boolean;
  isRequired: boolean;
  questionType?: TipoPergunta;
}

@Component({
  selector: "app-questionnaire-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
})
export class QuestionnaireBadgeComponent implements OnInit {
  @Input() model = {
    minRequiredError: false,
    maxRequiredError: false,
    requiredError: false,
    isRequired: false,
    minLength: 1,
    maxLength: 1,
    questionType: TipoPergunta.UNICA,
  } as BadgeModel;

  minLengthSuffix: string = "opção";
  maxLengthSuffix: string = "opção";

  labels = {
    required: () => {
      return "Pergunta obrigatória";
    },
    minLength: () => {
      return `Mínimo ${this.model.minLength} ${this.minLengthSuffix}`;
    },
    maxLength: () => {
      return `Máximo ${this.model.maxLength} ${this.maxLengthSuffix}`;
    },
  };

  ngOnInit() {
    this.updateLabels();
  }

  private updateLabels() {
    const formatter = (length: number, lessThanResult: string, moreThanResult: string) => {
      return length < 2 ? lessThanResult : moreThanResult;
    };

    if (
      [TipoPergunta.GRADE_MULTIPLA, TipoPergunta.GRADE_UNICA].includes(
        this.model.questionType
      )
    ) {
      this.minLengthSuffix = formatter(this.model.minLength, "opção por linha", "opções por linha");
      this.maxLengthSuffix = formatter(this.model.maxLength, "opção por linha", "opções por linha");
    } else if (TipoPergunta.MULTIPLA === this.model.questionType) {
      this.minLengthSuffix = formatter(this.model.minLength, "opção", "opções");
      this.maxLengthSuffix = formatter(this.model.maxLength, "opção", "opções");
    }
  }

  constructor() {}
}
