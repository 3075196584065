<div>
  <button [ngClass]="showMeatball ? 'view-container meatball-active' : 'view-container'">
    <i class="fa-solid fa-ellipsis"></i>
  </button>
  <div class="meatball-container" *ngIf="showMeatball">

    <span class="option" (click)="handleEditInstance()">
      <i class="fa-regular fa-pen-to-square icon"></i>
      <h4 class="option-name">{{ menuItems.EDITAR }}</h4>
    </span>

    <span [ngClass]="canInactiveBtn ? 'disabled-instance option' : 'option'"
      (click)="!canInactiveBtn && handleRequestInstanceAuth()"
      title="Disponível apenas para funcionário"
    >
      <i class="fa-regular fa-arrow-up-right-from-square icon"></i>
      <h4 class="option-name">{{ menuItems.ACESSARINSTANCIA }}</h4>
    </span>

    <span class="option" (click)="toggleHirerStatus()">
      <i *ngIf="status" class="fa-solid fa-toggle-large-on icon"></i>
      <i *ngIf="!status" class="fa-solid fa-toggle-large-off icon"></i>
      <h4 class="option-name">{{ !status ? menuItems.ATIVAR : 'Inativar' }}</h4>
    </span>

  </div>
</div>
