<div class="modal-containeir" [formGroup]="formGroup" class="row">
  <div class="modal-wrapper">
    <div class="modal-header">
      <span> Transferência de cotas </span>
    </div>
    <div class="modal-body">
      <div class="bloco-selecao">
        <span class="titulo-input"
          >Selecione o operador para quem você irá transferir as cotas</span
        >
        <app-seletor-operador-vinculado
          [operadoresVinculados]="data.operadoresVinculados"
          (operadorVinculadoSelecionadoEvt)="
            onOperadorVinculadoSelecionado($event)
          "
        ></app-seletor-operador-vinculado>
        <span
          class="error-message"
          *ngIf="
            exibirErrosValidacao &&
            formGroup.controls.idOperadorVinculadoDestino.errors?.required
          "
        >
          Você deve selecionar o operador para quem será transferido as cotas.
        </span>
      </div>

      <div class="bloco-definicao-quantidade sg-input-group">
        <span class="titulo-input" placeholder="Amostras"
          >Informe quantas amostras deseja transferir</span
        >
        <!--formControlName="titulo"-->
        <input
          mascara-numero
          placeholder="Ex.: 10"
          formControlName="valor"
          title="quantidade de amostras a ser transferida para outro operador"
          type="text"
        />
        <!--!(showErrors && controls.descricaoPesquisa.get('titulo').errors)-->
        <span
          class="error-message"
          *ngIf="exibirErrosValidacao && formGroup.controls.valor.errors?.max"
        >
          Você não tem amostras o suficiente para transferir este valor.
        </span>
        <span
          class="error-message"
          *ngIf="
            exibirErrosValidacao && formGroup.controls.valor.errors?.required
          "
        >
          Este campo é obrigatório
        </span>
        <span
          class="error-message"
          *ngIf="exibirErrosValidacao && formGroup.controls.valor.errors?.min"
        >
          O menor valor passível de transferência é 1.
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-12 btns-actions">
        <button
          class="sg-btn sg-alternate"
          title="Cancelar transferência"
          (click)="cancelar()"
        >
          Cancelar
        </button>
        <button
          class="sg-btn sg-secondary"
          title="adicionar operador"
          (click)="transferir()"
        >
          Transferir
        </button>
      </div>
    </div>
  </div>
</div>
