<div class="header">
  <app-filter-select
    [type]="monthConfig.type"
    [icon]="monthConfig.icon"
    [placeholder]="monthConfig.placeholder"
    [options]="monthConfig.options"
    (changeFilter)="handleSelectMonth($event)"
  >
  </app-filter-select>
  <app-filter-select
    [type]="yearConfig.type"
    [icon]="yearConfig.icon"
    [placeholder]="yearConfig.placeholder"
    [options]="yearConfig.options"
    (changeFilter)="handleSelectYear($event)"
  >
  </app-filter-select>
</div>
