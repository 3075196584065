import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sample-progress',
  templateUrl: './sampleProgress.component.html',
  styleUrls: ['./sampleProgress.component.scss']
})
export class SampleProgressComponent implements OnInit {
  // TODO: Resolver tipo

  @Input() progress: number;
  @Input() samples: number;

  difference: number = 0;

  ngOnInit() {
    this.statusProgress();
  }

  statusProgress() {
    return this.progress === this.samples ? true : false;
  }

  // Função para retornar um valor legível na porcentagem
  getProgressValue(progressValue: number, samplesValue: number): number {
    const result = (progressValue * 100) / samplesValue;
    return Math.floor(result)
  }

}

