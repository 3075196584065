import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PesquisaItem } from '../../../pesquisa-old/pesquisa-select/pesquisaItem';
import { EventoPerguntaSelecionada } from '../respostas-abertas.component';
import { EntrevistaService } from '../../service/entrevista.service';
import { ErrorHandlerService } from 'app/servico/requestService/error-handler.service';
import { IAutomatchPayload, ICitationToPersist } from '../../interface';

@Component({
  selector: 'app-pergunta-resposta-multi-selector',
  templateUrl: './pergunta-resposta-multi-selector.component.html',
  styleUrls: ['./pergunta-resposta-multi-selector.component.scss']
})
export class PerguntaRespostaMultiSelectorComponent implements OnInit, OnDestroy {

  /**
   * Subject que recebe notificações de EventoPerguntaSelecionada
   */
  @Input() perguntaSelecionadaSubject: Subject<EventoPerguntaSelecionada>;
  private perguntaSelecionadaSubscription: Subscription;

  @Input() idPesquisa: number;
  @Input() idPergunta: number;

  // tslint:disable-next-line: max-line-length
  @Output() perguntaRespostaSelecionada: EventEmitter<EventoPerguntaRespostaSelecionada> = new EventEmitter();
  @Input() novaPesquisaSelecionadaSubject: Subject<PesquisaItem>;

  private novaPesquisaSelecionadaSubscription: Subscription;

  trBodies: PerguntaRespostaMultiSelectorTrBody[] = [];

  isLoading: boolean = false;

  @Input() citacoes: PerguntaRespostaItem[];

  constructor(
    private entrevistaService: EntrevistaService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  /**
   * Hook de ciclo de vida que é disparado quando qualquer dado que escute o pai
   * é alterado pelo pai
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.citacoes) {
      this.carregarRespostasDaPerguntaEPesquisa(changes.citacoes.currentValue);
    }
  }

  ngOnInit() {

    // tslint:disable-next-line: max-line-length
    // caso uma nova pergunta seja selecionada, este componente deve carregar as citações desta nova pergunta
    if (this.perguntaSelecionadaSubject) {
      // tslint:disable-next-line: max-line-length
      this.perguntaSelecionadaSubscription = this.perguntaSelecionadaSubject.subscribe((evento: EventoPerguntaSelecionada) => {
        this.onEventoPerguntaSelecionada(evento);
      });
    }

    // tslint:disable-next-line: max-line-length
    // caso uma nova pesquisa seja selecionada, este componente deve zerar os resultados provenientes de uma outra pesquisa/pergunta
    if (this.novaPesquisaSelecionadaSubject) {
      // tslint:disable-next-line: max-line-length
      this.novaPesquisaSelecionadaSubscription = this.novaPesquisaSelecionadaSubject.subscribe((pesquisaSelecionada) => {
        this.idPesquisa = pesquisaSelecionada.id;
        this.trBodies = [];
      });
    }
  }

  ngOnDestroy() {
    if (this.perguntaSelecionadaSubscription) {
      this.perguntaSelecionadaSubscription.unsubscribe();
    }
    if (this.novaPesquisaSelecionadaSubscription) {
      this.novaPesquisaSelecionadaSubscription.unsubscribe();
    }
  }

  /**
   * Callback executado sempre que uma nova pergunta for selecionada.
   * Ele é responsável por chamar a rotina que carrega todas as respostas da pergunta selecionada.
   * @param evento
   */
  onEventoPerguntaSelecionada(evento: EventoPerguntaSelecionada) {
    this.carregarRespostasDaPerguntaEPesquisa(this.citacoes);
  }

  /**
   * Responsável por chamar o metodo que realiza o automatch
   */
  carregarRespostasDaPerguntaEPesquisa(localCitacoes: PerguntaRespostaItem[]) {
    this.isLoading = true;
    this.trBodies = this.toTrBodies(localCitacoes);
    this.isLoading = false;
  }

  /**
   * Realiza a consolidação autumática das citações de mesmo nome
   * @param citacoes
   * @returns PerguntaRespostaMultiSelectorTrBody[]
   */
  toTrBodies(citacoes: PerguntaRespostaItem[]): PerguntaRespostaMultiSelectorTrBody[] {
    let citacoesUnicas = new Array<PerguntaRespostaItem>();
    citacoes.forEach((item) => {

      let tinyItem = item.citacao && item.citacao.toLowerCase().trim()

      //Busca pelas citacoes que possuem o mesmo titulo
      let citacaoEncontrada = citacoesUnicas.find(({ citacao }) => citacao && citacao.toLowerCase().trim()  === tinyItem);

      //Busca pelo id da cicacao que possui o mesmo titulo
      let idEncontrado = citacoesUnicas.findIndex(({ citacao }) => citacao && citacao.toLowerCase().trim() === tinyItem);

      if (idEncontrado !== -1) {
        citacoesUnicas[idEncontrado] = {
          ...item,
          total: (item.total + citacaoEncontrada.total),
          porcentagem: (item.porcentagem + citacaoEncontrada.porcentagem)
        };

        const citationToPersist: ICitationToPersist = {
          citation: item.citacao,
          citation2: citacaoEncontrada.citacao
        }

        this.persistAutomatch(citationToPersist);

      } else {
        citacoesUnicas.push(item);
      }
    });
    return citacoesUnicas.map(c => ({ perguntaResposta: c, selecionado: false }));
  }


  /**
   * realiza a request para persistência sempre que um automatch ocorrer
   */
  persistAutomatch(citationToPersist: ICitationToPersist) {
    const payload: IAutomatchPayload = this.buildAutomatchPayload(citationToPersist);
    this.entrevistaService.automatchPersistence(this.idPesquisa, this.idPergunta, payload).subscribe({
      complete: () => {},
      error: (err) => {
        this.errorHandlerService.handleError(err, 'Erro ao persistir combinação');
      }
    })
  }

  /**
   * Cria o payload de automatch esperado pelo backend
   */
  buildAutomatchPayload({citation, citation2}) {
    const payload: IAutomatchPayload = {
      citacoesAntigas: [citation, citation2],
      novaCitacao: citation.toLowerCase().trim()
    }
    return payload;
  }

  /**
   * Recupera todas as pergunta Respostas selecionadas.
   */
  getRespostasPerguntasSelecionadas(): PerguntaRespostaItem[] {
    // tslint:disable-next-line: max-line-length
    return this.trBodies.filter(trBody => trBody.selecionado).map(trBody => trBody.perguntaResposta);
  }

  /**
   * Evento chamado sempre que uma resposta-pergunta é selecionada.
   * @param ev evento do checkbox de change.
   */
  onRespostaPerguntaSelect(selected: PerguntaRespostaMultiSelectorTrBody, ev) {
    selected.selecionado = ev.target.checked;

    const respostasSelecionadas = this.getRespostasPerguntasSelecionadas();

    const evento: EventoPerguntaRespostaSelecionada = {
      perguntaRespostas: respostasSelecionadas,
    }

    this.perguntaRespostaSelecionada.emit(evento);
  }
}

export class PerguntaRespostaItem {

  citacao: string;
  total: number;
  porcentagem: number;

}

export class PerguntaRespostaMultiSelectorTrBody {

  perguntaResposta: PerguntaRespostaItem;
  selecionado: boolean;

}

export class EventoPerguntaRespostaSelecionada {

  perguntaRespostas: PerguntaRespostaItem[];
}
