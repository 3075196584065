<div [formGroup]="formGroup" (click)="notificarSelecao($event)">

  <app-pergunta-card-marcacao
    [icone]="'fa fa-comment-o'"
    [titulo]="'PERGUNTA ÚNICA'"
    [nomePlaceHolder]="'Título da pergunta única?'"
    [formGroup]="formGroup">
      <ng-content></ng-content>
  </app-pergunta-card-marcacao>

</div>
