<div class="card-container">
  <div class="visao-geral">
    <h1>VISÃO GERAL</h1>
    <div class="visao-geral-info">
      <div class="info-item">
        <h1 [ngClass]="firstColumnData?.toString().split('').length > 4 ? 'smallText' : 'bigText'">{{ firstColumnData }}
        </h1>
        <h6>{{ firstColumnTitle }}</h6>
      </div>
      <div class="info-item">
        <h1 [ngClass]="secondColumnData?.toString().split('').length > 4 ? 'smallText' : 'bigText'">{{ secondColumnData
          }}</h1>
        <h6>{{ secondColumnTitle }}</h6>
      </div>
      <div class="info-item">
        <h1 [ngClass]="thirdColumnData?.toString().split('').length > 4 ? 'smallText' : 'bigText'">{{ thirdColumnData}}
        </h1>
        <h6>{{ thirdColumnTitle }}</h6>
      </div>
    </div>
  </div>
</div>