import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { Observable } from "rxjs";
import { Adiantamento } from "../../../modulos/audio-player/model/adiantamento";
import { ListagemAbstrata } from "../../listagem/listagemAbstrata";
import { TipoPergunta } from "../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { EntrevistaService } from "../service/entrevista.service";
import mockEntrevistaPerguntas from "./mockEntrevistaPerguntas";
import { EntrevistaPerguntas } from "./resposta-pergunta-listagem-tabela/model/entrevistaPerguntas";
import { RespostaPergunta } from "./resposta-pergunta-listagem-tabela/model/respostaPergunta";

@Component({
  selector: "app-resposta-pergunta-listagem",
  templateUrl: "./resposta-pergunta-listagem.component.html",
  styleUrls: ["./resposta-pergunta-listagem.component.scss"],
})
export class RespostaListagemComponent extends ListagemAbstrata {
  idEntrevista: number;
  // Atributo responsável por mostrar a ordem da entrevista selecionada
  ordemEntrevista: number = 1;
  entrevistaPerguntas: any;

  entrevistaFormGroup: UntypedFormGroup;

  constructor(
    public entrevistaService: EntrevistaService,
    private route: ActivatedRoute,
    private location: Location,
    router: Router,
    protected modalService: ModalService
  ) {
    super("entrevista", router, modalService);
  }

  reload() {
    const idEntrevista: string =
      this.route.snapshot.paramMap.get("idEntrevista");
    // tslint:disable-next-line: max-line-length
    this.router.navigate(["../", idEntrevista], {
      relativeTo: this.route,
      queryParams: { redirect: true },
    });
  }

  /**
   * @override
   * implementando life cycle hook disparado quando a rota é atualizada.
   */
  onNavigationEnd() {
    this.inicializarEntrevistaPerguntas();
    // this.carregarEntrevistaMock();
  }

  carregarEntrevistaMock() {
    this.entrevistaPerguntas = mockEntrevistaPerguntas.data();
    this.entrevistaFormGroup.patchValue({
      auditada: this.entrevistaPerguntas.auditada,
    });
  }

  inicializarEntrevistaPerguntas() {
    this.entrevistaService
      .buscarEntrevista(this.idEntrevista)
      .subscribe((entrevista: EntrevistaPerguntas) => {
        this.entrevistaPerguntas = entrevista;
        this.entrevistaFormGroup.patchValue({
          auditada: entrevista.auditada,
        });
      });
  }

  ngOnInit() {
    super.ngOnInit();

    // tslint:disable-next-line: ter-arrow-parens
    /**
     * Escutando parametros da rota
     */
    // tslint:disable-next-line: ter-arrow-parens
    this.navigationSubscription = this.route.queryParamMap.subscribe(
      (paramMap) => {
        // tslint:disable-next-line: semicolon
        const ordemEntrevistaParam = paramMap.get("order");

        if (ordemEntrevistaParam === null) {
          return;
        }
        // Setando a ordem da entrevista atual
        this.ordemEntrevista = Number(ordemEntrevistaParam);
      }
    );

    const idEntrevista: string =
      this.route.snapshot.paramMap.get("idEntrevista");
    this.idEntrevista = Number(idEntrevista);

    this.entrevistaFormGroup = new UntypedFormGroup({
      auditada: new UntypedFormControl(false),
    });

    this.inicializarEntrevistaPerguntas();
    // this.carregarEntrevistaMock();
  }

  /**
   * callback registrado no entrevistaPerguntasChange
   * para manter estado das alterações de entrevistaPergunta
   * do componente filho.
   * @param entrevistaPerguntas
   */
  onEntrevistaPerguntasChange(entrevistaPerguntas: EntrevistaPerguntas) {
    this.entrevistaPerguntas = entrevistaPerguntas;
  }

  private mapToPayload(respostaPergunta: RespostaPergunta, index: number): any {
    /**
     * função que mapeia respostas perguntas locais para o payload
     * do server, considerando as alterantivas.
     */

    const mapComAlternativa = (resposta: RespostaPergunta) => {
      return !!respostaPergunta.alternativasSelecionadas
        ? resposta.alternativasSelecionadas.map((alternativa, i) => {
            // Recupera perguntaRespostaId retornado pelo back-end quando existe para que a atualização seja efetiva;
            const perguntaRespostas =
              this.entrevistaPerguntas.respostasPerguntas[index]
                .alternativasSelecionadas[i];
            return {
              idRespostaPergunta: perguntaRespostas && perguntaRespostas.id,
              pergunta: {
                id: resposta.pergunta.id,
              },
              tipoRespostaPergunta: alternativa.tipo,
              tituloGrade: resposta.isGrade()
                ? {
                    id: alternativa.idTituloGrade,
                  }
                : null,
              alternativa: {
                id: alternativa.idAlternativa,
              },
              respostaAberta: alternativa.respostaAberta,
              arquivo: alternativa.arquivo,
              data: alternativa.data,
              horario: alternativa.horario,
              ordemSelecao: alternativa.ordem,
            };
          })
        : null;
    };

    /**
     * função que mapeia as respostas perguntas as respostas
     * perguntas locais para o paylaod do server desconsiderando
     * as alternativas (utilizado principalmente para FOTO,VIDEO,DATA,HORARIO,etc)
     */
    const mapSemAlternativa = (resposta: RespostaPergunta) => {
      return resposta.alternativasSelecionadas.map((alternativa) => {
        return {
          idRespostaPergunta: alternativa.id,
          pergunta: {
            id: resposta.pergunta.id,
          },
          tipoRespostaPergunta: alternativa.tipo,
          respostaAberta: alternativa.respostaAberta,
          arquivo: alternativa.arquivo,
          data: alternativa.data,
          horario: alternativa.horario,
          ordemSelecao: alternativa.ordem,
        };
      });
    };

    /**
     * função que mapeia as respostas perguntas as respostas
     * perguntas locais para o paylaod do server considerando
     * as alternativas e os titulos grades
     * (para perguntas de grade)
     */
    const mapGrade = (resposta: RespostaPergunta) => {
      return resposta.alternativasSelecionadas.map((alternativa) => {
        return {
          idRespostaPergunta: alternativa.id,
          pergunta: {
            id: resposta.pergunta.id,
          },
          tipoRespostaPergunta: alternativa.tipo,
          tituloGrade: {
            id: alternativa.idTituloGrade,
          },
          alternativa: {
            id: alternativa.idAlternativa,
          },
          respostaAberta: alternativa.respostaAberta,
          arquivo: alternativa.arquivo, // talvez possa remover, testar
          data: alternativa.data, // talvez possa remover, testar
          horario: alternativa.horario, // talvez possa remover, testar
          ordemSelecao: alternativa.ordem,
        };
      });
    };

    const strategies = {};
    strategies[TipoPergunta.DATA] = mapSemAlternativa;
    strategies[TipoPergunta.HORARIO] = mapSemAlternativa;
    strategies[TipoPergunta.FOTO] = mapSemAlternativa;
    strategies[TipoPergunta.VIDEO] = mapSemAlternativa;

    strategies[TipoPergunta.UNICA] = mapComAlternativa;
    strategies[TipoPergunta.MULTIPLA] = mapComAlternativa;
    strategies[TipoPergunta.MULTIPLA_ORDEM_CITACAO] = mapComAlternativa;
    strategies[TipoPergunta.ESCALA_NUMERICA] = mapComAlternativa;

    strategies[TipoPergunta.GRADE_UNICA] = mapGrade;
    strategies[TipoPergunta.GRADE_MULTIPLA] = mapGrade;

    return strategies[respostaPergunta.pergunta.tipo](respostaPergunta);
  }

  salvarAlteracoes() {
    // tslint:disable-next-line: max-line-length

    if (!this.entrevistaPerguntas) {
      return;
    }

    const respostasPerguntas: RespostaPergunta[] =
      this.entrevistaPerguntas.respostasPerguntas;

    const respostas = respostasPerguntas.map((resposta, index) =>
      this.mapToPayload(resposta, index)
    ).filter(resposta => !!resposta);

    if (this.entrevistaPerguntas.respostasPerguntas.length <= 0) {
      return;
    }

    const respostasPerguntasToSave = respostas.reduce((prev, next) =>
      prev.concat(next)
    );

    const msg =
      "Tem certeza que deseja aplicar as alterações realizadas nas respostas ?";
    this.exibirAlertaTemCerteza(msg).then(() => {
      this.isLoading = true;

      this.entrevistaService
        .atualizarEntrevista(this.idEntrevista, respostasPerguntasToSave)
        // tslint:disable-next-line: ter-arrow-parens
        .subscribe((respostaPesquisa) => {
          this.isLoading = false;

          this.modalService.showModal({
            title: "Entrevista atualizada",
            messageModal: "A entrevista foi atualizada com sucesso!",
            btnTitlePositive: "Entendi",
            icon: "fa-light fa-circle-info",
            isOnlyConfirmation: true,
            positiveCallback: () => this.reload(),
          });
        });
    });
  }

  exibirAlertaTemCerteza(message: string) {
    const temCerteza = message;

    return new Promise((accept, reject) => {
      this.modalService.showModal({
        title: "Confirmação",
        messageModal: temCerteza,
        btnTitlePositive: "Sim, tenho",
        btnTitleNegative: "Cancelar",
        icon: "fa-light fa-circle-info",
        positiveCallback: () => accept(true),
        negativeCallback: () => reject(),
      });
    });
  }

  onToggleAuditada(event: MatSlideToggleChange) {
    const auditada: boolean = event.checked;

    // tslint:disable-next-line: max-line-length
    const msg = `Tem certeza que deseja ${
      auditada ? "marcar" : "desmarcar"
    } a entrevista ${this.idEntrevista} como auditada ?`;

    this.exibirAlertaTemCerteza(msg)

      // caso a confirmação seja positiva
      .then(() => {
        this.isLoading = true;

        this.entrevistaService
          .atualizarAudicao(this.idEntrevista, auditada)
          .subscribe({
            next: (responseBody) => {
              this.isLoading = false;

              // tslint:disable-next-line: max-line-length
              const message = `A entrevista ${
                this.idEntrevista
              } foi atualizada com sucesso. Status: ${
                auditada ? "Auditada" : "Não Auditada"
              }.`;

              this.modalService.showModal({
                title: "Entrevista atualizada",
                messageModal: message,
                btnTitlePositive: "Entendi",
                icon: "fa-light fa-circle-info",
                isOnlyConfirmation: true,
              });
            },
            error: (err: any) => {
              this.modalService.showModal({
                title: "Atualização da Entrevista",
                messageModal:
                  "Um erro ocorreu durante a atualização. Por favor, tente novamente mais tarde.",
                btnTitlePositive: "Entendi",
                icon: "fa-light fa-circle-info",
                isOnlyConfirmation: true,
                positiveCallback: () => this.reload(),
              });
            },
          });
      })
      // caso a confirmação seja negativa
      .catch(() => {
        // resetando valor para o anterior
        this.entrevistaFormGroup.patchValue({
          auditada: !auditada,
        });
      });
  }

  /**
   * @override
   * @param evento
   */
  excluirDados(eventoTabela: EventoTabela): Observable<{}> {
    const idsARemover: string[] = eventoTabela.payload;
    // tslint:disable-next-line:max-line-length
    return this.entrevistaService.removerEntrevistas(idsARemover);
  }

  catchTentativaAdiantamento(tentativa: Adiantamento) {}

  /**
   * ToDo: Remover futuramente caso não quebre nada
   * aparentemente não é mais utilizada em nenhum lugar.
   */
  // formatEntrevistaId(idEntrevista: number) {
  //   return numberFormatter.addZeroBeforeIfLessThan10(idEntrevista);
  // }

  goBack() {
    this.location.back();
  }

  async canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.modalService.showModal({
        messageModal: "Deseja sair da tela de auditoria de respostas ?",
        title: "Atenção!",
        btnTitlePositive: "Sim",
        btnTitleNegative: "Não",
        negativeCallback: () => resolve(false),
        positiveCallback: () => resolve(true),
      });
    });
  }
}
