<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<div class="result-pesquisa-container">
    <div class="result-pesquisa-header">
        <div class="result-pesquisa-row">
            <div class="accordion">
                <app-filterable-select filterPlaceholder="Busque pelo título da pesquisa" label=""
                    placeholder="Selecione uma pesquisa para ver seus resultados " [data]="surveyList"
                    (changeSelectedItem)="handleChange($event)" [selectedItem]="selectedSurvey"
                    (changeInputText)="changeInputText($event)" usingBy="RESULTADOS"
                    (onScrollBottom)="onScrollBottom($event)">
                </app-filterable-select>
            </div>
            <div class="header-title">
                <div class="titles">
                    <h2>Resultados</h2>
                    <p>Acompanhe as estátisticas de sua(s) pesquisa(s) em tempo real</p>
                </div>
            </div>

        </div>
        <div class="result-pesquisa-row">
            <div class="orientation">
                <span (click)="selectLayout('GRID')" [ngClass]="layout==='GRID' ? 'selected-layout' : ''"
                    class="grid"><i class="fa-light fa-grid-2"></i></span>
                <span (click)="selectLayout('COLUMN')" [ngClass]="layout==='COLUMN' ? 'selected-layout' : ''"
                    class="column"><i class="fa-light fa-diagram-cells"></i></span>
            </div>
            <div class="status">
                <span>
                    <p>Cliente:</p>
                    <label>{{results.cliente?.nome}}</label>
                </span>
                <span>
                    <p>Status:</p>
                    <label class="survey-status">{{ surveyStatus }}</label>
                </span>
                <span>
                    <p>Coletas realizadas:</p>
                    <label>
                      {{results.pesquisa?.coletas_realizadas}}/{{results.pesquisa?.amostras_totais}}
                    </label>
                </span>
                <span>
                    <p>Data:</p>
                    <label>{{dateFormatter(results.pesquisa?.data_inicio)}} a {{dateFormatter(results.pesquisa?.data_fim)}}</label>
                </span>
            </div>
        </div>
    </div>
    <div [ngClass]="layout==='GRID' ? 'chart-cards-grid' : 'chart-cards-column'">
        <app-results-chart-card *ngFor="let item of results.perguntas" [questionData]="item"
            [layout]="layout"></app-results-chart-card>
    </div>
    <div class="no-has-questions" *ngIf="results.perguntas && !results.perguntas.length">
      <svg width="189" height="94" viewBox="0 0 189 94" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-left">
        <rect width="189" height="94" fill="#1E1E1E"/>
        <path d="M-330 -1509C-330 -1510.1 -329.105 -1511 -328 -1511H3411C3412.1 -1511 3413 -1510.1 3413 -1509V2595C3413 2596.1 3412.1 2597 3411 2597H-328C-329.104 2597 -330 2596.1 -330 2595V-1509Z" fill="#F9FAE9"/>
        <path d="M-328 -1510H3411V-1512H-328V-1510ZM3412 -1509V2595H3414V-1509H3412ZM3411 2596H-328V2598H3411V2596ZM-329 2595V-1509H-331V2595H-329ZM-328 2596C-328.552 2596 -329 2595.55 -329 2595H-331C-331 2596.66 -329.657 2598 -328 2598V2596ZM3412 2595C3412 2595.55 3411.55 2596 3411 2596V2598C3412.66 2598 3414 2596.66 3414 2595H3412ZM3411 -1510C3411.55 -1510 3412 -1509.55 3412 -1509H3414C3414 -1510.66 3412.66 -1512 3411 -1512V-1510ZM-328 -1512C-329.657 -1512 -331 -1510.66 -331 -1509H-329C-329 -1509.55 -328.552 -1510 -328 -1510V-1512Z" fill="white" fill-opacity="0.1"/>
        <g clip-path="url(#clip0_266_14477)">
        <rect width="1440" height="759" transform="translate(-132 -259)" fill="#F4F4F4"/>
        <rect width="1230" height="94" fill="white"/>
        <mask id="mask0_266_14477" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-25" y="-122" width="344" height="286">
        <path d="M103.702 49.5981H-25V106.799H-3.54964V78.1986H82.2518V106.799H103.702V49.5981Z" [attr.fill]="svgColor" />
        <path d="M168.049 49.5981H125.148V78.1986H168.049V49.5981Z" [attr.fill]="svgColor"/>
        <path d="M125.153 20.9976H103.703V49.598H125.153V20.9976Z" [attr.fill]="svgColor"/>
        <path d="M189.499 20.9976H168.049V49.598H189.499V20.9976Z" [attr.fill]="svgColor"/>
        <path d="M210.949 -36.1987H82.252V20.9975H103.702V-7.59825H189.499V20.9975H210.949V-36.1987Z" [attr.fill]="svgColor"/>
        </mask>
        <g mask="url(#mask0_266_14477)">
        <rect width="189" height="94" [attr.fill]="svgColor"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_266_14477">
        <rect width="1440" height="759" fill="white" transform="translate(-132 -259)"/>
        </clipPath>
        </defs>
      </svg>
      <h4>Ainda não existem resultados para esta pesquisa</h4>
      <svg width="191" height="94" viewBox="0 0 191 94" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-right">
        <rect width="191" height="94" fill="#1E1E1E"/>
        <path d="M-1369 -1509C-1369 -1510.1 -1368.1 -1511 -1367 -1511H2372C2373.1 -1511 2374 -1510.1 2374 -1509V2595C2374 2596.1 2373.1 2597 2372 2597H-1367C-1368.1 2597 -1369 2596.1 -1369 2595V-1509Z" fill="#F9FAE9"/>
        <path d="M-1367 -1510H2372V-1512H-1367V-1510ZM2373 -1509V2595H2375V-1509H2373ZM2372 2596H-1367V2598H2372V2596ZM-1368 2595V-1509H-1370V2595H-1368ZM-1367 2596C-1367.55 2596 -1368 2595.55 -1368 2595H-1370C-1370 2596.66 -1368.66 2598 -1367 2598V2596ZM2373 2595C2373 2595.55 2372.55 2596 2372 2596V2598C2373.66 2598 2375 2596.66 2375 2595H2373ZM2372 -1510C2372.55 -1510 2373 -1509.55 2373 -1509H2375C2375 -1510.66 2373.66 -1512 2372 -1512V-1510ZM-1367 -1512C-1368.66 -1512 -1370 -1510.66 -1370 -1509H-1368C-1368 -1509.55 -1367.55 -1510 -1367 -1510V-1512Z" fill="white" fill-opacity="0.1"/>
        <g clip-path="url(#clip0_266_14477)">
        <rect width="1440" height="759" transform="translate(-1171 -259)" fill="#F4F4F4"/>
        <rect width="1230" height="94" transform="translate(-1039)" fill="white"/>
        <mask id="mask0_266_14477" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-21" y="-65" width="344" height="286">
        <path d="M64.8016 20.8013H21.9009V49.4018H64.8016V20.8013Z" [attr.fill]="svgColor"/>
        <path d="M21.9008 -7.79883H0.450439V20.8016H21.9008V-7.79883Z" [attr.fill]="svgColor"/>
        <path d="M86.2519 -7.79883H64.8015V20.8016H86.2519V-7.79883Z" [attr.fill]="svgColor"/>
        <path d="M129.153 77.9976H107.702V106.598H129.153V77.9976Z" [attr.fill]="svgColor"/>
        <path d="M193.499 77.9976H172.049V106.598H193.499V77.9976Z" [attr.fill]="svgColor"/>
        <path d="M214.949 20.8013H86.2517V77.9975H107.702V49.4018H193.499V77.9975H214.949V20.8013Z" [attr.fill]="svgColor"/>
        </mask>
        <g mask="url(#mask0_266_14477)">
        <rect width="191" height="94" [attr.fill]="svgColor"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_266_14477">
        <rect width="1440" height="759" fill="white" transform="translate(-1171 -259)"/>
        </clipPath>
        </defs>
      </svg>
    </div>
</div>
