import { OnInit, Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CURRENT_HIRER } from "app/app.config";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { ThemeService } from "styles/services/theme.service";

@Component({
  selector: "app-integracao-contratante",
  templateUrl: "./integracao-contratante.component.html",
  styleUrls: ["./integracao-contratante.component.scss"],
})
export class IntegracaoContratanteComponent implements OnInit {
  /**
   * Subscriptions a serem fechadas com a morte do componente
   */
  subscriptions: Subscription[] = [];

  // tslint:disable-next-line: max-line-length
  constructor(
    private activatedRouter: ActivatedRoute,
    private securityService: SecurityService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private themeService: ThemeService,
    @Inject(CURRENT_HIRER) private currentHirer: BehaviorSubject<string>
  ) {}

  ngOnInit() {
    const queryParamsSubscription =
      this.activatedRouter.queryParamMap.subscribe((params) => {
        const contratante = params.get("schema");

        if (contratante) {
          this.doAuthentication(contratante);
        }
      });

      this.subscriptions.push(queryParamsSubscription);
  }

  onAuthenticationFailed(error) {
    this.errorHandler.handleError(error, "Token inválido");
  }

  onAuthenticationSuccess() {
    const hirer = this.securityService.getAuthenticatedUserSubtenant();
    this.currentHirer.next(hirer);
    this.router.navigateByUrl("");
  }

  doAuthentication(schemaName: string) {
    const callbacks = {
      next: () => this.onAuthenticationSuccess(),
      error: (error) => this.onAuthenticationFailed(error),
    };

    const authenticationSubscription = this.securityService
      .switchContractorAuthenticate(schemaName)
      .subscribe(callbacks);

    this.subscriptions.push(authenticationSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
