<div class="header-container">

  <div class="left-container">
    <h3 class="survey-title">{{ surveyTitle }}</h3>

    <section class="audit-status">
      <h4>Situação da pesquisa</h4>
      <div class="survey-status-wrapper">
        <span class="survey-status-circle" [style.backgroundColor]="surveyStatus.color"></span>
        <p>{{ surveyStatus.status }}</p>
      </div>
    </section>
  </div>

  <div class="right-contaienr">
    <section class="audit-collections">
      <div class="audit-collections-data">
        <h4>Coletas Auditadas</h4>
        <span>
          <p>{{0 || auditedCollections.auditadas}} coletas</p>
          <p>{{0 || percentualValue}}%</p>
        </span>
      </div>

      <div class="audit-progress">
        <div class="audit-progress-value" [style.width.%]="0 || percentualValue"></div>
      </div>

    </section>
  </div>

</div>

