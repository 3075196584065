import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { PerfilEntrevistado } from '../../../../../../pesquisa-old/cadastro/model/perfilEntrevistado';
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { Localidade } from '../localidade';
import { LocalidadesService } from '../localidades.service';
import searchService from '../searchService';

@Component({
  selector: 'app-localidades-wrapper',
  templateUrl: './localidades-wrapper.component.html',
  styleUrls: ['./localidades-wrapper.component.scss'],
})
export class LocalidadesWrapperComponent implements OnInit, OnDestroy {

  /**
   * perfis entrevistados atuais
   */
  perfisEntrevistados: PerfilEntrevistado[] = [];

  /**
   * localidades sendo exibidas atualmente
   */
  localidades: Localidade[] = [];

  /**
   * observable que fica escutando por alterações em perfis entrevistados
   */
  perfilEntrevistadoStoreSubscription: Subscription;

  @Input() filtrarLocalidadesOperadoresSubject: Subject<string>;
  filtrarLocalidadesOperadoresSubscription: Subscription;

  constructor(protected store: Store<CadastroPesquisaStoreState>,
              private localidadeService: LocalidadesService,
              private route: ActivatedRoute) { }

  getIdPesquisa() {
    return Number(this.route.snapshot.queryParamMap.get('id'));
  }

  ngOnInit() {

    this.subscribeToStore();
    this.initFiltrarLocalidadesOperadorSubject();

    // this.idPesquisa = this.getIdPesquisa();
    // console.log('[LocalidadesWrapperComonent.ngOnInit] idPesquisa: ', this.idPesquisa);

    /**
     * apenas se subscreve na store após carregar as localidades do servidor,
     * se a requisição para obter as localidades fosse realizada no callback registrado
     * da subscrição na store, provavelmente o front-end faria um ataque DDOs na API.
     */
    /* this.localidadeService.findAllLocalidadesByIdPesquisa(this.idPesquisa)
      .subscribe((localidades: Localidade[]) => {

        console.log('[LocalidadesWrapperComonent.ngOnInit] localidades: ', localidades);
        this.localidades = localidades;
        this.perfilEntrevistadoStoreSubscription =
      });

    this.initFiltrarLocalidadesOperadorSubject(); */
  }

  ngOnDestroy() {
    if (this.perfilEntrevistadoStoreSubscription) {
      this.perfilEntrevistadoStoreSubscription.unsubscribe();
    }
  }

  initFiltrarLocalidadesOperadorSubject() {
    this.filtrarLocalidadesOperadoresSubscription = this.filtrarLocalidadesOperadoresSubject.pipe(
      debounceTime(200),
    ).subscribe((queryText: string) => {

      // tslint:disable-next-line: max-line-length
      console.log('[LocalidadesWrapperComponent.filtrarLocalidadesOperadoresSubject] queryText', queryText);

      if (this.localidades) {
        this.localidades = searchService.filter(queryText, this.localidades);
        console.log('[LocalidadesWrapperComponent] after filter: ', this.localidades);
      }
    });
  }

  subscribeToStore(): Subscription {
    return this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        // tslint:disable-next-line: max-line-length
        map(cadastroPesquisa => cadastroPesquisa ? cadastroPesquisa : new CadastroPesquisaStoreState()),
      )
      .subscribe((cadastroPesquisa: CadastroPesquisaStoreState) => {

        this.perfisEntrevistados = cadastroPesquisa.pesquisa.perfisEntrevistados;
        // tslint:disable-next-line: max-line-length
        console.log('[LocalidadesWrapperComponent.subscribeToStore.onCadastroPesquisa] perfisEntrevistados: ', this.perfisEntrevistados);

        this.localidades = cadastroPesquisa.dadosGerenciadorCotas.localidades;

        // tslint:disable-next-line: max-line-length
        console.log('[LocalidadesWrapperComponent.subscribeToStore.onLocalidades] localidades: ', this.localidades);

        if (!this.localidades || this.localidades.length === 0) {
          return;
        }

        this.localidadeService.merge(this.localidades, this.perfisEntrevistados);
        // tslint:disable-next-line: max-line-length
        console.log('[LocalidadesWrapperComponent.subscribeToStore.onLocalidades] merged localidades: ', this.localidades);
      });
  }

}
