import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-audit-justification-modal',
  templateUrl: './audit-justification-modal.component.html',
  styleUrls: ['./audit-justification-modal.component.scss']
})
export class AuditJustificationModalComponent implements OnInit {
  constructor() {}

  @Input() collectionId: number;
  @Output() saveEvent = new EventEmitter<string>();
  @Output() cancelEvent = new EventEmitter();

  withFocus: boolean = false;

  publishMessage: string = "";

  btnStructure = {
    padding: '8px 24px',
    fontSize: '12px'
  }

  publishErrors = {
    maxlength: {
      value: false,
      message: "Limite de caractéres excedido",
    },
    empty: {
      value: false,
      message: "Campo obrigatório",
    },

    anyError(): boolean {
      return this.maxlength.value || this.empty.value;
    },

    getErrorMessage() {
      return (
        (this.maxlength.value && this.maxlength.message) || this.empty.message
      );
    },
  };

  ngOnInit(): void {
  }

  /**
   * Atribui os possíveis erros de usuário que podem acontecer
   * como:
   *
   * - O usuário passa do limite de caracteres de uma mensagem (255)
   * - O usuário tenta publicar uma mensagem vazia
   */
  checkPublishErrors(set: boolean = false) {
    if (set && !this.publishMessage.length) {
      this.publishErrors.empty.value = true;
    } else if (this.publishErrors.empty.value) {
      this.publishErrors.empty.value = false;
    }

    if (this.publishMessage.length > 255) {
      this.publishErrors.maxlength.value = true;
    } else if (this.publishErrors.maxlength.value) {
      this.publishErrors.maxlength.value = false;
    }

    return this.publishErrors.anyError();
  }

  /**
   * Quando o usuário digita chama a função para checar possiveis erros.
   */
  handleMessageInput(event) {
    this.checkPublishErrors();
  }

  /**
   * Limpa a mensagem digitada
   */
  resetMessage() {
    this.publishMessage = "";
  }

  changeFocus(): void {
    this.withFocus = !this.withFocus;
  }

  /**
   * Emite o evento do botão de salvamento
   * Essa emissão irá chamar o positveCallback no componente audit-verdict
   */
  handleSave(): void {
    const hasError = this.checkPublishErrors(true);
    if(!hasError) {
      this.saveEvent.emit(this.publishMessage);
    }
  }

  /**
   * Emite o eveento do botão de cancelar
   * Essa emissão irá settar o showModal para false
   */
  handleCancel(): void {
    this.cancelEvent.emit();
  }

}
