import { PesquisaCadastro } from '../../model/pesquisaCadastro';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, AbstractControl } from '@angular/forms';
import { InputValidatorFactory } from '../../inputs/inputValidatorFactory';
import { TipoInput } from '../../inputs/tipoInput';
import { ElementoQuestionario } from '../../model/elementoQuestionario';
import cotasValidator from 'app/util/validador/alternativa/cotasValidator';
import validadorMarcacao from 'app/util/validador/pergunta/validadorMarcacao';
import addPuloEDiferenteValidators from '../../util/addPuloEDiferenteValidators';

export function pesquisaToMarcacoesFormGroup(pesquisa: PesquisaCadastro): UntypedFormGroup {

  const formGroup = new UntypedFormGroup({});

  // tslint:disable-next-line: max-line-length
  formGroup.addControl('titulo', new UntypedFormControl(pesquisa.descricaoPesquisa.titulo, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO)));
  // tslint:disable-next-line: max-line-length
  formGroup.addControl('tituloCurto', new UntypedFormControl(pesquisa.descricaoPesquisa.tituloCurto, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO_CURTO)));
  // tslint:disable-next-line: max-line-length
  formGroup.addControl('textoInicial', new UntypedFormControl(pesquisa.textoInicial, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_TEXTO_INICIAL)));
  formGroup.addControl('elementosQuestionario', new UntypedFormArray([]));

  pesquisa.elementosQuestionario.forEach((elementoQuestionario) => {
    // tslint:disable-next-line: max-line-length
    const elementoQuestioarioFg = ElementoQuestionario.getInitializedControl(elementoQuestionario);
    (<UntypedFormArray>formGroup.get('elementosQuestionario')).push(elementoQuestioarioFg);
  });

  setUpValidators(formGroup);

  return formGroup;
}

/**
   * Função que cuida dos validadores ativos no passo
   * de marcação, tanto os que estarão ativos quanto
   * os que deverão ser desabilitados
   */
function setUpValidators(formGroup: UntypedFormGroup) {

  const elementosQuestionariFormArray = <UntypedFormArray>formGroup.controls.elementosQuestionario;
  // tslint:disable-next-line: max-line-length
  const secoesFg: UntypedFormGroup[] = elementosQuestionariFormArray.controls.map((elementoQuestionarioFg: UntypedFormGroup) => {
    return <UntypedFormGroup>elementoQuestionarioFg.controls.secao;
  });

  const perguntasFgMatriz = secoesFg.map((secaoFg: UntypedFormGroup) => {
    const elementosSecaoFormArray = <UntypedFormArray>secaoFg.controls.elementosSecao;
    const perguntasFg = elementosSecaoFormArray.controls.map((elementoSecao: UntypedFormGroup) => {
      return <UntypedFormGroup>elementoSecao.controls.pergunta;
    });
    return perguntasFg;
  });

  const perguntasFgArray: UntypedFormGroup[] = [].concat(...perguntasFgMatriz);

  perguntasFgArray.forEach((perguntaFg) => {

    // handlerPuloPergunta(perguntaFg);
    handleCotas(perguntaFg);
    handlePuloEDiferente(perguntaFg);
  });

}

function handlePuloEDiferente(perguntaFg: UntypedFormGroup) {
  addPuloEDiferenteValidators(perguntaFg);
}

// function handlerPuloPergunta(perguntaFg: FormGroup) {
//   /**
//    * Adicionando validadores de pergunta marcação, caso ela possua pulo a nível de pergunta.
//    */
//   if (perguntaFg.controls.possuiPuloPergunta.value) {

//     const marcacaoPerguntaControl = <FormGroup>perguntaFg.controls.marcacaoPergunta;
//     const perguntaDestinoControl = <FormGroup>marcacaoPerguntaControl.controls.perguntaDestino;

//     perguntaDestinoControl.setValidators([validadorMarcacao]);
//     perguntaDestinoControl.updateValueAndValidity();

//   }
// }

function handleCotas(perguntaFg: UntypedFormGroup) {
  if (perguntaFg.controls.possuiCota.value) {

    const alternativasFormArray = <UntypedFormArray>perguntaFg.get('alternativas');

    const cotaPercentualControls: UntypedFormControl[] = [];
    alternativasFormArray.controls.forEach((alternativaControl: UntypedFormGroup) => {

      const marcacaoFg = <UntypedFormGroup>alternativaControl.controls.marcacao;

      if (marcacaoFg) {
        /**
       * Habilitando os inputs da categoria marcação
       * pois estes inicialmente estão desabilitados
       */
        marcacaoFg.enable();

        const cotaPercentualControl = <UntypedFormControl>marcacaoFg.controls.cotaPercentual;

        /**
         * Coletando os controls das cotas percentuais
         */
        cotaPercentualControls.push(cotaPercentualControl);
      }

    });

    /**
     * Adicionando os validadores de cota para cada
     * alternativa
     */
    alternativasFormArray.controls.forEach((alternativaControl: UntypedFormGroup) => {

      const marcacaoFg = <UntypedFormGroup>alternativaControl.controls.marcacao;
      if (!marcacaoFg) {
        return;
      }
      const cotaPercentualControl = <UntypedFormControl>marcacaoFg.controls.cotaPercentual;

      /**
       * Na inicializacao do componente uma pesquisa vazia
       * é criada, por isso pode nao ter a cotaPercentualControl
       */
      if (cotaPercentualControl) {
        cotaPercentualControl.setValidators([cotasValidator(cotaPercentualControls)]);
        cotaPercentualControl.updateValueAndValidity();
      }
    });
  }
}
