<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-permissoes"></i>
      <h2 [routerLink]="'/grupo'" routerLinkActive="active">Grupos</h2>
      <span>/</span>
      <h4>Grupos cadastrados</h4>
    </div>
    <div class="action-header" *ngIf="getAuthenticatedUserAuthorities().includes(authority.CADASTRAR_GRUPO)">
      <button class="sg-btn sg-secondary" [routerLink]="'/grupo/cadastro'" title="Adicionar novo grupo">ADICIONAR
        NOVO</button>
    </div>
  </div>
  <div class="content-cadastrados">
    <app-tabela-filtravel
      #tabela
      [placeholder]="placeholder"
      (eventEmitter)="manipularEventoTabela($event)"
      [filterService]="grupoService"
      [removePermissions]="grupoRemovePermissions"
      [nomeAtributosExibidos]="nomeAtributosTabela"
      [nomeColunas]="nomeColunasTabela"
      [exibirOpcaoDownload]="false"
      [initialValue]="initialSearchValue"
    >
    </app-tabela-filtravel>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
