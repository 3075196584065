import { Component, OnInit } from "@angular/core";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { IFilterSelect } from "app/componentes/filter-select/filter-select";
import { IInfoCardData, InfoCardDataProps } from "app/componentes/info-card/info-card.component";
import { NotificatorService } from "app/notificador/notificator.service";
import { IListHirer } from "../interfaces";
import { ContratanteService } from "../services/contratante.service";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { Authority } from "app/infraestrutura/security/authority";
import { HirerStatusHeaderIndex, PAGE_SIZE } from "../constants";


@Component({
  selector: "app-listagem-contratante",
  templateUrl: "./listagem-contratante.component.html",
  styleUrls: ["./listagem-contratante.component.scss"],
})
export class ListagemContratanteComponent implements OnInit {
  hirerList: IListHirer[];

  currentPage: number = 1;
  totalPages: number = 1;
  filterSelectIsOpen = false;
  selectedFilter = "";

  page = 1;
  totalHirer: number = 0;

  inputSearch = "";
  isLoading = false;
  timeoutId;

  // authorities flags
  withRegisterAuthorities: boolean = false;

  // Variáveis de inicialização do filtro de situação
  filterConf = {
    icon: FilterIcon.FUNNEL,
    type: FilterTypes.RADIO,
  };

  // Configuração de filtragem das pesquisas por situação
  filterOptions: IFilterSelect[] = [
    { id: 1, label: "Ativo", key: "ATIVO" },
    { id: 2, label: "Inativo", key: "INATIVO" },
  ];

  // Para garantir a persistência das seleções de filtro selecionadas, mesmo quando o componente de filtragem
  // for destruído, guardamos aqui o estado de seleção corrente.
  selectedFilterOptions: IFilterSelect[] = [];

  infoCardData: InfoCardDataProps;

  constructor(
    private contratanteService: ContratanteService,
    private notificator: NotificatorService,
    private securityService: SecurityService,
  ) {}

  ngOnInit(): void {
    this.getInfoCardData();
    // Inicia os dados da lista de contratantes
    this.getHirerList();
    // Inicializa flags de controle de permissões de acesso
    this.initAuthoritiesConfig();
  }

  // Recupera os dados do header
  getInfoCardData() {
    this.contratanteService.getHeaderData().subscribe({
      next: (data) => {
        this.infoCardData = {
          title: 'Contratantes',
          text: 'Cadastre e gerencie as contratantes dos serviços Tensai',
          data: [
            {
              title: 'Cadastrados',
              value: data.total
            },
            {
              title: 'Ativos',
              value: data.ativos
            },
            {
              title: 'Inativos',
              value: data.inativos
            }
          ]
        }
      },
      error: (err) => { console.log(err) }
    });
  }

  /**
   * Atualiza os dados do header ao mudar o status de um cliente
   */
  updateHeaderData(status: boolean) {
    if(status) {
      this.infoCardData.data[HirerStatusHeaderIndex.ATIVOS].value++
      this.infoCardData.data[HirerStatusHeaderIndex.INATIVOS].value--
    } else {
      this.infoCardData.data[HirerStatusHeaderIndex.INATIVOS].value++
      this.infoCardData.data[HirerStatusHeaderIndex.ATIVOS].value--
    }
  }

  /**
   * Atualiza os dados do header quando um contratante
   * sofrer mudança no status
   */
  onStatusChange($event) {
    const hirerStatus = $event?.status
    this.updateHeaderData(hirerStatus)
  }

  /**
   * Inicializa flags de controle de permissões de acesso
   */
  initAuthoritiesConfig() {
    const authorities = this.securityService.getAuthenticatedUserAuthorities();

    this.withRegisterAuthorities = authorities.some(authority => [Authority.CADASTRAR_CONTRATANTE, Authority.CADASTRAR_INSTANCIA].includes(authority));
  }

  /**
   * Recupera do back-end o valor total de contratantes que estão sendo
   * exibidos
   */
  getHirerTotal(keywords: string[], ativo: boolean | null): void {
    this.contratanteService.getContractorTotal(keywords, ativo).subscribe({
      next: (data) => {
        this.totalHirer = data,
        this.getTotalPages(data)
      },
      error: (err) => console.error("erro:", err)
    })
  }

  /**
   * Calcula o total de páginas baseado no total de contratantes
   */
  getTotalPages(data: number): void {
    this.totalPages = Math.ceil(data / PAGE_SIZE)
  }

  get entriesPerPage(): number {
    return PAGE_SIZE;
  }
  /**
   * Recupera do back-end as informações de todos os contratantes.
   * Cada requisição recupera 10 contratantes por ordem de cadasto
   */
  getHirerList() {
    // isLoading controla o estado do load spinner
    this.isLoading = true;

    // Verifica o status da pesquisa para aplicar como filtro
    const ativo =
      this.selectedFilterOptions.length === 0
        ? null
        : this.selectedFilterOptions[0].label === "Ativo";

    // Palavras do filtro de busca para o filtro
    const keywords = this.inputSearch.split(" ");

    this.getHirerTotal(keywords, ativo);

    this.contratanteService
      .getContractorList(keywords, ativo, this.currentPage - 1)
      .subscribe({
        next: (response: IListHirer[]) => {
          if (response.length) {
            this.hirerList = response;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.notificator.showError(
            "Houve um problema",
            "Houve um problema na requisição"
          );
          console.error("erro:", error);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  // handle de input de busca
  handleSearch(event) {
    const {
      target: { value },
    } = event;
    this.inputSearch = value;

    // A cada caractere inserido no input o timeout é zerado pra que seja impedido a execução dos metodos internos
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      // Quando o usuário para de digitar é feita a requisição e atualizado os dados, a pagina volta pra 1.
      this.getHirerList();
      this.currentPage = 1;
    }, 2000);
  }

  // Função para alterar estado de seleção do seletor
  handleSelectOptionClick($event: IFilterSelect[]) {
    this.selectedFilterOptions = [...$event];

    this.getHirerList();
    this.currentPage = 1;
  }

  // navega entre as páginas
  changePage($event: number) {
    // Só irá realizar a requisição e atribuir a nova página caso a paginação clicada seja diferente da atual.
    if (this.currentPage !== $event) {
      this.currentPage = $event;

      // re-call survey list data fetching after update pagination
      this.getHirerList();
    }
  }
}
