import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ECharts, EChartsOption } from "echarts";
import { toEchartOption } from "./toEchartOption";
import { IResultAlternative } from "../../results-interface";
import { orderNumericScale } from "../utils/parseDate";

@Component({
  selector: "app-pie",
  templateUrl: "./pie.component.html",
  styleUrls: ["./pie.component.scss"],
})
export class PieComponent implements OnInit {
  @Input() alternatives;
  @Input() showModal: boolean;
  @Input() layout: string;
  chartOption: EChartsOption = {};
  echartsInstance: ECharts;

  constructor() {}

  ngOnInit(): void {
    if (this.alternatives) {
      this.sortNumericScale();
      this.getChartsValue();
    }
  }

  /**
   * Ordena as respostas do tipo escala numérica por ordem decrescente
   */
  sortNumericScale() {
    if (this.alternatives[0].tipo === "ESCALA_NUMERICA") {
      this.alternatives = orderNumericScale(this.alternatives);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.showModal &&
        changes.showModal.currentValue !== changes.showModal.previousValue) ||
      (changes.layout &&
        changes.layout.currentValue !== changes.layout.previousValue)
    ) {
      this.resizeChart();
    }
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
  }

  resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  getChartsValue() {
    let chartData: Object[] = [];
    let numberResult = [];
    this.alternatives.forEach(
      ({
        descricao: name,
        resultado_percentual: value,
        resultado_numerico,
      }) => {
        chartData.push({
          name,
          value,
          type: "bar",
        });
        numberResult.push(resultado_numerico);
      }
    );
    this.chartOption = toEchartOption(chartData, numberResult);
  }
}
