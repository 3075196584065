import { VoidEvent } from "app/modulos/pesquisa-beta/eventbus/event";

/**
 * Evento lançado quando houver uma solicitação ao servidor
 * para leitura de dados
 */
export class PendingLoadingEvent extends VoidEvent {
  static TYPE = "pesquisa.cadastro.load.running";

  getType(): string {
    return PendingLoadingEvent.TYPE;
  }
}

/**
 * Evento lançado quando uma solicitação enviada para
 * o servidor retornar com sucesso
 */
export class CompletedLoadingEvent extends VoidEvent {
  static TYPE = "pesquisa.cadastro.load.success";

  getType(): string {
    return CompletedLoadingEvent.TYPE;
  }
}

/**
 * Evento lançado quando uma solicitação enviada para
 * o servidor retornar com falha
 */
export class FailedLoadingEvent extends VoidEvent {
  static TYPE = "pesquisa.cadastro.load.failed";

  getType(): string {
    return FailedLoadingEvent.TYPE;
  }
}
