export enum ActionType {
    /**
     * Ações genéricas
     */
    ADICIONAR = 'ADICIONAR',
    ALTERAR = 'ALTERAR',
    REMOVER = 'REMOVER',
    SELECIONAR = 'SELECIONAR',
    /**
     * Ações especializadas
     */
    /**
     * Q U E S T I O N A R I O
     */
    ALTERAR_TITULOS = 'ALTERAR_TITULOS',
    ALTERAR_ANALISE_PESQUISA = 'ALTERAR_ANALISE_PESQUISA',
    ALTERAR_CONFIGURACAO_PESQUISA = 'ALTERAR_CONFIGURACAO_PESQUISA',
    ALTERAR_DESCRICAO_PESQUISA = 'ALTERAR_DESCRICAO_PESQUISA',
    ALTERAR_CLIENTE = 'ALTERAR_CLIENTE',
    MOVER_PERGUNTA = 'MOVER_PERGUNTA',
    REORDENAR_PERGUNTA = 'REORDENAR_PERGUNTA',
    REORDENAR_PERGUNTA_COTA = 'REORDENAR_PERGUNTA_COTA',
    ALTERAR_STATUS_PESQUISA = 'ALTERAR_STATUS_PESQUISA',
    SELECIONAR_PERGUNTA = 'SELECIONAR_PERGUNTA',
    SWITCH_ORDENACAO_COTAS = 'SWITCH_ORDENACAO_COTAS',
    /**
     * C O N T R O L E  G E R A L
     */
    DEFINIR_PASSO_ATUAL = 'DEFINIR_PASSO_ATUAL',
    DEFINIR_EXIBICAO_VALIDACOES = 'DEFINIR_EXIBICAO_VALIDACOES',
    EXIBIR_VALIDACOES_RASCUNHO = 'EXIBIR_VALIDACOES_RASCUNHO',
    CLEAN_STORE = 'CLEAN_STORE',
    DEFINIR_PESQUISA = 'DEFINIR_PESQUISA',
    PREPARAR_PARA_SALVAR = 'PREPARAR_PARA_SALVAR',
    SET_PREPARADO_PARA_SALVAR = 'SET_PREPARADO_PARA_SALVAR',
    /**
     * V I N C U L O S
     */
    ADICIONAR_LOCALIDADE = 'ADICIONAR_LOCALIDADE',
    ADICIONAR_LOCALIDADES = 'ADICIONAR_LOCALIDADES',
    VISUALIZAR_LOCALIDADE = 'VISUALIZAR_LOCALIDADE',
    EXPANDIR_FILHOS = 'EXPANDIR_FILHOS',
    CARREGAR_FILHOS = 'CARREGAR_FILHOS',
    ALTERAR_VINCULO_LOCALIDADE_COTA_PERCENTUAL = 'ALTERAR_VINCULO_LOPERADORCOTA_PERCENTUAL',
    REMOVER_VINCULO_LOCALIDADE = 'REMOVER_VINCULO_LOCALIDADE',
    SELECIONAR_VINCULO_LOCALIDADE = 'SELECIONAR_VINCULO_LOCALIDADE',
    ADICIONAR_VINCULO_OPERADOR = 'ADICIONAR_VINCULO_OPERADOR',
    ALTERAR_VINCULO_OPERADOR_COTA_PERCENTUAL = 'ALTERAR_VINCULO_LOCALIDADE_COTA_PERCENTUAL',
    REMOVER_VINCULO_OPERADOR = 'REMOVER_VINCULO_OPERADOR',
    SELECIONAR_OPERADOR = 'SELECIONAR_OPERADOR',
    SINCRONIZAR_PASSO_LOCALIDADES = 'SINCRONIZAR_PASSO_LOCALIDADES',
    SINCRONIZAR_VINCULO_LOCALIDADES_FORM_GROUP = 'SINCRONIZAR_VINCULO_LOCALIDADES_FORM_GROUP',
    CARREGAR_LOCALIDADES_VINCUOS = 'CARREGAR_LOCALIDADES_VINCUOS',
    DEFINIR_ESTADO_VALIDACAO = 'DEFINIR_ESTADO_VALIDACAO',
    BLOQUEAR_PASSOS_CADASTRO = 'BLOQUEAR_PASSOS_CADASTRO',
    /**
     * GERENCIADOR COTAS
     */
    TRANSFERIR_COTA = 'TRANSFERIR_COTA',
    AJUSTAR_COTA = 'AJUSTAR_COTA',
    SOLICITAR_LOCALIDADES = 'SOLICITAR_LOCALIDADES',
    CARREGAR_LOCALIDADES = 'CARREGAR_LOCALIDADES',
}
