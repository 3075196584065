import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoPergunta } from '../../../../../modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { RespostaPergunta } from '../model/respostaPergunta';
import { RespostaTrBody } from '../model/respostaTrBody';

@Component({
  selector: 'app-coluna-resposta-resumo',
  templateUrl: './coluna-resposta-resumo.component.html',
  styleUrls: ['./coluna-resposta-resumo.component.scss']
})
export class ColunaRespostaResumoComponent implements OnInit {

  @Input() trBody: RespostaTrBody;

  @Output() private visualizarResposta: EventEmitter<RespostaPergunta> = new EventEmitter();

  tipoPergunta = TipoPergunta;

  ngOnInit() { }

  visualizarRespostas(respostaPergunta: RespostaPergunta): void {
    this.visualizarResposta.emit(respostaPergunta);
  }

}
