export enum TipoMarcacao {

    COTA = 'COTA',
    DIFERENTE = 'DIFERENTE',
    PULO = 'PULO',
    PULO_PERGUNTA = 'PULO_PERGUNTA',
    OBRIGATORIA = 'OBRIGATORIA',
    DISCO = 'DISCO',

}
