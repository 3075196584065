<div [formGroup]="formGroup" class="containerConfigInput sg-input-group">
  
  <!-- <div class="teste123"> -->

    <!-- <div class="alternativeInput"> -->

      <div class="configInput">
        <input mascara-primeiro-digito-sem-espaco title="nome da opção da alternativa" type="text"
          formControlName="descricao" placeholder="Nome da opção">
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
          Deve ser informado um nome de até 250 caracteres para a alternativa
        </span>
      </div>

      <div class="alternativeInput">
        <app-tipo-resposta-input ngDefaultControl [formGroup]="formGroup"> </app-tipo-resposta-input>
        <span class="error-message" [hidden]="!(showErrors && formGroup.controls.tipoAlternativa.errors)">
          O tipo da alternativa deve ser informado
        </span>
      </div>

    <!-- </div> -->

    <div class="configAction" *ngIf="editavel">
      <span title="Remover alternativa" class="fa fa-trash-o" (click)="remover()"></span>
    </div>

  <!-- </div> -->

</div>
