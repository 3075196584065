import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgmMap, LatLngBounds } from '@renanklinsmann/agm-core';
import { GoogleMap } from '@renanklinsmann/agm-core/services/google-maps-types';

import { Subject, Subscription } from 'rxjs';
import { Entrevista } from '../../model/entrevista';

@Component({
  selector: 'app-localidades-mapa',
  templateUrl: './localidades-mapa.component.html',
  styleUrls: ['./localidades-mapa.component.scss'],
})
export class LocalidadesMapaComponent implements OnInit, OnDestroy {

  @ViewChild('agmap', { static: true }) agmMap: AgmMap;
  private googleMapsMap: GoogleMap;

  // nível de zoom do mapa
  zoom: number = 17.5;

  // posição central do mapa inicial.
  lat: number = -7.119496;
  lng: number = -34.845012;

  /**
   * Subject que é notificado sempre que as localidades de novas entrevistas
   * devem ser renderizadas no mapa.
   */
  @Input() novasEntrevistasSubject: Subject<Entrevista[]>;

  /**
   * Subscription da subject acima.
   */
  private novasEntrevistasSubscription: Subscription;

  /**
   * Conjunto de entrevistas cujas localidades devem ser renderizadas no mapa.
   * Essa lista é atualizada sempre que o subject "novasEntrevistasSubject"
   * é notificado com novas entrevistas.
   */
  @Input() entrevistas: Entrevista[] = [];
  /**
   * Identificador da pesquisa na qual as entrevistas recebidas pertencem
   */
  @Input() idPesquisa: number;

  ngOnInit() {

    /*
    if (this.novasEntrevistasSubject) {
      this.novasEntrevistasSubscription = this.novasEntrevistasSubject
        .subscribe((entrevistas: Entrevista[]) => {
          this.onNovasEntrevistas(entrevistas);
        });
    }
    */
  }

  ngOnDestroy() {

    if (this.novasEntrevistasSubscription) {
      this.novasEntrevistasSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {

    this.agmMap.mapReady.subscribe((map: GoogleMap) => {
      this.googleMapsMap = map;
      this.onMapInit();
    });
  }

  onMapInit() {

    if (this.entrevistas.length > 0) {
      this.onNovasEntrevistas(this.entrevistas);
    }

    if (this.novasEntrevistasSubject) {

      // tslint:disable-next-line:max-line-length
      this.novasEntrevistasSubscription = this.novasEntrevistasSubject.subscribe((entrevistas: Entrevista[]) => {

        /**
         * Resetando a marcação de inicialização, pois cada
         * conjunto de entrevistas representa um novo mapa
         */

        this.onNovasEntrevistas(entrevistas);
        /**
         * Disparando manualmente a centralização do mapa
         * quando os marcadores são atualizados,
         * já que a mudança não é detectada automaticamente
         */
        this.agmMap.triggerResize(true);
      });

    }
  }

  /**
   * Callback executado sempre que o subject novasEntrevistasSubject é notificado com novos
   * dados a serem renderizados no mapa.
   * @param entrevistas lista de entrevistas cujas localidades devem ser renderizadas.
   */
  onNovasEntrevistas(entrevistas: Entrevista[]) {
    this.entrevistas = entrevistas;
    this.centerMap(entrevistas);
  }

  /**
   * Centraliza o mapa do google maps baseado numa lista de marcadores,
   * de maneira que todos eles sejam visíveis no mapa.
   *
   * @param entrevistas marcadores do mapa
   */
  centerMap(entrevistas: Entrevista[]) {

    const bounds: LatLngBounds = new window['google'].maps.LatLngBounds();

    entrevistas.forEach((entrevista: Entrevista) => {
      // tslint:disable-next-line: max-line-length
      bounds.extend(new window['google'].maps.LatLng(entrevista.coordenadas.latitude, entrevista.coordenadas.longitude));
    });

    this.googleMapsMap.fitBounds(bounds);
  }


}
