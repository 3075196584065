import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from 'app/servico/requestService/crudService';
import { RequestService } from 'app/servico/request.service';
import { apiLocation } from 'app/infraestrutura/apiLocation';

export interface Localidade {
  id: number;
  nome: string;
  longitude: number;
  latitude: number;
  raio: number;
  possuiFilho: boolean;
  versao: string;
}

export interface LocalidadeAny {
  id: number;
  nome: string;
  versao: string;
  idLocalidadePai: number;
  filhas: LocalidadeAny[];
}

export interface LocalidadeFilha {
  id: number;
  nome: string;
  versao: string;
  idLocalidadePai: number;
  filhas: LocalidadeAny[];
}

@Injectable({
  providedIn: 'root',
})
export class PesquisaLocalidadeService extends CrudService<Localidade> {
  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/localidades`);
  }
  requestUrl: string;
  listarTodos(): Observable<Localidade[]> {
    this.requestUrl = `${this.resourcePath}/pais/selecao`;
    const response = <Observable<Localidade[]>>this.requestService.get(this.requestUrl);
    return response;
  }

  listarLocalidadesFilhas(id: number): Observable<LocalidadeFilha[]>  {
    this.requestUrl = `${this.resourcePath}/${id}/filhas`;
    const response = <Observable<LocalidadeFilha[]>>this.requestService.get(this.requestUrl);
    return response;
  }

}