import { ValidatorFn, Validators } from '@angular/forms';
import { TipoInput } from './tipoInput';
import validadorHoraMinuto from 'app/util/validador/validadorHoraMinuto';
import { min } from 'rxjs/operators';
import validadorMenorTempoPermitido from 'app/util/validador/validadorMenorTempoPermitido';

/**
 * Fabrica que provê acesso aos validadores que deverão ser utilizados
 * por determinado input da pesquisa
 */
export class InputValidatorFactory {

  private static validadores: Map<TipoInput, ValidatorFn[]>;

  public static getValidadores(tipoInput: TipoInput): ValidatorFn[] {

    if (!InputValidatorFactory.validadores) {
      InputValidatorFactory.init();
    }

    const validadores = InputValidatorFactory.validadores.get(tipoInput);

    return validadores;
  }

  private static init() {
    InputValidatorFactory.validadores = new Map();

    InputValidatorFactory.addDescricaoValidators();
    InputValidatorFactory.addQuestionarioValidators();
    InputValidatorFactory.addMarcacaoValidators();
  }

  private static addQuestionarioValidators() {
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_TEXTO_INICIAL, [Validators.required, Validators.maxLength(255)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_NOME, [Validators.required, Validators.maxLength(30)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_DESCRICAO, [Validators.maxLength(150)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_PERGUNTA, [Validators.required, Validators.maxLength(250)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_TIPO, [Validators.required]);
    // tslint:disable-next-line: max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_DESCRICAO, [Validators.maxLength(150)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_RESPOSTA_TIPO, [Validators.required]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_ALTERNATIVA_DESCRICAO, [Validators.required, Validators.maxLength(250)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_CONTEUDO_GRADE_TITULO, [Validators.required, Validators.maxLength(250)]);
  }

  private static addDescricaoValidators() {
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_CLIENTE, [Validators.required]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_TITULO, [Validators.required, Validators.maxLength(255)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_TITULO_CURTO, [Validators.required, Validators.maxLength(100)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_OBJETIVO, [Validators.required, Validators.maxLength(150)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_TIPO, [Validators.required, Validators.maxLength(150)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_DISTANCIA, [Validators.min(0)]);

    const validadorMaiorIgualA1Segundo = validadorMenorTempoPermitido('00:01', 'mm:ss');

    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_TEMPO_MINIMO, [Validators.required, validadorHoraMinuto, validadorMaiorIgualA1Segundo]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_AMOSTRAS, [Validators.required, Validators.min(0), Validators.max(9999999)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_QTD_DIARIA_ENTREVISTAS, [Validators.required, Validators.min(1), Validators.max(999999)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_QTD_MINIMA_CONSOLIDACAO, [Validators.required, Validators.min(1), Validators.max(999999)]);
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.DESCRICAO_QTD_ENTREVISTAS_DESCARTE, [Validators.required, Validators.min(1)]);
  }

  private static addMarcacaoValidators() {
    // tslint:disable-next-line:max-line-length
    InputValidatorFactory.validadores.set(TipoInput.MARCACAO_SECAO_PERGUNTA_ALTERNATIVA_CODIGO_MARCACAO, []);
    // tslint:disable-next-line: max-line-length
    InputValidatorFactory.validadores.set(TipoInput.MARCACAO_COTA_PERCENTUAL, [Validators.min(0), Validators.max(100)]);
    InputValidatorFactory.validadores.set(TipoInput.MARCACAO_COTA_VALOR, [Validators.min(0)]);
  }

}
