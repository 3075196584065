<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <img class="operators-icon" src="assets/icons/operators-icon.svg" />
      <h2 [routerLink]="'/operador'">Operadores</h2>
      <span>/</span>
      <h4> {{ tituloFormulario }} </h4>
    </div>
  </div>
  <form class="content-operadores-adicionar" #form="ngForm" [formGroup]="formGroup"
    (ngSubmit)="form.valid && salvar(form.value);showNotificationError()">
    <div class="row form sg-input-group section">
      <div class="col-12 sub-title">
        <h3>Informações básicas</h3>
      </div>
      <div class="mt-3 mb-5 mr-4 ml-4">
        <app-new-foto-uploader></app-new-foto-uploader>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="row">

          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="campo">
              <label for="">Matrícula</label>
              <input type="text" [disabled]="true" formControlName="matricula">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- CPF -->
            <div class="campo" formGroupName="pessoa">
              <label for="">CPF *</label>
              <input placeholder="Ex.:123.456.789-99" title="cpf do operador" type="text" mascara-cpf
                formControlName="cpf">
              <span class="error-message" [hidden]="!(form.submitted && formControls.cpf.errors)">
                CPF inválido</span>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            < !-- RG   -- >
            <div class="campo" formGroupName="pessoa">
              <label for="">RG</label>
              <input mascara-rg placeholder="Ex.:123456" title="rg do operador" formControlName="rg" id="rg" type="text"
                maxlength="10">
              <span class="error-message" [hidden]="!(form.submitted && formControls.rg.errors)">
                RG inválido</span>
            </div>
          </div> -->
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- NOME -->
            <div class="campo" formGroupName="pessoa">
              <label for="">Nome do operador *</label>
              <input mascara-primeiro-digito-sem-espaco mask-only-letters placeholder="Ex.:jose soares da silva"
                title="nome do operador" id="nome" formControlName="nome" type="text" maxlength="50">
              <span class="error-message" [hidden]="!(form.submitted && formControls.nome.errors)">
                Insira o nome</span>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            < !-- CEP  -- >
            <div class="campo" formGroupName="pessoa">
              <div formGroupName="endereco">
                <label for="">CEP</label>
                <input placeholder="Ex.:583100000" title="cep do operador" id="cep" mascara-cep formControlName="cep"
                  type="text" maxlength="9" minlength="9">
                <span class="error-message" [hidden]="!(form.submitted && formControls.cep.errors)">
                  Insira um CEP</span>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            <! -- BAIRRO  -- >
            <div class="campo" formGroupName="pessoa">
              <div formGroupName="endereco">
                <label for="">Bairro</label>
                <input mascara-primeiro-digito-sem-espaco placeholder="Ex.:manaira" title="bairro do operador"
                  id="bairro" formControlName="bairro" type="text" maxlength="60">
                <span class="error-message" [hidden]="!(form.submitted && formControls.bairro.errors)">
                  Insira um bairro
                </span>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            < !-- LOGRADOURO  -- >
            <div class="campo" formGroupName="pessoa">
              <div formGroupName="endereco">
                <label for="">Logradouro</label>
                <input mascara-primeiro-digito-sem-espaco placeholder="Ex.:rua santo antonio"
                  title="logradouro do operador" id="logradouro" formControlName="logradouro" type="text"
                  maxlength="60">
                <span class="error-message" [hidden]="!(form.submitted && formControls.logradouro.errors)">
                  Insira o logradouro
                </span>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            < !-- NUMERO -- >
            <div class="campo" formGroupName="pessoa">
              <div formGroupName="endereco">
                <label for="">Número</label>
                <input placeholder="Ex.:33" title="número da residência do operador" id="numero"
                  formControlName="numero" mascara-numero type="text" maxlength="5">
                <span class="error-message" [hidden]="!(form.submitted && formControls.numero.errors)">
                  Insira um número residencial</span>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            < !-- CIDADE/ESTADO  -- >
            <div class="campo" formGroupName="pessoa">
              <div formGroupName="endereco">
                <label for="">Cidade / Estado</label>
                <input mascara-primeiro-digito-sem-espaco placeholder="Ex.:cabedelo" title="cidade/estado do operador"
                  formControlName="cidadeEstado" id="cidadeEstado" type="text" maxlength="60" />
                <span class="error-message" [hidden]="!(form.submitted && formControls.cidadeEstado.errors)">
                  Insira uma cidade ou um estado
                </span>
              </div>
            </div>
          </div>-->
          <!-- <div class="col-lg-4 col-md-6 col-sm-12"> 
            < !-- CELULAR -- >
            <div class="campo" formGroupName="contato">
              <label for="">Celular</label>
              <input placeholder="Ex.:911223344" title="número do celular do operador" formControlName="celular"
                mascara-celular id="celular" type="text" minlength="15">
              <span class="error-message" [hidden]="!(form.submitted && formControls.celular.errors)">
                Insira um celular válido
              </span>
            </div>
          </div> -->

          <!-- <div class="col-lg-4 col-md-6 col-sm-12">
            < !-- TELEFONE -- >
            <div class="campo" formGroupName="contato">
              <label for="">Telefone</label>
              <input placeholder="Ex.:34210000" title="número do telefone do operador" formControlName="telefone"
                mascara-telefone id="telefone" type="text">
              <span class="error-message" [hidden]="!(form.submitted && formControls.telefone.errors)">
                Insira um telefone válido</span>
            </div>
          </div> -->
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- EMAIL -->
            <div class="campo" formGroupName="contato">
              <label for="">Email *</label>
              <input id="email" formControlName="email" type="email" title="email do operador"
                placeholder="Ex.:jose@email.com" maxlength="50">
              <span class="error-message" [hidden]="!(form.submitted && formControls.email.errors)">
                Insira um email válido
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row section form sg-input-group">
      <div class="col-12 sub-title">
        <h3>Dados de acesso</h3>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="row">
          <!-- SENHA -->
          <div class="col-md-6 col-lg-5">
            <div class="campo" formGroupName="usuario">
              <label *ngIf="isEdicao" for="">Senha</label>
              <label *ngIf="!isEdicao" for="">Senha *</label>
              <app-input-password [isEdicao]="isEdicao" [isAlteracaoSenha]="isAlteracaoSenha"
                [parentFormGroup]="formGroup" [controlName]="'senha'"></app-input-password>
              <span class="error-message"
                [hidden]="!(form.submitted && formControls.senha.errors && (formControls.senha.errors.required || formControls.senha.errors.minlength || formControls.senha.errors.maxlength)) ">
                Informe uma senha entre 8 e 30 caracteres
              </span>
            </div>
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="campo" formGroupName="usuario">
              <label *ngIf="isEdicao" for="">Repetir senha</label>
              <label *ngIf="!isEdicao" for="">Repetir senha *</label>

              <app-input-password [isEdicao]="isEdicao" [isAlteracaoSenha]="isAlteracaoSenha"
                [parentFormGroup]="formGroup" [controlName]="'senhaConfirmar'"></app-input-password>
              <span class="error-message" [hidden]="!(form.submitted && formControls.senhaConfirmar.errors) ">
                As senhas estão diferentes
              </span>
            </div>
          </div>
          <div class="col-md-6 col-lg-5 ">
            <div class="campo">
              <label for="">Início do vínculo *</label>
              <input max="9999-12-29" title="início do vínculo" formControlName="periodoInicial" type="date">
              <!-- <span class="error-message" [hidden]="!(form.submitted && formControls.periodoInicial.errors && !isEdicao)">
            Deve ser informada uma data válida a partir do dia de hoje e menor que o Período Final
          </span> -->
              <span class="error-message" [hidden]="!(form.submitted && formControls.periodoInicial.errors)">
                Deve ser informada uma data menor que o Período Final
              </span>
            </div>
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="campo">
              <label for="">Fim do vínculo *</label>
              <input max="9999-12-29" title="fim do vínculo" formControlName="periodoFinal" type="date">
              <!-- <span class="error-message" [hidden]="!(form.submitted && formControls.periodoFinal.errors  && !isEdicao)">
            Deve ser informada uma data válida a partir do dia de hoje e maior que o Período Inicial
          </span> -->
              <span class="error-message" [hidden]="!(form.submitted && formControls.periodoFinal.errors)">
                Deve ser informada uma data maior que o Período Inicial
              </span>
            </div>
          </div>
          <div class="col-md-12 col-lg-5">
            <div class="campo" formGroupName="usuario">
              <label for="">Status</label>
              <select title="status do operador" formControlName="ativo">
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
              <span class="arrow"></span>
            </div>
          </div>
          <div class="campo-inline" *ngIf="isEdicao">
            <mat-slide-toggle title="ativar alteração de senha" (click)="habilitarAlteracaoSenha()"></mat-slide-toggle>
            <label>Habilitar alteração de senha</label>
          </div>
          <div class="col-12 btns-actions">
            <button class="sg-btn sg-alternate" (click)="cancelarFluxo($event)" title="cancelar dados">CANCELAR</button>
            <button *ngIf="!isEdicao" class="sg-btn sg-secondary" type="submit"
              title="adicionar operador">ADICIONAR</button>
            <button *ngIf="isEdicao && getAuthenticatedUserAuthorities().includes(authority.ATUALIZAR_OPERADOR)"
              class="sg-btn sg-secondary" type="submit" title="atualizar dados do operador">ATUALIZAR</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <h1 i18n>Hello i18n!</h1> -->
  </form>
</div>

<app-loading *ngIf="isLoading"></app-loading>