import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PerfilEntrevistado } from '../../../../../../pesquisa-old/cadastro/model/perfilEntrevistado';
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-seletor-perfil-entrevistado',
  templateUrl: './seletor.perfil.component.html',
  styleUrls: ['./seletor.perfil.component.scss'],
})
export class SeletorPerfilComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * Auxiliar para capturar o (change) do select
   */
  perfilSelecionado: PerfilEntrevistado = null;

  /**
   * Ids de perfis que devem ser ignorados na listagem
   */
  @Input('perfisParaIgnorar') perfisParaIgnorar: number[] = [];

  /**
   * Perfis que serao renderizados no seletor, eles sao
   * recuperados a partir da store
   */
  perfis: PerfilEntrevistado[] = [];

  /**
   * Armazenando as inscricoes para desfaze-las quando necessario
   */
  private readonly subscriptions: Subscription[] = [];

  /**
   * Lancador de evento quando um perfil eh selecionado
   */
  @Output() readonly perfilSelecionadoEvt = new EventEmitter<PerfilEntrevistado>();

  constructor(private store: Store<CadastroPesquisaStoreState>) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.perfisParaIgnorar) {
      this.perfisParaIgnorar = changes.perfisParaIgnorar.currentValue;
      this.normalizarPerfisExibidos();
    }
  }

  ngOnInit() {
    this.listenToPerfis();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  normalizarPerfisExibidos() {

    // tslint:disable-next-line: max-line-length
    const filtrarPerfisExibidos = (perfis: PerfilEntrevistado[]) => perfis.filter(perfil => !this.perfisParaIgnorar.includes(perfil.id));
    this.perfis = filtrarPerfisExibidos(this.perfis);

    if (this.perfilSelecionado && this.perfisParaIgnorar.includes(this.perfilSelecionado.id)) {
      this.perfilSelecionado = null;
    }
  }

  /**
   * Metodo que inscreve o componente na store a fim
   * de detectar alteracoes nos perfis de cota
   */
  listenToPerfis() {

    const subscription = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(x => x['pesquisa']),
        map(x => x['perfisEntrevistados']),
      ).subscribe((perfisEntrevistados) => {
        this.perfis = perfisEntrevistados;
        this.normalizarPerfisExibidos();
      });

    this.subscriptions.push(subscription);
  }

  /**
   * Metodo que converte um perfil para a visualizacao textual
   * que sera exibida no seletor
   */
  perfilToTextoSelecao(perfil: PerfilEntrevistado): string {

    const textoSelecao = perfil
      .perfilEntrevistadoCaracteristicas
      .map(caracteristicaPerfil => caracteristicaPerfil.alternativa.descricao)
      .reduce((parcela, total) => `${parcela} / ${total}`);

    return textoSelecao;
  }

  /**
   * Publica o evento de selecao de um perfil
   */
  publicarNovaSelecao(perfil: PerfilEntrevistado) {
    this.perfilSelecionadoEvt.emit(perfil);
  }
}
