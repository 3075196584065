<div [formGroup]="formGroup" class="sg-input-group">
  <div class="container-white">
    <div class="row">
      <div class="col-12 title-step-section">
        <h3>3 de 4: Marcações</h3>
        <span class="error-message" *ngIf="!modificacaoPermitida">
          Você não tem permissão para modificar esse menu
        </span>
      </div>
    </div>
    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12 campo">
        <label title="título principal" for="">Título da pesquisa*</label>
        <input
          mascara-primeiro-digito-sem-espaco
          type="text"
          formControlName="titulo"
          (blur)="onTituloChange()"
        />
        <span
          class="error-message"
          [hidden]="!(showErrors && controls.titulo.errors)"
        >
          Deve ser informado um título para a pesquisa de até 255 caracteres
        </span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 campo">
        <label for="">Título curto da pesquisa*</label>
        <input
          mascara-primeiro-digito-sem-espaco
          title="título curto da pergunta"
          type="text"
          formControlName="tituloCurto"
          (blur)="onTituloChange()"
        />
        <span
          class="error-message"
          [hidden]="!(showErrors && controls.tituloCurto.errors)"
        >
          Deve ser informado um título curto para a pesquisa de até 100
          caracteres
        </span>
      </div>
      <div class="col-lg-12 col-md-12 col-xs-12 campo">
        <label
          placeholder="texto inicial"
          title="texto inicial da pesquisa"
          for=""
          >Texto inicial*</label
        >
        <input
          mascara-primeiro-digito-sem-espaco
          type="text"
          formControlName="textoInicial"
          (blur)="onTituloChange()"
        />
        <span
          class="error-message"
          [hidden]="!(showErrors && controls.textoInicial.errors)"
        >
          Deve ser informado um texto inicial de até 255 caracteres
        </span>
      </div>
    </div>
  </div>
  <div class="row section">
    <div class="col-12 header-section">
      <h3>Configure os comportamentos de suas perguntas</h3>
    </div>
  </div>

  <!-- BOTÃO PARA HABILITAR A ORDENACAO DAS PERGUNTAS COM COTAS -->
  <div *ngIf="possuiCota()" class="row-content-btn">
    <button
      [ngStyle]="{
        'background-color': ordenacaoCotasHabilitada ? '#610082' : ''
      }"
      class="btn-drag-drop"
      (click)="habilitarOrdenacaoCotas()"
      [disabled]="!modificacaoPermitida || modificacoesBloqueadas"
    >
      ORDENAR VIZUALIZAÇÃO DAS COTAS
      <!-- <div *ngIf="ordenacaoCotasHabilitada"> [ON]</div> -->
      <!-- <span class="fa fa-th"></span> -->
      <i class="fa fa-long-arrow-up"></i>
      <i class="fa fa-long-arrow-down"></i>
    </button>
  </div>
  <div class="row section positionRelative" *ngIf="ordenacaoCotasHabilitada">
    <div class="col-lg-8 interviewContainer">
      <div class="interviewHeader">Perguntas marcadas</div>

      <div cdkDropList (cdkDropListDropped)="onDropPerguntaCota($event)">
        <div
          cdkDrag
          *ngFor="
            let elementoSecao of getElementosSecaoFlat(formGroup)
              | filtrarEOrdenarPorCota;
            let i = index
          "
        >
          <app-pergunta [elementoSecao]="elementoSecao" [selected]="false">
            <!-- botao que dispara o drag-and-drop -->
            <div
              perguntaUnica
              cdkDragHandle
              title="Arraste para alterar a ordem que a cota é aplicada"
              class="moveIcon"
            >
              <span class="fa fa-th"></span>
            </div>

            <div
              perguntaEscalaNumerica
              cdkDragHandle
              title="Arraste para alterar a ordem que a cota é aplicada"
              class="moveIcon"
            >
              <span class="fa fa-th"></span>
            </div>

            <div
              perguntaMultiplaEscolha
              cdkDragHandle
              title="Arraste para alterar a ordem que a cota é aplicada"
              class="moveIcon"
            >
              <span class="fa fa-th"></span>
            </div>

            <div
              perguntaMultiplaOrdemCitacao
              cdkDragHandle
              title="Arraste para alterar a ordem que a cota é aplicada"
              class="moveIcon"
            >
              <span class="fa fa-th"></span>
            </div>
          </app-pergunta>
        </div>
      </div>
    </div>
  </div>

  <div class="row section positionRelative" *ngIf="!ordenacaoCotasHabilitada">
    <div class="col-lg-8 interviewContainer">
      <div class="interviewHeader">Perguntas marcadas</div>

      <div>
        <div
          *ngFor="
            let elementoQuestionario of getControlsFromAbstractFormArray(
              formGroup.get('elementosQuestionario')
            );
            let i = index
          "
        >
          <app-secao-marcacao [ordem]="i + 1" [showErrors]="showErrors">
          </app-secao-marcacao>
        </div>
      </div>
    </div>
    <div class="col-lg-4 positionSticky">
      <app-complex-input-factory
        *ngIf="itemSelecionado.item"
        [tipoInput]="itemSelecionado.tipoConteudo"
        [params]="conteudoComponentParams"
        [reRenderPublisher]=""
      ></app-complex-input-factory>
    </div>
  </div>
</div>
