<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<div class="result-container">
    <div class="result-card">
        <h2>Resultados</h2>
        <h6>Verifique os resultados de suas pesquisas concluídas e em execução</h6>
        <div class="accordion">
            <app-filterable-select filterPlaceholder="Busque pelo título da pesquisa" label=""
                placeholder="Selecione uma pesquisa para ver seus resultados " [data]="surveysList"
                (changeSelectedItem)="handleChange($event)" [selectedItem]="null"
                (changeInputText)="changeInputText($event)" usingBy="RESULTADOS"
                (onScrollBottom)="onScrollBottom($event)">
            </app-filterable-select>
        </div>

        <div class="last-surveys">
            <h4>Últimas pesquisas</h4>
            <div class="list-surveys">
                <div *ngFor="let survey of lastSurveys"
                    routerLink="/resultados/resultado-pesquisa/{{survey.id_pesquisa}}" class="survey-card">
                    <p class="text">{{survey.tipo_pesquisa}}</p>
                    <!--
                        * Essa div foi necessária pois o tooltip inserido direto no texto considera o tamanho
                        * completo da string, então titulos muito longos o tootip aparecia muito em baixo, essa
                        * foi a estratégia mais eficiente para manter o tooltip fiel ao figma.
                    -->
                    <div [attr.data-tooltip]="survey.titulo_curto" class="title">
                        <p>{{survey.titulo_curto}}</p>
                    </div>
                    <p class="status">{{formmatStatus(survey.status)}}</p>
                </div>
            </div>
        </div>
    </div>

</div>
