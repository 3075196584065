export enum DefaultImagem {
  login = 'https://alfa-tensai-teste.s3.amazonaws.com/tensai/medias/813d2ec1-d6e0-4cb0-9aaa-80d77cd861d2.png',
  logotipo_light = 'https://alfa-tensai-teste.s3.amazonaws.com/tensai/medias/f9379b32-39b5-4cec-9896-d53edc8ed565.png'
}

export const themeMatching = {
  default: 'default-theme',
  verde: 'green-theme',
  amarelo: 'yellow-theme',
  vermelho: 'red-theme',
  azul: 'default-theme',
  laranja: 'orange-theme',
};
