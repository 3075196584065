import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-integracao-sgmt",
  templateUrl: "./integracao-sgmt.component.html",
  styleUrls: ["./integracao-sgmt.component.scss"],
})

export class IntegracaoSGMTComponent implements OnInit {
 /**
 * Subscriptions a serem fechadas com a morte do componente
 */
 subscriptions: Subscription[] = [];

  // tslint:disable-next-line: max-line-length
  constructor(
    private activatedRouter: ActivatedRoute,
    private securityService: SecurityService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit() {
    const queryParamsSubscription = this.activatedRouter.queryParamMap.subscribe(params => {
        let token = params.get('token');

        if (token) {
            this.doAuthentication(token);
        }
    });

    this.subscriptions.push(queryParamsSubscription);
  }

  onAuthenticationFailed(error) {
    this.errorHandler.handleError(error, 'Token inválido');
  }   

  onAuthenticationSuccess() {
    window.location.href = '';
  }

  doAuthentication(token: string) {
    const callbacks = {
        next: () => this.onAuthenticationSuccess(),
        error: (error) => this.onAuthenticationFailed(error)
    };

    const authenticationSubscription = this.securityService
        .sgmtAuthenticate(token)
        .subscribe(callbacks);

    this.subscriptions.push(authenticationSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
