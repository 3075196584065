import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { emptyPodium } from 'app/modulos/produtividade-beta/constants/visao-geral';
import { IGeneralVisionPodium } from 'app/modulos/produtividade-beta/interfaces';

@Component({
  selector: 'app-podium',
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss']
})
export class PodiumComponent implements OnInit {
  @Input() podiumData: IGeneralVisionPodium[];
  surveyId: number;
  showTooltip: boolean = false;
  hasCollections: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params["surveyId"];
    this.ensureThreeElements();
    this.sortPodiumData();
  }

  /**
   * Garante que podium data tenha sempre 3 elementos.
   * Adiciona novos elementos (emptyPodium) se necessário.
   */
  ensureThreeElements(): void {
    while (this.podiumData.length < 3) {
      this.podiumData.push(emptyPodium);
    }
  }

  /**
   * Ordena podiumData da seguinte forma:
   * - Index 0: avaliacao_geral intermediaria
   * - Index 1: avaliacao_geral com maior valor
   * - Index 2: avaliacao_geral com menor valor
   */
  sortPodiumData(): void {
    if (!this.podiumData || this.podiumData.length !== 3) {
      return;
    }

    this.podiumData.sort((a, b) => b.avaliacao_geral - a.avaliacao_geral);
    this.podiumData = [this.podiumData[1], this.podiumData[0], this.podiumData[2]];
  }

  navigate() {
    this.router.navigate([`/produtividade-beta/visao-geral/${this.surveyId}/entrevistadores`]);
  }

}
