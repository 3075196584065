import { IAuditCollections, IAuditCollectionsResponse } from "../../interfaces";

/**
 * Recebe uma hora completa (hh:mm:ss) e retorna
 * sem os segundos (hh:mm)
 */
function timeWhitoutSeconds(receivedTime: string): string {
  if (!(typeof receivedTime === "string" && receivedTime.length > 6)) {
    return receivedTime;
  }
  return receivedTime.slice(0, 5);
}

export const adaptCollectionsListRespToModels = function (
  collectionsListResponse: IAuditCollectionsResponse[]
): IAuditCollections[] {
  return collectionsListResponse?.map((collection) => {
    return {
      id: collection.id,
      data: collection.data,
      hora_fim: timeWhitoutSeconds(collection.hora_fim),
      hora_inicio: timeWhitoutSeconds(collection.hora_inicio),
      operador: collection.operador["nome"],
      situacao: collection.situacao,
      localidade: collection.localidade["nome"],
      criterios: collection.criterios,
    };
  });
};
