<div class="progress-bar">
  <div
    class="localidades-graph-icon"
    alt=""
  ></div>
  <div class="progress-bar-container">

    <div class="header">
      <p>Distribuição da amostra</p>
      <p>{{getProgressValue(progress, samples)}}%</p>
    </div>

    <div class="progress">
      <div class="progress-value" [style.width.%]="(progress * 100) / samples"></div>
    </div>
    <div class="footer">
      <div *ngIf="!statusProgress()">
        <p>Você ainda precisa distribuir </p>
        <span>{{100 - getProgressValue(progress, samples)}}% das coletas</span>
      </div>

      <div *ngIf="statusProgress()">
        <p>Amostra distribuída</p>
        <span>com sucesso!</span>
      </div>
    </div>

  </div>
</div>
