import { Authority } from './authority';

export class AuthUser {
  id: string;
  login: string;
  name: string;
  photo: string;
  tipoUsuario: string;
  authorities: Authority[];
  permissoes: string[];
  subTenant: string;
  needChangePassword?: boolean;
  needSignDocuments?: boolean;
}
