import { Directive, OnInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[mascara-limite-caracteres]',
})
export class MascaraLimiteCaracteresDirective implements OnInit {

  @Input('mascara-limite-caracteres') mascaraLimiteCaracteres: number = 0;

  constructor(protected ngControl: NgControl) {}

  ngOnInit() {
  }

  // Aplicando as mascaras no caso de edicao
  ngAfterContentChecked(): void {
    if (this.aplicarMascara && this.ngControl && this.ngControl.value) {
      this.atualizarFormControl(this.ngControl.value);
      this.ngControl.control.updateValueAndValidity();
    }
  }

  atualizarFormControl(inputValue: string) {
    // garantindo que o input eh uma string
    const inputValueString = String(inputValue);

    // Limitando o numero de caracteres
    const valorBruto = inputValueString.substring(0, this.mascaraLimiteCaracteres);
    const valorFormatado = this.aplicarMascara(valorBruto);

    this.ngControl.reset();
    this.ngControl.control.patchValue(valorFormatado);
  }

  aplicarMascara(valor: string): string {
    return valor;
  }

}
