import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NotificatorService } from "app/notificador/notificator.service";
import { LocalidadeService } from "../../servico/localidade.service";
import { LeafletMapManager } from "../leaflet-map-manager";
import { ModalCreadUpdateService } from "../modal-create-update.service";
import { formatToMaxDecimalPlaces } from "../../../../util/formatter";
import { DEFAULT_RAIO_VALUE, MAX_CEP_LENGTH } from "./constants";

@Component({
  selector: "app-manual-form",
  templateUrl: "./manual-form.component.html",
  styleUrls: ["./manual-form.component.scss"],
})
export class ManualFormComponent implements OnInit {
  @ViewChild("inputcheckboxreference", { static: true })
  inputCheckbox: ElementRef;
  @ViewChild("inputwrappereference", { static: true }) inputWrapper: ElementRef;

  // @Input() timelineRef: number = 0;
  @Input() canRegisterFather: boolean = true;

  // Formulário de localidade
  public cleanForm: UntypedFormGroup = new UntypedFormGroup({});

  // objeto de facil acesso paara os formControls
  formControls: { [key: string]: UntypedFormControl } = {} as {
    [key: string]: UntypedFormControl;
  };

  // Gerenciador do leafletMap
  leafletMapManager: LeafletMapManager;
  /**
   * Atributo criado para persistir erro visualmente para o usuário quando o length for === 9
   * mas o valor não corresponder a nenhum endereço válido.
   * isInvalidCep vira verdadeiro quando retorno do viacep for {erro: true}
   */
  isInvalidCep: boolean = false;

  constructor(
    private localidadeService: LocalidadeService,
    private modalCreateUpdateService: ModalCreadUpdateService,
    private notificatorService: NotificatorService
  ) {
    this.leafletMapManager = new LeafletMapManager(localidadeService, modalCreateUpdateService, notificatorService);
  }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.formControls["latitude"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["longitude"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["fatherLocation"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["cep"] = new UntypedFormControl(null, [
      Validators.required, this.cepLengthValidator
    ]);
    this.formControls["country"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["state"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["city"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["address"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["raio"] = new UntypedFormControl(null, [
      Validators.required,
    ]);
    this.formControls["unit"] = new UntypedFormControl(null, [
      Validators.required,
    ]);

    this.cleanForm = new UntypedFormGroup({
      latitude: this.formControls.latitude,
      longitude: this.formControls.longitude,
      fatherLocation: this.formControls.fatherLocation,
      cep: this.formControls.cep,
      country: this.formControls.country,
      state: this.formControls.state,
      city: this.formControls.city,
      address: this.formControls.address,
      raio: this.formControls.raio,
      unit: this.formControls.unit,
    });

    this.cleanForm.get("unit").setValue("Kilômetros");
  }

  /**
   * Valida o tamanho do cep durante a interação do usuário com o input
   */
  cepLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length < MAX_CEP_LENGTH) {
      return { 'cepInvalidLength': true };
    }
    return null;
  }


  async handleAutoCompleteByCep(cep: any): Promise<void> {
    const cepValue = cep.target.value;
    if (cepValue.length < MAX_CEP_LENGTH) {
      this.cleanForm.get('cep').setErrors({ 'cepInvalidLength': true });
      return;
    }
    if(cepValue.length === MAX_CEP_LENGTH) {
      try {
        const cepResult = await this.localidadeService.buscarLocalidadeViaCEP(
          cepValue
        );

        // Verificação adicionada, pois, mesmo passando um cep inválido a API retorna 200 (desde que o valor tenha mais de 8 digitos)
        if (cepResult?.erro) {
          this.isInvalidCep = true;
          this.cleanForm.get('cep').setErrors({ 'cepInvalidLength': true });
        } else {
          this.isInvalidCep = false;
          this.cleanForm.get("address").setValue(cepResult.logradouro);
          this.cleanForm.get("country").setValue("Brasil");
          this.cleanForm.get("city").setValue(cepResult.localidade);
          this.cleanForm.get("state").setValue(cepResult.uf);
        }

      } catch (error) {
        this.isInvalidCep = true;
        this.cleanForm.get('cep').setErrors({ 'cepInvalidLength': true });
        this.notificatorService.showError(
          "CEP incorreto",
          null
        );
      }
    }
  }

  handleChangeUnit(unit: string) {
    this.cleanForm.get("unit").setValue(unit);
    this.inputCheckbox.nativeElement.checked = false;
  }

  // Função responsável por converter de KM para M
  handleConvertToMeters(raio: string): number {
    if (raio.includes(".") || raio.includes(",")) {
      if (raio.includes(",")) {
        const raioReplaced = raio.replace(",", ".");
        return Number(raioReplaced) * DEFAULT_RAIO_VALUE;
      } else {
        return Number(raio) * DEFAULT_RAIO_VALUE;
      }
    } else {
      return Number(raio) * DEFAULT_RAIO_VALUE;
    }
  }

  // Função que cancela ação de atualização
  handleCancelUpdate(): void {
    this.modalCreateUpdateService.setGoBackButton();
  }

  // Função para deixar o botão de salvar desabilitados
  canShowCancelFormButton(): boolean {
    return (
      this.modalCreateUpdateService.getCanCreateChildren() ||
      this.modalCreateUpdateService.getWhoIsEditing() !== "NONE"
    );
  }

  onSubmit() {
    let finalRaio;

    const valueOfUnit = this.cleanForm.get("unit").value;
    const raioOfForm = this.cleanForm.get("raio").value;

    finalRaio =
      valueOfUnit === "Kilômetros"
        ? this.handleConvertToMeters(String(raioOfForm))
        : Number(raioOfForm);

    const dataToSave = {
      nome: `${this.cleanForm.get("fatherLocation").value}, ${
        this.cleanForm.get("city").value
      }`,
      latitude: formatToMaxDecimalPlaces(this.cleanForm.get("latitude").value, 15),
      longitude: formatToMaxDecimalPlaces(this.cleanForm.get("longitude").value, 15),
      raio: finalRaio,
      endereco: {
        geoId: null, //this.cleanForm.get('geoId').value,
        cidade: this.cleanForm.get("city").value,
        pais: this.cleanForm.get("country").value,
        cep: this.cleanForm.get("cep").value,
        estado: this.cleanForm.get("state").value,
        enderecoCompleto: this.cleanForm.get("address").value,
      },
    };

    if (this.canRegisterFather) {
      this.modalCreateUpdateService.saveLocalidadeFilha(dataToSave);
      return;
    } else {
      this.modalCreateUpdateService.saveLocalidadePai(dataToSave);
    }

    // Update map
    this.leafletMapManager.updateInternals(
      dataToSave.latitude,
      dataToSave.longitude,
      dataToSave.raio
    );

    this.leafletMapManager.resolveMap();
  }

  // TODO: PRECISA PENSAR NUMA FORMA MAIS PERFOMÁTICA DE IMPLEMENTAR A EXPANSÃO DO SELECT
  // verificar se o clique foi dentro do componente ou fora.
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (
      this.inputWrapper &&
      this.inputWrapper.nativeElement.contains(event.target)
    ) {
      return;
    } else {
      this.inputCheckbox.nativeElement.checked = false;
    }
  }
}
