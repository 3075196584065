import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

/**
 * Serviço de manipulação do componente NotificatorComponent.
 */
@Injectable()
export class NotificatorService {
  constructor(private toastrService: ToastrService) { }

  // apresenta uma mensagem do tipo fail (error)
  public showError(title: string, message: string) {
    this.toastrService.error(message, title, {
      toastClass: 'ngx-toastr1 fail',
      disableTimeOut: false, //durante o desenvolvimento use true
    });
  }

  // apresenta uma mensagem do tipo sucesso
  public showInfo(title: string, message: string) {
    this.toastrService.success(message, title, {
      toastClass: 'ngx-toastr1 info',
      disableTimeOut: false, //durante o desenvolvimento use true
    });
  }

  // apresenta uma mensagem de dica
  public showDica(title: string, message: string) {
    this.toastrService.info(message, title, {
      toastClass: 'ngx-toastr1 dica',
      disableTimeOut: false, //durante o desenvolvimento use true
    })
  }

  // apresenta uma mensagem de alerta
  public showAlert(title: string, message: string) {
    this.toastrService.warning(message, title, {
      toastClass: 'ngx-toastr1 alert',
      disableTimeOut: false, //durante o desenvolvimento use true
    })
  }
}
