import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DadosPaginacao } from 'app/util/componente/paginacao/dadosPaginacao';
import { PesquisaItem } from '../../pesquisa-old/pesquisa-select/pesquisaItem';

@Component({
  selector: 'app-respostas-fechadas',
  templateUrl: './respostas-fechadas.component.html',
  styleUrls: ['./respostas-fechadas.component.scss']
})
export class RespostasFechadasComponent implements OnInit {

  @Input() pesquisaSelecionada: PesquisaItem;
  _pesquisaSelecionada: PesquisaItem;

  @Input() pagina: number = 0;
  @Output() paginacaoChange: EventEmitter<DadosPaginacao> = new EventEmitter();

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pagina) { }

    if (changes.pesquisaSelecionada) {
      const pesquisaSelecionadaChange: SimpleChange = changes.pesquisaSelecionada;
      this._pesquisaSelecionada = pesquisaSelecionadaChange.currentValue;
    }
  }

  propagarMudancaPaginacao(dadosPaginacao: DadosPaginacao) {
    this.paginacaoChange.emit(dadosPaginacao);
  }

}
