<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">
  
  <app-pergunta-card
    [icone]="'fa fa-clone'"
    [titulo]="ordem + ' - GRADE MÚLTIPLA'"
    [nomePlaceHolder]="'Título da pergunta de grade múltipla?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>


<!-- <div [formGroup]="formGroup" class="grade-unica">
  <app-grade-conteudo
    (inputUpdate)="handleNestedInputEvent()($event)"
    [parentEventPublisher]="nestedInputPublisher"
    >
  </app-grade-conteudo>

  <div>
    <label>
      Mínimo selecionado*
      <input type="text"
        mascara-numero
        formControlName="qtdMinima" 
        (blur)="onQtdMinimaChange()"
        maxlength="1"/>
      <span class="error-message" [hidden]="!(showErrors && controls.qtdMinima.errors && controls.qtdMinima.errors.maiorQueDeveria)">
        A quantidade minima deve ser menor que a quantidade máxima
      </span>
      <span class="error-message" [hidden]="!(showErrors && controls.qtdMinima.errors && controls.qtdMinima.errors.valorMaximoPermitido)">
        A quantidade mínima deve ser menor ou igual ao total de alternativas adicionadas
      </span>
    </label>
    <label>
      Máximo selecionado
      <input type="text" 
        mascara-numero
        formControlName="qtdMaxima" 
        (blur)="onQtdMaximaChange()"
        maxlength="1"/>
      <span class="error-message" [hidden]="!(showErrors && controls.qtdMaxima.errors && controls.qtdMaxima.errors.menorQueDeveria)">
        A quantidade máxima deve ser maior que a quantidade mínima
      </span>
      <span class="error-message" [hidden]="!(showErrors && controls.qtdMaxima.errors && controls.qtdMaxima.errors.valorMaximoPermitido)">
        A quantidade máxima deve ser menor ou igual ao total de alternativas adicionadas
      </span>
    </label>
  </div>
  
</div> -->
    