import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { CadastroBasico } from "app/modulos/cadastro/cadastroBasico";
import { PermissaoService } from "app/modulos/permissao/permissao.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { NewFotoUploaderComponent } from "app/util/componente/new-foto-uploader/new-foto-uploader.component";
import validadorCamposIguais from "app/util/validador/validadorCamposIguais";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { ManagementService } from "../instance.service";
import { InstanciaSistema } from "./InstanciaSistema";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-instancia-cadastro",
  templateUrl: "./instancia-cadastro.component.html",
  styleUrls: ["./instancia-cadastro.component.scss"],
})
/**
 * Componente responsável pelo cadastro de novas
 * instâncias no sistema
 */
export class InstanciaCadastroComponent
  extends CadastroBasico<InstanciaSistema>
  implements OnInit, OnDestroy
{
  formGroup: any;
  // permissoes: Permissao[] =[];
  controls: { [key: string]: AbstractControl } = {};

  /**
   * Marcador que indica se trata-se de uma
   * edição
   */
  isEdicao: boolean = false;
  /**
   * Marcacdor que idica se o loading está
   * ativo
   */
  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  instancia: InstanciaSistema;

  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected securityService: SecurityService,
    protected managementService: ManagementService,
    protected toastr: ToastrService,
    protected permissaoService: PermissaoService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "management",
      managementService,
      activatedRoute,
      router,
      toastr,
      notificatorService,
      modalService
    );
  }

  ngOnInit() {
    // this.carregarPermissoes();
    this.initFormGroup();
    // super.ngOnInit();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
        this.indagarSobeCancalamentoDoFluxo();
        if (this.newFotoUploaderComponent) {
          this.newFotoUploaderComponent.ngOnInit();
        }
      }
    });

    this.activatedRoute.url.subscribe((urlSegment) => {
      if (urlSegment && urlSegment.length <= 0) {
        return;
      }

      if (urlSegment[0].path !== "atualizar") {
        return;
      }

      // verificando se trata-se de uma edicao e nao cadastro
      this.activatedRoute.queryParamMap.subscribe((paramMap) => {
        const idEntidade = paramMap.get("id");

        this.idEntidadeRouteParam = idEntidade;

        this.isEdicao =
          idEntidade === null || idEntidade === undefined ? false : true;
        // this.router.getCurrentNavigation().finalUrl.

        if (this.isEdicao) {
          this.isLoading = true;
          this.managementService.findInstanciaByName(idEntidade).subscribe({
            next: (entidadeEditado) => {
              this.entidadeEdicaoCallback(entidadeEditado);

              this.formGroup.addControl(
                "id",
                new UntypedFormControl(entidadeEditado.name)
              );
              this.formGroup.addControl(
                "name",
                new UntypedFormControl(entidadeEditado.name)
              );

              this.isLoading = false;

              // tslint:disable-next-line: align
            },
            error: () => {
              this.modalService.showModal({
                title: `Editar ${this.nomeEntidade}`,
                messageModal: `Não foi possível editar o ${this.nomeEntidade} desejado, verifique a existência do mesmo e tente novamente`,
                btnTitlePositive: "Entendi",
                positiveCallback: () => this.redirecionar(true),
                isOnlyConfirmation: true,
              });
              this.isLoading = false;
            },
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    /**
     * Cancelando incrições
     */
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    super.ngOnDestroy();
  }

  initFormGroup() {
    if (this.isEdicao) {
      this.formGroup = InstanciaSistema.getInitializedControl(this.instancia);
      this.controls = this.formGroup.controls;

      /**
       * desativando campos caso seja instancia de administracao
       */
      if (this.instancia.name === "tensai") {
        setTimeout(() => {
          this.controls.friendlyName.disable();
          this.controls.ativo.disable();
        });
      }

      return;
    }

    this.formGroup = InstanciaSistema.getControl();
    this.controls = this.formGroup.controls;
  }

  habilitarAlteracaoSenha() {
    this.isAlteracaoSenha = !this.isAlteracaoSenha;
    const formControlSenha = this.controls.admin.get("usuario").get("senha");
    const formControlSenhaConfirma = this.controls.admin
      .get("usuario")
      .get("senhaConfirmar");

    const senhaCamposIguaisValidator = validadorCamposIguais(formControlSenha);
    // tslint:disable-next-line:max-line-length
    const senhaConfirmarCamposIguaisValidator = validadorCamposIguais(
      formControlSenhaConfirma
    );

    if (!this.isAlteracaoSenha) {
      formControlSenha.clearValidators();
      formControlSenhaConfirma.clearValidators();
      formControlSenha.reset();
      formControlSenhaConfirma.reset();
    } else {
      formControlSenha.setValidators([
        Validators.required,
        Validators.maxLength(30),
        Validators.minLength(8),
        senhaConfirmarCamposIguaisValidator,
      ]);

      formControlSenhaConfirma.setValidators(senhaCamposIguaisValidator);

      formControlSenha.updateValueAndValidity();
    }
  }

  prepararEntidadeParaPersistencia(
    entidade: InstanciaSistema
  ): InstanciaSistema {
    if (
      this.newFotoUploaderComponent.defaultImage ===
      this.newFotoUploaderComponent.url
    ) {
      entidade.admin.foto_enviada = null;
    } else {
      entidade.admin.foto_enviada.arquivo = this.newFotoUploaderComponent.url;
    }

    const entidadeToSave = { ...entidade };

    return entidadeToSave;
  }

  entidadeEdicaoCallback(entidade: InstanciaSistema) {
    this.instancia = entidade;

    if (this.instancia.admin.foto) {
      this.newFotoUploaderComponent.loadImageByUrl(this.instancia.admin.foto.arquivo);
    }
    this.initFormGroup();
  }

  getRedirectPath(): string[] {
    const listagemAuthorities = [Authority.ROLE_ADMINISTRADOR];
    if (this.securityService.userHasAuthorities(listagemAuthorities)) {
      return ["management"];
    }
    return [""];
  }
}
