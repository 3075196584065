import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { InfoCardDataProps } from "app/componentes/info-card/info-card.component";
import { BadgesComponent } from "app/util/componente/genericTable/components/customCell/badge/badges.component";
import { ActionButtonConfig } from "app/util/componente/genericTable/interfaces/actionButton";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { MeatballConfig } from "app/util/componente/genericTable/interfaces/meatball";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import { TableEvents, TableGlobalConfig } from "app/util/componente/genericTable/interfaces/table";
import {
  checkboxCellFactory,
  componentCellFactory,
  meatballCellFactory,
  statusCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import { FuncionarioService } from "../services/funcionario.service";
import { ModalService } from "app/componentes/modal/modal.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { IVerifyLoginCredentials } from "app/infraestrutura/security/service/loginCredentials";
import { FuncionarioNavigateService } from "../services/funcionario-navigate.service";
import { GenericTableService } from "app/util/componente/genericTable/service/generic-table.service";
import { Authority } from "app/infraestrutura/security/authority";
import { MEATBALL_OPTIONS } from "../constants/meatball";
import { IFilterOptions, ISwitchStatus } from "../interfaces";
import { EmployeeStatusHeaderIndex } from "../constants";

const STATUS_FILTER_ID = 1;
const SORTING_FILTER_ID = 2;

@Component({
  selector: "app-listagem-funcionarios-beta",
  templateUrl: "./listagem-funcionarios.component.html",
  styleUrls: ["./listagem-funcionarios.component.scss"],
})
export class FuncionarioListagemBetaComponent implements OnInit {
  actionButton: ActionButtonConfig = {
    label: "Novo funcionário",
    icon: "fa-light fa-plus",
  };

  showActionButton: boolean = false;

  filters: FilterConfigs[] = [
    {
      id: STATUS_FILTER_ID,
      type: FilterTypes.RADIO,
      icon: FilterIcon.FUNNEL,
      placeholder: "Situação",
      options: [
        { id: 1, label: "Ativo", key: true },
        { id: 2, label: "Inativo", key: false },
      ],
    },
    {
      id: SORTING_FILTER_ID,
      type: FilterTypes.RADIO,
      icon: FilterIcon.SORTING,
      placeholder: "Ordenar",
      options: [
        {
          id: 1,
          label: "Nome de A a Z",
          key: "NOME-ASC",
        },
        {
          id: 2,
          label: "Nome de Z a A",
          key: "NOME-DESC",
        },
      ],
    },
  ];

  searchConfig: SearchInputConfig = {
    delay: 2000,
    placeholder: "Busque por nome ou cargo do funcionário",
  };

  funcionarios = [];

  tableHead: TableHead[] = [
    {
      type: "checkbox",
      checkable: true,
      customStyles: {
        color: 'var(--gray200)'
      }
    },
    {
      type: "text",
      label: "Nome",
    },
    {
      type: "text",
      label: "Cargo",
    },
    {
      type: "text",
      label: "Permissões",
    },
    {
      type: "text",
      label: "Situação",
    },
    {
      type: "text",
      label: "",
    },
  ];

  tableConfig: TableGlobalConfig = {
    colWidths: [".02", ".2", ".15", ".49", ".1", ".04"],
    rowStyles: { "font-size": "14px" },
  };

  tableData: TableRow[] = [];

  meatballConfig: MeatballConfig = {
    type: "GENERIC",
    options: [
      { id: MEATBALL_OPTIONS.EDIT_OPTION_ID, icon: 'fa-regular fa-pen-to-square', label: "Editar" },
      { id: MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID, icon: (rowRef) => rowRef.metadata.ativo ? 'fa-toggle-large-on fa-regular' : 'fa-toggle-large-off fa-regular', label: (rowRef) => rowRef.metadata.ativo ? "Inativar" : "Ativar" },
    ],
  };

  filtersValue: IFilterOptions = {
    sorting: { direction: null, sort: null },
    status: null,
    keywords: [""],
  };

  pagination = {
    totalOfEntries: 1,
    current: 1,
    previous: 1,
  };

  rowCustomStyleId: number = null;
  checkboxModalState: boolean;

  isLoading = false;

  credentials: IVerifyLoginCredentials = {
    login: this.securityService.getAuthenticatedUser().login,
    password: ''
  }

  constructor(
    private employeeService: FuncionarioService,
    private router: Router,
    private notificator: NotificatorService,
    private modalService: ModalService,
    private securityService: SecurityService,
    private genericTableService: GenericTableService,
    private funcionarioNavigateService: FuncionarioNavigateService
  ) {}

  ngOnInit(): void {
    this.getInfoCardData();
    this.getEmployee();
    this.initAuthorityConfigs();

    // captura o valor do checkbox do modal e repassa para os componentes filhos
    this.modalService.getCheckbox().subscribe({
      next: (currValue) => (this.checkboxModalState = currValue),
    });

    this.modalService.getInput().subscribe({
      next: (inputValue) => {
        this.credentials.password = inputValue

        if(inputValue !== null && !inputValue.length) {
          this.modalService.handleInputErrors(['Senha obrigatória']);
        } else {
          this.modalService.cleanInputErrors();
        }
      }
    })
  }

  /**
   * Configura as opções visiveis e acessíveis para o usuário com base em suas permissões
   */
  initAuthorityConfigs() {
    const authorities = this.securityService.getAuthenticatedUserAuthorities();

    this.showActionButton = authorities.some(authority => authority === Authority.CADASTRAR_FUNCIONARIO);

    const canEditUser = authorities.some(authority => [Authority.EDICAO_PERFIL_FUNCIONARIO, Authority.ATUALIZAR_FUNCIONARIO].includes(authority));

    this.meatballConfig.disabledItemsIds = [
      ...(!canEditUser ? [MEATBALL_OPTIONS.EDIT_OPTION_ID, MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID] : []),
    ];
  }

  setFocusOnNewEmployee() {
    this.rowCustomStyleId = 66;
    const employeeId = this.funcionarioNavigateService.getEmployeeId()
    if(employeeId !== null) {
      setTimeout(() => {
        this.genericTableService.insertStyles({
          type: "ROW",
          rowId: employeeId,
          styles: {
            border: '1px solid var(--secondary)'
          },
          styleId: this.rowCustomStyleId
        })
      }, 150)
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick() {
    if(this.rowCustomStyleId !== null) {
      this.genericTableService.clearStyles({
        type: "ROW",
        styleId: this.rowCustomStyleId
      });
      this.rowCustomStyleId = null;
    }
  }

  /**
   * Lida com eventos de clique que ocorrem no meatball genérico
   */
  handleMeatballOptionClick(evt: TableEvents) {
    const { selectedMeatballOption, rowId, rowMetadata } = evt;
    console.log(rowMetadata)
    // TODO: implementar switch de situação
    switch (selectedMeatballOption.id) {
      case MEATBALL_OPTIONS.EDIT_OPTION_ID:
        this.rediretTo({ eventType: "CELL_CLICK", rowId: rowId });
        break;
      case MEATBALL_OPTIONS.ACTIVE_DEACTIVE_OPTION_ID:
        this.toggleStatus({ employeeId: rowId, status: !rowMetadata.ativo, nome: rowMetadata.nome });
        break;
      default:
        break;
    }
  }

  handleEventTable($event: TableEvents) {
    const { eventType } = $event;

    const eventTable = {
      ["PAGINATION_CHANGED"]: this.changePage.bind(this),
      ["SEARCH_FILTER_CHANGED"]: this.handleTextChange.bind(this),
      ["CELL_CLICK"]: this.rediretTo.bind(this),
      ["ACTION_BUTTON_CLICK"]: this.rediretTo.bind(this),
      ["HEADER_CHECKBOX_CLICK"]: () => {},
      ["CELL_CHECKBOX_CLICK"]: () => {},
      ["RADIO_FILTER_CHANGED"]: this.handleChangeRadioFilter.bind(this),
      ["CHECK_FILTER_CHANGED"]: () => {},
      ["MEATBALL_OPTION_SELECTED"]: this.handleMeatballOptionClick.bind(this),
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  handleChangeRadioFilter($event: TableEvents) {
    switch ($event.filterId) {
      case STATUS_FILTER_ID:
        this.handleChangeStatusFilter($event);
        break;
      case SORTING_FILTER_ID:
        this.handleChangeSortingFilter($event);
        break;
      default:
        break;
    }
  }

  /**
   * Controla o filtro de status
   */
  public handleChangeStatusFilter({ filterValue }: TableEvents) {
    const [item] = filterValue;
    this.filtersValue.status = filterValue.length ? item.key : null;
    this.resetPagination();
    this.getEmployee();
  }

  /**
   * Controla o filtro de ordem
   */
  public handleChangeSortingFilter({ filterValue }: TableEvents) {
    const [item] = filterValue;
    if(filterValue.length) {
      const [sort, direction] = item.key.split('-')
      this.filtersValue.sorting.direction = direction
      this.filtersValue.sorting.sort = sort
    } else {
      this.filtersValue.sorting.direction = null
      this.filtersValue.sorting.sort = null
    }
    this.getEmployee();
  }

  changePage({ requestedPage, previousPage }) {
    this.pagination.current = requestedPage;
    this.pagination.previous = previousPage;
    this.getEmployee();
  }

  handleTextChange({ filterValue }) {
    this.filtersValue.keywords = [filterValue];
    this.resetPagination();
    this.getEmployee();
  }

  rediretTo({ eventType, rowId }) {
    const redirectEvent = {
      ["ACTION_BUTTON_CLICK"]: () =>
        this.router.navigate(["funcionario-beta/cadastro"]),
      ["CELL_CLICK"]: () =>
        this.router.navigate(["funcionario-beta/atualizar"], {
          queryParams: { id: rowId },
        }),
    };
    redirectEvent[eventType]();
  }

  resetPagination() {
    this.pagination.current = 1;
    this.pagination.previous = 1;
  }

  toggleStatus({ employeeId, status, nome }: ISwitchStatus) {
    if (!this.checkboxModalState) {
      this.modalService.showModal({
        title: `${status ? "Ativar" : "Inativar"} funcionario`,
        icon: `fa-regular fa-toggle-large-${status ? "off" : "on"}`,
        messageModal: `Deseja mesmo ${
          status ? "ativar" : "inativar"
        } ${nome}`,
        close: true,
        checkbox: true,
        btnTitlePositive: `${status ? "Ativar" : "Inativar"}`,
        positiveCallback: () => this.toggleStatusRequest(employeeId, status),
      });
    } else {
      this.toggleStatusRequest(employeeId, status)
    }
  }

  /**
   * Realiza a mudança do status do funcionario
   * @param employeeId
   * @param status
   */
  toggleStatusRequest(employeeId: number, status: boolean) {
    this.isLoading = true;
    this.employeeService.setEmployeeStatus(employeeId, status).subscribe({
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
      complete: () => {
        this.getEmployee();
        this.updateHeaderData(status);
      },
    });
  }

  /**
   * Atualiza os dados do header ao mudar o status de um cliente
   */
  updateHeaderData(status: boolean) {
    if(status) {
      this.infoCardData.data[EmployeeStatusHeaderIndex.ATIVOS].value++
      this.infoCardData.data[EmployeeStatusHeaderIndex.INATIVOS].value--
    } else {
      this.infoCardData.data[EmployeeStatusHeaderIndex.INATIVOS].value++
      this.infoCardData.data[EmployeeStatusHeaderIndex.ATIVOS].value--
    }
  }

  // Recupera a listagem de funcionarios
  getEmployee() {
    const { keywords, status, sorting } = this.filtersValue;
    this.isLoading = true;
    this.getTotal();
    this.employeeService
      .getEmployeesList(keywords, status, sorting, this.pagination.current - 1)
      .subscribe({
        next: (resp) => {
          const allEmployees = resp && this.buildEmployeesRow(resp);
          this.tableData = allEmployees || [];
          this.funcionarios = resp
        },
        error: (err) => {
          this.isLoading = false;
          this.pagination.current = this.pagination.previous;
          console.error(err);
        },
        complete: () => {
          this.isLoading = false;
          this.setFocusOnNewEmployee();
        },
      });
  }

  // Obtem o total de funcionarios considerando os filtros aplicados
  getTotal() {
    const { keywords, status } = this.filtersValue;
    this.employeeService.getTotalEmployees(keywords, status).subscribe({
      next: (resp) => {
        this.pagination.totalOfEntries = resp;
      },
    });
  }

  buildEmployeesRow(employees: any[]): TableRow[] {
    return employees.map((employee) =>
      tRowFactory(
        checkboxCellFactory().checkable(true).build(),
        textCellFactory(employee.nome)
          .cellStyleBehavior("UNDERLINE_ON_HOVER")
          .build(),
        textCellFactory(employee.cargo)
          .customStyles({
            "pointer-events": "none",
          })
          .build(),
        componentCellFactory()
          .component(BadgesComponent)
          .data(employee.permissoes || [])
          .build(),
        statusCellFactory(
          employee.ativo ? "var(--status-done)" : "var(--status-error)",
          employee.ativo ? "Ativo" : "Inativo",
          "var(--white)"
        ).build(),
        meatballCellFactory().build()
      )
        .id(employee.id)
        .metadata({ ativo: employee.ativo, nome: employee.nome })
        .build()
    );
  }

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "Início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Funcionários",
      itemLink: `/funcionario-beta`,
      active: true,
    },
  ];

  infoCardData: InfoCardDataProps;

  // Recupera os dados do header
  getInfoCardData() {
    this.employeeService.getHeaderData().subscribe({
      next: (data) => {
        this.infoCardData = {
          title: 'Funcionários',
          text: 'Cadastre e gerencie os funcionários que fazem parte da sua equipe',
          data: [
            {
              title: 'Funcionários',
              value: data.total
            },
            {
              title: 'Ativos',
              value: data.ativos
            },
            {
              title: 'Inativos',
              value: data.inativos
            }
          ]
        }
      },
      error: (err) => { console.log(err) }
    });
  }

}
