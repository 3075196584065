<div>
  <div class="pesquisas-localidades-container">
    <div class="localidades-header">
      <div class="localidades-header-title">
        <div
          class="localidades-pin-icon"
          alt="Localidade"
        ></div>
        <p class="localidades-header-title-text">Localidades</p>
      </div>
      <div class="localidades-header-buttons">
        <button (click)="handleShowQuizPreview()" class="btn-ver-questionario">
          <div
            class="ver-questionario-icon"
            alt="Ver questionário"
          ></div>
          <span>VER QUESTIONÁRIO</span>
        </button>
      </div>
    </div>
    <hr />

    <div class="localidades-middle">
      <div class="localidades-middle-i">
        <p class="localidades-middle-text">
          {{ survey.descricaoPesquisa.titulo }}
        </p>
        <p class="localidades-middle-text-ii">
          {{ survey?.descricaoPesquisa.titulo }}
        </p>
      </div>
      <div class="localidades-middle-ii">
        <i class="fa-regular fa-circle-info fa-lg"></i>
        <p class="localidades-middle-text limited-text">
          Selecione a localidade onde sua pesquisa será executada e distribua
          toda a amostra em suas localidades filhas e sub localidades filhas
        </p>
      </div>
    </div>

    <div class="localidades-end">
      <div class="localidades-end-container">
        <app-filterable-select
          label="Local da Pesquisa"
          placeholder="Selecione uma localidade"
          [data]="filterableSelectData"
          (changeSelectedItem)="handleChange($event)"
          [selectedItem]="selectedLocationItem"
          [usingBy]="'LOCALIDADE'"
        >
        </app-filterable-select>
      </div>
    </div>
  </div>
  <div class="pesquisas-localidades-sublocalidades">
    <app-locationslist
      *ngIf="input?.selectedItemEvent?.item"
      [survey]="survey"
      [samples]="locations?.value"
      [location]="input?.selectedItemEvent.item.label"
      [locations]="locations"
      type="localidade"
      (output)="handleLocationListOutput($event)"
      (onCanNotSaveButton)="onCanNotSaveButtonNew($event)"
    >
    </app-locationslist>
  </div>
</div>
