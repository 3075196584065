<div class="containerDashboard">
  <app-sidebar ></app-sidebar>
  <div class="dashPage">
    <app-header
      (loading)="handleLoading($event)"
    ></app-header>
    <div class="dashContent">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- modal de alertas default -->
<app-modal></app-modal>
<!-- loading default -->
<app-loading *ngIf="isLoading"></app-loading>
<!-- chat movidesk -->
<app-movidesk-chat></app-movidesk-chat>
