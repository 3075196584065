<div class="contractor-container" [formGroup]="formGroup">
  <span class="title-wrapper">
    <i class="fa-regular fa-file-signature fa-lg"></i>
    <h3>Contratante</h3>
  </span>

  <hr class="line" />

  <section class="inputs-top">
    <div class="input-wrapper">
      <label>Nome da Contratante*</label>
      <input
        type="text"
        [ngClass]="
          formGroup.get('nome')?.invalid &&
          (formGroup.get('nome')?.dirty || formGroup.get('nome')?.touched)
            ? 'inputs name-input error-input '
            : 'inputs name-input'
        "
        formControlName="nome"
        placeholder="João Victor de Sousa Pinheiros Barbosa Filho"
        maxlength="101"
        mascara-primeiro-digito-sem-espaco
        mascara-remove-accents
        mascara-dynamic-regex
        [allowedChars]="nameRegex"
      />
      <span
        class="error-message"
        *ngIf="
          formGroup.get('nome')?.invalid &&
          (formGroup.get('nome')?.dirty || formGroup.get('nome')?.touched)
        "
      >
        <span *ngIf="formGroup.get('nome')?.errors.required"
          >Campo obrigatório</span
        >
        <span *ngIf="formGroup.get('nome')?.errors.maxlength"
          >Limite máximo de 100 caracteres</span
        >
        <span *ngIf="formGroup.get('nome')?.errors.invalidChars"
          >Você tentou inserir um caracter inválido</span
        >
      </span>
    </div>

    <div class="input-wrapper">
      <label>CPF/CNPJ*</label>
      <input
        type="text"
        [ngClass]="
          (formGroup.get('cpf_cnpj')?.invalid &&
          (submitted || !formGroup.get('cpf_cnpj')?.value.length) &&
          (formGroup.get('cpf_cnpj')?.dirty || formGroup.get('cpf_cnpj')?.touched))
            ? 'inputs contract-doc-input error-input '
            : 'inputs contract-doc-input'
        "
        (keyup)="handleInput($event)"
        (paste)="handleInput($event)"
        formControlName="cpf_cnpj"
        placeholder="000.000.000-00"
        maxlength="18"
        mascara-document
        [initialValue]="formGroup.get('cpf_cnpj').value"
        [attr.disabled]="isEdicao ? '' : null"
      />
      <span
        class="error-message"
        *ngIf="
          formGroup.get('cpf_cnpj')?.invalid &&
          (formGroup.get('cpf_cnpj')?.dirty ||
            formGroup.get('cpf_cnpj')?.touched)
        "
      >
        <span *ngIf="!formGroup.get('cpf_cnpj')?.value.length">Campo obrigatório</span>
        <span *ngIf="formGroup.get('cpf_cnpj')?.value.length && formGroup.get('cpf_cnpj')?.errors && submitted">CPF ou CNPJ inválido</span>
      </span>
    </div>

    <div class="input-wrapper">
      <label>Nome do domínio*</label>
      <input
        type="text"
        [ngClass]="
          formGroup.get('prefixo_url')?.invalid &&
          (formGroup.get('prefixo_url')?.dirty ||
            formGroup.get('prefixo_url')?.touched)
            ? 'inputs domain-input error-input '
            : 'inputs domain-input'
        "
        formControlName="prefixo_url"
        placeholder="joaovictorsousafilho"
        maxlength="64"
        [attr.disabled]="isEdicao ? '' : null"
        mascara-primeiro-digito-sem-espaco
        mascara-remove-accents
        [removeAllAcents]="true"
        mascara-dynamic-regex
        [allowedChars]="domainRegex"
      />
      <label class="prefix">www.tensai.com.br/</label>
      <span
        class="error-message"
        *ngIf="
          formGroup.get('prefixo_url')?.invalid &&
          (formGroup.get('prefixo_url')?.dirty ||
            formGroup.get('prefixo_url')?.touched)
        "
      >
        <span *ngIf="formGroup.get('prefixo_url')?.errors.required">Campo obrigatório</span>
        <span *ngIf="formGroup.get('prefixo_url')?.errors.maxlength">Limite máximo de 63 caracteres</span>
        <span *ngIf="formGroup.get('prefixo_url')?.errors.invalidChars">Você tentou inserir um caracter inválido</span>
        <span *ngIf="formGroup.get('prefixo_url')?.errors.alreadyExists && submitted">{{ prefixError }}</span>
      </span>
    </div>
  </section>

  <section class="inputs-bottom">
    <div class="input-wrapper">
      <label>Tipo de licença*</label>
      <div #licenseSelect class="select" (click)="toggleSelect()">
        <div
          class="select-header"
          [ngClass]="{
            'error-select':
              formGroup.get('tipo_licenca')?.invalid &&
              (formGroup.get('tipo_licenca')?.dirty ||
                formGroup.get('tipo_licenca')?.touched),
            'open-select': licenseIsOpen
          }"
        >
          <span class="selected-option">{{
            this.formGroup.get("tipo_licenca")?.value
              ? this.formGroup.get("tipo_licenca")?.value
              : "Selecione a licença"
          }}</span>
          <div class="select-data-icon">
            <span [class]="!licenseIsOpen ? 'arrow' : 'arrow-rotate'"></span>
          </div>
        </div>
        <div
          [ngClass]="
            formGroup.get('tipo_licenca')?.invalid &&
            (formGroup.get('tipo_licenca')?.dirty ||
              formGroup.get('tipo_licenca')?.touched)
              ? 'options error-select  '
              : 'options'
          "
          *ngIf="licenseIsOpen"
        >
          <!--<hr class="row"/>-->
          <div
            class="option"
            *ngFor="let option of licenseType"
            (click)="handleOptionClick(option)"
          >
            {{ option }}
          </div>
        </div>
      </div>
      <input type="hidden" formControlName="tipo_licenca" />
      <span
        class="error-message"
        *ngIf="
          formGroup.get('tipo_licenca')?.invalid &&
          (formGroup.get('tipo_licenca')?.dirty ||
            formGroup.get('tipo_licenca')?.touched)
        "
      >
        <span *ngIf="formGroup.get('tipo_licenca')?.errors.required"
          >Este campo é obrigatório</span
        >
      </span>
    </div>

    <div class="input-wrapper">
      <label>Situação*</label>
      <div
        #statusDropdown
        class="select status-select"
        (click)="toggleStatus()"
      >
        <div
          class="select-header"
          [ngClass]="statusIsOpen ? 'open-select' : ''"
        >
          <span class="selected-option">
            <div
              class="circle"
              [ngClass]="
                this.formGroup.get('ativo')?.value
                  ? 'circle-active'
                  : 'circle-inactive'
              "
            ></div>
            {{ this.formGroup.get("ativo")?.value ? "Ativo" : "Inativo" }}
          </span>
          <div class="select-data-icon">
            <span [class]="!statusIsOpen ? 'arrow' : 'arrow-rotate'"></span>
          </div>
        </div>
        <div class="options" *ngIf="statusIsOpen">
          <!--<hr class="row"/>-->
          <div
            *ngIf="!this.formGroup.get('ativo')?.value"
            class="option"
            (click)="handleStatusClick(true)"
          >
            <span class="span-option">
              <div class="circle circle-active"></div>
              Ativo
            </span>
          </div>
          <div
            *ngIf="this.formGroup.get('ativo')?.value"
            class="option"
            (click)="handleStatusClick(false)"
          >
            <span class="span-option">
              <div class="circle circle-inactive"></div>
              Inativo
            </span>
          </div>
        </div>
      </div>
      <input type="hidden" formControlName="ativo" />
    </div>
  </section>
</div>
