import { Injectable } from '@angular/core';
import { SecurityService } from '../service/securityService';
import { Router, CanActivate } from '@angular/router';

/**
 * Servico responsavel por verificar se o usuario esta autenticado no sistema,
 * ou seja, se deve impedir que o mesmo acesse paginas como a de login
 */
@Injectable({
  providedIn: 'root',
})
export class NotAlreadyAuthenticatedAuthorizationGuard implements CanActivate {

  constructor(
    private securityService: SecurityService,
    public router: Router,
  ) { }

  canActivate(): boolean {

    if (!this.securityService.getAuthenticatedUser()) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

}
