<section class="main-container">
  <h3>{{ questionData.ordem }} - {{ questionData.enunciado }}</h3>
  <span *ngIf="questionData.obrigatoria" class="required-flag">
    <h4>Pergunta obrigatória</h4>
  </span>
  <div
    [ngClass]="{
      'numeric-container': true,
      blockActions: actionsBlockedBySituation
    }"
  >
    <div
      class="numeric-scale"
      *ngFor="
        let alternativa;
        let i = index;
        of: gerenciador.state.respostas
      "
    >
      <div class="container-numbers" (click)="handleCheck(alternativa)">
        <p
          [ngClass]="{
            'number-item': true,
            'selected-number-item': alternativa.isSelecionada
          }"
        >
          {{ alternativa.enunciado }}
        </p>
        <div
          [ngClass]="{
            'check-button': true,
            'user-choice': alternativa.isRespostaOriginal,
            'auditor-choice': alternativa.isSelecionada
          }"
        ></div>
      </div>
    </div>
    <button
      *ngIf="!questionData.obrigatoria"
      (click)="null"
      class="clean-btn"
    >
      limpar
    </button>
  </div>
  <app-questionnaire-answer-reset
    *ngIf="gerenciador.state.possuiAlteracaoDoAuditor"
    (reset)="handleReset()"
  ></app-questionnaire-answer-reset>
</section>
