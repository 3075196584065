<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<app-info-card
  [title]="infoCardData?.title"
  [text]="infoCardData?.text"
  [data]="infoCardData?.data"
>
</app-info-card>
<div class="table-container">
  <app-generic-table
    [tableConfig]="tableConfig"
    [tHead]="tableHead"
    [data]="tableData"
    [enableFilters]="true"
    [enableSearch]="true"
    [filters]="filters"
    (events)="handleEventTable($event)"
    [currentPage]="pagination.current"
    [totalOfEntries]="totalOfSurveys"
    paginationLabel="Pesquisas"
    [showTotalOfEntries]="true"
    [searchInputConf]="searchInputConf"
  ></app-generic-table>
</div>

<app-loading *ngIf="isLoading"></app-loading>
