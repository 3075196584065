export enum LocalidadeCadastroEventType {

  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELED = 'CANCELED',
}

export class LocalidadeCadastroEvent {

  type: LocalidadeCadastroEventType;
  payload: any | { message: string };
}
