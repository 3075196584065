import { Injectable } from "@angular/core";
import {
  EGroupType,
  ICitation,
  IGroup,
} from "../../interfaces/audit-open-answer";
import { MatchOperationsService } from "./match-operations.service";

@Injectable({
  providedIn: "root",
})
export class AutomatchService {
  constructor(private matchOperationsService: MatchOperationsService) {}

  public processAutoMatch(avulsesArray: ICitation[], groupArray: IGroup[]) {
    const response = this.createAvulseGroup(avulsesArray, groupArray);
    return response;
  }

  // Consolida as alternativas com o mesmo titulo
  private matchingBetweenAvulses(
    avulsesArray: ICitation[],
    groupArray: IGroup[]
  ) {
    const grupos = avulsesArray
      .reduce((acumulador, objeto) => {
        // Verifique se já existe um grupo com o título do objeto
        const grupoExistente = acumulador.find(
          (grupo) => grupo.title.toLowerCase().trim() === objeto.title.toLowerCase().trim()
        );
        if (grupoExistente) {
          // Se o grupo já existe, adicione o objeto a esse grupo
          grupoExistente.backId = grupoExistente.backId;
          grupoExistente.id = `${grupoExistente.id}_${objeto.id}`;
          grupoExistente.citation.push(objeto);
        } else {
          // Se o grupo não existe, crie um novo grupo com o título e o objeto
          acumulador.push({
            backId: null,
            id: `${objeto.id}`,
            title: objeto.title,
            typeGroup: EGroupType.AUTOMATICA,
            citation: [objeto],
            total: 0
          });
        }
        return acumulador;
      }, [] as Array<IGroup>)
      .filter((grupo) => grupo.citation.length > 1);
    return [...groupArray, ...grupos];
  }

  // Adiciona a um grupo de respostas já criado uma alternativa com mesmo titulo
  private matchingBetweenGroupsAndAvulses(
    avulsesArray: ICitation[],
    groupArray: IGroup[]
  ) {
    return groupArray.map((group) => {
      if (group.typeGroup === EGroupType.AUTOMATICA) {
        const match = avulsesArray.filter(
          ({ title }) => title.toLowerCase() === group.title.toLowerCase()
        );
        match.forEach((citation) => {
          const existsInGroup = group.citation.some((c) => c.id === citation.id);
          if (!existsInGroup) {
            group.id = `${group.id}_${citation.id}`;
            group.citation.push(citation);
          }
        });
      }
      return group;
    });
  }

  private verifyIfExistsNewAvulse(
    avulsesArray: ICitation[],
    groupArray: IGroup[]
  ) {
    const newGroupArray = this.matchingBetweenAvulses(avulsesArray, groupArray);
    return this.matchingBetweenGroupsAndAvulses(avulsesArray, newGroupArray);
  }

  // Cria o grupo das citações avulsas
  private createAvulseGroup(avulsesArray: ICitation[], groupArray: IGroup[]) {
    const newGroupArray = this.verifyIfExistsNewAvulse(
      avulsesArray,
      groupArray
    );
    const newAvulseArray =
      this.matchOperationsService.removeAvulseMatchedWithinGroups(
        avulsesArray,
        newGroupArray
      );
    return {
      groups: newGroupArray,
      avulses: newAvulseArray,
    };
  }

  /**
   * Obtem as consolidacoes criadas por automatch de groupArray
   */
  public getAutoMatchGroups(groupArray: IGroup[]): IGroup[] | [] {
    if (!groupArray.length) {
      return [];
    } else {
      const automatchGroups = groupArray.filter(
        (group) => group.typeGroup !== EGroupType.MANUAL
      );
      return automatchGroups;
    }
  }
}
