<!-- 
  Isso pode ser refatorado por um strategy pattern, onde é escolhido qual componente renderizar de acordo com um qualificador, que seria o tipo da pergunta. 
  Porém como só existe essa variação (Video|Foto ou os outros tipos), foi feito um desvio condicional simples. Caso isso mude, será refatorado.
-->
<div *ngIf="trBody.tipoPergunta !== tipoPergunta.VIDEO && trBody.tipoPergunta !== tipoPergunta.FOTO; else fileResponse">
    {{ trBody.resposta }}
</div>
<ng-template #fileResponse>
  <a (click)="visualizarRespostas(trBody.respostaPergunta)" style="cursor: pointer">
    Ver {{ trBody.tipoPergunta === tipoPergunta.VIDEO ? 'Vídeo' : 'Foto' }}
  </a>
</ng-template>