import { Component, Input } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { RespostaComponent } from "../resposta.component";
// tslint:disable-next-line: max-line-length
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { TipoAlternativa } from "../../tipoAlternativa";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
// tslint:disable-next-line: max-line-length
import alternativaAbertaTextoObrigatorioUnica from "app/util/validador/alternativa/alternativaAbertaTextoObrigatorioUnica";

@Component({
  selector: "app-selecao-unica",
  templateUrl: "./selecao-unica.component.html",
  styleUrls: ["./selecao-unica.component.scss"],
})
export class RespostaSelecaoUnicaComponent extends RespostaComponent {
  @Input() public respostaPergunta: RespostaPergunta;
  @Input() public pergunta: PerguntaEsquema;
  @Input() alternativasSelecionadasFormGroup: any;
  @Input() alternativasSelecionadas: any;
  selecionado: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    // atualiza o formulário, setando o id da alternativa selecionada
    // tslint:disable-next-line: max-line-length
    this.alternativasSelecionadasFormGroup.controls.idAlternativaSelecionada.patchValue(
      this.getIdAlternativaSelecionada().toString()
    );

    // escutando alterações no formulário e printando no console
    this.alternativasSelecionadasFormGroup.valueChanges.subscribe((val) => {});

    this.alternativasSelecionadasFormGroup.setValidators([
      alternativaAbertaTextoObrigatorioUnica,
    ]);
  }

  public alternativaSelecionada(alternativa): boolean {
    return (
      alternativa.value.esquema.id ===
      Number(
        this.alternativasSelecionadasFormGroup.value.idAlternativaSelecionada
      )
    );
  }

  /**
   * Verifica se a alternativa passada é do tipo Aberta,
   * seja aberta numero ou aberta texto.
   *
   * @param alternativa
   */
  public isAberta(alternativa): boolean {
    const tipoAlternativa = alternativa.value.esquema.tipoAlternativa;

    return (
      tipoAlternativa === TipoAlternativa.ABERTA_TEXTO ||
      tipoAlternativa === TipoAlternativa.ABERTA_NUMERO
    );
  }

  public isAbertaTexto(alternativa) {
    const tipoAlternativa = alternativa.value.esquema.tipoAlternativa;
    return tipoAlternativa === TipoAlternativa.ABERTA_TEXTO;
  }

  /**
   * Recupera o id da alternativa selecionada entre as alternativasSelecionadas do FormArray
   */
  public getIdAlternativaSelecionada(): number {
    // tslint:disable-next-line: max-line-length
    const alternativasSelecionadas = this.alternativasSelecionadas.value.filter(
      (a) => a.selecionada
    );
    // tslint:disable-next-line: max-line-length
    const idAlternativa =
      alternativasSelecionadas.length > 0
        ? alternativasSelecionadas[0].idAlternativa
        : -1;
    return idAlternativa;
  }

  hasErrors() {
    return this.alternativasSelecionadasFormGroup.status === "INVALID";
  }

  /*
    para editar, basta recuperar, do FormArray, as alteranativas selecionadas em conjunto com o id
  */
  /**
   * @override
   */
  onProximaPergunta() {
    if (this.hasErrors()) {
      return;
    }

    // tslint:disable-next-line: max-line-length
    const idAlternativaSelecionada = Number(
      this.alternativasSelecionadasFormGroup.value.idAlternativaSelecionada
    );
    const alternativas: AlternativaSelecionada[] =
      this.alternativasSelecionadasFormGroup.value.alternativasSelecionadas
        .filter((alternativa) => alternativa.esquema.id === idAlternativaSelecionada)
        .map((alternativaSelecionda) => {
          return <AlternativaSelecionada>{
            id: alternativaSelecionda.id,
            respostaAberta: alternativaSelecionda.respostaAberta,
            idAlternativa: alternativaSelecionda.esquema.id,
            tipo: TipoRespostaPergunta.SELECAO_TEXTO,
            ordem: alternativaSelecionda.esquema.ordem
          };
        });

    // tslint:disable-next-line: max-line-length
    const respostaPergunta: RespostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativas,
      this.respostaPergunta.num
    );

    this.notifyRespostaPergunta(respostaPergunta);
  }

  onSelecionadoRespostaAberta() {
    this.selecionado = true;
  }

  onSelecionadoRespostaFechada() {
    this.selecionado = false;
  }
}
