import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";

export type ProdutividadeSituacaoPesquisas = "em_auditoria" | "nao_auditada" | "pesquisas" | "auditadas";

export enum ProdutividadeSituacaoPesquisasFriendlyNames {
    em_auditoria = "Em Auditoria",
    nao_auditada = "Não Auditada",
    pesquisas = "Pesquisas",
    auditadas = "Auditadas",
}

export enum ProdutividadeSituacaoOperadorColetas {
  REPROVADA = "REPROVADA",
  EM_ANALISE = "EM_ANALISE",
  NAO_AUDITADA = "NAO_AUDITADA",
  APROVADA = "APROVADA"
}

export enum ProdutividadeSituacaoOperadorColetasNomeAmigavel {
  REPROVADA = "Reprovada",
  EM_ANALISE = "Em análise",
  NAO_AUDITADA = "Não auditada",
  APROVADA = "Aprovada"
}

export interface IInfoCardPayload {
  pesquisas: number,
  auditadas: number,
  em_auditoria: number,
  nao_auditada: number
}

export enum OperatorSortFilter {
  NOME = 'NOME',
  COLETAS_CUMPRIDAS = 'COLETAS_CUMPRIDAS',
  DIAS_TRABALHADOS = 'DIAS_TRABALHADOS',
  COLETAS_APROVADAS = 'COLETAS_APROVADAS',
  AVALIACAO_GERAL = 'AVALIACAO_GERAL'
}

export enum CollectionSortFilter {
  DATA_REGISTRO = 'DATA_REGISTRO',
  TEMPO_DURACAO = 'TEMPO_DURACAO',
}

interface IOperatorCollections {
  aprovadas: number;
  cumpridas: number;
  esperadas: number;
  reprovadas: number;
}

interface IOperatorPhoto {
  arquivo: string;
}
export interface IOperator {
  avaliacao_geral: number;
  coletas: IOperatorCollections;
  dias_trabalhados: number;
  foto: IOperatorPhoto;
  id: number;
  nome: string;
}

export interface IOperatorCollection {
  id: number;
  localidade: {
    nome: string;
  };
  data_registro: string;
  inicio_coleta: string;
  fim_coleta: string;
  tempo_duracao: string;
  situacao: ProdutividadeSituacaoOperadorColetas;
}

interface ISorting {
  direction: DirectionOrderTypes;
  sort: OperatorSortFilter;
};

export interface IFilter {
  keywords: string[];
  sorting: ISorting;
  status?: ProdutividadeSituacaoOperadorColetas[];
  date?: ICollectionsDateFilter
}

export interface ICollectionsDateFilter {
  periodo_inicio: string,
  periodo_fim: string
}

export interface IPagination {
  current: number;
  previus: number;
}

export interface IFilterTotalOperators {
  palavrasChave: string[];
}
export interface IDateObject {
  inicial: Date
  final: Date
};
