import Big from 'big.js';

/**
 * Calcula a porcentagem dados dos valores,
 * o valor será retornado com 1 casa decimal
 * acrescido do prefixo '%'
 */
function calcularPorcentagem(valorAtual, valorTotal) {

  if (valorTotal === 0) {
    return '0%';
  }

  const porcentagem = valorAtual / valorTotal * 100;
  const porcentagemArredondada = Math.round(porcentagem * 10) / 10;

  // tslint:disable-next-line: max-line-length
  const porcentagemFinal = `${isNaN(porcentagemArredondada) ? 0 : porcentagemArredondada}%`.replace('.', ',');
  return porcentagemFinal;
}

/**
 * Calcula o valor numerico de acordo com o valorPorcentagem passado por parametro.
 * @param valorNumerico valor numerico total
 * @param valorPorcentagem porcentagem do valor numerico do valor numerico total
 */
function calcularValorCorrespondente(valorNumerico, valorPorcentagem) {

  if (valorNumerico === 0 || valorPorcentagem === 0) {
    return 0;
  }

  const valorNumericoBig = Big(valorNumerico);
  const valorPorcentagemBig = Big(valorPorcentagem / 100);

  return valorNumericoBig.times(valorPorcentagemBig).round().toString();
}

export { calcularPorcentagem, calcularValorCorrespondente };
