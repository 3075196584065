import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment/moment';

// validador que trata de inputs no formato de tempo HH:mm

/**
 * Cria um validador de dados temporais
 * que valida se um determinado campo temporal
 * no formato temporalPattern passado possui
 * um valor igual ou superior ao shortestTemporalValue passado,
 * também no formato temporalPattern
 *
 * @param shortestTemporalValue menor tempo permitido em string seguindo o
 * formato temporalPattern passado
 * @param temporalPattern formato na qual o valor temporal será interpretado
 */
export default (shortestTemporalValue: string, temporalPattern: string) => {

  return (control: AbstractControl): ValidationErrors => {

    const controlTemporalValue = control.value;

    if (ehIgualOuMaior(controlTemporalValue, shortestTemporalValue, temporalPattern)) {
      return null;
    }

    return {
      temporalValueToShort: {
        shortestTemporalValue,
      },
    };

  };
}

/**
 * Verifica se o valor temporal base passado no formato temporalPattern é igual ou maior
 * ao valor temporal toCompare passado.
 * @param base
 * @param toCompare
 * @param temporalPattern
 */
function ehIgualOuMaior(base: string, toCompare: string, temporalPattern: string): boolean {

  const ehIgualOuMaior: boolean = moment(base, temporalPattern)
    .isSameOrAfter(moment(toCompare, temporalPattern));


  return ehIgualOuMaior;
}
