<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<form class="cliente-container" [formGroup]="form" (ngSubmit)="handleSubmit()">
  <section class="cliente-section">
    <div class="header-options">
      <span class="cliente-title">
        <i class="fa-regular fa-clipboard-user"></i>
        <h2>{{isEdicao? 'Editar' : 'Novo'}} cliente</h2>
      </span>

      <span class="cliente-options" *ngIf="isEdicao">
        <div (click)="handleChangeEmail()">
          <i class="fa-regular fa-envelope"></i>
          <p>Alterar e-mail</p>
        </div>
      </span>
    </div>

    <main class="cliente-wrapper">

      <div class="section-wrapper">
        <section class="form-section">
          <div class="input-wrapper">
            <label>Nome do cliente*</label>
            <input
              type="text"
              [ngClass]="(form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)) ? 'inputs name-input error-input ' : 'inputs name-input'"
              formControlName="name"
              placeholder="Maria Morais de Sousa"
              maxlength="71"
              mascara-primeiro-digito-sem-espaco
            />
            <span class="error-message" *ngIf="form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)">
              <span *ngIf="form.get('name')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('name')?.errors.maxlength">Limite máximo de 70 caracteres</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>CPF/CNPJ*</label>
            <input
              type="text"
              [ngClass]="(form.get('document')?.invalid && (submitted || !form.get('document')?.value.length) &&
              (form.get('document')?.dirty || form.get('document')?.touched)) ? 'inputs document-input error-input ' : 'inputs document-input'"
              formControlName="document"
              placeholder="00.000.000-00"
              maxlength="18"
              mascara-document
              (keydown)="handleInputDoc($event)"
              (paste)="handleInputDoc($event)"
              [initialValue]="form.get('document').value"
              [attr.disabled]="isEdicao ? '' : null"
            />
            <span class="error-message" *ngIf="form.get('document')?.invalid && (form.get('document')?.dirty || form.get('document')?.touched)">
              <span *ngIf="!form.get('document')?.value.length">Campo obrigatório</span>
              <span *ngIf="form.get('document')?.errors && form.get('document')?.value.length && submitted">CPF ou CNPJ inválido</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>E-mail de acesso*</label>
            <input
              type="email"
              [ngClass]="(form.get('email')?.invalid && (submitted || !form.get('email')?.value.length) &&
              (form.get('email')?.dirty || form.get('email')?.touched)) ? 'inputs email-input error-input ' : 'inputs email-input'"
              formControlName="email"
              placeholder="email@email.com"
              [pattern]="emailRegex"
              [attr.disabled]="isEdicao ? '' : null"
            />
            <span class="error-message" *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)">
              <span *ngIf="form.get('email')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('email')?.errors.pattern && submitted">Formato de e-mail inválido</span>
            </span>
          </div>

        </section>

        <section class="form-section">

          <div class="input-wrapper">
            <label>CEP</label>
            <input
              type="text"
              [ngClass]="cepErrorControl ? 'inputs cep-input error-input ' : 'inputs cep-input'"
              placeholder="00000-000"
              maxlength="9"
              formControlName="cep"
              mascara-cep
              (blur)="handleInputCep()"
            />
            <span class="error-message" *ngIf="cepErrorControl">
              <span>CEP inválido</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>Logradouro</label>
            <input
              type="text"
              formControlName="address"
              class="inputs address-input"
              placeholder="Ex: Rua dos Limoeiros"
              maxlength="71"
            />
          </div>

          <div class="input-wrapper">
            <label>Número</label>
            <input
              type="text"
              formControlName="number"
              class="inputs number-input"
              placeholder="000"
              maxlength="5"
              mascara-numero
            />
          </div>

          <div class="input-wrapper">
            <label>Complemento</label>
            <input
              type="text"
              formControlName="complement"
              class="inputs complement-input"
              placeholder="Sala 1234"
              maxlength="71"
            />
          </div>

          <div class="input-wrapper">
            <label>Situação</label>
            <div
              #statusDropdown
              class="select status-select"
              (click)="toggleStatus()"
            >
              <div
                class="select-header"
                [ngClass]="statusIsOpen ? 'open-select' : ''"
              >
                <span class="selected-option">
                  <div
                    class="circle"
                    [ngClass]="
                      this.form.get('status')?.value
                        ? 'circle-active'
                        : 'circle-inactive'
                    "
                  ></div>
                  {{ this.form.get("status")?.value ? "Ativo" : "Inativo" }}
                </span>
                <div class="select-data-icon">
                  <span [class]="!statusIsOpen ? 'arrow' : 'arrow-rotate'"></span>
                </div>
              </div>
              <div class="options" *ngIf="statusIsOpen">
                <!--<hr class="row"/>-->
                <div
                  *ngIf="!this.form.get('status')?.value"
                  class="option"
                  (click)="handleStatusClick(true)"
                >
                  <span class="span-option">
                    <div class="circle circle-active"></div>
                    Ativo
                  </span>
                </div>
                <div
                  *ngIf="this.form.get('status')?.value"
                  class="option"
                  (click)="handleStatusClick(false)"
                >
                  <span class="span-option">
                    <div class="circle circle-inactive"></div>
                    Inativo
                  </span>
                </div>
              </div>
            </div>
            <input type="hidden" formControlName="status" />
          </div>
        </section>
      </div>
    </main>
  </section>

  <section class="cliente-section" *ngIf="isPJ()">
    <div class="header-options">
      <span class="cliente-title">
        <i class="fa-regular fa-user-shield"></i>
        <h2>Administrador do cliente</h2>
      </span>
    </div>

    <main class="cliente-wrapper">

      <div class="section-wrapper">
        <section class="form-section">
          <div class="input-wrapper">
            <label>Nome do Responsável*</label>
            <input
              type="text"
              [ngClass]="(form.get('responsible')?.invalid && (form.get('responsible')?.dirty || form.get('responsible')?.touched)) ? 'inputs responsible-input error-input ' : 'inputs responsible-input'"
              formControlName="responsible"
              placeholder="Digite o nome do responsável"
              maxlength="71"
              mascara-primeiro-digito-sem-espaco
            />
            <span class="error-message" *ngIf="form.get('responsible')?.invalid && (form.get('responsible')?.dirty || form.get('responsible')?.touched)">
              <span *ngIf="form.get('responsible')?.errors.required">Campo obrigatório</span>
              <span *ngIf="form.get('responsible')?.errors.maxlength">Limite máximo de 70 caracteres</span>
            </span>
          </div>

          <div class="input-wrapper">
            <label>Telefone</label>
            <input
              type="text"
              formControlName="phone"
              [ngClass]="invalidPhone ? 'inputs phone-input error-input ' : 'inputs phone-input'"
              placeholder="(00) 99111-2222"
              mascara-celular
              (keyup)="validatePhoneInput()"
            />
            <span class="error-message" *ngIf="invalidPhone">
              <span>Telefone inválido</span>
            </span>
          </div>

        </section>

      </div>
    </main>
  </section>

  <div class="footer-buttons">
    <button
      type="button"
      class="footer-cancel-button"
      (click)="handleClose()"
    >
      Cancelar
    </button>

    <button type="submit" class="footer-save-button">
      {{ isEdicao ? 'Salvar' : 'Adicionar' }}
    </button>
  </div>

</form>

<app-loading *ngIf="isLoading"></app-loading>
