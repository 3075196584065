import { IContractor, ICreateContractorPayload, IGroupId, IUpdateContractorPayload } from "../interfaces";

export const buildPostPayload = (contractorObject: IContractor): ICreateContractorPayload => {
  const payload = {
    nome: contractorObject["basicInformation"].nome,
    cpf_cnpj: contractorObject["basicInformation"].cpf_cnpj,
    prefixo_url: contractorObject["basicInformation"].prefixo_url,
    tipo_licenca: contractorObject["basicInformation"].tipo_licenca,
    ativo: contractorObject["basicInformation"].ativo,
    administrador: {
      pessoa: {
        nome: contractorObject["adminInformation"].nome,
        cpf: contractorObject["adminInformation"].cpf,
      },
      usuario: {
        login: contractorObject["adminInformation"].login,
      },
      contato: {
        telefone: null, // input removido do front (atributo permanece no back)
      },
    },
    customizacao: {
      imagem: {
        logotipo_light: contractorObject["customization"].logotipo_light,
        login: contractorObject["customization"].login,
      },
      tema: contractorObject["customization"].tema,
    },
  };

  return payload;
};


export const buildPutPayload = (contractorObject: IContractor, groupId: IGroupId, schemaName: string, adminPhone): IUpdateContractorPayload => {
  const payload = {
    name: schemaName,
    nome: contractorObject["basicInformation"].nome,
    cpf_cnpj: contractorObject["basicInformation"].cpf_cnpj,
    prefixo_url: contractorObject["basicInformation"].prefixo_url,
    tipo_licenca: contractorObject["basicInformation"].tipo_licenca,
    ativo: contractorObject["basicInformation"].ativo,
    administrador: {
      id: groupId.admin,
      pessoa: {
        id: groupId.person,
        nome: contractorObject["adminInformation"].nome,
        cpf: contractorObject["adminInformation"].cpf,
      },
      usuario: {
        id: groupId.user,
        login: contractorObject["adminInformation"].login,
      },
      contato: {
        telefone: adminPhone, // input removido do front (atributo permanece no back)
      },
    },
    customizacao: {
      imagem: {
        logotipo_light: contractorObject["customization"].logotipo_light,
        login: contractorObject["customization"].login,
      },
      tema: contractorObject["customization"].tema,
    },
  };

  return payload;
};
