// tslint:disable-next-line: max-line-length
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CadastroPesquisaStoreState } from '../../store/cadastroPesquisaStoreState';
import { AbstractPerguntaComponent } from '../questionario/componentes/secao/pergunta/abstractPerguntaComponent';

/**
 * Classe base das perguntas de marcacao, seu diferencial
 * basico é o tratamento dos validadores ativos nesta
 * etapa
 */
@Component({
  template: ''
})
export abstract class AbstractPerguntaMarcacaoComponent extends AbstractPerguntaComponent {
  constructor(store: Store<CadastroPesquisaStoreState>) {
    super(store)
  }
  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit();
  }

}
