import { Component } from "@angular/core";

@Component({
  selector: "app-page-privacy",
  templateUrl: "./page-privacy.component.html",
  styleUrls: ["./page-privacy.component.scss"],
})
export class PagePrivacyComponent {

  constructor() {}
}
