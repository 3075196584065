import { AfterViewInit, ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, createComponent, inject } from "@angular/core";
import { MeatballConfig } from "../../interfaces/meatball";
import { TableCell } from "../../interfaces/tCell";
import { TableRow } from "../../interfaces/tRow";
import { MeatballClickEvent } from "../../interfaces/table";

@Component({
  selector: "app-generic-table-cell",
  templateUrl: "./cell.component.html",
  styleUrls: ["./cell.component.scss", "./cell-custom.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericTableCell implements OnInit, AfterViewInit {
  constructor(private appRef: ApplicationRef) {}

  cdr = inject(ChangeDetectorRef);

  @ViewChild('vcr') vcr: ElementRef;

  componentRefs: ComponentRef<any>[] = [];

  @Input() meatballConfig: MeatballConfig = null;
  @Input() data: TableCell = null;
  @Input() rowRef: TableRow = null;
  @Output() cellClick = new EventEmitter<number[]>(null);
  @Output() meatballClick = new EventEmitter<MeatballClickEvent>();

  // Ids das opções do meatball genérico que serão desabilitados
  public disabledMeatballIds: number[] = [];

  ngOnInit(): void {
    this.initGenericMeatball();
  }

  public get isGenericMeatball() {
    return (
      this.data.type === "meatball" && this.meatballConfig.type === "GENERIC"
    );
  }

  handleMeatballClick($event: MeatballClickEvent) {
    this.meatballClick.emit($event);
  }

  initGenericMeatball() {
    if (this.isGenericMeatball) {
      const { disabledItemsIds } = this.meatballConfig;

      if (disabledItemsIds) {
        this.disabledMeatballIds = typeof disabledItemsIds === 'function'
          ? disabledItemsIds({ ...this.rowRef })
          : [...disabledItemsIds];
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.data.type === "Component") {
      this.renderDynamicComponent();
    }
  }

  ngOnDestroy() {
    this.componentRefs.forEach(c => c.destroy());
  }

  getIconClassName() {
    return {
      button: this.data.iconValue.behavior === "button",
      "left-tooltip": this.data.iconValue.dataTooltip?.position === "left",
      "right-tooltip": this.data.iconValue.dataTooltip?.position === "right",
      [this.data.className]: !!this.data.className,
    };
  }

  getTextClassName() {
    return {
      "left-tooltip": this.data.datatooltip?.position === "left",
      "right-tooltip": this.data.datatooltip?.position === "right",
      [this.data.className]: !!this.data.className,
    };
  }

  handleCellClick($event: MouseEvent) {
    this.cellClick.emit(this.data.coordinates);
  }

  renderDynamicComponent<T extends { data: any }>() {
    const componentRef = createComponent(this.data.componentValue.component, { hostElement: this.vcr.nativeElement, environmentInjector: this.appRef.injector });

    (componentRef.instance as { data: T['data']}).data = this.data.componentValue.data;

    this.appRef.attachView(componentRef.hostView);
    this.componentRefs.push(componentRef);
  }
}
