import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TooltipTextService } from "app/componentes/tooltip-text/tooltip-text.service";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import {
  AuditConclusionText,
  surveyListSituationView,
} from "app/modulos/auditoria-beta/constant";
import {
  IProgressInfo,
  ISurveyStatus,
} from "app/modulos/auditoria-beta/interfaces";
import { AuditoriaBetaService } from "app/modulos/auditoria-beta/services/auditoria-beta.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";

@Component({
  selector: "app-audit-progress",
  templateUrl: "./audit-progress.component.html",
  styleUrls: ["./audit-progress.component.scss"],
})
export class AuditProgressComponent implements OnInit {
  @Input() surveyStatus: ISurveyStatus;
  @Input() collectionsObjective: number; // meta de auditoria
  @Input() surveyId: number; // id da pesquisa
  @Input() auditedCollections: number; // coletas auditadas
  @Output() updateSurveyStatus = new EventEmitter();

  progressInfo: IProgressInfo;
  auditTarget: boolean = false; // controla se a meta de auditoria foi alcançada
  auditCollectionsPercent: number = 0; // porcentagem de coletas realizadas (conforme meta)
  auditCollectionsPercentBar: number = 0; // Similar ao auditCollectionsPercent porém limitado a 100% (para não quebrar a barra no layout)
  isTheChiefAuditor: boolean = false;
  auditComplete: boolean = false;

  modalData = new ModalData();

  constructor(
    private tooltipService: TooltipTextService,
    private notifcatorService: NotificatorService,
    private securityService: SecurityService,
    private auditoriaService: AuditoriaBetaService
  ) {}

  ngOnInit(): void {
    this.getAuditedPercentual();
    this.checkAuditorPermissions();
    this.progressInfo = this.renderProgressInfo();
    this.auditTarget = this.auditedCollections >= this.collectionsObjective;

    if (this.surveyStatus.status === "Auditada") {
      this.auditComplete = true;
    }
  }

  /**
   * Verifica se o auditor tem a permissão AUDITOR_CHEFE
   */
  checkAuditorPermissions() {
    const permissions: any[] =
      this.securityService.getAuthenticatedUserAuthorities();
    this.isTheChiefAuditor = permissions.includes("AUDITOR_CHEFE");
  }

  /**
   * Verifica se deve ser possivel concluir o processo de auditoria
   * @this isTheChiefAuditor: Indica se é o auditor chefe
   * @this auditTarget: Indica se a meta de auditoria foi cumprida
   */
  checksConclusionIsValid(): boolean {
    if (
      (this.surveyStatus.status === surveyListSituationView.NAO_AUDITADA ||
        this.surveyStatus.status === surveyListSituationView.EM_AUDITORIA ||
        this.surveyStatus.status === surveyListSituationView.EM_ANALISE) &&
      (this.isTheChiefAuditor || this.auditTarget)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Returns the percentage of collections audited
   */
  getAuditedPercentual(): void {
    if (this.auditedCollections && this.collectionsObjective) {
      this.auditCollectionsPercent = Math.round(
        (this.auditedCollections / this.collectionsObjective) * 100
      );
      this.auditCollectionsPercentBar = Math.min(
        this.auditCollectionsPercent,
        100
      );
    }
  }

  /**
   * Method responsible for calling the tooltip when the user hovers the mouse over the progress bar
   */
  handleShowTooltip(event: MouseEvent): void {
    let { clientX, clientY } = event;
    clientY = clientY + document.documentElement.scrollTop;

    this.tooltipService.setData(`${this.auditedCollections} coletas auditadas`);
    this.tooltipService.showAt(clientX, clientY);
  }

  /**
   * Method responsible for hiding the tooltip
   */
  handleHideTooltip() {
    this.tooltipService.hide();
  }

  /**
   * Returns index based on percentagem
   */
  getIndexBasedOnPercentage() {
    if (this.auditCollectionsPercent === 0) {
      return 0;
    } else if (
      this.auditCollectionsPercent > 0 &&
      this.auditCollectionsPercent <= 80
    ) {
      return 1;
    } else if (
      this.auditCollectionsPercent > 80 &&
      this.auditCollectionsPercent < 100
    ) {
      return 2;
    } else {
      return 3;
    }
  }

  /**
   * Returns audit progress info
   */
  renderProgressInfo(): IProgressInfo {
    const progressObject = {
      0: {
        title: "Vamos começar!",
        text: `O objetivo são ${this.collectionsObjective} coletas auditadas, vamos nessa!`,
      },
      1: {
        title: "Estamos avançando!",
        text: `${this.auditedCollections} coletas já foram auditadas, vamos continuar!`,
      },
      2: {
        title: "Falta pouco!",
        text: `Quase lá! Audite mais ${
          this.collectionsObjective - this.auditedCollections
        } coletas para concluir!`,
      },
      3: {
        title: "Auditoria concluída!",
        text: "",
      },
    };
    return progressObject[this.getIndexBasedOnPercentage()];
  }

  /**
   * Return audit status
   */
  renderAuditStats() {
    if (this.auditComplete) {
      return AuditConclusionText.CONCLUDE;
    } else if (!this.auditComplete && this.auditCollectionsPercent === 100) {
      return AuditConclusionText.CAN_CONCLUDE;
    } else if (!this.auditComplete && this.auditCollectionsPercent < 100) {
      return AuditConclusionText.INCOMPLETE;
    }
  }

  handleConcludesAudit() {
    this.showModal();
  }

  /**
   * Complete the audit and notify the user
   */
  completeAudit() {
    this.auditoriaService.completeAuditProcess(this.surveyId).subscribe({
      complete: () => {
        this.auditComplete = true;
        this.notifcatorService.showInfo("Pesquisa auditada com sucesso!", null);
        this.updateSurveyStatus.emit(null);
      },
      error: () => {
        this.notifcatorService.showError("Erro ao auditar pesquisa", null);
      },
    });
  }

  /**
   * Method responsible for assembling the body of the modal
   */
  buildModal(icon, titleDialog, textDialog, textBtn) {
    this.modalData.show = true;
    this.modalData.type = "information";
    this.modalData.icon = icon;
    this.modalData.titleDialog = titleDialog;
    this.modalData.textDialog = textDialog;
    this.modalData.actionPositive = textBtn;
    this.modalData.positiveCallback = () => this.completeAudit();
  }

  /**
   * Calls the build modal method passing the data to assembly
   */
  showModal() {
    if (!this.auditTarget) {
      this.buildModal(
        "fa-regular fa-circle-exclamation",
        "Concluir auditoria",
        "A quantidade de coletas auditadas ainda não atingiu a porcentagem mínima de confiabilidade. Deseja continuar?",
        "Continuar"
      );
    } else {
      this.buildModal(
        "fa-solid fa-check",
        "Concluir auditoria",
        "Deseja concluir o processo de auditoria da pesquisa?",
        "Continuar"
      );
    }
  }
}
