import { Authority } from './authority';

/**
 * Classe responsável pela configuração
 * de autorização dos routes da aplicação,
 * definindo as autoridades necessarias para
 * o recurso e o redirect caso a autorização falhe
 */
export class RouterItemData {

  necessaryAuthorities: Authority[] = [];
  failureRedirectTo: string = '';

  constructor(necessaryAuthorities: Authority[], failureRedirectTo: string = '') {
    this.necessaryAuthorities = necessaryAuthorities;
    this.failureRedirectTo = failureRedirectTo;
  }

}
