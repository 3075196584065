
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/** 
 * se uma palavra terminar com r acrescenta-se 'es' no final, se não acrecenta-se 's'
 */
function capitalizeLastLetter(string) {
  const lastLetter = string.charAt(string.length - 1) === 'r' ? `${string}(es)` : `${string}(s)`
 
  return lastLetter;
}
/**
 * Formata uma string para exibir apenas caracteres numéricos "[0-9,-]"
 */
function toOnlyNumericString(value: string): string {

  if (!value || value.trim().length === 0) {
    return '';
  }

  const formattedString = value.replace(/[^0-9.,-]/g, '');

  return formattedString;
}

/**
 * Formata uma string para exibir caracteres numéricos truncados, ou seja,
 * sem a vírgula ou ponto das casas decimais
 */
function toTruncatedNumericString(value: string): string {

  if (!value || value.trim().length === 0) {
    return '';
  }

  const onlyNumericString = toOnlyNumericString(value);
  const truncatedNumericString = onlyNumericString.replace(/[,.]/g, '');

  return truncatedNumericString;
}

function toOnlyPositiveNumberString(value: string): string {

  if (!value || value.trim().length === 0) {
    return '';
  }

  const onlyNumericString = toOnlyNumericString(value);
  const positiveNumericString = onlyNumericString.replace(/[-]/g, '');

  return positiveNumericString;
}

function toOnlyPositiveTruncatedNumberString(value: string): string {

  if (!value || value.trim().length === 0) {
    return '';
  }

  const onlyNumericString = toTruncatedNumericString(value);
  const positiveNumericString = onlyNumericString.trim().replace(/[-]/g, '');

  return String(positiveNumericString);
}

//dado uma string, é retornado apenas numeros 
function onlyNumbers(string){
  var numberString = string.replace(/[^0-9]/g, '');

  return parseInt(numberString);
}

const stringFormatter = {
  capitalizeFirstLetter,
  toOnlyNumericString,
  toTruncatedNumericString,
  toOnlyPositiveNumberString,
  toOnlyPositiveTruncatedNumberString,
  capitalizeLastLetter,
  onlyNumbers,
};

export { stringFormatter };
