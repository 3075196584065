import validadorCampoComparacao from './validadorCampoComparacao';

export default validadorCampoComparacao((baseControlValue : any, toCompareControlValue : any) => {

  if (baseControlValue >= toCompareControlValue) {
    return null;
  }

  return {
    maiorQueDeveria: true,
  };
});
