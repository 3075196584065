import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IQuestion } from 'app/modulos/auditoria-beta/interfaces/audit-question-collections';
import { MatCalendar, MatDatepicker } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { CustomDateAdapter } from './CustomAdapter';
import { HeaderDateSelectComponent } from '../filter-select/components/range-date/header-date-select/header-date-select.component';
import { dateFormatter } from 'app/util/misc/dateFormatter';
import moment, { Moment } from 'moment';

const appDateFormat = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
  },
};

@Component({
  selector: 'app-input-range-date',
  templateUrl: './input-range-date.component.html',
  styleUrls: ['./input-range-date.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: appDateFormat,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class InputRangeDateComponent implements OnInit {
  @ViewChild(MatDatepicker) picker: MatDatepicker<any>;

  @Input() inputDate: string;
  @Input() label: string;
  @Input() hasError: boolean;
  @Input() isDisabled: boolean = false;
  @Output() onChange = new EventEmitter<any>(null);

  header = HeaderDateSelectComponent;
  selectedDate: Moment = null;

  hasChanged: boolean = false;

  constructor() {}

  private get answer() {
    return dateFormatter.yMdToDmY(this.inputDate) || null;
  }

  private getAnswer(defaultAnswer?: string) {
    return defaultAnswer !== undefined
      ? defaultAnswer
      : this.answer;
  }

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  private initAnswers(defaultAnswer?: string) {
    this.selectedDate = moment(this.getAnswer(defaultAnswer) as string, "DD/MM/YYYY");

    this.emitAnswer();
  }

  public resetAnswer() {
    this.setHasChangedState(false);
    this.initAnswers(this.answer as string);
  }

  ngOnInit(): void {
    this.initAnswers();
    this.selectedDate = moment(this.getAnswer() as string, "DD/MM/YYYY");
  }

  ngAfterViewInit() {
    this.picker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.picker[
          "_componentRef"
        ].instance._calendar._userSelection.subscribe((event) => {
          this.selectedDate = event.value;
          this.handleConfirm();
          this.picker.close();
        });
      }, 0);

    });
  }

  emitAnswer() {
    this.onChange.emit({
      answer: this.selectedDate ? this.selectedDate.format("DD/MM/YYYY") : null,
      change: this.hasChanged
    });
  }

  /**
   * Emite o valor selecionado ou digitado para o componente pai
   */
  handleConfirm() {
    this.setHasChangedState(true);
    this.emitAnswer();
  }

  /**
   * Limpa a seleção e define como alterado para mostrar o valor original
   */
  handleClear() {
    this.selectedDate = null;
    this.setHasChangedState(true);
    this.emitAnswer();
  }

  /**
   * Controla quando o usuário muda pelo input o valor da data, e emite
   * para o componente pai o novo valor
   */
  onChangeDate($event) {
    this.setHasChangedState(true);
    this.emitAnswer();
  }


}
