export enum DirectionOrderTypes {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ClientStatusHeaderIndex {
  ATIVOS = 1,
  INATIVOS = 2
}

export enum ClientFilterName {
  NOME = 'NOME',
}
