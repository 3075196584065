<div
  class="trow-container"
  (click)="onRowClick($event)"
  [style.height]="height"
  [ngClass]="data.className"
  [ngStyle]="data.customStyles"
>
  <div
    class="tcol-wrapper"
    *ngFor="let cell of data.cells; let i = index"
    [ngStyle]="getDynamicStyles(cell.alignType, widths[i])"
    [style.overflow]="getOverflowStyle(cell)"
  >
    <app-generic-table-cell
      [data]="cell"
      [rowRef]="data"
      [meatballConfig]="meatballConfig"
      (meatballClick)="handleMeatballClick($event)"
      (cellClick)="onCellClick($event)"
    >
    </app-generic-table-cell>
  </div>
</div>
