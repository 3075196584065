<div class="audit-observations-container">
  <div
    class="observations-wrapper"
  >
    <p>Informe uma justificativa para reprovar a coleta <span>{{ collectionId }}</span>.</p>
  </div>

  <!-- Escrever comentários -->
  <div style="position: relative;">
    <div
      class="write-observations"
      [ngClass]="{
        error: publishErrors.anyError(),
        focus: withFocus && !publishErrors.anyError()
      }"
    >
      <textarea
        (focus)="changeFocus()"
        (blur)="changeFocus()"
        [placeholder]="'Insira uma justificativa'"
        [(ngModel)]="publishMessage"
        (input)="handleMessageInput($event)"
      ></textarea>
      <div class="publish-wrapper">
        <app-button
          [textContent]="'Cancelar'"
          [btnStyle]="'outline'"
          [btnStructure]="btnStructure"
          (clickEvent)="handleCancel()"
        ></app-button>

        <app-button
          [textContent]="'Inserir'"
          [disabled]="publishErrors.anyError()"
          [btnStructure]="btnStructure"
          (clickEvent)="handleSave()"
        ></app-button>
      </div>
    </div>

    <span class="error-message" *ngIf="publishErrors.anyError()">{{
      publishErrors.getErrorMessage()
    }}</span>
  </div>
</div>
