import { Component, Input } from "@angular/core";
import { TabelaComponent } from "app/util/componente/tabela/tabela.component";
import { TrBody } from "app/util/componente/tabela/trbody/trBody";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntrevistaEmDesconformidade } from "../entrevistaEmDesconformidade";
import { EntrevistasDesconformidadeService } from "../entrevistas-desconformidade.service";
import { EntrevistasEmDesconformidadeQuery } from "../entrevistasEmDesconformidadeQuery";
import { EntrevistaEmDesconformidadeTrBody } from "./entrevistaEmDesconformidadeTrBody";

@Component({
  selector: "app-entrevistas-desconformidade-table",
  templateUrl: "./entrevistas-desconformidade-table.component.html",
  styleUrls: ["./entrevistas-desconformidade-table.component.scss"],
})
export class EntrevistasDesconformidadeTableComponent extends TabelaComponent {
  @Input()
  public entrevistaEmDesconformidadeQuery: EntrevistasEmDesconformidadeQuery;

  trBodies: any;

  constructor(
    private entrevistaDesconformidadeService: EntrevistasDesconformidadeService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Implementação do método abstrato carregarTrBodies que recupera
   * as entrevistas em desconformidade paginada de acordo os parâmetros
   * de busca passados como @Input deste componente.
   */
  carregarTrBodies(): Observable<TrBody[]> {
    // tslint:disable-next-line: max-line-length
    const observable: Observable<EntrevistaEmDesconformidade[]> =
      this.entrevistaDesconformidadeService.buscarEntrevistasEmDesconformidade(
        this.entrevistaEmDesconformidadeQuery,
        this.ultimoDadoPaginacao
      );

    return observable.pipe(
      map((entrevistasEmDesconformidade: EntrevistaEmDesconformidade[]) => {
        return entrevistasEmDesconformidade.map(this.buildTrBody);
      })
    );
  }

  /**
   * Converte uma EntrevistaEmDesconformidade para
   * uma extensão de TrBody, para que seja compatível com
   * TabelaComponent.
   * @param entrevistaEmDesconformidade
   */
  // tslint:disable-next-line: max-line-length
  buildTrBody(
    entrevistaEmDesconformidade: EntrevistaEmDesconformidade
  ): EntrevistaEmDesconformidadeTrBody {
    const trBody = new EntrevistaEmDesconformidadeTrBody();

    trBody.id = entrevistaEmDesconformidade.id.toString();
    trBody.idLocalidade = entrevistaEmDesconformidade.idLocalidade;
    trBody.nomeLocalidade = entrevistaEmDesconformidade.nomeLocalidade;
    trBody.idOperador = entrevistaEmDesconformidade.idOperador;
    trBody.nomeOperador = entrevistaEmDesconformidade.nomeOperador;
    trBody.tempoDuracao = entrevistaEmDesconformidade.tempoDuracao;

    return trBody;
  }

  /**
   * Implementação do método abstrato getTotalResultados de TabelaComponent,
   * que busca o total de resultados de uma busca por entrevistas em desconformidade
   * baseado nos parâmetros de busca passados como @Input deste componente.
   */
  getTotalResultados(): Observable<number> {
    return this.entrevistaDesconformidadeService.buscarEntrevistasEmDesconformidadeCount(
      this.entrevistaEmDesconformidadeQuery
    );
  }
}
