import { Component, Input, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/util/componente/filterable-select/filterable-select.component";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { EventoInput } from "../../../pesquisa-old/cadastro/inputs/evento/eventoInput";
import { TipoEventoInput } from "../../../pesquisa-old/cadastro/inputs/evento/tipoEventoInput";
import { InputComponent } from "../../../pesquisa-old/cadastro/inputs/inputComponent";
import { PesquisaItem } from "../../../pesquisa-old/pesquisa-select/pesquisaItem";

@Component({
  selector: "app-pergunta-selector",
  templateUrl: "./pergunta-selector.component.html",
  styleUrls: ["./pergunta-selector.component.scss"],
})
export class PerguntaSelectorComponent extends InputComponent {
  @Input() public idPesquisa: number;
  public _idPesquisa: number;

  perguntas: SelectDataItem[] = [];

  isLoading: boolean = false;

  idPerguntaSelecionada: number | null;

  selectedCleaner: Subject<any> = new ReplaySubject<any>();

  /**
   * subject que é notificado sempre que uma nova pesquisa é selecionada.
   */
  @Input() novaPesquisaSelecionada: Subject<PesquisaItem>;
  public novaPesquisaSelecionadaSubscription: Subscription;

  /**
   * @override
   * Construtor responsável por criar uma instância de PerguntaSelectorComponent.
   * Injeta o service RequestService.
   * @param requestService serviço responsável por realizar requisições à API do back-end.
   */
  constructor(private requestService: RequestService) {
    super();
  }

  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit(); // chama initFormGroup na classe super
    this._idPesquisa = this.idPesquisa;
    this.carregarPerguntas();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idPesquisa) {
      this._idPesquisa = changes.idPesquisa.currentValue;
      this.carregarPerguntas();
      this.idPerguntaSelecionada = null;
      this.selectedCleaner.next(changes.idPesquisa.currentValue);
      this.initFormGroup();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.novaPesquisaSelecionadaSubscription) {
      this.novaPesquisaSelecionadaSubscription.unsubscribe();
    }
  }

  /**
   * Inicializa o formGroup deste componente, este método é uma sobrescrita de InputComponent.
   * @override
   */
  initFormGroup() {
    this.control = new UntypedFormControl(
      this.idPerguntaSelecionada ? this.idPerguntaSelecionada : null
    );
    this.formGroup = new UntypedFormGroup({
      pergunta: this.control,
    });
  }

  /**
   * Atualiza a pergunta selecionada dada um identificador da nova pergunta.
   * @param idPerguntaSelecionada
   */
  atualizarFormGroup(idPerguntaSelecionada: number): void {
    this.formGroup.patchValue({
      pergunta: idPerguntaSelecionada,
    });
    // this.onPerguntaSelect();
  }

  perguntaToSelectDataItem(pergunta: PerguntaItem): SelectDataItem {
    return {
      value: pergunta.id,
      label: pergunta.nome,
    };
  }

  /**
   * Realiza a requisição para API, recuperando as perguntas de {this.idPesquisa}
   * para popular o seletor.
   */
  carregarPerguntas() {
    this.isLoading = true;
    const uri = `${apiLocation}/pesquisas/${this.idPesquisa}/perguntas`;

    const queryParams = {
      tipoAlternativa: ["ABERTA_TEXTO", "ABERTA_NUMERO"],
    };

    this.requestService
      .get(uri, null, true, queryParams)
      .subscribe((data: PerguntaItem[]) => {
        this.perguntas = data.map(this.perguntaToSelectDataItem);
        this.isLoading = false;
      });
  }

  /**
   * Callback executado sempre que uma pergunta é selecionada no seletor.
   */
  handleSelectedPergunta(selectedItem: SelectedItemEvent) {
    const idPergunta = selectedItem.item.value;
    this.idPerguntaSelecionada = idPergunta;
    this.atualizarFormGroup(idPergunta);

    const eventoInput: EventoInput = {
      tipo: TipoEventoInput.CHANGE,
      payload: this.control,
    };

    this.notifyParent(eventoInput);
  }
}

/**
 * Classe que representa um item de seleção de pergunta
 */
export class PerguntaItem {
  id: number;
  nome: string;
}
