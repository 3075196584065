import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * Pipe que ordena um determinado array de valores.
 *
 * Este pipe necessita de dois parâmetros (fora a array de valores, claro):
 *  1. o atributo "order" que define se a ordenação será feita de maneira ascendente "asc"
 * ou de maneira decrescente "desc".
 *  2. E o atributo "column" que define qual coluna do item será utilizado para ordenar o
 * conjunto de valores passados. ex: [{nome: 'Pedro'},{nome: 'Victor'},{nome: 'Edmar'}],
 * se o desejado é ordenar a array acima pelo campo "nome" então a coluna passada deve ser
 * exatamente o mesmo nome do campo.
 *
 */
@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(value: any[], order = '', column: string = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (!column || column === '') { return _.sortBy(value); } // sort 1d array
    if (value.length <= 1) { return value; } // array with only one item
    return _.orderBy(value, [column], [order]);
  }
}