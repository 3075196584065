<main>
  <span class="pagination-text">
    <label
    >{{ visitedEntries }} - {{ totalOfEntries }} {{ placeholder }}
  </label>  </span>
  <ul class="pagination">
    <li
      [ngClass]="currentPage === 1 && 'disable'"
      (click)="goToPage(currentPage - 1)"
    >
      <a><i class="fa-regular fa-chevron-left"></i></a>
    </li>
    <li
      *ngFor="let page of visiblePages"
      [class.active]="page === currentPage"
      (click)="goToPage(page)"
    >
      <a>{{ page }}</a>
    </li>
    <li
      [ngClass]="currentPage === totalPages && 'disable'"
      (click)="goToPage(currentPage + 1)"
    >
      <a><i class="fa-regular fa-chevron-right"></i></a>
    </li>
  </ul>
</main>
