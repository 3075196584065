import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IAlternative } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { AlternativaAuditada } from "../../../gerenciador/entidade/alternativa";

type RenderTypes = "checkbox" | "radio";

@Component({
  selector: "app-alternativa-fechada",
  templateUrl: "./fechada.component.html",
  styleUrls: ["./fechada.component.scss"],
})
export class AlternativaFechadaComponent implements OnInit {
  @Input() alternativa = {} as AlternativaAuditada;
  @Input() renderType: RenderTypes = "radio";
  @Input() actionsBlockedBySituation: boolean = false;

  @Output() onCheck = new EventEmitter<{ alternativa: AlternativaAuditada }>(null);

  constructor() {}

  handleCheck(alternativa: AlternativaAuditada) {
    this.onCheck.emit({ alternativa });
  }

  ngOnInit(): void {}
}
