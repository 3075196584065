import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RequestService } from 'app/servico/request.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventoInput } from '../../../inputs/evento/eventoInput';
import { AdicionarVinculoOperador, AlterarVinculoOperadorCotaPercentual, RemoverVinculoOperador, SelecionarOperador } from '../../../store/actions/vinculosAction';
import { CadastroPesquisaStoreState } from '../../../store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../store/passoCadastro';
import { OperadorVinculo } from '../../../store/vinculos/model';

@Component({
  selector: 'app-vinculos-operadores',
  templateUrl: './vinculos-operadores.component.html',
  styleUrls: ['./vinculos-operadores.component.scss'],
})
export class VinculosOperadoresComponent implements OnInit {

  operadoresVinculos: OperadorVinculo[] = [];
  private localidadeVinculoSelecionadaHash: string = '';

  @Input() operadoresVinculosFormGroup: UntypedFormGroup;

  private preparadoParaSalvar: boolean = false;

  // tslint:disable-next-line: max-line-length
  constructor(protected store: Store<CadastroPesquisaStoreState>, private requestService: RequestService) { }

  ngOnInit() {

    const pesquisaObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    pesquisaObservable.subscribe((store) => {
      const passoVinculos = this.getPassoCadastro(store);

      this.localidadeVinculoSelecionadaHash = passoVinculos.localidadeVinculoSelecionadaHash;
      this.operadoresVinculos = passoVinculos.operadoresVinculos[this.localidadeVinculoSelecionadaHash];

      this.preparadoParaSalvar = store.dadosGeraisCadastro.prepararParaSalvar;

    });
  }

  getPassoCadastro(store: CadastroPesquisaStoreState) {
    return store.dadosPasso.get(PassoCadastro.LOCALIDADES);
  }

  getOperadorVinculoFormGroup(hash: string) {
    return <UntypedFormGroup>this.operadoresVinculosFormGroup.get(hash) as UntypedFormGroup;
  }

  getCotaPercentualControl(vinculoOperadorHash: string) {
    // tslint:disable-next-line: max-line-length
    return (<UntypedFormGroup>this.operadoresVinculosFormGroup.get(vinculoOperadorHash)).controls.cotaPercentual;
  }

  /**
   * Callback chamado quando o usuário clica no botão "adicionar operador".
   * uma ação do tipo "AdicionarVinculoOperador" com a localidade vinculo selecionada é
   * enviada.
   */
  adicionarOperador() {
    const adicionarVinculoOperador = new AdicionarVinculoOperador({ localidadeVinculoHash: this.localidadeVinculoSelecionadaHash });
    this.store.dispatch(adicionarVinculoOperador);
  }

  /**
   * Callback executado quando a cota percentual de um vinculoOperador é alterado,
   * esta implementação envia uma Action do tipo AlterarVinculoOperadorCotaPercentual
   * para a store.
   *
   * @param vinculoOperadorHash hash da vinculo operador
   */
  onCotaPercentualChange(vinculoOperadorHash: string) {

    // tslint:disable-next-line: max-line-length
    const vinculoOperadorFormGroup = <UntypedFormGroup>this.operadoresVinculosFormGroup.get(vinculoOperadorHash);
    const cotaPercentualControl = vinculoOperadorFormGroup.controls.cotaPercentual;

    const novaCotaPercentualStr = cotaPercentualControl.value;

    const actionPayload = {
      localidadeVinculoHash: this.localidadeVinculoSelecionadaHash,
      operadorVinculoHash: vinculoOperadorHash,
      novaCotaPercentual: novaCotaPercentualStr,
    };

    // tslint:disable-next-line: max-line-length
    const alterarVinculoOperadorCotaPercentual = new AlterarVinculoOperadorCotaPercentual(actionPayload);

    this.store.dispatch(alterarVinculoOperadorCotaPercentual);
  }

  /**
   * Callback executado sempre que um operador é selecionado.
   * Em resposta a este evento, uma ação do tipo SelecionarOperador é criada
   * com o hash da localidade vinculo selecionada, o hash do operador
   * vinculo e o id do operador selecionado.
   *
   * @param eventoInput
   */
  onOperadorSelect(eventoInput: EventoInput, operadorVinculoHash: string) {

    const eventPayload = eventoInput.payload;

    const operadorSelecionado = {
      id: eventPayload.id,
      nome: eventPayload.nome,
    };

    const selecionarOperador = new SelecionarOperador({
      operadorVinculoHash,
      localidadeVinculoHash: this.localidadeVinculoSelecionadaHash,
      idOperadorSelecionado: operadorSelecionado.id,
    });

    this.store.dispatch(selecionarOperador);
  }

  /**
   * Remove um vinculo operador de acordo com o hash do vinculo operador
   * passado por parâmetro.
   *
   * @param operadorVinculoHash operador vinculo hash
   */
  removerVinculoOperador(operadorVinculoHash: string) {

    const removerVinculoOperador = new RemoverVinculoOperador({
      operadorVinculoHash,
      localidadeVinculoHash: this.localidadeVinculoSelecionadaHash,
    });

    this.store.dispatch(removerVinculoOperador);
  }

}
