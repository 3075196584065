import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ICanNotSaveButton, PesquisaRequest } from "app/modulos/pesquisa-beta/cadastro/pesquisas-cadastro.model";
import { SelectDataItem } from "../../componentes/filterable-select/filterable-select-component";
import { LocationsData, SLOutput } from "../sublocal/sublocal-component";

export interface LocationListOutput {
  alteredLocations: LocationsData;
}

@Component({
  selector: "app-locationslist",
  templateUrl: "./locationslist.component.html",
  styleUrls: ["./locationslist.component.scss"],
})
export class LocationsListComponent implements OnInit {
  @Input() survey: PesquisaRequest;
  @Input() progress: number;
  @Input() samples: number;
  @Input() location: string;
  @Input() locations: LocationsData;
  @Input() type: string; // enum: localidade, vinculo
  @Input() operators: SelectDataItem[];
  @Output() output: EventEmitter<LocationListOutput> = new EventEmitter();
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter()

  toggleChecked: boolean = false;
  /**
   * false => foi alterado a partir do componente sublocal
   * true => foi alterado a partir do próprio componente
   */
  updateSubLocations: boolean;
  operatorsQuantity: number = 0;
  locationsWithOperators: number = 0;
  filterText: string;
  filterStatus: string;
  sortAZ: boolean;
  sortActive: number = 3;

  seeDistribution: boolean = false;
  distribuitionsLocations: LocationsData;

  handleSeeDistribution() {
    if (this.locationsWithOperators !== 0) {
      this.seeDistribution = !this.seeDistribution;
    }
  }

  closeDistribuitionModal = () => {
    this.seeDistribution = false;
  };
  onCanNotSaveButtonNew($event: ICanNotSaveButton): void {
    this.onCanNotSaveButton.emit($event)
  }
  ngOnInit() {
    if (!this.progress) {
      this.progress = 0;
    }
    this.locations.type = this.type;
  }

  statusProgress() {
    if (this.progress === 100) return true;
    return false;
  }

  handleChange(event: SLOutput) {
    // atribui valores
    this.distribuitionsLocations = event.alteredLocations;

    if (this.type === "localidade") {
      setTimeout(() => {
        this.progress = event.progress;
      }, 0);
    } else {
      this.locationsWithOperators = event.locationsWithOp;
      this.operatorsQuantity = event.operators;
    }
    this.output.emit({
      alteredLocations: event.alteredLocations,
    });
  }

  handleTextChange(value: string) {
    this.filterText = value;
  }

  handleSortAZ() {
    this.sortAZ = !this.sortAZ;
  }

  handleSortActive(event: string) {
    if (event === "ativo") {
      this.sortActive = 0;
    } else {
      this.sortActive = 1;
    }
  }

  getAmountOfLocationsWithValue(): number {
    const result = this.locations.locations.filter((loc) => loc.value > 0);

    let count = 0;
    for (let i = 0; i < result.length; i++) {
      count++;
      /*
      Código comentado pois estava acumulando operadores de localidades netas
      e apenas deve ser o somatório de localidades filhas
      */

      // if (result[i].sublocations.length > 0) {
      //   count = count + result[i].sublocations.filter(loc => loc.value > 0).length;
      // }
    }
    return count;
  }

  handleStatusFilterChange(type: string) {
    this.filterStatus = type;
  }

  handleToggleCheckClick(event: boolean) {
    this.toggleChecked = !event;

    this.updateSubLocations = true;
  }

  handleToggleCheckClickBySubLocation(event: boolean) {
    this.toggleChecked = !event;

    this.updateSubLocations = false;
  }

  getStringTemplateForDistribution(): string {
    const amoutOfLocationsWithValue = this.getAmountOfLocationsWithValue();

    if (amoutOfLocationsWithValue >= 10 && this.locationsWithOperators >= 10) {
      return `${this.locationsWithOperators}/${amoutOfLocationsWithValue}`;
    } else if (
      amoutOfLocationsWithValue >= 10 &&
      this.locationsWithOperators < 10
    ) {
      return `0${this.locationsWithOperators}/${amoutOfLocationsWithValue}`;
    } else if (
      amoutOfLocationsWithValue < 10 &&
      this.locationsWithOperators < 10
    ) {
      return `0${this.locationsWithOperators}/0${amoutOfLocationsWithValue}`;
    }
  }
}
