import {
  PerfilEntrevistado,
  PerfilEntrevistadoCaracteristica,
} from '../../../model/perfilEntrevistado';

/**
 * representa uma localidade na etapa 5 do cadastro da pesquisa,
 * o ger. de cotas. cada localidade possui uma lista de parents, seu id e seu nome.
 * além de um atributo para indicar se a localidade deve ser vista pelo usuario
 * (usado no filtro de localidades) e os operadores vinculados a localidade.
 */
export class Localidade {

  /**
   * identificador da localidade
   */
  id: number;

  /**
   * localidades pais desta localidade;
   */
  parents: { nome: string; nivel: number; }[];

  /**
   * nome da localidade, ex: 'manaira'
   */
  nome: string;

  /**
   * flag indicando se a localidade deve ser exibida ou não.
   */
  hidden: boolean;

  /**
   * operadores vinculados a localidade
   */
  operadoresVinculados: OperadorVinculado[];
}

/**
 * representa um operador vinculado a uma localidade,
 * esta est. de dados é utilizada para renderizar tudo
 * o que diz respeito a operadores e perfis na etapa 5
 * do ger. de cotas.
 */
export class OperadorVinculado {

  /**
   * id da entidade vinculo operador
   */
  idVinculoOperador: number;

  /**
   * id do operador propriamente dito
   */
  id: number;

  /**
   * nome do operaodr
   */
  nome: string;
  /**
   * foto do operador
   */
  foto: string;
  /**
   * matricula do operador, ex: '000005'
   */
  matricula: string;

  /**
   * periodo de vinculo do operador,
   * acompanha uma data de inicio e uma data de fim.
   */
  periodoVinculo: PeriodoVinculo;

  /**
   * flag indicando se o operador deve ser exibido ou não.
   * (utilizado na filtragem)
   */
  hidden?: boolean = false;

  /**
   * flag indicando se o operador está expandido ou não.
   * (o usuário pode expandir ou recolher um operador para exibir ou n seus dados)
   */
  expanded?: boolean = true;

  /**
   * perfis que as entrevistas realizadas pelo operador precisa satisfazer
   * para completar a pesquisa.
   */
  perfis?: Perfil[] = [];
}

/**
   * define um perfil que as entrevistas realizadas por um operador precisa satisfazer
   * para completar a pesquisa.
   */
export class Perfil {

  /**
   * id do perfil
   */
  id: number;

  /**
   * id do operador
   */
  idOperador: number;

  /**
   * caracteristicas do perfil
   */
  caracteristicas: Caracteristica[];

  /**
   * numero de entrevistas que o operador precisa realizar
   * satifazendo este perfil
   */
  cotaNumericaPrevista: number;

  /**
   * numero de entrevistas já realizadas pelo operador
   * satisfazendo este perfil.
   */
  cotaNumericaExecutada: number;

  /**
   * flag indicando se o perfil deve ou não ser exibido
   * (usado na busca de perfis)
   */
  hidden: boolean;

  /**
   * cria um perfil (no formato da exibição do gerenciador de cotas) a partir
   * do PerfilEntrevistado (formato da pesquisa), do progresso (cotaPerfil) e
   * de uma flag indicando se este perfil deve ser exibido ou não.
   * @param perfilEntrevistado perfilEntrevistado no formato de busca editar
   * @param progresso Como este perfil foi projetado para estar presente em cada operador
   * separadamente, é necessário o progresso de execução das entrevistas neste perfil, ou seja,
   * a cotaNumericaPrevista e a cotaNumericaExecutada.
   * @param hidden flag indicando se o perfil deve ser "escondido" ou não. opcional.
   * por default é false.
   */
  static from(idOperador: number,
              perfilEntrevistado: PerfilEntrevistado,
              progresso: { cotaNumericaPrevista: number; cotaNumericaExecutada: number},
              hidden: boolean = false): Perfil {

    const caracteristicas: Caracteristica[] = Caracteristica
    .fromMultiple(perfilEntrevistado.perfilEntrevistadoCaracteristicas);

    const perfil: Perfil = {
      idOperador,
      hidden,
      caracteristicas,
      id: perfilEntrevistado.id,
      cotaNumericaPrevista: progresso.cotaNumericaPrevista,
      cotaNumericaExecutada: progresso.cotaNumericaExecutada,
    };

    return perfil;
  }
}

/**
 * representa uma caracteristica de um perfil
 */
export class Caracteristica {

  id: number;
  idPergunta: number;
  nomePergunta: string;
  idAlternativa: number;
  nomeAlternativa: string;
  ordem: number;

  /**
   * Converte uma caracteristica no formato PerfilEntrevistadoCaracterica
   * (formato da edição da pesquisa), para uma instancia de caracteristica
   * no formato da exibição do gerenciador de cotas.
   *
   * @param perfilEntrevistadoCaracteristicas caracteristica de uma pesquisa no formato
   * de edição da pesquisa (buscar-editar).
   */
  // tslint:disable-next-line: max-line-length
  static from(perfilEntrevistadoCaracteristicas: PerfilEntrevistadoCaracteristica): Caracteristica {

    const caracteristica = {
      id: perfilEntrevistadoCaracteristicas.id,
      idPergunta: perfilEntrevistadoCaracteristicas.pergunta.id,
      nomePergunta: perfilEntrevistadoCaracteristicas.pergunta.descricao,
      idAlternativa: perfilEntrevistadoCaracteristicas.alternativa.id,
      nomeAlternativa: perfilEntrevistadoCaracteristicas.alternativa.descricao,
      ordem: perfilEntrevistadoCaracteristicas.ordem,
    };

    return caracteristica;
  }

  /**
   * itera sobre todas as caracteristicas passadas no formato da edição da pesquisa,
   * aplicando a conversão para caracteristicas no formato da exibição do gerenciador
   * de cotas.
   * @param perfilEntrevistadoCaracteristicas
   */
  // tslint:disable-next-line: max-line-length
  static fromMultiple(perfilEntrevistadoCaracteristicas: PerfilEntrevistadoCaracteristica[]): Caracteristica[] {
    return perfilEntrevistadoCaracteristicas.map(Caracteristica.from);
  }
}

export class PeriodoVinculo {

  /**
   * data que o vinculo do operador iniciou
   */
  inicio: string;
  /**
   * data que o vinculo do operador termina.
   */
  fim: string;
}
