<div
  [ngClass]="{
    alternative: true,
    blockActions: actionsBlockedBySituation
  }"
>
  <input
    [ngClass]="{
      selected: alternativa.isSelecionada,
      preselect: !!alternativa.isRespostaOriginal,
      checkbox: renderType === 'checkbox'
    }"
    (click)="handleCheck(alternativa)"
    type="button"
    name="selectable-alternatives"
  />
  <span>{{ alternativa.enunciado }}</span>
</div>
