// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  reporterUrl: "https://tensai-front-reporter.caprover.devs.sogo.com.br",
  formsUrl: "https://tensai-forms-ui.caprover.devs.sogo.com.br/forms",
  apiUrl: "https://tensai-api-2.caprover.devs.sogo.com.br/api",
  analyzeUrl:
    "http://ec2-3-134-134-136.us-east-2.compute.amazonaws.com:9001/app",
  nominatinUrl: (busca) =>
    `https://beta.tensai.com.br/nominatim/search?format=json&addressdetails=true&polygon_geojson=1&q=${busca}`,
  nominatimLookupUrl: (osm_ids: string) =>
    `https://beta.tensai.com.br/nominatim/lookup?osm_ids=${osm_ids}&format=json&addressdetails=true&polygon_geojson=1`,
  nominatinReverseUrl: (lat, long) =>
    `https://beta.tensai.com.br/nominatim/reverse?format=json&lat=${lat}&lon=${long}&addressdetails=true&polygon_geojson=1`,
  viaCepUrl: (cep: string) => `https://viacep.com.br/ws/${cep}/json/`,
  show_errors: ["ERROR_USER", "ERROR_INTEGRATION"],
  movidesk: {
    path: 'https://chat.movidesk.com/Scripts/chat-widget.min.js',
    mdChatClientCode: '9E20CBEAA909424C956296BD4921C70B'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
