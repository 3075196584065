import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PromptModalComponent implements OnInit {

  @Input() modalData: ModalData;

  @Output() onToggleCheckboxLocation = new EventEmitter<boolean>();

  localCheckboxState: boolean;

  handleCloseModal(): boolean {
    return this.modalData.show = !this.modalData.show;
  }
  
  ngOnInit() { }
  
  toggleCheckbox($event) {
    this.localCheckboxState = $event
  }
  
  handlePositiveCallback() {
    if (this.localCheckboxState) {
      this.onToggleCheckboxLocation.emit(true);
    }
    if (this.modalData.positiveCallback) {
      this.modalData.positiveCallback();
    }
    if (this.modalData.close) {
      this.handleCloseModal();
    }
  }

  handleNegativeCallback() {
    if (this.modalData.negativeCallback) {
      this.modalData.negativeCallback();
    }
  }
}

export class ModalData {
  show: boolean;

  // Título do alerta
  titleDialog: string;

  // Flag: indica se o container de ações será renderizado na modal, sempre inicializado em true.
  showActions: boolean = true;

  // Flag: indica se o container line-header será renderizado na modal, sempre inicializado em true.
  showLineHeader: boolean = true;

  // Ícone que será renderizado no canto superior direito da modal, referente à ação de "close".
  closeIconConfig = {
    className: "fa-regular fa-light fa-circle-xmark",
    size: "24px",
  }
  /**
   * Conteúdo textual do alerta
   * caso o usuário passe null será renderizado um ng-content possibilitando personalizar o corpo da modal
   */
  textDialog: string;

  // Nome exibido no botão negativo
  actionNegative: string = 'Cancelar';

  // nome exibido no botão positivo
  actionPositive: string = 'Ok';

  // Callback a ser executado caso a ação positiva seja escolhida
  positiveCallback: Function;

  // Callback a ser executado caso a ação positiva negativa seja escolhida
  negativeCallback: Function;

  close: boolean = true;

  isOnlyConfirmation = false;

  // tipo do modal (layout)
  type: 'warning' | 'information' | 'alert';

  /**
   * é preciso que o arquivo esteja dentro de assets
   * aí é necessário passar o nome da pasta e o nome do arquivo
   */
  imgFile: string = 'icons/icon-warning.svg';

  icon: string = null;
}
