import { AbstractControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { Pergunta } from './pergunta';

export class ElementoSecao {

  ordem: number;
  pergunta: Pergunta;
  /**
   * hash utilizado para diferenciação de elementos
   * no frontEnd, este atributo é indiferente ao back
   */
  hash: string;

  static getControl(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      pergunta: Pergunta.getControl(),
      hash: new UntypedFormControl(uuid()),
    });

    return formGroup;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(elementoSecao: ElementoSecao): AbstractControl {

    const formGroup = new UntypedFormGroup({
      pergunta: Pergunta.getInitializedControl(elementoSecao.pergunta),
      hash: new UntypedFormControl(elementoSecao.hash),
      ordem: new UntypedFormControl(elementoSecao.ordem),
    });

    return formGroup;
  }

}
