<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-permissoes"></i>
      <h2 [routerLink]="'/grupo'">Grupos</h2>
      <span>/</span>
      <h4>adicionar grupo</h4>
    </div>
    <div class="action-header">
      <button class="sg-btn sg-secondary" [routerLink]="'/grupos/grupos-cadastrados'" disabled>ADICIONAR NOVO</button>
    </div>
  </div>
  <form class="content-adicionar" #form="ngForm" [formGroup]="formGroup"
    (ngSubmit)="form.valid && salvar(form.value);showNotificationError()">

    <div class="row form sg-input-group section">
      <div class="col-12 sub-title">
        <h3>Detalhes do grupo</h3>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="campo">
              <label for="">Nome do grupo*</label>
              <input mascara-primeiro-digito-sem-espaco title="nome do grupo" type="text" placeholder="Ex.: admin"
                formControlName="nome" maxlength="20">
              <span class="error-message" [hidden]="!(form.submitted && formControls.nome.errors)">
                Deve ser informado um nome de até 20 caracteres
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="campo">
              <label for="">Status</label>
              <select title="status do grupo" formControlName="ativo">
                <option value="true" selected>Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="campo">
              <label for="">Permissões*</label>
              <select title="permissões do grupo" name="" class="containerGrupo" (change)="onPermissaoSelected($event)">
                <option [value]="null">selecione</option>
                <option [hidden]="permissao.selecionado" [value]="permissao.nomeReal"
                  *ngFor="let permissao of permissoes">
                  {{permissao.nomeAmigavel}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-8 campo cloudTags">
            <label for="">Permissões selecionadas</label>
            <div class="tags">
              <div class="tagItem" [hidden]="!permissao.selecionado" *ngFor="let permissao of permissoes">
                <span>{{permissao.nomeAmigavel}}</span>
                <span class="tagExcluir" title="Remover permissão"
                  (click)="alterarSelecaoPermissao(permissao.nomeReal)">&times;</span>
              </div>
            </div>
            <span class="error-message" [hidden]="!(form.submitted && formControls.permissoes.errors)">
              Ao menos uma permissão deve ser adicionada
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sub-title">
      <h3>Selecione os funcionários que pertencem ao grupo</h3>
    </div>

    <div class="col-md-12">
      <app-tabela-filtravel [backgroundFilter]="'transparent'" [placeholder]="'placeholder'" #tabela
        (eventEmitter)="manipularEventoTabela($event)" [filterService]="funcionarioService"
        [nomeAtributosExibidos]="nomeAtributosTabela" [nomeColunas]="nomeColunasTabela" [exibirOpcaoDownload]="true"
        [apenasListagem]="true" [tableEventProvider]="disparadorEventoTabelaSubject"></app-tabela-filtravel>
    </div>

    <div class="col-12 btns-actions">
      <button class="sg-btn sg-alternate" (click)="cancelarFluxo($event)" title="Cancelar">CANCELAR</button>
      <button *ngIf="!isEdicao" class="sg-btn sg-secondary" type="submit" title="Adicionar grupo">ADICIONAR</button>
      <button *ngIf="isEdicao && getAuthenticatedUserAuthorities().includes(authority.ATUALIZAR_GRUPO)"
        class="sg-btn sg-secondary" type="submit" title="Atualizar dados do grupo">ATUALIZAR</button>
    </div>
  </form>
</div>

<app-loading *ngIf="isLoading || permissoesLoading"></app-loading>
