import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MascaraBase } from './mascaraBase';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-rg]',
})
export class MascaraRgDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 12);
  }

  aplicarMascara(valor: string): string {
   
    // const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);
    
    const stringNumber = valor.replace(/[^a-z0-9]/gi, '');  

    // Coloca um ponto entre o segundo e o terceiro dígitos
    const valorPrimeiraFormatacao = stringNumber.replace(/(\w{2})(\w)/, '$1.$2');

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // para o segundo bloco de números
    const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(/(\w{3})(\w)/,'$1.$2');

    // Coloca um hífen no ultimo dígitos
    const valorTerceiraFormatacao = valorSegundaFormatacao.replace(/(\w{3})(\w{1})$/, '$1-$2');

    return stringNumber;
  }

}
