import { Component, Input } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSliderChange } from "@angular/material/slider";
import { Alternativa } from "../../resposta-pergunta-listagem-tabela/model/alternativa";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-escala-numerica",
  templateUrl: "./escala-numerica.component.html",
  styleUrls: ["./escala-numerica.component.scss"],
})
export class RespostaEscalaNumericaComponent extends RespostaComponent {
  @Input() private respostaPergunta: RespostaPergunta;
  @Input() pergunta: PerguntaEsquema;
  @Input() private alternativasSelecionadasFormGroup: UntypedFormGroup;
  @Input() private alternativasSelecionadas: UntypedFormArray;

  faixaNumerica: any;

  ngOnInit() {
    super.ngOnInit();

    const valorSelecionado = this.getValorFaixaNumericaSelecionado();

    const validadores = [
      Validators.min(this.pergunta.faixaNumerica.intervaloInicio),
      Validators.max(this.pergunta.faixaNumerica.intervaloFim),
    ];

    this.faixaNumerica = new UntypedFormGroup({
      valorSelecionado: new UntypedFormControl(valorSelecionado, validadores),
    });

    this.faixaNumerica.valueChanges.subscribe((val) => {
      // tslint:disable-next-line: max-line-length
      // tslint:disable-next-line: max-line-length
    });
  }

  keepState(ev: MatSliderChange) {
    console.log("changed: ", ev);
  }

  getValorFaixaNumericaSelecionado(): number {
    const selecionados = this.alternativasSelecionadas.controls.filter(
      (a) => a.value.selecionada
    );
    return selecionados.length > 0
      ? Number(
          this.getDescricaoAlternativaPorId(selecionados[0].value.idAlternativa)
        )
      : this.pergunta.faixaNumerica.intervaloInicio;
  }

  getDescricaoAlternativaPorId(idAlternativa: number): string {
    return this.pergunta.alternativas.filter((a) => a.id === idAlternativa)[0]
      .descricao;
  }

  getAlternativaPorValorSelecionado(valorSelecionado: number): Alternativa {
    const alternativaDescricao: string = valorSelecionado.toString();
    // tslint:disable-next-line: max-line-length
    const alternativas: Alternativa[] = this.pergunta.alternativas.filter(
      (a) => a.descricao === alternativaDescricao
    );

    return alternativas.length > 0 ? alternativas[0] : null;
  }

  hasErrors() {
    return this.faixaNumerica.invalid;
  }

  /**
   * @override
   */
  onProximaPergunta() {
    if (this.hasErrors()) {
      return;
    }

    const valorSelecionado: number = this.faixaNumerica.value.valorSelecionado;

    const alternativa: Alternativa =
      this.getAlternativaPorValorSelecionado(valorSelecionado);

    const alternativasSelecionadas: AlternativaSelecionada[] = [
      {
        idAlternativa: alternativa.id,
        tipo: TipoRespostaPergunta.ESCALA_NUMERICA,
        ordem: alternativa.ordem,
      },
    ];

    const respostaPergunta: RespostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativasSelecionadas,
      this.respostaPergunta.num
    );

    this.notifyRespostaPergunta(respostaPergunta);
  }
}
