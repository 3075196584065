import { Component, Input, OnInit } from '@angular/core';
import { IItemBreadcrumb } from './breadcrumb.interface';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() data: IItemBreadcrumb[];
  @Input() goBackText: string = '';

  hasGoBackText: boolean = false;

  constructor(private location: Location) {}

  ngOnInit() {
    if(this.goBackText.length) {
      this.hasGoBackText = true;
    }
  }

  goBack(): void {
    this.location.back();
  }

}
