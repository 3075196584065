import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MascaraBase } from './mascaraBase';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-cpf]',
})
export class MascaraCpfDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 14);
  }

  aplicarMascara(valor: string): string {

    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

    // Coloca um ponto entre o terceiro e o quarto dígitos
    const valorPrimeiraFormatacao = numberString.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    const valorSegundaFormatacao = valorPrimeiraFormatacao.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um hífen entre o terceiro e o quarto dígitos
    const valorTerceiraFormatacao = valorSegundaFormatacao.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return valorTerceiraFormatacao;
  }

}
