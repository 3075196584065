<main #combobox>
  <div
    [style.justify-content]="!selected.length ? 'space-between' : ''"
    class="permission"
  >
    <label *ngIf="!selected.length">Adicionar grupos de permissão</label>
    <ng-container *ngFor="let item of selected">
      <small>{{ item.nome }} <i class="fa-regular fa-xmark" (click)="selectGroup(item.nome)"></i></small>
    </ng-container>
    <i (click)="toggleCombobox()" class="fa-solid fa-plus"></i>
  </div>
  <ul
    [ngClass]="{
      'opened-combobox': comboboxOpened,
      'closed-combobox': !comboboxOpened
    }"
  >
    <div class="input-search">
      <input type="text" [(ngModel)]="inputSearch" placeholder="Buscar..." />
      <i class="fa-regular fa-magnifying-glass"></i>
    </div>
    <p class="missing-message" *ngIf="!getItems.length">
      Nenhum resultado encontrado
    </p>
    <div class="list-items">
      <li *ngFor="let item of getItems" (click)="selectGroup(item.nome)">
        <app-checkbox [isChecked]="item.selecionado"></app-checkbox>
        <p>{{ item.nome }}</p>
      </li>
    </div>
  </ul>
</main>
