<div class="exportacao-modal">

  <form [formGroup]="form" class="sg-input-group">
    <div class="campo">
      <label for="tipoArquivo">Tipo de arquivo</label>
      <select id="tipoArquivo" formControlName="tipoDownload" required>
        <option disabled>Selecionar tipo</option>
        <!--
          
          DESABILITANDO EXPORTAÇÃO DAS GRAVAÇÕES DAS ENTREVISTAS
          JÁ QUE ESTA FUNCIONALIDADE NÃO É CONTEMPLADA NA RELEASE
          BÁSICA DO TENSAI PREVISTA PARA 15/12.
        
        -->
        <!-- <option [ngValue]="tipoDownload.GRAVACOES">Gravações</option> -->
        <option [ngValue]="tipoDownload.CSV">Exportação em CSV</option>
      </select>
      <div class="error-message" *ngIf="form.controls.tipoDownload.errors">
        <p>Por favor, selecione o tipo de download acima</p>
      </div>
    </div>

    <div class="campo" [hidden]="!isExportacaoCSV()">
      <label>Tipo de exportação</label>
      <select formControlName="tipoExportacao" [required]="isExportacaoCSV()">
        <option [ngValue]="tipoExportacao.CODIGO_TEXTO">Código e texto</option>
        <option [ngValue]="tipoExportacao.CODIGO">Apenas Código</option>
        <option [ngValue]="tipoExportacao.TEXTO">Apenas Texto</option>
      </select>
      <div class="error-message" *ngIf="form.controls.tipoExportacao.errors">
        <p>Por favor, selecione um tipo de exportação.</p>
      </div>
    </div>
  </form>

  <div class="btns-actions">
    <button class="sg-btn sg-alternate mr-2" (click)="cancelDownload()" title="cancelar dados">CANCELAR</button>
    <button class="sg-btn sg-secondary" (click)="download()" title="Realizar download">DOWNLOAD</button>
  </div>

</div>

<app-loading *ngIf="isLoading"></app-loading>
