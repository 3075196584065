interface Args {
    id: number | string;
}

/**
 * traz o indice de acordo com o array passado
 * @param id id do objeto a ser pesquisado
 * @param list Uma lista de resposta ou grupo de resposta
 * @returns retorna um index do objeto no array
 */
export function getIndexByList<T extends Args>(id: number | string, list: Array<T>): number {
  return list.findIndex(({ id: itemId }) => itemId === id);
}

/**
 * @param dateString receba uma data em string
 * @returns retorna um new Date
 */
export function splitDate(dateString) {
  const [day, month, year] = dateString.split('/');
  return new Date(year, month - 1, day);
}
