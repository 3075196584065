<div class="audit-observations-container">
  <app-context-loading *ngIf="isLoading; else elseBlock"></app-context-loading>

  <ng-template #elseBlock>
    <div
      class="empty-observations-wrapper"
      *ngIf="
        !observations.comentarios || !observations.comentarios.length;
        else commentsBlock
      "
    >
      <span>Ainda não existem observações para esta coleta.</span>
    </div>

    <ng-template #commentsBlock>
      <!-- Container pai de todos os comentários -->
      <div class="comments-wrapper">
        <!-- Cada comentário -->
        <div
          *ngFor="let comment of observations.comentarios; index as i"
          class="auditor-comment-wrapper"
        >
          <!-- Imagem e nome do auditor -->
          <div
            class="auditor-message-info"
            *ngIf="getPrevMessageAuditorId(i) !== comment.auditor.id"
          >
            <div
              class="profile-pic"
              [ngStyle]="{
                'background-image': 'url(' + comment.auditor.foto + ')'
              }"
            ></div>
            <span class="auditor-name">{{ comment.auditor.nome }}</span>
          </div>
          <!-- Corpo das mensagens -->
          <div class="auditor-message">
            <span class="message-content" [innerText]="comment.conteudo"></span>
            <span class="message-date">{{ comment.dataHora }}</span>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Escrever comentários -->
    <div style="position: relative">
      <div
        *ngIf="allowComments"
        class="write-observations"
        [ngClass]="{
          error: publishErrors.anyError(),
          focus: withFocus && !publishErrors.anyError()
        }"
      >
        <textarea
          (focus)="changeFocus()"
          (blur)="changeFocus()"
          [placeholder]="'Digite o seu comentário'"
          [(ngModel)]="publishMessage"
          (input)="handleMessageInput($event)"
        ></textarea>
        <div class="publish-wrapper">
          <app-button
            [textContent]="'Publicar'"
            [customClass]="'publish-button'"
            [disabled]="publishErrors.anyError()"
            (clickEvent)="handlePublish()"
          ></app-button>
        </div>
      </div>

      <span class="error-message" *ngIf="publishErrors.anyError()">{{
        publishErrors.getErrorMessage()
      }}</span>
    </div>
  </ng-template>
</div>
