<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-operadores"></i>
      <h2 [routerLink]="'/operador'" routerLinkActive="active">Operadores</h2>
      <span>/</span>
      <h4>Operadores cadastrados</h4>
    </div>
    <div
      class="action-header"
      *ngIf="
        getAuthenticatedUserAuthorities().includes(authority.CADASTRAR_OPERADOR)
      "
    >
      <button
        class="sg-btn sg-secondary"
        [routerLink]="'/operador/cadastro'"
        title="adicionar novo operador"
      >
        ADICIONAR NOVO
      </button>
    </div>
  </div>
  <div class="content-clientes-cadastrados">
    <!--
      EXIBINDO OPÇÃO DOWNLOAD = FALSE
      exportação de relatorio de operadores n é contemplado na release
      básica do tensai.
    -->
    <app-tabela-filtravel
      #tabela
      [placeholder]="placeholder"
      (eventEmitter)="manipularEventoTabela($event)"
      [filterService]="operadorService"
      [nomeAtributosExibidos]="nomeAtributosTabela"
      [nomeColunas]="nomeColunasTabela"
      [exibirOpcaoDownload]="false"
      [removePermissions]="operadorRemovePermissions"
      [initialValue]="initialSearchValue"
    ></app-tabela-filtravel>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
