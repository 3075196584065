import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CrudService } from "app/servico/requestService/crudService";
import { RequestService } from "app/servico/request.service";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { PesquisaRequest, PesquisaResponse } from "../pesquisas-cadastro.model";

@Injectable({
  providedIn: "root",
})
export class PesquisaCadastroService extends CrudService<PesquisaRequest> {
  public baseUrl = this.resourcePath;
  public apiUrl = `${apiLocation}`;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/pesquisas`);
  }

  // TODO: listar apenas as paginações
  listarTodos(): Observable<PesquisaResponse[]> {
    const requestUrl = `${this.baseUrl}/pesquisas`;
    const response = <Observable<PesquisaResponse[]>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  encontrar(id: number): Observable<PesquisaResponse> {
    const requestUrl = `${this.baseUrl}/buscar-editar/${id}`;
    const response = <Observable<PesquisaResponse>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  validateFields(survey: PesquisaRequest): PesquisaRequest {
    const newData = { ...survey };
    // definir uma constante para as amostras online
    if (!newData.configuracaoPesquisa.amostrasOnline) {
      newData.configuracaoPesquisa.amostrasOnline = 0;
    }
    // definir o tempo mínimo para uma pesquisa
    if (
      newData.configuracaoPesquisa.tempoMinimo &&
      newData.configuracaoPesquisa.tempoMinimo.length < 3
    ) {
      newData.configuracaoPesquisa.tempoMinimo = `${newData.configuracaoPesquisa.tempoMinimo}:00`;
    }

    /*
      ==================================

      Foram acrescentadas este bloco de código a fim de que fosse possivel
      atualizar os dados da pesquisa.
      O Payload da API dava bad request quando o tempoMinimo não era informado
      na configuracaoPesquisa, ou quando o formado dele não correspondia ao suportado pela API

      E a API também não suportava o atributo analisePesquisa como sendo null,
      então apenas setando ele para o objeto com atributo possuiTracking setado para false,
      foi possivel corrigir o erro do payload e alterar o status da pesquisa para "Execucao"
    */

    if (newData.configuracaoPesquisa.tempoMinimo === null) {
      newData.configuracaoPesquisa.tempoMinimo = "01:00";
    }

    if (newData.analisePesquisa === null) {
      newData.analisePesquisa = {
        possuiTracking: false,
      };
    }

    // ==================================

    // result
    return newData;
  }

  salvarPesquisa(research: PesquisaRequest): Observable<PesquisaResponse> {
    const requestUrl = `${this.baseUrl}`;
    const response = <Observable<PesquisaResponse>>(
      this.requestService.post(requestUrl, this.validateFields(research))
    );
    return response;
  }

  atualizarPesquisa(research: PesquisaRequest): Observable<PesquisaResponse> {
    const requestUrl = `${this.baseUrl}/${research.id}`;
    const response = <Observable<PesquisaResponse>>(
      this.requestService.put(requestUrl, this.validateFields(research))
    );
    return response;
  }

  // Obter Operadores da pesquisa
  operadoresPorPesquisa(idPesquisa: number): Observable<any> {
    const requestUrl = `${this.baseUrl}/${idPesquisa}/operadores`;
    const response = <Observable<any>>this.requestService.get(requestUrl);

    return response;
  }

  // Dados da Pesquisa - Coletas e Entrevistas Rejeitadas
  getEstatisticasByIdOfSurvey(idPesquisa: number) {
    const requestUrl = `${this.apiUrl}/produtividade?idPesquisa=${idPesquisa}`;

    const response = <Observable<any>>this.requestService.get(requestUrl);

    return response;
  }
}
