import { UntypedFormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';
import validadorCampoMaiorIgual from 'app/util/validador/validadorCampoMaiorIgual';
import validadorCampoMenorIgual from 'app/util/validador/validadorCampoMenorIgual';

export class FaixaPergunta {

  intervaloInicio: number;
  intervaloFim: number;
  // menor: number;
  // maior: number;

  static getControl(intervaloMinimoValue: number = 1, intervaloFimValue: number = 2): UntypedFormGroup {

    const intervaloInicioControl = new UntypedFormControl(intervaloMinimoValue, []);
    const intervaloFimControl = new UntypedFormControl(intervaloFimValue, []);
    // const menorControl = new FormControl(menorValue, []);
    // const maiorControl = new FormControl(maiorValue, []);

    const formGroup = new UntypedFormGroup({
      intervaloInicio: intervaloInicioControl,
      intervaloFim: intervaloFimControl,
      // menor: menorControl,
      // maior: maiorControl,
    });

    return formGroup;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(faixaPergunta: FaixaPergunta): AbstractControl {

    if (!faixaPergunta) {
      return FaixaPergunta.getControl();
    }

    const formGroup = new UntypedFormGroup({
      intervaloInicio: new UntypedFormControl(faixaPergunta.intervaloInicio),
      intervaloFim: new UntypedFormControl(faixaPergunta.intervaloFim),
      // menor: new FormControl(faixaPergunta.menor),
      // maior: new FormControl(faixaPergunta.maior),
    });

    return formGroup;
  }

}
