import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { SelectDataItem } from "app/componentes/filterable-select/filterable-select-component";
import { NotificatorService } from "app/notificador/notificator.service";
import { formatDataToResultAccordeonSelect } from "../../../util/formatter";
import { parseDate } from "../charts/utils/parseDate";
import { IResultResponse } from "../results-interface";
import { ResultsService } from "../services/results.service";

interface AccordionList {
  id_pesquisa: number;
  data_inicial: string;
  data_final: string;
  cliente: string;
  titulo_curto: string;
}

enum Layout {
  GRID = "GRID",
  COLUMN = "COLUMN",
}

enum SurveyStatus {
  EXECUCAO = "Execução",
  CONCLUIDO = "Concluído"
}

@Component({
  selector: "app-resultado-pesquisa",
  templateUrl: "./resultado-pesquisa.component.html",
  styleUrls: ["./resultado-pesquisa.component.scss"],
})
export class ResultadoPesquisaComponent implements OnInit {
  results: IResultResponse = {} as IResultResponse;
  surveyList: SelectDataItem[] = [];
  selectedSurvey: SelectDataItem;
  hasNextPage = true;
  filterSurveyKeywords = [""];
  page = 0;
  surveyStatus: SurveyStatus;
  svgColor: string = 'var(--tertiary)';

  constructor(
    private resultsservice: ResultsService,
    private route: ActivatedRoute,
    private router: Router,
    private notifcator: NotificatorService
  ) {}

  ngOnInit() {
    this.getResults();
    this.getSurveys();
    this.setSelectedSurvey();
    // console.log(this.route.snapshot.params["id"]);
  }

  surveyId = this.route.snapshot.params["id"];
  layout = Layout.GRID;

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Resultados",
      itemLink: "/resultados",
      active: true,
    },
  ];

  // Seleção de um item no menú acordeon muda a pesquisa selecionada
  handleChange({ item: { value } }: any) {
    this.router.navigate([`/resultados/resultado-pesquisa/${value}`]);
    this.surveyId = value;
    this.getResults();
  }
  // Muda o modo de visulização dos gráficos de grid para linhas
  selectLayout(selectLayout: string) {
    this.layout = Layout[selectLayout];
  }

  //  Recupera os dados de uma pesquisa no momento a partir do arquivo de mocks.
  getResults() {
    this.resultsservice.getSurveyResults(this.surveyId).subscribe({
      next: (data) => {
        this.results = data
        this.surveyStatus = SurveyStatus[data.pesquisa?.status_pesquisa.toUpperCase()];
      },
      error: () => this.notifcator.showError('Erro', 'Ocorreu um problema ao renderizar os gráficos')
    })
  }

  // Recupera a lista de pesquisas para o menu acordeon
  getSurveys() {
    this.resultsservice.getSurveyList(0).subscribe((resp: AccordionList[]) => {
      this.surveyList = formatDataToResultAccordeonSelect(resp);
    });
  }

  setSelectedSurvey() {
    const surveyTitle = this.route.snapshot.params["id"].toString();
    const keywords = [...surveyTitle.split(" ")];
    this.resultsservice
      .getSurveyList(0, keywords)
      .subscribe((resp: AccordionList[]) => {
        resp.map((item) => {
          if (!item.titulo_curto.localeCompare(surveyTitle)) {
            this.surveyId = item.id_pesquisa;
            this.selectedSurvey = formatDataToResultAccordeonSelect([item])[0];
          }
        });
      });
  }

  filterSurveys() {
    this.resultsservice
      .getSurveyList(this.page, this.filterSurveyKeywords)
      .subscribe((resp: any[]) => {
        // Recupera e formata os dados para visualização no menu
        this.surveyList = formatDataToResultAccordeonSelect(resp);
      });
  }

  changeInputText($event: string) {
    this.page = 0;
    this.hasNextPage = true;
    this.filterSurveyKeywords = $event.split(" ");
    this.filterSurveys();
  }

/**
 * Convert date format
 * @param date: date yyyy-mm-dd
 * @returns date: dd/mm/yyyy
 */
  dateFormatter(date) {
    if(date) {
      const newdate = parseDate(date)
      return newdate
    }
  }

  onScrollBottom({ target: { scrollTop, scrollHeight, clientHeight } }) {
    if (scrollHeight - scrollTop === clientHeight && this.hasNextPage) {
      this.page++;
      this.resultsservice
        .getSurveyList(this.page, this.filterSurveyKeywords)
        .subscribe((resp: any[]) => {
          // Recupera e formata os dados para visualização no menu
          this.surveyList = [
            ...this.surveyList,
            ...formatDataToResultAccordeonSelect(resp),
          ];
          this.hasNextPage = resp.length === 16;
        });
    }
  }
}
