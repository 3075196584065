import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Contato } from "app/entidade/contato";
import { FotoEnviada } from "app/entidade/fotoEnviada";
import { Pessoa } from "app/entidade/pessoa";
import { Usuario } from "app/entidade/usuario";
import validadorCamposIguais from "app/util/validador/validadorCamposIguais";
import validadorCpf from "app/util/validador/validadorCpf";

export class Administrador {
  id: string;
  pessoa: Pessoa;
  usuario: Usuario;
  contato: Contato;
  foto: FotoEnviada;
  foto_enviada: FotoEnviada;

  constructor() {
    this.pessoa = new Pessoa();
    this.usuario = new Usuario();
    this.contato = new Contato();
    this.foto_enviada = new FotoEnviada();
  }

  static getControl(): UntypedFormGroup {
    return new UntypedFormGroup({
      pessoa: this.getControlPessoa(),
      usuario: this.getControlUsuario(),
      contato: this.getControlContato(),
      fotoEnviada: this.getControlFoto(),
      foto_enviada: this.getControlFoto(),
    });
  }

  static getInicializeControlFoto(foto: string): UntypedFormGroup {
    return new UntypedFormGroup({
      arquivo: new UntypedFormControl(foto),
    });
  }
  static getInicializeControlContato(contato: Contato): UntypedFormGroup {
    return new UntypedFormGroup({
      telefone: new UntypedFormControl(contato.telefone),
    });
  }
  static getInicializeControlUsuario(usuario: Usuario): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(usuario.id),
      login: new UntypedFormControl(usuario.login, [Validators.required]),
      senha_atual: new UntypedFormControl(),
      senha: new UntypedFormControl(),
      senha_nova: new UntypedFormControl(),
      senhaConfirmar: new UntypedFormControl(),
      // ativo: new FormControl(true, [Validators.required]),
    });
  }
  static getInicializeControlPessoa(pessoa: Pessoa): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(pessoa.id),
      nome: new UntypedFormControl(pessoa.nome, [Validators.required]),
      cpf: new UntypedFormControl(pessoa.cpf, [
        Validators.required,
        validadorCpf,
      ]),
    });
  }

  // controls cadastro
  static getControlFoto(): UntypedFormGroup {
    return new UntypedFormGroup({
      arquivo: new UntypedFormControl(),
    });
  }
  static getControlContato(): UntypedFormGroup {
    return new UntypedFormGroup({
      telefone: new UntypedFormControl(),
    });
  }
  static getControlUsuario(): UntypedFormGroup {
    const senha_atual = new UntypedFormControl();
    const senha = new UntypedFormControl();
    const senha_nova = new UntypedFormControl();
    const senhaConfirmar = new UntypedFormControl();

    const senhaCamposIguaisValidator = validadorCamposIguais(senha);
    // tslint:disable-next-line:max-line-length
    const senhaConfirmarCamposIguaisValidator =
      validadorCamposIguais(senhaConfirmar);

    senha_atual.setValidators(Validators.required);
    // tslint:disable-next-line: max-line-length
    senha.setValidators([
      Validators.required,
      Validators.maxLength(30),
      Validators.minLength(8),
      senhaConfirmarCamposIguaisValidator,
    ]);
    senhaConfirmar.setValidators(senhaCamposIguaisValidator);

    return new UntypedFormGroup({
      login: new UntypedFormControl("", [Validators.required]),
      senha_atual,
      // tslint:disable-next-line: object-shorthand-properties-first
      senha,
      // tslint:disable-next-line: object-shorthand-properties-first
      senha_nova,
      // tslint:disable-next-line: object-shorthand-properties-first
      senhaConfirmar,
      ativo: new UntypedFormControl(true, [Validators.required]),
    });
  }
  static getControlPessoa(): UntypedFormGroup {
    return new UntypedFormGroup({
      nome: new UntypedFormControl("", [Validators.required]),
      cpf: new UntypedFormControl("", [Validators.required, validadorCpf]),
    });
  }

  static getInitializedControl(admin: Administrador) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(admin.id),
      pessoa: this.getInicializeControlPessoa(admin.pessoa),
      usuario: this.getInicializeControlUsuario(admin.usuario),
      contato: this.getInicializeControlContato(admin.contato),
      foto_enviada: this.getInicializeControlFoto(admin.foto.arquivo),
    });
  }
}
