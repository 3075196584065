export class Endereco {
  id: number;
  cep: string;
  logradouro: string;
  numero: string;
  bairro: string;
  cidadeEstado?: string;
  cidade: string;
  estado: string;
  complemento?: string;
}
