import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import validadorAoMenosUmControlComValor from 'app/util/validador/validadorAoMenosUmControlComValor';
import validadorMarcacao from 'app/util/validador/pergunta/validadorMarcacao';

/**
 * Adiciona validadores de pulo e diferente ao formGroup de pergunta
 */
export default (perguntaFg: UntypedFormGroup) => {

  const controls = perguntaFg.controls;

  /**
   * adiciona validadores de pulo a nível de pergunta
   */
  if (perguntaFg.controls.possuiPuloPergunta.value) {
    const marcacaoPerguntaControl = <UntypedFormGroup>perguntaFg.controls.marcacaoPergunta;
    const perguntaDestinoControl = <UntypedFormGroup>marcacaoPerguntaControl.controls.perguntaDestino;

    perguntaDestinoControl.setValidators([validadorMarcacao]);
    perguntaDestinoControl.updateValueAndValidity();
  }

  // console.log('[addPuloEDiferenteValidators] pergunta: ', perguntaFg);

  if (controls.possuiPulo.value || controls.possuiDiferenteDe.value) {
    const alternativasFormArray = <UntypedFormArray>perguntaFg.get('alternativas');

    const codigoMarcacaoAlvoPuloControls: UntypedFormControl[] = [];
    const codigoMarcacaoAlvoDiferenteControls: UntypedFormControl[] = [];

    alternativasFormArray.controls.forEach((alternativaControl: UntypedFormGroup) => {

      const marcacaoFg = <UntypedFormGroup>alternativaControl.controls.marcacao;

      if (!marcacaoFg) {
        return;
      }

      if (controls.possuiPulo.value && marcacaoFg.controls.perguntaDestino) {
        // tslint:disable-next-line: max-line-length
        const codigoMarcacaoAlvoPuloControl = <UntypedFormControl>marcacaoFg.controls.perguntaDestino.get('codigoMarcacao');
        /**
         * Coletando os controls alvos da marcacao
         */
        codigoMarcacaoAlvoPuloControls.push(codigoMarcacaoAlvoPuloControl);
      }

      if (controls.possuiDiferenteDe.value && marcacaoFg.controls.perguntasDiferente) {
        // tslint:disable-next-line: max-line-length
        const codigoMarcacaoAlvoDiferenteControl = <UntypedFormControl>marcacaoFg.controls.perguntasDiferente.get('codigoMarcacao');
        /**
         * Coletando os controls alvos da marcacao
         */
        codigoMarcacaoAlvoDiferenteControls.push(codigoMarcacaoAlvoDiferenteControl);
      }
    });

    // tslint:disable-next-line: max-line-length

    

    

    /**
    * Adicionando os validadores de pulo/diferenca para cada
    * alternativa
    */
    alternativasFormArray.controls.forEach((alternativaControl: UntypedFormGroup) => {

      const marcacaoFg = <UntypedFormGroup>alternativaControl.controls.marcacao;

      if (!marcacaoFg) {
        return;
      }

      const puloControl = <UntypedFormControl>marcacaoFg.controls.perguntaDestino;
      const diferenteControl = <UntypedFormControl>marcacaoFg.controls.perguntasDiferente;

      if (controls.possuiPulo.value && puloControl) {
        // tslint:disable-next-line: max-line-length
        puloControl.setValidators([validadorAoMenosUmControlComValor(codigoMarcacaoAlvoPuloControls)]);
        puloControl.updateValueAndValidity();
      }

      if (controls.possuiDiferenteDe.value && diferenteControl) {
        // tslint:disable-next-line: max-line-length
        diferenteControl.setValidators([validadorAoMenosUmControlComValor(codigoMarcacaoAlvoDiferenteControls)]);
        diferenteControl.updateValueAndValidity();
      }
    });
  }

};
