<!--
  Todo o componente do passo 2 do cadastro de pesquisa foi feito
  com base na V3 do figma
-->
<!-- tela de listagem de seções e perguntas -->
<app-pesquisas-questionario-secoes-listagem
  *ngIf="screenMode === 1"
  [activeSectionElementData]="activeSectionElementData"
  [viewToken]="viewToken"
  [surveyTitle]="surveyTitle"
  [models]="sectionsModels"
  [codigoMarcacaoDaPerguntaCopia]="codigoMarcacaoDaPerguntaCopia"
  (isReady)="handleReadyChanges($event)"
  (onSaveAll)="handleSaveAllAction($event)"
  (onNewSection)="handleNewSectionAction()"
  (onEditSection)="handleEditSectionAction($event)"
  (onDeleteSection)="handleShowDeleteSectionComandePrompt($event)"
  (onNewSectionElem)="handleNewSectionElemAction($event)"
  (onEditSectionElem)="handleEditSectionElemAction($event)"
  (onDuplicateSectionElem)="handleDuplicateSectionElemAction($event)"
  (onDeleteSectionElem)="handleDeleteSectionElemAction($event)"

  (onCanNotSaveButton)="onCanNotSaveButtonQuestion($event)"
></app-pesquisas-questionario-secoes-listagem>
<!-- tela de cadastro de seção -->
<app-pesquisas-questionario-secoes-cadastro
  *ngIf="screenMode === 2"
  [surveyTitle]="surveyTitle"
  [model]="editedSectionModel"
  (onSave)="handleSectionFormSave($event)"
  (onChange)="handleSectionFormChange()"
  (onCancel)="handleSectionFormCancel()"

  (onCanNotSaveButton)="onCanNotSaveButtonQuestion($event)"
></app-pesquisas-questionario-secoes-cadastro>
<!-- cadastrar pergunta -->
<app-pesquisas-questionario-secoes-pergunta-cadastro
  *ngIf="screenMode === 3"
  [surveyTitle]="surveyTitle"
  [sectionId]="selectedSectionId"
  [sectionSeq]="selectedSectionSeq"
  [sectionName]="selectedSectionName"
  [model]="editedSectionElemModel"
  [desejaEditar]="screenMode === 3 ? true : false"
  (onCancel)="handleSectionElemFormCancel()"
  (onSave)="handleSectionElemFormSave($event)"

  (onCanNotSaveButton)="onCanNotSaveButtonQuestion($event)"
></app-pesquisas-questionario-secoes-pergunta-cadastro>
