import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-questionnaire-answer-reset",
  templateUrl: "./answer-reset.component.html",
  styleUrls: ["./answer-reset.component.scss"],
})
export class QuestionnaireAnswerResetComponent implements OnInit {
  constructor() {}

  @Output() reset = new EventEmitter<void>();

  ngOnInit() {}

  public handleReset() {
    this.reset.emit();
  }
}
