import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  IGroup,
  RemovedElementType,
} from "../../interfaces/audit-open-answer";
import { MatchOperationsService } from "../../services/consolidacao/match-operations.service";

@Component({
  selector: "app-selectable-dropdown",
  templateUrl: "./selectable-dropdown.component.html",
  styleUrls: ["./selectable-dropdown.component.scss"],
})
export class SelectableDropdownComponent implements OnInit {
  @Input() group: IGroup;
  @Input() selected = false;
  @Input() isNewGroup = "";
  @Output() selectGroup: EventEmitter<IGroup> = new EventEmitter<IGroup>();

  showDropdown = false;
  editMode = false;
  newTitle = "";

  constructor(private matchOperationsService: MatchOperationsService) {}

  ngOnInit(): void {}

  toogleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  // retorna a quantidade de respostas de uma consolidação (incluindo as respostas das filhas)
  get citationLength() {
    return this.group.total;
  }

  // Retorna a quantidade respostas da consolidação filha
  groupChildLength(child: IGroup) {
    return `(${child.total})`;
  }

  selectGroups(group: IGroup) {
    this.selectGroup.emit(group);
  }

  toggleEditMode() {
    this.newTitle = this.group.title;
    this.editMode = !this.editMode;
  }

  /**
   * Dispara a intenção de mudança de titulo
   * a persistência do novo titulo ocorre no open-answers-component
   */
  handleChangeGroup() {
    this.matchOperationsService.updateGroupTitle(this.group.id, this.newTitle);
    this.toggleEditMode();
  }

  /**
   * Envia o id da citação e o id do grupo ao qual ela pertence
   * @param idGroup id do grupo alterado
   * @param idCitation id da citação que irá retornar para lista de avulsas
   */
  handleDeleteCitationFromGroup(idGroup, idCitation) {
    this.matchOperationsService.updateConsolidationElementRemoved({
      idElement: idCitation,
      idGroup: idGroup,
      type: RemovedElementType.CITATION
    });
  }

  /**
   * Envia o id do grupo que sera removido
   * @param idGroup id do grupo alterado
   * @param childIdGroup id do grupo filho
   *
   */
  handleRemoveChildGroup(idGroup, childIdGroup) {
    this.matchOperationsService.updateConsolidationElementRemoved({
      idElement: childIdGroup,
      idGroup: idGroup,
      type: RemovedElementType.CONSOLIDATION
    });
  }
}
