<main class="general-evaluation-container">
  <div>
    <h3>Avaliação geral</h3>
    <span [class]="'status ' + assessmentStyle(operador.avaliacao_geral)">
      {{ perfomanceFormat(operador.avaliacao_geral) }}
    </span>
  </div>
  <div>
    <h3>Coletas cumpridas</h3>
    <span>{{ operador.coletas?.cumpridas }}</span>
  </div>
  <div>
    <h3>Tempo médio</h3>
    <span>{{ averageTime }}</span>
  </div>
  <div>
    <h3>Auditadas</h3>
    <span>{{ operador.coletas.auditadas }}</span>
  </div>
  <div class="evaluation-wrapper">
    <section class="green-section">
      <h3>Aprovadas</h3>
      <span>{{ operador.coletas.aprovadas }}</span>
    </section>

    <section class="red-section">
      <h3>Reprovadas</h3>
      <span>{{ operador.coletas.reprovadas }}</span>
    </section>
  </div>
</main>
