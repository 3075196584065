import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GenericMeatballOptions } from '../../interfaces/meatball';
import { TableRow } from '../../interfaces/tRow';
import { MeatballClickEvent } from '../../interfaces/table';

@Component({
  selector: 'app-generic-table-meatball',
  templateUrl: './generic-meatball.component.html',
  styleUrls: ['./generic-meatball.component.scss']
})
export class GenericTableMeatballComponent implements OnInit, OnDestroy {
  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) { }

  public showMeatball: boolean; // Control the meatball is open or closed

  private listener;

  public internalMeatballOptions: GenericMeatballOptions[] = [];

  @Input() options: GenericMeatballOptions[] = [];
  @Input() disabledOptionsIds: number[] = [];
  @Input() rowRef: TableRow = null;

  // retorna o item selecionado pelo menu meatball
  @Output() handleItemClicked = new EventEmitter<MeatballClickEvent>();

  ngOnInit(): void {
    this.internalMeatballOptions = this.options.map(
      option => ({
        ...option,
        label: typeof option.label === 'function' ? option.label(this.rowRef) : option.label,
        icon: typeof option.icon === 'function' ? option.icon(this.rowRef) : option.icon,
      }),
    )
  }

  toggleMeatballVisibility() {
    if (!this.showMeatball) {
      this.showMeatball = !this.showMeatball;
      this.startListener();
    } else {
      this.clearListeners();
    }
  }

  startListener() {
    this.listener = this.handleDocumentClick.bind(this);
    
    document.addEventListener("click", this.listener);
  }

  clearListeners() {
    this.showMeatball = false;
    
    document.removeEventListener("click", this.listener);
    this.listener = null;

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.clearListeners();
  }

  handleSelectOption(option: GenericMeatballOptions) {
    this.handleItemClicked.emit({ option: { ...option }, row: this.rowRef });
  }

  handleDocumentClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.clearListeners();
    }
  }

}
