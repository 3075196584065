<div class="container-page">
    <div class="header-component">
      <div class="breadcrumb">
        <i class="icon-dashboard"></i>
        <h2>Áreas de interesse</h2>
        <span>/</span>
        <h4>Áreas de interesse cadastradas</h4>
      </div>
      <div class="action-header">
        <button class="sg-btn sg-secondary" [routerLink]="'/areas-interesse/areas-interesse-adicionar'">ADICIONAR NOVO</button>
      </div>
    </div>
    <div class="content-operadores-cadastrados">
      <div class="filtro">
        <div class="filtro-quantidade">
          <span>Exibindo</span>
          <select name="" id="">
            <option value="">5</option>
            <option value="">15</option>
            <option value="">25</option>
          </select>
          <span>items</span>
        </div>
        <div class="filtro-palavra-chave sg-input-group" >
          <label for="">Filtro por nome:</label>
          <input class="" type="text" placeholder="Pesquise pelo nome">
        </div>
      </div>
      <div class="table-container">
        <table class="sg-table">
          <thead>  
            <th> <input type="checkbox"> </th>
            <th> Id </th>
            <th> Nome </th>
            <th> Vínculo </th>
            <th> Sub área de interesse  </th>
            <th> Editar </th>
            <th> Excluir </th>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="checkbox">
              </td>
              <td>
                1
              </td>
              <td>
                Júlio V. Melo
              </td>
              <td>
                113
              </td>
              <td>
                front-end
              </td>
              <td>
                <i class="fa fa-pencil"></i>
              </td>
              <td>
                <i class="fa fa-trash-o"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="message-info">
          <span>Nenhum item selecionado</span>
        </div>
      </div>
    </div>
  </div>  