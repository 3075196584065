<div class="main-container">
  <!-- Filtros -->
  <div class="filters" [ngClass]="{ 'hide': filterContainerVisibility === 'hidden' }">
    <div *ngIf="enableSearch">
      <app-generic-table-input-search
        [placeholder]="searchInputConf.placeholder"
        (handleChange)="searchChanged($event)"
      ></app-generic-table-input-search>
    </div>
    <ng-container *ngIf="enableFilters">
      <div *ngFor="let filterConf of filters">
        <app-filter-select
          [type]="filterConf.type"
          [placeholder]="filterConf.placeholder || null"
          [icon]="filterConf.icon"
          [options]="filterConf.options"
          [composedOptions]="filterConf.composedOptions"
          [selectConfig]="filterConf.selectConfig"
          (changeFilter)="filterChanged($event, filterConf.type, filterConf.id)"
        >
        </app-filter-select>
      </div>
    </ng-container>
    <ng-container *ngIf="enableActionButton">
      <button class="action-btn" (click)="handleActionButtonClick()" type="button">
        <i *ngIf="actionButtonConfig.icon" [className]="actionButtonConfig.icon"></i>
        <h3>{{ actionButtonConfig.label }}</h3>
      </button>
    </ng-container>
  </div>

  <div
    class="table"
    [ngClass]="{
      'spacing-top': enableFilters || enableSearch,
      'spacing-bottom': enablePagination
    }"
  >
    <div class="table-wrapper">
      <!-- table header -->
      <div [ngStyle]="tableConfig.headerStyles" [style.backgroundColor]="tableConfig.headerBackgroundColor">
        <app-generic-table-header
          (onClick)="handleHeaderClick($event)"
          [widths]="tableConfig.colWidths"
          [height]="tableConfig.headerHeight"
          [data]="tHead"
      ></app-generic-table-header>
      </div>

      <div class="tbody">
        <div
          class="trow-wrapper"
          *ngFor="let row of toRenderRows; let i = index"
          [style.backgroundColor]="
            i % 2 === 0
              ? tableConfig.rowBackgroundColors.even
              : tableConfig.rowBackgroundColors.odd
          "
          [ngStyle]="tableConfig.rowStyles"
        >
          <app-generic-table-row
            (rowClick)="handleRowClick(i)"
            (cellClick)="handleCellClick($event)"
            (meatballClick)="handleMeatballClick($event)"
            [data]="row"
            [meatballConfig]="meatballConfig"
            [widths]="tableConfig.cellWidths || tableConfig.colWidths"
            [height]="tableConfig.rowHeight"
          >
          </app-generic-table-row>
        </div>
      </div>
    </div>
  </div>

  <app-pagination *ngIf="enablePagination"
    [placeholder]="paginationConfig.label"
    [totalOfEntries]="paginationConfig.totalEntries"
    [currentPage]="paginationConfig.currentPage"
    [totalPages]="paginationConfig.totalPages"
    [entriesPerPage]="entriesPerPage"
    (changePage)="handleChangePagination($event)"
  ></app-pagination>
</div>
