import { CanDeactivate, Router } from '@angular/router';
// tslint:disable-next-line: max-line-length
import { Injectable } from '@angular/core';
import { LocalidadeCadastroComponent } from 'app/modulos/localidade/cadastro/localidade.cadastro.component';
import { AbstractCanDeactivateGuard } from './abstractCanDeactivateGuard';

/**
 * Intercepta uma determinada rota e mostra um popup de confirmação antes da rota ser execultada
 */
@Injectable({
  providedIn: 'root',
})
export class LocalidadeConfirmDeactivateGuard extends AbstractCanDeactivateGuard implements CanDeactivate<LocalidadeCadastroComponent> {
  constructor(protected router: Router) {
    super(router);
  }

  async canDeactivate(component: LocalidadeCadastroComponent) {


    if (this.ignoreRedirectRules()) {
      return true;
    }

    const deactivate: boolean = await component.canDeactivate();
    return deactivate;
  }

}
