<div class="header-container">

  <section class="title-container">
    <h3 class="title">{{ title }}</h3>
    <p class="text">{{ text }}</p>
  </section>

  <section class="data-container">
    <svg width="209" height="131" viewBox="0 0 209 131" fill="none" xmlns="http://www.w3.org/2000/svg" class="background-svg">
      <g id="Mask group">
        <mask id="mask0_71_5531" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="-1" width="209" height="132">
          <rect id="Rectangle 4570" y="-0.167969" width="209" height="131" rx="4" fill="#2CA0F4"/>
        </mask>
        <g [attr.mask]="'url(#mask0_71_5531)'">
          <g id="Group 1000003685">
            <path id="Vector" d="M169.669 78.5H90.3359V131.389H169.669V78.5Z" [attr.fill]="svgColor"/>
            <path id="Vector_2" d="M90.3307 25.6094H50.6641V78.4983H90.3307V25.6094Z" [attr.fill]="svgColor"/>
            <path id="Vector_3" d="M209.331 25.6094H169.664V78.4983H209.331V25.6094Z" [attr.fill]="svgColor"/>
            <path id="Vector_4" d="M249 -80.168H11V25.6098H50.6667V-27.2791H209.333V25.6098H249V-80.168Z" [attr.fill]="svgColor"/>
          </g>
        </g>
      </g>
    </svg>
    <div *ngFor="let item of data" class="info-results">
      <span class="result-wrapper">
        <h3>{{ item.title }}</h3>
        <p>{{ item.value }}</p>
      </span>
    </div>
  </section>

</div>

