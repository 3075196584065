<div #statusSelect (click)="toggleStatus()" [ngClass]="statusIsOpen ? 'isOpen' : 'isClosed'" class="status-select">
    <div class="status-select-header">
        <div class="circle" [ngClass]="selectedStatus.ativo ? 'circle-active' : 'circle-inactive'">
        </div>
        <label>{{status}}</label>
        <i class="fa-regular" [ngClass]="statusIsOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </div>
    <div [ngClass]="statusIsOpen ? 'isVisble' : 'isHidden'" class="select-status-body">
        <div (click)="handleStatusClick(false)" *ngIf="selectedStatus.ativo" class="option">
            <div class="circle circle-inactive"></div>
            <label> Inativo </label>
        </div>
        <div (click)="handleStatusClick(true)" *ngIf="!selectedStatus.ativo" class="option">
            <div class="circle circle-active"></div>
            <label> Ativo </label>
        </div>
    </div>
</div>