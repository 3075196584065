/**
 * @see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 */

function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

function base64urlToBase64(str: string): string {
    // Já está no formato ideal para decode
    if (!/[_-]/.test(str)) return str;
    // Necessita de decodificação:
    // _ é trocado por /
    // - é trocado por +
    // que são caracteres conhecidos em uma string codificada em base64.
    return str.replace(/_/g, '/').replace(/-/g, '+')
}

function b64DecodeUnicode(str) {
    const base64 = base64urlToBase64(str);

    return decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export { b64DecodeUnicode, b64EncodeUnicode };
