import { Component, ElementRef, HostListener, OnInit, ViewChild, Input } from "@angular/core";
import { ICriterio, ISurveyCollectionSummary, SituacaoTooltipMap } from "app/modulos/auditoria-beta/interfaces";
import { mapCollectionCriteriaToColorObject } from "app/modulos/auditoria-beta/utils/parsers/collectionCriteriaTo";
import { TooltipColetasService } from "./tooltip-coletas.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-survey-map-tooltip",
  templateUrl: "./tooltip-coletas.component.html",
  styleUrls: ["./tooltip-coletas.component.scss"],
})
export class AuditSurveyMapTooltipComponent implements OnInit {
  constructor(private tooltipService: TooltipColetasService, private router: Router) {}

  @Input() surveyId: number = null;
  @ViewChild('containerRef') elementRef: ElementRef;

  public x: string = "0px";
  public y: string = "0px";
  public isVisible: boolean = false;
  public criterios: any = [];

  model: ISurveyCollectionSummary = null;

  ngOnInit(): void {
    this.tooltipService.state$.subscribe((next) => {
      if (next) {
        const { model, x, y, visible } = next;

        this.model = { ...model };
        this.x = x + "px";
        this.y = y + "px";
        this.isVisible = visible;
        this.criterios = mapCollectionCriteriaToColorObject(this.model.criterios);
      }
    });
  }

  parseIdToText(id: number) {
    let zeros = '';

    // 0 - 9
    if (id < 10) {
      zeros = '00';
    }
    // 10 - 99
    else if (id < 100) {
      zeros = '0';
    }

    return zeros + id;
  }

  renderCriterios(criterios: ICriterio[]) {
    return mapCollectionCriteriaToColorObject(criterios)
  }

  openCollection(collectionId: number) {
    this.router.navigate([`auditoria-beta/detalhes/${this.surveyId}/questionario/${collectionId}`])
  }

  handleCloseTip() {
    this.tooltipService.hide();
  }

  /**
   * Retorna a situação da coleta formatada
   */
  get collectionSituation() {
    return SituacaoTooltipMap;
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!target.classList.contains('criteria') && this.elementRef && !this.elementRef.nativeElement.contains(event.target)) {
      this.handleCloseTip();
    }
  }
}
