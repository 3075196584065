<div>
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="title-step-section">
    <div class="row">
      <div class="col-12 mt-5">
        <h3>2 de 2: Auditoria de entrevistas</h3>
      </div>
    </div>
  </div>
  <app-entrevista-listagem-tabela *ngIf="pesquisaSelecionada" #tabela [pesquisa]="pesquisaSelecionada"
    [entrevistaService]="entrevistaService" [nomeAtributosExibidos]="nomeAtributosTabela"
    [atualizarTabelaSubject]="atualizarTabelaSubject" [nomeColunas]="nomeColunasTabela" [exibirOpcaoDownload]="true"
    (eventEmitter)="manipularEventoTabela($event)" (onEventoTabelaEntrevista)="onEventoTabelaEntrevista($event)"
    [pagina]="pagina">
  </app-entrevista-listagem-tabela>
</div>
