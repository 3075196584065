import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ModalService } from "app/componentes/modal/modal.service";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { Subject, Subscription } from "rxjs";
import {
  EventoPerguntaRespostaSelecionada,
  PerguntaRespostaItem,
} from "../pergunta-resposta-multi-selector/pergunta-resposta-multi-selector.component";

@Component({
  selector: "app-resposta-edicao",
  templateUrl: "./resposta-edicao.component.html",
  styleUrls: ["./resposta-edicao.component.scss"],
})
export class RespostaEdicaoComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;

  /**
   * subject recebido de um componente pai. O objetivo deste subject é
   * notificar a este componente, as respostas selecionadas, de maneira
   * que saibamos quais respostas alterar.
   */
  @Input()
  public perguntaRespostasSelecionadasSubject: Subject<EventoPerguntaRespostaSelecionada>;
  public perguntaRespostasSelecionasSubscription: Subscription;

  /**
   * event emitter responsável por notificar um componente pai que a edição
   * foi realizada com sucesso.
   */
  @Output() respostaEditada: EventEmitter<EventoRespostaEditada> =
    new EventEmitter();

  /**
   * Identificador da pesquisa na qual a edição deverá ser feita
   */
  @Input() public idPesquisa: number;
  /**
   * Identificador da pergunta na qual a edição deverá ser feita
   */
  @Input() public idPergunta: number;

  /**
   * conjunto de respostas selecionadas, as que deverão ser editadas em massa.
   */
  perguntaRespostasSelecionadas: PerguntaRespostaItem[] = [];

  /**
   * Flag indicando se o componente está carregando algo ou não.
   */
  isLoading: boolean = false;

  /**
   * Conjunto de respostas de perguntas abertas
   */
  @Input() citacoes: PerguntaRespostaItem[];
  /**
   * Variável auxiliar para autocomplete
   */
  ultimaBusca: string = "";
  /**
   * Variável auxiliar para autocomplete
   */
  citacoesBusca: PerguntaRespostaItem[];

  /**
   * Hook de ciclo de vida que é disparado quando qualquer dado que escute o pai
   * é alterado pelo pai
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.citacoes) {
      this.citacoes = changes.citacoes.currentValue;
    }
  }

  constructor(public requestService: RequestService, private modalService: ModalService) {}

  ngOnInit() {
    this.inicializarForm();

    if (this.perguntaRespostasSelecionadasSubject) {
      this.perguntaRespostasSelecionasSubscription =
        this.perguntaRespostasSelecionadasSubject.subscribe(
          (evento: EventoPerguntaRespostaSelecionada) => {
            this.onPerguntaRespostasSelecionadas(evento);
          }
        );
    }
  }

  /**
   * inicializa o formulario
   */
  inicializarForm() {
    this.formGroup = new UntypedFormGroup({
      citacao: new UntypedFormControl("", [Validators.required, Validators.maxLength(250)]),
    });
  }

  ngOnDestroy() {
    if (this.perguntaRespostasSelecionasSubscription) {
      this.perguntaRespostasSelecionasSubscription.unsubscribe();
    }
  }

  /**
   * Callback executado sempre que um novo evento é capturado pelo perguntaRespostasSelecionadasSubject,
   * uma nova perguntaResposta é selecionada.
   *
   * @param evento evento que encapsula as respostas selecionadas.
   */
  onPerguntaRespostasSelecionadas(evento: EventoPerguntaRespostaSelecionada) {
    //TO-DO
    this.perguntaRespostasSelecionadas = evento.perguntaRespostas;
  }

  /**
   * Realiza uma chamada à API do Tensai para modificar as citações para a nova citação inserida
   * no formulário.
   * @param corpoForm corpo do fomrulário contendo a citação inserida.
   */
  modificar(corpoForm: { citacao: string }, form: any) {
    const novaCitacao: string = corpoForm.citacao;

    const citacoes = this.perguntaRespostasSelecionadas
      .map((r) => r.citacao)
      .reduce((prev, next) => prev.concat(", ").concat(next));

    this.exibirAlertaTemCerteza(novaCitacao, citacoes)
      .then(() => {
        // tslint:disable-next-line: max-line-length
        const uri = `${apiLocation}/pesquisas/${this.idPesquisa}/perguntas/${this.idPergunta}/citacoes`;

        const citacoesAntigas: string[] =
          this.perguntaRespostasSelecionadas.map((r) => r.citacao);
        const payload = {
          citacoesAntigas,
          novaCitacao,
        };

        this.isLoading = true;

        this.requestService.patch(uri, payload).subscribe((response: any) => {
          const updateCount: number = response.body;

          this.isLoading = false;

          this.modalService.showModal({
            title: 'Respostas atualizadas.',
            messageModal: `${updateCount} respostas foram atualizadas com sucesso!`,
            btnTitlePositive: "Entendi",
            icon: "fa-light fa-circle-info",
            isOnlyConfirmation: true,
            positiveCallback: () => {
              this.resetComponent();
              form.resetForm();
              this.ultimaBusca = '';
              this.notificarParent(novaCitacao);
            },
          });
        });
      })
      .catch(() => {});
  }

  resetComponent() {
    this.perguntaRespostasSelecionadas = [];
    this.formGroup.reset();
    this.formGroup.updateValueAndValidity();
    this.formGroup.controls.citacao.updateValueAndValidity();
  }

  /**
   * Notifica os componentes pais que uma nova edição em massa de respostas abertas
   * foi feita com sucesso.
   * @param citacao
   */
  notificarParent(citacao: string) {
    this.respostaEditada.emit({
      novaResposta: citacao,
    });
  }

  exibirAlertaTemCerteza(novaCitacao: string, citacoesAntigas: string) {
    const temCerteza = `Tem certeza que deseja atualizar todas as respostas '${citacoesAntigas}' para '${novaCitacao}' ?`;

    return new Promise((accept, reject) => {
      this.modalService.showModal({
        title: 'Confirmação',
        messageModal: temCerteza,
        btnTitlePositive: "Sim, tenho",
        btnTitleNegative: 'Cancelar',
        icon: "fa-light fa-circle-info",
        positiveCallback: () => accept(temCerteza),
        negativeCallback: () => reject()
      });
    });
  }

  // lista todas as citacoes filtradas
  carregarCitacoes(input: any) {
    const value = input.target.value;
    this.ultimaBusca = value ? value : "";
    if (this.ultimaBusca !== "") {
      this.citacoesBusca = this.citacoes.filter((c) => {
        return c.citacao.toUpperCase().includes(this.ultimaBusca.toUpperCase());
      });
    } else {
      this.citacoesBusca = [];
    }
  }

  updateCounter(input: any) {
    const value = input.option.value;
    this.ultimaBusca = value ? value : "";
  }

}
export class EventoRespostaEditada {
  novaResposta: string;
}
