import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ListReadService } from "../list-read.service";

@Component({
  selector: "app-localidade-table-header",
  templateUrl: "./localidade-table-header.component.html",
  styleUrls: ["./localidade-table-header.component.scss"],
})
export class LocalidadeTableHeaderComponent implements OnInit {
  @Input() hasSomeLocalidadeChecked: boolean;

  constructor(private listReadService: ListReadService, private route: ActivatedRoute) {}

  initialSearchValue: string = '';

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('search'))
      this.initialSearchValue = this.route.snapshot.paramMap.get('search')
  }

  // Função para filtrar localidades pela key
  handleSearchLocationByKey(key: Event): void {
    setTimeout(() => {
      const value = (key.target as HTMLInputElement).value;
      const result = value.length > 0 ? true : false;
      this.listReadService.setCanLookForChild(result);
      this.listReadService.handleChangeLocalidades(value);
    }, 1000);
  }

  // Função para realizar deleção em massa
  handleMassiveDelete(): void {
    this.listReadService.deleteMassiveLocalidades();
  }
}
