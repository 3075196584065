import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificatorService } from "app/notificador/notificator.service";
import { Funcionario } from "../funcionario";
import { FuncionarioService } from "../servico/funcionario.service";
// tslint:disable-next-line:max-line-length
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { stringFormatter } from "app/util/misc/stringFormatter";
import validadorCamposIguais from "app/util/validador/validadorCamposIguais";
import validadorCpf from "app/util/validador/validadorCpf";
import { CadastroBasico } from "../../cadastro/cadastroBasico";
import { Grupo } from "../../grupo/grupo";
import { GrupoService } from "../../grupo/servico/grupo.service";
import { Permissao } from "../../permissao/permissao";
import { PermissaoService } from "../../permissao/permissao.service";
//import { _MatTabHeaderMixinBase } from '@angular/material/tabs/typings/tab-header';
import { ModalService } from "app/componentes/modal/modal.service";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { minCheckBoxSelecionado } from "app/util/misc/checkMinArraySelecionado";
import { TipoUsuarioSistema } from "app/util/usuario/tipoUsuario";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-funcionario-cadastro",
  templateUrl: "./funcionario.cadastro.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./funcionario.cadastro.component.scss"],
})
export class FuncionarioCadastroComponent
  extends CadastroBasico<Funcionario>
  implements AfterViewChecked
{
  /**
   * caso seja um cadastro o cliente vazio eh criado, caso contrario,
   * os dados previos serao carregados. Esta instancia de cliente serve apenas
   * para setar o patchValue dos formControls
   */
  funcionario: Funcionario;
  permissoes: Permissao[] = [];
  grupos: Grupo[] = [];
  url: string | ArrayBuffer;
  editarPerfil: boolean;
  // Atributo criado para copiar o e-mail do funcionário no momento da inicialização.
  employeeEmail: string;

  constructor(
    protected securityService: SecurityService,
    private formBuilder: UntypedFormBuilder,
    protected funcionarioService: FuncionarioService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected changeDetector: ChangeDetectorRef,
    private permissaoService: PermissaoService,
    private grupoService: GrupoService,
    protected toastr: ToastrService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "funcionario",
      funcionarioService,
      activatedRoute,
      router,
      toastr,
      notificatorService,
      modalService
    );
  }
  ngOnInit() {
    this.funcionario = new Funcionario();
    // this.formBuilder = new FormBuilder
    this.initFormGroup();
    super.ngOnInit();
    if (this.router.url.includes("/funcionario/perfil")) {
      this.isEdicao = true;
      this.editarPerfil = true;
      this.funcionarioService.getPerfilUsuario().subscribe((result) => {
        if (result) {
          this.entidadeEdicaoCallback(result);
        }
      });
    } else {
      this.carregarGrupos();
      this.carregarPermissoes();
    }
    this.tituloFormulario = `adicionar funcionários`;

    if (this.isEdicao) {
      this.tituloFormulario = `alterar funcionários`;
    }
  }
  ngAfterViewChecked() {
    this.atualizarValidadorPermissoes();
    //Detecta novas alterações mesmo depois da checagem estar concluida
    this.changeDetector.detectChanges();
  }

  createPermissaoFormGroup() {
    // this.formControls['permissao'] = new FormControl('', [Validators.requiredTrue]);
    var control = this.permissoes.map(
      (control) => new UntypedFormControl(control.selecionado)
    );

    return this.formBuilder.array(
      control,
      minCheckBoxSelecionado.minSelectedCheckboxes(1)
    );
  }

  //  Preview do upload de imagem
  readURL(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  carregarGrupos() {
    this.grupoService.listar().subscribe({
      next: (grupos) => {
        if (this.isEdicao && this.permissoes.length > 0) {
          this.grupos.forEach((grupo) => {
            grupos.forEach((grupoServidor) => {
              if (grupoServidor.id === grupo.id) {
                grupoServidor.selecionado = grupo.selecionado;
              }
            });
          });
        }

        this.grupos.length = 0;
        this.grupos.push(...grupos);

        // tslint:disable-next-line:align
      },
      error: (error) => {
        const operacao = this.isEdicao ? "atualizar" : "cadastrar";
        const operacaoCapitalizada =
          stringFormatter.capitalizeFirstLetter(operacao);

        this.modalService.showModal({
          title: `${operacaoCapitalizada} funcionario`,
          messageModal:
            "Não foi possível carregar os grupos do funcionário, tente novamente",
          btnTitlePositive: "Entendi",
          icon: "fa-light fa-circle-info",
          isOnlyConfirmation: true,
          positiveCallback: () => this.router.navigate([""]),
        });
      },
    });
  }

  carregarPermissoes() {
    this.permissaoService.listar().subscribe({
      next: (permissoes: Permissao[]) => {
        if (this.isEdicao && this.permissoes.length > 0) {
          this.permissoes.forEach((permissao) => {
            permissoes.forEach((permissaoServidor) => {
              if (permissaoServidor.nomeReal === permissao.nomeReal) {
                permissaoServidor.selecionado = permissao.selecionado;
              }
            });
          });
        }

        this.permissoes.length = 0;
        this.permissoes.push(...permissoes);

        this.initFormGroup();
        // tslint:disable-next-line:align

        // tslint:disable-next-line:align
      },
      error: (error) => {
        const operacao = this.isEdicao ? "atualizar" : "cadastrar";
        const operacaoCapitalizada =
          stringFormatter.capitalizeFirstLetter(operacao);

        this.modalService.showModal({
          title: `${operacaoCapitalizada} funcionario`,
          messageModal:
            "Não foi possível carregar as permissões do funcionário, tente novamente",
          btnTitlePositive: "Entendi",
          icon: "fa-light fa-circle-info",
          isOnlyConfirmation: true,
          positiveCallback: () => this.router.navigate([""]),
        });
      },
    });
  }

  initFormGroup() {
    // tslint:disable-next-line:max-line-length
    this.formControls["cargo"] = new UntypedFormControl(
      this.funcionario.cargo,
      [Validators.required, Validators.maxLength(50)]
    );
    this.formControls["permissoes"] = new UntypedFormControl();

    this.formGroup = new UntypedFormGroup({
      matricula: new UntypedFormControl({
        value: this.funcionario.matricula,
        disabled: true,
      }),
      // tslint:disable-next-line:max-line-length
      cargo: this.formControls.cargo,
      permissoes: this.formControls.permissoes,
      pessoa: this.createPessoaFormGroup(),
      usuario: this.createUsuarioFormGroup(),
      contato: this.createContatoFormGroup(),
      fotoEnviada: this.createFotoEnviadaFormGroup(),
    });
    this.employeeEmail = this.formGroup.value.usuario.login;
  }
  private createUsuarioFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["login"] = new UntypedFormControl(
      this.funcionario.usuario.login,
      [Validators.required, Validators.email, Validators.maxLength(50)]
    );
    this.formControls["mudarPassword"] = new UntypedFormControl();
    // tslint:disable-next-line:max-line-length
    this.formControls["ativo"] = new UntypedFormControl(
      this.funcionario.usuario.ativo,
      [Validators.required]
    );

    this.formControls["senha"] = new UntypedFormControl();
    this.formControls["senhaConfirmar"] = new UntypedFormControl();

    // tslint:disable-next-line:max-line-length
    // this.formControls['permissao'] = new FormControl(this.funcionario.usuario.permissoes, [Validators.requiredTrue]);

    // gerando o validador de campos iguais baseado no formControl de senha
    const senhaCamposIguaisValidator = validadorCamposIguais(
      this.formControls.senha
    );
    // tslint:disable-next-line:max-line-length
    const senhaConfirmarCamposIguaisValidator = validadorCamposIguais(
      this.formControls.senhaConfirmar
    );

    if (!this.isEdicao) {
      // tslint:disable-next-line:max-line-length
      this.formControls.senha.setValidators([
        Validators.required,
        Validators.maxLength(30),
        Validators.minLength(8),
        senhaConfirmarCamposIguaisValidator,
      ]);
      this.formControls.senhaConfirmar.setValidators(
        senhaCamposIguaisValidator
      );
    }
    const usuarioFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.funcionario.usuario.id),
      login: this.formControls.login,
      senha: this.formControls.senha,
      senhaConfirmar: this.formControls.senhaConfirmar,
      mudarPassword: this.formControls.mudarPassword,
      ativo: this.formControls.ativo,
      // permissoes: this.createPermissaoFormGroup(),
    });
    return usuarioFormGroup;
  }

  private createPessoaFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["nome"] = new UntypedFormControl(
      this.funcionario.pessoa.nome,
      [Validators.required, Validators.maxLength(50)]
    );
    // tslint:disable-next-line:max-line-length
    this.formControls["cpf"] = new UntypedFormControl(
      this.funcionario.pessoa.cpf,
      [Validators.required, validadorCpf]
    );
    // // tslint:disable-next-line:max-line-length
    // this.formControls["rg"] = new UntypedFormControl(
    //   this.funcionario.pessoa.rg,
    //   [Validators.maxLength(10)]
    // );

    const pessoaFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.funcionario.pessoa.id),
      nome: this.formControls.nome,
      cpf: this.formControls.cpf,
      // rg: this.formControls.rg,
      // endereco: this.createEnderecoFormGroup(),
    });

    return pessoaFormGroup;
  }

  // private createEnderecoFormGroup(): UntypedFormGroup {
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["cep"] = new UntypedFormControl(
  //     this.funcionario.pessoa.endereco.cep,
  //     [Validators.minLength(9), Validators.maxLength(9)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["logradouro"] = new UntypedFormControl(
  //     this.funcionario.pessoa.endereco.logradouro,
  //     [Validators.maxLength(60)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["numero"] = new UntypedFormControl(
  //     this.funcionario.pessoa.endereco.numero,
  //     [Validators.max(99999), Validators.min(0)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["bairro"] = new UntypedFormControl(
  //     this.funcionario.pessoa.endereco.bairro,
  //     [Validators.maxLength(60)]
  //   );
  //   // tslint:disable-next-line:max-line-length
  //   this.formControls["cidadeEstado"] = new UntypedFormControl(
  //     this.funcionario.pessoa.endereco.cidadeEstado,
  //     [Validators.maxLength(60)]
  //   );

  //   const enderecoFormGroup = new UntypedFormGroup({
  //     cep: this.formControls.cep,
  //     logradouro: this.formControls.logradouro,
  //     numero: this.formControls.numero,
  //     bairro: this.formControls.bairro,
  //     cidadeEstado: this.formControls.cidadeEstado,
  //   });

  //   return enderecoFormGroup;
  // }

  private createFotoEnviadaFormGroup(): UntypedFormGroup {
    const fotoEnviadaFormGroup = new UntypedFormGroup({
      arquivo: new UntypedFormControl(),
    });

    return fotoEnviadaFormGroup;
  }

  private createContatoFormGroup(): UntypedFormGroup {
    // tslint:disable-next-line:max-line-length
    this.formControls["email"] = new UntypedFormControl(
      this.funcionario.usuario.login,
      [Validators.required, Validators.email]
    );
    // // tslint:disable-next-line:max-line-length
    // this.formControls["telefone"] = new UntypedFormControl(
    //   this.funcionario.contato.telefone,
    //   [Validators.maxLength(14), Validators.minLength(14)]
    // );
    // // tslint:disable-next-line:max-line-length
    // this.formControls["celular"] = new UntypedFormControl(
    //   this.funcionario.contato.celular,
    //   [Validators.maxLength(15), Validators.minLength(15)]
    // );

    const contatoFormGroup = new UntypedFormGroup({
      email: this.formControls.email,
      // telefone: this.formControls.telefone,
      // celular: this.formControls.celular,
    });

    return contatoFormGroup;
  }

  //  Função para pegar o changeHandler do input de email pessoal e fazer com que
  //  o email de acesso tenha o mesmo valor e seja enviado no payload da API
  //  uma vez que não se faz necessário ter a duplicidade do campo email no formulário
  //  de cadastro de usuario (em termos de layout e UX).
  onEmailChange(eventValue: string) {
    this.formGroup.get("usuario").get("login").setValue(eventValue);
  }

  entidadeEdicaoCallback(entidade: Funcionario) {
    this.funcionario = entidade;
    if (this.funcionario.foto) {
      this.newFotoUploaderComponent.loadImageByUrl(this.funcionario.foto);
    }

    // this.funcionario = entidade;
    // tratando as permissoes previas
    if (this.permissoes.length > 0) {
      this.funcionario.usuario.permissoes.forEach((permissaoFuncionario) => {
        this.permissoes.forEach((permissao) => {
          if (permissao.nomeReal === permissaoFuncionario.nomeReal) {
            permissao.selecionado = true;
          }
        });
      });
    } else {
      this.funcionario.usuario.permissoes.forEach(
        (permissao) => (permissao.selecionado = true)
      );
      this.permissoes.push(...entidade.usuario.permissoes);
    }

    // tratando os grupos previos
    if (this.grupos.length > 0) {
      this.funcionario.usuario.grupos.forEach((grupoFuncionario) => {
        this.grupos.forEach((grupo) => {
          if (grupo.id === grupoFuncionario.id) {
            grupo.selecionado = true;
          }
        });
      });
    } else {
      this.funcionario.usuario.grupos.forEach(
        (grupo) => (grupo.selecionado = true)
      );
      this.grupos.push(...entidade.usuario.grupos);
    }

    this.initFormGroup();
  }

  prepararEntidadeParaPersistencia(entidade: Funcionario): Funcionario {
    // se o funcionario nao possuir fotos novas, removemos o atributo FotoEnviada
    if (
      this.newFotoUploaderComponent.defaultImage ===
      this.newFotoUploaderComponent.url
    ) {
      entidade.fotoEnviada = null;
    } else {
      entidade.fotoEnviada.arquivo = this.newFotoUploaderComponent.url;
    }

    const entidadeToSave = { ...entidade };

    // var valoresSelecionadosCheckbox = [];

    // valoresSelecionadosCheckbox = this.formGroup.value.usuario.permissoes
    //   .map((valor, indice) => valor ? this.permissoes[indice] : null)
    //   .filter(valor => valor !== null);

    const permissoesSelecionadas = this.permissoes.filter(
      (permissao) => permissao.selecionado
    );

    // // inserindo as permissões selecionadas
    // const permissoesSelecionada = valoresSelecionadosCheckbox;
    // this.permissoes.filter(permissao => permissao.selecionado);
    entidadeToSave.usuario.permissoes = permissoesSelecionadas;

    // inserindo grupos selecionados
    const gruposSelecionadas = this.grupos.filter((grupo) => grupo.selecionado);
    entidadeToSave.usuario.grupos = gruposSelecionadas;
    return entidadeToSave;
  }

  alterarSelecaoGrupo(grupoValue: number) {
    const gruposCorrespondentes = this.grupos.filter((grupo) => {
      return grupo.id === grupoValue;
    });

    if (gruposCorrespondentes && gruposCorrespondentes.length > 0) {
      gruposCorrespondentes[0].selecionado =
        !gruposCorrespondentes[0].selecionado;
      // console.log(gruposCorrespondentes[0].selecionado);
    }
  }

  onGrupoSelected(event) {
    const grupoIdString = event.target.value;
    const grupoId = Number(grupoIdString);

    if (!grupoId || isNaN(grupoId)) {
      return;
    }

    this.alterarSelecaoGrupo(grupoId);
    const grupoSelect = event.target;
    grupoSelect.value = null;
  }

  onPermissaoSelected(event) {
    const permissaoNomeReal = event.target.value;

    this.alterarSelecaoPermissao(permissaoNomeReal);
    const permissaoSelect = event.target;
    permissaoSelect.value = null;

    this.atualizarValidadorPermissoes();
  }

  alterarSelecaoPermissao(permissaoValue: string) {
    const permissoesCorrespondentes = this.permissoes.filter((permissao) => {
      return permissao.nomeReal === permissaoValue;
    });

    if (permissoesCorrespondentes && permissoesCorrespondentes.length > 0) {
      permissoesCorrespondentes[0].selecionado =
        !permissoesCorrespondentes[0].selecionado;
      // console.log(permissoesCorrespondentes[0].selecionado);
    }
    this.atualizarValidadorPermissoes();
  }

  atualizarValidadorPermissoes() {
    const permissoesSelecionadas = this.getPermissoesSelecionadas();
    const gruposSelecionados = this.getGruposSelecionadas();

    if (permissoesSelecionadas.length > 0 || gruposSelecionados.length > 0) {
      this.formControls.permissoes.reset();
    } else {
      this.formControls.permissoes.setErrors({ empty: true });
    }
  }
  getPermissoesSelecionadas(): Permissao[] {
    return this.permissoes.filter((permissao) => permissao.selecionado);
  }

  getGruposSelecionadas(): Grupo[] {
    return this.grupos.filter((grupo) => grupo.selecionado);
  }

  /**
   * @override
   */
  getRedirectPath(): string[] {
    const listagemAuthorities = [
      Authority.ATUALIZAR_FUNCIONARIO,
      Authority.LISTAR_FUNCIONARIO,
    ];
    if (this.securityService.userHasAuthorities(listagemAuthorities)) {
      return ["funcionario"];
    }
    return [""];
  }

  /**
   * Retorna o conjunto de autoridades (Authorities) do usuário autenticado.
   */
  allowChangePermission() {
    const authenticatedUser = this.securityService.getAuthenticatedUser();

    const usuarioFuncionarioTentandoEditarOProprioPerfil =
      authenticatedUser.tipoUsuario === TipoUsuarioSistema.ROLE_FUNCIONARIO &&
      authenticatedUser.login === this.employeeEmail;

    return (
      (authenticatedUser.authorities.includes(
        Authority.ATUALIZAR_FUNCIONARIO
      ) ||
        authenticatedUser.authorities.includes(
          Authority.CADASTRAR_FUNCIONARIO
        )) &&
      !usuarioFuncionarioTentandoEditarOProprioPerfil
    );
  }
  /**
   * @override
   */
  salvar(value) {
    if (this.editarPerfil) {
      const executarOperacaoCallback = () => {
        this.isLoading = true;

        this.funcionarioService
          .updatePerfilFuncionario({
            ...value,
            id: this.funcionario.id,
            fotoEnviada: value.fotoEnviada.arquivo,
            usuario: {
              ...this.funcionario.usuario,
              permissoes: this.permissoes,
              grupos: this.grupos,
            },
          })
          .subscribe({
            next: () => {
              this.redirecionar();
              this.showNotificationSuccess();
            },
            error: (error) => {
              this.onSalvarError(this.isEdicao, error);
            },
          });
      };

      this.modalService.showModal({
        title: "Atualizar funcionario",
        messageModal: `Deseja atualizar o ${this.nomeEntidade}?`,
        btnTitlePositive: "Atualizar",
        btnTitleNegative: "Cancelar",
        icon: "fa-light fa-circle-info",
        positiveCallback: () => executarOperacaoCallback,
      });
    } else {
      super.salvar(value);
    }
  }
}
