<div class="modalRejeicao">
  <form class="sg-input-group" [formGroup]="formGroup" #form="ngForm"
    (ngSubmit)="form.valid && salvarRejeicao(form.value)">

    <div class="campo">
      <div class="title">
        <i><span>!</span></i>
        <p>Motivo da rejeição</p>
      </div>
      <textarea formControlName='causa' placeholder='Motivo da rejeição'></textarea>
      <span class="error-message" [hidden]="!(form.submitted && formGroup.controls.causa.errors)">
        Este campo é obrigatório
      </span>
    </div>

    <div class="rejeicaoActions">
      <button class="cancel-btn" type="button" (click)="cancelar()">Cancelar</button>
      <button class="confirm-btn" type="submit">Confirmar Rejeição</button>
    </div>
  </form>
</div>