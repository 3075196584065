<!-- V2 e V3 do FIGMA -->
<div class="section-list-container" *ngIf="isVisibleCard()">
  <span class="question-amount"> PERGUNTAS: {{ data.perguntas?.length }} </span>
  <div class="section-list__card-header">
    <div class="dropdown" (click)="setDropDownActive()">
      <i class="fas fa-chevron-down" *ngIf="getDropDownActive()"> </i>
      <i class="fas fa-chevron-right" *ngIf="!getDropDownActive()"> </i>
    </div>

    <div class="content">
      <div class="content-left">
        <div class="vertical-bar"></div>

        <div class="section-list__card-title">
          <div class="section-list__card-subtitle">
            <p>SEÇÃO {{ data.ordem }}:</p>
            <h4>{{ data.nome }}</h4>
          </div>
          <p>{{ data.descricao }}</p>
        </div>
      </div>

      <span *ngIf="handleIsSomeQuestionHasLogic(data.perguntas)"> Lógica </span>
    </div>
  </div>

  <div class="section-list__card-body" *ngIf="getDropDownActive()">
    <div class="section-list__card-question-list"
         *ngFor="let question of data.perguntas; index as ind"
         [id]="'pergunta-' + question.ordem" >
      <div class="section-question-item"
          [ngClass]=" selectedQuestion?.titulo === question.titulo ? 'selected-question' : isQuestionClickable(question.id)"
          (click)="handleSelectedQuestion(question, data, data.id)">
        <div (click)="perguntaIsSimple(question.tipo) && handleExpandAlternativesButtonClick(question)"
          [class]="question.tipo !== 'HORARIO' &&
                   question.tipo !== 'DATA' &&
                   question.tipo !== 'VIDEO' &&
                   question.tipo !== 'FOTO' ? 'section-question-item__dropdown' : 'section-question-item__dropdown_disabled'"
          *ngIf="!isSelectable">

        <div class="pergunta-header">
          <div class="dropdown-wrapper" [ngClass]="perguntaIsSimple(question.tipo) ? 'expande' : 'nao-expande'">
            <i *ngIf="getclassDropAlternativa(question)" class="fas fa-chevron-down"></i>
            <i *ngIf="!getclassDropAlternativa(question)" class="fas fa-chevron-right"></i>
          </div>
        </div>
        </div>

        <div class="section-question-item__info">
          <div class="section-question-item__description">
            <h4>Pergunta {{ question.ordem }}</h4>
            <div class="section-question-item__flags" *ngIf="!isSelectable">
              <div class="span-wrapper" *ngIf="question.obrigatorio">
                <span title="Obrigatório">Obrigatório</span>
              </div>
              <div class="span-wrapper">
                <span [title]="formatTipo(question.tipo)">{{
                  formatTipo(question.tipo)
                }}</span>
              </div>
              <div class="span-wrapper" *ngIf="perguntaTemMarcacao(question)">
                <span title="Lógica">Lógica</span>
              </div>
            </div>
          </div>
          <p>{{ question.titulo }}</p>
        </div>

        <div
          [class]="
            question.tipo !== 'GRADE_UNICA' &&
            question.tipo !== 'GRADE_MULTIPLA' &&
            question.tipo !== 'MULTIPLA' &&
            question.tipo !== 'HORARIO' &&
            question.tipo !== 'DATA' &&
            question.tipo !== 'VIDEO' &&
            question.tipo !== 'FOTO'
              ? 'section-question-item__action'
              : 'section-question-item__disabled'
          "
          (click)="
            question.tipo !== 'GRADE_UNICA' &&
            question.tipo !== 'GRADE_MULTIPLA' &&
            question.tipo !== 'MULTIPLA' &&
            question.tipo !== 'HORARIO' &&
            question.tipo !== 'DATA' &&
            question.tipo !== 'VIDEO' &&
            question.tipo !== 'FOTO'
              ? getQuestionBySectionId(data.id, question.id)
              : null
          "
          *ngIf="!isSelectable"
        >
          <i
            class="fa-regular fa-gear-complex fa-lg"
            alt="Configurar Lógica"
          ></i>
        </div>

      </div>


      <div *ngIf="getalternativasExpandidas(question)">
        <div class="pergunta-alternativas-content">
          <div class="lista-de-alternativas" *ngIf="perguntaTemAlternativas(question.tipo)">
            <div class="alternativa" *ngFor="let alternativa of question.alternativas let indexOfSection = index">
              <h4>{{ indexOfSection + 1 }}. {{ alternativa.descricao }}</h4>
            </div>
          </div>
          <div class="lista-de-alternativas"*ngIf="perguntaIsEscalaNumerica(question.tipo)">
            <div class="alternativa">
              <h4>
                Escala de {{ question.faixaPergunta.intervaloInicio }} até {{ question.faixaPergunta.intervaloFim }}
              </h4>
            </div>
          </div>

          <div class="lista-de-grade" *ngIf="isItGrade(question.tipo)">
            <table>
              <thead>
                <tr class="thead">
                  <th></th>
                  <th *ngFor="let headerColumn of question.alternativas; let headerColumnIndex = index" [title]="headerColumn.descricao">{{ headerColumn.descricao }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let line of question.titulosGrade" class="tbody">
                  <td [title]="line.descricao">
                    {{ line.descricao }}
                  </td>
                  <td *ngFor="let checkoboxOrRadio of question.alternativas">
                    <div [ngClass]="question.tipo === 'GRADE_UNICA'? 'radio-square' : 'checkbox'"></div>
                  </td>
                  <td></td>
                </tr>
                <tr class="tbody footer">
                  <td *ngFor="let checkoboxOrRadioFooter of question.alternativas"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
