import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formsLocation } from "app/infraestrutura/apiLocation";
import { PesquisaService } from "app/modulos/pesquisa-beta/listagem/servico/pesquisas";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import {
  SelectDataItem,
  SelectedItemEvent
} from "../../../../../componentes/filterable-select/filterable-select-component";
import { LocationListOutput } from "../../../../../componentes/locations-list/locationslist.component";
import { LocationsData } from "../../../../../componentes/sublocal/sublocal-component";
import {
  ICanNotSaveButton,
  PesquisaRequest
} from "../../pesquisas-cadastro.model";
import {
  Localidade,
  PesquisaLocalidadeService
} from "./servico/pesquisa-localidades.service";

export interface PageLocationOutput {
  selectedItemEvent: SelectedItemEvent;
  locationOtp: LocationListOutput;
}

@Component({
  selector: "app-pesquisas-localidades",
  templateUrl: "./pesquisas-localidades.component.html",
  styleUrls: ["./pesquisas-localidades.component.scss"],
})
export class PesquisasLocalidadesComponent implements OnInit {
  @Input() input: PageLocationOutput;
  @Input() survey: PesquisaRequest;
  @Input() loadingSave: boolean;
  @Input() locationsListOutput: LocationListOutput;
  // saída dos eventos
  @Output() onChange: EventEmitter<void> = new EventEmitter();
  @Output() onDone: EventEmitter<void> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSave: EventEmitter<PageLocationOutput> = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter();

  // função que emite para o pai os dados a serem salvos
  @Output() onNewSave: EventEmitter<any> = new EventEmitter();

  filterableSelectData: SelectDataItem[] = [];
  locations: LocationsData;
  locationsData: Localidade[];

  requiredFieldsAreReady: boolean = false;

  tokenRef: string = "";

  selectedLocationItem: SelectDataItem;

  constructor(
    protected localidadeService: PesquisaLocalidadeService,
    public pesquisaService: PesquisaService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  initialData() {
    this.localidadeService.listarTodos().subscribe({
      next: (item) => {
        this.locationsData = item;
        this.filterableSelectData = item.map((v) => {
          return {
            label: v.nome,
            value: v.id,
            hidden: false,
          };
        });
      },
      error: (err) =>
        this.errorHandlerService.handleError(err, "Não foi possível carregar os dados")
    });
  }

  ngOnInit() {
    this.initialData();
    this.tokenRef =
      !!this.survey &&
      this.survey.configuracaoPesquisa &&
      this.survey.configuracaoPesquisa.tokenVisualizacao;
    this.selectedLocationItem =
      !!this.input && !!this.input.selectedItemEvent
        ? this.input.selectedItemEvent.item
        : null;
    this.locations = {
      type: "localidade",
      value: this.survey.configuracaoPesquisa.amostrasPresenciais,
      locations:
        this.input && this.input.locationOtp
          ? this.input.locationOtp.alteredLocations.locations
          : [],
    };

    // propaga se pode habilitar o botão de avançar ou não
    this.onCanNotSaveButton.emit({
      metadata: { canNotSaveButton: !this.requiredFieldsAreReady },
    });
  }

  onCanNotSaveButtonNew($event: ICanNotSaveButton): void {
    this.onCanNotSaveButton.emit($event);
  }
  /**
   * Função que verifica se os campos obrigatórios estão preenchidos
   */
  verifyRequiredFields(): boolean {
    // variável que guarda o somatório dos valores distribuídos nas localidades
    let count = 0;
    if (!this.locationsListOutput) return false;

    let totalPercent = 0;

    const locationsValues =
      this.locationsListOutput.alteredLocations.locations.map(
        (location) => location.value
      );
    // Em alguns momentos o array é devolvido vazio, e o reduce não pode ser aplicado num array vazio.
    if (locationsValues.length) {
      totalPercent = locationsValues.reduce(
        (acc, cur) => Number(acc) + Number(cur)
      );
    }

    const totalInteger =
      (Number(totalPercent.toPrecision(15)) * this.locations.value) / 100;

    /**
     * é feito um mapeamento em todas as localidades e subLocalidades
     * onde a soma geral do value em localidades e subLocalidades
     * deve ser igual a 100 que equivale a 100%
     */
    let haveInvalidField = false;
    this.locationsListOutput.alteredLocations.locations.map(
      ({ sublocations, value, toggleChecked }) => {
        if (toggleChecked && !value) {
          haveInvalidField = true;
        }
        if (
          sublocations.length > 0 &&
          value &&
          totalInteger === this.locationsListOutput.alteredLocations.value
        ) {
          // variável que guarda o somatório das amostras distribuídas nas subLocalidades
          const subLocationsCount = sublocations.reduce((acc, cur) => {
            return Number(acc) + Number(cur.value);
          }, 0);

          if (Number(subLocationsCount.toPrecision(15)) !== 100) return false;
        }

        count += Number(value);
      }
    );
    if (Number(count.toPrecision(15)) !== 100 || haveInvalidField) {
      return false;
    }

    return true;
  }

  async handleChange(e: SelectedItemEvent) {
    this.input = {
      ...this.input,
      locationOtp: this.locationsListOutput,
      selectedItemEvent: e,
    };
    await this.localidadeService
      .listarLocalidadesFilhas(e.item.value)
      .toPromise()
      .then((items) => {
        this.locations = {
          ...this.locations,
          locations: items.map((locationItem) => {
            return {
              id: locationItem.id,
              value: null,
              label: locationItem.nome,
              sublocations: locationItem.filhas,
              version: locationItem.versao,
              operators: [],
            };
          }),
        };
      });
    this.requiredFieldsAreReady = false;
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
  }

  /**
   * Função que recebe a propagação de dados do componente location list
   */
  handleLocationListOutput(event: LocationListOutput) {
    this.locationsListOutput = event;
    // notificar o mudança no modo da pesquisa
    this.onChange.emit();
    // propaga dados para fora do componente
    setTimeout(() => {
      this.requiredFieldsAreReady = this.verifyRequiredFields();
      // propaga se pode habilitar o botão de avançar ou não
      this.onCanNotSaveButton.emit({
        metadata: { canNotSaveButton: !this.requiredFieldsAreReady },
      });
      // propaga os dados que devem ser salvos pelo botão avançar
      this.onNewSave.emit({
        ...this.input,
        locationOtp: this.locationsListOutput,
      });
    }, 0);
  }

  // função do clique de salvar pesquisa
  handleSaveClick() {
    if (this.requiredFieldsAreReady) {
      // propaga dados para fora do componente
      this.onSave.emit({
        ...this.input,
        locationOtp: this.locationsListOutput,
      });
      this.onDone.emit();
    }
  }

  // função que irá abrir uma nova aba para mostrar o questionário
  handleShowQuizPreview() {
    let linkUrl = `${formsLocation}/${this.tokenRef}`;

    this.pesquisaService
      .getFriendlyToken(this.tokenRef)
      .toPromise()
      .then((token) => {
        linkUrl = `${formsLocation}/${token.token_amigavel}`;
        window.open(linkUrl, "_blank");
      })
      .catch((err) =>
        this.errorHandlerService.handleError(err, "Erro ao copiar link")
      );
  }
}
