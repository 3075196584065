<div class="question-card-container">
  <div class="question-card-header" [class.add-border-bottom]="showBody">
    <button class="question-card-opcao" [class.disabled-button]="pergunta.pergunta.alternativas.length <= 0"
      (click)="handleShowBody()">
      <i class="fa fa-chevron-right" aria-hidden="true" *ngIf="!showBody"></i>
      <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="showBody"></i>

      <!-- <button type="button">
      </button> -->
    </button>

    <div class="question-card-resume">
      <div class="header-title-and-flags">
        <h5>Pergunta {{ pergunta.ordem }}</h5>

        <div class="flags">
          <div class="flag" *ngIf="pergunta.pergunta.obrigatoria">
            Obrigatório
          </div>

          <div class="flag">
            {{ handleConvertString(pergunta.pergunta.tipo) }}
          </div>

          <div class="flag" *ngIf="pergunta.pergunta.possuiPulo">Lógica</div>
        </div>
      </div>
      <span>{{ pergunta.pergunta.nome }}</span>
    </div>
  </div>

  <div class="question-card-body" *ngIf="showBody && pergunta.pergunta.alternativas.length > 0">
    <div class="alternativa-list"
      *ngIf="(pergunta.pergunta.titulosGrade.length === 0)&&(pergunta.pergunta.tipo!=='ESCALA_NUMERICA')">
      <div class="alternativa-item" *ngFor="let alternativa of pergunta.pergunta.alternativas">
        <span>{{ alternativa.ordem }}. </span>
        <span>{{ alternativa.descricao }}</span>
      </div>
    </div>
    <div class="pergunta-escala" *ngIf="pergunta.pergunta.tipo==='ESCALA_NUMERICA'">
      <span>Escala de {{this.pergunta.pergunta.faixaPergunta.intervaloInicio}} até
        {{this.pergunta.pergunta.faixaPergunta.intervaloFim}}</span>
    </div>

    <div class="lista-de-grade" *ngIf="pergunta.pergunta.titulosGrade.length > 0">
      <table>
        <thead>
          <tr class="thead">
            <th></th>
            <th *ngFor="
                let headerColumn of pergunta.pergunta.alternativas;
                let headerColumnIndex = index
              " [title]="headerColumn.descricao">
              {{ headerColumn.descricao }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let line of pergunta.pergunta.titulosGrade" class="tbody">
            <td [title]="line.descricao">
              {{ line.descricao }}
            </td>
            <td *ngFor="let checkoboxOrRadio of pergunta.pergunta.alternativas">
              <div [ngClass]="
                  pergunta.pergunta.tipo === 'GRADE_UNICA'
                    ? 'radio-square'
                    : 'checkbox'
                "></div>
            </td>
            <td></td>
          </tr>
          <tr class="tbody footer">
            <td></td>
            <td *ngFor="let checkoboxOrRadioFooter of pergunta.pergunta.alternativas"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
