<div class="table-container-header">
  <ng-container *ngIf="!selectedItems.length">
    <app-input-search-auditoria
      [placeholder]="'Buscar...'"
      (handleChange)="handleTextChange($event)"
    ></app-input-search-auditoria>
    <app-filter-select
      [type]="filterConf.type"
      [icon]="filterConf.icon"
      placeholder="Filtrar por Situação"
      [options]="filterOptions"
      (changeFilter)="handleChangeStatusFilter($event)"
    ></app-filter-select>
    <app-filter-select
      [type]="sortingFilter.type"
      [icon]="sortingFilter.icon"
      [placeholder]="selectedSortingPlaceholder"
      [options]="sortingFilterOptions"
      (changeFilter)="handleChangeSorting($event)"
    ></app-filter-select>
  </ng-container>

  <ng-container *ngIf="selectedItems.length">
    <button
      [disabled]="disableButtonArchive()"
      (click)="
        handleActionButton() ? archiveSurvey($event) : unarchiveSurvey($event)
      "
      class="archive-collection"
    >
      <i class="fa-light fa-box-archive"></i>
      <span>
        {{ handleActionButton() ? "Desarquivar" : "Arquivar" }} coleta</span
      >
    </button>
  </ng-container>
</div>

<div class="table-container">
  <div class="table-header">
    <div class="header-item">
      <span>ID</span>
    </div>
    <div class="header-item">
      <span>Entrevistador</span>
    </div>
    <div class="header-item">
      <span>Localidade</span>
    </div>
    <div class="header-item">
      <span>Critérios</span>
    </div>
    <div class="header-item">
      <span>Situação</span>
    </div>
    <div class="header-item">
      <span></span>
    </div>
  </div>

  <div class="table-body">
    <div class="table-item" *ngFor="let item of data">
      <div class="item-content">{{ item.id }}</div>
      <div class="item-content">
        <h4 appTooltipIfEllipsis="{{ item.operador }}">{{ item.operador }}</h4>
      </div>
      <div class="item-content">
        <h4 appTooltipIfEllipsis="{{ item.localidade }}">{{ item.localidade }}</h4>
      </div>
      <div class="item-content">
        <span
          class="criterion"
          [style.backgroundColor]="criterion['backgroundColor']"
          [style.color]="criterion['color']"
          *ngFor="let criterion of renderCriteria(item.criterios)"
        >
          {{ criterion["text"] }}
        </span>
      </div>
      <div class="item-content">
        <div class="item-status-wrapper">
          <span
            class="status"
            [style.backgroundColor]="getCollectionStatusColor(item.situacao)"
          ></span>
          {{ surveySituation[item.situacao] }}
        </div>
      </div>
      <div class="item-content">
        <app-audit-collections-meatball
          [id]="item.id"
          [showMap]="!hasOnlineCollection"
          (handleItemClicked)="getClickOptionMeatball($event)"
        >
        </app-audit-collections-meatball>
      </div>
    </div>
  </div>
</div>

<!-- collection map -->
<app-audit-collection-map
  *ngIf="showMap"
  [showMap]="showMap"
  [collectIdMap]="collectIdMap"
  [surveyId]="surveyId"
  (handleModal)="handleCloseModal($event)"
>
</app-audit-collection-map>

<!-- Modal de observações -->
<app-prompt-modal *ngIf="observationsModal.show" [modalData]="observationsModal">
  <app-modal-observations
    [surveyId]="surveyId"
    [collectionId]="collectIdMap"
    [allowComments]="allowComments"
  ></app-modal-observations>
</app-prompt-modal>
