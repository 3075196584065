import { Injectable } from '@angular/core';
import { CrudService } from 'app/servico/requestService/crudService';
import { Funcionario } from '../../funcionario/funcionario';
import { RequestService } from 'app/servico/request.service';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { PesquisaCadastro } from '../cadastro/model/pesquisaCadastro';
import { Observable } from 'rxjs';
import { PesquisaSelecao } from '../pesquisaSelecao';
import { PalavraChave } from 'app/util/componente/tabela/tabela-filtravel/filter/palavraChave';
import { TipoStatusPesquisa } from '../cadastro/model/statusPesquisa';
import { DadosPaginacao } from 'app/util/componente/paginacao/dadosPaginacao';

@Injectable({
  providedIn: 'root',
})
export class PesquisaService extends CrudService<PesquisaCadastro> {

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/pesquisas`);
  }

  private toQueryParams(statuses: string[] = [], arquivada: boolean = false) {

    const queryParams: any = { arquivada };
    if (statuses !== null || statuses.length !== 0) {
      queryParams.status = statuses;
    }

    return queryParams;
  }

  // tslint:disable-next-line: max-line-length
  buscarPorPalavrasChavesEStatus(palavraChave: PalavraChave, statuses: string[], arquivada: boolean, dadosPaginacao?: DadosPaginacao): Observable<any[]> {
    const requestUrl = `${this.resourcePath}/filtrar/palavra-chave`;

    const queryParams = this.toQueryParams(statuses, arquivada);


    // tslint:disable-next-line:max-line-length
    const response = <Observable<any[]>>this.requestService.post(requestUrl, palavraChave, true, dadosPaginacao, queryParams);

    return response;
  }

  // tslint:disable-next-line: max-line-length
  getTotalPesquisasComStatus(statuses: string[], arquivada: boolean, palavraChave?: PalavraChave): Observable<number> {

    const requestUrl = `${this.resourcePath}/total-registros`;

    const queryParams = this.toQueryParams(statuses, arquivada);


    // tslint:disable-next-line: max-line-length
    return <Observable<number>>this.requestService.post(requestUrl, palavraChave, true, null, queryParams);
  }

  /**
   * Recupera as pesquisas que serão utilizadas para fins
   * de seleção, como em um select
   */
  getPesquisasSelecao(): Observable<PesquisaSelecao[]> {

    const requestUrl = `${apiLocation}/pesquisas/listar-pesquisas`;

    return this.requestService.get(requestUrl);
  }

  getPesquisasByStatus(status: string): Observable<any> {

    const requestUrl = `${apiLocation}/pesquisas/total?status=${status}`;

    return this.requestService.get(requestUrl);
  }

  /**
   * @author edmar
   * @returns retorna todas as RespostasPesquisa somente com seus status de auditada
   */
  getRespostaPesquisasByStatusAuditada(): Observable<any> {

    const requestUrl = `${apiLocation}/resposta-pesquisa`;

    return this.requestService.get(requestUrl);
  }

  /**
   * duplica uma pesquisa, dado o identificador da pesquisa original
   * @param id identificador da pesquisa a ser duplicada
   */
  postDuplicarPesquisa(id: number): Observable<PesquisaCadastro> {
    const requestUrl = `${apiLocation}/pesquisas/copia/${id}`;

    return <Observable<PesquisaCadastro>>this.requestService.post(requestUrl, true);
  }

  auditarPesquisa(id: number, auditada: boolean): Observable<{}> {

    const uri = `${apiLocation}/pesquisas/${id}/auditoria`;

    return this.requestService.put(uri, {}, {
      auditada,
    });
  }

  buscarEditar(id: any): Observable<PesquisaCadastro> {
    const requestPath = `${this.resourcePath}/buscar-editar/${id}`;
    return this.requestService.get(requestPath);
  }

  buscarReferencia(id: any): Observable<PesquisaSelecao> {
    const requestPath = `${apiLocation}/pesquisas/${id}/referencia`;
    return this.requestService.get(requestPath);
  }

  /**
   * Realiza uma solicitação de arquivamento de um conjunto
   * de pesquisas.
   * @param ids identificadores das pesquisas a serem arquivadas.
   */
  arquivarPesquisas(ids: number[]): Observable<{}> {
    const uri = `${apiLocation}/pesquisas/arquivar`;

    const payload = {
      pesquisas: ids,
    };

    return this.requestService.post(uri, payload);
  }

}
