<div class="header-component">

  <div class="breadcrumb">
    <h4 *ngFor="let item of itens" class="item">
      <div *ngIf="item.focus">
        <strong>{{item.label}}</strong>
      </div>
      <div *ngIf="!item.focus">
        {{item.label}} <span>></span>
      </div>
    </h4>
  </div>

</div>