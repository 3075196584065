import { Component, Input, OnInit } from "@angular/core";
import { surveyListSituationView } from "app/modulos/auditoria-beta/constant";
import { IResumoAuditoria } from "app/modulos/auditoria-beta/interfaces";
import { ECharts, EChartsOption } from "echarts";
import { toEchartOption } from "./toEchartOption";

@Component({
  selector: "app-audit-pie-chart-component",
  templateUrl: "./audit-pie-chart-component.html",
  styleUrls: ["./audit-pie-chart-component.scss"],
})
export class AuditPieChartComponent implements OnInit {
  @Input() totalCollections: number = null;
  @Input() chartData: IResumoAuditoria[];

  chartOption: EChartsOption = {};
  echartsInstance: ECharts;

  situacaoOrder = [
    {key: 'APROVADA', value: surveyListSituationView.APROVADA},
    {key: 'REPROVADA', value: surveyListSituationView.REPROVADA},
    {key: 'EM_ANALISE', value: surveyListSituationView.EM_ANALISE},
    {key: 'NAO_AUDITADA', value: surveyListSituationView.NAO_AUDITADA},
  ];

  constructor() {}

  ngOnInit(): void {

    const data = this.situacaoOrder.map(situacao => {
      const item = this.chartData.find(v => v.situacao === situacao.key);
      return { name: situacao.value, value: item.quantidade_coletas };
    });

    this.buildEchartOption(data)
  }

  buildEchartOption(chartObject) {
    this.chartOption = toEchartOption(chartObject)
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
  }

}
