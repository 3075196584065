import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = 'Itens por página';
  nextPageLabel = 'Próxima página';
  previousPageLabel = 'Página anterior';
  getRangeLabel = this.customGetRangeLabel;

  customGetRangeLabel(page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    const newlength = Math.max(length, 0);
    const startIndex = page * pageSize;
    // tslint:disable-next-line:max-line-length
    const endIndex = startIndex < newlength ? Math.min(startIndex + pageSize, newlength) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${newlength}`;
  }
}
