import { Injectable } from "@angular/core";
import { NotificatorService } from "app/notificador/notificator.service";
import { environment } from "environments/environment";

enum ERROR_TYPES {
  ERROR_USER = "ERROR_USER",
  ERROR_INTEGRATION = "ERROR_INTEGRATION",
}

interface Error {
  error_type: string;
  resumo: string;
  errors: Array<string>;
}

interface ErrorResponse {
  status: number;
  error: Error;
}

interface ErrorObject {
  parsedError?: string;
  error: ErrorResponse;
}

const defaultInternalServerErrorMessage =
  "A operação não pôde ser concluída devido à um problema interno.";
const defaultNetworkErrorMessage =
  "Houve um problema na sua solicitação, verifique a conexão e tente novamente";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  constructor(private notificatorService: NotificatorService) {}

  private isClientError(statusCode: number) {
    return statusCode >= 400 && statusCode < 500;
  }

  private isServerError(statusCode: number) {
    return statusCode >= 500 && statusCode < 600;
  }

  private isNetworkError(statusCode: number) {
    return statusCode === 0;
  }

  private isIntegrationErrorType(errorType: string) {
    return errorType === ERROR_TYPES.ERROR_INTEGRATION;
  }

  private isUserErrorType(errorType: string) {
    return errorType === ERROR_TYPES.ERROR_USER;
  }

  private shouldNotifyIntegrationErrors() {
    return environment.show_errors.includes(ERROR_TYPES.ERROR_INTEGRATION);
  }

  /**
   * Intercepta a natureza do erro de uma requisição e dispara uma flash message de erro para melhor entendimento do usuário à cerca
   * dos erros que podem ocorrer no sistema.
   *
   * handleError verificará a natureza do erro (client error, internal server error, networkError) e disparará
   * uma flassh message de erro através do título do erro e os atributos de descrição do próprio objeto de erro.
   *
   * Quando o erro é de ordem 4xx, o backend possui um mapeamento que contém o resumo do erro, porém esse erro pode ser
   * de dois tipos diferentes: ERROR_USER e INTEGRATION_ERROR.
   *
   * O erro de tipo "ERROR_USER" será notificado em todos os ambientes (dev, homolog e produção), já o erro de tipo
   * "ERROR_INTEGRATION" só será notificado em ambiente de dev. Isso acontecerá no momento porque alguns erros mapeados
   * no backend ainda poderão ter seu tipo mudado, já que percebeu-se que alguns erros de tipo "ERROR_INTEGRATION" deveriam
   * ter sido mapeados como "ERROR_USER", então essa estratégia de notificação de acordo com o ambiente foi aplicada para
   * contornar esse problema momentaneamente.
   * @param errorObject Objeto de erro retornado na resposta de uma requisição
   * @param errorTitle Título do erro.
   * @param message Mensagem de erro opcional.
   * @returns
   */
  handleError(errorObject: ErrorObject, errorTitle: string, message?: string) {
    if (typeof errorObject.error === "object") {
      const { status, error: errorResponse } = errorObject.error;

      if (
        this.isIntegrationErrorType(errorResponse.error_type) &&
        !this.shouldNotifyIntegrationErrors()
      ) {
        return;
      }

      let errMessage: string = null;

      if (this.isClientError(status)) {
        errMessage = message || errorResponse.resumo;
        this.notificatorService.showError(
          errorTitle,
          errMessage
        );
      } else if (this.isServerError(status)) {
        errMessage =
          message ||
          errorObject.parsedError ||
          defaultInternalServerErrorMessage;
        this.notificatorService.showError(errorTitle, errMessage);
      } else if (this.isNetworkError(status)) {
        errMessage = defaultNetworkErrorMessage;
        this.notificatorService.showError(
          errorTitle,
          errMessage
        );
      }
    } else if (errorObject.parsedError) {
      this.notificatorService.showError(errorTitle, errorObject.parsedError);
    }
  }
}
