import { Directive, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { NotificatorService } from "app/notificador/notificator.service";
import { CrudService } from "app/servico/requestService/crudService";

import { FotoUploaderComponent } from "app/util/componente/foto-uploader/foto-uploader.component";
import { NewFotoUploaderComponent } from "app/util/componente/new-foto-uploader/new-foto-uploader.component";
import { stringFormatter } from "app/util/misc/stringFormatter";
import validadorCamposIguais from "app/util/validador/validadorCamposIguais";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

export enum TipoUsuario {
  ADMINISTRADOR = 'Administrador',
  CLIENTE = 'Cliente',
  OPERADOR = 'Operador',
}

interface NotifyObject {
  title: string;
  text: string;
}

@Directive()
export abstract class CadastroBasico<T extends { id: any }>
  implements OnInit, OnDestroy
{
  navigationSubscription: Subscription;
  formGroup: UntypedFormGroup;
  // objeto de facil acesso pawra os formControls
  formControls: { [key: string]: UntypedFormControl } = {};

  isAlteracaoSenha: boolean = false;
  // marcador de edicao
  isEdicao: boolean = false;
  // marcador de loading
  isLoading: boolean = false;

  tituloFormulario: string = "";

  /**
   * Referência para o enum Authority de maneira
   * que suas constantes possam ser acessadas nos templates
   * e evitar a inserção de hard-coded strings.
   */
  authority = Authority;

  idEntidadeRouteParam: string;

  // componente de upload de foto caso necessario
  @ViewChild(FotoUploaderComponent, { static: true })
  fotoUploaderComponent: FotoUploaderComponent;

  // componente de upload de foto caso necessario
  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  constructor(
    protected securityService: SecurityService,
    protected nomeEntidade,
    protected crudService: CrudService<T>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {}

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
        this.indagarSobeCancalamentoDoFluxo();
        if (this.fotoUploaderComponent) {
          this.fotoUploaderComponent.ngOnInit();
        }

        if (this.newFotoUploaderComponent) {
          this.newFotoUploaderComponent.ngOnInit();
        }
      }
    });

    this.activatedRoute.url.subscribe((urlSegment) => {
      if (urlSegment && urlSegment.length <= 0) {
        return;
      }

      if (urlSegment[0].path !== "atualizar") {
        return;
      }

      // verificando se trata-se de uma edicao e nao cadastro
      this.activatedRoute.queryParamMap.subscribe((paramMap) => {
        const idEntidade = paramMap.get("id");

        this.idEntidadeRouteParam = idEntidade;

        this.isEdicao =
          idEntidade === null || idEntidade === undefined ? false : true;
        // this.router.getCurrentNavigation().finalUrl.

        if (this.isEdicao) {
          this.isLoading = true;
          this.crudService.findById(idEntidade).subscribe({
            next: (entidadeEditado) => {
              this.entidadeEdicaoCallback(entidadeEditado);

              this.formGroup.addControl(
                "id",
                new UntypedFormControl(entidadeEditado.id)
              );

              this.isLoading = false;
            },
            error: () => {
              this.modalService.showModal({
                title: `Editar ${this.nomeEntidade}`,
                messageModal: `Não foi possível editar o ${this.nomeEntidade} desejado, verifique a existência do mesmo e tente novamente`,
                btnTitlePositive: "Entendi",
                positiveCallback: () => this.redirecionar(true),
                isOnlyConfirmation: true,
              });
              this.isLoading = false;
            },
          });
        }
      });
    });
  }

  abstract initFormGroup();

  /**
   * callback utilizado para passar a entidade alvo de uma edicao
   */
  abstract entidadeEdicaoCallback(entidade: T);

  redirecionar(force: boolean = false) {
    const redirectTo: string[] = this.getRedirectPath();

    this.router.navigate(redirectTo, {
      queryParams: { redirect: force },
    });
  }

  /**
   * Recupera a url de redirecionamento
   * do cadastro a partir de suas permissões
   */
  abstract getRedirectPath(): string[];

  /**
   * Prepara a entidade para persistência, qualquer ajuste fino deve ser realizado aqui
   */
  abstract prepararEntidadeParaPersistencia(entidade: T): T;

  indagarSobeCancalamentoDoFluxo() {
    const operacao = this.isEdicao ? "da edição" : "do cadastro";

    this.modalService.showModal({
      title: `Sair ${operacao} de ${this.nomeEntidade}`,
      messageModal: `Deseja sair da tela de ${
        this.isEdicao ? "atualização" : "cadastro"
      } de ${this.nomeEntidade}?`,
      btnTitlePositive: "Sim",
      btnTitleNegative: "Não",
      positiveCallback: () => this.redirecionar(true),
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  /**
   * Método chamado quando o usuário deseja
   * cancelar um fluxo de uso (cadastro/edição)
   */
  cancelarFluxo(event: Event = null) {
    if (event) {
      event.preventDefault();
    }

    this.indagarSobeCancalamentoDoFluxo();
  }

  /**
   * Trata-se de um life cycle hook que é chamado
   * quando a requisição de salvar, seja de persistencia ou de atualizacao
   * resulta em uma resposta de erro. A implementação padrão é somente exibir que
   * um erro ocorreu num alerta. Mas pode ser sobrescrevido para exibir erros personalizados.
   * (Como é o caso do componente OperadorCadastroComponent).
   *
   * @param isEdicao se a operação foi de edição ou não
   * @param errorResponse a resposta de erro da requisição
   */
  onSalvarError(isEdicao: boolean, errorResponse) {
    const alertaDataTitleDialog = this.getAlertaDataTitleDialog();
    const mensagemDeErro =
      !!errorResponse.error.error && !!errorResponse.error.error.errors
        ? errorResponse.error.error.errors.join(";\n ")
        : !!errorResponse.error.error
        ? errorResponse.error.error
        : errorResponse.error;

    this.modalService.showModal({
      title: alertaDataTitleDialog,
      messageModal: `Houve um problema ao realizar a operação: ${mensagemDeErro}`,
      btnTitlePositive: "Entendi",
      isOnlyConfirmation: true,
    });

    this.isLoading = false;
  }

  /**
   * recupera a edicao baseado na flag this.isEdicao, se isEdicao = true,
   * retorna o texto 'atualizar', se isEdicao = false, retorna 'salvar'.
   */
  getOperacao() {
    return this.isEdicao ? "atualizar" : "salvar";
  }

  /**
   * recupera o nome da operação de forma capitalizada.
   * Este método usa o metodo this.getOperacao() para recuperar a alteracao
   * baseado no tipo de form (se eh de atualizar ou salvar)
   */
  getOperacaoCapitalizada() {
    return stringFormatter.capitalizeFirstLetter(this.getOperacao());
  }

  /**
   * recupera o titulo da caixa de alerta
   * este método retorna o titulo seguindo o seguinte template:
   * `${nome_operacao} ${nome_entidade}`
   */
  getAlertaDataTitleDialog() {
    return `${this.getOperacaoCapitalizada()} ${this.nomeEntidade}`;
  }

  salvar(entidade: T) {
    if (this.isEdicao) {
      // tslint:disable-next-line: max-line-length
      if (
        this.formGroup.get("id") === undefined ||
        this.formGroup.get("id") === null ||
        entidade.id === null
      ) {
        entidade.id = this.idEntidadeRouteParam;
      }
    }

    const operacao = this.isEdicao ? "atualizar" : "salvar";
    const operacaoCapitalizada =
      stringFormatter.capitalizeFirstLetter(operacao);
    // tslint:disable-next-line:max-line-length
    const alertaDataTitleDialog = `${operacaoCapitalizada} ${this.nomeEntidade}`;

    const executarOperacaoCallback = () => {
      const entidadeToSave = this.prepararEntidadeParaPersistencia(entidade);

      this.isLoading = true;
      this.crudService[operacao](entidadeToSave, false).subscribe({
        next: () => {
          this.redirecionar();
          this.showNotificationSuccess();

          // tslint:disable-next-line:align
        },
        error: (error) => {
          this.onSalvarError(this.isEdicao, error);
        },
      });
    };

    this.modalService.showModal({
      title: alertaDataTitleDialog,
      messageModal: `Deseja ${operacao} o ${this.nomeEntidade}?`,
      positiveCallback: () => executarOperacaoCallback(),
      btnTitlePositive: operacaoCapitalizada,
      btnTitleNegative: "Cancelar",
    });
  }

  /**
  * Habilita ou desabilita os campos senha e confirmar senha em uma edição assim
   como seus Validators
  */
  habilitarAlteracaoSenha() {
    this.isAlteracaoSenha = !this.isAlteracaoSenha;

    const senhaCamposIguaisValidator = validadorCamposIguais(
      this.formControls.senha
    );
    // tslint:disable-next-line:max-line-length
    const senhaConfirmarCamposIguaisValidator = validadorCamposIguais(
      this.formControls.senhaConfirmar
    );

    if (!this.isAlteracaoSenha) {
      this.formControls.senha.clearValidators();
      this.formControls.senhaConfirmar.clearValidators();
      this.formControls.senha.reset();
      this.formControls.senhaConfirmar.reset();
    } else {
      this.formControls.senha.setValidators([
        Validators.required,
        Validators.maxLength(30),
        Validators.minLength(8),
        senhaConfirmarCamposIguaisValidator,
      ]);

      this.formControls.senhaConfirmar.setValidators(
        senhaCamposIguaisValidator
      );

      this.formControls.senha.updateValueAndValidity();
    }
  }

  /**
   * Utilizado nome da entidade para personalizar a notificação de sucesso
   * NotifyObject = Atributo para receber o objeto com titulo e texto que serão apresentados ao usuário
   */
  showNotificationSuccess() {
    this.notificatorService.showInfo(
      `${stringFormatter.capitalizeFirstLetter(this.nomeEntidade)} ${
        this.isEdicao ? "atualizado" : "cadastrado"
      }`,
      `Seu ${this.nomeEntidade} foi ${
        this.isEdicao ? "atualizado" : "cadastrado"
      }!`
    );
  }

  showNotificationError() {
    if (this.formGroup.invalid) {
      const operacao = this.isEdicao ? "na Edição" : "no Cadastro";
      this.notificatorService.showError(
        `Problemas ${operacao}`,
        "Os campos contém erros que devem ser corrigidos"
      );

      // tslint:disable-next-line: max-line-length
      // this.toastr.error('Os campos contém erros que devem ser corrigidos', `Problemas ${operacao}`, {
      //   positionClass: 'toast-bottom-right',
      // });
    }
  }

  getAuthenticatedUserAuthorities(): Authority[] {
    return this.securityService.getAuthenticatedUser().authorities;
  }

  async canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.modalService.showModal({
        title: `Sair ${this.isEdicao ? "da edição" : " do cadastro"} de ${
          this.nomeEntidade
        }`,
        messageModal: `Deseja sair da tela de ${
          this.isEdicao ? "atualização" : "cadastro"
        } de ${this.nomeEntidade}?`,
        btnTitlePositive: "Sim",
        positiveCallback: () => resolve(true),
        btnTitleNegative: "Não",
        negativeCallback: () => resolve(false),
      });
    });
  }
}
