import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { errorMessages } from "app/modulos/contratante-beta/erros";
import {
  DOMAIN_NAME,
  NAME_REGEX,
} from "app/modulos/contratante-beta/utils/ContractorRegex";

@Component({
  selector: "app-basic-information",
  templateUrl: "./basic-information.component.html",
  styleUrls: ["./basic-information.component.scss"],
})
export class BasicInformationComponent implements OnInit {
  @ViewChild("statusDropdown", { static: true }) statusDropdown;
  @ViewChild("licenseSelect", { static: true }) licenseSelect;

  @Input() formGroup!: FormGroup;
  @Input() isEdicao: boolean;
  @Input() onInputChange: any;
  @Input() submitted: boolean;
  @Output() handleChangeDocInput = new EventEmitter();

  licenseIsOpen = false;
  licenseType = ["Tensai Pro"];
  statusIsOpen = false;
  prefixError = errorMessages.prefixoUrlAlreadyExists;

  // Regex utilizados no formulario
  nameRegex = NAME_REGEX;
  domainRegex = DOMAIN_NAME;

  constructor() {}

  ngOnInit(): void {
    this.formGroup.get("ativo")?.setValue(false); // inicia como inativo
  }

  toggleSelect() {
    this.licenseIsOpen = !this.licenseIsOpen;
  }

  toggleStatus() {
    this.statusIsOpen = !this.statusIsOpen;
  }

  handleOptionClick(type: string) {
    this.formGroup.get("tipo_licenca")?.setValue(type);
  }

  handleStatusClick(type: boolean) {
    this.formGroup.get("ativo")?.setValue(type);
  }

  handleInput(event) {
    let pasteControl = false;
    if (event.type == "paste") {
      pasteControl = true;
    }
    setTimeout(() => {
      this.handleChangeDocInput.emit({
        value: event.target.value,
        paste: pasteControl,
      });
    }, 0);
  }

  /**
   * Escuta evento de click e fecha select quando usuário clica fora da área dele.
   * @param event Evento de click
   */
  @HostListener("document:click", ["$event"])
  documentClick(event: Event) {
    if (!this.statusDropdown.nativeElement.contains(event.target)) {
      if (this.statusIsOpen) {
        this.toggleStatus();
      }
    }
    if(!this.licenseSelect.nativeElement.contains(event.target)) {
      if(this.licenseIsOpen) {
        this.toggleSelect()
      }
    }
  }
}
