import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClienteCadastroComponent } from "app/modulos/cliente/cadastro/cliente.cadastro.component";
import { ClienteListagemComponent } from "app/modulos/cliente/listagem/cliente.listagem.component";
import { LocalidadeCadastroComponent } from "app/modulos/localidade/cadastro/localidade.cadastro.component";
import { LocalidadeListagemComponent } from "app/modulos/localidade/listagem/localidade.listagem.component";
import { OperadorCadastroComponent } from "app/modulos/operador/cadastro/operador.cadastro.component";
import { OperadorListagemComponent } from "app/modulos/operador/listagem/operador.listagem.component";
import { ProdutividadeComponent } from "app/modulos/produtividade/produtividade.component";
import { ResultadosComponent } from "./modulos/resultados/resultados.component";
// tslint:disable-next-line:max-line-length
import { AreaInteresseCadastroComponent } from "app/modulos/area-interesse/cadastro/area-interesse.cadastro.component";
// tslint:disable-next-line:max-line-length
import { AreaInteresseListagemComponent } from "app/modulos/area-interesse/listagem/area-interesse.listagem.component";
// tslint:disable-next-line:max-line-length
import { FuncionarioListagemComponent } from "app/modulos/funcionario/listagem/funcionario.listagem.component";
// tslint:disable-next-line:max-line-length
import { FuncionarioCadastroComponent } from "app/modulos/funcionario/cadastro/funcionario.cadastro.component";
import { GrupoCadastroComponent } from "app/modulos/grupo/cadastro/grupo.cadastro.component";
import { GrupoListagemComponent } from "app/modulos/grupo/listagem/grupo.listagem.component";
// tslint:disable-next-line:max-line-length
import { AuthenticationGuardService } from "app/infraestrutura/security/routGuard/authenticationGuardService";
import { LoginPageComponent } from "app/modulos/login-page/login-page.component";
import { RouterComponent } from "./router/router.component";
// tslint:disable-next-line:max-line-length
import { AlreadyAuthenticatedAuthorizationGuard } from "app/infraestrutura/security/routGuard/alreadyAuthenticatedAuthorizationGuard";
// tslint:disable-next-line:max-line-length
import { AuthorizationGuardService } from "app/infraestrutura/security/routGuard/authorization/authorizationGuardService";
// import { PesquisaCadastroComponent } from 'app/modulos/pesquisa-old/cadastro/pesquisa.cadastro.component';
// import { PesquisaListagemComponent } from 'app/modulos/pesquisa-old/listagem/pesquisa.listagem.component';
// tslint:disable-next-line: max-line-length
import { Authority } from "app/infraestrutura/security/authority";
import { RouterItemData } from "app/infraestrutura/security/routerItemData";
// tslint:disable-next-line: max-line-length
import { FuncionarioConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/funcionarioDeactiveteGuard";
// tslint:disable-next-line: max-line-length
import { GrupoConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/grupoDeactivateQuard";
// tslint:disable-next-line: max-line-length
import { OperadorConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/operadorDeactivateQuard";
// tslint:disable-next-line: max-line-length
import { ClienteConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/clienteDeactivateQuard";
import { UpdateGuard } from "app/modulos/listagem/updateGuard";
// tslint:disable-next-line: max-line-length
import { LocalidadeConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/LocalidadeDeactivateQuard";
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
import { ApplicationAuthorizationGuardService } from "app/infraestrutura/security/routGuard/authorization/applicationAuthorizationGuardService";
import { ManagementComponent } from "app/modulos/management/management.component";
// tslint:disable-next-line: max-line-length
import { InstanciaConfirmDeactivateGuard } from "app/infraestrutura/security/routGuard/canDeactivateQuard/instanciaDeactivateQuard";
import { AdministradorComponentBeta } from "app/modulos/administrador-beta/administrador.component";
import { InstanciaCadastroComponent } from "app/modulos/management/instancia-cadastro/instancia-cadastro.component";
import { AlteracaoSenhaComponent } from "app/modulos/recuperar-senha/alteracao/alteracao-senha.component";
import { SolicitacaoTrocaSenhaComponent } from "app/modulos/recuperar-senha/solicitacao/solicitacao-troca-senha.component";

/**
 * páginas novas
 */
import { PesquisaComponent } from "./componentes/pesquisa/pesquisa.component";
import { CadastroContratanteComponent } from "./modulos/contratante-beta/cadastro-contratante/cadastro-contratante.component";
import { ListagemContratanteComponent } from "./modulos/contratante-beta/listagem-contratante/listagem-contratante.component";
import { DashboardBetaComponent } from "./modulos/dashboard-beta/dashboard.component";
import { PesquisasCadastroComponent } from "./modulos/pesquisa-beta/cadastro/pesquisas-cadastro.component";
import { PesquisasComponent } from "./modulos/pesquisa-beta/listagem/pesquisas.component";
import { SearchComponent } from "./modulos/search/search.component";

/*
  Localidades
*/
import { AuditoriaDeactivateGuard } from "./infraestrutura/security/routGuard/canDeactivateQuard/auditoriaDeactivateQuard";
import { RespostaPerguntaConfirmDeactivateGuard } from "./infraestrutura/security/routGuard/canDeactivateQuard/respostaPerguntaDeactivateGuard";
import { AuditDetailsComponent } from "./modulos/auditoria-beta/pages/detalhes-auditoria/audit-details.component";
import { QuestionnaireComponent } from "./modulos/auditoria-beta/pages/questionario/questionnaire.component";
import { AuditListingComponent } from "./modulos/auditoria-beta/pages/listagem-pesquisas/audit-listing.component";
import { AuditoriaComponent } from "./modulos/auditoria/auditoria.component";
import { EntrevistaListagemComponent } from "./modulos/auditoria/entrevista-listagem/entrevista-listagem.component";
import { RespostaListagemComponent } from "./modulos/auditoria/resposta-pergunta-listagem/resposta-pergunta-listagem.component";
import { RespostasAbertasComponent } from "./modulos/auditoria/respostas-abertas/respostas-abertas.component";
import { TipoPasso } from "./modulos/auditoria/tipoPasso";
import { CadastroLGPDComponent } from "./modulos/cadastro-lgpd/cadastro-lgpd.component";
import { ListagemClienteComponent } from "./modulos/cliente-beta/listagem-cliente/listagem-cliente.component";
import { IntegracaoSGMTComponent } from "./modulos/integracao-sgmt/integracao-sgmt.component";
import { ListReadComponent } from "./modulos/localidade-beta/list-read/list-read.component";
import { PagePrivacyComponent } from "./modulos/page-privacy/page-privacy.component";
import { InformacoesComponent } from "./modulos/pesquisa-beta/informacoes/informacoes.component";
import { DetalhesOperadorComponent } from "./modulos/produtividade-beta/detalhes-operador/detalhes-operador.component";
import { LIstagemOperadoresComponent } from "./modulos/produtividade-beta/listagem-operadores/listagem-operadores.component";
import { ProdutividadeBetaComponent } from "./modulos/produtividade-beta/listagem-pesquisas/listagem-pesquisas.component";
import { VisaoGeralComponent } from "./modulos/produtividade-beta/visao-geral/visao-geral.component";
import { ResultadoPesquisaComponent } from "./modulos/resultados/resultado-pesquisa/resultado-pesquisa.component";
import { OperadorListagemBetaComponent } from "./modulos/operador-beta/listagem/listagem-operadores.component";
import { CadastroOperadorBetaComponent } from "./modulos/operador-beta/cadastro/cadastro.component";
import { FuncionarioListagemBetaComponent } from "./modulos/funcionario-beta/listagem/listagem-funcionarios.component";
import { CadastroFuncionarioBetaComponent } from "./modulos/funcionario-beta/cadastro/cadastro.component";
import { CadastroComponent } from "./modulos/cliente-beta/cadastro/cadastro.component";
import {IntegracaoContratanteComponent} from './modulos/integracao-contratante/integracao-contratante.component';
import { ChangePasswordComponent } from "./componentes/change-password/change-password.component";
import { NotAlreadyAuthenticatedAuthorizationGuard } from "./infraestrutura/security/routGuard/notAlreadyAuthenticatedAuthorizationGuard";
/**
 * Guards que tratam autenticacao e autorizacao
 */
const authenticationGuard = [AuthenticationGuardService];
const authorizationGuard = [AuthorizationGuardService];
const authenticationAndAuthorizationGuard = [
  ...authenticationGuard,
  ...authorizationGuard,
];
const updateGuard = [...authenticationAndAuthorizationGuard, UpdateGuard];

/**
 * Guards que tratam o caso do usuario ja estar autenticado e nao poder acessar
 * mais conteudos como a pagina de login
 */
const alreadyAuthenticatedAuthorizationGuard = [
  AlreadyAuthenticatedAuthorizationGuard,
];

/**
 * Guards que protegem o acesso à aplicação,
 * por hora, apenas funcionarios podem acessar
 * a aplicação web
 */
const applicationAuthorizationGuard = [ApplicationAuthorizationGuardService];
const applicationGuard = [
  ...authenticationGuard,
  ...applicationAuthorizationGuard,
];

const routes: Routes = [
  {
    path: "",
    canActivate: applicationGuard,
    data: new RouterItemData([
      Authority.ROLE_FUNCIONARIO,
      Authority.ROLE_ADMINISTRADOR,
    ]),
    component: RouterComponent,
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "",
        component: DashboardBetaComponent,
      },
      {
        path: "administrador-beta",
        canActivate: applicationGuard,
        data: new RouterItemData([
          Authority.ROLE_FUNCIONARIO,
          Authority.ROLE_ADMINISTRADOR,
        ]),
        runGuardsAndResolvers: "always",
        children: [
          {
            path: "atualizar",
            component: AdministradorComponentBeta,
            // canDeactivate: [InstanciaConfirmDeactivateGuard],
            data: new RouterItemData([]),
          },
        ],
      },
      {
        path: "pesquisar",
        children: [
          {
            path: "",
            component: SearchComponent,
          },
        ],
      },
      {
        path: "management",
        canActivate: authenticationAndAuthorizationGuard,
        data: new RouterItemData([Authority.CADASTRAR_INSTANCIA]),
        children: [
          {
            path: "",
            component: ManagementComponent,
            canActivate: authenticationGuard,
            data: new RouterItemData([]),
          },
          {
            path: "cadastro",
            component: InstanciaCadastroComponent,
            canDeactivate: [InstanciaConfirmDeactivateGuard],
            data: new RouterItemData([]),
          },
          {
            path: "atualizar",
            component: InstanciaCadastroComponent,
            // canActivate: updateGuard,
            canDeactivate: [InstanciaConfirmDeactivateGuard],
            // runGuardsAndResolvers: 'always',
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData([]),
          },
        ],
      },
      {
        path: "produtividade",
        component: ProdutividadeComponent,
        canActivate: authenticationAndAuthorizationGuard,
        data: new RouterItemData([Authority.CONTROLE_PRODUTIVIDADE]),
      },
      {
        path: "area-interesse",
        canActivate: authenticationAndAuthorizationGuard,
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "listagem",
            component: AreaInteresseListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: {
              necessaryAuthorities: [],
            },
          },
          {
            path: "cadastro",
            component: AreaInteresseCadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: {
              necessaryAuthorities: [],
            },
          },
        ],
      },
      {
        path: "cliente",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: ClienteListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_CLIENTE, Authority.ATUALIZAR_CLIENTE],
              "cliente/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: ClienteCadastroComponent,
            canActivate: updateGuard,
            canDeactivate: [ClienteConfirmDeactivateGuard],
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData([
              Authority.LISTAR_CLIENTE,
              Authority.ATUALIZAR_CLIENTE,
            ]),
          },
          {
            path: "cadastro",
            component: ClienteCadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [ClienteConfirmDeactivateGuard],
            runGuardsAndResolvers: "always",
            data: new RouterItemData([Authority.CADASTRAR_CLIENTE]),
          },
        ],
      },
      {
        path: "cliente-beta",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: ListagemClienteComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_CLIENTE, Authority.ATUALIZAR_CLIENTE],
              "cliente/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: CadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            data: new RouterItemData([Authority.ATUALIZAR_CLIENTE]),
          },
          {
            path: "cadastro",
            component: CadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            data: new RouterItemData([Authority.CADASTRAR_CLIENTE]),
          },
        ],
      },
      {
        path: "operador",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: OperadorListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_OPERADOR, Authority.ATUALIZAR_OPERADOR],
              "operador/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: OperadorCadastroComponent,
            canActivate: updateGuard,
            canDeactivate: [OperadorConfirmDeactivateGuard],
            data: new RouterItemData([
              Authority.LISTAR_OPERADOR,
              Authority.ATUALIZAR_OPERADOR,
            ]),
          },
          {
            path: "cadastro",
            component: OperadorCadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [OperadorConfirmDeactivateGuard],
            data: new RouterItemData([Authority.CADASTRAR_OPERADOR]),
          },
        ],
      },
      {
        path: "operador-beta",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: OperadorListagemBetaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_OPERADOR, Authority.ATUALIZAR_OPERADOR],
              "operador/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: CadastroOperadorBetaComponent,
            canActivate: updateGuard,
            data: new RouterItemData([
              Authority.LISTAR_OPERADOR,
              Authority.ATUALIZAR_OPERADOR,
            ]),
          },
          {
            path: "cadastro",
            component: CadastroOperadorBetaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([Authority.CADASTRAR_OPERADOR]),
          },
        ],
      },
      {
        path: "localidade-beta",
        component: ListReadComponent,
      },
      {
        path: "localidade",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: LocalidadeListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_LOCALIDADE, Authority.ATUALIZAR_LOCALIDADE],
              "localidade/cadastro"
            ),
          },
          {
            path: "cadastro",
            component: LocalidadeCadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [LocalidadeConfirmDeactivateGuard],
            runGuardsAndResolvers: "always",
            data: new RouterItemData([Authority.CADASTRAR_LOCALIDADE]),
          },
        ],
      },
      {
        path: "funcionario",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: FuncionarioListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_FUNCIONARIO, Authority.ATUALIZAR_FUNCIONARIO],
              "funcionario/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: FuncionarioCadastroComponent,
            canActivate: updateGuard,
            canDeactivate: [FuncionarioConfirmDeactivateGuard],
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData([
              Authority.LISTAR_FUNCIONARIO,
              Authority.ATUALIZAR_FUNCIONARIO,
              Authority.EDICAO_PERFIL_FUNCIONARIO,
            ]),
          },
          {
            path: "cadastro",
            component: FuncionarioCadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [FuncionarioConfirmDeactivateGuard],
            runGuardsAndResolvers: "always",
            data: new RouterItemData([Authority.CADASTRAR_FUNCIONARIO]),
          },
          {
            path: "perfil",
            component: FuncionarioCadastroComponent,
            canActivate: updateGuard,
            canDeactivate: [FuncionarioConfirmDeactivateGuard],
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData([Authority.EDICAO_PERFIL_FUNCIONARIO]),
          },
        ],
      },
      {
        path: "funcionario-beta",
        canActivate: authenticationAndAuthorizationGuard,
        runGuardsAndResolvers: "always",
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: FuncionarioListagemBetaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_FUNCIONARIO, Authority.ATUALIZAR_FUNCIONARIO],
              "funcionario/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: CadastroFuncionarioBetaComponent,
            canActivate: updateGuard,
            runGuardsAndResolvers: "always",
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData([
              Authority.LISTAR_FUNCIONARIO,
              Authority.ATUALIZAR_FUNCIONARIO,
              Authority.EDICAO_PERFIL_FUNCIONARIO,
            ]),
          },
          {
            path: "cadastro",
            component: CadastroFuncionarioBetaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            runGuardsAndResolvers: "always",
            data: new RouterItemData([Authority.CADASTRAR_FUNCIONARIO]),
          },
        ],
      },
      {
        path: "grupo",
        canActivate: authenticationAndAuthorizationGuard,
        data: {
          necessaryAuthorities: [],
        },
        children: [
          {
            path: "",
            component: GrupoListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            // tslint:disable-next-line: max-line-length
            data: new RouterItemData(
              [Authority.LISTAR_GRUPO, Authority.ATUALIZAR_GRUPO],
              "grupo/cadastro"
            ),
          },
          {
            path: "atualizar",
            component: GrupoCadastroComponent,
            canActivate: updateGuard,
            canDeactivate: [GrupoConfirmDeactivateGuard],
            data: new RouterItemData([
              Authority.LISTAR_GRUPO,
              Authority.ATUALIZAR_GRUPO,
            ]),
          },
          {
            path: "cadastro",
            component: GrupoCadastroComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [GrupoConfirmDeactivateGuard],
            data: new RouterItemData([Authority.CADASTRAR_GRUPO]),
          },
        ],
      },
      // {
      //   path: "dashboard-beta",
      //   component: DashboardBetaComponent,
      // },
      {
        path: "pesquisa-beta",
        children: [
          {
            path: "",
            component: PesquisasComponent,
            children: [
              {
                path: "",
                component: PesquisaComponent,
              },
            ],
          },
          {
            path: "cadastro-beta",
            component: PesquisasCadastroComponent,
          },
          {
            path: "informacoes-beta",
            component: InformacoesComponent,
          },
        ],
      },
      {
        path: "contratante-beta",
        children: [
          {
            path: "",
            component: ListagemContratanteComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([Authority.CADASTRAR_INSTANCIA, Authority.LISTAR_INSTANCIA]),
          },
          {
            path: "cadastro",
            component: CadastroContratanteComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([Authority.CADASTRAR_INSTANCIA]),
          },
          {
            path: "atualizar/:tenant",
            component: CadastroContratanteComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([Authority.CADASTRAR_INSTANCIA]),
          },
        ],
      },
      {
        path: "comunicacao",
        children: [
          {
            path: "",
            component: CadastroLGPDComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([Authority.CADASTRAR_DOC_LGPD]),
          },
        ],
      },
      // {
      //   path: 'pesquisa',
      //   canActivate: authenticationAndAuthorizationGuard,
      //   // tslint:disable-next-line: max-line-length
      //   data: {
      //     necessaryAuthorities: [],
      //   },
      //   children: [
      //     {
      //       path: '',
      //       component: PesquisaListagemComponent,
      //       canActivate: authenticationAndAuthorizationGuard,
      //       // tslint:disable-next-line: max-line-length
      //       data: new RouterItemData([Authority.LISTAR_PESQUISA, Authority.CADASTRAR_PESQUISA_COMPLETA, Authority.CADASTRAR_PESQUISA_CARACTERISTICAS, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_LOCALIDADES], 'pesquisa/cadastro'),
      //     },
      //     {
      //       path: 'atualizar',
      //       component: PesquisaCadastroComponent,
      //       canActivate: updateGuard,
      //       canDeactivate: [PesquisaConfirmDeactivateGuard],
      //       // tslint:disable-next-line: max-line-length
      //       data: new RouterItemData([Authority.CADASTRAR_PESQUISA_COMPLETA, Authority.CADASTRAR_PESQUISA_CARACTERISTICAS, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_LOCALIDADES]),
      //     },
      //     {
      //       path: 'cadastro',
      //       component: PesquisaCadastroComponent,
      //       canActivate: authenticationAndAuthorizationGuard,
      //       canDeactivate: [PesquisaConfirmDeactivateGuard],
      //       // tslint:disable-next-line: max-line-length
      //       data: new RouterItemData([Authority.CADASTRAR_PESQUISA_COMPLETA, Authority.CADASTRAR_PESQUISA_CARACTERISTICAS]),
      //     },
      //   ],
      // },
      {
        path: "resultados",
        canActivate: authenticationAndAuthorizationGuard,
        data: new RouterItemData([Authority.ACESSO_RESULTADO]),
        children: [
          {
            path: "",
            component: ResultadosComponent,
          },
          {
            path: "resultado-pesquisa/:id",
            component: ResultadoPesquisaComponent,
          },
        ],
      },
      {
        path: "auditoria",
        canActivate: authenticationAndAuthorizationGuard,

        data: {
          necessaryAuthorities: [],
          data: new RouterItemData([
            Authority.AUDITORIA_PASSO_1,
            Authority.AUDITORIA_PASSO_2,
          ]),
        },
        children: [
          {
            path: "pesquisas",
            component: AuditoriaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([
              Authority.AUDITORIA_PASSO_1,
              Authority.AUDITORIA_PASSO_2,
            ]),
          },
          {
            path: "pesquisas/:idPesquisa",
            component: AuditoriaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [AuditoriaDeactivateGuard],

            data: {
              necessaryAuthorities: [],
            },

            children: [
              {
                path: TipoPasso.RESPOSTAS_ABERTAS,
                component: RespostasAbertasComponent,
                canActivate: authenticationAndAuthorizationGuard,

                data: new RouterItemData([Authority.AUDITORIA_PASSO_1]),
              },
              {
                path: TipoPasso.RESPOSTAS_FECHADAS,
                component: EntrevistaListagemComponent,
                canActivate: authenticationAndAuthorizationGuard,
                data: new RouterItemData([Authority.AUDITORIA_PASSO_2]),
              },
            ],
          },
          {
            path: "pesquisas",
            component: AuditoriaComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: {
              necessaryAuthorities: [],
            },
          },
          {
            path: "pesquisas/:idPesquisa/entrevistas/:idEntrevista",
            component: RespostaListagemComponent,
            canActivate: authenticationAndAuthorizationGuard,
            canDeactivate: [RespostaPerguntaConfirmDeactivateGuard],
            data: {
              necessaryAuthorities: [],
            },
          },
        ],
      },
      {
        path: "auditoria-beta",
        canActivate: authenticationAndAuthorizationGuard,

        data: {
          necessaryAuthorities: [],
          data: new RouterItemData([
            Authority.AUDITORIA_PASSO_1,
            Authority.AUDITORIA_PASSO_2,
          ]),
        },
        children: [
          {
            path: "",
            component: AuditListingComponent,
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([
              Authority.AUDITORIA_PASSO_1,
              Authority.AUDITORIA_PASSO_2,
            ]),
          },
          {
            path: "detalhes/:id",
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([
              Authority.AUDITORIA_PASSO_1,
              Authority.AUDITORIA_PASSO_2,
            ]),

            children: [
              {
                path: "",
                component: AuditDetailsComponent,
                canActivate: authenticationAndAuthorizationGuard,
                data: new RouterItemData([
                  Authority.AUDITORIA_PASSO_1,
                  Authority.AUDITORIA_PASSO_2,
                ]),
              },
              {
                path: "questionario/:collectionId",
                component: QuestionnaireComponent,
                canActivate: authenticationAndAuthorizationGuard,
                data: new RouterItemData([
                  Authority.AUDITORIA_PASSO_1,
                  Authority.AUDITORIA_PASSO_2,
                ]),
              },
            ],
          },
        ],
      },
      {
        path: "produtividade-beta",
        canActivate: authenticationAndAuthorizationGuard,
        data: new RouterItemData([Authority.CONTROLE_PRODUTIVIDADE]),
        children: [
          {
            path: "",
            component: ProdutividadeBetaComponent,
          },
          {
            path: "visao-geral/:surveyId",
            canActivate: authenticationAndAuthorizationGuard,
            data: new RouterItemData([Authority.CONTROLE_PRODUTIVIDADE]),

            children: [
              {
                path: "",
                component: VisaoGeralComponent,
              },
              {
                path: "entrevistadores",
                canActivate: authenticationAndAuthorizationGuard,
                data: new RouterItemData([Authority.CONTROLE_PRODUTIVIDADE]),

                children: [
                  {
                    path: "",
                    component: LIstagemOperadoresComponent,
                  },
                  {
                    path: "entrevistador/:entrevistadorId",
                    component: DetalhesOperadorComponent,
                    canActivate: authenticationAndAuthorizationGuard,
                    data: new RouterItemData([Authority.CONTROLE_PRODUTIVIDADE]),
                  }
                ]
              }
            ]
          },
        ]
      },
    ],
  },
  {
    path: "login",
    canActivate: alreadyAuthenticatedAuthorizationGuard,
    component: LoginPageComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "recuperar-senha",
    canActivate: alreadyAuthenticatedAuthorizationGuard,
    component: SolicitacaoTrocaSenhaComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "redefinir-senha",
    canActivate: alreadyAuthenticatedAuthorizationGuard,
    component: AlteracaoSenhaComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "nova-senha",
    canActivate: [NotAlreadyAuthenticatedAuthorizationGuard],
    component: ChangePasswordComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "privacy",
    component: PagePrivacyComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "auth/integration",
    component: IntegracaoSGMTComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "auth/contratante",
    component: IntegracaoContratanteComponent,
    runGuardsAndResolvers: "always",
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
