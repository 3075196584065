<section>
  <div class="date-picker">
    <label for="">{{ label }}</label>
    <div
      [ngClass]="{
        'opened-calendar': picker.opened,
        'input-disabled': isDisabled
      }"
      class="input-action"
      [ngStyle]="hasError && {'border-color': 'var(--status-error)'}"
    >
      <input
        mascara-data
        matInput
        [matDatepicker]="picker"
        placeholder="dd/mm/aaaa"
        [(ngModel)]="selectedDate"
        [disabled]="isDisabled"
        (ngModelChange)="onChangeDate($event)"
      />
      <i class="fa-regular fa-calendar" (click)="picker.open()"></i>
      <mat-datepicker
        #picker
        [calendarHeaderComponent]="header"
        >
        <mat-datepicker-actions>
          <button (click)="handleClear()" class="clean-btn" matDatepickerCancel>
            Limpar
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </div>
  </div>
</section>
