import { RespostaPergunta } from "./respostaPergunta";

export class EntrevistaPerguntas {

  audio: string;
  respostasPerguntas: RespostaPergunta[];
  auditada: boolean;
  causaRejeicao: string;
  rejeitada: boolean;

  constructor(audio : string, respostasPerguntas : RespostaPergunta[], auditada: boolean, causaRejeicao: string, rejeitada: boolean) {
    this.audio = audio;
    this.respostasPerguntas = respostasPerguntas;
    this.auditada = auditada;
    this.causaRejeicao = causaRejeicao;
    this.rejeitada = rejeitada;
  }

}
