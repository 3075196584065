import { Component, Input, OnInit, Renderer2 } from "@angular/core";

const BADGE_CONTAINER_WIDTH = 112
const BADGE_BORDER_WIDTH = 2

@Component({
  selector: "generic-table-cell-badge",
  templateUrl: "./badges.component.html",
  styleUrls: ["./badges.component.scss"],
})
export class BadgesComponent implements OnInit {
  @Input() data: string[] = [];
  showBadge: boolean = false; // controla a exibicação da tooltip no hover
  private tooltipElement: HTMLElement;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  // retorna os 3 primeiros itens
  getThreeFirstBadges() {
    return this.data.slice(0, 3);
  }

  // retorna o tamanho do array restante sem os 3 primeiros
  getRestLengthBadges() {
    return Math.max(this.data.length - 3, 0);
  }

  // retorna as badges removendo as 3 primeiras
  getLastBadges() {
    const badges = this.data.slice(3);
    const groupedBadges = [];

    for (let i = 0; i < badges.length; i += 2) {
      groupedBadges.push(badges.slice(i, i + 2).join(', '));
    }

    return groupedBadges.join('\n');
  }

  /**
   * Verifica se o tamanho do conteudo e igual ou maior que o container
   * para exibir a tooltip na badge
   *
   * BADGE_CONTAINER_WIDTH: 112px - Tamanho do container (maxWidth)
   * BADGE_BORDER_WIDTH: 2px - Tamanho da borda (border 1px left + right)
   *
   * @param event: Evento do mouse
   */
  currentBadge(event: MouseEvent, badge: string): void {
    const badgeElement = event.target as HTMLElement;
    const textWidth = badgeElement.scrollWidth;
    const containerWidth = BADGE_CONTAINER_WIDTH - BADGE_BORDER_WIDTH;

    if (textWidth >= containerWidth) {
      this.showTooltip(badgeElement, badge, true);
      this.renderer.listen(badgeElement, 'mouseleave', () => this.hideTooltip());
    } else {
      this.hideTooltip();
    }
  }

  /**
   * Recupera o evento do mouse e realiza a chamada do metodo que irá controlar
   * todo fluxo de renderização da tooltip
   */
  currentRemainingBadges(event: MouseEvent): void {
    const badgeElement = event.target as HTMLElement;
    this.showTooltip(badgeElement, this.getLastBadges(), false);
    this.renderer.listen(badgeElement, 'mouseleave', () => this.hideTooltip());
  }

  /**
   * Metodo responsável pela renderização da tooltip
   * Aqui ocorre todo controle de proporção e posicionamento das tooltips
   * @param target: htmlElement
   * @param badgeText: string com todo array de permissões concatenados
   * @param isSingleBadge: bool para saber se a badge faz parte das 3 primeiras renderizadas na tabela
   */
  private showTooltip(target: HTMLElement, badgeText: string, isSingleBadge: boolean): void {
    this.hideTooltip(); // Remove any existing tooltip
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'tooltip');
    this.tooltipElement.innerText = badgeText;
    this.renderer.appendChild(document.body, this.tooltipElement);

    const targetRect = target.getBoundingClientRect();

    const topPosition = this.validateTopPosition(isSingleBadge, targetRect);
    const leftPosition = isSingleBadge ? targetRect.left + window.scrollX : targetRect.left + window.scrollX + 50
    this.renderer.setStyle(this.tooltipElement, 'top', `${topPosition}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${leftPosition}px`);
    this.renderer.setStyle(this.tooltipElement, 'display', 'block');
    this.renderer.setStyle(this.tooltipElement, 'white-space', 'pre');
  }

  /**
   * Retorna a posição da tooltip baseado no tipo e quantidade
   * @returns posição top da tooltip
   */
  validateTopPosition(isSingleBadge: boolean, targetRect): number {
    if(isSingleBadge) {
      return targetRect.bottom + window.scrollY + 6;
    } else if (this.getRestLengthBadges()< 15) {
      return targetRect.bottom + window.scrollY;
    } else if (this.getRestLengthBadges()< 20) {
      return targetRect.bottom + window.scrollY - 100;
    } else {
      return targetRect.bottom + window.scrollY - 200;
    }
  }

  /**
   * Esconde a tooltip
   */
  private hideTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

}
