<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">
  
  <app-pergunta-card
    [icone]="'fa fa-square-o'"
    [titulo]="ordem + ' - GRADE ÚNICA'"
    [nomePlaceHolder]="'Título da pergunta de grade única?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>


<!-- <div class="grade-unica">
  <app-grade-conteudo
    (inputUpdate)="handleNestedInputEvent()($event)"
    [parentEventPublisher]="nestedInputPublisher"
    >
  </app-grade-conteudo>
</div> -->
  