import { TrBody } from 'app/util/componente/tabela/trbody/trBody';
import { Entrevista } from '../../../../auditoria/model/entrevista';

export class EntrevistaTrBody extends TrBody {

  duracao: string;
  dataColeta: string;
  inicioColeta: string;
  fimColeta: string;
  auditado: boolean;
  aprovado: boolean;
  localidade: string;
  operador: string;
  entrevista?: Entrevista;

}
