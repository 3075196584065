import { Injectable } from '@angular/core';
import { TipoPergunta } from '../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { SelecaoUnicaComponent } from '../../../pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/selecao/selecao-unica/selecao-unica.component';
import { SelecaoMultiplaComponent } from '../../../pesquisa-old/cadastro/passos/questionario/componentes/secao/pergunta/selecao/selecao-multipla/selecao-multipla.component';
import { RespostaSelecaoUnicaComponent } from './selecao-unica/selecao-unica.component';
import { RespostaSelecaoMultiplaComponent } from './selecao-multipla/selecao-multipla.component';
import { RespostaEscalaNumericaComponent } from './escala-numerica/escala-numerica.component';
import { RespostaGradeUnicaComponent } from './grade-unica/grade-unica.component';
import { RespostaMultiplaOrdemCitacaoComponent } from './multipla-ordem-citacao/multipla-ordem-citacao.component';
import { RespostaGradeMultiplaComponent } from './grade-multipla/grade-multipla.component';
import { RespostaFotoComponent } from './foto/foto.component';
import { RespostaVideoComponent } from './video/video.component';
import { RespostaDataComponent } from './data/data.component';
import { RespostaHorarioComponent } from './horario/horario.component';

@Injectable({
  providedIn: 'root',
})
export class RespostaComponentFactoryService {

  private tipoPerguntaRespostaComponentMapper : Map<string, any>;

  constructor() {
    this.tipoPerguntaRespostaComponentMapper = new Map();
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.UNICA, RespostaSelecaoUnicaComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.MULTIPLA, RespostaSelecaoMultiplaComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.ESCALA_NUMERICA, RespostaEscalaNumericaComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.GRADE_UNICA, RespostaGradeUnicaComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.GRADE_MULTIPLA, RespostaGradeMultiplaComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.MULTIPLA_ORDEM_CITACAO, RespostaMultiplaOrdemCitacaoComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.FOTO, RespostaFotoComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.VIDEO, RespostaVideoComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.DATA, RespostaDataComponent);
    this.tipoPerguntaRespostaComponentMapper.set(TipoPergunta.HORARIO, RespostaHorarioComponent);
  }

  getComponent(tipoPergunta : string) : any {
    const componentSchema = this.tipoPerguntaRespostaComponentMapper.get(tipoPergunta);
    return componentSchema;
  }
}
