import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OperadorVinculado, Perfil } from '../../localidades/localidade';
import { AjusteCotaModalComponent } from '../../perfilCota/ajuste/ajuste.cota.modal.component';
// tslint:disable-next-line: max-line-length
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TransferidorCotaModalComponent } from '../../perfilCota/transferidor-cota-modal/transferidor-cota-modal.component';
import perfisSearchService from './perfis/perfisSearchService';

@Component({
  selector: 'app-operador',
  templateUrl: './operador.component.html',
  styleUrls: ['./operador.component.scss'],
})
export class OperadorComponent implements OnInit, OnChanges, OnDestroy {

  @Input() operadorVinculado: OperadorVinculado;
  @Input() operadoresVinculados: OperadorVinculado[] = [];

  operadorAtingiuMetas: boolean = false;

  private searchHandler: Subject<string> = new Subject();
  private searchHandlerSubscription: Subscription;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.operadorAtingiuMetas = this.atingiuMetas(this.operadorVinculado);

    this.operadoresVinculados = this.filtrarOperadoresVinculadosIguaisAoPrincipal(
      this.operadorVinculado,
      this.operadoresVinculados);

    this.registerSearchHandlerListener();
  }

  ngOnDestroy() {
    if (this.searchHandlerSubscription) {
      this.searchHandlerSubscription.unsubscribe();
    }
  }

  /**
   * registra um listener para escutar as mensagens de solicitação
   * de busca que normalmente vem do evento keyUp do input de busca.
   */
  registerSearchHandlerListener() {
    this.searchHandlerSubscription = this.searchHandler.pipe(
      debounceTime(500),
    ).subscribe((queryText: string) => {

      if (this.operadorVinculado.perfis) {
        this.operadorVinculado.perfis = perfisSearchService
          .filter(queryText, this.operadorVinculado.perfis);
      }
    });
  }

  toggleExpand() {

    this.operadorVinculado.expanded = !this.operadorVinculado.expanded;
  }

  /**
   * Método que filtra perfis de um operador baseado na string passada.
   * É um simples mecanismo de filtragem onde o perfil que possui caracteristicas
   * que contém o texto passado (ignorando case), tem seu atributo "hidden" setado
   * como false e caso não tenha nenhuma caracteristica q possua o termo passado,
   * hidden é setado como true.
   *
   * @param terms parametro da busca
   */
  filtrarPerfis(terms: string) {
    this.searchHandler.next(terms);
  }

  filtrarOperadoresVinculadosIguaisAoPrincipal(principal: OperadorVinculado,
    operadoresVinculados: OperadorVinculado[]) {
    return operadoresVinculados
      .filter((operadorVinculado: OperadorVinculado) => {
        return principal.idVinculoOperador !== operadorVinculado.idVinculoOperador;
      });
  }

  /**
   * verifica se o operador já entrevistou o número esperado de pessoas que
   * satisfazem os perfis.
   */
  atingiuMetas(operadorVinculado: OperadorVinculado) {

    if (!this.operadorVinculado.perfis || this.operadorVinculado.perfis.length === 0) {
      return false;
    }

    const perfisNaoConcluidos = this.operadorVinculado.perfis
      .filter((perfil: Perfil) => {
        return perfil.cotaNumericaExecutada !== perfil.cotaNumericaPrevista;
      });

    return perfisNaoConcluidos.length === 0;
  }

  /**
   * usando ngOnChanges ao invés de chamar a função "atingiuMetas()" diretamente.
   * Pois funções chamadas diretamente pelo template são chamadas várias e várias vezes
   * devido ao ciclo de vida do componente. Com esta abordagem, só chamamos o metodo
   * "atingiuMetas()" quando o angnular detecta q o input "operadorVinculado" foi alterado.
   * Desta forma, chamamos o metodo e salvamos como estado interno do componente para ser
   * utilizado pelo template.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (changes.operadorVinculado) {
      this.operadorAtingiuMetas = this.atingiuMetas(changes.operadorVinculado.currentValue);
    }
  }

  requestTransferenciaCotas(perfil: Perfil) {
    this.dialog.open(TransferidorCotaModalComponent, {
      data: {
        idVinculadoOperadorOrigem: this.operadorVinculado.idVinculoOperador,
        operadoresVinculados: this.operadoresVinculados,
        perfilOrigem: perfil,
      },
    });
  }

  requestAjusteCotas() {
    this.dialog.open(AjusteCotaModalComponent, {
      data: {
        idVinculoOperador: this.operadorVinculado.idVinculoOperador,
      },
    });
  }
}
