<div class="containerAuditoriaResposta">
  <div class="title">
    <span class="{{ getIcon(respostaPergunta.pergunta.tipo) }}"></span> <h2>{{ getFormattedNumPergunta() }}. {{ respostaPergunta.pergunta.descricao }}</h2>
  </div>

  <app-container-resposta
    [respostaPergunta]="respostaPergunta"
    [proximaPerguntaSubject]="proximaPerguntaSubject"
    [novaRespostaPerguntaSubject]="novaRespostaPerguntaSubject"
    [closeRespostaSubject]="closeRespostaSubject">
  </app-container-resposta>

  <div class="actions">
    <button  (click)="onCancelar()" class="sg-btn sg-secondary mr-3">Cancelar</button>
    <button  (click)="onSalvar()" class="sg-btn sg-alternate">Salvar</button>
  </div>
</div>
