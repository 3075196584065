<div>
    <svg width="352" height="263" viewBox="0 0 352 263" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="247.704" cy="252.36" r="7.91534" stroke="#F4EDF7" stroke-width="4.65609"/>
        <circle cx="73.1005" cy="41.4389" r="13.9683" stroke="#F4EDF7" stroke-width="4.65609"/>
        <circle cx="340.825" cy="110.814" r="8.84656" stroke="#F4EDF7" stroke-width="4.65609"/>
        <path d="M349.387 147.868C343.853 132.416 335.122 127.714 326.821 117.796C316.679 105.644 309.846 96.8941 312.473 81.3394C316.772 55.8948 318.614 -7.55781 251.263 0.743002C227.118 3.71559 208.039 22.2429 194.158 40.6767C182.352 56.3903 161.104 57.7831 144.007 61.4474C123.003 65.9437 101.596 72.7488 82.1622 82.7042C42.1257 103.223 30.1699 132.724 31.8058 170.461C32.2919 181.865 33.909 199.822 53.1748 220.846C79.1709 249.207 116.945 253.002 162.413 239.98C172.817 236.998 182.66 232.315 192.896 228.763C203.543 225.102 214.584 222.705 225.791 221.621C251.908 219.097 278.979 223.659 304.031 215.863C317.341 211.701 329.379 204.234 339.02 194.158C350.985 181.65 355.239 164.17 349.387 147.868Z" fill="#F4EDF6"/>
        <path d="M53.1743 206.076C53.1743 209.815 100.465 212.835 158.804 212.835C217.143 212.835 264.434 209.806 264.434 206.076C264.434 202.346 217.134 199.327 158.804 199.327C100.474 199.327 53.1743 202.346 53.1743 206.076Z" fill="#CAA8D3"/>
        <path d="M117.636 214.416C117.636 216.753 105.624 218.641 90.8173 218.641C76.0105 218.641 63.9985 216.772 63.9985 214.416C63.9985 212.06 76.0011 210.2 90.808 210.2C105.615 210.2 117.636 212.07 117.636 214.416Z" fill="#AB87B5"/>
        <path d="M296.039 218.639C320.892 218.639 341.039 217.141 341.039 215.293C341.039 213.445 320.892 211.946 296.039 211.946C271.186 211.946 251.039 213.445 251.039 215.293C251.039 217.141 271.186 218.639 296.039 218.639Z" fill="#CAA8D3"/>
        <path d="M218.723 186.633H171.9V199.224H218.723V186.633Z" fill="#4A41A3"/>
        <path d="M267.939 73.9736H123.179C118.332 73.9736 114.402 77.9035 114.402 82.7512V181.968C114.402 186.816 118.332 190.746 123.179 190.746H267.939C272.787 190.746 276.716 186.816 276.716 181.968V82.7512C276.716 77.9035 272.787 73.9736 267.939 73.9736Z" fill="#5851BC"/>
        <path d="M266.294 78.2354H124.33C121.351 78.2354 118.936 80.6502 118.936 83.629V174.153C118.936 177.132 121.351 179.546 124.33 179.546H266.294C269.273 179.546 271.688 177.132 271.688 174.153V83.629C271.688 80.6502 269.273 78.2354 266.294 78.2354Z" fill="#C4C3FC"/>
        <path d="M158.616 198.149H231.996C233.811 198.149 235.551 198.87 236.834 200.154C238.118 201.437 238.839 203.177 238.839 204.992V206.086H151.773V204.992C151.773 203.177 152.494 201.437 153.778 200.154C155.061 198.87 156.801 198.149 158.616 198.149Z" fill="#5851BC"/>
        <g opacity="0.35">
        <path opacity="0.35" d="M257.61 85.2275H160.094C157.507 85.2275 155.411 87.3243 155.411 89.9108V152.382C155.411 154.968 157.507 157.065 160.094 157.065H257.61C260.196 157.065 262.293 154.968 262.293 152.382V89.9108C262.293 87.3243 260.196 85.2275 257.61 85.2275Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M170.226 137.715H165.571V149.4H170.226V137.715Z" fill="#FCA70C"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M180.715 127.452H176.06V149.401H180.715V127.452Z" fill="#B1AFFD"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M191.212 129.723H186.557V149.4H191.212V129.723Z" fill="#FCA70C"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M201.7 118.608H197.045V149.4H201.7V118.608Z" fill="#B1AFFD"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.15">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M178.2 127.452H176.06V149.401H178.2V127.452Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.15">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M167.711 137.715H165.571V149.4H167.711V137.715Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.62">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M199.186 118.608H197.045V149.4H199.186V118.608Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.15">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M188.698 129.723H186.557V149.4H188.698V129.723Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M204.168 152.616H162.496V108.252H163.132V151.981H204.168V152.616Z" fill="#D5DBDA"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M166.777 132.238L166.356 131.761L177.256 121.974L187.352 124.171L198.279 112L198.756 112.43L187.576 124.872L177.434 122.666L166.777 132.238Z" fill="#D5DBDA"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M199.607 114.225L196.532 111.271L200.505 110.205L199.607 114.225Z" fill="#D5DBDA"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M211.31 136.884H208.113V140.081H211.31V136.884Z" fill="#B1AFFD"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M211.31 142.483H208.113V145.68H211.31V142.483Z" fill="#FCA70C"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M222.322 143.866H212.189V145.68H222.322V143.866Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M222.322 138.268H212.189V140.081H222.322V138.268Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M230.11 136.884H226.913V140.081H230.11V136.884Z" fill="#B1AFFD"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M230.11 142.483H226.913V145.68H230.11V142.483Z" fill="#FCA70C"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M241.12 143.866H230.987V145.68H241.12V143.866Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M241.12 138.268H230.987V140.081H241.12V138.268Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M247.038 136.884H243.841V140.081H247.038V136.884Z" fill="#B1AFFD"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M247.038 142.483H243.841V145.68H247.038V142.483Z" fill="#FCA70C"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.59 143.866H247.925V145.68H254.59V143.866Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.59 138.268H247.925V140.081H254.59V138.268Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.59 106.336H208.711V107.168H254.59V106.336Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.59 96.0986H162.496V98.0056H254.59V96.0986Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 108.923H208.572V109.755H254.591V108.923Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 103.736H208.572V104.568H254.591V103.736Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 111.522H208.572V112.354H254.591V111.522Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 114.123H208.572V114.955H254.591V114.123Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 116.712H208.572V117.544H254.591V116.712Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 119.311H208.572V120.142H254.591V119.311Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 121.899H208.572V122.731H254.591V121.899Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 124.497H208.572V125.329H254.591V124.497Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M254.591 127.088H214.208V127.92H254.591V127.088Z" fill="#EFEFEF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M257.61 85.2373H160.103C158.863 85.2373 157.675 85.7298 156.798 86.6063C155.922 87.4828 155.429 88.6716 155.429 89.9112V94.0055H262.302V89.9112C262.302 89.2959 262.181 88.6866 261.945 88.1183C261.709 87.55 261.363 87.0339 260.927 86.5997C260.491 86.1654 259.973 85.8215 259.404 85.5877C258.835 85.3539 258.225 85.2349 257.61 85.2373Z" fill="#7A7AE5"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M248.552 89.79C248.552 90.0581 248.472 90.3201 248.323 90.543C248.174 90.7659 247.963 90.9397 247.715 91.0422C247.467 91.1448 247.195 91.1717 246.932 91.1194C246.669 91.0671 246.427 90.938 246.238 90.7484C246.048 90.5589 245.919 90.3174 245.867 90.0544C245.815 89.7915 245.841 89.519 245.944 89.2713C246.047 89.0236 246.22 88.8119 246.443 88.663C246.666 88.5141 246.928 88.4346 247.196 88.4346C247.556 88.4346 247.901 88.5774 248.155 88.8316C248.409 89.0857 248.552 89.4305 248.552 89.79Z" fill="#CFCFFF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M252.703 91.1267C253.446 91.1267 254.049 90.5241 254.049 89.7807C254.049 89.0372 253.446 88.4346 252.703 88.4346C251.959 88.4346 251.356 89.0372 251.356 89.7807C251.356 90.5241 251.959 91.1267 252.703 91.1267Z" fill="#CFCFFF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M258.208 91.1267C258.951 91.1267 259.554 90.5241 259.554 89.7807C259.554 89.0372 258.951 88.4346 258.208 88.4346C257.464 88.4346 256.862 89.0372 256.862 89.7807C256.862 90.5241 257.464 91.1267 258.208 91.1267Z" fill="#CFCFFF"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.55">
        <path d="M229.221 101.119H131.705C129.118 101.119 127.021 103.216 127.021 105.802V168.273C127.021 170.86 129.118 172.957 131.705 172.957H229.221C231.807 172.957 233.904 170.86 233.904 168.273V105.802C233.904 103.216 231.807 101.119 229.221 101.119Z" fill="#C4C3FC"/>
        </g>
        <path d="M231.39 98.3232H133.874C131.288 98.3232 129.191 100.42 129.191 103.006V165.478C129.191 168.064 131.288 170.161 133.874 170.161H231.39C233.977 170.161 236.073 168.064 236.073 165.478V103.006C236.073 100.42 233.977 98.3232 231.39 98.3232Z" fill="white"/>
        <path d="M144.007 150.803H139.352V162.487H144.007V150.803Z" fill="#FCA70C"/>
        <path d="M154.495 140.546H149.84V162.494H154.495V140.546Z" fill="#B1AFFD"/>
        <path d="M164.983 142.81H160.328V162.487H164.983V142.81Z" fill="#FCA70C"/>
        <path d="M175.481 131.704H170.826V162.496H175.481V131.704Z" fill="#B1AFFD"/>
        <g style="mix-blend-mode:multiply" opacity="0.15">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M151.98 140.546H149.84V162.494H151.98V140.546Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.15">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M141.492 150.803H139.352V162.487H141.492V150.803Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.62">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M172.966 131.704H170.826V162.496H172.966V131.704Z" fill="black"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.15">
        <g style="mix-blend-mode:multiply" opacity="0.26">
        <path d="M162.468 142.81H160.328V162.487H162.468V142.81Z" fill="black"/>
        </g>
        </g>
        <path d="M177.938 165.713H136.275V125.303H136.911V165.077H177.938V165.713Z" fill="#D5DBDA"/>
        <path d="M140.557 145.337L140.127 144.86L151.026 135.073L161.122 137.269L172.059 125.099L172.536 125.519L161.356 137.97L151.213 135.764L140.557 145.337Z" fill="#D5DBDA"/>
        <path d="M173.387 127.321L170.302 124.358L174.284 123.302L173.387 127.321Z" fill="#D5DBDA"/>
        <path d="M185.081 149.981H181.884V153.178H185.081V149.981Z" fill="#B1AFFD"/>
        <path d="M185.081 155.578H181.884V158.775H185.081V155.578Z" fill="#FCA70C"/>
        <path d="M196.101 156.964H185.968V158.777H196.101V156.964Z" fill="#EFEFEF"/>
        <path d="M196.101 151.354H185.968V153.167H196.101V151.354Z" fill="#EFEFEF"/>
        <path d="M203.888 149.981H200.691V153.178H203.888V149.981Z" fill="#B1AFFD"/>
        <path d="M203.888 155.578H200.691V158.775H203.888V155.578Z" fill="#FCA70C"/>
        <path d="M214.9 156.964H204.767V158.777H214.9V156.964Z" fill="#EFEFEF"/>
        <path d="M214.9 151.354H204.767V153.167H214.9V151.354Z" fill="#EFEFEF"/>
        <path d="M220.817 149.981H217.62V153.178H220.817V149.981Z" fill="#B1AFFD"/>
        <path d="M220.817 155.578H217.62V158.775H220.817V155.578Z" fill="#FCA70C"/>
        <path d="M228.36 156.964H221.695V158.777H228.36V156.964Z" fill="#EFEFEF"/>
        <path d="M228.36 151.354H221.695V153.167H228.36V151.354Z" fill="#EFEFEF"/>
        <path d="M228.361 119.432H182.482V120.264H228.361V119.432Z" fill="#EFEFEF"/>
        <path d="M228.37 109.187H136.275V111.093H228.37V109.187Z" fill="#EFEFEF"/>
        <path d="M228.37 122.021H182.351V122.853H228.37V122.021Z" fill="#EFEFEF"/>
        <path d="M228.37 116.832H182.351V117.664H228.37V116.832Z" fill="#EFEFEF"/>
        <path d="M228.37 124.619H182.351V125.451H228.37V124.619Z" fill="#EFEFEF"/>
        <path d="M228.37 127.209H182.351V128.041H228.37V127.209Z" fill="#EFEFEF"/>
        <path d="M228.37 129.808H182.351V130.64H228.37V129.808Z" fill="#EFEFEF"/>
        <path d="M228.37 132.396H182.351V133.228H228.37V132.396Z" fill="#EFEFEF"/>
        <path d="M228.37 134.994H182.351V135.826H228.37V134.994Z" fill="#EFEFEF"/>
        <path d="M228.37 137.584H182.351V138.416H228.37V137.584Z" fill="#EFEFEF"/>
        <path d="M228.36 140.184H187.978V141.016H228.36V140.184Z" fill="#EFEFEF"/>
        <path d="M231.39 98.3233H133.874C132.634 98.3233 131.446 98.8157 130.569 99.6922C129.693 100.569 129.2 101.758 129.2 102.997V107.101H236.073V102.997C236.073 102.383 235.952 101.774 235.717 101.206C235.481 100.639 235.136 100.123 234.701 99.6889C234.266 99.2548 233.75 98.9107 233.182 98.6763C232.613 98.442 232.005 98.322 231.39 98.3233Z" fill="#A4A4F7"/>
        <path d="M222.332 102.877C222.347 103.064 222.324 103.252 222.263 103.429C222.202 103.606 222.105 103.769 221.978 103.907C221.851 104.045 221.697 104.155 221.526 104.23C221.354 104.306 221.168 104.345 220.981 104.345C220.793 104.345 220.608 104.306 220.436 104.23C220.265 104.155 220.11 104.045 219.983 103.907C219.857 103.769 219.76 103.606 219.699 103.429C219.638 103.252 219.615 103.064 219.63 102.877C219.658 102.538 219.813 102.222 220.063 101.992C220.313 101.762 220.641 101.634 220.981 101.634C221.321 101.634 221.648 101.762 221.899 101.992C222.149 102.222 222.303 102.538 222.332 102.877Z" fill="#CFCFFF"/>
        <path d="M227.827 102.877C227.829 103.146 227.751 103.41 227.603 103.634C227.455 103.858 227.243 104.033 226.995 104.137C226.747 104.241 226.474 104.269 226.21 104.217C225.946 104.165 225.704 104.035 225.514 103.845C225.324 103.655 225.194 103.413 225.142 103.149C225.09 102.885 225.118 102.612 225.222 102.364C225.325 102.116 225.501 101.904 225.725 101.756C225.949 101.608 226.213 101.529 226.481 101.531C226.838 101.531 227.181 101.673 227.433 101.926C227.686 102.178 227.827 102.52 227.827 102.877Z" fill="#CFCFFF"/>
        <path d="M233.334 102.877C233.336 103.146 233.258 103.41 233.11 103.634C232.962 103.858 232.75 104.033 232.502 104.137C232.254 104.241 231.981 104.269 231.717 104.217C231.453 104.165 231.211 104.035 231.021 103.845C230.83 103.655 230.701 103.413 230.649 103.149C230.597 102.885 230.625 102.612 230.729 102.364C230.832 102.116 231.007 101.904 231.232 101.756C231.456 101.608 231.719 101.529 231.988 101.531C232.345 101.534 232.685 101.676 232.937 101.928C233.189 102.18 233.332 102.521 233.334 102.877Z" fill="#CFCFFF"/>
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <path d="M267.481 74.0579H242.457C242.625 82.1593 242.762 90.2607 242.868 98.3621C242.958 99.6048 242.802 100.853 242.41 102.036C242.083 102.036 242.233 102.12 242.111 102.709C241.529 103.506 240.795 104.179 239.951 104.691C239.107 105.203 238.17 105.543 237.194 105.691C237.166 105.779 237.166 105.874 237.194 105.962C237.079 106.046 236.996 106.167 236.961 106.305C236.925 106.443 236.938 106.59 236.998 106.719C237.11 106.99 238.55 107.355 240.503 107.149C241.248 107.032 241.975 106.828 242.672 106.541C242.965 106.4 243.235 106.218 243.476 105.999C243.726 105.973 243.971 105.913 244.205 105.822L244.28 105.896L246.523 104.962C246.523 104.962 246.28 103.55 246.084 102.812C246.051 102.69 246 102.573 245.934 102.466C247.812 97.3843 249.282 92.161 250.328 86.8456C249.926 93.389 247.523 106.831 247.383 107.289C247.134 107.84 246.743 108.316 246.25 108.667C245.758 109.018 245.181 109.233 244.579 109.29C244.046 109.29 241.438 109.187 241.775 110.383C241.84 110.673 243.195 111.318 245.149 111.412C245.903 111.429 246.656 111.357 247.393 111.197C247.9 111.034 248.373 110.777 248.785 110.439L249.084 110.841L251.459 110.271C251.459 110.271 251.459 109.645 251.459 108.906C251.415 108.387 251.238 107.888 250.945 107.457V106.111L252.253 105.952C252.253 105.952 254.899 97.3992 256.161 94.4921C256.723 93.3144 257.195 92.0957 257.572 90.8465C257.892 89.3486 258.077 87.825 258.124 86.2941C258.345 83.5037 258.856 80.7438 259.647 78.0587C259.691 77.8871 259.768 77.7253 259.872 77.582C260.115 77.3781 260.424 77.2715 260.741 77.2828C264.106 76.9744 265.649 77.5446 267.752 76.8902C270.556 76.0302 270.818 74.7683 270.958 74.7122C269.855 74.2606 268.672 74.0379 267.481 74.0579Z" fill="#5851BC"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <path d="M276.717 130.088V101.054C272.389 106.728 268.107 111.804 263.77 117.468C261.9 119.927 254.544 128.069 259.554 131.34C260.835 132.172 263.069 132.135 264.228 132.21C268.229 132.5 272.202 131.387 276.09 130.284L276.717 130.088Z" fill="#5851BC"/>
        </g>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <path d="M276.716 136.351L274.305 136.538V136.407L272.435 136.547L271.865 134.949C271.676 134.484 271.347 134.089 270.923 133.821C270.498 133.553 270.001 133.424 269.5 133.453C269.286 133.452 269.075 133.503 268.886 133.604C268.697 133.704 268.536 133.85 268.417 134.028C268.298 134.206 268.226 134.41 268.206 134.623C268.186 134.836 268.22 135.051 268.303 135.248C268.406 135.519 268.677 136.295 268.864 136.828L266.892 136.977V137.118L262.835 137.426C262.19 137.426 261.236 138.258 261.442 138.838V138.931C261.438 138.981 261.438 139.031 261.442 139.081L264.957 148.999L264.873 190.783H268.948L276.669 160.767L276.716 136.351ZM270.378 136.043C270.097 136.06 269.817 135.989 269.578 135.838C269.34 135.686 269.155 135.464 269.051 135.201C269.005 135.091 268.986 134.971 268.998 134.852C269.009 134.733 269.05 134.618 269.117 134.519C269.184 134.42 269.274 134.339 269.38 134.283C269.486 134.228 269.604 134.199 269.724 134.201C270.004 134.185 270.282 134.258 270.519 134.409C270.756 134.56 270.939 134.782 271.042 135.042C271.083 135.15 271.097 135.267 271.083 135.381C271.069 135.496 271.028 135.605 270.963 135.701C270.898 135.796 270.81 135.874 270.709 135.929C270.607 135.983 270.494 136.013 270.378 136.015V136.043Z" fill="#5851BC"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <path d="M124.61 119.112C123.651 120.363 122.841 121.721 122.198 123.16C121.736 124.101 120.929 124.827 119.945 125.188C118.16 126.002 116.206 126.787 114.402 127.507V132.246C118.141 130.003 121.525 127.899 121.581 127.881C124.441 128.974 126.834 126.591 127.601 124.88C127.947 124.113 127.423 123.796 127.405 123.431C127.405 123.244 127.638 122.132 127.601 121.982C127.377 120.982 124.684 121.861 124.909 121.384C125.105 120.726 125.496 120.142 126.031 119.711C127.349 118.776 128.358 117.841 128.358 117.439C128.358 116.785 124.918 118.767 124.61 119.112Z" fill="#5851BC"/>
        </g>
        <g opacity="0.45">
        <path opacity="0.45" d="M306.163 185.465H295.749C294.835 185.465 294.095 186.206 294.095 187.119V193.326C294.095 194.24 294.835 194.981 295.749 194.981H306.163C307.076 194.981 307.817 194.24 307.817 193.326V187.119C307.817 186.206 307.076 185.465 306.163 185.465Z" fill="#E2DEFF"/>
        </g>
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <path d="M269.407 160.411L271.099 164.861H278.175C278.175 164.861 277.679 184.089 277.651 187.697C277.623 191.305 280.615 216.18 280.615 216.18H284.709L284.288 184.818L290.093 168.731C290.093 168.731 294.468 190.23 296.282 193.867C298.095 197.503 307.826 216.18 307.826 216.18H311.565L301.909 174.928L297.768 160.598L276.885 150.839L269.407 151.577" fill="#5851BC"/>
        </g>
        <path d="M96.2575 99.4087C97.5101 101.577 96.4352 104.531 97.6971 106.691C98.2019 107.551 99.0619 108.233 99.3049 109.205C99.4092 109.616 99.3918 110.048 99.2549 110.45C99.118 110.851 98.8675 111.204 98.5337 111.465C98.2 111.727 97.7974 111.885 97.3751 111.922C96.9527 111.959 96.5287 111.873 96.1547 111.673C95.9963 111.579 95.8306 111.498 95.6592 111.43C95.0423 111.252 94.4721 111.804 93.9299 112.168C93.6083 112.407 93.2362 112.568 92.8422 112.639C92.4483 112.71 92.0433 112.69 91.6585 112.58C91.1957 112.364 90.7874 112.047 90.4638 111.652C90.1401 111.258 89.9095 110.795 89.7889 110.299C88.8542 107.579 89.5272 104.606 90.3685 101.886C90.7798 100.521 91.3967 98.9974 92.7427 98.502" fill="#5045AF"/>
        <path d="M125.396 122.442C125.396 122.741 125.872 123.003 125.564 123.638C124.882 125.05 122.76 127.013 120.226 126.106C120.114 126.153 106.205 134.182 105.139 134.182C104.073 134.182 96.726 127.284 94.3891 118.74C93.8188 116.702 94.0432 114.3 95.9502 113.271C96.522 113.466 97.0492 113.772 97.5003 114.174C97.9515 114.575 98.3177 115.063 98.577 115.608C100.157 118.59 104.887 128.471 105.934 128.845C107.242 127.976 113.617 126.041 118.768 123.891C119.625 123.61 120.341 123.011 120.769 122.217C121.345 121.023 122.064 119.902 122.909 118.88C123.19 118.6 126.237 116.964 126.237 117.497C126.237 117.805 125.302 118.628 124.171 119.366C123.704 119.708 123.357 120.189 123.18 120.74C122.984 121.133 125.368 120.413 125.564 121.245C125.592 121.395 125.396 122.329 125.396 122.442Z" fill="#FFB9B0"/>
        <path d="M95.3796 112.982C96.2636 113.187 97.093 113.581 97.8109 114.136C98.5289 114.691 99.1184 115.394 99.5393 116.198C99.7492 116.621 99.9364 117.055 100.1 117.497C100.839 119.367 101.858 121.087 102.605 122.975C101.607 123.672 100.502 124.202 99.3337 124.546C98.3616 124.91 97.3201 125.05 96.2863 124.957C96.1099 124.949 95.9362 124.911 95.7723 124.845C95.5661 124.729 95.3849 124.573 95.2394 124.387C94.9215 124.022 94.6131 123.648 94.3046 123.274C94.0288 122.965 93.8188 122.602 93.6876 122.209C93.5896 121.654 93.6379 121.084 93.8279 120.554" fill="#7762EF"/>
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <g style="mix-blend-mode:multiply" opacity="0.35">
        <path d="M97.2019 118.16C97.14 118.077 97.0601 118.01 96.9682 117.963C96.8721 117.937 96.7698 117.943 96.6781 117.982C96.5864 118.021 96.5107 118.091 96.4635 118.178C96.3829 118.36 96.3476 118.559 96.3605 118.758C96.3705 119.525 96.4901 120.287 96.7158 121.02L96.4821 124.993C97.4357 125.044 98.3897 124.901 99.2864 124.572C99.7164 124.413 100.221 124.245 100.642 124.058C100.212 121.833 98.6881 119.936 97.2019 118.16Z" fill="#7762EF"/>
        </g>
        </g>
        <path d="M91.0421 109.075C91.0421 109.075 90.986 111.562 91.3693 111.721C91.8889 111.935 92.427 112.101 92.9771 112.216V117.367L86.1065 116.338L81.4326 113.702C81.4326 113.702 86.3402 112.076 86.387 111.59C86.4337 111.104 86.1346 108.309 86.1346 108.309" fill="#FFB9B0"/>
        <path d="M80.5257 183.576C78.6561 192.484 79.5909 204.711 79.1235 211.423L81.6755 211.75C81.6755 211.75 87.4804 183.501 88.5367 178.098C88.6061 177.753 88.653 177.403 88.6769 177.051C88.9293 172.976 85.2556 172.77 83.1898 176.117C82.8319 176.696 82.5194 177.302 82.255 177.93C82.1335 178.201 82.0307 178.463 81.9185 178.753C81.5554 179.669 81.2433 180.606 80.9837 181.557V181.66C80.8903 181.95 80.8248 182.239 80.75 182.529V182.595C80.6192 182.968 80.5257 183.268 80.5257 183.576Z" fill="#FFB9B0"/>
        <path d="M91.491 151.615C91.4256 152.354 91.033 155.859 90.5562 160.028C90.1543 163.506 89.7149 167.432 89.3691 170.535C89.1167 172.863 88.9298 174.714 88.8456 175.592C88.8137 176.098 88.745 176.601 88.64 177.097C87.7613 181.164 84.9009 181.397 83.237 180.453C83.0046 180.334 82.7878 180.187 82.5919 180.014C82.2232 179.707 81.9668 179.286 81.8629 178.817C81.7601 177.976 81.6478 176.836 81.5543 175.48C81.3487 172.835 81.1711 169.442 81.0216 166.132C80.8346 162.066 80.7131 158.14 80.6851 155.934C80.6851 155.803 80.6851 155.691 80.6851 155.569C80.6851 148.792 86.8825 144.446 90.7525 146.549L90.8927 146.624L91.033 146.708C91.4817 147.007 91.6219 148.017 91.6313 149.176C91.6248 149.991 91.578 150.805 91.491 151.615Z" fill="#FFB9B0"/>
        <path d="M82.0117 177.191L81.535 175.415C81.5662 177.496 81.3687 179.575 80.9461 181.613V181.715C80.8713 181.996 80.8059 182.295 80.7124 182.585V182.65C80.6132 182.954 80.5474 183.267 80.5161 183.585C80.5161 184.389 82.2268 180.781 82.6194 179.977L82.6941 179.818L82.2361 177.948L82.0117 177.191Z" fill="#FFB9B0"/>
        <path d="M95.4268 185.277C95.2492 194.625 98.1284 205.562 98.8949 212.339C98.9884 212.339 101.241 212.04 101.241 212.04C101.241 212.04 101.671 183.473 101.709 177.827C101.711 177.468 101.692 177.109 101.652 176.752C101.185 172.639 97.848 173.34 96.558 177.201C96.3353 177.871 96.1541 178.555 96.0157 179.248C95.9596 179.538 95.9036 179.837 95.8569 180.183C95.6925 181.195 95.5802 182.215 95.5204 183.239V183.351C95.5204 183.651 95.5203 183.959 95.4642 184.286V184.361L95.4268 185.277Z" fill="#FFB9B0"/>
        <path d="M97.3523 181.473C97.1224 181.41 96.9023 181.315 96.698 181.192C96.2976 180.972 95.9916 180.613 95.8379 180.183C95.5949 179.379 95.3051 178.257 94.9779 176.93V176.874C94.3423 174.303 93.6038 170.966 92.9027 167.722C92.0521 163.721 91.2762 159.852 90.8742 157.655L90.4723 155.785C89.2758 148.98 94.5947 144.568 98.4554 145.839L98.6049 145.886C99.3807 146.204 99.5957 148.933 99.7733 150.775C99.9509 152.616 101.26 171.742 101.587 175.201C101.635 175.716 101.657 176.234 101.652 176.752C101.559 181.052 99.0723 181.987 97.3523 181.473Z" fill="#FFB9B0"/>
        <path d="M95.716 178.548L94.9868 176.902C95.3666 178.983 95.542 181.097 95.5103 183.212V183.324C95.5103 183.614 95.5103 183.932 95.4542 184.259V184.334C95.4304 184.645 95.4304 184.957 95.4542 185.269C95.5851 186.072 96.5292 182.09 96.7348 181.165C96.7348 181.043 96.7348 180.978 96.7348 180.978L95.9964 179.239L95.716 178.548Z" fill="#FFB9B0"/>
        <path d="M83.8814 138.53C83.8814 138.53 78.8804 144.886 79.3945 151.261C79.6095 153.925 79.9366 156.262 80.2357 158.936C80.2357 159.319 80.8807 164.928 80.7125 164.937C80.7125 164.937 90.995 168.115 100.96 163.946C100.96 163.273 101.764 150.205 96.3793 137.772C92.2371 138.326 88.0602 138.579 83.8814 138.53Z" fill="#5146AF"/>
        <path d="M99.5297 123.872C99.5297 125.686 98.5296 126.798 97.1367 128.331C96.7317 130.652 96.4788 132.997 96.3795 135.351C96.3047 136.763 96.903 137.641 96.3795 137.754C94.3884 139.502 87.1813 140.913 83.8816 138.53C84.2274 135.594 82.9468 129.135 82.012 125.854C81.8718 125.349 81.7502 124.919 81.6381 124.592C80.8155 122.096 79.3199 114.094 80.4229 113.749C80.6473 113.674 80.9838 113.59 81.3577 113.468C83.0777 112.945 85.6296 112.057 85.6296 112.057C85.6296 112.057 87.1252 116.478 91.0233 117.011C93.1452 116.142 92.9582 113.449 92.8928 112.216C93.5845 112.412 93.893 112.599 96.043 113.244C96.6506 114.113 96.1271 115.927 97.0993 118.628C97.4265 119.301 99.5858 121.657 99.5297 123.872Z" fill="#7762EF"/>
        <path d="M96.0058 98.96C96.0058 98.96 96.3984 103.765 95.5758 106.289C95.1308 107.31 94.4092 108.186 93.4925 108.819C92.5757 109.451 91.5004 109.815 90.3878 109.869C84.6483 109.869 83.62 102.073 83.648 101.69C83.6761 101.306 92.1171 98.0439 92.1171 98.0439L96.0058 98.96Z" fill="#FFB9B0"/>
        <path d="M106.942 215.059C104.895 215.424 102.717 214.844 101.82 215.003C101.081 215.134 99.1741 215.611 98.4637 215.003C97.7533 214.396 97.8656 212.105 98.6882 211.806C98.8623 211.861 99.0404 211.901 99.2209 211.928C100.029 212.046 100.854 211.979 101.633 211.731C102.694 212.317 103.833 212.748 105.017 213.012C105.789 213.161 106.544 213.39 107.269 213.694C108.036 214.012 108.092 214.872 106.942 215.059Z" fill="#5045AF"/>
        <path d="M84.8731 215.956C82.8259 215.611 81.5359 214.48 80.6385 214.321C79.9 214.19 78.0305 213.825 77.8342 213.059C77.6846 212.498 78.2455 210.133 79.1148 210.133C79.2593 210.245 79.4125 210.345 79.5728 210.432C80.3009 210.807 81.1011 211.02 81.9191 211.058C82.719 211.967 83.6445 212.757 84.6674 213.405C85.336 213.808 85.9623 214.278 86.537 214.807C87.1726 215.396 86.0322 216.153 84.8731 215.956Z" fill="#5045AF"/>
        <path d="M55.0249 120.46L56.8945 118.216L68.3735 128.499C68.3735 128.499 73.9822 121.021 75.5152 118.712C77.2165 116.151 78.7402 113.963 81.2174 113.533C81.7876 113.888 81.021 123.087 80.7313 123.302C77.2632 125.9 70.5329 134.248 68.3829 134.687C65.9244 135.145 55.0249 120.46 55.0249 120.46Z" fill="#FFB9B0"/>
        <path d="M82.0775 113.093C82.0775 113.093 79.7311 113.093 78.7216 113.953C77.712 114.813 72.898 122.412 72.898 122.412C72.8976 122.922 73.0117 123.425 73.2317 123.884C73.4518 124.344 73.7721 124.748 74.1692 125.067C75.1669 125.873 76.3664 126.391 77.6373 126.563C77.6373 126.563 80.0491 124.161 81.3764 123.067C82.2271 122.384 82.3112 124.067 82.3112 124.067L86.5737 119.907L82.0775 113.093Z" fill="#7762EF"/>
        <path d="M80.6564 113.177L80.2825 112.972C79.6007 112.649 79.0546 112.096 78.7401 111.41C78.2735 110.208 78.2735 108.874 78.7401 107.671C79.2179 107.029 79.7462 106.426 80.3199 105.867C80.9372 104.937 81.2627 103.843 81.2547 102.726C81.2402 101.619 81.3152 100.512 81.479 99.4173C81.8716 97.5477 82.4138 95.7997 83.8347 94.6032C86.1155 92.6869 90.7053 91.5558 93.1824 93.6684C93.6311 94.061 93.7246 94.3695 94.3322 94.4255C95.07 94.4576 95.7888 94.6694 96.4261 95.0425C97.2487 95.5099 98.2395 96.1829 98.118 97.2579C97.9009 98.1791 97.3475 98.9859 96.5663 99.5201C96.2769 99.6658 95.9554 99.7365 95.6315 99.7257C94.6968 99.7257 93.8554 99.1088 92.9019 99.2116C92.6687 99.2381 92.4429 99.3104 92.2376 99.4243C92.0323 99.5382 91.8514 99.6914 91.7054 99.8753C91.3595 100.296 91.4063 100.894 91.0043 101.287C90.3593 101.922 89.3685 101.866 88.602 102.287C88.4678 102.342 88.3537 102.436 88.2748 102.558C88.2098 102.682 88.1806 102.821 88.1906 102.96V104.166C87.8167 103.998 87.7701 103.465 87.4616 103.231C87.2988 103.117 87.102 103.061 86.9033 103.073C86.7046 103.084 86.5158 103.163 86.3679 103.297C86.0858 103.581 85.9023 103.948 85.8443 104.344C85.7919 104.833 85.8626 105.328 86.0501 105.783C86.3025 106.606 86.9849 107.344 87.5738 106.802C87.9851 107.41 87.5737 108.419 87.5176 109.102C87.4615 109.784 87.5177 110.719 88.0224 111.074C88.5272 111.429 89.2282 111.149 89.2563 112.009C89.2193 112.393 89.0466 112.752 88.7692 113.021C88.4917 113.29 88.1276 113.451 87.742 113.476C86.9692 113.499 86.1999 113.365 85.4799 113.084C84.7527 112.796 83.9509 112.757 83.199 112.972C82.7962 113.205 82.3701 113.396 81.9277 113.542C81.4746 113.57 81.0256 113.441 80.6564 113.177Z" fill="#5045AF"/>
        <path d="M59.811 120.805C59.811 120.805 57.1096 118.646 56.9226 118.057C56.7357 117.468 56.6983 116.832 56.4833 116.253C56.3131 115.832 56.0598 115.449 55.7386 115.128C55.4174 114.807 55.0349 114.553 54.6137 114.383C53.9407 114.112 54.2772 113.878 53.5481 114.019C52.4544 114.234 51.9402 115.299 51.5009 116.141C49.6313 119.711 54.7352 120.384 54.9222 120.394C55.2961 120.394 57.1937 122.562 57.1937 122.562" fill="#FFB9B0"/>
        <path d="M51.2861 119.086L53.1557 120.32C53.2136 120.357 53.2786 120.382 53.3466 120.394C53.4145 120.406 53.4841 120.404 53.5513 120.388C53.6184 120.372 53.6817 120.343 53.7374 120.303C53.7931 120.262 53.8401 120.21 53.8755 120.151L57.9324 113.355L55.53 112L51.1739 118.375C51.1342 118.429 51.1055 118.49 51.0896 118.555C51.0737 118.62 51.071 118.688 51.0814 118.754C51.0918 118.82 51.1153 118.883 51.1504 118.94C51.1856 118.997 51.2317 119.047 51.2861 119.086Z" fill="#8484EF"/>
        <path d="M44.6857 104.652L45.3213 105.091L47.8919 101.352L47.2563 100.922C46.7691 100.64 46.1907 100.56 45.6453 100.7C45.1 100.84 44.6313 101.188 44.3398 101.67C43.9948 102.113 43.8377 102.674 43.9024 103.232C43.9671 103.789 44.2484 104.299 44.6857 104.652Z" fill="#C4C4F7"/>
        <path d="M42.3398 109.223L54.1648 111.018L56.8569 107.083L50.8931 96.7441L42.3398 109.223Z" fill="#C4C4F7"/>
        <path d="M43.0874 109.383L54.1645 111.019L55.2395 109.449L45.9198 105.261L43.0874 109.383Z" fill="#BBBBF2"/>
        <path d="M59.026 113.917C59.3687 114.116 59.7757 114.171 60.1589 114.071C60.5421 113.972 60.8705 113.725 61.0731 113.385L61.5498 112.693C61.7916 112.382 61.9016 111.988 61.8562 111.596C61.8107 111.205 61.6134 110.847 61.3068 110.599C60.964 110.401 60.557 110.345 60.1738 110.445C59.7906 110.545 59.4622 110.791 59.2596 111.132L58.7829 111.823C58.5388 112.134 58.4273 112.528 58.4729 112.921C58.5184 113.313 58.7172 113.671 59.026 113.917Z" fill="#C4C4F7"/>
        <path d="M59.2593 111.113L58.7825 111.805C58.6446 111.953 58.5402 112.129 58.4765 112.321C58.4129 112.513 58.3915 112.716 58.4139 112.917C58.4362 113.118 58.5017 113.312 58.6059 113.485C58.7101 113.658 58.8507 113.807 59.0177 113.921C59.1848 114.035 59.3744 114.111 59.5738 114.145C59.7731 114.178 59.9774 114.169 60.1726 114.116C60.3678 114.063 60.5493 113.969 60.7047 113.84C60.8601 113.71 60.9856 113.549 61.0727 113.366L61.5494 112.675C61.5871 112.621 61.6215 112.564 61.6524 112.506C61.4328 112.793 61.1154 112.99 60.7605 113.059C60.4056 113.128 60.0378 113.064 59.7267 112.88C59.4395 112.662 59.2446 112.344 59.1806 111.989C59.1165 111.635 59.188 111.269 59.3808 110.964C59.3353 111.009 59.2945 111.06 59.2593 111.113Z" fill="#BBBBF2"/>
        <path d="M41.9572 109.607C42.157 109.723 42.3948 109.756 42.6188 109.698C42.8428 109.641 43.035 109.497 43.1537 109.298L51.2675 97.4828C51.3387 97.3919 51.3914 97.288 51.4225 97.1768C51.4535 97.0656 51.4622 96.9494 51.4484 96.8348C51.4345 96.7203 51.3981 96.6095 51.3414 96.509C51.2847 96.4084 51.2089 96.3201 51.118 96.2489C50.917 96.1332 50.6784 96.1018 50.4542 96.1613C50.2301 96.2208 50.0386 96.3666 49.9215 96.5667L41.8076 108.382C41.7366 108.472 41.6842 108.576 41.6533 108.686C41.6224 108.796 41.6136 108.912 41.6275 109.026C41.6414 109.139 41.6777 109.249 41.7343 109.349C41.7909 109.449 41.8666 109.536 41.9572 109.607Z" fill="#A4A4F7"/>
        <path d="M51.3334 96.4736L44.575 106.317C44.4484 106.509 44.2553 106.648 44.0328 106.706C43.8103 106.765 43.574 106.739 43.3691 106.635C43.2888 106.572 43.2193 106.496 43.1635 106.41L41.808 108.383C41.7349 108.47 41.6808 108.572 41.6493 108.682C41.6177 108.792 41.6093 108.907 41.6246 109.02C41.6399 109.133 41.6785 109.242 41.738 109.339C41.7976 109.436 41.8768 109.52 41.9706 109.585C42.0643 109.65 42.1705 109.695 42.2826 109.717C42.3946 109.739 42.5099 109.737 42.6212 109.712C42.7326 109.687 42.8375 109.639 42.9292 109.571C43.021 109.503 43.0975 109.417 43.1541 109.317L51.2679 97.5019C51.3784 97.3558 51.4435 97.1804 51.4552 96.9976C51.4668 96.8148 51.4245 96.6326 51.3334 96.4736Z" fill="#8484EF"/>
        <path d="M55.2116 113.084L55.9687 113.607C56.5789 113.962 57.3038 114.062 57.9873 113.887C58.6709 113.713 59.2585 113.276 59.6237 112.673L60.3808 111.56C60.5984 111.286 60.7598 110.972 60.8555 110.636C60.9513 110.299 60.9794 109.947 60.9386 109.599C60.8978 109.252 60.7887 108.916 60.6176 108.611C60.4465 108.306 60.2167 108.037 59.9415 107.821L59.1751 107.298C58.5663 106.944 57.8428 106.844 57.1608 107.019C56.4788 107.194 55.8929 107.63 55.5294 108.233L54.7722 109.345C54.5546 109.619 54.3933 109.933 54.2976 110.27C54.2019 110.606 54.1736 110.958 54.2144 111.306C54.2552 111.653 54.3644 111.989 54.5355 112.294C54.7066 112.6 54.9364 112.868 55.2116 113.084Z" fill="#A4A4F7"/>
        <path d="M54.8103 109.29L53.6699 110.954C53.5329 111.129 53.4708 111.351 53.4971 111.572C53.5234 111.792 53.6359 111.994 53.8101 112.132L57.3716 114.562C57.5652 114.675 57.7956 114.707 58.0126 114.651C58.2297 114.595 58.4159 114.456 58.5307 114.263L59.6618 112.599C59.5488 112.791 59.3642 112.93 59.1487 112.986C58.9331 113.042 58.7042 113.011 58.512 112.898L54.9505 110.477C54.7757 110.338 54.663 110.135 54.6367 109.912C54.6105 109.69 54.6728 109.467 54.8103 109.29Z" fill="#8484EF"/>
        <path d="M51.6875 117.375C51.6045 116.888 51.6187 116.39 51.7294 115.909C51.8401 115.427 52.0451 114.973 52.3325 114.571C52.534 114.316 52.7561 114.079 52.9962 113.86C53.104 113.736 53.2426 113.643 53.3981 113.589C53.6989 113.553 54.0028 113.623 54.2581 113.786C54.564 113.942 54.8127 114.19 54.9686 114.496C55.0331 114.715 55.0155 114.949 54.9191 115.156C54.8227 115.363 54.6541 115.527 54.4451 115.618C54.4049 115.643 54.3725 115.678 54.3516 115.721C54.3516 115.786 54.3516 115.861 54.417 115.936C54.6053 116.21 54.7003 116.538 54.6881 116.87C54.6802 116.944 54.6575 117.015 54.6212 117.079C54.585 117.143 54.5361 117.199 54.4775 117.243C54.4189 117.288 54.3517 117.32 54.2803 117.338C54.2088 117.356 54.1345 117.359 54.0618 117.347C53.9215 117.347 53.7347 117.263 53.6879 117.347C53.6737 117.386 53.6737 117.429 53.6879 117.469C53.7067 117.606 53.6973 117.745 53.6603 117.879C53.6234 118.012 53.5596 118.137 53.473 118.245C53.4277 118.3 53.3652 118.34 53.2953 118.357H53.1177C52.8978 118.29 52.6873 118.196 52.4914 118.076" fill="#FFB9B0"/>
        <path d="M56.9036 118.198C56.8476 117.805 56.7074 117.151 56.6887 116.749C56.6739 116.203 56.5234 115.669 56.2508 115.196C55.9783 114.723 55.5923 114.325 55.1275 114.038C55.0536 113.987 54.9652 113.96 54.8752 113.963C54.8204 113.978 54.7713 114.009 54.7346 114.053C54.6978 114.096 54.6751 114.15 54.6695 114.206C54.6573 114.32 54.6734 114.436 54.7163 114.543C54.8285 114.889 55.3145 115.534 55.165 115.879C54.9137 116.405 54.7827 116.98 54.7817 117.562C54.7817 118.076 55.0341 118.908 55.6417 118.992" fill="#FFB9B0"/>
        <path d="M43.1904 91.0234L45.5741 96.6414" stroke="#C4C4F7" stroke-width="0.701083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M38.2271 96.0146L43.1066 99.6696" stroke="#C4C4F7" stroke-width="0.701083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M35.0859 102.812L41.1433 103.41" stroke="#C4C4F7" stroke-width="0.701083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M229.351 43.8083C229.351 43.8083 227.173 42.2659 227.098 41.6209C226.589 40.2797 225.887 39.02 225.014 37.8818C223.913 37.1884 222.702 36.686 221.434 36.3955C220.892 36.3207 217.76 36.5825 218.153 37.0125C218.545 37.4425 220.564 37.2836 220.957 37.5172L221.35 37.7509C221.35 37.7509 220.957 38.2744 221.116 38.5922C221.275 38.9101 220.452 39.4055 220.761 39.9477C221.069 40.4898 220.639 40.4524 220.91 41.0227C221.378 41.9574 222.546 42.4809 222.864 42.2285C223.098 42.78 223.986 43.9205 224.827 43.8363C225.668 43.7522 227.697 45.7059 227.697 45.7059" fill="#FFB9B0"/>
        <path d="M262.564 32.8525C262.639 34.3108 262.564 36.8627 262.947 37.2366C263.33 37.6106 264.723 37.5545 264.723 37.5545C264.723 37.5545 259.891 43.6679 259.461 43.3314C259.031 42.9949 253.31 38.3397 254.59 38.0966C255.871 37.8536 257.133 37.9097 257.096 37.246C257.058 36.5823 257.021 35.9186 256.993 35.2549" fill="#FFB9B0"/>
        <path d="M268.07 65.4855C268.07 65.4855 258.648 66.5605 256.47 66.5979C254.292 66.6353 254.413 65.5136 254.413 65.5136C254.292 62.1893 253.98 58.8747 253.478 55.5863C253.058 53.8008 252.871 53.1465 252.544 51.4545C251.73 47.7154 251.768 46.9676 250.908 42.3311L256.516 37.7975C256.765 38.3595 257.182 38.831 257.709 39.1472C258.236 39.4635 258.848 39.6091 259.461 39.5642C260.287 39.6406 261.117 39.4765 261.852 39.0917C262.587 38.7069 263.194 38.1179 263.602 37.3955L271.286 42.49L272.006 54.8945L271.071 65.1116" fill="#FCA70C"/>
        <path d="M262.667 31.5261C262.498 32.264 262.276 32.9888 262.003 33.6948C261.848 34.2505 261.503 34.7344 261.029 35.0623C260.554 35.3902 259.979 35.5414 259.405 35.4895L256.516 35.555C255.732 35.676 254.931 35.5065 254.263 35.0782C253.784 34.4858 253.523 33.7464 253.525 32.9843C253.357 32.0496 253.216 31.1615 253.114 30.2454C252.908 28.4397 252.824 26.6221 252.861 24.805C252.855 24.5572 252.877 24.3095 252.927 24.0666C252.936 23.9652 252.979 23.8698 253.048 23.7955C253.148 23.7166 253.268 23.668 253.394 23.6553C255.199 23.3721 257.027 23.2563 258.853 23.3094C260.145 23.2315 261.429 23.5583 262.527 24.2442C263.477 25.1406 264.155 26.2879 264.48 27.5533C264.555 27.7496 264.602 28.0113 264.48 28.1328" fill="#FFB9B0"/>
        <path d="M241.522 99.1183C241.654 99.9678 241.842 100.808 242.083 101.633C242.336 102.278 244.439 101.689 244.57 101.268C244.458 100.098 244.239 98.94 243.916 97.8096" fill="#ED9C80"/>
        <path d="M245.308 101.765C245.485 102.457 245.719 103.784 245.719 103.784L243.616 104.719L243.27 104.392C242.944 104.776 242.547 105.093 242.101 105.327C241.447 105.594 240.763 105.782 240.064 105.888C238.194 106.084 236.885 105.738 236.773 105.486C236.315 104.42 238.755 104.14 239.232 104.009C239.787 103.864 240.294 103.574 240.7 103.168C241.106 102.762 241.396 102.255 241.541 101.7C241.653 101.139 241.541 101.045 241.821 101.064C241.922 101.067 242.021 101.09 242.112 101.131C242.204 101.173 242.286 101.233 242.354 101.307C242.533 101.514 242.774 101.659 243.041 101.721C243.308 101.783 243.588 101.76 243.84 101.653C244.149 101.532 244.411 101.316 244.588 101.036C244.766 101.074 244.929 101.164 245.057 101.293C245.185 101.423 245.272 101.587 245.308 101.765Z" fill="#38287C"/>
        <path d="M250.813 74.6557C247.267 75.2816 243.645 75.3667 240.073 74.9081C240.54 76.2355 241.681 77.9461 242.111 79.825C243.55 86.0132 244.046 94.3327 244.41 99.4553C244.41 99.4553 243.718 100.773 241.512 99.7731C238.222 91.6406 232.473 75.1605 232.529 71.5803C232.595 67.0373 243.962 65.9716 252.281 64.8125" fill="#453087"/>
        <path d="M265.322 65.4955C267.94 67.365 250.815 74.6095 250.815 74.6095C248.968 74.975 247.089 75.1535 245.206 75.1424C245.018 74.812 244.88 74.4562 244.794 74.0861C244.692 73.5813 244.794 73.0671 244.654 72.5624C244.547 72.0804 244.339 71.6265 244.044 71.2303C243.749 70.8341 243.374 70.5044 242.944 70.2628C242.08 69.7677 241.127 69.4499 240.139 69.328C238.024 69.1108 235.895 69.0732 233.773 69.2159C238.924 65.4207 249.459 64.9252 254.339 64.6074C255.329 66.1872 264.948 65.2244 265.322 65.4955Z" fill="#38287C"/>
        <path d="M246.916 103.446C246.901 104.308 246.938 105.169 247.028 106.026C247.168 106.699 249.337 106.484 249.543 106.101C249.654 104.926 249.654 103.743 249.543 102.567" fill="#ED9C80"/>
        <path d="M250.216 106.747C250.272 107.457 250.272 108.804 250.272 108.804L248.038 109.346L247.758 108.962C247.37 109.282 246.926 109.526 246.449 109.682C245.758 109.831 245.052 109.897 244.346 109.878C242.476 109.757 241.233 109.187 241.167 108.944C240.906 107.813 243.355 107.953 243.85 107.915C244.421 107.868 244.97 107.67 245.439 107.341C245.909 107.013 246.283 106.566 246.524 106.046C246.729 105.522 246.608 105.401 246.907 105.466C247.104 105.511 247.275 105.632 247.384 105.803C247.528 106.036 247.742 106.219 247.994 106.326C248.247 106.434 248.527 106.46 248.795 106.401C249.119 106.335 249.414 106.168 249.637 105.924C249.802 105.992 249.944 106.105 250.046 106.251C250.149 106.397 250.208 106.569 250.216 106.747Z" fill="#38287C"/>
        <path d="M271.042 65.1113C267.182 65.4488 263.306 65.5737 259.432 65.4852C257.946 65.4852 246.738 67.5885 245.672 69.4861C243.906 72.6176 246.822 90.8738 246.551 103.951C246.551 103.951 247.897 105.073 250.29 104.017C250.879 97.5948 254.674 82.4421 252.057 75.3658C258.236 76.1884 263.274 77.0017 268.883 75.1415C269.58 74.9072 270.209 74.5049 270.715 73.9703C271.22 73.4356 271.586 72.7849 271.781 72.0754C272.314 69.7364 272.055 67.2864 271.042 65.1113Z" fill="#453087"/>
        <path d="M256.469 37.7969L254.862 37.8903C253.729 37.9518 252.609 38.1656 251.534 38.526C250.01 39.0869 248.926 40.5077 247.682 41.6201C246.439 42.7325 240.279 48.6029 239.129 48.8179C237.98 49.0329 227.809 42.0781 227.809 42.0781L225.332 44.5553C225.332 44.5553 236.353 54.7444 239.643 54.7631C241.233 54.7631 250.038 50.1359 252.852 48.3412L256.469 37.7969Z" fill="#FCA70C"/>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M239.662 52.8099C237.4 52.9314 227.015 45.0045 225.809 44.0791L225.332 44.5558C225.332 44.5558 236.353 54.7449 239.643 54.7636C240.971 54.7636 247.299 51.5573 251.001 49.4447L251.936 48.8839L251.356 45.4626C251.356 45.4626 242.074 52.6697 239.662 52.8099Z" fill="#CE4A30"/>
        </g>
        <path d="M229.089 67.1782H254.328L249.804 52.4275C249.682 52.0281 249.434 51.679 249.097 51.4328C248.76 51.1867 248.352 51.0568 247.934 51.0627H225.836C225.64 51.063 225.446 51.1094 225.27 51.198C225.094 51.2866 224.942 51.4151 224.825 51.5731C224.708 51.7311 224.629 51.9143 224.595 52.1081C224.562 52.3019 224.574 52.5009 224.631 52.6892L229.089 67.1782Z" fill="#A4A4F7"/>
        <path d="M228.772 67.1782H253.628L249.104 52.4274C248.981 52.0288 248.732 51.6806 248.395 51.4346C248.059 51.1887 247.651 51.0582 247.234 51.0626H225.136C224.939 51.0624 224.746 51.1086 224.57 51.1972C224.395 51.2859 224.242 51.4146 224.126 51.573C224.009 51.7313 223.932 51.9149 223.899 52.1088C223.867 52.3027 223.881 52.5015 223.939 52.6891L228.268 66.8229C228.302 66.9288 228.37 67.0207 228.461 67.0847C228.552 67.1488 228.661 67.1816 228.772 67.1782Z" fill="#7A74D1"/>
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <g style="mix-blend-mode:multiply" opacity="0.25">
        <path d="M267.575 44.9114C266.611 49.587 265.986 54.3262 265.705 59.092C261.293 60.8026 259.498 63.3545 255.189 65.2708C255.189 65.2708 251.777 64.71 251.45 65.9906C251.244 66.7384 255.432 67.3273 255.432 67.3273C259.433 67.3273 269.977 64.4202 270.697 64.2239C271.417 64.0276 273.501 50.6229 273.567 43.3503C273.693 42.3156 273.459 41.2689 272.903 40.3871C272.536 39.9887 272.09 39.6705 271.594 39.4523C269.105 38.1608 266.35 37.4663 263.546 37.4238" fill="#CE4A30"/>
        </g>
        </g>
        <path d="M255.816 66.1491C255.54 65.8872 255.208 65.6915 254.845 65.5765C254.482 65.4615 254.098 65.4304 253.722 65.4854C253.156 65.5039 252.591 65.5507 252.03 65.6256C251.777 65.6256 251.488 65.7191 251.46 65.9902C251.431 66.2613 252.693 65.9902 253.329 66.1865C252.03 66.2956 250.725 66.3299 249.422 66.2893C249.274 66.2612 249.121 66.2612 248.973 66.2893C248.901 66.3142 248.838 66.3607 248.792 66.4225C248.747 66.4844 248.722 66.5586 248.721 66.6352C248.742 66.7503 248.805 66.8535 248.898 66.9249C249.288 67.2276 249.754 67.4154 250.244 67.4671C252.228 67.8804 254.29 67.6983 256.171 66.9436" fill="#FFB9B0"/>
        <path d="M267.304 44.7992C267.201 50.8753 266.762 53.6516 266.883 59.7556C263.836 60.41 259.62 63.8593 255.301 65.7849C255.161 66.3116 255.211 66.871 255.442 67.3647C255.778 67.4862 269.996 64.0182 271.137 62.9806C272.277 61.943 273.492 50.6603 273.576 43.3503C273.703 42.3156 273.469 41.2689 272.913 40.3871C272.545 39.9887 272.1 39.6705 271.604 39.4523C269.115 38.1608 266.359 37.4663 263.556 37.4238" fill="#FCA70C"/>
        <path opacity="0.45" d="M241.597 59.3428C241.691 60.6421 240.158 61.6891 238.185 61.6891C236.213 61.6891 234.53 60.6421 234.446 59.3428C234.362 58.0435 235.876 57.0059 237.858 57.0059C239.84 57.0059 241.504 58.0528 241.597 59.3428Z" stroke="#A4A4F7" stroke-width="0.934777" stroke-miterlimit="10"/>
        <path d="M240.233 59.4263C240.261 58.7092 239.267 58.0883 238.013 58.0395C236.76 57.9907 235.721 58.5324 235.693 59.2495C235.665 59.9665 236.659 60.5874 237.912 60.6362C239.166 60.6851 240.205 60.1433 240.233 59.4263Z" fill="#A4A4F7"/>
        <path d="M258.226 27.6459C258.226 27.4216 257.675 27.3748 257.17 27.4403C256.439 27.4891 255.718 27.6401 255.029 27.889C254.758 28.104 254.768 28.2535 254.627 28.2722C254.487 28.2909 254.468 28.1413 254.141 28.0011C253.613 27.8987 253.071 27.8987 252.543 28.0011C252.029 28.0666 251.552 28.1787 251.608 28.4124C251.609 28.6579 251.681 28.8979 251.814 29.1042C251.916 29.1696 251.916 29.1603 252.309 30.0389C252.34 30.1668 252.397 30.2868 252.476 30.3912C252.556 30.4955 252.658 30.5818 252.773 30.6444C252.889 30.7071 253.016 30.7446 253.147 30.7546C253.278 30.7647 253.41 30.7469 253.534 30.7026C254.366 30.5157 254.412 29.5715 254.468 29.1696C254.524 28.7676 254.599 28.7209 254.721 28.7022C254.842 28.6835 254.936 28.7022 255.095 29.0948C255.254 29.4874 255.375 30.31 256.226 30.3568C257.347 30.4222 257.824 30.0389 257.983 29.3192C258.189 28.3844 258.142 28.3844 258.226 28.2629C258.505 28.2598 258.783 28.2817 259.058 28.3283V28.0385C258.771 27.9288 258.493 27.7975 258.226 27.6459ZM254.169 29.5996C254.193 29.8433 254.12 30.0866 253.966 30.2772C253.812 30.4677 253.59 30.5903 253.347 30.6185C252.833 30.6839 252.599 30.4129 252.318 29.7585C252.121 29.396 252.061 28.9744 252.15 28.5713C252.244 28.4124 252.515 28.4218 253.15 28.3844C253.786 28.347 254.001 28.3283 254.085 28.6087C254.157 28.9338 254.185 29.267 254.169 29.5996ZM257.806 29.0668C257.703 29.7679 257.441 30.1044 256.74 30.1885C255.749 30.31 255.553 29.9174 255.413 29.4407C255.307 29.1393 255.244 28.8247 255.226 28.5059C255.226 28.2068 255.459 28.1694 256.086 28.0479C256.712 27.9263 257.553 27.7861 257.675 27.917C257.852 28.273 257.898 28.68 257.806 29.0668Z" fill="#483A99"/>
        <path d="M264.967 26.094C264.911 25.5897 264.821 25.0899 264.696 24.5983C264.539 23.9546 264.251 23.3505 263.848 22.8243C263.446 22.298 262.939 21.8612 262.359 21.5416C260.724 20.7296 258.849 20.5434 257.087 21.0181C256.152 21.2892 255.759 21.794 255.217 21.7847C254.751 21.6921 254.299 21.5414 253.871 21.336C250.132 19.9806 250.73 23.6729 251.132 24.6918C251.31 25.1405 252.16 25.4677 252.272 25.6266C252.368 26.1201 252.428 26.62 252.45 27.1222C252.48 27.2454 252.537 27.3601 252.618 27.4574C252.699 27.5547 252.802 27.6319 252.917 27.6831C252.917 27.6831 252.917 26.4959 252.917 26.3277C252.924 26.1136 252.977 25.9035 253.072 25.7116C253.167 25.5196 253.303 25.3504 253.469 25.2153C253.891 24.9993 254.352 24.869 254.824 24.832C255.776 24.8572 256.72 25.0145 257.629 25.2994C258.339 25.8977 258.563 27.2811 258.713 28.1038C258.807 28.4777 258.909 28.7301 259.059 28.7301C259.18 27.6083 259.564 27.5803 260.237 27.9635C260.433 28.1614 260.584 28.3989 260.681 28.66C260.778 28.9212 260.817 29.2001 260.798 29.4779C260.828 29.8392 260.733 30.1999 260.529 30.4995C260.324 30.799 260.023 31.0192 259.676 31.1231C259.405 31.1792 260.209 32.7029 260.611 33.0861C260.728 33.2159 260.86 33.3319 261.003 33.432C261.148 33.5074 261.302 33.5639 261.461 33.6002L262.003 33.7498C262.239 33.8377 262.495 33.8571 262.742 33.8059C263.135 33.6937 263.35 33.2731 263.527 32.8711C263.826 32.2635 264.163 31.6653 264.406 31.0016C264.967 29.4305 265.159 27.7512 264.967 26.094Z" fill="#483A99"/>
        <path d="M332.972 82.0211H285.242L290.963 77.7866V57.128C290.963 56.3 291.292 55.5058 291.877 54.9203C292.463 54.3348 293.257 54.0059 294.085 54.0059H333.009C333.837 54.0083 334.629 54.3381 335.214 54.923C335.799 55.508 336.129 56.3007 336.131 57.128V78.899C336.131 79.3121 336.049 79.7212 335.89 80.1025C335.731 80.4837 335.498 80.8296 335.204 81.12C334.91 81.4104 334.561 81.6396 334.178 81.7943C333.795 81.949 333.385 82.0261 332.972 82.0211Z" fill="#D5D2FF"/>
        <path opacity="0.45" d="M331.934 58.0361H310.107V60.1207H331.934V58.0361Z" fill="white"/>
        <path opacity="0.45" d="M331.934 61.1572H310.107V63.2418H331.934V61.1572Z" fill="white"/>
        <path opacity="0.45" d="M331.934 64.2793H310.107V66.3638H331.934V64.2793Z" fill="white"/>
        <path opacity="0.45" d="M331.934 67.3174H310.107V69.4019H331.934V67.3174Z" fill="white"/>
        <path opacity="0.45" d="M325.335 70.9531H310.107V73.0377H325.335V70.9531Z" fill="white"/>
        <path d="M305.228 58.0361H297.852C296.102 58.0361 294.684 59.4549 294.684 61.205V70.8987C294.684 72.6488 296.102 74.0676 297.852 74.0676H305.228C306.978 74.0676 308.397 72.6488 308.397 70.8987V61.205C308.397 59.4549 306.978 58.0361 305.228 58.0361Z" fill="#A9ADFF"/>
        <path opacity="0.45" d="M308.397 76.0674H294.684V77.7874H308.397V76.0674Z" fill="white"/>
        <path d="M155.523 53.1548H216.386L209.095 47.7612V14.4831C209.095 13.4286 208.677 12.4172 207.932 11.6706C207.187 10.9241 206.177 10.5035 205.122 10.501H155.523C155.001 10.501 154.483 10.6041 154.001 10.8043C153.518 11.0045 153.08 11.298 152.711 11.6679C152.342 12.0378 152.049 12.4768 151.85 12.9599C151.651 13.443 151.549 13.9606 151.55 14.4831V49.1914C151.553 50.2434 151.972 51.2515 152.717 51.9945C153.462 52.7376 154.471 53.1548 155.523 53.1548Z" fill="#D5D2FF"/>
        <g opacity="0.45">
        <path opacity="0.45" d="M156.83 19.3369L184.64 19.3369V16.6728L156.83 16.6728V19.3369Z" fill="white"/>
        <path opacity="0.45" d="M156.83 23.3193L184.64 23.3193V20.6552L156.83 20.6552V23.3193Z" fill="white"/>
        <path opacity="0.45" d="M156.83 27.291L184.64 27.291V24.6269L156.83 24.6269V27.291Z" fill="white"/>
        <path opacity="0.45" d="M156.83 31.1611L184.64 31.1611V28.497L156.83 28.497V31.1611Z" fill="white"/>
        <path opacity="0.45" d="M156.83 35.041H184.64V32.3769H156.83V35.041Z" fill="white"/>
        <path opacity="0.45" d="M156.83 42.0791H184.64V39.415H156.83V42.0791Z" fill="white"/>
        <path opacity="0.45" d="M156.831 46.2666L204.299 46.2666V43.6025L156.831 43.6025V46.2666Z" fill="white"/>
        </g>
        <path d="M190.867 37.1064H200.28C202.505 37.1064 204.309 35.3027 204.309 33.0776V20.7105C204.309 18.4854 202.505 16.6816 200.28 16.6816H190.867C188.642 16.6816 186.838 18.4854 186.838 20.7105V33.0776C186.838 35.3027 188.642 37.1064 190.867 37.1064Z" fill="#C4C4F7"/>
        <path opacity="0.45" d="M186.838 41.8447H204.309V39.648H186.838V41.8447Z" fill="white"/>
        <path d="M296.272 228.428C311.083 228.428 323.091 227.164 323.091 225.605C323.091 224.046 311.083 222.782 296.272 222.782C281.46 222.782 269.453 224.046 269.453 225.605C269.453 227.164 281.46 228.428 296.272 228.428Z" fill="#F4EDF6"/>
        <path d="M281.886 221.594C281.154 222.535 280.187 223.267 279.082 223.716C277.596 224.38 274.408 224.697 276.184 225.286C277.775 225.679 279.436 225.698 281.036 225.342C282.12 225.197 283.213 225.131 284.307 225.146C285.924 225.146 285.934 225.043 285.719 223.22C285.42 220.706 282.615 220.173 281.886 221.594Z" fill="#9AA3F4"/>
        <path d="M311.164 220.687C311.07 221.191 311.79 222.724 310.51 223.846C309.229 224.968 306.303 226.155 308.238 226.202C309.876 226.258 311.506 225.955 313.015 225.314C313.949 224.828 313.211 224.061 314.436 223.678C316.043 223.173 315.707 221.986 314.875 221.257C313.8 218.854 311.416 219.051 311.164 220.687Z" fill="#9AA3F4"/>
        <path d="M305.003 169.637C305.003 169.637 308.854 158.308 297.927 148.988C297.927 148.988 292.075 151.316 287.383 151.596C285.514 151.762 283.633 151.721 281.774 151.475C281.774 151.475 281.503 161.589 281.503 166.964L305.003 169.637Z" fill="#4A41A3"/>
        <path d="M280.568 188.446C280.362 183.379 285.85 184.043 287.869 187.829C292.057 195.643 286.326 213.656 285.569 222.191C284.653 222.584 283.629 222.647 282.671 222.369C282.299 222.154 282.004 221.827 281.83 221.434C281.989 210.974 280.83 193.858 280.568 188.446Z" fill="#4A41A3"/>
        <path d="M287.719 189.213C287.093 191.279 280.082 193.448 280.652 185.923C280.904 182.585 281.587 164.161 281.699 162.385C281.811 160.609 282.129 158.01 282.989 157.711C287.28 156.085 294.87 161.703 293.748 168.368C292.991 172.808 289.028 184.96 287.719 189.213Z" fill="#4A41A3"/>
        <path d="M298.75 192.081C297.058 187.305 302.554 186.473 305.676 189.408C312.986 196.297 313.276 212.525 315.024 220.901C314.062 221.496 312.958 221.819 311.827 221.835C311.659 221.832 311.493 221.79 311.343 221.714C311.192 221.638 311.061 221.529 310.958 221.396C308.023 211.338 300.563 197.185 298.75 192.081Z" fill="#4A41A3"/>
        <path d="M305.975 190.567C306.171 192.727 300.366 197.176 298.029 189.988C297.01 186.8 292.645 167.965 292.093 166.273C291.542 164.581 291.701 161.075 292.374 160.449C295.739 157.336 303.348 160.075 304.806 166.684C305.807 171.049 305.582 186.062 305.975 190.567Z" fill="#4A41A3"/>
        <path d="M275.894 108.054C276.09 107.849 276.296 107.634 276.474 107.409C277.857 105.633 278.053 104.063 279.343 102.016C279.614 101.4 279.926 100.803 280.278 100.23C280.864 99.5383 281.57 98.9586 282.363 98.5194C282.961 98.211 282.447 99.529 282.447 99.529C282.447 99.529 282.952 98.7905 283.447 98.8466C283.634 98.8466 283.671 99.987 283.671 100.183C283.696 101.736 283.337 103.271 282.624 104.652C282.427 104.958 282.305 105.307 282.269 105.67C282.722 105.595 283.141 105.387 283.475 105.072C283.643 104.918 283.844 104.804 284.063 104.741C284.282 104.678 284.513 104.666 284.737 104.708C284.675 104.89 284.594 105.066 284.494 105.231C284.053 106.32 283.277 107.24 282.279 107.858C281.652 108.148 280.932 108.204 280.316 108.531C279.203 109.12 278.67 110.485 277.511 111.036" fill="#F2A181"/>
        <path d="M285.85 100.34L282.556 98.962C282.247 98.8325 281.891 98.9785 281.761 99.2881L278.832 106.291C278.702 106.6 278.848 106.956 279.158 107.086L282.452 108.464C282.762 108.593 283.118 108.447 283.247 108.137L286.176 101.135C286.306 100.825 286.16 100.47 285.85 100.34Z" fill="#5E5DBB"/>
        <path d="M285.978 100.933L282.278 99.3857L279.245 106.638L282.944 108.186L285.978 100.933Z" fill="#B4B4DB"/>
        <path d="M286.009 115.215C284.199 115.342 282.407 115.655 280.662 116.15C276.857 117.291 275.549 119.048 274.23 119.777C276.782 116.534 279.634 109.663 279.634 109.663C279.634 109.663 276.203 106.859 275.801 107.504C275.399 108.149 261.003 125.031 261.452 127.76C261.639 128.882 263.322 129.994 264.78 129.948C268.874 129.807 273.614 128.321 278.587 126.695C280.774 125.975 281.447 127.517 281.447 127.517" fill="#7A74D1"/>
        <path d="M274.231 119.748C274.231 119.748 272.418 123.413 271.875 123.646C271.333 123.88 273.193 122.889 274.222 121.833C275.159 120.646 275.97 119.365 276.643 118.01C275.797 118.529 274.991 119.111 274.231 119.748Z" fill="#6960C4"/>
        <path d="M279.924 102.036C280.083 102.188 280.177 102.396 280.185 102.616C280.381 103.415 280.355 104.252 280.11 105.037C279.894 105.515 279.575 105.939 279.176 106.28C278.722 106.674 278.223 107.013 277.689 107.29" fill="#F2A181"/>
        <path d="M286.093 95.9502C285.158 96.2306 284.625 97.4739 284.298 98.3245C283.927 99.3565 283.686 100.43 283.578 101.521C283.433 102.27 283.487 103.044 283.737 103.765C283.902 104.118 284.113 104.448 284.363 104.746C284.486 104.929 284.637 105.09 284.812 105.223C284.901 105.289 285.004 105.333 285.112 105.352C285.221 105.372 285.332 105.366 285.438 105.335C285.577 105.251 285.694 105.137 285.781 105.001C285.869 104.865 285.924 104.711 285.943 104.55C286.413 102.506 287.192 100.545 288.252 98.7358" fill="#170D54"/>
        <path d="M285.027 102.346C285.027 101.71 284.335 101.289 283.746 101.953C283.294 102.66 283.069 103.489 283.101 104.327C283.101 105.262 283.793 105.43 284.382 105.561" fill="#F2A181"/>
        <path d="M278.596 118.535C279.792 117.18 283.559 114.927 288.598 115.086C292.066 115.198 301.516 115.768 302.619 118.096C303.162 119.283 303.741 131.435 302.956 135.633C302.358 138.783 300.834 146.588 300.834 148.476C300.834 150.365 301.853 151.159 301.376 151.711C300.32 152.935 293.085 153.449 289.691 153.449C286.663 153.449 281.521 152.645 281.278 151.673C280.895 150.243 281.867 150.29 281.998 148.785C282.129 147.28 282.512 137.502 281.998 135.894C280.998 133.006 280.512 128.294 279.717 126.154C279.007 124.537 277.408 119.9 278.596 118.535Z" fill="#7A74D1"/>
        <path d="M300.245 128.369C300.61 129.126 303.049 133.043 303.049 133.043L303.143 133.519C303.099 134.224 303.018 134.926 302.9 135.623C302.302 138.773 300.778 146.578 300.778 148.466C300.778 150.355 301.713 151.121 301.32 151.701C300.488 152.832 286.85 155.374 287.523 152.215C287.579 151.934 298.964 143.241 299.03 142.951C299.553 140.455 299.965 138.661 300.236 136.847C300.806 132.482 299.89 127.621 300.245 128.369Z" fill="#6960C4"/>
        <path d="M285.849 110.738C285.863 112.112 285.8 113.485 285.662 114.851C285.366 115.055 285.054 115.233 284.728 115.384C284.728 115.384 285.288 118.824 286.148 118.563C287.008 118.301 292.692 115.44 292.692 115.44C292.692 115.44 291.888 115.113 291.888 114.795C291.888 114.478 292.655 108.252 292.655 108.252" fill="#FFBE9D"/>
        <path d="M289.804 96.3799C288.972 96.6042 286.785 96.1555 286.187 97.0529C285.616 98.2368 285.162 99.4734 284.831 100.745C284.383 101.942 284.083 103.232 283.756 104.484C283.336 106.092 282.634 109.046 284.205 110.392C284.527 110.731 284.956 110.949 285.42 111.009L287.663 111.439C288.833 111.754 290.062 111.779 291.244 111.514C292.786 111.009 293.627 108.906 294.048 107.466L296.31 99.259L289.804 96.3799Z" fill="#FFBE9D"/>
        <path d="M285.709 120.104C285.709 120.104 285.382 135.537 284.774 144.922L287.045 148.053L288.606 144.127C288.916 136.127 288.421 128.117 287.13 120.216L285.709 120.104Z" fill="#3B2E7A"/>
        <path d="M285.186 118.75L285.242 118.964C285.345 119.364 285.502 119.748 285.709 120.105L287.102 120.217C287.102 120.217 288.037 119.722 288.177 119.189C288.317 118.656 286.616 117.777 286.616 117.777L286.42 117.861C285.949 118.047 285.531 118.345 285.204 118.731L285.186 118.75Z" fill="#3B2E7A"/>
        <path d="M291.972 113.832C292.604 114.257 293.144 114.804 293.561 115.44C292.851 117.076 291.224 119.983 289.448 121.31C288.87 119.889 287.897 118.663 286.644 117.777C286.644 117.777 291.477 116.917 291.972 113.832Z" fill="white"/>
        <path d="M286.616 117.777C285.418 118.326 284.433 119.252 283.812 120.413C283.636 118.774 283.766 117.116 284.195 115.524C284.195 115.524 285.335 114.066 285.728 114.038C285.699 115.34 286.005 116.627 286.616 117.777Z" fill="white"/>
        <path d="M286.84 152.242C285.53 152.416 284.336 153.085 283.503 154.112C283.428 154.196 283.344 154.289 283.363 154.402C283.382 154.514 283.541 154.598 283.681 154.626C284.485 154.747 284.616 154.243 285.363 153.925C285.868 153.719 286.373 153.495 286.868 153.28" fill="#F2A181"/>
        <path d="M280.979 139.66L287.092 159.243C287.139 159.35 287.158 159.467 287.148 159.583C287.138 159.699 287.1 159.811 287.036 159.909C286.972 160.006 286.884 160.086 286.782 160.141C286.679 160.196 286.564 160.225 286.447 160.225L273.697 161.346C273.456 161.334 273.224 161.248 273.032 161.102C272.84 160.955 272.697 160.753 272.622 160.524L266.079 141.23C265.91 140.706 266.668 139.959 267.182 139.949L279.932 138.837C280.17 138.85 280.398 138.936 280.585 139.083C280.773 139.23 280.91 139.431 280.979 139.66Z" fill="#E9E6FF"/>
        <path d="M280.568 140.175L286.588 159.459C286.625 159.564 286.637 159.675 286.622 159.785C286.607 159.895 286.567 160 286.504 160.091C286.441 160.182 286.357 160.257 286.26 160.31C286.162 160.363 286.054 160.391 285.943 160.394L273.193 161.516C272.951 161.504 272.718 161.418 272.527 161.269C272.336 161.12 272.196 160.916 272.127 160.684L266.098 141.399C266.051 141.292 266.032 141.175 266.042 141.059C266.051 140.943 266.09 140.831 266.154 140.734C266.218 140.636 266.306 140.556 266.408 140.501C266.511 140.446 266.626 140.417 266.743 140.418L279.493 139.296C279.741 139.312 279.978 139.406 280.171 139.563C280.364 139.721 280.502 139.935 280.568 140.175Z" fill="#E0D9FF"/>
        <path d="M270.379 139.538L270.828 141.23L276.791 140.706L276.305 139.023L270.379 139.538Z" fill="white"/>
        <path d="M271.51 137.976L272.108 139.845L274.913 139.583L274.315 137.667C274.191 137.27 273.943 136.923 273.605 136.678C273.268 136.434 272.861 136.305 272.445 136.312C272.258 136.329 272.078 136.391 271.92 136.494C271.763 136.596 271.633 136.735 271.541 136.898C271.449 137.062 271.398 137.246 271.393 137.433C271.387 137.621 271.428 137.807 271.51 137.976ZM272.136 137.929C272.091 137.833 272.069 137.727 272.073 137.621C272.077 137.514 272.107 137.411 272.161 137.318C272.214 137.226 272.289 137.149 272.379 137.092C272.47 137.036 272.572 137.002 272.679 136.994C272.914 136.99 273.144 137.062 273.335 137.201C273.526 137.339 273.666 137.536 273.735 137.761C273.783 137.856 273.807 137.961 273.804 138.068C273.802 138.174 273.773 138.279 273.72 138.371C273.667 138.464 273.592 138.542 273.502 138.599C273.411 138.655 273.308 138.688 273.202 138.695C272.969 138.696 272.741 138.622 272.553 138.484C272.364 138.346 272.225 138.152 272.155 137.929H272.136Z" fill="white"/>
        <path d="M291.702 150.858C290.365 151.139 286.794 152.205 286.42 152.373C285.615 152.866 284.855 153.429 284.149 154.055C282.802 155.401 283.494 156.028 282.961 157.86C282.947 157.956 282.947 158.054 282.961 158.15C282.961 158.337 283.214 158.496 283.475 158.318C283.616 158.561 284.027 158.411 284.233 158.159C284.233 158.337 284.485 158.449 284.896 158.224C285.111 158.103 285.074 158.112 285.588 157.654C286.022 157.288 286.511 156.991 287.037 156.776C287.521 156.591 287.992 156.376 288.449 156.131C288.991 155.775 289.963 155.682 290.44 154.139C290.589 153.813 290.851 153.55 291.178 153.401L293.356 152.027" fill="#FFBE9D"/>
        <path d="M299.918 125.77C300.488 127.789 302.844 141.811 302.844 141.811L289.551 151.43L290.664 154.337C290.664 154.337 306.555 148.999 307.817 145.354C309.079 141.708 309.013 125.209 304.592 119.432C303.445 118.153 301.991 117.188 300.367 116.628" fill="#7A74D1"/>
        <path d="M286.289 155.906C285.917 156.218 285.567 156.555 285.242 156.916C284.975 157.305 284.661 157.66 284.308 157.972C284.527 157.543 284.797 157.141 285.112 156.776C285.276 156.589 285.465 156.426 285.672 156.289C285.859 156.131 286.074 155.981 286.289 155.906Z" fill="#F2A181"/>
        <path d="M285.55 155.047C285.333 155.254 285.142 155.486 284.98 155.739C284.811 155.979 284.661 156.232 284.531 156.496C284.291 157.056 283.977 157.581 283.597 158.057C283.802 157.477 284.083 156.944 284.335 156.402C284.474 156.122 284.654 155.864 284.868 155.636C285.055 155.397 285.287 155.197 285.55 155.047Z" fill="#F2A181"/>
        <path d="M284.709 154.308C284.554 154.558 284.379 154.795 284.186 155.018C284.018 155.248 283.888 155.504 283.803 155.775L283.167 157.411C283.197 156.815 283.343 156.231 283.597 155.691C283.71 155.408 283.875 155.148 284.083 154.925C284.307 154.71 284.522 154.532 284.709 154.308Z" fill="#F2A181"/>
        <path d="M294.87 106.026C294.347 105.718 293.935 105.4 293.608 105.895C293.431 106.167 293.319 106.531 293.01 106.625C292.852 106.647 292.692 106.608 292.561 106.516C292.431 106.424 292.341 106.285 292.309 106.129C292.269 105.812 292.311 105.49 292.43 105.194C292.636 104.521 292.851 103.848 293.057 103.175C293.337 102.539 293.39 101.826 293.206 101.156C293.06 100.837 292.838 100.558 292.561 100.343C291.662 99.6411 290.596 99.1838 289.467 99.0155C288.341 98.8425 287.223 98.6117 286.121 98.3237C285.659 98.198 285.227 97.982 284.849 97.6881C284.559 97.4289 284.327 97.1122 284.166 96.7581C284.005 96.4041 283.919 96.0205 283.915 95.6316C283.927 94.8562 284.154 94.0993 284.569 93.4442C284.654 93.7078 284.767 93.9617 284.905 94.2014C285.039 93.85 285.246 93.5313 285.513 93.2666C285.831 95.0707 293.449 94.4725 295.225 95.267C296.459 95.8185 297.478 96.2018 297.441 98.1835C297.494 98.3083 297.577 98.4176 297.684 98.5013C298.228 99.0228 298.659 99.6501 298.952 100.345C299.245 101.039 299.392 101.786 299.385 102.54C299.553 104.755 298.263 107.157 297.001 108.99C296.76 109.417 296.444 109.797 296.067 110.111C295.549 110.462 294.918 110.605 294.3 110.513C293.748 110.41 292.664 110.457 292.655 109.896C292.655 109.494 293.505 108.784 293.739 108.457" fill="#3B2E7A"/>
        <path d="M292.869 108.121C292.847 108.236 292.848 108.353 292.872 108.467C292.896 108.581 292.943 108.689 293.009 108.785C293.134 108.898 293.29 108.97 293.457 108.994C293.623 109.017 293.793 108.99 293.944 108.916C294.339 108.765 294.686 108.512 294.952 108.184C295.218 107.856 295.393 107.463 295.458 107.046C295.645 106.111 295.131 105.121 294.253 105.373C294 105.47 293.783 105.644 293.634 105.87C293.485 106.097 293.41 106.364 293.421 106.635" fill="#FFBE9D"/>
        <path d="M285.859 111.113L288.299 111.571C287.969 111.884 287.58 112.128 287.155 112.289C286.729 112.449 286.276 112.523 285.822 112.506" fill="#F2A181"/>
        <g opacity="0.3">
        <path opacity="0.3" d="M239.12 27.5709C240.273 26.1156 240.835 24.2789 240.693 22.4276C240.551 20.5763 239.717 18.8466 238.355 17.5838C236.994 16.321 235.207 15.618 233.35 15.6152C231.493 15.6125 229.704 16.3101 228.339 17.5689C226.974 18.8276 226.134 20.5548 225.987 22.4057C225.84 24.2566 226.396 26.0949 227.545 27.5536C228.694 29.0123 230.35 29.9841 232.184 30.275C234.018 30.5658 235.894 30.1543 237.437 29.1226L240.298 30.6463L239.12 27.5709Z" fill="#3939D3"/>
        </g>
        <circle cx="27.9365" cy="172.274" r="23.9456" stroke="#C4C3FA" stroke-width="7.98186"/>
        <g clip-path="url(#clip0_1071_15223)">
        <path d="M327.817 18.8884C327.387 18.4695 326.7 18.4695 326.313 18.8884C325.926 19.3073 325.883 19.9776 326.313 20.3547L328.977 22.9521C329.407 23.371 330.095 23.371 330.525 22.9521C330.955 22.5331 330.955 21.9047 330.525 21.4858L327.817 18.8884Z" fill="#C4C3FC"/>
        <path d="M329.02 26.2612C329.02 25.6747 328.548 25.2139 327.946 25.2139H324.207C323.605 25.2139 323.132 25.6747 323.132 26.2612C323.132 26.8477 323.605 27.3085 324.207 27.3085H327.946C328.505 27.3504 328.978 26.8896 329.02 26.345C329.02 26.3031 329.02 26.3031 329.02 26.2612Z" fill="#C4C3FC"/>
        <path d="M328.892 29.613L326.227 32.2104C325.797 32.6293 325.797 33.2577 326.227 33.6767C326.657 34.0956 327.345 34.0956 327.774 33.6767L330.439 31.0793C330.869 30.6604 330.869 30.032 330.439 29.613C330.052 29.1941 329.365 29.1941 328.892 29.613Z" fill="#C4C3FC"/>
        <path d="M338.003 23.2453C338.304 23.2453 338.562 23.1196 338.777 22.9521L341.442 20.3547C341.871 19.9357 341.871 19.2655 341.442 18.8884C341.012 18.4695 340.324 18.4695 339.937 18.8884L337.273 21.4858C336.843 21.9047 336.843 22.5331 337.273 22.9521C337.487 23.1615 337.745 23.2453 338.003 23.2453Z" fill="#C4C3FC"/>
        <path d="M333.834 21.5694C334.436 21.5694 334.909 21.1086 334.909 20.5221V16.8774C334.909 16.2909 334.436 15.8301 333.834 15.8301C333.233 15.8301 332.76 16.2909 332.76 16.8774V20.5221C332.76 21.1086 333.233 21.5694 333.834 21.5694Z" fill="#C4C3FC"/>
        <path d="M333.834 25.2139H341.571C342.172 25.2139 342.688 25.6747 342.688 26.2612C342.688 26.8477 342.215 27.3085 341.656 27.3504H336.456L341.442 32.1681C341.871 32.5871 341.871 33.2155 341.442 33.6344C341.012 34.0533 340.324 34.0533 339.894 33.6344L334.909 28.8167V33.802C334.909 34.3885 334.436 34.8912 333.834 34.8912C333.233 34.8912 332.76 34.4304 332.717 33.8857V26.2612C332.76 25.6747 333.233 25.2139 333.834 25.2139Z" fill="#C4C3FC"/>
        </g>
        <defs>
        <clipPath id="clip0_1071_15223">
        <rect width="19.5556" height="19.0614" fill="white" transform="translate(323.132 15.8301)"/>
        </clipPath>
        </defs>
    </svg>
</div>