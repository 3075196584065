import { Component } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../inputs/complex-input/tipoPergunta';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../store/cadastroPesquisaStoreState';
import { TipoConteudo } from '../../../../questionario/componentes/secao/conteudo/tipoConteudo';
import { AbstractPerguntaMarcacaoComponent } from '../../../abstractPerguntaMarcacaoComponent';

@Component({
  selector: 'app-video-marcacao',
  templateUrl: './video-marcacao.component.html',
  styleUrls: ['./video-marcacao.component.scss'],
})
export class VideoMarcacaoComponent extends AbstractPerguntaMarcacaoComponent {

  /**
   * @override
   */
  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_VIDEO_MARCACAO;

  /**
   * @override
   */
  tipoPergunta: TipoPergunta = TipoPergunta.VIDEO;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
