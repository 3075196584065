import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-question-card",
  templateUrl: "./question-card.component.html",
  styleUrls: ["./question-card.component.scss"],
})
export class QuestionCardComponent implements OnInit {
  @Input() pergunta;

  showBody: boolean = false;

  ngOnInit() {
    console.log(this.pergunta.pergunta.alternativas.length);
  }

  handleShowBody(): void {
    if (this.pergunta.pergunta.alternativas.length > 0) {
      this.showBody = !this.showBody;
    }
  }

  handleConvertString(tipo: string): string {
    switch (tipo) {
      case "UNICA":
        return "Única";
      case "MULTIPLA":
        return "Múltipla";
      case "FOTO":
        return "Foto";
      case "VIDEO":
        return "Vídeo";
      case "DATA":
        return "Data";
      case "HORARIO":
        return "Horário";
      case "ESCALA_NUMERICA":
        return "Escala";
      case "GRADE_UNICA":
        return "Grade Única"
      default:
        return "Grade Múltipla"
    }
  }
}
