import { v4 as uuid } from 'uuid';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Event } from './event';
import { Listener } from './listener';

/**
 * Este barramento de eventos possibilita que o módulo
 * pesquisa-beta possa avisar, globalmente, dentro de sua
 * estrutura sobre os eventos que estão ocorrendo e com
 * isto removar a necessidade de implementação de atribuíção
 * recorrentes entre componentes pais e filhos.
 * 
 * Use isto para fazer notificações que afetem vários pontos
 * na interface deste módulo, para ações assíncronas e para
 * remover circularidade entre componentes (ou seus diretórios).
 * 
 */
@Injectable()
export class EventBus {    
  private subjects = new Map();

  fire<T>(event: Event<T>) {
    // verificar se existe o subject
    const subj = this.subjects.get(event.getType());
    if (!!subj) {
      // disparar
      subj.next(event);
    }
  }

  on<T>(type: string, listener: Listener<T>) {
    // verificar se existe o subject,
    // se não tiver, criar
    let subject = this.subjects.get(type);
    if (!subject) {
      subject = new Subject<Event<T>>();
      this.subjects.set(type, subject);
    }
    // inscrever o listener para o evento 'type'
    const sid = uuid();
    subject.subscribe({
        sid,
        next: listener,
    });
    //
    return sid;
  }

  unregister(sid) {
    // this.subjects.
    // TODO: resolver
  }
}