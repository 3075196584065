<div class="options">
  <div
    class="option"
    *ngFor="let item of options"
    (click)="!isOptionDisabled(item) && selectFilterOption(item)"
    [ngClass]="{
      'selected': hasSelected(item),
      'isDisabled' : isOptionDisabled(item)
    }"
  >
    <div class="checkbox">
      <i class="fa-regular fa-check"></i>
    </div>
    <label for="">{{ item.label }}</label>
  </div>
</div>
