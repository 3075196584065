<div [formGroup]="formGroup" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card-marcacao
    [icone]="'fa fa-list'"
    [titulo]="'MÚLTIPLA ESCOLHA'"
    [nomePlaceHolder]="'Título da pergunta de múltipla escolha?'"
    [formGroup]="formGroup"
  >
  <ng-content></ng-content>
</app-pergunta-card-marcacao>

</div>