// tslint:disable-next-line: max-line-length
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';
import { Alternativa } from './alternativa';
import { FaixaPergunta } from './faixaPergunta';
import { TituloGrade } from './tituloGrade';
import { TipoPergunta } from '../inputs/complex-input/tipoPergunta';
import { v4 as uuid } from 'uuid';
import { TipoConteudo } from '../passos/questionario/componentes/secao/conteudo/tipoConteudo';
import validadorCampoMaiorIgual from 'app/util/validador/validadorCampoMaiorIgual';
import validadorCampoMenorIgual from 'app/util/validador/validadorCampoMenorIgual';
// tslint:disable-next-line: import-name max-line-length
import getQuantidadeMaximaSelecaoValidator from 'app/util/validador/pergunta/quantidadeMaximaSelecaoValidator';
import { TipoAlternativa } from '../../../auditoria/resposta-pergunta-listagem/tipoAlternativa';
import { MarcacaoPergunta } from './marcacaoPergunta';

export class Pergunta {

  id: string;
  nome: string;
  descricao: string;
  discoInducao: boolean;
  possuiCota: boolean;
  obrigatoria: boolean;
  tipo: TipoPergunta;
  /**
   * Marcador do tipo de conteudo da pergunta
   * a ser renderizado
   */
  tipoConteudo: TipoConteudo;
  alternativas: Alternativa[] = [];
  qtdMinima: number = 1;
  qtdMaxima: number = 1;
  faixaPergunta: FaixaPergunta;
  titulosGrade: TituloGrade[] = [];
  /**
   * Caso a pergunta nao tenha um ID, o hash aleatorio
   * sera utilizado, caso contrario, o ID assumira o
   * papel do hash.
   *
   * O hash serve para identificarmos a pergunta
   * no pularPara e diferenteDe, pois no cadastro
   * ainda não existe o id para esta referencia.
   */
  hash: string = uuid();
  hashSecao: string;
  // marcações do passo 2
  possuiPulo: boolean;
  possuiPuloPergunta: boolean;
  possuiDiferenteDe: boolean;
  /**
   * Codigo marcacao, igual ao hash
   */
  codigoMarcacao: string;

  marcacaoPergunta: MarcacaoPergunta;

  /**
   * marcação responsável por informar se a pergunta é a ultima da lista ou nao.
   */
  isLastItemElementoSecao: boolean = false;

  /**
   * marcação responsável por informar se a pergunta é a primeira da lista .
   */
  isFirstItemElementoSecao: boolean = false;

  /**
   * caso esta pergunta seja possua cota este atributo eh preenchido
   * com a ordem em que a cota dessa pergunta eh aplicada com relacao
   * as outras perguntas com cota do questionario.
   */
  ordemCota: number;

  constructor(tipo: TipoPergunta) {
    this.tipo = tipo;

    /**
     * Escala numerica nao possui alternativas
     * por padrão
     */
    const iniciarAlternativasPadrao = this.tipo !== TipoPergunta.ESCALA_NUMERICA
      && this.tipo !== TipoPergunta.DATA
      && this.tipo !== TipoPergunta.FOTO
      && this.tipo !== TipoPergunta.HORARIO
      && this.tipo !== TipoPergunta.VIDEO;

    /**
     * Somente perguntas do tipo grade possuem titulos grade
     */
    const iniciarTitulosGrade = this.tipo === TipoPergunta.GRADE_UNICA
      || this.tipo === TipoPergunta.GRADE_MULTIPLA;

    if (iniciarAlternativasPadrao) {
      /**
       * Adicionando 2 alternativas
       */
      const numeroAlternativas = 2;
      for (let i = 0; i < numeroAlternativas; i += 1) {
        const alternativa = new Alternativa();
        this.alternativas.push(alternativa);
      }
    }

    if (iniciarTitulosGrade) {
      /**
       * Adicionando 2 titulos grade
       */
      const numeroTitulosGrade = 2;
      for (let i = 0; i < numeroTitulosGrade; i += 1) {
        const titulo = new TituloGrade();
        this.titulosGrade.push(titulo);
      }
    }

  }

  static getControl(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      // tslint:disable-next-line:max-line-length
      nome: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA)),
      // tslint:disable-next-line: max-line-length
      descricao: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_DESCRICAO)),
      discoInducao: new UntypedFormControl(false),
      possuiCota: new UntypedFormControl(false),
      obrigatoria: new UntypedFormControl(false),
      // tslint:disable-next-line:max-line-length
      tipo: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_TIPO)),
      alternativas: new UntypedFormArray([]),
      qtdMaxima: new UntypedFormControl(1, []),
      qtdMinima: new UntypedFormControl(1, []),
      /**
       * Faixa só existe em pergunta do tipo escanaNumerica,
       * ele é adicionado posteriormente
       */
      // faixaPergunta: FaixaPergunta.getControl(),
      titulosGrade: new UntypedFormArray([]),
      hash: new UntypedFormControl(uuid()),
      hashSecao: new UntypedFormControl(undefined),
      possuiPulo: new UntypedFormControl(false),
      possuiPuloPergunta: new UntypedFormControl(false),
      possuiDiferenteDe: new UntypedFormControl(false),
      tipoConteudo: new UntypedFormControl(),

      isLastItemElementoSecao:new UntypedFormControl(false),
      isFirstItemElementoSecao:new UntypedFormControl(false),

      ordemCota: new UntypedFormControl(),

    });

    formGroup.addControl('codigoMarcacao', new UntypedFormControl(formGroup.get('hash').value));

    adicionarValidadoresDetiposDePerguntaEspecificos(formGroup);

    return formGroup;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(pergunta: Pergunta): UntypedFormGroup {
    // console.log('perguntaCOntrol', pergunta);
    /**
     * Caso a pergunta nao tenha um ID, o hash aleatorio
     * sera utilizado, caso contrario, o ID assumira o
     * papel do hash.
     *
     * Por conta do hash ser um uuid(String), trabalharemos com
     * o id como se fosse uma também
     */
    pergunta.id = pergunta.id ? String(pergunta.id) : pergunta.id;
    const hash = pergunta.id ? pergunta.id : pergunta.hash;

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(pergunta.id),
      // tslint:disable-next-line:max-line-length
      nome: new UntypedFormControl(pergunta.nome, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA)),
      // tslint:disable-next-line: max-line-length
      descricao: new UntypedFormControl(pergunta.descricao, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_DESCRICAO)),
      discoInducao: new UntypedFormControl(pergunta.discoInducao),
      possuiCota: new UntypedFormControl(pergunta.possuiCota),
      obrigatoria: new UntypedFormControl(pergunta.obrigatoria),
      // tslint:disable-next-line:max-line-length
      tipo: new UntypedFormControl(pergunta.tipo, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_TIPO)),
      hash: new UntypedFormControl(hash),
      codigoMarcacao: new UntypedFormControl(hash),
      qtdMaxima: new UntypedFormControl(pergunta.qtdMaxima, [Validators.required]),
      qtdMinima: new UntypedFormControl(pergunta.qtdMinima, [Validators.required]),
      hashSecao: new UntypedFormControl(pergunta.hashSecao),
      possuiPulo: new UntypedFormControl(pergunta.possuiPulo),
      possuiPuloPergunta: new UntypedFormControl(pergunta.possuiPuloPergunta),
      possuiDiferenteDe: new UntypedFormControl(pergunta.possuiDiferenteDe),
      tipoConteudo: new UntypedFormControl(pergunta.tipoConteudo),
      isLastItemElementoSecao:new UntypedFormControl(pergunta.isLastItemElementoSecao),
      isFirstItemElementoSecao:new UntypedFormControl(pergunta.isFirstItemElementoSecao),
      ordemCota: new UntypedFormControl(pergunta.ordemCota),
    });

    if (pergunta.possuiPuloPergunta) {
      // tslint:disable-next-line: max-line-length
      const marcacaoPerguntaControl = MarcacaoPergunta.getInitializedControl(pergunta.marcacaoPergunta);
      formGroup.addControl('marcacaoPergunta', marcacaoPerguntaControl);
    } else {
      // console.log('NÃO POSSUI PULO_PERGUNTA!!');
    }
    if (pergunta.faixaPergunta) {
      const faixaPerguntaControl = FaixaPergunta.getInitializedControl(pergunta.faixaPergunta);
      formGroup.addControl('faixaPergunta', faixaPerguntaControl);
    }

    if (pergunta.titulosGrade) {
      // tslint:disable-next-line: max-line-length
      const titulosGradeControls = pergunta.titulosGrade.map(tituloGrade => TituloGrade.getInitializedControl(tituloGrade));
      /**
       * Ordenando os titulos grade
      */
      titulosGradeControls.forEach((tituloGradeControl, i) => {
        const ordemControl = tituloGradeControl.get('ordem');
        const ordemAtual = ordemControl.value;
        /**
        * A ordenação deve-se iniciar a
        * partir de 1
        */
        const index = i + 1;
        const ordemValida = ordemAtual ? ordemAtual : index;
        ordemControl.patchValue(ordemValida);
      });

      const titulosGradeFormArray = new UntypedFormArray(titulosGradeControls);

      formGroup.addControl('titulosGrade', titulosGradeFormArray);
    }

    // tslint:disable-next-line: max-line-length
    const alternativaControls = pergunta.alternativas.map(alternativa => Alternativa.getInitializedControl(alternativa));
    /**
     * Ordenando as alternativas e setando seu tipo
     * para GRADE caso seja uma pergunta desse tipo
     * ou ESCALA_NUMERICO caso seja uma pergunta desse tipo
     */
    alternativaControls.forEach((alternativaControl, i) => {
      const ordemControl = alternativaControl.get('ordem');
      const ordemAtual = ordemControl.value;

      /**
       * A ordenação deve-se iniciar a
       * partir de 1
       */
      const index = i + 1;
      const ordemValida = ordemAtual ? ordemAtual : index;
      ordemControl.patchValue(ordemValida);

      const tipoAlternativaControl = alternativaControl.get('tipoAlternativa');

      // tslint:disable-next-line: max-line-length
      if (pergunta.tipo === TipoPergunta.GRADE_MULTIPLA || pergunta.tipo === TipoPergunta.GRADE_UNICA) {
        tipoAlternativaControl.patchValue(TipoAlternativa.GRADE);
      }

      if (pergunta.tipo === TipoPergunta.ESCALA_NUMERICA) {
        tipoAlternativaControl.patchValue(TipoAlternativa.ESCALA_NUMERICA);
      }

    });

    const alternativaFormArray = new UntypedFormArray(alternativaControls);

    formGroup.addControl('alternativas', alternativaFormArray);

    adicionarValidadoresDetiposDePerguntaEspecificos(formGroup);
    // console.log('getInitializedControl', formGroup);

    return formGroup;
  }

}

/**
 * Adiciona ao formGroup passado os validadores que so existem em tipos
 * especificos de perguntas
 */
function adicionarValidadoresDetiposDePerguntaEspecificos(formGroup: UntypedFormGroup) {
  const validadores = {
    MULTIPLA: {
      adicionar: () => {
        const controls = formGroup.controls;

        const maiorIgualAQtdMinima = validadorCampoMaiorIgual(controls.qtdMinima);
        const menorIgualAQtdMaxima = validadorCampoMenorIgual(controls.qtdMaxima);

        controls.qtdMaxima.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.qtdMaxima.setValidators([Validators.required, maiorIgualAQtdMinima, getQuantidadeMaximaSelecaoValidator(controls), Validators.min(-1)]);
        controls.qtdMaxima.updateValueAndValidity();

        controls.qtdMinima.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.qtdMinima.setValidators([Validators.required, menorIgualAQtdMaxima, getQuantidadeMaximaSelecaoValidator(controls), Validators.min(-1)]);
        controls.qtdMinima.updateValueAndValidity();
      },
    },

    GRADE_MULTIPLA: {
      adicionar: () => {
        const controls = formGroup.controls;

        const maiorIgualAQtdMinima = validadorCampoMaiorIgual(controls.qtdMinima);
        const menorIgualAQtdMaxima = validadorCampoMenorIgual(controls.qtdMaxima);

        controls.qtdMaxima.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.qtdMaxima.setValidators([Validators.required, maiorIgualAQtdMinima, getQuantidadeMaximaSelecaoValidator(controls), Validators.min(1)]);
        controls.qtdMaxima.updateValueAndValidity();

        controls.qtdMinima.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.qtdMinima.setValidators([Validators.required, menorIgualAQtdMaxima, getQuantidadeMaximaSelecaoValidator(controls), Validators.min(1)]);
        controls.qtdMinima.updateValueAndValidity();

        controls.titulosGrade.clearValidators();
        controls.titulosGrade.setValidators([Validators.required, Validators.min(1)]);
        controls.titulosGrade.updateValueAndValidity();

        controls.alternativas.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.alternativas.setValidators([Validators.required,Validators.min(1)]);
        controls.alternativas.updateValueAndValidity();
      },
    },

    UNICA: {
      adicionar: () => {
        const controls = formGroup.controls;

        controls.alternativas.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.alternativas.setValidators([Validators.required, Validators.min(1)]);
        controls.alternativas.updateValueAndValidity();

      },
    },

    GRADE_UNICA: { 
      adicionar: () => {
        const controls = formGroup.controls;

        controls.titulosGrade.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.titulosGrade.setValidators([Validators.required, Validators.min(1)]);
        controls.titulosGrade.updateValueAndValidity();

        controls.alternativas.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.alternativas.setValidators([Validators.required, Validators.min(1)]);
        controls.alternativas.updateValueAndValidity();

      },
    },
    MULTIPLA_ORDEM_CITACAO: {
      adicionar: () => {
        const controls = formGroup.controls;

        const maiorIgualAQtdMinima = validadorCampoMaiorIgual(controls.qtdMinima);
        const menorIgualAQtdMaxima = validadorCampoMenorIgual(controls.qtdMaxima);

        controls.alternativas.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.alternativas.setValidators([Validators.required, Validators.min(1)]);
        controls.alternativas.updateValueAndValidity();

        controls.qtdMaxima.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.qtdMaxima.setValidators([Validators.required, maiorIgualAQtdMinima, getQuantidadeMaximaSelecaoValidator(controls), Validators.min(1)]);
        controls.qtdMaxima.updateValueAndValidity();

        controls.qtdMinima.clearValidators();
        // tslint:disable-next-line:max-line-length
        controls.qtdMinima.setValidators([Validators.required, menorIgualAQtdMaxima, getQuantidadeMaximaSelecaoValidator(controls), Validators.min(1)]);
        controls.qtdMinima.updateValueAndValidity();

      },
    },

    ESCALA_NUMERICA: {
      adicionar: () => {

        // console.log('ESCALA_NUMERICA', formGroup);

        if (!formGroup.controls.faixaPergunta) {
          formGroup.addControl('faixaPergunta', FaixaPergunta.getControl());
        }

        const controls = formGroup.controls;

        function escalaNumericaGetQuantidadeMaximaSelecaoValidator(): ValidatorFn {

          return (control: AbstractControl): ValidationErrors | null => {
            const valorMaximoPermitido = controls.faixaPergunta.get('intervaloFim').value;

            if (control.value > valorMaximoPermitido) {
              return {
                valorMaximoPermitido: true,
              };
            }

            return null;
          };
        }

        // tslint:disable-next-line:max-line-length
        const maiorIgualAIntervaloInicio = validadorCampoMaiorIgual(controls.faixaPergunta.get('intervaloInicio'));
        // tslint:disable-next-line:max-line-length
        const menorIgualAIntervaloFim = validadorCampoMenorIgual(controls.faixaPergunta.get('intervaloFim'));
        // tslint:disable-next-line:max-line-length
        const maiorIgualAMenor = validadorCampoMaiorIgual(controls.faixaPergunta.get('menor'));
        // tslint:disable-next-line:max-line-length
        const menorIgualAMaior = validadorCampoMenorIgual(controls.faixaPergunta.get('maior'));

        // tslint:disable-next-line:max-line-length
        // controls.faixaPergunta.get('menor').setValidators([escalaNumericaGetQuantidadeMaximaSelecaoValidator(), maiorIgualAIntervaloInicio, menorIgualAIntervaloFim, menorIgualAMaior]);
        // tslint:disable-next-line:max-line-length
        // controls.faixaPergunta.get('maior').setValidators([escalaNumericaGetQuantidadeMaximaSelecaoValidator(), maiorIgualAIntervaloInicio, menorIgualAIntervaloFim, maiorIgualAMenor]);
      },
    },

  };

  const tipoPergunta = formGroup.get('tipo').value;

  if (validadores[tipoPergunta]) {
    validadores[tipoPergunta].adicionar();
  }

}
