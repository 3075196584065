<div class="modal-container" [formGroup]="formGroup">
  <div class="modal-wrapper">
    <div class="modal-header">
      <span> Ajuste de cotas </span>
    </div>
    <div class="modal-body">
      <div class="bloco-selecao-perfil">
        <span class="titulo-input"
          >Selecione o perfil que terá as cotas reduzidas</span
        >
        <app-seletor-perfil-entrevistado
          (perfilSelecionadoEvt)="onPerfilOrigemChange($event)"
        >
        </app-seletor-perfil-entrevistado>
        <span
          class="error-message"
          *ngIf="
            submited &&
            formGroup.controls.perfilOrigem.errors &&
            formGroup.controls.perfilOrigem.errors?.required
          "
          >Você deve informar o perfil de origem</span
        >
      </div>
      <div class="bloco-selecao-perfil">
        <span class="titulo-input"
          >Seleciona o perfil que terá as cotas incrementadas</span
        >
        <app-seletor-perfil-entrevistado
          (perfilSelecionadoEvt)="onPerfilDestinoChange($event)"
          [perfisParaIgnorar]="perfilDestinoIgnorar"
        >
        </app-seletor-perfil-entrevistado>
        <span
          class="error-message"
          *ngIf="
            submited &&
            formGroup.controls.perfilDestino.errors &&
            formGroup.controls.perfilDestino.errors?.required
          "
          >Você deve informar o perfil de destino</span
        >
      </div>
      <div class="bloco-definicao-quantidade sg-input-group">
        <span class="titulo-input" placeholder="Amostras"
          >Informe quantas amostras deseja transferir</span
        >
        <input
          type="text"
          mascara-numero
          formControlName="quantidadeTransferencia"
        />
        <span
          class="error-message"
          *ngIf="
            submited &&
            formGroup.controls.quantidadeTransferencia.errors &&
            formGroup.controls.quantidadeTransferencia.errors?.required
          "
          >Você deve informar a quantidade a ser transferida</span
        >
        <span
          class="error-message"
          *ngIf="
            submited &&
            formGroup.controls.quantidadeTransferencia.errors &&
            formGroup.controls.quantidadeTransferencia.errors?.min
          "
          >Ao menos 1 amostra deve ser transferida</span
        >
        <span
          class="error-message"
          *ngIf="
            submited &&
            formGroup.controls.quantidadeTransferencia.errors &&
            formGroup.controls.quantidadeTransferencia.errors?.max
          "
          >Este perfil pode transferir no máximo
          {{
            formGroup.controls.quantidadeTransferencia.errors.max.max
          }}
          amostra(s)</span
        >
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-12 btns-actions">
        <button
          class="sg-btn sg-alternate"
          title="Cancelar transferência"
          (click)="cancelar()"
        >
          Cancelar
        </button>
        <button
          class="sg-btn sg-secondary"
          title="adicionar operador"
          (click)="ajustar()"
        >
          Ajustar
        </button>
      </div>
    </div>
  </div>
</div>
