import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { TipoUsuarioSistema } from 'app/util/usuario/tipoUsuario';

export enum ListagemContratantesMenu {
  ACESSARINSTANCIA = 'Acessar instância',
  ATIVAR = 'Ativar',
  EDITAR = 'Editar',
}

export interface IOptionMeatball {
  id?: number | string;
  type?: string;
  status?: boolean;
}

@Component({
  selector: 'app-contratante-meatball',
  templateUrl: './meatball.component.html',
  styleUrls: ['./meatball.component.scss']
})
export class ListagemContratantesMeatballComponent implements OnInit {

  showMeatball: boolean; // Control the meatball is open or closed
  canInactiveBtn: boolean = false;
  @Input() id: string | number; // Id do item selecionado
  @Input() status: boolean;

  // retorna o item selecionado pelo menu meatball
  @Output() handleItemClicked = new EventEmitter<IOptionMeatball>()

  menuItems = ListagemContratantesMenu;

  constructor(
    private el: ElementRef,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.canInactiveBtn = this.canInactiveBtnWhenRoleIsNotEmployee();
  }

  canInactiveBtnWhenRoleIsNotEmployee() {
    return this.securityService.getAuthenticatedUser().tipoUsuario !== TipoUsuarioSistema.ROLE_FUNCIONARIO;
  }

  // Method that deals with the meatball manipulation
  handleShowMeatball() {
    this.showMeatball = !this.showMeatball;
  }

  /**
   * Open collection
   */
  handleRequestInstanceAuth() {
    // propaga o id do item e o tipo de botão clicado
    this.handleItemClicked.emit({
      id: this.id,
      type: this.menuItems.ACESSARINSTANCIA
    })
  }

  /**
   * Open observation list
   */
  toggleHirerStatus() {
    // propaga o id do item e o tipo de botão clicado
    this.handleItemClicked.emit({
      id: this.id,
      type: this.menuItems.ATIVAR,
      status: this.status
    })
  }

  /**
   * Open the map modal
   */
  handleEditInstance() {
    // fecha o meatball
    this.handleShowMeatball()
    // propaga o id do item e o tipo de botão clicado
    this.handleItemClicked.emit({
      id: this.id,
      type: this.menuItems.EDITAR
    })
  }

  // Verify where was the click
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    if (this.el.nativeElement.contains(event.target)) {
      if (this.showMeatball) {
        return;
      } else {
        this.handleShowMeatball();
      }
    } else {
      if (!this.showMeatball) {
        return;
      } else {
        this.handleShowMeatball();
      }
    }
  }

}
