import { ValidationErrors, UntypedFormControl, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { PerfilEntrevistado } from '../../../../../../../pesquisa-old/cadastro/model/perfilEntrevistado';

/**
 * Fabrica que gera validadores de quantidade maxima que pode ser transferida
 * de um perfil para outro
 * @param idVinculoOperador vinculo operador que deseja realizar o ajuste 
 * @param quantidadeTransferencia quantidade que sera enviada de um perfil a outro
 * @param perfilOrigem perfil de onde sera decerementada amostas
 */
// tslint:disable-next-line: max-line-length
function createMaxAjusteValidator(formGroup: UntypedFormGroup): ValidatorFn {

  return (formControl: UntypedFormControl) => {

    const idVinculoOperador: number = formGroup.controls.idVinculoOperador.value;
    const quantidadeTransferencia: number = formGroup.controls.quantidadeTransferencia.value;
    const perfilOrigem: PerfilEntrevistado = formGroup.controls.perfilOrigem.value;

    return validate(idVinculoOperador, quantidadeTransferencia, perfilOrigem);
  };
}

// tslint:disable-next-line: max-line-length
function validate(idVinculoOperador: number, quantidadeTransferencia: number, perfilOrigem: PerfilEntrevistado): ValidationErrors {

  const maximoAjustavel = perfilOrigem.cotasPerfil
    .filter(cotaPerfil => cotaPerfil.vinculoOperador.id === idVinculoOperador)
    .map(cotaPerfil => cotaPerfil.cotaNumericaPrevista - cotaPerfil.cotaNumericaExecutada)
    .reduce((parcela, total) => parcela + total, 0);

  if (quantidadeTransferencia > maximoAjustavel) {
    return {
      max: { max: maximoAjustavel, actual: quantidadeTransferencia },
    };
  }
}

export { createMaxAjusteValidator };
