import { Component, OnInit } from '@angular/core';
import { AbstractPerguntaComponent } from '../abstractPerguntaComponent';
import { TipoConteudo } from '../../conteudo/tipoConteudo';
import { TipoPergunta } from '../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-horario-questionario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss'],
})
export class HorarioComponent extends AbstractPerguntaComponent {

  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_HORARIO;
  tipoPergunta: TipoPergunta = TipoPergunta.HORARIO;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
