import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditoriaObservationsModalService } from "app/componentes/audit-observations/modal-observations.service";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { SituacaoListagemDeColetas } from "app/modulos/auditoria-beta/constant";
import {
  ICollectionSummaryQuery,
  ISurveyCollectionSummary,
  ISurveyResumoColetaResponse,
} from "app/modulos/auditoria-beta/interfaces";
import {
  IQuestion,
  IQuestionResponse,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { AuditoriaBetaService } from "app/modulos/auditoria-beta/services/auditoria-beta.service";
import { adaptCollectionSummaryRespToModel } from "app/modulos/auditoria-beta/utils/parsers";
import { mapCollectionCriteriaToColorObject } from "app/modulos/auditoria-beta/utils/parsers/collectionCriteriaTo";
import { sortQuestionArray } from "app/modulos/auditoria-beta/utils/parsers/questionParserPayload";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import { AuditoriaBetaQuestionarioService } from "../../services/questionario.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "../../constant/errors";
import { ModalService } from "app/componentes/modal/modal.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-questionnaire",
  templateUrl: "./questionnaire.component.html",
  styleUrls: ["./questionnaire.component.scss"],
})
export class QuestionnaireComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private autidService: AuditoriaBetaService,
    private observationsModalService: AuditoriaObservationsModalService,
    private questionService: AuditoriaBetaQuestionarioService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: ModalService
  ) {}

  public isOnlineCollection: boolean = false;

  private requestLoadingState: {
    getResumoColeta: boolean;
    getQuestionario: boolean;
  } = {
    getResumoColeta: false,
    getQuestionario: false,
  };

  questionList: IQuestion[] = [];

  // Estado da modal de observações.
  observationsModal: ModalData = null;

  surveyId: number = null;
  collectionId: number = null;
  status: SituacaoListagemDeColetas = null;

  allowComments: boolean = false;

  auditAudio: string = "";
  modalData = new ModalData();

  // Valores default do header do questionário
  headerModel: ISurveyCollectionSummary =  {
    id: 0,
    horaInicio: "00:00",
    horaFim: "00:00",
    data: "",
    situacao: "",
    operador: "",
    criterios: [],
    localidade: "",
    pesquisa: "Pesquisa"
  }

  dataBreadcrumb: IItemBreadcrumb[];

  // Critérios da view
  headerCriterios = [
    {
      text: "Coleta Online",
      backgroundColor: "var(--gray150)",
      color: "var(--gray300)",
    },
  ];

  ngOnInit(): void {
    this.initParamsData();
    this.fetchHeaderModels();
    this.initObservationsModalData();
    this.getQuestionList();

    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Auditoria",
        itemLink: "/auditoria-beta",
        active: false,
      },
      {
        itemName: "Detalhes",
        itemLink: "../../",
        active: false,
      },
      {
        itemName: `Questionário ${this.collectionId}`,
        itemLink: "/questionario/1",
        active: true,
      },
    ];
  }

  public get allRequestIsComplete() {
    return (
      this.requestLoadingState.getQuestionario &&
      this.requestLoadingState.getResumoColeta
    );
  }

  initObservationsModalData() {
    this.observationsModal = this.observationsModalService.modalData;
  }

  fetchHeaderModels() {
    const query = {
      surveyId: this.surveyId,
      collectionId: this.collectionId,
    } as ICollectionSummaryQuery;

    if (query.surveyId && query.collectionId) {
      this.autidService.getCollectionSumary(query).subscribe({
        next: (data: ISurveyResumoColetaResponse) => {
          if (data) {
            this.headerModel = adaptCollectionSummaryRespToModel(data);
            this.headerCriterios = mapCollectionCriteriaToColorObject(
              this.headerModel.criterios
            );

            this.isOnlineCollection = this.headerModel.criterios.some(
              (criterio) => criterio.nome === "COLETA_ONLINE" && criterio.atendido === true
            );

            this.requestLoadingState.getResumoColeta = true;
          }
        },
      });
    }
  }

  onRequestShowObservations() {
    this.allowComments = ![
      SituacaoListagemDeColetas.APROVADA,
      SituacaoListagemDeColetas.REPROVADA,
    ].includes(this.status);

    this.observationsModalService.allowComments(this.allowComments);

    this.observationsModalService.show();
  }

  initParamsData() {
    const { id, collectionId } = this.route.snapshot.params;

    this.surveyId = id || null;
    this.collectionId = collectionId || null;
  }

  scrollIsTop() {
    return false;
  }
  scrollIsBottom() {
    return false;
  }

  /**
   * Realiza a construção das modais
   * @param icon = icone da modal
   * @param titleDialog = titulo da modal
   * @param textDialog = texto da modal
   * @param textBtn = texto do botão
   */
  buildModal(icon, titleDialog, textDialog, textBtn) {
    this.modalData.show = true;
    this.modalData.type = "information";
    this.modalData.icon = icon;
    this.modalData.titleDialog = titleDialog;
    this.modalData.textDialog = textDialog;
    this.modalData.actionPositive = textBtn;
  }

  /**
   * Cancela o processo de auditoria e retorna para listagem
   */
  handleCancel(): void {
    this.modalService.showModal({
      icon: 'fa-regular fa-door-open',
      title: 'Cancelar auditoria',
      messageModal: 'As alterações serão descartadas. Deseja cancelar o processo de auditoria?',
      btnTitlePositive: 'Sim',
      positiveCallback: () => this.location.back()
    })
  }

  setQuestions(questions: IQuestion) {
    this.questionList = sortQuestionArray({ ...questions });
  }

  setAudio(audio: string) {
    this.auditAudio = audio;
  }

  /**
   * Recupera os dados da coleta de uma pesquisa e guarda em um estado
   */
  getQuestionList() {
    if (this.surveyId && this.collectionId) {
      this.questionService
        .getQuestionCollectionList(this.surveyId, this.collectionId)
        .subscribe({
          next: ({ perguntas, audio }) => {
            this.setQuestions(perguntas);
            this.setAudio(audio);
            this.requestLoadingState.getQuestionario = true;
          },
          error: (err) => {
            const { title, message } = errorMessages.getQuestionnaire;

            this.errorHandlerService.handleError(err, title, message);
          },
        });
    }
  }
}
