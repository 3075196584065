import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// tslint:disable-next-line: max-line-length
import { Store } from "@ngrx/store";
import { OperadorVinculado, Perfil } from "../../localidades/localidade";
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from "../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState";
// tslint:disable-next-line: max-line-length
import { TransferenciaCotaAction } from "../../../../../../pesquisa-old/cadastro/store/gerenciadorCotas/transferencia/transferenciaCotaAction";

/**
 * representa os dados trafegados entre o client e o modal.
 */
export class TransferidorCotaModalData {
  perfilOrigem: Perfil;
  idVinculadoOperadorOrigem: number;
  operadoresVinculados: OperadorVinculado[] = [];
}

@Component({
  selector: "app-transferidor-cota-modal",
  templateUrl: "./transferidor-cota-modal.component.html",
  styleUrls: ["./transferidor-cota-modal.component.scss"],
})
export class TransferidorCotaModalComponent implements OnInit {
  idPerfisParaIgnorar: number[] = [];
  idsOperadoresIgnorados: number[] = [];

  formGroup: UntypedFormGroup;

  exibirErrosValidacao: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TransferidorCotaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransferidorCotaModalData,
    protected store: Store<CadastroPesquisaStoreState>
  ) {}

  ngOnInit() {
    this.initFormGroup();
  }

  /**
   * inicializa o formGroup que representa o formulario de transferencia
   * com seus validadores.
   */
  initFormGroup() {
    this.formGroup = new UntypedFormGroup({
      valor: new UntypedFormControl(0, [
        Validators.max(this.data.perfilOrigem.cotaNumericaPrevista),
        Validators.min(1),
        Validators.required,
      ]),
      idOperadorVinculadoDestino: new UntypedFormControl(null, [
        Validators.required,
      ]),
    });
  }

  onOperadorVinculadoSelecionado(
    operadorVinculadoSelecionado: OperadorVinculado
  ) {
    this.formGroup.patchValue({
      idOperadorVinculadoDestino:
        operadorVinculadoSelecionado.idVinculoOperador,
    });

    this.formGroup.get("idOperadorVinculadoDestino").updateValueAndValidity();
    this.formGroup.get("idOperadorVinculadoDestino").markAsTouched();
  }

  transferir() {
    this.exibirErrosValidacao = true;
    if (this.formGroup.invalid) {
      return;
    }

    const idPerfil = this.data.perfilOrigem.id;
    const vinculoOperadorDestino = this.formGroup.get(
      "idOperadorVinculadoDestino"
    ).value;
    const vinculoOperadorOrigem = this.data.idVinculadoOperadorOrigem;
    const quantidadeTransferida = Number(this.formGroup.get("valor").value);

    const action = new TransferenciaCotaAction({
      vinculoOperadorOrigem,
      vinculoOperadorDestino,
      quantidadeTransferida,
      idPerfil,
    });

    const msg = "Tem certeza que deseja realizar a transferencia de cotas?";

    this.exibirAlertaTemCerteza(msg).then(() => {
      this.store.dispatch(action);

      // tslint:disable-next-line: max-line-length
      this.exibirAlerta(
        "Transferência realizada",
        "A transferência foi realizada com sucesso!",
        "Entendi",
        () => {
          this.dialogRef.close();
        },
        null,
        null
      );
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

  exibirAlerta(
    titulo: string,
    textDialog: string,
    positiveText: string,
    positiveCallback: () => void,
    negativeText: string,
    negativeCallback: () => void
  ) {}

  exibirAlertaTemCerteza(message: string) {
    const temCerteza = message;

    return new Promise((accept, reject) => {
      this.exibirAlerta(
        "Confirmação",
        temCerteza,
        "Sim, tenho",
        () => {
          accept(true);
        },
        "Cancelar",
        () => {
          reject();
        }
      );
    });
  }
}
