import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { EntrevistasEmDesconformidadeQuery } from '../entrevistasEmDesconformidadeQuery';

interface EntrevistasDesconformidadeModalData {
  entrevistasEmDesconformidadeQuery: EntrevistasEmDesconformidadeQuery;
}

@Component({
  selector: 'app-entrevistas-desconformidade-modal',
  templateUrl: './entrevistas-desconformidade-modal.component.html',
  styleUrls: ['./entrevistas-desconformidade-modal.component.scss'],
})
export class EntrevistasDesconformidadeModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EntrevistasDesconformidadeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EntrevistasDesconformidadeModalData,
    private router: Router) { }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dialogRef.close();
      }
    });
  }

}
