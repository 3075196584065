import { formatToMaxDecimalPlaces } from "app/util//formatter";
import { AssessmentColor } from "../../constants/listagem-operadores";

export class InterviewFormatter {

  // Formata o desempenho e o retorna com 1 casa decimal
  perfomanceFormat(perfomance: number): string {
    if (perfomance === null) {
      return "-";
    } else {
      return formatToMaxDecimalPlaces(perfomance.toString(), 1);
    }
  }

  // altera o estilo de acordo com o score do operador
  assessmentStyle(textValue: string | number): string {
    if (+textValue > 7.0) {
      return AssessmentColor.GREEN;
    } else if (+textValue > 5.0) {
      return AssessmentColor.YELLOW;
    } else {
      return AssessmentColor.RED;
    }
  }
}
