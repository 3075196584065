import { Injectable } from "@angular/core";
import { ISurveyCollectionSummary } from "app/modulos/auditoria-beta/interfaces";
import { BehaviorSubject, Observable } from "rxjs";

interface IState {
  model: ISurveyCollectionSummary;
  visible: boolean;
  x: number;
  y: number;
}

@Injectable({ providedIn: "root" })
export class TooltipColetasService {
  constructor() {}

  private state: BehaviorSubject<IState> = new BehaviorSubject<IState>(null);
  public state$: Observable<IState> = this.state.asObservable();

  private data: ISurveyCollectionSummary = null;
  private isVisible: boolean = false;
  private x: number = 0;
  private y: number = 0;

  showAt(x: number, y: number) {
    this.isVisible = true;
    this.x = x;
    this.y = y;
    
    this.state.next({ model: this.data, x: this.x, y: this.y, visible: this.isVisible });
  }

  setData(data: ISurveyCollectionSummary) {
    this.data = data;
  }

  hide() {
    this.isVisible = false;

    this.state.next({ ...this.state.getValue(), visible: this.isVisible });
  }
}
