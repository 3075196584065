import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { HeaderDateSelectComponent } from "./header-date-select/header-date-select.component";
import {
  DateRange,
  MatCalendarCellClassFunction,
} from "@angular/material/datepicker";
import moment from "moment";
import { CollectionInterval } from "../../constants";
import { IIntervalDate } from "../../filter-select";

@Component({
  selector: "app-range-date",
  templateUrl: "./range-date.component.html",
  styleUrls: ["./range-date.component.scss"],
})
export class RangeDateComponent implements OnInit {
  @Output() changeFilter = new EventEmitter<any>();
  @Output() callbackEnd = new EventEmitter();
  @Input() intervalDate: IIntervalDate = null;

  selectedDateRange: DateRange<Date> = null;

  ngOnInit(): void {
    this.initIntervalCoillections();
  }

  // Componente  de cabeçalho do calendário
  header = HeaderDateSelectComponent;

  /**
   * Define, de acordo com intervalo retornado pelo backend, os valores dos
   * meses e dos anos que devem ser apresentados para seleção do usuário.
   */
  initIntervalCoillections() {
    CollectionInterval.month = [
      this.intervalDate.startDate.getMonth(),
      this.intervalDate.endDate.getMonth(),
    ];
    CollectionInterval.year = [
      this.intervalDate.startDate.getFullYear(),
      this.intervalDate.endDate.getFullYear(),
    ];
  }

  /**
   * Emite para o componente pai os valores selecionados caso haja um valor selecionado
   * Havendo ou não valores essa ação fecha o select
   */
  handleConfirmDateRange() {
    this.changeFilter.emit(
      !!this.selectedDateRange ? this.selectedDateRange : ""
    );
    this.callbackEnd.emit();
  }

  // Desabilita datas anteriores a startDate e posteriores a endDate
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate) => {
    const start = moment(cellDate).isSameOrAfter(this.intervalDate.startDate);
    const end = moment(cellDate).isSameOrBefore(this.intervalDate.endDate);
    return !(start && end) ? "disabled-date" : "";
  };

  /**
   * limpa seleção dos usuário
   */
  handleClearDateRange() {
    // TODO: resetar o estado do mês ao limpar
    this.selectedDateRange = null;
    this.changeFilter.emit("");
  }

  /**
   * Define a menor data com start e a maior como end e guarda em um objeto de estado
   * @param date data selecionado pelo usuário
   */
  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }
}
