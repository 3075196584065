import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { IImageConfig, IImageTheme } from 'styles/interface';
import { DefaultImagem } from 'styles/constant';
import { RequestService } from 'app/servico/request.service';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { CrudService } from 'app/servico/requestService/crudService';
import { HttpClient } from '@angular/common/http';
import { RequestErrorHandler } from 'app/servico/requestService/requestErrorHandler';

interface ThemeManagerResponse {
  tema: string;
  imagem: IImageTheme;
}

@Injectable({
  providedIn: "root",
})
export class ThemeService extends CrudService<any>{
  // estado que guarda as imagens da aplicação
  private images = new BehaviorSubject<IImageTheme>(DefaultImagem);
  currentImages = this.images.asObservable(); // Retorna as imagens do contratante

  constructor(
    protected requestService: RequestService,
    private http: HttpClient,
    private requestErrorHandler: RequestErrorHandler
  ) {
    super(requestService, `${apiLocation}`);
  }

  private baseUrl: string;

  /**
   * Recupera as imagens do contratante
   */
  setImage(config: IImageConfig) {
    this.images.next(this.buildImage(config.imagem));
  }

  /**
   * Constrói o objeto de imagem, verifica se o retorno do backend é nullo, se for o caso, atribui o valor default
   * @param image: objeto de imagem retornado pelo backend
   * @returns: novo objeto sem valores nulos
   */
  private buildImage(image: IImageTheme): IImageTheme {
    const imageObject: IImageTheme = {
      login: image.login !== null ? image.login : DefaultImagem.login,
      logotipo_light: image.logotipo_light !== null ? image.logotipo_light : DefaultImagem.logotipo_light
    }
    return imageObject;
  }

  /**
   * Função que é responsável por recuperar o tema de acordo com o contratante logado
   * @param hirer contratante que está logado no sistema
   * @returns {tema: 'nome_do_tema'}
   */
  getThemeByHirer(hirer: string): Observable<ThemeManagerResponse> {
    this.baseUrl = `${this.resourcePath}/contratantes/${hirer}/customizacao`;
    return this.http.get(this.baseUrl, {
      observe: 'response',
    }).pipe(
      map(response => response.body as ThemeManagerResponse),
      catchError(error => this.requestErrorHandler.handleError(error))
    );
  }

}
