import { Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { EventoInput } from "../cadastro/inputs/evento/eventoInput";
import { TipoEventoInput } from "../cadastro/inputs/evento/tipoEventoInput";
import { InputComponent } from "../cadastro/inputs/inputComponent";

import { Router } from "@angular/router";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";

import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/util/componente/filterable-select/filterable-select.component";
import { PesquisaItem } from "./pesquisaItem";

@Component({
  selector: "app-pesquisa-select",
  templateUrl: "./pesquisa-select.component.html",
  styleUrls: ["./pesquisa-select.component.scss"],
})
export class PesquisaSelectComponent extends InputComponent {
  pesquisas: SelectDataItem[] = [];

  private requestService: RequestService;

  isLoading: boolean = false;

  @Input() selecionarPrimeiro: boolean;
  @Input() idPesquisaSelecionada: number;

  @Input() pesquisaSelecionada: PesquisaItem;

  /**
   * @override
   * Construtor responsável por criar uma instância de PesquisaSelectComponent.
   * Injeta o service RequestService.
   * @param requestService serviço responsável por realizar requisições à API do back-end.
   */
  constructor(requestService: RequestService, private router: Router) {
    super();
    this.requestService = requestService;
  }

  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit(); // chama initFormGroup na classe super
    this.carregarPesquisas();
  }

  selectItemToPesquisa(selectedItem) {
    return {
      id: selectedItem.value,
      nome: selectedItem.label,
    };
  }

  pesquisaToSelectItem(pesquisa) {
    return {
      value: pesquisa.id,
      label: pesquisa.nome,
    };
  }

  getPesquisaById(idPesquisa: number): SelectDataItem | undefined {
    const pesquisaIndex = this.pesquisas.findIndex(
      (p) => p.value === idPesquisa
    );
    if (pesquisaIndex > -1) {
      return this.pesquisas[pesquisaIndex];
    }
  }

  /**
   * @override
   */
  initFormGroup() {
    this.control = new FormControl(
      this.idPesquisaSelecionada ? this.idPesquisaSelecionada : null
    );
    this.formGroup = new FormGroup({
      pesquisa: this.control,
    });
  }

  atualizarFormGroup(idPesquisaSelecionada: number): void {
    this.formGroup.patchValue({
      pesquisa: idPesquisaSelecionada,
    });
    this.onPesquisaSelect();
  }

  carregarPesquisas() {
    this.isLoading = true;
    const uri = `${apiLocation}/pesquisas/listar-pesquisas`;

    this.requestService.get(uri).subscribe((data) => {
      this.pesquisas = data.map(this.pesquisaToSelectItem);
      this.isLoading = false;
    });

    console.log("pesquisas", this.pesquisas);
  }

  handleSelectedPesquisa(event: SelectedItemEvent) {
    if (!this.pesquisaSelecionada) {
      const pesquisaSelecionada: SelectDataItem = this.getPesquisaById(
        event.item.value
      );

      this.atualizarFormGroup(event.item.value);

      const eventoInput: EventoInput = {
        tipo: TipoEventoInput.CHANGE,
        payload: this.selectItemToPesquisa(pesquisaSelecionada),
      };
      this.notifyParent(eventoInput);

      return;
    }

    //popup que indaga sobre mudança de pesquisa
    // const operacaoCapitalizada = stringFormatter.capitalizeFirstLetter(operacao);
  }

  onPesquisaSelect() {
    //inicialmente não haverá pesquisa selecionada, portanto não será exibido um popup
    if (!this.pesquisaSelecionada) {
      const pesquisaSelecionada: SelectDataItem = this.getPesquisaById(
        this.control.value
      );

      const eventoInput: EventoInput = {
        tipo: TipoEventoInput.CHANGE,
        payload: this.selectItemToPesquisa(pesquisaSelecionada),
      };
      this.notifyParent(eventoInput);

      return;
    }
  }

  redirecionar(force: boolean = false) {
    const pesquisaSelecionada: SelectDataItem = this.getPesquisaById(
      this.control.value
    );

    const eventoInput: EventoInput = {
      tipo: TipoEventoInput.CHANGE,
      payload: this.selectItemToPesquisa(pesquisaSelecionada),
    };

    this.notifyParent(eventoInput);

    const redirectTo: string[] = [
      `/auditoria/pesquisas/${pesquisaSelecionada.value}/respostasAbertas`,
    ];

    this.router.navigate(redirectTo, {
      queryParams: { redirect: force },
    });
  }
}
