import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../store/cadastroPesquisaStoreState';
import { AbstractPerguntaConteudoMarcacao } from '../../../abstractPerguntaConteudoMarcacao';
// tslint:disable-next-line: max-line-length
import { PesquisaAuthorityService } from '../../../../../security/pesquisaAuthorityService';

@Component({
  selector: 'app-selecao-unica-conteudo-marcacao',
  templateUrl: './selecao-unica-conteudo-marcacao.component.html',
  styleUrls: ['./selecao-unica-conteudo-marcacao.component.scss'],
})
export class SelecaoUnicaConteudoMarcacaoComponent extends AbstractPerguntaConteudoMarcacao {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
              protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
