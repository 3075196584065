import { AbstractAction } from '../../actions/abstractAction';
import { PassoCadastro } from '../../passoCadastro';
import { ActionType } from '../../actionType';

/**
 * Action utilizada para realizar a  transferencia
 * de cotas entre um mesmo perfil de operadores distintos
 */
export class TransferenciaCotaAction extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERENCIADOR_COTAS;
  readonly type = ActionType.TRANSFERIR_COTA;
  payload: SolicitacaoTransferenciaCota;

  constructor(payload: SolicitacaoTransferenciaCota) {
    super();
    this.payload = payload;
    this.isReplayable = true;
  }

}

export class SolicitacaoTransferenciaCota {

  vinculoOperadorOrigem: number;
  vinculoOperadorDestino: number;
  quantidadeTransferida: number;
  idPerfil: number;

  // tslint:disable-next-line: max-line-length
  constructor(vinculoOperadorOrigem: number, vinculoOperadorDestino: number, quantidadeTransferida: number, idPerfil: number) {
    this.vinculoOperadorOrigem = vinculoOperadorOrigem;
    this.vinculoOperadorDestino = vinculoOperadorDestino;
    this.quantidadeTransferida = quantidadeTransferida;
    this.idPerfil = idPerfil;
  }
}
