import { MascaraBase } from './mascaraBase';
import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-min-seg]',
})
export class MascaraMinutoSegundo extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 5);
  }

  aplicarMascara(valor: string): string {

    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);

    let valorFormatado = numberString;
    if (numberString.length >= 3) {
      // adicionando o ':' após os dois primeiros dígitos
      valorFormatado = numberString.replace(/(\d{2})/, '$1:');
    }

    return valorFormatado;
  }

}
