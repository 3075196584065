import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { auditSurveyStatus } from "../../constant";
import { IAuditedCollectionsData, IOverviewData } from "../../interfaces";
import { AuditoriaBetaService } from "../../services/auditoria-beta.service";

@Component({
  selector: "app-audit-details",
  templateUrl: "./audit-details.component.html",
  styleUrls: ["./audit-details.component.scss"],
})
export class AuditDetailsComponent implements OnInit {

  surveyId: number = null;
  isLoading: boolean = false;
  overviewData: IOverviewData;

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Auditoria",
      itemLink: "/auditoria-beta",
      active: false,
    },
    {
      itemName: "Detalhes",
      itemLink: `${this.surveyId}/detalhes/`,
      active: true,
    },
  ];

  TABS = {
    OVERVIEW: "overview",
    COLLECTIONS: "collections",
    OPEN_ANSWERS: "open_answers",
    SURVEY_MAP: "survey_map",
  };

  currentTab;

  auditedCollectionsData: IAuditedCollectionsData = {
    surveyTitle: "",
    surveyStatus: { color: "", status: "" },
    auditedCollections: {
      meta_auditoria: 0,
      auditadas: 0,
    },
  };

  possuiAmostrasPresenciais: boolean = false;

  constructor(
    private auditService: AuditoriaBetaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // set current survey id
    this.surveyId = this.route.snapshot.params.id;

    this.getAuditOverviewData();

    // get collections
    this.getAuditedCollectionsData();
  }

  /**
   * Atualiza o estado da variável "possuiAmostrasPresenciais" com base
   * na configuração da pesquisa.
   */
  updateWithPresencialSamples() {
    if (!this.overviewData) { return; }

    this.possuiAmostrasPresenciais = this.overviewData.configuracao.amostras_presenciais > 0;
  }

  getAuditOverviewData() {
    this.isLoading = true;

    this.auditService.getOverviewData(this.surveyId).subscribe({
      next: (data) => {
        this.overviewData = data;
        this.updateWithPresencialSamples();
      },
      complete: () => {
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;

      }
    });
  }

  /**
   * Obtem o status do backend e retorna o objeto correspondente
   * @param status: valor retornado pelo backend
   * @returns objeto que será passado para o audited-collections
   */
  getSurveyStatus(status: string) {
    return auditSurveyStatus.find((e) => e.key === status);
  }

  /**
   * Quando uma pesquisa auditoria for concluida deverá ser feita uma nova
   * request para atualizar os valores do header
   */
  updateSurveyStatus() {
    this.getAuditedCollectionsData();
  }

  getAuditedCollectionsData() {
    this.auditService.getAuditedCollectionsHeaderData(this.surveyId).subscribe({
      next: (data) => {
        this.auditedCollectionsData = {
          surveyTitle: data.titulo,
          surveyStatus: this.getSurveyStatus(data.situacao),
          auditedCollections: {
            meta_auditoria: data.resumo_coletas.meta_auditoria,
            auditadas: data.resumo_coletas.auditadas,
          },
        };
      },
      complete: () => { this.currentTab = this.TABS.OVERVIEW; },
      error: (err) => console.error(err)
    });
  }

  handleChangeTab(event: string) {
    this.currentTab = event;
  }

}
