import {
  Input,
  OnDestroy,
  OnInit,
  Component,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { TableCell } from "./interfaces/tCell";
import { TableHead } from "./interfaces/tHead";
import { RowCustomStyles, TableRow } from "./interfaces/tRow";
import { FilterConfigs, SearchInputConfig } from "./interfaces/filters";
import { EventTypes, MeatballClickEvent, TableEvents, TableGlobalConfig } from "./interfaces/table";
import { FilterTypes } from "app/componentes/filter-select/constants";
import { TablePagination } from "./interfaces/pagination";
import { RowCheckboxConfig } from "./interfaces/rowCheckbox";
import { checkboxHeadFactory } from "./utils/tHeadFactory";
import { checkboxCellFactory } from "./utils/cellFactory";
import { GenericTableService } from "./service/generic-table.service";
import {
  ClearCustomStyles,
  GenericCustomStyles,
  RequestToggleCheckboxAccessibility,
} from "./interfaces/service";
import { MeatballConfig } from "./interfaces/meatball";
import { ActionButtonConfig } from "./interfaces/actionButton";

@Component({
  selector: "app-generic-table",
  templateUrl: "./generic-table.component.html",
  styleUrls: ["./generic-table.component.scss"],
})
export class GenericTableComponent implements OnInit, OnChanges, OnDestroy {
  constructor(private service: GenericTableService) {}

  @Output() events = new EventEmitter<TableEvents>(null);

  @Input() tHead: TableHead[] = [];

  // flags de controle
  @Input() enablePagination: boolean = true;
  @Input() enableSearch: boolean = false;
  @Input() enableFilters: boolean = false;
  @Input() enableActionButton: boolean = false;
  @Input() filterContainerVisibility: "hidden" | "visible" = "visible";
  @Input() enableVirtualMode: boolean = false;
  @Input() enableCheckbox: boolean = false;
  @Input() enableMeatball: boolean = false;
  @Input() meatballConfig: MeatballConfig = null;

  //
  @Input() disabledEvents: EventTypes[] = [];

  // filtros habilitados
  @Input() filters: FilterConfigs[] = [];

  // search input config
  @Input() searchInputConf: SearchInputConfig;

  @Input() tableConfig: TableGlobalConfig = {};

  // dados de cada célula
  @Input() data: TableRow[] = [];

  // Action button à direita
  @Input() actionButtonConfig: ActionButtonConfig = {
    label: "DEFAULT",
    icon: "",
  };

  //
  public toRenderRows: TableRow[] = [];

  // ações possívels de cada linha
  @Input() checkboxConfig: RowCheckboxConfig = {
    selectable: () => true,
    headerNotSelectable: false,
  };

  @Input() currentPage: number = 1;
  @Input() totalOfEntries: number = 1;
  @Input() paginationLabel: string = "";
  @Input() entriesPerPage: number = 10;

  // configuração da paginação
  public paginationConfig: TablePagination = {
    currentPage: 1,
    entriesPerPage: 10,
    totalEntries: 0,
    totalPages: 1,
    label: "default",
  };

  private defaultSearchInputConf: SearchInputConfig = {
    placeholder: "Buscar...",
    dropdownMode: false,
    delay: 500,
  };

  // Controla o delay de envio do evento de mudança do filtro de busca por string.
  private searchFilterInterval: number = null;

  // Armazena as células no formato coluna : célula[]
  private cellRefs: { [key: number]: TableCell[] } = {};

  private defaultTableConfig: TableGlobalConfig = {
    rowHeight: "60px",
    headerHeight: "60px",
    rowBackgroundColors: { even: "var(--white)", odd: "var(--gray100)" },
    headerBackgroundColor: "var(--tertiary)",
  };

  private lastRowSequenceId: number = 0;
  private lastColSequenceId: number = 0;
  private lastStyleSequenceId: number = 0;

  public _selectedRows: number[] = [];
  public allRowsIsSelected: boolean = false;

  private _generatedStylesRefs: {
    [key: number]: { dataRef: TableRow[]; style: RowCustomStyles };
  } = {};

  ngOnInit(): void {
    this.init();
    this.startService();
  }

  ngOnDestroy(): void {
    this.service.dispose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage) {
      this.paginationConfig.currentPage = changes.currentPage.currentValue;
    }

    if (changes.totalOfEntries) {
      this.paginationConfig.totalEntries = changes.totalOfEntries.currentValue;
    }

    if (changes.entriesPerPage) {
      this.paginationConfig.entriesPerPage =
        changes.entriesPerPage.currentValue;
    }

    if (changes.totalOfEntries || changes.entriesPerPage) {
      this.refreshPagination();
    }

    if (changes.data) {
      this.refreshTableConfigs();
    }
  }

  private startService() {
    this.service.initSubscriptions({
      SELECT_ROWS: this.selectRows.bind(this),
      UNSELECT_ALL: this.unselectAllRows.bind(this),
      UNSELECT_ROWS: this.unselectRows.bind(this),
      SELECT_ALL_ROWS: this.selectAllRows.bind(this),
      INSERT_STYLES: this.insertStyles.bind(this),
      CLEAR_STYLES: this.clearStyles.bind(this),
      UPDATE_PAGINATION_CONFIG: this.updatePaginationConfig.bind(this),
      NEXT_PAGE: this.nextPage.bind(this),
      PREVIOUS_PAGE: this.previousPage.bind(this),
      JUMP_TO_PAGE: this.jumpToPage.bind(this),
      TOGGLE_CHECKBOX_ACCESSIBILITY: this.toggleCheckboxAccessibility.bind(this),
    });
  }

  private getCellByCoords(coords: number[]) {
    const [i, j] = coords;
    return this.toRenderRows[i].cells[j];
  }

  private getRow(index: number) {
    return this.toRenderRows[index];
  }

  private refreshTableConfigs() {
    this.cellRefs = {};
    this.setRowAndCellMetadata();
    this.setGlobalStylesAndValues();
    this.refreshPagination();
    this.updateToRenderRows();
    this.initCheckboxConfig();
  }

  private init() {
    this.setRowAndCellMetadata();
    this.setGlobalStylesAndValues();
    this.setTableGlobalConfig();
    this.initPagination();
    this.updateToRenderRows();
  }

  /** Set internal configs */

  private isRowChecked(rowId: number) {
    return this.selectedRows.includes(rowId);
  }

  private clearCheckedRows() {
    this.toRenderRows = this.toRenderRows.map((r) => ({
      ...r,
      cells: r.cells.map((c) => ({ ...c, checked: false })),
    }));
  }

  set selectedRows(value: number[]) {
    // limpeza
    if (!value.length) {
      this.clearCheckedRows();
    }
    // inserção
    else {
      this.toRenderRows = this.toRenderRows.map((r) => ({
        ...r,
        cells: r.cells.map((c) => ({
          ...c,
          checked: value.includes(r.id),
        })),
      }));
    }

    this._selectedRows = value;

    const checkedRowsToSubject = this._selectedRows.map((v) => ({
      id: v,
      index: this.toRenderRows.findIndex((r) => r.id === v),
    }));

    this.service.setCheckedRows(checkedRowsToSubject);
  }

  get selectedRows() {
    return this._selectedRows;
  }

  private selectAllRows() {
    this.selectedRows = this.toRenderRows
      .filter((row) => this.checkboxConfig.selectable(row.id))
      .map((row) => row.id);
  }

  private unselectAllRows() {
    this.selectedRows = [];
  }

  private nextStyleId() {
    return ++this.lastStyleSequenceId;
  }

  private insertRowStylesById(
    id: number | number[],
    styles: RowCustomStyles,
    styleId?: number
  ) {
    let ids = !Array.isArray(id) ? [id] : id;

    const matchedRows = this.toRenderRows.filter((row) => ids.includes(row.id));

    if (matchedRows.length) {
      matchedRows.forEach(
        (row) => (row.customStyles = { ...row.customStyles, ...styles })
      );

      const nextStyleId = styleId === undefined ? this.nextStyleId() : styleId;

      this._generatedStylesRefs[nextStyleId] = {
        dataRef: matchedRows,
        style: styles,
      };
    }
  }

  private insertRowStylesByIndex(
    index: number | number[],
    styles: RowCustomStyles,
    styleId?: number
  ) {
    let indexes = !Array.isArray(index) ? [index] : index;

    const matchedRows = this.toRenderRows.filter((_, i) => indexes.includes(i));

    if (matchedRows.length) {
      matchedRows.forEach(
        (row) => (row.customStyles = { ...row.customStyles, ...styles })
      );

      const nextStyleId = styleId === undefined ? this.nextStyleId() : styleId;

      this._generatedStylesRefs[nextStyleId] = {
        dataRef: matchedRows,
        style: styles,
      };
    }
  }

  private insertStyles(config: GenericCustomStyles) {
    const { type, styles, rowId, rowIndex, styleId } = config;

    switch (type) {
      case "ROW":
        if (rowId !== undefined) {
          this.insertRowStylesById(rowId, styles, styleId);
        } else if (rowIndex !== undefined) {
          this.insertRowStylesByIndex(rowIndex, styles, styleId);
        }
        break;
      default:
        break;
    }
  }

  private getLastStyleId() {
    return this.lastStyleSequenceId;
  }

  private clearRowStyles(styleId?: number) {
    const sId = styleId === undefined ? this.getLastStyleId() : styleId;

    if (this._generatedStylesRefs[sId]) {
      for (let i = 0; i < this._generatedStylesRefs[sId].dataRef.length; i++) {
        const refStyles = {
          ...this._generatedStylesRefs[sId].dataRef[i].customStyles,
        };

        const refStyles2 = { ...this._generatedStylesRefs[sId].style };

        for (let property in refStyles) {
          for (let property2 in refStyles2) {
            if (property === property2) delete refStyles[property];
          }
        }

        this._generatedStylesRefs[sId].dataRef[i].customStyles = {
          ...refStyles,
        };
      }
    }
  }

  private clearStyles(config: ClearCustomStyles) {
    const { type, styleId } = config;

    switch (type) {
      case "ROW":
        this.clearRowStyles(styleId);
        break;
      default:
        break;
    }
  }

  private onSelectRow(rowId: number, cellRef: TableCell) {
    const index = this.selectedRows.indexOf(rowId);

    if (index === -1) {
      this.selectedRows = [...this.selectedRows, rowId];
    } else {
      const aux = [...this.selectedRows];
      aux.splice(index, 1);

      this.selectedRows = aux;
    }
  }

  private selectRows(rowsIds: number | number[]) {
    const rows = !Array.isArray(rowsIds) ? [rowsIds] : rowsIds;
    this.selectedRows = [...new Set([...this.selectedRows, ...rows])];
  }

  private unselectRows(rowsIds: number | number[]) {
    const rows = !Array.isArray(rowsIds) ? [rowsIds] : rowsIds;

    this.selectedRows = this.selectedRows.filter((rId) => !rows.includes(rId));
  }

  private toggleCheckboxAccessibilityById(action: "ENABLE" | "DISABLE", values: number[]) {
    const checkable = action === "DISABLE" ? false : true;

    this.toRenderRows = this.toRenderRows.map((row) => ({
      ...row,
      cells: values.includes(row.id)
        ? row.cells.map((cell) => ({
            ...cell,
            ...(cell.type === "checkbox" ? { checkable } : {}),
          }))
        : row.cells,
    }));
  }


  private toggleCheckboxAccessibilityByIndex(action: "ENABLE" | "DISABLE", values: number[]) {
    const checkable = action === "DISABLE" ? false : true;

    this.toRenderRows = this.toRenderRows.map((row) => ({
      ...row,
      cells: values.includes(row.id)
        ? row.cells.map((cell) => ({
            ...cell,
            ...(cell.type === "checkbox" ? { checkable } : {}),
          }))
        : row.cells,
    }));
  }
  private toggleCheckboxAccessibility(config: RequestToggleCheckboxAccessibility) {
    const actionType = config.action;

    switch (actionType) {
      case "DISABLE":
        if (config.disableBy === "ID") this.toggleCheckboxAccessibilityById("DISABLE", config.values);
        else this.toggleCheckboxAccessibilityByIndex("DISABLE", config.values);
        break;
      default:
        if (config.enableBy === "ID") this.toggleCheckboxAccessibilityById("ENABLE", config.values);
        else this.toggleCheckboxAccessibilityByIndex("ENABLE", config.values);
        return;
    }
  }

  private unselectHeader() {
    this.allRowsIsSelected = false;
  }

  private initCheckboxConfig() {
    if (this.enableCheckbox) {
      const tHeadCheckbox = this.tHead.find(
        (thead) => thead.type === "checkbox"
      );

      tHeadCheckbox.checked = this.allRowsIsSelected;
      tHeadCheckbox.checkable = !this.checkboxConfig.headerNotSelectable;

      this.toRenderRows = this.toRenderRows.map((r) => ({
        ...r,
        cells: r.cells.map((cell) => ({
          ...cell,
          ...(cell.type === "checkbox"
            ? {
                checked: this.isRowChecked(r.id),
                checkable: this.checkboxConfig.selectable(r.id),
              }
            : {}),
        })),
      }));
    }
  }

  private setTableGlobalConfig() {
    this.searchInputConf = {
      ...this.defaultSearchInputConf,
      ...this.searchInputConf,
    };

    this.tableConfig = {
      ...this.defaultTableConfig,
      ...this.tableConfig,
    };

    if (!this.tableConfig.colWidths) {
      this.tableConfig.colWidths = [];
    }

    if (!this.tableConfig.cellWidths || !this.tableConfig.cellWidths.length) {
      this.tableConfig.cellWidths = [...(this.tableConfig.colWidths || [])];
    }

    this.paginationConfig = {
      ...this.paginationConfig,
      totalEntries: this.totalOfEntries,
      currentPage: this.currentPage,
      label: this.paginationLabel,
    };
  }

  private initPagination() {
    const { entriesPerPage, totalEntries } = this.paginationConfig;

    this.paginationConfig.entriesPerPage =
      entriesPerPage === 0 ? 1 : entriesPerPage;

    this.paginationConfig.entriesPerPage =
      this.data.length > 0 && this.data.length < entriesPerPage
        ? this.data.length
        : entriesPerPage;

    this.paginationConfig.totalEntries =
      this.data.length > 0 && totalEntries < this.data.length
        ? this.data.length
        : totalEntries;

    this.paginationConfig.totalPages = Math.ceil(
      this.paginationConfig.totalEntries / this.paginationConfig.entriesPerPage
    );
  }

  private updatePaginationConfig(config: TablePagination) {
    this.paginationConfig = {
      ...config,
    };

    const { entriesPerPage, totalEntries, currentPage, label } =
      this.paginationConfig;

    this.entriesPerPage = entriesPerPage;
    this.totalOfEntries = totalEntries;
    this.currentPage = currentPage;
    this.paginationLabel = label;

    this.initPagination();
  }

  private nextPage() {
    const { currentPage } = this.paginationConfig;

    if (currentPage + 1 <= this.paginationConfig.totalPages)
      this.paginationConfig.currentPage += 1;
  }

  private previousPage() {
    const { currentPage } = this.paginationConfig;

    if (currentPage - 1 > 0) this.paginationConfig.currentPage -= 1;
  }

  private jumpToPage(requestedPage: number) {
    if (requestedPage > 0 && requestedPage <= this.paginationConfig.totalPages)
      this.paginationConfig.currentPage = requestedPage;
  }

  private refreshPagination() {
    if (this.enablePagination) {
      this.paginationConfig.totalPages = Math.ceil(
        this.paginationConfig.totalEntries /
          this.paginationConfig.entriesPerPage
      );
    }
  }

  private updateToRenderRows() {
    if (this.enablePagination) {
      const { entriesPerPage, currentPage } = this.paginationConfig;

      let start: number;
      let end: number;

      start = this.enableVirtualMode ? (currentPage - 1) * entriesPerPage : 0;
      end = start + entriesPerPage;

      this.toRenderRows = this.data.slice(start, end);
    } else {
      this.toRenderRows = [...this.data];
    }
  }

  private nextRowId(): number {
    return ++this.lastRowSequenceId;
  }

  private nextCellId(): number {
    return ++this.lastColSequenceId;
  }

  private setRowAndCellMetadata() {
    this.cellRefs = {};
    this.data = this.data.map((row, rowIndex) => {
      if (!row.id) {
        row.id = this.nextRowId();
      }

      row.cells.forEach((cell, cellIndex) => {
        if (!cell.id) {
          cell.id = this.nextCellId();
        }

        if (!this.cellRefs[cellIndex]) this.cellRefs[cellIndex] = [cell];
        else this.cellRefs[cellIndex].push(cell);

        cell.coordinates = [rowIndex, cellIndex];
      });

      return { ...row };
    });
  }

  private setGlobalStylesAndValues() {
    if (this.data.length) {
      this.tHead = this.tHead.map((col, colIndex) => {
        const headAlignType = this.tableConfig.headerAlignType;
        const cellAlignType = this.tableConfig.cellAlignType;

        col.alignTypes = col.alignTypes || headAlignType;
        col.cellAlign = col.cellAlign || cellAlignType;

        const refs = [];
        const pushAndReturns = (v) => {
          refs.push(v);
          return v;
        };

        const iconDefaultValue = pushAndReturns(col.defaultCellIconValue);
        const htmlTemplateDefaultValue = pushAndReturns(
          col.defaultCellHtmlTemplateValue
        );
        const cellType = pushAndReturns(col.cellType);
        const customStyles = pushAndReturns(col.cellStyles);
        const styleBehavior = pushAndReturns(col.cellStyleBehavior);
        const alignType = pushAndReturns(col.cellAlign);
        const customCellClassName = pushAndReturns(col.className);
        const cellDataToolTip = pushAndReturns(col.cellDataToolTip);

        if (refs.length) {
          this.cellRefs[colIndex].forEach((cell) => {
            cell.type = cell.type || cellType;
            cell.iconValue = cell.iconValue || iconDefaultValue;
            cell.htmlTemplateValue =
              cell.htmlTemplateValue || htmlTemplateDefaultValue;
            cell.datatooltip = cell.datatooltip || cellDataToolTip;

            if (typeof customStyles === "function") {
              cell.customStyles = customStyles({ ...cell });
            } else {
              cell.customStyles = cell.customStyles || customStyles;
            }

            if (typeof customCellClassName === "function") {
              cell.className = customCellClassName({ ...cell });
            } else {
              cell.className = cell.className || customCellClassName;
            }

            cell.styleBehavior = cell.styleBehavior || styleBehavior;
            cell.alignType = cell.alignType || alignType;

            const [i, j] = cell.coordinates;

            this.data[i].cells[j] = { ...cell };
          });
        }

        return {
          ...col,
        };
      });
    }
  }

  /** General table event handling */
  public handleHeaderClick(headerRef: TableHead) {
    if (headerRef.type === "checkbox") {
      this.allRowsIsSelected = !this.allRowsIsSelected;

      headerRef.checked = this.allRowsIsSelected;

      // Notify to service subject
      this.service.headerCheckboxStateSubject.next(this.allRowsIsSelected);

      if (this.allRowsIsSelected) {
        this.selectAllRows();
      } else {
        this.unselectAllRows();
      }

      if (!this.disabledEvents.includes("HEADER_CHECKBOX_CLICK")) {
        this.events.emit({
          eventType: "HEADER_CHECKBOX_CLICK",
          selectedRows: this.selectedRows,
        });
      }
    }
  }

  public handleRowClick(rowIndex: number) {
    if (!this.disabledEvents.includes("ROW_CLICK")) {
      this.events.emit({
        eventType: "ROW_CLICK",
        rowIndex: rowIndex,
        metadata: this.data[rowIndex].metadata,
        rowRef: { ...this.data[rowIndex] },
      });
    }
  }

  public handleCellClick(coordinates: number[]) {
    const cell = this.getCellByCoords(coordinates);

    let eventName: EventTypes;

    switch (cell.type) {
      case "icon":
        eventName = "CELL_ICON_CLICK";
        break;
      case "checkbox":
        eventName = "CELL_CHECKBOX_CLICK";
        break;
      default:
        eventName = "CELL_CLICK";
        break;
    }

    if (this.disabledEvents.includes(eventName)) return;

    const row = this.getRow(coordinates[0]);

    const defaultProperties = {
      eventType: eventName,
      cellMetadata: cell.metadata,
      rowMetadata: row.metadata,
      rowIndex: coordinates[0],
      colIndex: coordinates[1],
      rowRef: { ...row },
      rowId: row.id,
      cellId: cell.id,
      cellRef: { ...cell },
    };

    if (eventName === "CELL_CHECKBOX_CLICK") {
      let hasChecked: boolean = cell.checked;

      // handle checkbox selecting logic
      this.onSelectRow(row.id, cell);

      this.events.emit({
        ...defaultProperties,
        checkedState: cell.checked,
        previousCheckedState: hasChecked,
        selectedRows: this.selectedRows,
      });
    } else {
      this.events.emit(defaultProperties);
    }
  }

  /**
   * Meatball click event dispatcher
   */
  public handleMeatballClick($event: MeatballClickEvent) {
    this.events.emit({
      eventType: "MEATBALL_OPTION_SELECTED",
      selectedMeatballOption: $event.option,
      rowId: $event.row.id,
      rowMetadata: $event.row.metadata,
    });
  }

  public handleActionButtonClick() {
    this.events.emit({
      eventType: "ACTION_BUTTON_CLICK",
    });
  }

  /** Filter event emitting */

  public filterChanged($event, filterType: FilterTypes, filterId?: number) {
    let eventName: EventTypes;

    switch (filterType) {
      case FilterTypes.CHECK:
        eventName = "CHECK_FILTER_CHANGED";
        break;
      case FilterTypes.COMPOSED_CHECK:
        eventName = "COMPOSED_CHECK_FILTER_CHANGE";
        break;
      case FilterTypes.RADIO:
        eventName = "RADIO_FILTER_CHANGED";
        break;
      case FilterTypes.RANGE_DATE:
        eventName = "DATE_FILTER_CHANGED";
        break;
      default:
        break;
    }

    this.events.emit({
      eventType: eventName,
      filterValue: $event,
      ...(typeof filterId === 'number') ? { filterId } : {},
    });
  }

  public searchChanged($event: string) {
    const emitEvent = () => {
      this.searchFilterInterval = window.setTimeout(() => {
        this.events.emit({
          eventType: "SEARCH_FILTER_CHANGED",
          filterValue: $event,
        });
      }, this.searchInputConf.delay);
    };

    if (this.searchFilterInterval === null) {
      emitEvent();
    } else {
      window.clearTimeout(this.searchFilterInterval);
      emitEvent();
    }
  }

  /** Pagination event emitting */
  public handleChangePagination(requestedPage: number) {
    // comportamento default: emitir a mudança para o parent e ele lidará com as requisições e lógicas
    // específicas para alteração dos dados
    if (!this.enableVirtualMode) {
      if (requestedPage !== this.paginationConfig.currentPage) {
        this.events.emit({
          eventType: "PAGINATION_CHANGED",
          requestedPage: requestedPage,
          previousPage: this.paginationConfig.currentPage,
        });
      }
    }
    // tabela virtual
    else {
      this.paginationConfig.currentPage = requestedPage;
      this.updateToRenderRows();
      this.unselectHeader();
      this.initCheckboxConfig();
    }
  }
}
