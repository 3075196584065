<div>

  Localidades removidas:
  <ul>
    <li *ngFor="let localidade of data.localidadesRemovidas">
      {{localidade.nome}}
    </li>
  </ul>

  Localidades atualizadas:
  <ul>
    <li *ngFor="let localidade of data.localidadesConflitantes">
      {{localidade.nome}}
    </li>
  </ul>

  Operadores removidos:
  <ul>
    <li *ngFor="let operador of data.operadoresRemovidos">
      {{operador.nome}}
    </li>
  </ul>

  Operadores inativos:
  <ul>
    <li *ngFor="let operador of data.operadoresInativos">
      {{operador.nome}}
    </li>
  </ul>

  Cliente removido:
  <p>{{data.idClienteInvalido}}</p>

</div>