<div class="card-perfil-wrapper">

    <div class="card-perfil-header-wrapper">

        <div class="perfil-info-wrapper">
            <i class="icon-clientes"></i>
            <span class="nomenclatura-perfil">PERFIL {{perfilIndex}}</span>
        </div>

        <div class="andamento-wrapper">
            <span class="andamento" [class.concluido]="perfil.cotaNumericaExecutada === perfil.cotaNumericaPrevista">{{perfil.cotaNumericaExecutada}}/{{perfil.cotaNumericaPrevista}}</span>
        </div>
    </div>

    <div class="card-perfil-body-wrapper">
        <app-perfil-caracteristica *ngFor="let caracteristicaPerfil of perfil.caracteristicas | sortBy: 'asc':'ordem'"
            [caracteristicaPerfil]="caracteristicaPerfil"></app-perfil-caracteristica>
    </div>

    <div class="card-perfil-footer-wrapper">
        <i class="icon-transferir" title="Transferir cota" (click)="emitEvent()"></i>
    </div>

</div>