<div cdkDropList (cdkDropListDropped)="drop($event)" class="lista-de-secoes">
  <!-- cdkDrag
  cdkDragLockAxis="y" -->
  <div *ngFor="let secao of models; let indexOfSection = index" [ngClass]="
      !verifySectionElments(secao) ? 'secao-item no-questions' : 'secao-item'
    ">
    <!-- [cdkDragData]="secao"
    (cdkDragStarted)="drag($event, secao)" -->
    <!-- zona da seção -->
    <div class="secao-header" cdkDragHandle>
      <!-- informativo -->
      <span>PERGUNTAS:
        {{ secao.elementosSecao ? secao.elementosSecao.length : "0" }}</span>
      <div class="secao-container" [ngClass]="verifySectionElments(secao) && 'has-questions'">
        <button class="dropdown-menu" [ngClass]="
            !algumElementoEstaSendoArrastado &&
            verifySectionElments(secao) &&
            'hover-actived'
          " (click)="
            verifySectionElments(secao) ? handleExpandButtonClick(secao) : null
          ">
          <i *ngIf="!verifySectionElments(secao)" class="fas fa-chevron-right"></i>
          <i *ngIf="verifySectionElments(secao)" [ngClass]="
              !algumElementoEstaSendoArrastado &&
              estaSecaoTemPerguntasExpandidas(secao) &&
              verifySectionElments(secao)
                ? 'fas fa-chevron-down enabled'
                : 'fas fa-chevron-right enabled'
            "></i>
        </button>
        <div class="info" (click)="handleEditSection(secao)">
          <h4 class="secao-titulo">
            SEÇÃO {{ indexOfSection + 1 }}: <b>{{ secao.nome.toUpperCase() }}</b>
          </h4>
          <h5 class="secao-descricao">{{ secao.descricao }}</h5>
        </div>
        <button class="adicionar-pergunta" (click)="handleAddSectionElem(secao.id)">
          <i class="fas fa-plus"></i>
          <h6>Adicionar Pergunta</h6>
        </button>
        <button class="deletar-secao" (click)="handleDeleteSection(secao)">
          <div class="trash" alt="Excluir Seção"></div>
        </button>
      </div>
    </div>
    <!-- zona sem perguntas -->
    <div class="no-questions-feedback" *ngIf="!algumElementoEstaSendoArrastado && !verifySectionElments(secao)">
      <h2>Sem perguntas</h2>
      <h3>Adicione perguntas ao seu questionário para realizar sua pesquisa</h3>
    </div>
    <!-- zona das perguntas -->
    <div class="lista-de-perguntas" *ngIf="
        !algumElementoEstaSendoArrastado &&
        estaSecaoTemPerguntasExpandidas(secao)
      ">
      <div [ngClass]="
          elementoSecao.id === this.idElementoSecaoResultanteDaAcaoDeCopia ||
          (activeSectionElementData && elementoSecao.ordem === ordemNewQuestion)
            ? 'pergunta copia'
            : 'pergunta'
        " *ngFor="
          let elementoSecao of secao.elementosSecao | sortSectionElem;
          let indexOfElementoSecao = index
        " [id]="'pergunta-' + elementoSecao.ordem">
        <div class="pergunta-header">
          <div (click)="
              !perguntaIsSimple(elementoSecao.pergunta.tipo) &&
                handleExpandAlternativesButtonClick(elementoSecao)
            " class="dropdown-wrapper" [ngClass]="
              esteTipoDePerguntaExpande(elementoSecao.pergunta.tipo)
                ? 'expande'
                : 'nao-expande'
            ">
            <i [ngClass]="handleClassStringFormat(elementoSecao)"></i>
          </div>
          <div class="pergunta-content">
            <div class="data" (click)="handleEditSectionElem(secao.id, elementoSecao.ordem)">
              <div class="top-info">
                <h3>Pergunta {{ indexOfElementoSecao + 1 }}</h3>
                <div class="spans">
                  <div class="span-wrapper" *ngIf="elementoSecao.pergunta.obrigatoria">
                    <span title="Obrigatório">Obrigatório</span>
                  </div>
                  <div class="span-wrapper">
                    <span [title]="formatTipo(elementoSecao.pergunta.tipo)">{{
                      formatTipo(elementoSecao.pergunta.tipo).replace(
                      "Resposta ",
                      ""
                      )
                      }}</span>
                  </div>
                  <div class="span-wrapper" *ngIf="perguntaTemMarcacao(elementoSecao.pergunta)">
                    <span title="Lógica">Lógica</span>
                  </div>
                </div>
              </div>
              <div class="bottom-info">
                <h4>{{ elementoSecao.pergunta.nome }}</h4>
              </div>
            </div>
            <div class="utils">
              <button title="Duplicar pergunta" class="duplicate" (click)="
                  handleDuplicateSectionElem(
                    secao.id,
                    elementoSecao.ordem,
                    elementoSecao
                  )
                ">
                <div alt="Duplicar"></div>
              </button>
              <button title="Remover pergunta" class="remove" (click)="
                  handleDeleteSectionElem(
                    secao.id,
                    elementoSecao.ordem,
                    elementoSecao.pergunta.nome
                  )
                ">
                <div alt="Excluir"></div>

              </button>
            </div>
          </div>
        </div>
        <div class="pergunta-alternativas-content" *ngIf="esteElementoSecaoTemAlternativasExpandidas(elementoSecao)">

          <div class="lista-de-alternativas" *ngIf="perguntaTemAlternativas(elementoSecao.pergunta.tipo)"
            (click)="handleEditSectionElem(secao.id, elementoSecao.ordem)">
            <div class="alternativa" *ngFor="
                let alternativa of elementoSecao.pergunta.alternativas
                  | sortSectionElemItem;
                let indexOfAlternativa = index
              ">
              <h4>{{ indexOfAlternativa + 1 }}. {{ alternativa.descricao }}</h4>
            </div>
          </div>

          <div class="lista-de-alternativas" *ngIf="perguntaIsEscalaNumerica(elementoSecao.pergunta.tipo)"
            (click)="handleEditSectionElem(secao.id, elementoSecao.ordem)">
            <div class="alternativa">
              <h4>
                Escala de {{ elementoSecao.pergunta.faixaPergunta.intervaloInicio }} até {{
                elementoSecao.pergunta.faixaPergunta.intervaloFim }}
              </h4>
            </div>
          </div>

          <div class="lista-de-grade" *ngIf="isItGrade(elementoSecao.pergunta.tipo)"
            (click)="handleEditSectionElem(secao.id, elementoSecao.ordem)">
            <table>
              <thead>
                <tr class="thead">
                  <th></th>
                  <th *ngFor="
                      let headerColumn of elementoSecao.pergunta.alternativas;
                      let headerColumnIndex = index
                    " [title]="headerColumn.descricao">
                    {{ headerColumn.descricao }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let line of elementoSecao.pergunta.titulosGrade" class="tbody">
                  <td [title]="line.descricao">
                    {{ line.descricao }}
                  </td>
                  <td *ngFor="
                      let checkoboxOrRadio of elementoSecao.pergunta
                        .alternativas
                    ">
                    <div [ngClass]="
                        elementoSecao.pergunta.tipo === 'GRADE_UNICA'
                          ? 'radio-square'
                          : 'checkbox'
                      "></div>
                  </td>
                  <td></td>
                </tr>
                <tr class="tbody footer">
                  <td></td>
                  <td *ngFor="
                      let checkoboxOrRadioFooter of elementoSecao.pergunta
                        .alternativas
                    "></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <button class="adicionar-pergunta-listagem" (click)="handleAddSectionElem(secao.id)">
        <i class="fas fa-plus"></i>
        <h6>Adicionar Pergunta</h6>
      </button>
    </div>
  </div>
</div>
