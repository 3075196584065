<div
  [ngClass]="{
    alternative: true,
    blockActions: actionsBlockedBySituation,
    selected: alternativa.isSelecionada,
    preselect: alternativa.isRespostaOriginal,
    checkbox: renderType === 'checkbox'
  }"
>
  <input
    *ngIf="renderCheckbox"
    (click)="handleCheck(alternativa)"
    type="button"
    name="selectable-alternatives"
  />

  <textarea
    [ngClass]="!renderCheckbox && 'singleRenderMode'"
    (click)="handleInputFocus(alternativa)"
    #textareaRef
    class="text-input"
    maxlength="255"
    [placeholder]="alternativa.enunciado"
    (input)="handleInput($event)"
    [disabled]="!this.selecionavel && !alternativa.isSelecionada"
    [(ngModel)]="value"
  ></textarea>
</div>
