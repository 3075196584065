import { Action } from '@ngrx/store';
import { ActionType } from '../actionType';
import { PassoCadastro } from '../passoCadastro';

/**
 * Classe base para uma Action no sistema
 */
export abstract class AbstractAction implements Action {
  /**
   * Passo do cadastro a qual a action se refere
   */
  abstract passoCadastro: PassoCadastro;

  /**
   * Tipo da action, a partir dela que se é definida a acao a ser tomada pelos
   * reducers
   */
  abstract type: ActionType;

  /**
   * Payload da action que sera utilizada pelos reducers
   */
  payload: any;

  /**
   * Marcacao se trata-se de uma acao de replay
   * ou duma action lancada pela primeira vez
   */
  isReplayAction: boolean = false;

  /**
   * Marcacacao que indica se uma action pode
   * ser reexecutada
   */
  isReplayable: boolean = false;

}
