<button
  class="ic-button"
  [disabled]="disabled"
  [ngClass]="distributionMode === 'DISTRIBUTION' && 'distribution-button'"
>
  <div
    class="icon-img"
  ></div>
  <span></span>
  {{ title }}
</button>
