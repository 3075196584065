<div class="operador-wrapper">

  <div class="operador-header">

    <div class="operador-data">

      <div class="data-item">
        <img class='profile-pic' [src]="operadorVinculado.foto || 'https://s3.amazonaws.com/alfa-tensai-teste/default-images/placeholder-upload.png'" />
      </div>

      <div class="data-item">
        {{operadorVinculado.nome}}
      </div>

      <span class="divider">/</span>

      <div class="data-item">
        Matricula {{operadorVinculado.matricula}}
      </div>

      <span class="divider">/</span>

      <div class="data-item">
        Vínculo: {{ operadorVinculado.periodoVinculo.fim }}
      </div>

      <div *ngIf="operadorAtingiuMetas">
        <span class='metas-atingidas'>Metas atingidas :)</span>
      </div>

    </div>

    <div class="operador-actions">
      <div class="profiles-search-input">
        <input type="text" placeholder="Filtre por perfis" />
        <div class="icon-busca-wrapper">
          <i class="icon-busca"></i>
        </div>
      </div>
      <div class="card-options-wrapper">
        <div class="reajustar-cota-wrapper">
          <i class="icon-ajuste" title="Ajustar cotas" (click)="requestAjusteCotas()"></i>
        </div>
        <div class="minimizar-wrapper" (click)="toggleExpand()">
          <div *ngIf="!operadorVinculado.expanded">
            <i class="icon-seta-baixo" title="Maximizar"></i>
          </div>
          <div class="r180" *ngIf="operadorVinculado.expanded">
            <i class="icon-seta-baixo" title="Minimizar"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="operador-perfis" *ngIf="operadorVinculado.expanded">
    <div class="aux row">
      <div *ngFor="let perfil of operadorVinculado.perfis; let i = index" class="col-xl-3 col-lg-4 col-sm-6 col-12 operador-perfil" [hidden]="perfil.hidden">
        <app-perfil-card style="width: 100%;" [perfil]="perfil" [perfilIndex]="i+1"
          (onTransferenciaButtonClick)="requestTransferenciaCotas(perfil)"></app-perfil-card>
      </div>
    </div>
  </div>

</div>
