import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormGroupDirective, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { NotificatorService } from "app/notificador/notificator.service";
import { Modal } from "app/util/componente/modal-generico/modal-generico.component";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import numberFormatter from "app/util/misc/numberFormatter";
import { stringFormatter } from "app/util/misc/stringFormatter";
import { ToastrService } from "ngx-toastr";
import { CadastroBasico } from "../../cadastro/cadastroBasico";
import { Localidade } from "../localidade";
import { LocalidadePai } from "../localidadePai";
import { LocalidadeService } from "../servico/localidade.service";
import {
  LocalidadeCadastroEvent,
  LocalidadeCadastroEventType,
} from "./localidadeCadastroEvent";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-localidade-cadastro",
  templateUrl: "./localidade.cadastro.component.html",
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./localidade.cadastro.component.scss"],
})

// tslint:disable-next-line: max-line-length
export class LocalidadeCadastroComponent
  extends CadastroBasico<Localidade>
  implements AfterContentChecked
{
  localidades: Localidade[] = [];

  localidadesFilhas: Localidade[] = [];

  // Localização detalhada do nominatim
  localidadesDetalhadas = [];

  localidadeSelecionadaId: number;

  ultimaBusca: string = "";

  // Input de busca e controle do tempo da função
  buscaLocalidade: string = "";

  debounceTime: ReturnType<typeof setTimeout>;

  alertaModal: Modal;

  localidade: Localidade;

  @Input() edicao: boolean = false;
  // responsavel por receber os dados da tabela de localidade para a edição
  @Input() localidadeEdicao: Localidade;
  // emite o evento para fechar o modal de cadastro
  @Output() eventEmitter: EventEmitter<LocalidadeCadastroEvent> =
    new EventEmitter();

  constructor(
    protected securityService: SecurityService,
    protected localidadeService: LocalidadeService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected changeDetector: ChangeDetectorRef,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "localidade",
      localidadeService,
      activatedRoute,
      router,
      toastr,
      notificatorService,
      modalService
    );
  }

  /**
   * Garante que o botão de adicionar localidade so seja exibido depois
   * que todos os dados da tela tenham sido checados
   */
  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.isEdicao = this.edicao;

    this.localidade = this.localidadeEdicao;
    if (!this.localidade) {
      this.localidade = new Localidade();
    }

    if (this.localidade.localidadePai == null) {
      this.localidade.localidadePai = new LocalidadePai();
    }

    this.initFormGroup();
    super.ngOnInit();
  }

  /**
   * @override
   */
  getRedirectPath(): string[] {
    // tslint:disable-next-line: max-line-length
    const authoritiesNeededToListagem = [
      Authority.LISTAR_LOCALIDADE,
      Authority.ATUALIZAR_LOCALIDADE,
    ];

    if (this.securityService.userHasAuthorities(authoritiesNeededToListagem)) {
      return ["localidade"];
    }

    return [""];
  }

  /**
   * Callback chamado quando o usuário "concluir" ou encerrar
   * o cadastro de uma localidade. Esta função exibe uma alerta de confirmação
   * que irá perguntar se o usuário realmente deseja encerrar o cadastro.
   */
  encerrarCadastro() {
    super.canDeactivate().then((modalReturn) => {
      if (modalReturn) {
        this.sendCanceledEvent();
      }
    });
  }

  /**
   * Método auxiliar que
   * publica no @Output deste componente um evento
   * do tipo CANCELED.
   */
  sendCanceledEvent() {
    this.eventEmitter.emit({
      type: LocalidadeCadastroEventType.CANCELED,
      payload: {},
    });
  }

  /**
   * Método auxiliar que
   * publica no @Output deste componente um evento
   * do tipo SUCCESS.
   */
  sendSuccessEvent(message: string) {
    this.eventEmitter.emit({
      type: LocalidadeCadastroEventType.SUCCESS,
      payload: { message },
    });
  }

  /**
   * Método auxiliar que
   * publica no @Output deste componente um evento
   * do tipo FAILURE.
   */
  sendFailureEvent(cause: string) {
    this.eventEmitter.emit({
      type: LocalidadeCadastroEventType.FAILURE,
      payload: { message: cause },
    });
  }

  /*closeModalCadastro() {

    this.router.navigate(this.getRedirectPath(), {
      queryParams: { redirect: true },
    });
    this.alertaModal = new Modal();
    this.alertaModal.show = false;

    this.eventEmitter.emit(this.alertaModal);
  }*/

  // sobrescrita da função salvar generico com alguns detalhes diferentes
  salvar(localidade: Localidade, form?: FormGroupDirective) {
    this.entidadeEdicaoCallback(localidade);
    const operacao = this.edicao ? "atualizar" : "salvar";

    const operacaoCapitalizada =
      stringFormatter.capitalizeFirstLetter(operacao);
    // tslint:disable-next-line:max-line-length
    const alertaDataTitleDialog = `${operacaoCapitalizada} ${this.nomeEntidade}`;

    let requestObservable;

    if (this.edicao) {
      const entidade = this.prepararEntidadeParaPersistencia(localidade);
      const entidadeToUpdate = {
        ...entidade,
        localidadePai: { id: entidade.localidadePai.id },
        localidadesFilhas: entidade.localidadesFilhas.map(
          (localidadeFilha) => ({ id: localidadeFilha.id })
        ),
      };
      requestObservable = this.localidadeService.atualizarLocalidade(
        entidadeToUpdate,
        false
      );
    } else {
      const entidadeToSave = this.prepararEntidadeParaPersistencia(localidade);
      requestObservable = this.localidadeService.salvar(entidadeToSave);
    }

    // tslint:disable-next-line:max-line-length
    requestObservable.subscribe(
      (dado) => {
        // Se for cadastrada uma localidade sem pai será mostrado o alerta confirmando o cadastro
        if (dado.localidadePai === null && !this.edicao) {
          this.modalService.showModal({
            title: alertaDataTitleDialog,
            messageModal: "Localidade pai cadastrada com sucesso",
            isOnlyConfirmation: true,
            btnTitlePositive: "Entendi",
          });
          form.onReset();
          return;
        } else if (this.edicao) {
          this.modalService.showModal({
            title: alertaDataTitleDialog,
            messageModal: "Localidade pai atualizada com sucesso",
            isOnlyConfirmation: true,
            btnTitlePositive: "Entendi",
          });
          this.sendCanceledEvent();
          form.onReset();
        }

        // se não for uma edição, a localidade cadastrada eh inserida na lista
        if (!this.edicao) {
          // array onde ficarão todas as localidades filhas após serem cadastradas
          this.localidadesFilhas.push(dado);
          form.onReset();
          this.setarFormControlLocalidadePai(dado);

          return;
        }
        // se for uma edição será mostrada apenas um alerta confirmando a edição
        this.modalService.showModal({
          title: alertaDataTitleDialog,
          messageModal: "Localidade editada",
          isOnlyConfirmation: true,
          btnTitlePositive: "Entendi",
          positiveCallback: () => this.sendSuccessEvent(""),
        });

        this.showNotificationSuccess();

        // tslint:disable-next-line:align
      },
      (error) => {
        let message;

        if (error.status === 404) {
          // tslint:disable-next-line: max-line-length
          message =
            "Algum outro usuário removeu a localidade enquanto a edição estava sendo processada.";
        } else {
          message = error.error ? error.error : error;
        }

        this.modalService.showModal({
          title: alertaDataTitleDialog,
          messageModal: `Houve um problema ao realizar a operação: ${message}`,
          isOnlyConfirmation: true,
          btnTitlePositive: "Entendi",
          positiveCallback: () => this.sendFailureEvent(""),
        });
      }
    );
  }

  // listar com detalhes preenchendo os campos de latitude, longitude e raio
  carregarLocalidadeComDetalhe(input: any = this.buscaLocalidade) {
    this.buscaLocalidade = input.target.value ? input.target.value : "";

    this.buscaLocalidade = this.buscaLocalidade
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    clearTimeout(this.debounceTime);

    this.debounceTime = setTimeout(() => {
      this.localidadesDetalhadas =
        this.localidadeService.listarLocalidadesComDetalhes(
          this.buscaLocalidade
        );
    }, 500);
  }

  // Ao selecionar o item, preencher os campos de latitude,
  selecionarLocalidade(id: number) {
    this.localidadeSelecionadaId = id;

    this.localidadesDetalhadas.map((item) => {
      if (item.place_id === this.localidadeSelecionadaId) {
        const [latmin, latmax, lonmin, lonmax] = item.boundingbox;
        const latCenter = this.calculateCenter(Number(latmax), Number(latmin));
        const lonCenter = this.calculateCenter(Number(lonmax), Number(lonmin));
        const dist = this.calculateRadius(latCenter, latmax, lonCenter, lonmax);
        this.setarFormControlAutoComplete(item.lat, item.lon, dist);
      }
    });
  }

  calculateCenter(coor1: number, coor2: number) {
    return (coor1 + coor2) / 2;
  }

  convertToRadius(value: number) {
    return (Number(value) * Math.PI) / 180;
  }

  calculateRadius(
    latMin: number,
    latMax: string,
    lonMin: number,
    lonMax: string
  ) {
    const earthRadius = 6371; // in kilometers
    const dLat = this.convertToRadius(Number(latMax) - Number(latMin));
    const dLon = this.convertToRadius(Number(lonMax) - Number(lonMin));
    const latSeno = Math.sin(dLat / 2);
    const lonSeno = Math.sin(dLon / 2);

    const a =
      Math.pow(latSeno, 2) +
      Math.pow(lonSeno, 2) *
        Math.cos(this.convertToRadius(Number(latMin))) *
        Math.cos(this.convertToRadius(Number(latMax)));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const dist = earthRadius * c;

    return dist;
  }

  setarFormControlAutoComplete(
    latitude: string,
    longitude: string,
    dist: number
  ) {
    this.formGroup.get("latitude").setValue(latitude);
    this.formGroup.get("longitude").setValue(longitude);
    this.formGroup.get("raio").setValue(dist);
  }

  setarFormControlLocalidadePai(localidade: Localidade) {
    this.formGroup
      .get("localidadePai")
      .get("id")
      .setValue(localidade.localidadePai.id);
    this.formGroup
      .get("localidadePai")
      .get("nome")
      .setValue(localidade.localidadePai.nome);
  }
  // lista todas as localidades filtradas pelo input de localidade pai
  carregarLocalidades(input: any = this.ultimaBusca) {
    this.ultimaBusca = input.target.value ? input.target.value : "";

    const palavraChave = new PalavraChave(this.ultimaBusca.trim().split(" "));

    this.localidadeService.listarTodos(palavraChave).subscribe({
      next: (localidades) => {
        this.localidades.length = 0;

        // verificando se o pai retornado da api não é o mesmo que o filho
        // caso seja, será removido da lista, pois uma localidade não pode ser pai dela mesmo
        // garante que o usuario nao tente editar uma localidade com o pai sendo ela mesmo
        if (localidades.length > 0) {
          localidades.forEach((localidade) => {
            const localidadeFormGroup = this.localidadeEdicao.id;
            if (localidade.id === localidadeFormGroup) {
              const indexRemove = localidades.indexOf(localidade);
              localidades.splice(indexRemove, 1);
            }
          });
        }
        palavraChave.palavrasChave.forEach((palavraChave) => {
          if (palavraChave !== null) {
            this.localidades = localidades;
          }
        });
        // se for uma edicao, marca as localidades pai e filhas
        // if (this.isEdicao && this.localidade.localidadePai) {
        //   localidades.forEach((localidade) => {
        //     if (localidade.id === this.localidade.localidadePai.id) {
        //       console.log('match pai', localidade);
        //       localidade.isPai = true;
        //     }
        //   });
        // }

        // if (this.isEdicao && this.localidade.localidadesFilhas) {
        //   localidades.forEach((localidade) => {
        //     this.localidade.localidadesFilhas.forEach((localidadeFilha) => {
        //       if (localidadeFilha.id === localidade.id) {
        //         localidade.isFilho = true;
        //       }
        //     });
        //   });
        // }
        // tslint:disable-next-line:align
      },
      error: (error) => {
        const operacao = this.isEdicao ? "atualizar" : "cadastrar";
        const operacaoCapitalizada =
          stringFormatter.capitalizeFirstLetter(operacao);

        this.modalService.showModal({
          title: `${operacaoCapitalizada} localidade`,
          messageModal:
            "Não foi possível carregar as localidades, tente novamente",
          isOnlyConfirmation: true,
          btnTitlePositive: "Entendi",
          positiveCallback: () => this.router.navigate([""]),
        });
      },
    });
  }

  initFormGroup() {
    this.formGroup = Localidade.formGroup(this.localidade);
    this.formControls = <{ [key: string]: UntypedFormControl }>(
      this.formGroup.controls
    );
  }

  entidadeEdicaoCallback(localidadeAtual: Localidade) {
    if (this.edicao) {
      // caso o campo nome não seja preenchido, será salvo uma localidade sem pai
      if (
        localidadeAtual.localidadePai.nome == null ||
        localidadeAtual.localidadePai.nome == ""
      ) {
        localidadeAtual.localidadePai = new LocalidadePai();
      }
    }
  }

  prepararEntidadeParaPersistencia(localidade: Localidade): Localidade {
    if (this.edicao) {
      localidade.localidadesFilhas = this.localidade.localidadesFilhas;
    } else {
      // se o input do form de localidade pai for vazio, seta a localidade pai como null
      if (
        localidade.localidadePai.nome == null ||
        localidade.localidadePai.nome === ""
      ) {
        localidade.localidadePai = new LocalidadePai();
      }
    }
    const longitudeFormatada = numberFormatter.replacePerPoint(
      localidade.longitude
    );
    const latitudeSFormatada = numberFormatter.replacePerPoint(
      localidade.latitude
    );

    localidade.longitude = parseFloat(longitudeFormatada);
    localidade.latitude = parseFloat(latitudeSFormatada);

    // caso o usuario digite um nome de uma localidade  que nao existe,
    // entao a localidade atual não possuirá pai
    if (
      localidade.localidadePai.id === null ||
      localidade.localidadePai === null
    ) {
      localidade.localidadePai = new LocalidadePai();
    }
    return localidade;
  }

  alterarSelecaoFilho(localidade: Localidade) {
    const indiceARemover = this.localidadesFilhas.indexOf(localidade);
    this.localidadesFilhas.splice(indiceARemover, 1);
    const payload = [localidade.id];
    this.crudService.deletar(payload).subscribe(() => {});
  }

  buildLocalidadeFilhaTitle(localidade: Localidade): string {
    const spaceVariable = "--==--";
    // tslint:disable-next-line:max-line-length
    const mensagem = `Nome: ${localidade.nome} ${spaceVariable} Latitude: ${localidade.latitude} ${spaceVariable} Longitude: ${localidade.longitude} ${spaceVariable} Raio: ${localidade.raio}`;

    const mensagemFormatada = mensagem.replace(
      new RegExp(`${spaceVariable}`, "g"),
      " "
    );
    return mensagemFormatada;
  }
  localidadePaiSelecionada(id: any) {
    this.formGroup.get("localidadePai").get("id").setValue(id);
    // this.localidades = [];
  }

  /**
   * Retorna o conjunto de autoridades (Authorities) do usuário autenticado.
   */
  getAuthenticatedUserAuthorities() {
    const authenticatedUser = this.securityService.getAuthenticatedUser();
    return authenticatedUser.authorities;
  }

  /**
   * Callback executado ao clicar no botão "Cancelar" em Edição.
   */
  cancelarEdicao() {
    if (
      this.securityService.userHasAuthorities([Authority.ATUALIZAR_LOCALIDADE])
    ) {
      super.canDeactivate().then((modalReturn) => {
        if (modalReturn) {
          this.sendCanceledEvent();
        }
      });

      return;
    }

    this.sendCanceledEvent();
  }

  // EVENTOS NÃO ULTILIZADOS

  // onFilhoSelected(event) {
  //   const filhoIdString = event.target.value;
  //   const filhoId = Number(filhoIdString);

  //   if (!filhoId || isNaN(filhoId)) {
  //     return;
  //   }

  //   this.alterarSelecaoFilho(filhoId);
  //   const filhoSelect = event.target;
  //   filhoSelect.value = null;
  // }

  // adicionarFilhos() {
  //   const localidadesFilha: Localidade = this.formGroup.value;

  //   if (localidadesFilha.nome != null) {
  //     this.localidadesFilhas.push(localidadesFilha);
  //   }
  // }

  // onLocalidadePaiSelecionada(idLocalidadeString: any) {

  //   const idLocalidade = Number(idLocalidadeString);

  //   const localidadesMatch = this.localidades.filter(localidade => localidade.id === idLocalidade);

  //   let localidade = null;
  //   if (localidadesMatch.length > 0) {
  //     localidade = localidadesMatch[0];

  //     this.formGroup.get('localidadePai').setValue(localidade);
  //   } else {
  //     this.formGroup.get('localidadePai').setValue(null);
  //   }

  //   // resetando as localidades pai
  //   this.localidades.forEach(localidade => localidade.isPai = false);
  //   if (localidade) {
  //     localidade.isPai = true;
  //   }
  // }
}
