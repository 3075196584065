<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-clientes"></i>
      <h2 [routerLink]="'/cliente-beta'" routerLinkActive="active">Clientes</h2>
      <span>/</span>
      <h4>Clientes cadastrados</h4>
    </div>
    <div class="action-header" *ngIf="getAuthenticatedUserAuthorities().includes(authority.CADASTRAR_CLIENTE)">
      <button class="sg-btn sg-secondary" [routerLink]="'/cliente/cadastro'" title="adicionar novo cliente">ADICIONAR NOVO</button>
    </div>
  </div>
  <div class="content-clientes-cadastrados">

    <!--
      EXIBINDO OPÇÃO DOWNLOAD = FALSE
      exportação de relatorio de clientes n é contemplado na release
      básica do tensai.
    -->
    <app-tabela-filtravel #tabela [placeholder]="placeholder" (eventEmitter)="manipularEventoTabela($event)" [filterService]="clienteService"
    [nomeAtributosExibidos]="nomeAtributosTabela" [nomeColunas]="nomeColunasTabela" [exibirOpcaoDownload]="false" [removePermissions]="clienteRemovePermissions"
    [initialValue]="initialSearchValue"
    ></app-tabela-filtravel>

  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
