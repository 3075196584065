import { Injectable } from '@angular/core';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { CrudService } from 'app/servico/requestService/crudService';
import { BehaviorSubject, Observable, delay } from 'rxjs';
import { Administrador } from '../../management/instancia-cadastro/Administrador';

@Injectable({
  providedIn: 'root',
})
export class AdministradorService extends CrudService<Administrador> {

  updateInfo = new BehaviorSubject(false);

  protected resourcePath = `${apiLocation}/administrador`;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/administrador`);
  }

  getIdAdministradorPorIdContaUsuario(id:string):  Observable<any> {
    const requestUrl = `${apiLocation}/administrador/buscar/${id}`;

    return this.requestService.get(requestUrl);
  }

  /**
   * Atualiza os dados do administrador
   *
   * @param data: payload com os novos dados
   */
  putAdministrator(data: Administrador):  Observable<Administrador> {
    const requestUrl = `${apiLocation}/administrador/perfil`;
    this.updateInfo.next(true);
    return this.requestService.put(requestUrl, data);
  }

  /**
   * Retorna o valor de updateInfo (atribudo utiliziado para interceptar mudanças nos dados do admin)
   * @returns updateInfo
   */
  handleUpdateInfo(): Observable<boolean> {
    return this.updateInfo.asObservable().pipe(delay(500));
  }

  /**
   * Obtem os dados atualizados do administrador
   * @returns dados do admin
   */
  getAdministrator():  Observable<Administrador> {
    const requestUrl = `${apiLocation}/administrador/perfil`;
    this.updateInfo.next(false);
    return this.requestService.get(requestUrl);
  }

}
