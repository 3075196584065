<header>
  <p>{{questionData.ordem}} - {{ questionData.enunciado }}</p>
  <div class="badges">
    <small *ngIf="questionData.obrigatoria">Pergunta obrigatória</small>
  </div>
</header>
<section>
  <div class="image-container">
    <img [src]="questionData.respostas.entrevistador" />
    <a [href]="questionData.respostas.entrevistador" class="download-button">
      <i class="fa-regular fa-arrow-down-to-line"></i> Baixar imagem
    </a>
  </div>
</section>
