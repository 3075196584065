import { Component, Input, OnInit } from '@angular/core';
import { InterviewFormatter } from 'app/modulos/produtividade-beta/components/interviewer-formattter/interview-formatter.service';
import { IOperadorData } from 'app/modulos/produtividade-beta/interfaces';
import { formatToMaxDecimalPlaces } from "app/util//formatter";
import dates from 'app/util/misc/dates';

@Component({
  selector: 'app-general-evaluation',
  templateUrl: './general-evaluation.component.html',
  styleUrls: ['./general-evaluation.component.scss']
})
export class GeneralEvaluationComponent extends InterviewFormatter implements OnInit {

  @Input() operador: IOperadorData;
  averageTime: string = ''

  constructor() { super() }

  ngOnInit(): void {
    this.averageTime = dates.timeFormatter(this.operador.tempo_medio);
  }

}
