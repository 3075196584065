import { Component, Input } from '@angular/core';
import { Caracteristica } from '../../../localidades/localidade';

@Component({
  selector: 'app-perfil-caracteristica',
  templateUrl: './perfil.caracteristica.component.html',
  styleUrls: ['./perfil.caracteristica.component.scss'],
})
export class PerfilCaracteristicaComponent {

  @Input('caracteristicaPerfil') caracteristicaPerfil: Caracteristica;

}
