import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IItemBreadcrumb } from 'app/componentes/breadcrumb/breadcrumb.interface';
import { ModalService } from "app/componentes/modal/modal.service";
import { NotificatorService } from "app/notificador/notificator.service";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import validadorCnpj from "app/util/validador/validadorCnpj";
import validadorCpf from "app/util/validador/validadorCpf";
import { DefaultImagem } from "styles/constant";
import { ICreateContractorPayload, IGroupId, IUpdateContractorPayload } from "../interfaces";
import { ContratanteService } from "../services/contratante.service";
import { EMAIL_REGEX } from "../utils/ContractorRegex";
import { buildPostPayload, buildPutPayload } from "../utils/buildRegisterPayload";
import { AdminInformationComponent } from "./components/admin-information/admin-information.component";
import { backendErrors, errorMessages } from "../erros";
import { ModalDataControllerService } from "../services/modal-data-controller.service";

@Component({
  selector: "app-cadastro-contratante",
  templateUrl: "./cadastro-contratante.component.html",
  styleUrls: ["./cadastro-contratante.component.scss"],
})
export class CadastroContratanteComponent implements OnInit {
  @ViewChild(AdminInformationComponent) adminComponent: AdminInformationComponent;

  form: FormGroup | any;
  newAdmin: FormGroup;
  isEdicao: boolean = false; // True = editando cadastro de contratante
  previousValues: { [key: string]: string } = {}; // Recebe os valores iniciais do contratante no modo edição
  changedValues: string[] = []; // Armazena os formControlNames dos inputs que tiveram seus valores alterados
  dataBreadcrumb: IItemBreadcrumb[];
  isLoading: boolean = false;
  documentLengthControl: number; // Controla o tamanho do input de CPF/CNPJ
  groupId: IGroupId;
  schema_name?: string;
  themeName = 'default';
  submitted = false;
  modalCallbackIsValid: boolean;
  adminPhone: string = null;

  previousAdminValue = {
    nome: '',
    cpf: '',
    login: '',
  };

  modalData = new ModalData();
  tenant = this.activatedRoute.snapshot.params.tenant;

  constructor(
    private formBuilder: FormBuilder,
    protected activatedRoute: ActivatedRoute,
    private router: Router,
    private contratanteService: ContratanteService,
    private notificatorService: NotificatorService,
    private modalService: ModalService,
    private modalController: ModalDataControllerService
  ) {}

  ngOnInit() {
    this.isEdicao = this.tenant === null || this.tenant === undefined ? false : true;

    this.form = this.formBuilder.group({
      basicInformation: this.formBuilder.group({
        nome: ["", [Validators.required, Validators.maxLength(100)]], // Nome do Contratante
        cpf_cnpj: ["", [Validators.required]], // Pode ser CPF ou CNPJ
        prefixo_url: ["", [Validators.required, Validators.maxLength(63)]], // Nome do Dominio
        tipo_licenca: ["Tensai Pro", [Validators.required]], // Tipo de liderança
        ativo: false, // Status do Contratante
      }),
      adminInformation: this.formBuilder.group({
        nome: ["", [Validators.required, Validators.maxLength(100)]], // nome do Administrador
        cpf: ["", [Validators.required, validadorCpf]], // CPF do Administrador
        login: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]], // E-mail do Administrador
      }),
      customization: this.formBuilder.group({
        logotipo_light: [
          DefaultImagem.logotipo_light,
          [Validators.required],
        ], // Logotipo 1 do tema light
        login: [
          DefaultImagem.login,
          [Validators.required],
        ], // Imagem da tela de login
        tema: ["default", [Validators.required]], // Tema escolhido
      }),
    });

    this.newAdmin = this.formBuilder.group({
      nome: ["", [Validators.required, Validators.maxLength(100)]],
      cpf: ["", [Validators.required, validadorCpf]],
      login: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    })

    if (this.isEdicao) {
      this.retrieveHirerValues();
    }

    /**
     * Menu superior para navegação
     * Sendo inicialiizado no onInit, pois, a mudança do atributo isEdicao ocorre após a render
     */
    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/contratante-beta",
        active: false,
      },
      {
        itemName: `${this.isEdicao ? "Editar perfil" : "Cadastrar novo"}`,
        itemLink: `/contratante-beta/${
          this.isEdicao ? "atualizar" : "cadastro"
        }`,
        active: true,
      },
    ];

    this.modalCallbackIsValid = this.modalController.getFlag();
  }

  adminInitialValues() {
    this.form.get('adminInformation').setValue({
      nome: this.previousAdminValue.nome,
      cpf: this.previousAdminValue.cpf,
      login: this.previousAdminValue.login,
    })
  }

  /**
   * Recupera os dados do contratante
   * @param tenant
   */
  retrieveHirerValues() {
    this.contratanteService.getContractorData(this.tenant).subscribe({
      next: (contractorData) => {
        this.themeName = contractorData.customizacao.tema
        this.form.patchValue({
          basicInformation: {
            nome: contractorData.nome,
            cpf_cnpj: contractorData.cpf_cnpj,
            prefixo_url: contractorData.prefixo_url,
            tipo_licenca: contractorData.tipo_licenca,
            ativo: contractorData.ativo,
          },
          adminInformation: {
            nome: contractorData.administrador.pessoa.nome,
            cpf: contractorData.administrador.pessoa.cpf,
            login: contractorData.administrador.usuario.login,
          },
          customization: {
            logotipo_light: contractorData.customizacao.imagem.logotipo_light !== null ? contractorData.customizacao.imagem.logotipo_light : DefaultImagem.logotipo_light,
            login: contractorData.customizacao.imagem.login !== null ? contractorData.customizacao.imagem.login : DefaultImagem.login,
            tema: contractorData.customizacao.tema,
          },
        }),
        this.groupId = {
          admin: contractorData.administrador.id,
          person: contractorData.administrador.pessoa.id,
          user: contractorData.administrador.usuario.id
        }
        this.schema_name = contractorData.schema_name
        this.adminPhone = contractorData.administrador.contato.telefone;
        this.previousAdminValue = {
          nome: contractorData.administrador.pessoa.nome,
          cpf: contractorData.administrador.pessoa.cpf,
          login: contractorData.administrador.usuario.login,
        };
      },
      complete: () => this.getAllInputFields()
    })
  }

  /**
   * Adiciona e/ou remove a validação de CPF/CNPJ de acordo com o tamanho do valor digitado no input
   *
   * Toda vez que for chamada remove a validação de CPF e CNPJ e atualiza essas remoções, após isso verifica o tamanho e adiciona a validação correspondente
   * A formatação visual é realizada através da propriedade mask="CPF_CNPJ" da lib ngx-mask incluida diretamente no input
   *
   * @param event = evento emitido do componente filho para controlar os caracteres do input CPF/CNPJ
   */

  changeDocInput(event) {
    this.documentLengthControl = event["value"].length;
    const paste = event["paste"];

    if (paste) {
      this.form.get("basicInformation.cpf_cnpj").removeValidators(validadorCpf);
      this.form
        .get("basicInformation.cpf_cnpj")
        .removeValidators(validadorCnpj);
      this.form.get("basicInformation.cpf_cnpj").updateValueAndValidity();
    }
    if (this.documentLengthControl > 14) {
      this.form.get("basicInformation.cpf_cnpj").removeValidators(validadorCpf);
      this.form.get("basicInformation.cpf_cnpj").setValidators(validadorCnpj);
    } else {
      this.form
        .get("basicInformation.cpf_cnpj")
        .removeValidators(validadorCnpj);
      this.form.get("basicInformation.cpf_cnpj").setValidators(validadorCpf);
    }
  }

  /**
   * Obtem todos os formControlNames dos inputs
   */
  getAllInputFields() {
    for (const outerKey in this.form.value) {
      if (Object.hasOwnProperty.call(this.form.value, outerKey)) {
        const nestedObj = this.form.value[outerKey];
        for (const innerKey in nestedObj) {
          if (Object.hasOwnProperty.call(nestedObj, innerKey)) {
            this.listenValueChanges(`${outerKey}.${innerKey}`);
          }
        }
      }
    }
  }

  /**
   * Intercepta alterações de valor nos inputs e salva os formControlNames correspondentes no array changedValues
   * @param controlName: string -  formBuildName + formControlName
   */
  listenValueChanges(controlName: string) {
    const control = this.form.get(controlName);

    // Armazena o valor inicial do campo
    this.previousValues[controlName] = control.value;
    // Assinar as alterações de valor do FormControl
    control.valueChanges.subscribe((value) => {
      if (this.removeMask(value, controlName) !== this.previousValues[controlName]) {
        if (!this.changedValues.includes(controlName)) {
          this.changedValues.push(controlName);
        }
      } else {
        // Remover o campo do array se o valor for restaurado
        const index = this.changedValues.indexOf(controlName);
        if (index !== -1) {
          this.changedValues.splice(index, 1);
        }
      }
    });
  }

  /**
   * Remove os caracteres especiais provenientes das mascaras dos inputs de CPF
   * @param value: valor do input
   * @param controlName: nome do input
   * @returns valor sem os caracteres especiais para comparação
   */
  removeMask(value: string, controlName: string): string {
    if(value !== null && controlName === 'adminInformation.cpf') {
      let newString = value.replace(/[^a-zA-Z0-9]/g, '');
      return newString;
    }
  }

  /**
   * Atribui o nome tema escolhido pelo usuário ao sistema
   */
  handleThemeChoice() {
    // TODO: add logica para renderizar o tema escolhido do contratante no cadastro (persistir no localStorage tb)
  }

  handleCloseButton() {
    this.closeModal();
  }

  /**
   * Toca todos os campos do formulario para indicar os erros para o usuário
   *
   * @param form: Atributo que representa o formulario:
   */
  markFieldsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);

      if (control instanceof FormGroup) {
        this.markFieldsAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
   * Controla o botão de registro realizando a chamada da modal
   */
  handleRegisterButton() {
    this.submitted = true;
    this.markFieldsAsTouched(this.form);

    if (this.form.status === "VALID") {
      this.registerModal();
    }
  }

  /**
   * Controla o botão de salvar edição realizando a chamada da modal
   */
  handleSaveButton() {
    this.submitted = true;
    this.markFieldsAsTouched(this.form);
    this.modalCallbackIsValid = this.modalController.getFlag();
    if (this.form.status === "VALID" && this.modalCallbackIsValid) {
      this.saveModal();
    }
  }

  /**
   * Realiza a chamada ao backend passando o payload de cadastro de um novo contratante
   */
  registerContractor() {
    const sendingPayload: ICreateContractorPayload = buildPostPayload(this.form.value);
    this.isLoading = true;
    this.contratanteService.createNewContractor(sendingPayload).subscribe({
      next: () => {},
      complete: () => {
        this.isLoading = false;
        this.handleThemeChoice();
        this.router.navigate(["/contratante-beta"]);
        this.notificatorService.showInfo(
          "Cadastro concluído!",
          "Novo contratante cadastrado com sucesso"
        );
      },
      error: ({ error: err }) => {
        this.isLoading = false;
        if(err.status === 400) {
          if(err.error.errors.includes(backendErrors.prefixoUrlAlreadyExists)) {
            this.form.get('basicInformation.prefixo_url').setErrors({ alreadyExists: true });
          }
          if(err.error.errors.includes(backendErrors.emailAlreadyExists)) {
            this.form.get('adminInformation.login').setErrors({ alreadyExists: true });
          }
        }
        this.notificatorService.showError(
          errorMessages.createNewHirer.title,
          errorMessages.createNewHirer.message
        );
      }
    });
  }

  /**
   * Realiza a chamada ao backend passando o payload de edição de contratante.
   *
   * RF - Mudança de admin: Quando o admin mudar, o groupId deve ser null, pois
   * CPF é um atributo que não deve mudar e caso se envie o um id o banco vai considerar
   * como um atualização do admin atual e vai manter o CPF.
   */
  editContractor(adminChange = null) {
    const groupIdDefault: IGroupId = {admin: null, person: null, user: null}
    const groupIds = adminChange === null ? this.groupId : groupIdDefault;

    const sendingPayload: IUpdateContractorPayload = buildPutPayload(this.form.value, groupIds, this.schema_name, this.adminPhone);

    this.isLoading = true;
    this.contratanteService.updateContractor(sendingPayload).subscribe({
      next: () => {},
      complete: () => {
        this.isLoading = false;
        this.handleThemeChoice();
        this.router.navigate(["/contratante-beta"]);
        if(adminChange !== null) {
          this.updateAdminData(adminChange);
          this.notificatorService.showInfo('Alterações salvas', 'E-mail enviado ao novo administrador');
        }
        else if (this.changedValues.length === 1 && this.changedValues.includes("adminInformation.login")) {
          this.notificatorService.showInfo("Solicitação enviada!", "E-mail de confirmação enviado com sucesso!");
        } else {
          this.notificatorService.showInfo("Alterações salvas!", "Os dados da contratante foram atualizados");
        }
      },
      error: ({ error: err }) => {
        this.isLoading = false;
        if(err.status === 400) {
          if(err.error.errors.includes(backendErrors.emailAlreadyExists)) {
            if(adminChange !== null) {
              this.newAdmin.get('login').setErrors({ alreadyExists: true });
            } else {
              this.adminInitialValues();
              this.form.get('adminInformation.login').setErrors({ alreadyExists: true });

              this.notificatorService.showError(
                errorMessages.updateHirer.title,
                errorMessages.updateHirer.message
              );
            }
          }
        }
      }
    })
  }

  /**
   * Se não ocorrer erro na atualização do admin, atualiza o formúlario com os novos dados de administrador
   */
  updateAdminData(newData) {
    this.form.get('adminInformation').setValue({
      nome: newData.nome,
      cpf: newData.cpf,
      login: newData.login,
    })
  }

  /**
   * Realiza a construção das modais
   * @param icon = icone da modal
   * @param titleDialog = titulo da modal
   * @param textDialog = texto da modal
   * @param textBtn = texto do botão
   * @param cb() = função de callback repassada para o modal
   */
  buildModal(
    icon: string,
    title: string,
    messageModal: string,
    btnTitlePositive: string,
    cb: () => void
  ) {
    this.modalService.showModal({
      title,
      messageModal,
      btnTitlePositive,
      icon,
      positiveCallback: () => cb(),
    });
  }

  /**
   * Verifica se a alteração de valores ocorreu somente no e-mail para devolver o modal correspondente
   */
  saveModal() {
    this.modalData.positiveCallback = () => this.editContractor(null);
    if (
      this.changedValues.length === 1 &&
      this.changedValues.includes("adminInformation.login")
    ) {
      this.buildModal(
        "fa-regular fa-envelope",
        "Solicitar mudança de e-mail",
        "Uma mensagem de confirmação será enviada para o novo e-mail do administrador. Deseja continuar?",
        "Alterar",
        this.editContractor.bind(this)
      );
    } else {
      this.buildModal(
        "fa-regular fa-regular fa-badge-check",
        "Salvar edição",
        "Você realizou edições nesta contratante. Deseja salvar suas alterações?",
        "Salvar",
        this.editContractor.bind(this)
      );
    }
  }

  /**
   * Se o campo status for ativo retorna uma mensagem diferente do inativo
   */
  registerModal() {
    this.modalData.positiveCallback = () => this.registerContractor();
    if (this.form.value.basicInformation.ativo === true) {
      this.buildModal(
        "fa-regular fa-user",
        "Ativar novo Contratante",
        "Ao concluir o cadastro, um e-mail será enviado ao administrador para definição de senha. Deseja continuar?",
        "Concluir",
        this.registerContractor.bind(this)
      );
    } else {
      this.buildModal(
        "fa-regular fa-user",
        "Concluir novo cadastro de Contratante",
        "Deseja mesmo concluir o cadastro deste contratante?",
        "Concluir",
        this.registerContractor.bind(this)
      );
    }
  }

  // função acionada no botão de cancelar do modal
  handleNavigate() {
    this.router.navigate(["/contratante-beta"])
  }


  /**
   * Mostra uma mensagem de acordo com o modo: Cadastro / Edição
   */
  closeModal() {
    this.modalData.positiveCallback = () =>
      this.router.navigate(["/contratante-beta"]);
      this.buildModal(
        "fa-regular fa-door-open",
        "Sair sem salvar",
        "Deseja mesmo descartar as alterações realizadas em Contratante?",
        "Sair sem salvar",
        this.handleNavigate.bind(this)
      );
  }
}
