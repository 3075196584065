import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { TipoConteudo } from '../../../conteudo/tipoConteudo';
import { SelecaoComponent } from '../selecao.component';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-selecao-unica-questionario',
  templateUrl: './selecao-unica.component.html',
  styleUrls: ['./selecao-unica.component.scss'],
})
export class SelecaoUnicaComponent extends SelecaoComponent {

  /**
   * @override
   */
  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_UNICA;

  /**
   * @override
   */
  tipoPergunta: TipoPergunta = TipoPergunta.UNICA;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
