import { Injectable } from '@angular/core';
import { RequestService } from 'app/servico/request.service';
import { apiLocation } from 'app/infraestrutura/apiLocation';

@Injectable({
  providedIn: 'root',
})
export class RecuperarSenhaService {

  public baseUrl = apiLocation;

  constructor(private requestService: RequestService) {
  }

  testarValidadeDoToken(payload: {token: string}) {
    const requestUrl = `${apiLocation}/redefinir-senha/token/valid`
    const request = this.requestService.post(requestUrl, payload);
    return request
  }

  solicitarRecuperacao(formData: { email: string, callbackLink: string }) {
    const  payload = {
      login: formData.email,
      callbackLink: formData.callbackLink
    }

    const requestUrl = `${apiLocation}/redefinir-senha`;
    // tslint:disable-next-line: max-line-length
    const request = this.requestService.post(requestUrl, payload);
    return request;
  }

  alterarSenha(payload: { token: string, senha: string }): any {

    const requestUrl = `${apiLocation}/redefinir-senha/alterar`;
    const request = this.requestService.post(requestUrl, payload);
    return request;
  }

}
