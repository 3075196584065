import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IAuditedCollections, IOverviewData, ISurveyStatus } from "../../../interfaces";
import { AuditoriaBetaService } from "../../../services/auditoria-beta.service";
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-audit-overview',
  templateUrl: './audit-overview.component.html',
  styleUrls: ['./audit-overview.component.scss']
})
export class AuditOverviewComponent implements OnInit {
  @Input() overviewData: IOverviewData;
  surveyId = this.route.snapshot.params["id"]; // representa o id da pesquisa selecionada
  collectionsMade: number = 0;
  collectionsObjective: number = 0; // meta de auditoria

  @Output() updateSurveyStatus = new EventEmitter();
  @Input() surveyStatus: ISurveyStatus;
  @Input() auditedCollections: number

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.collectionsMade = this.overviewData?.resumo_coletas.efetuadas
    this.collectionsObjective = this.overviewData?.resumo_coletas.meta_auditoria
  }

  updateHeaderSurveyStatus() {
    this.updateSurveyStatus.emit(null);
  }

}
