<div class="alternative" [ngClass]="isChecked && 'selected'">
  <p>
    {{ alternativa.enunciado }}
  </p>
  <input
    #numberInput
    type="number"
    class="number-input"
    maxlength="9"
    step="1"
    pattern="/^-?\d+\.?\d*$/"
    (input)="handleInput($event)"
    (focus)="handleFocus()"
    [disabled]="!isChecked && !selecionavel"
    [(ngModel)]="value"
  />
</div>
