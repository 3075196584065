<div>
  <label
    class="switch"
  >
    <input
      type="checkbox"
      [checked]="checked"
      [ngModel]="checked"
      (ngModelChange)="handleChange($event)"
    >
    <span
      class="slider round"
    >
    </span>
  </label>
</div>
