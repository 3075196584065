import { Injectable } from "@angular/core";
import { AnswerOutput, QuestionsComponent } from "../questions.component";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { AnswerRequest } from "../questions.models";
import { TipoAlternativa } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-logica/pesquisas-logica.component";

interface StoredAnswers {
  [key: string]: AnswerOutput;
}

/**
 * Adaptador genérico para as perguntas. 
 * O objetivo é converter as respostas selecionadas pelo auditor para o modelo esperado na request de atualização.
 */
class GenericQuestionAdapter {
  protected output: AnswerOutput;

  constructor(answer: AnswerOutput) {
    this.output = answer;
  }

  protected toRequest(): AnswerRequest {
    return null;
  }
}

/**
 * Adaptadores específicos de cada pergunta.
 */
class DateQuestionAdapter extends GenericQuestionAdapter {
  protected toRequest(): AnswerRequest {
    return {
      id_pergunta: this.output.questionId,
      respostas: this.output.answer || null,
    };
  }
}

class HorarioQuestionAdapter extends DateQuestionAdapter {}

class NumericScaleQuestionAdapter extends GenericQuestionAdapter {
  protected toRequest(): AnswerRequest {
    return {
      id_pergunta: this.output.questionId,
      respostas: this.output.answer
        ? [{ id_alternativa: this.output.answer }]
        : null,
    };
  }
}

class SingleResponseQuestionAdapter extends GenericQuestionAdapter {
  protected toRequest(): AnswerRequest {
    const strategies = {
      [TipoAlternativa.ABERTA_TEXTO]: "texto",
      [TipoAlternativa.ABERTA_NUMERO]: "numero",
      [TipoAlternativa.FECHADA]: "id_alternativa",
    };

    return {
      id_pergunta: this.output.questionId,
      respostas: this.output.answer.length
        ? [
            ...this.output.answer.map((alt) => ({
              id_alternativa: alt.id_alternativa,
              [strategies[alt.tipo]]: alt[strategies[alt.tipo]],
            })),
          ]
        : null,
    };
  }
}

class MultipleResponseQuestionAdapter extends SingleResponseQuestionAdapter {}

class GridQuestionAdapter extends GenericQuestionAdapter {
  protected toRequest(): AnswerRequest {
    return {
      id_pergunta: this.output.questionId,
      respostas: this.output.answer.length ? [...this.output.answer] : null,
    };
  }
}

/**
 * Adaptador do questionário: Utiliza um padrão strategy para definir qual
 * resposta de uma pergunta será adaptada ao modelo da requisição de atualização.
 */
class QuestionnaireAdapter {
  constructor() {}

  private contexts = {
    [TipoPergunta.UNICA]: (answer: AnswerOutput) =>
      new SingleResponseQuestionAdapter(answer),
    [TipoPergunta.MULTIPLA]: (answer: AnswerOutput) =>
      new MultipleResponseQuestionAdapter(answer),
    [TipoPergunta.DATA]: (answer: AnswerOutput) =>
      new DateQuestionAdapter(answer),
    [TipoPergunta.ESCALA_NUMERICA]: (answer: AnswerOutput) =>
      new NumericScaleQuestionAdapter(answer),
    [TipoPergunta.HORARIO]: (answer: AnswerOutput) =>
      new HorarioQuestionAdapter(answer),
    [TipoPergunta.GRADE_UNICA]: (answer: AnswerOutput) =>
      new GridQuestionAdapter(answer),
    [TipoPergunta.GRADE_MULTIPLA]: (answer: AnswerOutput) =>
      new GridQuestionAdapter(answer),
  };

  process(answers: StoredAnswers) {
    const entries = Object.entries(answers);
    const result: AnswerRequest[] = [];

    for (let [_, value] of entries) {
      const pickedStrategy = this.contexts[value.type];

      if (pickedStrategy) {
        const adapter = pickedStrategy(value);
        result.push(adapter.toRequest());
      }
    }

    return result;
  }
}

/**
 * Gerenciador do questionário em auditoria, tem a responsabilidade de processar 
 * e armazenar as respostas do auditor.
 */
@Injectable({ providedIn: "any" })
export class AuditQuestionManager {
  private adapter: QuestionnaireAdapter = new QuestionnaireAdapter();
  private answers = {} as StoredAnswers;

  public getAnswers() {
    return this.adapter.process(this.answers);
  }

  public update(answer: AnswerOutput) {
    this.answers[answer.questionId] = { ...answer };
    console.log('[ESTADO ATUAL DO GERENCIADOR DE RESPOSTAS]: ', this.answers);
  }
}
