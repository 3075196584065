import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import validadorCamposIguais from 'app/util/validador/validadorCamposIguais';
import { NotifcationType, buildNotificationSuccess } from '../../utils/notify';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  // variáveis que garda a força das senhas
  passwordStrength: number = 0
  confirmedPasswordStrength: number = 0

  @Input() formGroup;
  @Input() controls;
  @Input() isAlteracaoSenha;
  @Input() showModal;
  @Input() notifyObject;
  @Output() handleShowModal = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
    this.handleUpdatePassword();
    this.notifyObject = buildNotificationSuccess(NotifcationType.PASSWORD);
  }

  /**
   * Fecha a modal de senha limpando todas as validações e valores previamente inseridos.
   */
  handleCloseModal() {
    this.formGroup.get('usuario.senha_atual').setValue(null);
    this.formGroup.get('usuario.senha_nova').setValue(null);
    this.formGroup.get('usuario.senhaConfirmar').setValue(null);
    this.clearPasswordValidation();

    this.handleShowModal.emit();
  }

    // função que recebe o valor digitado pelo usuário
    handleCheckUpdatePassword($event: string, inputName: string) {
      // verifica a senha principal
      if (inputName === 'password') {
        this.passwordStrength = 0
        if ($event.match(/[a-z]+/)) {
          this.passwordStrength += 1;
        }
        if ($event.match(/[A-Z]+/)) {
          this.passwordStrength += 1;
        }
        if ($event.match(/[0-9]+/)) {
          this.passwordStrength += 1;
        }
        if ($event.match(/[$@#&!]+/)) {
          this.passwordStrength += 1;
        }
        if ($event.length > 7) {
          this.passwordStrength += 1;
        }
      }

      // verifica a senha de confirmação
      if (inputName === 'confirmedPassword') {
        this.confirmedPasswordStrength = 0
        if ($event.match(/[a-z]+/)) {
          this.confirmedPasswordStrength += 1;
        }
        if ($event.match(/[A-Z]+/)) {
          this.confirmedPasswordStrength += 1;
        }
        if ($event.match(/[0-9]+/)) {
          this.confirmedPasswordStrength += 1;
        }
        if ($event.match(/[$@#&!]+/)) {
          this.confirmedPasswordStrength += 1;
        }
        if ($event.length > 7) {
          this.confirmedPasswordStrength += 1;
        }
      }
    }

    /**
     * Adiciona ou limpa as validações dos campos de senha
     */
    handleUpdatePassword() {
      this.isAlteracaoSenha = !this.isAlteracaoSenha
      const formControlSenhaAntiga = this.controls.usuario.get('senha_atual');
      const formControlSenha = this.controls.usuario.get('senha_nova');
      const formControlSenhaConfirma = this.controls.usuario.get('senhaConfirmar');

      const senhaCamposIguaisValidator = validadorCamposIguais(formControlSenha);
      const senhaConfirmarCamposIguaisValidator = validadorCamposIguais(formControlSenhaConfirma);

      if (!this.isAlteracaoSenha) {
        this.clearPasswordValidation();
      } else {

        formControlSenhaAntiga.setValidators(Validators.required);
        formControlSenha.setValidators([
          Validators.required,
          Validators.maxLength(30),
          Validators.minLength(8),
          senhaConfirmarCamposIguaisValidator
        ]);

        formControlSenhaConfirma.setValidators(senhaCamposIguaisValidator);
        formControlSenha.updateValueAndValidity();

      }
    }

    /***
     * Reseta todas as validações de senha
     */
    clearPasswordValidation() {
      this.controls.usuario.get('senha_atual').clearValidators();
      this.controls.usuario.get('senha_atual').reset();

      this.controls.usuario.get('senha_nova').clearValidators();
      this.controls.usuario.get('senha_nova').reset();

      this.controls.usuario.get('senhaConfirmar').clearValidators();
      this.controls.usuario.get('senhaConfirmar').reset();
    }

  /**
   * Retorna a classe que deverá ser aplicada a flag que indica a força da senha
   * No caso da senha ser média ou forte, adiciona uma nova classe que transforma a flag em absolute.
   * Essa abordagem é necessária, pois, caso a senha seja fraca deverá ser renderizada uma mensagem indicativa e
   ** de acordo com o design a info de preenchimento deve ser movida para baixo do erro.
   * @param password  length da senha digitada
   * @returns classe que será aplicada na flag da senha
   */
  checkPasswordIsValid(password): string {
    let passwordClass;
    if(password <= 2) {
      passwordClass = 'flag-strength fraca'
    } else if(password > 2 && password <= 4) {
      passwordClass = 'flag-strength media absolute-strength-flag'
    } else {
      passwordClass ='flag-strength forte absolute-strength-flag'
    }
    return passwordClass
  }

  /**
   * Retorna uma string informando a força da senha para o usuário
   * @param password: length da senha digitada
   * @returns Fraca | Media | Forte
   */
  returnPasswordStrength(password): string {
    if(password <= 2) {
      return 'Fraca'
    } else if(password > 2 && password <= 4) {
      return 'Média'
    } else {
      return 'Forte'
    }
  }

  /**
   * "Toca" todos os inputs para exibir possiveis erros antes da request.
   */
  handleSubmit() {
    this.formGroup.markAllAsTouched();
  }

}
