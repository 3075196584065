import { LocalidadePai } from './localidadePai';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';

export class Localidade {
  id: number;
  nome: string;
  longitude: number;
  latitude: number;
  raio: number;
  localidadePai: LocalidadePai;
  localidadesFilhas: Localidade[];
  // apenas para tratamento de selects
  isPai: boolean;
  isFilho: boolean;

  versao: string;

  constructor() {
    this.localidadePai = new LocalidadePai();
  }

  static localidadePaiFormGroup(localidade: Localidade): UntypedFormGroup {

    const nomeFormControl: UntypedFormControl = new UntypedFormControl(localidade.localidadePai.nome);
    const idFormControl: UntypedFormControl = new UntypedFormControl(localidade.localidadePai.id);

    const localidadePaiFormGroup = new UntypedFormGroup({
      id: idFormControl,
      nome: nomeFormControl,
    });

    return localidadePaiFormGroup;
  }

  /**
   * Método estático que cria um FormGroup a partir de uma instancia de Localidade.
   * O formGroup criado já acompanha todos os validadores que diz respeito a uma pesquisa,
   * comprimento da string de nome, tamanho minimo e maximo de latitude e longitude, etc.
   * @param localidade estrutura de dados que representa uma localidade
   */
  static formGroup(localidade: Localidade) {

    // tslint:disable-next-line:max-line-length
    const nomeFormControl: UntypedFormControl = new UntypedFormControl(localidade.nome, [Validators.required, Validators.maxLength(50)]);
    // tslint:disable-next-line:max-line-length
    const latitudeFormControl: UntypedFormControl = new UntypedFormControl(localidade.latitude, [Validators.required, Validators.min(-90), Validators.max(90), Validators.maxLength(15)]);
    // tslint:disable-next-line:max-line-length
    const longitudeFormControl: UntypedFormControl = new UntypedFormControl(localidade.longitude, [Validators.required, Validators.min(-180), Validators.max(180), Validators.maxLength(15)]);
    // tslint:disable-next-line:max-line-length
    const raioFormControl: UntypedFormControl = new UntypedFormControl(localidade.raio, [Validators.required, Validators.maxLength(7), Validators.minLength(1), Validators.min(1)]);
    // ['localidadePai'] = new FormControl();
    const versaoFormControl: UntypedFormControl = new UntypedFormControl(localidade.versao, []);

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(localidade.id),
      nome: nomeFormControl,
      latitude: latitudeFormControl,
      longitude: longitudeFormControl,
      raio: raioFormControl,
      localidadePai: Localidade.localidadePaiFormGroup(localidade),
      versao: versaoFormControl,
    });

    return formGroup;
  }

}
