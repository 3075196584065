import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { RespostaPergunta } from "../resposta-pergunta-listagem-tabela/model/respostaPergunta";

@Directive()
export abstract class RespostaComponent implements OnInit, OnDestroy {

  @Input() proximaPerguntaSubject: Subject<number>;
  @Input() novaRespostaPerguntaSubject: Subject<RespostaPergunta>;
  @Input() closeRespostaSubject: Subject<{}>;

  private proximaPerguntaSubscription: Subscription;

  ngOnInit() {
    if (this.proximaPerguntaSubject) {
      this.proximaPerguntaSubscription = this.proximaPerguntaSubject.subscribe(idPergunta => this.onProximaPergunta());
    }
  }

  ngOnDestroy() {
    if (this.proximaPerguntaSubscription) {
      this.proximaPerguntaSubscription.unsubscribe();
    }
  }

  /**
   * Callback executado quando o subject proximaPerguntaSubject receber uma nova mensagem.
   * Uma mensagem para este subject é enviada quando o usuário aciona o botão "próxima"
   * na visualização da resposta de uma pergunta.
   */
  abstract onProximaPergunta(): any;

  abstract hasErrors(args?: any): boolean;

  /**
   * Método que notifica quaisquer interessados de respostaPergunta editada.
   * @param respostaPergunta
   */
  protected notifyRespostaPergunta(respostaPergunta: RespostaPergunta) {
    this.novaRespostaPerguntaSubject.next(respostaPergunta);
  }

  protected closeResposta() {
    this.closeRespostaSubject.next({});
  }
}
