import { TipoEventoInput } from './tipoEventoInput';

/**
 * Classe que encapsula um evento que pode ser emitido
 * por um input do cadastro de pesquisa
 */
export class EventoInput {

  tipo: TipoEventoInput;
  payload: any;

  constructor(tipo: TipoEventoInput, payload: any = null) {
    this.tipo = tipo;
    this.payload = payload;
  }

}
