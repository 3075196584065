import { TrBody } from './trBody';  
import { InstanciaSistema } from 'app/modulos/management/instancia-cadastro/InstanciaSistema';

export class TrBodyFactory {
  /**
   * Construi uma TrBody a partir do dado passado e o nome dos
   * atributos que serao inseridos na TrBody
   * @param dado Objeto de onde serão extraidos os dados
   * @param nomeAtributos Nome dos atributos que serao utilizados pelo TrBody
   * @param novoIdCustom novo identificador utilizado na trBody caso o identificador padrão não exista
   */
  static buildTrBody(dado: any = [], nomeAtributos: string[] = [], title:string, novoIdCustom?:any): TrBody {

    if (!dado || !nomeAtributos || nomeAtributos.length === 0) {
      return new TrBody();
    }
    const trBody = new TrBody();
    trBody.selected = false;
    trBody.status = dado.ativo;
    trBody.title = dado.title;
    trBody.id = dado.id;

    if (trBody.id === null || trBody.id === undefined && novoIdCustom !== null) {
      trBody.id = dado[novoIdCustom];
    }

    trBody.customData = nomeAtributos.map((nomeAtributo) => {
      return dado[nomeAtributo];
    });
    return trBody;

    // const instanciasToTableInstance = instancias.map(instancia => {...instancia, id = instancia.name});
  }
  /**
   * Construi uma TrBody a partir de dados passados e o nome dos
   * atributos que serao inseridos na TrBody
   * @param dados Array de objetos de onde serão estraidos os dados
   * @param nomeAtributos Nome dos atributos que serao utilizados pelo TrBody
   */
  static buildTrBodies(dados: any[] = [], nomeAtributos: string[] = [], title:string, novoIdCustom?:any): TrBody[] {

    // var instanciasToTableInstance = dados.map(dado => {...dado, id = dado.name});

    if (!dados || dados.length === 0 || !nomeAtributos || nomeAtributos.length === 0) {
      return [];
    }

    const trBodies: TrBody[] = dados.map((dado) => {
      return this.buildTrBody(dado, nomeAtributos, title, novoIdCustom);
    });

    return trBodies;
  }
}
