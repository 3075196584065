import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { RequestService } from 'app/servico/request.service';
import { EventoInput } from '../../../inputs/evento/eventoInput';
import { TipoEventoInput } from '../../../inputs/evento/tipoEventoInput';
import { InputComponent } from '../../../inputs/inputComponent';
import { LocalidadeItem } from './localidadeItem';

@Component({
  selector: 'app-selecao-localidade',
  templateUrl: './selecao-localidade.component.html',
  styleUrls: ['./selecao-localidade.component.scss']
})
export class SelecaoLocalidadeComponent extends InputComponent {

  localidades: LocalidadeItem[] = [];

  private requestService : RequestService;

  isLoading : boolean = false;

  @Input() selecionarPrimeiro : boolean;
  @Input() idLocalidadeSelecionada : number;

  /**
   * @override
   * Construtor responsável por criar uma instância de SelecaoLocalidadeComponent.
   * Injeta o service RequestService.
   * @param requestService serviço responsável por realizar requisições à API do back-end.
   */
  constructor(requestService : RequestService) {
    super();
    this.requestService = requestService;
  }

  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit(); // chama initFormGroup na classe super
    this.carregarLocalidades();
  }

  /**
   * @override
   */
  initFormGroup() {
    this.control = new UntypedFormControl(this.idLocalidadeSelecionada ? this.idLocalidadeSelecionada : null);
    this.formGroup = new UntypedFormGroup({
      localidade: this.control,
    });
  }

  atualizarFormGroup(idLocalidadeSelecionada : number) : void {

    this.formGroup.patchValue({
      localidade: idLocalidadeSelecionada,
    });
    this.onLocalidadeSelect();
  }

  carregarLocalidades() {

    this.isLoading = true;
    const uri = `${apiLocation}/localidades/pais/selecao`;

    this.requestService.get(uri).subscribe((data) => {

      this.localidades = data;
      this.isLoading = false;
    });
  }

  /**
   * recupera o nome da localidade pelo id passado por parâmetro.
   * @param id identificador da localidade
   */
  getLocalidade(id: number): LocalidadeItem {
    return this.localidades.filter(l => l.id === id)[0];
  }

  onLocalidadeSelect() {

    const idLocalidadeSelecionada = this.control.value;
    const localidade = this.getLocalidade(idLocalidadeSelecionada);

    const nomeLocalidadeSelecionada = localidade.nome;
    const versaoLocalidadeSelecionada = localidade.versao;

    const eventoInput : EventoInput = {
      tipo: TipoEventoInput.CHANGE,
      payload: {
        id: idLocalidadeSelecionada,
        nome: nomeLocalidadeSelecionada,
        versao: versaoLocalidadeSelecionada,
      },
    };

    // console.log('[SelecaoLocalidadeComponent.onLocalidadeSelect] evento: ', eventoInput);

    this.notifyParent(eventoInput);
  }

}
