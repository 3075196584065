<div [formGroup]="gradesAlternativas">
  <div class="containerGradeUnica sg-input-group" *ngFor="let tituloGrade of pergunta.titulosGrades">
    <div class="campo-inline">
      <label>{{ tituloGrade.descricao }}</label>
      <select formControlName="{{ tituloGrade.id }}">
        <option *ngFor="let alternativa of pergunta.alternativas" [value]="alternativa.id">
          {{ alternativa.descricao }}
        </option>
      </select>
    </div>
  </div>
</div>

<!-- <div>
  <div *ngFor="let tituloGrade of pergunta.titulosGrades">
    <div class="row">
      <div class="col-md-2">
        {{ tituloGrade.descricao }}
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-select [formControl]="getTituloGradeControl(tituloGrade.id)">
            <mat-option *ngFor="let alternativa of pergunta.alternativas" [value]="alternativa.id">
              {{ alternativa.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div> -->
