import { TipoAlternativa } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-logica/pesquisas-logica.component";
import { IResultAlternative } from "app/modulos/resultados/results-interface";
import arrays from "app/util/misc/arrays";

function isOpenedAnswer(tipo: TipoAlternativa) {
  return ["ABERTA_TEXTO", "ABERTA_NUMERO"].includes(tipo);
}

function adaptByAnswerType(answer: IResultAlternative) {
  const adaptedAnswer = { ...answer };

  if (
    isOpenedAnswer(answer.tipo as TipoAlternativa) &&
    typeof answer.resposta !== undefined
  ) {
    adaptedAnswer.descricao = answer.resposta;
  }

  return adaptedAnswer;
}

/**
 * Assume que o atributo "descricao" de IResultAlternative deve ter o valor do atributo
 * "resposta" caso o tipo da resposta seja ABERTA_TEXTO ou ABERTA_NUMERO e adapta as respostas seguindo
 * essa regra. Essa especificação está relacionada com a TC-2345
 * @param answers respostas de uma pergunta
 * @returns respostas adaptadas
 */
export function adaptAnswerByType(answers: IResultAlternative[]) {
  if (arrays.isEmpty(answers)) {
    return [];
  }

  const adaptedByAnswer = answers.map(adaptByAnswerType);

  return adaptedByAnswer;
}
