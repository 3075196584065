import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from 'app/servico/request.service';
import { CrudService } from 'app/servico/requestService/crudService';
import { Observable } from 'rxjs';
import { IListHirer, ICreateContractorPayload, IUpdateContractorPayload } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ContratanteService extends CrudService<any> {
  baseUrl = this.resourcePath

  constructor(
    protected requestService: RequestService,

  ) { super(requestService, `${apiLocation}`); }


  /**
   *
   * @param keywords Array de palavras chaves para requisição
   * @param ativo status da pesquisa: Ativo | Inativo | null
   * @param page número da página
   * @returns lista de 10 contratantes
   */
  getContractorList(keywords = [""], ativo = null, page = 0 ): Observable<IListHirer[]> {
    /**
     * Verifica se ativo é null para que não seja enviado na requisição, pois o endpoint interpreta null como a ausencia dos status na url
     */
    const status = ativo !== null ? `ativo=${ativo}` : '';
    const url = `${this.baseUrl}/contratantes/filtrar/palavra-chave?${status}&page=${page}&size=10`;
    const response = <Observable<IListHirer[]>>(
      this.requestService.post(url, {palavrasChave: keywords})
    );
    return response;
  }

  /**
   * Realiza o cadastro de um novo contratante
   */
  createNewContractor(contractor: ICreateContractorPayload): Observable<Object | HttpResponse<Object>> {
    const url = `${this.baseUrl}/contratantes`;
    const response = this.requestService.post(url, contractor);

    return response;
  }


  /**
   * Realiza o cadastro de um novo contratante
   */
  getContractorTotal(keywords = [""], ativo = null): Observable<number> {
    const status = ativo !== null ? `ativo=${ativo}` : '';
    const url = `${this.baseUrl}/contratantes/filtrar/palavra-chave/total-registros?${status}`

    const response = <Observable<number>>(
      this.requestService.post(url, {palavrasChave: keywords})
    );
    return response;
  }

  /**
   * Recupera o contratante que vai ser editado
   */
  getContractorData(name: string): Observable<IUpdateContractorPayload> {
    const url = `${this.baseUrl}/management/instance/${name}`;
    const response = this.requestService.get(url);

    return response;
  }

  /**
   * Edita o contratante
   */
  updateContractor(contractor: IUpdateContractorPayload): Observable<Object | HttpResponse<Object>> {
    const schemaName = contractor.name

    const url = `${this.baseUrl}/contratantes/${schemaName}`;
    const response = this.requestService.put(url, contractor);

    return response;
  }

  changeContractorStatus(schemaName, status): Observable<Object | HttpResponse<Object>>{
    const url = `${this.baseUrl}/contratantes/${schemaName}/ativo`;

    const editedStatus = { ativo: status }

    const response = this.requestService.put(url, editedStatus);

    return response;
  }

  /**
   * Envia uma imagem ao backend e retorna uma string que representa o caminho da mesma
   *
   * @param media = imagem (type file)
   */
  storeMedia(media: File): Observable<Object | HttpResponse<Object>> {
    const url = `${this.baseUrl}/medias`;
    const formData = new FormData();
    formData.append('file', media);

    const response =  this.requestService.post(url, formData, false);
    return response;
  }


  /**
   * Comunica o backend sobre a solicitação de alteração de senha
   *
   * @param schemaName
   * @returns
   */
  changeAdminPassword(schemaName) {
    const url = `${this.baseUrl}/contratantes/${schemaName}/redefinir-senha`;
    const response = this.requestService.patch(url);

    return response;
  }

  /*
   * Recupera os dados do header
   */
  getHeaderData() {
    const url = `${this.baseUrl}/contratantes/resumo`;
    const response = this.requestService.get(url);
    return response;
  }

}
