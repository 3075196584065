/**
 * Tipo do elemento que disparou a Action do reducer
 */
export enum TipoElemento {

    PERGUNTA = 'PERGUNTA',
    SECAO = 'SECAO',
    ALTERNATIVA = 'ALTERNATIVA',
    TITULO_GRADE = 'TITULO_GRADE',

}
