<div class="pesquisa-conclusao-wrapper">
  <section class="top-wrapper">
    <i class="fa-solid fa-message-smile smile-icon"></i>
    <div class="title-wrapper">
      <h4 class="info-title">Avaliação cadastrada com sucesso!</h4>
      <h1 class="survey-title">{{ surveyTitle }}</h1>
    </div>
    <a class="forms-btn" [href]="formLink" target="_blank">
      <i class="fa-regular fa-eye"></i>
      <p class="btn-title">Visualizar questionário</p>
    </a>
  </section>
  <section class="bottom-wrapper">
    <div class="action-card">
      <p class="action-text">Comece a receber respostas mudando o status de <b>Rascunho</b> para <b>Execução</b>
      </p>
      <div class="select-body">
        <select name="status" id="status" (change)="handleSelectOption($event)"
          [disabled]="research.status !== 'RASCUNHO'">
          <option value="RASCUNHO">
            <span class="circle circle-rascunho"></span>
            Rascunho
          </option>
          <option value="EXECUCAO" [selected]="research.status !== 'RASCUNHO'">
            <span class="circle circle-execucao"></span>
            Execução
          </option>
        </select>
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
    <hr class="line">
    <div *ngIf="pesquisaTemColetasPresenciais && !isOnlyPresencial" class="link-area">
      <div class="input-wrapper">
        <h4 class="link-info-title">
          Compartilhe o questionario
        </h4>
        <span class="action-options">
          <input id="copy" type="text" [value]="formLink" readonly
          [class]="research.status === 'RASCUNHO' ? 'disable-pointer' : ''" />

          <qrcode class="qrcode-element" (qrCodeURL)="onChangeURL($event)" [qrdata]="formLink" [width]="500"></qrcode>

          <div class="btn-wrapper">
            <button class="survey-link-btn" type="button" (click)="copyToClipboard()">
              <i class="fa-regular fa-copy"></i>
              <p>Copiar link</p>
            </button>
            <a class="survey-link-btn" [href]="qrCodeDownloadLink" download="qrcode">
              <i class="fa-regular fa-qrcode"></i>
              <p>Baixar QR Code</p>
            </a>
          </div>
        </span>
      </div>
    </div>
  </section>
</div>
