export enum TipoConteudo {
  /**
   * Q U E S T I O N A R I O
   */
  CONTEUDO_SECAO = 'CONTEUDO_SECAO',
  CONTEUDO_PERGUNTA_DATA = 'CONTEUDO_PERGUNTA',
  CONTEUDO_PERGUNTA_FOTO = 'CONTEUDO_PERGUNTA_FOTO',
  CONTEUDO_PERGUNTA_HORARIO = 'CONTEUDO_PERGUNTA_HORARIO',
  CONTEUDO_PERGUNTA_VIDEO = 'CONTEUDO_PERGUNTA_VIDEO',
  CONTEUDO_PERGUNTA_SELECAO_UNICA = 'CONTEUDO_PERGUNTA_SELECAO_UNICA',
// tslint:disable-next-line: max-line-length
  CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO = 'CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO',
  CONTEUDO_PERGUNTA_SELECAO_MULTIPLA = 'CONTEUDO_PERGUNTA_SELECAO_MULTIPLA',
  CONTEUDO_PERGUNTA_ESCALA_NUMERICA = 'CONTEUDO_PERGUNTA_ESCALA_NUMERICA',
  CONTEUDO_PERGUNTA_GRADE_UNICA = 'CONTEUDO_PERGUNTA_GRADE_UNICA',
  CONTEUDO_PERGUNTA_GRADE_MULTIPLA = 'CONTEUDO_PERGUNTA_GRADE_MULTIPLA',
  /**
   * M A R C A Ç Ã O
   */
  CONTEUDO_PERGUNTA_SELECAO_UNICA_MARCACAO = 'CONTEUDO_PERGUNTA_SELECAO_UNICA_MARCACAO',
  CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_MARCACAO = 'CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_MARCACAO',
// tslint:disable-next-line: max-line-length
  CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO_MARCACAO = 'CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO_MARCACAO',
  CONTEUDO_PERGUNTA_ESCALA_NUMERICA_MARCACAO = 'CONTEUDO_PERGUNTA_ESCALA_NUMERICA_MARCACAO',
  CONTEUDO_PERGUNTA_GRADE_UNICA_MARCACAO = 'CONTEUDO_PERGUNTA_GRADE_UNICA_MARCACAO',
  CONTEUDO_PERGUNTA_FOTO_MARCACAO = 'CONTEUDO_PERGUNTA_FOTO_MARCACAO',
  CONTEUDO_PERGUNTA_VIDEO_MARCACAO = 'CONTEUDO_PERGUNTA_VIDEO_MARCACAO',
  CONTEUDO_PERGUNTA_DATA_MARCACAO = 'CONTEUDO_PERGUNTA_DATA_MARCACAO',
  CONTEUDO_PERGUNTA_HORARIO_MARCACAO = 'CONTEUDO_PERGUNTA_HORARIO_MARCACAO',
}
