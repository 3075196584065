import { IDateObject } from "../interfaces";

// Função para converter a string de data no formato dd/mm/yyyy para um objeto Date
function converterData(dataString) {
  const partesData = dataString.split('/');
  return new Date(partesData[2], partesData[1] - 1, partesData[0]);
}

export function getFirstAndLastDate(operadores): IDateObject {
  // Encontrar a data inicial mais antiga
  const dataInicialMaisAntiga = operadores?.reduce((dataAntiga, item) => {
    const dataAtual = converterData(item.periodo_inicial);
    return dataAtual < dataAntiga ? dataAtual : dataAntiga;
  }, converterData(operadores[0].periodo_inicial));

  // Encontrar a data final mais distante
  const dataFinalMaisDistante = operadores?.reduce((dataDistante, item) => {
    const dataAtual = converterData(item.periodo_final);
    return dataAtual > dataDistante ? dataAtual : dataDistante;
  }, converterData(operadores[0].periodo_final));

  return {inicial: dataInicialMaisAntiga, final: dataFinalMaisDistante}
}

