export enum NotifcationType {
  BASIC = 'basic',
  PASSWORD = 'password',
  EMAIL = 'email',
}

export const buildNotificationSuccess = (change: NotifcationType) => {
  switch (change) {
    case "basic":
      return {title: 'Alterações salvas', text: 'Seus dados de cadastro foram atualizados'}
    case "password":
      return {title: 'Senha alterada com sucesso!', text: null}
    case "email":
      return {title: 'E-mail redefinido com sucesso!', text: null}
  }
}

export const defaultError = {title: 'Problemas na Edição', text:'Os campos contém erros que devem ser corrigidos'}
export const passwordError = {title: 'Problemas na Edição', text:'A senha atual do usuário está incorreta.'}
