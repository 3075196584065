<div class="badges">
  <small *ngIf="model.isRequired" [ngClass]="{ error: model.requiredError }"
    >{{ labels.required() }}</small
  >
  <small *ngIf="model.minLength" [ngClass]="{ error: model.minRequiredError }"
    >{{ labels.minLength() }}</small
  >
  <small *ngIf="model.maxLength" [ngClass]="{ error: model.maxRequiredError }"
    >{{ labels.maxLength() }}</small
  >
</div>
