import { NavigationEnd, Router } from "@angular/router";
import { CrudService } from "app/servico/requestService/crudService";
// tslint:disable-next-line:max-line-length
import { stringFormatter } from "app/util/misc/stringFormatter";
// tslint:disable-next-line:max-line-length
import { Directive, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
// tslint:disable-next-line:max-line-length
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { TipoEventoTabela } from "app/util/componente/tabela/evento/tipoEventoTabela";
import { TabelaFiltravelComponent } from "app/util/componente/tabela/tabela-filtravel/tabela.filtravel.component";
import { ModalService } from "app/componentes/modal/modal.service";

@Directive()
export abstract class ListagemGenerica<T extends { id: any }>
  implements OnInit, OnDestroy
{
  @ViewChild("tabela", { static: true }) tabela: TabelaFiltravelComponent;
  navigationSubscription: Subscription;

  // marcador de loading
  isLoading: boolean = false;

  // geracao e manipulacao de telas de alerta

  entidadesExcluidas: any[] = [];

  /**
   * Referência para o enum Authority de maneira
   * que suas constantes possam ser acessadas nos templates
   * e evitar a inserção de hard-coded strings.
   */
  authority = Authority;

  constructor(
    protected securityService: SecurityService,
    protected nomeEntidade,
    protected crudService: CrudService<T>,
    protected router: Router,
    protected modalService: ModalService
  ) {}

  ngOnInit() {
    // reinicializando a tabela caso o componente de listagem seja atualizado
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.tabela.ngOnInit();
      }
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  manipularEventoTabela(eventoTabela: EventoTabela) {
    switch (eventoTabela.tipo) {
      case TipoEventoTabela.EDITAR:
        {
          this.manipularEventoEdicao(eventoTabela);
        }
        break;
      case TipoEventoTabela.EXCLUIR:
        {
          this.manipularEventoExclusao(eventoTabela);
        }
        break;
      case TipoEventoTabela.DUPLICACAO:
        {
          this.manipularEventoDuplicacao(eventoTabela);
        }
        break;
      case TipoEventoTabela.DOWNLOAD: {
        this.manipularEventoDownload(eventoTabela);
      }
      case TipoEventoTabela.ARQUIVAR: {
        this.manipularEventoArquivar(eventoTabela);
      }
    }
  }
  manipularEventoDuplicacao(eventoTabela: EventoTabela) {}

  manipularEventoEdicao(eventoTabela: EventoTabela) {
    this.router.navigate([this.nomeEntidade, "atualizar"], {
      queryParams: { id: eventoTabela.payload },
    });
  }

  /**
   * life cycle hook de remoção de entidades. Este callback é chamado
   * quando uma remoção não é completada em todas as entidades solicitadas.
   *
   * @param resposta trata-se das entidades cuja remoção não foi realizada.
   */
  manipularExclusaoIncompleta(resposta: any[]) {}

  manipularEventoExclusao(eventoTabela: EventoTabela) {
    const excluirCallback = () => {
      if (eventoTabela.payload && eventoTabela.payload.length > 0) {
        this.isLoading = true;

        // tslint:disable-next-line: max-line-length
        this.crudService.deletar(eventoTabela.payload, false).subscribe({
          next: (response: any) => {
            /**
             * Caso a requisição de removação, retorne como corpo da resposta,
             * uma array não vazia, significa, por convenção, que a exclusão
             * foi incompleta, e retornou uma lista como as entidades cuja exclusão
             * não foi realizada.
             */
            if (response.status === 207) {
              this.manipularExclusaoIncompleta(response.body);
              return;
            }

            const resultado = response.data;

            this.isLoading = false;

            const refreshCallback = () => {
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => this.router.navigate([this.nomeEntidade]));
            };

            // tslint:disable-next-line:max-line-length
            const nomeEntidadeCapitalizado =
              stringFormatter.capitalizeFirstLetter(this.nomeEntidade);

            this.modalService.showModal({
              title: `Excluir ${this.nomeEntidade}`,
              messageModal: `Registro(s) de ${nomeEntidadeCapitalizado} excluído(s) com sucesso!`,
              btnTitlePositive: "Entendi",
              positiveCallback: () => refreshCallback(),
              isOnlyConfirmation: true,
            });
          },
          // tslint:disable-next-line:align
          error: (error) => {
            this.modalService.showModal({
              title: `Excluir ${this.nomeEntidade}`,
              messageModal: `Não foi possível excluir o(s) ${this.nomeEntidade}(es): ${error}`,
              btnTitlePositive: "Entendi",
              isOnlyConfirmation: true,
            });
          },
        });
      }
    };
    if (eventoTabela.payload.length === 0) {
      const entidadeCapitalizada = stringFormatter.capitalizeLastLetter(
        this.nomeEntidade
      );
      this.modalService.showModal({
        title: `Excluir ${this.nomeEntidade}`,
        messageModal: `Selecione um ou mais  ${entidadeCapitalizada}`,
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
      });
    } else {
      const entidadeCapitalizada = stringFormatter.capitalizeLastLetter(
        this.nomeEntidade
      );
      this.modalService.showModal({
        title: `Excluir ${this.nomeEntidade}`,
        messageModal: `Deseja realmente excluir o(s) registro(s) de ${entidadeCapitalizada}?`,
        btnTitlePositive: "Excluir",
        positiveCallback: () => excluirCallback(),
        btnTitleNegative: "Cancelar",
      });
    }
  }

  manipularEventoDownload(eventoTabela: EventoTabela) {
    try {
      this.crudService.downloadListagem().subscribe((showLoading) => {
        this.isLoading = showLoading;
      });
    } catch (error) {
      this.modalService.showModal({
        title: `Donwload da listagem`,
        messageModal:
          "Houve um problema no processamento de sua requisição, tente novamente",
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
      });
    }
  }

  manipularEventoArquivar(eventoTabela: EventoTabela) {}

  /**
   * Life cycle hook que é chamado toda vez que uma exclusão é
   * realizada com sucesso.
   */
  onExclusaoSucesso(ids: any) {}

  getAuthenticatedUserAuthorities(): Authority[] {
    return this.securityService.getAuthenticatedUser().authorities;
  }
}
