import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { Authority } from "app/infraestrutura/security/authority";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { NotificatorService } from "app/notificador/notificator.service";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { TipoEventoTabela } from "app/util/componente/tabela/evento/tipoEventoTabela";
import { stringFormatter } from "app/util/misc/stringFormatter";
import { ToastrService } from "ngx-toastr";
import { ReplaySubject, Subject } from "rxjs";
import { CadastroBasico } from "../../cadastro/cadastroBasico";
import { FuncionarioService } from "../../funcionario/servico/funcionario.service";
import { Permissao } from "../../permissao/permissao";
import { PermissaoService } from "../../permissao/permissao.service";
import { Grupo } from "../grupo";
import { GrupoService } from "../servico/grupo.service";

@Component({
  selector: "app-grupo-cadastro",
  templateUrl: "./grupo.cadastro.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./grupo.cadastro.component.scss"],
})
export class GrupoCadastroComponent
  extends CadastroBasico<Grupo>
  implements AfterViewChecked
{
  grupo: Grupo;

  usuariosOriginais = [];

  permissoes: Permissao[] = [];

  placeholder: string = "Ex.: administrador";

  // configuracoes da tabela
  nomeAtributosTabela = ["nome", "matricula", "cpf"];
  nomeColunasTabela = ["Nome", "Matrícula", "CPF"];

  disparadorEventoTabelaSubject: Subject<EventoTabela> = new ReplaySubject();

  /**
   * Marcação que indica se as permissões
   * estão sendo carregadas
   */
  permissoesLoading: boolean = false;

  constructor(
    protected securityService: SecurityService,
    protected changeDetector: ChangeDetectorRef,
    public grupoService: GrupoService,
    public funcionarioService: FuncionarioService,
    protected permissaoService: PermissaoService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) {
    super(
      securityService,
      "grupo",
      grupoService,
      activatedRoute,
      router,
      toastr,
      notificatorService,
      modalService
    );
  }

  ngOnInit() {
    this.grupo = new Grupo();
    super.ngOnInit();
    this.carregarPermissoes();
    // Cria o formgroup
    this.initFormGroup();
  }

  carregarPermissoes() {
    this.permissoesLoading = true;

    this.permissaoService.listar().subscribe({
      next: (permissoes: Permissao[]) => {
        if (this.isEdicao && this.permissoes.length > 0) {
          this.permissoes.forEach((permissao) => {
            permissoes.forEach((permissaoServidor) => {
              if (permissaoServidor.nomeReal === permissao.nomeReal) {
                permissaoServidor.selecionado = permissao.selecionado;
              }
            });
          });
        }

        this.permissoes.length = 0;
        this.permissoes.push(...permissoes);
        console.log("noLoading", permissoes);

        this.permissoesLoading = false;
        // tslint:disable-next-line:align
      },
      error: (error) => {
        const operacao = this.isEdicao ? "atualizar" : "cadastrar";
        const operacaoCapitalizada =
          stringFormatter.capitalizeFirstLetter(operacao);

        this.modalService.showModal({
          title: `${operacaoCapitalizada} grupo`,
          messageModal:
            "Não foi possível carregar as permissões, tente novamente",
          btnTitlePositive: "Entendi",
          icon: "fa-light fa-circle-info",
          isOnlyConfirmation: true,
          positiveCallback: () => this.router.navigate([""]),
        });

        this.permissoesLoading = false;
      },
    });
  }

  initFormGroup() {
    // tslint:disable-next-line:max-line-length
    this.formControls["nome"] = new UntypedFormControl(this.grupo.nome, [
      Validators.required,
      Validators.maxLength(20),
    ]);
    this.formControls["ativo"] = new UntypedFormControl(this.grupo.ativo, [
      Validators.required,
    ]);
    this.formControls["permissoes"] = new UntypedFormControl();
    this.formControls["usuariosAdicionados"] = new UntypedFormControl();
    this.formControls["usuariosRemovidos"] = new UntypedFormControl();

    this.formGroup = new UntypedFormGroup({
      nome: this.formControls.nome,
      ativo: this.formControls.ativo,
      permissoes: this.formControls.permissoes,
      usuariosAdicionados: this.formControls.usuariosAdicionados,
      usuariosRemovidos: this.formControls.usuariosRemovidos,
    });
  }

  ngAfterViewChecked(): void {
    this.atualizarValidadorPermissoes();
    //Detecta novas alterações mesmo depois da checagem estar concluida
    this.changeDetector.detectChanges();
  }

  entidadeEdicaoCallback(entidade: Grupo) {
    this.grupo = entidade;
    // tratando as permissoes previas
    if (this.permissoes.length > 0) {
      entidade.permissoes.forEach((permissaoPrevia) => {
        this.permissoes.forEach((permissao) => {
          if (permissao.nomeReal === permissaoPrevia) {
            permissao.selecionado = true;
          }
        });
      });
    } else {
      const permissoesTemporarias = entidade.permissoes.map(
        (permissaoPrevia) => {
          const permissao = new Permissao();
          permissao.nomeReal = permissaoPrevia;
          permissao.selecionado = true;

          return permissao;
        }
      );
      this.permissoes.push(...permissoesTemporarias);
    }
    // carregando os usuarios selecionados
    // tslint:disable-next-line:max-line-length
    const idUsuariosSelecionados = entidade.usuariosAdicionados.map(
      (usuarioSelecionado) => usuarioSelecionado.id
    );
    // tslint:disable-next-line:max-line-length
    this.usuariosOriginais.push(...idUsuariosSelecionados);

    // tslint:disable-next-line: max-line-length
    const eventoTabelaSelecao: EventoTabela = new EventoTabela(
      TipoEventoTabela.SELECAO,
      idUsuariosSelecionados
    );

    this.disparadorEventoTabelaSubject.next(eventoTabelaSelecao);

    this.initFormGroup();
  }

  prepararEntidadeParaPersistencia(entidade: Grupo): Grupo {
    const entityToSave = { ...entidade };

    const permissoesSelecionadas = this.getPermissoesSelecionadas();
    const permissoes = permissoesSelecionadas.map(
      (permissao) => permissao.nomeReal
    );

    entityToSave.permissoes = permissoes;

    return entityToSave;
  }

  onPermissaoSelected(event) {
    const permissaoNomeReal = event.target.value;

    if (!permissaoNomeReal) {
      return;
    }

    this.alterarSelecaoPermissao(permissaoNomeReal);
    const permissaoSelect = event.target;
    permissaoSelect.value = null;

    this.atualizarValidadorPermissoes();
  }

  getPermissoesSelecionadas(): Permissao[] {
    return this.permissoes.filter((permissao) => permissao.selecionado);
  }

  alterarSelecaoPermissao(permissaoNomeReal: string) {
    const permissoesCorrespondentes = this.permissoes.filter((permissao) => {
      return permissao.nomeReal === permissaoNomeReal;
    });

    if (permissoesCorrespondentes && permissoesCorrespondentes.length > 0) {
      permissoesCorrespondentes[0].selecionado =
        !permissoesCorrespondentes[0].selecionado;
    }

    this.atualizarValidadorPermissoes();
  }

  manipularEventoTabela(eventoTabela: EventoTabela) {
    switch (eventoTabela.tipo) {
      case TipoEventoTabela.SELECAO:
        {
          this.formControls.usuariosAdicionados.patchValue(
            eventoTabela.payload
          );
          const usuariosAtuais = this.formControls.usuariosAdicionados.value;

          const usuariosRemovidos = this.usuariosOriginais.filter(
            (usuarioOriginal) => !usuariosAtuais.includes(usuarioOriginal)
          );

          this.formControls.usuariosRemovidos.patchValue(usuariosRemovidos);
        }
        break;
    }
  }

  atualizarValidadorPermissoes() {
    const permissoesSelecionadas = this.getPermissoesSelecionadas();

    if (permissoesSelecionadas.length > 0) {
      this.formControls.permissoes.reset();
    } else {
      this.formControls.permissoes.setErrors({ empty: true });
    }
  }

  /**
   * @override
   */
  getRedirectPath(): string[] {
    const listagemAuthorities = [
      Authority.ATUALIZAR_GRUPO,
      Authority.LISTAR_GRUPO,
    ];
    if (this.securityService.userHasAuthorities(listagemAuthorities)) {
      return ["grupo"];
    }
    return [""];
  }
}
