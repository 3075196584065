import { Injectable } from "@angular/core";
import { CrudService } from "app/servico/requestService/crudService";
import { RequestService } from "app/servico/request.service";
import { apiLocation, nominatimLocation, nominatimReverseLocation, nominatimLookupUrl, viaCepUrl } from "app/infraestrutura/apiLocation";
import { Localidade } from "../localidade";
import { Observable, of } from "rxjs";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import { LocalidadeTree } from "../localidadeTree";
import { LocalidadeMapeada } from "../localidadeMapeada";

@Injectable({
  providedIn: "root",
})
export class LocalidadeService extends CrudService<Localidade> {
  requestUrl: string;
  public baseUrl = this.resourcePath;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/localidades`);
  }

  atualizarLocalidade(
    localidade: LocalidadeMapeada,
    mapErrorToString: boolean = true
  ): Observable<LocalidadeMapeada> {
    this.requestUrl = `${this.resourcePath}/${localidade.id}`;

    return this.requestService.put(
      this.requestUrl,
      localidade,
      {},
      mapErrorToString
    );
  }

  listarLocalidades(id?: string): Observable<any[]> {
    this.requestUrl = `${this.resourcePath}/listar-filhos?codigo=${id}`;

    const response = <Observable<any[]>>(
      this.requestService.get(this.requestUrl)
    );

    return response;
  }

  listarLocalidadeById(id: string): Observable<Localidade> {
    this.requestUrl = `${this.resourcePath}/${id}`;

    const responseData = <Observable<Localidade>>(
      this.requestService.get(this.requestUrl)
    );

    return responseData;
  }

  /**
   * lista todas as localidades filhas a partir do identificador
   * de uma localidade pai.
   *
   * Obs: este metodo retorna, para cada filha, toda a arvore de localidades.
   * @param id identificador da localidade pai
   */
  listarLocalidadesFilhas(id: number): Observable<LocalidadeTree[]> {
    this.requestUrl = `${this.resourcePath}/${id}/filhas`;

    const responseData = <Observable<LocalidadeTree[]>>(
      this.requestService.get(this.requestUrl)
    );
    return responseData;
  }

  listarTodos(palavraChave: PalavraChave): Observable<Localidade[]> {
    const requestUrl = `${this.resourcePath}/filtrar/palavra-chave?page=0&size=10`;

    // tslint:disable-next-line:max-line-length
    const response = <Observable<Localidade[]>>(
      this.requestService.post(requestUrl, palavraChave, true)
    );

    return response;
  }

  // Obter o total de localidades cadastradas
  totalDeLocalidades(search?: string): Observable<any> {
    const searchBox = search ? search : "";
    const palavraChave = new PalavraChave(searchBox.trim().split(" "));

    const requestPath = `${this.resourcePath}/total-registros`;

    const response = <Observable<any[]>>(
      this.requestService.post(requestPath, palavraChave, true)
    );

    return response;
  }

  // Listar localidades (nova rota) que retorna também a quantidade de filhas
  listarLocalidadesParaTabela(
    search?: string,
    page?: number,
    filtrarFilhas?: boolean
  ): Observable<any[]> {
    const searchBox = search ? search : "";
    const pageToLook = page ? page : 0;
    const filhasToLook = filtrarFilhas ? filtrarFilhas : false;

    const palavraChave = new PalavraChave(searchBox.trim().split(" "));

    const requestPath = `${this.resourcePath}/todos/filtrar/palavra-chave?filtrarFilhas=${filhasToLook}&page=${pageToLook}&size=10`;

    const response = <Observable<any[]>>(
      this.requestService.post(requestPath, palavraChave, true)
    );

    return response;
  }

  // requisição para deletar localidade
  deletarLocalidade(id: any): Observable<Localidade[]> {
    const requestPath = `${this.resourcePath}/${id}`;

    const response = <Observable<Localidade[]>>(
      this.requestService.delete(requestPath)
    );

    return response;
  }

  /**
   * duplica uma Localidade, dado o identificador da pesquisa original
   * @param id identificador da localidade a ser duplicada
   */
  postDuplicarPesquisa(id: number): Observable<Localidade> {
    const requestUrl = `${apiLocation}/localidades/copia/${id}`;

    return <Observable<Localidade>>this.requestService.post(requestUrl, true);
  }

  listarLocalidadesComDetalhes(busca: string) {
    const sogoURLNominatim = nominatimLocation(busca);
    let valor = [];
    const headerDict = {
      'Accept-Language' : 'pt-BR',
    };
    const requestOptions = {
      headers: new Headers(headerDict),
    };
    fetch(sogoURLNominatim, requestOptions)
      .then((data) => data.json())
      .then((data) => data.map((item) => valor.push(item)))
      .catch((error) => console.error(error));

    return valor;
  }

  listarLocalidadesComDetalhesAsync(busca: string) {
    const sogoURLNominatim = nominatimLocation(busca);

    const headerDict = {
      'Accept-Language' : 'pt-BR',
    };

    const requestOptions = {
      headers: new Headers(headerDict),
    };

    const promiseValor = fetch(sogoURLNominatim, requestOptions)
      .then((data) => data.json())
      .then((result) => result)
      .catch((error) => console.error(error));

    return promiseValor;
  }

  // Recupera uma lista de localidades através do reverse-api do nominatim
  // que recupera objetos OSM (open street map) através de uma coordenada (lat e long)
  listarLocalidadesComDetalhesByCoords(lat: number, long: number) {
    const sogoURLNominatim = nominatimReverseLocation(lat, long);

    const headerDict = {
      'Accept-Language' : 'pt-BR',
    };

    const requestOptions = {
      headers: new Headers(headerDict),
    };

    const promiseValor = fetch(sogoURLNominatim, requestOptions)
      .then((data) => data.json())
      .then((result) => result)
      .catch((error) => console.error(error));

    return promiseValor;
  }

  /**
   * Recupera uma lista de localidades com osm_ids conhecidos.
   * @param geoIds - lista de osm_ids
   */
  listarLocalidadesComDetalhesByGeoId(geoIds: string[]) {
    const sogoURLNominatim = nominatimLookupUrl(geoIds.join(','));

    const headerDict = {
      'Accept-Language' : 'pt-BR',
    };

    const requestOptions = {
      headers: new Headers(headerDict),
    };

    const promiseValor = fetch(sogoURLNominatim, requestOptions)
      .then((data) => data.json())
      .then((result) => result)
      .catch((error) => console.error(error));

    return promiseValor;
  }

  buscarLocalidadeViaCEP(cep: string) {
    const viaCep = viaCepUrl(cep);

    const result = fetch(viaCep)
      .then((data) => data.json())
      .then((result) => result)
      .catch((error) => console.error(error));

    return result;
  }

}
