<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <img class="cliente-icon" src="assets/icons/cliente-register-icon.svg" />
      <h2 [routerLink]="'/cliente-beta'">Clientes</h2>
      <span>/</span>
      <h4>{{ tituloFormulario }}</h4>
    </div>
  </div>
  <form
    class="content-clientes-adicionar"
    #form="ngForm"
    [formGroup]="formGroup"
    (ngSubmit)="form.valid && salvar(form.value); showNotificationError()"
  >
    <div class="row sg-input-group section">
      <div class="col-12 sub-title">
        <h3>Informações básicas</h3>
      </div>
      <!-- IMAGEM -->
      <div class="mt-3 mb-5 mr-5 ml-3">
        <app-new-foto-uploader></app-new-foto-uploader>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
            <!-- TIPO CLIENTE -->
            <div class="campo">
              <label>Tipo</label>
              <div formGroupName="pessoa">
                <select
                  title="tipo do cliente"
                  formControlName="tipoPessoa"
                  (change)="onTipoPessoaChange($event)"
                >
                  <option value="F">Pessoa Física</option>
                  <option value="J">Pessoa Jurídica</option>
                </select>
              </div>
              <!-- <span class="error-message" *ngIf="form.submitted && formGroup.controls.pessoa.tipoPessoa.invalid">Preencha o tipo
              do cliente</span> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
            <!-- CPF -->
            <div
              class="campo"
              [hidden]="tipoPessoa !== 'F'"
              formGroupName="pessoa"
            >
              <label for="">CPF *</label>
              <input
                placeholder="Ex.:111.222.333-96"
                title="cpf do cliente"
                type="text"
                mascara-cpf
                formControlName="cpf"
              />
              <span
                class="error-message"
                [hidden]="!(form.submitted && formControls.cpf.errors)"
                >CPF inválido</span
              >
            </div>
            <!-- CNPJ -->
            <div
              class="campo"
              [hidden]="tipoPessoa !== 'J'"
              formGroupName="pessoa"
            >
              <label for="">CNPJ *</label>
              <input
                placeholder="Ex.:11.222/3333-96"
                title="cnpj do cliente"
                id="cnpj"
                mascara-cnpj
                formControlName="cnpj"
                type="text"
              />
              <span
                class="error-message"
                *ngIf="form.submitted && formControls.cnpj.errors"
                >CNPJ inválido</span
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
            <!-- RG   -->
            <div formGroupName="pessoa">
              <div class="campo" [hidden]="tipoPessoa !== 'F'">
                <label for="">RG *</label>
                <input
                  placeholder="Ex.:111334"
                  title="rg do cliente"
                  mascara-rg
                  formControlName="rg"
                  id="rg"
                  type="text"
                  maxlength="10"
                />
                <span
                  class="error-message"
                  *ngIf="form.submitted && formControls.rg.errors"
                  >RG inválido</span
                >
              </div>
            </div>
            <!-- INSCRICAO ESTADUAL -->
            <div formGroupName="pessoa">
              <div class="campo" [hidden]="tipoPessoa !== 'J'">
                <label for="">Inscrição estadual</label>
                <input
                  placeholder="Ex.:123456789123456"
                  title="inscrição estadual do cliente"
                  mascara-inscricaoEstadual
                  formControlName="inscricaoEstadual"
                  id="inscricaoEstadual"
                  type="text"
                  maxlength="15"
                />
                <!-- <span class="error-message" *ngIf="form.submitted && formControls.inscricaoEstadual.errors">Insira a
                  inscrição estadual</span> -->
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-md-6 col-sm-12 content-spacing">
            <!-- NOME DO CLIENTE  -->
            <div
              class="campo"
              [hidden]="tipoPessoa !== 'F'"
              formGroupName="pessoa"
            >
              <label for="">Nome do cliente *</label>
              <input
                mascara-primeiro-digito-sem-espaco
                mask-only-letters
                placeholder="Ex.:manoel soares da silva"
                title="nome do cliente"
                id="nome"
                formControlName="nome"
                type="text"
                maxlength="50"
              />
              <span
                class="error-message"
                *ngIf="form.submitted && formControls.nome.errors"
                >Insira o nome</span
              >
            </div>
            <!-- EMPRESA -->
            <div
              class="campo"
              [hidden]="tipoPessoa !== 'J'"
              formGroupName="pessoa"
            >
              <label for="">Nome da empresa *</label>
              <input
                mascara-primeiro-digito-sem-espaco
                placeholder="Ex.:sogo"
                title="nome da empresa"
                id="nomeEmpresa"
                formControlName="nome"
                type="text"
                maxlength="50"
              />
              <span
                class="error-message"
                *ngIf="form.submitted && formControls.nome.errors"
                >Insira o nome da empresa</span
              >
            </div>
          </div>
          <div
            class="col-lg-5 col-md-6 col-sm-12 content-spacing"
            [hidden]="tipoPessoa !== 'J'"
          >
            <!-- RESPONSAVEL -->
            <div
              class="campo"
              [hidden]="tipoPessoa !== 'J'"
              formGroupName="pessoa"
            >
              <label for="">Nome do responsável</label>
              <input
                mascara-primeiro-digito-sem-espaco
                placeholder="Ex.:eduardo da silva"
                title="responsável"
                formControlName="responsavel"
                id="responsavel"
                type="text"
                maxlength="50"
              />
              <span
                class="error-message"
                *ngIf="form.submitted && formControls.responsavel.errors"
              >
                O nome do responsável deve ter até 50 caracteres
              </span>
            </div>
          </div>

          <div class="col-lg-5 col-md-6 col-sm-12 content-spacing">
            <!-- EMAIL -->
            <div class="campo" formGroupName="contato">
              <label for="">E-mail *</label>
              <input
                id="email"
                formControlName="email"
                (ngModelChange)="onEmailChange($event)"
                type="email"
                placeholder="Ex.:jose@gmail"
                maxlength="50"
              />
              <span
                class="error-message"
                *ngIf="form.submitted && formControls.email.errors"
              >
                Insira um email válido
              </span>
            </div>
          </div>

          <div class="col-lg-5 col-md-6 col-sm-12 content-spacing">
            <!-- TELEFONE -->
            <div class="campo" formGroupName="contato">
              <label for="">Telefone </label>
              <input
                placeholder="Ex.:34216666"
                title="telefone do cliente"
                formControlName="telefone"
                mascara-telefone
                id="telefone"
                type="text"
              />
              <span
                class="error-message"
                [hidden]="!(form.submitted && formControls.telefone.errors)"
                >Insira um telefone válido</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row section form sg-input-group">
      <div class="col-12 sub-title">
        <h3>Endereço</h3>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12 content-spacing">
        <!-- CEP  -->
        <div formGroupName="pessoa">
          <div class="campo" formGroupName="endereco">
            <label for="">CEP</label>
            <input
              placeholder="Ex.:583100000"
              title="cep do cliente"
              id="cep"
              mascara-cep
              formControlName="cep"
              type="text"
              maxlength="9"
              minlength="9"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
        <!-- LOGRADOURO  -->
        <div formGroupName="pessoa">
          <div class="campo" formGroupName="endereco">
            <label for="">Logradouro</label>
            <input
              mascara-primeiro-digito-sem-espaco
              placeholder="Ex.:rua são joão"
              title="logradouro do cliente"
              id="logradouro"
              formControlName="logradouro"
              type="text"
              maxlength="60"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 content-spacing">
        <!-- NUMERO -->
        <div class="campo" formGroupName="pessoa">
          <div formGroupName="endereco">
            <label for="">Número</label>
            <input
              placeholder="Ex.:22"
              title="número da residência do cliente"
              id="numero"
              formControlName="numero"
              mascara-numero
              type="text"
              maxlength="5"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12 content-spacing">
        <!-- COMPLEMENTO  -->
        <div class="campo" formGroupName="pessoa">
          <div formGroupName="endereco">
            <label for="">Complemento</label>
            <input
              mascara-primeiro-digito-sem-espaco
              placeholder="Ex: APT 0001, Bloco C"
              title="complemento"
              id="complemento"
              formControlName="complemento"
              type="text"
              maxlength="60"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
        <!-- BAIRRO  -->
        <div class="campo" formGroupName="pessoa">
          <div formGroupName="endereco">
            <label for="">Bairro</label>
            <input
              mascara-primeiro-digito-sem-espaco
              placeholder="Ex.:Manaíra"
              title="bairro do cliente"
              id="bairro"
              formControlName="bairro"
              type="text"
              maxlength="60"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
        <!-- CIDADE  -->
        <div class="campo" formGroupName="pessoa">
          <div formGroupName="endereco">
            <label for="">Cidade</label>
            <input mascara-primeiro-digito-sem-espaco placeholder="Ex.:João Pessoa" title="cidade do cliente"
              formControlName="cidade" id="cidade" type="text" maxlength="40" />
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 content-spacing">
        <!-- ESTADO  -->
        <div class="campo" formGroupName="pessoa">
          <div formGroupName="endereco">
            <label for="">Estado</label>
            <input mascara-primeiro-digito-sem-espaco placeholder="Ex.:Paraiba" title="estado do cliente"
              formControlName="estado" id="estado" type="text" maxlength="20" />
          </div>
        </div>
      </div>
    </div>

    <div class="row section form sg-input-group">
      <div class="col-12 sub-title">
        <h3>Dados de acesso</h3>
      </div>
      <div class="col-md-4 content-spacing">
        <!-- SENHA -->
        <div class="campo" formGroupName="usuario">
          <label *ngIf="isEdicao" for="">Senha</label>
          <label *ngIf="!isEdicao" for="">Senha *</label>
          <app-input-password
            [isEdicao]="isEdicao"
            [isAlteracaoSenha]="isAlteracaoSenha"
            [parentFormGroup]="formGroup"
            [controlName]="'senha'"
          ></app-input-password>
          <!-- <input formControlName="senha" id="password" type="password" placeholder="" maxlength="30"> -->
          <span
            class="error-message"
            [hidden]="
              !(
                form.submitted &&
                formControls.senha.errors &&
                (formControls.senha.errors.required ||
                  formControls.senha.errors.minlength ||
                  formControls.senha.errors.maxlength)
              )
            "
          >
            Informe uma senha entre 8 e 30 caracteres
          </span>
        </div>
      </div>
      <div class="col-md-4 content-spacing">
        <!-- REPETIR SENHA -->
        <div class="campo" formGroupName="usuario">
          <label *ngIf="isEdicao" for="">Repetir Senha</label>
          <label *ngIf="!isEdicao" for="">Repetir Senha *</label>
          <app-input-password
            [isEdicao]="isEdicao"
            [isAlteracaoSenha]="isAlteracaoSenha"
            [parentFormGroup]="formGroup"
            [controlName]="'senhaConfirmar'"
          ></app-input-password>
          <!-- <input formControlName="senhaConfirmar" id="senhaConfirmar" type="password" placeholder="" maxlength="30"> -->
          <span
            class="error-message"
            [hidden]="!(form.submitted && formControls.senhaConfirmar.errors)"
          >
            As senhas estão diferentes
          </span>
        </div>
      </div>
      <div class="col-md-4 content-spacing">
        <!-- STATUS -->
        <div class="campo" formGroupName="usuario">
          <label for="">Status *</label>
          <select title="status do cliente" formControlName="ativo">
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
        </div>
        <!-- EMAIL -->
        <!-- <div class="col-md-3">
          <div class="campo campo-hidden" formGroupName="usuario">
            <label for="">Email *</label>
            <input hidden placeholder="Ex.:jose@gmail" title="email de acesso ao sistema" formControlName="login"
              type="email" maxlength="50">
            <span class="error-message" *ngIf="form.submitted && formControls.login.errors">Informe um email
              válido</span>
          </div>
        </div> -->
      </div>
      <!-- ATIVAR ALTERAÇÃO DE SENHA -->
      <div class="campo-inline" *ngIf="isEdicao">
        <mat-slide-toggle
          title="ativar alteração de senha"
          (click)="habilitarAlteracaoSenha()"
        ></mat-slide-toggle>
        <label>Habilitar alteração de senha</label>
      </div>
      <div class="col-12 btns-actions">
        <button
          class="sg-btn sg-alternate"
          (click)="cancelarFluxo($event)"
          title="Cancelar"
        >
          CANCELAR
        </button>
        <button
          *ngIf="!isEdicao"
          class="sg-btn sg-secondary"
          type="submit"
          title="Adicionar cliente"
        >
          ADICIONAR
        </button>
        <button
          *ngIf="
            isEdicao &&
            getAuthenticatedUserAuthorities().includes(
              authority.ATUALIZAR_CLIENTE
            )
          "
          class="sg-btn sg-secondary"
          type="submit"
          title="Atualizar dados do cliente"
        >
          ATUALIZAR
        </button>
      </div>
    </div>
  </form>
</div>

<app-loading *ngIf="isLoading"></app-loading>
