import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { v4 as uuid } from "uuid";
import { ICanNotSaveButton } from "../../../pesquisas-cadastro.model";
import {
  SectionElemModel,
  SectionElemQuestionModel,
  TipoAlternativa,
  TipoPergunta,
} from "./pesquisas-questionario-secoes-pergunta-cadastro.model";

interface Column {
  descricao: string;
}

interface Row {
  descricao: string;
}

const PERGUNTA_ALTERNATIVA_MAX_LENGTH: number = 250;
const ALTERNATIVA_ABERTA_MAX_LENGTH: number = 40;

@Component({
  selector: "app-pesquisas-questionario-secoes-pergunta-cadastro",
  templateUrl:
    "./pesquisas-questionario-secoes-pergunta-cadastro.component.html",
  styleUrls: [
    "./pesquisas-questionario-secoes-pergunta-cadastro.component.scss",
  ],
})
export class CadastroPerguntaComponent implements OnInit {
  @Input() sequence: number = 1;
  @Input() surveyTitle: string = "Título da pesquisa";
  @Input() sectionId: number = -1;
  @Input() sectionSeq: number = 1;
  @Input() sectionName: string = "Nome da seção";
  @Input() model: SectionElemModel;
  //  Input para informar se o usuário deseja editar
  @Input() desejaEditar: boolean = false;
  //  Indice do array de secoes da secao em que se deseja criar uma nova pergunta
  @Input() IndiceDaSecaoSelecionada: number = 0;
  //  Titulo da secao em que se deseja criar uma nova pergunta
  @Input() TituloDaSecaoSelecionada: string = "";
  //  Evento de criar nova pergunta
  @Output() onSave: EventEmitter<{
    sectionId: number;
    sectionElem: SectionElemModel;
  }> = new EventEmitter();
  // Evento de clique do checkbox do item da listagem de pesquisa
  @Output() onCancel = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> =
    new EventEmitter();

  // Boolean para informar se as opções de tipos de perguntas estão visiveis para o usuario
  tiposDePerguntaVisiveis: boolean = false;
  // timer para debounce do digitar do texto
  timer = null;
  // indice da alternativa que tem seu seletor de tipos de alternativa com o menu de opcoes visivel
  indiceAlternativaComMenuVisivel: number = -1;
  //
  formGroup: UntypedFormGroup;
  // objeto de facil acesso pawra os formControls
  formControls: { [key: string]: UntypedFormControl } = {} as {
    [key: string]: UntypedFormControl;
  };
  // variáveis de grade que recebem o valor enquanto o botão de salvar não é clicado
  gradeRows;
  gradeColumns;
  // opções do setValue do formGroup
  options = {
    emitEvent: true,
    onlySelf: true,
  };

  alternativaMaxLength: number = 250;

  alternativasFormArray = new UntypedFormArray([]);
  gradeLinhasFormArray = new UntypedFormArray([]);

  //  String utilizada para referenciar o ultimo input que houve evento de change do texto
  //  Para caso o usuario troque de input na pergunta do tipo grade (das alternativas e dos titulos grade)
  //  Num tempo menor que o timer e ignore o conteúdo digitado no input anterior
  inputTimerStamp = "";

  // variável para guardar os tipos de pergunta
  typesAlt = TipoAlternativa;
  typesPer = TipoPergunta;

  ngOnInit() {
    this.initFormGroup();

    this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: true } });

    this.formGroup.get("obrigatoria").setValue(true);
    this.formGroup.get("faixaPergunta").setValue({
      intervaloInicio: 0,
      intervaloFim: 10,
    });
    this.formGroup.get("alternativas").setValue(
      [] as {
        descricao: string;
        tipoAlternativa?: TipoAlternativa;
        ordem?: number;
      }[]
    );
    this.formGroup.get("qtdMinima").setValue(1);
    this.formGroup.get("qtdMaxima").setValue(1);
    this.formGroup.get("titulosGrade").setValue([
      {
        descricao: "",
        ordem: 1,
      },
    ]);
    //
    if (!!this.model && !!this.model.pergunta) {
      this.formGroup.get("nome").setValue(this.model.pergunta.nome);
      this.formGroup.get("orientacao").setValue(this.model.pergunta.descricao);
      this.formGroup.get("tipo").setValue(this.model.pergunta.tipo);
      this.formGroup
        .get("obrigatoria")
        .setValue(this.model.pergunta.obrigatoria);
      //
      const tipoDaPerguntaAEditar = this.model.pergunta.tipo;

      // A fim de evitar perda de tempo testando tipo de pergunta para os tipos que estes não se encaixam
      if (
        [
          TipoPergunta.DATA,
          TipoPergunta.FOTO,
          TipoPergunta.HORARIO,
          TipoPergunta.VIDEO,
        ].includes(tipoDaPerguntaAEditar)
      )
        return;
      //
      if (tipoDaPerguntaAEditar === TipoPergunta.ESCALA_NUMERICA) {
        this.formGroup
          .get("faixaPergunta")
          .setValue(this.model.pergunta.faixaPergunta);
        return;
      }
      if (
        [
          TipoPergunta.UNICA,
          TipoPergunta.MULTIPLA,
          TipoPergunta.GRADE_UNICA,
          TipoPergunta.GRADE_MULTIPLA,
        ].includes(tipoDaPerguntaAEditar)
      ) {
        if (
          this.model.pergunta.alternativas &&
          this.model.pergunta.alternativas.length > 0
        ) {
          const alternativas = this.formGroup.get("alternativas");
          alternativas.setValue(this.model.pergunta.alternativas);

          this.updateAlternativasFormArray(alternativas.value);
        }
      }
      //
      if (
        [TipoPergunta.MULTIPLA, TipoPergunta.GRADE_MULTIPLA].includes(
          tipoDaPerguntaAEditar
        )
      ) {
        this.formGroup.get("qtdMinima").setValue(this.model.pergunta.qtdMinima);
        this.formGroup.get("qtdMaxima").setValue(this.model.pergunta.qtdMaxima);
      }
      //
      if (
        [TipoPergunta.GRADE_UNICA, TipoPergunta.GRADE_MULTIPLA].includes(
          tipoDaPerguntaAEditar
        )
      ) {
        if (
          this.model.pergunta.titulosGrade &&
          this.model.pergunta.titulosGrade.length > 0
        ) {
          this.formGroup
            .get("titulosGrade")
            .setValue(this.model.pergunta.titulosGrade);
        }
      }
    }

    // o setTimeout é usado para dar um atraso na chamada da chamada da função
    // no caso ele espera todo o componente ser renderizado para chamar.
    setTimeout(() => {
      // caso tenha alternativas a mesma estratégia é usada
      if (this.model.pergunta && this.model.pergunta !== null) {
        const nome = { target: { value: this.model.pergunta.nome } };
        const descricao = { target: { value: this.model.pergunta.descricao } };

        this.handleInputChange(nome, "nome");
        this.handleInputChange(descricao, "orientacao");
        this.model.pergunta.alternativas.map((alt, index) => {
          const altI = { target: { value: alt.descricao } };
          this.handleInputChange(altI, `alternativa${index}`);
        });
      }
    }, 0);
  }

  initFormGroup() {
    this.formControls["nome"] = new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(PERGUNTA_ALTERNATIVA_MAX_LENGTH),
    ]);
    this.formControls["orientacao"] = new UntypedFormControl("", [
      Validators.maxLength(PERGUNTA_ALTERNATIVA_MAX_LENGTH),
    ]);
    this.formControls["tipo"] = new UntypedFormControl("", [
      Validators.required,
    ]);
    this.formControls["obrigatoria"] = new UntypedFormControl();
    this.formControls["faixaPergunta"] = new UntypedFormControl();
    this.formControls["alternativas"] = new UntypedFormControl();
    this.formControls["qtdMinima"] = new UntypedFormControl();
    this.formControls["qtdMaxima"] = new UntypedFormControl();
    this.formControls["titulosGrade"] = new UntypedFormControl();

    this.formGroup = new UntypedFormGroup({
      nome: this.formControls.nome,
      orientacao: this.formControls.orientacao,
      tipo: this.formControls.tipo,
      obrigatoria: this.formControls.obrigatoria,
      faixaPergunta: this.formControls.faixaPergunta,
      alternativas: this.formControls.alternativas,
      qtdMinima: this.formControls.qtdMinima,
      qtdMaxima: this.formControls.qtdMaxima,
      titulosGrade: this.formControls.titulosGrade,
    });
  }

  updateAlternativasFormArray(
    alternativas,
    isArrayOfLabels: boolean = false,
    isGradeQuestionType: boolean = false
  ) {
    const formArrayReference =
      (!isGradeQuestionType && this.alternativasFormArray) ||
      this.gradeLinhasFormArray;
    this.resetAlternativasFormArray(formArrayReference);

    if (typeof alternativas === "object") {
      alternativas.forEach((alternativa, alternativaIndex: number) => {
        this.setAltenativeMaxLength(isArrayOfLabels, alternativa);

        const description = !isArrayOfLabels
          ? alternativa.descricao || ""
          : alternativa;

        // TODO: Deve ser refatorada essa logica de adicionar control a cada alteração no input
        formArrayReference.setControl(
          alternativaIndex,
          new UntypedFormControl(description, [
            Validators.required,
            Validators.maxLength(this.alternativaMaxLength),
          ])
        );
      });
    }
  }

  resetAlternativasFormArray(formArrayReference) {
    formArrayReference.controls.length = 0;
    formArrayReference.value.length = 0;
  }

  setControlAlternativasFormArray(index: number) {
    this.alternativasFormArray.setControl(
      index,
      new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(PERGUNTA_ALTERNATIVA_MAX_LENGTH), // alternativa fechada sempre inicia com 250
      ])
    );
  }

  //  Função que formata o tipo de uma alternativa
  formatTipoAlternativa(tipo: TipoAlternativa): string {
    switch (tipo) {
      case TipoAlternativa.ABERTA_NUMERO:
        return "Aberta Número";
      case TipoAlternativa.ABERTA_TEXTO:
        return "Aberta";
      default:
        return "Fechada";
    }
  }

  // Função para alterar o estado de visibilidade das opções do tipo de perguntas baseado no valor anterior
  toggleVisibilidadeDePerguntas() {
    this.tiposDePerguntaVisiveis = !this.tiposDePerguntaVisiveis;
  }

  // Função que formata tipo de pergunta para o placeholder do select
  formatTipo(tipo: TipoPergunta): string {
    return tipo === TipoPergunta.DATA
      ? "Data"
      : tipo === TipoPergunta.ESCALA_NUMERICA
      ? "Escala Numérica"
      : tipo === TipoPergunta.FOTO
      ? "Foto"
      : tipo === TipoPergunta.GRADE_MULTIPLA
      ? "Grade Múltipla"
      : tipo === TipoPergunta.GRADE_UNICA
      ? "Grade Única"
      : tipo === TipoPergunta.HORARIO
      ? "Horário"
      : tipo === TipoPergunta.MULTIPLA
      ? "Resposta Múltipla"
      : tipo === TipoPergunta.UNICA
      ? "Resposta Única"
      : "Vídeo";
  }

  //  Função de drop da alternativa
  drop(event: CdkDragDrop<string[]>) {
    const questions = this.formGroup.get("alternativas").value;

    moveItemInArray(questions, event.previousIndex, event.currentIndex);

    // Garante que o atributo ordem de uma alternativa sempre estará correto
    // de acordo com o índice atual do array
    questions.forEach((alternativa, index: number) => {
      alternativa.ordem = 1 + index;
    });

    this.formGroup.get("alternativas").setValue(questions);
    this.updateAlternativasFormArray(questions);
  }

  //  Função de adicionar alternativa
  adicionarAlternativa(): void {
    const newAlternativas = [
      ...this.formGroup.get("alternativas").value,
      {
        descricao: "",
        tipoAlternativa: TipoAlternativa.FECHADA,
        ordem: this.formGroup.get("alternativas").value.length
          ? this.formGroup.get("alternativas").value.length + 1
          : 1,
      },
    ];

    this.formGroup.get("alternativas").setValue(newAlternativas);

    // Adiciona controle de validação para esta alternativa
    this.setControlAlternativasFormArray(
      this.alternativasFormArray.value.length
    );
  }

  //  Função que remove alternativa
  removerAlternativa(alternativaIndex: number): void {
    this.formGroup.get("alternativas").setValue([
      ...this.formGroup.get("alternativas").value.filter(
        (
          alternativa: {
            descricao: string;
            tipoAlternativa: TipoAlternativa;
          },
          index: number
        ) => index !== alternativaIndex
      ),
    ]);

    // Remove o controle de validação atrelado à alternativa removida.
    this.alternativasFormArray.removeAt(alternativaIndex);
  }

  // Seta a classe da alternativa, verificando se a alternativa possui erro de limite de caracteres.
  handleAlternativaClassName(
    tipoAlternativa: string,
    alternativaIndex: number
  ) {
    let className = "";
    const FECHADA = "tipo-alternativa-fechada";
    const ERROR = " error";

    const classNames = {
      fechada: "FECHADA",
      selecione: "Selecione",
      aberta: "ABERTA",
      grade: "GRADE",
    };

    const { fechada, selecione, aberta } = classNames;

    if (tipoAlternativa === fechada || tipoAlternativa === selecione) {
      className += FECHADA;
    }

    const alternativaControl =
      this.alternativasFormArray.controls[alternativaIndex];
    if (
      alternativaControl &&
      alternativaControl.errors &&
      (alternativaControl.errors.maxlength ||
        alternativaControl.errors.required)
    ) {
      const fechadaLower = fechada.toLocaleLowerCase();

      const errorClassByType =
        tipoAlternativa.indexOf(fechada) >= 0 ||
        tipoAlternativa.indexOf(selecione) >= 0
          ? fechadaLower
          : tipoAlternativa.indexOf(aberta) >= 0
          ? aberta.toLowerCase()
          : fechadaLower;

      className += `${ERROR}-${errorClassByType}`;
    }

    return className;
  }

  //  Função que altera o indice da alternativa cujo seletor de opções de tipo de alternativas tem seu menu visível
  alterarIndiceDaAlternativaComMenuTipoAlternativaVisivel(indice: number) {
    if (indice === this.indiceAlternativaComMenuVisivel) {
      this.indiceAlternativaComMenuVisivel = -1;
    } else {
      this.indiceAlternativaComMenuVisivel = indice;
    }
  }

  //  Função que verifica se o menu do seletor de opções de tipo de alternativas está visível num índice específico
  isMenuTipoAlternativaVisivel(indice: number): boolean {
    return this.indiceAlternativaComMenuVisivel === indice;
  }

  /**
   * Dependendo do tipo de pergunta, precisa ser inicializado alguma propriedade
   * Ex: Perguntas do tipo MÚLTIPLA e ÚNICA precisam ter um array de alternativas
   * e o formato das alternativas é: { descricao: string, tipoAlternativa: TipoAlternativa, ondem: number }
   * enquanto as perguntas do tipo GRADE tem um array de alternativas com o formato semelhante e uma propriedade
   * titulosGrade, que é um array de titulos, no seguinte formato: { descricao: string, ordem: number } que precisam
   * ser ambos inicializados com pelo menos um registro com a descrição vazia.
   *
   * a função initFormControlValues(tipoPergunta, tipoPerguntaAnterior) recebe o tipo da pergunta e o tipo
   * da pergunta anterior e inicializa os formgroups de acordo com a necessidade
   */
  initFormControlValues(
    tipoPergunta: TipoPergunta,
    tipoPerguntaAnterior: TipoPergunta
  ) {
    const multiplaUnicaTypes = [TipoPergunta.UNICA, TipoPergunta.MULTIPLA];
    const gradeTypes = [TipoPergunta.GRADE_MULTIPLA, TipoPergunta.GRADE_UNICA];

    const shouldResetAlternativaLabels = !(
      multiplaUnicaTypes.includes(tipoPerguntaAnterior) &&
      multiplaUnicaTypes.includes(tipoPergunta)
    );

    // Necessário para atualizar o tipo de alternativa quando mudamos de uma pergunta do tipo grade para
    // uma pergunta do tipo resposta multipla ou unica.
    const shouldResetTipoAlternativa =
      gradeTypes.includes(tipoPerguntaAnterior) &&
      !gradeTypes.includes(tipoPergunta);

    //  Antes de mais nada, é necessário remover todos os dados que possivelmente foram inicializados em outras seleções
    let previousAlternativaData = null;
    const alternativasFormulario = this.formGroup.get("alternativas").value;

    if (!shouldResetAlternativaLabels) {
      previousAlternativaData = alternativasFormulario.map((alternativa) => ({
        ...alternativa,
      }));
    }

    this.formGroup.get("alternativas").setValue([]);
    this.formGroup.get("titulosGrade").setValue([]);

    if (
      [
        TipoPergunta.DATA,
        TipoPergunta.FOTO,
        TipoPergunta.HORARIO,
        TipoPergunta.VIDEO,
      ].includes(tipoPergunta)
    )
      return;

    if (
      [
        TipoPergunta.UNICA,
        TipoPergunta.MULTIPLA,
        TipoPergunta.GRADE_UNICA,
        TipoPergunta.GRADE_MULTIPLA,
      ].includes(tipoPergunta)
    ) {
      const isGradeQuestionType = [
        TipoPergunta.GRADE_MULTIPLA,
        TipoPergunta.GRADE_UNICA,
      ].includes(tipoPergunta);

      // Caso a pergunta seja do tipo grade, atualiza também as descrições das linhas.
      if (isGradeQuestionType) {
        const titulosGrade = this.formGroup.get("titulosGrade").value;
        this.updateAlternativasFormArray(titulosGrade, false, true);
      }

      //  se for uma pergunta no modo edição, ele precisa carregar os dados das alternativas pré cadastradas.
      const alternativasModelExists =
        this.model &&
        this.model.pergunta &&
        this.model.pergunta.alternativas &&
        this.model.pergunta.alternativas.length > 0;
      const alternativasFormExists =
        Array.isArray(alternativasFormulario) &&
        alternativasFormulario.length > 0;

      if (alternativasModelExists || alternativasFormExists) {
        const listaAlternativasAtuais = alternativasModelExists
          ? this.model.pergunta.alternativas
          : alternativasFormulario;
        let alternativasCopyObject = [
          ...(previousAlternativaData ||
            listaAlternativasAtuais.map((alternativa) => ({
              ...alternativa,
              tipoAlternativa: shouldResetTipoAlternativa
                ? "Selecione"
                : alternativa.tipoAlternativa,
            }))),
        ];

        if (isGradeQuestionType) {
          alternativasCopyObject = alternativasCopyObject.map(
            (alternative) => ({
              ...alternative,
              tipoAlternativa: TipoAlternativa.GRADE,
            })
          );
        }

        this.formGroup.get("alternativas").setValue(alternativasCopyObject);

        this.updateAlternativasFormArray(alternativasCopyObject);
      } else {
        this.formGroup.get("alternativas").setValue([
          {
            ordem: 1,
            descricao: "",
            //  caso a pergunta seja do tipo GRADE, as alternativas devem ser do tipo GRADE
            //  se forem perguntas do tipo UNICA ou MULTIPLA, a alternativa deve ser inicializada com 'FECHADA'
            tipoAlternativa: tipoPergunta.includes("GRADE")
              ? TipoAlternativa.GRADE
              : "Selecione",
          },
        ]);

        // Reseta as validações armazenadas no form array de alternativas.
        const formArrayReference =
          (!isGradeQuestionType && this.alternativasFormArray) ||
          this.gradeLinhasFormArray;
        this.resetAlternativasFormArray(formArrayReference);
      }
    }

    if (
      [TipoPergunta.MULTIPLA, TipoPergunta.GRADE_MULTIPLA].includes(
        tipoPergunta
      )
    ) {
      //  Caso seja uma pergunta que possa marcar mais de uma alternativa (multipla ou grade multipla)
      //  e esteja no modo de edição, se faz necessário carregar os dados da pergunta previamente cadastrada.
      if (this.model && this.model.pergunta && this.model.pergunta.qtdMinima)
        this.formGroup.get("qtdMinima").setValue(this.model.pergunta.qtdMinima);
      if (this.model && this.model.pergunta && this.model.pergunta.qtdMaxima)
        this.formGroup.get("qtdMaxima").setValue(this.model.pergunta.qtdMaxima);
    }

    if (
      [TipoPergunta.GRADE_UNICA, TipoPergunta.GRADE_MULTIPLA].includes(
        tipoPergunta
      )
    ) {
      //  Se for uma pergunta no modo edição, precisa e for do tipo grade unica ou multipla, precisa carregar
      //  os dados da propriedade titulosGrade previamente cadastrados. Do contrário, inicializar esses dados da maneira correta.
      if (
        this.model &&
        this.model.pergunta &&
        this.model.pergunta.titulosGrade &&
        this.model.pergunta.titulosGrade.length > 0
      ) {
        this.formGroup
          .get("titulosGrade")
          .setValue(this.model.pergunta.titulosGrade);
      } else {
        this.formGroup.get("titulosGrade").setValue([
          {
            //  Deve ser inicializado com o número 1
            ordem: 1,
            descricao: "",
          },
        ]);
      }
    }

    if (
      [
        TipoPergunta.UNICA,
        TipoPergunta.MULTIPLA,
        TipoPergunta.ESCALA_NUMERICA,
      ].includes(tipoPergunta)
    ) {
      const alternativas = this.formGroup.get("alternativas");
      alternativas.setValue(
        alternativas.value.map((a) => ({
          ...a,
          tipoAlternativa: TipoAlternativa.FECHADA,
        }))
      );
    }
  }

  //  Função que retorna a informação se o usuario deseja criar uma pergunta do tipo que recebe como parâmetro
  perguntaDoMesmoTipoQue(tipo: TipoPergunta) {
    return this.formGroup.get("tipo").value === tipo;
  }

  //  Função que retorna a informação se a pergunta é do tipo pergunta unica ou pergunta multipla
  ehPerguntaMultiplaOuPerguntaUnica(): boolean {
    return [TipoPergunta.UNICA, TipoPergunta.MULTIPLA].includes(
      this.formGroup.get("tipo").value
    );
  }

  //  Função que retorna se o cadastro de pergunta é de um tipo de pergunta simples
  questionIsSimple(): boolean {
    return [
      TipoPergunta.VIDEO,
      TipoPergunta.FOTO,
      TipoPergunta.DATA,
      TipoPergunta.HORARIO,
    ].includes(this.formGroup.get("tipo").value);
  }

  // Verifica se a pergunta é obrigatória
  isQuestionRequired(): boolean {
    return this.formGroup.get("obrigatoria").value;
  }

  // verifica se a quantidade minima de alternativas marcadas da pergunta do tipo grade grade multipla ou unica é válida
  verifyIfMinAlternativesAreOk(): boolean {
    return (
      (this.perguntaDoMesmoTipoQue(TipoPergunta.MULTIPLA) ||
        this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_MULTIPLA)) &&
      this.formGroup.get("qtdMinima").value !== null &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("qtdMaxima").value &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("alternativas").value.length &&
      this.formGroup.get("qtdMinima").value >= 1
    );
  }

  // verifica se a quantidade maxima de alternativas marcadas da pergunta do tipo grade grade multipla ou unica é válida
  verifyIfMaxAlternativesAreOk(): boolean {
    return (
      (this.perguntaDoMesmoTipoQue(TipoPergunta.MULTIPLA) ||
        this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_MULTIPLA)) &&
      this.formGroup.get("qtdMaxima").value !== null &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("qtdMaxima").value &&
      this.formGroup.get("qtdMaxima").value <=
        this.formGroup.get("alternativas").value.length &&
      this.formGroup.get("qtdMaxima").value >= 1
    );
  }

  // verifica se quando for grade multipla o valor mínimo só pode ser igual ou menor que o máximo
  verifyMultipleQuestionValuesIsOk(): boolean {
    return (
      this.perguntaDoMesmoTipoQue(TipoPergunta.MULTIPLA) &&
      this.formGroup.get("qtdMinima").value !== null &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("qtdMaxima").value &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("alternativas").value.length &&
      this.formGroup.get("qtdMinima").value >= 1 &&
      this.formGroup.get("qtdMaxima").value >= 1 &&
      this.formGroup.get("qtdMaxima").value !== null &&
      this.formGroup.get("qtdMaxima").value <=
        this.formGroup.get("alternativas").value.length
    );
  }

  // verifica se quando for grade multipla o valor mínimo só pode ser igual ou menor que o máximo
  verifyIfAnswerQuantityIsOk(): boolean {
    return (
      (this.perguntaDoMesmoTipoQue(TipoPergunta.MULTIPLA) ||
        this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_MULTIPLA)) &&
      this.formGroup.get("qtdMinima").value !== null &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("qtdMaxima").value &&
      this.formGroup.get("qtdMinima").value <=
        this.formGroup.get("alternativas").value.length &&
      this.formGroup.get("qtdMinima").value >= 1 &&
      this.formGroup.get("qtdMaxima").value !== null &&
      this.formGroup.get("qtdMaxima").value <=
        this.formGroup.get("alternativas").value.length
    );
  }

  //  Função que retorna se o formulário é válido ou não
  formIsValid(): boolean {
    return (
      this.alternativasFormArray.status === "VALID" &&
      this.gradeLinhasFormArray.status === "VALID" &&
      this.formGroup.valid &&
      (this.questionIsSimple() ||
        this.perguntaDoMesmoTipoQue(TipoPergunta.ESCALA_NUMERICA) ||
        (this.perguntaDoMesmoTipoQue(TipoPergunta.UNICA) &&
          this.formGroup.get("alternativas").value.length > 0 &&
          this.formGroup
            .get("alternativas")
            .value.every(
              (alternativa: {
                descricao: string;
                tipoAlternativa: TipoAlternativa;
              }) =>
                alternativa.descricao !== "" &&
                (alternativa.tipoAlternativa === "ABERTA_TEXTO" ||
                  alternativa.tipoAlternativa === "ABERTA_NUMERO" ||
                  alternativa.tipoAlternativa === "FECHADA")
            )) ||
        (this.perguntaDoMesmoTipoQue(TipoPergunta.MULTIPLA) &&
          this.formGroup
            .get("alternativas")
            .value.every(
              (alternativa: {
                descricao: string;
                tipoAlternativa: TipoAlternativa;
              }) =>
                alternativa.descricao !== "" &&
                (alternativa.tipoAlternativa === "ABERTA_TEXTO" ||
                  alternativa.tipoAlternativa === "ABERTA_NUMERO" ||
                  alternativa.tipoAlternativa === "FECHADA")
            ) &&
          this.formGroup.get("alternativas").value.length > 0 &&
          this.formGroup.get("qtdMinima").value !== null &&
          this.formGroup.get("qtdMinima").value >= 1 &&
          this.formGroup.get("qtdMinima").value <=
            this.formGroup.get("alternativas").value.length &&
          this.formGroup.get("qtdMaxima").value !== null &&
          this.formGroup.get("qtdMaxima").value <=
            this.formGroup.get("alternativas").value.length &&
          this.formGroup.get("qtdMaxima").value >=
            this.formGroup.get("qtdMinima").value) ||
        (this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_UNICA) &&
          this.formGroup.get("alternativas").value.length > 0 &&
          this.formGroup
            .get("alternativas")
            .value.every(
              (alternativa: { descricao: string }) =>
                alternativa.descricao !== ""
            ) &&
          this.formGroup.get("titulosGrade").value.length > 0 &&
          this.formGroup
            .get("titulosGrade")
            .value.every(
              (titulo: { descricao: string }) => titulo.descricao !== ""
            )) ||
        (this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_MULTIPLA) &&
          this.formGroup.get("alternativas").value.length > 0 &&
          this.formGroup
            .get("alternativas")
            .value.every(
              (alternativa: { descricao: string }) =>
                alternativa.descricao !== ""
            ) &&
          this.formGroup.get("titulosGrade").value.length > 0 &&
          this.formGroup
            .get("titulosGrade")
            .value.every(
              (titulo: { descricao: string }) => titulo.descricao !== ""
            ) &&
          this.formGroup.get("qtdMinima").value !== null &&
          this.formGroup.get("qtdMaxima").value !== null &&
          this.verifyIfAnswerQuantityIsOk()))
    );
  }

  /**
   * @author murilio
   * @description a função pega o valor final e monta todas as
   * alternativas para a pergunta do tipo escala numérica
   * @returns
   *  [{
   *    "hash": string,
   *    "tipoAlternativa": string,
   *    "descricao": string,
   *    "ordem": number
   *  }]
   */
  handleMontarAlternativasEscalaNumerica() {
    let es = [];

    const [startInterval, endInterval] = [
      this.formGroup.value.faixaPergunta.intervaloInicio,
      this.formGroup.value.faixaPergunta.intervaloFim,
    ];

    let counter = 0;

    for (let i = startInterval; i <= endInterval; i++) {
      es.push({
        hash: uuid(),
        tipoAlternativa: "ESCALA_NUMERICA",
        descricao: `${i}`,
        ordem: counter,
      });

      counter++;
    }

    return es;
  }

  montarObjetoDePergunta(): SectionElemModel {
    /**
     * Reseta configurações de lógica de pulos dos objetos de alternativas para os tipos de perguntas
     * que ainda não tem configurações de pulos definidas em sua regra de negócio.
     */
    const handleLogicFromAlternatives = (alternatives) => {
      // Inferindo que se o primeiro elemento é um objeto, os outros também são
      const isArrayOfRealObjects =
        typeof alternatives[0] === "object" && alternatives !== null;
      if (Array.isArray(alternatives) && isArrayOfRealObjects) {
        const defaultLogicBehavior = "CONTINUAR_ENTREVISTA";
        // Aqui simplesmente mapeamos o objeto da alternativa para resetar os atributos de pulo.
        return alternatives.map((alternative) => ({
          ...alternative,
          ...(alternative.codigoMarcacao ? { codigoMarcacao: null } : {}),
          ...(alternative.marcacao ? { marcacao: null } : {}),
          ...(alternative.comportamento
            ? { comportamento: defaultLogicBehavior }
            : {}),
        }));
      }

      return alternatives;
    };

    // valor default da pesquisa
    const resultDefault = {
      ...(!!this.model && !!this.model.pergunta
        ? this.model.pergunta
        : {
            id: null,
            hash: uuid(),
            nome: this.formControls.nome.value,
            descricao: this.formControls.orientacao.value,
            tipo: this.formControls.tipo.value,
            obrigatoria: this.formControls.obrigatoria.value,
            codigoMarcacao: uuid(),
            discoInducao: false,
            possuiCota: false,
            possuiPulo: false,
            possuiDiferenteDe: false,
            possuiPuloPergunta: false,
            faixaPergunta: null,
            qtdMaxima: 1,
            qtdMinima: 1,
            titulosGrade: [],
            marcacaoPergunta: null,
            ordemCota: null,
            alternativas: [],
          }),
      nome: this.formControls.nome.value,
      descricao: this.formControls.orientacao.value,
      tipo: this.formControls.tipo.value,
      obrigatoria: this.formControls.obrigatoria.value,
    } as SectionElemQuestionModel;
    // criando uma pergunta por tipo
    const result = this.questionIsSimple()
      ? {
          pergunta: {
            ...resultDefault,
            qtdMaxima: 1,
            qtdMinima: 1,
            titulosGrade: [],
            alternativas: [],
            faixaPergunta: null,
            codigoMarcacao: uuid(),
          } as SectionElemQuestionModel,
        }
      : this.perguntaDoMesmoTipoQue(TipoPergunta.ESCALA_NUMERICA)
      ? {
          pergunta: {
            ...resultDefault,
            qtdMaxima: 1,
            qtdMinima: 1,
            faixaPergunta: this.formControls.faixaPergunta.value,
            codigoMarcacao: uuid(),
            marcacaoPergunta: null,
            titulosGrade: [],
            alternativas: handleLogicFromAlternatives(
              this.handleMontarAlternativasEscalaNumerica()
            ),
          } as SectionElemQuestionModel,
        }
      : this.perguntaDoMesmoTipoQue(TipoPergunta.UNICA)
      ? {
          pergunta: {
            ...resultDefault,
            qtdMaxima: 1,
            qtdMinima: 1,
            faixaPergunta: null,
            titulosGrade: [],
            alternativas: handleLogicFromAlternatives(
              this.formControls.alternativas.value
            ),
            codigoMarcacao: uuid(),
            marcacaoPergunta: null,
          } as SectionElemQuestionModel,
        }
      : this.perguntaDoMesmoTipoQue(TipoPergunta.MULTIPLA)
      ? {
          pergunta: {
            ...resultDefault,
            faixaPergunta: null,
            titulosGrade: [],
            alternativas: handleLogicFromAlternatives(
              this.formControls.alternativas.value
            ),
            qtdMaxima: this.formControls.qtdMaxima.value,
            qtdMinima: this.formControls.qtdMinima.value,
            codigoMarcacao: uuid(),
            marcacaoPergunta: null,
          } as SectionElemQuestionModel,
        }
      : this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_UNICA)
      ? {
          pergunta: {
            ...resultDefault,
            qtdMaxima: 1,
            qtdMinima: 1,
            faixaPergunta: null,
            titulosGrade: this.formControls.titulosGrade.value,
            alternativas: handleLogicFromAlternatives(
              this.formControls.alternativas.value
            ),
            codigoMarcacao: uuid(),
            marcacaoPergunta: null,
          } as SectionElemQuestionModel,
        }
      : this.perguntaDoMesmoTipoQue(TipoPergunta.GRADE_MULTIPLA)
      ? {
          pergunta: {
            ...resultDefault,
            faixaPergunta: null,
            titulosGrade: this.formControls.titulosGrade.value,
            alternativas: handleLogicFromAlternatives(
              this.formControls.alternativas.value
            ),
            qtdMaxima: this.formControls.qtdMaxima.value,
            qtdMinima: this.formControls.qtdMinima.value,
            codigoMarcacao: uuid(),
            marcacaoPergunta: null,
          } as SectionElemQuestionModel,
        }
      : {
          pergunta: {
            ...resultDefault,
            codigoMarcacao: uuid(),
          } as SectionElemQuestionModel,
        };
    //
    const finalResult = {
      ...result,
      hash: uuid(),
      ordem: this.model.ordem,
      id: !!this.model ? this.model.id : null,
    };
    // return
    return finalResult;
  }

  // Função para retornar a quanidade máxima de colunas
  getGradeInputMaxNumberOfColumns(): number {
    return this.formGroup.get("alternativas").value.length;
  }

  // função para retornar o atual valor da quantidade minima de grade input
  getGradeInputMultiplaQtdMinima(): number {
    return this.formGroup.get("qtdMinima").value;
  }

  // função para retornar o atual valor da quantidade maxima do grade input
  getGradeInputMultiplaQtdMaxima(): number {
    return this.formGroup.get("qtdMaxima").value;
  }

  // função para retornar o atual valor da quantidade minima de grade input
  getInputMultiplaQtdMinima(): number {
    return this.formGroup.get("qtdMinima").value;
  }

  // função para retornar o atual valor da quantidade maxima do grade input
  getInputMultiplaQtdMaxima(): number {
    return this.formGroup.get("qtdMaxima").value;
  }

  //  Função que adiciona nova linha nas alternativas da pergunta do tipo grade
  handleAddGridRow(): void {
    const result = [
      ...this.formGroup.get("titulosGrade").value,
      {
        descricao: "",
        ordem: this.formGroup.get("titulosGrade").value
          ? this.formGroup.get("titulosGrade").value.length + 1
          : 1,
      },
    ];

    this.formGroup.get("titulosGrade").setValue(result);
    this.updateAlternativasFormArray(result, false, true);
  }

  //  Função que adiciona nova coluna nas alternativas da pergunta do tipo grade
  handleAddGridColumn(): void {
    const result = [
      ...this.formGroup.get("alternativas").value,
      {
        descricao: "",
        ordem: this.formGroup.get("alternativas").value.length
          ? this.formGroup.get("alternativas").value.length + 1
          : 1,
        tipoAlternativa: "GRADE",
      },
    ];

    this.formGroup.get("alternativas").setValue(result);

    this.updateAlternativasFormArray(result, false);
  }

  //  Função que deleta linha das alternativas da pergunta do tipo grade
  handleDeleteGridRow(rowIndex: number): void {
    const result = [
      ...this.formGroup
        .get("titulosGrade")
        .value.filter(
          (item: { descricao: string; ordem: number }, indexOfItem: number) =>
            item && indexOfItem !== rowIndex
        ),
    ];

    this.formGroup.get("titulosGrade").setValue(result);

    this.updateAlternativasFormArray(result, false, true);
  }

  //  Função que deleta coluna das alternativas da pergunta do tipo grade
  handleDeleteGridColumn(columnIndex: number): void {
    const result = [
      ...this.formGroup
        .get("alternativas")
        .value.filter(
          (item: { descricao: string; ordem: number }, indexOfItem: number) =>
            item && indexOfItem !== columnIndex
        ),
    ];

    this.formGroup.get("alternativas").setValue(result);

    this.updateAlternativasFormArray(result, false);
  }

  //  Função que altera valor da descrição de um item da coluna de uma alternativa de uma pergunta do tipo grade
  handleEditGridColumnItemDescription(payload: {
    newDescription: string;
    columnIndex: number;
  }): void {
    const { newDescription, columnIndex } = payload;
    const timerStamp = `coluna-${columnIndex}`;
    if (this.inputTimerStamp === timerStamp) clearTimeout(this.timer);

    this.inputTimerStamp = timerStamp;

    this.timer = setTimeout(() => {
      const tempColumnsArr = [...this.formGroup.get("alternativas").value];

      tempColumnsArr[columnIndex] = {
        ...tempColumnsArr[columnIndex],
        descricao: newDescription,
      };

      this.formGroup.get("alternativas").setValue(tempColumnsArr, this.options);
      this.gradeColumns = tempColumnsArr;
    }, 700);

    const newArrayOfLabels = [...this.alternativasFormArray.value];
    newArrayOfLabels[columnIndex] = newDescription;

    this.updateAlternativasFormArray(newArrayOfLabels, true);
  }

  //  Função que altera valor da descrição de um item da linha de uma alternativa de uma pergunta do tipo grade
  handleEditGridRowItemDescription(payload: {
    newDescription: string;
    columnIndex: number;
  }): void {
    const { newDescription, columnIndex } = payload;

    const timerStamp = `linha-${columnIndex}`;
    if (this.inputTimerStamp === timerStamp) clearTimeout(this.timer);

    this.inputTimerStamp = timerStamp;

    this.timer = setTimeout(() => {
      const tempRowsArr = [...this.formGroup.get("titulosGrade").value];

      tempRowsArr[columnIndex] = {
        ...tempRowsArr[columnIndex],
        descricao: newDescription,
      };

      this.formGroup.get("titulosGrade").setValue(tempRowsArr, this.options);
      this.gradeRows = tempRowsArr;
    }, 700);

    const newArrayOfLabels = [...this.gradeLinhasFormArray.value];
    newArrayOfLabels[columnIndex] = newDescription;

    this.updateAlternativasFormArray(newArrayOfLabels, true, true);
  }

  handleQuantidadeMinimaChange(event: any) {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.formGroup.get("qtdMinima").setValue(+event.target.value);
    }, 700);
  }

  handleQuantidadeMaximaChange(event: any) {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.formGroup.get("qtdMaxima").setValue(+event.target.value);
    }, 700);
  }

  //  change handler em formato de debounce uma vez que a cada keypress seria esperado uma atualizacao de estado que tira o foco do input por ser um estado do componente
  handlePerguntaDescricaoChange(event: any, indexOfAlternativa: number) {
    //  limpa o timout
    // clearTimeout(this.timer);

    const alternativas = [...this.formGroup.get("alternativas").value];

    alternativas[indexOfAlternativa] = {
      ...alternativas[indexOfAlternativa],
      descricao: event.target.value,
    };

    this.formGroup.get("alternativas").setValue(alternativas, this.options);

    // Se tipoAlternativa === ABERTA_TEXTO envia o objeto completo de alternativa para aplicar as devidas validações
    if(alternativas[indexOfAlternativa].tipoAlternativa === TipoAlternativa.ABERTA_TEXTO) {
      const newAlternativeArray = [...alternativas];
      newAlternativeArray[indexOfAlternativa].descricao = event.target.value;
      this.updateAlternativasFormArray(newAlternativeArray);
    } else {
      const newArrayOfLabels = [...this.alternativasFormArray.value];
      newArrayOfLabels[indexOfAlternativa] = event.target.value;
      this.updateAlternativasFormArray(newArrayOfLabels, true);
    }
  }

  //  change handler do tipo da pergunta
  handleAlternativaTipoChange(
    tipo: TipoAlternativa,
    indexOfAlternativa: number
  ) {
    const alternativas = [...this.formGroup.get("alternativas").value];
    alternativas[indexOfAlternativa] = {
      ...alternativas[indexOfAlternativa],
      tipoAlternativa: tipo,
    };
    
    this.formGroup.get("alternativas").setValue(alternativas, this.options);
    this.formGroup.updateValueAndValidity();
    this.indiceAlternativaComMenuVisivel = -1;

    this.updateAlternativasFormArray(this.formGroup.get("alternativas").value);

    // caso tenha alternativas chama a função
    // para aplicar a altura do input de acordo com a quantidade de caracteres
    setTimeout(() => {
      // usando aqui para alternativas vindas da API
      this.model.pergunta.alternativas.map((alt, index) => {
        let altI = { target: { value: alt.descricao } };
        this.handleInputChange(altI, `alternativa${index}`);
      });

      // usando aqui para alternativas vindas da memória
      alternativas.map((alt, index) => {
        let altI = { target: { value: alt.descricao } };
        this.handleInputChange(altI, `alternativa${index}`);
      });
    }, 0);
  }

  // Função que seta opção selecionada no menu de tipo de perguntas para o formdata
  handleSelecionarTipoPergunta(opcao: TipoPergunta) {
    if (this.formControls.tipo.value === opcao) return;
    const opcaoAnterior = this.formGroup.get("tipo").value;

    this.formGroup.get("tipo").setValue(opcao);
    this.initFormControlValues(opcao, opcaoAnterior);
  }

  // Função que controla estado do booleano que informa se o usuario deseja criar uma pergunta obrigatoria
  handleRequiredCheckboxClick() {
    if (this.formGroup.get("obrigatoria").value) {
      this.formGroup.get("qtdMinima").setValue(1);
      this.formGroup.get("qtdMaxima").setValue(1);
    }
    this.formGroup
      .get("obrigatoria")
      .setValue(!this.formGroup.value.obrigatoria);
  }

  //  Função que captura o evento de mudança do valor do seletor do inicio do intervalo da seleção numérica
  handleSelectInicioOption(event: any) {
    this.formGroup.get("faixaPergunta").setValue({
      ...this.formGroup.get("faixaPergunta").value,
      intervaloInicio: event.target.value,
    });
  }

  //  Função que captura o evento de mudança do valor do seletor do fim do intervalo da seleção numérica
  handleSelectFimOption(event: any) {
    this.formGroup.get("faixaPergunta").setValue({
      ...this.formGroup.get("faixaPergunta").value,
      intervaloFim: event.target.value,
    });
  }

  // função para salvar nova pergunta
  handleSave() {
    //  criar modelo e salvar
    const result = this.montarObjetoDePergunta();
    this.onSave.emit({
      sectionId: this.sectionId,
      sectionElem: result,
    });

    setTimeout(() => {
      // scroll to new question delayed to wait until component has the element to scroll to
      // const perguntaEmFoco = document.getElementById(`pergunta-${!!this.secao.elementosSecao ? this.secao.elementosSecao.length + 1 : 1}`);
      const perguntaEmFoco = document.getElementById(
        `pergunta-${this.sequence}`
      );
      if (perguntaEmFoco)
        perguntaEmFoco.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
    }, 300);
  }

  // Função do botão cancelar
  handleCancelClick() {
    this.onCancel.emit();
  }

  /**
   *
   * @param event texto digitado pelo usuário
   * @param inputID id do input
   */
  handleInputChange(event: any, inputID: string) {
    let element = document.querySelector(`#${inputID}`) as HTMLElement;
    element.style.height = `${
      event.length < 80
        ? 48
        : element.scrollHeight > 48
        ? element.scrollHeight
        : 48
    }px`;
  }

  /**
   * Define o maxLength de uma alternativa
   * @param hasTypeAttr: boolean que indica se alternative possui o atributo de tipoAlternativa
   * @param alternative: array de objetos que vai de acordo com o contexto.
   */
  setAltenativeMaxLength(hasTypeAttr: boolean, alternative) {
    if(!hasTypeAttr && alternative?.tipoAlternativa === TipoAlternativa.ABERTA_TEXTO) {
      this.alternativaMaxLength = ALTERNATIVA_ABERTA_MAX_LENGTH;
    } else {
      this.alternativaMaxLength = PERGUNTA_ALTERNATIVA_MAX_LENGTH;
    }
  }

  /**
   * @returns mensagem de erro de acordo com tipo de alternativa
   */
  maxLengthErrorMessage(tipoAlternativa: string): string {
    if(tipoAlternativa === TipoAlternativa.ABERTA_TEXTO) {
      return `Máximo de ${ALTERNATIVA_ABERTA_MAX_LENGTH} caracteres`;
    } else {
      return `Máximo de ${PERGUNTA_ALTERNATIVA_MAX_LENGTH} caracteres`;
    }
  }
}
