import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ModalData } from "./modal";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  // estado interno com os dados default para instanciar apenas uma vez
  private defaultModalData: ModalData = new ModalData();
  // estado que controla a visibilidade do modal
  private show: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // subject para comunicação do estado de loading entre o service e o component.
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // estado para o valor do checkbox
  private checkbox: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // estado para o valor do input
  private input: BehaviorSubject<string> = new BehaviorSubject(null);
  // estado para os erros do input
  private inputErrors: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
  // objeto que guarda todos os valores do modal
  private inputModalData: BehaviorSubject<ModalData> = new BehaviorSubject(
    this.defaultModalData
  );

  // função que controla a exibição do modal
  public handleCloseModal() {
    this.show.next(false);
  }

  // função que repassa os dados para o componente de modal
  public showModal(data: ModalData) {
    // antes de abrir uma nova modal, é feito um reset nos valores atuais
    this.reset();

    /**
     * abre o modal
     * com um delay de 200 milissegundos para que seja possível uma animação na tela */
    setTimeout(() => this.show.next(true), 250);
    // seta os valores para o modal
    this.inputModalData.next({ ...this.defaultModalData, ...data });
  }

  // Retorna um observer do estado de loading.
  public getLoading() {
    return this.loading.asObservable();
  }

  // Seta e notifica um novo estado de loading.
  public setLoading(state: boolean) {
    this.loading.next(state);
  }

  // lida com o checkbox do input
  public handleCheckbox(value: boolean) {
    this.checkbox.next(value);
  }

  // lida com o value do input
  public handleInput(value: string) {
    this.input.next(value);
  }

  // lida com os erros do input
  public handleInputErrors(values: Array<string>) {
    this.inputErrors.next(values);
  }

  // limpa os erros manualmente
  public cleanInputErrors() {
    this.inputErrors.next([]);
  }

  /**
   * função de reset
   * fecha o modal
   * e limpa os valores */
  private reset() {
    this.show.next(false);
    this.loading.next(false);
    this.checkbox.next(false);
    this.input.next(null);
    this.inputModalData.next(this.defaultModalData);
  }

  // função que retorna o valor do checkbox
  public getCheckbox(): Observable<boolean> {
    return this.checkbox.asObservable();
  }

  // função que retorna o valor do input
  public getInput(): Observable<string> {
    return this.input.asObservable();
  }

  // função que retorna os erros de validação relacionados ao input
  public getInputErrors(): Observable<Array<string>> {
    return this.inputErrors.asObservable();
  }

  // função que observa se o modal está aberto ou não
  public getShowModal(): Observable<boolean> {
    return this.show.asObservable();
  }

  // função que retorna o estado de visibilidade da modal diretamente.
  public get isOpened() {
    return this.show.getValue();
  }

  // função que retorna os valores para o modal
  public getDataModal(): Observable<ModalData> {
    return this.inputModalData.asObservable();
  }
}
