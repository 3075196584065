import { Component } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { TipoConteudo } from '../../../../questionario/componentes/secao/conteudo/tipoConteudo';
import { AbstractPerguntaMarcacaoComponent } from '../../../abstractPerguntaMarcacaoComponent';

@Component({
  selector: 'app-selecao-multipla-marcacao',
  templateUrl: './selecao-multipla-marcacao.component.html',
  styleUrls: ['./selecao-multipla-marcacao.component.scss'],
})
export class SelecaoMultiplaMarcacaoComponent extends AbstractPerguntaMarcacaoComponent {

  /**
   * @override
   */
  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_MARCACAO;

  /**
   * @override
   */
  tipoPergunta: TipoPergunta = TipoPergunta.MULTIPLA;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
