<div class="table-container" *ngIf="possuiEntrevistas()">

  <div class="tableActions">

    <div class="actions">
      <div class="icon-action" [class.icon-disabled]="!(trBodiesSelecionados.length > 0)" (click)="showLocalidades()">
        <span class="fa-sharp fa-regular fa-location-dot" title="Visualizar localidade(s)"></span>
      </div>
      <div class="icon-action" [class.icon-disabled]="!(trBodiesSelecionados.length > 0)"
        (click)="enviarEventoDownload()">
        <span class="icon-baixar" title="Baixar selecionado(s)"></span>
      </div>
      <div class="icon-action" [class.icon-disabled]="!(trBodiesSelecionados.length > 0)"
        (click)="enviarEventoExclusaoItensSelecionados()">
        <span class="icon-lixeira" title="Excluir selecionado(s)"> </span>
      </div>
      <div class="icon-action" (click)="getAllCollectionsForMap()">
        <i class="fa-regular fa-map"></i>
      </div>
    </div>

    <div class="sg-input-group">
      <div class="campo-pesquisa">
        <label for="">Filtrar por palavras-chave:</label>
        <input [formControl]="buscaPalavrasChavesControl" maxlength="50" placeholder="termo1 termo2 etc" type="text">
      </div>
    </div>
  </div>

  <div class="tabelaContent">
    <table class="sg-table">
      <thead>
        <th class="table-checkbox-wrapper" scope="col">
          <label class="clickable" title="Marcar itens da página">
            <input type="checkbox" class="sg-check" (change)="$event.preventDefault(); acionarSelecionarTodos()"
              [checked]="selecionarTodos">
          </label>
        </th>
        <th class="" *ngFor="let th of tableHeaders" (click)="sort(th)" scope="col">
          {{th.friendlyName}}
          <!-- <div class="" *ngIf="th.sorts">
            <div *ngIf="th.sortingOptions">
              <i class="fa fa-sort-desc" *ngIf="th.sortingOptions.isASC()"></i>
              <i class="fa fa-sort-asc" *ngIf="!th.sortingOptions.isASC()"></i>
            </div>
            <div *ngIf="!th.sortingOptions">
              <i class="fa fa-sort-asc"></i>
            </div>
          </div> -->
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let trBody of trBodies; let i = index" >
          <td class="table-checkbox-wrapper" title="Selecionar">
            <input title="selecionar item" class="sg-check" type="checkbox" [checked]="trBody.selected"
              (click)="selectTrBody(trBody)">
          </td>
          <td title="Cod. Entrevista">{{trBody.id}}</td>
          <td title="Operador">{{trBody.operador}}</td>
          <td title="Localidade">{{trBody.localidade}}</td>
          <td title="Duração">{{trBody.duracao}}</td>
          <td title="Data"> {{trBody.dataColeta}}</td>
          <td title="Inicio da coleta">{{trBody.inicioColeta}}</td>
          <td title="Fim da coleta">{{trBody.fimColeta}}</td>
          <td title="Visualizar">
            <a (click)="onVisualizarEntrevista(trBody.id, i+1)">
              <i class="fa fa-eye"></i>
            </a>
          </td>
          <td title="Audição"
            [ngClass]="{ 'status-habilitado' : trBody.auditado, 'status-desabilitado' : !trBody.auditado }">
            <i class="fa fa-circle"
              [ngClass]="{ 'status-habilitado' : trBody.auditado, 'status-desabilitado' : !trBody.auditado }"></i>
            {{ trBody.auditado ? 'Auditada' : 'Não Auditada' }}
          </td>
          <td [title]="trBody.aprovado ? 'Aprovada' : 'Reprovada'">
            <mat-slide-toggle *ngIf="trBody.aprovado" (change)="rejeitarEntrevista(trBody.id)" [checked]="true">
            </mat-slide-toggle>
            <mat-slide-toggle *ngIf="!trBody.aprovado" (change)="aprovarEntrevista(trBody.id)" [checked]="false">
            </mat-slide-toggle>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="paginacao-wrapper sg-input-group">
    <div class="campo-inline">
      <div [formGroup]="pesquisaFormGroup" *ngIf="pesquisaFormGroup">
        <mat-slide-toggle formControlName="auditada" (change)="alterarStatusPesquisa($event)"></mat-slide-toggle>
        <label>Pesquisa auditada</label>
      </div>
    </div>
    <div>
      <app-paginacao [indicadorReinicializacao]="subjectPaginacao" (onPaginationChange)="onPaginationChange($event)">
      </app-paginacao>
    </div>
    <div></div>
  </div>
  <app-loading *ngIf="isLoading"></app-loading>
</div>
<div class="msg-error" *ngIf="trBodies.length === 0">
  <span> Não existem respostas a serem auditadas </span>
</div>
