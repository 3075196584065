import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Localidade, Perfil, OperadorVinculado } from './localidade';
import { RequestService } from 'app/servico/request.service';
import { apiLocation } from 'app/infraestrutura/apiLocation';
import { PerfilEntrevistado, CotaPerfil } from '../../../model/perfilEntrevistado';

/**
 * Serviço de localidades utilizado pelo gerenciador de cotas.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalidadesService {

  constructor(protected requestService: RequestService) {}

  /**
   * recupera todas as localidades folhas da pesquisa passada.
   * cada pesquisa vem acompanhada de seus oepradores vinculados e cada operador vinculado,
   * de seus perfis.
   *
   * @param idPesquisa identificador da pesquisa
   */
  findAllLocalidadesByIdPesquisa(idPesquisa: number): Observable<Localidade[]> {

    const uri = `${apiLocation}/pesquisas/${idPesquisa}/gerenciador_cotas/localidades`;

    const observable: Observable<Localidade[]> = this.requestService.get(uri, null, true, null);

    return observable;
  }

  /**
   * Realiza o merge entre um determinado conjunto de localidades do gerenciador de cotas
   * e um conjunto de perfisEntrevistados.
   *
   * O que seria este merge?
   *
   * cada localidade, quando é retornada da API, vem com os operadores vinculados. Mas cada
   * operador vinculado não possui seus perfis. Para que possamos utilizar a est. de dados
   * de localidades para montar a 5 etapa do ger. de cotas precisamos atribuir a cada operador
   * vinculado todos os perfis, porém cada perfil de cada operador precisa ter sua própria
   * cotaPerfil.
   *
   * Esta é a responsabilidade deste serviço, identificar quais cotasPerfis são de quais
   * operadores baseado no id_vinculo_operador e criar replicas de perfis para cada vinculo
   * operador.
   * @param localidades localidades retornadas da API, cada uma delas com a relação de
   * localidades pais e operadores vinculados.
   * @param perfisEntrevistados perfis da pesquisa na estrutura da entidade propriamente dita.
   * É desta estrutura de dados que iremos puxar os perfis de cada operador.
   */
  merge(localidades: Localidade[], perfisEntrevistados: PerfilEntrevistado[]) {

    console.log('[localidadesService.merge] perfilEntrevistados: ', perfisEntrevistados);

    /**
     * estrutura criada para facilitar o merge v
     * {
     *   id_perfil(1): {
     *     id_vinculo_operador(2): CotaPerfil;
     *   }
     * }
     */
    // tslint:disable-next-line: max-line-length
    const perfisToCotaPerfilMapper: { [key: number]: {[key: number]: CotaPerfil} } = perfisEntrevistados
      .map((perfilEntrevistado: PerfilEntrevistado) => {
        const perfisToCotaPerfilMapper = {};

        const vinculoOperadorToCotaPerfil: {[key: number]: CotaPerfil} = perfilEntrevistado
          .cotasPerfil.map((cotaPerfil: CotaPerfil) => {
            const vinculoOperadorToCotaPerfilMapper = {};
            vinculoOperadorToCotaPerfilMapper[cotaPerfil.vinculoOperador.id] = cotaPerfil;
            return vinculoOperadorToCotaPerfilMapper;
          })
          .reduce((reduced, next) => {
            return { ...reduced, ...next };
          });

          // tslint:disable-next-line: max-line-length
          // console.log('[LocalidadeService.merge] perfisToVinculoOperadorToCotaPerfilMapper: ', perfisToCotaPerfilMapper);

        perfisToCotaPerfilMapper[perfilEntrevistado.id] = vinculoOperadorToCotaPerfil;

        return perfisToCotaPerfilMapper;
      })
      .reduce((reduced, next) => {
        return { ...reduced, ...next };
      });

    console.log('[LocalidadesService.merge] mapper: ', perfisToCotaPerfilMapper);

    localidades.forEach((localidade: Localidade) => {
      localidade.operadoresVinculados.forEach((operadorVinculado: OperadorVinculado) => {

        operadorVinculado.expanded = true;

        const perfis = perfisEntrevistados
          .map((perfilEntrevistado: PerfilEntrevistado) => {

            /**
             * aqui utilizamos a estrutura que criamos para buscar a partir do id
             * do perfil e do id do vinculo operador a cota desta operador.
             */
            const cotaPerfil = perfisToCotaPerfilMapper[perfilEntrevistado.id]
                                                       [operadorVinculado.idVinculoOperador];
            const progresso = {
              cotaNumericaPrevista: cotaPerfil.cotaNumericaPrevista,
              cotaNumericaExecutada: cotaPerfil.cotaNumericaExecutada,
            };

            // console.log('operadorVinculado: ', operadorVinculado);

            const perfil = Perfil.from(operadorVinculado.id,
                                       perfilEntrevistado, progresso, false);
            return perfil;
          });

        operadorVinculado.perfis = perfis;
      });
    });
  }
}
