import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";


@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  constructor() {}

  @Input() disabled: boolean = false;

  @Input() textContent: string = "Click me!";

  @Input() btnStyle: string = "default";

  @Input() btnStructure: { [key: string]: any } = {
    padding: '10px 46px',
    fontSize: '18px'
  }

  @Output() clickEvent = new EventEmitter<MouseEvent>();

  ngOnInit(): void {}

  handleClick($event: MouseEvent) {
    this.clickEvent.emit($event);
  }
}
