import { Component, Input, OnInit } from "@angular/core";
import { OperatorProps } from "../bond-section.component";

@Component({
  selector: "app-operador-card",
  templateUrl: "./operador-card.component.html",
  styleUrls: ["./operador-card.component.scss"],
})
export class OperadorCardComponent implements OnInit {
  @Input() operator: OperatorProps;

  ngOnInit() { }
}
