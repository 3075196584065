<div [formGroup]="formGroup">

  <select 
    placeholder="localidade" 
    formControlName="localidade" 
    (change)="onLocalidadeSelect()" title="localidade relacionada a pesquisa">
    <option [ngValue]="null" disabled>Selecione uma localidade</option>
    <option *ngFor="let localidade of localidades" [ngValue]="localidade.id">
      {{ localidade.nome }}
    </option>
  </select>

</div>