export class PerfilEntrevistado {

  /**
   * id do perfil
   */
  id: number;

  /**
   * quantidade de entrevistas TOTAL a ser realizadas na pesquisa com este perfil
   * (por todos os operadores)
   */
  cotaNumerica: number;

  /**
   * caracteristicas deste perfil. Isto eh, quais alternativas
   * de quais perguntas o entrevistado deve responder para satisfazer
   * o perfil.
   *
   * ex, caracteristicas =
   *
   * p1. sexo
   *  a) masculino
   *
   * p2. religião
   *  a) católico
   *
   * sendo "p1. sexo" e "p2. religião" perguntas com cotas, para satisfazer este perfil,
   * um entrevistado precisa ter respondido "masculino" e "católico" para as perguntas
   * "p1. sexo" e "p2. religião", respectivamente.
   */
  perfilEntrevistadoCaracteristicas: PerfilEntrevistadoCaracteristica[];

  /**
   * definição de quantas entrevistas cada vinculoOperador precisa
   * realizar e quantas já realizou satisfazendo este perfil.
   */
  cotasPerfil: CotaPerfil[];
}

/**
   * define uma caracteristica de um perfil. Isto eh, qual alternativa de uma pergunta
   * o entrevistado precisa responder para satisfazer a caracteristica do perfil.
   *
   * ex, caracteristicas =
   *
   * p1. sexo
   *  a) masculino
   *
   * p2. religião
   *  a) católico
   *
   * sendo "p1. sexo" e "p2. religião" perguntas com cotas, para satisfazer este perfil,
   * um entrevistado precisa ter respondido "masculino" e "católico" para as perguntas
   * "p1. sexo" e "p2. religião", respectivamente.
   */
export class PerfilEntrevistadoCaracteristica {

  /**
   * identificador da caracteristica
   */
  id: number;

  /**
   * pergunta com cota que espera-se ter uma determinada
   * resposta para satisfazer um perfil.
   */
  pergunta: {
    /**
     * identificador da pergunta
     */
    id: number;

    /**
     * descrição da pergunta, ex: "Qual o seu sexo?"
     */
    descricao: string;
  };

  /**
   * alternativa da pergunta acima que precisa ser selecionada
   * para satisfazer este perfil.
   */
  alternativa: {
    /**
     * identificador da alternativa
     */
    id: number;
    /**
     * descrição da alternativa ex: "Masculino"
     */
    descricao: string;
  };

  /**
   * ordem de exibição da caracteristica no perfil
   */
  ordem: number;
}

/**
 * definição de quantas entrevistas um vinculoOperador precisa
 * realizar e quantas já realizou satisfazendo um perfil.
 */
export class CotaPerfil {

  /**
   * identificador da cota perfil
   */
  id: number;

  /**
   * vinculo operador que deverá realizar uma determinada quantidade de entrevistas
   * satisfazendo o perfil
   */
  vinculoOperador: {
    /**
     * identificador do vinculo operador
     */
    id: number;
  };

  /**
   * número de entrevistas que o vinculo operador deve realizar satisfazendo o perfil;
   */
  cotaNumericaPrevista: number;

  /**
   * número de entrevistas que o vinculo operador já realizou satisfazendo o perfil;
   */
  cotaNumericaExecutada: number;
}