import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTooltipIfEllipsis]'
})
export class TooltipIfEllipsisDirective {
  @Input('appTooltipIfEllipsis') tooltipText: string;

  constructor(private elementRef: ElementRef) {}

  /**
   * Escuta o evento de entrada do mouse no elemento html
   * Verifica se o conteudo e maior que o container
   * para exibir a tooltip
   */
  @HostListener('mouseenter') onMouseEnter() {
    const element = this.elementRef.nativeElement as HTMLElement;
    const isTextOverflowing = element.clientWidth < element.scrollWidth;
    if (isTextOverflowing) {
      element.setAttribute("title", element.textContent)
    } else {
      element.removeAttribute("title")
    }
  }

  /**
   * Escuta o evento de saida do mouse no elemento html
   * e chama o metodo que ira destruir a tooltip
   */
  @HostListener('mouseleave') onMouseLeave() {
    const element = this.elementRef.nativeElement as HTMLElement;
    element.removeAttribute("title")
  }


}
