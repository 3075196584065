import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUser } from 'app/infraestrutura/security/authUser';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { TipoUsuarioSistema } from 'app/util/usuario/tipoUsuario';
import { HeaderSearchDataV4 } from '../../componentes/header/servico/header-service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  constructor(
    protected router: Router,
    private securityService: SecurityService,
  ) { }
  // resultados do dado da pesquisa
  @Input()
  researchData: HeaderSearchDataV4;
  // total de resultados
  @Input()
  researchSum: number = 0;

  userLogado: AuthUser;

  //  Função que retorna a informação em formato de boolean se o usuario é admnistrador ou não
  userIsAdm(): boolean {
    return this.userLogado.tipoUsuario && this.userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR;
  }

  // pega o usuário que está logado
  getUsuarioLogado(): AuthUser {
    const user: AuthUser = this.securityService.getAuthenticatedUser();
    return user;
  }

  ngOnInit() {
    this.userLogado = new AuthUser();
    this.userLogado = this.getUsuarioLogado()
    if (!history.state.researchData) {
      this.router.navigate(['/']);
    } else {
      this.researchData = history.state.researchData;
      this.researchSum = history.state.researchSum;
    }
  }

  // navega para a pagina de acordo com o item selecionado
  handleNavigate(uri: string, search: number): void {
    // navega para página de lista
    this.router.navigate([uri], { queryParams: { id: search } });
  }

  // manipula ação de selecionar cliente
  handleAcessClient(search: number): void {
    this.handleNavigate("/cliente/atualizar", search);
  }

  // manipula ação de selecionar operador
  handleAcessOperator(search: number): void {
    this.handleNavigate("/operador/atualizar", search);
  }

   // manipula alção de seleciona uma pesquisa
   handleAcessResearches(search: number): void {
    this.handleNavigate("/avaliacao/cadastro-beta", search);
  }

   // manipula alção de seleciona um grupo
  handleAcessGroups(search: number): void {
    this.handleNavigate("/grupo/atualizar", search);
  }

   // manipula alção de seleciona uma instancia
  handleAcessInstance(search: number): void {
    this.handleNavigate("/management/atualizar", search);
  }

   // manipula alção de seleciona um funcionario
  handleAcessEmployees(search: number): void {
    this.handleNavigate("funcionario/atualizar", search);
  }

   // manipula alção de seleciona uma localidade
  handleAcessLocations(search: number): void {
    this.handleNavigate("localidade-beta", search);
  }
}
