import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OperatorNavigateService {

  private parametro: number;

  /**
   * Setta o id do operador
   */
  setOperatorId(valor: number): void {
    this.parametro = valor;
  }

  /**
   * Obtem o id do operador, parametro será utilizado para aplicar o focus na tabela
   * após o cadastro/edição
   */
  getOperatorId(): number {
    const employeeId = this.parametro
    this.setOperatorId(null)
    return employeeId;
  }
}
