import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "app/componentes/modal/modal.service";
import { errorMessages } from "app/modulos/contratante-beta/erros";
import { ContratanteService } from "app/modulos/contratante-beta/services/contratante.service";
import { ModalDataControllerService } from "app/modulos/contratante-beta/services/modal-data-controller.service";
import {
  EMAIL_REGEX,
  NAME_REGEX,
} from "app/modulos/contratante-beta/utils/ContractorRegex";
import { NotificatorService } from "app/notificador/notificator.service";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";

@Component({
  selector: "app-admin-information",
  templateUrl: "./admin-information.component.html",
  styleUrls: ["./admin-information.component.scss"],
})
export class AdminInformationComponent implements OnInit {

  @Input() formGroup!: FormGroup;
  @Input() isEdicao: boolean;
  @Input() submitted: boolean;
  @Output() editContractor = new EventEmitter();
  @Output() adminInitialValues = new EventEmitter();

  rerenderProps: Array<number> = [1];
  @Input() newAdmin: FormGroup;

  // modal para renderizar um componente como children
  modalData = new ModalData();

  // Regex utilizados no formulario
  nameRegex = NAME_REGEX;
  emailRegex = EMAIL_REGEX;

  loginError = errorMessages.emailAlreadyExists;
  schemaName = this.activatedRoute.snapshot.params.tenant;

  constructor(
    private modalService: ModalService,
    protected activatedRoute: ActivatedRoute,
    private contratanteService: ContratanteService,
    private notificatorService: NotificatorService,
    private modalController: ModalDataControllerService
  ) {}

  ngOnInit(): void {}

  /**
   * Constrói a modal de edição de administrador
   * @param icon = icone da modal
   * @param titleDialog = titulo da modal
   * @param textDialog = texto da modal
   * @param textBtn = texto do botão
   */
  buildModal(
    icon: string,
    title: string,
    messageModal: string,
    btnTitlePositive: string,
    cb: () => void
  ) {
    this.modalService.showModal({
      title,
      messageModal,
      btnTitlePositive,
      icon,
      positiveCallback: () => cb(),
      negativeCallback: () => this.handleCancelBtn(),
    });
  }

  // modal de alterar os dados do administrador
  handleChangeAdmin() {
    this.modalController.setFlag(false);
    this.modalData.show = true;
    this.modalData.close = true;
    this.modalData.type = "information";
    this.modalData.icon = "fa-regular fa-user-pen";
    this.modalData.titleDialog = "Mudança de administrador";
    this.modalData.textDialog = null;
    this.modalData.showLineHeader = false;
    this.modalData.actionPositive = "Alterar";
    this.modalData.closeIconConfig = {
      className: "fa-solid fa-xmark",
      size: "16px"
    }
    this.modalData.positiveCallback = () => this.handleOkBtn();
    this.modalData.negativeCallback = () => this.handleCancelBtn();
  }

  // modal de alterar senha
  handleChangePass() {
    this.buildModal(
      "fa-regular fa-lock-keyhole",
      "Redefinir senha do administrador",
      "Uma solicitação de redefinição de senha será enviada ao e-mail do administrador. Deseja continuar?",
      "Continuar",
      () => this.changeAdminPassword()
    );
  }

  changeAdminPassword(): void {
    this.contratanteService.changeAdminPassword(this.schemaName).subscribe({
      complete: () => {
        this.notificatorService.showInfo(
          "Solicitação enviada!",
          "E-mail de redefinição de senha enviado ao administrador"
        );
      },
      error: () => {
        this.notificatorService.showError(
          "Erro na solicitação!",
          "E-mail não enviado"
        );
      }
    })
  }

  // Verifica se os campos estao validos antes de efetivar a troca do admin
  handleOkBtn() {
    Object.keys(this.newAdmin.controls).forEach((key) => {
      const control = this.newAdmin.get(key);
      control.markAsTouched();
    });

    if (this.newAdmin.status === "VALID") {
      this.changeAdminValues()
    }
    this.modalData.close = false;
  }

  /**
   * Ao cancelar reverte a atualização de valores precoce realizada pelo formControlName para os valores inicias (anteriores a abertura da modal)
   */
  handleCancelBtn() {
    this.modalController.setFlag(true);
    this.newAdmin.reset();
    this.adminInitialValues.emit();
  }

  changeAdminValues() {
    this.formGroup.patchValue({
      nome: this.newAdmin.get('nome').value,
      cpf: this.newAdmin.get('cpf').value,
      login: this.newAdmin.get('login').value,
    })

    this.updateFormValues();
  }

  /**
   * Chama o metódo que fara a request com a mudança de dados
   */
  updateFormValues() {
    this.editContractor.emit({
      nome: this.formGroup.get('nome').value,
      cpf: this.formGroup.get('cpf').value,
      login: this.formGroup.get('login').value,
    });
  }
}
