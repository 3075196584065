/**
 * Recupera a hora e o minuto de um horário completo (HH:MM)
 */
export const splitTime = function (time: string) {
  const [hh, mm] = time.split(":");

  return [hh, mm];
};

/**
 * Formata um valor numérico único para representar um horário válido
 * ex: 1 -> 01
 */
export const padStartTime = function (time: string) {
  if (time.length === 1) {
    return "0" + time;
  }

  return time;
};

/**
 * Formata uma hora (0-23) e um minuto (0-59) para um horário completo
 * ex: toFullTime(0, 5) -> 00:05
 */
export const toFullTime = function (hour: string, minute: string) {
  return `${padStartTime(hour)}:${padStartTime(minute)}`;
};

/**
 * Garante que o tempo sempre estará no formato HH:MM
 */
export const toValidFormat = function (time: string) {
  const [h, m] = splitTime(time);

  return `${padStartTime(h)}:${padStartTime(m)}`;
}

/**
 * Verifica se um horário está no formato HH:MM e retorna um booleano
 */
export const isValidTimeFormat = function (time: string) {
  const s = time.split(":");
  return s.length === 2 && !s.some((str) => str === "");
};

/**
 * Verifica se os parâmetros de entrada são números válidos para hora e minuto
 */
export const isValidHourAndMinute = function (hour: string, minute: string) {
  return !isNaN(parseFloat(hour)) && !isNaN(parseFloat(minute));
};

/**
 * Verifica se uma variável única de tempo (HH ou MM) representa um valor
 * válido. 
 * 
 * Exemplo, para horas, o máximo é o número 23, e para minutos, 59.
 *
 * Caso o valor seja inválido, será forçado o valor "" como default. 
*/
export const parseSingleTime = function (time: string, max = 23) {
  time = time.replace(/\D/g, "");

  if (time.length > 2 || parseFloat(time) > max) {
    time = "";
  }

  return time;
};

/**
 * Formata um horário inválido para garantir que sempre esteja no formato
 * HH:MM.
 */
export const replaceBadInputs = (hourInput: string) => {
  hourInput = hourInput.replace(/[hH]/g, "");
  hourInput = hourInput.replace(/[^\dh:]/, "");
  hourInput = hourInput.replace(/^[^0-2]/, "");
  hourInput = hourInput.replace(/^([2-9])[4-9]/, "$1");
  hourInput = hourInput.replace(/^\d[:h]/, "");
  hourInput = hourInput.replace(/^([01][0-9])[^:h]/, "$1");
  hourInput = hourInput.replace(/^(2[0-3])[^:h]/, "$1");
  hourInput = hourInput.replace(/^(\d{2}[:h])[^0-5]/, "$1");
  hourInput = hourInput.replace(/^(\d{2}h)./, "$1");
  hourInput = hourInput.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
  hourInput = hourInput.replace(/^(\d{2}:\d[0-9])./, "$1");

  return hourInput;
};
