import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from "@angular/core";
//import { viewAttached } from "@angular/core/src/render3/instructions";
import { removeSpecialCharacters } from "app/util/formatter";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

export interface SelectDataItem {
  value: number;
  label: string;
  hidden?: boolean;
}

export interface SelectedItemEvent {
  item: SelectDataItem;
  index: number;
}

function defaultFilterCallback(
  data: SelectDataItem[],
  query: string
): SelectDataItem[] {
  return data.filter((entry) => {
    const includes = removeSpecialCharacters(entry.label)
      .trim()
      .toLowerCase()
      .includes(removeSpecialCharacters(query.toLowerCase()).trim());
    return includes;
  });
}

@Component({
  selector: "app-filterable-select-old",
  templateUrl: "./filterable-select.component.html",
  styleUrls: ["./filterable-select.component.css"],
})
export class FilterableSelectComponent implements OnInit, OnDestroy {
  @Input() data: SelectDataItem[] = [];

  public selectedItem: SelectDataItem | null = null;
  public selectedItemIdx: number | null = null;

  public showBody: boolean = false;

  public filter: string = "";

  @Output()
  changeSelectedItem: EventEmitter<SelectedItemEvent> = new EventEmitter();

  @Input() filterCallback: (
    data: SelectDataItem[],
    query: string
  ) => SelectDataItem[] = defaultFilterCallback;

  @Input() placeholder: string = "Selecione um item";
  @Input() filterPlaceholder: string = "Busque por itens";
  @Input() selectedValue: number = null;
  @Input() closeOnSelect: boolean = false;
  @Input() errorMessage: string =
    "Não há dados para serem exibidos e/ou filtrados";
  @Input() backgroundColor: string = "rgb(248, 249, 253)";

  @Input() width: string = "400px";

  private filterSubject: Subject<string> = new Subject();
  private filterSubjectSubscription: Subscription | null = null;

  @Input() selectedCleaner: Subject<any>;
  private selectedCleanerSubscription: Subscription;

  getSelectedItem(data = [], itemValue) {
    const selectedIdx = data.findIndex((item) => item.value == itemValue);

    if (selectedIdx < 0) {
      return [selectedIdx, null];
    }

    return [selectedIdx, data[selectedIdx]];
  }

  ngOnInit(): void {
    this.initFilterHandlingSubject();

    const [idx, selected] = this.getSelectedItem(this.data, this.selectedValue);

    this.selectedItem = selected;
    this.selectedItemIdx = idx;

    if (this.selectedCleaner) {
      this.selectedCleanerSubscription = this.selectedCleaner.subscribe(() => {
        this.selectedItem = null;
        this.selectedItemIdx = null;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe(this.filterSubjectSubscription);
    this.unsubscribe(this.selectedCleanerSubscription);
  }

  hasDataEntries() {
    return this.data.length > 0;
  }

  /**
   * util method that unsubscribe a subscription
   * @param subscription
   */
  private unsubscribe(subscription: Subscription | null) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  handleShowBody() {
    this.showBody = !this.showBody;
  }

  /**
   * handle item select events
   * @param item selected item
   * @param itemIdx selected item index
   */
  handleItemSelect(item: SelectDataItem, itemIdx: number) {
    this.selectedItem = item;
    this.selectedItemIdx = itemIdx;
    const selectedItemEvent: SelectedItemEvent = {
      item: this.selectedItem,
      index: this.selectedItemIdx,
    };

    this.changeSelectedItem.emit(selectedItemEvent);
    this.cleanFilter();
    if (this.closeOnSelect) {
      this.showBody = false;
    }
  }

  cleanFilter() {
    this.filter = "";
    this.filterSubject.next("");
  }

  /**
   * inits the subject that listens to query events
   * obs: used by ngOnInit() {}
   */
  private initFilterHandlingSubject() {
    this.filterSubjectSubscription = this.createFilterHandlingSubject();
  }

  /**
   * create a subject that listens to query events,
   * it delays by 200ms the query events,
   * when 200ms is passed, it filters the data
   */
  private createFilterHandlingSubject() {
    return this.filterSubject
      .pipe(debounceTime(200))
      .subscribe((query: string) => {
        this.filter = query;

        const filteredValues = new Set(
          this.filterCallback(this.data, query).map((item) => {
            return item.value;
          })
        );

        this.data = this.data.map((item) => {
          const hidden = !filteredValues.has(item.value);

          return {
            ...item,
            hidden,
          };
        });
      });
  }

  /**
   * handle input filter events
   * @param event
   */
  handleFilter(event: any) {
    this.filterSubject.next(event.target.value);
  }
}
