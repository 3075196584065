<div class="box-operador" [formGroup]="operadoresVinculosFormGroup">
  <div class="box-header">
    <span class="fa fa-user-o"></span> <h3>Vincule os operadores</h3>
  </div>

  <div class="box-item" *ngFor="let operadorVinculo of operadoresVinculos">

    <div class='item-content' formGroupName="{{ operadorVinculo.hash }}">

      <div class="item-select">
        <app-selecao-operador [editavel]="operadoresVinculosFormGroup.enabled" (inputUpdate)="onOperadorSelect($event, operadorVinculo.hash)" [idOperadorSelecionado]="operadorVinculo.idOperador"></app-selecao-operador>
        <span *ngIf="operadoresVinculosFormGroup.enabled" (click)="removerVinculoOperador(operadorVinculo.hash)" class="fa fa-trash-o"></span>
      </div>

      <div class="item-cota">
        <div class="cota-porcentagem">
          <span>Cota %</span>
          <app-cota-percentual
            #appCotaPercentual
            (cotaPercentualChangeBlur)="onCotaPercentualChange(operadorVinculo.hash)"
            ngDefaultControl>
          </app-cota-percentual>
        </div>

        <div class="cota-numerica">
          <span>Numérica</span>
          <div>{{ operadorVinculo.cotaNumerica }}</div>
        </div>
      </div>
      <span class="error-message mt-3" *ngIf="getOperadorVinculoFormGroup(operadorVinculo.hash).controls.cotaPercentual.errors?.cotaInvalida">
        A soma de todas as cotas da pergunta deve corresponder a 100%
      </span>
      <span class="error-message mt-3" *ngIf="getOperadorVinculoFormGroup(operadorVinculo.hash).controls.cotaPercentual.errors?.menorQueValorMinimo">
        A cota percentual deve ser maior do que {{getOperadorVinculoFormGroup(operadorVinculo.hash).controls.cotaPercentual.errors?.valorMinimo}}
      </span>
      <span class="error-message mt-3" *ngIf="getOperadorVinculoFormGroup(operadorVinculo.hash).controls.idOperador.errors">
        Você deve selecionar um operador
      </span>

    </div>
  </div>

  <div class="box-action" *ngIf="operadoresVinculosFormGroup.enabled">
    <div class="add-operador" (click)="adicionarOperador()">
      <span class="fa fa-plus"></span>
    </div>
    <span (click)="adicionarOperador()">Adicionar operador</span>
  </div>

</div>
