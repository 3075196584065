/**
 * Tratando os dados para ficar ordenado de acordo com:
 * 1. linhas do gráfico
 * 2. legenda do gráfico (coluna)
*/
export const sortChartData = (alternatives, lines, columns) => {

  const seriesData = [...alternatives]
  .sort((a, b) => {
    if (lines.indexOf(a.linha) < lines.indexOf(b.linha)) return -1;
    if (lines.indexOf(a.linha) > lines.indexOf(b.linha)) return 1;
    return 0;
  })
  .sort((a, b) => {
    if (columns.indexOf(a.coluna) < columns.indexOf(b.coluna)) return -1;
    if (columns.indexOf(a.coluna) > columns.indexOf(b.coluna)) return 1;
    return 0;
  });

  return seriesData;
}
