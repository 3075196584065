import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BarSeriesOption, ECharts, EChartsOption } from 'echarts';
import { toEchartOption } from './toEchartOption';
import { orderNumericScale } from '../utils/parseDate';
import { DEFAULT_BAR_WIDTH, GAP_BETWEEN_BARS, GAP_BETWEEN_LEGENDS, MAXIMUM_BAR_ALTERNATIVES, MAXIMUM_BAR_ALTERNATIVES_COLUMN_MODE, MAXIMUM_BAR_SIZE } from './constants';
import { sortSeriesPercentuallyAndAlphabetically } from './utils/seriesSorter';
import canvas from 'app/util/misc/canvas';
import { ViewConfig, renderModes } from './model';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ["./bar.component.scss"],
})
export class BarComponent implements OnInit, OnChanges {

  @Input() alternatives;
  @Input() showModal: boolean;
  @Input() layout: string;
  chartOption: EChartsOption = {};
  echartsInstance: ECharts;
  estimatedWidth: number = 800;

  viewConfig: ViewConfig = {
    enableScrolling: false,
    barWidth: DEFAULT_BAR_WIDTH,
    renderMode: "DEFAULT"
  };

  constructor() {}

  ngOnInit(): void {
    if(this.alternatives) {
      this.sortNumericScale()
      this.updateChartState();
    }
  }

  /**
   * Ordena as respostas do tipo escala numérica por ordem decrescente
   */
  sortNumericScale() {
    if (this.alternatives[0].tipo === "ESCALA_NUMERICA") {
      this.alternatives = orderNumericScale(this.alternatives);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if((changes.showModal && changes.showModal.currentValue !== changes.showModal.previousValue)
      || (changes.layout && changes.layout.currentValue !== changes.layout.previousValue)) {
        this.calculateEstimatedChartWidth();
        console.log(changes);
    }
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;

    this.echartsInstance.on("rendered", () => {
      this.resizeChartContainer();
    });

    this.calculateEstimatedChartWidth();
  }

  getChartContainer(): HTMLElement {
    if (this.echartsInstance) {
      return this.echartsInstance.getDom();
    }
  }

  /**
   * Força que o container pai do gráfico sempre ocupe o tamanho real do card, isso pode ser melhorado, mas foi utilizado aqui
   * para evitar bug de dimensionamento da grid ao mudar o layout para "row"
   */
  resizeChartContainer() {
    const container = this.getChartContainer();

    if (container.children[0] instanceof HTMLElement) {
      container.children[0].style.width = '100%';
    }
  }

  updateRenderMode(renderMode: renderModes) {
    const enableScrolling: boolean = renderMode === "EXPANSIBLE";

    this.viewConfig.enableScrolling = enableScrolling;
    this.viewConfig.renderMode = renderMode;

    this.echartsInstance.resize({ width: this.estimatedWidth });
    this.updateChartState();
  };

  getMaximumBarSizeByLayout(): number {
    return this.layout === "COLUMN" || !!this.showModal
      ? MAXIMUM_BAR_ALTERNATIVES_COLUMN_MODE
      : MAXIMUM_BAR_ALTERNATIVES;
  };

  /**
   * Calcula o comprimento estimado que o gráfico terá com base na quantidade de alternativas ou no comprimento
   * das legendas e aplica um efeito de scroll horizontal caso esse valor ultrapasse o comprimento do card.
   */
  calculateEstimatedChartWidth() {
    if (Array.isArray(this.alternatives) && this.echartsInstance) {
      const container = this.getChartContainer();
      const cardWidth = container.parentElement.clientWidth;

      let estimatedWidth = cardWidth;

      this.estimatedWidth = estimatedWidth;

      let estimatedWidthForLegends = 0;
      let estimatedWidthForBars = 0;

      let maximumBarSize = DEFAULT_BAR_WIDTH;

      estimatedWidthForBars = ((DEFAULT_BAR_WIDTH * this.alternatives.length) + ((GAP_BETWEEN_BARS * (this.alternatives.length - 1))));

      /**
       * Se o valor estimado ocupado pelas barras (considerando gaps) for menor que o comprimento do card, então não fazemos nada
       * e não habiltiamos o scroll.
       */
      if (this.alternatives.length <= this.getMaximumBarSizeByLayout()) {
        this.updateRenderMode("DEFAULT");
        return;
      }

      const canvasElement = canvas.createElement();

      /**
       * Calcula o comprimento estimado considerando as legendas
       */
      this.alternatives.forEach(({ descricao }) => {
        estimatedWidthForLegends += (canvas.measureTextWidth(canvasElement, descricao, "14px Arial") + GAP_BETWEEN_LEGENDS);
      });

      /**
       * Se o comprimento estimado para legendas for menor que o comprimento estimado para as barras nós mantemos a referência
       * de espaço necessário como o comprimento das barras
       */
      if (estimatedWidthForLegends < estimatedWidthForBars) {
        estimatedWidth = estimatedWidthForBars;
      }
      /**
       * Caso contrário, a referência de espaço necessário se torna o comprimento estimado para as legendas, e é calculado o comprimento
       * médio que cada barra do gráfico deve ter para que ele se adeque ao tamanho estimado para as legendas.
       */
      else {
        maximumBarSize = (estimatedWidthForLegends / this.alternatives.length) - GAP_BETWEEN_BARS;
        estimatedWidth = estimatedWidthForLegends;
      }

      if (maximumBarSize > MAXIMUM_BAR_SIZE) {
        maximumBarSize = MAXIMUM_BAR_SIZE;
      }

      this.viewConfig.barWidth = maximumBarSize;
      this.estimatedWidth = estimatedWidth;

      this.updateRenderMode("EXPANSIBLE");
    }
  }

  // retorna os resultados numericos com base no sortedData
  buildResultadosNumericos(sortedData: BarSeriesOption[]): number[] {
    return sortedData.map(({ name }) => {
      const alternative = this.alternatives.find(alternative => alternative.descricao === name);
      if (alternative) {
        return alternative.resultado_numerico
      }
    })
  }

  buildChartSeries() {
    let chartData: Object[] = [];

    this.alternatives.forEach(({ descricao, resultado_percentual }) => {
      const seriesPropeties: BarSeriesOption = {
        name: descricao,
        data: [resultado_percentual],
        type: 'bar',
      };

      if (this.viewConfig.enableScrolling) {
        seriesPropeties.barWidth = this.viewConfig.barWidth;
      };

      chartData.push(seriesPropeties);
    });

    return chartData;
  }

  /**
   * metodo criado para formatar e armazenar os dados que serão utilizados para renderizar o gráfico.
   * @chartData = Armazena os dados que serão plotados no gráfico (name, resultado_percentual e type)
   * @numberResult = Armazena o valor númerico que será utilizado na tooltip
   */
  updateChartState() {
    const chartData = this.buildChartSeries();

    /**
     * Aplica o algorítmo de sorting por ordem alfabética e pelo valor percentual
     */
    const sortedData = sortSeriesPercentuallyAndAlphabetically(chartData);
    const sortedNumberResult = this.buildResultadosNumericos(sortedData);

    this.chartOption = toEchartOption(sortedData, this.viewConfig.renderMode, sortedNumberResult);
  }

}


