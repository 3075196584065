import { IAuditCollections, IAuditCollectionsResponse } from "../../interfaces";

export const adaptCollectionsListRespToModels = function (
  collectionsListResponse: IAuditCollectionsResponse[]
): IAuditCollections[] {
  return collectionsListResponse?.map((data) => ({
    id: data.id,
    operador: data.operador['nome'],
    situacao: data.situacao,
    localidade: data.localidade['nome'],
    criterios: data.criterios,
  }));
};
