<!-- V2 do FIGMA -->
<div class="editar-pergunta-container">
  <header>
    <p>SEÇÃO {{ sectionData.index + 1 }}:</p>
    <h4>{{ sectionData.nome }}</h4>
  </header>

  <div class="question-details">
    <div class="question-details__info">
      <section>
        <div
          class="input-fields__input"
          [ngClass]="
            !(questionData.tipo == 'UNICA' || questionData.tipo == 'MULTIPLA' || questionData.tipo == 'ESCALA_NUMERICA')
              ? 'larger'
              : 'none'
          "
        >
          <label for="">Título da pergunta</label>
          <input
            type="text"
            id=""
            placeholder="{{ questionData.titulo }}"
            disabled
          />
        </div>

        <div
          class="input-fields__input fixed-width"
          [ngClass]="
            questionData.tipo == 'UNICA' || questionData.tipo == 'MULTIPLA' || questionData.tipo == 'ESCALA_NUMERICA'
              ? 'bigger'
              : 'none'
          "
        >
          <label for="">Tipo da pergunta*</label>
          <input
            type="text"
            id=""
            [placeholder]="formatTipoPergunta(questionData.tipo)"
            disabled
          />
        </div>
      </section>

      <section [ngClass]="questionData.obrigatorio == false && 'grid-one'">
        <div class="input-fields__input">
          <label for="">Orientação para responder a pergunta*</label>
          <input
            type="text"
            id=""
            placeholder="{{ questionData.orientacao }}"
            disabled
          />
        </div>

        <div class="mandatory-check" *ngIf="questionData.obrigatorio == true">
          <i class="fas fa-check-square"></i>
          <span>Está alternativa deve ser obrigatoriamente respondida</span>
        </div>
      </section>
    </div>
  </div>

  <div class="answers-content">
    <div
      class="answers-content__open-answer"
      *ngIf="!getTypeOfQuestion(questionData.tipo)"
    >
      <div class="answer-item__options">
        <span>Pular para</span>
        <div
          class="select-container"
          [ngClass]="perguntaTemMarcacao() && 'selected'"
        >
          <div
            class="drop-select"
            [ngClass]="_dropDownActive ? 'bigger-display' : 'default-display'"
          >
            <div class="drop-select__header">
              <div class="input-fields__select" (click)="setDropDownActive()">
                <p
                  *ngIf="!perguntaTemMarcacao()"
                  [ngClass]="!perguntaTemMarcacao() && 'has-margin'"
                >
                  Selecione a pergunta referente ao pulo
                </p>
                <div
                  class="input-fields__selected-content"
                  *ngIf="perguntaTemMarcacao()"
                >
                  <div class="selected-content__question">
                    <h4>Pergunta {{ _changeSelectedQuestionRef?.ordem }} -</h4>
                    <p title="{{ _changeSelectedQuestionRef?.titulo }}">
                      {{ _changeSelectedQuestionRef?.titulo }}
                    </p>
                  </div>
                  <div class="selected-content__section">
                    <h4>SEÇÃO {{ _changeSelectedSectionRef?.ordem }} :</h4>
                    <p
                      title="{{
                        _changeSelectedSectionRef?.nome ||
                          _changeSelectedSectionRef?.descricao
                      }}"
                    >
                      {{
                        _changeSelectedSectionRef?.nome ||
                          _changeSelectedSectionRef?.descricao
                      }}
                    </p>
                  </div>
                </div>
                <i class="fas fa-chevron-down btnChevron"> </i>
              </div>

              <div class="drop-select__content" *ngIf="_dropDownActive">
                <!-- FUNCIONALIDADE A SER IMPLEMENTADA -->
                <div class="input-fields__search">
                  <input
                    type="text"
                    id="search"
                    [value]="inputText"
                    (keyup)="inputChangeHandler($event)"
                    placeholder="Faça uma busca pela pergunta que procura"
                  />
                  <i class="fas fa-search"></i>
                </div>

                <!-- CHAMADA DO COMPONENTE DE SEÇÃO PASSANDO ALGUMAS PROPRIEDADES PARA -->
                <!-- UTILIZAR NA INTERAÇÃO DO USUÁRIO -->
                <app-section-list
                  *ngFor="let secao of secoesFiltradas"
                  [data]="secao"
                  [pesquisaRealizada]="!!inputText"
                  [tpyeOfUse]="'LOGIC'"
                  [isSelectable]="true"
                  [questionRef]="questionData"
                  [sectionRef]="sectionData"
                  [selectedQuestion]="_changeSelectedQuestionRef"
                  [selectedSection]="_changeSelectedSectionRef"
                  (setSelectedQuestion)="setSelectedQuestion($event)"
                >
                </app-section-list>
              </div>
            </div>
          </div>
          <!-- COMPONENTIZAR -->
          <button
            *ngIf="perguntaTemMarcacao() && !_dropDownActive"
            class="button-remove-jump"
            (click)="setResetSelectedQuestion()"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div
      class="answers-content__closed-answer"
      *ngIf="getTypeOfQuestion(questionData.tipo)"
    >
      <div class="closed-answer__header">
        <h4>Alternativas</h4>
        <button
          [disabled]="!perguntaTemPulo()"
          (click)="removerMarcacoes()"
        >
          Remover pulos
        </button>
      </div>

      <div class="closed-answer__list">
        <app-answer-item
          *ngFor="
            let answer of formGroup.value.alternativas;
            let indexOfAlternativa = index
          "
          [answer]="answer"
          [ordemAlternativa]="indexOfAlternativa + 1"
          [fatherAnswer]="questionData"
          [sectionRef]="sectionData"
          [sectionsData]="sectionList"
          (adicionarMarcacaoAAlternativa)="
            adicionarMarcacaoAAlternativa(indexOfAlternativa, $event)
          "
          (removerMarcacaoDaAlternativa)="
            removerMarcacaoDaAlternativa(indexOfAlternativa)
          "
          [retornaReferenciasDaSecaoEDaPerguntaDaAlternativaComPulo]="
            retornaReferenciasDaSecaoEDaPerguntaDaAlternativaComPulo(answer)
          "
        >
        </app-answer-item>
      </div>
    </div>
  </div>

  <footer>
    <button id="cancel-button" (click)="cancelar()">Cancelar</button>
    <button
      id="success-button"
      (click)="
        ['UNICA', 'MULTIPLA', 'ESCALA_NUMERICA'].includes(questionData.tipo)
          ? adicionarLogicaAUmaPerguntaComAlternativas()
          : adicionarLogicaAUmaPerguntaSemAlternativas()
      "
    >
      Salvar
    </button>
  </footer>
</div>
