<main #combobox>
  <div
    [style.justify-content]="!selected.length ? 'space-between' : ''"
    [style.border-color]="
      (form.get('permissions')?.invalid && (form.get('permissions')?.dirty || form.get('permissions')?.touched)
      && (form.get('permissions')?.errors.required)) ? 'var(--status-error)' : ''"
    class="permission"
  >
    <label *ngIf="!selected.length">Adicionar outras permissões</label>
    <ng-container *ngFor="let item of selected">
      <small
        [ngStyle]="item.disabled && {backgroundColor: 'var(--gray150)'}"
      >{{ item.nomeAmigavel }}
        <i
          class="fa-regular fa-xmark"
          *ngIf="!item.disabled"
          (click)="selectPermission(item.nomeAmigavel)"
        ></i>
      </small>
    </ng-container>
    <i (click)="toggleCombobox()" class="fa-solid fa-plus"></i>
  </div>
  <ul
    [ngClass]="{
      'opened-combobox': comboboxOpened,
      'closed-combobox': !comboboxOpened
    }"
  >
    <div class="input-search">
      <input type="text" [(ngModel)]="inputSearch" placeholder="Buscar..." />
      <i class="fa-regular fa-magnifying-glass"></i>
    </div>
    <p class="missing-message" *ngIf="!getItems.length">
      Nenhum resultado encontrado
    </p>
    <div class="list-items">
      <li *ngFor="let item of getItems" (click)="selectPermission(item.nomeAmigavel)">
        <app-checkbox [isDisabled]="item.disabled" [isChecked]="item.selecionado"></app-checkbox>
        <p>{{ item.nomeAmigavel }}</p>
      </li>
    </div>
  </ul>
</main>
