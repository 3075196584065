<app-breadcrumb [data]="handleGetSurveyTitle()"></app-breadcrumb>

<div class="informacoes-container" *ngIf="canShow">
  <app-header-informacoes [pesquisaBody]="handleGetPesquisaInfo()"></app-header-informacoes>
  <app-info-section [pesquisaBody]="handleGetPesquisaInfo()"></app-info-section>

  <app-questionnaire-section [pesquisaBody]="handleGetPesquisaInfo()"></app-questionnaire-section>

  <app-bond-section
    *ngIf="handleCanShowVinculos()"
    [vinculosBody]="handleGetPesquisaInfoVinculos()"
    [operatorData]="operadoresDataToVinculos"
  ></app-bond-section>
</div>
