const parseNumber = (n: number) => n < 10 ? '0' + n : n;

export const SurveyListModalTexts = {
  archiveUniqueSurvey: {
    title: "Arquivar pesquisa",
    body: "Ao arquivar uma pesquisa ela será apenas guardada, caso queira consultar basta filtrar por arquivadas."
  },
  archiveMultipleSurveys: {
    title: "Arquivar pesquisas",
    body(totalOfSurveys: number) {
      return `Deseja realmente <b class="info">arquivar</b> ${parseNumber(totalOfSurveys)} pesquisas? Elas poderão ser acessadas através do filtro ”Arquivadas”.`
    },
  },
  deleteUniqueSurvey: {
    title: "Excluir pesquisa",
    body: "Ao excluir a pesquisa teste 4 você perderá todos os dados referente ao seu cadastro e coletas, caso exista.",
  },
  deleteMultipleSurveys: {
    title: "Excluir pesquisas",
    body(totalOfSurveys: number) {
      return `Deseja realmente <b class="info">excluir</b> ${parseNumber(totalOfSurveys)} pesquisa${
        totalOfSurveys > 1 ? "s" : ""
      }? Os dados de cadastro e coletas serão perdidos.`
    }
  },
};
