import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SURVEY_STATUS_COLORS } from "app/modulos/pesquisa-beta/listagem/constants/surveyStatus";

enum Status {
  RASCUNHO = "Rascunho",
  CONCLUIDO = "Concluído",
  ARQUIVADA = "Arquivada",
  EXECUCAO = "Em execução",
}
@Component({
  selector: "pesquisa-item",
  templateUrl: "./pesquisa.component.html",
  styleUrls: ["./pesquisa.component.scss"],
})
export class PesquisaComponent implements OnInit {
  @Input() pesquisaId: number;
  @Input() tituloCurto: string;
  @Input() cliente: string;
  @Input() amostrasOnline: number;
  @Input() amostrasPresenciais: number;
  @Input() entrevistasOnlineRealizadas: number;
  @Input() entrevistasPresenciaisRealizadas: number;
  @Input() status: string;
  @Input() statusText: string;
  //
  @Input() isFlagged: number = 0;
  // status
  @Input() estadoArquivada: boolean = false;
  @Input() estadoRascunho: boolean = false;
  @Input() estadoExecucao: boolean = false;
  @Input() estadoConcluida: boolean = false;
  @Input() possuiAmostrasOnline: boolean = false;
  @Input() possuiLocalidadeVinculo: boolean = false;
  // booleano que informa se a pesquisa foi selecionada ou não
  @Input() isPesquisaSelecionada: boolean;
  // booleano que informa se a pesquisa foi duplicada ou não
  @Input() isDuplicated: boolean = false;
  // Evento de clique do checkbox do item da listagem de pesquisa
  @Output() onCheckboxClick = new EventEmitter<number>();
  // Evento de clique dos botões de evento
  @Output() onKebabClick = new EventEmitter<{
    pesquisaId: number;
    pesquisaEmExecucao: boolean;
    status: string;
    action: string;
  }>();

  public statusLabel: string = "";
  public statusColor: string = "";

  ngOnInit() {
    this.statusLabel = this.getStatusLabel();
    this.statusColor = this.getSurveyStatusColor(this.statusLabel);
  }

  private getSurveyStatusColor(status: string) {
    return SURVEY_STATUS_COLORS.find((s) => s.status === status)?.color || 'blue';
  }

  private getStatusLabel() {
    if (this.isFlagged === 0) {
      return this.estadoArquivada ? "Arquivada" : this.capitalizeStatus(this.status);
    }
    else {
      return this.isFlagged === 1 ? "Arquivada" : this.capitalizeStatus(this.status);
    }
  }

  capitalizeStatus(s: string): string {
    return Status[s];
  }

  handleClick() {
    this.onCheckboxClick.emit(this.pesquisaId);
  }

  handleMethodClick(action) {
    this.onKebabClick.emit({
      pesquisaId: this.pesquisaId,
      pesquisaEmExecucao: this.estadoExecucao,
      status: this.statusText,
      action,
    });
  }
}
