<div
  [ngClass]="
    isPesquisaSelecionada == true
      ? 'pesquisa-item pesquisa-item-checked'
      : isDuplicated == true
      ? 'pesquisa-item duplicated'
      : 'pesquisa-item'
  "
>
  <div [ngClass]="estadoExecucao ? 'checkbox-th th disabled' : 'checkbox-th th'">
    <div class="checkbox" (click)="estadoExecucao ? null : handleClick()">
      <i class="fa fa-check"></i>
    </div>
  </div>
  <div class="titulo-th th">
    <h4 appTooltipIfEllipsis="{{ tituloCurto }}" >{{ tituloCurto }}</h4>
  </div>
  <div class="clientes-th th">
    <h4 appTooltipIfEllipsis="{{ cliente }}" >{{ cliente }}</h4>
  </div>
  <div class="presencial-th th">
    <h4>{{ entrevistasPresenciaisRealizadas }}/{{ amostrasPresenciais }}</h4>
  </div>
  <div class="online-th th">
    <h4>{{ entrevistasOnlineRealizadas }}/{{ amostrasOnline }}</h4>
  </div>
  <div class="status-th th">
    <div class="item-status-container">
      <div class="item-status-wrapper">
        <span
          class="status"
          [style.backgroundColor]="statusColor"
        ></span>
        {{ statusLabel }}
      </div>
    </div>
    <app-kebab-para-pesquisa
      [estadoArquivada]="estadoArquivada"
      [estadoRascunho]="estadoRascunho"
      [estadoExecucao]="estadoExecucao"
      [estadoConcluida]="estadoConcluida"
      [possuiAmostrasOnline]="possuiAmostrasOnline"
      [possuiLocalidadeVinculo]="possuiLocalidadeVinculo"
      (onMethodClick)="handleMethodClick($event)"
    >
    </app-kebab-para-pesquisa>
  </div>
</div>
