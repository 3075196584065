import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { QueryParamsResolver } from "app/util/formatter/queryParams";
import { Observable } from "rxjs";
import { IOperatorPostPayload } from "../interfaces";

@Injectable({
  providedIn: "root",
})
export class OperadorService extends CrudService<any> {
  public baseUrl = this.resourcePath;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}`);
  }

  /**
   * Recupera os grupos de permissões do funcionário
   */
  getHeaderData(): Observable<any> {
    const url = `${this.baseUrl}/operadores/resumo`;
    const response = this.requestService.get(url);
    return response;
  }

  /**
   * Obtem o total de operadores
   * @returns
   */
  getTotalRegistros(
    keywords = [""],
    date,
    em_campo = null,
    status = null,
  ): Observable<any> {

    const {periodo_inicial, periodo_final} = date
    const queryParams = new QueryParamsResolver({
      ativo: status,
      periodo_inicio: periodo_inicial,
      periodo_fim: periodo_final,
      em_campo
    }).toString();

    const url = `${this.baseUrl}/operadores/total-registros${queryParams}`;

    const response = <Observable<number>>(
      this.requestService.post(url, { palavrasChave: keywords })
    );

    return response;
  }

  /**
   * Recupera a lista de operadores cadastrados
   * @param keywords: palavras do filtro
   * @param page: página atual
   * @param size: itens por página
   * @returns
   */
  getOperatorList(
    keywords = [""],
    status = null,
    sorting: { direction: DirectionOrderTypes, sort: string },
    page = 0,
    date,
    em_campo = null,
    size = 10,
  ): Observable<any> {

    const { direction, sort } = sorting;
    const {periodo_inicial, periodo_final} = date

    const queryParams = new QueryParamsResolver({
      ativo: status,
      page,
      size,
      direction,
      sort,
      periodo_inicial,
      periodo_final,
      em_campo
    }).toString();

    const url = `${this.baseUrl}/operadores/filtrar/palavra-chave${queryParams}`;

    const response = this.requestService.post(url, { palavrasChave: keywords })

    return response;
  }

  /**
   * Cria um novo operador
   * @param operatorData: Dados do operador
   */
  createOperator(operatorData: IOperatorPostPayload): Observable<any> {
    const url = `${this.baseUrl}/operadores`;
    const response = this.requestService.post(url, operatorData);
    return response;
  }

  /**
   * Recupera os dados do operador
   * @param operatorData: Dados do operador
   */
  getOperatorById(id: number): Observable<any> {
    const url = `${this.baseUrl}/operadores/${id}`;
    const response = this.requestService.get(url);
    return response;
  }

  /**
   * Recupera os dados do operador
   * @param operatorData: Dados do operador
   */
  updateOperator(operatorData): Observable<any> {
    const url = `${this.baseUrl}/operadores/${operatorData.id}`;
    const response = this.requestService.put(url, operatorData);
    return response
  }

  /**
   * Muda o status do operador
   * @param id: id do operador
   * @param status: novo status
   */
  changeOperatorStatus(id: number, status: boolean): Observable<any> {
    const url = `${this.baseUrl}/operadores/${id}/ativo`;

    const response = this.requestService.put(url, {ativo: status});
    return response
  }

}
