<div class="login-container">
  <div class="form-container">
    <div class="top-container">
      <img class="tensai-logo" [src]="contractorImages.logotipo_light" alt="logo tensai">
      <div class="welcome-message">
        <h3>{{ title }}</h3>
        <p>{{ subtitle }}</p>
      </div>
      <ng-content></ng-content>
      <p>Distribuído por © {{year}} Tensai. Todos os direitos reservados</p>
      <span class="lgpd-documents">
        <a [href]="termsOfUsagePDF" target="_blank">Termos de uso</a>
        |
        <a [href]="privacyPolicyPDF" target="_blank">Política de privacidade</a>
      </span>
    </div>
  </div>
  <div class="images-container">
    <img [src]="contractorImages.login" alt="login-img" class="foto-ilustrativa"/>
  </div>
</div>
