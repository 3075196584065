<section class="interviewConfig container-white">
    <div class="headerConfig">
      <span class="fa fa-clock-o"></span>
      <h3>Horário</h3>
    </div>
    <div class="contentConfig">
      <div [formGroup]="formGroup" class="section sg-input-group">
        <div class="campo">
          <label>Título da pergunta*</label>
          <input mascara-primeiro-digito-sem-espaco placeholder="título da pergunta" title="título principal" type="text" formControlName="nome"  maxlength="250">
    <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
      A pergunta deve possuir um titulo de até 250 caracteres
    </span>
        </div>
        <div class="campo">
          <label>Orientação</label>
          <input mascara-primeiro-digito-sem-espaco placeholder="Orientação ao Operador" title="Orientação para pergunta do tipo horário" type="text" formControlName="descricao"  maxlength="150">
    <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
      A pergunta deve possuir uma descrição de até 150 caracteres
    </span>
        </div>
        <div class="actionConfig">
          <button title="Cancelar pergunta de horário" class="sg-btn sg-alternate" (click)="cancelar()">Cancelar</button>
          <button title="Salvar pergunta de horário" class="sg-btn sg-secondary" (click)="onChange()" *ngIf="modificacaoPermitida && !passoBloqueado">Salvar</button>
        </div>
      </div>
    </div>
  </section>
