<div class="modal-questionary-ordenation-container">
  <div class="box-content">
    <header>
      <section class="label">
        <div class="questionary-order-content">
          <i class="fa fa-list-ol"></i>
          <span>Ordenar questionário</span>
        </div>
        <button class="close-modal" (click)="handleCloseModal()">
          <i class="fa fa-times-circle-o"></i>
        </button>
      </section>
      <section class="description-questionary-modal">
        <p>
          Ordene as seções e perguntas do seu questionário. Caso a pergunta ou
          seção tenha a tag de lógica, significa que existe algum tipo de
          configuração lógica em alguma pergunta que se for reorganizada perderá
          essa configuração
        </p>
      </section>
    </header>
    <main cdkDropList (cdkDropListDropped)="dropSecoes($event)" class="main-container">
      <section class="section-with-questions-container"
        *ngFor="let itemOfSecao of controllerSection; let itemIndex = index" cdkDrag cdkDragLockAxis="y"
        cdkDragBoundary=".main-container">
        <div class="section-card">
          <button type="button" class="drag-drop-content" (click)="handleShowQuestions(itemIndex)">
            <i class="fa fa-chevron-right" aria-hidden="true" *ngIf="!itemOfSecao.canOpen"></i>
            <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="itemOfSecao.canOpen"></i>
          </button>
          <div class="center-info">
            <div class="info-label">
              <h1>Seção {{ itemIndex + 1 }} - {{ itemOfSecao.nome }}</h1>
              <p>{{ itemOfSecao.descricao }}</p>
            </div>

            <div class="flag" *ngIf="itemOfSecao.hasFlag">
              <span>Lógica</span>
            </div>
          </div>

          <button type="button" class="drag-drop-content" cdkDragHandle>
            <div alt="Mover"></div>
          </button>
        </div>

        <div cdkDropList class="question-card-list" *ngIf="controllerSection[itemIndex].canOpen"
          (cdkDropListDropped)="dropPerguntas($event, itemIndex)">
          <div class="question-card" *ngFor="
              let question of itemOfSecao.perguntas;
              let questionIndex = index
            " cdkDrag cdkDragLockAxis="y" cdkDragBoundary=".question-card-list">
            <div class="center-info">
              <div class="info-label">
                <h1>Pergunta {{ questionIndex + 1 }}</h1>
                <p>{{ question.nome }}</p>
              </div>

              <div class="flag" *ngIf="question.hasFlag">
                <span>Lógica</span>
              </div>
            </div>

            <button class="drag-drop-content" type="button" cdkDragHandle>
              <div alt="Mover"></div>
            </button>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <button type="button" id="cancel" (click)="handleCloseModal()">
        Cancelar
      </button>
      <button type="button" id="save" (click)="handleSaveReOrderedSectionAndQuestions()"
        [disabled]="!canSaveDragAndDropChanges">
        Salvar
      </button>
    </footer>
  </div>
</div>
