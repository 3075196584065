import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LocalidadeService } from "../servico/localidade.service";
import { ListReadService, LocalidadeToTable } from "./list-read.service";

interface EditFilha {
  idPaiApi: number;
  idFilhaApi: number;
  idFilhaList: number;
}

@Component({
  selector: "app-list-read",
  templateUrl: "./list-read.component.html",
  styleUrls: ["./list-read.component.scss"],
})
export class ListReadComponent implements OnInit, OnDestroy {
  // Modal
  modalVisibility: boolean = false;
  isEdicao: boolean = false;

  // ID Localidade Pai selecionada para edição
  localidadePaiSelectedById: number = null;

  // ID Localidade Filha selecionada para edição
  localidadeFilhaSelectedById: number = null;

  // ID Localidade Filha List selecionada para edição
  localidadeFilhaListSelectedById: number = null;

  searchToEditLocationId: string = '';

  localidadesToTableData: LocalidadeToTable[] = [];

  constructor(
    private listReadService: ListReadService,
    private localidadeService: LocalidadeService,
    private route: ActivatedRoute,

  ) {}

  ngOnInit() {
    this.handleInitListReadTable();
    this.searchToEditLocationId = this.route.snapshot.queryParams.id;
    if(this.searchToEditLocationId) {
      this.handleOpenEditModal(Number(this.searchToEditLocationId))
    }
  }

  // FUNÇÃO PARA RETORNAR A PRIMEIRA PÁGINA COM AS LOCALIDADES
  /**
   *
   */
  handleInitListReadTable(): void {
    let requestObservable;

    requestObservable = this.localidadeService.listarLocalidadesParaTabela();

    requestObservable.subscribe((response: LocalidadeToTable[]) => {
      let finalResponse: LocalidadeToTable[];

      finalResponse = response.map((item) => ({
        ...item,
        checked: item.checked ? item.checked : false,
        isOpen: item.isOpen ? item.isOpen : false,
      }));

      this.listReadService.setLocalidades(finalResponse);
      this.listReadService.mountChildrenData();
      this.handleThisGetTotal();
    });
  }

  handleThisGetTotal(): void {
    let requestObservable;

    requestObservable = this.localidadeService.totalDeLocalidades();

    requestObservable.subscribe((response) => {
      this.listReadService.setTotal(response);
    });
  }

  handleGetTotalOfLocalidades(): number {
    return this.listReadService.getTotalOfLocalidades();
  }

  handleGetStartOf(): number {
    return this.listReadService.getStartOf();
  }

  handleGetOneOf(): number {
    return this.listReadService.getOneOf();
  }

  handleGetCanClickNextPage(): boolean {
    return this.listReadService.getCanSetNextPage();
  }

  handleGetLocalidades(): LocalidadeToTable[] {
    this.localidadesToTableData = this.listReadService.getLocalidades();

    return this.localidadesToTableData;
  }

  // Table Header
  // Verificar se existe checkbox ativado para habilitar botão
  handleGetIfHasSomeLocalidadeChecked(): boolean {
    return (
      this.listReadService.getIfHasSomeLocalidadesChecked() ||
      this.listReadService.getIfHasSomeLocalidadesFilhasChecked()
    );
  }

  handleGetMainCheck(): boolean {
    return this.listReadService.getMainCheck();
  }

  // Função para abrir o modal
  handleChangeOpenModal() {
    this.modalVisibility = true;
    this.isEdicao = false;
  }

  // FUNÇÃO PARA ABRIR MODAL NO MODO EDIÇÃO
  handleOpenEditModal(idApi: number) {
    this.localidadePaiSelectedById = idApi;
    this.isEdicao = true;
    this.modalVisibility = true;
  }

  // FUNÇÃO PARA ABRIR MODAL NO MODO EDIÇÃO DE LOCALIDADE FILHA
  handleOpenEditFilhaModal(data: EditFilha): void {
    this.localidadePaiSelectedById = data.idPaiApi;
    this.localidadeFilhaSelectedById = data.idFilhaApi;
    this.localidadeFilhaListSelectedById = data.idFilhaList;
    this.modalVisibility = true;
    this.isEdicao = true;
  }

  // Função para fechar modal
  handleChangeCloseModal() {
    this.localidadePaiSelectedById = null;
    this.localidadeFilhaSelectedById = null;
    this.modalVisibility = false;
    this.isEdicao = false;

    this.ngOnInit();
  }

  ngOnDestroy() {
    this.listReadService.reset();
  }
}
