import { Component, Input, OnInit } from "@angular/core";

export interface IInfoCardData {
  title: string;
  value: number;
}

export interface InfoCardDataProps {
  title: string;
  text: string;
  data: IInfoCardData[];
}

@Component({
  selector: "app-info-card",
  templateUrl: "./info-card.component.html",
  styleUrls: ["./info-card.component.scss"],
})
export class InfoCardComponent implements OnInit {
  @Input() data: IInfoCardData[];
  @Input() title: string;
  @Input() text: string;
  svgColor: string = 'var(--tertiary)';
  constructor() {}

  ngOnInit(): void {}
}
