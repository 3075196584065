import { HttpResponse } from '@angular/common/http';
import { JWTService } from './service/jwtService';
import { Injectable } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS_FACTORY } from '@angular/material/snack-bar';
import { Operador } from 'app/modulos/operador/operador';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenMapper {

  constructor(private jwtService: JWTService) { }

  /**
   * Funcao que mapeia uma requisicao de resposta em busca de um token
   * de refresh, caso encontre, atualiza a sessao do usuario
   * @param responseRequest Resposta oriunda de uma requisicao arbitraria
   */
  mapRefreshToken(): Function {

    return (responseRequest): HttpResponse<Object> => {
      const refreshTokenHeaderName = 'Refreshed-Token';

      if (responseRequest.headers.has(refreshTokenHeaderName)) {
        const refreshedToken = responseRequest.headers.get(refreshTokenHeaderName);

        this.jwtService.setToken(refreshedToken);
      }

      return responseRequest;
    };

  }

}
