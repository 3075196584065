import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilterSelect } from "../../filter-select";

@Component({
  selector: "app-list-select",
  templateUrl: "./list-select.component.html",
  styleUrls: ["./list-select.component.scss"],
})
export class ListSelectComponent implements OnInit {
  @Input() options = [];
  @Output() changeFilter = new EventEmitter<IFilterSelect>();
  @Output() callbackEnd = new EventEmitter();

  selectedItem: string;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Seleciona um item dos listados
   * @param option Pode ser qualquer valor que esteja dentro do array de options;
   */
  selectFilterOption(option: IFilterSelect) {
    this.selectedItem = option.label;
    this.changeFilter.emit(option);
    this.callbackEnd.emit();
  }
}
