import { PassoCadastro } from '../passoCadastro';
import { ActionType } from '../actionType';
import { PesquisaCadastro } from '../../model/pesquisaCadastro';
import { EstadoValidacao } from '../cadastroPesquisaStoreState';
import { AbstractAction } from './abstractAction';

export class SetPreparadoParaSalvar extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.SET_PREPARADO_PARA_SALVAR;

  constructor(passoCadastro: PassoCadastro, preparado: boolean) {
    super();
    this.payload = {
      passoCadastro, preparado,
    };
  }
}

export class PrepararParaSalvar extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.PREPARAR_PARA_SALVAR;

  constructor(prepararParaSalvar: boolean) {
    super();
    this.payload = {
      prepararParaSalvar,
    };
  }
}

export class DefinirPesquisa extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.DEFINIR_PESQUISA;

  constructor(estado: PesquisaCadastro) {
    super();
    this.payload = {
      estado,
    };
  }
}

export class DefinirPassoAtual extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.DEFINIR_PASSO_ATUAL;

  constructor(estado: PassoCadastro) {
    super();
    this.payload = {
      estado,
    };
  }
}

export class DefinirExibicaoValidacoes extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.DEFINIR_EXIBICAO_VALIDACOES;

  constructor(estado: boolean, passo: PassoCadastro) {
    super();
    this.payload = {
      estado,
      passo,
    };
  }
}

/**
 * Utilizado para quando se deseja exibir as validações de campos
 * que são apenas interessantes para o rascunho da pesquisa
 */
export class ExibirValidacoesRascunho extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.EXIBIR_VALIDACOES_RASCUNHO;

  constructor(estado: boolean, passo: PassoCadastro) {
    super();
    this.payload = {
      estado,
      passo,
    };
  }
}

export class CleanStore extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.CLEAN_STORE;

  constructor() {
    super();
    this.passoCadastro = PassoCadastro.GERAL;
    this.type = ActionType.CLEAN_STORE;
  }
}

export class DefinirEstadoDeValidacao extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.DEFINIR_ESTADO_VALIDACAO;

  constructor(payload: EstadoValidacao) {
    super();
    this.passoCadastro = PassoCadastro.GERAL;
    this.type = ActionType.DEFINIR_ESTADO_VALIDACAO;
    this.payload = payload;
  }

}

export class BloquearPassosCadastro extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERAL;
  readonly type = ActionType.BLOQUEAR_PASSOS_CADASTRO;

  constructor(payload: PassoCadastro[]) {
    super();
    this.payload = payload;
  }

}
