import { Component, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-foto-uploader",
  templateUrl: "./foto-uploader.component.html",
  styleUrls: ["./foto-uploader.component.scss"],
})
export class FotoUploaderComponent implements OnDestroy {
  // marcador para a exibica oda animacao de carregamento
  showLoading: boolean = false;
  // url da foto que pode tanto ser uma URI ou a codificacao base64
  url: string;
  // subscription caso a imagen seja adquirida por uma url
  imageProviderSubscription: Subscription;
  // imagem padrao do componente
  defaultImage =
    "https://s3.amazonaws.com/alfa-tensai-teste/default-images/placeholder-upload.png";
  // referencia para o input DOM
  @ViewChild("fileInput", { static: true }) fileInputElRef: ElementRef;

  /**
   * Mime types aceitos para as imagens
   */
  private validFormats = ["image/jpeg", "image/jpg"];

  constructor(private http: HttpClient, private modalService: ModalService) {}

  ngOnInit() {
    this.url = this.defaultImage;
  }

  //  Preview do upload de imagem
  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];

      if (this.isValidImage(file)) {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent) => {
          const processedFile = (<FileReader>event.target).result;
          this.url = <string>processedFile;
        };

        reader.readAsDataURL(file);
      }
    }
  }

  loadImageByUrl(url: string) {
    this.url = url;
    // this.activeLoading();

    // const onLoadError = () => {
    // };

    // this.http.get(url, { responseType: 'blob' }).subscribe((imageData) => {
    //   const reader = new FileReader();

    //   reader.onloadend = (event: Event) => {
    //     const base64ProcessedFile = <string>(<FileReader>event.target).result;

    //     const base64ImagePrefix = 'data:image/jpeg;base64';
    //     // tslint:disable-next-line:max-line-length
    //     const base64ProcessedFileWithoutPrefix = base64ProcessedFile.substring(base64ProcessedFile.indexOf(','), base64ProcessedFile.length);
    //     const finalBase64ProcessedFile = `${base64ImagePrefix}${base64ProcessedFileWithoutPrefix}`;

    //     this.url = finalBase64ProcessedFile;
    //     this.deactivateLoading();
    //   };

    //   reader.onerror = onLoadError;

    //   reader.readAsDataURL(imageData);

    //   // tslint:disable-next-line:align
    // }, (error) => {
    //   this.deactivateLoading();
    //   onLoadError();
    // });
  }

  ngOnDestroy(): void {
    if (this.imageProviderSubscription) {
      this.imageProviderSubscription.unsubscribe();
    }
  }

  isValidImage(file: File): boolean {
    // maximo de 3mb por foto
    const maxImageSize = 3242880;

    if (file.size > maxImageSize) {
      this.modalService.showModal({
        title: "Seleção de imagem",
        messageModal: "A imagem deve possuir até 3mb de tamanho",
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
      });
      return false;
      // tslint:disable-next-line:no-else-after-return
    } else if (!this.validFormats.includes(file.type)) {
      this.modalService.showModal({
        title: "Seleção de imagem",
        messageModal: "A imagem deve estar no formato jpeg",
        btnTitlePositive: "Entendi",
        isOnlyConfirmation: true,
      });
      return false;
    }

    return true;
  }

  // activeLoading() {
  //   this.showLoading = true;
  // }

  // deactivateLoading() {
  //   setTimeout(() => {
  //     this.showLoading = false;
  //     // tslint:disable-next-line:align
  //   }, 1000);
  // }
}
