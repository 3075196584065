import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { syncFormGroup } from 'app/util/misc/syncControl';
import { EventoInput } from '../../../../../../../../../../pesquisa-old/cadastro/inputs/evento/eventoInput';
import { TipoEventoInput } from '../../../../../../../../../../pesquisa-old/cadastro/inputs/evento/tipoEventoInput';
import { InputComponent } from '../../../../../../../../../../pesquisa-old/cadastro/inputs/inputComponent';
import { Alternativa } from '../../../../../../../../../../pesquisa-old/cadastro/model/alternativa';

@Component({
  selector: 'app-alternativas',
  templateUrl: './alternativas.component.html',
  styleUrls: ['./alternativas.component.scss'],
})
export class AlternativasComponent extends InputComponent {

  @Input() source: Alternativa;
  @Input() hash;
  @Input() hashPergunta: string;
  @Input() hashSecao: string;

  initFormGroup() {
    this.controls['alternativas'] = new UntypedFormArray([]);
    this.formGroup = new UntypedFormGroup({
      alternativas: this.controls.alternativas,
    });

    // Por padrão, adicionamos duas alternativas
    const quantidadeAlternativasIniciais = 2;
    for (let i = 0; i < quantidadeAlternativasIniciais; i += 1) {
      (<UntypedFormArray>this.controls.alternativas).push(Alternativa.getControl());
    }

    this.onSelecaoComponentCreated();
  }

  /**
   * Lifecycle hook that are called when the component is created
   */
  onSelecaoComponentCreated() {
  }

  onNestedInputRemoveInput(payload: any) {

    const alternativaFG: UntypedFormGroup = payload;
    const alternativaHash = alternativaFG.value.hash;

    // console.log('[input.selecao.unica] children removal inputs.value: ', alternativaFG.value);

    const resultadoBusca = this.getAlternativaById(alternativaHash);
    (<UntypedFormArray>this.controls.alternativas).removeAt(resultadoBusca.index);

    this.controls.alternativas.updateValueAndValidity();
    this.notifyParentComSnapshotAtual();
  }

  notifyParentComSnapshotAtual() {

    const evento = new EventoInput(TipoEventoInput.CHANGE, this.formGroup);
    // console.log('[input.selecao.unica] current controls: ', this.controls);
    // console.log('[input.selecao.unica] notifying parent current state: ', this.formGroup);

    this.notifyParent(evento);
  }

  getAlternativaById(alternativaHash: string): { index: number, control: UntypedFormGroup } {

    const resultadoBusca = (<UntypedFormArray>this.controls.alternativas).controls
      .filter(alternativaControl => alternativaControl.value.hash === alternativaHash)
      .map((control: UntypedFormGroup, index) => ({
        index,
        control,
      }))[0];

    return resultadoBusca;
  }

  onNestedInputChange(payload: any) {

    // console.log('[input.selecao.unica] current state: ', this.controls);

    const alternativaFG = <UntypedFormGroup>payload;

    this.updateCurrentState(alternativaFG);
    this.notifyParentComSnapshotAtual();
  }

  updateCurrentState(nestedInputNewState: UntypedFormGroup) {

    // tslint:disable-next-line:max-line-length
    const alternativaToUpdate: UntypedFormGroup = this.getAlternativaById(nestedInputNewState.value.hash).control;
    // console.log('[input.alternativas] updating alternativa: ', alternativaToUpdate);
    // tslint:disable-next-line:max-line-length

    syncFormGroup(nestedInputNewState, alternativaToUpdate.controls);
    this.controls.alternativas.updateValueAndValidity();
  }

  addAlternativa() {
    const alternativa = Alternativa.getControl();
    const alternativasFormArray = <UntypedFormArray>this.controls.alternativas;
    alternativasFormArray.push(alternativa);
    this.notifyParentComSnapshotAtual();
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(abstractFormArray: AbstractControl): AbstractControl[] {

    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }

}
