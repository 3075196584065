<div class="container-info-media">
  <h1>{{ title }}</h1>

  <div *ngIf="!!data" class="options">
    <div class="info-card">
      <p>{{ infoTitle }}
        <span>{{ formatByType() }}</span>
      </p>
    </div>

    <div class="options-list" [ngClass]="type.toLocaleLowerCase()">
      <div *ngFor="let operador of data; let i = index" [ngClass]="['item item-' + i]">
        <img *ngIf="operador.foto && type === 'COLETAS_POR_DIA'" [src]="operador.foto.arquivo" [alt]="operador.nome">
        <i *ngIf="!operador.foto && type === 'COLETAS_POR_DIA'" class="fa-duotone fa-user"></i>
        <i *ngIf="!operador.foto && type === 'APROVACOES'" class="fa-solid fa-star"></i>
        <i *ngIf="!operador.foto && type === 'TEMPO_MEDIO_COLETA'" class="fa-solid fa-crown"></i>
        <div *ngIf="type === 'APROVACOES'" class="progress">
          <div class="bar" [ngStyle]="{'width.%': operador.valor.toString().split('%')[0]}"></div>
        </div>
        <span>{{ i + 1}}</span>
        <button (click)="goDetails(+operador.id)" [disabled]="operador.nome === '-'">
          <p [title]="operador.nome">{{operador.nome}}</p>
        </button>
        <div *ngIf="type === 'COLETAS_POR_DIA'" class="value">
          {{+operador.valor > 0 ? roundNumber(+operador.valor) : 0}} Coletas
        </div>
        <div *ngIf="type === 'APROVACOES'" class="value">{{operador.valor}}%</div>
        <div *ngIf="type === 'TEMPO_MEDIO_COLETA'" class="value">{{operador.valor}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="!(!!data)" class="empty-card">
    <p>Ainda não existem auditorias</p>
    <p>nesta avaliação</p>
  </div>
</div>
