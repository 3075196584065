// FIXME: devido falta de entendimento da arquitetura,
// este ponto está sendo duplicado. Request uma revisão posterior

// tipos de alternativas
export enum TipoAlternativa {
  FECHADA = 'FECHADA',
  ABERTA_TEXTO = 'ABERTA_TEXTO',
  ABERTA_NUMERO = 'ABERTA_NUMERO',
  GRADE = 'GRADE',
  ESCALA_NUMERICA = 'ESCALA_NUMERICA',
}

// tipos de perguntas
export enum TipoPergunta {
  UNICA = 'UNICA',
  MULTIPLA = 'MULTIPLA',
  MULTIPLA_ORDEM_CITACAO = 'MULTIPLA_ORDEM_CITACAO',
  ESCALA_NUMERICA = 'ESCALA_NUMERICA',
  FOTO = 'FOTO',
  VIDEO = 'VIDEO',
  GRADE_UNICA = 'GRADE_UNICA',
  GRADE_MULTIPLA = 'GRADE_MULTIPLA',
  DATA = 'DATA',
  HORARIO = 'HORARIO',
}

export interface SectionElemQuestionModel {
  id?: number;
  hash: string;
  nome: string;
  descricao: string;
  obrigatoria: boolean;
  tipo: TipoPergunta;
  codigoMarcacao: string;
  discoInducao: boolean;
  possuiCota: boolean;
  possuiPulo: boolean;
  possuiDiferenteDe: boolean;
  possuiPuloPergunta: boolean;
  faixaPergunta: {
    intervaloInicio: number;
    intervaloFim: number;
  }
  alternativas: {
    id?: number;
    descricao: string;
    tipoAlternativa?: TipoAlternativa;
    ordem?: number;
    hash?: string;
    marcacao?: {
      id?: number;
      possuiPulo: boolean;
      possuiDiferenteDe: boolean;
      possuiCota: boolean;
      perguntaDestino: {
        codigoMarcacao: string;
      };
    }
  }[],
  qtdMaxima: number;
  qtdMinima: number;
  titulosGrade: {
    descricao: string;
    ordem: number;
  }[],
  marcacaoPergunta: {
    id?: number;
    perguntaDestino: {
      codigoMarcacao: string;
    };
  };
  ordemCota: null
}

// elementos da seção
export interface SectionElemModel {
  id?: number;
  ordem: number;
  hash: string;
  pergunta: SectionElemQuestionModel;
}
