import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Pergunta } from '../../../../../../pesquisa-old/cadastro/model/pergunta';
// tslint:disable-next-line: max-line-length
import { Observable, Subscription } from 'rxjs';
import { ElementoSecao } from '../../../../../../pesquisa-old/cadastro/model/elementoSecao';
import { AlternativaComponent } from '../../../questionario/componentes/secao/pergunta/alternativa/alternativa.component';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { calcularValorCorrespondente } from 'app/util/misc/porcentagem';
import { map } from 'rxjs/operators';
import { Alternativa } from '../../../../../../pesquisa-old/cadastro/model/alternativa';
import { PesquisaCadastro } from '../../../../../../pesquisa-old/cadastro/model/pesquisaCadastro';
import { ItemProtegido } from '../../../../../../pesquisa-old/cadastro/security/itemProtegido';
import { PesquisaAuthorityService } from '../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';
import { CadastroPesquisaStoreState } from '../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../../pesquisa-old/cadastro/store/passoCadastro';

@Component({
  selector: 'app-alternativa-tipada-marcacao',
  templateUrl: './alternativa-tipada-marcacao.component.html',
  styleUrls: ['./alternativa-tipada-marcacao.component.scss'],
})
export class AlternativaTipadaMarcacaoComponent extends AlternativaComponent implements OnDestroy {

  perguntasDisponiveis: Pergunta[] = [];
  perguntaFg: UntypedFormGroup;

  /**
   * totalAmostras que a pesquisaPossui
   */
  totalAmostrasPesquisa;

  subscriptions: Subscription[] = [];

  /**
   * Marcador para saber se os erros de validacao
   * devem ser exibidos
   */
  showErrors: boolean = false;

  passoCadastro: PassoCadastro = PassoCadastro.MARCACOES;

  /**
   * Marcador que indica se modificações
   * estão habilitadas no passo
   */
  modificacaoPermitida = false;

  @Output() cotaChange: EventEmitter<Alternativa> = new EventEmitter();

  // tslint:disable-next-line: max-line-length
  constructor(protected store: Store<CadastroPesquisaStoreState>, private pesquisaAuthorityService: PesquisaAuthorityService) {
    super(store);
  }

  /**
   * @override
   */
  ngOnInit() {
    super.ngOnInit();

    this.perguntaFg = <UntypedFormGroup>this.formGroup.parent.parent;

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    const pesquisaSubscription = pesquisaStoreObservable
      .subscribe((store) => {
        this.totalAmostrasPesquisa = store.pesquisa.configuracaoPesquisa.amostrasPresenciais;
        this.carregarPerguntasDisponiveis(store.pesquisa);

        this.verificarPermissaoAlteracao();
        const passosBloqueados = store.dadosGeraisCadastro.passosBloqueados;

        if (this.modificacaoPermitida && !passosBloqueados.includes(this.passoCadastro)) {
          this.formGroup.enable();
          this.disableQuestionarioControls();
        } else {
          this.formGroup.disable();
        }
      });

    this.subscriptions.push(pesquisaSubscription);

    /**
     * Verificando se deve-se exibir as mensagens de validação
     * no passo atual, caso positivo, é feito o unsubscribe
     * no canal
     */
    const dadosPassoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso),
      )
      .subscribe((dadosPasso) => {
        const dados = dadosPasso.get(this.passoCadastro);
        // tslint:disable-next-line: max-line-length
        this.showErrors = dados.exibirValidacao;
        if (this.showErrors && dadosPassoSubscription) {
          dadosPassoSubscription.unsubscribe();
        }
      });

    this.subscriptions.push(dadosPassoSubscription);
  }

  verificarPermissaoAlteracao() {
    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.MARCACOES);
  }

  disableQuestionarioControls() {
    this.controls.descricao.disable();

    const marcacaoFg = <UntypedFormGroup>this.controls.marcacao;
    if (marcacaoFg.controls.cotaValor) {
      marcacaoFg.controls.cotaValor.disable();
    }
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe);
  }

  /**
   * Carrega as perguntas disponiveis para salto e diferente de
   */
  carregarPerguntasDisponiveis(pesquisa: PesquisaCadastro) {

    // tslint:disable-next-line: max-line-length
    const elementosSecaoMatriz = pesquisa.elementosQuestionario.map(elemetoQuestionario => elemetoQuestionario.secao.elementosSecao);
    const elementosSecaoArray: ElementoSecao[] = [].concat(...elementosSecaoMatriz);
    const perguntas = elementosSecaoArray.map(elementoSecao => elementoSecao.pergunta);

    /**
     * Removendo a propria questao
     */
    let indexToRemove = -1;
    perguntas.some((pergunta, index) => {

      if (pergunta.hash === this.perguntaFg.get('hash').value) {
        indexToRemove = index;
        return true;
      }
    });

    perguntas.splice(indexToRemove, 1);

    this.perguntasDisponiveis = perguntas;
  }

  onCotaPercentualChange(cotaPercentual) {

    const marcacaoFg = <UntypedFormGroup>this.controls.marcacao;
    const cotaValorControl = marcacaoFg.controls.cotaValor;

    /**
     * Calculando o valor da cota
     */
    const valorNumerico = cotaPercentual.replace('%', '').replace(',', '.');
    const porcentagemASerCalculada = valorNumerico.length > 0 ? valorNumerico : 0;
    // tslint:disable-next-line: max-line-length
    const cotaValor = calcularValorCorrespondente(this.totalAmostrasPesquisa, porcentagemASerCalculada);

    cotaValorControl.patchValue(cotaValor);

    this.cotaChange.emit(this.formGroup.getRawValue());
  }

}
