import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CadastroPesquisaStoreState } from 'app/modulos/pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { AlternativaComponent } from '../alternativa.component';

@Component({
  selector: 'app-alternativa-tipada',
  templateUrl: './alternativa-tipada.component.html',
  styleUrls: ['./alternativa-tipada.component.scss'],
})
export class AlternativaTipadaComponent extends AlternativaComponent {
  constructor(store: Store<CadastroPesquisaStoreState>) {
    super(store)
  }
}
