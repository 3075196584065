import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, AbstractControl } from "@angular/forms";
import { Store } from "@ngrx/store";
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from "../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState";
// tslint:disable-next-line: max-line-length
import { TipoPergunta } from "../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { TipoMarcacao } from "./tipoMarcacao";
// tslint:disable-next-line: max-line-length
import { MarcacaoPerguntaService } from "../../../../../../../../pesquisa-old/cadastro/passos/marcacoes/marcacaoPergunta.service";
import { ToastrService } from "ngx-toastr";
import {
  AlterarPergunta,
  Selecionar,
  RemoverPergunta,
} from "../../../../../../../../pesquisa-old/cadastro/store/actions/questionarioAction";

/**
 * componente que simboliza os cards de pergunta do questionario
 */
@Component({
  selector: "app-pergunta-card",
  templateUrl: "./pergunta-card.component.html",
  styleUrls: ["./pergunta-card.component.scss"],
})
export class PerguntaCardComponent implements OnInit {
  @Input() icone: string;
  @Input() titulo: string;
  @Input() nomePlaceHolder: string;

  tipoMarcacao = TipoMarcacao;

  isLastElementoSecaoArray: boolean = false;

  isFirstElementoSecaoArray: boolean = false;

  @Input() formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl };

  constructor(
    private store: Store<CadastroPesquisaStoreState>,
    private marcacaoPerguntaService: MarcacaoPerguntaService,
    private toastr: ToastrService
  ) {}

  /**
   * @override
   */
  ngOnInit(): void {
    this.controls = this.formGroup.controls;
    this.isLastElementoSecaoArray = this.controls.isLastItemElementoSecao.value;
    this.isFirstElementoSecaoArray =
      this.controls.isFirstItemElementoSecao.value;
  }

  // tslint:disable-next-line: function-name
  // Função que verifica se uma pergunta deve possuir pulo
  perguntaComMarcacaoPulo(event: Event) {
    event.stopPropagation();
    const mensagemMarcacao =
      "A última pergunta não pode possuir a marcação do tipo 'pulo'";
    this.verificarSePerguntaPossuiMarcacao(
      this.isLastElementoSecaoArray,
      mensagemMarcacao
    );
  }

  // tslint:disable-next-line: function-name
  // Função que verifica se uma pergunta deve possuir marcação 'diferente de'
  perguntaComMarcacaoDiferenteDe(event: Event) {
    event.stopPropagation();
    // tslint:disable-next-line: max-line-length
    const mensagemMarcacao =
      "A primeira pergunta não pode possuir a marcação do tipo 'diferente de'";
    this.verificarSePerguntaPossuiMarcacao(
      this.isFirstElementoSecaoArray,
      mensagemMarcacao
    );
  }

  /**
   * Verifica se uma pergunta pode possuir um determinado tipo de marcação. Caso não deva possuir,
   * uma mensagem é apresentada ao usuário.
   * @param showMessagePerguntaSemMarcacao retorna true caso uma pergunta tenha alguma restrição
   *  de marcação.
   * @param mensagemMarcacao Mensagem apresentada ao usuario final
   */
  // tslint:disable-next-line: max-line-length
  verificarSePerguntaPossuiMarcacao(
    showMessagePerguntaSemMarcacao: boolean,
    mensagemMarcacao: string
  ) {
    if (showMessagePerguntaSemMarcacao) {
      this.showMessageMarcacao(mensagemMarcacao);
    }
  }

  showMessageMarcacao(mensagemMarcacao: string) {}

  /**
   * Metodo que informa se determinada marcação
   * pode ser escolhida pelo usuario
   */
  possuiMarcacao(tipoMarcacao: TipoMarcacao): boolean {
    const tipoPergunta = this.controls.tipo.value;
    return this.marcacaoPerguntaService.possuiMarcacao(
      tipoPergunta,
      tipoMarcacao
    );
  }

  toggleCheckbox(nomeControlAlvo: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.controls[nomeControlAlvo].patchValue(
      !this.controls[nomeControlAlvo].value
    );
    this.onChange();
  }

  // Change responsavel somente pela alteração das perguntas com marcação de pulo
  // So será adicionado a marcação de pulo caso o a pergunta não seja o ultimo item das seções
  onChangePulo() {
    // caso a pergunta seja o ultimo item da seção, não será disparado o evento com os dados
    // modificados
    if (!this.isLastElementoSecaoArray) {
      this.onChange();
      return;
    }

    this.controls.possuiPulo.patchValue(null);
    this.controls.possuiPuloPergunta.patchValue(null);
  }

  onChangePerguntaDiferenteDe() {
    // caso a pergunta seja o ultimo item da seção, não será disparado o evento com os dados
    // modificados
    if (!this.isFirstElementoSecaoArray) {
      this.onChange();
      return;
    }

    this.controls.possuiDiferenteDe.patchValue(null);
  }

  onChange() {
    this.store.dispatch(
      new Selecionar(this.formGroup.value, this.controls.tipoConteudo.value)
    );
    this.store.dispatch(new AlterarPergunta(this.formGroup.value));
  }

  remover(event: Event) {
    event.stopPropagation();

    const removerCalback = () => {
      this.store.dispatch(new RemoverPergunta(this.formGroup.value));
    };
  }
}
