<div>
  <app-calendar-dates
  [initialDate]="initialDate"
  [usedBy]="usedBy"
  [endDate]="endDate"
  [dates]="dates"
  (output)="handleDateOutput($event)"
  ></app-calendar-dates>
  <span #dateInputs>
    <app-calendar-inputs
    [initialDate]="initialDate"
    [usedBy]="usedBy"
    [endDate]="endDate"
    [isDateValid]="isDateValid"
    (output)="handleInputsChanges($event)"
    [cylclesDays]="cylclesDays"
    ></app-calendar-inputs>
  </span>
</div>
