<!-- V2 do FIGMA -->
<!-- Interface Padrão de Listagem de Seções e suas perguntas -->
<div class="pesquisas-logica-container" *ngIf="!getShowDetails()">
  <header>
    <div class="header-guide">
      <div class="header-guide__left">
        <div
          class="list-dinamic"
          alt="Lógica"
        ></div>
        <h4>Lógica</h4>
      </div>
      <div class="header-guide__center">
        <p>Passo não obrigatório |</p>
        <button (click)="handleNextStep()">deseja avançar?</button>
      </div>
      <div class="header-guide__right">
        <button (click)="handleShowQuizPreview()" class="btn-ver-questionario">
          <div
            class="ver-questionario-icon"
            alt="Ver questionário"
          ></div>
          <span>VER QUESTIONÁRIO</span>
        </button>
      </div>
    </div>

    <div class="header-info">
      <div class="header-info__title">
        <p>Título da avaliação</p>
        <h4>{{ input?.descricaoPesquisa.titulo }}</h4>
      </div>
      <div class="header-info__vertical-bar"></div>
      <div class="header-info__description">
        <i class="fa-regular fa-circle-info fa-lg" alt="Salvar Dados Atuais"></i>
        <p>
          A lógica cria um fluxo alternativo a partir das respostas obtidas no
          questionário. Através da configuração de pulos de uma pergunta para
          outra, é possível otimizar os resultados da avaliação. <br>Configure em
          <i class="fa-regular fa-gear-complex fa-lg" alt="Configurações"></i>
        </p>
      </div>
    </div>
  </header>

  <main class="pesquisas-logica-section-list">
    <div class="section-list__header">
      <div
        class="click-action"
        alt="Seleção de Perguntas"
      ></div>
      <h4>Defina a lógica do questionário</h4>
    </div>

    <div class="section-list__content">
      <p>Selecione a pergunta que deseja configurar a lógica</p>

      <div class="section-list__cards" *ngFor="let secao of lista">
        <app-section-list
          [secoes]="lista"
          [data]="secao"
          [tpyeOfUse]="'REGULAR'"
          (setChildrenShowDetails)="setShowDetails()"
          (getChooseSectionAndQuestion)="detailsQuestion($event)"
        >
        </app-section-list>
      </div>
    </div>
  </main>
</div>

<!-- Interface condicional de detalhamento de uma pergunta -->
<div class="pesquisas-logica-question-detail" *ngIf="getShowDetails()">
  <div class="question-detail__header">
    <img src="../../../../../../assets/icons/list-dinamic.svg" alt="Lógica" />
    <h4>Lógica</h4>
  </div>

  <div class="question-detail__info">
    <p>Título da avaliação</p>
    <h4>{{ input?.descricaoPesquisa.titulo }}</h4>
  </div>

  <app-editar-pergunta
    [questionData]="getChooseQuestion()"
    [sectionData]="getChooseSection()"
    [sectionList]="lista"
    (handleSetShowDetails)="setShowDetails()"
    (handleAdicionarLogicaAUmaPerguntaComAlternativas)="adicionarLogicaAUmaPerguntaComAlternativas($event)"
    (handleAdicionarLogicaAUmaPerguntaSemAlternativas)="adicionarLogicaAUmaPerguntaSemAlternativas($event)"

    (onCanNotSaveButton)="onCanNotSaveButtonQuestion($event)"
  >
  </app-editar-pergunta>
</div>
