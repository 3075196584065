import { PerguntaQuestionarioAuditadoManager } from "..";
import { RespostaQuestionarioTipoSimplesProcessor } from "./simples/simplesProcessor";
import { Processor } from "./ProcessorInterface";

/**
 * Gerencia os processadores de disponíveis para cada tipo
 * de pergunta
 */
export class RespostaProcessorManager {
  constructor(context: PerguntaQuestionarioAuditadoManager) {
    this.context = context;

    this.processarRespostas();
  }

  public context: PerguntaQuestionarioAuditadoManager;

  private _simplesProcessor: RespostaQuestionarioTipoSimplesProcessor;
  private _activeProcessor: Processor;

  private processarRespostas() {
    this._simplesProcessor = new RespostaQuestionarioTipoSimplesProcessor(
      this.context
    );

    /**
     * Para possiveis processadores posteriores; atualmente só o processador
     * de perguntas simples foi implementado
     */
    if (this.context.utils.isPerguntaSimplesComAlternativas()) {
      this._activeProcessor = this.simplesProcessor;
    }

    if (this._activeProcessor) {
      this._activeProcessor.processar();
    }
  }

  public get simplesProcessor() {
    return this._simplesProcessor;
  }

  public get activeProcessor() {
    return this._activeProcessor;
  }
}
