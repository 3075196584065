import { TableCell } from "../interfaces/tCell";
import { TableRow } from "../interfaces/tRow";

export function tRowFactory(...cells: TableCell[]) {
  const tRow = new TableRow();

  tRow.cells = cells;

  const o = {
    id: (v: number) => {
      tRow.id = v;

      return o;
    },
    class: (className: string) => {
      tRow.className = className;
      return o;
    },
    metadata: (m: any) => {
      tRow.metadata = m;

      return o;
    },
    build: () => tRow,
  };

  return o;
};
