import { AfterContentChecked, Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Classe basica com as acoes necessarias para uma mascara
 */
@Directive()
export abstract class MascaraBase implements AfterContentChecked {

  constructor(protected ngControl: NgControl, protected maxInputLength: number) {
  }

  /**
   * Metodo que contem formatacoes customizadas
   */
  abstract aplicarMascara(valor: string): string;

  // Aplicando as mascaras no caso de edicao
  ngAfterContentChecked(): void {
    if (this.aplicarMascara && this.ngControl && this.ngControl.value) {
      this.atualizarFormControl(this.ngControl.value);
      this.ngControl.control.updateValueAndValidity();
    }
  }

  atualizarFormControl(inputValue: string) {
    // garantindo que o input eh uma string
    const inputValueString = String(inputValue);

    // Limitando o numero de caracteres
    const valorBruto = inputValueString.substring(0, this.maxInputLength);
    const valorFormatado = this.aplicarMascara(valorBruto);

    this.ngControl.reset();
    this.ngControl.control.patchValue(valorFormatado);
  }

}
