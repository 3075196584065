<table class="sg-table">
  <thead>
    <th scope="col"></th>
    <th scope="col">Citação</th>
    <th scope="col">Total</th>
    <th scope="col">Porcentagem</th>
  </thead>
  <tbody>
    <tr *ngFor="let tr of trBodies">
      <td title="Selecionar">
          <input type="checkbox" (change)="onRespostaPerguntaSelect(tr, $event)" [checked]="tr.selecionado">
      </td>
      <td title="Citação">
        {{ tr.perguntaResposta.citacao }}
      </td>
      <td title="Total">
        {{ tr.perguntaResposta.total }}
      </td>
      <td title="Porcentagem">
        {{ tr.perguntaResposta.porcentagem }}%
      </td>
    </tr>
  </tbody>
</table>
<app-loading *ngIf="isLoading"></app-loading>
