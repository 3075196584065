import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-generico',
  templateUrl: './modal-generico.component.html',
  styleUrls: ['./modal-generico.component.scss']
})
export class ModalGenericoComponent implements OnInit {

  @Input() modal: Modal;

  @Input() title: string;

  ngOnInit() { }

  closeModal() {
    this.modal.show = !this.modal.show;
  }
}

export class Modal {
  show: boolean;
}
