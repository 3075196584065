import {
  IAuditObservationsModels,
  IAuditObservationsResponse,
} from "../../interfaces";

export const adaptCollectionObservationsRespToModel = function (
  resp: IAuditObservationsResponse
): IAuditObservationsModels {
  return {
    comentarios: resp.comentarios.map((comment) => ({
      id: comment.id,
      dataHora: comment.data_hora,
      conteudo: comment.conteudo,
      auditor: { ...comment.auditor },
    })),
    justificativaRejeicao: !resp.justificativa_rejeicao
      ? null
      : {
          id: resp.justificativa_rejeicao.id,
          dataHora: resp.justificativa_rejeicao.data_hora,
          conteudo: resp.justificativa_rejeicao.conteudo,
          auditor: { ...resp.justificativa_rejeicao.auditor },
        },
  };
};
