import { Injectable } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  /**
   * @param style estilo apresentados dos dias da semana
   * @returns array com lista dos dias da semana
   */
  getDayOfWeekNames(style: "long" | "short" | "narrow") {
    return ["D", "S", "T", "Q", "Q", "S", "S"];
  }
}
