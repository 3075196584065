import { PerguntaIdentificador } from './perguntaIdentificador';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

/**
 * Representa a marcação de uma pergunta, que possui dados
 * como a pergunta destino, caso seja uma marcação de pulo.
 */
export class MarcacaoPergunta {

  /**
   * Corresponde ao identificador da marcação gerado pelo servidor.
   * Só será preenchido após sua persistência.
   */
  id?: number;
  perguntaDestino: PerguntaIdentificador;

  static getControl(): UntypedFormGroup {

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(),
      perguntaDestino: new UntypedFormControl(PerguntaIdentificador.getControl(), []),
    });

    return formGroup;
  }

  static getInitializedControl(marcacao: MarcacaoPergunta): UntypedFormGroup {

    if (!marcacao) {
      return MarcacaoPergunta.getControl();
    }

    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(marcacao.id),
// tslint:disable-next-line: max-line-length
      perguntaDestino: PerguntaIdentificador.getInitializedControl(marcacao.perguntaDestino),
    });

    return formGroup;
  }
}
