import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { UsedByRef } from "../calendar.component";

export interface CalendarInputsOutput {
  type: string;
  value: string;
}

@Component({
  selector: "app-calendar-inputs",
  templateUrl: "./calendar-inputs.component.html",
  styleUrls: ["./calendar-inputs.component.scss"],
})
export class CalendarInputsComponent {
  @Input() usedBy: UsedByRef = "PESQUISA";
  @Input()
  cylclesDays: number = 0;
  @Input()
  initialDate: number;
  @Input()
  endDate: number;

  @Input() isDateValid: boolean;

  @Output()
  output: EventEmitter<CalendarInputsOutput> = new EventEmitter();

  toMoment(value: number): string {
    return moment(value).format("YYYY-MM-DD");
  }

  getMinDate(type: string) {
    if (type === "initialDate") {
      return this.toMoment(new Date().getTime());
    }

    return this.toMoment(this.initialDate);
  }

  getMaxDate(type: string) {
    if (type === "initialDate") {
      return moment(new Date().getTime()).add(1, "years").format("YYYY-MM-DD");
    }

    return moment(this.initialDate).add(1, "years").format("YYYY-MM-DD");
  }

  handleInputChange(event: string, type: string) {
    this.output.emit({
      type: type,
      value: event,
    });
  }

}
