import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { ICanNotSaveButton } from "app/modulos/pesquisa-beta/cadastro/pesquisas-cadastro.model";
import { SelectDataItem } from "../../componentes/filterable-select/filterable-select-component";
import { ChildrenLocationOperatorOutput } from "../children-location-operator/children-location.-operator.component";
import {
  CreateOperatorInput,
  CreateOperatorOutput,
} from "../create-operator/create-operator.component";
import { ModalService } from "../modal/modal.service";

export interface LocationsData {
  value: number;
  locations: Location[];
  // enum: [localidade, vinculo]
  type: string;
}

export interface Location {
  id: number;
  label: string;
  value?: number;
  flagInput?: boolean;
  minRange?: number;
  typeOfChildrenMessage?: number;
  checked?: boolean;
  hidden?: boolean;
  split?: boolean;
  firstChanges?: boolean;
  toggleChecked?: boolean;
  operators?: CreateOperatorOutput[];
  sublocations?: SubLocation[];
  subLocationsNameWithOperators?: string[];
  openSublocation?: boolean;
  canStretch?: boolean;
  version: string;
}

export interface SubLocation {
  id: number;
  nome: string;
  versao: string;
  value?: number;
  checked?: boolean;
  toggleChecked?: boolean;
  operators?: CreateOperatorOutput[];
}

export interface SLOutput {
  progress: number;
  operators: number;
  locationsWithOp: number;
  alteredLocations: LocationsData;
}

export interface InputOptions {
  displayValue: number;
  realValue: number;
  width: number;
}

// TODO: Renomear
@Component({
  selector: "app-sublocal",
  templateUrl: "./sublocal-component.html",
  styleUrls: ["./sublocal-component.scss"],
})
export class SublocalComponent implements OnInit, OnChanges {
  @Input() data: LocationsData;
  @Input() textFilter: string;
  @Input() statusFilter: string;
  @Input() sortAZ: boolean;
  @Input() canUpdate: boolean;
  @Input() toggleRef: boolean;
  @Input() progress: number;
  @Input() operators: SelectDataItem[];

  @Output() handleToggleChange = new EventEmitter();
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> =
    new EventEmitter();

  @ViewChildren("inputSamples") inputs: QueryList<ElementRef>;

  // guarda se o checkbox do modal foi selecionado
  checkboxModalState: boolean;

  progressValue: number;
  grandsonSampleValueRef: number;
  howManyleft: number;

  // Não pode ser iniciado em zero quando o componente é renderizado pela primeira vez. Por isso foi setado
  // para undefined.
  lastInputChangedById: number = undefined;
  lastGrandsonInputChanged: number = 0;

  allChecked: boolean;
  ngInitBlock: boolean = false;

  grandsonInputs: Array<InputOptions> = [];

  filteredData: Location[];
  filteredDataRef: Location[];

  checkeds: number[] = [];

  showModal: boolean = false;
  // Inicio da largura do input é de 15 ch porque o placeholder tem 15 caracteres;
  inputOptions: InputOptions[] = [];

  selectedOperator: CreateOperatorInput = {
    index: -1,
    title: "",
    value: 0,
    operators: [],
  };

  selectedOperatorType: {
    indexes: number[];
    type: string;
  };

  @Output() changeSelectedItem: EventEmitter<SLOutput> = new EventEmitter();

  // timer para debounce do digitar do texto
  timer = null;
  amostraTimer = null;

  // adiciona valores iniciais a variavel 'data'

  constructor(private modalService: ModalService) {}

  onCanNotSaveButtonNew($event: ICanNotSaveButton): void {
    this.onCanNotSaveButton.emit($event);
  }

  addInitialValues(): Location[] {
    return this.data.locations.map((item) => ({
      ...item,
      value: item.value ? item.value : null,
      flagInput: item.flagInput ? item.flagInput : false,
      minRange: item.minRange ? item.minRange : 0,
      typeOfChildrenMessage: item.typeOfChildrenMessage
        ? item.typeOfChildrenMessage
        : 3,
      split: item.split ? item.split : false,
      hidden: item.hidden ? item.hidden : false,
      checked: item.checked ? item.checked : false,
      /**
       * Esse atributo irá auxiliar as localidades filhas que possuem netas
       * para que na primeira alteração de amostra o prompt não venha aparecer
       */
      firstChanges: item.firstChanges ? item.firstChanges : true,
      /*
      Checagem do toggle adicionará ativa quando o id for nulo (significa que está em modo de criação)
      ou quando o valor da amostra de uma localidade for diferente de nulo ou maior que zero
      */
      toggleChecked: item.value !== null || !item.id,
      operators: item.operators ? item.operators : [],
      sublocations: item.sublocations
        ? this.addSublocationsInitialValues(item.sublocations)
        : [],
      subLocationsNameWithOperators: item.subLocationsNameWithOperators
        ? this.handleMountSubLocationsNameWithOperators(item.sublocations)
        : [],
      canStretch:
        item.value === 0 || item.value === null || item.value === undefined
          ? true
          : false,
      openSublocation:
        item.openSublocation || (item.value > 0 && item.sublocations.length > 0)
          ? true
          : false,
    }));
  }

  /**
   * Função responsável por percorrer as sub-localidades para retornar o array de
   * operadores
   */
  handleMountSubLocationsNameWithOperators(
    sublocation: SubLocation[]
  ): string[] {
    const operatorStringList = sublocation.reduce((acc, location) => {
      location.operators.forEach((item) => {
        const operatorNameIndex = acc.findIndex(
          (itemAcc) => itemAcc === location.nome
        );
        if (operatorNameIndex === -1) {
          acc.push(location.nome);
        }
      });

      return acc;
    }, []);

    return operatorStringList;
  }

  // adiciona valores iniciais a sublocalidades
  addSublocationsInitialValues(data: SubLocation[]): SubLocation[] {
    return data.map((item) => ({
      ...item,
      checked: item.checked ? item.checked : false,
      toggleChecked: item.toggleChecked ? item.toggleChecked : true,
      operators: item.operators ? item.operators : [],
    }));
  }

  ngOnInit() {
    // valores iniciais
    this.inputOptions = [];
    this.filteredData = this.addInitialValues();
    this.filteredDataRef = this.addInitialValues();
    // Descomentado pois estava deixando de gerar o complemento da barra ao carregar a localidade
    // de uma pesquisada editada
    this.handleSelected();
    this.filteredData.map(({ value }: Location) => {
      value = Number(value);
      const displayValue = value ? Number(value.toFixed(2)) : null;
      let valueLength = displayValue ? `${displayValue}`.length : 0;
      valueLength = valueLength > 0 ? valueLength : 15;
      const realValue = value;
      const width = valueLength;
      this.inputOptions.push({ displayValue, realValue, width });
    });

    // Quando o componente for renderizado irá testar se a distribuição das
    // localidades netas já está em 100%.
    this.initializeGrandchildrenProgress();

    // captura o valor do checkbox do modal e repassa para os componentes filhos
    this.modalService.getCheckbox().subscribe({
      next: (currValue) => (this.checkboxModalState = currValue),
    });
  }

  // Aplica as regras de verificação de progresso quando o componente é inicializado
  // pela primeira vez
  initializeGrandchildrenProgress() {
    this.filteredData.forEach(
      ({ value, sublocations }: Location, indexFather: number) => {
        if (value && sublocations && sublocations.length) {
          this.updateProgressSampleRef(indexFather);
        }
      }
    );
  }

  /* Função para retornar um valor numério a partir da porcentagem, implementando a regra geral
  de arredondamento para valores decimais*/
  getProgressValue(itemValue: number): number {
    const result = (itemValue * 100 * this.data.value) / 10000;
    return Number(Math.round(result));
  }

  // função que retorna valor para fora do componte
  handleSelected() {
    if (this.data.type === "localidade") {
      this.changeSelectedItem.emit({
        progress: this.handleChangedOutputValue(),
        alteredLocations: {
          locations: this.filteredData,
          value: this.data.value,
          type: this.data.type,
        },
      } as SLOutput);
    } else {
      const [op, locOp] = this.getOperators();
      this.changeSelectedItem.emit({
        operators: op,
        locationsWithOp: locOp,
        alteredLocations: {
          ...this.data,
          locations: this.filteredData,
        },
      } as SLOutput);
    }

    this.filteredDataRef = this.filteredData;
  }

  // recupera total de operadores
  getOperators(): [number, number] {
    let countOperators = 0;
    let locationWithOperators = 0;
    let operatorsByLabel = [];

    for (let i = 0; i < this.filteredData.length; i++) {
      if (this.filteredData[i].operators.length > 0) {
        /*
        Loop para percorrer os operadores de cada localidade e criar um array
        com apenas o label (nome do operador)
        onde posteriormente seja retirado operadores duplicados, ex.:
        Localidade 1 => Operadores (A, B, C) => três
        Localidade 2 => Operadores (A, E, F) => três

        Após a retirada de duplicados o somatório ficará apenas 5, uma vez que
        A está em duas localidades.
        */
        for (let j = 0; j < this.filteredData[i].operators.length; j++) {
          operatorsByLabel.push(this.filteredData[i].operators[j].label);
        }

        let finalCountByTotalOfOperators = operatorsByLabel.filter(
          (value, index) => operatorsByLabel.indexOf(value) === index
        );

        countOperators = finalCountByTotalOfOperators.length;

        if (this.filteredData[i].sublocations.length > 0) {
          locationWithOperators =
            this.filteredData[i].subLocationsNameWithOperators.length ===
            this.filteredData[i].sublocations.length
              ? locationWithOperators + 1
              : locationWithOperators;
        } else {
          locationWithOperators = locationWithOperators + 1;
        }
      }

      /*
      Código comentado pois estava acumulando operadores de localidades netas
      e apenas deve ser o somatório de localidades filhas
      */
      // if (this.filteredData[i].sublocations.length > 0) {
      //   for (let index = 0; index < this.filteredData[i].sublocations.length; index++) {
      //     const element = this.filteredData[i].sublocations[index];
      //     if (element.operators.length > 0) {
      //       countOperators = countOperators + element.operators.length;
      //       locationWithOperators = locationWithOperators + 1;
      //     }
      //   }
      // }
    }
    return [countOperators, locationWithOperators];
  }

  // função que verifica a soma dos valores
  handleChangedOutputValue(): number {
    let value = 0;
    for (let i = 0; i < this.filteredData.length; i++) {
      if (this.filteredData[i].value && this.filteredData[i].toggleChecked) {
        value = value + this.getProgressValue(this.filteredData[i].value);
      }
    }
    return value;
  }

  // esconde dados não filtrados
  // função atualizada para incluir na busca localidades netas (trazendo a localidade filha)
  hideValues() {
    this.filteredData.map((location) => {
      if (
        !location.label
          .toLocaleLowerCase()
          .includes(this.textFilter.toLocaleLowerCase())
      ) {
        const hasChildren = location.sublocations.some(
          (child) =>
            child.nome.toLocaleLowerCase() ==
            this.textFilter.toLocaleLowerCase()
        );
        if (hasChildren) {
          location.hidden = false;
        } else {
          location.hidden = true;
        }
      } else {
        location.hidden = false;
      }
      return location;
    });
    // this.filteredData.filter(item => !item.label.toLocaleLowerCase().includes(this.textFilter.toLocaleLowerCase()) || item.sublocations.find(child => child.nome.includes(this.textFilter))).forEach(item => item.hidden = true);
  }

  // esconde dados não filtrados
  hideToggleValues() {
    this.filteredData.forEach((item) => (item.hidden = false));
    if (this.textFilter) {
      this.hideValues();
    }
    switch (this.statusFilter) {
      case "ativo": {
        // filtrar os que estão marcados
        this.filteredData
          .filter((item) => item.toggleChecked === false)
          .forEach((item) => (item.hidden = true));
        this.filteredData
          .filter((item) => item.toggleChecked === true)
          .forEach((item) => (item.hidden = false));
        return;
      }
      case "inativo": {
        // filtrar os que não estão marcados
        this.filteredData
          .filter((item) => item.toggleChecked === true)
          .forEach((item) => (item.hidden = true));
        this.filteredData
          .filter((item) => item.toggleChecked === false)
          .forEach((item) => (item.hidden = false));
        return;
      }
    }
  }

  // ordena dados filtrados A para Z
  oderByAZ() {
    return this.filteredData.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  }

  // ordena dados filtrados Z para A
  oderByZA() {
    return this.filteredData.sort(function (a, b) {
      if (a.label < b.label) {
        return 1;
      }
      if (a.label > b.label) {
        return -1;
      }
      return 0;
    });
  }

  // função que lida com a atualização dos toggles das localidades filhas a partir do toggle em massa
  handleUpdateFilteredData() {
    if (this.allChecked) {
      this.allChecked = !this.allChecked;
    }

    return this.filteredData.forEach((location) => {
      if (location.checked) {
        location.toggleChecked =
          location.toggleChecked === !this.toggleRef
            ? location.toggleChecked
            : !location.toggleChecked;
        location.checked = !location.checked;
        location.value = null;
      }
      this.handleSelected();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && this.filteredData) {
        switch (propName) {
          case "textFilter": {
            this.filteredData = this.filteredDataRef;
            this.filteredData.forEach((item) => (item.hidden = false));

            if (this.textFilter) {
              this.hideValues();
            }
          }
          case "sortAZ": {
            this.ngInitBlock = true;
            if (this.sortAZ) {
              this.filteredData = this.oderByAZ();
            } else {
              this.filteredData = this.oderByZA();
            }
          }
          case "statusFilter": {
            this.ngInitBlock = true;
            this.hideToggleValues();
          }
          case "data": {
            if (this.ngInitBlock) {
              return;
            }
            this.ngOnInit();
          }
          case "progress": {
            if (
              typeof this.lastInputChangedById === "number" &&
              this.filteredData.length > 0
            ) {
              this.filteredData.map((location) => (location.flagInput = false));

              clearTimeout(this.timer);

              this.timer = setTimeout(() => {
                /**
                 * Função responsável por percorrer as localidades filhas
                 * e em cada valor realizar um somatório
                 */
                const values = this.filteredData
                  .map((item) => this.getProgressValue(item.value))
                  .reduce(function (acc, at) {
                    return acc + at;
                  }, 0);
                if (values > this.data.value) {
                  const difference = values - this.data.value;
                  this.filteredData[this.lastInputChangedById].flagInput = true;
                  this.progressValue =
                    this.filteredData[this.lastInputChangedById].value -
                    difference;
                } else if (values <= this.data.value) {
                  const difference = this.data.value - this.progress;
                  this.progressValue =
                    this.data.value -
                    values +
                    this.filteredData[this.lastInputChangedById].value;
                }

                if (
                  this.filteredData[this.lastInputChangedById].value <=
                  this.data.value
                ) {
                  this.updateProgressSampleRef(this.lastInputChangedById);
                }
              }, 350);
            }
          }
          case "canUpdate": {
            if (this.canUpdate) {
              this.handleUpdateFilteredData();
            }
          }
        }
      }
    }
  }

  handleUpdateValueForSampleLocation(index: number, value: number): void {
    this.filteredData[index].value = value;
    this.lastInputChangedById = index;

    if (
      this.filteredData[index].value === undefined ||
      this.filteredData[index].value === null
    ) {
      this.filteredData[index].canStretch = true;
    } else {
      this.filteredData[index].canStretch = false;
    }

    if (
      this.filteredData[index].sublocations.length > 0 &&
      (value / 100) * this.data.value <= this.data.value
    ) {
      this.handleCanAutomaticInsertonGrandSon(index);
      this.updateProgressSampleRef(index);
    }

    if (
      !this.filteredData[index].openSublocation &&
      this.filteredData[index].value > 0
    ) {
      this.openSublocations(
        this.filteredData[index].sublocations.length,
        index,
        this.filteredData[index].toggleChecked
      );
    }

    this.handleSelected();
  }

  /* Essa função é resoponsável por calcular e returnar o valor que ainda pode ser inserido no input, sendo assim,
  a cada interação com um input o valor maximo de cada input pode ser aumentado ou reduzido entre os valores de 0 e 100*/
  getMaxRangeInput() {
    const maxRange =
      this.progress <= this.data.value
        ? 100 - (this.progress * 100) / this.data.value
        : 0;
    return maxRange;
  }

  /*Ao ser chamada essa função ajusta os dados do popup para o tipo alert e em seguida o exibe.
  os valores dinâmicos correspondem a o valor informado pelo usuário no input e o valor a ser
  arredondado pelo sistema, naturalmente o popup só é chamado se for necessário haver arredondamento.
  OBS: a verificação ocorre dentro da função porque "checkboxModalState" é uma variável que é
  recebida via parâmetro pelo componente, alem disso a função "openAlertPopup(args)" é enviada
  por parâmetro para o componente <<children-location>> */
  openAlertPopup(data: {
    oldValue: number;
    actualValue: number;
    numericOld: number;
    numericAtual: number;
  }): void {
    const { oldValue, actualValue, numericAtual, numericOld } = data;
    if (!this.checkboxModalState) {
      this.modalService.showModal({
        title: "Arredondamento de amostra",
        messageModal: `Arredondamos sua amostra de: <b>${oldValue}% - ${numericOld}</b>
          para <b>${actualValue}% - ${numericAtual}</b>, pois, como se sabe, não é possível ter uma
          quantidade de coletas com casas decimais (ex.: 16,3). Dessa forma, caso você queira manipular
          melhor o arredondamento das coletas, basta alterar o valor percentual.`,
        btnTitlePositive: "Continuar",
        btnTitleNegative: "Cancelar",
        imgFile: "icons/icon-warning.svg",
        checkbox: true,
      });
    }
  }

  // Essa função calcula e retorna o comprimento do valor do input
  getInputValueLength(value: number): number {
    if (!value) return 15;
    return Number(`${value}`.length === 0 ? 15 : `${value}`.length);
  }

  /**
   * Dispara no blur e valida o valor percentual e a distribuição por operadores
   * @param value valor a ser tratado pelo algoritimo de arrendondamento
   * @param index posição da localidade no array
   */
  onBlur(value: number, index: number) {
    this.handleRoundValue(value, index);
    this.updateOperatorValueOnChange(index);
  }

  /**
   * Valida se houve alteração na distribuição de coletas por operador, e zera a distribuição em caso positivo
   * @param index posição da localidade no array
   */
  updateOperatorValueOnChange(index: number) {
    const oldValue = this.data.locations[index].value;
    const currentValue = this.filteredData[index].value;
    if (currentValue !== oldValue) {
      this.filteredData[index].operators = [
        ...this.filteredData[index].operators.map((operator) => {
          const newOperator = {
            ...operator,
            value: 0,
          };
          return newOperator;
        }),
      ];
    }
  }

  /* Ao ritirar o foco de um input essa função é chamada e trata de verificar se é preciso fazer um arredondamento com base no calculo
  de conversão de amostra percentual para amostra numérica, caso haja resto nesse calculo a função tratará de arredondar para que haja
  consistência entre a amostra numérica e percentual, assim tanto a amostra numérica é arredondada quanto a amostra percentual. A seguir
  o popup é chamado, em seguida o tamanho do elemento input do html é recalculado e salvo no array "inputOptions" posição "index" */

  handleRoundValue(value: number, index: number): void {
    const percentProgress = (this.progress * 100) / this.data.value;

    // essa função calcula e retorna o resto do valor do input convertido para amostra numérica.
    const haveRest = () => {
      const numericSample = ((value / 100) * this.data.value).toPrecision(15);
      return parseFloat(numericSample) % 1;
    };

    // essa condicional verifica se há resto, caso haja ela realiza o arredondamento por aproximação, (descrito no notion),
    if (haveRest()) {
      const oldValue = value;

      // valor percentual arredondado
      const percentValueRounded =
        (this.getProgressValue(value) * 100) / this.data.value;

      // valor arredondado abaixo ou igual ao limite disponível
      const howManyLeft = Math.abs(percentProgress - 100 - percentValueRounded);
      value =
        this.progress > this.data.value
          ? (this.getProgressValue(howManyLeft) / this.data.value) * 100
          : (this.getProgressValue(value) / this.data.value) * 100;

      this.openAlertPopup({
        oldValue,
        actualValue: value,
        numericOld: (oldValue * 100 * this.data.value) / 10000,
        numericAtual: this.getProgressValue(value),
      });
    } else {
      value =
        this.progress > this.data.value
          ? Math.abs(percentProgress - 100 - value)
          : value;
    }
    this.handleUpdateValueForSampleLocation(index, value);

    this.inputOptions[index].realValue = Number(value);
    this.inputOptions[index].displayValue = this.inputOptions[index].realValue
      ? Number(this.inputOptions[index].realValue.toFixed(2))
      : null;
    this.inputOptions[index].width = this.getInputValueLength(
      this.inputOptions[index].displayValue
    );
  }
  // realiza mudança do valor do input
  handleChange(value: any, index: number) {
    this.inputOptions[index].width = this.getInputValueLength(
      value.target.value
    );
    // Foi convertido para numero
    this.inputOptions[index].realValue = Number(value.target.value);
    if (
      this.filteredData[index].value &&
      this.filteredData[index].firstChanges &&
      !this.filteredData[index].flagInput &&
      this.filteredData[index].sublocations.length > 0 &&
      value.target.value <= this.progressValue
    ) {
      this.modalService.showModal({
        title: "Edição de amostra",
        messageModal:
          "Você está redefinindo a amostra, com isso você perderá a distribuição já realizada e o sistema irá redistribuir de forma automática a amostra definida",
        btnTitlePositive: "Redefinir",
        btnTitleNegative: "Cancelar",
        imgFile: "icons/icon-warning.svg",
      });
    } else {
      if (!this.filteredData[index].firstChanges) {
        this.filteredData[index].firstChanges = true;
      }
    }
    this.handleUpdateValueForSampleLocation(
      index,
      value.target.value ? Number(value.target.value) : value.target.value
    );
  }

  // marca todos checkboxes
  handleAllCheck() {
    this.allChecked = !this.allChecked;
    if (this.allChecked) {
      this.filteredData
        .filter((item) => item.hidden === false)
        .forEach((item) => (item.checked = true));
      this.handleUpdateMainToggle();
      return;
    }
    this.filteredData.forEach((item) => (item.checked = false));
  }

  // função que lida com marcação do checkbox
  handleCheckClick(index: number) {
    this.allChecked = false;
    this.filteredData[index].checked = !this.filteredData[index].checked;
    // caso desmarque checkbox
    // if (!this.filteredData[index].checked) {
    //   this.filteredData[index].value = null;
    // }
    // caso haja filhas
    if (this.filteredData[index].sublocations.length > 0) {
      this.filteredData[index].sublocations.map((sub) => {
        sub.checked = this.filteredData[index].checked;
        // if (!this.filteredData[index].checked) {
        //   sub.value = null;
        // }
        return sub;
      });
    }
    this.handleSelected();
    this.handleUpdateMainToggle();
  }

  // função que lida com a manipulação do toggle principal quando um checkbox é ativado em uma localidade filha
  handleUpdateMainToggle() {
    // coletando array de localiades que estão checadas
    const selectedLocations = this.filteredData.filter(
      (location) => location.checked
    );

    // distribuindo as localidades pelo toggle ativo ou inativo
    const toggledLocations = selectedLocations.filter(
      (location) => location.toggleChecked
    );
    const notToggledLocations = selectedLocations.filter(
      (location) => !location.toggleChecked
    );

    // coletando apenas o tamanho dos arrays
    const toggledLocationsLength = toggledLocations.length;
    const notToggledLocationsLength = notToggledLocations.length;

    if (toggledLocationsLength === 0 && notToggledLocationsLength === 0) {
      this.handleToggleChange.emit(true);
    } else if (toggledLocationsLength > notToggledLocationsLength) {
      this.handleToggleChange.emit(false);
    } else if (notToggledLocationsLength > toggledLocationsLength) {
      this.handleToggleChange.emit(true);
    }
  }

  // função que verifica se o checkbox está marcado
  isChecked(index: number): boolean {
    const grandsonLength = this.filteredData[index].sublocations.length;
    const allGrandsonIsChecked = this.filteredData[index].sublocations.filter(
      (sub) => sub.checked
    );
    if (this.filteredData[index].sublocations.length > 0) {
      return this.filteredData[index].checked ||
        allGrandsonIsChecked.length === grandsonLength
        ? true
        : false;
    } else {
      return this.filteredData[index].checked ? true : false;
    }
  }

  // função que valida se um toggle está "checado"
  isToggleChecked(index: number): boolean {
    if (this.filteredData[index].value) {
      this.inputOptions[index].displayValue = this.filteredData[index]
        .toggleChecked
        ? Number(this.filteredData[index].value.toFixed(2))
        : null;
    }
    return this.filteredData[index].toggleChecked ? true : false;
  }

  // função para atualizar o check da localidade neta na filha
  updateGrandSonCheck(data: { fatherId: number; subLocationId: number }) {
    this.filteredData.map((item, index) => {
      if (index === data.fatherId) {
        item.sublocations.map((sub) => {
          if (sub.id === data.subLocationId) {
            sub.checked = !sub.checked;
          }
          return sub;
        });
      }
      return item;
    });

    // Fazendo uma nova verificação para identificar se todas as netas estão checked
    const grandsonLength = this.filteredData[data.fatherId].sublocations.length;
    const allGrandsonIsChecked = this.filteredData[
      data.fatherId
    ].sublocations.filter((sub) => sub.checked);

    if (allGrandsonIsChecked.length === grandsonLength) {
      this.filteredData[data.fatherId].checked = true;
    } else {
      this.filteredData[data.fatherId].checked = false;
    }
  }

  // Seta o array de inputs de valores percentuais reais e de display
  // que é emitido pelo componente de localidades netas.
  handleUpdateGrandsonInputs($event) {
    this.grandsonInputs.length = 0;
    this.grandsonInputs.push(...$event);
  }

  // função que lida com a inserção automática de valores nos inputs de localidades netas
  // quando as localidades netas estão todas selecionadas
  /**
   * a função irá percorrer todas as localidades filhas e quando encontrar
   * a que tem id do input que está sendo alterada, irá fazer algumas validações
   * identificar o valor da filha e o comprimento das localidades neta (quantidade total)
   * e caso o hasAllCheck pegue todas as localidades netas checadas ele irá ativar uma flag
   * que indicará que o usuário pretende utilizar o preenchimento automático das netas
   * nesse momento será feito um calculo, pegando o valor da filha e dividindo pela quantidade de netas
   * onde o primeiro item recebe + 1 para fechar o valor total
   * caso seja falso (ou seja, um das localidades netas não foi checada), irá habilitar os inputs para edição
   */
  handleCanAutomaticInsertonGrandSon(indexFather: number) {
    this.filteredData.map((location, index) => {
      if (index === indexFather) {
        const valueOfFather = this.filteredData[indexFather].value;
        const hasAllCheck = location.sublocations.filter(
          (item) => item.toggleChecked
        );
        const firstCheckedIndex = location.sublocations.indexOf(hasAllCheck[0]);

        const sublocationsLenght = hasAllCheck.length;

        if (sublocationsLenght) {
          location.split = true;

          /**
           * 0 - Preenchido automaticamente
           * 1 - Restam x amostras para serem distribuidas
           * 2 - Distribuição completa
           * 3 - Default
           * 5 - Exceção
           */
          this.filteredData[indexFather].typeOfChildrenMessage = 0;

          const numericValueFather = Number(
            ((valueOfFather * this.data.value) / 100).toPrecision(15)
          );
          /* Base de distribuição, o valor do input é convertido para valor numérico correnpondente a porcentagem
          em seguida dividido pela quantidades de sublocalidades da localidade que corresponde ao input*/
          const valueByGrandson = Math.floor(
            numericValueFather / sublocationsLenght
          );

          location.minRange = sublocationsLenght;
          /* Distrinuição do valor calculado anteriormente, para cada sublocalidades, considerando ao valor da amostra numérica
          ese valor é distrinuído igualmente para cada sublocalidades em valor percentual. */
          location.sublocations.forEach((sub) => {
            // Só queremos recalcular as localidades netas que estão em toggledChecked true.
            if (location.value && sub.toggleChecked) {
              sub.value = (valueByGrandson / numericValueFather) * 100;
            } else {
              sub.value = 0;
            }
          });

          // Segundo a regra definida em reunião, o valor do resto é integrado ao valor do primeiro input em valor percentual
          let valueToIncrease = numericValueFather % sublocationsLenght;
          const toIncrese = (valueToIncrease / numericValueFather) * 100;
          const increasedSubValue =
            location.sublocations[firstCheckedIndex].value + toIncrese || 0;
          location.sublocations[firstCheckedIndex].value = increasedSubValue;

          // Aqui manipulamos o array de inputs das localidades netas para que
          // os valores percentuais sejam renderizados em tela.
          if (this.grandsonInputs.length) {
            location.sublocations.forEach(
              ({ value, toggleChecked }, subIndex) => {
                const v = (toggleChecked && Number(value)) || 0;

                this.grandsonInputs[subIndex].realValue = v;
                this.grandsonInputs[subIndex].displayValue =
                  Number(v.toFixed(2)) || null;
                this.grandsonInputs[subIndex].width = this.getInputValueLength(
                  this.grandsonInputs[subIndex].displayValue
                );
              }
            );
          }

          /*for (let i = 0; i < sublocationsLenght; i++) {
            if(valueToIncrease>0){

              valueToIncrease = valueToIncrease - 1;
            }
          }*/
        } else {
          location.split = false;
          this.filteredData[indexFather].typeOfChildrenMessage = 1;

          location.sublocations.map((sub) => {
            if (!sub.toggleChecked) {
              sub.value = 0;
            }
            return sub;
          });
        }
      }
      return location;
    });
  }

  // função que lida com marcação do checkbox
  handleToggleCheckClick(index: number, event: boolean) {
    this.inputOptions[index].width = 15;
    this.filteredData[index].toggleChecked = event;
    if (!event) {
      this.filteredData[index].openSublocation = false;
      // this.filteredData[index].checked = false;
      this.inputOptions[index].displayValue = null;
      this.inputOptions[index].realValue = 0;
      this.filteredData[index].value = 0;

      this.filteredData[index].sublocations.map((sub) => {
        sub.checked = false;
        sub.value = null;
        sub.toggleChecked = true;
        return sub;
      });
    }
    this.handleSelected();
    this.handleUpdateMainToggle();
    this.hideToggleValues();
    this.filteredData[index].typeOfChildrenMessage = 3;

    if (
      this.filteredData[index].checked &&
      !this.filteredData[index].toggleChecked
    ) {
      this.filteredData[index].checked = false;
    }
  }

  // função que lida com ver modal
  handleShowModal(item: Location, index: number) {
    window.scrollTo(0, 0);
    const locationValue = this.getProgressValue(
      Number(this.filteredData[index].value.toPrecision(15))
    );
    this.selectedOperatorType = {
      type: "children",
      indexes: [],
    };
    if (
      this.selectedOperator.title === item.label ||
      this.selectedOperator.title === ""
    ) {
      this.showModal = !this.showModal;
    } else {
      this.showModal = true;
    }
    this.selectedOperator = {
      index: index,
      title: item.label,
      value: locationValue,
      operators: item.operators
        ? item.operators.map((v) => {
            return {
              label: v.label,
              value: v.value,
              item: {
                index: null,
                item: {
                  label: v.label,
                  value: v.id,
                },
              },
            };
          })
        : null,
    };
  }

  // Função que lida com ver modal das localidades netas
  handleShowModalGrandchild(
    event: ChildrenLocationOperatorOutput,
    locationIndex: number
  ) {
    const locationValue =
      (this.filteredData[locationIndex].value * this.data.value) / 100;
    const sublocationValue = Math.floor(
      (Number(event.sublocation.value.toPrecision(15)) * locationValue) / 100
    );
    this.selectedOperatorType = {
      type: "grandChildren",
      indexes: [locationIndex],
    };
    if (
      this.selectedOperator.title === event.sublocation.nome ||
      this.selectedOperator.title === ""
    ) {
      this.showModal = !this.showModal;
    } else {
      this.showModal = true;
    }
    this.selectedOperator = {
      index: event.index,
      title: event.sublocation.nome,
      value: sublocationValue,
      operators: event.sublocation.operators
        ? event.sublocation.operators.map((v) => {
            return {
              label: v.label,
              value: v.value,
              item: {
                index: null,
                item: {
                  label: v.label,
                  value: v.id,
                },
              },
            };
          })
        : null,
    };
  }

  closeModal = (): void => {
    this.showModal = false;
  };

  calculateOperatorDistribuition(
    operatorsCount: number,
    value: number
  ): number {
    let result: number;
    result = value / operatorsCount;
    if (!Number.isInteger(result)) {
    }
    return Math.round(result);
  }

  // função que lida com mudanças ao criar operador
  handleOperatorChange(event: any) {
    if (this.selectedOperatorType.type !== "grandChildren") {
      // atribui a todas localidades netas o operador selecionado
      if (
        this.filteredData[this.selectedOperator.index].sublocations.length > 0
      ) {
        this.filteredData[this.selectedOperator.index].sublocations.map(
          (t) => (t.operators = event)
        );
        for (
          let i = 0;
          i <
          this.filteredData[this.selectedOperator.index].sublocations.length;
          i++
        ) {
          const element =
            this.filteredData[this.selectedOperator.index].sublocations[i];
          // zera operadores TODO: Zerar operadores?
          element.operators = [];
          if (element.value && element.value > 0) {
            // quantidade de operadores que faltam ser inseridos na localidade neta
            let operatorsNumber = event.length;
            // quantidade de amostras restantes
            let samplesRest = element.value;
            for (let index = 0; index < event.length; index++) {
              // verifica se o restante das amostras é maior que 0
              if (samplesRest > 0) {
                const result = Math.ceil(samplesRest / operatorsNumber);
                element.operators.push({
                  id: event[index].id,
                  label: event[index].label,
                  value: result,
                });
                operatorsNumber--;
                samplesRest -= result;
              }
            }
          }
        }
      }
      this.filteredData[this.selectedOperator.index].operators = event;
    } else {
      this.filteredData[this.selectedOperatorType.indexes[0]].sublocations[
        this.selectedOperator.index
      ].operators = event;

      /**
       * TODO: Enviar a localidade filha todos os operadores - sem duplicidade
       * de todos os operadores nas localidades netas
       */
      const operadoresToLocalidadeFilha = this.filteredData[
        this.selectedOperatorType.indexes[0]
      ].sublocations.reduce((acc, operador, i) => {
        operador.operators.forEach((item, forEachIndex) => {
          const index = acc.findIndex(
            (itemIndex) => itemIndex.id === operador.operators[forEachIndex].id
          );

          if (index !== -1) {
            acc[index] = {
              ...acc[index],
              value: acc[index].value + operador.operators[forEachIndex].value,
            };
          } else {
            acc = [...acc, operador.operators[forEachIndex]];
          }
        });

        return acc;
      }, []);
      this.filteredData[this.selectedOperatorType.indexes[0]].operators =
        operadoresToLocalidadeFilha;

      const locationNameIndex = this.filteredData[
        this.selectedOperatorType.indexes[0]
      ].subLocationsNameWithOperators.findIndex(
        (item) =>
          item ===
          this.filteredData[this.selectedOperatorType.indexes[0]].sublocations[
            this.selectedOperator.index
          ].nome
      );

      if (locationNameIndex === -1) {
        this.filteredData[
          this.selectedOperatorType.indexes[0]
        ].subLocationsNameWithOperators.push(
          this.filteredData[this.selectedOperatorType.indexes[0]].sublocations[
            this.selectedOperator.index
          ].nome
        );
      }
    }

    this.handleSelected();
  }

  // função que lida com abrir sub localidades
  openSublocations(value: number, index: number, locationToggle: boolean) {
    // const v = this.filteredData[index].sublocations.find(item => item.value > 0)
    if (value > 0 && locationToggle) {
      this.filteredData[index].openSublocation =
        !this.filteredData[index].openSublocation;
    }
  }

  // função que lida com marcação do checkbox da sublocalidade
  handleSubCheckClick(index: number, subIndex: number) {
    this.filteredData[index].sublocations[subIndex].checked =
      !this.filteredData[index].sublocations[subIndex].checked;
  }

  // realiza mudança do valor do input da sublocalidade
  handleSubChange(value: number, index: number, subIndex: number) {
    this.filteredData[index].sublocations[subIndex].value = value;
  }

  // função que lida com marcação do checkbox da sublocalidade
  handleSubToggleCheckClick(index: number, event: boolean, subIndex: number) {
    if (!event) {
      this.filteredData[index].sublocations[subIndex].checked = event;
      this.filteredData[index].sublocations[subIndex].value = null;
    }
    this.filteredData[index].sublocations[subIndex].toggleChecked = event;

    this.updateProgressSampleRef(index);
  }

  // função que lida com a atualização do samplePiece para servir como referência
  // para as localidades netas
  handleUpdateSample(data: {
    value: number;
    indexFather: number;
    indexGrandSon: number;
  }) {
    this.filteredData[data.indexFather].sublocations.map((item) => {
      if (item.id === data.indexGrandSon) {
        item.value = data.value;
      }
      return item;
    });

    this.lastGrandsonInputChanged = data.indexGrandSon;

    this.updateProgressSampleRef(data.indexFather);
  }

  // função que lida com a atualização por localidade filha via id para o max range do input das localidades netas
  updateProgressSampleRef(indexFather: number) {
    /**
     * Função responsável por percorrer as localidades filhas
     * e em cada valor realizar um somatório
     */
    const values = Number(
      this.filteredData[indexFather].sublocations
        .map(({ value }) => Number(value))
        .reduce((acc, at) => acc + at, 0)
        .toPrecision(15)
    );

    // Condicional para determinar quando o valor ultrapassa o somatório do
    // total de coletas de uma localidade filha
    if (values > 100) {
      if (this.lastGrandsonInputChanged != 0) {
        const diference = values - 100;
        const sublocationInfo = this.filteredData[
          indexFather
        ].sublocations.find((sub) => sub.id === this.lastGrandsonInputChanged);

        this.grandsonSampleValueRef = sublocationInfo.value - diference;

        this.howManyleft = 100 - values;

        this.filteredData[indexFather].typeOfChildrenMessage = 5;
      }
    } else if (values <= 100) {
      this.grandsonSampleValueRef = 100;
      this.howManyleft = 100 - values;
      this.filteredData[indexFather].typeOfChildrenMessage = 1;
    } else if (
      this.filteredData[indexFather].value === 0 ||
      this.filteredData[indexFather].value === null
    ) {
      this.filteredData[indexFather].typeOfChildrenMessage === 3;
    }

    if (this.howManyleft === 0 && values !== 0) {
      this.filteredData[indexFather].typeOfChildrenMessage = 2;
    } else {
      this.filteredData[indexFather].typeOfChildrenMessage = 1;
    }

    if (this.howManyleft < 0) {
      this.filteredData[indexFather].typeOfChildrenMessage = 5;
      this.howManyleft = 100;
    }
  }

  // função que lida com a verificação se uma localidade filha tem netas com check ativo
  isLocationGrandSonToggled(sub: SubLocation[]): boolean {
    const hasOrNot = sub.some((sub) => sub.toggleChecked && sub.value > 0);

    return hasOrNot ? true : false;
  }

  // função que lida com a atualização do toggle da localidade neta
  handleGrandSonToggleChecked(data: {
    fatherId: number;
    subIndex: number;
    event: boolean;
  }) {
    this.filteredData[data.fatherId].sublocations.map((sub) => {
      if (sub.id === data.subIndex) {
        sub.toggleChecked = !data.event;
        sub.value = null;
      }
      return sub;
    });

    this.updateProgressSampleRef(data.fatherId);
  }
}
