import {  } from '@angular/core';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { ISwitchStatus } from '../../interfaces/list';

@Component({
  selector: 'app-kebab-cliente',
  templateUrl: './kebab-cliente.component.html',
  styleUrls: ['./kebab-cliente.component.scss']
})
export class KebabClienteComponent implements OnInit {
  @ViewChild("kebab", { static: true }) kebab;

  @Input() clientId: number;
  @Input() situacao = false;
  
  @Output() switchSituacao = new EventEmitter<ISwitchStatus>();
  @Output() deleteClient = new EventEmitter<number>();
  @Output() viewClient = new EventEmitter<number>();
  
  isOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleStatus() {
    this.switchSituacao.emit({idClient: this.clientId, status: !this.situacao})
  }

  removeClient() {
    this.deleteClient.emit(this.clientId);
  }

  editClient() {
    this.viewClient.emit(this.clientId)
  }

  toggleMeatBall() {
    this.isOpen = !this.isOpen
  }
  
  /**
   * Escuta evento de click e fecha select quando usuário clica fora da área dele.
   * @param event Evento de click
  */
 @HostListener("document:click", ["$event"])
 documentClick(event: Event) {
   if (!this.kebab.nativeElement.contains(event.target)) {
     if (this.isOpen) {
        this.toggleMeatBall()
      }
    }
  }
}
