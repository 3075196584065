<div [formGroup]="alternativasSelecionadasFormGroup">
  <div formArrayName="alternativasSelecionadas">

    <div *ngFor="let alternativa of alternativasSelecionadas.controls">

      <div [formGroup]="alternativa" class="sg-input-group">

        <div class="sg-checkbox-order-full">

          <input type="checkbox" [formControl]="alternativa.get('selecionada')"
            (change)="onAlternativaChange($event, alternativa.value.hash)" [id]="alternativa.value.hash" />

          <label [for]="alternativa.value.hash"
            *ngIf="alternativa.controls.esquema.value.tipoAlternativa === 'FECHADA' || alternativa.controls.esquema.value.tipoAlternativa === 'ABERTA_TEXTO' || alternativa.controls.esquema.value.tipoAlternativa === 'ABERTA_NUMERO'">
            <span>{{ alternativa.controls.esquema.value.descricao }}</span>
            <span *ngIf="alternativa.controls.ordem.value">{{ alternativa.controls.ordem.value }}</span>
          </label>

        </div>

        <div class="campo">
          <input *ngIf="alternativa.controls.esquema.value.tipoAlternativa === 'ABERTA_TEXTO'" type="text"
            placeholder="Insira a resposta aberta" [formControl]="alternativa.get('respostaAberta')">

          <input *ngIf="alternativa.controls.esquema.value.tipoAlternativa === 'ABERTA_NUMERO'" type="number"
            placeholder="Insira a resposta aberta" [formControl]="alternativa.get('respostaAberta')">

          <span class="error-message" *ngIf="alternativa.errors?.respostaAbertaVazia">
            Uma alternativa do tipo resposta aberta está marcada. Você deve preencher o campo textual.
          </span>
        </div>

        <!-- <input type="checkbox" [formControl]="alternativa.get('selecionada')"
          (change)="onAlternativaChange($event, alternativa.value.hash)" [id]="alternativa.value.hash" />

        <span *ngIf="alternativa.controls.ordem.value">
          {{ alternativa.controls.ordem.value }}
        </span>

        <label [for]="alternativa.value.hash" *ngIf="alternativa.controls.esquema.value.tipoAlternativa === 'FECHADA'">
          {{ alternativa.controls.esquema.value.descricao }}
        </label>

        <label [for]="alternativa.value.hash" *ngIf="alternativa.controls.esquema.value.tipoAlternativa === 'ABERTA_TEXTO'">
          {{ alternativa.controls.esquema.value.descricao }}
        </label>

        <span *ngIf="alternativa.controls.esquema.value.tipoAlternativa === 'ABERTA_TEXTO'">
          <input type="text" placeholder="Insira a resposta aberta" [formControl]="alternativa.get('respostaAberta')" />
        </span> -->

      </div>
    </div>

    <span class="error-message"
      *ngIf="alternativasSelecionadasFormGroup.get('selected').errors?.maiorQueDeveria || alternativasSelecionadasFormGroup.get('selected').errors?.menorQueDeveria">
      Você deve selecionar entre {{ pergunta.qtdMinimaSelecionada }} e {{ pergunta.qtdMaximaSelecionada }} alternativas.
    </span>

  </div>
</div>