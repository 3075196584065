<div class="pesquisa-questionario-secao-cadastro-wrapper">
  <header>
    <div class="page-label">
      <i class="fas fa-clipboard-list"></i>
      <h3>Questionário - Nova Seção</h3>
    </div>
  </header>
  <h4 class="titulo-pesquisa-label">Título da avaliação</h4>
  <h3 class="titulo-pesquisa">{{surveyTitle}}</h3>
  <form class="form-container" #form="ngForm" [formGroup]="formGroup" (ngSubmit)="handleSave()">
    <div class="inputs-container">
      <div class="field">
        <div class="title" style="display: flex;">
          <label for="name">Nome da seção*</label>
        </div>
        <textarea name="name" formControlName="name" maxlength="161" id="name" placeholder="Informe o nome da seção"
          (input)="handleInputChange($event, 'name')" [ngClass]="
        (form.dirty && formControls.name.errors?.maxlength)
        ||
        (form.dirty && formControls.name.errors?.required)
        ? 'input-error' : ''
        "></textarea>
        <span class="error-message" [hidden]="!(form.dirty && formControls.name.errors?.maxlength)">
          Máximo de 160 caracteres
        </span>
        <span class="error-message" [hidden]="!(form.dirty && formControls.name.errors?.required)">
          Campo obrigatório
        </span>
      </div>
      <div class="field">
        <div class="title" style="display: flex;">
          <label for="description">Descrição da seção</label>
        </div>
        <textarea type="text" maxlength="161" Description="description" formControlName="description" id="description"
          placeholder="Crie uma descrição para a seção" (input)="handleInputChange($event, 'description')"
          [ngClass]="(form.dirty && formControls.description.errors?.maxlength) ? 'input-error' : ''"></textarea>
        <span class="error-message" [hidden]="!(form.dirty && formControls.description.errors?.maxlength)">
          Máximo de 160 caracteres
        </span>
      </div>
    </div>
    <div class="buttons-container">
      <button class="cancelar" type="button" (click)="handleCancel()" [disabled]="isBusy">
        Cancelar
      </button>
      <button class="salvar" type="button" (click)="handleSave()" [disabled]="isBusy || !form.valid">
        Salvar
      </button>
    </div>
  </form>
</div>
