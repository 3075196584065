import { AbstractAction } from '../../actions/abstractAction';
import { PassoCadastro } from '../../passoCadastro';
import { ActionType } from '../../actionType';

/**
 * Action utilizada para transferir cotas entre perfis
 * de um mesmo operador
 */
export class AjusteCotaAction extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERENCIADOR_COTAS;
  readonly type = ActionType.AJUSTAR_COTA;
  payload: SolicitacaoAjusteCota;

  constructor(payload: SolicitacaoAjusteCota) {
    super();
    this.payload = payload;
    this.isReplayable = true;
  }
}

export class SolicitacaoAjusteCota {

  vinculoOperador: number;
  quantidadeTransferida: number;
  idPerfilOrigem: number;
  idPerfilDestino: number;

  // tslint:disable-next-line: max-line-length
  constructor(vinculoOperador: number, quantidadeTransferida: number, idPerfilOrigem: number, idPerfilDestino: number) {
    this.vinculoOperador = vinculoOperador;
    this.quantidadeTransferida = quantidadeTransferida;
    this.idPerfilOrigem = idPerfilOrigem;
    this.idPerfilDestino = idPerfilDestino;
  }
}
