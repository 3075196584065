<header>
  <p> {{questionData.ordem}} - {{ questionData.enunciado }}</p>
  <div class="badges">
    <small *ngIf="questionData.obrigatoria" [ngClass]="{ error: !selectedDate }"
      >Pergunta obrigatória</small
    >
  </div>
</header>
<section>
  <div class="date-picker">
    <label for="">Insira uma data</label>
    <div [ngClass]="{ 'opened-calendar': picker.opened }" class="input-action">
      <i class="fa-regular fa-calendar"></i>
      <input
        mascara-data
        matInput
        [matDatepicker]="picker"
        placeholder="dd/mm/aaaa"
        [(ngModel)]="selectedDate"
        (change)="onChangeDate($event)"
      />
      <i class="fa-solid fa-chevron-down" (click)="picker.open()"></i>
      <mat-datepicker #picker [calendarHeaderComponent]="header">
        <mat-datepicker-actions>
          <button (click)="handleClear()" class="clean-btn" matDatepickerCancel>
            Limpar
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </div>
  </div>
</section>
<app-questionnaire-answer-reset
  *ngIf="hasChanged"
  (reset)="resetAnswer()"
></app-questionnaire-answer-reset>
