<div class="radio-select-container" [class.active]="showBody">
  <label>{{ label }}</label>

  <div
    #base
    class="radio-select-content {{ kind }}"
    [ngClass]="disabled ? 'disabled' : ''"
    [style.height]="showBody ? 'auto' : '48px'"
  >
    <div class="radio-select {{ kind }}" (click)="handleShowBody()">
      <div class="radio-select-placeholder">
        <div>
          <span
            *ngIf="!selected || !selected.label"
            class="selector-header-text"
            >{{ placeholder }}</span
          >
          <span
            *ngIf="!!selected && usedBy === 'CHEVRON'"
            class="selector-header-text"
            [ngClass]="selected.label && 'input-selected'"
          >
            {{ selected.label }}
          </span>

          <span
            *ngIf="!!selected && usedBy === 'TOOLTIP'"
            class="selector-header-text"
            [ngClass]="selected.label && 'input-selected'"
          >
            {{ selected.label }} min
          </span>
        </div>
        <div class="selector-header-content-icons">
          <span [class]="!showBody ? 'chevron' : 'chevron-rotate'"></span>

          <div
            *ngIf="usedBy === 'TOOLTIP' && !isLoadingOptions"
            class="tooltip icon-gray"
          >
            <i class="fa-regular fa-circle-info interview-time-helper-icon"></i>

            <span
              class="tooltiptext"
              [ngClass]="kind === 'input-light' && 'canSee'"
            >
              Referente ao tempo mínimo em que uma entrevista deve ser executada
              em minutos. Esse parâmetro é utilizado no processo de auditoria
            </span>
          </div>

          <div
            *ngIf="usedBy === 'TOOLTIP' && isLoadingOptions"
            class="loader"
          ></div>
        </div>
      </div>
    </div>
    <div class="options-content" *ngIf="showBody">
      <div class="devider"></div>
      <div class="selector-body-options">
        <div *ngFor="let item of options">
          <div
            class="option"
            [ngClass]="
              selected && item.value === selected.value && 'option-active'
            "
            (click)="handleSelectItem(item)"
          >
            <span *ngIf="usedBy === 'TOOLTIP'"> {{ item.label }} min </span>
            <span *ngIf="usedBy === 'CHEVRON'">
              {{ item.label }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
