<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <img class="funcionario-icon" src="assets/icons/funcionarios-icon.svg">
      <h2 [routerLink]="'/funcionario'">Funcionários</h2>
      <span>/</span>
      <h4> {{ tituloFormulario }} </h4>
    </div>
    <!-- <div class="action-header">
      <button class="sg-btn sg-secondary" disabled title="listar todos os funcionários">ADICIONAR
        NOVO</button>
    </div> -->
  </div>
  <form class="content-funcionarios-adicionar" #form="ngForm" [formGroup]="formGroup"
    (ngSubmit)="showNotificationError();form.valid && salvar(form.value)">
    <div class="row form sg-input-group section">
      <div class="col-12 sub-title">
        <h3>Informações básicas</h3>
      </div>
      <!-- IMAGEM -->
      <div class="mt-3 mb-5 mr-5 ml-5">
        <app-new-foto-uploader></app-new-foto-uploader>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- MATRICULA -->
            <div class="campo">
              <label for="">Matrícula</label>
              <input type="text" formControlName="matricula">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- CPF -->
            <div class="campo" formGroupName="pessoa">
              <label for="">CPF *</label>
              <input placeholder="Ex:.111.222.333-44" title="cpf do funcionário" type="text" mascara-cpf
                formControlName="cpf">
              <span class="error-message" [hidden]="!(form.submitted && formControls.cpf.errors)">
                CPF inválido
              </span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- NOME -->
            <div class="campo" formGroupName="pessoa">
              <label for="">Nome completo *</label>
              <input mascara-primeiro-digito-sem-espaco mask-only-letters placeholder="Ex:.hernani soares da silva"
                title="nome do funcionário" id="nome" formControlName="nome" type="text" maxlength="50">
              <span class="error-message" [hidden]="!(form.submitted && formControls.nome.errors)">
                Insira o nome</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- CARGO -->
            <div class="campo">
              <label for="">Cargo *</label>
              <input placeholder="Ex.:analista" title="cargo do funcionário" mascara-primeiro-digito-sem-espaco
                [mascara-limite-caracteres]="50" type="text" formControlName="cargo" />
              <span class="error-message" [hidden]="!(form.submitted && formControls.cargo.errors)">
                Insira o cargo
              </span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <!-- EMAIL -->
            <div class="campo" formGroupName="contato">
              <label for="">E-mail *</label>
              <input id="email" formControlName="email" type="email" placeholder="Ex:.maria@email.com"
                title="email do funcionário" maxlength="50" (ngModelChange)="onEmailChange($event)">
              <span class="error-message" [hidden]="!(form.submitted && formControls.email.errors)">
                Insira um email válido
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row section form sg-input-group">
      <div class="col-12 sub-title">
        <h3>Dados de acesso</h3>
      </div>
      <!-- SENHA -->
      <div class="col-md-4">
        <div class="campo" formGroupName="usuario">
          <label *ngIf="isEdicao" for="">Senha</label>
          <label *ngIf="!isEdicao" for="">Senha *</label>
          <app-input-password [isEdicao]="isEdicao" [isAlteracaoSenha]="isAlteracaoSenha" [parentFormGroup]="formGroup"
            [controlName]="'senha'"></app-input-password>
          <span class="error-message"
            [hidden]="!(form.submitted && formControls.senha.errors && (formControls.senha.errors.required || formControls.senha.errors.minlength || formControls.senha.errors.maxlength))">
            Informe uma senha entre 8 e 30 caracteres
          </span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="campo" formGroupName="usuario">
          <label *ngIf="isEdicao" for="">Repetir Senha</label>
          <label *ngIf="!isEdicao" for="">Repetir Senha *</label>
          <app-input-password [isEdicao]="isEdicao" [isAlteracaoSenha]="isAlteracaoSenha" [parentFormGroup]="formGroup"
            [controlName]="'senhaConfirmar'"></app-input-password>
          <span class="error-message" [hidden]="!(form.submitted && formControls.senhaConfirmar.errors)">
            As senhas estão diferentes
          </span>
        </div>
      </div>
      <!-- STATUS -->
      <div class="col-md-4">
        <div class="campo" formGroupName="usuario">
          <label for="">Status</label>
          <select title="status do funcionario" formControlName="ativo" name="ativo">
            <option selected value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
          <span class="arrow"></span>
        </div>
      </div>
      <div class="col-md-3">
        <!-- EMAIL -->
        <div class="campo campo-hidden" formGroupName="usuario">
          <label for="">Email *</label>
          <input formControlName="login" type="email" placeholder="Ex:.jose@email.com" title="email de login do sistema"
            maxlength="50" hidden>
          <span class="error-message" *ngIf="form.submitted && formControls.login.errors">Informe
            um email
            válido</span>
        </div>
      </div>
      <!-- ATIVAR ALTERAÇÃO DE SENHA -->
      <div class="col-12">
        <div class="campo-inline" *ngIf="isEdicao">
          <mat-slide-toggle title="ativar alteração de senha" (click)="habilitarAlteracaoSenha()"></mat-slide-toggle>
          <label>Habilitar alteração de senha</label>
        </div>
      </div>
      <!-- <div class="col-12" *ngIf="permissoes?.length >= 0">
        <div class="campo" formGroupName="usuario">
          <label title="permissões do funcionário" for="">Permissões *</label>
          <div class="containerPermissao">
            <label formArrayName="permissoes"
              *ngFor="let permissao of formGroup.get('usuario').get('permissoes').controls;let i=index">
              <input [formControlName]="i" id="{{i}}" type="checkbox" [checked]="permissoes[i].selecionado"
                [value]="permissao.nomeReal" (change)="permissao.selecionado = !permissao.selecionado">
              <span> {{permissoes[i].nomeAmigavel}}</span>
            </label>
             <span class="error-message"
              [hidden]="!(form.submitted && !formGroup.get('usuario').get('permissoes').valid)">
              Selecione uma permissão
            </span>

          </div>
        </div>
      </div> -->
      <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="allowChangePermission()">
        <div class="campo">
          <label for="">Grupos</label>
          <select title="grupos" name="" class="containerGrupo" (change)="onGrupoSelected($event)">
            <option [value]="null">selecione</option>
            <option [hidden]="grupo.selecionado" [value]="grupo.id" *ngFor="let grupo of grupos">
              {{grupo.nome}}
            </option>
          </select>
          <span class="arrow"></span>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 campo cloudTags" *ngIf="allowChangePermission()">
        <label for="">Grupos Selecionados</label>
        <div class="tags">
          <div class="tagItem" [hidden]="!grupo.selecionado" *ngFor="let grupo of grupos">
            <span>{{grupo.nome}}</span>
            <!-- <span (click)="alterarSelecaoGrupo(grupo.id)">&times;</span> -->
            <span class="tagExcluir" title="excluir grupo" (click)="alterarSelecaoGrupo(grupo.id)">&times;</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="allowChangePermission()">
        <div class="campo">
          <label for="">Permissões *</label>
          <select title="permissões" name="" class="containerGrupo" (change)="onPermissaoSelected($event)">
            <option [value]="null">selecione</option>
            <option [hidden]="permissao.selecionado" [value]="permissao.nomeReal" *ngFor="let permissao of permissoes">
              {{permissao.nomeAmigavel}}
            </option>
          </select>
          <span class="arrow"></span>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 campo cloudTags" *ngIf="allowChangePermission()">
        <label for="">Permissões Selecionadas</label>
        <div class="tags">
          <div class="tagItem" [hidden]="!permissao.selecionado" *ngFor="let permissao of permissoes">
            <span>{{permissao.nomeAmigavel}}</span>
            <!-- <span (click)="alterarSelecaoGrupo(grupo.id)">&times;</span> -->
            <span class="tagExcluir" title="excluir permissão"
              (click)="alterarSelecaoPermissao(permissao.nomeReal)">&times;</span>
          </div>
          <span class="error-message" [hidden]="!(form.submitted && formControls.permissoes.errors)">
            Ao menos uma permissão ou um grupo deve ser adicionado
          </span>
        </div>
      </div>
      <div class="col-12 btns-actions">
        <button class="sg-btn sg-alternate" (click)="cancelarFluxo($event)" title="cancelar dados">CANCELAR</button>
        <button *ngIf="!isEdicao" class="sg-btn sg-secondary" type="submit"
          title="adicionar funcionário">ADICIONAR</button>
        <button *ngIf="isEdicao" class="sg-btn sg-secondary" type="submit"
          title="atualizar dados do funcionário">ATUALIZAR</button>
      </div>
    </div>
  </form>
</div>
<app-loading *ngIf="isLoading"></app-loading>
