import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-questionnaire-section",
  templateUrl: "./questionnaire-section.component.html",
  styleUrls: ["./questionnaire-section.component.scss"],
})
export class QuestionnaireSectionComponent implements OnInit {
  @Input() pesquisaBody;

  showBody: boolean = false;
  amountOfQuestions: number = 0;

  ngOnInit() {
    this.pesquisaBody.elementosQuestionario.map(
      (item) => (this.amountOfQuestions += item.secao.elementosSecao.length)
    );
  }

  handleShowBody(): void {
    this.showBody = !this.showBody;
  }
}
