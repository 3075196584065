<div
  #selectFilter
  [ngClass]="filterSelectIsOpen ? 'active' : ''"
  class="select-filter"
>
  <div (click)="toggleFilterSelect()" class="select-header">
    <i *ngIf="!!icon" [class]="icon"></i>
    <h5>{{ selectedFilter ? selectedFilter : placeholder }}</h5>
    <i
      [ngClass]="{
        'fa-regular arrow fa-chevron-down': true,
        'rotate': filterSelectIsOpen
      }"
    ></i>
  </div>
  <div
    [ngClass]="filterSelectIsOpen ? 'select-opened-body' : 'select-open-body'"
    class="select-body"
  >
    <template-element [ngSwitch]="type">
      <ng-container *ngSwitchCase="'list'">
        <app-list-select
          [options]="options"
          (changeFilter)="handleSelect($event)"
          (callbackEnd)="toggleFilterSelect()"
        ></app-list-select>
      </ng-container>

      <ng-container *ngSwitchCase="'check'">
        <app-check-select
          [options]="options"
          [selected]="selectedOptions"
          (changeFilter)="handleSelect($event)"
          [disabledOptions]="disabledOptions"
        ></app-check-select>
      </ng-container>

      <ng-container *ngSwitchCase="'composedCheck'">
        <app-composed-check-select
          [composedOptions]="composedOptions"
          (changeFilter)="handleSelect($event)"
        ></app-composed-check-select>
      </ng-container>

      <ng-container *ngSwitchCase="'radio'">
        <app-filter-select-radio
          [options]="options"
          (changeFilter)="handleSelect($event)"
        ></app-filter-select-radio>
      </ng-container>

      <ng-container *ngSwitchCase="'rangeDate'">
        <app-range-date
          (changeFilter)="handleSelect($event)"
          (callbackEnd)="toggleFilterSelect()"
          [intervalDate]="selectConfig.intervalDate"
        ></app-range-date>
      </ng-container>
    </template-element>
  </div>
</div>
