
type TInputConfig = {
  show: boolean,
  placeholder: string,
  eyeIcon: boolean,
}

export class ModalData {
  // título do modal
  title: string;

  /**
   * conteúdo textual do modal
   * caso o usuário passe null será renderizado um
   * ng-content possibilitando personalizar o corpo da modal
   */
  messageModal?: string;

  // exibe o checkbox de confirmação dentro da modal
  checkbox?: boolean = false;

  // exibe o input caso show = true
  inputConfig?: TInputConfig = {
    show: false,
    placeholder: '',
    eyeIcon: false,
  };

  // texto exibido no botão negativo
  btnTitleNegative?: string = 'Cancelar';

  // texto exibido no botão positivo
  btnTitlePositive: string = 'Ok';

  // callback a ser executado caso a ação positiva seja escolhida
  positiveCallback?: () => void;

  // callback a ser executado caso a ação positiva negativa seja escolhida
  negativeCallback?: () => void;
  // callback a ser executado caso a ação de fechar a modal seja disparada através do clique no close do header.
  closeFromHeaderCallback?: () => void;

  /**
   * se o modal deve ser fechando ou não
   * close = true; o modal vai ser fechado ao clicar no botão positivo
   * close = false; o modal permanece aberto mesmo clicando no botão positivo
   * default é sempre fechando */
  close?: boolean = true;

  /**
   * deixa o modal com estilo de confirmação
   * isOnlyConfirmation = true; remove o botão de cancelar e o botão de fechar superior
   * isOnlyConfirmation = false; mantém o botão de cancelar e o botão de fechar superior
   * default é false mantendo os botões responsáveis por cancelar a modal */
  isOnlyConfirmation?: boolean = false;

  /**
   * é preciso que o arquivo esteja dentro de assets
   * aí é necessário passar o nome da pasta e o nome do arquivo
   *
   * TODO: atributo depreciado, sendo preciso realizar a migração
   * para o icon
   */
  imgFile?: string = 'icons/icon-warning.svg';

  /**
   * ícone que é exibido dentro da modal, para dar um
   * contexto visual ao texto repassado;
   * devem-se usar os ícones do:
   * https://fontawesome.com/ */
  icon?: string = null;

  /**
   * loading = true inicializa o comportamento de loading interno à modal
   * loading = false mostra o conteúdo da modal sem loading
   */
  loading?: boolean = false;
}
