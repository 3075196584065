import { Injectable } from '@angular/core';
import { StatusPesquisa } from './statusPesquisa';
import { StatusPesquisaDestino } from './statusPesquisaDestino';

/**
 * Classe responsavel por gerenciar o 'ciclo de vida'
 * da pesquisa, que por sua vez, é definido com base em 'status'
 *
 * @author victor hugo
 */
@Injectable({
  providedIn: 'root',
})
export class StatusPesquisaManager {

  private statusToDestinoMap: Map<StatusPesquisa, StatusPesquisaDestino[]> = new Map();

  constructor() {
    this.statusToDestinoMap.set(
      StatusPesquisa.RASCUNHO,
      [
        new StatusPesquisaDestino(StatusPesquisa.RASCUNHO, false),
        new StatusPesquisaDestino(StatusPesquisa.EXECUCAO, true)
      ]);

    this.statusToDestinoMap.set(
      StatusPesquisa.EXECUCAO,
      [
        new StatusPesquisaDestino(StatusPesquisa.EXECUCAO, true),
        new StatusPesquisaDestino(StatusPesquisa.RASCUNHO, false),
        new StatusPesquisaDestino(StatusPesquisa.CONCLUIDO, false)
      ]);

    this.statusToDestinoMap.set(
      StatusPesquisa.CONCLUIDO,
      [
        new StatusPesquisaDestino(StatusPesquisa.CONCLUIDO, false)
      ]);
  }

  getDestinosPossiveis(statusPesquisa: StatusPesquisa) {
    const destinosPossiveis = this.statusToDestinoMap.get(statusPesquisa);
    return destinosPossiveis;
  }
}
