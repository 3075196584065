<header>
  <div class="title">
    <h3>{{tituloHeader}}</h3>
  </div>
  <div class="item">
    <label>{{ item1.titulo }}</label>
    <p>{{ item1.inicio }} | {{ item1.fim }}</p>
  </div>
  <div class="item">
    <label>{{ item2.titulo }}</label>
    <p>{{ item2.aprovadas }} aprovadas | {{ item2.reprovadas }} reprovadas</p>
  </div>
  <div class="progress-card">
    <div class="text">
      <label>{{ progresso.total }} solicitadas</label>
      <p>{{ progresso.realizadas }} realizadas|{{ progresso.porcentagem }}%</p>
    </div>
    <progress [value]="progresso.porcentagem" max="100"></progress>
  </div>
</header>
