import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { IFuncionarioData, IPermissoes } from "../interfaces";
import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";
import { QueryParamsResolver } from "app/util/formatter/queryParams";

@Injectable({
  providedIn: "root",
})
export class FuncionarioService extends CrudService<any> {
  public baseUrl = this.resourcePath;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}`);
  }

  getEmployeesList(
    keywords = [""],
    status = null,
    sorting: { direction: DirectionOrderTypes, sort: string },
    page = 0,
    size = 10
  ): Observable<any> {

    const { direction, sort } = sorting;

    const queryParams = new QueryParamsResolver({
      ativo: status,
      page,
      size,
      direction,
      sort,
    }).toString();

    const url = `${this.baseUrl}/funcionarios/filtrar/palavra-chave${queryParams}`;

    const response = <Observable<any>>(
      this.requestService.post(url, { palavrasChave: keywords })
    );
    return response;
  }

  getTotalEmployees(keywords = [""], status = null): Observable<number> {

    const queryParams = new QueryParamsResolver({
      ativo: status,
    }).toString();

    const url = `${this.baseUrl}/funcionarios/total-registros${queryParams}`;

    const response = <Observable<number>>(
      this.requestService.post(url, { palavrasChave: keywords })
    );

    return response;
  }

  /**
   * Realiza o cadastro de um novo funcionário
   * @param employeeData: dados do funcionário
   */
  registerEmployee(employeeData): Observable<any> {
    const url = `${this.baseUrl}/funcionarios`;
    const response = this.requestService.post(url, employeeData);
    return response;
  }

  /**
   * Recupera os dados do funcionário na tela de cadastro
   */
  getEmployeeData(id): Observable<IFuncionarioData> {
    const url = `${this.baseUrl}/funcionarios/${id}`;
    const response = this.requestService.get(url);
    return response;
  }

  /**
   * Atualiza os dados do funcionário
   * @param employeeData: dados do funcionário
   */
  updateEmployee(employeeData): Observable<any>  {
    const url = `${this.baseUrl}/funcionarios/${employeeData.id}`
    const response = this.requestService.put(url, employeeData);
    return response;
  }

  /**
   * Recupera as permissões do funcionário
   */
  getPermissions(): Observable<IPermissoes[]> {
    const url = `${this.baseUrl}/permissoes`;
    const response = <Observable<IPermissoes[]>>(
      this.requestService.get(url)
    );
    return response;
  }

  /**
   * Recupera os grupos de permissões do funcionário
   */
  getGroups(): Observable<any> {
    const url = `${this.baseUrl}/grupos`;
    const response = <Observable<any>>this.requestService.get(url);
    return response;
  }

  /**
   * Recupera os grupos de permissões do funcionário
   */
  getGroupPermissions(idGroup): Observable<any> {
    const url = `${this.baseUrl}/grupos/${idGroup}`;
    const response = this.requestService.get(url);
    return response;
  }

  /**
   * Atualiza o status do funcionario
   */
  setEmployeeStatus(id: number, status: boolean): Observable<any> {
    const url = `${this.baseUrl}/funcionarios/${id}/ativo`;

    const response = <Observable<any>>this.requestService.put(url, {ativo: status});
    return response

  }

  /*
   * Recupera os dados do header
   */
  getHeaderData() {
    const url = `${this.baseUrl}/funcionarios/resumoGeral`;
    const response = this.requestService.get(url);
    return response;
  }

  /**
   * Recupera o id do funcionario com base no id do usuario logado
   * @param id: id do funcionario
   */
  getIdFuncionarioPorIdContaUsuario(id: string):  Observable<number>{
    const requestUrl = `${apiLocation}/funcionarios/buscarporidConta?id=${id}`;

    return this.requestService.get(requestUrl);
  }
}
