import { ValidatorFn, ValidationErrors, AbstractControl, UntypedFormArray } from '@angular/forms';

/**
 * Funcao de validacao para que algum tipo de input
 * nao possa possuir um valor maior que a quantidade de
 * alternativas inseridas
 */
// tslint:disable-next-line: max-line-length
function getQuantidadeMaximaSelecaoValidator(controls: { [key: string]: AbstractControl }): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    const alternarivasFormArray = <UntypedFormArray>controls.alternativas;
    const valorMaximoPermitido = alternarivasFormArray.length;

    if (control.value > valorMaximoPermitido) {
      return {
        valorMaximoPermitido: true,
      };
    }

    return null;
  };
}

export default getQuantidadeMaximaSelecaoValidator;
