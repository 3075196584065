<ng-container>
  <div [ngClass]="{ 'container-modal': true, 'show-modal': show }">
    <app-loading *ngIf="isLoading"></app-loading>
    <div [style.width]="modalData.messageModal ? '536px' : 'max-content'" [ngClass]="{ 'content-modal': true, 'show-modal': show }">
      <div class="header-modal">
        <button *ngIf="!modalData.isOnlyConfirmation" class="button-close-header" (click)="close(closeEventType.HEADER)">
          <i class="fa-sharp fa-regular fa-xmark"></i>
        </button>

        <div class="title-modal">
          <i [class]="modalData.icon" *ngIf="modalData.icon"></i>
          <img [src]="'../../../../assets/' + modalData.imgFile" alt="" *ngIf="!modalData.icon">
          <h3>{{ modalData.title }}</h3>
        </div>
      </div>

      <div
        *ngIf="modalData.messageModal"
        [ngClass]="modalData.inputConfig?.show ? 'message-modal lower-bottom' : 'message-modal'"
        [innerHTML]="modalData.messageModal | safeHtml"
      >
      </div>
      <div *ngIf="!modalData.messageModal" class="message-modal">
        <ng-content></ng-content>
      </div>

      <div class="checkbox-container" *ngIf="modalData.checkbox">
        <input class="checkbox-element" type="checkbox" [(ngModel)]="checkbox" (ngModelChange)="handleCheckbox($event)"
          id="input-checkbox" />
        <label class="label-checkbox" for="input-checkbox">Não perguntar novamente</label>
      </div>

      <form class="input-container" autocomplete="off" *ngIf="modalData.inputConfig?.show">
        <input
          [ngClass]="{'input-error': inputErros.length, 'input-password': inputValue?.length}"
          [placeholder]="modalData.inputConfig?.placeholder"
          id="input-password"
          name="input-password"
          [(ngModel)]="inputValue" (input)="handleInput($event)"
          type="{{ modalData.inputConfig.eyeIcon && hide ? 'password' : 'text' }}"
        >
        <span *ngIf="modalData.inputConfig.eyeIcon">
          <i class="fa-sharp fa-light fa-eye eye-icon" *ngIf="!hide" (click)="toggleHidePassword()"></i>
          <i class="fa-sharp fa-light fa-eye-slash eye-icon" *ngIf="hide" (click)="toggleHidePassword()"></i>
        </span>
        <p class="error-message" *ngFor="let error of inputErros">
          {{ error }}
        </p>
      </form>

      <div class="modal-actions">
        <button *ngIf="modalData.btnTitleNegative && !modalData.isOnlyConfirmation" class="cancel-button"
          (click)="handleNegativeCallback();">
          {{ modalData.btnTitleNegative }}
        </button>

        <button class="positive-button" (click)="handlePositiveCallback();">
          {{ modalData.btnTitlePositive }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
