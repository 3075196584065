import { Directive, OnInit } from '@angular/core';
import { MascaraBase } from './mascaraBase';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-porcentagem]',
  host: {
    '(keyup)': 'onInputChange($event)',
  },
})
export class MascaraPorcentagemDirective implements OnInit {

  constructor(protected ngControl: NgControl) {
  }

  /**
   * adicionando a mascara assim que o componente
   * é criado
   */
  ngOnInit(): void {
    this.formatarValor(this.ngControl.control.value);
  }

  onInputChange(event) {
    const newValue = event.target.value;
    this.formatarValor(newValue);
  }

  formatarValor(valor) {
    const stringValue = String(valor);

    const formattedValue = aplicarMascaraPorcentagem(stringValue);

    this.ngControl.control.patchValue(formattedValue);
  }

}

export function aplicarMascaraPorcentagem(valor): string {
  const stringValue = String(valor);
  let numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(stringValue);

  /**
   * Garantindo o tamanho minimo do input
   */
  while (numberString.length < 4) {
    numberString = `0${numberString}`;
  }

  const is100 = numberString === '10000';

  if (!is100) {
    while (numberString.length > 4) {
      numberString = numberString.substring(1, numberString.length);
    }
  }

  /**
   * Adicionando a virgula na segunda casa decimal
   */
  // tslint:disable-next-line: max-line-length
  const valorPrimeiraFormatacao = is100 ? numberString.replace(/(\d{3})/, '$1,') : numberString.replace(/(\d{2})/, '$1,');
  const valorPrimeiraFormatacaoNumber = Number(numberString.replace(/(\d{2})/, '$1.'));

  let valorSegundaFormatacao = valorPrimeiraFormatacao;
  if (valorPrimeiraFormatacaoNumber > 100) {

    // tslint:disable-next-line: max-line-length
    numberString = `${numberString.substring(0, numberString.length - 2)}${numberString.substring(numberString.length - 1, numberString.length)}`;
    valorSegundaFormatacao = numberString.replace(/(\d{2})/, '$1,');
  }

  /**
   * Adicionando o símbolo da porcentagem
   */
  const valorFinal = `${valorSegundaFormatacao}`;
  return valorFinal;
}
