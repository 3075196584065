<div [formGroup]="formGroup">
  <label>Cliente*</label>
  <!-- <select formControlName="cliente" (change)="onClienteSelected()">
    <option [ngValue]="null" disabled>Selecione</option>
    <option *ngFor="let cliente of clientes" [ngValue]="cliente">{{cliente.nome}}</option>
  </select> -->
  <div *ngIf="clientes.length > 0">
    <app-filterable-select-old
      [closeOnSelect]="true"
      (changeSelectedItem)="handleSelectedCliente($event)"
      [placeholder]="'Selecione um cliente'"
      [filterPlaceholder]="'Digite o nome de algum cliente'"
      [errorMessage]="'Não há clientes cadastrados'"
      [width]="'300px'"
      [data]="clientes"
      [backgroundColor]="'white'"
      [selectedValue]="idClienteSelecionado"
    >
    </app-filterable-select-old>
  </div>
</div>
