import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-interesse-cadastro',
  templateUrl: './area-interesse.cadastro.component.html',
  styleUrls: ['./area-interesse.cadastro.component.scss'],
})

export class AreaInteresseCadastroComponent implements OnInit {
  ngOnInit() { }
}
