import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { SchedulesModel } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-configuracoes/pesquisas-configuracoes.model";
import * as moment from "moment/moment";
import { CalendarInputsOutput } from "./calendar-inputs/calendar-inputs.components";

export interface CalendarData {
  action: number;
  initalDate: Date;
  endDate: Date;
  dates: Date[];
}

export interface DateValidation {
  isValid: boolean;
  initialDateIsNotValid: boolean;
  endDateIsNotValid: boolean;
}

export type UsedByRef = "PESQUISA" | "INFO";
@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() usedBy: UsedByRef = "PESQUISA";

  // propriedades
  @Input() model: SchedulesModel;
  // eventos
  @Output() onRangeChange: EventEmitter<CalendarData> = new EventEmitter();
  @Output() changeDateInputValidate: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("dateInputs") inputs: ElementRef;
  calendar: [] = [];
  weekDays: string[] = ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"];
  calendarValue: moment.Moment = moment().subtract(1, "day");
  monthName: string = moment().format("MMMM");
  initialDate: number = 0;
  endDate: number = 0;
  dates: number[] = [];
  removedDates: number[] = [];
  cylclesDays: number = 0;
  intervalDates: number =0;
  isDateValid: boolean = true;

  dateHasRemoved: boolean = false;

  // recupera as datas inicial e final (pressupõe dates not null)
  getInitialAndEndDate(dates: number[]): [number, number] {
    let initial = dates[0];
    let end = dates[0];
    // percorre array de datas
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      // verifica se a data é menor que a inicial
      if (date < initial) {
        // atribui data a data inicial
        initial = date;
        // verifica se a data é posterior a data final
      } else if (date > end) {
        // atribui a data final
        end = date;
      }
    }
    return [initial, end];
  }

  ngOnInit() {
    if (this.model && !this.model.isEmpty()) {
      // converte as datas em valores
      this.dates = this.model.toUnixDates();
      const initialAndEndDate = this.getInitialAndEndDate(this.dates);
      // atribui às propriedades
      this.initialDate = initialAndEndDate[0];
      this.endDate = initialAndEndDate[1];

      setTimeout(() => {
        this.checkDateValidations(null);
        this.cylclesDays != this.intervalDates ? this.cylclesDays : this.cylclesDays = this.getTimeDiffInputsValue();
      }, 0);
    }
  }

  ngOnChanges() {
    if(this.dateHasRemoved) {
      this.dateHasRemoved = false;
      return
    }
    this.ngOnInit();
  }

  reset() {
    this.initialDate = 0;
    this.endDate = 0;
  }

  checkDateValidations(type: string): boolean {
    const { isValid, initialDateIsNotValid, endDateIsNotValid } =
      this.getDateValidationStatus();

    // emitters
    this.changeDateInputValidate.emit(isValid);
    this.isDateValid = isValid;

    if (type) {
      const errorInCurrentInput =
        type === "initialDate" ? initialDateIsNotValid : endDateIsNotValid;

      if (errorInCurrentInput) {
        return true;
      }

      return false;
    }
  }

  handleInputsChanges(event: CalendarInputsOutput) {
    setTimeout(()=>{
      this.cylclesDays = this.getTimeDiffInputsValue();
    }, 0)
    // validations
    if (this.checkDateValidations(event.type)) return;
    const newDate = moment(event.value, "YYYY-MM-DD").toDate();
    const newMoment = moment(newDate).valueOf();
    // verifica se a data não é muito antiga

    if (newMoment < 0 || isNaN(newMoment)) {
      return;
    }
    //
    this.dates = [];
    this.removedDates = [];
    if (event.value !== "") {
      if (event.type === "initialDate") {
        this.initialDate = newMoment;
      } else {
        this.endDate = newMoment;
      }
      // expande
      this.expand(this.initialDate, this.endDate, newMoment);
    } else {
      // caso a data seja inválida ele reseta se possui as duas datas
      if (this.initialDate && this.endDate) {
        this.reset();
      }
    }
    this.outputData(3);
  }

  handleInitialOrEndDate(date: number) {
    this.dates = [];
    if (date === this.initialDate) {
      this.initialDate = null;
      this.endDate = null;
    } else {
      this.initialDate = date;
      this.endDate = date;
    }
  }


  /**
   * Metódo responsável por incluir e/ou remover uma data de dentro do range selecionado (range = data_inicial + data_final)
   *
   * No if ocorre a inclusão de uma data anteriormente removida e no Else ocorre a exclusão da data.
   * @param date
   */
  selectOrRemove(date: number) {
    if (!this.dates.find((obj) => obj === date)) {
      const index = this.removedDates.indexOf(date);
      this.dates.push(date);
      this.removedDates.splice(index, 1);
      this.intervalDates = this.cylclesDays;
      this.cylclesDays > 0 ?  this.cylclesDays++   : this.cylclesDays;
    } else {
      const index = this.dates.indexOf(date);
      this.dates.splice(index, 1);
      this.removedDates.push(date);
      this.dateHasRemoved = true;
      // Controla os dias de ciclo que aparecem no canto inferior do calendario
      this.intervalDates = this.cylclesDays;
      this.cylclesDays > 0 ?  this.cylclesDays--   : this.cylclesDays;
    }
  }

  toggle(date: number) {
    if (this.initialDate === date || this.endDate === date) {
      const newDate = date === this.initialDate ? this.endDate : this.initialDate;
      this.initialDate = newDate;
      this.endDate = newDate;
      this.removedDates = [];
      this.expand(this.initialDate, this.endDate, newDate);
      this.intervalDates = this.cylclesDays;
    } else {
      this.selectOrRemove(date);
    }
  }

  expand(initialDate: number, endDate: number, newDate: number) {
    this.initialDate =
      newDate < initialDate || initialDate === 0 ? newDate : this.initialDate;
    this.endDate = newDate > endDate || endDate === 0 ? newDate : this.endDate;

    this.dates = [];

    const newDates = [];

    for (
      let date = moment(this.initialDate);
      date.isSameOrBefore(moment(this.endDate));
      date.add(1, "day")
    ) {
      if (!this.removedDates.find((obj) => obj === date.valueOf())) {
        newDates.push(date.valueOf());
      }
    }
    this.dates = newDates;
  }

  outputData(action: number) {
    const newDates = this.dates.map((item) => {
      return moment(item).toDate();
    });

    this.onRangeChange.emit({
      action: action,
      dates: newDates,
      endDate: moment(this.endDate).toDate(),
      initalDate: moment(this.initialDate).toDate(),
    });
  }

  handleDateOutput(event: number) {
    if (event < this.initialDate || event > this.endDate) {
      this.intervalDates = this.cylclesDays;
      if (this.initialDate !== this.endDate) {
        this.removedDates = [];
        this.initialDate = 0;
        this.endDate = 0;
      }
      this.expand(this.initialDate, this.endDate, event);
      this.outputData(1);
    } else {
      this.toggle(event);
      this.outputData(2);
    }

    // Sincronização das validações ao alterar as datas pelo evento de onClick
    window.setTimeout(() => {
      this.checkDateValidations(null);
    }, 0);
  }

  // DATE VALIDATIONS
  getDateValidationStatus(): DateValidation {
    const status = {
      isValid: false,
      initialDateIsNotValid: false,
      endDateIsNotValid: false,
    } as DateValidation;

    const nativeElement = this.inputs.nativeElement;
    const inputs =
      nativeElement && nativeElement.querySelectorAll(".raw-input");

    if (inputs && !!inputs.length) {
      const [initialInput, endInput] = inputs;
      const [initialValidity, endValidity] = [
        initialInput.validity,
        endInput.validity,
      ];

      const initialDateIsNotValid = initialValidity.rangeOverflow || initialValidity.rangeUnderflow || initialValidity.badInput;
      const endDateIsNotValid = endValidity.rangeOverflow || endValidity.rangeUnderflow || endValidity.badInput;


      status.isValid = !(initialDateIsNotValid || endDateIsNotValid);
      status.initialDateIsNotValid = initialDateIsNotValid;
      status.endDateIsNotValid = endDateIsNotValid;
    }

    return status;
  }

  getTimeDiffInputsValue(): number {
    const nativeElement = this.inputs.nativeElement;
    const inputs = nativeElement && nativeElement.querySelectorAll(".raw-input");
    if (inputs && !!inputs.length) {
        const [initialInput, endInput] = inputs;
        const [initialValue, endValue] = [initialInput.value, endInput.value];
        const initialMoment = moment(initialValue)
        const endMoment = moment(endValue);
        const diff = moment.duration(endMoment.diff(initialMoment)).asDays()
        return diff>=0?diff+1:diff;
      }
    return 0;
  }
}
