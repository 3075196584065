import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PesquisaAuthorityService } from '../../../../../../../../pesquisa-old/cadastro/security/pesquisaAuthorityService';
import { CadastroPesquisaStoreState } from '../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { AbstractPerguntaConteudo } from '../abstractPerguntaConteudo';

@Component({
  selector: 'app-pergunta-grade-unica-content',
  templateUrl: './pergunta-grade-unica-content.component.html',
  styleUrls: ['./pergunta-grade-unica-content.component.scss'],
})
export class PerguntaGradeUnicaContentComponent extends AbstractPerguntaConteudo {

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
    protected store: Store<CadastroPesquisaStoreState>) {
    super(pesquisaAuthorityService, store);
  }

}
