import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { containText } from "app/util/search/search";

export interface IDropdownOptions {
  id: number;
  label: string;
  key?: any;
}

@Component({
  selector: "app-generic-table-input-search",
  templateUrl: "./input-search.component.html",
  styleUrls: ["./input-search.component.scss"],
})
export class GenericTableInputSearchComponent implements OnInit {
  // placeholder do input
  @Input() placeholder = 'Buscar...'
  // Flag: Indica se a renderização será em modo dropdown
  @Input() dropdownMode: boolean = false;
  // Opções da dropdown caso seja inicializado nesse modo
  @Input() dropdownOptions: IDropdownOptions[] = [];
  // Opções atualmente filtradas da dropdown
  filteredOptions: IDropdownOptions[] = [];
  // Valor atual do input.
  public currentValue: string = "";
  // Flag de controle da visualização da dropdown
  public showDropdown: boolean = false;
  // função que propaga o evento do texto digitado pelo usuário
  @Output() handleChange = new EventEmitter<any>(null);

  constructor() {}

  ngOnInit(): void {}

  // RN: Verifica se possui matches entre o input do usuário e as opções do dropdown
  private checkDropdownMatches() {
    this.filteredOptions = this.dropdownOptions.filter(v => containText(v.label, this.currentValue));

    return !!this.filteredOptions.length;
  }

  // State change: mudança de estado de visibilidade da dropdown
  private changeDropdownVisibility(state: boolean) {
    this.showDropdown = state;
  }

  // Ação: usuário clica em uma das opções da dropdown
  public handlePickDropdownOption(option: IDropdownOptions) {
    this.currentValue = option.label;

    this.changeDropdownVisibility(false);
    this.handleChange.emit(option);
  }

  // função que emite o texto digitado no input
  handleTextChange($event: Event): void {
    // Se não está em modo dropdown apenas será emitido o valor textual
    if (!this.dropdownMode) {
      this.handleChange.emit(this.currentValue);
      return;
    }

    // TODO: forma temporária de "limpeza" do valor selecionado na dropdown
    // terá de ser definido com PD.
    if (!this.currentValue) {
      this.handleChange.emit(null);
    }

    this.changeDropdownVisibility(this.checkDropdownMatches())
  }
}
