import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthUser } from 'app/infraestrutura/security/authUser';
import { Authority } from 'app/infraestrutura/security/authority';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { TipoMenu } from 'app/router/menu.enum';
import { Subscription } from 'rxjs';
import { IImageTheme } from 'styles/interface';
import { ThemeService } from 'styles/services/theme.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit {

  menuOpen: boolean = false;
  isShow: boolean = false;
  isShowMobile: boolean = false;
  nomeMenu: String;
  navigationSubscription: Subscription;
  userLogado: AuthUser;
  nomeEntidade: any;
  isLoading: boolean = false;
  contractorImages: IImageTheme;

  //  estado criado para armazenar a informação de que o cursor está passando encima da opção do link de atalho para o analyze
  analyseOptionIsHovered: boolean = false;

  //  atributo utilizado para armazenar o subtenant do usuario logado (a fim de definir a visibilidade da opção do menu lateral do atalho do analyze)
  currentUserSubtenant: string = '';
  /**
   * atributo responsável por criar uma ngClass que desabilitará a rota do meu Perfil
   * caso a rota ja seja de atualização do usuário
   */
  activated: boolean = false;

  tipoMenu = TipoMenu;

  activeMenu: TipoMenu = TipoMenu.DASHBOARDBETA;

  /**
   * Mapa com as altoridades necessarias
   * para exibir determinados menus do dashboard
   */
  menuAuthorityMap: Map<TipoMenu, Authority[]> = new Map();

  constructor(
    private securityService: SecurityService,
    private router: Router
  ) { }

  ngOnInit() {

    this.currentUserSubtenant = this.securityService.getAuthenticatedUserSubtenant();

    // tslint:disable-next-line: ter-arrow-parens
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activated = false;
      }
    });
    this.userLogado = new AuthUser();

    this.menuAuthorityMap.set(TipoMenu.CONTRATANTE, [Authority.CADASTRAR_INSTANCIA, Authority.LISTAR_INSTANCIA]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.GERENCIAMENTO, [Authority.CADASTRAR_INSTANCIA]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.CLIENTE, [Authority.ATUALIZAR_CLIENTE, Authority.CADASTRAR_CLIENTE, Authority.LISTAR_CLIENTE]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.FUNCIONARIO, [Authority.ATUALIZAR_FUNCIONARIO, Authority.CADASTRAR_FUNCIONARIO, Authority.LISTAR_FUNCIONARIO]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.LOCALIDADE, [Authority.LISTAR_LOCALIDADE, Authority.CADASTRAR_LOCALIDADE, Authority.ATUALIZAR_LOCALIDADE]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.OPERADOR, [Authority.LISTAR_OPERADOR, Authority.CADASTRAR_OPERADOR, Authority.ATUALIZAR_OPERADOR]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.GRUPO, [Authority.LISTAR_GRUPO, Authority.CADASTRAR_GRUPO, Authority.ATUALIZAR_GRUPO]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.PRODUTIVIDADE, [Authority.CONTROLE_PRODUTIVIDADE]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.PESQUISA, [Authority.CADASTRAR_PESQUISA_COMPLETA, Authority.LISTAR_PESQUISA, Authority.MUDANCA_STATUS_PESQUISA, Authority.CADASTRAR_PESQUISA_CARACTERISTICAS, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_LOCALIDADES]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.PESQUISABETA, [Authority.CADASTRAR_PESQUISA_COMPLETA, Authority.LISTAR_PESQUISA, Authority.MUDANCA_STATUS_PESQUISA, Authority.CADASTRAR_PESQUISA_CARACTERISTICAS, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_QUESTIONARIO, Authority.CADASTRAR_PESQUISA_LOCALIDADES]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.AUDITORIA, [Authority.AUDITORIA_PASSO_1, Authority.AUDITORIA_PASSO_2]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.RESULTADOS, [Authority.ACESSO_RESULTADO]);
    this.menuAuthorityMap.set(TipoMenu.COMUNICACAO, [Authority.CADASTRAR_DOC_LGPD]);
  }

  // tslint:disable-next-line: max-line-length
  // Verifica se a tela for maior que 1000px para executar a alteração, no mobile ignora os eventos de mouse over e mouse out
  actionMenu() {
    if (window.screen.width > 1000) {
      this.isShow = !this.isShow;
    }else {
      // só vai
    }
  }

  // Função própria para os botões que só são visíveis no mobile
  actionMenuMobile() {
    this.isShowMobile = !this.isShowMobile;
  }

  onMenuItemClick(tipoMenu: TipoMenu) {
    if(tipoMenu !== TipoMenu.ANALYZE) {
      this.activeMenu = tipoMenu;
      this.handleHideMenu();
    } else {
      const jwt = this.securityService.getJwt()
      const authPath = `${environment.analyzeUrl}/auth/tensai?token=${jwt}`
      window.open(authPath);
    }
  }

  handleHideMenu() {
    this.isShowMobile = !this.isShowMobile;
  }

  isMenuActive(tipoMenu: TipoMenu) {
    return this.activeMenu === tipoMenu;
  }

  subMenu() {
    // this.subMenuOpen = !this.subMenuOpen;
  }

  doLogout() {
    this.securityService.logout();
    this.router.navigate(['login'], { queryParams: { redirect: true } });
  }
  /**
   * Metodo que define se determinado
   * menu sera exibido dependendo
   * das permissões do funcionario logado
   */
  showMenu(menu: TipoMenu): boolean {
    const currentUserAuthorities = this.securityService.getAuthenticatedUserAuthorities();
    const necessariesAuthorities = this.menuAuthorityMap.get(menu);

    const hasNecessaryAuthority = currentUserAuthorities.some(userAuthority => necessariesAuthorities.includes(userAuthority));
    return hasNecessaryAuthority;
  }

  // returna true caso o subtenant seja o tensai
  showOptionTenantTensai(): boolean {
    return this.securityService.getAuthenticatedUserSubtenant() === 'tensai'
  }

  /**
   * Removida validação que verificava o ambiente antes de retornar o link para o analyze
   * @returns boolean
   */
  showAnalyzeOption(): boolean {
    // return this.currentUserSubtenant !== 'tensai'
    return true;
  }

  isActive(domMenuItem) {
    return domMenuItem.classList.contains('active');
  }
}
