<app-moldura-telas-de-acesso
  [title]="'Bem-vindo de volta!'"
  [subtitle]="'Faça login na sua conta para continuar'"
>
  <form
    *ngIf="tokenValido && !senhaRedefinida"
    action=""
    [formGroup]="formGroup"
    #form="ngForm"
    (ngSubmit)="form.valid && alterar()"
  >
    <a [routerLink]="'/login'" class="return-link">
      <img src="assets/images/go-back.png" alt="return arrow icon" />
      Voltar
    </a>
    <div class="title-wrapper">
      <h2 class="title">Nova senha</h2>
    </div>
    <h5 *ngIf="showApiError">{{ apiError }}</h5>
    <div class="input-wrapper">
      <div
        [ngClass]="
          form.submitted && formGroup.controls.senha.errors
            ? 'login-input error'
            : 'login-input'
        "
      >
        <input
          matInput
          type="password"
          placeholder="Nova senha"
          formControlName="senha"
          name="senha"
          id="senhaInput"
          maxlength="50"
        />
      </div>
      <h6 *ngIf="form.submitted && formGroup.controls.senha.errors">
        Campo obrigatório
      </h6>
    </div>
    <div class="input-wrapper">
      <div
        [ngClass]="
          form.submitted &&
          (this.formGroup.get('senhaConfirmar').errors ||
            formularioComSenhasDiferentes())
            ? 'login-input error'
            : 'login-input'
        "
      >
        <input
          matInput
          type="password"
          placeholder="Confirmar senha"
          formControlName="senhaConfirmar"
          name="senhaConfirmar"
          id="senhaConfirmarInput"
          maxlength="50"
        />
      </div>
      <h6
        *ngIf="
          form.submitted &&
          (this.formGroup.get('senhaConfirmar').errors ||
            formularioComSenhasDiferentes())
        "
      >
        {{
          this.formGroup.get("senhaConfirmar").value === ""
            ? "Campo obrigatório"
            : "As senhas não são iguais"
        }}
      </h6>
    </div>
    <button type="submit">Confirmar</button>
  </form>
  <form
    *ngIf="senhaRedefinida"
    action=""
    [formGroup]="formGroup"
    #form="ngForm"
  >
    <a [routerLink]="'/login'" class="return-link">
      <img src="assets/images/go-back.png" alt="return arrow icon" />
      Voltar
    </a>
    <div class="title-wrapper">
      <h2 class="title">Nova senha</h2>
    </div>
    <div class="feedback-data">
      <div class="info-data">
        <h1>Alteração feita com sucesso!</h1>
        <h4>
          Você pode voltar a cadastrar, gerenciar e coletar dados referentes as
          suas pesquisas. Volte a tela de login para acessar a sua conta.
        </h4>
        <input
          matInput
          type="password"
          placeholder="Nova senha"
          formControlName="senha"
          name="senha"
          id="senhaInput"
          hidden
          maxlength="50"
        />
        <input
          matInput
          type="password"
          placeholder="Confirmar senha"
          formControlName="senhaConfirmar"
          name="senhaConfirmar"
          id="senhaConfirmarInput"
          maxlength="50"
          hidden
        />
        <button type="button" (click)="irParaTelaDeLogin()">
          Ir para o login
        </button>
      </div>
      <img
        src="assets/images/recuperar-senha-imagem-ilustrativa.png"
        alt="pessoa feliz"
      />
    </div>
  </form>
</app-moldura-telas-de-acesso>
<app-loading *ngIf="isLoading"></app-loading>
