import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { Secao } from './secao';

export class ElementoQuestionario {

  ordem: number;
  secao: Secao;
  /**
   * hash utilizado para diferenciação de elementos
   * no frontEnd, este atributo é indiferente ao back
   */
  hash: string;

  /**
   * Cria um FormGroup representando a entidade
   */
  static getControl(): AbstractControl {

    const formGroup = new UntypedFormGroup({
      ordem: new UntypedFormControl(),
      hash: new UntypedFormControl(uuid()),
      secao: Secao.getControl(),
    });

    return formGroup;
  }

  /**
   * Cria um FormGroup preenchido representando a entidade
   */
  static getInitializedControl(elemeElementoQuestionario: ElementoQuestionario): AbstractControl {

    const formGroup = new UntypedFormGroup({
      ordem: new UntypedFormControl(elemeElementoQuestionario.ordem),
      hash: new UntypedFormControl(elemeElementoQuestionario.hash),
      secao: Secao.getInitializedControl(elemeElementoQuestionario.secao),
    });

    return formGroup;
  }

}
