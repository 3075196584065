<div
  *ngIf="!!model"
  class="tooltip-container"
  [style.left]="x"
  [style.top]="y"
  [style.display]="!isVisible ? 'none' : 'flex'"
  #containerRef
>
  <div class="close-btn" (click)="handleCloseTip()">
    <i class="fa-regular fa-xmark"></i>
  </div>
  <span class="tooltip-header" (click)="openCollection(model.id)"
    >Coleta {{ parseIdToText(model.id) }}</span
  >
  <div class="period-wrapper">
    <span><b>Início:</b> {{ model.horaInicio }}</span>
    <span><b>Fim: </b>{{ model.horaFim }}</span>
  </div>
  <span><b>Data: </b>{{ model.data }}</span>
  <span><b>Operador: </b>{{ model.operador }}</span>
  <span><b>Status: </b>{{ collectionSituation[model.situacao] }}</span>

  <div class="criteria-wrapper">
    <span
      class="criteria"
      *ngFor="let criterio of criterios; index as i"
      [style.backgroundColor]="criterio['backgroundColor']"
      [style.color]="criterio['color']"
    >
      {{ criterio["text"] }}
    </span>
  </div>
</div>
