import { CellAlignTypes, CellStyleBehavior, CellTypes, CustomStyles, IconCellValue, TableCell, TooltipData } from "../interfaces/tCell";
import { HeadAlignTypes, TableHead } from "../interfaces/tHead";

export function checkboxHeadFactory(
  checked: boolean = false,
  checkable: boolean = true
) {
  const tHead = new TableHead();

  tHead.type = "checkbox";
  tHead.checkable = checkable;
  tHead.checked = checked;

  const o = {
    cellType: (ct: CellTypes) => {
      tHead.cellType = ct;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      tHead.customStyles = cs;
      return o;
    },
    cellStyles: (cs: CustomStyles | ((cellRef: TableCell) => CustomStyles)) => {
      tHead.cellStyles = cs;

      return o;
    },
    cellAlign: (alignType: CellAlignTypes) => {
      tHead.cellAlign = alignType;

      return o;
    },
    alignType: (alignType: HeadAlignTypes) => {
      tHead.alignTypes = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      tHead.cellStyleBehavior = bh;

      return o;
    },
    build: () => tHead,
  };

  return o;
}

export function tHeadFactory(text: string) {
  const tHead = new TableHead();

  tHead.type = "text";
  tHead.label = text;

  const o = {
    htmlTemplateDefaultValue: (htmlTemplate: string) => {
      tHead.defaultCellHtmlTemplateValue = htmlTemplate;

      return o;
    },
    iconDefaultValue: (dv: IconCellValue) => {
      tHead.defaultCellIconValue = dv;

      return o;
    },
    class: (className: string | ((cellRef: TableCell) => string)) => {
      tHead.className = className;
      return o;
    },
    cellType: (ct: CellTypes) => {
      tHead.cellType = ct;

      return o;
    },
    cellDataToolTip: (ct: TooltipData) => {
      tHead.cellDataToolTip = ct;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      tHead.customStyles = cs;
      return o;
    },
    cellStyles: (cs: CustomStyles | ((cellRef: TableCell) => CustomStyles)) => {
      tHead.cellStyles = cs;

      return o;
    },
    cellAlign: (alignType: CellAlignTypes) => {
      tHead.cellAlign = alignType;

      return o;
    },
    alignType: (alignType: HeadAlignTypes) => {
      tHead.alignTypes = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      tHead.cellStyleBehavior = bh;

      return o;
    },
    build: () => tHead,
  };

  return o;
};
