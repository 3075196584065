import { colorPalette } from "../colors"
import { pieTooltipFormatter } from "../utils/tooltipFormatter"
import { EChartsOption } from 'echarts';

export function toEchartOption(chartData, numberResult): EChartsOption {
  const chartOption = {
    tooltip: {
      trigger: "item",
      confine: true,
      position: 'top',
      axisPointer: {
        type: "shadow",
      },
      borderColor: "#610082",
      formatter: (params) => pieTooltipFormatter(params, numberResult)
    },
    legend: {
      left: "left",
      icon: "circle",
      type: 'scroll',
    },
    color: colorPalette,
    series: [
      {
        type: "pie",
        radius: ["40%", "55%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          formatter: '{c}%',
          fontWeight: 700,
          color: "#610082",
        },
        labelLine: {
          show: true, // mostra a linha de label
          lineStyle: {
            color: "#610082", // define a cor da linha de label
          },
        },
        data: chartData,
      },
    ],
  };

  return chartOption as EChartsOption;
}
