<div>
  <button [ngClass]="showMeatball ? 'view-container meatball-active' : 'view-container'">
    <i class="fa-solid fa-ellipsis"></i>
  </button>
  <div class="meatball-container" *ngIf="showMeatball">

    <span class="option" (click)="handleOpenCollection()">
      <i class="fa-regular fa-eye icon"></i>
      <h4 class="option-name">{{ menuItems.VER_COLETA }}</h4>
    </span>

    <span class="option" (click)="handleOpenObservation()">
      <i class="fa-regular fa-message-lines icon"></i>
      <h4 class="option-name">{{ menuItems.VER_OBSERVACOES }}</h4>
    </span>

    <span class="option" *ngIf="showMap" (click)="handleOpenMapModal()">
      <i class="fa-sharp fa-regular fa-location-dot icon"></i>
      <h4 class="option-name">{{ menuItems.VER_NO_MAPA }}</h4>
    </span>

  </div>
</div>
