<app-moldura-telas-de-acesso [title]="'Olá, ' + userName + '!'" [subtitle]="'Por favor, insira sua nova senha abaixo'">
  <form action="" [formGroup]="form" (ngSubmit)="form.valid && handleSubmit()">
    <div class="input-wrapper">
      <div class="password-input">
        <input placeholder="Digite sua nova senha*" formControlName="password" name="password" id="passwordInput"
          maxlength="30" type="{{ passwordVisibily ? 'password' : 'text' }}">
        <i class="fa-sharp fa-light fa-eye eye-icon" *ngIf="!passwordVisibily" (click)="toggleHidePassword()"></i>
        <i class="fa-sharp fa-light fa-eye-slash eye-icon" *ngIf="passwordVisibily" (click)="toggleHidePassword()"></i>
      </div>
      <app-password-validator *ngIf="this.form.get('password').touched || this.form.get('password').dirty"
        [inputValue]="form.get('password').value" (inputIsValid)="checkInputIsValid($event)"></app-password-validator>
    </div>

    <div class="input-wrapper">
      <div
        [ngClass]="this.form.get('confirmPassword').value.length && (passwordInputIsValid && !checkPasswordIsEqual)
        ? 'password-input password-input-error' : 'password-input'">
        <input placeholder="Repetir nova senha*" formControlName="confirmPassword" name="confirmPassword"
          id="confirmPasswordInput" maxlength="30" type="{{ confirmPasswordVisibily ? 'password' : 'text' }}"
          [attr.disabled]="!passwordInputIsValid ? '' : null">
        <i class="fa-sharp fa-light fa-eye eye-icon" *ngIf="!confirmPasswordVisibily"
          (click)="toggleHideConfirmPassword()"></i>
        <i class="fa-sharp fa-light fa-eye-slash eye-icon" *ngIf="confirmPasswordVisibily"
          (click)="passwordInputIsValid && toggleHideConfirmPassword()"></i>
      </div>
      <span class="error-msg" *ngIf="this.form.get('confirmPassword').value.length && (passwordInputIsValid && !checkPasswordIsEqual)">As senhas não correspondem</span>
    </div>

    <button type="submit" [disabled]="!passwordInputIsValid || !checkPasswordIsEqual">Confirmar</button>
  </form>
</app-moldura-telas-de-acesso>


<app-loading *ngIf="loading"></app-loading>
