import { HttpParams } from "@angular/common/http";

interface QueryParamObject {
  [key: string]: string | number | boolean;
}

/**
 * Utilitário de formatação de queryParams
 */
export class QueryParamsResolver {
  constructor(fromObject: QueryParamObject) {
    this.queryParamsObject = this.filterObject(fromObject);
  }

  private queryParamsObject: QueryParamObject = {};

  private isValidQueryParamValue(v: string | number | boolean) {
    return ["string", "number", "boolean"].includes(typeof v) && v !== "";
  }

  private filterObject(obj: QueryParamObject): QueryParamObject {
    return Object.entries(obj)
      .filter(([_, value]) => this.isValidQueryParamValue(value))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }

  /**
   * 
   * @returns queryParams no formato de string
   */
  public toString() {
    const params = new HttpParams({ fromObject: this.queryParamsObject });

    let paramsToString = params.toString();

    return paramsToString.length ? `?${paramsToString}` : paramsToString;
  }

  /**
   * Objeto queryParams filtrado, apenas com valores válidos
   */
  public toObject() {
    return this.queryParamsObject;
  }
}
