import { Directive } from '@angular/core';
import { MascaraLimiteCaracteresDirective } from './mascara.limite.caracteres.directive';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-raio]',
})
export class MascaraRaioDirective extends MascaraLimiteCaracteresDirective {

  ngOnInit() {
    this.mascaraLimiteCaracteres = 7;
  }

  aplicarMascara(valor: string): string {
    const numberString = stringFormatter.toOnlyPositiveTruncatedNumberString(valor);
    return numberString;
  }

}
