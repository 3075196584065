import { Component, Input, Output } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { KeyValue } from 'app/util/misc/keyValue';
import { EventEmitter } from 'events';
import { PesquisaCadastro } from '../../model/pesquisaCadastro';
import { EventoInput } from '../evento/eventoInput';
import { TipoEventoInput } from '../evento/tipoEventoInput';
import { InputComponent } from '../inputComponent';
import { InputValidatorFactory } from '../inputValidatorFactory';
import { TipoInput } from '../tipoInput';
import { TipoPergunta } from './tipoPergunta';

@Component({
  selector: 'app-tipo-pergunta-input',
  templateUrl: './tipo-pergunta-input.component.html',
  styleUrls: ['./tipo-pergunta-input.component.scss'],
})
export class TipoPerguntaInputComponent extends InputComponent {

  formGroup: UntypedFormGroup;
  control: UntypedFormControl;
  tiposPergunta: KeyValue[];

  @Input() hashPergunta: string;
  @Input() hashSecao: string;
  @Output() tipoPerguntaChange: EventEmitter = new EventEmitter();

  ngOnInit() {
    this.tiposPergunta = this.getTiposPergunta();
    super.ngOnInit();
  }

  initFormGroup() {
    // tslint:disable-next-line:max-line-length
    this.control = new UntypedFormControl(null, InputValidatorFactory.getValidadores(TipoInput.QUESTIONARIO_SECAO_PERGUNTA_TIPO));
    this.formGroup = new UntypedFormGroup({
      tipo: this.control,
    });
  }

  getTiposPergunta() {
    const tipoPerguntaEnumKeys: string[] = Object.keys(TipoPergunta);
    const tiposPergunta: KeyValue[] = tipoPerguntaEnumKeys.map((key: string) => {
      return new KeyValue(key, TipoPergunta[key]);
    });
    return tiposPergunta;
  }

  getTipoPerguntaById(key: string) {
    let perguntaSelecionada = null;

    this.tiposPergunta.some((tipoPergunta) => {
      if (tipoPergunta.key === key) {
        perguntaSelecionada = tipoPergunta;
        return true;
      }
    });

    return perguntaSelecionada;
  }

  onParentChange(payload: any) {

    // console.log('[tipo-pergunta-input] parent change, snapshot: ', payload);
    const pesquisaSnapshot: PesquisaCadastro = payload;

    if (!pesquisaSnapshot || !pesquisaSnapshot.elementosQuestionario) {
      this.control.patchValue(null);
      return;
    }

    const secao = pesquisaSnapshot
      .elementosQuestionario
      .filter(elementoQuestionario => elementoQuestionario.hash === this.hashSecao)
      .map(elementoQuestionario => elementoQuestionario.secao)
    [0];

    if (secao) {
      const pergunta = secao.elementosSecao
        .filter(elementoSecao => elementoSecao.hash === this.hashPergunta)
        .map(elementoSecao => elementoSecao.pergunta)
      [0];

      this.control.patchValue(pergunta.tipo);
    }

  }

  onTipoPerguntaChange() {

    const evento = new EventoInput(TipoEventoInput.CHANGE, this.formGroup);

    const tipoPerguntaSelecionado: string = this.control.value;
    this.tipoPerguntaChange.emit(tipoPerguntaSelecionado);

    this.notifyParent(evento);
  }

}
