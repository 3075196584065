<div>
  <div class="pesquisas-vinculos-container">
    <div class="vinculos-header">
      <div class="vinculos-header-title">
        <div
          class="link-icon"
        ></div>
        <p class="vinculos-header-title-text">Vínculos</p>
      </div>
      <div class="vinculos-header-buttons">
        <button (click)="handleShowQuizPreview()" class="btn-ver-questionario">
          <div
            class="ver-questionario-icon"
            alt="Ver questionário"
          ></div>
          <span>VER QUESTIONÁRIO</span>
        </button>
      </div>
    </div>
    <hr />

    <div class="vinculos-middle">
      <div class="vinculos-middle-i">
        <p class="vinculos-middle-text">Título da Pesquisa</p>
        <p class="vinculos-middle-text-ii">
          {{ survey.descricaoPesquisa.titulo }}
        </p>
      </div>
      <div class="vinculos-middle-ii">
        <div class="vinculos-middle-ii-icon">
          <i class="fa-regular fa-circle-info fa-lg"></i>
        </div>
        <p class="vinculos-middle-text">
          Adicione os operadores nas localidades que foram vinculadas. Cada
          localidade deve ter ao menos um operador para realizar as coletas
        </p>
      </div>
    </div>
  </div>
  <div class="pesquisas-vinculos-sublocalidades">
    <app-locationslist
      *ngIf="localidade"
      [survey]="survey"
      [location]="localidade?.selectedItemEvent.item.label"
      [locations]="localidade?.locationOtp.alteredLocations"
      type="vinculo"
      (output)="handleLocationListOutput($event)"
      [operators]="operators"
    ></app-locationslist>
  </div>
</div>
