import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { v4 as uuid } from "uuid";
import {
  Callbacks,
  CheckedRows,
  ClearCustomStyles,
  GenericCustomStyles,
  RequestToggleCheckboxAccessibility,
  RequestPaginationChange,
} from "../interfaces/service";
import { TablePagination } from "../interfaces/pagination";

@Injectable({
  providedIn: "root",
})
export class GenericTableService {
  constructor() {}

  private _activeTableHash: string = null;

  private selectRowSubject: BehaviorSubject<number | number[]> =
    new BehaviorSubject<number | number[]>(null);

  private unselectAllSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  private unselectRowSubject: BehaviorSubject<number | number[]> =
    new BehaviorSubject<number | number[]>(null);

  private selectAllRowsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  public checkedRowsSubject: BehaviorSubject<CheckedRows[]> =
    new BehaviorSubject<CheckedRows[]>([]);

  private toggleCheckboxAccessibilitySubject: BehaviorSubject<RequestToggleCheckboxAccessibility> =
    new BehaviorSubject<RequestToggleCheckboxAccessibility>(null);

  public headerCheckboxStateSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private insertStylesSubject: BehaviorSubject<GenericCustomStyles> =
    new BehaviorSubject<GenericCustomStyles>(null);

  private clearStylesSubject: BehaviorSubject<ClearCustomStyles> =
    new BehaviorSubject<ClearCustomStyles>(null);

  private paginationConfigSubject: BehaviorSubject<TablePagination> =
    new BehaviorSubject<TablePagination>(null);

  private requestPageChangeSubject: BehaviorSubject<RequestPaginationChange> =
    new BehaviorSubject<RequestPaginationChange>(null);

  /**
   * Elimina todas as subscrições realizadas, esse método somente deve ser chamado no componente da tabela genérica.
   */
  public dispose() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private generateTableHash() {
    if (this._activeTableHash !== null) {
      this.dispose();
    }

    this._activeTableHash = uuid();
  }

  private handlePaginationSubscription(
    req: RequestPaginationChange,
    callbacks: Callbacks
  ) {
    switch (req.type) {
      case "NEXT_PAGE":
        return callbacks.NEXT_PAGE();
      case "PREV_PAGE":
        return callbacks.PREVIOUS_PAGE();
      case "JUMP_TO_PAGE":
        return callbacks.JUMP_TO_PAGE(req.value);
      default:
        return;
    }
  }

  private _subscriptions: Subscription[] = [];

  initSubscriptions(callbacks: Callbacks) {
    this.generateTableHash();

    // checkbox

    this._subscriptions.push(
      this.selectRowSubject.subscribe({
        next: (v) => callbacks.SELECT_ROWS(v),
      }),

      this.unselectAllSubject.subscribe({
        next: (v) => v && callbacks.UNSELECT_ALL(),
      }),

      this.unselectRowSubject.subscribe({
        next: (v) => callbacks.UNSELECT_ROWS(v),
      }),

      this.selectAllRowsSubject.subscribe({
        next: (v) => v && callbacks.SELECT_ALL_ROWS(),
      }),

      this.toggleCheckboxAccessibilitySubject.subscribe({
        next: (v) => v && callbacks.TOGGLE_CHECKBOX_ACCESSIBILITY(v),
      }),

      // styling

      this.insertStylesSubject.subscribe({
        next: (v) => v && callbacks.INSERT_STYLES(v),
      }),

      this.clearStylesSubject.subscribe({
        next: (v) => v && callbacks.CLEAR_STYLES(v),
      }),

      // pagination

      this.paginationConfigSubject.subscribe({
        next: (config) => config && callbacks.UPDATE_PAGINATION_CONFIG(config),
      }),

      this.requestPageChangeSubject.subscribe({
        next: (req) => req && this.handlePaginationSubscription(req, callbacks),
      })
    );
  }

  // Seleciona todas as linhas da tabela
  selectAllRows() {
    if (!this.selectAllRowsSubject.closed.valueOf()) {
      this.selectAllRowsSubject.next(true);
    }
  }

  // Seleciona todas as linhas com os id's especificados
  selectRows(rowIds: number[]) {
    if (!this.selectRowSubject.closed.valueOf()) {
      this.selectRowSubject.next(rowIds);
    }
  }

  // Seleciona a linha com o id especificado
  selectRow(rowId: number) {
    if (!this.selectRowSubject.closed.valueOf()) {
      this.selectRowSubject.next(rowId);
    }
  }

  // Desseleciona todas as linhas atualmente selecionadas
  unselectAll() {
    if (!this.unselectAllSubject.closed.valueOf()) {
      this.unselectAllSubject.next(true);
    }
  }

  // Desseleciona a linha com o id especificado
  unselectRow(rowId: number | number[]) {
    if (!this.unselectRowSubject.closed.valueOf()) {
      this.unselectRowSubject.next(rowId);
    }
  }

  // Retorna a lista de id's das linhas atualmente selecionadas
  getCheckedRows(): CheckedRows[] {
    if (!this.checkedRowsSubject.closed.valueOf()) {
      return this.checkedRowsSubject.getValue();
    }

    return [];
  }

  getHeaderCheckboxState(): boolean {
    if (!this.headerCheckboxStateSubject.closed.valueOf()) {
      return this.headerCheckboxStateSubject.getValue();
    }

    return null;
  }

  disableCheckboxOn(config: RequestToggleCheckboxAccessibility) {
    if (!this.toggleCheckboxAccessibilitySubject.closed.valueOf()) {
      this.toggleCheckboxAccessibilitySubject.next({
        action: "DISABLE",
        disableBy: config.disableBy || "INDEX",
        ...config,
      });
    }
  }

  enableCheckboxOn(config: RequestToggleCheckboxAccessibility) {
    if (!this.toggleCheckboxAccessibilitySubject.closed.valueOf()) {
      this.toggleCheckboxAccessibilitySubject.next({
        action: "ENABLE",
        enableBy: config.enableBy || "INDEX",
        ...config,
      });
    }
  }

  // Atualiza o estado interno do subject de linhas selecionadas
  setCheckedRows(checkedRows: CheckedRows[]) {
    if (!this.checkedRowsSubject.closed.valueOf()) {
      this.checkedRowsSubject.next(checkedRows);
    }
  }

  // Insere estilos dinamicamente à tabela, atualmente só suporta inserção de estilo nas linhas.
  insertStyles(config: GenericCustomStyles) {
    if (!this.insertStylesSubject.closed.valueOf()) {
      this.insertStylesSubject.next(config);
    }
  }

  /**
   * Limpa as configurações de estilo definidas através de service#insertStyles(config).
   *
   * styleId - Caso tenha sido definido um styleId, serão removidos os estilos definidos específicos para este ID
   * caso contrário, será removido o ultimo estilo inserido utilizando service#insertStyles
   */
  clearStyles(config: ClearCustomStyles) {
    if (!this.clearStylesSubject.closed.valueOf()) {
      this.clearStylesSubject.next(config);
    }
  }

  /**
   * Inicializa a configuração de paginação da tabela
   */
  setPaginationConfig(conf: TablePagination) {
    if (!this.paginationConfigSubject.closed.valueOf()) {
      this.paginationConfigSubject.next({ ...conf });
    }
  }

  /**
   * Atualiza a configuração de paginação da tabela
   */
  updatePaginationConfig(conf: TablePagination) {
    if (!this.paginationConfigSubject.closed.valueOf()) {
      this.paginationConfigSubject.next({
        ...this.paginationConfigSubject.getValue(),
        ...conf,
      });
    }
  }

  /**
   * Aciona o evento de mudança de paginação para a próxima página
   */
  nextPage() {
    if (!this.requestPageChangeSubject.closed.valueOf())
      this.requestPageChangeSubject.next({ type: "NEXT_PAGE" });
  }

  /**
   * Aciona o evento de mudança de paginação para a página anterior
   */
  previousPage() {
    if (!this.requestPageChangeSubject.closed.valueOf())
      this.requestPageChangeSubject.next({ type: "PREV_PAGE" });
  }

  /**
   * Aciona o evento de mudança de paginação para a página requisitada
   */
  jumpToPage(requestedPage: number) {
    if (!this.requestPageChangeSubject.closed.valueOf())
      this.requestPageChangeSubject.next({
        type: "JUMP_TO_PAGE",
        value: requestedPage,
      });
  }
}
