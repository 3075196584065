import { IMessages } from "../interfaces";

export const errorMessages: IMessages = {
  surveyList: {
    title: "Erro na listagem de pesquisas",
    message: "Ocorreu um erro ao tentar listar as pesquisas",
  },
  surveyMap: {
    title: "Erro ao buscar coletas",
    message: "Ocorreu um erro ao tentar listar as coletas da pesquisa."
  },
  getQuestionnaire: {
    title: "Erro ao buscar as perguntas da coleta",
    message: "Ocorreu um erro ao tentar buscar as perguntas da coleta atual. Por favor, recarregue a página."
  },
  getObservations: {
    title: "Erro ao buscar comentários",
    message: "Ocorreu um erro ao tentar buscar os comentários da coleta."
  },
  updateObservations: {
    title: "Erro ao cadastrar comentário",
    message: "Ocorreu um erro ao enviar o comentário, por favor, tente novamente."
  },
  createManualConsolidation: {
    title: "Falha na consolidação",
    message: "Selecione alguma resposta e/ou grupo para realizar consolidação"
  },
  autoMatchConsolidation: {
    title: "Falha no automatch",
    message: "Ocorreu um erro ao tentar combinar as respostas"
  },
  undoConsolidation: {
    title: "Erro ao desfazer o grupo!",
    message: null
  },
  changeCitationTitle: {
    title: "Não foi possível alterar o título",
    message: null
  }
};
