<div
  class="alternative"
  [ngClass]="{
    selected: isChecked,
    preselect: !!preSelected,
    checkbox: renderType === 'checkbox'
  }"
>
  <input
    *ngIf="selectable"
    (click)="handleCheck(alternativa.id)"
    type="button"
    name="selectable-alternatives"
  />

  <textarea
    [ngClass]="!selectable && 'singleRenderMode'"
    (click)="handleInputFocus(alternativa.id)"
    #textareaRef
    class="text-input"
    maxlength="255"
    [placeholder]="alternativa.enunciado"
    (input)="handleInput($event)"
    [(ngModel)]="value"
  ></textarea>
</div>
