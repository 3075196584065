<div class="range-picker">
  <mat-calendar
    (selectedChange)="_onSelectedChange($event)"
    [selected]="selectedDateRange"
    [headerComponent]="header"
    [dateClass]="dateClass"
  >
  </mat-calendar>
  <div class="actions">
    <button class="clean-btn" (click)="handleClearDateRange()">Limpar</button>
    <button class="ok-btn" (click)="handleConfirmDateRange()">Confirmar</button>
  </div>
</div>
