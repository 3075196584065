<div class="container-alert">
  <div class="alert">
    <div class="title">
      <!-- Título deve conter uma indagação específica, sobre o que se trata -->
      <h3>{{title}} </h3>
    </div>
    <div class="message">
      <!-- aqui vem o corpo -->
      <ng-content>

      </ng-content>
    </div>
    <div class="actions">
      <!-- O texto das ações devem explicitar o que vai ocorrer ao click -->
      <!-- <a class="negative-button"  (click)="closeModal()">Excluir</a>
      <a class="positive-button" (click)="closeModal()" >Adicioinar</a> -->
    </div>
  </div>
</div>