import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from 'app/servico/requestService/crudService';
import { RequestService } from 'app/servico/request.service';
import { apiLocation } from 'app/infraestrutura/apiLocation';

// Representa o endereço de um cliente
export interface Address {
  id: number;
  cep: string;
  logradouro: string;
  numero: string;
  bairro: string;
  cidadeEstado: string;
}

// Representa os dados de uma pessoa
export interface Person {
  id: number;
  nome: string;
  cpf: string;
  rg: string;
  cnpj?: any;
  inscricaoEstadual?: any;
  site?: any;
  responsavel?: any;
  endereco: Address;
  tipoPessoa: string;
}

// Representa os dados de contato de uma pessoa
export interface Contact {
  telefone: string;
  celular: string;
  email: string;
}

// Representa os dados de usuários de uma pessoa
export interface User {
  id: number;
  login: string;
  ativo: boolean;
}

// Representa uma pessoa como cliente (apenas o suficiente)
export interface SimpleCustomer {
  id: number;
  nome: string;
}

// Estrutura os dados de um cliente de forma detalhada
export interface Customer extends SimpleCustomer {
  pessoa: Person;
  contato: Contact;
  usuario: User;
  foto: string;
  permissaoAnalyze: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PesquisaConfiguracoesService extends CrudService<Customer> {

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/clientes`);
  }
  
  listarTodosClientes(): Observable<Customer[]> {
    return this.listar();
  }

  encontrarCliente(id: number): Observable<Customer> {
    return this.findById(id);
  }


}