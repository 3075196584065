<!-- V2 e V3 do FIGMA -->
<div class="answer-item-container">
  <div class="answer-item__group-input">
    <div class="input-fields__alternative">
      <label for="">Alternativa</label>
      <input type="text" id="" placeholder="{{ answer.descricao }}" disabled />
    </div>

    <div class="input-fields__type">
      <label for="">Tipo da alternativa</label>
      <input
        type="text"
        [value]="formatTipoAlternativa(answer.tipoAlternativa)"
        disabled
      />
    </div>
  </div>

  <div class="answer-item__options">
    <span>Pular para</span>
    <div class="select-container">
      <div
        class="drop-select"
        [ngClass]="[
          _answerItemDropDownActive ? 'bigger-display' : 'default-display',
          answer.marcacaoPergunta ? 'selected' : ''
        ]"
      >
        <div class="drop-select__header">
          <div
            class="input-fields__select"
            (click)="setAnswerItemDropDownActive()"
          >
            <p
              *ngIf="answer.comportamento === 'CONTINUAR_ENTREVISTA'"
              [ngClass]="answer.comportamento === 'CONTINUAR_ENTREVISTA' && 'has-margin'"
            >
              Continuar entrevista
            </p>
            <p
              *ngIf="answer.comportamento === 'FINALIZAR_ENTREVISTA'"
              [ngClass]="answer.comportamento === 'FINALIZAR_ENTREVISTA' && 'has-margin'"
            >
              Finalizar entrevista
            </p>
            <div
              class="input-fields__selected-content"
              *ngIf="answer.comportamento === 'PULAR_PARA_PERGUNTA'"
            >
              <div class="selected-content__question">
                <h4>Pergunta {{ returnSelectedQuestion().ordem }} -</h4>
                <p>
                  {{ returnSelectedQuestion().titulo }}
                </p>
              </div>
              <div class="selected-content__section">
                <h4>SEÇÃO {{ _changeSelectedSectionRef?.ordem }} :</h4>
                <p>
                  {{ _changeSelectedSectionRef?.nome || _changeSelectedSectionRef?.descricao }}
                </p>
              </div>
            </div>
            <!-- CHAMADA DA FUNÇÃO RESPONSÁVEL POR REALIZAR A MODIFICAÇÃO DO ESTADO E EXPANDIR O SELECT -->
            <i class="fas fa-chevron-down"> </i>
          </div>

          <!-- CAIXA SERÁ RENDERIZADA QUANDO O ATRIBUTO ESTIVER INDICANDO QUE O SELECT FOI EXPANDIDO -->
          <div class="drop-select__content" *ngIf="_answerItemDropDownActive">

            <div class="options__search">
              <p (click)="handleFinalizarEntrevista({
                question: fatherAnswer,
                section: sectionRef
              })">Finalizar entrevista</p>
              <p (click)="handleRemoverMarcacaoDaAlternativa()">Continuar entrevista</p>
            </div>

            <!-- FUNCIONALIDADE A SER IMPLEMENTADA -->
            <div class="input-fields__search">
              <input
                type="text"
                id="search"
                [value]="inputText"
                (keyup)="inputTextChangeHandler($event)"
                placeholder="Faça uma busca pela pergunta que procura"
              />
              <i class="fas fa-search"></i>
            </div>

            <!-- CHAMADA DO COMPONENTE DE SEÇÃO PASSANDO ALGUMAS PROPRIEDADES PARA -->
            <!-- UTILIZAR NA INTERAÇÃO DO USUÁRIO -->
            <app-section-list
              *ngFor="let secao of secoesFiltradas"
              [data]="secao"
              [pesquisaRealizada]="inputText.length > 0"
              [tpyeOfUse]="'LOGIC'"
              [isSelectable]="true"
              [questionRef]="fatherAnswer"
              [sectionRef]="sectionRef"
              [selectedQuestion]="_changeSelectedQuestionRef"
              [selectedSection]="_changeSelectedSectionRef"
              (setSelectedQuestion)="
                handleAdicionarMarcacaoAAlternativa($event)
              "
            >
            </app-section-list>
          </div>
        </div>
      </div>
      <button
        *ngIf="answer.marcacaoPergunta && !_answerItemDropDownActive"
        class="button-remove-jump"
        (click)="handleRemoverMarcacaoDaAlternativa()"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
