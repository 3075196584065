<div class="kebab-container" #kebab>
  <button class="meatball-btn" (click)="toggleMeatBall()">
    <i class="fa-regular fa-ellipsis"></i>
  </button>
  <ul [ngClass]="{ kebab: true, 'show-kebab': isOpen, 'hide-kebab': !isOpen }">
    <li (click)="editClient()">
      <i class="fa-regular fa-pen-to-square"></i><label>Editar</label>
    </li>
    <li (click)="toggleStatus()">
      <i
        [ngClass]="{
          'fa-toggle-large-off': !situacao,
          'fa-toggle-large-on': situacao,
          'fa-regular': true
        }"
      ></i>
      <label>{{ !situacao ? "Ativar" : "Inativar" }}</label>
    </li>
    <li (click)="removeClient()">
      <i class="fa-regular fa-trash"></i><label>Deletar</label>
    </li>
  </ul>
</div>
