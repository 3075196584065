<div class="question" [formGroup]="formGroup">

    <ng-content></ng-content>

    <div class="questionHeader">
      <div class="iconQuestion">
        <span class={{icone}}></span>
      </div>
      <div class="titleQuestion">
        <span>{{titulo}}</span>
        <h3>{{controls.nome.value ? controls.nome.value : nomePlaceHolder}}</h3>
      </div>
    </div>
    <div class="questionContent">
      <div class="questionProperties">
        <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.PULO)">
          <mat-slide-toggle formControlName="possuiPulo" [checked]="controls.possuiPulo.value"></mat-slide-toggle>
          <label>Pulo</label>
        </div>
        <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.PULO_PERGUNTA)">
          <mat-slide-toggle formControlName="possuiPuloPergunta" [checked]="controls.possuiPuloPergunta.value"></mat-slide-toggle>
          <label>Pulo</label>
        </div>
        <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.DISCO)">
          <mat-slide-toggle formControlName="discoInducao" [checked]="controls.discoInducao.value"></mat-slide-toggle>
          <label for="">Disco</label>
        </div>
        <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.COTA)">
          <mat-slide-toggle formControlName="possuiCota" [checked]="controls.possuiCota.value"></mat-slide-toggle>
          <label for="">Cotas</label>
        </div>
        <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.DIFERENTE)">
          <mat-slide-toggle formControlName="possuiDiferenteDe" type="checkbox" [checked]="controls.possuiDiferenteDe.value"></mat-slide-toggle>
          <label for="">Diferente de</label>
        </div>
        <div class="campo-toggle" *ngIf="possuiMarcacao(tipoMarcacao.OBRIGATORIA)">
          <mat-slide-toggle formControlName="obrigatoria" type="checkbox" [checked]="controls.obrigatoria.value"></mat-slide-toggle>
          <label for="">Obrigatório</label>
        </div>
      </div>
    </div>
  </div>  