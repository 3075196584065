<!-- <div [formGroup]="horarioFormGroup">
  <div>
    <span>Horário selecionado: </span>
    <input type="time" formControlName="horario" />
  </div>
</div> -->

<div [formGroup]="horarioFormGroup" class="sg-input-group">
  <div class="campo">
    <input type="time" formControlName="horario" step="1">
    <img src="../../../../../assets/images/relogio.svg" alt="ícone de relógio">
  </div>

  <span class="error-message" *ngIf="hasErrors()">
    Você precisa informar um valor temporal válido.
  </span>
</div>
