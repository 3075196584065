import { AbstractControl, ValidationErrors } from "@angular/forms";
import { TipoAlternativa } from "app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa";

/**
 * Validador que verifica se uma determinada resposta pergunta do tipo única
 * está com o campo respostaAberta vazia caso a alternativa relacionada
 * seja do tipo ABERTA_TEXTO ou ABERTA_NUMERO.
 */
export default (toCompareControl: AbstractControl): ValidationErrors => {
  const alternativaSelecionada = getAlternativaSelecionada(
    toCompareControl.value
  );

  if (!alternativaSelecionada) {
    return {
      respostaAbertaVazia: false,
    };
  }

  if (
    alternativaSelecionada.selecionada &&
    alternativaEhAberta(alternativaSelecionada.esquema) &&
    respostaAbertaEstaVazia(alternativaSelecionada)
  ) {
    return {
      respostaAbertaVazia: true,
    };
  }
};

function getAlternativaSelecionada(respostas: any) {
  if (respostas.idAlternativaSelecionada === "-1") {
    return null;
  }

  const idAlternativaSelecionada = Number(respostas.idAlternativaSelecionada);
  const alternativaSelecionadaIndex = respostas.alternativasSelecionadas.findIndex(
    (a) => a.esquema.id === idAlternativaSelecionada
  );

  const alternativaSelecionada =
    respostas.alternativasSelecionadas[alternativaSelecionadaIndex];
  return alternativaSelecionada;
}

/**
 * Verifica se a alternativa trata-se de uma alternativa
 * do tipo ABERTA_TEXTO ou ABERTA_NUMERO.
 * @param resposta
 */
function alternativaEhAberta(esquema: any): boolean {
  const tipoAlternativa = esquema.tipoAlternativa;

  return (
    tipoAlternativa === TipoAlternativa.ABERTA_TEXTO ||
    tipoAlternativa === TipoAlternativa.ABERTA_NUMERO
  );
}

/**
 * Verifica se o atributo "respostaAberta" da resposta
 * encontra-se undefined ou vazio.
 * @param resposta
 */
function respostaAbertaEstaVazia(resposta: any): boolean {
  const respostaAberta = resposta.respostaAberta;
  return respostaAberta === undefined || respostaAberta === "";
}
