<div *ngIf="researchData" class="container-research">
  <div class="results-found">
    <img src="../../../assets/images/ai-results.svg" />
    <span>{{researchSum}}</span>
    <p *ngIf="researchSum > 1">resultados encontrados</p>
    <p *ngIf="researchSum < 1">resultado encontrado</p>
  </div>

  <div class="client-research" *ngIf="researchData.cliente.length > 0">
    <div class="title">
      <div>
        Relacionado em clientes <span>({{researchData.cliente.length}})</span>
      </div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.cliente; let i = index" (click)="handleAcessClient(item.id)">
      <div class="item-container">
        <div class="avatar">
          <img [src]="item.fotoperfil ? item.fotoperfil : '../../../assets/images/photoSearchComponentListItem.svg'" [alt]="item.nome">
        </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

  <div class="client-research" *ngIf="researchData.operador.length > 0">
    <div class="title">
      <div> Relacionado em operadores (<span>{{researchData.operador.length}}</span>)</div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.operador; let i = index" (click)="handleAcessOperator(item.id)">
      <div class="item-container">
        <div class="avatar">
          <img [src]="item.fotoperfil ? item.fotoperfil : '../../../assets/images/photoSearchComponentListItem.svg'" [alt]="item.nome">
        </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

  <div class="client-research" *ngIf="researchData.funcionario.length > 0">
    <div class="title">
      <div>
        Relacionado em funcionários (<span>{{researchData.funcionario.length}}</span>)
      </div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.funcionario; let i = index" (click)="handleAcessEmployees(item.id)">
      <div class="item-container">
        <div class="avatar">
          <img [src]="item.fotoperfil ? item.fotoperfil : '../../../assets/images/photoSearchComponentListItem.svg'" [alt]="item.nome">
        </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

  <div class="client-research" *ngIf="researchData.localidade.length > 0">
    <div class="title">
      <div>
        Relacionado emm localidade (<span>{{researchData.localidade.length}}</span>)
      </div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.localidade; let i = index" (click)="handleAcessLocations(item.id)">
      <div class="item-container">
        <div class="avatar">
          <img src="../../../assets/images/photoSearchComponentListItem.svg" [alt]="item.nome">
        </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

  <div class="client-research" *ngIf="researchData.grupo.length > 0">
    <div class="title">
      <div>
        Relacionado em grupo (<span>{{researchData.grupo.length}}</span>)
      </div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.grupo; let i = index" (click)="handleAcessGroups(item.id)">
      <div class="item-container">
        <div class="avatar"> <img src="../../../assets/images/photoSearchComponentListItem.svg"> </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

  <div class="client-research" *ngIf="researchData.instancia.length > 0 && userIsAdm()">
    <div class="title">
      <div>
        Relacionado em contratante (<span>{{researchData.instancia.length}}</span>)
      </div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.instancia; let i = index" (click)="handleAcessInstance(item.id)">
      <div class="item-container">
        <div class="avatar"> <img src="../../../assets/images/photoSearchComponentListItem.svg"> </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

  <div class="client-research" *ngIf="researchData.pesquisa.length > 0">
    <div class="title">
      <div>
        Relacionado em pesquisas (<span>{{researchData.pesquisa.length}}</span>)
      </div>
      <hr>
    </div>
    <div class="item-research" *ngFor="let item of researchData.pesquisa; let i = index" (click)="handleAcessResearches(item.id)">
      <div class="item-container">
        <div class="avatar"> <img src="../../../assets/images/photoSearchComponentListItem.svg"> </div>
        {{item.nome}}
      </div>
      <div class="access">
        Acessar
      </div>
    </div>
  </div>

</div>
