import { Component, OnInit } from '@angular/core';
import { ModalService } from 'app/componentes/modal/modal.service';
import { NotificatorService } from 'app/notificador/notificator.service';

const options = [
  {id: 0, label: 'Aprovar coleta'},
  {id: 1, label: 'Reprovar coleta'},
  {id: 2, label: 'Manter em análise'},
]

const REJECTION_MESSAGE_LENGTH_LIMIT: number = 255;

@Component({
  selector: 'app-audit-verdict',
  templateUrl: './audit-verdict.component.html',
  styleUrls: ['./audit-verdict.component.scss']
})
export class AuditVerdictComponent implements OnInit {
  renderOptions = [...options];
  selectedOption: any;
  selectIsOpen: boolean = false;
  collectionId: number = 1563;
  rejectionMessage: string;

  constructor(
    private notificator: NotificatorService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.selectedOption = this.renderOptions[0];
    this.removeSelectedOption();
    this.modalInputControl();
  }

  /**
 * Controla o valor do input da modal e atribui as devidas validações
 */
  modalInputControl() {
    this.modalService.getInput().subscribe({
      next: (inputValue) => {
          this.rejectionMessage = inputValue;

          if(inputValue !== null && !inputValue.length) {
            this.modalService.handleInputErrors(['Campo obrigatório']);
          } else if(inputValue?.length > REJECTION_MESSAGE_LENGTH_LIMIT) {
            this.modalService.handleInputErrors(['Limite de caractéres excedido']);
          } else {
            this.modalService.cleanInputErrors();
          }
        }
    })
  }

  /**
   * Remove a opção selecionada do array de opções
   */
  removeSelectedOption(): void {
    this.renderOptions = this.renderOptions.filter(op => op !== this.selectedOption);
  }

  /**
   * Controla a seleção das opções
   */
  handleSelectOption(option): void {
    this.selectedOption = option;
    this.renderOptions =  [...options];
    this.removeSelectedOption();
    this.selectIsOpen = false;
  }

  /**
   * Controla a abertura e fechamento do select
   */
  toggleSelect() {
    this.selectIsOpen = !this.selectIsOpen;
  }

  /**
   * Realizada a chamada da modal para aplicação do veredito.
   */
  handleApplyVerdict(): void {
    if(this.selectedOption === options[0]) {
      this.modalService.showModal({
        icon: 'fa-solid fa-check',
        title: 'Aprovar coleta',
        messageModal: `Deseja aprovar a coleta <b>${this.collectionId}</b>? Esta ação não pode ser desfeita.`,
        btnTitlePositive: 'Concluir',
        positiveCallback: () => this.handleSubmitVerdict(`Coleta ${this.collectionId} aprovada com sucesso!`)
      });
    } else if(this.selectedOption === options[1]) {
      this.modalService.showModal({
        icon: 'fa-solid fa-xmark',
        title: 'Reprovar coleta',
        messageModal: `Informe uma justificativa para reprovar a coleta <b>${this.collectionId}</b>.`,
        close: false,
        inputConfig: {
          show: true,
          eyeIcon: false,
          placeholder: 'Insira uma justificativa'
        },
        btnTitlePositive: 'Concluir',
        positiveCallback: () => this.rejectCollection()
      });
    } else {

      this.modalService.showModal({
        icon: 'fa-regular fa-bookmark',
        title: 'Manter em análise',
        messageModal: `A coleta <b>${this.collectionId}</b> continuará com a situação <b>Em análise</b> para auditoria e todas as alterações serão salvas. Deseja continuar?`,
        btnTitlePositive: 'Continuar',
        positiveCallback: () => this.handleSubmitVerdict(`Os dados da coleta ${this.collectionId} foram salvos com sucesso`)
      });
    }
  }

  // controla o fluxo de rejeição da modal
  rejectCollection() {
    if(!this.rejectionMessage?.length) {
      this.modalService.handleInputErrors(['Campo obrigatório'])
    } else if(this.rejectionMessage?.length > REJECTION_MESSAGE_LENGTH_LIMIT) {
      this.modalService.handleInputErrors(['Limite de caractéres excedido'])
    } else {
      this.handleSubmitVerdict(`Coleta ${this.collectionId} reprovada com sucesso!`)
    }
  }

  /**
   * Realiza a chamada ao backend para atualizar o status de auditoria [Aprovada | Em análise | Reprovada]
   */
  handleSubmitVerdict(notifyText: string): void {
    //TODO: request para o backend
    this.modalService.handleCloseModal();
    this.notificator.showInfo(notifyText, null);
  }

}
