<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-funcionarios"></i>
      <h2 [routerLink]="'/management'" routerLinkActive="active">
        Gerenciamento
      </h2>
    </div>
    <div class="action-header">
      <button
        class="sg-btn sg-secondary"
        [routerLink]="'/management/cadastro'"
        title="Criar nova instância"
      >
        ADICIONAR NOVO
      </button>
    </div>
  </div>
  <div class="content-instancias-cadastradas">
    <app-tabela-filtravel
      [ocultarCheckbox]="true"
      [nomeAtributoIdCustomizado]="nomeAtributoIdCustomizado"
      #tabela
      [placeholder]="placeholder"
      (eventEmitter)="manipularEventoTabela($event)"
      [filterService]="managementService"
      [nomeAtributosExibidos]="nomeAtributosTabela"
      [exibirOpcaoDownload]="false"
      [nomeColunas]="nomeColunasTabela"
      [exibirOpcaoDownload]="true"
      [initialValue]="initialSearchValue"
    ></app-tabela-filtravel>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
