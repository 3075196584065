import { Component, Input } from "@angular/core";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class RespostaVideoComponent extends RespostaComponent {
  @Input() private pergunta: PerguntaEsquema;
  @Input() private respostaPergunta: RespostaPergunta;

  videoResposta: string = "";

  hasVideo() {
    return this.videoResposta.length > 0;
  }

  ngOnInit() {
    super.ngOnInit();
    const respostaVideos = this.respostaPergunta.alternativasSelecionadas.map(
      (a: AlternativaSelecionada) => {
        return a.arquivo;
      }
    );

    this.videoResposta =
      respostaVideos.length > 0 ? respostaVideos[0] : this.videoResposta;
  }

  hasErrors() {
    return false;
  }

  onProximaPergunta() {
    this.notifyRespostaPergunta(this.respostaPergunta);
  }
}
