import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

export interface SelectData {
  label: string;
  value: any;
}

@Component({
  selector: "app-s-input",
  templateUrl: "./s-input.component.html",
  styleUrls: ["./s-input.component.scss"],
})
export class SInputComponent implements OnInit, OnChanges, AfterContentChecked {
  // propriedades do componente
  @Input() label: string;
  @Input() placeholder: string;
  @Input() kind: string;
  @Input() disabled: string = "false";
  @Input() data: SelectData[];
  @Input() selected: string;
  @Input() inputSelected: string;
  @Input() type: string = "text";
  @Input() min: number | string;
  @Input() max: number | string;
  @Input() maxlength: number;
  @Input() required: boolean = undefined;
  @Input() id: string;

  inputHeight: number;
  maxLengthError: boolean = false;
  requiredError: boolean = false;

  // eventos
  @Output() changedText: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    if (this.data) {
      if (!this.selected) {
        setTimeout(() => {
          this.selected = this.data[0].value;
          this.handleChange(this.selected);
        }, 0);
      }
    }
  }

  // verifica se o input é o title e chama a função para validar o tamanho do campo
  ngAfterContentChecked() {
    if (
      this.id === "title" ||
      this.id === "orientation" ||
      this.id === "objective"
    ) {
      this.checkValidations(this.inputSelected);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isNotFirstDisabledChange =
      typeof this.required !== "undefined" &&
      changes.disabled &&
      !changes.disabled.firstChange;

    // Verifica se o input estava desabilitado ou está desabilitado no momento, caso esteja, o erro
    // de required não será apresentado.
    if (
      isNotFirstDisabledChange &&
      (changes.disabled.previousValue || changes.disabled.currentValue)
    ) {
      this.requiredError = false;
    }
  }

  checkValidations(currentValue: string) {
    if (this.maxlength && currentValue) {
      this.maxLengthError =
        currentValue.length > this.maxlength - 1 ? true : false;
    }

    if (typeof this.required !== "undefined" && currentValue) {
      this.requiredError = !currentValue.length;
    }
  }

  handleChange(event: string) {
    if (
      this.id === "title" ||
      this.id === "orientation" ||
      this.id === "objective"
    ) {
      const wd = document.querySelector(`#${this.id}`) as HTMLElement;
      if (wd.scrollHeight) {
        wd.style.height = `${
          event.length < 30 ? 48 : wd.scrollHeight > 48 ? wd.scrollHeight : 48
        }px`;
      }
    }
    this.changedText.emit(event);
    this.checkValidations(event);
  }
}
