import { Directive } from '@angular/core';
import { MascaraBase } from './mascaraBase';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[mascara-localidade-nome]',
})
export class MascaraLocalidadeNomeDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 50);
  }

  onKeyUp(event): void {
    const newValue = event.target.value;
    const formattedValue = this.aplicarMascara(newValue);

    this.ngControl.control.patchValue(formattedValue);
  }

  aplicarMascara(valor: string): string {
    const stringFormatada = valor.replace(/^\ */g, '');
    return stringFormatada;
  }
}
