export enum TipoRespostaPergunta {

  SELECAO_TEXTO = 'SELECAO_TEXTO',
  FOTO = 'FOTO',
  VIDEO = 'VIDEO',
  DATA = 'DATA',
  HORARIO = 'HORARIO',
  GRADE = 'GRADE',
  ESCALA_NUMERICA = 'ESCALA_NUMERICA',

}
