import { AbstractControl, ValidationErrors } from '@angular/forms';

  export default (control: AbstractControl): ValidationErrors => {

  const cnpj = control.value;

  if (isCnpjValido(cnpj)) {
    return null;
  }

  return {
    invalid: true,
  };

};

function isCnpjValido(cnpj): boolean {
  if (!cnpj) {
    return false;
  }

  const cnpjApenasNumeros = cnpj.replace(/\D/g, '');
  
  if (cnpjApenasNumeros === '') {
    return false;
  }

  if (cnpjApenasNumeros.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (cnpjApenasNumeros === '00000000000000' ||
    cnpjApenasNumeros === '11111111111111' ||
    cnpjApenasNumeros === '22222222222222' ||
    cnpjApenasNumeros === '33333333333333' ||
    cnpjApenasNumeros === '44444444444444' ||
    cnpjApenasNumeros === '55555555555555' ||
    cnpjApenasNumeros === '66666666666666' ||
    cnpjApenasNumeros === '77777777777777' ||
    cnpjApenasNumeros === '88888888888888' ||
    cnpjApenasNumeros === '99999999999999') {

    return false;
  }

  // Valida DVs
  let tamanho = cnpjApenasNumeros.length - 2;
  let numeros = cnpjApenasNumeros.substring(0, tamanho);
  const digitos = cnpjApenasNumeros.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpjApenasNumeros.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1)) {
    return false;
  }

  return true;

}
