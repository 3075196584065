import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-interesse-listagem',
  templateUrl: './area-interesse.listagem.component.html',
  styleUrls: ['./area-interesse.listagem.component.scss'],
})

export class AreaInteresseListagemComponent implements OnInit {
  ngOnInit() { }
}
