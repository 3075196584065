import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalService } from 'app/componentes/modal/modal.service';
import { AuthUser } from 'app/infraestrutura/security/authUser';
import { Authority } from 'app/infraestrutura/security/authority';
import { SecurityService } from 'app/infraestrutura/security/service/securityService';
import { AdministradorService } from 'app/modulos/administrador-beta/service/administrador.service';
import { ClienteService } from 'app/modulos/cliente/servico/cliente.service';
import { FuncionarioService } from 'app/modulos/funcionario/servico/funcionario.service';
import { OperadorService } from 'app/modulos/operador/servico/operador.service';
import { EventoTabela } from 'app/util/componente/tabela/evento/eventoTabela';
import { TipoEventoTabela } from 'app/util/componente/tabela/evento/tipoEventoTabela';
import { TipoUsuarioSistema } from 'app/util/usuario/tipoUsuario';
import { Subscription } from 'rxjs';
import { TipoMenu } from './menu.enum';

/**
 * Componente de roteamento da aplicação (entrypoint)
 */
@Component({
  selector: "router-dashboard",
  templateUrl: "./router.component.html",
  styleUrls: ["./router.component.scss"],
})
export class RouterComponent implements OnInit {
  menuOpen: boolean = false;
  isShow: boolean = false;
  isShowMobile: boolean = false;
  nomeMenu: String;
  navigationSubscription: Subscription;
  userLogado: AuthUser;
  nomeEntidade: any;
  isLoading: boolean = false;

  /**
   * atributo responsável por criar uma ngClass que desabilitará a rota do meu Perfil
   * caso a rota ja seja de atualização do usuário
   */
  activated: boolean = false;

  tipoMenu = TipoMenu;

  activeMenu: TipoMenu = TipoMenu.DASHBOARDBETA;

  /**
   * Mapa com as altoridades necessarias
   * para exibir determinados menus do dashboard
   */
  menuAuthorityMap: Map<TipoMenu, Authority[]> = new Map();

  constructor(
    private securityService: SecurityService,
    private funcionarioService: FuncionarioService,
    private operadorService: OperadorService,
    private clienteService: ClienteService,
    private administradorService: AdministradorService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    // tslint:disable-next-line: ter-arrow-parens
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activated = false;
      }
    });
    this.userLogado = new AuthUser();
    this.userLogado = this.getUsuarioLogado();

    this.menuAuthorityMap.set(TipoMenu.GERENCIAMENTO, [
      Authority.CADASTRAR_INSTANCIA,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.CLIENTE, [
      Authority.ATUALIZAR_CLIENTE,
      Authority.CADASTRAR_CLIENTE,
      Authority.LISTAR_CLIENTE,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.FUNCIONARIO, [
      Authority.ATUALIZAR_FUNCIONARIO,
      Authority.CADASTRAR_FUNCIONARIO,
      Authority.LISTAR_FUNCIONARIO,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.LOCALIDADE, [
      Authority.LISTAR_LOCALIDADE,
      Authority.CADASTRAR_LOCALIDADE,
      Authority.ATUALIZAR_LOCALIDADE,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.OPERADOR, [
      Authority.LISTAR_OPERADOR,
      Authority.CADASTRAR_OPERADOR,
      Authority.ATUALIZAR_OPERADOR,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.GRUPO, [
      Authority.LISTAR_GRUPO,
      Authority.CADASTRAR_GRUPO,
      Authority.ATUALIZAR_GRUPO,
    ]);
    this.menuAuthorityMap.set(TipoMenu.PRODUTIVIDADE, [
      Authority.CONTROLE_PRODUTIVIDADE,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.PESQUISA, [
      Authority.CADASTRAR_PESQUISA_COMPLETA,
      Authority.LISTAR_PESQUISA,
      Authority.MUDANCA_STATUS_PESQUISA,
      Authority.CADASTRAR_PESQUISA_CARACTERISTICAS,
      Authority.CADASTRAR_PESQUISA_QUESTIONARIO,
      Authority.CADASTRAR_PESQUISA_QUESTIONARIO,
      Authority.CADASTRAR_PESQUISA_LOCALIDADES,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.PESQUISABETA, [
      Authority.CADASTRAR_PESQUISA_COMPLETA,
      Authority.LISTAR_PESQUISA,
      Authority.MUDANCA_STATUS_PESQUISA,
      Authority.CADASTRAR_PESQUISA_CARACTERISTICAS,
      Authority.CADASTRAR_PESQUISA_QUESTIONARIO,
      Authority.CADASTRAR_PESQUISA_QUESTIONARIO,
      Authority.CADASTRAR_PESQUISA_LOCALIDADES,
    ]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.AUDITORIA, [Authority.AUDITORIA_PASSO_1, Authority.AUDITORIA_PASSO_2]);
    // tslint:disable-next-line: max-line-length
    this.menuAuthorityMap.set(TipoMenu.RESULTADOS, [Authority.ACESSO_RESULTADO]);
  }

  // tslint:disable-next-line: max-line-length
  // Verifica se a tela for maior que 1000px para executar a alteração, no mobile ignora os eventos de mouse over e mouse out
  actionMenu() {
    if (window.screen.width > 1000) {
      this.isShow = !this.isShow;
    } else {
      // só vai
    }
  }

  // Função própria para os botões que só são visíveis no mobile
  actionMenuMobile() {
    this.isShowMobile = !this.isShowMobile;
  }

  onMenuItemClick(tipoMenu: TipoMenu) {
    this.activeMenu = tipoMenu;
    this.handleHideMenu();
  }

  handleHideMenu() {
    this.isShowMobile = !this.isShowMobile;
  }

  isMenuActive(tipoMenu: TipoMenu) {
    return this.activeMenu === tipoMenu;
  }

  subMenu() {
    // this.subMenuOpen = !this.subMenuOpen;
  }

  doLogout() {
    this.securityService.logout();
    this.router.navigate(["login"], { queryParams: { redirect: true } });
  }

  // pega o usuário que está logado
  getUsuarioLogado(): AuthUser {
    const user: AuthUser = this.securityService.getAuthenticatedUser();
    return user;
  }
  /**
   * redireciona o usuario logado para sua página de edição
   * caso o usuário não tenha permissão para editar seu própio perfil, será emitido um alerta
   */
  getPerfilUsuarioLogadoToEdicao() {
    const userLogado = this.getUsuarioLogado();

    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_FUNCIONARIO) {
      const listagemAuthorities = [Authority.EDICAO_PERFIL_FUNCIONARIO];
      const temPermissao =
        this.securityService.userHasAuthorities(listagemAuthorities);
      if (temPermissao) {
        this.perfilFuncionario(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_ADMINISTRADOR) {
      const listagemAuthorities = [Authority.ATUALIZAR_FUNCIONARIO];
      const temPermissao =
        this.securityService.userHasAuthorities(listagemAuthorities);

      if (temPermissao) {
        this.perfilAdministrador(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_CLIENTE) {
      const listagemAuthorities = [Authority.ATUALIZAR_CLIENTE];
      const temPermissao =
        this.securityService.userHasAuthorities(listagemAuthorities);

      if (temPermissao) {
        this.perfilCliente(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
    if (userLogado.tipoUsuario === TipoUsuarioSistema.ROLE_OPERADOR) {
      const listagemAuthorities = [Authority.ATUALIZAR_OPERADOR];
      const temPermissao =
        this.securityService.userHasAuthorities(listagemAuthorities);

      if (temPermissao) {
        this.perfilOperador(userLogado.id);
        return;
      }

      this.emitirAlertaSemPermissao();
    }
  }

  //
  perfilAdministrador(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = "administrador";
    this.administradorService
      .getIdAdministradorPorIdContaUsuario(payload)
      .subscribe((result) => {
        result !== null ? result : null;

        const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, result);
        this.manipularEventoEdicao(eventoEdicao);
        this.isLoading = false;
      });
  }

  //
  emitirAlertaSemPermissao() {
    const redirect = () => {
      this.router.navigate([""]);
    };
    this.modalService.showModal({
      title: "Meu perfil",
      messageModal: "Você não tem permissão para acessar esta página",
      btnTitlePositive: "ok",
      positiveCallback: () => redirect(),
      isOnlyConfirmation: true,
    });
  }

  //
  perfilFuncionario(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = "funcionario";
    const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, 0);
    this.manipularEventoEdicaoPerfil(eventoEdicao);
    this.isLoading = false;
  }

  //
  perfilOperador(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = "operador";

    this.operadorService
      .getIdOperadorPorIdContaUsuario(payload)
      // tslint:disable-next-line: ter-arrow-parens
      .subscribe((result) => {
        result !== null ? result : null;

        const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, result);
        this.manipularEventoEdicao(eventoEdicao);
        this.isLoading = false;
      });
  }

  //
  perfilCliente(payload: string) {
    this.isLoading = true;
    this.nomeEntidade = "cliente";

    this.clienteService
      .getIdClientePorIdContaUsuario(payload)
      .subscribe((result) => {
        result !== null ? result : null;

        const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, result);
        this.manipularEventoEdicao(eventoEdicao);

        this.isLoading = false;
      });
  }

  //
  manipularEventoEdicao(eventoTabela: EventoTabela) {
    // verifica se a rota atual é a mesma que o usuario será redirecionado
    if (this.router.url.includes(`${this.nomeEntidade}/atualizar`)) {
      this.activated = true;
      return;
    }
    this.activated = false;
    // tslint:disable-next-line: max-line-length
    this.router.navigate([this.nomeEntidade, "atualizar"], {
      queryParams: { id: eventoTabela.payload },
    });
  }

  //
  manipularEventoEdicaoPerfil(eventoTabela: EventoTabela) {
    // verifica se a rota atual é a mesma que o usuario será redirecionado
    if (this.router.url.includes(`${this.nomeEntidade}/perfil`)) {
      this.activated = true;
      return;
    }
    this.activated = false;
    // tslint:disable-next-line: max-line-length
    // this.router.navigate(['funcionario', 'atualizar'], {state: eventoTabela} );
    this.router.navigate([this.nomeEntidade, "perfil"], {
      queryParams: { id: 1 },
      state: eventoTabela,
    });
  }

  /**
   * Metodo que define se determinado
   * menu sera exibido dependendo
   * das permissões do funcionario logado
   */
  showMenu(menu: TipoMenu): boolean {
    const currentUserAuthorities =
      this.securityService.getAuthenticatedUserAuthorities();
    const necessariesAuthorities = this.menuAuthorityMap.get(menu);

    // tslint:disable-next-line: max-line-length
    const hasNecessaryAuthority = currentUserAuthorities.some((userAuthority) =>
      necessariesAuthorities.includes(userAuthority)
    );

    return hasNecessaryAuthority;
  }

  isActive(domMenuItem) {
    return domMenuItem.classList.contains("active");
  }

  handleLoading(event: boolean) {
    this.isLoading = event;
  }
}
