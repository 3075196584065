import { Component, OnInit } from '@angular/core';
import { IAuditCollections, IFilterSelect } from '../../../interfaces';
import { AuditoriaBetaService } from '../../../services/auditoria-beta.service';
import { adaptCollectionsListRespToModels } from '../../../utils/parsers/collectionsListRespToModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-audit-collections-section',
  templateUrl: './audit-collections-section.component.html',
  styleUrls: ['./audit-collections-section.component.scss']
})
export class AuditCollectionsSectionComponent implements OnInit {

  collectionsListData: IAuditCollections[];

  surveyId: number = this.route.snapshot.params["id"]; // representa o id da pesquisa selecionada
  totalCollections: number = 0;
  totalPages: number = 1;

  collectionsQueryParams: any = {
    currentPage: 1,
    surveysPerPage: 10,
    filtrableAttributes: "",
    filtrableOrder: "",
    keywords: [],
    status: [],
  }

  isLoading: boolean = false;


  constructor(
    private auditService: AuditoriaBetaService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initializeCollectionsListData();
  }

  /**
   * Inicializa o número total de páginas utilizado no componente de paginação
   * com base no número total de pesquisas e a quantidade de itens por página.
   */
  initializePaginationAttributes() {
    const { surveysPerPage } = this.collectionsQueryParams;
    if (this.totalCollections === 0) {
      this.totalCollections = 1;
    }

    this.totalPages = Math.ceil(this.totalCollections / surveysPerPage);
  }

  totalCollectionsRegistered() {
    const { keywords, status } = this.collectionsQueryParams;
    const filters = {
      palavrasChave: [ ...keywords ],
      situacoes: [ ...status ]
    }

    this.auditService.getTotalCollections(filters, this.surveyId).subscribe({
      next: (data) => this.totalCollections = data,
    })
  }

  handleChangeFilter($event: { status: IFilterSelect[], keywords: string, sorting: IFilterSelect[] }) {
    const [selectedSortingOption] = $event.sorting;
    const [sortingType = "", direction = ""] = (selectedSortingOption.key || "").split('-');

    this.collectionsQueryParams.status = [...$event?.status.map(v => v.key)];
    this.collectionsQueryParams.keywords = [$event.keywords];
    this.collectionsQueryParams.filtrableAttributes = sortingType;
    this.collectionsQueryParams.filtrableOrder = direction;

    this.initializeCollectionsListData();
  }


  /**
   * Realiza a request para obter os dados da listagem de coletas
   */
  initializeCollectionsListData(): void {
    this.isLoading = true;

    this.totalCollectionsRegistered();

    this.auditService.getCollectionsListData(
      this.surveyId,
      this.collectionsQueryParams
      ).subscribe({
      next: (data) => {
        this.initializePaginationAttributes()
        this.collectionsListData = adaptCollectionsListRespToModels(data);
      },
      complete: () => {
        this.isLoading = false;
      },

      error: (err) => {
        this.isLoading = false;
        console.error(err)
      }
    })
  }

    // navega entre as páginas
  changePage($event: number) {
    this.collectionsQueryParams.currentPage = $event;

    this.initializeCollectionsListData();
  }

}
