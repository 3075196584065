<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-localidades"></i>
      <h2 [routerLink]="'/localidade'" routerLinkActive="active">
        Localidades
      </h2>
      <span>/</span>
      <h4>localidades cadastradas</h4>
    </div>
    <div
      class="action-header"
      *ngIf="
        getAuthenticatedUserAuthorities().includes(
          authority.CADASTRAR_LOCALIDADE
        )
      "
    >
      <button
        class="sg-btn sg-secondary"
        (click)="mostrarModalCadastro($event)"
        title="Adicionar nova localidade"
      >
        ADICIONAR NOVO
      </button>
    </div>
  </div>

  <div class="content-localidades-cadastrados">
    <div class="tableActions no-print">
      <div class="actions">
        <div
          *ngIf="exibirExclusao"
          class="icon-action"
          [class.icon-disabled]="!(localidadesTrSelecionadas.length > 0)"
          (click)="EventoExclusaoItensSelecionados()"
        >
          <span class="icon-lixeira" title="excluir itens selecionados"> </span>
        </div>
        <!--

          DESABILITANDO A DUPLICAÇÃO DE LOCALIDADES
          por não ser contemplada na release básica
          do tensai.

        -->
        <!--
        <div class="icon-action" [class.icon-disabled]="!(localidadesTrSelecionadas.length > 0) || !isPaiPrimeiroNivel"
          (click)="duplicarLocalidade()">
          <span class="fa fa-clone" title="duplicar localidade"></span>
        </div>
        -->
      </div>

      <div class="sg-input-group">
        <div class="campo-pesquisa">
          <label for="">Filtrar por palavras-chave:</label>
          <input
            title="Filtrar por palavra-chave"
            (input)="listarLocalidades($event)"
            maxlength="50"
            placeholder="ex.: joão pessoa"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="tableContent">
      <table #tabela class="sg-table">
        <thead>
          <th scope="col" class="table-checkbox-wrapper">
            <label class="clickable" title="Marcar itens da página">
              <input
                type="checkbox"
                [checked]="
                  localidadesTrSelecionadas.length > 0 && selecionarTodos
                "
                (click)="acionarSelecionarTodos()"
              />
            </label>
          </th>
          <th scope="col" class="theadVazio"></th>
          <th scope="col" class="theadLocalidade">Localidade</th>
          <th scope="col">Visualizar</th>
          <th scope="col" *ngIf="exibirExclusao">Excluir</th>
        </thead>
        <tbody
          *ngFor="let localidadeTr of localidadesTr"
          [class.isChild]="localidadeTr.hierarquia > 20"
        >
          <tr>
            <td title="Selecionar" class="table-checkbox-wrapper">
              <input
                class="sg-check"
                type="checkbox"
                [checked]="localidadeTr.checkBoxSelected"
                (click)="selectLocalidadesTr(localidadeTr)"
              />
            </td>
            <td title="Filhos" class="check-acordion">
              <img
                *ngIf="
                  localidadeTr.possuiFilho &&
                  localidadeTr.localidadesFilhasTr.length === 0
                "
                (click)="
                  localidadeTr.selected = !localidadeTr.selected;
                  listarLocalidadesFilhas(localidadeTr.localidade.id)
                "
                src="assets/images/plus.svg"
                alt=""
              />
              <img
                *ngIf="
                  localidadeTr.possuiFilho &&
                  localidadeTr.localidadesFilhasTr.length > 0
                "
                (click)="
                  localidadeTr.selected = !localidadeTr.selected;
                  esconderLocalidadesFilhas(localidadeTr.localidade.id)
                "
                src="assets/images/minus.svg"
                alt=""
              />
            </td>
            <td
              title="Localidade"
              class="nomeLocalidade"
              [style.padding-left.px]="localidadeTr.hierarquia"
            >
              <img
                *ngIf="localidadeTr.hierarquia > 20"
                src="assets/images/icon-setaabaixo.svg"
              />
              <span>{{ localidadeTr.localidade.nome }}</span>
            </td>
            <td
              title="Editar"
              (click)="editarLocalidade(localidadeTr.localidade.id)"
            >
              <i class="fa fa-eye clickable" title="Visualizar/Editar"></i>
            </td>
            <td
              title="Excluir"
              *ngIf="exibirExclusao"
              (click)="excluirLocalidade([localidadeTr.localidade.id])"
            >
              <i class="fa fa-trash-o clickable" title="Excluir"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paginacao-wrapper no-print">
        <app-paginacao
          [indicadorReinicializacao]="subjectPaginacao"
          (onPaginationChange)="onPaginationChange($event)"
        >
        </app-paginacao>
      </div>
    </div>
    <app-loading *ngIf="isLoading"></app-loading>

    <!-- Aqui vem o modal de cadastro e da edição de localidade -->
    <app-modal-generico
      [modal]="alertaModal"
      *ngIf="alertaModal && alertaModal.show"
    >
      <app-localidade-cadastro
        [edicao]="isEdicao"
        [localidadeEdicao]="localidadeEdicao"
        (eventEmitter)="handleCadastroEvents($event)"
      ></app-localidade-cadastro>
    </app-modal-generico>
  </div>
</div>
