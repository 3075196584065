<div class="dashboard-content">
  <div class="left-section">
    <header class="global-vision">
      <div class="info">
        <div class="info">
          <img src="../../../assets/images/tablet-rezise.png" alt="Visão Global" />
          <div>
            <h4>Visão Geral</h4>
            <p>
              Gerencie e otimize os <br />
              resultados de suas pesquisas
            </p>
          </div>
        </div>
        <div class="group-options">
          <a [routerLink]="'/pesquisa-beta'">
            <p>
              Gerenciar <br />
              pesquisas
            </p>
          </a>
          <a [routerLink]="'/cliente'">
            <p>
              Gerenciar <br />
              clientes
            </p>
          </a>
          <a [routerLink]="'/produtividade'">
            <p>
              Ver <br />
              Produtividade
            </p>
          </a>
        </div>
      </div>
    </header>
    <main>
      <app-global-card [link]="'/operador/cadastro'" [title]="'Entrevistadores'" [firstColumnTitle]="'Cadastrados'"
        [firstColumnData]="totalOperadores" [activatedColumnTitle]="'Ativos'"
        [activatedColumnData]="totalOperadoresAtivos" [inactivatedColumnTitle]="'Inativos'"
        [inactivatedColumnData]="totalOperadoresInativos" [show-add-button]="true">
      </app-global-card>
      <app-global-card [link]="'/pesquisa-beta/cadastro-beta'" [title]="'Pesquisas'" [firstColumnTitle]="'Realizadas'"
        [firstColumnData]="totalPesquisaRealizada" [activatedColumnTitle]="'Em Andamento'"
        [activatedColumnData]="totalPesquisaEmAndamento" [inactivatedColumnTitle]="'Concluídas'"
        [inactivatedColumnData]="totalPesquisaEncerrada" [show-add-button]="true">
      </app-global-card>
      <app-global-card [link]="'/cliente/cadastro'" [title]="'Clientes'" [firstColumnTitle]="'Cadastrados'"
        [firstColumnData]="totalClientes" [activatedColumnTitle]="'Ativos'" [activatedColumnData]="totalClientesAtivos"
        [inactivatedColumnTitle]="'Inativos'" [inactivatedColumnData]="totalClientesInativos" [show-add-button]="true">
      </app-global-card>
      <app-global-card [title]="'Coletas'" [firstColumnTitle]="'Realizadas'"
        [firstColumnData]="totalEntrevistaRealizada" [activatedColumnTitle]="'Auditadas'"
        [activatedColumnData]="totalEntrevistaAuditada" [inactivatedColumnTitle]="'Não Auditadas'"
        [inactivatedColumnData]="totalEntrevistaNaoAuditada">
      </app-global-card>
    </main>
    <!-- <footer>
      <i class="fa fa-calendar-o"></i>
      <p>Última atualização: <span>21/09/21 | 19:51</span></p>
    </footer> -->
  </div>
  <div class="recent-researches">
    <header></header>
    <main>
      <ul>
        <li *ngFor="let pesquisa of pesquisas">
          <a [routerLink]=" pesquisa.status === 'RASCUNHO' ? ['/pesquisa-beta/cadastro-beta/'] : ['/pesquisa-beta/informacoes-beta']"
            [queryParams]="{id: pesquisa.id}"
            [title]="pesquisa.tituloCurto"
          >
            {{ pesquisa.tituloCurto }}
          </a>
        </li>
      </ul>
    </main>
    <footer>
      <a [routerLink]="'/pesquisa-beta'">Ver todas</a>
     </footer>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>
