<div class="modal-localidade-container">
  <div
    class="box-content animate-up delay-2"
    [ngClass]="
      (handleCanStretchContainer() || handleCanShowManualForm()) &&
      'strechContent'
    "
  >
    <button
      *ngIf="handleCanShowGoBackButton()"
      type="button"
      class="goBack-button"
      (click)="handleGoBack()"
    >
      <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
      <span>Voltar</span>
    </button>
    <main>
      <section class="localidades">
        <div class="localidades-group">
          <!--
            Condicional para exibir a lista de localidades no topo do formulário
            -> Edição de localidade filha
            -> Modo zero do cadastro e negar outras possibilidades (formulário para localidade manual, mostrar formulário nominatim)
          -->
          <app-localidades-list
            *ngIf="
              !handleCanShowManualForm() &&
              (handleGetWhoIsEditing() === 'CHILDREN' ||
                handleGetWhoIsEditing() === 'NONE') &&
              (handleGetWhichIdShouldILook() || !handleCanShowForm())
            "
            [ngStyle]="{ width: '100%' }"
            [getLocalidadePai]="handleGetLocalidadePai()"
            [getLocalidadesFilhas]="handlegetLocalidadesFilhas()"
            [isShowingForm]="handleCanShowGoBackButton()"
            [whoIsEditing]="handleGetWhoIsEditing()"
          ></app-localidades-list>
          <app-nominatim-form
            *ngIf="handleCanShowForm()"
            [isChildrenLocation]="handleCanCreateChildren()"
            [whoId]="handleGetWhoId()"
            [whoIsEditing]="handleGetWhoIsEditing()"
          ></app-nominatim-form>
          <!--
            Condicional para exibir a lista de localidades no fim do formulário
            -> Edição de localidade pai
          -->
          <app-localidades-list
            *ngIf="
              handleSeeFatherData() &&
              !handleCanShowManualForm() &&
              handleGetWhoIsEditing() === 'FATHER'
            "
            [ngStyle]="{ width: '100%' }"
            [getLocalidadePai]="handleGetLocalidadePai()"
            [getLocalidadesFilhas]="handlegetLocalidadesFilhas()"
            [isShowingForm]="handleCanShowGoBackButton()"
            [whoIsEditing]="handleGetWhoIsEditing()"
          ></app-localidades-list>
          <app-manual-form
            *ngIf="handleCanShowManualForm()"
            [canRegisterFather]="handleSeeFatherData()"
          >
          </app-manual-form>
        </div>
        <button
          *ngIf="handleCanShowButton()"
          class="btn-open-form"
          type="button"
          (click)="handleShowChildrenLocationForm()"
        >
          <i class="fa fa-plus"></i>
          <span>Adicionar localidade filha</span>
        </button>
      </section>
      <section class="map-content">
        <app-leaflet-map [canMountMap]="handleCanMountMap()"></app-leaflet-map>
      </section>
    </main>
    <footer
      [ngClass]="handleGetWhoIsEditing() === 'CHILDREN' && 'footer-margin-top'"
    >
      <button id="cancel" type="button" (click)="handleCloseModal()">
        CANCELAR
      </button>
      <button
        id="finished"
        type="button"
        (click)="handleFinishCreateLocation()"
        [disabled]="!handleSeeFatherData()"
      >
        CONCLUIR
      </button>
    </footer>
  </div>
</div>
