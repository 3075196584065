export enum PassoCadastro {

    /**
     * Geral significa que é algo que faz parte
     * do cadastro como um todo e nao de um passo em si
     */
    GERAL = 'GERAL',
    CARACTERISTICAS = 'CARACTERISTICAS',
    QUESTIONARIO = 'QUESTIONARIO',
    MARCACOES = 'MARCACOES',
    LOCALIDADES = 'LOCALIDADES',
    GERENCIADOR_COTAS = 'GERENCIADOR_COTAS',

}

const mapaPassoCadastroNumber: Map<number, PassoCadastro> = new Map();
mapaPassoCadastroNumber.set(0, PassoCadastro.CARACTERISTICAS);
mapaPassoCadastroNumber.set(1, PassoCadastro.QUESTIONARIO);
mapaPassoCadastroNumber.set(2, PassoCadastro.MARCACOES);
mapaPassoCadastroNumber.set(3, PassoCadastro.LOCALIDADES);
mapaPassoCadastroNumber.set(4, PassoCadastro.GERENCIADOR_COTAS);

export { mapaPassoCadastroNumber };
