<div class="select--content">
  <div #select class="select" [style.height]="showBody ? 'auto' : ''">
    <!--  -->
    <div class="select-placeholder">
      <div class="label">
        <label *ngIf="!selectedItem">{{label}}</label>
        <span class="selector-header-text" *ngIf="!selectedItem">{{placeholder}}</span>
        <div class="selected-item" *ngIf="selectedItem">
          <div>
            <div class="imagem" (click)="handleDeleteSelectedItem()">
            </div>
            <div class="label">
              <label>{{label}}</label>
              <span class="selected-header-text">{{selectedItem.label}}</span>
            </div>
          </div>
          <!-- como o campo do input foi inicializado como undefined, coloquei o placeholder como 0,
          para que o usuario digite o primeiro numero e o zero não entre no valor digitado no input e não atrapalhe o usuario ao digitar o valor desejado -->
          <input type="number" placeholder="0" min="0" [max]="maxRange" [ngModel]="selectedValue" (ngModelChange)="handleInputChange($event)"
            [ngClass]="changedByInput && 'out-of-range'" />
        </div>
      </div>
      <div #chevronButton class="divider">
        <span [class]="!showBody ?  'chevron' : 'chevron-rotate' "></span>
      </div>
    </div>
    <!-- seletor de busca de itens -->
    <div [style.display]="showBody ? 'block' : 'none'">
      <div class="selector-body" #selectorbody>
        <div class="selector-body-input">
          <input
            [ngModel]="filter"
            type="text"
            (ngModelChange)="handleChange($event)"
            *ngIf="data"
          />
          <img src="assets/images/new_search.svg" alt="">
        </div>
        <div class="selector-body-options">
          <div *ngFor="let item of filteredData; let itemIdx = index">
            <div class="option" [class.selected]="selectedItem && item.value === selectedItem.value"
              *ngIf="!item.hidden && !itemIsSelected(item)" (click)="handleItemSelect(item, itemIdx)">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
