import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { IAuditedCollections, ISurveyStatus } from "../../interfaces";

@Component({
  selector: "app-audited-collections-header",
  templateUrl: "./audited-collections-header.component.html",
  styleUrls: ["./audited-collections-header.component.scss"],
})
export class AuditedCollectionsHeaderComponent implements OnInit, OnChanges {
  @Input() surveyTitle: string; // titulo da pesquisa
  @Input() surveyStatus: ISurveyStatus; // Situação da pesquisa
  @Input() auditedCollections: IAuditedCollections; // { Coletas && percentual }

  percentualValue: number = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.getAuditedPercentual();
  }

  /**
   * Obtem o valor percentual de pesquisas auditadas
   */
  getAuditedPercentual(): void {
    if (
      typeof this.auditedCollections.auditadas === "number" &&
      typeof this.auditedCollections.meta_auditoria === "number"
    ) {
      this.percentualValue =
        (this.auditedCollections.auditadas /
          this.auditedCollections.meta_auditoria) *
        100;
    }
  }
}
