import { Injectable } from '@angular/core';
import { RespostaPergunta } from './model/respostaPergunta';
import { TipoPergunta } from '../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';

@Injectable({
  providedIn: 'root'
})
export class RespostaDescricaoFactoryService {

  private mapper : Map<string, (respostaPergunta : RespostaPergunta) => string> = new Map();

  constructor() {

    this.addStrategy(
      [
        TipoPergunta.ESCALA_NUMERICA, TipoPergunta.GRADE_MULTIPLA,
        TipoPergunta.MULTIPLA, TipoPergunta.UNICA,
      ],
      this.extractRespostaDescricaoAlternativas,
    );

    this.addStrategy(
      [
        TipoPergunta.MULTIPLA_ORDEM_CITACAO,
      ],
      this.extractRespostaDescricaoAlternativasOrdem,
    );

    this.addStrategy([TipoPergunta.GRADE_UNICA], this.extractRespostaDescricaoGrade);
    this.addStrategy([TipoPergunta.GRADE_MULTIPLA], this.extractRespostaDescricaoGradeMultipla);
    this.addStrategy([TipoPergunta.DATA], this.extractRespostaDescricaoData);
    this.addStrategy([TipoPergunta.HORARIO], this.extractRespostaDescricaoHorario);
    this.addStrategy([TipoPergunta.VIDEO, TipoPergunta.FOTO], (res: any) => '');
  }

  addStrategy(tipos : string[], callback : (respostaPergunta : RespostaPergunta) => string) : void {
    tipos.forEach((tipo) => {
      this.mapper.set(tipo, callback);
    });
  }

  /**
   * Recupera a descrição geral, em texto, da respostaPergunta passada por parâmetro.
   * @param respostaPergunta instância de RespostaPergunta contendo os
   * dados da pergunta e da resposta.
   */
  getRespostaDescricao(respostaPergunta : RespostaPergunta) : string {
    const tipoPergunta = respostaPergunta.pergunta.tipo;
    const respostaDescricaoCallback = this.mapper.get(tipoPergunta);
    return !!respostaPergunta.alternativasSelecionadas ? respostaDescricaoCallback(respostaPergunta) : null;
  }

  extractRespostaDescricaoGrade(respostaPergunta : RespostaPergunta) : string {

    // tslint:disable-next-line: max-line-length
    return respostaPergunta.getDescricaoAlternativasSelecionadasGrade();
  }

  extractRespostaDescricaoGradeMultipla(respostaPergunta : RespostaPergunta) : string {
    return respostaPergunta.getDescricaoAlternativasSelecionadasGradeMultipla();
  }

  extractRespostaDescricaoAlternativas(respostaPergunta : RespostaPergunta) : string {
    return respostaPergunta.getDescricaoAlternativasSelecionadas();
  }

  extractRespostaDescricaoAlternativasOrdem(respostaPergunta : RespostaPergunta) : string {
    return respostaPergunta.getDescricaoAlternativasOrdemCitacao();
  }

  extractRespostaDescricaoData(respostaPergunta : RespostaPergunta) : string {
    return respostaPergunta.getDescricaoRespostaData();
  }

  extractRespostaDescricaoHorario(respostaPergunta : RespostaPergunta) : string {
    return respostaPergunta.getDescricaoRespostaHorario();
  }

}

