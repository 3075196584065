import { Component, Input, OnInit } from '@angular/core';
import { DefaultImagem } from 'styles/constant';
import { IImageTheme } from 'styles/interface';
import { ThemeService } from 'styles/services/theme.service';
import { LgpdService } from '../../servico/lgpdService/lgpd.service';

@Component({
  selector: 'app-moldura-telas-de-acesso',
  templateUrl: './moldura-telas-de-acesso.component.html',
  styleUrls: ['./moldura-telas-de-acesso.component.scss'],
})

export class MolduraCredenciaisComponent implements OnInit {
  @Input() title: string = "";
  @Input() subtitle: string = "";
  public year: number
  contractorImages: IImageTheme;
  defaultLogo: string = DefaultImagem.logotipo_light;
  isImageDefault: boolean;

  privacyPolicyPDF: string = '#';
  termsOfUsagePDF: string = '#';

  constructor(
    private themeService: ThemeService,
    private lgpdService: LgpdService
  ) {}

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.getContractorImages();
    this.recoverLgpdDocuments();
  }

  /**
   * Recupera as imagens do contratante
   */
  getContractorImages(): void {
    this.themeService.currentImages.subscribe({
      next: (img) => {
        this.contractorImages = img;
      }
    });
  }

  /**
   * Recupera os documentos lgpd
   */
  recoverLgpdDocuments(): void {
    this.lgpdService.getLgpdDocuments().subscribe({
      next: (data) => {
        data && data.forEach(({type, location}) => {
          if(type === 'PRIVACY_POLICY') {
            this.privacyPolicyPDF = location;
          } else {
            this.termsOfUsagePDF = location;
          }
        })
      }
    })
  }
}
