import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CadastroPesquisaStoreState } from 'app/modulos/pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { syncFormGroup } from 'app/util/misc/syncControl';
import { AbstractPerguntaComponent } from '../abstractPerguntaComponent';

/**
 * Classe base para os inputs de grade
 */
export abstract class GradeComponent extends AbstractPerguntaComponent {
  constructor(store: Store<CadastroPesquisaStoreState>) {
    super(store)
  }

  /**
   * @override
   */
  onNestedInputChange(payload: any) {
    // console.log('[GradeComponent] onNestedInputChange payload', payload);

    const titulosGradeFG: UntypedFormGroup = payload;
    syncFormGroup(titulosGradeFG, this.controls);

    this.onChange();
  }

}
