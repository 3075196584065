import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { formsLocation } from "app/infraestrutura/apiLocation";
import {
  FormMode,
  ModeChangeEvent
} from "app/modulos/pesquisa-beta/cadastro/events";
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import { PesquisaService } from "app/modulos/pesquisa-beta/listagem/servico/pesquisas";
import { TipoPergunta } from "app/modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";
import { NotificatorService } from 'app/notificador/notificator.service';
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import {
  AlternativaRequest, ICanNotSaveButton, PesquisaRequest
} from "../../pesquisas-cadastro.model";

export enum TipoAlternativa {
  FECHADA = "FECHADA",
  ABERTA_TEXTO = "ABERTA_TEXTO",
  ABERTA_NUMERO = "ABERTA_NUMERO",
  GRADE = "GRADE",
  ESCALA_NUMERICA = "ESCALA_NUMERICA",
}

interface EventsProps {
  sectionId: number;
  questionId: number;
}

export interface VinculoPergunta {
  id?: number;
  codigoMarcacao: string;
}

export interface Alternativa {
  id: number;
  tipoAlternativa: TipoAlternativa;
  marcacaoPergunta?: VinculoPergunta;
  comportamento?: string;
  descricao: string;
}

export interface Pergunta {
  ordem: number;
  id: number;
  nome?: string;
  titulo: string;
  tipo: TipoPergunta;
  codigoMarcacao: string;
  orientacao: string;
  obrigatorio: boolean;
  alternativas?: Alternativa[];
  faixaPergunta?: {
    intervaloInicio: number;
    intervaloFim: number;
  };
  titulosGrade?: TitulosGradeItem[];
  marcacaoPergunta?: VinculoPergunta;
}

export interface TitulosGradeItem {
  id: number;
  ordem: number;
  descricao: string;
}
export interface Secao {
  index: number;
  ordem: number;
  id: number;
  nome: string;
  descricao: string;
  perguntas: Pergunta[];
}

type SaveMetaData = {
  metadata: { name: string; seq: number };
  model: PesquisaRequest;
};

@Component({
  selector: "app-pesquisas-logica",
  templateUrl: "./pesquisas-logica.component.html",
  styleUrls: ["./pesquisas-logica.component.scss"],
})
export class PesquisasLogicaComponent implements OnInit, OnChanges {
  /**
   * Propriedade interna do componente responsável por exibir o detalhamento
   * de uma pergunta
   */
  private _showDetails: boolean = false;

  /**
   * Propriedade que recebe os dados referente a Seção
   * baseando-se na Pergunta escolhida
   */
  private _chooseSection: Secao;

  // Propiedade que recebe os dados da pergunta escolhida pelo usuário
  private _chooseQuestion: Pergunta;

  /**
   * Atual posição do passo no formulário
   */
  @Input() currentIndex: number;

  /**
   * entrada
   */
  @Input() input: PesquisaRequest;

  @Output() onSave: EventEmitter<SaveMetaData> = new EventEmitter();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter()

  /**
   * Função responsável por avançar um passo no formulário
   */
  @Output() onNextStep = new EventEmitter<void>();

  /**
   * Array de Seções com suas respectivas perguntas
   */
  lista: Secao[];

  tokenRef: string = "";

  constructor(
    private eventBus: EventBus,
    public pesquisaService: PesquisaService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  addInitialValues() {
    this.lista = this.input.elementosQuestionario.map((item, index) => {
      return {
        index,
        descricao: item.secao.descricao ? item.secao.descricao : null,
        nome: item.secao.nome ? item.secao.nome : "",
        id: item.id ? item.id : index,
        ordem: item.ordem ? item.ordem : "",
        perguntas: item.secao.elementosSecao
          ? item.secao.elementosSecao.map((elemento, elementoIndex) => {
              return {
                ordem: elemento.ordem,
                id: elemento.pergunta.id ? elemento.pergunta.id : elementoIndex,
                // logica
                codigoMarcacao: elemento.pergunta.codigoMarcacao,
                obrigatorio: elemento.pergunta.obrigatoria
                  ? elemento.pergunta.obrigatoria
                  : null,
                orientacao: elemento.pergunta.descricao,
                tipo: elemento.pergunta.tipo ? elemento.pergunta.tipo : null,
                titulo: elemento.pergunta.nome,
                marcacaoPergunta: elemento.pergunta.marcacaoPergunta
                  && elemento.pergunta.marcacaoPergunta.perguntaDestino.codigoMarcacao
                  ? {
                      id: elemento.pergunta.marcacaoPergunta.id
                        ? elemento.pergunta.marcacaoPergunta.id
                        : null,
                      codigoMarcacao:
                        elemento.pergunta.marcacaoPergunta.perguntaDestino
                          .codigoMarcacao,
                    }
                  : null,
                faixaPergunta: elemento.pergunta.faixaPergunta ? elemento.pergunta.faixaPergunta : null,    
                titulosGrade: elemento.pergunta.titulosGrade ? elemento.pergunta.titulosGrade : null,            
                alternativas: elemento.pergunta.alternativas
                  ? elemento.pergunta.alternativas.map((alternativa, i) => {
                      return alternativa.marcacao &&
                        alternativa.marcacao.perguntaDestino
                        ? {
                            //  Foi necessario criar esse spread operator para que não se perca algum dado referente à alternativa
                            ...alternativa,
                            id: i,
                            tipoAlternativa: alternativa.tipoAlternativa,
                            descricao: alternativa.descricao,
                            marcacaoPergunta:
                              alternativa.marcacao.perguntaDestino
                                .codigoMarcacao,
                            comportamento: alternativa.comportamento ? alternativa.comportamento : 'CONTINUAR_ENTREVISTA'
                          }
                        : ({
                            //  Foi necessario criar esse spread operator para que não se perca algum dado referente à alternativa
                            ...alternativa,
                            id: i,
                            tipoAlternativa: alternativa.tipoAlternativa,
                            descricao: alternativa.descricao,
                            comportamento: alternativa.comportamento ? alternativa.comportamento : 'CONTINUAR_ENTREVISTA'
                          } as Alternativa);
                    })
                  : [],
              } as Pergunta;
            })
          : [],
      } as Secao;
    });
  }

  ngOnInit() {
    this.tokenRef = this.input.configuracaoPesquisa.tokenVisualizacao;
    if (this.input && this.input.elementosQuestionario) {
      this.addInitialValues();
    }

    // emitindo como false para habilitar na listagem no passo 3
    this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: false }})
  }

  // remontando o componente a cada evento
  ngOnChanges(): void {
    this.ngOnInit()
  }

  // função que repassa para o componente filho se é possível avançar ou não
  onCanNotSaveButtonQuestion($event: ICanNotSaveButton) {
    this.onCanNotSaveButton.emit($event)
  }

  // função que lida com botão salvar
  handleSaveAndOutputData(type: string) {
    // propaga dados
    this.onSave.emit({ metadata: { name: type, seq: null }, model: this.input });
    // notificar o mudança no modo da pesquisa
    this.eventBus.fire(new ModeChangeEvent(FormMode.Done));
  }

  adicionarLogicaAUmaPerguntaComAlternativas(payload: {
    secaoSelecionada: Secao;
    perguntaSelecionada: Pergunta;
    alternativas: Alternativa[];
  }): void {
    let possuiPulo = false;

    const { alternativas, perguntaSelecionada, secaoSelecionada } = payload;

    const newAlt = alternativas.map((v) => {
      return {
        ...v,
        ...(typeof v.id === 'number' ? { id: null } : {}),
        marcacao: v.marcacaoPergunta
          ? v.marcacaoPergunta.codigoMarcacao
            ? {
                perguntaDestino: {
                  codigoMarcacao: v.marcacaoPergunta.codigoMarcacao,
                },
                possuiPulo: (possuiPulo = true),
                possuiCota: false,
                possuiDiferenteDe: false,
              }
            : {
                perguntaDestino: {
                  codigoMarcacao: v.marcacaoPergunta,
                },
                possuiPulo: (possuiPulo = true),
                possuiCota: false,
                possuiDiferenteDe: false,
              }
          : undefined,
        marcacaoPergunta: null,
      } as AlternativaRequest;
    });

    const indexOfPergunta =
      secaoSelecionada.perguntas.indexOf(perguntaSelecionada);
    
    const currentQuestionInList = this.lista[secaoSelecionada.index].perguntas[indexOfPergunta];

    currentQuestionInList.alternativas =
      alternativas.map((alternativa) => ({
        id: null,
        tipoAlternativa: alternativa.tipoAlternativa,
        ...(alternativa.marcacaoPergunta
          ? { marcacaoPergunta: alternativa.marcacaoPergunta }
          : {}),
        descricao: alternativa.descricao,
        comportamento: alternativa.comportamento,
      }));

    // Seta o atributo de lógica corretamente
    this.input.elementosQuestionario[
      secaoSelecionada.index
    ].secao.elementosSecao[indexOfPergunta].pergunta = {
      ...this.input.elementosQuestionario[secaoSelecionada.index].secao
        .elementosSecao[indexOfPergunta].pergunta,
        possuiPulo,
      alternativas: newAlt,
    };

    this.handleSaveAndOutputData('salvar-logica');
  }

  adicionarLogicaAUmaPerguntaSemAlternativas(payload: {
    secaoSelecionada: Secao;
    perguntaSelecionada: Pergunta;
    codigoMarcacao: string;
  }): void {
    const { codigoMarcacao, secaoSelecionada, perguntaSelecionada } = payload;
    const indexOfPergunta =
      secaoSelecionada.perguntas.indexOf(perguntaSelecionada);
    this.lista[secaoSelecionada.index].perguntas[indexOfPergunta] = {
      ...this.lista[secaoSelecionada.index].perguntas[indexOfPergunta],
      marcacaoPergunta:
        codigoMarcacao !== ""
          ? {
            codigoMarcacao: codigoMarcacao,
          }
          : null,
    };
    this.input.elementosQuestionario[
      secaoSelecionada.index
    ].secao.elementosSecao[indexOfPergunta].pergunta = {
      ...this.input.elementosQuestionario[secaoSelecionada.index].secao
        .elementosSecao[indexOfPergunta].pergunta,
      // Atributo que indica se a pergunta possui ou não pulo.
      possuiPuloPergunta: (codigoMarcacao !== ""),
      marcacaoPergunta:
        codigoMarcacao !== ""
          ? {
            perguntaDestino: {
              codigoMarcacao: codigoMarcacao,
            },
          }
          : null,
    };

    this.handleSaveAndOutputData('salvar-logica');
  }

  /**
   * Função responsável por expor a listagem de Seções
   */
  getSectionList(): Secao[] {
    return this.lista;
  }

  getShowDetails(): boolean {
    return this._showDetails;
  }

  getChooseQuestion(): Pergunta {
    return this._chooseQuestion;
  }

  getChooseSection(): Secao {
    return this._chooseSection;
  }

  // função que irá abrir uma nova aba para mostrar o questionário
  handleShowQuizPreview() {
    let linkUrl = `${formsLocation}/${this.tokenRef}`;

    this.pesquisaService
      .getFriendlyToken(this.tokenRef)
      .toPromise()
      .then((token) => {
        linkUrl = `${formsLocation}/${token.token_amigavel}`
        window.open(linkUrl, '_blank');
      })
      .catch((err) => this.errorHandlerService.handleError(err, 'Erro ao copiar link'));
  }

  /**
   * Função responsável por modificar o estado de exibição da listagem de
   * Seções/perguntas para detalhar uma pergunta
   */
  setShowDetails(): boolean {
    return (this._showDetails = !this._showDetails);
  }

  /**
   * Função responsável por buscar uma pergunta recebendo como parâmetro o id
   * da seção e o id da pergunta no ato do clique.
   */
  detailsQuestion(data: EventsProps): void {
    const chooseSection = this.lista.find(
      (section) => section.id === data.sectionId
    );
    this._chooseSection = chooseSection;
    this._chooseQuestion = chooseSection.perguntas.find(
      (question) => question.id === data.questionId
    );
  }

  /**
   * Permite avançar o passo do formulário (pois o passo da lógica não é
   * obrigatório)
   */
  handleNextStep(): void {
    this.onNextStep.emit();
  }
}
