import { AbstractAction } from '../../actions/abstractAction';
import { PassoCadastro } from '../../passoCadastro';
import { ActionType } from '../../actionType';
import { Localidade } from '../../../passos/gerenciador-cotas/localidades/localidade';

/**
 * Action que representa a preparação para buscar as localidades na API.
 * Esta action serve para setar, na store uma flag isLoading = true, por ex.
 */
export class SolicitarLocalidadesAction extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERENCIADOR_COTAS;
  readonly type = ActionType.SOLICITAR_LOCALIDADES;
  payload: { idPesquisa: number; };

  constructor(payload: { idPesquisa: number; }) {
    super();
    this.payload = payload;
    this.isReplayable = true;
  }
}

/**
 * Action que representa a obtenção das localidades na requisição realizada anteriormente, 
 */
export class CarregarLocalidadesAction extends AbstractAction {

  readonly passoCadastro = PassoCadastro.GERENCIADOR_COTAS;
  readonly type = ActionType.CARREGAR_LOCALIDADES;
  payload: Localidade[];

  constructor(payload: Localidade[]) {
    super();
    this.payload = payload;
    this.isReplayable = true;
  }

}