import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ChartConfiguration } from "app/util/componente/chart/model/chartConfiguration";
import { dateFormatter } from "app/util/misc/dateFormatter";
import { calcularPorcentagem } from "app/util/misc/porcentagem";
import { ReplaySubject, Subject } from "rxjs";
import { OperadorSelecao } from "../operador/operadorSelecao";
import { OperadorService } from "../operador/servico/operador.service";
import { PesquisaService } from "../pesquisa-old/servico/pesquisa.service";
import { Produtividade } from "./model/produtividade";
import { ProdutividadeFiltro } from "./produtividadeFiltro";
import { ProdutividadeService } from "./service/produtividade.service";
import { GraficosProdutividadeGenerator } from "./util/graficoGenerator";
// tslint:disable-next-line: max-line-length
import { EntrevistasDesconformidadeModalComponent } from "./entrevistas-desconformidade/entrevistas-desconformidade-modal/entrevistas-desconformidade-modal.component";
// tslint:disable-next-line: max-line-length
import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/util/componente/filterable-select/filterable-select.component";
import { EntrevistasEmDesconformidadeQuery } from "./entrevistas-desconformidade/entrevistasEmDesconformidadeQuery";
import { TipoDesconformidade } from "./entrevistas-desconformidade/tipoDesconformidade";
import { ModalService } from "app/componentes/modal/modal.service";

@Component({
  selector: "app-produtividade",
  templateUrl: "./produtividade.component.html",
  styleUrls: ["./produtividade.component.scss"],
})
export class ProdutividadeComponent implements OnInit {
  // Refazer de forma escalável
  isExpand: boolean = false;
  id: String = "";

  handleExpand(e) {
    e.preventDefault();
    this.id = e.target.attributes.id.nodeValue;
    this.isExpand = !this.isExpand;
  }

  // chartProducaoDiariaCanvasElemRef: ElementRef;
  // @ViewChild('chartProducaoDiaria') set chartProducaoDiariaCanvas(elRef: ElementRef) {
  //   this.chartProducaoDiariaCanvasElemRef = elRef;
  // }

  // tslint:disable-next-line:max-line-length
  @ViewChild("chartProducaoDiaria", { static: true })
  chartProducaoDiariaCanvasElemRef: ElementRef;

  formGroup: UntypedFormGroup;
  controls: { [key: string]: AbstractControl } = {};
  showLoading = false;

  /**
   * Subject que notifica possiveis componentes filhos
   * sobre o novo estado da produtividade. Pode ser utilizado
   * por exemplo, para a atualização do mapa de cerca eletrônica
   */
  produtividadeSubject: Subject<Produtividade> = new ReplaySubject();

  /**
   * Subject que notifica novos filtros de produtividade para componentes filhos.
   * Pode ser utilizado, por exemplo, para a atualização do componente de realtime.
   */
  novaProdutividadeFiltroSubject: Subject<ProdutividadeFiltro> =
    new ReplaySubject();

  // VARIAVEIS RELACIONADAS AOS GRAFICOS
  subjectGraficoProducaoDiaria: Subject<ChartConfiguration> =
    new ReplaySubject();
  subjectGraficoEspacamento: Subject<ChartConfiguration> = new ReplaySubject();
  subjectGraficoTempoMinimo: Subject<ChartConfiguration> = new ReplaySubject();
  geradorGrafico: GraficosProdutividadeGenerator =
    new GraficosProdutividadeGenerator();

  produtividade: Produtividade;
  pesquisas: SelectDataItem[] = [];
  operadores: OperadorSelecao[] = [];

  constructor(
    private pesquisaService: PesquisaService,
    private operadorService: OperadorService,
    private produtividadeService: ProdutividadeService,
    public dialog: MatDialog,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.initFormGroup();
    this.carregarPesquisas();
  }
  /**
   * Carrega o form group do componente, basicamente,
   * os filtros que serão utilizados
   */
  initFormGroup() {
    this.formGroup = ProdutividadeFiltro.getControl();
    this.controls = this.formGroup.controls;
  }

  pesquisaToSelectDataItem(pesquisa) {
    return {
      value: pesquisa.id,
      label: pesquisa.nome,
    };
  }

  selectDataItemToPesquisa(selectDataItem: SelectDataItem) {
    return {
      id: selectDataItem.value,
      nome: selectDataItem.label,
    };
  }

  carregarPesquisas() {
    this.showLoading = true;
    this.pesquisaService.getPesquisasSelecao().subscribe({
      next: (pesquisasSelecao) => {
        this.pesquisas.length = 0;
        this.pesquisas.push(
          ...pesquisasSelecao.map(this.pesquisaToSelectDataItem)
        );
        // tslint:disable-next-line:align
      },
      error: () => {
        this.modalService.showModal({
          title: "Controle de produtividade",
          messageModal:
            "Não foi possível carregar as pesquisas, tente novamente",
          btnTitlePositive: "Entendi",
          isOnlyConfirmation: true,
        });
        this.showLoading = false;
      },
    });
  }

  /**
   * Quando uma pesquisa é modificada, deve carregar os
   * operadores que fazem parte da mesma e atualizar as estatisticas
   */
  onPesquisaChanged() {
    this.carregarOperadores();
    this.atualizarEstatisticas();
  }

  handleSelectedPesquisa(selectedItem: SelectedItemEvent) {
    const idPesquisa = selectedItem.item.value;
    this.formGroup.patchValue({
      idPesquisa,
    });

    this.carregarOperadores();
    this.atualizarEstatisticas();
  }

  /**
   * Quando um operador é modificada, deve-se
   * atualizar as estatisticas
   */
  onOperadorChanged() {
    this.atualizarEstatisticas();
  }

  /**
   * Quando uma data é modificada, deve-se
   * atualizar as estatisticas
   */
  onDataChange() {
    this.atualizarEstatisticas();
  }

  carregarOperadores() {
    // removendo o operador previamente selecionado
    this.controls.idOperador.patchValue(null);

    this.showLoading = true;

    // tslint:disable-next-line:max-line-length
    this.operadorService
      .getOperadorSelecao(this.controls.idPesquisa.value)
      .subscribe({
        next: (operadoresSelecao: OperadorSelecao[]) => {
          this.operadores.length = 0;
          this.operadores.push(...operadoresSelecao);
          this.showLoading = false;
          // tslint:disable-next-line:align
        },
        error: () => {
          this.modalService.showModal({
            title: "Controle de produtividade",
            messageModal:
              "Não foi possível carregar os operadores, tente novamente",
            btnTitlePositive: "Entendi",
            isOnlyConfirmation: true,
          });
          this.showLoading = false;
        },
      });
  }

  /**
   * Notifica, através do Subject novoFiltro, os componentes filhos subscritos com o
   * filtro de produtividade atual.
   */
  notifyFiltroProdutividade(produtividadeFiltro: ProdutividadeFiltro) {
    // tslint:disable-next-line: max-line-length

    this.novaProdutividadeFiltroSubject.next(produtividadeFiltro);
  }

  /**
   * Recupera a instância atual do formulário de filtragem de produtividade
   * obedecendo a classe ProdutividadeFiltro
   */
  getProdutividadeFiltro(): ProdutividadeFiltro {
    const produtividadeFiltro = this.formGroup.value;
    // convertendo as datas
    produtividadeFiltro.dataInicial = dateFormatter.yMdToDmY(
      produtividadeFiltro.dataInicial
    );
    produtividadeFiltro.dataFinal = dateFormatter.yMdToDmY(
      produtividadeFiltro.dataFinal
    );

    return produtividadeFiltro;
  }

  atualizarEstatisticas() {
    this.showLoading = true;

    const produtividadeFiltro: ProdutividadeFiltro =
      this.getProdutividadeFiltro();

    // tslint:disable-next-line:max-line-length
    this.produtividadeService.getEstatisticas(produtividadeFiltro).subscribe({
      next: (produtividade: Produtividade) => {
        this.produtividade = produtividade;

        // tslint:disable-next-line:max-line-length
        const configuracaoGraficoProducaoDiaria =
          this.geradorGrafico.gerarGraficoProducaoDiaria(produtividade);
        this.subjectGraficoProducaoDiaria.next(
          configuracaoGraficoProducaoDiaria
        );

        // tslint:disable-next-line:max-line-length
        const configuracaoGraficoEspacamento =
          this.geradorGrafico.gerarGraficoEspacamento(produtividade);
        this.subjectGraficoEspacamento.next(configuracaoGraficoEspacamento);

        // tslint:disable-next-line:max-line-length
        const configuracaoGraficoTempoMinimo =
          this.geradorGrafico.gerarGraficoTempoMinimo(produtividade);
        this.subjectGraficoTempoMinimo.next(configuracaoGraficoTempoMinimo);

        this.produtividadeSubject.next(produtividade);

        this.produtividade = produtividade;
        this.showLoading = false;

        // tslint:disable-next-line:align
      },
      error: () => {
        this.modalService.showModal({
          title: "Controle de produtividade",
          messageModal:
            "Não foi possível carregar as estatísticas, tente novamente",
          btnTitlePositive: "Entendi",
          isOnlyConfirmation: true,
        });
        this.showLoading = false;
      },
    });

    this.atualizarRealtime(produtividadeFiltro);
  }

  /**
   * Calcula a porcentagem dados dos valores,
   * o valor será retornado com 1 casa decimal
   * acrescido do prefixo '%'
   */
  calcularPorcentagem(valorAtual, valorTotal) {
    return calcularPorcentagem(valorAtual, valorTotal);
  }

  /**
   * Solicita a atualização do realtime, seja para pesquisas específicas,
   * operador específico ou mesmo para ativar o modo de histórico.
   */
  atualizarRealtime(produtividadeFiltro: ProdutividadeFiltro) {
    this.notifyFiltroProdutividade(produtividadeFiltro);
  }

  visualizarEntrevistasEmDesconformidadeMenosTempoMinimo() {
    this.visualizarEntrevistasPorTiposDesconformidade([
      TipoDesconformidade.FRAUDE_AUDIO,
      TipoDesconformidade.FRAUDE_DATA_COLETA,
      TipoDesconformidade.FRAUDE_DISTANCIA,
    ]);
  }

  visualizarEntrevistasEmDesconformidadeAbaixoDoTempoMinimo() {
    this.visualizarEntrevistasPorTiposDesconformidade([
      TipoDesconformidade.FRAUDE_TEMPO,
    ]);
  }

  visualizarEntrevistasEmDesconformidadeForaCerca() {
    this.visualizarEntrevistasPorTiposDesconformidade([
      TipoDesconformidade.FRAUDE_DISTANCIA,
    ]);
  }

  visualizarEntrevistasPorTiposDesconformidade(
    tiposDesconformidade: TipoDesconformidade[]
  ) {
    /**
     * idPesquisa: new FormControl(),
      idOperador: new FormControl(),
      dataInicial: new FormControl(),
      dataFinal: new FormControl(),
     *
     */

    const produtividadeFiltro = this.formGroup.value;

    // produtividadeFiltro.dataInicial = dateFormatter.yMdToDmY(produtividadeFiltro.dataInicial);
    // produtividadeFiltro.dataFinal = dateFormatter.yMdToDmY(produtividadeFiltro.dataFinal);

    this.dialog.open(EntrevistasDesconformidadeModalComponent, {
      data: {
        entrevistasEmDesconformidadeQuery: <EntrevistasEmDesconformidadeQuery>{
          tiposDesconformidade,
          idPesquisa: produtividadeFiltro.idPesquisa,
          idOperador: produtividadeFiltro.idOperador,
          dataInicio: produtividadeFiltro.dataInicial,
          dataFinal: produtividadeFiltro.dataFinal,
        },
      },
    });
  }
}
