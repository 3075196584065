import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TooltipTextService } from './tooltip-text.service';

@Component({
  selector: 'app-tooltip-text',
  templateUrl: './tooltip-text.component.html',
  styleUrls: ['./tooltip-text.component.scss']
})
export class TooltipTextComponent implements OnInit {

  constructor(private tooltipService: TooltipTextService) {}

  @ViewChild('containerRef') elementRef: ElementRef;

  public x: string = "0px";
  public y: string = "0px";
  public isVisible: boolean = false;

  data: any = null;

  ngOnInit(): void {
    this.tooltipService.state$.subscribe((next) => {
      if (next) {
        const { data, x, y, visible } = next;

        this.data = data ; // text
        this.x = x + "px"; // X axis
        this.y = y + "px"; // Y axis
        this.isVisible = visible; // controls tooltip visibility
      }
    });
  }

}
