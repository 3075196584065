import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { INPUT_MIN_LENGTH } from "../../constant";

@Component({
  selector: "app-password-validator",
  templateUrl: "./password-validator.component.html",
  styleUrls: ["./password-validator.component.scss"],
})
export class PasswordValidatorComponent implements OnInit, OnChanges {
  @Input() inputValue: string = null;
  @Output() inputIsValid = new EventEmitter<boolean>(false);

  // validadores
  lengthValidate: boolean = false;
  hasTwoNumbers: boolean = false;
  hasOneUpercaseLetter: boolean = false;
  hasOneLowercaseLetter: boolean = false;
  hasOneSpecialChar: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // Se ocorreu mudança no inputValue
    if (!!changes.inputValue) {
      // Se o valor atual pós mudança é válido
      if (changes.inputValue.currentValue) {
        this.handleAllValidations();
      } else {
        this.clearAllValidations();
        this.inputIsValid.emit(false);
      }
    }
  }

  // Limpa todas as validações
  private clearAllValidations() {
    this.lengthValidate = false;
    this.hasTwoNumbers = false;
    this.hasOneUpercaseLetter = false;
    this.hasOneLowercaseLetter = false;
    this.hasOneSpecialChar = false;
  }

  // Chamada todas as valições para verificar o status do input
  private handleAllValidations() {
    this.lengthIsValid();
    this.hasNumbers();
    this.hasUppercase();
    this.hasLowercase();
    this.hasSpecialCharacter();
    this.checkInputIsvalid();
  }

  // Verifica se o valor do input possui o tamanho minimo esperado
  private lengthIsValid() {
    if (this.inputValue.length >= INPUT_MIN_LENGTH) {
      this.lengthValidate = true;
    } else {
      this.lengthValidate = false;
    }
  }

  // Verifica se o valor do input possui pelo menos 2 números
  private hasNumbers() {
    const regex = /^(?=(.*\d){2,}).*$/;
    this.hasTwoNumbers = regex.test(this.inputValue);
  }

  // Verifica se o valor do input possui pelo uma letra maiusucula
  private hasUppercase() {
    const regex = /[A-Z]/;
    this.hasOneUpercaseLetter = regex.test(this.inputValue);
  }

  // Verifica se o valor do input possui pelo uma letra minuscula
  private hasLowercase() {
    const regex = /[a-z]/;
    this.hasOneLowercaseLetter = regex.test(this.inputValue);
  }

  // Verifica se o valor do input possui pelo um caractere especial
  private hasSpecialCharacter() {
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    this.hasOneSpecialChar = regex.test(this.inputValue);
  }

  // Verifica a validade do input
  private checkInputIsvalid() {
    if (
      this.lengthValidate &&
      this.hasTwoNumbers &&
      this.hasOneUpercaseLetter &&
      this.hasOneLowercaseLetter &&
      this.hasOneSpecialChar
    ) {
      this.inputIsValidOutput(true);
    } else {
      this.inputIsValidOutput(false);
    }
  }

  // Realiza a emissão do status do input (se é válido ou inválido)
  private inputIsValidOutput(inputStatus: boolean) {
    this.inputIsValid.emit(inputStatus);
  }
}
