import {
  Component, EventEmitter, Input,
  OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from "@angular/core";
import * as moment from "moment";
import "moment/locale/pt-br";
import { Moment } from "moment/moment";
import { DatePickerDirective, IDayCalendarConfig, SingleCalendarValue } from "ng2-date-picker";
import { Observable } from "rxjs";
import { StatusPesquisa } from "../../../../pesquisa-old/status/statusPesquisa";

@Component({
  selector: "app-calendario",
  templateUrl: "./calendario.component.html",
  styleUrls: ["./calendario.component.scss"],
})
export class CalendarioComponent implements OnChanges, OnInit, OnDestroy {
  /**
   * Api do calendario
   */
  @ViewChild("datePicker", { static: true }) datePicker: DatePickerDirective;

  /**
   * Dias selecionados no calendário
   */
  diasSelecionados: moment.Moment[] = [];
  /**
   * Dias selecionados previamente no calendário
   */
  @Input() datasPreviamenteSelecionadas: Date[] = [];

  @Input() editavel: boolean = true;

  /**
   * Lançador de eventos que informa quando
   * o calendario sofreu alteração e quais
   * são suas novas datas
   */
  @Output() calendarioChange: EventEmitter<Date[]> = new EventEmitter();

  @Input() statusAtualPesquisa: StatusPesquisa;

  // Indicador responsável por avisar sempre  que houver uma mudança de status da pesquisa
  @Input() indicadorMudancaStatus: Observable<StatusPesquisa>;

  /**
   * Configuração do calendário
   */
  calendarConfig: IDayCalendarConfig = {
    allowMultiSelect: true,
    isDayDisabledCallback: this.isDisabled.bind(this),
    enableMonthSelector: false,
  };

  constructor() {
    moment.locale("pt-br");
  }

  ngOnInit() {
    this.indicadorMudancaStatus.subscribe((statusAtual) => {
      if (!statusAtual) {
        return;
      }

      this.statusAtualPesquisa = statusAtual;
    });
  }

  ngOnDestroy(): void {
    // this.indicadorMudancaStatus.un
  }

  /**
   * desabilita datas anteriores ao dia atual
   * @param date recebe um moment como parâmetro
   */
  isDisabled(date: Moment) {
    const isDataPosterior = date.isSameOrAfter(new Date(), "day");

    // caso o status da pesquisa esteja em [EXECUCAO] os dias do calendario nao podem ficar ativo
    if (this.statusAtualPesquisa === StatusPesquisa.EXECUCAO) {
      return true;
    }

    if (this.editavel && isDataPosterior) {
      return false;
    }

    return true;
  }

  /**
   * Marcação para saber se a posição inicial
   * do calendario ja foi definida
   */
  calendarioAlinhado = false;

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line: max-line-length
    if (
      changes.datasPreviamenteSelecionadas.currentValue !==
      changes.datasPreviamenteSelecionadas.previousValue
    ) {
      this.carregarDatasPreviamenteSelecionadas();
    }
  }

  onCalendarioChange() {
    const datasSelecionadas = this.diasSelecionados.map((momentDate) =>
      momentDate.toDate()
    );
    this.calendarioChange.emit(datasSelecionadas);
  }

  /**
   * Carrega as datas previamente selecionadas
   */
  carregarDatasPreviamenteSelecionadas() {
    if (this.datasPreviamenteSelecionadas.length === 0) {
      return;
    }
    /**
     * Garantindo a ordem das datas
     */
    // tslint:disable-next-line: max-line-length
    this.datasPreviamenteSelecionadas.sort(
      (data, dataToCompare) => data.getTime() - dataToCompare.getTime()
    );

    this.diasSelecionados = this.datasPreviamenteSelecionadas.map((data) =>
      moment(data)
    );

    if (!this.calendarioAlinhado) {
      const diaSelecionado = this.diasSelecionados[0];
      if (diaSelecionado && diaSelecionado.isValid()) {
        this.datePicker.api.moveCalendarTo(diaSelecionado as SingleCalendarValue);
      }
      this.calendarioAlinhado = true;
    }
  }
}
