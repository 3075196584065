<section class="modal-container" *ngIf="showModal" [formGroup]="formGroup">
  <div class="modal-content">
    <div class="modal-header">
      <div class="left-header">
        <i class="fa-light fa-lock"></i>
        <h3>Redefinir senha</h3>
      </div>

      <button type="button" class="button-close" (click)="handleCloseModal()">
        <i class="fa-light fa-xmark"></i>
      </button>
    </div>
    <div class="modal-info">

      <div class="modal-input-password" formGroupName="usuario">
        <label for="senha_atual">Senha atual*</label>
        <app-input-password [parentFormGroup]="formGroup" [controlName]="'senha_atual'"
        [hasError]="(controls.usuario.get('senha_atual')?.touched && controls.usuario.get('senha_atual').errors)">></app-input-password>
        <span class="error-message" [hidden]="!(controls.usuario.get('senha_atual')?.touched && controls.usuario.get('senha_atual').errors
        && (controls.usuario.get('senha_atual').errors.required || controls.usuario.get('senha_atual').invalid))">
          Senha incorreta
        </span>
      </div>

      <div class="modal-input-password" formGroupName="usuario">
        <label for="senha_nova">Nova senha*</label>
        <app-input-password [parentFormGroup]="formGroup" [controlName]="'senha_nova' "(canUpdatePassword)="handleCheckUpdatePassword($event, 'password')"
        [hasError]="(controls.usuario.get('senha_nova')?.touched && controls.usuario.get('senha_nova').errors)
        || (passwordStrength && returnPasswordStrength(passwordStrength) === 'Fraca')">
        </app-input-password>
        <!-- <span class="error-message" [hidden]="!(controls.usuario.get('senha_nova')?.touched  && controls.usuario.get('senha_nova').errors
        && (controls.usuario.get('senha_nova').errors.required
        || controls.usuario.get('senha_nova').errors.minlength
        || controls.usuario.get('senha_nova').errors.maxlength))">
          Informe uma senha entre 8 e 30 caracteres
        </span> -->
        <span *ngIf="(passwordStrength > 0 && returnPasswordStrength(passwordStrength) !== 'Forte')" class="message">
          <b>Dica:</b> Adicione <b>números</b> e <b>caracteres</b> especiais para uma senha forte
        </span>
        <span *ngIf="passwordStrength" class="error-message password-error">
          <span *ngIf="returnPasswordStrength(passwordStrength) === 'Fraca'">A segurança da senha é baixa
            demais</span>
          <span [class]="checkPasswordIsValid(passwordStrength)">
            <p>{{returnPasswordStrength(passwordStrength)}}</p>
          </span>
        </span>
      </div>

      <div class="modal-input-password" formGroupName="usuario">
        <label for="confirmar-senha">Repetir nova senha*</label>
        <app-input-password [parentFormGroup]="formGroup" [controlName]="'senhaConfirmar'"
          (canUpdatePassword)="handleCheckUpdatePassword($event, 'confirmedPassword')" [hasError]="(controls.usuario.get('senhaConfirmar')?.touched
          && controls.usuario.get('senhaConfirmar').errors) || (confirmedPasswordStrength && returnPasswordStrength(confirmedPasswordStrength) === 'Fraca')">
        </app-input-password>
        <span class="error-message" [hidden]="!(controls.usuario.get('senhaConfirmar')?.touched
        && controls.usuario.get('senhaConfirmar').errors)">
          As senhas não correspondem
        </span>
        <span *ngIf="(confirmedPasswordStrength > 0 && returnPasswordStrength(confirmedPasswordStrength) !== 'Forte')" class="message">
          <b>Dica:</b> Adicione <b>números</b> e <b>caracteres</b> especiais para uma senha forte
        </span>
        <span *ngIf="confirmedPasswordStrength" class="error-message password-error">
          <span *ngIf="returnPasswordStrength(confirmedPasswordStrength) === 'Fraca'">A segurança da senha é baixa
            demais</span>
          <span [class]="checkPasswordIsValid(confirmedPasswordStrength)">
            <p>{{returnPasswordStrength(confirmedPasswordStrength)}}</p>
          </span>
        </span>

      </div>

    </div>

    <div class="modal-buttons">
      <button class="cancel-btn" type="button" (click)="handleCloseModal()">Cancelar</button>
      <button type="submit" (click)="handleSubmit()">Redefinir</button>
    </div>
  </div>
</section>
