import { Pipe, PipeTransform } from "@angular/core";
import { SectionElem, SectionElemItem } from "./secao-listagem.component";

@Pipe({name: 'sortSectionElem'})
export class SortSectionElemPipe implements PipeTransform {
  
  transform(values: SectionElem[], ...args: any[]) {
    return values.sort((a, b) => a.ordem - b.ordem);
  }

}

/**
 * Ordenação das alternativas de perguntas pelo atributo ordem
 */
@Pipe({name: 'sortSectionElemItem'})
export class SortSectionElemItemPipe implements PipeTransform {
  transform(values: SectionElemItem[], ...args: any[]) {
    return values.sort((a, b) => a.ordem - b.ordem);
  }
}