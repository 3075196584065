<div [formGroup]="formGroup" class="containerConfigInput">
  <div class="configInput">
    <input placeholder="título da linha" title="título da linha de grade única" type="text" formControlName="descricao" maxlength="250">
    <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
      Uma descrição de até 250 caracteres deve ser fornecida
    </span>
  </div>
  <div class="configAction" *ngIf="editavel">
      <span title="Remover títulos" class="fa fa-trash-o" (click)="remover()"></span>
  </div>
</div>
