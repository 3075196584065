import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import { v4 as uuid } from "uuid";
import {
  CompletedSavingEvent,
  FailedSavingEvent,
  PendingSavingEvent,
} from "../../../events/save";
import { ICanNotSaveButton } from "../../../pesquisas-cadastro.model";
import { SectionModel } from "./pesquisas-questionario-secoes-cadastro.model";

@Component({
  selector: "app-pesquisas-questionario-secoes-cadastro",
  templateUrl: "./pesquisas-questionario-secoes-cadastro.component.html",
  styleUrls: ["./pesquisas-questionario-secoes-cadastro.component.scss"],
})
export class PesquisasQuestionarioSecoesCadastroComponent
  implements OnInit, OnChanges
{
  // título da pesquisa
  @Input() surveyTitle: string = "Título da Pesquisa";
  // modelo de dados deste componente
  @Input() model: SectionModel;
  // evento de cancelar edição ou inserção
  @Output() onCancel = new EventEmitter<void>();
  // evento de salvar
  @Output() onSave = new EventEmitter<SectionModel>();
  // evento de salvar
  @Output() onChange = new EventEmitter<SectionModel>();

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> =
    new EventEmitter();

  // diz o passo está ocupado
  public isBusy: boolean = false;

  // objeto de fácil acesso pawra os formControls
  public formControls: { [key: string]: UntypedFormControl } = {} as {
    [key: string]: UntypedFormControl;
  };
  // campos do formulário de nova seção e sua inicialização
  public formGroup: UntypedFormGroup;
  public formData: {
    name: string;
    description: string;
  } = {
    name: "",
    description: "",
  };

  private secaoTituloMaxLength: number = 160;
  private descricaoSecaoMaxLength: number = 160;

  constructor(private eventBus: EventBus) {}

  ngOnInit() {
    this.eventBus.on(PendingSavingEvent.TYPE, () => (this.isBusy = true));
    this.eventBus.on(FailedSavingEvent.TYPE, () => (this.isBusy = false));
    this.eventBus.on(CompletedSavingEvent.TYPE, () => {
      this.isBusy = false;
      this.model = null;
    });

    if (this.formControls.name.value || this.formControls.name.value) {
      this.handleInputChange(this.formControls.name.value, "name");
      this.handleInputChange(
        this.formControls.description.value,
        "description"
      );
    }

    this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: true } });
  }

  ngOnChanges() {
    this.setupFormGroup();

    if (this.model) {
      this.formGroup.setValue({
        name: this.model.nome,
        description: this.model.descricao,
      });
    }
  }

  // inicializando form group
  setupFormGroup() {
    this.formControls["name"] = new UntypedFormControl(this.formData.name, [
      Validators.required,
      Validators.maxLength(this.secaoTituloMaxLength),
    ]);
    this.formControls["description"] = new UntypedFormControl(
      this.formData.description,
      [Validators.maxLength(this.descricaoSecaoMaxLength)]
    );

    this.formGroup = new UntypedFormGroup({
      name: this.formControls.name,
      description: this.formControls.description,
    });
  }

  // Função do botão cancelar
  handleCancel() {
    this.onCancel.emit();
  }

  // Função para salvar nova seção
  handleSave() {
    // se tiver ocupado não deverá funcionar
    if (this.isBusy) {
      return;
    }
    // verificar se o modelo não existe, popular o modelo
    if (!this.model) {
      this.model = {
        hash: uuid(),
        nome: this.formControls.name.value,
        descricao: this.formControls.description.value,
      };
    }
    // enviar dados para salvar (editing)
    this.onSave.emit({
      id: this.model.id,
      hash: this.model.hash,
      nome: this.formControls.name.value,
      descricao: this.formControls.description.value,
    });
  }

  // manipulação da solicitação de criar nova seção
  handleNewSectionClick() {
    this.onChange.emit();
  }

  /**
   *
   * @param event texto digitado pelo usuário
   * @param inputID id do input
   */
  handleInputChange(event: any, inputID: string) {
    let element = document.querySelector(`#${inputID}`) as HTMLElement;

    element.style.height = `${
      event && event.length
        ? element.scrollHeight > 48
          ? element.scrollHeight
          : 48
        : 48
    }px`;
  }
}
