import { Injectable } from '@angular/core';
import { RequestService } from 'app/servico/request.service';
import { Observable } from 'rxjs';
import { Permissao } from './permissao';
import { apiLocation } from 'app/infraestrutura/apiLocation';

@Injectable({
  providedIn: 'root',
})
export class PermissaoService {

  private baseUrl = `${apiLocation}/permissoes`;
  constructor(private requestService: RequestService) { }

  listar():Observable<Permissao[]> {
    return this.requestService.get(this.baseUrl);
  }

}
