import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TipoPergunta } from '../../../../../../../../../pesquisa-old/cadastro/inputs/complex-input/tipoPergunta';
import { TipoConteudo } from '../../../conteudo/tipoConteudo';
import { SelecaoMultiplaComponent } from '../selecao-multipla/selecao-multipla.component';
// tslint:disable-next-line: max-line-length
import { CadastroPesquisaStoreState } from '../../../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';

@Component({
  selector: 'app-selecao-multipla-ordem-citacao-questionario',
  templateUrl: './selecao-multipla-ordem-citacao.component.html',
  styleUrls: ['./selecao-multipla-ordem-citacao.component.scss'],
})
export class SelecaoMultiplaOrdemCitacaoComponent extends SelecaoMultiplaComponent {

  tipoConteudo: TipoConteudo = TipoConteudo.CONTEUDO_PERGUNTA_SELECAO_MULTIPLA_ORDEM_CITACAO;
  tipoPergunta: TipoPergunta = TipoPergunta.MULTIPLA_ORDEM_CITACAO;

  constructor(protected store: Store<CadastroPesquisaStoreState>) {
    super(store);
  }

}
