<div class="operador-card-container">
  <div class="operador-card-header">
    <h4>{{ operator.operador.nome }}</h4>

    <div class="quantidade-localidades-content">
      {{ operator.localidades.length }}
      {{ operator.localidades.length > 1 ? "localidades" : "localidade" }}
    </div>
  </div>

  <div class="operador-card-localidades-list">
    <div
      class="localidade-item"
      *ngFor="let localidade of operator.localidades"
    >
    <i class="fa-light fa-location-dot"></i>
      <span>{{ localidade.nome }}</span>
    </div>
  </div>
</div>
