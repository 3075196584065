<div class="table-header-container">
  <button
    class="massive-delete-box"
    type="button"
    [ngClass]="!hasSomeLocalidadeChecked && 'inactive'"
    (click)="handleMassiveDelete()"
  >
    <i class="fas fa-trash-alt"></i>
  </button>

  <div class="search-box">
    <label>Filtrar por palavras chave: </label>
    <input
      type="text"
      placeholder="ex.: João pessoa"
      (input)="handleSearchLocationByKey($event)"
      [value]="initialSearchValue"
    />
  </div>
</div>
