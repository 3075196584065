import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MeatballConfig } from "../../interfaces/meatball";
import { CellAlignTypes, CustomStyles, TableCell } from "../../interfaces/tCell";
import { TableRow } from "../../interfaces/tRow";
import { MeatballClickEvent } from "../../interfaces/table";

@Component({
  selector: "app-generic-table-row",
  templateUrl: "./table-row.component.html",
  styleUrls: ["./table-row.component.scss", "./table-row-custom.component.scss"],
})
export class GenericTableRow implements OnInit {
  constructor() {}

  @Input() data: TableRow = null;
  @Input() widths: string[] = [];
  @Input() height: string = "60px";
  @Input() meatballConfig: MeatballConfig = null;
  @Output() cellClick = new EventEmitter<number[]>(null);
  @Output() rowClick = new EventEmitter<number>(null);
  @Output() meatballClick = new EventEmitter<MeatballClickEvent>();

  private defaultWidths: string[] = [];
  private defaultHeight: string = "60px";

  ngOnInit(): void {
    if (!this.height) this.height = this.defaultHeight;
    if (!this.widths) this.widths = this.defaultWidths;

    this.initRows();
  }

  handleMeatballClick($event: MeatballClickEvent) {
    this.meatballClick.emit($event);
  }

  initRows() {
    this.defaultWidths = this.data.cells.map(_ => '.25');

    if (!this.widths.length) {
      this.widths = [...this.defaultWidths];
    }
  }

  onRowClick($event) {
    this.rowClick.emit($event);
  }

  onCellClick($event) {
    this.cellClick.emit($event);
  }

  getDynamicStyles(alignType: CellAlignTypes = "flex-start", width: string) {
    const dynamicStyle: CustomStyles = {
      justifyContent: alignType,
    };

    if (width.includes("px")) {
      dynamicStyle.width = width;
    } else {
      dynamicStyle.flex = width;
    }

    return dynamicStyle;
  }

  getOverflowStyle(renderedCell: TableCell) {
    if (renderedCell.type === "meatball") {
      return "visible";
    }

    return "hidden";
  }

}
