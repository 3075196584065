import { TrBody } from "app/util/componente/tabela/trbody/trBody";
import { RespostaPergunta } from "./respostaPergunta";

export class RespostaTrBody extends TrBody {
  numero?: number;
  pergunta?: string;
  resposta?: string;
  tipoPergunta?: string;
  respostaPergunta?: RespostaPergunta;
}
