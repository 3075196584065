import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Valida se o valor do control é uma data válida
 */
export default (control: AbstractControl): ValidationErrors => {

  if (isValidDate(control.value)) {
    return null;
  }

  return {
    notDate: true,
  };

};

function isValidDate(dateString: string) {
  if (!dateString || (!dateString.includes('/') && !dateString.includes('-'))) {
    return false;
  }

  if (dateString.includes('-')) {    
    const isoDate = new Date(dateString);
    return isoDate && !isNaN(isoDate.getDate());
  }

  const dateStringArray = dateString.split('/');
  const dateNumberArray = dateStringArray.map(ds => parseInt(ds, 10));

  const d = new Date(dateNumberArray[2], dateNumberArray[1] - 1, dateNumberArray[0]);
  return d && (d.getMonth() + 1) === dateNumberArray[1];
}
