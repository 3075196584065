<div class="container-page">
  <div class="header-component">
    <div class="breadcrumb">
      <i class="icon-funcionarios"></i>
      <h2 [routerLink]="'/funcionario'" routerLinkActive="active">Funcionários</h2>
      <span>/</span>
      <h4>Funcionários cadastrados</h4>
    </div>
    <div class="action-header" *ngIf="getAuthenticatedUserAuthorities().includes(authority.CADASTRAR_FUNCIONARIO)">
      <button class="sg-btn sg-secondary" [routerLink]="'/funcionario/cadastro'" title="adicionar novo funcionário">ADICIONAR NOVO</button>
    </div>
  </div>
  <div class="content-clientes-cadastrados">

    <!--
      EXIBINDO OPÇÃO DOWNLOAD = FALSE
      exportação de relatorio de funcionarios n é contemplado na release
      básica do tensai.
    -->
    <app-tabela-filtravel
      #tabela
      [placeholder]="placeholder"
      (eventEmitter)="manipularEventoTabela($event)"
      [filterService]="funcionarioService"
      [nomeAtributosExibidos]="nomeAtributosTabela"
      [nomeColunas]="nomeColunasTabela"
      [exibirOpcaoDownload]="false"
      [removePermissions]="funcionarioRemovePermissions"
      [initialValue]="initialSearchValue"
    ></app-tabela-filtravel>

  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
