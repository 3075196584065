import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalDataControllerService {
  private isValid: boolean = true;

  getFlag(): boolean {
    return this.isValid;
  }

  setFlag(value: boolean) {
    this.isValid = value;
  }
}
