<div class="months-container">
  <div class="containerArrows">
    <span class="arrow-left" (click)="prevMonth()"></span>
  </div>
  <div class="month">{{monthName}}</div>
  <div class="containerArrows">
    <span class="arrow-right" (click)="nextMonth()"></span>
  </div>
</div>
<div class="container">
  <div class="calendar">
    <div class="div-alter" *ngFor="let day of weekDays"> <span class="week-day"> {{ day }} </span></div>
  </div>
  <div class="calendar" *ngFor="let week of calendar">
    <div *ngFor="let day of week" class="div-alter"
      [style.background]="isInitialDateOrEndDate(day.valueOf())">
      <div [style.background]="isInitialDateOrEndDate(day.valueOf())">
        <span
        [class]="initialDate === day.valueOf() ? 'initial-date' : endDate === day.valueOf() ? 'end-date' : 'isSame'"
        [style.background]="background(day)"
        [style.color]="textColor(day)"
        [style.fontWeight]="fontWeight(day)"
        (click)="handleDayClick(day)">
        {{ day.format('DD') }}
      </span>
      </div>
    </div>
  </div>
</div>