<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>

<form
  [formGroup]="form"
  class="form-container"
  (ngSubmit)="isEdicao ? handleSaveButton() : handleRegisterButton()"
>
  <app-basic-information
    [formGroup]="form.get('basicInformation')"
    [isEdicao]="isEdicao"
    [submitted]="submitted"
    (handleChangeDocInput)="changeDocInput($event)"
  >
  </app-basic-information>

  <app-admin-information
    [formGroup]="form.get('adminInformation')"
    [newAdmin]="newAdmin"
    [isEdicao]="isEdicao"
    [submitted]="submitted"
    (editContractor)="editContractor($event)"
    (adminInitialValues)="adminInitialValues()"
  >
  </app-admin-information>

  <app-customization
    [formGroup]="form.get('customization')"
    [isEdicao]="isEdicao"
    [themeName]="themeName"
  >
  </app-customization>

  <div class="footer-buttons">
    <button
      type="button"
      class="footer-cancel-button"
      (click)="handleCloseButton()"
    >
      Cancelar
    </button>

    <button type="submit" class="footer-save-button">
      <span *ngIf="isEdicao">Salvar</span>
      <span *ngIf="!isEdicao">Cadastrar</span>
    </button>
  </div>
</form>

<app-loading *ngIf="isLoading"></app-loading>
