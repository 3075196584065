import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import { LeafletMapBetaComponent } from "app/componentes/leaflet-map-beta/leaflet-map-beta.component";
import { LocalidadeService } from "app/modulos/localidade-beta/servico/localidade.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { SituacaoListagemDeColetasParams } from "../../../constant";
import { errorMessages } from "../../../constant/errors";
import {
  IFilterSelect,
  IFindedLocationResponse,
  ISurveyCollectionSummary,
  ISurveyMapDetailsResponse,
  ISurveyMapQuery,
} from "../../../interfaces";
import {
  IMapResponsePayload,
  IOperatorsListModel,
  IOperatorsListResponse,
} from "../../../interfaces/survey-map";
import { AuditoriaBetaService } from "../../../services/auditoria-beta.service";
import { adaptCollectionSummaryRespToModel } from "../../../utils/parsers";
import { TooltipColetasService } from "./tooltip-coletas/tooltip-coletas.service";
import { IFilterSelectEvent } from "app/componentes/filter-select/filter-select";
import { splitDate } from "app/modulos/auditoria-beta/utils/getIndexByArray";
import { ModalService } from "app/componentes/modal/modal.service";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: "app-survey-map",
  templateUrl: "./survey-map.component.html",
  styleUrls: ["./survey-map.component.scss"],
})
export class SurveyMapComponent implements OnInit {
  // Id de referência da pesquisa
  @Input() surveyId: number = null;

  // Referência para o componente mapa
  @ViewChild(LeafletMapBetaComponent) leafletMap!: LeafletMapBetaComponent;

  isLoading: boolean = false;

  statusOptions: IFilterSelect[] = [
    { id: 1, label: "Aprovada", key: SituacaoListagemDeColetasParams.APROVADA },
    {
      id: 2,
      label: "Em análise",
      key: SituacaoListagemDeColetasParams.EM_ANALISE,
    },
    {
      id: 3,
      label: "Não auditada",
      key: SituacaoListagemDeColetasParams.NAO_AUDITADA,
    },
    {
      id: 4,
      label: "Reprovada",
      key: SituacaoListagemDeColetasParams.REPROVADA,
    },
  ];
  locationOption: IFilterSelect[] = [];

  // Opções de filtragem do mapa.
  filteredOptions: ISurveyMapQuery = {
    surveyId: this.surveyId,
    id_operador: null,
    localidades: [],
    situacao: [],
    periodo_inicio: "",
    periodo_fim: "",
  };

  filterLocationConf = {
    icon: FilterIcon.MARKER,
    type: FilterTypes.CHECK,
  };
  filterStatusConf = {
    icon: FilterIcon.FUNNEL,
    type: FilterTypes.CHECK,
  };
  filterDateRangeConf = {
    icon: FilterIcon.CALENDAR,
    type: FilterTypes.RANGE_DATE,
    placeholder: "Período",
  };

  surveyIntevalCollection = {
    startDate: null,
    endDate: null,
  };

  operators: IOperatorsListModel[] = [];
  operatorDropdownOptions: IFilterSelect[] = [];

  constructor(
    private auditoriaService: AuditoriaBetaService,
    private localidadeService: LocalidadeService,
    private tooltipService: TooltipColetasService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.initializeQueryOptions();
    this.fetchSurveyMapResults();
    this.fetchPeriod();
    this.fetchLocations();
    this.fetchOperators();
  }

  private fetchPeriod() {
    this.auditoriaService
      .getPeriod(this.surveyId)
      .subscribe({
        next: (data) => {
          const dateList = data.map(i => splitDate(i.data_coleta)) as [];
          this.surveyIntevalCollection.startDate = new Date(Math.min(...dateList));
          this.surveyIntevalCollection.endDate = new Date(Math.max(...dateList));
        },
        error: (err) => this.errorHandlerService.handleError(err, 'Erro ao listar os periodos de coleta')
      });
  }

  private fetchLocations() {
    this.auditoriaService
      .getLocations(this.surveyId)
      .subscribe({
        next: (data) => {
          this.locationOption = data.map(location => ({ id: location.id, label: location.nome }))
        },
        error: (err) => this.errorHandlerService.handleError(err, 'Erro ao listar localidades')
      });
  }

  private fetchOperators() {
    this.auditoriaService
      .getAllOperators(this.surveyId)
      .subscribe({
        next: (operatorObject: IOperatorsListResponse) => {
          this.operators = Object.entries(operatorObject).map((list) => {
            return {
              id: Number(list[0]),
              nome: list[1],
            } as IOperatorsListModel;
          });

          this.operatorDropdownOptions = this.operators.map((v) => ({
            id: v.id,
            label: v.nome,
          }));
        },
        error: (err) => this.errorHandlerService.handleError(err, 'Erro ao listar operadores')
      });
  }

  private initializeQueryOptions() {
    this.filteredOptions.surveyId = this.surveyId;
  }

  public handleTextChange($event: IFilterSelect) {
    this.filteredOptions.id_operador = $event?.id || null;
    this.fetchSurveyMapResults($event.label);
  }

  public handleChangeLocations($event: IFilterSelectEvent) {
    const selectedLocationIds = $event.currentState.map((_loc) => _loc.id);

    this.updateSelectedLocations(selectedLocationIds);
  }

  public handleChangeStatusFilter($event: IFilterSelectEvent) {
    this.filteredOptions.situacao = [...$event.currentState.map((v) => v.key)];

    this.fetchSurveyMapResults();
  }

  public handleChangeDateRange($event: DateRange<Date>) {
    if (!!$event) {
      const { start, end } = $event;
      this.filterDateRangeConf.placeholder = `${start.toLocaleDateString()} ${
        end ? "- " + end.toLocaleDateString() : ""
      }`;
    } else {
      this.filterDateRangeConf.placeholder = "Período";
    }
    this.updateSelectedDateRange($event);
  }

  private updateSelectedLocations(locs: number[]) {
    this.filteredOptions.localidades = [...locs];

    this.fetchSurveyMapResults();
  }

  private updateSelectedDateRange(range: DateRange<Date>) {
    if (!!range) {
      const { start, end } = range;
      this.filteredOptions.periodo_inicio = start.toLocaleDateString();
      this.filteredOptions.periodo_fim = end ? end.toLocaleDateString() : "";
    } else {
      this.filteredOptions.periodo_inicio = "";
      this.filteredOptions.periodo_fim = "";
    }
    this.fetchSurveyMapResults();
  }

  private mapGeoIdToResponsability(localidades: IFindedLocationResponse[]) {
    const searchByGeoIdParams = [];
    const searchByCoordsParams = [];

    localidades.forEach((_loc) => {
      if (_loc.geoId) {
        searchByGeoIdParams.push({ geoId: _loc.geoId, radius: _loc.raio });
      } else {
        searchByCoordsParams.push({
          lat: _loc.latitude,
          long: _loc.longitude,
          radius: _loc.raio,
        });
      }
    });

    const fetchByGeoId = !!searchByGeoIdParams.length
      ? {
          fetchByGeoId: {
            params: searchByGeoIdParams,
          },
        }
      : {};

    const fetchByCoords = !!searchByCoordsParams.length
      ? {
          fetchByCoords: {
            params: searchByCoordsParams,
          },
        }
      : {};

    return {
      ...fetchByGeoId,
      ...fetchByCoords,
    };
  }

  private async fetchLocalityDetailsByGeoId(params: string[]) {
    const result =
      await this.localidadeService.listarLocalidadesComDetalhesByGeoId(params);

    return result;
  }

  private async fetchLocalityDetailsByCoords(
    coords: { lat: number; long: number; radius: number }[]
  ) {
    const result = [];

    // TEMP: Depois o processamento não será síncrono, isso é apenas uma medida temporária.
    for (let i = 0; i < coords.length; i++) {
      const nominatimResp =
        await this.localidadeService.listarLocalidadesComDetalhesByCoords(
          coords[i].lat,
          coords[i].long
        );

      result.push({ ...nominatimResp, radius: coords[i].radius });
    }

    return result;
  }

  private async fetchAllPlacesProxy(placesResponse: IFindedLocationResponse[]) {
    const locFetcherStrategies = this.mapGeoIdToResponsability(placesResponse);

    const results = [];

    if (locFetcherStrategies.fetchByGeoId) {
      const geoIds = locFetcherStrategies.fetchByGeoId.params.map(
        (v) => v.geoId
      );
      const radius = locFetcherStrategies.fetchByGeoId.params.map(
        (v) => v.radius
      );

      const result = await this.fetchLocalityDetailsByGeoId(geoIds);

      results.push(...result.map((v, i) => ({ ...v, radius: radius[i] })));
    }

    if (locFetcherStrategies.fetchByCoords) {
      const result = await this.fetchLocalityDetailsByCoords(
        locFetcherStrategies.fetchByCoords.params
      );

      results.push(...result);
    }

    return results;
  }

  private requestShowCollectionSummary(
    collectionId: number,
    positiveCallback: Function
  ) {
    this.auditoriaService
      .getCollectionSumary({
        surveyId: this.surveyId,
        collectionId: collectionId,
      })
      .subscribe({
        next: (response) => {
          const adaptedRes = adaptCollectionSummaryRespToModel(response);
          positiveCallback(adaptedRes);
        },
      });
  }

  private setUpMapEvents() {
    const onShowTooltip = async (mapClickEvent) => {
      const {
        target: {
          feature: {
            properties: { id },
          },
        },
      } = mapClickEvent;

      this.requestShowCollectionSummary(
        id,
        (collectionSummary: ISurveyCollectionSummary) => {
          this.tooltipService.setData(collectionSummary);

          this.tooltipService.showAt(
            mapClickEvent.originalEvent.x,
            mapClickEvent.originalEvent.y + document.documentElement.scrollTop
          );
        }
      );
    };

    this.leafletMap.addEventToLayer({
      eventType: "click",
      LayerType: LeafletMapBetaComponent.LAYERTYPES.MARKER,
      handler: onShowTooltip,
    });
  }

  private async handleMapMounting(mapDetails: ISurveyMapDetailsResponse) {
    const results = await this.fetchAllPlacesProxy(mapDetails.localidades);

    if (!results.length) {
      return;
    }

    // set current location
    await this.leafletMap.setLocation(
      mapDetails.localidades[0].latitude,
      mapDetails.localidades[0].longitude
    );

    if (this.filteredOptions.localidades.length > 1) {
      const selectedLocationsCoords = mapDetails.localidades.map((_loc) => ({
        lat: _loc.latitude,
        lon: _loc.longitude,
      }));
      await this.leafletMap.fitBoundsByLinearCoords(selectedLocationsCoords);
    } else {
      // set bounding box if api return's some data
      await this.leafletMap.fitBounds(results[0].boundingbox);
    }

    const polygonalResults = [];
    const circularResults = [];

    results.forEach((osmObject) => {
      if (osmObject.geojson && osmObject.geojson.type === "Polygon") {
        polygonalResults.push(osmObject.geojson);
      } else {
        circularResults.push({
          lat: osmObject.lat,
          lon: osmObject.lon,
          radius: osmObject.radius,
        });
      }
    });

    // Map results to expected marker config { type, coordinates }, its useful to create a Feature Collection in leaflet.
    const polygonsAndPointers = [
      // Polygons
      ...polygonalResults,
      // Points
      ...mapDetails.coletas.map((v) => ({
        type: "Point",
        coordinates: [v.longitude, v.latitude],
        properties: {
          id: v.id,
        },
      })),
    ];

    // draw polygons and pointers
    this.leafletMap.drawMarkers(polygonsAndPointers);
    // draw circles
    this.leafletMap.drawCircles(circularResults);

    // set up events (such as click events or hover events)
    this.setUpMapEvents();
  }

  /**
   * Modal chamada quando se deseja limpar os layers do mapa
   */
  cleanMapModal(title: string, text: string, icon: string) {
    this.modalService.showModal({
      title: title,
      messageModal: text,
      isOnlyConfirmation: true,
      icon: icon,
      btnTitlePositive: 'Entendi',
      positiveCallback: () => this.leafletMap.removeLayers()
    })
  }

  /**
   * Verfica se o status da requisição é 200 para renderização do mapa
   * Se status for igual à 204 remove todos os layers do mapa (pins, delimitadores, etc.)
   */
  private onSurveyMapResultsIsValid(response: HttpResponse<IMapResponsePayload>, operatorName: string) {
    const { body, status } = response;
    if(status === 204) {
      if(operatorName.length) {
        this.cleanMapModal('Nenhuma coleta encontrada', `${operatorName} ainda não enviou nenhuma coleta. Por favor realize uma nova busca.`,
          'fa-regular fa-magnifying-glass');
      } else {
        this.cleanMapModal('Nenhum resultado encontrado', 'Não foram encontrados resultados. Revise os filtros selecionados.', 'fa-regular fa-filter');
      }
    } else if (body) this.handleMapMounting(body);
  }

  private fetchSurveyMapResults(operatorName: string = '') {
    this.isLoading = true;
    this.auditoriaService.getSurveyMapDetails(this.filteredOptions).subscribe({
      next: (response) => {
        this.onSurveyMapResultsIsValid(response, operatorName);
      },
      complete: () => {
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.errorHandlerService.handleError(err, errorMessages.surveyMap.title, errorMessages.surveyMap.message);
      },
    });
  }
}
