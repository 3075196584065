
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

import { Pergunta } from '../../../../../../../pesquisa-old/cadastro/model/pergunta';

import { Alternativa } from '../../../../../../../pesquisa-old/cadastro/model/alternativa';
import { TituloGrade } from '../../../../../../../pesquisa-old/cadastro/model/tituloGrade';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoAlternativa } from '../../../../../../../auditoria/resposta-pergunta-listagem/tipoAlternativa';
import { CadastroPesquisaStoreState } from '../../../../../../../pesquisa-old/cadastro/store/cadastroPesquisaStoreState';
import { PassoCadastro } from '../../../../../store/passoCadastro';
// tslint:disable-next-line: max-line-lengthformG
import { ItemProtegido } from '../../../../../security/itemProtegido';
import { PesquisaAuthorityService } from '../../../../../security/pesquisaAuthorityService';
//import { store } from '@angular/core/src/render3';
import { AlterarPergunta, Selecionar } from '../../../../../store/actions/questionarioAction';

/**
 * Classe abstrata que define o comportamento padrao
 * de conteudos de pergunta no questionario
 */
@Directive()
export abstract class AbstractPerguntaConteudo implements OnInit, OnDestroy {

  public formGroup: UntypedFormGroup;
  public controls: { [key: string]: AbstractControl };

  /**
   * Marcador para saber se os erros de validacao
   * devem ser exibidos
   */
  public showErrors: boolean = false;

  /**
  * Subscriptions que deverão ser encerradas
  * junto com a destruição do componente
  */
  public subscriptions: Subscription[] = [];

  @Input() source: Pergunta;

  /**
   * Marcador que indica se modificações
   * estão habilitadas
   */
  public modificacaoPermitida = false;

  /**
   * totalAmostras que a pesquisaPossui
   */
  protected totalAmostrasPesquisa;

  public passoBloqueado: boolean = false;
  /**
   * Passo do cadastro ao qual o conteudo esta vinculado
   */
  public passoCadastro: PassoCadastro;

  constructor(protected pesquisaAuthorityService: PesquisaAuthorityService,
    protected store: Store<CadastroPesquisaStoreState>, passoCadastro: PassoCadastro = PassoCadastro.QUESTIONARIO) {
  }

  /**
   * @override
   */
  ngOnInit(): void {
    this.formGroup = Pergunta.getInitializedControl(this.source);
    this.controls = this.formGroup.controls;

    // tslint:disable-next-line: max-line-length
    this.modificacaoPermitida = this.pesquisaAuthorityService.usuarioPodeModificar(ItemProtegido.QUESTIONARIO);

    const pesquisaStoreObservable: Observable<CadastroPesquisaStoreState> = this.store
      .pipe(
        map(x => x['cadastroPesquisa']),
        map(pesquisa => pesquisa ? pesquisa : new CadastroPesquisaStoreState()),
      );

    const passosBloqueadosSubscription = pesquisaStoreObservable.subscribe((store) => {
      // tslint:disable-next-line: max-line-length
      this.passoBloqueado = store.dadosGeraisCadastro.passosBloqueados.includes(this.passoCadastro);

      if (!this.modificacaoPermitida || this.passoBloqueado) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    });

    const totalAmostrasSubscription = pesquisaStoreObservable.pipe(
      map(storeState => storeState.pesquisa),
    ).subscribe((pesquisa) => {
      this.totalAmostrasPesquisa = pesquisa.configuracaoPesquisa.amostrasPresenciais;
    });

    /**
     * Verificando se deve-se exibir as mensagens de validação
     * no passo atual, caso positivo, é feito o unsubscribe
     * no canal
     */
    const dadosPassoSubscription = pesquisaStoreObservable
      .pipe(
        map(store => store.dadosPasso),
      )
      .subscribe((dadosPasso) => {
        const dados = dadosPasso.get(PassoCadastro.QUESTIONARIO);
        // tslint:disable-next-line: max-line-length
        this.showErrors = dados.exibirValidacao;
        if (this.showErrors && dadosPassoSubscription) {
          dadosPassoSubscription.unsubscribe();
        }
      });

    this.subscriptions.push(passosBloqueadosSubscription);
    this.subscriptions.push(totalAmostrasSubscription);
    this.subscriptions.push(dadosPassoSubscription);
  }

  onChange() {
    this.store.dispatch(new AlterarPergunta(this.formGroup.getRawValue()));
  }

  /**
   * @override
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Função que fecha o conteudo que está
   * sendo exibido
   */
  cancelar() {
    this.store.dispatch(new Selecionar(null, null));
  }

  adicionarAlternativa(tipoAlternativa: TipoAlternativa) {
    const alternativa = new Alternativa();
    alternativa.hashPergunta = this.source.hash;
    alternativa.hashSecao = this.source.hashSecao;
    alternativa.tipoAlternativa = tipoAlternativa;
    /**
     * As alternativas sao inseridas localmente, só devem
     * ser enviadas à store quando clicar em 'Salvar'
     */
    (<UntypedFormArray>this.controls.alternativas).push(Alternativa.getInitializedControl(alternativa));
    // this.store.dispatch(new AdicionarAlternativa(alternativa));
  }

  adicionarTituloGrade() {
    const tituloGrade = new TituloGrade();
    tituloGrade.hashPergunta = this.source.hash;
    tituloGrade.hashSecao = this.source.hashSecao;

    /**
     * Os titulos grade sao inseridos localmente, só devem
     * ser enviadas à store quando clicar em 'Salvar'
     */
    (<UntypedFormArray>this.controls.titulosGrade).push(TituloGrade.getInitializedControl(tituloGrade));
    // this.store.dispatch(new AdicionarTituloGrade(tituloGrade));
  }

  /**
   * Auxiliar para loops em AbstractControl que na verdade
   * são FormArrays
   */
  getControlsFromAbstractFormArray(abstractFormArray: AbstractControl): AbstractControl[] {

    const formArray = <UntypedFormArray>abstractFormArray;
    return formArray.controls;
  }

}
