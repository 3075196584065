<section class="interviewConfig container-white">
  <div class="headerConfig">
    <span class="fa fa-flag-o"></span>
    <h3>Seção</h3>
  </div>
  <div class="contentConfig">
    <div [formGroup]="formGroup" class="section sg-input-group">
      <div class="campo">
        <label>Nome*</label>
        <input mascara-primeiro-digito-sem-espaco placeholder="nome da seção" title="seção" type="text" formControlName="nome" maxlength="30">
        <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
            A seção deve possuir um nome de até 30 caracteres
        </span>
      </div>
      <div class="campo">
        <label>Descrição</label> 
        <input mascara-primeiro-digito-sem-espaco placeholder="descrição da seção" title="descrição da seção" type="text" formControlName="descricao" maxlength="150">
        <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
            A seção deve possuir uma descrição de até 150 caracteres
        </span>
      </div>
      <div class="actionConfig">
        <button title="Cancelar seção" class="sg-btn sg-alternate" (click)="cancelar()">Cancelar</button>
        <button title="Salvar seção" class="sg-btn sg-secondary" (click)="onChange()" *ngIf="modificacaoPermitida && !passoBloqueado">Salvar</button>
      </div>
    </div>
  </div>
</section>
