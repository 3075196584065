<header>
  <div class="operator-info">
    <img class="operator-image" [src]="operador.foto.arquivo" />
    <span>
      <h3>{{ operador.nome }}</h3>
      <p>Matrícula: {{ operador.matricula }}</p>
    </span>
  </div>

  <div class="survey-info">
    <h3>{{ surveyTitle }}</h3>
  </div>

  <div class="work-info">
    <h3>Dias trabalhados</h3>
    <p>{{ operador.dias_trabalhados }} dias</p>
  </div>

  <div class="svg-card">

    <svg width="263" height="286" viewBox="0 0 263 286" fill="none" xmlns="http://www.w3.org/2000/svg" class="background-svg">
      <mask id="mask0_386_15768" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="358" height="286">
      <path d="M89.3592 85.8018H44.6797V114.402H89.3592V85.8018Z" [attr.fill]="svgColor"/>
      <path d="M44.6796 57.2009H22.3398V85.8014H44.6796V57.2009Z" [attr.fill]="svgColor"/>
      <path d="M111.699 57.2009H89.359V85.8014H111.699V57.2009Z" [attr.fill]="svgColor"/>
      <path d="M134.039 0H0V57.201H22.3398V28.6005H111.699V57.201H134.039V0Z" [attr.fill]="svgColor"/>
      <path d="M268.073 57.2009H245.733V85.8014H268.073V57.2009Z" [attr.fill]="svgColor"/>
      <path d="M357.431 0H223.393V57.201H245.733V28.6005H335.092V57.201H357.431V0Z" [attr.fill]="svgColor"/>
      <path d="M89.3592 257.399H44.6797V286H89.3592V257.399Z" [attr.fill]="svgColor"/>
      <path d="M44.6796 228.799H22.3398V257.399H44.6796V228.799Z" [attr.fill]="svgColor"/>
      <path d="M111.699 228.799H89.359V257.399H111.699V228.799Z" [attr.fill]="svgColor"/>
      <path d="M134.039 171.598H0V228.799H22.3398V200.198H111.699V228.799H134.039V171.598Z" [attr.fill]="svgColor"/>
      <path d="M268.073 228.799H245.733V257.399H268.073V228.799Z" [attr.fill]="svgColor"/>
      <path d="M357.432 171.598H223.393V228.799H245.733V200.198H335.092V228.799H357.432V171.598Z" [attr.fill]="svgColor"/>
      <path d="M201.053 171.598H156.374V200.198H201.053V171.598Z" [attr.fill]="svgColor"/>
      <path d="M156.379 142.997H134.039V171.598H156.379V142.997Z" [attr.fill]="svgColor"/>
      <path d="M223.393 142.997H201.053V171.598H223.393V142.997Z" [attr.fill]="svgColor"/>
      <path d="M245.733 85.8008H111.699V142.997H134.039V114.401H223.393V142.997H245.733V85.8008Z" [attr.fill]="svgColor"/>
      </mask>
      <g mask="url(#mask0_386_15768)">
      <rect x="-18" y="118" width="241" height="135" [attr.fill]="svgColor"/>
      </g>
    </svg>


  </div>
</header>
