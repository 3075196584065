import { Injectable } from "@angular/core";
import { b64DecodeUnicode } from "app/util/misc/base64Handler";

@Injectable({
  providedIn: "root",
})
export class JWTService {
  _defaultAuthUserKey: string = "security-login-token";
  _activeAuthUserIdentifier: string = "active-auth-user";

  setActiveAuthUserIdentifier(authUserKey: string) {
    sessionStorage.setItem(this._activeAuthUserIdentifier, authUserKey);
  }

  getActiveAuthUser() {
    return sessionStorage.getItem(this._activeAuthUserIdentifier);
  }

  getActiveAuthUserIdentifier() {
    return (
      this.getActiveAuthUser() ||
      this._defaultAuthUserKey
    );
  }

  /**
   * recupera as claims de um dado token
   */
  getTokenClaims(token: string) {
    if (!token || token.trim().length === 0) {
      return {};
    }

    encodeURIComponent;

    const encodedTokenData = token.split(".")[1];
    const decodedTokenData = b64DecodeUnicode(encodedTokenData);

    const tokenData = JSON.parse(decodedTokenData);

    return tokenData;
  }

  /**
   * remove o token do localStorage
   */
  removeToken() {
    localStorage.removeItem(this.getActiveAuthUserIdentifier());
    sessionStorage.removeItem(this.getActiveAuthUserIdentifier());
  }

  /**
   * armazena um dado token no localStorage
   */
  setToken(token: string, keepConnected?: boolean) {
    if (!token || token.trim().length === 0) {
      return;
    }

    if (token.includes("Bearer")) {
      const start = token.indexOf(" ") + 1;
      const formattedToken = token.substr(start, token.length);
      keepConnected
        ? localStorage.setItem(
            this.getActiveAuthUserIdentifier(),
            formattedToken
          )
        : sessionStorage.setItem(
            this.getActiveAuthUserIdentifier(),
            formattedToken
          );
    } else {
      keepConnected
        ? localStorage.setItem(this.getActiveAuthUserIdentifier(), token)
        : sessionStorage.setItem(this.getActiveAuthUserIdentifier(), token);
    }
  }

  /**
   * recupera o token armazenado no localStorage
   */
  getToken(): string {
    return (
      sessionStorage.getItem(this.getActiveAuthUserIdentifier()) ||
      localStorage.getItem(this.getActiveAuthUserIdentifier())
    );
  }
}
