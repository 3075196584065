import { Component, Input, OnInit } from '@angular/core';
import { PesquisaDependencyConflict } from './pesquisaDependencyConflict';

@Component({
  selector: 'app-pesquisa-dependency-conflict',
  templateUrl: './pesquisa-dependency-conflict.component.html',
  styleUrls: ['./pesquisa-dependency-conflict.component.scss'],
})
export class PesquisaDependencyConflictComponent implements OnInit {
  @Input() data: PesquisaDependencyConflict;

  ngOnInit() { }
}
