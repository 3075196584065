/**
 * Opções de ordenação.
 * @param sort atributo no qual o resultado deve ser ordenado. (ex: nome)
 * @param orientation orientação da ordenação, pode ser "asc" para ascendente e
 * "desc" para descendente.
 */
export class SortingOptions {

  sort: string;
  orientation: string;

  static ASC : string = 'asc';
  static DESC : string = 'desc';

  constructor(sort : string, orientation : string = SortingOptions.ASC) {
    this.sort = sort;
    this.orientation = orientation;
  }

  public switchOrientation() : void {

    if (this.isASC()) {
      this.orientation = SortingOptions.DESC;
    } else {
      this.orientation = SortingOptions.ASC;
    }
  }

  public toString() : string {
    return `${this.sort},${this.orientation}`;
  }

  public isASC() : boolean {
    return this.orientation === SortingOptions.ASC;
  }

}
