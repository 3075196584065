/**
 * Enum que determina quais items da pesquisa
 * estão protegidos, ou seja, quais regras de
 * autorização se aplicam à ele
 */
export enum ItemProtegido {

    CARACTERISTICAS = 'CARACTERISTICAS',
    QUESTIONARIO = 'QUESTIONARIO',
    MARCACOES = 'MARCACOES',
    VINCULOS = 'VINCULOS',
    MUDANCA_STATUS = 'MUDANCA_STATUS',
    CADASTRAR_CLIENTE = 'CADASTRAR_CLIENTE',

}
