import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfiguracaoPaginacao } from '../paginacao/configuracaoPaginacao';
import { DadosPaginacao } from '../paginacao/dadosPaginacao';
import { EventoTabela } from './evento/eventoTabela';
import { TipoEventoTabela } from './evento/tipoEventoTabela';
import { TrBody } from './trbody/trBody';

@Directive()
export abstract class TabelaComponent implements OnInit, OnDestroy {

  trBodies: TrBody[] = [];
  trBodiesSelecionados: string[] = [];
  selecionarTodos: boolean = false;
  ultimaBusca: string = '';

  /**
   * a tabela pode ser inicializada com um dado de paginação
   */
  @Input() ultimoDadoPaginacao: DadosPaginacao;

  isLoading: boolean = false;
  // Caso a lista de trBodies esteja vazia , será setado para true,
  // onde será renderizado a mensagem informando que os dados estarão vazio
  showMensagem: boolean = false;

  // tslint:disable-next-line:max-line-length
  subjectPaginacao: BehaviorSubject<ConfiguracaoPaginacao> = new BehaviorSubject<ConfiguracaoPaginacao>(undefined);

  @Input() apenasListagem: boolean = false;

  @Input() tableEventProvider: Observable<EventoTabela>;
  tableEventProviderSubscription: Subscription;

  /**
   * Exibir opcao download
   */
  @Input() exibirOpcaoDownload: boolean = false;

  /**
   * Nome das colunos da tabela, em suma, os <th> do <thead>
   */
  @Input() nomeColunas: string[] = [];

  /**
   * Nome dos atributos do objeto que serao exibidos na tabela,
   * na ordem em que serao exibidos
   */
  @Input() nomeAtributosExibidos: string[];

  /**
   * A execucoes solicitadas nas tarefas serao
   * repassadas na forma de eventos
   */
  @Output() eventEmitter: EventEmitter<EventoTabela> = new EventEmitter();

  ngOnInit() {
    // Inicializando a tabela
    this.inicializarDadosTabela();

    if (this.tableEventProvider) {
      // tslint:disable-next-line:max-line-length
      this.tableEventProviderSubscription = this.tableEventProvider.subscribe(this.handleTableEventProvider());
    }

  }

  /**
   * Função responsavel por selecionar qual a estrategia adequada para
   * manipular os eventos de tabela que foram disparados
   */
  handleTableEventProvider() {
    return (eventoTabela: EventoTabela) => {

      switch (eventoTabela.tipo) {
        case TipoEventoTabela.SELECAO: this.handleSelectionTableEvent(eventoTabela);
          break;
      }
    };
  }

  inicializarDadosTabela(isEventoPaginacao: boolean = false) {
    this.isLoading = true;

    this.carregarTrBodies()
      .subscribe((data) => {
        this.isLoading = false;
        this.trBodies = data;
        if (this.trBodies.length === 0) {
          this.showMensagem = true;
        }
      });

    /**
     * Se eh uma nova busca e nao uma navegacao de paginacao, uma nova configuracao
     * para a paginacao deve ser enviada
     */
    if (!isEventoPaginacao) {
      this.getTotalResultados().subscribe((total) => {
        this.atualizarPaginacao(total, 0);
      });
    }
  }

  atualizarPaginacao(totalPaginas: number, paginaAtual: number, emit: boolean = true) {

    const configuracaoPaginacao = new ConfiguracaoPaginacao();

    configuracaoPaginacao.totalPaginas = totalPaginas;
    configuracaoPaginacao.paginaAtual = paginaAtual;
    configuracaoPaginacao.emit = emit;

    this.subjectPaginacao.next(configuracaoPaginacao);
  }

  abstract getTotalResultados(): Observable<number>;

  /**
   * Método abstrato cuja implementação deverá prover
   * instâncias de TrBody para o preenchimento da
   * tabela.
   */
  abstract carregarTrBodies(): Observable<TrBody[]>;

  ngOnDestroy(): void {
    if (this.tableEventProviderSubscription) {
      this.tableEventProviderSubscription.unsubscribe();
    }
  }

  /**
   * Função que trata eventos de seleção na tabela,
   * neste caso, marcando os checkbox caso o id contido
   * no payload do evento estaja presente na tabela
   */
  handleSelectionTableEvent(eventoTabela: EventoTabela) {

    const idsSelecionados = eventoTabela.payload;
    this.trBodiesSelecionados.push(...idsSelecionados);

    this.atualizarSelecaoTrBodies();
  }

  /**
   * Atualiza os checkbox dos trBodies, util pois todos os fluxos sao assincronos
   */
  atualizarSelecaoTrBodies() {
    this.trBodies.forEach((trBody) => {
      // const isSelecionado = this.trBodiesSelecionados.includes(trBody.idContaUsuario);

      this.trBodiesSelecionados.map((selecionado) => {
        if (selecionado === trBody.idContaUsuario || selecionado === trBody.id) {
          trBody.selected = true;
        }
      });
      // if (isSelecionado) {
      //   trBody.selected = true;
      // }
    });
    this.enviarEventoSelecao();
  }

  /**
   * envia evento de mudança na paginação da tabela
   */
  enviarEventoPaginacao(paginacao: DadosPaginacao) {
    const eventoEdicao = new EventoTabela(TipoEventoTabela.PAGINACAO, paginacao);
    this.eventEmitter.emit(eventoEdicao);
  }

  enviarEventoEdicao(id: string) {

    const eventoEdicao = new EventoTabela(TipoEventoTabela.EDITAR, id);
    this.eventEmitter.emit(eventoEdicao);
  }

  enviarEventoExclusao(ids: string[]) {

    const payload = Array.isArray(ids) ? ids : [ids];
    const eventoExclusao = new EventoTabela(TipoEventoTabela.EXCLUIR, payload);
    this.eventEmitter.emit(eventoExclusao);

  }

  enviarEventoDownload() {
    const eventoDownload = new EventoTabela(TipoEventoTabela.DOWNLOAD, null);
    this.eventEmitter.emit(eventoDownload);
  }

  enviarEventoExclusaoItensSelecionados() {
    const idItensSelecionados = this.trBodiesSelecionados;
    this.enviarEventoExclusao(idItensSelecionados);
  }

  enviarEventoSelecao() {
    // tslint:disable-next-line:max-line-length
    const eventoTabela = new EventoTabela(TipoEventoTabela.SELECAO, this.trBodiesSelecionados);
    this.eventEmitter.emit(eventoTabela);
  }

  acionarSelecionarTodos() {
    this.selecionarTodos = !this.selecionarTodos;

    this.trBodies.forEach((trBody) => {
      this.selectTrBody(trBody, this.selecionarTodos);
    });
  }

  /**
   * Seleciona ou desceleciona uma linha da tabela
   */
  selectTrBody(trBody: TrBody, value: boolean = undefined) {

    if (value === undefined) {
      trBody.selected = !trBody.selected;
    } else {
      trBody.selected = value;
    }

    if (trBody.selected) {
      this.trBodiesSelecionados.push(trBody.id);
    } else {
      const indexToRemove = this.trBodiesSelecionados.indexOf(trBody.id);
      if (indexToRemove > -1) {
        this.trBodiesSelecionados.splice(indexToRemove, 1);
      }
    }
    this.enviarEventoSelecao();
  }

  /**
   * Quando ocorre um evento de paginacao, a mesma eh armazenada em this.ultimoDadoPaginacao
   * a fim de  aplicar a configuracao de paginacao (basicamente o tamanho da página)
   * no caso de uma busca no filtro
   */
  onPaginationChange(eventoPaginacao: DadosPaginacao) {
    this.ultimoDadoPaginacao = eventoPaginacao;
    this.inicializarDadosTabela(true);
    this.enviarEventoPaginacao(eventoPaginacao);
  }

  /**
   * Recupera todos as linhas selecionadas da tabela.
   */
  getTodosTrBodiesSelecionados() {
    return this.trBodies.filter(tr => tr.selected);
  }

  /**
   * Recupera o identificador associado a cada linha selecionada
   * da tabela.
   */
  getTodosIdsTrBodiesSelecionados() {
    return this.getTodosTrBodiesSelecionados().map(tr => tr.id);
  }

}
