import { AgendamentoColeta } from './agendamentoColeta';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';

export class ConfiguracaoPesquisa {

  distancia: string;
  tempoMinimo: string;
  cercaEletronica: boolean = false;
  agendamentos: AgendamentoColeta[] = [];
  token: string;
  tokenVisualizacao?: string;
  amostrasPresenciais: number = 0;
  amostrasOnline: number = 0;

  static getControl(): UntypedFormGroup {

    return new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      distancia: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_DISTANCIA)),
      // tslint:disable-next-line: max-line-length
      amostrasPresenciais: new UntypedFormControl('0', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_AMOSTRAS)),
      // tslint:disable-next-line: max-line-length
      tempoMinimo: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TEMPO_MINIMO)),
      cercaEletronica: new UntypedFormControl(false),
      agendamentos: new UntypedFormArray([]),
      // forms
      token: new UntypedFormControl(''),
      amostrasOnline: new UntypedFormControl('0', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_AMOSTRAS)),
    });
  }

  static getInitializedControl(configuracaoPesquisa: ConfiguracaoPesquisa): UntypedFormGroup {

    // tslint:disable-next-line: max-line-length
    const agendamentosColetaFgs = configuracaoPesquisa.agendamentos.map(agendamentoColeta => AgendamentoColeta.getInitializedControl(agendamentoColeta));

    return new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      distancia: new UntypedFormControl(configuracaoPesquisa.distancia, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_DISTANCIA)),
      // tslint:disable-next-line: max-line-length
      amostrasPresenciais: new UntypedFormControl(configuracaoPesquisa.amostrasPresenciais, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_AMOSTRAS)),
      // tslint:disable-next-line: max-line-length
      tempoMinimo: new UntypedFormControl(configuracaoPesquisa.tempoMinimo, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TEMPO_MINIMO)),
      cercaEletronica: new UntypedFormControl(configuracaoPesquisa.cercaEletronica),
      agendamentos: new UntypedFormArray(agendamentosColetaFgs,Validators.required),
      // forms
      token: new UntypedFormControl(configuracaoPesquisa.token),
      amostrasOnline: new UntypedFormControl(configuracaoPesquisa.amostrasOnline),
    });
  }

}
