<div [formGroup]="formGroup" class="sg-input-group">
  <div class="campo">
    <input type="date" formControlName="data">
  </div>
  <span class="error-message" *ngIf="hasErrors()">
    Você precisa informar uma data válida.
  </span>
</div>

<!-- <mat-form-field>
  <input matInput [matDatepicker]="picker" placeholder="Escolha uma data" formControlName="data">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field> -->
