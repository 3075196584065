import {
  Component, EventEmitter, Input, OnInit, Output
} from "@angular/core";
import { TipoPergunta } from "app/modulos/pesquisa-old/cadastro/inputs/complex-input/tipoPergunta";

import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import {
  FormMode,
  ModeChangeEvent
} from "app/modulos/pesquisa-beta/cadastro/events";
import { EventBus } from "app/modulos/pesquisa-beta/eventbus";
import { ICanNotSaveButton } from "../../../pesquisas-cadastro.model";
import { Alternativa, Pergunta, Secao, TipoAlternativa } from "../pesquisas-logica.component";

@Component({
  selector: "app-editar-pergunta",
  templateUrl: "./editar-pergunta.component.html",
  styleUrls: ["./editar-pergunta.component.scss"],
})
export class EditarPerguntaComponent implements OnInit {
  // Array de secoes
  @Input() sectionList: Secao[];
  @Output() onCancel: EventEmitter<boolean> = new EventEmitter();
  //  Texto que usuario digita para filtrar a listagem de secoes e perguntas por titulo da pergunta
  inputText: string = "";

  // função que pega o comportamento igual do botão salvar
  @Output() onCanNotSaveButton: EventEmitter<ICanNotSaveButton> = new EventEmitter()

  //  Array resultante do filtro da lista de secoes de acordo com o texto digitado pelo usuario (por titulo de pergunta)
  secoesFiltradas: Secao[];
  inputChangeHandler(event: any) {
    this.inputText = event.target.value;
    this.secoesFiltradas = [
      ...this.sectionList
        .filter((section) =>
          section.perguntas.some((pergunta) =>
            pergunta.titulo.toLowerCase().includes(this.inputText.toLowerCase())
          )
        )
        .map((section) => ({
          ...section,
          perguntas: section.perguntas.filter((pergunta) =>
            pergunta.titulo.toLowerCase().includes(this.inputText.toLowerCase())
          ),
        })),
    ];
  }
  /**
   * Referência usada pelo HTML para exibir o drop de perguntas dentro de uma seção
   */
  public _dropDownActive: boolean = false;

  /**
   * Atributo que recebe o objeto da pergunta selecionada
   */
  public _changeSelectedQuestionRef: Pergunta = {} as Pergunta;

  /**
   * Atributo que recebe o objeto da Seção selecionada
   */
  public _changeSelectedSectionRef: Secao = {} as Secao;

  /**
   * questionData -> Referente a pergunta selecionada pelo usuário
   * sectionData -> Referente a Seção da Pergunta selecionada pelo usuário
   * sectionLIst -> Todas as Seções e suas pergunta
   */
  @Input() questionData: Pergunta = {} as Pergunta;
  @Input() sectionData: Secao = {} as Secao;

  formGroup: UntypedFormGroup;
  // objeto de facil acesso pawra os formControls
  formControls: { [key: string]: UntypedFormControl } = {} as {
    [key: string]: UntypedFormControl;
  };

  initFormGroup() {
    this.formControls["alternativas"] = new UntypedFormControl();

    this.formGroup = new UntypedFormGroup({
      alternativas: this.formControls.alternativas,
    });

    this.formGroup.get("alternativas").setValue(this.questionData.alternativas);
  }

  adicionarMarcacaoAAlternativa(indexOfAlternativa: number, {codigoMarcacao, comportamento}) {
    const tempArr = [...this.formGroup.get("alternativas").value];
    tempArr[indexOfAlternativa] = {
      ...tempArr[indexOfAlternativa],
      marcacaoPergunta: {
        codigoMarcacao: comportamento !== 'FINALIZAR_ENTREVISTA' ? codigoMarcacao : 'null',
      },
      comportamento: comportamento
    };
    this.formGroup.get("alternativas").setValue(tempArr);
  }

  removerMarcacaoDaAlternativa(indexOfAlternativa: number) {
    const tempArr = [...this.formGroup.get("alternativas").value.map((alternativa) => ({ ...alternativa }))];
    tempArr[indexOfAlternativa].marcacaoPergunta = null;
    tempArr[indexOfAlternativa].comportamento = 'CONTINUAR_ENTREVISTA';
    this.formGroup.get("alternativas").setValue(tempArr);
  }

  /**
   * handleSetShowDetails -> Referente a evento a ser transmitido para o pai para voltar para tela de listagem de seções no passo de marcações
   * handleAdicionarLogicaAUmaPerguntaComAlternativas -> Referente a evento a ser transmitido para o componente pai para a criar uma marcação de uma pergunta com alternativas
   * handleAdicionarLogicaAUmaPerguntaSemAlternativas -> Referente a evento a ser transmitido para o componente pai para a criar uma marcação de uma pergunta sem alternativas
   */

  @Output() handleSetShowDetails = new EventEmitter();
  @Output() handleAdicionarLogicaAUmaPerguntaComAlternativas =
    new EventEmitter<{
      secaoSelecionada: Secao;
      perguntaSelecionada: Pergunta;
      alternativas: Alternativa[];
    }>();
  @Output() handleAdicionarLogicaAUmaPerguntaSemAlternativas =
    new EventEmitter<{
      secaoSelecionada: Secao;
      perguntaSelecionada: Pergunta;
      codigoMarcacao: string;
    }>();

  adicionarLogicaAUmaPerguntaComAlternativas() {
    this.handleAdicionarLogicaAUmaPerguntaComAlternativas.emit({
      secaoSelecionada: this.sectionData,
      perguntaSelecionada: this.questionData,
      alternativas: this.formGroup.get("alternativas").value,
    });
    this.handleSetShowDetails.emit();
    // notificar o mudança no modo da pesquisa
    this.eventBus.fire(new ModeChangeEvent(FormMode.Editing));
  }

  adicionarLogicaAUmaPerguntaSemAlternativas() {
    // verifica se existe pergunta selecionada, caso não exista, atribui string vazia
    const newCode =
      this._changeSelectedQuestionRef &&
      this._changeSelectedQuestionRef.codigoMarcacao
        ? this._changeSelectedQuestionRef.codigoMarcacao
        : "";
    this.handleAdicionarLogicaAUmaPerguntaSemAlternativas.emit({
      secaoSelecionada: this.sectionData,
      perguntaSelecionada: this.questionData,
      codigoMarcacao: newCode,
    });
    this.handleSetShowDetails.emit();
    // notificar o mudança no modo da pesquisa
    this.eventBus.fire(new ModeChangeEvent(FormMode.Editing));
  }

  perguntaTemMarcacao(): boolean {
    return (
      ([TipoPergunta.UNICA, TipoPergunta.MULTIPLA, TipoPergunta.ESCALA_NUMERICA].includes(
        this.questionData.tipo
      ) &&
        this.formGroup
          .get("alternativas")
          .value.some(
            (alternativa: { marcacaoPergunta: { codigoMarcacao: string } }) =>
              !!alternativa.marcacaoPergunta
          )) ||
      (this._changeSelectedQuestionRef &&
        this._changeSelectedQuestionRef.codigoMarcacao)
    );
  }

  //verifica se a pergunta possui pulo configurado
  perguntaTemPulo(): boolean {
    return (
      ([TipoPergunta.UNICA, TipoPergunta.MULTIPLA, TipoPergunta.ESCALA_NUMERICA].includes(
        this.questionData.tipo
      ) &&
        this.formGroup
          .get("alternativas")
          .value.some(
            (alternativa) => alternativa.comportamento === 'FINALIZAR_ENTREVISTA' ||
            alternativa.comportamento === 'PULAR_PARA_PERGUNTA'
          ))
    );
  }

  removerMarcacoes(): void {
    const alternativas = this.formGroup
      .get("alternativas")
      .value.map((alternativa: Alternativa) => {
        // Estava mantendo a referência do objeto de alternativa original.
        const alternativaTemporaria = { ...alternativa };
        delete alternativaTemporaria.marcacaoPergunta;
        alternativaTemporaria.comportamento = 'CONTINUAR_ENTREVISTA'
        return alternativaTemporaria;
      });
    this.formGroup.get("alternativas").setValue(alternativas);
    // notificar o mudança no modo da pesquisa
    this.eventBus.fire(new ModeChangeEvent(FormMode.Done));
  }

  cancelar() {
    this.handleSetShowDetails.emit();
    // notificar o mudança no modo da pesquisa
    this.eventBus.fire(new ModeChangeEvent(FormMode.Done));
    this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: false } })
  }

  retornaReferenciasDaSecaoEDaPerguntaDaAlternativaComPulo(
    alternativaComPulo: Alternativa
  ): {
    pergunta: Pergunta;
    secao: Secao;
  } {
    if((alternativaComPulo.comportamento&&alternativaComPulo.comportamento) === 'FINALIZAR_ENTREVISTA') {
      const secao: Secao = {
        descricao: "Seção Fake",
        id: null,
        index: 0,
        nome: "Seção Fake",
        ordem: 1,
        perguntas: []
      }

      const pergunta: Pergunta = {
        alternativas: null,
        codigoMarcacao: null,
        id: null,
        marcacaoPergunta: null,
        obrigatorio: true,
        ordem: 1,
        orientacao: null,
        tipo: TipoPergunta.UNICA,
        titulo: "Pergunta Fake"
      }
      return {
        pergunta,
        secao,
      };
    }
    if (alternativaComPulo.marcacaoPergunta &&
        (alternativaComPulo.comportamento&&alternativaComPulo.comportamento) !== 'FINALIZAR_ENTREVISTA') {
      const codigoMarcacaoDaPerguntaDoPulo = alternativaComPulo.marcacaoPergunta
        .codigoMarcacao
        ? alternativaComPulo.marcacaoPergunta.codigoMarcacao
        : alternativaComPulo.marcacaoPergunta;

      const secao = this.sectionList
        ? this.sectionList.find((secao) =>
            secao.perguntas.some(
              (pergunta) =>
                pergunta.codigoMarcacao ===
                String(codigoMarcacaoDaPerguntaDoPulo)
            )
          )
        : ({} as Secao);

      const pergunta =
        secao && secao.perguntas
          ? secao.perguntas.find(
              (pergunta) =>
                pergunta.codigoMarcacao ===
                String(codigoMarcacaoDaPerguntaDoPulo)
            )
          : ({} as Pergunta);

      return {
        pergunta,
        secao,
      };
    } else {
      return {
        pergunta: {} as Pergunta,
        secao: {} as Secao,
      };
    }
  }

  constructor(private eventBus: EventBus) { }

  ngOnInit() {
    //  inicializando o array de secoes filtradas com o array original
    //  (enquanto o usuario nao digita algum dado para filtrar as secoes e perguntas)
    this.secoesFiltradas = [...this.sectionList];
    // verifica se a questão já possui vínculo
    if (
      this.questionData &&
      this.questionData.marcacaoPergunta &&
      this.questionData.marcacaoPergunta.codigoMarcacao
    ) {
      this._changeSelectedSectionRef = this.sectionList.find((item) =>
        item.perguntas.some(
          (pergunta) =>
            pergunta.codigoMarcacao ===
            this.questionData.marcacaoPergunta.codigoMarcacao
        )
      );

      this._changeSelectedQuestionRef =
        this._changeSelectedSectionRef.perguntas.find(
          (item) =>
            item.codigoMarcacao &&
            item.codigoMarcacao ===
              this.questionData.marcacaoPergunta.codigoMarcacao
        );
    }

    this.initFormGroup();

    // propago o evento dizendo que o usuário não pode usar o botão de avançar
    this.onCanNotSaveButton.emit({ metadata: { canNotSaveButton: true } })
  }

  /**
   * Função responsável por validar se o tipo de pergunta é única ou Múltipla
   * para renderizar o contexto de opções, ou se o tipo da pergunta é diferente
   * que então renderiza outro tipo de contexto mais simples
   */
  getTypeOfQuestion(type: TipoPergunta): boolean {
    return [TipoPergunta.UNICA, TipoPergunta.MULTIPLA, TipoPergunta.ESCALA_NUMERICA].includes(type);
  }

  /**
   * Função responsável por exibir ou tirar o dropdown de pulo de pergunta
   */
  setDropDownActive(): boolean {
    return (this._dropDownActive = !this._dropDownActive);
  }

  /**
   * Função que recebe por parâmetro o objeto de uma questão (ou pergunta) e
   * de seção que foi escolhido pelo usuário como salto
   * A função realiza três passos: o primeiro é setar nos atributos com os objetos
   * da questão e seção selecionada respectivamente; em seguida modifica o status
   * do atributo responsável por dizer que aquela pergunta tem um pulo agora; e
   * por fim, fecha o dropdown do select.
   */
  setSelectedQuestion(payload: { question; section }): void {
    const { question, section } = payload;
    // this.questionData.marcacaoPergunta = {
    //   codigoMarcacao: question.codigoMarcacao,
    // }
    this._changeSelectedQuestionRef = question;
    this._changeSelectedSectionRef = section;

    // this._hasSelected = true;
    this._dropDownActive = false;
  }

  /**
   * Este método faz o inversão do método de setar uma pergunta selecionada
   * ele limpa os atributos e modifica o status para false onde condiz com o cenário
   * de que não há pulo para a resposta/pergunta
   */
  setResetSelectedQuestion(): void {
    this._changeSelectedSectionRef = null;
    this._changeSelectedQuestionRef = null;
    // this._hasSelected = false;
  }

  // Função que formata tipo de pergunta
  formatTipoPergunta(tipo: TipoPergunta): string {
    return tipo === TipoPergunta.DATA
      ? "Data"
      : tipo === TipoPergunta.ESCALA_NUMERICA
      ? "Escala Numérica"
      : tipo === TipoPergunta.FOTO
      ? "Foto"
      : tipo === TipoPergunta.GRADE_MULTIPLA
      ? "Grade Múltipla"
      : tipo === TipoPergunta.GRADE_UNICA
      ? "Grade Única"
      : tipo === TipoPergunta.HORARIO
      ? "Horário"
      : tipo === TipoPergunta.MULTIPLA
      ? "Resposta Múltipla"
      : tipo === TipoPergunta.UNICA
      ? "Resposta Única"
      : "Vídeo";
  }

  formatTipoAlternativa(tipo: TipoAlternativa) {
    return tipo === TipoAlternativa.ABERTA_NUMERO
      ? "Aberta Número"
      : tipo === TipoAlternativa.ABERTA_TEXTO
      ? "Aberta Texto"
      : tipo === TipoAlternativa.ESCALA_NUMERICA
      ? "Escala Numérica"
      : tipo === TipoAlternativa.FECHADA
      ? "Fechada"
      : tipo === TipoAlternativa.GRADE
      ? "Grade"
      : "";
  }

  handleCancel(): void {
    this.onCancel.emit(true);
  }
}
