import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() setVisible: boolean;
  @Input() textInfo: string;
  @Input() type: string;

  ngOnInit() { }

  getCustomClass(type): string {
    switch (type) {
      case 'location':
        return 'isVisible location-pos';
        break;
      case 'sample':
        return 'isVisible sample-pos';
        break;
      case 'action':
        return 'isVisible action-pos';
        break;
      default:
        return 'isVisible';
        break;
    }
  }
}
