<div class="containerCard" [formGroup]="formGroup" class="perguntaa" (click)="notificarSelecao($event)"
    [ngClass]="selecionado ? 'selecionado' : ''">

  <app-pergunta-card
    [icone]="'fa fa-comment-o'"
    [titulo]="ordem + ' - PERGUNTA ÚNICA'"
    [nomePlaceHolder]="'Título da pergunta única?'"
    [formGroup]="formGroup"
  ></app-pergunta-card>

</div>
  <!-- <div>
    <i>ICONE DA Pergunta</i>
  </div>

  <div>
    <div>
      <span>PERGUNTA ÚNICA - {{ordem}}</span>
    </div>
    <label>
      {{controls.nome.value ? controls.nome.value : 'Titulo da pergunta única?*'}}
      <span class="error-message" [hidden]="!(showErrors && controls.nome.errors)">
        A pergunta deve possuir um título de até 250 caracteres
      </span>
    </label>
    <label>
      {{controls.descricao.value ? controls.descricao.value : 'Descrição*'}}
      <span class="error-message" [hidden]="!(showErrors && controls.descricao.errors)">
        A pergunta deve possuir uma descrição de até 150 caracteres
      </span>
    </label>
  </div>
  <button (click)="solicitarRemocao()">Remover Pergunta</button>

  <ul>
    <li>
      <input formControlName="possuiPulo" type="checkbox" (click)="toggleCheckbox('possuiPulo')">
      Pulo
    </li>
    <li>
      <input formControlName="possuiCota" type="checkbox" (click)="toggleCheckbox('possuiCota')">
      Cotas
    </li>
    <li>
      <input formControlName="possuiDiferenteDe" type="checkbox" (click)="toggleCheckbox('possuiDiferenteDe')">
      Diferente de
    </li>
    <li>
      <input formControlName="obrigatoria" type="checkbox" (click)="toggleCheckbox('obrigatoria')">
      Obrigatório
    </li>
  </ul> -->

<!-- </div> -->


<!-- <div class="">
  <div *ngFor="let alternativa of controls.alternativas.controls">
    <app-alternativa-tipada
      (inputUpdate)="handleNestedInputEvent()($event)"
      [parentEventPublisher]="nestedInputPublisher"
      [hashPergunta]="hashPergunta"
      [hashSecao]="hashSecao"
      [hash]="alternativa.value.hash"
    ></app-alternativa-tipada>
  </div>
  <button (click)="addAlternativa()">Adicionar Alternativa</button>
</div> -->
