<main class="options">
  <section *ngFor="let group of composedOptions; let groupIndex = index">
    <h3>{{ group?.title }}</h3>
    <div
      class="option"
      *ngFor="let option of group?.data"
      (click)="selectFilterOption(option, groupIndex)"
      [ngClass]="hasSelected(option, groupIndex) ? 'selected' : ''"
    >
      <div class="checkbox">
        <i class="fa-regular fa-check"></i>
      </div>
      <label for="">{{ option?.label }}</label>
    </div>
  </section>
</main>
