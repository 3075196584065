import { Localidade } from './localidade';
// abstração que representa uma linha da tabela de localidade
export class LocalidadeTr {

  localidade: Localidade;
  hierarquia: any;
  possuiFilho: boolean;
  selected: boolean;
  checkBoxSelected: boolean = false;
  localidadesFilhasTr: LocalidadeTr[] = [];
  filtrarApenasSearchResult?: any;

  constructor() {
    this.localidade = new Localidade();
  }
}
