import { Directive } from '@angular/core';
import { MascaraBase } from './mascaraBase';
import { NgControl } from '@angular/forms';
import { stringFormatter } from '../misc/stringFormatter';

@Directive({
  selector: '[mascara-lat-long]',
})
export class MascaraLatLongDirective extends MascaraBase {

  constructor(protected ngControl: NgControl) {
    super(ngControl, 15);
  }

  aplicarMascara(valor: string): string {

    const numericString = stringFormatter.toOnlyNumericString(valor);

    const numericStringVirgulasFormatadas = this.removerVirgulasIndevidas(numericString);

    // tslint:disable-next-line:max-line-length
    const numericStringNegativoFormatado = this.removerNegativoIndevido(numericStringVirgulasFormatadas);

    return numericStringNegativoFormatado;
  }

  removerNegativoIndevido(valor: string): string {

    const iniciaComNegacao = valor.startsWith('-');

    const valorSemNegacao = valor.replace(/-/g, '');

    return iniciaComNegacao ? `-${valorSemNegacao}` : valorSemNegacao;
  }

  removerVirgulasIndevidas(valor: string): string {

    const possuiVirgula = valor.includes(',');
    const possuiPonto = valor.includes('.');

    if (possuiPonto) {
      return valor.replace('.', ',');
    }

    if (!possuiVirgula || valor.startsWith(',')) {
      return valor.replace(',', '');
    }

    const posicaoVirgula = valor.indexOf(',');

    if (valor.startsWith('-') && posicaoVirgula === 1) {
      return valor.replace(',', '');
    }

    const valorSemVirgula = valor.replace(/,/g, '');

    // tslint:disable-next-line:max-line-length
    const valorFinalComVirgula = `${valorSemVirgula.slice(0, posicaoVirgula)},${valorSemVirgula.slice(posicaoVirgula, valorSemVirgula.length)}`;

    return valorFinalComVirgula;
  }

}
