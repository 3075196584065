import { Component, Input, OnInit } from "@angular/core";
import { IQuestion } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";

@Component({
  selector: "app-photo",
  templateUrl: "./photo.component.html",
  styleUrls: ["./photo.component.scss"],
})
export class PhotoComponent implements OnInit {
  @Input() questionData: IQuestion;

  constructor() {}

  ngOnInit(): void {}
}
