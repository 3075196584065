import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { errorMessages } from 'app/modulos/contratante-beta/erros';
import { EMAIL_REGEX, NAME_REGEX } from 'app/modulos/contratante-beta/utils/ContractorRegex';

@Component({
  selector: 'app-change-admin',
  templateUrl: './change-admin.component.html',
  styleUrls: ['./change-admin.component.scss']
})
export class ChangeAdminComponent implements OnInit {

  @Input() formGroup!: FormGroup;
  @Input() submitted: boolean;

  // Regex utilizados no formulario
  nameRegex = NAME_REGEX;
  emailRegex = EMAIL_REGEX;
  loginError = errorMessages.emailAlreadyExists;

  constructor() { }

  ngOnInit(): void {}

}
