import { Injectable } from '@angular/core';
import { ActionQueue } from '../actionQueue';
import { CadastroPesquisaStoreState } from '../cadastroPesquisaStoreState';
import { Store } from '@ngrx/store';
import { AbstractAction } from '../actions/abstractAction';

@Injectable({
  providedIn: 'root',
})
/**
 * Servico responsavel por resolver conflitos que uma pesquisa
 * possa ter durante sua edição
 */
export class ResolucaoConflitoService {

  private readonly actionQueue = new ActionQueue();

  constructor(private store: Store<CadastroPesquisaStoreState>) { }

  /**
   * Reexecuta alteracoes que a pesquisa sofreu durante a
   * edicao do usuario atual. Consiste em um replay das actions
   * lancadas pelo usuario desde o inicio da edição
   */
  reexecutarAlteracoes() {

    const replayedActions: AbstractAction[] = [];
    while (!this.actionQueue.isEmpty()) {

      const actionToReplay = this.actionQueue.dequeueAction();
      actionToReplay.isReplayAction = true;
      this.store.dispatch(actionToReplay);

      replayedActions.push(actionToReplay);
    }

    while (replayedActions.length > 0) {
      const actionToRequeue = replayedActions.shift();
      actionToRequeue.isReplayAction = false;
      this.actionQueue.enqueueAction(actionToRequeue);
    }
  }

  /**
   * Limpa as alteracoes feitas pelo usuario a pesquisa.
   * Com o termo 'limpar', estamos nos referindo as
   * actions que foram lancadas e estao armazenadas
   * na ActionQueue
   */
  limparAlteracoes() {
    this.actionQueue.clear();
  }

}
