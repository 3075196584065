function contarCasasDecimais(num: number): number {

  if (Math.floor(num) === num) {
    return 0;
  }

  return num.toString().split('.')[1].length || 0;
}

export { contarCasasDecimais };
