<form class="clean-form" [formGroup]="cleanForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="form-group">
    <div class="form-input">
      <label [class.error-format]="!cleanForm.get('latitude').valid && cleanForm.get('latitude').touched">
        Latitude
      </label>
      <input [class.error-format]="!cleanForm.get('latitude').valid && cleanForm.get('latitude').touched"
        class="just-input" type="text" placeholder="-0.0000000" id="latitude" formControlName="latitude" />
      <span class="error-input" *ngIf="
          !cleanForm.get('latitude').valid && cleanForm.get('latitude').touched
        ">
        Campo obrigatório
      </span>
    </div>
    <div class="form-input">
      <label [class.error-format]="!cleanForm.get('longitude').valid && cleanForm.get('longitude').touched">
        Longitude
      </label>
      <input [class.error-format]="!cleanForm.get('longitude').valid && cleanForm.get('longitude').touched"
        class="just-input" type="text" placeholder="-00.0000000" id="longitude" formControlName="longitude" />
      <span class="error-input" *ngIf="
          !cleanForm.get('longitude').valid &&
          cleanForm.get('longitude').touched
        ">
        Campo obrigatório
      </span>
    </div>
  </div>

  <div class="form-input">
    <label [class.error-format]="!cleanForm.get('fatherLocation').valid && cleanForm.get('fatherLocation').touched">
      Nome da Localidade pai
    </label>
    <input [class.error-format]="!cleanForm.get('fatherLocation').valid && cleanForm.get('fatherLocation').touched"
      class="just-input" type="text" placeholder="Informe um nome para a localidade" id="fatherLocation"
      formControlName="fatherLocation" />
    <span class="error-input" *ngIf="
        !cleanForm.get('fatherLocation').valid &&
        cleanForm.get('fatherLocation').touched
      ">
      Campo obrigatório
    </span>
  </div>

  <div class="form-group">
    <div class="form-input">
      <label
        [ngClass]="{
          'error-format': (cleanForm.get('cep').value && cleanForm.get('cep').hasError('cepInvalidLength')) || isInvalidCep || (cleanForm.get('cep').touched && !cleanForm.get('cep').value)
        }">
        CEP*
      </label>
      <input
        [ngClass]="{
          'error-format': (cleanForm.get('cep').value && cleanForm.get('cep').hasError('cepInvalidLength')) || isInvalidCep || (cleanForm.get('cep').touched && !cleanForm.get('cep').value),
          'just-input': true
        }"
        type="text" placeholder="0000-000" id="cep" formControlName="cep" mascara-cep
        (blur)="handleAutoCompleteByCep($event)" />
      <span class="error-input" *ngIf="!cleanForm.get('cep').value && cleanForm.get('cep').touched">
        Campo obrigatório
      </span>
      <span class="error-input" *ngIf="(cleanForm.get('cep').value && cleanForm.get('cep').hasError('cepInvalidLength')) || isInvalidCep">
        Cep inválido
      </span>
    </div>



    <div class="form-input">
      <label [class.error-format]="!cleanForm.get('country').valid && cleanForm.get('country').touched">
        País
      </label>
      <input [class.error-format]="!cleanForm.get('country').valid && cleanForm.get('country').touched"
        class="just-input" type="text" placeholder="Ex: Brasil" id="country" formControlName="country" />
      <span class="error-input" *ngIf="
          !cleanForm.get('country').valid && cleanForm.get('country').touched
        ">
        Campo obrigatório
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="form-input">
      <label [class.error-format]="!cleanForm.get('state').valid && cleanForm.get('state').touched">
        Estado
      </label>
      <input [class.error-format]="!cleanForm.get('state').valid && cleanForm.get('state').touched" class="just-input"
        type="text" placeholder="Ex: Paraíba" id="state" formControlName="state" />
      <span class="error-input" *ngIf="!cleanForm.get('state').valid && cleanForm.get('state').touched">
        Campo obrigatório
      </span>
    </div>
    <div class="form-input">
      <label [class.error-format]="!cleanForm.get('city').valid && cleanForm.get('city').touched">
        Cidade
      </label>
      <input [class.error-format]="!cleanForm.get('city').valid && cleanForm.get('city').touched" class="just-input"
        type="text" placeholder="Ex: João Pessoa" id="city" formControlName="city" />
      <span class="error-input" *ngIf="!cleanForm.get('city').valid && cleanForm.get('city').touched">
        Campo obrigatório
      </span>
    </div>
  </div>

  <div class="form-input">
    <label [class.error-format]="!cleanForm.get('address').valid && cleanForm.get('address').touched">
      Endereço
    </label>
    <input [class.error-format]="!cleanForm.get('address').valid && cleanForm.get('address').touched" class="just-input"
      type="text" placeholder="Informe o endereço da localidade" id="address" formControlName="address" />
    <span class="error-input" *ngIf="
        !cleanForm.get('address').valid && cleanForm.get('address').touched
      ">
      Campo obrigatório
    </span>
  </div>

  <div class="group-raio">
    <div class="form-select">
      <label [class.error-format]="!cleanForm.get('unit').valid && cleanForm.get('unit').touched">
        Unidade
      </label>
      <div class="wrapper" #inputwrappereference>
        <input type="checkbox" id="input" #inputcheckboxreference />
        <label for="input" class="button">
          <span class="selected-option">
            {{ cleanForm.get("unit").value }}
          </span>
          <span class="icon"></span>
        </label>
        <div class="menu-options">
          <ul>
            <li [class.active-option]="cleanForm.get('unit').value === 'Metros'">
              <button type="button" (click)="handleChangeUnit('Metros')">
                Metros
              </button>
            </li>
            <li [class.active-option]="cleanForm.get('unit').value === 'Kilômetros'">
              <button type="button" (click)="handleChangeUnit('Kilômetros')">
                Kilômetros
              </button>
            </li>
          </ul>
        </div>
      </div>
      <span class="error-input" *ngIf="!cleanForm.get('unit').valid && cleanForm.get('unit').touched">
        Campo obrigatório
      </span>
    </div>
    <div class="form-input">
      <label [class.error-format]="!cleanForm.get('raio').valid && cleanForm.get('raio').touched">
        Raio
      </label>
      <input [class.error-format]="!cleanForm.get('raio').valid && cleanForm.get('raio').touched" class="just-input"
        type="text" id="raio" formControlName="raio" [placeholder]="
          cleanForm.get('unit').value === 'Kilômetros'
            ? 'Informe a distância em KM'
            : 'Informe a distância em M'
        " />
      <span class="error-input" *ngIf="!cleanForm.get('raio').valid && cleanForm.get('raio').touched">
        Campo obrigatório
      </span>
    </div>
  </div>

  <div class="group-action-buttons">
    <button class="buttonSalvar" type="submit" [disabled]="!cleanForm.valid">
      Salvar
    </button>
    <button class="buttonCancel" type="button" (click)="handleCancelUpdate()" *ngIf="canShowCancelFormButton()">
      Cancelar
    </button>
  </div>
</form>
