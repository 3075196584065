import { Component, OnInit, Input } from "@angular/core";
import { IResultAlternative, IResultAnswer } from "../../results-interface";
import { orderNumericScale } from "../utils/parseDate";
@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  @Input() answer;
  @Input() questionType;
  @Input() showModal;

  renderLineData: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.isPhotoOrVideo();
    this.sortResultList();
  }

  /**
   * Caso a pergunta seja do tipo foto ou vídeo será verificado se existe descrição
   * Se não existir descrição será atribuido o valor 'Não encontrado'
   */
  checkIfDescriptionExists(): void {
    this.answer.forEach((a) => {
      if (!a.descricao) a.descricao = "Não encontrado";
    });
  }

  /**
   * Verifica se a pergunta é do tipo foto ou vídeo para
   * renderização condicional das informações percentuais
   */
  isPhotoOrVideo(): void {
    const types: string[] = ["FOTO", "VIDEO"];
    if (types.some((item) => this.questionType.includes(item))) {
      this.renderLineData = false;
      this.checkIfDescriptionExists();
    }
  }

  /**
   * Ordena as respostas exibidas de acordo com o questionType
   */
  sortResultList() {
    if (this.questionType === "ESCALA_NUMERICA") {
      this.answer = orderNumericScale(this.answer);
    } else {
      this.answer = this.answer.sort((a, b) => b.resultado_numerico - a.resultado_numerico);
    }
  }

  /***
   * Função criada para formatação do valor númerico na view de listagem.
   * Procura o maior valor dentro array de resultado_numerico e subtrai o length dele pelo length de currentResult
   * para retornar o padding que será aplicado a esquerda do currentResult.
   *
   * @currentResult : number = Representa o valor atual que está sendo renderizado
   * @valores : array<number> = Array de valores do atributo resultado_númerico
   * @maior : number = reprenta o maior valor encontrado no array de resultado_númerico
   * @returns valor que será aplicado no padding-left na renderização do elemento.
   */
  countCharacters(currentResult: number): string {
    const values = this.answer.map((obj) => obj["resultado_numerico"]);
    const biggerValue = values.reduce((bigger, current) =>
      bigger > current ? bigger : current
    );
    return `${
      biggerValue.toString().length - currentResult.toString().length
    }ch`;
  }
}
