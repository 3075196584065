import { v4 as uuid } from 'uuid';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import validadorEmptyControls from 'app/util/validador/validadorEmptyControls';
// tslint:disable-next-line: max-line-length
import { toFlatStructure, calcularHierarquias, toTreeStructure } from '../../passos/vinculos/localidadesUtils';
import { Vinculo, VinculoOperador } from '../../../../pesquisa-old/cadastro/model/vinculoEdicao';
import cotasValidator from 'app/util/validador/alternativa/cotasValidator';
import { aplicarMascaraPorcentagem } from 'app/util/mascara/mascaraPorcentagem';
import validadorMaiorQueNumber from 'app/util/validador/pergunta/validadorMaiorQueNumber';
import { contarCasasDecimais } from 'app/util/misc/numbers';

export class PesquisaQuartaEtapa {

  preparadoParaSalvar: boolean;
  exibirValidacao: boolean;

  /**
   * Representa o hash da localidade selecionada atualmente
   */
  localidadeVinculoSelecionadaHash: string;
  /**
   * Representa o estado da lista de localidades filhas da localidade selecionada,
   * cada uma com o identificador da localidade, cota percentual, e suas localidades filhas.
   */
  localidadesVinculos: LocalidadesVinculos;
  /**
   * mapa contendo todos os vinculos operadores mapeado para o hash de uma localidadeVinculo
   */
  operadoresVinculos: { [key: string]: OperadorVinculo[] };

  /**
   * form group que representa o formulário da quarta etapa,
   * aqui que pode ser verificado se o formulário possui erros de validação, etc.
   */
  formGroup: UntypedFormGroup;

  static fromVinculos(vinculos: Vinculo[]) {

    const state = defaultState();

    if (!vinculos || vinculos.length === 0) {
      state.operadoresVinculos = {};
      state.localidadesVinculos = {
        data: [],
        isLoading: false,
      };

      return state;
    }

    // cria novos vinculos a partir dos passados por parâmetro, porém com hashes gerados
    // pela biblioteca de uuid.
    const vinculosComHashes = PesquisaQuartaEtapa.criaHashes(vinculos);
    // calcula as hierarquias dos vinculos passados por parâmetros retornando um mapa
    // de hash -> hierarquia.
    const hierarquiasMap = calcularHierarquias(vinculosComHashes, 0, 'hash', 'filhos');
    // converte os vinculos com hash para uma estrutura flat ordenada.
    const vinculosComHashesFlat = <Vinculo[]>toFlatStructure(vinculosComHashes, 'filhos');

    // converte os vinculos flat com hash para LocalidadeVinculo.
    const localidadeVinculos: LocalidadeVinculo[] = vinculosComHashesFlat.map((vinculo) => {
      const hierarquia = hierarquiasMap[vinculo.hash];
      return LocalidadeVinculo.fromVinculo(vinculo, hierarquia);
    });

    // tslint:disable-next-line: max-line-length
    const operadoresVinculos: { [key: string]: OperadorVinculo[] } = vinculosComHashesFlat.map(v => {

      const entry = {};

      const operadoresVinculos = v.operadores.map((o) => {
        return OperadorVinculo.fromOperador(o);
      });

      entry[v.hash] = operadoresVinculos;

      return entry;
    }).reduce((prev, next) => ({ ...prev, ...next }));

    state.operadoresVinculos = operadoresVinculos;
    state.localidadesVinculos = {
      data: localidadeVinculos,
      isLoading: false,
    };

    console.log('state: ', state);

    return state;
  }

  private static criaHashes(roots: Vinculo[], paiVinculoHash: string = ''): Vinculo[] {

    const novosRoots = [];
    if (!roots) {
      return novosRoots;
    }

    for (const vinculo of roots) {

      vinculo.hash = vinculo.id ? String(vinculo.id) : uuid();
      vinculo.paiVinculoHash = paiVinculoHash;

      const filhosComHash = PesquisaQuartaEtapa.criaHashes(vinculo.filhos, vinculo.hash);
      vinculo.filhos = filhosComHash;

      novosRoots.push(vinculo);
    }

    return novosRoots;
  }

  static criaOperadoresVinculosFormGroup(operadoresVinculos: OperadorVinculo[]): UntypedFormGroup {

    if (!operadoresVinculos || operadoresVinculos.length === 0) {
      return new UntypedFormGroup({});
    }

    const operadoresVinculosControls = operadoresVinculos.map((o) => {

      const controls = {};
      controls[o.hash] = new UntypedFormGroup({
        cotaPercentual: new UntypedFormControl(o.cotaPercentualStr),
        idOperador: new UntypedFormControl(o.idOperador ? o.idOperador : undefined, [Validators.required]),
      });

      return controls;

    }).reduce((prev, next) => ({ ...prev, ...next }));

    const operadoresVinculosFormGroup = new UntypedFormGroup({
      ...operadoresVinculosControls,
    });

    PesquisaQuartaEtapa.setUpValidators(operadoresVinculosFormGroup, operadoresVinculos);

    operadoresVinculosFormGroup.updateValueAndValidity();

    return operadoresVinculosFormGroup;
  }

  // tslint:disable-next-line: max-line-length
  static setUpValidators(operadoresVinculosFormGroup: UntypedFormGroup, operadorVinculos: OperadorVinculo[]) {

    operadorVinculos.forEach((operadorVinculo) => {

      // tslint:disable-next-line: max-line-length
      PesquisaQuartaEtapa.setValidator(operadoresVinculosFormGroup, operadorVinculo, operadorVinculos);
    });
  }

  /**
   * Recupera um conjunto de FormGroup associados aos operadores vinculos
   * passados por parâmetro.
   *
   * @param operadoresVinculos operadores vinculos que terão seus FormGroups retornados na busca.
   */
  // tslint:disable-next-line: max-line-length
  static getFormGroups(operadoresVinculosFormGroup: UntypedFormGroup, operadoresVinculos: OperadorVinculo[]): UntypedFormGroup[] {

    const controls: UntypedFormGroup[] = operadoresVinculos.map((l) => {
      return <UntypedFormGroup>operadoresVinculosFormGroup.get(l.hash);
    });

    return controls;
  }

  // tslint:disable-next-line: max-line-length
  static getControls(operadoresVinculosFormGroup: UntypedFormGroup, operadoresVinculos: OperadorVinculo[], controlName: string): UntypedFormControl[] {

    // tslint:disable-next-line: max-line-length
    const cotasPercentuaisControl = <UntypedFormControl[]>PesquisaQuartaEtapa.getFormGroups(operadoresVinculosFormGroup, operadoresVinculos).map(fm => fm.get(controlName));
    return cotasPercentuaisControl;
  }

  /**
   * Configurando validador de que todas a soma das cotas devem ser 100%.
   * @param operadoresVinculosFormGroup form group a ser adicionado as validações e buscado
   * a referencia para cada control de operador vinculo
   * @param operadorVinculoBase operador vinculo cuja cota deve ter esta validação
   * @param operadoresAComparar operadores vinculos irmãos a serem utilizados
   * na soma para verificar se a mesma é 100%
   */
  // tslint:disable-next-line: max-line-length
  static setValidator(operadoresVinculosFormGroup: UntypedFormGroup, operadorVinculoBase: OperadorVinculo, operadoresAComparar: OperadorVinculo[]) {
    // form group de cada operador vinculo
    const baseFormGroup = operadoresVinculosFormGroup.get(operadorVinculoBase.hash);
    // recuperando o conjunto de form controls de cotaPercentual dos
    // vinculos operadores passadas por parâmetro
    const toCompareControls: UntypedFormControl[] = PesquisaQuartaEtapa
      .getControls(operadoresVinculosFormGroup, operadoresAComparar, 'cotaPercentual');

    const validadorMaiorQueZero = validadorMaiorQueNumber(0);

    // criando um validador que utilizará como parâmetro o conjunto
    // de cota percentual control dos operadores vinculos passadas
    const cotasValidatorInstance = cotasValidator(toCompareControls);
    // utilizando o validador criado acima no control de cotaPercentual do operador vinculo atual.
    // tslint:disable-next-line: max-line-length
    baseFormGroup.get('cotaPercentual').setValidators([cotasValidatorInstance, validadorMaiorQueZero]);
    baseFormGroup.get('cotaPercentual').updateValueAndValidity();
  }

}

export class OperadorVinculo {

  /**
   * identificador do vinculo operador já cadastrado no banco, se já estiver.
   */
  id?: number;

  /**
   * identificador do componente mapeado para este estado, gerado localmente
   */
  hash: string;
  /**
   * referência a qual operador esta selecionado
   */
  idOperador: number;

  /**
   * cota percentual que este operador deverá cumprir
   */
  cotaPercentual: number | string;
  cotaPercentualStr: string;

  /**
   * Cota numérica relativa a cota percentual deste operador * cota numérica da localidade
   */
  cotaNumerica: number;

  constructor(idOperador: number = undefined, cotaPercentual: number = 0) {

    this.idOperador = idOperador;
    this.cotaPercentual = cotaPercentual;
    this.hash = uuid();

    this.cotaPercentual = 0;
    this.cotaPercentualStr = '0';
    this.cotaNumerica = 0;
  }

  static fromOperador(vinculoOperador: VinculoOperador): OperadorVinculo {
    return {
      cotaNumerica: vinculoOperador.cotaValor,
      cotaPercentual: vinculoOperador.cotaPercentual,
      cotaPercentualStr: aplicarMascaraPorcentagem(Number(vinculoOperador.cotaPercentual) * 100),
      hash: uuid(),
      id: vinculoOperador.idVinculoOperador,
      idOperador: vinculoOperador.idOperador,
    };
  }

}

export class LocalidadesVinculos {

  isLoading: boolean;
  data: LocalidadeVinculo[];

  // tslint:disable-next-line: max-line-length
  static criaLocalidadesVinculosFormGroup(localidadeVinculos: LocalidadeVinculo[], operadoresVinculos: { [key: string]: OperadorVinculo[] }) {

    /**
     * Caso não tenhamos localidades, retornamos um formGroup
     * invalido
     */
    if (localidadeVinculos.length === 0) {
      return new UntypedFormGroup({
        required: new UntypedFormControl(null, Validators.required)
      });
    }

    const localidadeVinculosTree = toTreeStructure(localidadeVinculos);

    const controls = localidadeVinculos.map((l: LocalidadeVinculo) => {

      // tslint:disable-next-line: max-line-length
      const operadoresVinculosDaLocalidade = operadoresVinculos[l.hash] ? operadoresVinculos[l.hash] : [];

      const controls = {};

      // constrói o form group de operadores vinculos
      // tslint:disable-next-line: max-line-length
      const operadoresVinculosFG: UntypedFormGroup = PesquisaQuartaEtapa.criaOperadoresVinculosFormGroup(operadoresVinculosDaLocalidade);
      // caso a localidade não possua filhos, seta o validador
      // de que a localidade DEVE ter operadores vinculados.
      if (!l.possuiFilho) {
        operadoresVinculosFG.setValidators([validadorEmptyControls]);
        operadoresVinculosFG.updateValueAndValidity();
      }

      controls[l.hash] = new UntypedFormGroup({
        cotaPercentual: new UntypedFormControl(l.cotaPercentualStr),
        operadoresVinculos: operadoresVinculosFG,
      });

      return controls;

    }).reduce((prev, next) => ({ ...prev, ...next }));

    const localidadesFormGroup = new UntypedFormGroup({
      ...controls,
    });

    // configura validadores para localidadesFormGroup
    // validadores como "a soma dos campos de cotas devem ser 100%", etc
    // tslint:disable-next-line: max-line-length
    LocalidadesVinculos.setUpValidatorRecursively(localidadesFormGroup, localidadeVinculosTree);

    localidadesFormGroup.setValidators([validadorEmptyControls]);
    localidadesFormGroup.updateValueAndValidity();

    return localidadesFormGroup;
  }

  /**
   * Configura os validadores para todas as localidades
   * @param localidadesTree localidades vinculos em estrutura de árvore
   */
  // tslint:disable-next-line: max-line-length
  static setUpValidatorRecursively(localidadesFormGroup: UntypedFormGroup, localidadesTree: LocalidadeVinculo[]) {

    if (!localidadesTree || localidadesTree.length === 0) {
      return;
    }

    for (const localidade of localidadesTree) {

      LocalidadesVinculos.setValidator(localidadesFormGroup, localidade, localidadesTree);
      LocalidadesVinculos.setUpValidatorRecursively(localidadesFormGroup, localidade.children);
    }
  }

  /**
   * Recupera um conjunto de FormGroup associados às localidadesVinculos
   * passadas por parâmetro.
   *
   * @param localidades localidades vinculos que terão seus FormGroups retornados na busca.
   */
  // tslint:disable-next-line: max-line-length
  static getFormGroups(localidadesFormGroup: UntypedFormGroup, localidades: LocalidadeVinculo[]): UntypedFormGroup[] {

    const controls: UntypedFormGroup[] = localidades.map((l) => {
      return <UntypedFormGroup>localidadesFormGroup.get(l.hash);
    });

    return controls;
  }

  // tslint:disable-next-line: max-line-length
  static getControls(localidadesFormGroup: UntypedFormGroup, localidades: LocalidadeVinculo[], controlName: string): UntypedFormControl[] {

    // tslint:disable-next-line: max-line-length
    const cotasPercentuaisControl = <UntypedFormControl[]>this.getFormGroups(localidadesFormGroup, localidades).map(fm => fm.get(controlName));
    return cotasPercentuaisControl;
  }

  /**
   * Configura os validadores de CotaPercentual para as localidadesVinculos
   * passadas por parâmetro.
   *
   * @param localidades localidadesVinculos que devem ser validadas em conjunto
   */
  // tslint:disable-next-line: max-line-length
  static setValidator(localidadesFormGroup: UntypedFormGroup, localidadeBase: LocalidadeVinculo, localidadesAComparar: LocalidadeVinculo[]) {

    let localidadesACompararNomes = '';

    if (localidadesAComparar.length > 0) {
      localidadesACompararNomes = localidadesAComparar
        .map(l => `${l.nomeLocalidade}[${l.cotaPercentual}]`)
        .reduce((prev, next) => prev.concat(', ').concat(next));
    }

    // tslint:disable-next-line: max-line-length
    // console.log(`localidadeBase: ${localidadeBase.nomeLocalidade}[${localidadeBase.cotaPercentual}] localidades irmãs: `, localidadesACompararNomes);

    // recupera o form group da localidade base
    const baseFormGroup = localidadesFormGroup.get(localidadeBase.hash);
    // console.log('localidadeBaseFG: ', baseFormGroup);
    /**
     * recuperando o conjunto de form controls
     * de cotaPercentual das localidades a serem comparadas (localidades irmãs de localidadeBase)
     */
    // tslint:disable-next-line: max-line-length
    const toCompareControls: UntypedFormControl[] = LocalidadesVinculos.getControls(localidadesFormGroup, localidadesAComparar, 'cotaPercentual');
    // console.log('localidadesIrmasFG: ', toCompareControls);

    const validadorMaiorQueZero = validadorMaiorQueNumber(0);

    /**
     * criando um validador que utilizará como parâmetro
     * o conjunto de cota percentual control das localdiades passadas
     */
    const cotasValidatorInstance = cotasValidator(toCompareControls);
    // utilizando o validador criado acima no control de cotaPercentual da localidade atual.
    // tslint:disable-next-line: max-line-length
    baseFormGroup.get('cotaPercentual').setValidators([cotasValidatorInstance, validadorMaiorQueZero]);
    baseFormGroup.get('cotaPercentual').updateValueAndValidity();
  }
}

export class LocalidadeVinculo {

  id?: number;

  /**
   * dados da vinculo pai
   */
  hashVinculoPai?: string;
  nomeLocalidadePai: string;
  cotaNumericaPai: number;

  /**
   * dados de localidade
   */
  hash: string;
  idLocalidade: number;
  nomeLocalidade: string;

  /**
   * Flag indicando se esta localidade vinculo
   * está expandida ou não (e.g. mostra localidades filhas ou não)
   */
  expanded: boolean;

  /**
   * Flag indicando se esta localidade vinculo deve ser exibida ou não. Bastante
   * util para esconder as localidades filhas quando o vinculo pai não está expandido.
   */
  hidden: boolean;

  /**
   * Flag indicando se a localidade referenciada possui localidades filhas.
   */
  possuiFilho: boolean;
  /**
   * Flag indicando se seus filhos já foram carregados.
   */
  childrenLoaded: boolean;

  /**
   * cota desta localidade
   */
  cotaPercentual: number;
  cotaPercentualStr: string;
  cotaNumerica: number;

  /**
   * Valor indicando a hierarquia da localidade.
   */
  hierarquia: number;

  versao: string;

  /**
   * localidades filhas
   */
  children: LocalidadeVinculo[] = [];

  // tslint:disable-next-line: max-line-length
  constructor(
    idLocalidade: number,
    nomeLocalidade: string,
    versao: string,
    possuiFilho: boolean,
    childrenLoaded: boolean,
    hashVinculoPai?: string,
    ) {

    this.idLocalidade = idLocalidade;
    this.nomeLocalidade = nomeLocalidade;
    this.cotaPercentual = 0;
    this.cotaPercentualStr = '0';
    this.versao = versao;
    this.cotaNumerica = 0;
    this.possuiFilho = possuiFilho;
    this.hash = uuid();
    this.hashVinculoPai = hashVinculoPai;
    this.expanded = false;
    this.hidden = false;
    this.childrenLoaded = childrenLoaded;
  }

  // tslint:disable-next-line: max-line-length
  static fromVinculo(vinculo: Vinculo, hierarquia: number = 0): LocalidadeVinculo {

    const cotaPercentual = vinculo.cotaPercentual;
    const cotaNumerica = vinculo.cotaValor;
    const idLocalidade = vinculo.localidade.id;
    const nomeLocalidade = vinculo.localidade.nome;

    const nomeLocalidadePai = vinculo.paiVinculoHash ? '' : vinculo.localidade.nomeLocalidadePai;

    const cotaPercentualNum = Number(vinculo.cotaPercentual);

    const cotaPercentualCasasDecimais = contarCasasDecimais(cotaPercentualNum);

    const cotaPercentualNormalized =
      cotaPercentualCasasDecimais < 2 ? cotaPercentualNum * 100
        : cotaPercentualNum;

    return {

      id: vinculo.id,

      hash: vinculo.hash,
      children: [],
      // tslint:disable-next-line: object-shorthand-properties-first
      cotaNumerica,
      // tslint:disable-next-line: object-shorthand-properties-first
      cotaPercentual: Number(cotaPercentual),
      cotaPercentualStr: aplicarMascaraPorcentagem(cotaPercentualNormalized),
      hidden: false,
      expanded: true,
      // tslint:disable-next-line: object-shorthand-properties-first
      nomeLocalidade,
      possuiFilho: vinculo.localidade.possuiFilhos,
      versao: vinculo.localidade.versao,
      // tslint:disable-next-line: object-shorthand-properties-first
      idLocalidade,
      // tslint:disable-next-line: object-shorthand-properties-first
      hierarquia,

      cotaNumericaPai: undefined,
      // tslint:disable-next-line: object-shorthand-properties-first
      nomeLocalidadePai,
      hashVinculoPai: vinculo.paiVinculoHash,

      childrenLoaded: true,
    };
  }
}

// SELETORES

export class OperadorSelect {
  isLoading: boolean;
  items: OperadorItem[];
}

export class OperadorItem {
  id: number;
  nome: string;
  foto: string;
  matricula: string;
  dataInicioVinculo: Date;
  dataInicioVinculoString: string;
  dataFimVinculo: Date;
  dataFimVinculoString: string;
}

export class LocalidadesSelect {
  idLocalidadeSelecionada: number;
  isLoading: boolean;
  items: LocalidadeItem[];
}

export class LocalidadeItem {
  id: number;
  nome: string;
}

export function defaultState() {

  return <PesquisaQuartaEtapa>{

    preparadoParaSalvar: false,
    exibirValidacao: false,

    localidadeVinculoSelecionadaHash: undefined,
    localidadesVinculos: {
      data: [],
      isLoading: false,
    },

    operadoresVinculos: {},

    formGroup: new UntypedFormGroup({}),
  };

}
