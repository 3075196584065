import { NotificatorService } from "app/notificador/notificator.service";
import { LocalidadeService } from "../../servico/localidade.service";
import { ModalCreadUpdateService } from "../modal-create-update.service";

/**
 * Classe gerenciadora do nominatim, atualmente lida com o preparo
 * da montagem do mapa personalizado.
 * 
 * TODO: Esta classe futuramente terá o intúito de lidar tanto com o mapa
 * gerado no modo default (quando existem dados armazenados no banco de dados do nominatim)
 * quanto no modo custom (quando a localidade é personalizada)
 */
export class LeafletMapManager {
  constructor(
      private localidadeService: LocalidadeService,
      private modalCreateUpdateService: ModalCreadUpdateService,
      private notificatorService: NotificatorService
  ) {}
  
  private latitude: number = null;
  private longitude: number = null;
  private latMin: number = null;
  private latMax: number = null;
  private longMin: number = null;
  private longMax: number = null;
  private radius: number = null;

  // Atualiza os valores internos para preparar os dados de montagem
  // do mapa.
  updateInternals(
    lat: number,
    long: number,
    radius: number,
  ) {
    this.latitude = lat;
    this.longitude = long;
    this.radius = radius;
  }

  // Converte um raio em km para metros.
  private handleConvertToMeters(raio: string) {
    const raioToSplit = `${raio}`.split(".");
    if (raioToSplit.length > 1) {
      const splitDecimal = raioToSplit[1].split("");
      const finalTemplateRaio = `${raioToSplit[0]}${splitDecimal[0]}${splitDecimal[1]}${splitDecimal[2]}`;
      return Number(finalTemplateRaio);
    }
    return Number(raio);
  }

  // Cuida da montagem do mapa
  async resolveMap() {
    // Usamos a reverse api do nominatim para recuperar objetos OSM através da latitude
    // e longitude definidos pelo usuário, o intúito é ter uma visualização mais precisa
    // do mapa quando o usuário configurar as informações no formulário.
    const nominatimLocations = await this.localidadeService.listarLocalidadesComDetalhesByCoords(this.latitude, this.longitude);
    const locs = [];

    // O retorno da api pode ser um array de objetos OSM ou um único objeto OSM, por isso
    // tratamos ambos os casos aqui, incluindo o caso de erro, que é quando a api do nominatim
    // não consegue encontrar nenhuma localidade próxima às coordenadas inseridas.
    if (Array.isArray(nominatimLocations)) {
      locs.push(...nominatimLocations);
    } else if (typeof nominatimLocations === 'object' && !nominatimLocations.error) {
      locs.push(nominatimLocations);
    }

    // Se nenhuma localidade proxima foi encontrada, nós renderizamos o mapa
    // apenas com uma circunferência e seu raio
    if (!locs.length) {
      this.handleMountCustomMap();
    }
    // Caso contrário, nós passamos os objetos OSM retornados pela api.
    else {
      locs.forEach((loc) => {
        this.handleMountCustomMap(loc);
      });
    }
  }

  private isValidLatitudeAndLong(lat: number | string, long: number | string) {
    if (typeof lat !== 'number' || typeof long !== 'number') {
      lat = Number(lat);
      long = Number(long);
    }

    return (isFinite(lat) && Math.abs(lat) <= 90) && (isFinite(long) && Math.abs(long) <= 180);
  }

  // Cuida da montagem do mapa
  private handleMountCustomMap(localidade?): void {
    // Atualizando a posição da latitude e longitude No Serviço
    const currentUserLat = this.latitude;
    const currentUserLon = this.longitude

    // Caso as coordenadas não sejam válidas, será exibido um alerta indicando a impossibilidade de montar o mapa
    if (!this.isValidLatitudeAndLong(currentUserLat, currentUserLon)) {
      return this.notificatorService.showAlert(
        "Montagem do mapa",
        "Não foi possível demarcar a localidade"
      );
    }

    // Atualizando os dados no Serviço do componente Pai
    this.modalCreateUpdateService.saveCurrentLatAndLon(
      currentUserLat,
      currentUserLon
    );

    if (localidade) {
      const [latmin, latmax, lonmin, lonmax] = localidade.boundingbox;

      // pegando a boundingBox da localidade selecionada
      this.latMin = latmin;
      this.latMax = lonmin;
      this.longMin = latmax;
      this.longMax = lonmax;

      this.modalCreateUpdateService.saveBoundingBox(
        this.latMin,
        this.latMax,
        this.longMin,
        this.longMax
      );
    };

    // Convertendo raio em km para metros
    const radius = this.handleConvertToMeters(`${this.radius}`);
    // Salvando nas variaveis de controle do serviço
    this.modalCreateUpdateService.saveCurrentRadius(radius);
    // Indica ao componente leaftlet que o mapa pode ser renderizado.
    this.modalCreateUpdateService.canCreateBounds(true, 'Circle');
    this.modalCreateUpdateService.setUpUpdateMap();
  }
}