export enum TipoPergunta {

    UNICA = 'UNICA',
    MULTIPLA = 'MULTIPLA',
    MULTIPLA_ORDEM_CITACAO = 'MULTIPLA_ORDEM_CITACAO',
    ESCALA_NUMERICA = 'ESCALA_NUMERICA',
    FOTO = 'FOTO',
    VIDEO = 'VIDEO',
    GRADE_UNICA = 'GRADE_UNICA',
    GRADE_MULTIPLA = 'GRADE_MULTIPLA',
    DATA = 'DATA',
    HORARIO = 'HORARIO',

}
