<div class="echarts-container"
  [ngClass]="enableScrolling && 'scrollable'"
>
  <div
  echarts
  [options]="chartOption"
  class="chart"
  [ngClass]="showModal ? 'expand-chart' : 'chart'"
  (chartInit)="onChartInit($event)"
>
</div>
</div>
