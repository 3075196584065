import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { Subject, takeUntil } from "rxjs";
import { FilterTypes, months } from "../../../constants";
import { DateAdapter } from "@angular/material/core";

@Component({
  selector: "app-header-date-select",
  templateUrl: "./header-date-select.component.html",
  styleUrls: ["./header-date-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDateSelectComponent implements OnInit {
  private _destroyed = new Subject<void>();

  date = new Date();
  CollectionInterval = {
    month: [0, 11],
    year: [
      this.setInitialYearRange(),
      this.date.getFullYear()+20
    ],
  };

  //  Objeto de configuração do select de mês
  monthConfig = {
    type: FilterTypes.LIST,
    icon: null,
    placeholder: months[this.CollectionInterval.month[0]].label,
    options: [],
  };

  //  Objeto de configuração do select de ano
  yearConfig = {
    type: FilterTypes.LIST,
    icon: null,
    placeholder: `${this.CollectionInterval.year[0]}`,
    options: [],
  };

  constructor(
    private _dateAdapter: DateAdapter<Date>,
    private _calendar: MatCalendar<any>,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }
  ngOnInit(): void {
    this.setInitialYearRange();
    this.initYears();
    this.initMonths();
    this.calendarBootstrap();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  /**
   * Define a o ano inicial no range do calendário
   * @returns dataInicial
   */
  setInitialYearRange() {
    const userDate = new Date(this._calendar.activeDate);
    if(userDate.getFullYear() < this.date.getFullYear()) {
      return userDate.getFullYear();
    } else {
      return this.date.getFullYear();
    }
  }

  /**
   * Inicia o mês do calendário como o primeiro mês do inicio das coletas
   */
  calendarBootstrap() {
    let userDate: Date;
    try {
      userDate = new Date(this._calendar.activeDate);
    } catch(err) {
      userDate = new Date('');
      userDate.setMonth(0);
    }

    const goToMonth = {
      label: months[userDate.getMonth()].label,
      id: months[userDate.getMonth()].id,
    };
    const goToYear = { label: userDate.getFullYear(), value: 0 };

    this.handleSelectYear(goToYear);
    this.handleSelectMonth(goToMonth);
  }

  monthsByYear = {};
  /**
   * Inicializa o array de meses a sere exibidos no cabeçalho do calendário
   */
  initMonths() {
    // Calcula o intervalo dos meses e atribui no array e define no objeto de configurações de mês
    const {
      year: [startYear, endYear],
      month: [startMonth, endMonth],
    } = this.CollectionInterval;

    if (startYear === endYear) {
      this.monthConfig.options = months.slice(startMonth, endMonth + 1);
    } else {
      this.obterMesesDoAno();
      this.monthConfig.options = this.monthsByYear[startYear];
    }
    this.monthConfig.placeholder = months[0].label;
  }

  /**
   * Inicializa o array de anos a sere exibidos no cabeçalho do calendário
   */
  initYears() {
    // Calcula o intervalo dos anos de atribui a um array e define no objeto de configurações de ano
    const [startYear, endYear] = this.CollectionInterval.year;
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push({ label: i, id: i - startYear });
    }
    this.yearConfig.options = years;
  }

  obterMesesDoAno() {
    const {
      year: [startYear, endYear],
      month: [startMonth, endMonth],
    } = this.CollectionInterval;

    this.yearConfig.options.forEach(({ label: y }) => {
      this.monthsByYear[y] = months;
    });

    this.monthsByYear[startYear] = this.monthsByYear[startYear].slice(
      startMonth,
      12
    );
    this.monthsByYear[endYear] = this.monthsByYear[endYear].slice(
      0,
      endMonth + 1
    );
  }

  /**
   * Muda para o mês selecionado pelo usuáriono no select e no componente de calendário
   * @param month numero do mês selecionado pelo usuário
   */
  handleSelectMonth(month) {
    // Altera a label exibida no placeholder do select
    this.monthConfig.placeholder = month.label;

    // Calcula quantos meses deve avançar ou voltar a partir do mês atual
    const goTo =
      month.id - this._dateAdapter.getMonth(this._calendar.activeDate);

    // Altera o mês selecionado no componente mat-calendar do angular material
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this.getNewCalendarDate(),
      goTo
    );
  }

  /**
   * Muda para o ano selecionado pelo usuáriono no select e no componente de calendário
   * @param year tipo number do ano que foi selecionado pelo usuário
   */
  handleSelectYear(year) {
    const [startYear, endYear] = this.CollectionInterval.year;
    if (startYear !== endYear) {
      this.monthConfig.options = this.monthsByYear[year.label];
      this.monthConfig.placeholder = this.monthConfig.options[0].label;
    }

    // Altera o valor da label do placeholder do select
    this.yearConfig.placeholder = year.label;

    // Calcula quantos anos deve avançar ou voltar a partir do ano atual
    const goTo =
      year.label - this._dateAdapter.getYear(this._calendar.activeDate);

    // Altera o ano selecionado no componente mat-calendar do angular material
    this._calendar.activeDate = this._dateAdapter.addCalendarYears(
      this._dateAdapter.createDate(
        this._dateAdapter.getYear(this._calendar.activeDate),
        this.monthConfig.options[0].id,
        1
      ),
      goTo
    );
  }

  // Cria uma nova data a partir do mês e ano atual a partir do dia 1
  getNewCalendarDate() {
    return this._dateAdapter.createDate(
      this._dateAdapter.getYear(this._calendar.activeDate),
      this._dateAdapter.getMonth(this._calendar.activeDate),
      1
    );
  }
}
