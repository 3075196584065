import {
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { Subject } from "rxjs";
import { RespostaPergunta } from "../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { RespostaComponentFactoryService } from "./resposta-component-factory.service";

@Component({
  selector: "app-container-resposta",
  templateUrl: "./container-resposta.component.html",
  styleUrls: ["./container-resposta.component.scss"],
})
export class ContainerRespostaComponent implements OnInit, OnDestroy {
  @Input() respostaPergunta: RespostaPergunta;
  @Input() proximaPerguntaSubject: Subject<{}>;
  @Input() novaRespostaPerguntaSubject: Subject<RespostaPergunta>;
  @Input() closeRespostaSubject: Subject<{}>;

  @ViewChild("respostaComponent", { static: true }) container: ViewContainerRef;
  private createdRespostaComponent: ComponentRef<any>;

  constructor(
    private resolver: ViewContainerRef,
    private respostaComponentFactory: RespostaComponentFactoryService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.createdRespostaComponent) {
      this.createdRespostaComponent.destroy();
    }
    this.createComponent(this.respostaPergunta);
  }

  ngOnDestroy() {}

  /**
   * Renderiza um componente baseado no tipo da pergunta.
   *
   * É utilizado o RespostaComponentFactoryService para recuperar o componente
   * baseado no tipoPergunta.
   */
  createComponent(respostaPergunta: RespostaPergunta) {
    if (this.container) {
      // this.container.clear();

      const tipoPergunta: string = respostaPergunta.pergunta.tipo;

      const respostaComponent: any =
        this.respostaComponentFactory.getComponent(tipoPergunta);

      if (!respostaComponent) {
        console.error(
          "[RespostaComponent.createComponent] there's no component registered for the qualifier ",
          tipoPergunta
        );
        return;
      }

      this.createdRespostaComponent =
        this.resolver.createComponent(respostaComponent);
      const createdRespostaComponentInstance =
        this.createdRespostaComponent.instance;

      createdRespostaComponentInstance.respostaPergunta = respostaPergunta;
      createdRespostaComponentInstance.pergunta = respostaPergunta.pergunta;
      createdRespostaComponentInstance.novaRespostaPerguntaSubject =
        this.novaRespostaPerguntaSubject;
      createdRespostaComponentInstance.proximaPerguntaSubject =
        this.proximaPerguntaSubject;
      createdRespostaComponentInstance.closeRespostaSubject =
        this.closeRespostaSubject;

      const formArray: UntypedFormArray = this.formBuilder.array(
        respostaPergunta
          .getAlternativasSelecionadas()
          .map((as) => this.formBuilder.group(as))
      );

      createdRespostaComponentInstance.alternativasSelecionadasFormGroup =
        new UntypedFormGroup({
          alternativasSelecionadas: formArray,
          idAlternativaSelecionada: new UntypedFormControl(-1),
        });

      createdRespostaComponentInstance.alternativasSelecionadas = formArray;
    }
  }
}
