export class ChartColor {

  backgroundColor : string | CanvasGradient | string[];
  borderColor : string;
  pointBackgroundColor : string;
  pointBorderColor : string;
  pointHoverBackgroundColor : string;
  pointHoverBorderColor : string;

}
