interface IContato {
  email?: string;
  telefone?: string;
}

export interface IPermissoes {
  nomeReal: string;
  nomeAmigavel: string;
  selecionado: boolean;
  disabled?: boolean;
  groupsIds?: number[];
}

export interface IGroups {
  nome: string;
  id: number;
  selecionado: boolean;
}

interface IUsuario {
  grupos: IGroups[];
  ativo: boolean;
  id: number | null;
  login: string;
  permissoes: IPermissoes[];
}

interface IPessoa {
  cpf: string;
  id: number | null;
  nome: string;
}

interface IFoto {
  arquivo: string;
}

export interface IFuncionarioPayload {
  cargo: string;
  contato: IContato;
  fotoEnviada: IFoto | null;
  pessoa: IPessoa;
  usuario: IUsuario;
}

export interface IFuncionarioEditPayload {
  cargo: string;
  contato: IContato;
  fotoEnviada: IFoto | null;
  id: number;
  pessoa: IPessoa;
  usuario: IUsuario;
}

export interface IFuncionarioData {
  cargo: string;
  contato: IContato;
  foto: string;
  id: number;
  matricula: number;
  pessoa: IPessoa;
  usuario: IUsuario;
}

export enum GroupActionType {
  ADD_GROUP = 'ADD_GROUP',
  REMOVE_GROUP = 'REMOVE_GROUP'
}

export interface IGetOrRemoveGroup {
  groupId: number;
  actionType: GroupActionType;
}

