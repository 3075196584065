import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IItemBreadcrumb } from 'app/componentes/breadcrumb/breadcrumb.interface';
import { ModalService } from "app/componentes/modal/modal.service";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import { NotificatorService } from "app/notificador/notificator.service";
import { NewFotoUploaderComponent } from 'app/util/componente/new-foto-uploader/new-foto-uploader.component';
import { ToastrService } from "ngx-toastr";
import { Administrador } from "../management/instancia-cadastro/Administrador";
import { AdministradorService } from './service/administrador.service';
import { NotifcationType, buildNotificationSuccess, defaultError, passwordError } from './utils/notify';

@Component({
  selector: "app-administrador-beta",
  templateUrl: "./administrador.component.html",
  styleUrls: ["./administrador.component.scss"],
})
export class AdministradorComponentBeta implements OnInit {
  // componente de upload de foto caso necessario
  @ViewChild(NewFotoUploaderComponent, { static: true })
  newFotoUploaderComponent: NewFotoUploaderComponent;

  controls: { [key: string]: AbstractControl } = {};

  // caminho da página
  dataBreadcrumb: IItemBreadcrumb[];

  isAlteracaoSenha: boolean = false;
  administrador: Administrador;
  formGroup;

  // variáveis referente ao modal
  showModal: boolean = false
  modalType: string;

  notifyObject: any;
  isLoading: boolean = false;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected securityService: SecurityService,
    protected administradorService: AdministradorService,
    protected toastr: ToastrService,
    protected notificatorService: NotificatorService,
    protected modalService: ModalService
  ) { }

  ngOnInit() {

    this.initFormGroup();
    this.notifyObject = buildNotificationSuccess(NotifcationType.BASIC);

    // retorna o caminho da página
    this.dataBreadcrumb = [
      {
        itemName: "Início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Editar perfil",
        itemLink: `/administrador-beta/atualizar?id=${this.activatedRoute.snapshot.queryParams["id"]}`,
        active: true,
      },
    ];
  }

  // função para voltar uma página
  handleBackPage(): void {
    this.modalService.showModal({
      title: 'Sair da edição do perfil',
      messageModal: 'Ao sair, as informações inseridas serão descartadas. Deseja mesmo sair?',
      btnTitlePositive: 'Sair',
      btnTitleNegative: 'Cancelar',
      icon: 'fa-sharp fa-regular fa-door-open',
      positiveCallback: () => this.router.navigate([".."]),
    });

  }

  handleShowModal(type = null): void {
    // exibe ou fecha o modal
    this.modalType = type;
    this.showModal = !this.showModal;
  }

  initFormGroup() {
    this.administradorService.getAdministrator().subscribe({
      next: (data) => {
        this.formGroup = Administrador.getInitializedControl(data);
        this.controls = this.formGroup.controls;
        this.renderAdminPhoto(data);
      }
    })

    this.formGroup = Administrador.getControl();
    this.controls = this.formGroup.controls;
  }


  addPhotoToPayload() {
    if (this.newFotoUploaderComponent.defaultImage === this.newFotoUploaderComponent.url) {
      this.formGroup.value.foto_enviada = null;
    } else {
      this.formGroup.value.foto_enviada.arquivo = this.newFotoUploaderComponent.url;
    }
  }


/**
 * Renderiza a foto do usuário
 * @param entidade: representa o objeto do admin
 */
 renderAdminPhoto(entidade: Administrador) {
    this.administrador = entidade;
    if (this.administrador.foto.arquivo) {
      this.newFotoUploaderComponent.loadImageByUrl(this.administrador.foto.arquivo);
    }
  }


  /**
   * Verifica se o formulário é valido para chamar a modal de confirmação
   */
  handleSubmit() {
    if(this.formGroup.status === 'VALID') {
      this.showModal ? this.showModal = false : ''
      this.modalService.showModal({
        title: 'Salvar edições',
        messageModal: 'Você realizou edições no seu perfil. Deseja salvar suas alterações?',
        btnTitlePositive: 'Continuar',
        btnTitleNegative: 'Cancelar',
        icon: 'fa-regular fa-badge-check',
        positiveCallback: () => this.submit(),
      });
    }
  }

  /**
   * Metodo que realiza a chamada ao backend para atualização do administrador
   */
  submit(): void {
    const {title, text} = this.notifyObject;

      this.isLoading = true;
      this.addPhotoToPayload();
      this.administradorService.putAdministrator(this.formGroup.value).subscribe({
        next: (response) => {},
        complete: () => {
          this.isLoading = false;
          this.notificatorService.showInfo(title, text);
        },
        error: ({ error }) =>  {
          this.isLoading = false;
          /**
           * Verifica se dentro do array de erros existe o erro de "Senha incorreta"
           * Se existir o erro o input se torna invalido (invalidInput: True)
           */
          const erroResumo = error && error.error.resumo
          if(erroResumo && erroResumo.includes(passwordError['text'])) {
            this.controls.usuario.get('senha_atual').setErrors({ invalidInput: true })
            this.notificatorService.showError(passwordError['title'], passwordError['text']); // notifica o erro de senha
          } else {
            this.notificatorService.showError(defaultError['title'], defaultError['text']); // notifca erro genérico
          }
        }
      });
  }
}
