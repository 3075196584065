import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { InputValidatorFactory } from '../inputs/inputValidatorFactory';
import { TipoInput } from '../inputs/tipoInput';

export class DescricaoPesquisa {

  titulo: string;
  tituloCurto: string;
  objetivo: string;
  tipoPesquisa: string;
  permitirGravacao: boolean = false;

  static getControl(): UntypedFormGroup {

    return new UntypedFormGroup({
      titulo: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO)),
      // tslint:disable-next-line: max-line-length
      tituloCurto: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO_CURTO)),
      // tslint:disable-next-line: max-line-length
      objetivo: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_OBJETIVO)),
      // tslint:disable-next-line: max-line-length
      tipoPesquisa: new UntypedFormControl('', InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TIPO)),
      permitirGravacao: new UntypedFormControl(false),
    });

  }

  static getInitializedControl(descricaoPesquisa: DescricaoPesquisa): UntypedFormGroup {

    return new UntypedFormGroup({
      // tslint:disable-next-line: max-line-length
      titulo: new UntypedFormControl(descricaoPesquisa.titulo, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO)),
      // tslint:disable-next-line: max-line-length
      tituloCurto: new UntypedFormControl(descricaoPesquisa.tituloCurto, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TITULO_CURTO)),
      // tslint:disable-next-line: max-line-length
      objetivo: new UntypedFormControl(descricaoPesquisa.objetivo, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_OBJETIVO)),
      // tslint:disable-next-line: max-line-length
      tipoPesquisa: new UntypedFormControl(descricaoPesquisa.tipoPesquisa, InputValidatorFactory.getValidadores(TipoInput.DESCRICAO_TIPO)),
      permitirGravacao: new UntypedFormControl(descricaoPesquisa.permitirGravacao),
      
    });
  }

}
