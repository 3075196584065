import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'enumToArray' })
export class EnumToArrayPipe implements PipeTransform {

  transform(object: any): Object {
    const items = Object.keys(object).filter(key => key)
      .map((key) => {
        return {
          key,
          value: object[key],
        };
      });

    return items;
  }
}
