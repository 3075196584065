import { ColetaCriterioFriendlyName } from "../../constant/criterio";
import { ICriterio } from "../../interfaces";

interface ColorObject {
  text: string;
  backgroundColor: string;
  color: string;
}

export const mapCollectionCriteriaToColorObject = function (
  criteria: ICriterio[]
): ColorObject[] {
  // Verifica se a coleta é online
  const hasOnlineCollection = criteria.some(
    (criterion) =>
      criterion.nome === "COLETA_ONLINE" && criterion.atendido === true
  );

  if (hasOnlineCollection) {
    return [
      {
        text: ColetaCriterioFriendlyName.COLETA_ONLINE,
        backgroundColor: "var(--gray150)",
        color: "var(--gray300)",
      },
    ];
  } else {
    const renderedCriterion = [...criteria]
      .filter((criterio) => criterio.nome !== "COLETA_ONLINE") // Retira o critério de "coleta online" do array
      .map((criterio) => {
        if (criterio.atendido) {
          return {  
            text: ColetaCriterioFriendlyName[criterio.nome],
            backgroundColor: "var(--success100)",
            color: "var(--success200)",
          };
        } else {
          return {
            text: ColetaCriterioFriendlyName[criterio.nome],
            backgroundColor: "var(--alert100)",
            color: "var(--alert200)",
          };
        }
      });

    return renderedCriterion;
  }
};
