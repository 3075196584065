import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { Rejeicao } from '../../model/rejeicao';

@Component({
  selector: 'app-rejeicao-dialog',
  templateUrl: './rejeicao-dialog.component.html',
  styleUrls: ['./rejeicao-dialog.component.scss']
})
export class RejeicaoDialogComponent implements OnInit {

  formGroup : UntypedFormGroup;
  private onRejeicaoConfirmada : Subject<Rejeicao>;
  private idEntrevista : number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<RejeicaoDialogComponent>,
    private formBuilder : UntypedFormBuilder) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      'causa': ['', Validators.required],
    });

    this.onRejeicaoConfirmada = this.data.onRejeicaoConfirmada;
    this.idEntrevista = this.data.idEntrevista;
  }

  cancelar() {
    this.dialogRef.close();
  }

  salvarRejeicao(form : { causa : string }) {
    if(this.onRejeicaoConfirmada) {

      const causa : string = form.causa;

      this.onRejeicaoConfirmada.next({
        causa,
        idEntrevista: this.idEntrevista,
      });

      this.dialogRef.close();
    }
  }

}
