<app-moldura-telas-de-acesso
  [title]="'Recuperação de senha'"
  [subtitle]="!requested ?
  'Informe seu e-mail de cadastro. Enviaremos o link de recuperação' : ''
  "
>
  <form action="" [formGroup]="formGroup" #form="ngForm"
    (ngSubmit)="form.valid && solicitar(form.value)">
    <div *ngIf="requested">
      <div class="info-data">
        <p>Link de recuperação foi enviado para</p>
        <p class="user-email">{{this.formGroup.get('email')?.value}}</p>
        <p>
          Lembre-se de também verificar a sua caixa de spam. Caso não tenha recebido o e-mail, envie novamente clicando no botão abaixo.
        </p>
      </div>
    </div>
    <div  class="input-wrapper" *ngIf="!requested">
      <div [ngClass]="form.submitted && this.formGroup.get('email').errors ? 'login-input error': 'login-input'">
        <input
          type="text"
          placeholder="email@email.com"
          formControlName="email"
          name="email"
          id="loginInput"
          maxlength="50"
        >
      </div>
      <span *ngIf="form.submitted && formGroup.controls.email.errors">Campo obrigatório</span>
    </div>
    <button type="submit" [disabled]="isButtonDisabled">
      {{ renderSubmitBtnTitle }}
    </button>
    <button type="button" (click)="goBackLoginPage()">Voltar ao início</button>

  </form>

</app-moldura-telas-de-acesso>
<app-loading *ngIf="isLoading"></app-loading>
