<div class="containerAuditoriaResposta">

  <div class="title">
    <h2>Localidades das Entrevistas Selecionadas</h2>
  </div>

  <section class="map-wrapper">
    <app-leaflet-map-beta
      [mapHeight]="'200px'"
    ></app-leaflet-map-beta>
  </section>

  <section class="audit-info-wrapper">
    <div class="audit-info" *ngFor="let entrevista of entrevistasSelecionadas">
        <span><strong>Operador:</strong> {{ entrevista.nomeOperador }} </span>
        <span><strong>Duração da entrevista:</strong> {{entrevista.duracao}} </span>
        <span><strong>Data de início:</strong>  {{entrevista.inicioColeta}} </span>
        <span><strong>Data de fim:</strong> {{entrevista.fimColeta}} </span>
        <span><a routerLink="/auditoria/pesquisas/{{idPesquisa}}/entrevistas/{{entrevista.id}}">VISUALIZAR ENTREVISTA</a></span>
    </div>
  </section>


  <div class="actions">
    <button (click)="fecharModal()" class="sg-btn sg-alternate mr-3">Sair</button>
  </div>

</div>
